// Customizable Area Start
import React from "react";
//Resources
import { DeleteIcon,fileUploadIcon } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";
// mui components
import ClearIcon from "@material-ui/icons/Clear";
// custom components
import CustomSelectField from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
//accordian css

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  TextField,
  Checkbox,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { Typography, Textarea } from '@mui/joy';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter} from "react-router-dom";
import Box from "@mui/material/Box";
// icons
//components
import AdminLearningCoursesTasksController from "./AdminLearningCoursesTasksController.web";
import { commonCmptStyles } from "./dropDownCss";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//@ts-ignore

import { Avatar } from "@mui/material";
import Editor from "../../../components/src/TaskTextEditor"
import Loader from "../../../components/src/Loader.web";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const schema = Yup.object().shape({
  title: Yup.string().required("This field is required."),
  options: Yup.array()
    .required("Options are required.")
    .min(4),
  allOptiosFilled: Yup.string().when("options", {
    is: (options) => {
      const isEmpty = options.filter(
        (obj: any) =>
          !obj.attributes.title_option && !obj.attributes.option_image
      );
      return isEmpty.length > 0;
    },
    then: Yup.string().required("Options cannot be empty"),
  }),
  rightAnswer: Yup.array()
    .required("Please select right answer.")
    .min(1),
  task_point: Yup.string()
    .matches(/^[0-9]$/, "Point not valid")
    .required("This field is required."),
});
class AdminLearningCoursesTasks extends AdminLearningCoursesTasksController {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const courseStatus = ["active", "deactivate", "draft"];
    const taskDetails = this.state.taskDetails?.attributes;
    const AvatarBg=["#8db1f6","#ba8df6","#f68dc3","#69cbb2","#b28df6"]
    return (
      <>
        <Loader loading={this.state.loading} />
        <Box className="adminAddProductWrapper">
          <Formik
            enableReinitialize
            innerRef={this.state.myRef}
            initialValues={this.taskInitialValue(taskDetails)}
            validationSchema={this.state?.schema}
            onSubmit={(values) => {
              this.handleTaskSubmit(values)
            }}
          >
            {(formikProps) => {
              const {
                values,
                setFieldValue,
                handleChange,
                errors,

              } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid container xs={12} spacing={2}>
                    <Grid item md={8}>
                      <div className="productDetailsContainer">
                        {/* MCQ MODAL */}
                        <div className="header">
                          <div onClick={() => this.handleBackButton()}>
                            <ArrowBackIcon />
                          </div>
                          <h4>
                            {this.handleTaskHeadingText()}{" "}
                            Task
                          </h4>
                        </div>
                        {this.state.task_type == "mcq" && (
                          <Box className="learningModalBlocks" id="mcqBlock">
                            <div className="subModalBlock">
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <label className="texteditor-block">
                                    Task Title
                                  </label>
                                  <Editor placeholder={"Q1. Type your question here..."} values={values}
                                    setFieldValue={setFieldValue} />
                                  <span
                                    className="errorMsg"
                                    style={{ margin: "20px" }}
                                  >
                                    <ErrorMessage name="title" />
                                  </span>
                                </div>
                              </div>
                              <div className="modalContent learningAddPartModalContent mcqaModalContantainer ">
                                <div className="taskPoint">
                                  <h6
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Task Point
                                  </h6>
                                </div>
                                <div className="outlineTextfield boxRadiusBorder">
                                  <div className="customTextfield">
                                    <TextField
                                      id="task_point"
                                      value={values.task_point}
                                      onChange={handleChange}
                                      variant="standard"
                                      placeholder="Enter Marks..."
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                    <span
                                      className="errorMsg"
                                    // style={{ margin: "20px" }}
                                    >
                                      <ErrorMessage name="task_point" />
                                    </span>
                                  </div>
                                </div>

                                <div className="answerListContainer">
                                  <h3>Answers</h3>
                                  <div className="mcqaHeader">
                                    <p>Select tick for correct answer</p>
                                    <Box className="checkboxContainer">
                                      <label htmlFor="">Multiple Answers</label>
                                      <div className="customToggleButton">
                                        <label className="switch" style={{"background":`${values.isMultiple ? "#e7ffed" :"#fff"}`}}>
                                          <input
                                           id="isMultipleSwitch"
                                            checked={values.isMultiple}
                                            onChange={(e: any) => {
                                              this.handleMultipleCheckSwitch(e, setFieldValue)
                                            }}
                                            className="switchCheckbox"
                                            type="checkbox"
                                          />
                                          <div className="switchButton" style={{"backgroundColor":`${values.isMultiple ? "#20a258" :"gray"}`}} />
                                        </label>
                                      </div>
                                    </Box>
                                  </div>
                                  <div className="answerLists">
                                    {values.options.map(
                                      (option: any, index: any) => {
                                        const serial = [
                                          "A",
                                          "B",
                                          "C",
                                          "D",
                                          "E",
                                        ][index];
                                        return (
                                          <div
                                            className="answer"
                                            key={option.id}
                                          >
                                            <Avatar
                                              sx={{
                                                bgcolor: `${AvatarBg[index]}`,
                                                width: 55,
                                                height: "unset",
                                              }}
                                              variant="rounded"
                                            >
                                              {serial}
                                            </Avatar>
                                            <div className="regRestInfoForm">
                                              <div className="outlineTextfield boxRadiusBorder">
                                                <div className="customTextfield mcqWordInput">
                                                  <TextField
                                                    id="text"
                                                    data-testid="textEditor"
                                                    variant="standard"
                                                    value={
                                                      option.attributes
                                                        .title_option
                                                    }
                                                    onChange={(e: any) => {
                                                      this.handleTexteditorchange(values, option, setFieldValue, e)
                                                    }}
                                                    placeholder="Enter Option"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                                  {/* contentContainer1 */}
                                                  <div
                                                    className={`contentContainer ${this.handleContentCSS(option?.attributes?.option_image)} `}
                                                  >
                                                    {option.attributes
                                                      .option_image && (
                                                        <div className="imgContainer">
                                                          {option.attributes
                                                            .option_image.url ? (
                                                            <img
                                                              alt="image"
                                                              src={
                                                                option.attributes
                                                                  .option_image
                                                                  .url
                                                              }
                                                            />
                                                          ) : (
                                                            <img
                                                              alt="image"
                                                              src={this.handleImageSrc(option)}
                                                            />
                                                          )}
                                                          <span className="imageTitle">
                                                            {this.handleImageTitle(option?.attributes)}
                                                          </span>
                                                          <span
                                                            onClick={() => {
                                                              this.optionsClick(values, option, setFieldValue)
                                                            }}
                                                          >
                                                            <ClearIcon />
                                                          </span>
                                                        </div>
                                                      )}
                                                    <div className="iconContainer">
                                                      <div className="iconBlock">
                                                        <div className="fileUpload">
                                                          <div className="customFileUpload">
                                                            <input
                                                              type="file"
                                                              accept="image/png, image/jpeg, image/jpg"
                                                              id={option.id}
                                                              data-testid="fileUploadInput"
                                                              className="fileUploadInput"
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                this.imageUploadChange(e, values, option, setFieldValue)
                                                              }}
                                                            />
                                                            <label
                                                              htmlFor={
                                                                option.id
                                                              }
                                                              className="fileUploadIcon"
                                                            >
                                                              <span className="icon">
                                                                <img src={fileUploadIcon}/>
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="customLabelCheckbox mcqCheckWidth">
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox
                                                                id={option.id}
                                                                data-testid="checkBoxChange"
                                                                checked={values.rightAnswer.includes(
                                                                  //@ts-ignore
                                                                  option.id
                                                                )}
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  this.checkBoxChange(e, values, option, setFieldValue)
                                                                  //this.isOptionFilled()
                                                                }}
                                                              />
                                                            }
                                                            label=""
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="icon"
                                              id="deleteIcon"
                                              onClick={() => {
                                                this.handleOptionDeleteClick(option, values, setFieldValue)
                                              }}
                                            >
                                              <Button>
                                                <img
                                                  src={DeleteIcon}
                                                  className="edit-icon mcqDeleteIcon"
                                                  alt="Edit Icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  {values.options.length > 0 &&
                                    !errors.allOptiosFilled && (
                                      <span className="errorMsg">
                                        <ErrorMessage name="options" />
                                      </span>
                                    )}
                                  <div
                                    className="btnContainer"
                                    style={{
                                      cursor: "pointer",
                                      opacity:`${values.options.length==5 ? .5:1}`
                                    }}
                                    onClick={() => {

                                      this.addOptionClick(values, setFieldValue)
                                      //this.isOptionFilled();
                                    }}
                                  >
                                    <span className="addbtn" />
                                    <span>Add Option</span>
                                  </div>
                                  {values.options.length == 0 ? (
                                    <span className="errorMsg">
                                      <ErrorMessage name="options" />
                                    </span>
                                  ) : (
                                    <span className="errorMsg">
                                      <ErrorMessage name="allOptiosFilled" />
                                    </span>
                                  )}
                                  {!errors.allOptiosFilled && !errors.options && (
                                    <span className="errorMsg">
                                      <ErrorMessage name="rightAnswer" />
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="btnContainer"
                                  style={{
                                    marginTop: "35px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <span
                                        className="grayButton"
                                        onClick={() => {
                                          //@ts-ignore
                                          this.props.history.goBack();
                                        }}
                                      >
                                        <CustomButton
                                          color="secondary"
                                          label="Cancel"
                                        />
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <span className="greenContained">
                                        <CustomButton
                                          color="secondary"
                                          type="submit"
                                          label={`${this.handlebuttonLabel()}`}
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )}

                        { }
                        { }
                        {this.state.task_type == "true_false" && (
                          <Box className="learningModalBlocks">
                            <div className="subModalBlock">
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <label className="texteditor-block">
                                    Task Title
                                  </label>
                                  <Editor placeholder={"Q1. Type your question here..."} values={values}
                                    setFieldValue={setFieldValue} />
                                  <span
                                    className="errorMsg"
                                    style={{ margin: "20px" }}
                                  >
                                    <ErrorMessage name="title" />
                                  </span>
                                </div>
                              </div>
                              <div className="modalContent learningAddPartModalContent trueFalseModalConent ">
                                <div className="taskPoint">
                                  <h6>Task Point</h6>
                                  <div className="outlineTextfield boxRadiusBorder">
                                    <div className="customTextfield">
                                      <TextField
                                        id="task_point"
                                        value={values.task_point}
                                        onChange={handleChange}
                                        variant="standard"
                                        placeholder="Enter Marks..."
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      <span
                                        className="errorMsg"
                                      // style={{ margin: "20px" }}
                                      >
                                        <ErrorMessage name="task_point" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="answersListContainer">
                                  <div className="taskPoint">
                                    <h6>Answers</h6>
                                  </div>
                                  <p>Select tick for correct answer</p>

                                  <div className="iconContainer">
                                    <div className="iconBlock">
                                      <div className="customLabelCheckbox">
                                        <FormControl>
                                          <RadioGroup
                                          data-testid="bool_value"
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={values.bool_value}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                "bool_value",
                                                e.target.value
                                              );
                                            }}
                                            name="radio-buttons-group"
                                          >
                                            <div className="answer ">
                                              <Avatar
                                                sx={{
                                                  bgcolor: "#8db1f6",
                                                  width: 55,
                                                  height: "unset",
                                                }}
                                                variant="rounded"
                                              >
                                                A
                                              </Avatar>
                                              <div className="contentContainer true">
                                                <div className="answerDescription">
                                                  True
                                                </div>
                                                <div className="radioButtonContainer">
                                                  <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="answer ">
                                              <Avatar
                                                sx={{
                                                  bgcolor: "#ba8df6",
                                                  width: 55,
                                                  height: "unset",
                                                }}
                                                variant="rounded"
                                              >
                                                B
                                              </Avatar>

                                              <div className="contentContainer false">
                                                <div className="answerDescription">
                                                  False
                                                </div>
                                                <div className="radioButtonContainer">
                                                  <FormControlLabel
                                                    value="2"
                                                    control={<Radio />}
                                                    label=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="btnContainer"
                                  style={{
                                    marginTop: "35px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <span
                                      data-testid="cancel_bool"
                                        className="grayButton"
                                        onClick={() => {
                                          //@ts-ignore
                                          this.props.history.goBack();
                                        }}
                                      >
                                        <CustomButton
                                          color="secondary"
                                          label="Cancel"
                                        />
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <span className="greenContained">
                                        <CustomButton
                                          color="secondary"
                                          type="submit"
                                          label={`${this.handlebuttonLabel()
                                            }`}
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )}
                        { }
                        { }
                        {["long_answer", "short_answer"].includes(this.state.task_type) && (
                          <Box className="learningModalBlocks">
                            <div className="subModalBlock">
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <label className="texteditor-block">
                                    Task Title
                                  </label>
                                  <Editor placeholder={"Q1. Type your question here..."} values={values}
                                    setFieldValue={setFieldValue} />
                                  <span
                                    className="errorMsg"
                                    style={{ margin: "20px" }}
                                  >
                                    <ErrorMessage name="title" />
                                  </span>
                                </div>
                              </div>
                              <div className="modalContent learningAddPartModalContent longAnsModalContent">
                                <div className="taskPoint">
                                  <h6>Task Point</h6>
                                  <div className="outlineTextfield boxRadiusBorder">
                                    <div className="customTextfield">
                                      <TextField
                                        id="task_point"
                                        value={values.task_point}
                                        onChange={handleChange}
                                        variant="standard"
                                        placeholder="Enter Marks..."
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                      <span
                                        className="errorMsg"
                                      // style={{ margin: "20px" }}
                                      >
                                        <ErrorMessage name="task_point" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="taskTypeContainer">
                                  <div className="taskPoint">
                                    <h6>Task Type</h6>
                                  </div>
                                  {/* <Grid container spacing={2}> */}
                                  <div className="iconContainer">
                                    <div className="iconBlock">
                                      <div className="customLabelCheckbox">
                                        <Grid item xs={12} md={8}>
                                          <FormControl>
                                            <RadioGroup
                                              id="radioLong"
                                              aria-labelledby="demo-radio-buttons-group-label"
                                              value={this.state.task_type}
                                              name="radio-buttons-group"
                                              onChange={(e:any)=>{
                                                this.handleRadioToggle(e.target.value,setFieldValue)                                           
                                              }}
                                            >
                                              <Grid container spacing={2}>
                                              {this.handleShowAns("short_answer") && <Grid item xs={12} md={6}>
                                                  <div className="taskType">
                                                    <p>Short Answer</p>
                                                    <FormControlLabel
                                                      value="short_answer"
                                                      control={<Radio />}
                                                      label=""
                                                    />
                                                  </div>
                                                </Grid>}
                                                {this.handleShowAns("long_answer") && <Grid item xs={12} md={6}>
                                                  <div className="taskType">
                                                    <p>Long Answer</p>
                                                    <FormControlLabel
                                                      value="long_answer"
                                                      control={<Radio />}
                                                      label=""
                                                    />
                                                  </div>
                                                </Grid>}
                                              </Grid>
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </Grid> */}
                                  {/* <Grid item xs={5} md={5}> */}
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                      <Grid item xs={12} md={6}>
                                        <div className="outlineTextfield boxRadiusBorder">
                                          <div className="customTextfield">
                                            <TextField
                                              id="word_limit"
                                              value={values.word_limit}
                                              onChange={handleChange}
                                              variant="standard"
                                              placeholder="Word Limit"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                            <span
                                              className="errorMsg"
                                            // style={{ margin: "20px" }}
                                            >
                                              <ErrorMessage name="word_limit" />
                                            </span>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {/* </Grid> */}
                                  <Grid
                                    container
                                    spacing={2}
                                    className="solutionRow"
                                  >
                                    <Grid item xs={12} md={12}>
                                      <label>Solution</label>
                                      <Textarea
                                        id="answer"
                                        value={values.answer}
                                        onChange={(event) => setFieldValue("answer", event.target.value)}
                                        placeholder="Solution"
                                        endDecorator={
                                          <Typography level="body3" sx={{ ml: 'auto' }} className="characterLength">
                                            {this.handlecharacterLength(values)}
                                          </Typography>
                                        }

                                      />
                                      <span className="errorMsg">
                                        <ErrorMessage name="answer" />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div
                                  className="btnContainer"
                                  style={{
                                    marginTop: "35px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <span
                                        className="grayButton"
                                        id="backBtnAns"
                                        onClick={() => {
                                          //@ts-ignore
                                          this.props.history.goBack();
                                        }}
                                      >
                                        <CustomButton
                                          color="secondary"
                                          label="Cancel"
                                        />
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <span className="greenContained">
                                        <CustomButton
                                          color="secondary"
                                          type="submit"
                                          label={`${this.handlebuttonLabel()}`}
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              { }
                            </div>
                          </Box>
                        )}


                        { }
                        {this.state.task_type == "action" && (
                          <Box className="learningModalBlocks">
                            <div className="subModalBlock">
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <label className="texteditor-block">
                                    Task Title
                                  </label>
                                  <Editor placeholder={"Q1. Type your question here..."} values={values}
                                    setFieldValue={setFieldValue} />
                                  <span
                                    className="errorMsg"
                                    style={{ margin: "20px" }}
                                  >
                                    <ErrorMessage name="title" />
                                  </span>
                                </div>
                              </div>
                              <div className="modalContent learningAddPartModalContent mcqaModalContantainer ">
                                <div
                                  className="btnContainer"
                                  style={{
                                    marginTop: "35px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <span
                                      id="actionBack"
                                        className="grayButton"
                                        onClick={() => {
                                          //@ts-ignore
                                          this.props.history.goBack();
                                        }}
                                      >
                                        <CustomButton
                                          color="secondary"
                                          label="Cancel"
                                        />
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <span className="greenContained">
                                        <CustomButton
                                          color="secondary"
                                          type="submit"
                                          label={`${this.handlebuttonLabel()}`}
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className="productStatusContainer commonDropdown">
                        <div className="productStatus">
                          <h4>Task status</h4>
                          <div className="outlineSelect">
                            <div
                              className={`customSelect itemStatus ${this.handleDraftCss(values.task_status)} ${this.handleDeactiveCss(values.task_status)}`}
                            >
                              <FormControl>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  id="demo-controlled-open-select"
                                  value={values.task_status}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "task_status",
                                      e.target.value
                                    );
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {courseStatus.map(
                                    (status: any, index: any) => {
                                      return (
                                        <MenuItem value={status} key={index}>
                                          {this.handleStatus(status)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                            {this.state.mode == "edit" && (
                              <div
                                className="btns"
                                onClick={() => this.handleDialog()}
                              >
                                <Button>Delete</Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {/* remove modal */}
                  <div>
                    <Dialog
                    data-testid="dialog"
                      open={this.state.openDialog}
                      onClose={() => this.handleDialog()}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Are you sure you want to delete this ?
                      </DialogTitle>
                      <DialogActions>
                        <span className="redContained customCancelBtn">
                          <CustomButton
                          data-testid="cancelBtn"
                            color="secondary"
                            label="No"
                            onClick={() => this.handleDialog()}
                          />
                        </span>
                        <span className="greenContained">
                          <CustomButton
                          data-testid="greenBtn"
                            color="secondary"
                            label="Yes"
                            onClick={() => {
                              this.handleDialog();
                              this.deletingtask();
                            }}
                            autoFocus
                          />
                        </span>
                        {/* <Button
                  onClick={() => {
                    this.handleDialog();
                    this.deleteProduct();
                  }}
                  autoFocus
                >
                  Yes
                </Button> */}
                      </DialogActions>
                    </Dialog>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
}

export {AdminLearningCoursesTasks}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningCoursesTasks)
);

// Customizable Area End
