// Customizable Area Start
import React from 'react'
import { googleImg, googleLoginAction } from '../assets';
import { Button } from "@material-ui/core";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import '../assets/css/social_btn.scss'
const configJSON = require("../config.js")

interface IFailureProps {
    error: string
    details: string
}

const GoogleButton = (props: any) => {
    const dispatch = useDispatch()
    const { isGoogleLoading } = useSelector((state: any) => state.user)

    const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        const { history, close } = props
        dispatch(googleLoginAction(res, history, close))
    }

    const onFailure = (res: IFailureProps) => {
        const { error } = res
        if(error === "idpiframe_initialization_failed") {
            toast.error('Cookies are disabled. Please allow cookies from the above eye icon to login using Google.')
        }
        else toast.error("Google login failed please try again")
    }

    return (
        <>
            <GoogleLogin
                clientId={configJSON.GOOGLE_LOGIN_CLIENT_ID}
                cookiePolicy='single_host_origin'
                render={(renderProps) => (
                    <Button
                        variant="contained"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="social_btn"
                        classes={{ label: "btn_color_black" }}
                        startIcon={isGoogleLoading ? null : <img src={googleImg} alt="icon" className="social_Img" />}
                    >
                        {isGoogleLoading ? (
                            <CircularProgress size={22} color="inherit" />
                        ) : (
                            "Google"
                        )}
                    </Button>
                )}
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
            />
        </>
    );
}

export default withRouter(GoogleButton);
// Customizable Area End
