// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import NewDiscussionDialogController, {
    Props,
    ITopic,
} from "./NewDiscussionDialogController.web"
import { connect } from "react-redux"
import {
    DefaultProfile,
    setDiscussionTopics,
    setCommunityDiscussions,
    setDiscussionActiveTopic,
    setDiscussionFilter
} from "../../assets"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    MenuItem,
    Select,
    TextareaAutosize,
    TextField,
    Typography,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core"
import "../../assets/css/community/communityDiscussionDialog.scss"
import CustomDialogTitle from "../../../../../components/src/CustomDialogTitle.web"

class NewDiscussionDialog extends NewDiscussionDialogController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { isOpen, firstName, lastName, profileImage, topics } = this.props
        const {
            loader,
            topicsLoader,
            discussionTopicId,
            discussionHeading,
            discussionDesc,
        } = this.state

        const isSubmitBtnDisabled =
            loader || !discussionTopicId || !discussionHeading || !discussionDesc

        return (
            <Dialog
                maxWidth="sm"
                open={isOpen}
                onClose={() => this.handleCloseDialog()}
                classes={{
                    paper: "custom_dialog_paper",
                }}
            >
                <CustomDialogTitle onClose={this.handleCloseDialog}>
                    <Box className="ndd_user_details">
                        <Box>
                            <img src={profileImage || DefaultProfile} alt="profile image" />
                        </Box>
                        <Typography>
                            {firstName} {lastName}
                        </Typography>
                    </Box>
                </CustomDialogTitle>

                <DialogContent>
                    <Box className="ndd_content_wrapper">
                        <Box className="ndd_heading_container">
                            <Select
                                value={discussionTopicId}
                                onChange={this.handleDiscussionTopicChange}
                                className="ndd_topic_select"
                                disableUnderline
                                displayEmpty
                                placeholder="Choose Topic"
                                IconComponent={() => null}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value="" disabled hidden>
                                    Choose Topic
                                </MenuItem>
                                {topicsLoader ? (
                                    <MenuItem disabled className="ndd_topic_menu_loader">
                                        <Box>
                                            <CircularProgress size={18} />
                                        </Box>
                                    </MenuItem>
                                ) : (
                                    topics?.map((topic: ITopic) => {
                                        const {
                                            id,
                                            attributes: { name },
                                        } = topic
                                        return (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        )
                                    })
                                )}
                            </Select>
                            <TextField
                                value={discussionHeading}
                                disabled={loader}
                                onChange={this.handleHeadingChange}
                                className="ndd_heading"
                                placeholder="Discussion Topic..."
                                InputProps={{ disableUnderline: true }}
                            />
                        </Box>
                        <Box>
                            <TextareaAutosize
                                value={discussionDesc}
                                disabled={loader}
                                onChange={this.handleDescriptionChange}
                                minRows={12}
                                className="ndd_description"
                                placeholder="Write Post..."
                            />
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions className="ndd_submit_btn_container">
                    <Box>
                        <Button
                            classes={{
                                disabled: "ndd_submit_disabled",
                            }}
                            disabled={isSubmitBtnDisabled}
                            onClick={this.handlePostNewDiscussion}
                        >
                            {loader ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                "Post Discussion"
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        firstName: state.user.userDetails?.first_name || "",
        lastName: state.user.userDetails?.last_name || "",
        profileImage: state.user.userDetails?.profile_pic || "",
        topics: state.community.discussionTopics,
        activeTopicId: state.community.activeTopicId,
        discussionFilter: state.community.discussionFilter,
        discussions: state.community.discussions,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        setDiscussionTopics,
        setCommunityDiscussions,
        setDiscussionActiveTopic,
        setDiscussionFilter
    })(NewDiscussionDialog)
)
// Customizable Area End
