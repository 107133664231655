export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const FarmerMerchant = require("../assets/images/farmer-merchant.png");
export const Restaurateur = require("../assets/images/restauranteur.png");
export const EducationalContributor = require("../assets/images/educational-contributor.png");
export const DoneImg = require("../assets/images/done.png");
export const RoleProfileLocationMap = require("../assets/images/roleprofilemap.png");
export const ProfileImg = require("../assets/images/no-profile.svg");
export const {
  sentOTP1Action,
  sentOTP2Action,
  changeOTP1Verify,
  changeOTP2Verify,
  verifyOTP1Action,
  verifyOTP2Action,
  setSameContactDetails,
  resatOTPFlags,
  verifyUserAction,
} = require("../../../components/src/redux/Users/userActions");

export const {
  setSelectRoleData
} = require("../../../components/src/redux/CMS/cmsActions")
