// Customizable Area Start
import * as React from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { LessonTab } from "./LessonTab.web";
import { OverviewTab } from "./OverviewTab.web";
import { ResourcesTab } from "./ResourcesTab.web";
import Image from "material-ui-image";
import { UserIcon } from "./assets";
import LazyLoad from "react-lazy-load";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

export class EnrolledPageTabs extends React.Component<any,any>{
    constructor(props:any){
        super(props);
        this.state={
          value : 0,
          knowMore : false
        }
    }
    handleKnowMore = () => {
      this.setState({knowMore : !this.state.knowMore})
    }
    handleChange = ( e:any,newValue: number) => {
      this.setState({value : newValue});
    };
    render(){
        const hh = this.props.hours?.split(" ")
        const mm = this.props.mins?.split(" ")
        const ss = this.props.sec
        const isBioLength = this.props.content?.attributes?.biography?.length <= 500
    return (
      <Box className="enrolledTabContainer">
        <Box className="tabBox">
          <Tabs TabIndicatorProps={{ style: { background: '#169d4e'} }}
            value={this.state.value} onChange={this.handleChange}
            aria-label="basic tabs example"
            className="enrolled-tabs"
          >
            <Tab label="Lesson" className="tab-name" {...a11yProps(0)} />
            <Tab label="Overview" className="tab-name" {...a11yProps(1)} />
            <Tab label="Resources" className="tab-name" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={this.state.value} index={0}>
          <LessonTab 
           courseData={this.props.courseData}
           lesson={this.props.lesson} 
           navigation={undefined} id={""} 
           content={this.props.content} 
           lessonLength={undefined} hours={undefined} 
           mins={undefined} enrolled={undefined} 
           handleVideoStatus={undefined}
           handleStartLessonBtn={this.props.handleStartLessonBtn}
           lessonDetail={undefined} Video={undefined} />
        </TabPanel>
        <TabPanel value={this.state.value} index={1} >
          <Grid container style={{ boxShadow: '0px 5px 10px #ccc', backgroundColor: '#f6faf6' }}>
            <Grid item xs={12} className="overviewBoxWrapper">
              <Typography className="overview-box" >{this.props.lessonLength > 1 ? `${this.props.lessonLength - 1}+ Lessons` : '1 Lesson'}</Typography>
              <Typography className="overview-box" >{this.props.enrolled }</Typography>
              <Typography className="overview-box" >{hh > '0' ? `${Math.round(hh[0])} hrs` : ''}  {mm > '0' ? `${Math.round(mm[0])} min` : ''} {ss ? `${ss} sec` : null}</Typography>
            </Grid>
          </Grid>
          <Box className="overviewLesson" >
            <Grid container style={{ marginTop: '2.5rem' }}>
              <Grid xs={12}>
                <OverviewTab section={this.props.courseData} navigation={undefined} id={""} content={undefined} lesson={undefined} lessonLength={undefined} hours={undefined} mins={undefined} enrolled={undefined} handleVideoStatus={undefined} lessonDetail={undefined} Video={undefined}/>
              </Grid>
            </Grid>
  
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={4} >
                <div className="container-educator-img">
                  <LazyLoad offset={100}>
                  <Image
                    src={this.props.content.attributes?.educator_image?.url || UserIcon}
                    alt="Educator Image"
                    className="image"
                  />
                  </LazyLoad>
                </div>
              </Grid>
  
              <Grid item xs={8} sm={8} md={6} lg={6}  className="margin3" style={{ marginBottom:'200px' }}>
  
                <div >
                  <Box className="rightEducatorContainer" >
                    <h3 className="h3">
                      About {this.props.content.attributes && this.props.content.attributes.first_name} 
                      {this.props.content.attributes && this.props.content.attributes.last_name}
                    </h3>
                    <div className="educatorDescription"
                    dangerouslySetInnerHTML={{ __html: this.state.knowMore ? `${this.props.content?.attributes?.biography}` : `${this.props.content?.attributes?.biography?.slice(0, 500)}` }}
                      />
                    {!isBioLength &&
                    <div style={{ paddingTop: '20px'}}>
                      <h3 className="knowlink" 
                      onClick={() => this.handleKnowMore()} >
                        {this.state.knowMore ? 'Know Less' : 'Know More'}
                      </h3>
                    </div>
                    }
                  </Box>
                </div>
              </Grid>
            </Grid>
  
          </Box>
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <ResourcesTab section={this.props.courseData} handleVideoStatus={this.props.handleVideoStatus} navigation={undefined} id={""} content={this.props.content} lesson={undefined} lessonLength={undefined} hours={undefined} mins={undefined} enrolled={undefined} lessonDetail={undefined} Video={undefined}/>
        </TabPanel>
      </Box>
    );
    }
  }
// Customizable Area End 
