// Customizable Area Start
import React from "react"
import { Switch, withRouter } from "react-router-dom"
import { ScrollView } from "react-native"
import CommunityDashboardController, {
    Props,
} from "./CommunityDashboardController.web"
import CommunityHeader from "../CommunityHeader/CommunityHeader.web"
import CommunityDiscussion from "../CommunityDiscussion/CommunityDiscussion.web"
import CommunityDiscussionDetails from "../CommunityDiscussion/CommunityDiscussionDetails.web"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import ProtectedRoute from "../../../../../components/src/ProtectedRoute/WebProtectedRoute.web"

class CommunityDashboard extends CommunityDashboardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <ScrollView>
                <CommunityHeader />
                <Switch>
                    <ProtectedRoute
                        path={AppRoutings.CommunityDiscussion}
                        render={(routeProps: any) => (<CommunityDiscussion {...routeProps} />)}
                        exact
                    />
                    <ProtectedRoute
                        path={AppRoutings.CommunityDiscussionDetailsId}
                        render={(routeProps: any) => (<CommunityDiscussionDetails {...routeProps} />)}
                        exact
                    />
                </Switch>
            </ScrollView>
        )
    }
}

export default withRouter(CommunityDashboard)
// Customizable Area End
