import React, { forwardRef } from "react";


import { makeStyles, withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";

import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import LoopIcon from '@material-ui/icons/Loop';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SkipPreviousOutlinedIcon from '@material-ui/icons/SkipPreviousOutlined';
import SkipNextOutlinedIcon from '@material-ui/icons/SkipNextOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';

import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

// import PauseIcon from '@material-ui/icons/Pause';
import "../assets/css/educationPlayerControls.scss"
const useStyles = makeStyles({
    controlsWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.6)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
    },
    controlIcons: {
        color: "#777",
        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },
    volumeSlider: {
        width: 100,
    },
});

function ValueLabelComponent(props: any) {
    const { children, open } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={props.value}>
            {children}
        </Tooltip>
    );
}

const PrettoSlider = withStyles({
    root: {
        height: 8,

    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: "green",
        border: "2px solid currentColor",
        marginTop: -6,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {

    },
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 3,
        borderRadius: 4,
        backgroundColor: "green"

    },
    rail: {
        height: 3,
        borderRadius: 4,
    },
})(Slider);

export const PlayerControls = forwardRef((props: any, ref: any) => {
    const classes = useStyles();
    const { elapsedTime } = props
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handlePopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "playbackrate-popover" : undefined;
    return (
        <div className={classes.controlsWrapper} ref={ref}>

            <div className="controlContainer">
                <div className="playPauseContainer">


                    <SkipPreviousOutlinedIcon style={{ color: "green" }} />


                    {props.playing ? (
                        <PauseIcon fontSize="large" style={{ color: "white" }} onClick={props.onPlayPause} />
                    ) : (
                        <PlayArrowOutlinedIcon fontSize="large" style={{ color: "white" }} onClick={props.onPlayPause} />
                    )}

                    {/* <PlayArrowOutlinedIcon style={{ color: "white" }} onClick={props.onPlayPause} /> */}



                    <SkipNextOutlinedIcon style={{ color: "green" }} />

                </div>

                <div className="sliderContainer">

                    <span>{props.elapsedTime}</span>
                    <PrettoSlider
                        min={0}
                        max={100}

                        // ValueLabelComponent={(props) => (
                        //     <ValueLabelComponent {...props} value={elapsedTime} />
                        // )}
                        value={props.played * 100}
                        // orientation="vertical"
                        onChange={props.onSeek}
                        onMouseDown={props.onSeekMouseDown}
                        onChangeCommitted={props.onSeekMouseUp}
                    // onDuration={props.onDuration}
                    />
                    <span>{props.totalDuration}</span>

                </div>

                <div className="loopIconContainer">

                    <MusicNoteIcon style={{ color: "green" }} />


                    <LoopIcon style={{ color: "green" }} />


                    <div className="volumeContainer">


                        <IconButton
                            // onClick={() => setState({ ...state, muted: !state.muted })}
                            onClick={props.handleMute}
                            style={{ color: "green" }}
                        >
                            {props.muted || props.volume == 0 ? (
                                <VolumeOffIcon fontSize="large" />
                            ) : props.volume > 0.5 ? (
                                <VolumeUp fontSize="large" />
                            ) : (
                                <VolumeDown fontSize="large" />
                            )}
                        </IconButton>

                        <div className="sliderContainer">

                            <Slider
                                orientation="vertical"
                                min={0}
                                max={100}
                                value={props.muted ? 0 : props.volume * 100}
                                onChange={props.onVolumeChange}
                                aria-labelledby="input-slider"

                                onMouseDown={props.onSeekMouseDown}
                                onChangeCommitted={props.onVolumeSeekDown}
                            />
                        </div>

                    </div>


                </div>

            </div>

        </div>
    );
});


export default PlayerControls


