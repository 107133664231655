import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");
import { RouteComponentProps } from "react-router-dom"
import { scrollToTop } from "../../../components/src/utility/helper";
import { IImage } from "../../../components/src/interfaces/common";

export interface IMenu {
  label: string
  value: string
}

export interface IProduct {
  id: string
  type: string
  attributes: {
    name: string
    image: IImage
    description: string
    average_rating: number
    price: string
    compare_at_price: string
    variant_options: {
        id: string
        type: string
        attributes: {
          is_active: boolean
          option_name: string
          price: string
          weight: string
        }
    }[]
  }
}
// Customizable Area End

export interface Props extends RouteComponentProps{
  navigation: any;
  id: string;
  // Customizable Area Start
  match:any;
  getFarmProductListAction:any
  activeTab: string
  farmDetails: any
  farm: any
  products: any
  farmCampaign: any
  updateActiveTab?: (activeTab: string) => void
  setProductsFilterPrice: (id: string, value: string) => void
  resetProductCatalogueFilters?: () => void
  setFarmsProductsCurrentPage: (id: string, value: number) => void
  setProductsFilterSearchText: (value: string) => void
  setProductsFilterBySearch: (id: string, value: string) => void
  addRatingFarmProductsAction: (ratingDetails: any, clearFunc: () => void) => void
  toggleFarmForumLike: (forumId: string, isLiked: boolean, likeCount: number) => void
  handleFarmForumCommentCount: (forumId: string, count: number) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string;
  rating: number;
  review: string;
  search: string;
  loginDialog: boolean;
  isMobileScreen: boolean
  isFiltersOpen: boolean
  sortAnchorEl: HTMLButtonElement | null
}

interface SS {
  id: any;
}

export default class FarmCatelogWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      id: "",
      rating: 0,
      review: "",
      search: "",
      loginDialog: false,
      isMobileScreen: window.innerWidth <= 768,
      isFiltersOpen: false,
      sortAnchorEl: null
    };
    this.handleRating = this.handleRating.bind(this)
    this.handleWriteText = this.handleWriteText.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleWriteSearch = this.handleWriteSearch.bind(this)
    this.handleSearchEnter = this.handleSearchEnter.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const { activeTab, updateActiveTab } = this.props
    window.addEventListener('resize', this.getWindowSize)
    activeTab !== ActiveTabEnum.Market && !!updateActiveTab && updateActiveTab(ActiveTabEnum.Market)
    this.getFarmProductDetails()
  }

  componentWillUnmount(): any {
    window.removeEventListener('resize', this.getWindowSize)
    !!this.props.resetProductCatalogueFilters && this.props.resetProductCatalogueFilters()
  }

  getWindowSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 768 })
  }

  handleFiltersClick = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen })
    if(!this.state.isFiltersOpen) scrollToTop("top_container", "end")
  }

  handleOpenSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ sortAnchorEl: event.currentTarget })
  }

  handleCloseSortMenu = () => {
    this.setState({ sortAnchorEl: null })
  }

  handleSortChange = (value: string) => {
    const { id } = this.state
    scrollToTop("top_container", "end")
    this.props.setProductsFilterPrice(id, value)
    this.handleCloseSortMenu()
  }

  getFarmProductDetails = () => {
    const { match, getFarmProductListAction } = this.props
    const { id } = match.params
    this.setState({ id })
    getFarmProductListAction(id)
  }

  handleRating (event: React.SyntheticEvent<Element, Event>, value: number | null) {
    this.setState({
      rating: value || 0
    })
  }

  handleWriteText (event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      review: event.target.value
    })
  }

  handleWriteSearch (event: React.ChangeEvent<HTMLInputElement>) {
    this.props.setProductsFilterSearchText(event.target.value)

    if(event.target.value.length === 0) {
      this.props.setProductsFilterBySearch(this.state.id, "")
    }
  }

  handleSearchEnter(event: React.KeyboardEvent<HTMLInputElement>){
    if(event.key === 'Enter'){
      this.props.setProductsFilterBySearch(this.state.id,this.state.search)
    }
  }

  handleClear () {
    this.setState({
      rating: 0,
      review: ''
    })
  }

  capitalizeValue = (value: string) => {
    if (!value) return '';
    return `${value[0].toUpperCase()}${(value.slice(1))}`
  }

  donationPercentage = () => {
    const { farmCampaign } = this.props

    const raised = Number(farmCampaign?.donation_raised?.replace("$", "")) || 0
    const required = Number(farmCampaign?.required_donation?.replace("$", "")) || 0

    if (raised === 0 || required === 0) return 0;
    return Math.round((raised * 100) / required);
  }

  handleReviewSubmit = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    const { id, rating, review } = this.state
    if(isLoggedIn) this.props.addRatingFarmProductsAction({ id, rating, comment: review }, this.handleClear)
    else this.handleOpenLoginDialog()
  }

  handleCloseLoginDialog = () => {
    this.setState({ loginDialog: false })
  }

  handleOpenLoginDialog = () => {
    this.setState({ loginDialog: true })
  }
  // Customizable Area End
}
