// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Box, Slider } from "@material-ui/core";
import "./assets/css/farmpagesidebar.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import {
  setRestaurantsSelectedCategory,
  setRestaurantsSelectedTag,
  setRestaurantFilterByPrice,
  setRestaurantNearByFilter,
  getRestaurantsListAction
} from "./assets";
import { useDispatch, useSelector } from "react-redux";
import { SLIDER_STEP } from "./utility/constants";

interface Props {
  categoryTitle: string;
  state: string | null;
};

function valuetext(value: any) {
  return `${value}°C`;
}

const RestaurantPageSideBar = (props: Props) => {

  const { state, categoryTitle } = props
  const {
    restaurantsTags,
    restaurantsCategories,
    selectedCategory,
    selectedTag,
    nearByFilter,
    minPrice,
    maxPrice
  } = useSelector((state: any) => state.restaurants)
  const dispatch = useDispatch()
  const [rangeValue, setRangeValue] = useState([minPrice, maxPrice])

  useEffect(() => {
    setRangeValue([minPrice, maxPrice])
  }, [minPrice, maxPrice])

  useEffect(() => {
    getLocation()
  }, [])
  
  const handleChange = (_: any, newValue: any) => {
    setRangeValue(newValue)
  }
  
  const handlePriceChange = (_: any, newValue: any) => {
    dispatch(setRestaurantFilterByPrice(newValue))
    document.getElementById("restaurantList")?.scrollIntoView({behavior: 'smooth'})
  }
  
  const handleCategoriesClick = (value: any) => {
    dispatch(setRestaurantsSelectedCategory(value))
    document.getElementById("restaurantList")?.scrollIntoView({behavior: 'smooth'})
  }
  
  const handleTagClick = (value: any) => {
    dispatch(setRestaurantsSelectedTag(value))
    document.getElementById("restaurantList")?.scrollIntoView({behavior: 'smooth'})
  }

  const handleGetRestaurants = () => {
    if (state) dispatch(setRestaurantsSelectedCategory(state))
    else dispatch(getRestaurantsListAction())
  }
  
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      dispatch(
        setRestaurantNearByFilter(
          position.coords.latitude,
          position.coords.longitude,
          state
        )
      )
    }, () => {
      handleGetRestaurants()
    })
  }  

  return (
    <Box className="farm_sidebar_container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <Box className="farm_sidebar_heading">
              <ArrowBackIosIcon className="farm_side_bar_icons" />
              <p className="farm_sidebar_box_heading">{categoryTitle}</p>
            </Box>
            <Box className="related_category_contents">
              <Box className="sidebar_text_and_content_wrapper">
                <Box>
                  {restaurantsCategories?.length > 0 &&
                    restaurantsCategories?.map((item: { id: number, name: string }) => {
                      const { name, id } = item
                      return (
                        <p
                          key={id}
                          className={selectedCategory === name ? "selected" : ""}
                          onClick={() => handleCategoriesClick(name)}
                        >
                          {`${name[0].toUpperCase()}${name.slice(1)}`}
                        </p>
                      )
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <p className="farm_sidebar_box_heading">Refine by</p>
            <Box
              className={nearByFilter ? "refine_by_content_active" : "refine_by_content"}
              onClick={(getLocation)}
              style={{ cursor: "pointer" }}
            >
              <p>Near by restaurants</p>
              <CloseIcon
                className="side_bar_close_icon_active"
                fontSize="small"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <p className="farm_sidebar_box_heading">
              Price
            </p>
            <Box className="farm_sidebar_range">
              <Slider
                min={minPrice}
                step={SLIDER_STEP}
                max={maxPrice}
                value={rangeValue}
                onChange={handleChange}
                onChangeCommitted={handlePriceChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
            </Box>
            <p className="price_container">
              Price :{" "}
              <span>
                ${rangeValue[0]}-${rangeValue[1]}
              </span>
            </p>
          </Box>
        </Grid>

        <Box className="product_tag_content_wrapper">
          <Box />
          <p className="product_tag_header">Product Tag</p>
          <Grid container spacing={2} className="product_tag_content">
            {
              restaurantsTags?.data?.length > 0 &&
              restaurantsTags?.data?.map((item: { attributes: { id: number, name: string } }) => {
                const { id, name } = item.attributes
                return (
                  <Grid item xs={6} md={12} lg={6} key={id}>
                    <p
                      className={selectedTag === name ? "selected" : ""}
                      onClick={() => handleTagClick(name)}
                    >
                      {`${name[0].toUpperCase()}${name.slice(1)}`}
                    </p>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default RestaurantPageSideBar;
// Customizable Area End