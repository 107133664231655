// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { WithStyles } from "@material-ui/core/styles";
import { styles } from "./OrderCancelRejectDialog.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
const configJSON = require("./config.js");

export type BUTTON_BEHAVIOR_TYPES = "submit" | "button";
export type BUTTON_TYPES = "success" | "close" | "error";

interface IRejectionReason {
  id: number;
  rejection_reason: string;
  created_at: string;
  updated_at: string;
}

export interface Props extends WithStyles<typeof styles> {
  isOpen: boolean;
  orderId: string;
  handleClose: () => void;
  onCancellationRejection: () => void;
}

interface S {
  isLoading: boolean;
  isReasonsLoading: boolean;
  currentStep: 0 | 1;
  reasonsList: IRejectionReason[];
  apiErrors: { message: string }[];
}

interface SS {
  id: any;
}

export default class OrderCancelApproveDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  getReasonsListApiCallId: string = "";
  rejectCancellationApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isLoading: false,
      isReasonsLoading: false,
      currentStep: 0,
      reasonsList: [],
      apiErrors: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleDialogClose = () => {
    const { handleClose, onCancellationRejection } = this.props;
    if (!this.state.isLoading) {
      handleClose();
      this.setState({
        currentStep: 1,
        apiErrors: [],
        isLoading: false,
        isReasonsLoading: false,
        reasonsList: []
      })
      onCancellationRejection();
    }
  };

  async componentDidMount(): Promise<void> {
    this.getReasonsList();
  }

  handleCancelSubmit = (values: { rejectionReason: string }) => {
    const { rejectionReason } = values;
    const { orderId } = this.props;

    const httpBody = {
      id: orderId,
      rejection_reason: rejectionReason
    };
    this.setState({ apiErrors: [] });
    this.rejectCancellationApi(httpBody);
  };

  getReasonsList = async () => {
    this.setState({ isReasonsLoading: true });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReasonsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rejectionReasonsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  rejectCancellationApi = async (httpBody: {
    id: string;
    rejection_reason: string;
  }) => {
    this.setState({ isLoading: true });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectCancellationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancellationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getReasonsListApiCallId) {
      this.setState({
        isReasonsLoading: false,
        reasonsList: responseJson.rejection_reason
      });
    }

    if (apiRequestCallId === this.rejectCancellationApiCallId) {
      this.setState({
        isLoading: false,
        currentStep: 1
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSuccessResponse(apiRequestCallId, responseJson);
      } else {
        if (apiRequestCallId === this.getReasonsListApiCallId) {
          this.setState({ isReasonsLoading: false, reasonsList: [] });
        }

        if (apiRequestCallId === this.rejectCancellationApiCallId) {
          this.setState({ apiErrors: responseJson.errors, isLoading: false });
        }
      }
    }
  }
  // Customizable Area End
}
