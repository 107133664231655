Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";
exports.httpPatchType = "PATCH";

exports.authToken = "authToken";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.coursesAPIEndpoint = "bx_block_library/courses/";
exports.courseContent = "/course_content";
exports.getLessonDetailAPIEndpoint = "bx_block_library/course_sub_sections/";
exports.postVideoAPIEndpoint = "bx_block_library/courses/create_user_course_sub_section?course_sub_section_id=";
exports.videoStatus = "&video_status=";
exports.progressAPIEndPoint = "bx_block_library/task_results/progress_bar?course_id=";
exports.getListingOfLessonEndpoint = "bx_block_library/course_sub_sections/listing_user_course_sub_section";
exports.lessonDetailURL = "/learning/courses/sub_section?id=";
exports.courseDetailUserFlowAPIEndpoint = "bx_block_library/courses/course_detail?id=";
exports.videoLengthAPIEndpoint = "bx_block_library/course_sub_sections/video_length_of_course?course_id=";
exports.reviewType = "BxBlockLibrary::Course";
exports.createReviewAPIEndpoint = 'catalogue/reviews';
exports.delReplyAPIEndpoint = "bx_block_catalogue/reply_reviews/";
exports.createReplyAPIEndpoint = `bx_block_catalogue/reply_reviews`;
exports.addToFavAPIEndpoint = 'bx_block_favourites/favourites/favourites';
exports.addToCartAPIEndpoint = `bx_block_order_management/cart_items/add_to_cart`;
exports.cartDetailAPIEndpoint = `bx_block_order_management/carts/cart_detail`;
exports.createFreeOrderAPIEndpoint = `bx_block_order_management/orders/create_free_order`;
exports.courseMarkAdCompleteApiEndPoint = "bx_block_library/course_sub_sections/mark_as_complete"
exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.popularCourses = "bx_block_library/courses/get_popular_courses"
exports.knowMoreText = "Know More";
exports.knowLessText = "Know Less";
exports.linkCopiedText = "Link Copied!";
exports.trainingText = "OF TRAINING";
exports.shareText = "Share";
exports.copyText = "Copy";
exports.aboutText = "About";
exports.shareCourseText = "Share this course";
exports.freeText = "Free";
exports.goToCourseLabel = "Go to Course";
exports.enrollNowLabel = "Enroll Now";
exports.reviewsText = "Reviews";
exports.writeCommentText = "Write Comment";
exports.writeReviewText = "Write review";
exports.yourReplyText = "Your Reply";
exports.editText = "Edit";
exports.saveText = "Save";
exports.lessonText = "Lesson";
exports.completedText = "Completed";
exports.firstNameText = "firstname";
exports.lastNameText = "lastname";
exports.cartIdText = 'cartId';
exports.somethingWentWrongText = "something went wrong";
exports.secondsText = " Seconds";
exports.OneLessonText = '1 Lesson';
exports.courseAddedToFavMsg = "Course has been successfully added to favourites";
exports.courseRemovedFromFavMsg = "Course has been removed from favourites";
exports.courseAddedMsg = "Item added to cart";
exports.addRatingMsg = "Please add a rating";
exports.writeCommentMsg = "Please write a comment";
exports.courseAlreadyAddedMsg = "Course has already been added to the Cart";
exports.enrolledCoursePath = '/learning/courses/enrolled?id=';
exports.linkNotCopiedMsg = "Link not copied!";
exports.studentsText = "Students";
exports.learnDailyText = "Learn Daily";
exports.searchPlaceholderText = "Search for courses";
exports.popularTeacherText = "Popular Teachers"; 
exports.linkCopiedMessage = "Link copied"
// Customizable Area End