// Customizable Area Start
import React from "react"
import {
  Grid,
  Typography,
  Box,
  Paper,
  InputBase,
  Container,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { Link, withRouter } from "react-router-dom"
import "./assets/css/webfooter.scss"
import LocalPhoneIcon from "@material-ui/icons/LocalPhoneOutlined"
import MailIcon from "@material-ui/icons/MailOutlineOutlined"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import { WhiteLogo, send, setGreenBannerDesc, setFooterDesc } from "./assets"
import WebFooterController from "./WebFooterController.web"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { AppRoutings } from "./utility/app-routing"
const configJSON = require("./config.js")

const PRIVACY_LINKS = [
  {
    label: configJSON.termsConditionsLabel,
    redirectUrl: `${AppRoutings.PolicyInfo}/1`,
  },
  {
    label: configJSON.privacyPolicyLabel,
    redirectUrl: `${AppRoutings.PolicyInfo}/0`,
  },
  {
    label: configJSON.policyForBuyerLabel,
    redirectUrl: `${AppRoutings.PolicyInfo}/2`,
  },
  {
    label: configJSON.policyForSellerLabel,
    redirectUrl: `${AppRoutings.PolicyInfo}/3`,
  },
]

const QUICK_LINKS = [
  {
    label: configJSON.contactUsLabel,
    redirectUrl: AppRoutings.ContactUs,
  },
  {
    label: configJSON.faqsLabel,
    redirectUrl: AppRoutings.WebFAQ,
  },
  {
    label: configJSON.helpSectionLabel,
    redirectUrl: AppRoutings.Home,
  },
]

class WebFooter extends WebFooterController {
  render() {
    const { FooterDescription } = this.props
    const { subscribeEmail, subscribeEmailError } = this.state

    return (
      <>
        {FooterDescription && (
          <Box className="footer_container">
            <Container>
              <Grid container>
                <Box className="footer_logo">
                  <img src={WhiteLogo} alt="we thrive together logo" />
                </Box>
                <Grid item container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    className="foot_list_wrapper"
                  >
                    <Box>
                      <h5 className="foot_list_title">Contact us</h5>
                      <Box className="contact_us">
                        <Box className="contact_icons">
                          <LocalPhoneIcon className="contact-icon" />
                        </Box>
                        <a
                          href={`tel:${FooterDescription.phone_number}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="foot_list_item contact-item"
                        >
                          {FooterDescription.phone_number}
                        </a>
                      </Box>
                      <Box className="contact_us">
                        <Box className="contact_icons">
                          <MailIcon className="contact-icon" />
                        </Box>
                        <a
                          href={`mailto:${FooterDescription.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="foot_list_item contact-item"
                        >
                          {FooterDescription.email}
                        </a>
                      </Box>
                      <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="local_store_link"
                      >
                        Location Store
                      </a>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    className="foot_list_wrapper"
                  >
                    <ul className="foot_list">
                      <h5 className="foot_list_title">Policy & Info</h5>
                      {PRIVACY_LINKS.map((link) => {
                        const { label, redirectUrl } = link

                        return (
                          <li key={label} className="list-unstyled">
                            <Link to={redirectUrl} className="foot_list_item">
                              {label}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    className="foot_list_wrapper"
                  >
                    <ul>
                      <h5 className="foot_list_title">Quick Links</h5>
                      {QUICK_LINKS.map((link) => {
                        const { label, redirectUrl } = link
                        return (
                          <li key={label} className="list-unstyled">
                            <Link to={redirectUrl} className="foot_list_item">
                              {label}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className="foot_list_wrapper"
                  >
                    <ul>
                      <h5 className="foot_list_title">{FooterDescription.hyperlink_heading}</h5>
                      {FooterDescription.hyperlinks.map((linkInfo) => {
                        const { id, heading } = linkInfo

                        return (
                          <li key={id} className="list-unstyled">
                            <Link
                              to={{
                                pathname: `${AppRoutings.HyperLinkContent}/${id}`,
                                state: heading,
                              }}
                              className="foot_list_item"
                            >
                              {heading}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className="foot_list_wrapper"
                  >
                    <Box>
                      <h5 className="foot_list_title">Subscribe Now</h5>
                      <p className="foot_list_item subscribe_text">
                        {FooterDescription.subscribe_now_description}
                      </p>
                      <Paper className="foot_input_field">
                        <InputBase
                          placeholder="Enter your email address"
                          value={subscribeEmail}
                          error={!!subscribeEmailError}
                          onChange={this.handleEmailValueChange}
                          onKeyDown={this.handleKeyDownEmailField}
                        />
                        <IconButton
                          aria-label="search"
                          className="send_icon"
                          disabled={!subscribeEmail}
                          onClick={this.handleSubscribe}
                        >
                          <img src={send} alt="send" />
                        </IconButton>
                      </Paper>
                      <p className="email_error">{subscribeEmailError}</p>
                      <div className="social_icons_list">
                        <button
                          onClick={() => {
                            window.open(
                              `${FooterDescription.facebook}`,
                              "_blank"
                            )
                          }}
                        >
                          <FacebookIcon className="social_icon" />
                        </button>
                        <button
                          onClick={() => {
                            window.open(
                              `${FooterDescription.instagram}`,
                              "_blank"
                            )
                          }}
                        >
                          <InstagramIcon className="social_icon" />
                        </button>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Box className="copywrite">
              <Typography>Copyright &copy; wethrivetogether</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    FooterBannerDescription: state.cms.FooterBannerDescription,
    FooterDescription: state.cms.FooterDescription,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setGreenBannerDesc: bindActionCreators(setGreenBannerDesc, dispatch),
    setFooterDesc: bindActionCreators(setFooterDesc, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WebFooter)
)
// Customizable Area End
