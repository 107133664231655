// Customizable Area Start
import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Box } from "@material-ui/core"
import FarmsFavouritePanel from "./FavPanels/FarmsFavouritePanel.web"
import RestaurantsFavouritePanel from "./FavPanels/RestaurantsFavouritePanel.web"
import CoursesFavouritePanel from "./FavPanels/CoursesFavouritePanel.web"

const CustomTabs = withStyles({
  root: {
    backgroundColor: "#ffffff",
  },
  flexContainer: {
    flexWrap: "wrap",
    gap: "25px",
    "@media(max-width: 612px)": {
      justifyContent: "center !important",
    },
  },
  indicator: {
    display: "none",
    backgroundColor: "#fff",
  },
})(Tabs)

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    width: "140px",
    borderRadius: "30px",
    backgroundColor: "#f3f0f0",
    minWidth: 72,
    "&>.MuiTab-wrapper": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#000 !important",
    },
    fontFamily: [
      "-apple-system",
      "Montserrat",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "#20a258",
      fontWeight: theme.typography.fontWeightMedium,
      "& > .MuiTab-wrapper": {
        color: "#fff !important",
      },
    },
    "&:focus": {
      color: "#fff",
    },
  },
  selected: {},
}))((props: any) => <Tab disableRipple {...props} />)

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "40px 0px 10px 0px",
    "@media(max-width: 400px)": {
      margin: "30px 15px",
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  tabsHeader: {
    backgroundColor: theme.palette.background.paper,
  },
}))

interface IProps {
  isLoading: boolean
  favouriteList: any[]
  activeTab: number
  handleTabChange: (tab: number) => void
}

const FavouriteTabs = ({
  activeTab,
  isLoading,
  favouriteList,
  handleTabChange,
}: IProps) => {
  const classes = useStyles()

  const handleChange = (_: any, newValue: number) => {
    handleTabChange(newValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabsHeader}>
        <CustomTabs value={activeTab} onChange={handleChange}>
          <CustomTab label="Farm" />
          <CustomTab label="Restaurant" />
          <CustomTab label="Courses" />
        </CustomTabs>
        <Typography className={classes.padding} />
      </div>
      <div>
        <TabPanel value={activeTab} index={0}>
          <FarmsFavouritePanel
            loader={isLoading}
            favouriteList={favouriteList}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <RestaurantsFavouritePanel
            loader={isLoading}
            favouriteList={favouriteList}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <CoursesFavouritePanel
            loader={isLoading}
            favouriteList={favouriteList}
          />
        </TabPanel>
        <Typography className={classes.padding} />
      </div>
    </div>
  )
}

export default FavouriteTabs
// Customizable Area End
