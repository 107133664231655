// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { Message } from "framework/src/Message"
import { WithStyles } from "@material-ui/core/styles"
import { RouteComponentProps } from "react-router-dom"
import { FormikProps } from "formik"
import React from "react"
import { toast } from "react-toastify"
import { styles } from "./AdminAdCampaignDetails.web"
import StorageProvider from "framework/src/StorageProvider.web"
import {
  AdvertisementTypeEnum,
  BusinessTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from "./AdCampaignEnums.web"
import { AppRoutings } from "../../../components/src/utility/app-routing"
import moment from "moment"
import dayjs, { Dayjs } from "dayjs"
const configJSON = require("./config.js")

const DefaultValues = {
  id: "",
  type: "",
  attributes: {
    name: "",
    description: "",
    campaign_type: CampaignTypeEnum.LocalCampaign,
    campaign_ad_business_type: BusinessTypeEnum.Farm,
    advertisement_type: AdvertisementTypeEnum.BusinessTile,
    advertisement_banner: null,
    estimated_cost: "0",
    total_cost: "0",
    url: "",
    campaign_ad_status: CampaignStatusEnum.Scheduled,
    start_day: null,
    end_day: null,
    start_time: null,
    end_time: null,
    catalogue_id: "",
    banner: null,
  },
}

type IImageResponse = {
  id: number
  url: string
  filename: string
}
export interface IStatus {
  value: string
  label: string
  className: "scheduledMenu" | "expiredMenu" | "pauseMenu" | "activeMenu"
}

interface IDuplicateAdParams {
  banner: IImageResponse
  advertisement_banner: IImageResponse
  advertisement_type: AdvertisementTypeEnum
  campaign_ad_business_type: BusinessTypeEnum
  campaign_ad_status: CampaignStatusEnum
  campaign_type: CampaignTypeEnum
  catalogue_id: number | null
  description: string
  name: string
  url: string
}

interface ICatelog {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    status: string
  }
}

interface ICatelogListingResponse {
  data: ICatelog[]
}

interface ICampaignRatesResponse {
  campaign_rates: {
    cost_per_click: number
    cost_per_mile: number
  }[]
}

interface IEstimatedCostResponse {
  estimated_cost: number
}

type IAdCampaign = {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    campaign_type: CampaignTypeEnum
    campaign_ad_business_type: BusinessTypeEnum
    advertisement_type: AdvertisementTypeEnum
    advertisement_banner: IImageType | null
    estimated_cost: string
    total_cost: string
    url: string
    campaign_ad_status: CampaignStatusEnum
    start_day: string
    end_day: string
    start_time: string
    end_time: string
    farm_id: number | null
    restaurant_id: number | null
    banner: IImageType | null
  }
}

type IDefaultValues = {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    campaign_type: CampaignTypeEnum
    campaign_ad_business_type: BusinessTypeEnum
    advertisement_type: AdvertisementTypeEnum
    advertisement_banner: IImageType | null
    estimated_cost: string
    total_cost: string
    url: string
    campaign_ad_status: CampaignStatusEnum
    start_day: Dayjs | null
    end_day: Dayjs | null
    start_time: Dayjs | null
    end_time: Dayjs | null
    catalogue_id: string
    banner: IImageType | null
  }
}

type IImageType = null | Blob | IImageResponse

export interface IInitialValues {
  id: string
  bannerImage: IImageType
  campaignName: string
  description: string
  businessType: BusinessTypeEnum
  catalogueId: string
  campaignType: CampaignTypeEnum
  advertisementTileType: AdvertisementTypeEnum
  advertisementTileAsset: IImageType
  fromDate: Dayjs | null
  toDate: Dayjs | null
  startTime: Dayjs | null
  endTime: Dayjs | null
  estimatedCost: string
  totalCost: string
  status: CampaignStatusEnum
}

interface IAdCampaignDetailsResponse {
  data: IAdCampaign
}

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface S {
  isLoading: boolean
  isGettingList: boolean
  isGettingRates: boolean
  isSaving: boolean
  currentMode: string
  cpmRate: number
  cpcRate: number
  catelogListing: ICatelog[]
  adCampaignDetails: IDefaultValues
}

interface SS {
  id: any
}

export default class AdminAdCampaignDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  formikRef: React.RefObject<FormikProps<IInitialValues>> = React.createRef()
  farmListingApiCallId: string = ""
  restaurantListingApiCallId: string = ""
  adCampaignRatesApiCallId: string = ""
  saveAdCampaignApiCallId: string = ""
  editAdCampaignApiCallId: string = ""
  getAdCampaignDetailsApiCallId: string = ""
  getEstimationCostApiCallId: string = ""
  ID_PARAMETER_KEYS = {
    farm: "farm_id",
    restaurant: "restaurant_id",
  }

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      isLoading: false,
      isGettingList: false,
      isGettingRates: false,
      isSaving: false,
      currentMode: configJSON.createText,
      cpcRate: 0,
      cpmRate: 0,
      catelogListing: [],
      adCampaignDetails: DefaultValues,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.handlePageLoad()
  }

  getUTCDateTime = () => {
    const currentDate = new Date();
    return new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate())
  }

  fromDateValidation = (fromDate: Date | null) => {
    if (fromDate) {
      const now = new Date();
      const utcDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      const userDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())
      return userDate >= utcDate
    }
    return true
  }
  
  startTimeValidation = (fromDate: Date | null, startTime: Date | null) => {
    const now = new Date();
    const utcDateTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    if (fromDate && startTime) {
      const userDateTime = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())
      if (utcDateTime.toDateString() === userDateTime.toDateString()) {
        utcDateTime.setHours(now.getUTCHours())
        utcDateTime.setMinutes(now.getUTCMinutes())
        userDateTime.setHours(startTime.getHours())
        userDateTime.setMinutes(startTime.getMinutes())
        return userDateTime > utcDateTime
      }
    }
    return true
  }

  customValuePasser = (key: string) => {
    return (value: unknown) => this.setValueBySetFieldValue(key, value as Dayjs)
  }

  setValueBySetFieldValue = (key: string, value: Dayjs | null) => {
    if (this.formikRef.current) {
      const { setFieldValue } = this.formikRef.current
      setFieldValue(key, value)
    }
  }

  dateTimeAcceptParser = (key: string) => {
    return (value: Dayjs | null) => {
      if (this.formikRef.current) {
        const { values } = this.formikRef.current
        this.handleEstimationCost(values, key, value)
      }
    }
  }

  handleDateTimeBlur = () => {
    if(this.formikRef.current) {
      this.getEstimationCost(this.formikRef.current.values)
    }
  }

  getCatalogueListingByValue = async (businessType: BusinessTypeEnum) => {
    if (businessType === BusinessTypeEnum.Farm) {
      await this.getFarmListingApi()
    } else {
      await this.getRestaurantListingApi()
    }
  }

  handleDuplicateMode = async () => {
    const { state } = this.props.location as { state: IDuplicateAdParams }

    if (state && this.formikRef.current) {
      const {
        banner,
        advertisement_type,
        campaign_ad_business_type,
        campaign_type,
        catalogue_id,
        description,
        name,
        advertisement_banner,
      } = state
      const { initialValues, setValues } = this.formikRef.current

      await this.getCatalogueListingByValue(campaign_ad_business_type)
      await this.handleGetAdRates(campaign_type)

      setValues({
        ...initialValues,
        bannerImage: banner,
        campaignName: name,
        description,
        businessType: campaign_ad_business_type,
        catalogueId: String(catalogue_id),
        campaignType: campaign_type,
        advertisementTileType: advertisement_type,
        advertisementTileAsset: advertisement_banner,
      })
    }
  }

  handleCreateMode = () => {
    this.getFarmListingApi()
    this.handleGetAdRates(CampaignTypeEnum.LocalCampaign)
  }

  handleEditMode = () => {
    const query = new URLSearchParams(window.location.search)
    const campaignId = query.get(configJSON.idText)

    if (campaignId) {
      this.getAdCampaignDetailsApi(campaignId)
    } else {
      this.showDefaultError()
      this.props.history.push(AppRoutings.AdminAdCampaigns)
    }
  }

  handlePageLoad = () => {
    const query = new URLSearchParams(window.location.search)
    const mode = query.get(configJSON.modeText)

    if (!mode) {
      this.showDefaultError()
      this.handleGoBack()
      return
    }

    this.setState({ currentMode: mode })

    switch (mode) {
      case configJSON.editText:
        this.handleEditMode()
        break
      case configJSON.duplicateText:
        this.handleDuplicateMode()
        break
      default:
        this.handleCreateMode()
        break
    }
  }

  getEstimationCost = (values: IInitialValues) => {
    const { campaignType, fromDate, toDate, startTime, endTime } = values

    if (campaignType && fromDate && toDate && startTime && endTime) {
      const convertedFromDate = this.convertDateToFormat(fromDate as Dayjs)
      const convertedToDate = this.convertDateToFormat(toDate as Dayjs)
      const convertedStartTime = this.convertTimeToFormat(startTime as Dayjs)
      const convertedEndTime = this.convertTimeToFormat(endTime as Dayjs)
      const query = `?campaign_type=${campaignType}&start_day=${convertedFromDate}&end_day=${convertedToDate}&start_time=${convertedStartTime}&end_time=${convertedEndTime}`
      this.getEstimationCostApi(query)
    }
  }

  handleEstimationCost = (values: IInitialValues, key: string, valueOfKey: string | null | Dayjs) => {
    const updatedValues = {
      ...values,
      [key]: valueOfKey
    }
    this.getEstimationCost(updatedValues)
  }

  handleGoBack = () => {
    this.props.history.goBack()
  }

  showDefaultError = () => {
    toast.error(configJSON.SomethingWentWrongMessage)
  }

  handleGetAdRates = async (type: string) => {
    const query = `?campaign_type=${type}`
    await this.getAdCampaignRatesApi(query)
  }

  handleCampaignTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.formikRef.current) {
      const { values, setFieldValue } = this.formikRef.current
      setFieldValue("campaignType", e.target.value)
      this.handleGetAdRates(e.target.value)
      this.handleEstimationCost(values, "campaignType", e.target.value as CampaignTypeEnum)
    }
  }

  handleBusinessTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.formikRef.current) {
      const { setFieldValue } = this.formikRef.current
      const value = e.target.value
      setFieldValue("businessType", value)
      setFieldValue("catalogueId", "")

      if (value === BusinessTypeEnum.Restaurant) {
        this.getRestaurantListingApi()
        setFieldValue("campaignType", CampaignTypeEnum.LocalCampaign)
        this.handleGetAdRates(CampaignTypeEnum.LocalCampaign)
      } else {
        this.getFarmListingApi()
      }
    }
  }

  handleAdvertisementTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.formikRef.current) {
      const { setFieldValue } = this.formikRef.current
      const value = e.target.value
      setFieldValue("advertisementTileType", value)

      if (value === AdvertisementTypeEnum.BusinessTile) {
        setFieldValue("advertisementTileAsset", null)
      }
    }
  }

  handleListingResponse = (list: ICatelog[]) => {
    if (this.formikRef.current) {
      const { values, setFieldValue } = this.formikRef.current
      this.setState({ catelogListing: list, isGettingList: false })
      const isPreviousRecordExist = list.some(
        (record) => record.id === values.catalogueId
      )

      if ((!isPreviousRecordExist || !values.catalogueId) && list.length > 0) {
        setFieldValue("catalogueId", list[0].id)
      }
    }
  }

  convertDate = (date: Dayjs, format: string) => {
    return moment(date.toString()).format(format)
  }

  convertTimeToFormat = (date: Dayjs) => {
    return this.convertDate(date, configJSON.TIME_PAYLOAD_FORMAT)
  }

  convertDateToFormat = (date: Dayjs) => {
    return this.convertDate(date, configJSON.DATE_FORMAT)
  }

  handleCreateAdCampaign = (values: IInitialValues) => {
    const {
      id,
      bannerImage,
      campaignName,
      description,
      businessType,
      catalogueId,
      campaignType,
      advertisementTileType,
      advertisementTileAsset,
      fromDate,
      toDate,
      startTime,
      endTime,
      status,
      estimatedCost,
    } = values

    const isDuplicateMode = this.state.currentMode === configJSON.duplicateText
    const formData = new FormData()
    bannerImage &&
      !("url" in bannerImage) &&
      formData.append("banner", bannerImage)
    bannerImage &&
      isDuplicateMode &&
      "url" in bannerImage &&
      formData.append("banner", bannerImage.url)
    formData.append("name", campaignName)
    formData.append("description", description)
    formData.append("campaign_ad_business_type", businessType)
    formData.append(this.ID_PARAMETER_KEYS[businessType], catalogueId)
    formData.append("is_super_admin_ad", "false")
    formData.append("campaign_type", campaignType)
    formData.append("advertisement_type", advertisementTileType)
    advertisementTileAsset &&
      !("url" in advertisementTileAsset) &&
      formData.append("advertisement_banner", advertisementTileAsset)
    formData.append("start_day", this.convertDateToFormat(fromDate as Dayjs))
    formData.append("end_day", this.convertDateToFormat(toDate as Dayjs))
    formData.append("start_time", this.convertTimeToFormat(startTime as Dayjs))
    formData.append("end_time", this.convertTimeToFormat(endTime as Dayjs))
    formData.append("estimated_cost", estimatedCost)
    formData.append("campaign_ad_status", status)

    this.createAdCampaignApi(formData, id)
  }

  createAdCampaignApi = async (formData: FormData, id: string) => {
    this.setState({ isSaving: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token,
    }

    let apiEndPoint = configJSON.adCampaignApiEndPoint
    let apiType = configJSON.apiMethodTypePost

    if (this.state.currentMode === configJSON.editText) {
      apiEndPoint = `${configJSON.adCampaignApiEndPoint}/${id}`
      apiType = configJSON.apiMethodTypePut
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.saveAdCampaignApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiType
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getAdCampaignDetailsApi = async (campaignId: string) => {
    this.setState({ isLoading: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getAdCampaignDetailsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.adCampaignApiEndPoint}/${campaignId}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getFarmListingApi = async () => {
    this.setState({ isGettingList: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.farmListingApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getRestaurantListingApi = async () => {
    this.setState({ isGettingList: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.restaurantListingApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getAdCampaignRatesApi = async (query: string) => {
    this.setState({ isGettingRates: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.adCampaignRatesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.adCampaignRatesApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getEstimationCostApi = async (query: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getEstimationCostApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.adCampaignEstimationCostApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  convertToTime = (timeString: string) => {
    const time = moment(timeString, configJSON.RESPONSE_TIME_FORMAT)
    return dayjs(time.toDate())
  }

  parseDate = (dateString: string) => {
    return dayjs(moment(dateString, configJSON.DATE_FORMAT).toDate())
  }

  handleAdDetailsApiResponse = (responseJson: IAdCampaignDetailsResponse) => {
    if ("data" in responseJson) {
      const {
        data,
        data: {
          attributes: {
            campaign_ad_business_type,
            campaign_type,
            end_day,
            end_time,
            start_day,
            start_time,
            farm_id,
            restaurant_id,
          },
        },
      } = responseJson

      const fromDate = this.parseDate(start_day)
      const toDate = this.parseDate(end_day)
      const startTime = this.convertToTime(start_time)
      const endTime = this.convertToTime(end_time)

      const convertedData = {
        ...data,
        attributes: {
          ...data.attributes,
          start_day: fromDate,
          end_day: toDate,
          start_time: startTime,
          end_time: endTime,
          catalogue_id: String(farm_id ?? restaurant_id),
        },
      }

      this.getCatalogueListingByValue(campaign_ad_business_type)
      this.handleGetAdRates(campaign_type)
      this.setState({ adCampaignDetails: convertedData })
    } else {
      this.showDefaultError()
    }
    this.setState({ isLoading: false })
  }

  handleSaveAdCampaignApiResponse = (
    responseJson: IAdCampaignDetailsResponse
  ) => {
    if ("data" in responseJson) {
      toast.success(
        this.state.currentMode === configJSON.editText
          ? configJSON.campaignUpdatedMessage
          : configJSON.campaignCreatedMessage
      )
      this.props.history.push(AppRoutings.AdminAdCampaigns)
    } else {
      this.showDefaultError()
      this.setState({ isSaving: false })
    }
  }

  handleSuccessResponse = (
    apiRequestCallId: string,
    responseJson:
      | IAdCampaignDetailsResponse
      | ICatelogListingResponse
      | ICampaignRatesResponse
      | IEstimatedCostResponse
  ) => {
    const isListingApiResponse = [
      this.farmListingApiCallId,
      this.restaurantListingApiCallId,
    ].includes(apiRequestCallId)
  
    if (apiRequestCallId === this.saveAdCampaignApiCallId) {
      this.handleSaveAdCampaignApiResponse(
        responseJson as IAdCampaignDetailsResponse
      )
    }
  
    if (isListingApiResponse) {
      if ("data" in responseJson && Array.isArray(responseJson.data)) {
        this.handleListingResponse(responseJson.data)
        return
      }
    }
  
    if (apiRequestCallId === this.adCampaignRatesApiCallId) {
      if ("campaign_rates" in responseJson) {
        let cpcRate = 0
        let cpmRate = 0
  
        if (responseJson.campaign_rates.length > 0) {
          const { cost_per_click, cost_per_mile } = responseJson.campaign_rates[0]
  
          cpcRate = cost_per_click
          cpmRate = cost_per_mile
        }
  
        this.setState({ cpcRate, cpmRate, isGettingRates: false })
      }
    }
  
    if (apiRequestCallId === this.getAdCampaignDetailsApiCallId) {
      this.handleAdDetailsApiResponse(responseJson as IAdCampaignDetailsResponse)
    }
  
    if (apiRequestCallId === this.getEstimationCostApiCallId) {
      if (this.formikRef.current) {
        this.formikRef.current.setFieldValue(
          "estimatedCost",
          (responseJson as IEstimatedCostResponse).estimated_cost
        )
      }
    }
  }  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.handleSuccessResponse(apiRequestCallId, responseJson)
      } else {
        const isListingApiResponse = [
          this.farmListingApiCallId,
          this.restaurantListingApiCallId,
        ].includes(apiRequestCallId)

        if (isListingApiResponse && this.formikRef.current) {
          toast.error(
            `Unable to fetch the list of ${this.formikRef.current.values.businessType}s`
          )
          this.setState({ isGettingList: false })
        }

        if (apiRequestCallId === this.saveAdCampaignApiCallId) {
          responseJson.errors.forEach((error: { message: string }) => {
            toast.error(error.message)
          })
          this.setState({ isSaving: false })
        }

        if (apiRequestCallId === this.getAdCampaignDetailsApiCallId) {
          this.showDefaultError()
          this.handleGoBack()
        }
      }
    }
  }
  // Customizable Area End
}
