// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import "react-toastify/dist/ReactToastify.css";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  routeUrl: any;
  history?: any;
  classes?: any;
}

interface S {

  token: string;
  courseDetails: any;
  productMode:any;
  course_id:any;
  loading:any;
  categories:any;
  restOrderModal:any;
  formRef:any
  

}

interface SS {
  id: any;
}

export default class AdminCreateCourseListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoryApiCallId: string = "";
  getCourseDetailsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      courseDetails: "",
      productMode:"",
      course_id:"",
      loading:false,
      categories:"",
      restOrderModal: false,
      formRef: React.createRef(),

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    function getQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = getQuery();
    let mode = query.get("mode")
    let course_id = query.get("course_id")
    this.setState({
      productMode: mode,
      course_id: course_id,
    })

    if (mode == "edit") {
      this.getCourseDetails(course_id)
    }
    this.getCategory()

  }
  


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.successApiCall(responseJson, apiRequestCallId)


      }
    }

  }


  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId != null) {
      //get farms


      //get category       
      if (apiRequestCallId === this.getCategoryApiCallId) {
        this.setState({
          categories: responseJson,
          loading: false,
        });

      }
      //get course details 
      if (apiRequestCallId === this.getCourseDetailsApiCallId) {
        this.setState({
          courseDetails: responseJson.data,
          loading: false,
        });

      }

      
      }

    }
  

  // get category
  getCategory = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCoursesCategoryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get  Course Details
  getCourseDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseDetailsAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getMode = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode")
    return mode
  }

  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return query

  }

  getTab = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let tab = query.get("tab")
    return tab
  }

  handleOpacity=()=>{
    let tab = this.getTab()
    //@ts-ignore
    return tab==1 ? .6 : 1
  }
  handleRestOrderOpenModal = () => {
    this.setState({ restOrderModal: true });
  };

}
// Customizable Area End
