// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom"
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
const configJSON = require("../config.js")

interface IRLPData {
  restaurant_tag_line: string
  restaurant_heading: string
  restaurant_description: string
  restaurant_submit_heading: string
  restaurant_submt_description: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeTab: string
  RLPTagLine: string
  updateActiveTab: (tab: string) => void
  getRestaurantsPopularCategories: () => void
  getRestaurantsListAction: () => void
  setRLPData: (data: IRLPData) => void
  resetRestaurantFilters: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RestaurantLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  rlpApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ]

    this.state = {
      loader: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const { activeTab, updateActiveTab, getRestaurantsListAction, getRestaurantsPopularCategories } = this.props
    activeTab !== ActiveTabEnum.Restaurant && updateActiveTab(ActiveTabEnum.Restaurant)
    this.rlpApi()
    getRestaurantsPopularCategories()
    getRestaurantsListAction()
  }

  async componentWillUnmount(): Promise<void> {
    this.props.resetRestaurantFilters()
  }

  rlpApi = async () => {
    const { RLPTagLine } = this.props
    !RLPTagLine && this.setState({ loader: true })

    const header = {
      "Content-Type": configJSON.productApiContentType
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.rlpApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rlpApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.rlpApiCallId) {
          const { setRLPData } = this.props
          setRLPData(responseJson)
          this.setState({ loader: false })
        }
      } else {
        if (apiRequestCallId === this.rlpApiCallId) {
          this.setState({ loader: false })
          toast.error("Something went wrong")
        }
      }
    }
  }
  // Customizable Area End

}
