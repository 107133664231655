// Customizable Area Start
import React from "react";
import { DustbinIcon, GreenPlus, getDishAllCategories } from "./assets";
import "../../../web/src/assets/css/style.scss";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  TextareaAutosize,
  Box,
  IconButton, 
  InputAdornment,
  withStyles,
  Hidden
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../assets/css/admin_restar_credit.scss";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import CancelIcon from '@material-ui/icons/CloseRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AdminRestaurantCreateDishesController, {
  Props, configJSON
} from "./AdminRestaurantCreateDishesController.web";
import CustomFileUpload from "../../../components/src/CustomFileUpload.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPriceTextField from "../../../components/src/CustomPriceTextField.web";
import TagsSuggestion from "../../../components/src/TagsSuggestion.web";

const styles = {
  priceText : {
    fontWeight: 600,
    color: '$GREEN',
    fontSize: '20px',
    paddingRight: '10px',
    fontFamily : 'Montserrat,sans-serif'
  },
  addOnContainer : {
    marginTop: "10px"
  },
  dishCategory : {
    borderRadius: "14px",
    backgroundColor: "#fff"
  }
}

// Customizable Area End

class AdminRestaurantCreateDishes extends AdminRestaurantCreateDishesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    const containerClassName = `
    ${this.outlineClass(this.state.dishStatus, configJSON.draftStatus, 'orange-outline')}
    ${this.outlineClass(this.state.dishStatus, configJSON.activeText, 'green-outline')}
    ${this.outlineClass(this.state.dishStatus, configJSON.deactiveText, 'deactiveStatus')}
  `;
    return (
      <div>
        {/* main card container */}
        <div className="create-dish-container">
          {this.state.dishLoader ?
            (<Box className="create_dish_loader_box">
              <CircularProgress classes={{
                root: "create_dish_loader"
              }}/>
            </Box>) :
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8} lg={9}>
              <div className="create-blk">
                <div className="create_dish">
                  <div className="credit_dish_blk">
                    <IconButton data-test-id="backButton" onClick={this.handleBackButton}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" className="credit_dish_txt">
                      {this.state.modeType} {configJSON.dishText}
                    </Typography>
                  </div>
                  
                  <div className="dish_inputs">
                  <div className="dish_type_container">
                    <FormControlLabel
                      value="start"
                      classes={{
                        label: "dish_switch_label"
                      }}
                      control={<CustomSwitch checked={this.state.isVeg} 
                      onChange={this.handleDishType} />}
                      label={configJSON.vegText}
                      labelPlacement="start"
                    />
                  </div>
                    {/* <form> */}
                    <label className="dish_label">{configJSON.dishTitleText}</label>
                    <br />
                    <div className="outlineTextfield boxRadiusBorder">
                      <CustomTextfield
                        data-test-id="dishTitle"
                        value={this.state.dishTitle}
                        onChange={this.handleDishTitleChange}
                        placeholder={configJSON.titlePlaceholderText}
                      />
                      {this.state.dishTitle.length == 0 && <span data-test-id="titleError" className="errorMsg">{this.state.titleError}</span>}
                    </div>
                    <label className="Description_txt">{configJSON.descriptionText}</label>
                    <br />
                    <div className="normalTextArea">
                      <TextareaAutosize
                      data-test-id="dishDescription"
                        value={this.state.dishDescription}
                        onChange={this.handleDishDescriptionChange}
                        minRows={10}
                        cols={40}
                      />

                      {this.state.dishDescription.length == 0 && <span className="errorMsg" data-test-id="descError">{this.state.descError}</span>}
                    </div>
                  </div>
                  <div className="dish_img_blk">
                    <Typography variant="h6" className="dish_img">
                      {configJSON.dishImageText}
                    </Typography>
                    <div className="add_photo">
                      {this.state.dishImages?.map((image: any, index: number) => {
                        return (
                          <div className="dish_images_cover" key={image?.id}>
                            <img className="dish_image" src={image.url} alt="dish" />
                            <IconButton className="dish_remove_icon" onClick={this.removeDishImage.bind(this,index,image?.id)}>
                              <CancelIcon fontSize="small" />
                            </IconButton>
                          </div>
                        )
                      })}
                      <div className="dish_images_cover">
                        <CustomFileUpload
                          id="dish_images"
                          label="Add Photo"
                          acceptTypes="image/png, image/jpeg"
                          allowMultipleFiles
                          handleChange={this.handleDishImages}
                        />
                      </div>
                    </div>
                    {this.state.dishImages.length == 0 && <div data-test-id="imageError" className="errorMsg">{this.state.imageError}</div>}
                  </div>
                  <div className="pricing">
                    <div className="customheading">
                      <Typography className={classes.priceText} variant="h4">{configJSON.pricingText}</Typography>
                      <hr/>
                    </div>

                    <div className="form-pricing">
                      <div className="price_field_container">
                        <Typography className="dish_price1">
                          {configJSON.dishPriceText}
                        </Typography>
                        <div className="outlineTextfield boxRadiusBorder">
                          <CustomPriceTextField
                            data-test-id="dishPrice"
                            value={this.state.dishPrice}
                            handleChange={this.handleDishPriceChange}
                          />
                          {this.state.dishPrice.length == 0 && <span className="errorMsg" data-test-id="priceError">{this.state.priceError}</span>}
                        </div>
                      </div>
                      <div className="price_field_container">
                        <Typography className="dish_price2">
                          {configJSON.compareAtPriceText}
                        </Typography>
                        <div className="outlineTextfield boxRadiusBorder">
                            <CustomPriceTextField
                            data-test-id="dishComparePrice"
                              value={this.state.dishComparePrice}
                              handleChange={this.handleDishComparePriceChange}
                            />
                            {this.state.dishComparePrice.length == 0 && <span data-test-id="comparePriceError" className="errorMsg">{this.state.comparePriceError}</span>}
                        </div>
                      </div>
                    </div>

                    <Box mt={4}>
                      <Box className="customheading create_dish_addons_heading">
                        <Typography variant="h4" className={classes.priceText}>{configJSON.addOnsText}</Typography>
                        <hr />
                      </Box>
                      {this.state.createDishAddons?.map((addOn: any, index: number) => {
                        const { addOnType, addOnList } = addOn

                        return (
                          <Box mt={2} key={addOn} className="outlineTextfield boxRadiusBorder">
                            <CustomTextfield
                              data-test-id="addOnCategory"
                              value={addOnType}
                              onChange={this.handleChangeAddOnType.bind(this,index)}
                              placeholder={configJSON.addOnCategoryPlaceholder}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                    data-test-id="deleteBtn"
                                      src={DustbinIcon}
                                      alt="delete"
                                      className="add_ons_category_delete"
                                      onClick={this.removeAddOnType.bind(this,index, addOn?.id)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Box className="add_ons_details_container">
                              <Grid container spacing={2}>
                                <Grid container justifyContent="space-evenly">
                                  <Grid item xs={5}>
                                    <span className="add_on_details_heading">{configJSON.addOnNameText}</span>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <span className="add_on_details_heading">{configJSON.priceText}</span>
                                  </Grid>
                                  <Grid item xs={1} />
                                </Grid>
                                {addOnList?.map((addOn: any, childIndex: number) => {

                                  const { name, price } = addOn

                                  if ((childIndex + 1) === addOnList.length) {
                                    return (
                                      <Grid key={addOn} container justifyContent="space-evenly" className={classes.addOnContainer}>
                                        <Grid item xs={4}>
                                          <div className="add_ons_detail">
                                            <div className="outlineTextfield boxRadiusBorder">
                                              <CustomTextfield
                                              data-test-id="addOnName"
                                                value={name}
                                                onChange={this.handleChangeAddOnDetails.bind(this,index, childIndex, "name")}
                                                placeholder={configJSON.addOnDetailsPlaceholder} />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="add_ons_detail">
                                            <div className="outlineTextfield boxRadiusBorder">
                                              <CustomPriceTextField
                                              data-test-id="addOnPrice"
                                                placeholder={configJSON.addOnDetailPriceText}
                                                value={price}
                                                handleChange={this.handleChangeAddOnDetails.bind(this,index, childIndex, "price")}
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className="add_on_details_action_icon">
                                              <img data-test-id="plusBtn" src={GreenPlus} alt="add" onClick={this.addAddOnDetails.bind(this,index)} />
                                            </div>
                                        </Grid>
                                      </Grid>
                                    )
                                  } else {
                                    return (
                                      <Grid key={addOn} container justifyContent="space-evenly" className={classes.addOnContainer}>
                                        <Grid item xs={5}>
                                            <div className="outlineTextfield boxRadiusBorder">
                                              <CustomTextfield 
                                              data-test-id="addOnNameOne"
                                                value={name}
                                                onChange={this.handleChangeAddOnDetails.bind(this,index, childIndex, "name")}
                                                placeholder={configJSON.addOnDetailsPlaceholder} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <div className="add_ons_detail">
                                            <div className="outlineTextfield boxRadiusBorder">
                                              <CustomTextfield 
                                              data-test-id="addOnPriceOne"
                                                value={price}
                                                onChange={this.handleChangeAddOnDetails.bind(this,index, childIndex,"price")}
                                                placeholder={configJSON.addOnDetailPriceText}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <AttachMoneyIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />

                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div className="add_on_details_action_icon">
                                              <img data-test-id="deleteAddOnBtn" src={DustbinIcon} alt="delete" onClick={this.removeAddOn.bind(this,index, childIndex, addOn?.id)}/>
                                            </div>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                })}
                              </Grid>
                            </Box>
                          </Box>
                        )
                      })}

                      <Button
                      data-test-id="newAddOnCategoryBtn"
                        variant="outlined"
                        classes={{
                          root: "create_dish_add_ons_button",
                          label: "create_dish_add_ons_button_label",
                        }}
                        onClick={this.createAddOnType}
                      >
                        {configJSON.newAddOnTypeText}
                      </Button>
                    </Box>
                  </div>
                    <Hidden smDown>
                      <div className="btns">
                        <Button className="cancel" onClick={this.handleBackButton}>{configJSON.cancelText}</Button>
                        <Button className="save"
                          data-test-id="saveUpdateBtn"
                          disabled={this.state.buttonLoader}
                          onClick={this.validate
                          }>
                          {this.state.dishLoader ? (
                            <CircularProgress size={30} color="inherit" />
                          ) :
                            (this.state.buttonName)}
                        </Button>
                      </div>
                    </Hidden>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Box className={classes.dishCategory}>
                <Box>
                  <div className="product-status">
                    <div className="product-blk">
                      <Typography variant="h6" className="product-text">
                        {configJSON.productStatusText}
                      </Typography>
                      <div className={containerClassName}>
                        <CustomDropDown
                          data-test-id="dishStatus"
                          value={this.state.dishStatus}
                          defaultRequire={false}
                          handleChange={this.handleDishStatusChange}
                          options={this.state.status}
                        />
                      </div>
                        <Typography
                          align="right"
                          onClick={this.handleDeleteButton}
                          className="create_dish_delete">{configJSON.deleteText}</Typography> 
                    </div>
                  </div>
                </Box>
                <Box>
                  <div className="dish-category">
                    <Typography variant="h6" className="select-text">
                      {configJSON.dishCategoryText}
                    </Typography>
                    <div className="green-outline">
                      <CustomDropDown
                      data-test-id="dishCategory"
                        value={this.state.dishCategory}
                        defaultOption={configJSON.selectCategoryText}
                        handleChange={this.handleDishCategoryChange}
                        options={this.formatOptions(this.props.dishCategories)}
                      />
                    </div>
                    <span className="create_dish_discovery">
                      {configJSON.dishCategoryHeadingText}
                    </span>
                    <br />
                    <label className="tag_label">{configJSON.tagsText}</label>
                    <br />
                    <TagsSuggestion
                      data-test-id="tags"
                      dataTestId="tags"
                      name="tags"
                      value={this.state.dishTags}
                      placeholder={configJSON.tagsPlaceholderText}
                      handleValueChange={this.handleDishTags}
                    />
                  </div>
                </Box>
                  <Hidden mdUp>
                    <div className="btns">
                      <Button className="cancel" onClick={this.handleBackButton}>{configJSON.cancelText}</Button>
                      <Button className="save"
                        data-test-id="saveUpdateBtn"
                        disabled={this.state.buttonLoader}
                        onClick={this.validate
                        }>
                        {this.state.dishLoader ? (
                          <CircularProgress size={30} color="inherit" />
                        ) :
                          (this.state.buttonName)}
                      </Button>
                    </div>
                  </Hidden>
              </Box>
            </Grid>
          </Grid>}
        </div>
        <Dialog
        data-test-id="deleteDish"
          open={this.state.deleteDialog}
          onClose={this.closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
                {configJSON.deleteDishMessageText}
          </DialogTitle>
         
          <DialogActions>
            <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label={configJSON.noText}
                    onClick={this.closeDeleteDialog}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-test-id="yesBtn"
                    color="secondary"
                    label={configJSON.yesText}
                    onClick={this.handleYesBtn} 
                    autoFocus
                  />
                </span>
          </DialogActions>
        </Dialog>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const mapStateToProps = (state: any) => {
  return { dishCategories: state.userAdminRestaurant.dishCategories };
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(withRouter(connect(mapStateToProps, { getDishAllCategories })(AdminRestaurantCreateDishes)));
export {AdminRestaurantCreateDishes}
// Customizable Area End
