import React from "react";
import {
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux"
import { setCheckoutOrder } from '../../assets'
import { withRouter } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import "../../../../ShoppingCart/assets/css/Shoppingcartpaymentmethod.scss";
import PickUpPaymentController, { Props } from "./PickUpPaymentController.web"

class PickUpPaymentMethod extends PickUpPaymentController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { checkoutOrder, isShippingLoading } = this.props

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total item</p>
                <p>${checkoutOrder?.order_details?.total_item}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Discount</p>
                <p>-${checkoutOrder?.order_details?.discount}</p>
              </Box>
            </Box>
            <hr />
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Sub total</p>
                <p>${checkoutOrder?.order_details?.sub_total}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Transaction fees</p>
                <p>${checkoutOrder?.order_details?.transaction_fees}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Shipping</p>
                <p>
                  {isShippingLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    `${checkoutOrder?.order_details?.shipping}`
                  )}
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box className="cart_total_payment_wrapper">
              <Box className="cart_payment_details">
                <p>{`Total(${checkoutOrder?.order_details?.item_count}
                  ${checkoutOrder?.order_details?.item_count > 1 ? 'items' : 'item'})`}</p>
                <p>${checkoutOrder?.order_details?.total}</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state:any) => {
  return {
    checkoutOrder: state.user.checkoutOrder
   };
};

export default withRouter(connect(mapStateToProps, { setCheckoutOrder })(PickUpPaymentMethod));
