// Customizable Area Start
export enum AppRoutings {
  Home = "/",
  Search = "/search",
  Wallet = "/wallet",
  TransactionHistory = "/transaction-history",
  SearchPage = "/search-page",
  SearchProducts = "/search/products",
  SearchDishes = "/search/dishes",
  SearchFarms = "/search/farms",
  SearchRestaurants = "/search/restaurants",
  SearchCourses = "/search/courses",
  LandingPageLoggedIn = "/landing-page-logged-in",
  AccountSetting = "/account-setting",
  OrderHistory = "/order-history",
  WishList = "/wish-list",
  ViewAddresses = "/view-addresses",
  FavoritePage = "/favourites-page",
  AboutUs = "/about-us",
  ContactUs = "/contact-us",
  PolicyAndInfo = "/policy&info/:id",
  WebFAQ = "/faq",
  SquareConnectRedirect= "/square-connect-redirect",
  PolicyInfo = "/policy&info",
  HyperLinkContent = "/hyperlink-content",
  HyperLinkContentId = "/hyperlink-content/:id",

  FarmsMarket = "/farms-market",
  FarmDetails = "/farm-details",
  FarmDetailsId = "/farm-details/:id",
  FarmDetailsCatelog = "/farm-details-catelog",
  FarmDetailsCatelogId = "/farm-details-catelog/:id",
  ProductDetails = "/product-details",
  FarmProductDetails = "/product-details/description",
  FarmProductDetailsId = "/product-details/description/:id",

  RestaurantLandingPage = "/restaurant-landing-page",
  RestaurantMapView = "/restaurant-map-view",
  RestaurantCatelog = "/restaurant-catelog",
  RestaurantDetails = "/restaurant-details",
  RestaurantDetailsId = "/restaurant-details/:id",

  Learning = "/learning",
  LearningCourses = "/learning-courses",
  LearningCoursesSingle = "/learning/courses/single",
  LearningCoursesEnrolled = "/learning/courses/enrolled",
  LearningCoursesSubsection = "/learning/courses/sub_section",
  CourseDetails = "/learning/courses/single?id=",

  ShoppingCart = "/shopping-cart",
  CheckOutOrder = "/check-out-order",

  ProductDetailsReviewsId = "/product-details/reviews/:id",

  Donate = "/donate",
  DonateId = "/donate/:id",

  Community = "/community",
  CommunityDiscussion = "/community-discussions",
  CommunityDiscussionDetails = "/community-discussion-details",
  CommunityDiscussionDetailsId = "/community-discussion-details/:id",
  CmsVendorPage="/cms-vendor-page",

  Experts = "/experts",
  ExpertsDetails = "/expert-details",
  ExpertsDetailsId = "/expert-details/:id",

  CreateRestaurantProfile = "/create-restaurant-profile/:2",

  AdminDashboard = "/admin/dashboard",
  SelectYourRole = "/select-your-role",
  AdminFarmDetails = "/admin/farm/details",
  AdminRestaurantDetails = "/admin/restaurant/details",
  AdminRestaurantDishes = "/admin/restaurant/dishes",
  AdminLearningDetails = "/admin/learning/details",
  AdminFarmEditFarmId = "/admin/farm/add-farm/?mode=edit&farm_id=",
  AdminFarmProduct = "/admin/farm/add-product/",
  AdminCourse = "/admin/learning/add-course",
  AdminAdCampaigns = "/admin/ad-campaigns",
  AdminAdCampaign = "/admin/ad-campaign",
  AdminTransactionHistory = "/admin/transaction-history",
}
// Customizable Area End
