// Customizable Area Start
import React from "react"
import { WeekReport, MonthReport, ReportsIcon, TodayReport } from "./assets"
import "../../../web/src/assets/css/style.scss"
import "../assets/css/adminRestDishes.scss"
import "../assets/css/restOrder.scss"
import "../assets/css/adminRestaurantReports.scss"
import "../../../blocks/catalogue/assets/css/formCreateRestaurant.scss"
import { commonCmptStyles } from "./dropDownCss"
import CustomTextfield from "../../../components/src/CustomTextfield.web"
import { withRouter } from "react-router-dom"
import SearchIcon from "@material-ui/icons/Search"
import CustomTab from "../../../components/src/CustomTab.web"
import {
  Box,
  InputAdornment,
  Button,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core"
import CustomDateRangePicker from "../../../components/src/CustomDateRangePicker"
import AdminRestaurantOrdersController, {
  Props, configJSON
} from "./AdminRestaurantOrdersController.web"
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web"
import OrderCancelApproveDialog from "../../ordermanagement/src/OrderCancelApproveDialog.web"
import OrderCancelRejectDialog from "../../ordermanagement/src/OrderCancelRejectDialog.web"
import AdminRestaurantOrderDetailsDialog from "./AdminRestaurantOrderDetailsDialog.web"
// Customizable Area End

// Customizable Area Start
const ReportModal = (props: any) => {
  const {
    restOrderReportModal,
    handleRestOrderReportsCloseModal,
    todayReport,
    weekReport,
    monthReport
  } = props
  return (
    <Dialog
      open={restOrderReportModal}
      onClose={handleRestOrderReportsCloseModal}
      fullWidth
      maxWidth="lg"
      classes={{
        paper: "custom_dialog_paper",
      }}
    >
      <CustomDialogTitle
        rootClassName="headerContainer"
        onClose={handleRestOrderReportsCloseModal}
      >
        <div className="headingReport">
          <img className="reportsIcon" src={ReportsIcon} alt="ReportsIcon" />
          <h3>{configJSON.reportsText}</h3>
        </div>
      </CustomDialogTitle>
      <DialogContent>
        <Box className="reportCardWrapper">
          <Grid container spacing={4} justifyContent="space-evenly">
            <Grid item>
              <Box className="reportCard">
                <h4>{configJSON.todayText}</h4>
                <div className="reportOrdersWeek">
                  <h3>{todayReport.Today}</h3>
                  <h5>{todayReport.orders}</h5>
                </div>
                <div className="todayReportImg">
                  <img src={TodayReport} alt="week_report" />
                </div>
              </Box>
            </Grid>
            <Grid item>
              <Box className="reportCard">
                <h4>
                  {configJSON.thisWeekText}
                  <br />
                  <span>{weekReport.start_and_end_week}</span>
                </h4>
                <div className="reportOrdersWeek">
                  <h3>{weekReport.This_week}</h3>
                  <h5>{weekReport.orders}</h5>
                </div>
                <div className="todayReportImg">
                  <img src={WeekReport} alt="week_report" />
                </div>
              </Box>
            </Grid>
            <Grid item>
              <Box className="reportCard">
                <h4>
                  {configJSON.thisMonthText}
                  <br />
                  <span>{monthReport.month}</span>
                </h4>
                <div className="reportOrdersWeek">
                  <h3>{monthReport.This_month}</h3>
                  <h5>{monthReport.orders}</h5>
                </div>
                <div className="todayReportImg">
                  <img src={MonthReport} alt="week_report" />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
// Customizable Area End


export class AdminRestaurantOrders extends AdminRestaurantOrdersController {
  constructor(props: Props) {
    super(props);

  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      isOrderCancelApproveDialog,
      isOrderCancelRejectDialog,
      isUserAdminCancelling,
      cancellationOrderId,
      isInitiateRefund,
      isOrderDetailsDialog,
      orderDetails
    } = this.state
    const currentStep = isInitiateRefund ? 2 : 0
    
    return (
      <main className="restOrderWrapper adminRestDishesContainer">
        {/* main card container */}
        <div className="tableContainer" >
          {/* filter section */}
          <div className="headerListContainer">
            <div className="prodListHeader">
              <Grid item xs={12} sm={4} md={4} className="orderblocks">
                <h4 data-test-id="ordersLabel">{configJSON.ordersText}</h4>
                <div className="greenOutlineButton reportsButton">
                  <Button
                    data-test-id="reportsBtn"
                    onClick={this.showOrdersReports}>
                    <img src={ReportsIcon} alt="ReportsIcon" />
                    <span>{configJSON.reportsText}</span>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="orderblocks ordersFirstRow">
                <div className="block1">
                  <div className="customSelect restaurantDropDown restMargin">
                    <FormControl>
                      <Select
                        value={this.state.activeRestaurant}
                        disabled={this.state.dishListLoader}
                        onChange={this.handleChangeActiveRestaurant}
                        className="menu-customize"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown
                          }
                        }}
                      >
                        {this.state.restaurantsList?.length === 0 && <MenuItem
                          className="dish_type_menus"
                          disabled
                          value={0}><span>{configJSON.selectRestText}</span>
                          <span className="checkmarkRest">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>}
                        {this.state.restaurantsList?.map((restaurant: any) => {
                          const { id, name } = restaurant.attributes
                          return (
                            <MenuItem className="dish_type_menus" value={id} key={id}>
                              <span>{name}</span>
                              <span className="checkmarkRest">
                                <div className="checkmark_circle" />
                                <div className="checkmark_stem" />
                                <div className="checkmark_kick" />
                              </span>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Grid item xs={12} className="rightOrders">
                  <span className="text">{configJSON.acceptingOrderText}</span>
                  <div className="grayBackGroundButton">
                    <div className="customToggleButton acceptOrderBtn">
                      <label className="switch">
                        <input
                          checked={this.state.acceptOrder}
                          onChange={() => this.handleAcceptingOrderStat(!this.state.acceptOrder)}
                          className="switchCheckbox"
                          type="checkbox"
                        />
                        <div className={ `${this.state.acceptOrder ? configJSON.greenSwitchClass : configJSON.redSwitchClass}`} />
                        <div className="switchLabels">
                          <span>{configJSON.yesText}</span>
                          <span>{configJSON.noText}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  </Grid>
                </div>
              </Grid>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <Grid item xs={12} sm={8} md={8}  className="outlineTextfield">
                <CustomTextfield
                className="searchOrdersRest"
                  value={this.state.search}
                  onKeyUp={this.handleSearchOrders}
                  placeholder={configJSON.restOrderPlaceholderText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleSearchValueChange}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} className="block1">
                <div data-test-id="todayContainer" className={this.state.today === '' ? configJSON.btnContainerClass : configJSON.todayBtnClass}
                >
                  <Button
                    variant="outlined"
                    className="todayText"
                    onClick={this.handleSetToday}
                  >
                    {configJSON.todayText}
                  </Button>
                  <CustomDateRangePicker data-test-id="dateRange" startDate={this.state.startDate} endDate={this.state.endDate} isRestChanged={this.state.isRestChanged} today={this.state.today} setRange={this.handleSetRange} />

                </div>
              </Grid>
            </div>
          </div>
          {/* tab section */}
          <div>
            <CustomTab
              data-test-id="ordersWrapper"
              handleOpenModal={this.handleRestOrderOpenModal}
              orderList={this.state.orderList}
              orderCount={this.state.orderCount}
              status={this.state.status}
              orderdeliveryTime={this.state.deliveryTime}
              handleStatus={this.handleStatus}
              open={this.state.restOrderModal}
              onClose={this.handleRestOrderCloseModal}
              handleOrderTime={this.handleOrderTime}
              handleAccept={this.putAcceptOrder}
              handleOrderReady={this.putOrderReady}
              handlePickOrder={this.putPickOrder}
              handleOrderDeliver={this.putOrderDeliver}
              handleChangeTablePage={this.handleChangeTablePage}
              handleIncreasePageView={this.handleIncreasePageView}
              handleDecreasePageView={this.handleDecreasePageView}
              restOrdersCurrentPage={this.state.ordersCurrentPage}
              restOrdersPerPage={this.state.ordersPerPage}
              totalOrderCount={this.state.totalOrderCount}
              totalOrderPage={this.state.totalOrderPage}
              currentPage={this.state.ordersCurrentPage}
              perPage={this.state.ordersPerPage}
              handlePagination={this.handlePagination}
              openOrderCancelApproveDialog={this.openOrderCancelApproveModal}
              openOrderCancelRejectDialog={this.openOrderCancelRejectModal}
              openOrderDetailsDialog={this.openOrderDetailsDialog}
            />
          </div>

        </div>
        {/* ends: main card container */}

        {this.state.restOrderReportModal && <ReportModal
        data-test-id="reportsData"
          restOrderReportModal={this.state.restOrderReportModal}
          handleRestOrderReportsCloseModal={this.handleRestOrderReportsCloseModal}
          todayReport={this.state.todayReport}
          weekReport={this.state.weekReport}
          monthReport={this.state.monthReport}
          currentMonth={this.state.currentMonth}
          currentWeek={this.state.currentWeek}
        />}

        {orderDetails && <AdminRestaurantOrderDetailsDialog
          isOpen={isOrderDetailsDialog}
          orderDetails={orderDetails}
          handleClose={this.closeOrderDetailsDialog}
        />}

        <OrderCancelApproveDialog
          isOpen={isOrderCancelApproveDialog}
          currentStep={currentStep}
          orderId={cancellationOrderId}
          cancellationByUserAdmin={isUserAdminCancelling}
          onCancellationSuccess={this.handleGetOrders}
          handleClose={this.closeOrderCancelApproveModal}
        />

        <OrderCancelRejectDialog
          isOpen={isOrderCancelRejectDialog}
          orderId={cancellationOrderId}
          onCancellationRejection={this.handleGetOrders}
          handleClose={this.closeOrderCancelRejectModal}
        />
      </main>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withRouter(withStyles(commonCmptStyles)(AdminRestaurantOrders));
// Customizable Area End
