// Customizable Area Start
import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { styled } from "@mui/system";
import Popover from "@material-ui/core/Popover";
import { NotificationIcon } from "./assets";
import InAppNotificationsController from "./InAppNotificationsController.web";
import AdminNotificationCard from "../../../components/src/AdminNotificationCard.web";
import NotificationLoaderWrapper from "../../../components/src/NotificationLoaderWrapper.web";
const configJSON = require("./config.js");

const CustomIconButton = styled(IconButton)({
  position: "relative"
});

const CustomImage = styled("img")({
  height: "20px",
  width: "20px",
  filter:
    "invert(67%) sepia(75%) saturate(575%) hue-rotate(327deg) brightness(100%) contrast(94%)",
  webkitFilter:
    "invert(67%) sepia(75%) saturate(575%) hue-rotate(327deg) brightness(100%) contrast(94%)"
});

const Badge = styled("span")({
  position: "absolute",
  top: "0px",
  right: "1px",
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  backgroundColor: "#f69946",
  border: "2px solid #fff"
});

const NotificationWrapper = styled(Box)({
  padding: "10px",
  width: "85vw",
  maxWidth: "400px",
  height: "70vh",
  maxHeight: "380px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
    borderRadius: "6px",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7"
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "6px",
    backgroundColor: "#c3c3c3",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "6px",
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7"
  }
});

const ListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "5px"
});

const CenterContentWrapper = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const NotificationHeading = styled(Typography)({
  padding: "10px 12px",
  fontSize: "17px",
  fontWeight: "bold",
  color: "#000",
  borderBottom: "1px solid #ddd",
  fontFamily: "'Montserrat', sans-serif"
});

export class InAppNotifications extends InAppNotificationsController {
  render() {
    const {
      anchorEl,
      loader,
      isUnSeenNotification,
      notifications
    } = this.state;
    const open = Boolean(anchorEl);
    const popOverId = open ? configJSON.notificationPopoverId : undefined;

    return (
      <>
        <CustomIconButton
          data-testid="notification-icon"
          size="small"
          onClick={this.openNotificationsPanel}
        >
          <CustomImage
            src={NotificationIcon}
            alt={configJSON.notificationsText}
          />
          {isUnSeenNotification && <Badge />}
        </CustomIconButton>
        <Popover
          open={open}
          id={popOverId}
          anchorEl={anchorEl}
          onClose={this.closeNotificationsPanel}
          anchorOrigin={configJSON.notificationPopoverAnchor}
          transformOrigin={configJSON.notificationPopoverTransform}
        >
          <NotificationHeading>
            {configJSON.notificationsText}
          </NotificationHeading>
          <NotificationWrapper>
            <NotificationLoaderWrapper isLoading={loader}>
              {notifications.length > 0 ? (
                <ListWrapper>
                  {notifications.map(notification => {
                    const {
                      id,
                      attributes: { headings, contents, is_read, created_at }
                    } = notification;
                    const time = this.getNotificationCreationTime(created_at);
                    return (
                      <AdminNotificationCard
                        key={id}
                        isSeen={is_read}
                        heading={headings}
                        content={contents}
                        time={time}
                      />
                    );
                  })}
                </ListWrapper>
              ) : (
                <CenterContentWrapper data-testid="empty-list-message">
                  {configJSON.noNotificationsText}
                </CenterContentWrapper>
              )}
            </NotificationLoaderWrapper>
          </NotificationWrapper>
        </Popover>
      </>
    );
  }
}

export default InAppNotifications;
// Customizable Area End
