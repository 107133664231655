import React from 'react';
import {
  getCartItemList,
  bulkAddToCart,
  removeCartItemApi,
  updateItemQuantityApi,
  sentOTP,
  verifyOTP,
  verifyUser,
  googleLoginAPI,
  getAddressList,
  addAddress,
  deleteAddress,
  updateAddress,
  updateAccountDetails,
  getWishlistCount,
  facebookLoginAPI
} from "./userAPI"
import { toast } from "react-toastify"
import StorageProvider from "framework/src/StorageProvider"
import store from "../store"
import { isTokenExpired } from "../../utility/helper"
// Set the user details to redux store
export const getUserDetails = (details) => async (dispatch) => {
  dispatch({
    type: 'GET_USER_DETAILS',
    payload: details,
  });
};

// Get user data from local storage
export const setUserDetailsFromLocal = () => async (dispatch) => {
  const profile = localStorage.getItem("profile")
  if (profile && profile !== "undefined") {
    dispatch({
      type: 'GET_USER_DETAILS',
      payload: JSON.parse(profile),
    });
  }
}

export const setUserCartId = (id) => async (dispatch) => {
    dispatch({
      type: 'SET_CART_ID',
      payload: id,
    })
}

export const setUserCartIdFromLocal = () => async (dispatch) => {
  const cartId = localStorage.getItem("cartId")
  if (cartId && cartId !== "undefined") {
    dispatch({
      type: 'SET_CART_ID',
      payload: JSON.parse(cartId),
    });
  }
}

// for open the login modal
export const setLoginModalTrue = () => async (dispatch) => {
  dispatch({ type: 'SET_LOGIN_MODAL_TRUE' });
};

// for close the login modal
export const setLoginModalFalse = () => async (dispatch) => {
  dispatch({ type: 'SET_LOGIN_MODAL_FALSE' });
};

// Action for sent OTP 1st
export const sentOTP1Action = (email) => async (dispatch) => {
  dispatch({ type: 'SET_LOADING_OTP_1_TRUE' });
  const response = await sentOTP({
    email
  })
  if (response.email_otp_id) {
    dispatch({
      type: 'SET_OTP_ID_1',
      payload: response.email_otp_id,
    });
    toast.success("OTP sent successfully")
  } else {
    response.errors.map((err) => {
      toast.error(err?.message)
    })
  }
  dispatch({ type: 'SET_LOADING_OTP_1_FALSE' });
}

// Action for sent OTP 2nd
export const sentOTP2Action = (phoneNumber) => async (dispatch) => {
  dispatch({ type: 'SET_LOADING_OTP_2_TRUE' });
  const response = await sentOTP({
    phone_number: `1${phoneNumber}`
  })
  if (response.sms_otp_id) {
    dispatch({
      type: 'SET_OTP_ID_2',
      payload: response.sms_otp_id,
    });
    toast.success("OTP sent successfully")
  } else {
    response.errors.map((err) => {
      toast.error(err?.message)
    })
  }
  dispatch({ type: 'SET_LOADING_OTP_2_FALSE' });
}

export const verifyOTP1Action = (otpData) => async (dispatch) => {
  dispatch({ type: 'SET_LOADING_VERIFY_OTP_1_TRUE' });
  const { email_otp_id, pin } = otpData;
  const response = await verifyOTP({
    email_otp_id,
    pin
  });
  if (!response.errors) {
    dispatch({
      type: 'SET_VERIFIED_OTP_1_TRUE',
    });
    dispatch({
      type: 'SET_VERIFICATION_ERROR_1',
      payload: false,
    });
  } else {
    dispatch({
      type: 'SET_VERIFICATION_ERROR_1',
      payload: true,
    });
  }
  dispatch({ type: 'SET_LOADING_VERIFY_OTP_1_FALSE' });
}

export const changeOTP1Verify = (isVerified) => async (dispatch) => {
  const actionType = isVerified ? 'SET_VERIFIED_OTP_1_TRUE' : 'SET_VERIFIED_OTP_1_FALSE'
  dispatch({ type: actionType })
}

export const verifyOTP2Action = (otpData) => async (dispatch) => {
  dispatch({ type: 'SET_LOADING_VERIFY_OTP_2_TRUE' });
  const { email_otp_id, pin } = otpData;
  const response = await verifyOTP({
    email_otp_id,
    pin
  })

  if (!response.errors) {
    dispatch({ type: 'SET_VERIFIED_OTP_2_TRUE', });
    dispatch({
      type: 'SET_VERIFICATION_ERROR_2',
      payload: false,
    });
  } else {
    dispatch({
      type: 'SET_VERIFICATION_ERROR_2',
      payload: true,
    });
  }
  dispatch({ type: 'SET_LOADING_VERIFY_OTP_2_FALSE' });
}

export const setSameContactDetails = (value) => async (dispatch) => {
  dispatch({
    type: 'SET_SAME_CONTACT_DETAILS_FLAG',
    payload: !value,
  });
  if (!value) {
    dispatch({ type: 'SET_VERIFIED_OTP_2_TRUE', });
  } else {
    dispatch({ type: 'SET_VERIFIED_OTP_2_FALSE', });
  }
}

export const resatOTPFlags = () => async (dispatch) => {
  dispatch({ type: 'RESAT_OTP_FLAGS', });
}

// This action is to verify user and update the local user profile
export const verifyUserAction = (history) => async (dispatch) => {
  const Token = await StorageProvider.get("authToken")
  console.log("Token", Token)
  if (Token) {
    const response = await verifyUser()
    if (!response.errors) {
      await StorageProvider.set('AdminAuth', response?.data?.attributes?.is_admin)
      await StorageProvider.set('firstname', response?.data?.attributes?.first_name)
      await StorageProvider.set('lastname', response?.data?.attributes?.last_name)
      localStorage.setItem("profile", JSON.stringify(response?.data?.attributes))
      dispatch({
        type: 'GET_USER_DETAILS',
        payload: response.data.attributes,
      });
    } else {
      toast.error("Session Expired Please login")
      dispatch({ type: 'RESET_USER_DETAILS', });
      await StorageProvider.remove('AdminAuth')
      await StorageProvider.remove('firstname')
      await StorageProvider.remove('lastname')
      await StorageProvider.remove('token')
      await StorageProvider.remove('authToken')
      localStorage.removeItem("profile")
      localStorage.removeItem("authToken")
      history.push("/")
    }
  }
}

export const resetUserDetails = () => async (dispatch) => {
  dispatch({ type: 'RESET_USER_DETAILS' })
  dispatch({ type: 'RESET_FARMS_FILTERS' })
  dispatch({ type: 'RESET_FARM_PRODUCT_FILTERS' })
  dispatch({ type: 'RESET_LP_DATA' })
}

export const googleLoginAction = (googleInfo, history, modalClose) => async (dispatch) => {
  dispatch({ type: 'SET_GOOGLE_LOADING_TRUE' });
  const postBody = {
    data: {
      type: "google",
      attributes: {
        access_token: googleInfo.tokenId,
        first_name: googleInfo?.profileObj?.givenName,
        last_name: googleInfo?.profileObj?.familyName,
      }
    }
  }
  try {
    const res = await googleLoginAPI(postBody)
    await StorageProvider.set('AdminAuth', res?.meta?.account?.is_admin)
    await StorageProvider.set('token', res?.meta.token)
    await StorageProvider.set('firstname', res?.meta?.account?.first_name)
    await StorageProvider.set('lastname', res?.meta?.account?.last_name)
    await StorageProvider.set("authToken", res?.meta.token)
    localStorage.setItem("authToken", res?.meta.token)
    localStorage.setItem("profile", JSON.stringify(res?.meta?.account))
    localStorage.setItem("cartId", JSON.stringify(res?.meta?.cart?.id))
    dispatch(setUserCartId(res?.meta?.cart.id))
    // @ts-ignore
    dispatch(getUserDetails(res?.meta?.account))
    modalClose()

    const orders = await StorageProvider.get("orders") || "[]"
    const ordersJSON = JSON.parse(orders)

    if(ordersJSON.length > 0) {
      const orderType = ordersJSON[0].orderCategory
      const apiOrders = ordersJSON.map((order) => {
        const { cartableId, quantity } = order
        if(order.orderCategory === "BxBlockCatalogue::FarmCatalogue") {
          return {
            cartable_id: cartableId,
            quantity
          }
        } else {
          const { cookingInstruction, extraThings } = order
          const addOnsIds = extraThings.map((addon) => addon.id)
          return {
            cartable_id: cartableId,
            quantity,
            addon_ids: addOnsIds,
            special_cooking_instruction: cookingInstruction,
          }
        }
      })
      await bulkAddToCart({ cartable_type: orderType, cartable_params: apiOrders })
    }
    dispatch(getCartItems())

    if (res.meta.is_new_user) {
      history.push("/select-your-role")
    }
  } catch (e) {
    console.log("error", e);
    toast.error("Something went wrong")
  }
  dispatch({ type: 'SET_GOOGLE_LOADING_FALSE' });
}

export const facebookLoginAction = (data,history,modalClose) => async (dispatch) => {
  dispatch({ type: 'SET_FACEBOOK_LOADING_TRUE' });
  console.log("Data", data)
  const httpBody = {
    data: {
        type: "facebook",
        attributes: {
            access_token: data.accessToken
        }
    }
  }
  try {
    const res = await facebookLoginAPI(httpBody)
    await StorageProvider.set('AdminAuth', res?.meta?.account?.is_admin)
    await StorageProvider.set('token', res?.meta.token)
    await StorageProvider.set('firstname', res?.meta?.account?.first_name)
    await StorageProvider.set('lastname', res?.meta?.account?.last_name)
    await StorageProvider.set("authToken", res?.meta.token)
    localStorage.setItem("authToken", res?.meta.token)
    localStorage.setItem("profile", JSON.stringify(res?.meta?.account))
    localStorage.setItem("cartId", JSON.stringify(res?.meta?.cart?.id))
    dispatch(setUserCartId(res?.meta?.cart.id))
    dispatch(getUserDetails(res?.meta?.account))
    modalClose()

    const orders = await StorageProvider.get("orders") || "[]"
    const ordersJSON = JSON.parse(orders)

    if(ordersJSON.length > 0) {
      const orderType = ordersJSON[0].orderCategory
      const apiOrders = ordersJSON.map((order) => {
        const { cartableId, quantity } = order
        if(order.orderCategory === "BxBlockCatalogue::FarmCatalogue") {
          return {
            cartable_id: cartableId,
            quantity
          }
        } else {
          const { cookingInstruction, extraThings } = order
          const addOnsIds = extraThings.map((addon) => addon.id)
          return {
            cartable_id: cartableId,
            quantity,
            addon_ids: addOnsIds,
            special_cooking_instruction: cookingInstruction,
          }
        }
      })
      await bulkAddToCart({ cartable_type: orderType, cartable_params: apiOrders })
    }
    dispatch(getCartItems())

    if (res.meta.is_new_user) {
      history.push("/select-your-role")
    }
  } catch (e) {
    console.log("error", e);
    toast.error("Something went wrong")
  }
  dispatch({ type: 'SET_FACEBOOK_LOADING_FALSE' });
}

export const getAddressListAction = () => async (dispatch) => {
  dispatch({ type: 'SET_ADDRESS_LOADING_FALSE', });
  try {
    const res = await getAddressList()
    if(!res?.error && !res?.errors) dispatch({ type: 'SET_USER_ADDRESS', payload: res.data })
    else {
      if(await isTokenExpired(res)) return
      dispatch({ type: 'SET_USER_ADDRESS', payload: res.data })
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({ type: 'SET_ADDRESS_LOADING_FALSE', });
}

export const setAddAddressErrors = (errors) => async (dispatch) => {
  dispatch({ type: 'SET_ADD_ADDRESS_ERRORS', payload: errors })
}

export const setEditAddressErrors = (errors) => async (dispatch) => {
  dispatch({ type: 'SET_EDIT_ADDRESS_ERRORS', payload: errors })
}

export const addAddressAction = (body, closeModal) => async (dispatch) => {
  dispatch({ type: "SET_ADDRESS_MODAL_LOADING_TRUE" })
  dispatch(setAddAddressErrors([]))
  try {
    const responseJson = await addAddress(body)
    if (
      responseJson?.hasOwnProperty("data") &&
      responseJson?.meta?.message === "Address Created Successfully"
    ) {
      toast.success("Address added successfully")
      dispatch(getAddressListAction())
      closeModal()
    } else {
      if (await isTokenExpired(responseJson)) return

      if (responseJson?.hasOwnProperty("errors")) {
        dispatch(setAddAddressErrors(responseJson?.errors ?? []))
      }
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({ type: "SET_ADDRESS_MODAL_LOADING_FALSE" })
}

export const updateAddressAction =
  (body, id, closeModal) => async (dispatch) => {
    dispatch({ type: "SET_ADDRESS_MODAL_LOADING_TRUE" })
    dispatch(setEditAddressErrors([]))
    try {
      const responseJson = await updateAddress(body, id)
      if (
        responseJson?.hasOwnProperty("data") &&
        responseJson?.meta?.message === "Address Updated Successfully"
      ) {
        toast.success("Address Updated successfully")
        dispatch(getAddressListAction())
        closeModal()
      } else {
        if (await isTokenExpired(responseJson)) return

        if (responseJson?.hasOwnProperty("errors")) {
          dispatch(setEditAddressErrors(responseJson?.errors ?? []))
        }
      }
    } catch (e) {
      toast.error("Something went wrong")
    }
    dispatch({ type: "SET_ADDRESS_MODAL_LOADING_FALSE" })
  }

export const deleteAddressAction = (id) => async (dispatch) => {
  dispatch({ type: 'SET_ADDRESS_DELETE_LOADING_TRUE' });
  try {
    const responseJson = await deleteAddress(id)
    if (responseJson?.meta?.message === "Address Deleted Successfully") {
      toast.success("Address deleted successfully")
      dispatch(getAddressListAction())
    } else {
      if(await isTokenExpired(responseJson)) return

      if (responseJson?.hasOwnProperty("errors")) {
        responseJson.errors.map((err) => {
          toast.error(err?.message)
        })
      }
    }
  } catch (e) {
    toast.error("Something went wrong..!!")
  }
  dispatch({ type: 'SET_ADDRESS_DELETE_LOADING_FALSE' });
}

export const updateAccountDetailsAction = (body) => async (dispatch) => {
  dispatch({ type: 'SET_ADDRESS_MODAL_LOADING_TRUE' });
  console.log("BODY", body)
  try {
    const responseJson = await updateAccountDetails(body)
    if (responseJson?.hasOwnProperty("data") && responseJson?.meta?.message === "Your profile updated successfully.") {
      dispatch({ type: 'UPDATE_USER_DETAILS', payload: body })
      toast.success("Account Details Updated Successfully")
    } else {
      if(await isTokenExpired(responseJson)) return

      if (responseJson?.hasOwnProperty("errors")) {
        responseJson.errors.map((err) => {
          toast.error(err?.message)
        })
      }
    }
  } catch (e) {
    toast.error("Something went wrong..!!")
  }
  dispatch({ type: 'SET_ADDRESS_MODAL_LOADING_FALSE' });
}

export const handleCartLoader = (loading) => async (dispatch) => {
  dispatch({ type: 'SET_CART_LOADER', payload: loading })
}

export const setCartBadge = (badgeCount) => async (dispatch) => {
  dispatch({ type: "SET_CART_BADGE", payload: badgeCount })
}

export const getCartItems = (loader = false) => async (dispatch) => {
  !loader && dispatch({ type: 'SET_CART_LOADER', payload: true });
  try {
    const res = await getCartItemList()
    if(res?.hasOwnProperty("type")) {
      dispatch({ type: 'SET_CART_ITEMS', payload: res })
      await StorageProvider.set("cartBadge", res?.cart_details?.data?.attributes?.total_count)
      dispatch(setCartBadge(res?.cart_details?.data?.attributes?.total_count))
    } else {
      if(await isTokenExpired(res)) return

      dispatch({ type: 'SET_CART_ITEMS', payload: {} })
      await StorageProvider.set("cartBadge", 0)
      dispatch(setCartBadge(0))
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({ type: 'SET_CART_LOADER', payload: false })
}

export const setCartItems = (cartItems) => async (dispatch) => {
  dispatch({ type: 'SET_CART_ITEMS', payload: cartItems })
}

export const removeCartItem = (id) => async (dispatch) => {
  try {
    const res = await removeCartItemApi(id)
    if (!res.error && !res.errors) {
      toast.success("Item removed successfully")
      dispatch(getCartItems(true))
      const checkoutOrder = store.getState().user.checkoutOrder
      if(checkoutOrder?.productList?.includes(id)) {
        const order = {...checkoutOrder}
        const index = order.productList.indexOf(id)
        order.productList.splice(index, 1)
        dispatch(setCheckoutOrder(order))
      }
    } else {
      if(await isTokenExpired (res)) return
      toast.error("Something went wrong")
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
}

export const updateItemQuantity = (id, quantity) => async (dispatch) => {
  try {
    const res = await updateItemQuantityApi(id, quantity)
    if (!res.error && !res.errors) {
      dispatch(getCartItems(true))
    } else {
      if(await isTokenExpired(res)) return
      toast.error("Something went wrong")
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
}

export const setCheckoutOrder = (order) => async (dispatch)=> {
  dispatch({ type: 'SET_CHECKOUT_ORDER', payload: order })
}

export const resetCheckoutOrder = () => async (dispatch)=> {
  dispatch(setCheckoutOrder({}))
}

export const setOrderReceiveType = (data) => async (dispatch) => {
  dispatch({ type: 'SET_CHECKOUT_ORDER_RECEIVE_TYPE', payload: data })
}

export const setOrderAddress = (address) => async (dispatch) => {
  dispatch({ type: 'SET_CHECKOUT_ORDER_ADDRESS', payload: address })
}

export const setOrderPaymentType = (type) => async (dispatch) => {
  dispatch({ type: 'SET_ORDER_PAYMENT_TYPE', payload: type })
}

export const setOrderCouponCode = (code) => async (dispatch) => {
  dispatch({ type: 'SET_ORDER_COUPON_CODE', payload: code })
}

export const setOrderCouponDiscount = (discount) => async (dispatch) => {
  dispatch({ type: 'SET_ORDER_COUPON_DISCOUNT', payload: discount })
}

export const setOrderNote = (note) => async (dispatch) => {
  dispatch({ type: 'SET_ORDER_NOTE', payload: note })
}

export const setCartOrderMeta = (details) => async (dispatch) => {
  dispatch({ type: 'SET_CART_ORDER_META', payload: details })
}

export const resetCartOrderMeta = () => async (dispatch) => {
  dispatch({ type: 'RESET_CART_ORDER_META' })
}

// for handle active tab
export const updateActiveTab = (activeTab) => async (dispatch) => {
  dispatch({ type: 'SET_ACTIVE_TAB', payload: activeTab });
};

export const toggleSidebar = () => async (dispatch) => {
  dispatch({ type: 'SET_SIDEBAR' })
}

export const handleProfileImage = (profileImage) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_PROFILE_IMG', payload: profileImage })
}

export const setWishlistCount = () => async (dispatch) => {
  const res = await getWishlistCount()
  let count = 0
  if(res?.hasOwnProperty("wishlist_count")) count = res.wishlist_count
  dispatch({ type: 'SET_WISHLIST_COUNT', payload: count })
}

export const updateWishlistCount = (count) => async (dispatch) => {
  dispatch({ type: 'SET_WISHLIST_COUNT', payload: count })
}