// Customizable Area Start
import React from "react"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import { createStyles, withStyles } from "@material-ui/core/styles"
import TransferRewardPointsDialogController, {
  Props,
  IMessageError,
} from "./TransferRewardPointsDialogController.web"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import FormikErrorMessage from "../../../../../components/src/FormikErrorMessage.web"
const configJSON = require("../../config.js")

const validationSchema = Yup.object().shape({
  transferAmount: Yup.number()
    .typeError("Amount must be a number")
    .test("is-positive", "Enter a valid amount", (value) => value > 0)
    .required("This field is required"),
  recipientEmail: Yup.string()
    .trim()
    .email("Please enter a valid email address")
    .required("Recipient's email is required"),
})

export const styles = () =>
  createStyles({
    dialogPaper: {
      borderRadius: "20px",
    },
    dialogTitle: {
      padding: "35px 35px 10px",
    },
    dialogContent: {
      padding: "10px 35px 35px",
    },
    mainHeading: {
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "normal",
      color: "#686868",
      width: "95%",
      fontFamily: "'Montserrat', sans-serif",
    },
    amountActionButton: {
      "& svg": {
        color: "#000",
      },
      "&:disabled svg": {
        color: "#a9a9a9",
      },
    },
    emailWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
      marginTop: "15px",
      "& p": {
        textAlign: "center",
        fontSize: "17px",
        color: "#686868",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "normal",
        width: "90%",
      },
    },
    customField: {
      "& .MuiInputBase-root": {
        borderRadius: "10px",
      },
      "& input": {
        padding: "15px",
        textAlign: "center",
      },
    },
    amountFieldWrapper: {
      maxWidth: "50%",
    },
    amountField: {
      "& input": {
        color: "#20a258",
        fontSize: "22px",
        fontFamily: "'Montserrat', sans-serif",
        padding: "12px",
      },
    },
    transferActionWrapper: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "10px",
    },
    actionButtonsWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginTop: "15px",
      "& button": {
        textTransform: "none",
      },
    },
    transferButton: {
      backgroundColor: "#20a258",
      borderRadius: "8px",
      minHeight: "37px",
      "& > span": {
        color: "#fff",
      },
      "&:hover": {
        opacity: 0.9,
        backgroundColor: "#20a258",
      },
    },
    cancelButton: {
      backgroundColor: "#d1d1d1",
      borderRadius: "8px",
      minHeight: "37px",
      "& > span": {
        color: "#ED5959",
      },
      "&:hover": {
        backgroundColor: "#d1d1d1",
        opacity: 0.9,
      },
    },
  })

class TransferRewardPointsDialog extends TransferRewardPointsDialogController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes, isOpen, availableBalance, handleClose } = this.props
    const { apiErrors, isTransferring } = this.state

    return (
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className={classes.dialogTitle}>
          <h4 className={classes.mainHeading}>
            {configJSON.transferPointsMainHeading}
          </h4>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={{
              transferAmount: "",
              recipientEmail: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => this.handleTransferSubmit(values)}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => {
              return (
                <Form noValidate>
                  <Box>
                    <Box className={classes.transferActionWrapper}>
                      <IconButton
                        disabled={Number(values.transferAmount) === 0}
                        className={classes.amountActionButton}
                        onClick={() =>
                          this.handleDecreasePoint(
                            values.transferAmount,
                            setFieldValue
                          )
                        }
                      >
                        <RemoveRoundedIcon />
                      </IconButton>
                      <Box className={classes.amountFieldWrapper}>
                        <TextField
                          variant="filled"
                          className={`${classes.customField} ${
                            classes.amountField
                          }`}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          autoFocus
                          name="transferAmount"
                          value={values.transferAmount}
                          onChange={(e) =>
                            this.handlePointsValueChange(e, setFieldValue)
                          }
                          placeholder={configJSON.amountText}
                        />
                        <Box>
                          <FormikErrorMessage
                            error={errors.transferAmount}
                            isTouched={touched.transferAmount}
                          />
                        </Box>
                      </Box>
                      <IconButton
                        disabled={
                          availableBalance === 0 ||
                          Number(values.transferAmount) === availableBalance
                        }
                        className={classes.amountActionButton}
                        onClick={() =>
                          this.handleIncreasePoint(
                            values.transferAmount,
                            setFieldValue
                          )
                        }
                      >
                        <AddRoundedIcon />
                      </IconButton>
                    </Box>
                    <Box className={classes.emailWrapper}>
                      <Typography>
                        {configJSON.enterRecipientEmailHeading}
                      </Typography>
                      <TextField
                        variant="filled"
                        name="recipientEmail"
                        value={values.recipientEmail}
                        onChange={handleChange}
                        className={classes.customField}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        placeholder={configJSON.EnterRecipientEmailText}
                      />
                      <FormikErrorMessage
                        error={errors.recipientEmail}
                        isTouched={touched.recipientEmail}
                      />
                    </Box>
                    <Box>
                      {apiErrors.map((error: IMessageError | string) => {
                        const errorMessage =
                          typeof error === "string" ? error : error.message
                        return (
                          <Alert key={errorMessage} severity="error">
                            {errorMessage}
                          </Alert>
                        )
                      })}
                    </Box>
                    <Box className={classes.actionButtonsWrapper}>
                      <Button
                        type="submit"
                        disabled={isTransferring}
                        className={classes.transferButton}
                      >
                        {isTransferring ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          configJSON.transferText
                        )}
                      </Button>
                      <Button
                        className={classes.cancelButton}
                        disabled={isTransferring}
                        onClick={handleClose}
                      >
                        {configJSON.cancelText}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(TransferRewardPointsDialog)
// Customizable Area Start
