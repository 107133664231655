// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  sidebarToggle?: any;
  history: any;
  setCartBadge: any;
  resetUserDetails: any;
}
interface S {
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  toggleSidebar: any;
  userDetails: any;
}
interface SS {
  id: any;
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      toggleSidebar: false,
      userDetails: localStorage.getItem("profile"),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false,
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false,
          });
        }
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false,
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false,
          });
        }
      }
    }
  }

  getDashboardData(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  sidebarToggle = async () => {
    console.log(this.state.toggleSidebar);
    this.setState({
      toggleSidebar: !this.state.toggleSidebar,
    });
  };

  handleUserLogout = async () => {
    const { history, setCartBadge, resetUserDetails } = this.props;

    await StorageProvider.remove(configJSON.AUTH_TOKEN);
    await StorageProvider.remove(configJSON.FIRST_NAME_KEY);
    await StorageProvider.remove(configJSON.LAST_NAME_KEY);
    localStorage.removeItem(configJSON.PROFILE_KEY);
    localStorage.removeItem(configJSON.AUTH_TOKEN);
    localStorage.removeItem(configJSON.CART_ID_KEY);
    resetUserDetails();

    const cartOrders =
      (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]";
    const ordersJSON = JSON.parse(cartOrders);
    setCartBadge(ordersJSON.length);
    toast.success("User Logout Successfully")

    history.push("/");
  };
}
// Customizable Area End
