// Customizable Area Start
import React from "react"
import AllWishlistPanelController, {
  Props,
  IProduct,
  IWishList
} from "./AllWishlistPanelController.web"
import "../../assets/css/wishlist.scss"
import WishlistContentWrapper from "../WishlistContentWrapper.web"
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import WishlistContentBox from "../WishlistContentBox.web"
import { veggies, updateWishlistCount } from "../assets"
import { connect } from "react-redux"

export class FarmWishlistPanel extends AllWishlistPanelController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, wishlists } = this.state

    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <Box>
            {wishlists?.length > 0 ? (
              wishlists.map((list: IWishList) => {
                const { name, wishlist } = list
                return (
                  <WishlistContentWrapper
                    heading={`Added From: ${name}'s Farm`}
                    key={name}
                  >
                    <Grid container spacing={3}>
                      {wishlist?.data?.map((product: IProduct) => {
                        const { id, attributes } = product
                        const { product_id, seller_id, product_name, image, price } = attributes
                        return (
                          <Grid item xs={12} key={id}>
                            <WishlistContentBox
                              id={id}
                              productId={product_id}
                              image={image?.url || veggies}
                              name={product_name}
                              sellerId={seller_id}
                              sellerName={name}
                              price={price}
                              cartableType="farm"
                              handleRemoveItemLocal={this.handleRemoveItemLocal}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </WishlistContentWrapper>
                )
              })
            ) : (
              <Box mt={3} className="order_history_blank_container">
                <Typography align="center">
                  Looks like you don't have favorite products
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    wishlistCount: state.user.wishlistCount
  }
}

export default connect(mapStateToProps, { updateWishlistCount })(FarmWishlistPanel)
// Customizable Area End
