import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { isTokenExpired } from "../../../../components/src/utility/helper";
export const configJSON = require("../config.js")
// Customizable Area End

// Customizable Area Start
interface IForum {
  forumId: string
  heading: string
  bannerImage: string
  likeCount: number
  commentCount: number
  isLiked: boolean
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  info: any
  classes: any
  onLoginSuccess: () => void
  onLikeChange: (forumId: string, isLiked: boolean, likeCount: number) => void
  onCommentUpdate: (forumId: string, commentCount: number) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean
  isLoginDialog: boolean
  forumDetails: IForum | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReceiptCardWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  likeChangeApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      openModal: false,
      isLoginDialog: false,
      forumDetails: null
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  openForumDialog = (forumDetails: any) => {
    const { banner, heading, likes, comments, is_like } = forumDetails.attributes

    const details: IForum = {
      forumId: forumDetails.id,
      heading,
      bannerImage: banner?.url || "",
      likeCount: likes,
      commentCount: comments,
      isLiked: is_like
    }
    this.setState({ openModal: true, forumDetails: details })
  }

  closeForumDialog = () => {
    this.setState({ openModal: false, forumDetails: null })
  }

  openLoginDialog = () => {
    this.setState({ isLoginDialog: true })
  }

  closeLoginDialog = () => {
    this.setState({ isLoginDialog: false })
  }

  capitalizeValue = (value: string) => {
    if (!value) return '';
    return `${value[0].toUpperCase()}${(value.slice(1))}`
  }

  handleLocallyLikeChange = (isLiked: boolean) => {
    const { info, onLikeChange } = this.props
    const { forumDetails } = this.state
    const likeCount = isLiked
      ? info?.attributes?.likes + 1
      : info?.attributes?.likes - 1

    const temp = forumDetails
      ? { ...forumDetails, isLiked, likeCount }
      : forumDetails
    this.setState({ forumDetails: temp })
    onLikeChange(info?.id, isLiked, likeCount)
  }  

  handleLikeChange = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation()
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(!isLoggedIn) {
      this.openLoginDialog()
      return
    }
    this.handleLikeChangeApi()
  }

  handleLikeChangeApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const { id } = this.props?.info
    const httpBody = {
      data: {
        attributes: {
          likeable_id: id,
          "likeable_type": configJSON.donationDataText
        }
      }

    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.likeChangeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true;
  }

  handleLoginSuccess = () => {
    this.props.onLoginSuccess()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.likeChangeApiCallId) {
          const isLiked = !!responseJson?.data?.attributes?.is_like
          this.handleLocallyLikeChange(isLiked)
        }
      } else {
        if(await isTokenExpired(responseJson)) return

        if (apiRequestCallId === this.likeChangeApiCallId) {
          toast.error(configJSON.errorText)
        }
      }
    }
  }
  // Customizable Area End
}
