// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import {RestaurantCardImg} from "../../../info-page/src/assets";

interface IRestaurantCardList {
  isActive: boolean
  image: string
  restaurantName: string
  rating: string | number
  dishName: string
  lat: number
  lng: number
  id: string
  handleClick: (lat: number, lng: number, id: string) => void
}

const RestaurantCardList = ({
  isActive,
  image,
  restaurantName,
  rating,
  dishName,
  lat,
  lng,
  id,
  handleClick
}: IRestaurantCardList) => {
  return (
    <Box className="restaurant_map_dish_card_wrapper">
      <Box
        className={`restaurant_map_dish_card ${isActive ? "active_restaurant_map_dish_card" : ""
          }`}
        onClick={() => handleClick(lat, lng, id)}
      >
        <img src={image || RestaurantCardImg} alt="restaurant_image" />
        <Box className="dish_card_rating">
          <StarOutlineIcon />
          <Typography>{rating}</Typography>
        </Box>
        <Box className="dish_card_details">
          <Typography>{dishName}</Typography>
          <Typography>{restaurantName}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RestaurantCardList;
// Customizable Area End
