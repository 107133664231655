// Customizable Area Start
import React from "react";
import { ProfileImg, DeleteIcon } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";
import CustomButton from "../../../components/src/CustomButton.web";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  withStyles,
  FilledInput,
  Tabs,
  Tab,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
//components
import AdminLearningDetailsController from "./AdminLearningDetailsController.web";
import { commonCmptStyles } from "./dropDownCss";
import "react-toastify/dist/ReactToastify.css";
import { Formik, ErrorMessage, Form } from "formik";
//@ts-ignore
import Editor from "../../../components/src/TaskTextEditor"
import Loader from "../../../components/src/Loader.web";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};


export class AdminLearningDetails extends AdminLearningDetailsController {
  //@ts-ignore
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown">
          <Loader loading={this.state.loading} />

          <Box className="create_role_main_wrapper">
            <Box className="create_profile_container">
              <Box className="">
                <Formik
                  innerRef={this.state.innerRef}
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    heading: "",
                    title: "",
                    website:"",
                    language: "",
                    twitter: "",
                    facebook: "",
                    linkedIn: "",
                    youTube: "",
                    twitterUsername: "",
                    facebookUsername: "",
                    linkedInUsername: "",
                    youTubeUsername: "",
                    image: "",
                  }}
                  validationSchema={this.Schema}
                  onSubmit={(values) => this.createContributor(values)}
                  data-testid="formik"
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                  }) => {
                    return (
                      <Form
                        noValidate
                        autoComplete="off"
                      >
                        <Grid
                          container
                          spacing={3}
                          className="contributeForms"
                        >
                          <Grid item xs={12}>
                            <div className="headerLearning">
                              <h4>Contributor Details</h4>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Tabs
                              aria-label="basic tabs example"
                              value="1"
                            >
                              <Tab
                                label="Profile "
                                value="1"
                              />
                            </Tabs>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="Profileheader">
                              <h4>Profile & Settings</h4>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                First Name
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                data-test-id="firstName"
                                  classes={{
                                    root:
                                      "create_role_profile_input_root",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="firstName"
                                  value={values.firstName}
                                  placeholder="Enter First Name"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  onChange={handleChange}
                                  error={
                                    touched.firstName &&
                                    Boolean(
                                      errors.firstName
                                    )
                                  }
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="firstName" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Last Name
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root:
                                      "create_role_profile_input_root",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="lastName"
                                  value={values.lastName}
                                  placeholder="Enter Last Name"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  onChange={handleChange}
                                  error={
                                    touched.lastName &&
                                    Boolean(errors.lastName)
                                  }
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="lastName" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Headline
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root:
                                      "create_role_profile_input_root",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="heading"
                                  value={values.heading}
                                  placeholder="Instructor for at"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  onChange={handleChange}
                                  error={
                                    touched.heading &&
                                    Boolean(errors.heading)
                                  }
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="heading" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Biography
                              </h6>
                              <Box
                                className="web_input_field"
                                style={{
                                  marginLeft: "-19px",
                                  marginRight: "-16px",
                                }}
                              >
                                <Editor placeholder="" values={values}
                                    setFieldValue={setFieldValue} />
                                <p className="helperText">
                                  Your biography should have
                                  at least 50 characters,
                                  links and coupons code are
                                  not permitted.
                                </p>
                                <span
                                  className="errorMsg"
                                  style={{
                                    marginLeft: "24px",
                                  }}
                                >
                                  <ErrorMessage name="title" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Language
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root:
                                      "create_role_profile_input_root",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="language"
                                  value={values.language}
                                  placeholder="English (US)"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  onChange={handleChange}
                                  error={
                                    touched.language &&
                                    Boolean(errors.language)
                                  }
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="language" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Website
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root:
                                      "create_role_profile_input_root",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="website"
                                  value={values.website}
                                  placeholder="URL"
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  onChange={handleChange}
                                  error={
                                    touched.website &&
                                    Boolean(errors.website)
                                  }
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="website" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Twitter
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.twitter.com/"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root urlClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="twitter"
                                  value={values.twitter}
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root userNameClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="twitterUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={
                                    values.twitterUsername
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Facebook
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.facebook.com/"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root urlClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="facebook"
                                  onChange={handleChange}
                                  value={values.facebook}
                                  aria-describedby="filled-weight-helper-text"
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root userNameClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="facebookUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={
                                    values.facebookUsername
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Linkedin
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.linkedin.com/"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root urlClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="linkedIn"
                                  value={values.linkedIn}
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root userNameClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="linkedInUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={
                                    values.linkedInUsername
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Youtube
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.youtube.com/"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root urlClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  aria-describedby="filled-weight-helper-text"
                                  id="youTube"
                                  onChange={handleChange}
                                  value={values.youTube}
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                  classes={{
                                    root:
                                      "create_role_profile_input_root userNameClass",
                                    input:
                                      "create_role_profile_input",
                                  }}
                                  id="youTubeUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={
                                    values.youTubeUsername
                                  }
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="headerLearning">
                              <h4>Profile & Settings</h4>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Image preview
                              </h6>
                              <p className="helperText helperText2">
                                Minimum 20kb, Maximum 512kb
                              </p>
                              <Box className="web_input_field">
                                <div className="mediaUploaderLearning mediaUploaderLearninghelper ">
                                  <img
                                    src={this.settingImage(
                                      values,
                                      ProfileImg
                                    )}
                                  />
                                </div>
                                <div className="textUpdateBlock">
                                  <span className="textUpdate">
                                    <input
                                      type="file"
                                      name="image"
                                      id="image"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg"
                                      className="inputfile"
                                      onChange={(
                                        e: any
                                      ) => {
                                        e.target.files.length>0 && setFieldValue(
                                          "image",
                                          e.target.files
                                        );
                                      }}
                                    />
                                    <label htmlFor="image">
                                      {values.image
                                        ? "Update"
                                        : "Upload"}
                                    </label>
                                  </span>
                                  {values.image ? (
                                    <img
                                      data-test-id="delIcon"
                                      src={DeleteIcon}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      alt="delete"
                                      onClick={() => {
                                        setFieldValue(
                                          "image",
                                          ""
                                        );
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                  <span className="errorMsg" data-test-id="imageError">
                                    <ErrorMessage name="image" />
                                  </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4} md={2}>
                            <Button
                            data-test-id="submitBtn"
                              classes={{
                                root:
                                  "create_role_profile_btn",
                              }}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
        </div>
      </>
    );
  }
}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningDetails)
);
// Customizable Area End