// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import RewardPointSettingController, {
  Props,
} from "./RewardPointsSettingController.web"
import TransferRewardPointsDialog from "./TransferRewardPointsDialog.web"

export const styles = () =>
  createStyles({
    mainWrapper: {
      display: "flex",
      justifyContent: "space-between",
      gap: "5px",
    },
    transferPointButton: {
      border: "none",
      backgroundColor: "transparent",
      color: "#20a258",
      fontSize: "13px",
      borderRadius: "10px",
      marginTop: "5px",
      padding: "4px 14px",
      boxShadow:
        "0px 2px 5px rgba(0,0,0,0.16), inset 0px 2px 5px rgba(0,0,0,0.16)",
      "&:disabled": {
        color: "rgba(118, 118, 118, 0.5)",
        cursor: "default",
      },
    },
    transactionHistoryButton: {
      border: "none",
      color: "#0F92AE",
      backgroundColor: "transparent",
      fontSize: "14px",
      textDecoration: "underline",
      display: "block",
      marginTop: "10px",
    },
    optionHeading: {
      fontSize: "18px",
    },
    rewardPointsBalance: {
      color: "#333",
      fontWeight: 800,
      fontFamily: "'Montserrat', sans-serif",
    },
  })

class RewardPointsSetting extends RewardPointSettingController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    const { rewardPoints, isPointTransferDialog } = this.state

    return (
      <>
        <Box className={classes.mainWrapper}>
          <Box>
            <h6 className={classes.optionHeading}>Loyalty Points</h6>
            <button
              disabled={rewardPoints === 0}
              className={classes.transferPointButton}
              onClick={this.openPointTransferDialog}
            >
              Transfer points to user
            </button>
            <button
              className={classes.transactionHistoryButton}
              onClick={this.redirectTransactionHistoryPage}
            >
              History of point transaction
            </button>
          </Box>
          <Box>
            <span className={classes.rewardPointsBalance}>${rewardPoints}</span>
          </Box>
        </Box>
        <TransferRewardPointsDialog
          availableBalance={rewardPoints}
          isOpen={isPointTransferDialog}
          getRewardPointsApi={this.getRewardPointsApi}
          handleClose={this.closePointTransferDialog}
        />
      </>
    )
  }
}

export default withStyles(styles)(withRouter(RewardPointsSetting))
// Customizable Area Start
