const cartState = {
    cart_details: {
        data: {
            id: '',
            type: '',
            attributes: {
                discount: 0,
                quantity: "0.0",
                shipping: 0,
                sub_total: 0,
                total: 0,
                total_count: 0,
                total_item: "0.0"
            }
        }
    },
    coupon: { data: null },
    delivery_time: null,
    order_accepted: false,
    out_of_stock_ids: [],
    product_cart_items: {},
    type: null
}

const initialState = {
    userDetails: {},
    loginModal: false,
    loadingOTP1: false,
    loadingOTP2: false,
    loadingOTPVerification1: false,
    loadingOTPVerification2: false,
    email_otp_id: "",
    sms_otp_id1: "",
    sms_otp_id2: "",
    isSameContactDetails: false,
    verifiedOTP1: false,
    verifiedOTP2: false,
    verificationErrorOTP1: false,
    verificationErrorOTP2: false,
    isOTP1Sent: false,
    isOTP2Sent: false,
    isGoogleLoading: false,
    address: [],
    isAddressLoading: false,
    isAddressModalLoading: false,
    isAddressDeleteBtnLoading: false,
    addAddressApiErrors: [],
    editAddressApiErrors: [],
    activeTab: "",
    isSidebarOpen: false,
    cart: { ...cartState },
    cartId: "",
    cartBadge: 0,
    cartLoader: true,
    cartOrderMeta: {},
    checkoutOrder: {},
    wishlistCount: 0,
    isFacebookLoading : false
}

export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'GET_USER_DETAILS':
            return {
                ...state,
                userDetails: payload
            }
        case 'REMOVE_USER_DETAILS':
            return {
                ...state,
                userDetails: {}
            }
        case 'SET_LOGIN_MODAL_TRUE':
            return {
                ...state,
                loginModal: true
            }
        case 'SET_LOGIN_MODAL_FALSE':
            return {
                ...state,
                loginModal: false
            }
        case 'SET_LOADING_OTP_1_TRUE':
            return {
                ...state,
                loadingOTP1: true
            }
        case 'SET_LOADING_OTP_1_FALSE':
            return {
                ...state,
                loadingOTP1: false
            }
        case 'SET_LOADING_OTP_2_TRUE':
            return {
                ...state,
                loadingOTP2: true
            }
        case 'SET_LOADING_OTP_2_FALSE':
            return {
                ...state,
                loadingOTP2: false
            }
        case 'SET_OTP_ID_1':
            return {
                ...state,
                // sms_otp_id1: payload,
                email_otp_id: payload,
                isOTP1Sent: true,
            }
        case 'SET_OTP_ID_2':
            return {
                ...state,
                sms_otp_id2: payload,
                isOTP2Sent: true,
            }
        case 'SET_SAME_CONTACT_DETAILS_FLAG':
            return {
                ...state,
                isSameContactDetails: payload
            }
        case 'SET_VERIFIED_OTP_1_TRUE':
            return {
                ...state,
                verifiedOTP1: true
            }
        case 'SET_VERIFIED_OTP_1_FALSE':
            return {
                ...state,
                verifiedOTP1: false
            }
        case 'SET_VERIFIED_OTP_2_TRUE':
            return {
                ...state,
                verifiedOTP2: true
            }
        case 'SET_VERIFIED_OTP_2_FALSE':
            return {
                ...state,
                verifiedOTP2: false
            }
        case 'SET_LOADING_VERIFY_OTP_1_TRUE':
            return {
                ...state,
                loadingOTPVerification1: true
            }
        case 'SET_LOADING_VERIFY_OTP_1_FALSE':
            return {
                ...state,
                loadingOTPVerification1: false
            }
        case 'SET_LOADING_VERIFY_OTP_2_TRUE':
            return {
                ...state,
                loadingOTPVerification2: true
            }
        case 'SET_LOADING_VERIFY_OTP_2_FALSE':
            return {
                ...state,
                loadingOTPVerification2: false
            }
        case 'SET_VERIFICATION_ERROR_1':
            return {
                ...state,
                verificationErrorOTP1: payload
            }
        case 'SET_VERIFICATION_ERROR_2':
            return {
                ...state,
                verificationErrorOTP2: payload
            }
        case 'SET_OTP_SENT_1_TRUE':
            return {
                ...state,
                isOTP1Sent: true
            }
        case 'SET_OTP_SENT_2_TRUE':
            return {
                ...state,
                isOTP2Sent: true
            }
        case 'SET_GOOGLE_LOADING_TRUE':
            return {
                ...state,
                isGoogleLoading: true
            }
        case 'SET_GOOGLE_LOADING_FALSE':
            return {
                ...state,
                isGoogleLoading: false
            }
        case 'SET_ADDRESS_LOADING_TRUE':
            return {
                ...state,
                isAddressLoading: true
            }
        case 'SET_ADDRESS_LOADING_FALSE':
            return {
                ...state,
                isAddressLoading: false
            }
        case 'SET_ADDRESS_MODAL_LOADING_TRUE':
            return {
                ...state,
                isAddressModalLoading: true
            }
        case 'SET_ADDRESS_MODAL_LOADING_FALSE':
            return {
                ...state,
                isAddressModalLoading: false
            }
        case 'SET_ADDRESS_DELETE_LOADING_TRUE':
            return {
                ...state,
                isAddressDeleteBtnLoading: true
            }
        case 'SET_ADDRESS_DELETE_LOADING_FALSE':
            return {
                ...state,
                isAddressDeleteBtnLoading: false
            }
        case 'SET_USER_ADDRESS':
            return {
                ...state,
                address: payload
            }
        case 'SET_ADD_ADDRESS_ERRORS':
            return {
                ...state,
                addAddressApiErrors: payload
            }
        case 'SET_EDIT_ADDRESS_ERRORS':
            return {
                ...state,
                editAddressApiErrors: payload
            }
        case 'RESET_USER_DETAILS':
            return {
                ...state,
                userDetails: {},
                cart: {},
                cartId: "",
                checkoutOrder: {},
                activeTab: "",
                address: [],
                isSidebarOpen: false,
                wishlistCount: 0
            }
        case 'UPDATE_USER_DETAILS':
            const updatedUser = {
                ...state.userDetails,
                email: payload.account.email,
                first_name: payload.account.first_name,
                last_name: payload.account.last_name,
            }
            localStorage.setItem("profile", JSON.stringify(updatedUser))
            return {
                ...state,
                userDetails: updatedUser
            }
        case 'UPDATE_USER_PROFILE_IMG':
            const updatedProfile = {
                ...state.userDetails,
                profile_pic: payload,
            }
            localStorage.setItem("profile", JSON.stringify(updatedProfile))
            return {
                ...state,
                userDetails: updatedProfile
            }
        case 'RESAT_OTP_FLAGS':
            return {
                ...state,
                loadingOTP1: false,
                loadingOTP2: false,
                loadingOTPVerification1: false,
                loadingOTPVerification2: false,
                sms_otp_id1: "",
                sms_otp_id2: "",
                isSameContactDetails: false,
                verifiedOTP1: false,
                verifiedOTP2: false,
                verificationErrorOTP1: false,
                verificationErrorOTP2: false,
                isOTP2Sent: false,
                isOTP1Sent: false,
            }
        case 'SET_ACTIVE_TAB':
            return {
                ...state,
                activeTab: payload,
                isSidebarOpen: false
            }
        case 'SET_SIDEBAR':
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen
            }
        case 'SET_CART_ITEMS':
            return {
                ...state,
                cart: payload
            }
        case 'SET_CART_LOADER':
            return {
                ...state,
                cartLoader: payload
            }
        case 'SET_CHECKOUT_ORDER':
            return {
                ...state,
                checkoutOrder: payload
            }
        case 'SET_CHECKOUT_ORDER_RECEIVE_TYPE':
            return {
                ...state,
                checkoutOrder: {
                    ...state.checkoutOrder,
                    address_type: payload.type,
                    address_id: payload.id
                }
            }
        case 'SET_CHECKOUT_ORDER_ADDRESS':
            return {
                ...state,
                checkoutOrder: {
                    ...state.checkoutOrder,
                    address_id: payload
                }
            }
        case 'SET_CART_BADGE':
            return {
                ...state,
                cartBadge: payload
            }
        case 'SET_CART_ID':
            return {
                ...state,
                cartId: payload
            }
        case 'SET_CART_ORDER_META':
            const { sellerId, itemsIds, orderNote } = payload
            const ordersMeta = state.cartOrderMeta
            ordersMeta[sellerId] = { sellerId, itemsIds, orderNote }
            return {
                ...state,
                cartOrderMeta: ordersMeta,
            }
        case 'RESET_CART_ORDER_META':
            return {
                ...state,
                cartOrderMeta: {}
            }
        case 'RESET_CART_ITEMS':
            return {
                ...state,
                cart: {}
            }
        case 'SET_WISHLIST_COUNT':
            return {
                ...state,
                wishlistCount: payload
            }
        case 'SET_FACEBOOK_LOADING_TRUE':
            return {
                ...state,
                isFacebookLoading: true
            } 
        case 'SET_FACEBOOK_LOADING_FALSE':
            return {
                ...state,
                isFacebookLoading: false
            }        
        default:
            return state
    }
}