// Customizable Area Start
import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { User_Avtar1 } from "./assets"

const useStyles = makeStyles(() => ({
  cardContainer: {
    position: "relative",
    height: "380px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    cursor: "pointer",
    "@media(max-width: 480px)": {
      height: "350px"
    },
    
  },
  cardImage: {
    position: "relative",
    borderRadius: "20px",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  cardDetailContainer: {
    position: "absolute",
    bottom: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: "20px",
    padding: "0px 20px 20px",
    gap: "10px",
  },
  name: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "28px",
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    maxWidth: "220px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  skill: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    maxWidth: "220px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))

interface IExpertCardCarousel {
  id: string
  image: string
  name: string
  skill: string
  handleOnClick: (id: string) => void
}

const ExpertCardCarousel = ({
  id,
  image,
  name,
  skill,
  handleOnClick,
}: IExpertCardCarousel) => {
  const classes = useStyles()

  return (
    <Box className={classes.cardContainer} onClick={() => handleOnClick(id)}>
      <img
        src={image || User_Avtar1}
        alt="expert"
        className={classes.cardImage}
      />
      <Box className={classes.cardDetailContainer}>
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.skill}>{skill}</Typography>
      </Box>
    </Box>
  )
}

export default ExpertCardCarousel
// Customizable Area End
