import React, { Component } from "react";
import { Grid, Box, Container } from "@material-ui/core";
import { RestaurantCardImg } from "../../assets";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import "../../assets/css/restaurantsearchmapviewlayout.scss";
class RestaurantSearchCard extends Component {
  render() {
    return (
      <Box className="restaurant_search_card">
        <Box className="restaurant_search_card_content">
          <div className="restaurant_rating">
            <span>
              <StarBorderIcon />
            </span>
            <p>9.8</p>
          </div>
          <div className="restaurant_details">
            <h6>burgers</h6>
            <h5>Best Burgers</h5>
          </div>
        </Box>
      </Box>
    );
  }
}

export default RestaurantSearchCard;
