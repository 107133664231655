// Customizable Area Start
import React from "react";
import "../assets/css/landingpageloggedin.scss";
import GreenBanner from "../../../../components/src/GreenBanner.web";
import WebFooter from "../../../../components/src/WebFooter.web";
import LandingPageController, {
  Props,
} from "./LandingPageController.web";
import {connect} from "react-redux";
import { updateActiveTab } from '../assets'
import LandingPageLogin from "./LandingPageLogin.web"
import LandingPageGuestWeb from "./LandingPageGuest.web";
// Customizable Area End

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start

    return (
      <>
        {JSON.stringify(this.props?.userInfo?.userDetails) != "{}" ?
          <LandingPageLogin />
          :
          <LandingPageGuestWeb />
        }
        <GreenBanner />
        <WebFooter />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return { userInfo: state.user };
};


export default connect(mapStateToProps, { updateActiveTab })(LandingPage);
// Customizable Area End
