import * as React from "react";
import { HarvestImage } from "./assets";

import "../../web/src/assets/css/content/norecords.scss";
import CustomButton from "./CustomButton.web";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

const CustomNoRecordsFound = (props: any) => {
  const { title, routeUrl ,disable,search} = props;

  console.log(props.routeUrl);
  return (
    <div className="norecord-container">
      <img src={HarvestImage} alt="harvest" />
      {search == "" ?<>
      <h3>
        No{" "}
        <span style={{ textTransform: "lowercase", color: "#20a258" }}>
          {title}s
        </span>{" "}
        listed
      </h3>

      <p>
        Please Upload {title} by clicking <br /> “+ New {title}" button.
      </p>

      <div className="addButton">
        <CustomButton
          label={`New ${title}`}
          onClick={() => {
            //@ts-ignore
            props.history.push(routeUrl);
          }}
          disabled={disable}
        />
      </div>
      </>:
      <h3>
      No{" "}
      <span style={{ textTransform: "lowercase", color: "#20a258" }}>
        {title}s
      </span>{" "}
      found
    </h3>}
    </div>
  );
};

//@ts-ignore
export default withRouter(CustomNoRecordsFound);
