// Customizable Area Start
import React, { useEffect, useState } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from "react-circular-progressbar"
import "../../web/src/assets/css/content/progressbar.scss"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "240px",
            height: "240px"
        },
        primaryHeading: {
            textAlign: "center",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "45px",
            fontWeight: 800,
            color: "#fff",
            margin: '10px 0px',
            "@media(max-width: 400px)": {
                fontSize: "30px"
            }
        },
        secondaryHeading: {
            textAlign: "center",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "15px",
            fontWeight: 500,
            color: "#fff",
            "@media(max-width: 400px)": {
                fontSize: "12px"
            }
        },
        progressWrapper: {
            position: "absolute",
        },
        contentWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
        },
        bottom: {
            color: "#e8e8e8",
        },
        top: {
            color: "#ffa700",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
        },
        circle: {
            strokeLinecap: "round",
        },
    })
)

interface ICustomDonationProgressbar {
    donatedAmount: number
    requiredAmount: number
}

const CustomDonationProgressbar = ({
    donatedAmount,
    requiredAmount,
}: ICustomDonationProgressbar) => {
    const classes = useStyles()
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        if(requiredAmount > 0) {
            const percentage = (donatedAmount / requiredAmount) * 100
            setPercentage(percentage)
        }
    }, [donatedAmount, requiredAmount])

    return (
        <Box className={classes.root}>
            <CircularProgressbarWithChildren
                value={percentage}
                styles={buildStyles({
                    pathColor: "#ffa700",
                    trailColor: "#ffffff",
                })}
            >
                <p className={classes.secondaryHeading}>Generated</p>
                <h5 className={classes.primaryHeading}>${donatedAmount}</h5>
                <p className={classes.secondaryHeading}>out of ${requiredAmount}</p>
            </CircularProgressbarWithChildren>
        </Box>
    )
}

export default CustomDonationProgressbar
// Customizable Area End
