// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import "../../../blocks/catalogue/assets/css/formCreateRestaurant.scss";
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import AdminRestaurantForumsController, { configJSON,
  Props,
} from "./AdminRestaurantForumsController.web";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";
// Customizable Area End

// Customizable Area Start
interface EnhancedTableProps {
  numSelected: number;
  orderBy?: string;
  rowCount: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
              data-test-id="table-head"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={-1}
            />
            <span>{configJSON.forumNameLabel}</span>
          </div>
        </TableCell>
        <TableCell align="center"> {configJSON.campaignStatusLabel}</TableCell>
        <TableCell align="center"> {configJSON.forumIdLabel}</TableCell>
        <TableCell align="center">{configJSON.likesLabel}</TableCell>
        <TableCell align="center">{configJSON.commentsLabel}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End


class AdminRestaurantForums extends AdminRestaurantForumsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const {
      forumsCurrentPage,
      forumsPerPage,
      totalForumCount,
      totalForumsPage,
      forumsList,
      loading,
      currentRestId,
      search,
      restaurants,
      bulkAction,
      selectedForum,
      openDialog,
    } = this.state

    const restStatus = configJSON.campaignStatusMenu;
    const menu = configJSON.options;
    const existRest = window.location.pathname.split("/")[4] == "0";

    const startingIndex = (forumsCurrentPage - 1) * forumsPerPage + 1

    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown restForumTableContainer">
          {/* filter section */}
          <Loader loading={loading} />
          <div className="headerListContainer">
            <div className="prodListHeader">
            <div className="blocks">
                <h4>{configJSON.forumsLabel}</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="customSelect restForum farmOrdersDropDown restaurantDropDown">
                    <FormControl>
                      <Select
                        data-test-id="currentRest"
                        className="menu-customize"
                        value={currentRestId || 0}
                        disabled={existRest}
                        onChange={this.handleRestaurantSelection}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText,
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText,
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown,
                          },
                        }}
                      >
                        <MenuItem value={0} disabled className="dish_type_menus">
                          <span>{configJSON.selectRestText}</span>
                          <span className="checkmarkRest">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {restaurants?.length > 0 &&
                          restaurants.map(
                            (i: any) => {
                              return (
                                <MenuItem value={i.id} key={i.id} className="dish_type_menus">
                                  <span>{i.attributes.name}</span>
                                  <span className="checkmarkRest">
                                    <div className="checkmark_circle" />
                                    <div className="checkmark_stem" />
                                    <div className="checkmark_kick" />
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                  <div className="addButton">
                    <CustomButton
                      data-test-id="createForumBtn"
                      label={configJSON.createForumBtn}
                      disabled={existRest}
                      onClick={this.handleCreateForumBtn}
                    />
                  </div>
                </div>
              </div>
              
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                id="input-with-icon-textfield"
                value={search}
                onChange={this.handleSearch}
                variant="standard"
                  placeholder={configJSON.restOrderPlaceholderText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect categoryDropDown dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        {configJSON.actionsLabel}
                      </InputLabel>
                      <Select
                        data-test-id="actions"
                        className="menu-customize"
                        value={bulkAction}
                        disabled={selectedForum.length < 2}
                        onChange={this.bulkActions}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText,
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText,
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.categoryDropDown,
                          },
                        }}
                      >
                        {restStatus.map((status: any, index: any) => {
                          return (
                            <MenuItem value={status} key={index}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block restforums">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                {forumsList?.length > 0 && (
                  <EnhancedTableHead
                  data-test-id="table"
                    numSelected={selectedForum.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={forumsList?.length}
                  />
                )}
                <TableBody>
                  {forumsList?.length > 0 ? (
                    forumsList
                      .map((item: any) => {
                        const isItemSelected = this.isSelectedForum(
                          item.id
                        );
                        const x = item.attributes;

                        return (
                          <TableRow key={item.id} data-test-id="forumsList">
                            <TableCell scope="row">
                              <div
                                className="productCell"
                                onClick={this.handleClickForumRow.bind(this,item.id)}
                              >
                                <CustomCheckboxField
                                  id={item.id}
                                  checked={isItemSelected}
                                />
                                <img
                                  src={x.banner.url}
                                  className="prodImage"
                                  alt="banner"
                                />
                                <p>{x.heading}</p>
                              </div>
                            </TableCell>

                            <TableCell align="center">
                              <div
                                className={`customSelect itemStatus ${x.status ==
                                  configJSON.draftStatus && configJSON.warningVariantText}`}
                              >
                                <FormControl>
                                  <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={x.status}
                                    onChange={
                                      this.updateForumStatus.bind(this,
                                        item.id
                                      )}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: configJSON.bottomText,
                                        horizontal: configJSON.leftText,
                                      },
                                      transformOrigin: {
                                        vertical: configJSON.topText,
                                        horizontal: configJSON.leftText,
                                      },
                                      classes: {
                                        paper: `${classes.itemStatus}`,
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {restStatus.map(
                                      (status: any, index: any) => {
                                        return (
                                          <MenuItem
                                            value={status}
                                            key={index}
                                          >
                                            {status}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </TableCell>
                            <TableCell align="center" className="BoldCell">
                              {item.attributes?.forum_id}
                            </TableCell>
                            <TableCell align="center">{x.likes}</TableCell>
                            <TableCell align="center">
                              {x.comments}
                            </TableCell>
                            <TableCell align="center">
                              <div className="option-dropDown">
                                <BasicMenu
                                  data-test-id="basic-menu"
                                  item={item}
                                  menu={menu}
                                  handleMenuClick={this.handleMenuClick}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0", border: 'none' }}
                      >
                        <CustomNoRecordsFound
                          routeUrl={`/admin/restaurant/add-forum/${
                            window.location.pathname.split("/")[4]
                          }?mode=create`}
                          title={configJSON.forumTitle}
                          disable={existRest}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {forumsList?.length > 0 && <div className="paginationRow restOrderPagination">
              <div>
                {startingIndex}-{startingIndex + forumsList.length - 1} of {totalForumCount} {configJSON.itemsText}
              </div>
              <CustomPagination
                data-test-id="pagination"
                count={totalForumsPage}
                page={forumsCurrentPage}
                pagePerView={forumsPerPage}
                handlePageChange={this.handleChangeTablePage}
                shape={configJSON.roundedText}
                siblingCount={1}
                increasePagePerView={this.handleIncreasePageView}
                decreasePagePerView={this.handleDecreasePageView}
                boundaryCount={1}
              />
            </div>}
          </div>
          {/* remove modal */}
          <div>
            <Dialog
              open={openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {configJSON.deleteForumText}
              </DialogTitle>
              <DialogActions>

                <span className="redContained customCancelBtn">
                  <CustomButton
                  data-test-id="noBtn"
                    color="secondary"
                    label="No"
                    onClick={this.handleDialog}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-test-id="yesBtn"
                    color="secondary"
                    label="Yes"
                    onClick={this.handleYesBtn}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantForums}
export default withRouter(withStyles(commonCmptStyles)(AdminRestaurantForums))
// Customizable Area End
