// Customizable Area Start
import * as React from "react";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenTopBanner from "../../../components/src/GreenTopBanner.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import Heading from "../../../components/src/HeadingComponent.web";
import "../assets/css/contactus.scss";
import {
  Container,
  Box,
  Grid,
  Typography,
  FilledInput,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import ContactUsController, { Props } from "./ContactusController.web";
import "../assets/css/aboutus.scss";
import { Formik, Form, ErrorMessage } from "formik";
import Loader from "../../../components/src/Loader.web";
import * as Yup from "yup";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";

class ContactUsUser extends ContactUsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {/* sssss */}
        <Loader loading={this.state.loading} />
        <GreenTopBanner bannerHeading="Contact Us" pageName="Contact Us" />
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            email: "",
            phone_number: "",
            subject: "",
            message: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("This field is required."),
            email: Yup.string()
              .email("Invalid email format")
              .required("This field is required."),
              phone_number: Yup.string()
              .matches(
                /^(\+1[-\s.]?)?(\(?\d{3}\)?[-\s.]?)?\d{3}[-\s.]?\d{4}$/,
                "Phone number not valid"
              )
              .required("This field is required."),
            subject: Yup.string().required("This field is required."),
            message: Yup.string().required("This field is required."),
          })}
          onSubmit={(values: any, { resetForm }: any) => {
            this.postContactUs(values);
            resetForm({ values: "" });
          }}
        >
          {(formikProps) => {
            const { values, handleChange, touched, errors } = formikProps;
            return (
              <Form translate="no" noValidate autoComplete="off">
                <Container fixed>
                  <Grid
                    container
                    spacing={6}
                    justifyContent="center"
                    className="contact_container_wrapper"
                  >
                    <Grid item xs={12} md={5}>
                      <Box className="map_image" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Grid
                          container
                          spacing={3}
                          className="contact_container"
                        >
                          <Grid
                            item
                            xs={12}
                            className="contact_right_container"
                          >
                            <Typography className="green_small_heading">
                              Contact Us
                            </Typography>

                            <Heading>Get in Touch with Us</Heading>
                            <Typography className="contact_us_text">
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Eos accusamus numquam, quis ex
                              ipsam debitis quod facilis minus ea
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Box width="100%" className="text_field">
                              <FilledInput
                                placeholder="Your Name"
                                classes={{
                                  input: "Login_Input",
                                  root: "Login_Input_Field",
                                  focused: "Login_Input_Field_Focus",
                                }}
                                id="name"
                                value={values.name}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleChange}
                                error={touched.name && Boolean(errors.name)}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="name" />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box width="100%" className="text_field">
                              <FilledInput
                                placeholder="Email Address"
                                classes={{
                                  input: "Login_Input",
                                  root: "Login_Input_Field",
                                  focused: "Login_Input_Field_Focus",
                                }}
                                id="email"
                                value={values.email}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="email" />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box width="100%" className="text_field">
                              <FilledInput
                                placeholder="Phone Number"
                                classes={{
                                  input: "Login_Input",
                                  root: "Login_Input_Field",
                                  focused: "Login_Input_Field_Focus",
                                }}
                                id="phone_number"
                                value={values.phone_number}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleChange}
                                error={
                                  touched.phone_number &&
                                  Boolean(errors.phone_number)
                                }
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="phone_number" />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box width="100%" className="text_field">
                              <FilledInput
                                placeholder="Subject"
                                classes={{
                                  input: "Login_Input",
                                  root: "Login_Input_Field ",
                                  focused: "Login_Input_Field_Focus",
                                }}
                                id="subject"
                                value={values.subject}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleChange}
                                error={
                                  touched.subject && Boolean(errors.subject)
                                }
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="subject" />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box width="100%" className="text_field">
                              <TextareaAutosize
                                aria-label="Write Message"
                                className="contact_us_text_area"
                                minRows={6}
                                placeholder="Write Message"
                                id="message"
                                value={values.message}
                                onChange={handleChange}
                                //@ts-ignore
                                error={
                                  touched.message && Boolean(errors.message)
                                }
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="message" />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              classes={{
                                root: "contact_us_btn",
                                label: "btn-label",
                              }}
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            );
          }}
        </Formik>

        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}

export default ContactUsUser;

// Customizable Area End
