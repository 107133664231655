// Customizable Area Start
import React from "react"
import CommunityHomePageController, {
    Props,
} from "./CommunityHomePageController.web"
import { Box, CircularProgress } from "@material-ui/core"
import { connect } from "react-redux"
import { setCommunityHomeDesc, updateActiveTab } from "../../assets"
import { bindActionCreators } from "redux"
import CommunityLatestForums from "./CommunityLatestForums.web"
import CommunityExperts from "./CommunityExperts.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import "../../assets/css/community/communityHomePage.scss"

class CommunityHomePage extends CommunityHomePageController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { loader } = this.state

        return (
            <>
                {loader ?
                    <Box className="chp_loader_container">
                        <CircularProgress size={30} />
                    </Box>
                    :
                    <>
                        <CommunityLatestForums />
                        <CommunityExperts />
                    </>
                }
                <GreenBanner />
                <WebFooter />
            </>)
    }
}

const mapStateToProps = (state: any) => {
    return {
        activeTab: state.user.activeTab,
        communityLatestForumsDesc: state.community.communityLatestForumsDesc
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateActiveTab: bindActionCreators(updateActiveTab, dispatch),
        setCommunityHomeDesc: bindActionCreators(setCommunityHomeDesc, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityHomePage)
// Customizable Area End
