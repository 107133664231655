// Customizable Area Start
import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { NumericFormat } from "react-number-format"

const useStyles = makeStyles(() => ({
    fieldCOntainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        border: "1px solid rgba(193, 193, 193, 0.4)",
        padding: "0px 10px",
        borderRadius: "8px",
    },
    inputField: {
        border: "none",
        outline: "none",
        padding: "10px 5px",
        width: "100%",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.87)",
        "&::placeholder": {
            color: '#394053',
            opacity: '0.42',
            fontSize: '14px',
            fontFamily: '"Poppins", sans-serif'
          }
    },
}))

interface IProps {
    value: string | number
    decimalScale?: number
    placeholder?: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomPriceTextField = ({
    value,
    decimalScale = 1,
    placeholder = "",    
    handleChange,
}: IProps) => {
    const classes = useStyles()

    return (
        <Box className={classes.fieldCOntainer}>
            <Box>
                <AttachMoneyIcon />
            </Box>
            <NumericFormat
                className={classes.inputField}
                value={value}
                placeholder={placeholder}
                decimalScale={decimalScale}
                onChange={handleChange}
            />
        </Box>
    )
}

export default CustomPriceTextField
// Customizable Area End
