// Customizable Area Start
const WLLPInitialState = {
  WLLPCarousels: [],
  WLLPSegmentHeading: "",
  WLLPWhatIsWtt: [],
  WLLPAboutUsHeading: "",
  WLLPAboutUsDesc: "",
  WLLPFarmingHeading: "",
  WLLPFarmingDesc: "",
  WLLPServicesHeading: "",
  WLLPServices: [],
  WLLPTestimonialsHeading: "",
  WLLPTestimonials: []
}

const LPInitialState = {
  LPIsReOrder: false,
  LPLatestUpdatesDesc: "",
  LPLatestUpdates: [],
  LPLikedFarmDesc: "",
  LPLikedFarms: [],
  LPFavRestaurantDesc: "",
  LPFavRestaurants: [],
  LPFavCoursesDesc: "",
  LPFavCourses: [],
  LPBodyDesc: "",
  LPUniqueOffering: []
}

const SelectRoleInitialState = {
  SelectRoleDesc: ""
}

const FDPInitialState = {
  FDPPopularProductsDesc: ""
}

const RLPInitialState = {
  RLPTagLine: "",
  RLPHeading: "",
  RLPDescription: "",
  RLPSubmitHeading: "",
  RLPSubmitDescription: ""
}

const FPInitialState = {
  FPFarmDesc: "",
  FPRestaurantDesc: "",
  FPCourseDesc: ""
}

const FooterBannerInitialState = {
  FooterBannerDescription: "",
  FooterDescription: {
    email: "",
    facebook: "",
    footer_description: "",
    instagram: "",
    phone_number: "",
    subscribe_now_description: "",
    twitter: "",
    hyperlink_heading: "",
    hyperlinks: []
  }
};

const initialState = {
  ...WLLPInitialState,
  ...LPInitialState,
  ...SelectRoleInitialState,
  ...FDPInitialState,
  ...RLPInitialState,
  ...FPInitialState,
  ...FooterBannerInitialState
}

export const cmsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_WLLP_DATA":
      const {
        carousels,
        segment_heading,
        what_is_wtt,
        about_us_heading,
        about_us_description,
        farming_heading,
        farming_description,
        our_services,
        our_services_heading,
        testimonial_heading,
        testimonial
      } = payload;

      return {
        ...state,
        WLLPCarousels: carousels.data,
        WLLPSegmentHeading: segment_heading,
        WLLPWhatIsWtt: what_is_wtt.data,
        WLLPAboutUsHeading: about_us_heading,
        WLLPAboutUsDesc: about_us_description,
        WLLPFarmingHeading: farming_heading,
        WLLPFarmingDesc: farming_description,
        WLLPServicesHeading: our_services_heading,
        WLLPServices: our_services.data,
        WLLPTestimonialsHeading: testimonial_heading,
        WLLPTestimonials: testimonial.data
      };
    case "SET_LP_DATA":
      const {
        reorder,
        latest_updates_description,
        latest_updates,
        farms_which_you_liked_description,
        farms_which_you_liked,
        favourite_restaurants_description,
        favourite_restaurants,
        favourite_courses_description,
        favourite_courses,
        landing_page_body,
        our_unique_offerings
      } = payload;

      return {
        ...state,
        LPIsReOrder: reorder,
        LPLatestUpdatesDesc: latest_updates_description,
        LPLatestUpdates: latest_updates.data,
        LPLikedFarmDesc: farms_which_you_liked_description,
        LPLikedFarms: farms_which_you_liked.data,
        LPFavRestaurantDesc: favourite_restaurants_description,
        LPFavRestaurants: favourite_restaurants.data,
        LPFavCoursesDesc: favourite_courses_description,
        LPFavCourses: favourite_courses.data,
        LPBodyDesc: landing_page_body,
        LPUniqueOffering: our_unique_offerings.data
      };
    case "RESET_LP_DATA":
      return {
        ...state,
        ...LPInitialState,
      };
    case "REMOVE_CMS_FAV_FARM":
      const favFarms = [...state.LPLikedFarms];
      const updatedFarms = favFarms.filter(
        (farm) => farm.attributes.id !== payload
      );

      return {
        ...state,
        LPLikedFarms: updatedFarms,
      };
    case "REMOVE_CMS_FAV_RES":
      const favRestaurants = [...state.LPFavRestaurants];
      const updatedRestaurants = favRestaurants.filter(
        (restaurant) => restaurant.attributes.id !== payload
      );

      return {
        ...state,
        LPFavRestaurants: updatedRestaurants,
      };
    case "REMOVE_CMS_FAV_COURSE":
      const favCourses = [...state.LPFavCourses];
      const updatedCourses = favCourses.filter(
        (course) => course.attributes.id !== payload
      );

      return {
        ...state,
        LPFavCourses: updatedCourses,
      };
    case "SET_SELECT_ROLE_DATA":
      return {
        ...state,
        SelectRoleDesc: payload,
      };
    case "SET_FDP_DATA":
      return {
        ...state,
        FDPPopularProductsDesc: payload,
      };
    case "SET_RLP_DATA":
      const {
        restaurant_tag_line,
        restaurant_heading,
        restaurant_description,
        restaurant_submit_heading,
        restaurant_submit_description,
      } = payload;

      return {
        ...state,
        RLPTagLine: restaurant_tag_line,
        RLPHeading: restaurant_heading,
        RLPDescription: restaurant_description,
        RLPSubmitHeading: restaurant_submit_heading,
        RLPSubmitDescription: restaurant_submit_description,
      };
    case "SET_FP_DATA":
      const {
        favorite_farm_description,
        favorite_restaurant_description,
        favorite_course_description,
      } = payload;
      return {
        ...state,
        FPFarmDesc: favorite_farm_description,
        FPRestaurantDesc: favorite_restaurant_description,
        FPCourseDesc: favorite_course_description,
      };
    case "SET_FOOTER_BANNER_DESC":
      return {
        ...state,
        FooterBannerDescription: payload,
      };
    case "SET_FOOTER_DESC":
      return {
        ...state,
        FooterDescription: payload,
      };
    default:
      return state;
  }
}
// Customizable Area End
