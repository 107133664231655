// Customizable Area Start
import * as React from "react";
import { MenuItem, Menu, Button } from "@material-ui/core";
import { DefaultProfileImg } from "./assets";
import "./assets/css/header.scss";
import "../../web/src/assets/css/content/dropdown.scss";
import { useSelector } from "react-redux";

export default function BasicMenu(props: any) {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const { userDetails } = useSelector((state: any) => state.user)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { handleMenuClick, menu, item, testdemo } = props

  return (
    <div className="customDropdown">
      <Button      
        className="btnGroup"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={userDetails?.profile_pic || DefaultProfileImg}
          className="userAvtar"
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="menu-customize"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menu &&
          menu.map((x: string) => {
            return (
              <MenuItem
                data-test-id="menu-item"
                onClick={() => {
                  handleClose()
                  if (x === "Remove") {
                    return testdemo()
                  } else if (["edit", "remove","view","Home","Account Setting","Log Out","Reactive","Block","Active"].includes(x)) {
                    handleMenuClick(x, item)
                  }
                }}
                key={x}
              >
                {x}
              </MenuItem>
            )
          })}
      </Menu>
    </div>
  )
}
// Customizable Area End