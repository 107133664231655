import React from "react"
import Pagination from "@material-ui/lab/Pagination"
import "../../web/src/assets/css/content/pagination.scss"
import { IconButton } from "@material-ui/core"
import { PAGINATION_MAX_PAGE_VIEW, PAGINATION_MIN_PAGE_VIEW } from "./utility/constants"

interface ICustomPaginationProps {
  count: number
  page: number
  pagePerView: number
  siblingCount: number
  boundaryCount: number
  shape: "round" | "rounded" | undefined
  handlePageChange: (pageNo: number) => void
  increasePagePerView: () => void
  decreasePagePerView: () => void
}

const CustomPagination = ({
  count,
  page,
  shape,
  pagePerView,
  boundaryCount,
  siblingCount,
  handlePageChange,
  increasePagePerView,
  decreasePagePerView,
}: ICustomPaginationProps) => {
  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    handlePageChange(value)
  }

  return (
    <div className="customPagination">
      <Pagination
         data-testid="pageChange"
        count={count}
        page={page}
        shape={shape}
        boundaryCount={boundaryCount}
        siblingCount={siblingCount}
        onChange={handleChange}
      />
      <div className="pagePerViewContainer">
        <p>{pagePerView}</p>
        <div className="btnContainer">
          <IconButton
            data-testid="increasePage"
            className="icon"
            disabled={pagePerView === PAGINATION_MAX_PAGE_VIEW}
            onClick={increasePagePerView}
          />
          <IconButton
          data-testid="decreasePage"
            className="icon"
            disabled={pagePerView === PAGINATION_MIN_PAGE_VIEW}
            onClick={decreasePagePerView}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomPagination
