// Customizable Area Start
import React, { Component } from "react"
import { Box, Typography } from "@material-ui/core"
import { styled } from "@mui/system"

const Card = styled(Box)(({ isSeen }: { isSeen: boolean }) => ({
  backgroundColor: isSeen ? "transparent" : "rgba(152, 176, 155, 0.15)",
  display: "flex",
  alignItems: "flex-start",
  gap: "3px",
  borderRadius: "8px",
  padding: "10px",
  cursor: "pointer",
}))

const CustomParagraph = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  letterSpacing: "normal"
})

const Heading = styled(CustomParagraph)({
  color: "#333",
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: "1px",
})

const Content = styled(CustomParagraph)({
  color: "#5a5a5a",
  fontSize: "14px",
})

const Time = styled(CustomParagraph)({
  color: "#505050",
  fontSize: "13px",
})

interface Props {
  isSeen: boolean
  heading: string
  content: string
  time: string
}

export class AdminNotificationCard extends Component<Props> {
  render() {
    const { isSeen, heading, content, time } = this.props

    return (
      <Card isSeen={isSeen}>
        <Box flex={1}>
          <Heading>{heading}</Heading>
          <Content>{content}</Content>
        </Box>
        <Time>{time}</Time>
      </Card>
    )
  }
}

export default AdminNotificationCard
// Customizable Area End
