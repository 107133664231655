import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class webEducationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCartApiCallId:string=""
  lineChart: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors){
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {
          //create order
          // if (apiRequestCallId === this.postcreateorderApiCallId) {
          //   console.log("create order ", responseJson.data.order.data);
          //   this.setState({
          //     Loader: false,
          //   });
          // }
          } 
          //create order
          // if (apiRequestCallId === this.postcreateorderApiCallId) {
          //   console.log("create order ", responseJson.data.order.data);
          //   this.setState({
          //     Loader: false,
          //   });
          // }
          } 

        }
      }
      // if(responseJson?.errors){
       
        // responseJson?.errors[0].token == "Token has Expired" &&  StorageProvider.remove("authToken");
        // this.sessionLogout();
      // }
    }


// get all courses
  // getCart = (id: any): boolean => {
  //   this.setState({
  //     Loading: true,
  //   });
  //   const headers = {
  //     "Content-Type": configJSON.productApiContentType,
  //     token: this.state.authToken,
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.getCartApiCallId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.postCreateOrderAPiEndPoint + `/${id}`
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.validationApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);

  //   return true;
  // };

    // Customizable Area End
  


