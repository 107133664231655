import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import {
  Card,
  Box,
  CssBaseline,
  Drawer,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";

import {
  PlayIcon,
  PauseIcon,
} from "./assets";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
// css
import "../assets/css/educationsidebar.scss"
import CustomButton from "../../../components/src/CustomButton.web";

// const drawerWidth = 240;
const webEduCoursePlaySidebar = (props: any) => {
  const { window, handleDrawerToggle, mobileOpen } = props;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : "");
  };

  const drawer = (
    <aside className="EduCoursePlaySidebar">
      <div className="cardContainer">
        <Card className="card coursePlayCard">
          <div className="cardHeader">
            <Typography variant="h6">Course Content</Typography>
            <div
              className="greenOutlineGrayButton filterBtn"
              onClick={() => handleDrawerToggle()}
            >
              <CloseIcon className="closeIcon" />
            </div>
            <hr />
          </div>
          <div className="customAccordian">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
              >
                <Typography variant="h6" className="topTitle">
                  Topic  1: New Farming Technique
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails">
                <ul className="navList">
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg pauseCircle"
                      style={{ border: "2px solid #ec9e31" }}
                    >
                      <img src={PauseIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
              >
                <Typography variant="h6" className="topTitle">
                  Topic 3: New Farming Technique
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails">
                <ul className="navList">
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg pauseCircle"
                      style={{ border: "2px solid #ec9e31" }}
                    >
                      <img src={PauseIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
              >
                <Typography variant="h6" className="topTitle">
                  Topic 4: New Farming Technique
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails">
                <ul className="navList">
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg pauseCircle"
                      style={{ border: "2px solid #ec9e31" }}
                    >
                      <img src={PauseIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
              >
                <Typography variant="h6" className="topTitle">
                  Topic 4: New Farming Technique
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails">
                <ul className="navList">
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg pauseCircle"
                      style={{ border: "2px solid #ec9e31" }}
                    >
                      <img src={PauseIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                  <li>
                    <div
                      className="circleBg playCircle"
                      style={{ border: "2px solid #20a258" }}
                    >
                      <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <h6>Right way to do farming</h6>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        </Card>
      </div>
    </aside>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="educationSidebar">
      <CssBaseline />

      <Box className="drawerNav"
        component="nav"
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className="drawerContainer"
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"

          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

webEduCoursePlaySidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

//@ts-ignore
export default withRouter(webEduCoursePlaySidebar);
// Customizable Area Start

// Customizable Area End
