// custom components
import React from "react";
import "../../web/src/assets/css/content/fileupload.scss";

import { FileUploadIcon } from "./assets";

export default function CustomFileUpload(props: any) {
  const {
    label,
    allowMultipleFiles = false,
    handleChange,
    handleClick,
    id,
    acceptTypes = ""
  } = props;

  return (
    <div className="customFileUpload">
      <input
        type="file"
        id={id}
        className="fileUploadInput"
        multiple={allowMultipleFiles}
        onChange={handleChange}
        onClick={handleClick}
        accept={acceptTypes}
      />
      <label htmlFor={id} className="fileUploadLabel">
        <img src={FileUploadIcon} alt="carrot" />
        <span>{label}</span>
      </label>
    </div>
  );
}
