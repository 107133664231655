import React from "react";
import webEducationFilterController, {
  Props,
} from "./webEducationFilterController.web";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import EducationSidebar from "./EducationSidebar.web";
import { withRouter } from "react-router-dom";
// css
import "../assets/css/educationfilter.scss";
import {
  Link,
  Button,
  MenuItem,
  Select,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
} from "@material-ui/core";
//images
import {
  CourseImg4,
  CourseImg5,
  CourseImg6,
  LikeIcon,
  CommentIcon,
  Receipt1Img,
  ProfileImg1,
  ProfileImg2,
} from "./assets";
//components
import CustomSelectField from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Rating from "@material-ui/lab/Rating";
import CustomTextAreafield from "../../../components/src/CustomTextAreafield.web";

class webEducationFilter extends webEducationFilterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {/* <div className="Header"> */}
        <WebHeader />
        <WebRoutingTab />
        {/* education filter wrapper */}
        {/* education container */}
        <main className="educationFilterWapper">
          <div className="subContentWrapper">
            <section className="choseusSection commonSection">
              <Grid container spacing={2} className="cardContainer">
                <Grid item xs={12} md={3} xl={2}>
                  <EducationSidebar handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen} />
                </Grid>
                <Grid item xs={12} sm={12} md={9} xl={10}>
                  <div className="filterCoursesWrapper">
                    <div className="filterCoursesHeader">
                      <h4>Courses</h4>
                      <div className="filterRightBlock">
                        <div className="courselist">
                          <span>sort by: </span>
                          <CustomSelectField label="low to high" />
                        </div>
                        <div className="greenOutlineGrayButton" >
                          <CustomButton label="Filter" onClick={() => {

                            this.handleDrawerToggle()
                          }} />
                        </div>
                      </div>
                    </div>
                    <Grid container spacing={5} className="cardContainer">
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">
                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg4}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Foods with Nutritions
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />

                            <CardMedia
                              component="img"
                              image={CourseImg5}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Environment Science
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg6}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>

                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Carbon  Forestry
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg4}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Foods with Nutritions
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">

                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg5}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Environment Science
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">

                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg6}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Carbon  Forestry
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg4}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Foods with Nutritions
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">

                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg5}
                              alt="Course Img"
                              className="courseImg"
                            />

                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Environment Science
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card className="card purchaseCard">
                          <div className="imgContainer">

                            <Rating
                              name="simple-controlled"
                              value={3}
                              className="rating"
                            />
                            <CardMedia
                              component="img"
                              image={CourseImg6}
                              alt="Course Img"
                              className="courseImg"
                            />
                          </div>
                          <CardContent className="cardContent">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              Carbon  Forestry
                            </Typography>
                            <Typography variant="body2">By John Doe</Typography>
                            <Typography variant="body2">
                              This is the most popular and in-demand class so it
                              will be even more fun
                            </Typography>
                          </CardContent>
                          <CardActions className="CardActions">
                            <h4>$130</h4>
                            <div className="greenFilledButton">
                              <CustomButton label="Buy Now" />
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                    <div className="circleBtn filterBtnRow">
                      <CustomButton label="Show more" />
                    </div>
                    <div className="paginationRow">
                      <CustomPagination count={20}
                        shape="rounded" siblingCount={1}
                        boundaryCount={1} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          </div>

          <section className="farmBannerSection commonSection">
            <div className="subContentWrapper">
              <h1>Help John Doe's farm</h1>
              <h5>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </h5>
              <div className="greenFilledButton primaryBtn">
                <CustomButton label="DONATE TODAY" />
              </div>
            </div>
          </section>

          <div className="subContentWrapper">
            <section className="learnSection commonSection">
              <h2>Learn Something New</h2>
              <Grid container spacing={5} className="cardContainer">
                <Grid item xs={12} sm={4}>
                  <Card className="card receiptCard">
                    <CardMedia
                      component="img"
                      image={Receipt1Img}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <div className="receiptDate">10 July, 2021</div>
                      <Typography gutterBottom variant="h4" component="h4">
                        Best receipes using
                        <br />
                        Carrots
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <div className="actionRow">
                        <img
                          src={LikeIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Likes</h6>
                      </div>
                      <div className="actionRow">
                        <img
                          src={CommentIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Comments</h6>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card receiptCard">
                    <CardMedia
                      component="img"
                      image={Receipt1Img}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <div className="receiptDate">10 July, 2021</div>
                      <Typography gutterBottom variant="h4" component="h4">
                        Best receipes using
                        <br />
                        Carrots
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <div className="actionRow">
                        <img
                          src={LikeIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Likes</h6>
                      </div>
                      <div className="actionRow">
                        <img
                          src={CommentIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Comments</h6>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card receiptCard">
                    <CardMedia
                      component="img"
                      image={Receipt1Img}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <div className="receiptDate">10 July, 2021</div>
                      <Typography gutterBottom variant="h4" component="h4">
                        Best receipes using
                        <br />
                        Carrots
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <div className="actionRow">
                        <img
                          src={LikeIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Likes</h6>
                      </div>
                      <div className="actionRow">
                        <img
                          src={CommentIcon}
                          className="actionIcons"
                          alt="Like Icon"
                        />
                        <h5>1532</h5>
                        <h6>Comments</h6>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </section>
            <section className="reviewSection commonSection">
              <div className="reviewHeader">
                <h6>Reviews(10)</h6>
                <a href="#">Write review</a>
              </div>
              <Grid className="cardContainer">
                <Card className="card reviewCard">
                  <CardMedia
                    component="img"
                    image={ProfileImg1}
                    alt="Profile Img"
                    className="profileImg"
                  />
                  <CardContent className="cardContent">
                    <div className="topRow">
                      <h5>Lisa Methew</h5>
                      <h6>10 July, 2021</h6>
                    </div>
                    <div className="ratingRow">
                      <Rating name="simple-controlled" value={3} />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </p>
                  </CardContent>
                </Card>
                <Card className="card reviewCard">
                  <CardMedia
                    component="img"
                    image={ProfileImg2}
                    alt="Profile Img"
                    className="profileImg"
                  />
                  <CardContent className="cardContent">
                    <div className="topRow">
                      <h5>Lisa Methew</h5>
                      <h6>10 July, 2021</h6>
                    </div>
                    <div className="ratingRow">
                      <Rating name="simple-controlled" value={3} />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </p>
                  </CardContent>
                </Card>
              </Grid>
              <div className="commentBlock">
                <h6>Write Review</h6>
                {/* <div className="outlineTextfield"> */}
                <CustomTextAreafield
                  rows={5}
                  cols={20}
                  placeholder="Write comment here..."
                />
                {/* </div> */}
                <div className="circleBtn">
                  <CustomButton label="Submit" />
                </div>
              </div>
            </section>
          </div>
        </main>
        {/* ends: education filter wrapper */}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}
//@ts-ignore
export default withRouter(webEducationFilter);
// Customizable Area Start

// Customizable Area End
