// Customizable Area Start
import React from "react"
import { Box, Typography, CircularProgress } from "@material-ui/core"
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded"
import SearchResultPagination from "./SearchResultPagination.web"

interface ISearchCustomLayoutProps {
  loader: boolean
  totalPages: number
  listLength: number
  emptyMessage: string
  children: React.ReactElement | React.ReactElement[]
}

const SearchCustomLayout = ({
  loader,
  totalPages,
  listLength,
  emptyMessage,
  children,
}: ISearchCustomLayoutProps) => {
  if (loader) {
    return (
      <Box style={styles.loaderWrapper}>
        <CircularProgress size={40} style={styles.loader} />
      </Box>
    )
  }

  return listLength > 0 ? (
    <Box>
      {children}
      <Box paddingTop={2}>
        <SearchResultPagination totalPages={totalPages} />
      </Box>
    </Box>
  ) : (
    <Box style={styles.mainWrapper}>
      <ErrorOutlineRoundedIcon fontSize="large" style={styles.errorIcon} />
      <Typography variant="h5" style={styles.emptyText}>
        {emptyMessage}
      </Typography>
    </Box>
  )
}

const styles = {
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    height: "50vh",
  },
  errorIcon: {
    color: "#333",
  },
  emptyText: {
    color: "#333",
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50vh",
  },
  loader: {
    color: "#20a258",
  },
}

export default SearchCustomLayout
// Customizable Area End
