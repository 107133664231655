// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import StorageProvider from "framework/src/StorageProvider";
import { toast } from "react-toastify";
const configJSON = require("../../config")
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper";
// Customizable Area End

// Customizable Area Start
export interface Props extends RouteComponentProps {
    isOpen: boolean
    isOnlySave?: boolean
    handleClose: () => void
    onCardAdd: () => void
    handlePayment: (id: string) => void
}
// Customizable Area End

interface S {
    // Customizable Area Start
    isCardLoader: boolean
    isSaveCard: boolean
    errorMsg: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddCardDialogController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    addCardApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            isCardLoader: false,
            isSaveCard: true,
            errorMsg: ''
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    toggleSaveCardCheckbox = () => {
        this.setState({ isSaveCard: !this.state.isSaveCard })
    }

    handleCardTokenizeResponse = (cardDetails: any) => {
        this.setState({ isCardLoader: true, errorMsg: '' })
        if (!cardDetails?.token) {
            this.setState({ isCardLoader: false, errorMsg: 'Something went wrong!' })
            return
        }
        const { isSaveCard } = this.state
        if (isSaveCard) this.addCardApi(cardDetails.token)
        else this.props.handlePayment(cardDetails.token)
    }

    addCardApi = async (cardSourceId: string) => {
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addCardApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addCardApiEndPoint}${cardSourceId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (this.addCardApiCallId === apiRequestCallId) {
            if (!responseJson.success) {
                this.setState({
                    isCardLoader: false,
                    errorMsg: responseJson?.message || "Something went wrong!",
                })
                return
            }
            const { onCardAdd, handleClose } = this.props
            setTimeout(() => {
                this.setState({ isCardLoader: false })
                toast.success("Card added successfully")
                onCardAdd()
                handleClose()
            }, 4000)
        }
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(isTokenExpireResponse(responseJson)) return

                if (this.addCardApiCallId === apiRequestCallId) {
                    this.setState({
                        isCardLoader: false,
                        errorMsg: responseJson?.message || "Something went wrong!",
                    })
                }
            }
        }
        // Customizable Area End
    }
}
