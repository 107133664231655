import React from "react"
import "../../../web/src/assets/css/style.scss"
import OrderTrackingDialogController, {
    Props,
} from "./OrderTrackingDialogController.web"
import "../assets/css/orderDetailsDialog.scss"
import "../assets/css/orderTrackingDialog.scss"
import {
    Box,
    Dialog,
    Typography,
    CircularProgress,
    Button,
    DialogContent,
} from "@material-ui/core"
import OrderTrackingStepper from "./OrderTrackingStepper.web"
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web"
import CustomDialogActions from "../../../components/src/CustomDialogActions.web"
import OrderCancelRequestDialog from "../../ordermanagement/src/OrderCancelRequestDialog.web"
import Alert from "@material-ui/lab/Alert"
import Skeleton from "react-loading-skeleton"
const configJSON = require("./config.js")

const CANCEL_ORDER_TITLES = [configJSON.cancelOrderTitle1, configJSON.cancelOrderTitle2, configJSON.cancelOrderTitle3]

class OrderTrackingDialog extends OrderTrackingDialogController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { isOpen, handleClose } = this.props
        const {
          trackingDetailsLoader,
          currentStep,
          isCancelDialog,
          isCreatingCancelRequest,
          cancelOrderMessage,
          isGettingCancellationStatus
        } = this.state
        const cancellationStatus = this.getCancellationStatus()
        const isCancelled = cancellationStatus === "Cancelled" || cancellationStatus === "Cancelled by owner"

        return (
          <>
            <Dialog
              open={isOpen}
              onClose={() => handleClose()}
              fullWidth
              maxWidth="sm"
              classes={{
                paper: "custom_roundish_dialog_paper",
              }}
            >
              <CustomDialogTitle onClose={handleClose}>
                <Box className="forgot_PW_tab_container">
                  <Typography variant="subtitle1" className="od_main_heading">
                    Order tracking
                  </Typography>
                </Box>
              </CustomDialogTitle>
              <DialogContent>
                {trackingDetailsLoader ? (
                  <Box className="od_loader_container">
                    <CircularProgress className="od_loader" />
                  </Box>
                ) : (
                    <Box>
                      {
                        isGettingCancellationStatus ? (
                          <Box display="flex" justifyContent="flex-end">
                            <Skeleton width="230px" height="16px" borderRadius="8px" />
                          </Box>
                        ) : (
                            <>
                              <Box className="od_cancel_order_wrapper">
                                {cancellationStatus ? (
                                  <div>
                                    <Typography className="od_status">
                                      {configJSON.cancellationStatusText} {cancellationStatus}
                                    </Typography>
                                    {isCancelled && (
                                      <Typography className="od_status">
                                        {configJSON.refundStatusText} {this.getRefundStatus()}
                                      </Typography>
                                    )}
                                  </div>
                                ) : (
                                  <button disabled={isCreatingCancelRequest} onClick={this.openCancelDialog}>
                                    {configJSON.cancelOrderText}
                                  </button>
                                )}
                              </Box>
                              {cancelOrderMessage && <Box mt={2}>
                                <Alert severity="success">
                                  {configJSON.cancellationRequestCreatedMessage}
                                </Alert>
                              </Box>}
                            </>
                        )
                      }
                    <OrderTrackingStepper isDisabled={isCancelled} currentStep={currentStep} />
                  </Box>
                )}
              </DialogContent>
              <CustomDialogActions>
                <Box width="100%">
                  <Button
                    classes={{
                      root: "ot_main_button",
                    }}
                    onClick={() => handleClose()}
                  >
                    OK
                  </Button>
                </Box>
              </CustomDialogActions>
            </Dialog>
            <OrderCancelRequestDialog
              isOpen={isCancelDialog}
              titleList={CANCEL_ORDER_TITLES}
              isButtonLoading={isCreatingCancelRequest}
              buttonTitle={configJSON.confirmCancellationText}
              handleClose={this.closeCancelDialog}
              handleButtonClick={this.handleCancelOrder}
            />
          </>
        )
    }
}

export default OrderTrackingDialog
