export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const veggies = require("../assets/images/veggies.png");
export const trash =  require("../assets/images/trash.png");
export const edit =  require("../assets/images/edit.png");
export const downloadInvoiceIcon = require("../assets/images/download-invoice.png")
export const PlacedIcon = require("../assets/images/placed-icon.svg")
export const ConfirmedIcon = require("../assets/images/confirmed-icon.svg")
export const ProcessedIcon = require("../assets/images/processed-icon.svg")
export const DeliveredIcon = require("../assets/images/delivered-icon.png")
export const WTTLogo = require("../assets/images/logo.png")

export const PoppinsRegularFont = require("../assets/fonts/Poppins-Regular.ttf");
export const PoppinsSemiBoldFont = require("../assets/fonts/Poppins-SemiBold.ttf");
export const PoppinsMediumFont = require("../assets/fonts/Poppins-Medium.ttf");

export const {
    getAddressListAction,
    addAddressAction,
    updateAddressAction,
    deleteAddressAction,
    setEditAddressErrors,
    setAddAddressErrors
  } = require("../../../components/src/redux/Users/userActions")
  