Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notifications";
exports.labelBodyText = "Notifications Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetMethod = "GET"
exports.nowText = "Now";
exports.moText = "mo"
exports.wText = "w"
exports.dText = "d"
exports.hText = "h"
exports.minText = "min"
exports.AUTH_TOKEN = "authToken"
exports.notificationsText = "Notifications"
exports.noNotificationsText = "No Notifications"
exports.noNotificationFoundText = "No notification found."
exports.notificationPopoverId = "notification-popover"
exports.notificationBadgeApiEndPoint = "bx_block_notifications/notifications/get_unseen_notifications"
exports.notificationsApiEndPoint = "bx_block_notifications/notifications"

exports.notificationPopoverAnchor = {
  vertical: "bottom",
  horizontal: "center",
}
exports.notificationPopoverTransform = {
  vertical: "top",
  horizontal: "center",
}
// Customizable Area End