// Customizable Area Start
import React from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import SearchCoursesController from "./SearchCoursesController.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import SearchResultFilters from "../SearchResultFilters.web"
import { ISearchCourse } from "../../interfaces/search"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import CustomHeading from "../../../../info-page/src/Farm/FarmHeadingWithFilterInput.web"
import SearchCourseCard from "../SearchCourseCard.web"
import SearchCustomLayout from "../SearchCustomLayout.web"
import { styles } from "../commonStyles"
const configJSON = require("../../config.js")

export class SearchCourses extends SearchCoursesController {
  render() {
    const {
      loader,
      categoriesLoader,
      priceRangeLoader,
      categories,
      minPrice,
      maxPrice,
      courses,
      totalPages,
    } = this.state
    const isFiltersLoading = categoriesLoader || priceRangeLoader

    return (
      <>
        <Container>
          <Box style={styles.mainWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <SearchResultFilters
                  categoryTitle={configJSON.relatedCategoriesText}
                  categories={categories}
                  isToShowPriceFilter={true}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  isToShowNearByFilter={false}
                  nearByText=""
                  tags={[]}
                  isLoading={isFiltersLoading}
                />
              </Grid>
              <Grid item xs={12} md={9} xl={8}>
                <CustomHeading heading={configJSON.coursesText} />
                <SearchCustomLayout
                  loader={loader}
                  totalPages={totalPages}
                  listLength={courses.length}
                  emptyMessage={configJSON.noCoursesFoundText}
                >
                  <Grid container spacing={2}>
                    {courses.map((course: ISearchCourse) => {
                      const {
                        id,
                        attributes: {
                          title,
                          banner_image: image,
                          first_name,
                          last_name,
                        },
                      } = course
                      const imageUrl = image && image.url ? image.url : ""
                      const authorName = `${first_name} ${last_name}`
                      const redirectUrl = `${AppRoutings.CourseDetails}${id}`

                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                          <SearchCourseCard
                            image={imageUrl}
                            courseName={title}
                            author={authorName}
                            redirectUrl={redirectUrl}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </SearchCustomLayout>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withRouter(SearchCourses)
// Customizable Area End
