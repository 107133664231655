import * as React from 'react';
// import { styled } from '@mui/material/styles';
import TagFacesIcon from "@material-ui/icons/TagFaces";

// css
import "../../web/src/assets/css/content/chiparray.scss";

import {
  Chip,
  List,
  ListItem,
} from "@material-ui/core";

const CustomChipArray = () => {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Agriculture' },
    { key: 1, label: 'Soil' },
    { key: 2, label: 'Nutrition' },
    // { key: 3, label: 'React' },
    { key: 4, label: 'Carbon' },
    { key: 5, label: 'Enviornment' },
    { key: 6, label: 'Farming' },
  ]);
  const handleDelete = (chipToDelete: any) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };
  return (
    <List className="customChipArrayList">
      {chipData.map((data) => {
        let icon;

        if (data.label === 'React') {
          icon = <TagFacesIcon />;
        }

        return (
          <ListItem key={data.key}>
            <Chip
              // icon={icon}
              label={data.label}
              onDelete={data.label === 'React' ? undefined : handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
export default CustomChipArray
