// Customizable Area Start
import React from "react"
import "../../../../web/src/assets/css/style.scss"
import "../../assets/css/orderDetailsDialog.scss"
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core"
import CustomDialogTitle from "../../../../components/src/CustomDialogTitle.web"
import { formatPhoneNumber } from "../../../../components/src/utility/helper"
import { downloadInvoiceIcon } from "../assets"
import { PDFDownloadLink } from "@react-pdf/renderer"
import InvoicePdfTemplate from "./InvoicePdfTemplate.web"
const configJSON = require('../config.js')

const NON_DELIVER_ORDER_TYPES = ["restaurant", "course"]

interface IProps {
  type: 'farm' | 'restaurant' | 'course'
  orderDetailsLoader: boolean
  orderDate: string
  orderId: string
  orderTotal: string
  paymentMethod: string
  price: string
  fullName: string
  billingAddress: string
  number: string
  sFullName: string
  sAddressLine1: string
  sAddressLine2: string
  sCity: string
  sState: string
  sCountry: string
  items: string
  discount: number
  loyaltyPoints: number
  subTotal: string
  shipping: number
  transactionFees: number
  total: string
  handleClose: () => void
}

const OrderDetailsDialogContent = (props: IProps) => {

  const {
    type,
    orderDetailsLoader,
    orderDate,
    orderId,
    orderTotal,
    paymentMethod,
    price,
    fullName,
    billingAddress,
    number,
    sFullName,
    sAddressLine1,
    sAddressLine2,
    sCity,
    sState,
    sCountry,
    items,
    discount,
    loyaltyPoints,
    subTotal,
    shipping,
    transactionFees,
    total,
  } = props

  const isNonDeliverInvoice = NON_DELIVER_ORDER_TYPES.includes(type)

  return (
    <>
      <CustomDialogTitle onClose={props.handleClose}>
        <Box className="forgot_PW_tab_container">
          <Typography variant="subtitle1" className="od_main_heading">
            Order details
          </Typography>
        </Box>
      </CustomDialogTitle>
      <DialogContent className="od_dialog_main_wrapper">
        <Grid container spacing={2}>
          {orderDetailsLoader ? (
            <Box className="od_loader_container">
              <CircularProgress className="od_loader" />
            </Box>
          ) : (
            <Box>
              <Grid item xs={12} className="od_subheading">
                <Typography>View order details</Typography>
              </Grid>
              <Grid container item xs={12} className="od_container">
                <Grid container item xs={12} className="od_details_container">
                  <Grid item xs={12} sm={8}>
                    <Typography className="od_light_paragraph">
                      Order date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className="od_light_dark_paragraph">
                      {orderDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography className="od_light_paragraph">Order ID</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className="od_light_dark_paragraph">
                      {orderId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography className="od_light_paragraph">
                      Order total
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className="od_light_dark_paragraph">
                      {orderTotal}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PDFDownloadLink
                    document={
                      <InvoicePdfTemplate
                        {...props}
                        isNonDeliverInvoice={isNonDeliverInvoice}
                      />
                    }
                    fileName={configJSON.InvoiceFileName}
                  >
                    {() => (
                      <Button
                        classes={{
                          root: "od_download_invoice",
                        }}
                      >
                        <Typography className="od_dark_paragraph">
                          Download Invoice
                        </Typography>
                        <img src={downloadInvoiceIcon} alt="download-icon" />
                      </Button>
                    )}
                  </PDFDownloadLink>
                </Grid>
              </Grid>

              <Grid item xs={12} className="od_subheading">
                <Typography>Payment information</Typography>
              </Grid>
              <Grid container item xs={12} className="od_container">
                <Grid container item xs={12} className="od_details_container">
                  <Grid item xs={12}>
                    <Typography className="od_dark_paragraph">
                      Payment method
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="od_light_dark_paragraph">
                      {paymentMethod}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="od_light_dark_paragraph">
                    <Typography className="od_light_dark_paragraph">
                      {price}
                    </Typography>
                  </Grid>
                </Grid>
                {!isNonDeliverInvoice && (
                  <Grid
                    container
                    item
                    xs={12}
                    className="od_details_container od_border_top"
                  >
                    <Grid item xs={12} className="od_dark_paragraph">
                      <Typography className="od_dark_paragraph">
                        Billing address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="od_light_dark_paragraph">
                      <Typography className="od_light_dark_paragraph">
                        {fullName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="od_light_dark_paragraph">
                      <Typography className="od_light_dark_paragraph">
                        {billingAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="od_light_dark_paragraph">
                      <Typography className="od_light_dark_paragraph">
                        {formatPhoneNumber(number)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {!isNonDeliverInvoice && (
                <>
                  <Grid item xs={12} className="od_subheading">
                    <Typography>Shipping address</Typography>
                  </Grid>
                  <Grid container item xs={12} className="od_container">
                    <Grid container item xs={12} className="od_details_container">
                      <Grid item xs={12}>
                        <Typography className="od_light_dark_paragraph">
                          {sFullName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="od_light_dark_paragraph">
                        <Typography className="od_light_dark_paragraph">
                          {sAddressLine1},{sAddressLine2}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="od_light_dark_paragraph">
                        <Typography className="od_light_dark_paragraph">
                          {sCity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="od_light_dark_paragraph">
                        <Typography className="od_light_dark_paragraph">
                          {sState}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="od_light_dark_paragraph">
                        <Typography className="od_light_dark_paragraph">
                          {sCountry}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={12} className="od_subheading">
                <Typography>Order summary</Typography>
              </Grid>
              <Grid container item xs={12} className="od_container">
                <Grid container item xs={12} className="od_details_container">
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Items Price
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      {items} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      -{discount} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Loyalty points
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      -{loyaltyPoints} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Sub total
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      {subTotal} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Transaction fees
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      {transactionFees} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_light_dark_paragraph">
                      Shipping
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_light_dark_paragraph">
                      {shipping} $
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography className="od_total_paragraph">
                      Order total
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography align="right" className="od_total_paragraph">
                      {total} $
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </DialogContent>
    </>
  )
}

export default OrderDetailsDialogContent
// Customizable Area End
