// Customizable Area Start
import React, { useState } from "react"
import {
    Grid,
    Box,
    Typography,
    Button,
    DialogTitle,
    DialogContent,
    IconButton,
} from "@material-ui/core"
import { Logo } from "../../../../components/src/assets"
import GoogleButton from "../../../../components/src/Social_buttons/GoogleButton.web"
import FacebookButton from "../../../../components/src/Social_buttons/FacebookButton.web"
import OnlineEmailAccountRegister from "../../../email-account-registration/src/OnlineEmailAccountRegistration.web"
import OnlineEmailAccountLogin from "../../../email-account-login/src/OnlineEmailAccountLogin.web"
import { Link } from "react-router-dom"
import clsx from "clsx"
import CloseIcon from "@material-ui/icons/CloseRounded"
import {
    WithStyles,
    createStyles,
    withStyles,
    Theme,
} from "@material-ui/core/styles"
import InstagramLoginRegistration from "../../../social-media-account/src/InstagramLoginRegistration.web"

interface ILoginSignUpTabsProps {
  isForgotPassword?: boolean
  onClose: () => void
  onLoginSuccess?: () => void
  openForgotPasswordScreen: () => void
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      position: 'sticky',
      left: 0,
      top: 0,
      right: 0,
      backgroundColor: '#fff',
      zIndex: 1,
      borderBottom: '1px solid #20a258'
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2.5),
      top: theme.spacing(3),
      bottom: theme.spacing(2.5),
      color: theme.palette.grey[500],
      "& svg": {
        color: "#000",
      },
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode
  onClose: () => void
}

const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography align="center" variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const CustomDialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
}))(DialogContent)

const LoginSignUpTabs = ({
  isForgotPassword,
  onClose,
  onLoginSuccess,
  openForgotPasswordScreen,
}: ILoginSignUpTabsProps) => {
  const [activeTab, setActiveTab] = useState<string>("login")

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        <Box className="logo_wrapper_heading">
          <img src={Logo} alt="logo" />
        </Box>
      </CustomDialogTitle>
      <CustomDialogContent>
      <Box className="login_layout_wrapper">
          <Grid container>
            <Grid item xs={6}>
              <Box
                className="login_tab_container"
                onClick={() => handleTabChange("login")}
              >
                <Typography
                  variant="subtitle1"
                  className={clsx("tab adjust_tab", {
                    ["active_tab"]: activeTab === "login",
                  })}
                >
                  Login
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="login_tab_container"
                onClick={() => handleTabChange("register")}
              >
                <Typography
                  variant="subtitle1"
                  className={clsx("tab", {
                    ["active_tab"]: activeTab === "register",
                  })}
                >
                  Create Account
                </Typography>
              </Box>
            </Grid>
            {activeTab === "login" ? (
              <OnlineEmailAccountLogin
                close={() => {
                  if (onLoginSuccess) onLoginSuccess()
                  onClose()
                }}
              >
                {isForgotPassword ? (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="forgotPassword_wrapper"
                  >
                    <Button onClick={openForgotPasswordScreen}>
                      Forgot Password?
                    </Button>
                  </Box>
                ) : null}
              </OnlineEmailAccountLogin>
            ) : (
              <OnlineEmailAccountRegister />
            )}
            <Grid item xs={12}>
              <Box mt="25px" mb="5px">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <GoogleButton close={onClose} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {
                      //@ts-ignore
                      <FacebookButton close={onClose}/>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <InstagramLoginRegistration handleClose={onClose} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Typography className="terms_of_condition">
              By clicking create account or login with google, facebook or
              instagram, you agree to WTT{" "}
              <Link
                to={{
                  pathname: `/policy&info/1`,
                }}
              >
                {" "}
                <span onClick={onClose}>Terms of user </span>
              </Link>
              and{" "}
              <Link
                className="green_text"
                to={{
                  pathname: `/policy&info/0`,
                }}
              >
                <span onClick={onClose}>Privacy Policy</span>
              </Link>
              .
            </Typography>
          </Grid>
      </Box>
      </CustomDialogContent>
    </>
  )
}

export default LoginSignUpTabs
// Customizable Area End
