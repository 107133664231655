// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import CommunityDiscussionDetailsController, {
    Props,
    IComment,
} from "./CommunityDiscussionDetailsController.web"
import { connect } from "react-redux"
import {
    Box,
    Button,
    CircularProgress,
    TextareaAutosize,
    Typography,
} from "@material-ui/core"
import CommunityNavigator from "../CommunityCustomComponents/CommunityNavigator.web"
import "../../assets/css/community/communityDiscussionDetails.scss"
import CommentWeb from "../../farmForum/comment.web"

class CommunityDiscussionDetails extends CommunityDiscussionDetailsController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            loader,
            addCommentLoader,
            commentText,
            discussionDetails: {
                attributes: { discussion_heading, description, forum_comments },
            },
        } = this.state

        return (
            <Box>
                {loader ? (
                    <Box className="cdd_loader_container">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <CommunityNavigator navigationText={discussion_heading} />
                        <Box className="cdd_content_container">
                            <Typography>{description}</Typography>

                            <Box className="cdd_separator" />

                            <Box className="cdd_comments_wrapper">
                                {forum_comments?.data.length > 0 ?
                                    forum_comments.data.map((commentDetails: IComment) => {
                                        const {
                                            id,
                                            attributes: { account_id, full_name, date, comment, image, replies, report_spam },
                                        } = commentDetails

                                        return (
                                            <CommentWeb
                                                key={id}
                                                isCommunity
                                                accountId={account_id}
                                                reviewId={id}
                                                comment={comment}
                                                fullName={full_name}
                                                reviewDate={date}
                                                profileImage={image?.url}
                                                isSpamReported={report_spam}
                                                replies={replies?.data || []}
                                                handleAddNewReview={this.handleAddNewReview}
                                                handleCommentRemove={this.handleRemoveComment}
                                                handleCommentEdit={this.handleUpdateComment}
                                                handleReviewEdit={this.handleEditReply}
                                                handleReviewsRemove={this.removeReply}
                                            />
                                        )
                                    })
                                    :
                                    <Box height="30vh" className="cdl_empty_list_container">
                                        <Typography>
                                            No comments have been added for this discussion
                                        </Typography>
                                    </Box>
                                }
                            </Box>

                            <Box className="cdd_add_comment_wrapper">
                                <TextareaAutosize
                                    minRows={3}
                                    placeholder="Write your view here..."
                                    disabled={addCommentLoader}
                                    value={commentText}
                                    onChange={this.handleCommentTextChange}
                                />
                                <Box className="farm_forum_comment_textarea_btn_wrapper">
                                    <Button
                                        classes={{
                                            disabled: "reply_btn_disabled",
                                        }}
                                        disabled={addCommentLoader || !commentText}
                                        onClick={this.addDiscussionCommentApi}
                                    >
                                        {addCommentLoader ? (
                                            <CircularProgress size={18} color="inherit" />
                                        ) : (
                                            "Send"
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

export default withRouter(
    connect(mapStateToProps, {})(CommunityDiscussionDetails)
)
// Customizable Area End
