import React from "react"
import {
  Grid,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core"
import {
  green_leafy_veggies,
  DeleteWhiteIcon,
  removeCartItem,
  updateItemQuantity,
  setCartBadge,
} from "../assets"
import "../../assets/css/shoppingcartcard.scss"
import WebCartNumberInput from "../../../../components/src/webCartNumberInput.web"
import { connect } from "react-redux"
import CustomActionDialog from "../../../../components/src/CustomDialog.web"
import ShoppingCartCardController, {
  Props,
} from "./ShoppingCartCardController.web"
import { withRouter } from "react-router-dom"
import clsx from "clsx"
import { LESS_QUANTITY_NUMBER } from "../../../../components/src/utility/constants"
import { IProductVariant } from "../../../../components/src/interfaces/shoppingCart"
const configJSON = require("../config.js")

export class ShoppingCartCard extends ShoppingCartCardController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { isRemoving, removeItemDialog } =
      this.state
    const {
      name,
      image,
      price,
      quantity,
      addOns,
      isTrackQuantity,
      availableQuantity,
      cookingInstruction,
      isAcceptingOrder,
      isLearningOrder,
      isProductActive,
      selectedVariantId,
      variantDescription,
      variants
    } = this.props
    const isExtraInOrder = addOns || cookingInstruction
    const isOutOfStock = isTrackQuantity && availableQuantity === 0
    const isLessQuantity = isTrackQuantity && availableQuantity < LESS_QUANTITY_NUMBER
    const isCounterDisabled = !isAcceptingOrder || isOutOfStock || !isProductActive
    const quantityErrorMessage = this.productQuantityError()

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <img
                src={image?.url || image || green_leafy_veggies}
                className="cart_product_image"
                alt="product_image"
                width="100%"
                height="100%"
              />
            </Box>
          </Grid>
          <Grid item container xs={12} sm={8}>
            <Grid item xs={12}>
              <Box className="shopping_cart_details_wrapper">
                <Box className="shopping_cart_card_header">
                  <h4 className="shopping_cart_product_name">{name}</h4>
                  <IconButton size="small" onClick={this.openRemoveDialog}>
                    <img src={DeleteWhiteIcon} alt="delete_icon" />
                  </IconButton>
                </Box>
                {isExtraInOrder && (
                  <Box>
                    {addOns && (
                      <p className="shopping_cart_card_paragraph">{`Extra: ${addOns}`}</p>
                    )}
                    {cookingInstruction && (
                      <>
                        <p className="shopping_cart_card_instruction">
                          Cooking Instruction:
                        </p>
                        <p className="shopping_cart_card_paragraph">
                          {cookingInstruction}
                        </p>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="shopping_cart_price_wrapper">
                <Box className="cart_price">
                  <span>${this.getPrice()}</span>
                  {(!selectedVariantId && this.isDiscountedPrice()) && <del>${price}</del>}
                </Box>
                {!isLearningOrder && !isOutOfStock && (
                  <>
                  <WebCartNumberInput
                    count={quantity}
                    isDisable={isCounterDisabled}
                    addCount={this.increaseQuantity}
                    minusCount={this.decreaseQuantity}
                  />
                  {(variants?.length > 0 && variantDescription) && <Box className="cart_variant_wrapper">
                    <h6>{variantDescription}: </h6>
                      <Box>
                        {variants.map((variant: IProductVariant) => {
                          const {
                            id,
                            attributes: { option_name },
                          } = variant

                          return (
                            <button
                              key={id}
                              className={clsx("cart_variant_btn", {
                                ["cart_selected_variant"]: String(selectedVariantId) === id,
                              })}
                              onClick={() => this.handleVariantSelection(id)}
                            >
                              {option_name}
                            </button>
                          )
                        })}
                      </Box>
                  </Box>}
                  </>
                )}

                {!isProductActive ? (
                  <Typography className="product_stock_info">
                    {configJSON.activeStateErrorText}
                  </Typography>
                ) : (
                  <>
                    {!quantityErrorMessage && isLessQuantity && (
                      <Typography className="product_less_stock_info">
                        Hurry, Only few left!
                      </Typography>
                    )}

                    {quantityErrorMessage && (
                      <Typography className="product_stock_info">
                        {quantityErrorMessage}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <CustomActionDialog
          open={removeItemDialog}
          title="Are you sure want to remove the item?"
          actionBtnTitle="Yes"
          closeBtnTitle="No"
          isLoading={isRemoving}
          handleAction={this.removeItem}
          handleClose={this.closeRemoveDialog}
        />
      </>
    )
  }
}

export default withRouter(
  connect(null, { removeCartItem, updateItemQuantity, setCartBadge })(
    ShoppingCartCard
  )
) as any
