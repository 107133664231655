import React from "react";
import { IBlock } from "./../../framework/src/IBlock";
import { Message } from "./../../framework/src/Message";
import { BlockComponent } from "./../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "./../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "./../../framework/src/StorageProvider.web";
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config.js");
import * as Yup from "yup";
import { AppRoutings } from "./utility/app-routing";
// Customizable Area End



export interface Props extends RouteComponentProps {
  navigation?: any;
  id?: string;
  sidebarToggle?: any;
  toggle?: any;
  displayProp?: string;
  setIsAuth?: any;
  isAuth?: boolean;
  // Customizable Area Start
  userInfo: any
  cartBadge: number
  isSidebarOpen?: boolean
  toggleSidebar: () => void
  updateActiveTab: (tabName: string) => void
  setCartBadge: (count: number) => void
  getCartItems: () => void
  resetUserDetails: () => void;
  history: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  resetPWSchema: any;
  errorMsg: string;
  loading: boolean;
  IsAdmin: string;
  openModal: boolean;
  closeModal: boolean;
  showTabs: boolean;
  openInnerModal: boolean;
  closeInnerModal: boolean;
  openSecondInnerModal: boolean;
  activeTab: string;
  showSearchInput: boolean;
  formType: string;
  roles: any;
  isLoginDialog: boolean
  menuAnchorEl: null | HTMLElement
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class WebHeaderController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiResetPWApiCallId: string = "";
  apigetRemainingRolesApiCallId: string = "";
  getContibuterdetailsId: string = "";
  searchAPIString: string= '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    let resetPWSchema = Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Invalid email address format")
        .required("Email is required"),
    });

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      IsAdmin: "",
      openModal: false,
      openInnerModal: false,
      closeInnerModal: false,
      closeModal: false,
      showTabs: true,
      showSearchInput: false,
      openSecondInnerModal: false,
      formType: "",
      resetPWSchema: resetPWSchema,
      activeTab: "login",
      roles: "",
      isLoginDialog: false,
      menuAnchorEl: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const Token = await StorageProvider.get("authToken");
    const admin = await StorageProvider.get("AdminAuth");
    this.getCartCount()

    if (admin != null) {
      this.setState({ IsAdmin: admin });
    }

    if (Token != null) {
      this.setState({ token: Token });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apigetRemainingRolesApiCallId){
          this.setState({
            roles:responseJson
          })
          this.errorHandle(responseJson)
        }
        if (apiRequestCallId === this.getContibuterdetailsId) {
          this.props.history.push(
            `/admin/learning/details/${responseJson.data.id}`
          );
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    const anchorEl = this.state.menuAnchorEl ? null : event.currentTarget
    this.setState({ menuAnchorEl: anchorEl })
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }

  isActive = (tab:string) => {
    return this.props.userInfo.activeTab === tab ? "active_route" : ""
  }

  toggleDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    this.props.toggleSidebar()
  }

  openLoginDialog = () => {
    this.setState({ isLoginDialog: true })
  }

  closeLoginDialog = () => {
    this.setState({ isLoginDialog: false })
  }

  getCartCount = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    const { setCartBadge } = this.props
    if(!isLoggedIn) {
      const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
      const ordersJSON = JSON.parse(orders)
      !!setCartBadge && setCartBadge(ordersJSON.length)
      await StorageProvider.set(configJSON.CART_BADGE_KEY, ordersJSON.length)
    } else {
      const cartBadge = await StorageProvider.get("cartBadge") || "0"
      const cartCount = JSON.parse(cartBadge)
      !!setCartBadge && setCartBadge(cartCount)
    }
  }

  handleUserLogout = async () => {
    const { history, setCartBadge, resetUserDetails } = this.props

    await StorageProvider.remove(configJSON.AUTH_TOKEN)
    await StorageProvider.remove(configJSON.FIRST_NAME_KEY)
    await StorageProvider.remove(configJSON.LAST_NAME_KEY)
    localStorage.removeItem(configJSON.PROFILE_KEY)
    localStorage.removeItem(configJSON.AUTH_TOKEN)
    localStorage.removeItem(configJSON.CART_ID_KEY)
    resetUserDetails()
    
    const cartOrders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJSON = JSON.parse(cartOrders)
    setCartBadge(ordersJSON.length)

    this.handleMenuClose()
    history.push(AppRoutings.Home)
  }

  redirectToFavourites = async () => {
    const isLoggedIn = await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(!isLoggedIn){
      this.setState({ isLoginDialog: true })
      return
    }
    this.props.history.push(AppRoutings.FavoritePage)
  }

  isFavPage = () => {
    return this.props.location.pathname === AppRoutings.FavoritePage
  }
  // Customizable Area End

  showModal = () => {
    this.setState({ openModal: true });
    // @ts-ignore
    this.props.setLoginModalTrue()
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  showInnerModal = () => {
    this.setState({ openInnerModal: true });
  }

  closeInnerModal = () => {
    this.setState({ openInnerModal: false });
  }

  showSecondInnerModal() {
    this.setState({ openSecondInnerModal: true });
  }

  closeSecondInnerModal() {
    this.setState({ openSecondInnerModal: false });
  }

  hideTabs = () => {
    this.setState({
      showTabs: !this.state.showTabs,
      showSearchInput: !this.state.showSearchInput,
    });
  };

  registerTab() {
    this.setState({ activeTab: "register" });
  }
  loginTab() {
    this.setState({ activeTab: "login" });
  }

  closeFunc() {
    // @ts-ignore
    // this.props.setLoginModalFalse()
  }

  doResetPassword = async (values: { email: string }): Promise<boolean> => {
    this.setState({
      formType: "resetPassword",
    });

    const header = {
      "Content-Type": configJSON.restPWApiContentType,
    };

    const httpBody = {
      email: values.email.trim() ? values.email.trim() : "",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPWApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPWAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetPWAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRemainingRoles =async()=> {
    const token = await StorageProvider.get("authToken")
    const header = {
      "token":token,
      "Content-Type": configJSON.restPWApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetRemainingRolesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRemainingRolesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContibuterdetails = async () => {
    const token = await StorageProvider.get("authToken");

    const header = {
      "Content-Type": configJSON.restPWApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterdetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setActive = (tabName: string) => {
    !!this.props.updateActiveTab && this.props.updateActiveTab(tabName)
  }
  routeToAdmin=()=>{
    let roles=this.state.roles?.data;
    if(roles.length>0){
      let roleID= roles[0].attributes.role.id;
      if(roleID==1){
        this.props.history.push("/admin/farm/orders/00")
      }
      if(roleID==2){
        this.props.history.push("/admin/restaurant/orders")
      }
      if(roleID==3){
        this.getContibuterdetails()
      }
    }
      else{
      this.props.history.push("/admin/farm/orders/00")
    }
  }
  errorHandle=(responseJson:any)=>{
    if(!responseJson.errors){
      this.routeToAdmin()
    }else{
      this.handleUserLogout()
    }
  }

}
