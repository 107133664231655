// Customizable Area Start
import { AppRoutings } from './app-routing';
import StorageProvider from "../../../framework/src/StorageProvider"
//@ts-ignore
import store from "../redux/store.js";
//@ts-ignore
import history from "./useHistory.js";
const configJSON = require("../config.js")

const useLogout = async () => {
  await StorageProvider.remove(configJSON.AUTH_TOKEN)
  await StorageProvider.remove(configJSON.FIRST_NAME_KEY)
  await StorageProvider.remove(configJSON.LAST_NAME_KEY)
  localStorage.removeItem(configJSON.PROFILE_KEY)
  localStorage.removeItem(configJSON.AUTH_TOKEN)
  localStorage.removeItem(configJSON.CART_ID_KEY)

  const cartOrders =
    (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]"
  const ordersJSON = JSON.parse(cartOrders)
  store.dispatch({ type: "SET_CART_BADGE", payload: ordersJSON.length })
  store.dispatch({ type: 'RESET_USER_DETAILS' })
  store.dispatch({ type: 'RESET_FARMS_FILTERS' })
  store.dispatch({ type: 'RESET_FARM_PRODUCT_FILTERS' })
  store.dispatch({ type: 'RESET_LP_DATA' })
  history.push(AppRoutings.Home)
}

export default useLogout
// Customizable Area End
