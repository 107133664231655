// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../framework/src/Message"
import { runEngine } from "../../../framework/src/RunEngine"
import { toast } from "react-toastify"
import { IResolveParams } from "reactjs-social-login"
import StorageProvider from "../../../framework/src/StorageProvider.web"
const configJSON = require("./config.js")

interface IFarmOrder {
  uniqueId: string
  orderCategory: "BxBlockCatalogue::FarmCatalogue"
  cartableId: string
  quantity: number
  productImage: string
  name: string
  description: string
  price: string
  comparePrice: string
  selectedVariantId: string
  variant_options: {
    id: string;
    type: string;
    attributes: {
      price: string;
      option_name: string;
      is_active: boolean;
    }
  }[]
  variant_description: string
  sellerId: string
  sellerName: string
  sellerImage: string
}

interface IRestaurantOrder {
  uniqueId: string
  orderCategory: "BxBlockCatalogue::Menu"
  cartableId: string
  quantity: number
  description: string
  productImage: string
  name: string
  price: string
  comparePrice: number
  cookingInstruction: string
  extraThings: { id: string }[]
  addOnsPrice: string
  sellerId: string
  sellerName: string
  sellerImage: string
  deliveryTime: string
}

interface IAccount {
  id: number
  first_name: string
  last_name: string
  email: string
  activated: boolean
  device_id: string | null
  customer_number: string
  is_admin: boolean | null
  educator_detail: string | null
  profile_pic: string | null
}

interface ICart {
  id: number
  account_id: number
  coupon_id: null | string
  created_at: string
  updated_at: string
  quantity: string
  total_mrp: string
}

interface ILoginResponse {
  meta: {
    account: IAccount
    token: string
    cart: ICart
    is_new_user: boolean
  }
}

interface IHttpBody {
  data: {
    type: string
    attributes: {
      access_token: string
      first_name: string
      last_name: string
    }
  }
}

interface Props {
  handleClose: () => void
  getUserDetails: (account: IAccount) => void
  setUserCartId: (cartId: number) => void
  getCartItems: () => void
}

interface S {
  isLoading: boolean
}

interface SS {
  id: any
}

export default class InstagramLoginRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  instagramLoginRegistrationApiCallId: string = ""
  bulkAddToCartApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      isLoading: false,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  getOrdersFromStorage = async () => {
    return await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
  }

  onSuccess = ({ data }: IResolveParams) => {
    if(data) {
      const httpBody = {
        data: {
          type: "instagram",
          attributes: {
            access_token: data.accessToken,
            first_name: "",
            last_name: "",
          },
        },
      }
      this.instagramLoginRegistrationApi(httpBody)
    }
  }

  onError = (error: unknown) => {
    typeof error === "string" && toast.error(error)
  }

  instagramLoginRegistrationApi = async (httpBody: IHttpBody) => {
    this.setState({ isLoading: true })

    const headers = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.instagramLoginRegistrationApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.instagramLoginRegistrationApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  bulkAddToCartApi = async (orders: unknown, token: string) => {
    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.bulkAddToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkAddToCartApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(orders)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  uploadLocalCartItems = async (token: string) => {
    const orders = await this.getOrdersFromStorage()
    const ordersJSON = JSON.parse(orders)

    if(ordersJSON.length > 0) {
      const orderType = ordersJSON[0].orderCategory
      const apiOrders = ordersJSON.map((order: IFarmOrder | IRestaurantOrder) => {
        const { cartableId, quantity } = order
        if(order.orderCategory === configJSON.CARTABLE_TYPE_FARM) {
          const { selectedVariantId } = order as IFarmOrder
          return {
            cartable_id: cartableId,
            selected_variant_option_id: selectedVariantId,
            quantity,
          }
        } else {
          const { cookingInstruction, extraThings } = order as IRestaurantOrder
          const addOnsIds = extraThings.map((addon: { id: string }) => addon.id)
          return {
            cartable_id: cartableId,
            addon_ids: addOnsIds,
            quantity,
            special_cooking_instruction: cookingInstruction,
          }
        }
      })
      await this.bulkAddToCartApi({
        cartable_type: orderType,
        cartable_params: apiOrders
      }, token)
    }
  }

  handleApiSuccessResponse = async (apiRequestCallId: string, responseJson: ILoginResponse) => {
    if (apiRequestCallId === this.instagramLoginRegistrationApiCallId) {
      if ("meta" in responseJson) {
        const { handleClose, getUserDetails, setUserCartId, getCartItems } = this.props
        const { account, cart, token } = responseJson.meta
        const { is_admin, first_name, last_name } = account

        await StorageProvider.set(configJSON.ADMIN_AUTH_KEY, is_admin)
        await StorageProvider.set(configJSON.TOKEN, token)
        await StorageProvider.set(configJSON.FIRST_NAME_KEY, first_name)
        await StorageProvider.set(configJSON.LAST_NAME_KEY, last_name)
        await StorageProvider.set(configJSON.AUTH_TOKEN, token)
        localStorage.setItem(configJSON.AUTH_TOKEN, token)
        localStorage.setItem(configJSON.PROFILE_KEY, JSON.stringify(account))
        localStorage.setItem(configJSON.CART_ID_KEY, JSON.stringify(cart.id))

        const orders = await this.getOrdersFromStorage()
        const orderLength = JSON.parse(orders).length

        if(orderLength > 0){
          await this.uploadLocalCartItems(token)
          getUserDetails(account)
          setUserCartId(cart.id)
        } else {
          getUserDetails(account)
          setUserCartId(cart.id)
          getCartItems()
          handleClose()
        }
      }
    }
  }

  handleApiErrorResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.instagramLoginRegistrationApiCallId) {
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (response && !response.errors) {
        this.handleApiSuccessResponse(apiRequestId, response)
      } else {
        this.handleApiErrorResponse(apiRequestId)
      }
    }
  }
}
// Customizable Area End
