// Customizable Area Start
import React from "react";
import SelectYourRoleController, {
  Props,
} from "./SelectYourRoleController.web";
import {
  DoneImg,
} from "./assets";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import "../assets/css/done.scss";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End

class RestaurantDonePage extends SelectYourRoleController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { rolesData } = this.state

    return (
      <Box className="done_container_wrapper">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img src={DoneImg} alt="done" width="250px" />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className="Farmars-Profile-cre">
              Restaurant’s Profile created Successfully
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {rolesData.length > 0 ? <Typography align="center" className="Now-you-can-go-to-da">
              Now you can go to dashboard or you<br />can add another roles.
            </Typography> :
              <Typography align="center" className="Now-you-can-go-to-da">
                Now you can go to dashboard
              </Typography>}
          </Grid>
          <Grid container spacing={4} item xs={12} justifyContent="center">
            {rolesData.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => this.handleAnotherRoleRedirect()}
                  className="Add-another-Role">
                  Add another Role
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => this.handleDashboardRedirect('restaurant')}
                className="Go-To-Dashboard ">
                Go To Dashboard
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
    // Customizable Area End
  }
}
export default withRouter(RestaurantDonePage);
