// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isTokenExpired} from "../../../components/src/utility/helper"
import * as Yup from "yup";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  classes?: any;
  logout:any
  
}

interface S {
  
  arrayHolder: any;
  token: string;
  loading: any;
  openDialog: any;
  contributorDetails: any;
  innerRef:any


  
}

interface SS {
  id: any;
}

export default class AdminLearningDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  
  getContributorDetailsApiCallId: string = "";
  postCreateContributerApiCallId: string = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      loading: false,
      openDialog: false,
      contributorDetails: "",
      innerRef:React.createRef()



    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {

    this.getContributorDetails()

  }


  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {

          this.successApiCall(responseJson, apiRequestCallId)
        }
      }
      if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        }
      }
    }

  }

  // success api call 
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    //get contributer details      
    if (apiRequestCallId === this.getContributorDetailsApiCallId) {
      this.settingInitialValue(responseJson.data)
      this.setState({
        contributorDetails: responseJson.data,
        loading: false
      })


    }
    //update contributer 
    if (apiRequestCallId === this.postCreateContributerApiCallId) {
      this.setState({
        contributorDetails: responseJson.data,
        loading: false
      })
      toast.success("Contributor Updated Successfully")


    }
  }

  getContributorDetails = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContributorDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint + `/${window.location.pathname.split("/")[4]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  createContributor = async (values: any) => {
    this.setState({
      loading: true
    })

    const token = await StorageProvider.get("authToken")

    const headers = {
      token:
        token
    };

     console.log(values,"values")
    const formdata = new FormData();
    formdata.append("educational_contributor[first_name]", values.firstName);
    formdata.append("educational_contributor[last_name]", values.lastName);
    formdata.append("educational_contributor[headline]", values.heading);
    formdata.append("educational_contributor[biography]", values.title);
    formdata.append("educational_contributor[language]", values.language);
    formdata.append("educational_contributor[youtube]", values.youTube);
    formdata.append("educational_contributor[youtube_username]", values.youTubeUsername);
    formdata.append("educational_contributor[twitter]", values.twitter);
    formdata.append("educational_contributor[twitter_username]", values.twitterUsername);
    formdata.append("educational_contributor[facebook]", values.facebook);
    formdata.append("educational_contributor[facebook_username]", values.facebookUsername);
    formdata.append("educational_contributor[linkedin]", values.linkedIn);
    formdata.append("educational_contributor[linkedin_username]", values.linkedInUsername);
    formdata.append("educational_contributor[website]", values.website);
    !values.image.url && values.image[0].name && formdata.append("educational_contributor[educator_image]", values.image[0]);




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateContributerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint + `/${window.location.pathname.split("/")[4]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  Schema = Yup.object().shape({
    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    heading: Yup.string().required("This field is required."),
    title: Yup.string().min(50, "Atleast 50 characters required"),
    image: Yup.mixed().test("fileSize", "Maximum 512kb required",
    (file :any) =>{
      if(file?.[0] && file[0]!="h"){
        return file[0]?.size <= 512000
      } return true}
    ).test("fileSize", "Minimum 20kb required",
     (file :any) =>{
        if(file?.[0] && file[0]!="h"){
          return file[0]?.size >= 20000
        }
           return true
      }).required("This field is required.")
  });

  settingImage = (values: any, ProfileImg: any) => {
    if (values?.image) {
      return (values?.image?.url && values?.image?.url[0]) !="h"
        ? (
          window.URL ||
          window.webkitURL
        )
          //@ts-ignore
          .createObjectURL(
            values
              .image[0]
          )
        : values.image.url
    } else {
      return ProfileImg
    }
  }

  settingInitialValue = (values:any) => {
    const details = values && values.attributes;
    const setValue= this.state.innerRef.current.setFieldValue
    setValue("firstName",details.first_name)
    setValue("lastName",details.last_name)
    setValue("heading",details.headline)
    setValue("title",details.biography)
    setValue("website",details.website)
    setValue("language",details.language)
    setValue("twitter",details.twitter)
    setValue("facebook",details.facebook)
    setValue("linkedIn",details.linkedin)
    setValue("youTube",details.youtube)
    setValue("image",details.educator_image)
   setValue("twitterUsername",details.twitter_username)
    setValue("facebookUsername",details.facebook_username)
    setValue("linkedInUsername",details.linkedin_username)
    setValue("youTubeUsername",details.youtube_username)
   
  }



}
// Customizable Area End
