import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import "../../../../ShoppingCart/assets/css/Shoppingcartpaymentmethod.scss";

class BitcoinPayment extends Component {
  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total item</p>
                <p>$400.000</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Discount</p>
                <p>-$50.000</p>
              </Box>
            </Box>
            <hr />
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>sub total</p>
                <p>$350.000</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Shipping</p>
                <p>$10.000</p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box className="cart_total_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total(2 items)</p>
                <p>$350.000</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default BitcoinPayment;
