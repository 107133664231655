// Customizable Area Start
import React from "react";
import AdminConsoleController, { Props } from "./AdminConsoleController.web";
import { Switch, withRouter } from "react-router-dom";
import { resetUserDetails, setCartBadge } from "./assets";
import { connect } from "react-redux";

import AdminHeader from "../../../components/src/AdminHeader.web";
import AdminSidebar from "../../../components/src/AdminSidebar.web";
import AdminDashboard from "./AdminDashboard.web";
import AdminProductList from "../../catalogue/src/AdminProductList.web";
import AdminProductListAddProduct from "../../catalogue/src/AdminProductListAddProduct.web";
import AdminCustomerList from "../../catalogue/src/AdminCustomerList.web";
import AdminAnalytics from "../../catalogue/src/AdminAnalytics.web";
import AdminRestaurantOrders from "../../catalogue/src/AdminRestaurantOrders.web";
import AdminRestaurantDishes from "../../catalogue/src/AdminRestaurantDishes.web";
import AdminRestaurantCreateDishes from "../../catalogue/src/AdminRestaurantCreateDishes.web";
import AdminRestaurantForums from "../../catalogue/src/AdminRestaurantForums.web";
import AdminRestaurantForumsEdit from "../../catalogue/src/AdminRestaurantForumsEdit.web";
import AdminRestaurantCampaigns from "../../catalogue/src/AdminRestaurantCampaigns.web";
import AdminRestaurantEditCampaign from "../../catalogue/src/AdminRestaurantEditCampaign.web";
import AdminRestaurantDetails from "../../catalogue/src/AdminRestaurantDetails.web";
import AdminRestaurantRegisterInfo from "../../catalogue/src/AdminRestaurantRegisterInfo.web";
import AdminOrders from "../../catalogue/src/AdminOrders.web";
import AdminFarmListing from "../../catalogue/src/AdminFarmListing.web";
import AdminFarmDetails from "../../catalogue/src/AdminFarmDetails.web";
import AdminFarmForums from "../../catalogue/src/AdminFarmForums.web";
import AdminFarmForumsEdit from "../../catalogue/src/AdminFarmForumsEdit.web";
import AdminFarmCampaigns from "../../catalogue/src/AdminFarmCampaigns.web";
import AdminFarmCampaignsEdit from "../../catalogue/src/AdminFarmCampaignsEdit.web";
import AdminLearningCourseList from "../../catalogue/src/AdminLearningCourseList.web";
import AdminLearningCreateCourseList from "../../catalogue/src/AdminLearningCreateCourseList.web";
import AdminLearningCoursesTasks from "../../catalogue/src/AdminLearningCoursesTasks.web";
import AdminLearningStudents from "../../catalogue/src/AdminLearningStudents.web";
import AdminLearningDiscussion from "../../catalogue/src/AdminLearningDiscussion.web";
import AdminLearningDetails from "../../catalogue/src/AdminLearningDetails.web";
import AdminLearningCampaigns from "../../catalogue/src/AdminLearningCampaigns.web";
import AdminLearningEditCampaign from "../../catalogue/src/AdminLearningEditCampaign.web";
import AdminProductWeightDimensions from "../../catalogue/src/AdminProductWeightDimensions.web";

// common css
import "../../../web/src/assets/css/style.scss";
import AdminRestaurantListingWeb from "../../catalogue/src/AdminRestaurantListing.web";
import AdminLearningPurchases from "../../catalogue/src/AdminLearningPurchases.web";

import AdminRegisterRestaurantWeb from "../../catalogue/src/AdminRegisterRestaurant.web";
import ProtectedRoute from "../../../components/src/ProtectedRoute/WebProtectedRoute.web";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import AdminAdCampaign from "../../LocalListingAds/src/AdminAdCampaignList.web";
import AdminAdCampaignDetails from "../../LocalListingAds/src/AdminAdCampaignDetails.web";
import AdminTransactionHistory from "../../catalogue/src/AdminTransactionHistory/AdminTransactionHistory.web";

class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { match }: any = this.props;

    return (
      <>
        <div className="mainWrapper">
          <AdminHeader
            /* @ts-ignore */
            sidebarToggle={this.sidebarToggle}
            logout={this.handleUserLogout}
          />
          <div className="contentWrapper">
            <AdminSidebar
              /* @ts-ignore */
              sidebarToggle={this.sidebarToggle}
              toggle={this.state.toggleSidebar}
              logout={this.handleUserLogout}
            />
            <div className="subContentWrapper">
              <Switch>
                <>
                  <ProtectedRoute
                    path={`${match.path}/dashboard`}
                    render={(routeProps: any) => (
                      <AdminDashboard
                        {...routeProps}
                        {...this.state}
                        // logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/products/:id`}
                    render={(routeProps: any) => (
                      <AdminProductList
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/weight-dimensions`}
                    render={(routeProps: any) => (
                      <AdminProductWeightDimensions
                        {...routeProps}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/add-product/:id`}
                    render={(routeProps: any) => (
                      <AdminProductListAddProduct
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/customers`}
                    render={(routeProps: any) => (
                      <AdminCustomerList
                        {...routeProps}
                        {...this.state}
                        // logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/analytics`}
                    render={(routeProps: any) => (
                      <AdminAnalytics
                        {...routeProps}
                        {...this.state}
                        // logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/orders/:id`}
                    render={(routeProps: any) => (
                      <AdminOrders
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />{" "}
                  <ProtectedRoute
                    path={`${match.path}/farm/details`}
                    render={(routeProps: any) => (
                      <AdminFarmListing
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/add-farm`}
                    render={(routeProps: any) => (
                      <AdminFarmDetails
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/forums/:id`}
                    render={(routeProps: any) => (
                      <AdminFarmForums
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/add-forum/:id`}
                    render={(routeProps: any) => (
                      <AdminFarmForumsEdit
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/campaigns`}
                    render={(routeProps: any) => (
                      <AdminFarmCampaigns
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/farm/add-campaigns`}
                    render={(routeProps: any) => (
                      <AdminFarmCampaignsEdit
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/orders`}
                    render={(routeProps: any) => (
                      <AdminRestaurantOrders
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    exact
                    path={`${match.path}/restaurant/dishes/:id`}
                    render={(routeProps: any) => (
                      <AdminRestaurantDishes
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path={`${match.path}/restaurant/dishes/:id/create-dish`}
                    render={(routeProps: any) => (
                      <AdminRestaurantCreateDishes
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/forums/:id`}
                    render={(routeProps: any) => (
                      <AdminRestaurantForums
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/add-forum/:id`}
                    render={(routeProps: any) => (
                      <AdminRestaurantForumsEdit
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />{" "}
                  <ProtectedRoute
                    path={`${match.path}/restaurant/campaigns`}
                    render={(routeProps: any) => (
                      <AdminRestaurantCampaigns
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    exact
                    path={`${match.path}/restaurant/add-campaigns`}
                    render={(routeProps: any) => (
                      <AdminRestaurantEditCampaign
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/details`}
                    render={(routeProps: any) => (
                      <AdminRestaurantListingWeb
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/details/edit`}
                    render={(routeProps: any) => (
                      <AdminRestaurantDetails
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/details/register-form`}
                    render={(routeProps: any) => (
                      <AdminRegisterRestaurantWeb
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/restaurant/register-Info`}
                    render={(routeProps: any) => (
                      <AdminRestaurantRegisterInfo
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/course-list/:id`}
                    render={(routeProps: any) => (
                      <AdminLearningCourseList
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/add-course`}
                    render={(routeProps: any) => (
                      <AdminLearningCreateCourseList
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/add-task`}
                    render={(routeProps: any) => (
                      <AdminLearningCoursesTasks
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/details/:id`}
                    render={(routeProps: any) => (
                      <AdminLearningDetails
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/students/:id/:id`}
                    render={(routeProps: any) => (
                      <AdminLearningStudents
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/purchases`}
                    render={(routeProps: any) => (
                      <AdminLearningPurchases
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/campaigns`}
                    render={(routeProps: any) => (
                      <AdminLearningCampaigns
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    exact
                    path={`${match.path}/learning/add-campaigns`}
                    render={(routeProps: any) => (
                      <AdminLearningEditCampaign
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                  />
                  <ProtectedRoute
                    path={`${match.path}/learning/discussion/:id`}
                    render={(routeProps: any) => (
                      <AdminLearningDiscussion
                        {...routeProps}
                        {...this.state}
                        logout={this.handleUserLogout}
                      />
                    )}
                    exact
                  />
                  <ProtectedRoute
                    path={AppRoutings.AdminAdCampaigns}
                    render={() => (<AdminAdCampaign />)}
                    exact
                  />
                  <ProtectedRoute
                    path={AppRoutings.AdminAdCampaign}
                    render={() => (<AdminAdCampaignDetails />)}
                    exact
                  />
                  <ProtectedRoute
                    path={AppRoutings.AdminTransactionHistory}
                    render={() => (<AdminTransactionHistory />)}
                    exact
                  />
                </>
              </Switch>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};
//@ts-ignore
export default withRouter(
  //@ts-ignore
  connect(
    mapStateToProps,
    {
      resetUserDetails,
      setCartBadge,
    }
    //@ts-ignore
  )(AdminConsole)
);
// Customizable Area End
