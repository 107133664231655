// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import StorageProvider from "../../../../../framework/src/StorageProvider"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper"
const configJSON = require("../../config")

export interface ICardProps {
    id: string
    name: string
    last4: string
    exp_month: number
    exp_year: number
    payment_method: string
}
// Customizable Area End

// Customizable Area Start
export interface Props extends RouteComponentProps {
    isOnlySave?: boolean
    selectedCardId: string
    onSelectedCardChange: (cardId: string) => void
    handlePaymentOnUnSaveCard: (id: string) => void
}
// Customizable Area End

interface S {
    // Customizable Area Start
    loader: boolean
    isCardDialog: boolean
    cards: ICardProps[]
    isRemoveCardDialog : boolean
    cardId : string
    isCardRemoving: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CardListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCardsApiCallId: string = ""
    removeCardApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            isCardDialog: false,
            cards: [],
            isRemoveCardDialog : false,
            cardId : '',
            isCardRemoving: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getCardListApi()
    }

    async componentDidUpdate(_: Readonly<Props>, preState: Readonly<S>): Promise<void> {
        const { cards } = this.state

        if (preState.cards.length !== cards.length) {
            let id = ""
            if(cards.length > 0) id = cards[0].id
            this.props.onSelectedCardChange(id)
        }
    }

    openCardDialog = () => this.setState({ isCardDialog: true })

    closeCardDialog = () => this.setState({ isCardDialog: false })

    openRemoveCardDialog = (id: string) => {
        this.setState({
            isRemoveCardDialog: true,
            cardId: id
        })
    }

    closeRemoveCardDialog = () => {
        this.setState({ isRemoveCardDialog: false, cardId: '' })
    }

    handleRemoveCard = () => {
        this.removeCardApi(this.state.cardId)
    }

    handleRemoveCardLocal = (cardId: string) => {
        const cardList = [...this.state.cards]
        const updatedCardList = cardList.filter((card: ICardProps) => card.id !== cardId)
        this.setState({ cards: updatedCardList })
    }

    getCardListApi = async () => {
        const isCardsExist = this.state.cards?.length > 0
        !isCardsExist && this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getCardsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCardsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    removeCardApi = async (id: string) => {
        this.setState({ isCardRemoving: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.removeCardApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.removeCardApiEndPoint}${id}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDelete
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getCardsApiCallId) {
            this.setState({ cards: responseJson?.cards || [], loader: false })
        }

        if (apiRequestCallId === this.removeCardApiCallId) {
            if(!responseJson.success) {
                this.setState({ isCardRemoving: false })
                toast.error("Something went wrong")
                return
            }
            const { id } = responseJson.data
            this.handleRemoveCardLocal(id)
            this.setState({ isRemoveCardDialog: false, isCardRemoving: false })
            toast.success("Card removed successfully")
        }
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(isTokenExpireResponse(responseJson)) return

                if (apiRequestCallId === this.getCardsApiCallId) {
                    this.setState({ cards: [], loader: false })
                    toast.error("Something went wrong. We are unable to retrieve cards")
                }
            }
        }
        // Customizable Area End
    }
}
