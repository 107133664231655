// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { Message } from "../../../framework/src/Message"
import { WithStyles } from "@material-ui/core/styles"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import { adCampaignListStyles } from "./AdminAdCampaignList.web"
import { AppRoutings } from "../../../components/src/utility/app-routing"
import StorageProvider from "../../../framework/src/StorageProvider.web"
import {
  AdvertisementTypeEnum,
  BusinessTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from "./AdCampaignEnums.web"
const configJSON = require("./config.js")

export interface IStatusList {
  label: string
  value: string
  className: "scheduledMenu" | "activeMenu" | "pauseMenu" | "expiredMenu"
}

type IImageResponseType = {
  id: number
  url: string
  filename: string
}

interface IAdCampaign {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    campaign_type: CampaignTypeEnum
    campaign_ad_business_type: BusinessTypeEnum
    advertisement_type: AdvertisementTypeEnum
    estimated_cost: number
    total_cost: number
    url: string
    campaign_ad_status: CampaignStatusEnum
    start_day: string
    end_day: string
    start_time: string
    end_time: string
    farm_id: number | null
    restaurant_id: number | null
    banner: IImageResponseType
    advertisement_banner: IImageResponseType
  }
}

interface IAdCampaignListResponse {
  data: IAdCampaign[]
  meta: {
    pagination: {
      current_page: number
      next_page: number
      per_page: number
      prev_page: number
      total_count: number
      total_pages: number
    }
  }
}

interface IAdCampaignStatusUpdateResponse {
  data: IAdCampaign
}

export interface Props
  extends RouteComponentProps,
    WithStyles<typeof adCampaignListStyles> {}

interface S {
  isLoading: boolean
  searchValue: string
  searchString: string
  selectedIds: string[]
  currentRecord: null | IAdCampaign
  anchorEl: null | HTMLElement
  adCampaignList: IAdCampaign[]
  currentPage: number
  perPage: number
  totalPages: number
  totalRecords: number
}

interface SS {
  id: any
}

export default class AdminAdCampaignListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAdCampaignListApiCallId: string = ""
  statusUpdateApiCallId: string = ""
  bulkStatusUpdateApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      isLoading: false,
      searchValue: "",
      searchString: "",
      selectedIds: [],
      currentRecord: null,
      anchorEl: null,
      adCampaignList: [],
      currentPage: 1,
      perPage: 5,
      totalPages: 0,
      totalRecords: 0,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.handleGettingList()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    const { currentPage, perPage, searchString } = this.state

    if (
      prevState.currentPage !== currentPage ||
      prevState.perPage !== perPage ||
      prevState.searchString !== searchString
    ) {
      this.handleGettingList()
    }
  }

  showErrorToast = () => {
    toast.error(configJSON.SomethingWentWrongMessage)
  }

  handleChangePageNumber = (newPageNo: number) => {
    this.setState({ currentPage: newPageNo })
  }

  handleIncreasePageView = () => {
    const { perPage } = this.state

    if (perPage < 25) {
      this.setState({
        perPage: perPage + 5,
        selectedIds: [],
        currentPage: 1,
      })
    }
  }

  handleDecreasePageView = () => {
    const { perPage } = this.state

    if (perPage > 5) {
      this.setState({
        perPage: perPage - 5,
        currentPage: 1,
        selectedIds: [],
      })
    }
  }

  handleGettingList = () => {
    const { currentPage, perPage, searchValue } = this.state
    const query = `?page=${currentPage}&per=${perPage}&search=${searchValue}`

    this.getAdCampaignListApi(query)
  }

  handleSearchDebounce = (value: string) => {
    this.setState({ currentPage: 1, selectedIds: [], searchString: value })
  }

  searchDebounce = (call: Function, delay: number) => {
    let timer: any

    return function(...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  SearchDebounceUpdate = this.searchDebounce(this.handleSearchDebounce, 700)

  handleCreateCampaign = () => {
    this.props.history.push(`${AppRoutings.AdminAdCampaign}?mode=create`)
  }

  handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value })
    this.SearchDebounceUpdate(event.target.value)
  }

  handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    adDetails: IAdCampaign
  ) => {
    event.stopPropagation()
    this.setState({ anchorEl: event.currentTarget, currentRecord: adDetails })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, currentRecord: null })
  }

  handleEditClick = () => {
    this.props.history.push(
      `${AppRoutings.AdminAdCampaign}?mode=edit&id=${this.state.currentRecord?.id}`
    )
  }

  handleDuplicateClick = () => {
    const { currentRecord } = this.state

    if (currentRecord) {
      const {
        attributes: {
          banner,
          advertisement_type,
          advertisement_banner,
          campaign_ad_business_type,
          campaign_ad_status,
          campaign_type,
          farm_id,
          restaurant_id,
          description,
          name,
          url,
        },
      } = currentRecord

      this.props.history.push(`${AppRoutings.AdminAdCampaign}?mode=duplicate`, {
        banner,
        advertisement_type,
        advertisement_banner,
        campaign_ad_business_type,
        campaign_ad_status,
        campaign_type,
        catalogue_id: farm_id ?? restaurant_id,
        description,
        name,
        url,
      })
    }
  }

  handleSelectAllClick = (event: React.ChangeEvent<{}>, checked: boolean) => {
    const { selectedIds, adCampaignList } = this.state;
    const validRecords = [CampaignStatusEnum.Active];
    const selectedIdsLength = selectedIds.length;
    const isPartiallySelected = selectedIdsLength > 0 && selectedIdsLength < adCampaignList.length;
  
    if (isPartiallySelected || !checked) {
      this.setState({ selectedIds: [] });
    } else if (checked) {
      const newSelected = adCampaignList
        .filter(adCampaign => validRecords.includes(adCampaign.attributes.campaign_ad_status))
        .map(adCampaign => adCampaign.id);
      this.setState({ selectedIds: newSelected });
    }
  }

  handleClick = (campaignId: string) => {
    const { selectedIds } = this.state
    const isSelected = selectedIds.includes(campaignId)

    let newSelected: string[] = []

    if (isSelected) {
      newSelected = selectedIds.filter((selectedId) => selectedId !== campaignId)
    } else {
      newSelected = [...selectedIds, campaignId]
    }

    this.setState({ selectedIds: newSelected })
  }

  handleBulkStatusUpdate = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { selectedIds } = this.state
    const { value } = event.target
    let campaignIds = ""

    selectedIds.forEach((campaignId: string) => {
      campaignIds = `${campaignIds}&ids[]=${campaignId}`
    })

    const query = `?campaign_ad_status=${value}${campaignIds}`

    this.bulkStatusUpdateApi(query)
  }

  handleChangeAdCampaignStatus = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    campaignId: string
  ) => {
    const { value } = event.target
    const query = `?campaign_ad_status=${value}&id=${campaignId}`
    this.adCampaignStatusUpdateApi(query)
  }

  getAdCampaignListApi = async (query: string) => {
    this.setState({ isLoading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getAdCampaignListApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.adCampaignApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  adCampaignStatusUpdateApi = async (query: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.statusUpdateApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.statusUpdateAdCampaignApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  bulkStatusUpdateApi = async (query: string) => {
    this.setState({ isLoading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.bulkStatusUpdateApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bulkStatusUpdateAdCampaignApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleSuccessResponse = (apiRequestCallId: string, responseJson: IAdCampaignListResponse | IAdCampaignStatusUpdateResponse) => {
    if (apiRequestCallId === this.getAdCampaignListApiCallId) {
      if ("data" in responseJson) {
        const {
          data,
          meta: {
            pagination: { total_pages, total_count },
          },
        } = responseJson as IAdCampaignListResponse
        this.setState({
          adCampaignList: data,
          isLoading: false,
          totalPages: total_pages,
          totalRecords: total_count,
        })
      } else {
        this.setState({
          isLoading: false,
          adCampaignList: [],
          totalPages: 0,
          totalRecords: 0,
        })
        this.showErrorToast()
      }
    }

    if (apiRequestCallId === this.statusUpdateApiCallId) {
      if ("data" in responseJson) {
        const {
          data,
          data: { id },
        } = responseJson as IAdCampaignStatusUpdateResponse
        const updatedData = [...this.state.adCampaignList].map(
          (campaignDetails) => {
            if (campaignDetails.id === id) return data
            return campaignDetails
          }
        )
        this.setState({ adCampaignList: updatedData })
      }
    }

    if (apiRequestCallId === this.bulkStatusUpdateApiCallId) {
      this.handleGettingList()
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.handleSuccessResponse(apiRequestCallId, responseJson)
      } else {
        const statusUpdateApisResponse = [
          this.statusUpdateApiCallId,
          this.bulkStatusUpdateApiCallId,
        ].includes(apiRequestCallId)
        if (apiRequestCallId === this.getAdCampaignListApiCallId) {
          this.setState({ adCampaignList: [], isLoading: false })
          this.showErrorToast()
        }

        if (statusUpdateApisResponse) {
          this.showErrorToast()
          this.setState({ isLoading: false })
        }
      }
    }
  }
  // Customizable Area End
}
