import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import OtpInput from "react-otp-input"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    },
    fieldStyle: {
        width: "50px !important",
        height: "50px",
        outline: "none",
        padding: "10px",
        fontWeight: 400,
        textAlign: "center",
        borderRadius: "5px",
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid #c1c1c15e",
        fontFamily: "'Poppins', sans-serif",
        "&:disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            border: "1px solid rgba(0, 0, 0, 0.04)",
        },
    },
}))

interface IOtpInputProps {
    value: string
    isInputNum?: boolean
    isDisabled?: boolean
    onChange: (value: string) => void
}

const CustomOTPInput: React.FC<IOtpInputProps> = ({
    value,
    isDisabled,
    isInputNum = true,
    onChange,
}) => {
    const classes = useStyles()

    return (
        <Box>
            <OtpInput
                value={value}
                containerStyle={classes.container}
                inputStyle={classes.fieldStyle}
                isDisabled={isDisabled}
                isInputNum={isInputNum}
                onChange={onChange}
            />
        </Box>
    )
}

export default CustomOTPInput
