// Customizable Area Start
import React from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Box,
  IconButton,
  TextField,
  withStyles,
  Typography,
  InputAdornment,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core"
import { createStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import CallMadeRoundedIcon from "@material-ui/icons/CallMadeRounded"
import CloseIcon from "@material-ui/icons/CloseRounded"

import NavbarSearchController, {
  ISearchResult
} from "./NavbarSearchController.web"
import { ISearchResultTypeEnum } from "../../interfaces/searchEnum"
import { DefaultImage } from "../../assets"
const configJSON = require("../../config.js")

export const styles = () =>
  createStyles({
    searchFieldWrapper: (props: any) => ({
      maxWidth: props.isMobileSearchPage ? "auto" : "400px",
      width: "100%",
      position: "relative",
    }),
    searchField: {
      backgroundColor: "#f0f0f0",
      borderRadius: "50px",
      width: "100%",
      padding: "15px 15px 15px 22px",
      justifyContent: "flex-start !important",
      "& > div": {
        width: "100%",
      },
      "& input": {
        padding: "0px",
      },
    },
    listWrapper: {
      position: "absolute",
      top: "55px",
      padding: "15px 10px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
      width: "100%",
      display: "block !important",
      zIndex: 1,
    },
    listContainer: {
      display: "block !important",
      maxHeight: "350px",
      overflowY: "auto",
      padding: "0px 5px",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "8px",
        borderRadius: "8px",
        scrollbarFaceColor: "#f7f7f7",
        scrollbarTrackColor: "#f7f7f7",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        backgroundColor: "#c3c3c3",
        scrollbarFaceColor: "#f7f7f7",
        scrollbarTrackColor: "#f7f7f7",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
        borderRadius: "8px",
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        scrollbarFaceColor: "#f7f7f7",
        scrollbarTrackColor: "#f7f7f7",
      },
    },
    listCardContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "6px",
      cursor: "pointer",
      padding: "8px",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
    },
    imageNameWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start !important",
      gap: "10px",
      width: "100%",
      overflow: "hidden",
    },
    itemImage: {
      borderRadius: "6px",
      height: "40px",
      width: "40px",
    },
    nameTagWrapper: {
      alignItems: "flex-start !important",
      overflow: "hidden",
    },
    name: {
      fontSize: "15px",
      fontFamily: "'Montserrat', sans-serif",
      lineHeight: 1.4,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    tag: {
      borderRadius: "8px",
      color: "#20a258 !important",
      padding: "1px 5px",
      width: "fit-content",
      fontSize: "11px",
      backgroundColor: "rgba(32, 162, 88, 0.09)",
      fontFamily: "'Montserrat', sans-serif",
    },
    redirectIcon: {
      color: "#20a258",
    },
  })

export class NavbarSearch extends NavbarSearchController {
  render() {
    const { classes } = this.props
    const { loader, searchValue, isListPopupOpen, searchResults } = this.state
    const isSearchPopupOpen = isListPopupOpen && searchResults.length > 0

    const closeIcon = searchValue ? (
      <InputAdornment position="end">
        <IconButton size="small" onClick={this.handleResetSearch}>
          <CloseIcon fontSize="small" color="action" />
        </IconButton>
      </InputAdornment>
    ) : null

    return (
      <>
        <Box className={classes.searchFieldWrapper}>
          <TextField
            value={searchValue}
            inputRef={this.searchFieldRef}
            className={classes.searchField}
            placeholder={configJSON.SearchForProductsText}
            onChange={this.handleSearchValueChange}
            onKeyDown={this.handleSearchFieldKeyDown}
            onClick={this.handleSearchPopupOpen}
            InputProps={{
              endAdornment: loader ? (
                <InputAdornment position="end">
                  <CircularProgress
                    size={18}
                    className={classes.redirectIcon}
                  />
                </InputAdornment>
              ) : (
                closeIcon
              ),
              disableUnderline: true,
            }}
          />
          {isSearchPopupOpen && (
            <ClickAwayListener onClickAway={this.handleSearchPopupClose}>
              <Box className={classes.listWrapper}>
                <Box className={classes.listContainer}>
                  {searchResults.map((result: ISearchResult) => {
                    const { id, type, name, image, producerId } = result
                    const imageUrl = image ? image.url : DefaultImage
                    const redirectId =
                      type === ISearchResultTypeEnum.Dish ? producerId : id

                    return (
                      <Link key={`${id}${type}`} to={this.getRedirectUrl(type, redirectId)}>
                        <Box className={classes.listCardContainer}>
                          <Box className={classes.imageNameWrapper}>
                            <img
                              src={imageUrl}
                              alt="product"
                              className={classes.itemImage}
                            />
                            <Box
                              flexDirection="column"
                              className={classes.nameTagWrapper}
                            >
                              <Typography variant="h6" className={classes.name}>
                                {name}
                              </Typography>
                              <Typography className={classes.tag}>
                                {type}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton size="small">
                            <CallMadeRoundedIcon
                              fontSize="small"
                              className={classes.redirectIcon}
                            />
                          </IconButton>
                        </Box>
                      </Link>
                    )
                  })}
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
        <Box className="search_icon">
          <IconButton onClick={this.handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
      </>
    )
  }
}

export default withStyles(styles)(withRouter(NavbarSearch))
// Customizable Area End
