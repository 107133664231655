// Customizable Area Start
import React from "react"
import { Box, Button, Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CustomDonationProgressbar from "./CustomDonationProgressbar.web"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "./utility/app-routing"

const useStyles = makeStyles({
  mainWrapper: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "rgba(12, 12, 12, 0.22)",
    backgroundBlendMode: "multiply"
  },
  containerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "50px",
    padding: "80px",
    "@media(max-width: 700px)": {
      flexWrap: "wrap-reverse",
      gap: "70px",
      padding: "60px 30px",
    },
    "@media(max-width: 400px)": {
      padding: "40px 10px",
      gap: "40px"
    },
  },
  contentWrapper: {
    width: "100%",
    "& > h6": {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: "40px",
      fontWeight: 800,
      color: "#fff",
      lineHeight: 'normal'
    },
    "& > p": {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: "18px",
      fontWeight: 500,
      color: "#fff",
      textAlign: "justify",
      marginTop: "5px",
      wordBreak: "break-word",
      "@media(max-width: 700px)": {
        textAlign: "center",
        fontSize: "14px"
      },
    },
    "@media(max-width: 700px)": {
      textAlign: "center",
      "& > h6": {
        fontSize: "32px",
      },
    },
  },
  donateBtn: {
    backgroundColor: "#ffa700",
    padding: "12px 30px",
    marginTop: "25px",
    borderRadius: "45px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#ffa700",
    },
    "& > span": {
      fontWeight: "bold",
      fontFamily: '"Montserrat", sans-serif',
      color: "#fff",
    },
    "@media(max-width: 400px)": {
      padding: "8px 20px",
      fontSize: "14px"
    },
  },
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "@media(max-width: 700px)": {
      justifyContent: "center",
    },
  },
})

interface ICustomDonationBanner extends RouteComponentProps {
  donationId: string
  heading: string
  description: string
  backgroundImage: string
  donatedAmount: number
  requiredAmount: number
}

const CustomDonationBanner = ({
  history,
  donationId,
  heading,
  description,
  backgroundImage,
  donatedAmount,
  requiredAmount,
}: ICustomDonationBanner) => {
  const classes = useStyles()

  const handleRedirectToDonationPage = () => {
    history.push(`${AppRoutings.Donate}/${donationId}`)
  }

  return (
    <Box
      className={classes.mainWrapper}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Container maxWidth="xl">
        <Box className={classes.containerWrapper}>
          <Box className={classes.contentWrapper}>
            <Typography variant="h6">{heading}</Typography>
            <Typography>{description}</Typography>
            <Button
              className={classes.donateBtn}
              onClick={handleRedirectToDonationPage}
            >
              Donate Today
            </Button>
          </Box>
          <Box className={classes.progressWrapper}>
            <CustomDonationProgressbar
              donatedAmount={donatedAmount}
              requiredAmount={requiredAmount}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default withRouter(CustomDonationBanner)
// Customizable Area End
