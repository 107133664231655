import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
// Customizable Area End
import { RouteComponentProps } from "react-router-dom";

export interface Props extends RouteComponentProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    location: any;
    activeTab : any;
    courses:any;
    courseList : any;
    searchCourse : string;
    setCourse : (value: string) => void
    categorySelectChip : (value : string) => void
    updateActiveTab: (tab: string) => void
    getCourseListOnSearch: () => void
    getCategoriesELearning : () => void
    resetLearningFilters : () => void
    setCourseCurrentPage: (currentPage: number) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentPage : any;
    coursesPerPage :any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class LandingPage2Controller extends BlockComponent<
    Props,
    S,
    SS
>{

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        
        this.state = {
            currentPage : 1,
            coursesPerPage : 9
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const { activeTab, updateActiveTab, getCourseListOnSearch,getCategoriesELearning } = this.props;
        activeTab !== ActiveTabEnum.Learning && updateActiveTab(ActiveTabEnum.Learning)

        getCourseListOnSearch()
        getCategoriesELearning()
        // Customizable Area End
    }

    // Customizable Area Start
    async componentWillUnmount() {
        this.props.resetLearningFilters()
    }

    searchCourseByEnter = (e:any) => {
        e.key === "Enter" && this.handleSearchCourse()
    }

    handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        !! this.props.setCourse && this.props.setCourse(e.target.value)
    }

    handleSearchCourse = () => {
        this.props.getCourseListOnSearch()
    }

    handleCourse = (id: any) => {
        this.props.history.push('/learning/courses/single?id=' + id)
    }

    handleCourseListPage = (value:any) => {
        this.props.setCourseCurrentPage(value)
        document.getElementById("courseList")?.scrollIntoView({behavior: 'smooth'})
    }
// Customizable Area End
}