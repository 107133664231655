// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { Message } from "framework/src/Message"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import StorageProvider from "framework/src/StorageProvider.web"
import { isTokenExpired } from "../../../components/src/utility/helper"
import { styles } from "./AdminProductWeightDimensions.web"
import { RouteComponentProps } from "react-router-dom"
import { WithStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
export const configJSON = require("./config")

export const DEFAULT_WEIGHT_DIMENSION = {
  id: "",
  startWeight: "",
  endWeight: "",
  length: "",
  width: "",
  height: "",
}

interface IWeightDimensionResponse {
  id: string
  start_weight: string
  end_weight: string
  weight_unit: string
  length: string
  width: string
  height: string
}

export interface IWeightDimension {
  id: string
  startWeight: string
  endWeight: string
  length: string
  width: string
  height: string
}

interface IWeightDimensionsValues {
  unit: string
  weightDimensions: IWeightDimension[]
}

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  navigation: any
  id: string
}

interface S {
  loading: boolean
  isSubmitting: boolean
  isRemoving: boolean
  isRemoveDialog: boolean
  removingRecordId: string
  dimensionsDetails: IWeightDimensionsValues | null
}

interface SS {
  id: any
}

export default class AdminProductWeightDimensionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getWeightDimensionsApiCallId: string = ""
  updateWeightDimensionsApiCallId: string = ""
  removeWeightDimensionApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      loading: false,
      isSubmitting: false,
      isRemoving: false,
      isRemoveDialog: false,
      removingRecordId: "",
      dimensionsDetails: null,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getWeightDimensionsApi()
  }

  handleWeightDimensionsSubmit = (values: IWeightDimensionsValues) => {
    const { unit, weightDimensions } = values

    if (weightDimensions.length === 0) {
      toast.error("A minimum one weight dimension is required")
      return
    }

    const payloadData = weightDimensions.map(
      (weightDimension: IWeightDimension) => {
        const {
          id,
          startWeight,
          endWeight,
          length,
          width,
          height,
        } = weightDimension

        let data: any = {
          start_weight: startWeight,
          end_weight: endWeight,
          weight_unit: unit,
          length,
          width,
          height,
        }
        if (id) {
          data = { ...data, id }
        }

        return data
      }
    )

    this.updateWeightDimensionApi(payloadData)
  }

  handleRemoveButtonClick = (recordId: string) => {
    this.setState({
      isRemoveDialog: true,
      removingRecordId: recordId,
    })
  }

  closeRemoveRecordDialog = () => {
    this.setState({
      isRemoveDialog: false,
      removingRecordId: ""
    })
  }

  handleRemoveWeightDimension = (
    id: string,
    index: number,
    remove: (index: number) => void
  ) => {
    if (!id) {
      remove(index)
      return
    }

    this.handleRemoveButtonClick(id)
  }

  handleReceivedWeightDimensions = (
    responseJson: IWeightDimensionResponse[]
  ) => {
    const isValidResponse = responseJson.length > 0

    if (isValidResponse) {

      const unit = responseJson[0].weight_unit
      const convertedData = responseJson.map(
        (variant: IWeightDimensionResponse) => {
          const { weight_unit, start_weight, end_weight, ...rest } = variant

          return {
            startWeight: start_weight,
            endWeight: end_weight,
            ...rest,
          }
        }
      )

      const dimensionsDetails = {
        unit,
        weightDimensions: convertedData,
      }
      this.setState({
        loading: false,
        isSubmitting: false,
        dimensionsDetails,
      })
    } else {
      this.setState({
        loading: false,
        isSubmitting: false,
        dimensionsDetails: null,
      })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.successApiCall(apiRequestCallId, responseJson)
      } else {
        this.errorApiCall(responseJson)
      }
    }
  }

  successApiCall = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getWeightDimensionsApiCallId) {
      if ("data" in responseJson) {
        this.handleReceivedWeightDimensions(responseJson.data)
      }
    }

    if(apiRequestCallId === this.updateWeightDimensionsApiCallId) {
      this.handleReceivedWeightDimensions(responseJson)
      toast.success("Weight dimensions updated successfully")
    }

    if (apiRequestCallId === this.removeWeightDimensionApiCallId) {
      await this.getWeightDimensionsApi(false)
      this.setState({
        isRemoving: false,
        isRemoveDialog: false,
        removingRecordId: ""
      })
    }
  }

  errorApiCall = async (responseJson: any) => {
    if (await isTokenExpired(responseJson)) return

    if (Array.isArray(responseJson.errors)) {
      responseJson.errors.forEach((error: any) => {
        toast.error(error.message || error)
      })
    } else {
      toast.error("Something went wrong")
    }
  }

  getWeightDimensionsApi = async (loader: boolean = true) => {
    loader && this.setState({ loading: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getWeightDimensionsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWeightDimensionsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  removeWeightDimensionApi = async () => {
    this.setState({ isRemoving: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const httpBody = {
      id: this.state.removingRecordId,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.removeWeightDimensionApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeWeightDimensionApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  updateWeightDimensionApi = async (data: any) => {
    this.setState({ isSubmitting: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const httpBody = {
      variants: data,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.updateWeightDimensionsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUpdateWeightDimensionsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }
}
// Customizable Area End
