import React from "react";
import AdminHeaderController, { Props } from "./AdminHeaderController.web";
import moment from "moment";
import { Switch, Route, withRouter } from "react-router-dom";

// common css
import "../../web/src/assets/css/style.scss";
import "./assets/css/header.scss";
import "./assets/css/farmHeader.scss"


import { Logo, Search_Icon, Notification_Icon } from "./assets";

import Rating from "@material-ui/lab/Rating";



import CustomProgressbar from "./CustomProgressbar.web"
import CircularProgress from '@material-ui/core/CircularProgress';



class AdminFarmHeader extends AdminHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { match }: any = this.props;
    return (
      <>
        <header className="mainHeader farmHeader">
          <div className="imageContainer">

            <img src={Logo} className="headerLogo" />
          </div>
          <div className="heading">
            <h5> New Agricultural  Techniques</h5>
          </div>

          <div className="courseProgress">
            <div className="progressBarContaine">
              <CircularProgress variant="determinate" value={80} thickness={4} size={60} />
              <p className="progresValue">50%</p>
            </div>
            <p>Completed</p>
          </div>

          <div className="rating">
            <p>Rate :</p>
            <Rating name="size-large" defaultValue={3} size="medium" />
          </div>

        </header>
      </>
    );
  }
}

//@ts-ignore
export default withRouter(AdminFarmHeader);
