// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import SearchDiscussionCardController, {
    Props,
} from "./SearchDiscussionCardController.web"
import { connect } from "react-redux"
import { Box, Typography, Button, CircularProgress } from "@material-ui/core"
import { FollowIcon, FollowingIcon, setCommunitySearchDialog } from "../../assets"
import "../../assets/css/community/communitySearchDiscussionCard.scss"
import Highlighter from "react-highlight-words"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded"

class SearchDiscussionCard extends SearchDiscussionCardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            heading,
            authorName,
            date,
            description,
            isSearchCard,
            searchValue,
        } = this.props
        const { loader, isFollowing } = this.state
        const followBtnText = isFollowing ? "Following" : "Follow"

        return (
            <Box className="sdc_container" onClick={this.handleDetailsRedirect}>
                <Box>
                    <Box>
                        <Highlighter
                            className="sdc_heading"
                            searchWords={[searchValue]}
                            textToHighlight={heading}
                        />
                    </Box>
                    {isSearchCard ? (
                        <Box>
                            <Highlighter
                                className="sdc_description"
                                searchWords={[searchValue]}
                                textToHighlight={description}
                            />
                        </Box>
                    ) : (
                        <Box className="sdc_user_info_container">
                            <Typography>{authorName}</Typography>
                            <FiberManualRecordIcon />
                            <Typography>Started {date}</Typography>
                        </Box>
                    )}
                </Box>
                {!isSearchCard && (
                    <Button
                        className="sdc_follow_btn"
                        onClick={this.handleToggleFollow}
                        startIcon={loader ? null :
                            <img
                                src={isFollowing ? FollowingIcon : FollowIcon}
                                className="sdc_follow_icon"
                            />
                        }
                        disabled={loader}
                    >
                        {loader ? <CircularProgress size={24} color="inherit" /> : followBtnText}
                    </Button>
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

export default withRouter(
    connect(
        mapStateToProps,
        { setCommunitySearchDialog }
    )(SearchDiscussionCard)
)
// Customizable Area End
