// Customizable Area Start
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import React from "react";
import "../../info-page/src/assets/css/receiptcard.scss";
import { expandArrow } from './assets';
import Image from "material-ui-image";
import LazyLoad from "react-lazy-load";


export function BoolOptionTask({loader, data, boolAnswer, handleButtonClick, handleModalClose, isModalOpen }: any) {
    const [boolAns, setBoolAns] = React.useState(false)
    const accountId = localStorage.getItem('cartId')
    return (
        <>
        {data.attributes.title_image &&
            <Grid item xs={12} style={{ display: 'block', marginBottom:'20px' }} className="taskImageContainer">
            <LazyLoad offset={100}>   
            <Image
              style={{position:'unset', paddingTop:'0'}}
              src={data?.attributes?.title_image?.url}
              alt="Title Image"
              imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
              animationDuration={500}
              loading={
                  <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
              }
              />
              </LazyLoad>
              </Grid>
            
            }
            <Grid container>
            {loader && !isModalOpen ? <Box className="task_result_loader">
            <CircularProgress size={30} />
          </Box> :
          <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Select Option:</Typography>
                
                {data?.attributes?.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                    return (
                        <>
                            {JSON.stringify(data.attributes.bool_answer) !== (item.attributes.bool_answer) ?
                                <Typography className='select-option' style={{ color: '#d16565' }}>
                                    Wrong. Correct Answer is {item.attributes?.bool_answer === 'false' ? 'A' : 'B'}
                                </Typography> :
                                <Typography className='select-option' style={{ color: '#147620' }}>
                                    Correct Answer
                                </Typography>
                            }
                        </>
                    )
                })}
            </Grid>
                {data.attributes.task_results.length === 0 ?
                    <>
                        
                        <TaskBoolOptionType data={data} boolAns={boolAns} setBoolAns={setBoolAns} boolAnswer={boolAnswer}
                            loader={loader} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                
                    </>
                    :
                    <>
                        {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                            <>

                                <TaskBoolOptionType data={data} boolAns={boolAns} setBoolAns={setBoolAns} boolAnswer={boolAnswer}
                                    loader={loader} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                    return (
                                        <>
                                            <BoolTaskWithResults data={data} item={item} boolAns={boolAns} setBoolAns={setBoolAns} boolAnswer={boolAnswer}
                                    loader={loader} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen}/>
                                        </> 
                                    )
                                })}
                            </>
                        }
                    </>
                }
                </>}
                {/* </Box> */}
            </Grid>
        </>
    )
}

const TaskBoolOptionType = ({ loader,data, boolAns, setBoolAns, boolAnswer, handleButtonClick, handleModalClose, isModalOpen }: any) => {
    return (
        <>
            <Grid container style={{ display: 'flex' }}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex', alignItems:'center' }}>
                    <button className='option-A'>A</button>
                    <button className={`${boolAns === 'true' ? "select" : "option-container"}`}
                        onClick={() => setBoolAns('true')}>
                        True
                    </button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle">
                    <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
                    <button className={`${boolAns === 'false' ? "select" : "option-container"}`}
                        onClick={() => setBoolAns('false')}>
                        False
                    </button>
                </Grid>
            </Grid>
            <Grid container className="taskBtnContainer">
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className='cancelBtn' onClick={() => setBoolAns('')}>Cancel</button>
                    <button className='submitBtn' onClick={() => boolAnswer(boolAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                </Grid>
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className='fullBtnContainer'
                        style={{ margin: '0', padding: '16px 22px 16.2px', alignItems: 'flex-end' }}
                        onClick={handleButtonClick}
                    >
                        <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose} >
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                        <BooleanTaskPopUp loader={loader} data={data} accountId={data.attributes.account_id} boolAnswer={boolAnswer} setBoolAns={setBoolAns} boolAns={boolAns} />
                    </Box>
                </Grid>
            </Modal>


        </>
    )
}

const BoolTaskWithResults = ({loader, data, item, boolAnswer, handleButtonClick, handleModalClose, isModalOpen, boolAns,setBoolAns}:any) => {
    const points = item?.attributes?.bool_answer === data?.attributes?.bool_answer.toString()
    return(
    <>
        <Grid container style={{ display: 'flex' }}>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', alignItems:'center' }}>
                <button className='option-A'>A</button>
                <button className={`${data?.attributes?.bool_answer === true || item?.attributes?.bool_answer === 'true' ? "select" : "option-container"}
            ${(item?.attributes?.bool_answer === 'true') && (data?.attributes?.bool_answer !== true) ? "wrong" : "option-container"}`}
                >True
                </button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle">
                <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
                <button className={`${data?.attributes?.bool_answer === false || item?.attributes?.bool_answer === 'false' ? "select" : "option-container"}
            ${(item?.attributes?.bool_answer === 'false') && (data?.attributes?.bool_answer !== false) ? "wrong" : "option-container"}`}
                >
                    False
                </button>
            </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between'}}>
            <Grid className="pointContainer">
                <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
            </Grid>
            <Grid style={{ margin: '30px 0 20px' }}>
                <button className='fullBtnContainer' style={{ margin: '0', padding: '16px 22px 16.2px' }} onClick={handleButtonClick}>
                    <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
            </Grid>
        </Grid>
        <Modal open={isModalOpen} onClose={handleModalClose} >
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                    <BooleanTaskPopUp loader={loader} data={data} boolAnswer={boolAnswer} setBoolAns={setBoolAns} boolAns={boolAns} />
                </Box>
            </Grid>
        </Modal>
    </>
    )
}

const TaskBoolOptionModal = ({data,boolAns,setBoolAns,boolAnswer}:any) => {
    return(
    <>
    <Grid container style={{ display: 'flex' }}>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', alignItems:'center' }}>
                <button className='option-A'>A</button>
                <button className={`${boolAns === 'true' ? "select-popup" : "options-popup"}`}
                    onClick={() => setBoolAns('true')}>
                    True
                </button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle">
                <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
                <button className={`${boolAns === 'false' ? "select-popup" : "options-popup"}`}
                    onClick={() => setBoolAns('false')}>
                    False
                </button>
            </Grid>
        </Grid>
        <Grid container className="taskBtnContainer">
            <Grid style={{ display: 'flex', marginTop: '30px' }}>
                <button className='cancelBtn' onClick={() => setBoolAns('')}>Cancel</button>
                <button className='submitBtn' onClick={() => boolAnswer(boolAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
            </Grid>
        </Grid>
    </>
    )    
}

const BoolTaskWithResultsModal = ({data, item, boolAnswer}:any) => {
    const points = item?.attributes?.bool_answer === data?.attributes?.bool_answer.toString()
    return(
        <>
        <Grid container style={{ display: 'flex' }}>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', alignItems:'center' }}>
            <button className='option-A'>A</button>
            <button className={`${data.attributes.bool_answer === true || item.attributes.bool_answer === 'true' ? "select-popup" : "options-popup"}
        ${(item.attributes.bool_answer === 'true') && (data.attributes.bool_answer !== true) ? "wrong-popup" : "options-popup"}`}
            >True
            </button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle">
            <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
            <button className={`${data.attributes.bool_answer === false || item.attributes.bool_answer === 'false' ? "select-popup" : "options-popup"}
        ${(item.attributes.bool_answer === 'false') && (data.attributes.bool_answer !== false) ? "wrong-popup" : "options-popup"}`}
            >
                False
            </button>
        </Grid>
    </Grid>
    <Grid className="pointContainer">
        <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
    </Grid>
        </>
    )
}

function BooleanTaskPopUp({ loader,data, boolAnswer, boolAns, setBoolAns }: any) {
    const accountId = localStorage.getItem('cartId')

    return (
        <>
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                    <Typography className="select-text">Select Correct Answer</Typography>
                    <div className='horizontal-line'></div>
                    <Typography className='task-title'>Task Title</Typography>
                    <Box className='task-question'>
                    <Typography variant="body1" component="div" >
                        <div  className="question" dangerouslySetInnerHTML={{ __html:  data.attributes.title }} />
                    </Typography>
                    </Box>
                    {data.attributes.title_image &&
                        <Grid item xs={12} style={{ display: 'block', margin:'20px 0' }} className="taskImageContainer">
                        <LazyLoad offset={100}>    
                        <Image
                        style={{position:'unset', paddingTop:'0'}}
                        src={data?.attributes?.title_image?.url}
                        alt="Title Image"
                        imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
                        animationDuration={500}
                        loading={
                            <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
                        }
                        />
                        </LazyLoad>
                        </Grid>
                        
                    }
                    <Grid container style={{ marginTop: '24px' }}>
                        {loader ?
                            <Box className="task_result_loader">
                                <CircularProgress size={30} />
                            </Box>
                        :
                        <>
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='task-option'>Options</Typography>
                            {data?.attributes?.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                return (
                                    <>
                                        {JSON.stringify(data.attributes.bool_answer) !== (item.attributes.bool_answer) ?
                                            <Typography className='task-option' style={{ color: '#d16565', marginRight:'30px' }}>
                                                Wrong. Correct Answer is {item.attributes?.bool_answer === 'false' ? 'A' : 'B'}
                                            </Typography> :
                                            <Typography className='task-option' style={{ color: '#147620',marginRight:'30px' }}>
                                                Correct Answer
                                            </Typography>
                                        }
                                    </>
                                )
                            })}
                            {data.attributes.task_results.length === 0 ?
                            <>
                                <TaskBoolOptionModal data={data} boolAns={boolAns} setBoolAns={setBoolAns} boolAnswer={boolAnswer} />
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                                    <>
                                        <TaskBoolOptionModal data={data} boolAns={boolAns} setBoolAns={setBoolAns} boolAnswer={boolAnswer} />
                                    </>
                                    :
                                    <>
                                        {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                            return (
                                                <>
                                                    <BoolTaskWithResultsModal data={data} item={item} boolAnswer={boolAnswer} />
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </>
                }
                        </Grid>
                        </>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}
// Customizable Area End