// Customizable Area Start
import React from "react";
import { Box, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { cardBg, removeCMSFavCourse } from "../../assets";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating"
import LPFavCourseCardController, { Props } from "./LPFavCourseCardController.web"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

class LPFavCourseCard extends LPFavCourseCardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            name,
            author,
            image,
            rating
        } = this.props
        const { isRemoving } = this.state

        return (
            <Box className="fav_courses_card_wrapper">
                <img src={image || cardBg} width="100%" />
                <Box className="fav_course_item">
                    <Box className="fav_course_item_text">
                        <Typography className="fav_course_name">
                            {name}
                        </Typography>
                        <Typography className="fav_course_author">By : {author}</Typography>
                    </Box>
                    <Box className="fav_courses_rating">
                        <Rating
                            className="StyledRating"
                            classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                            name="customized-empty"
                            defaultValue={rating}
                            precision={0.5}
                            readOnly
                            emptyIcon={<StarIcon className="empty_star_rating" fontSize="inherit" />}
                        />
                        <Box className="favourate_icon">
                            <IconButton disabled={isRemoving} onClick={this.handleRemoveFromFav}>
                                {isRemoving ? <CircularProgress size={22} /> : <FavoriteIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withRouter(connect(null, { removeCMSFavCourse })(LPFavCourseCard))
// Customizable Area End
