// Customizable Area Start
import React from "react";
import { DustbinIcon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import { commonCmptStyles } from "./dropDownCss";
import AdminLearningCampaignController, {
  configJSON,
  Props
} from "./AdminLearningCampaignController.web";
import {
  Box,
  Select,
  MenuItem,
  DialogTitle,
  DialogActions,
  Dialog,
  withStyles,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../assets/css/editCampaign.scss";
import FormControl from "@mui/material/FormControl";
import "../../../web/src/assets/css/content/progressedit.scss";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CampaignDetailsContainer from "./LearningCampaignDetails.web";



export class AdminLearningEditCampaign extends AdminLearningCampaignController {
  constructor(props: Props) {
    super(props);
  }

  CampHeaderContent = (loading:any, classes:any,campStatClass:any, values:any, setFieldValue:any, learningStatus:any, deleteIcon: any) => {
    return(
    <>
    {!loading && (
      <div className="rightSideHeader commonDropdown">
        <div className="">
          <div
            className={`customSelect campaignSelect itemStatus ${campStatClass}`}
          >
            <FormControl>
              <Select
                name="status"
                className="menu-customize campaign_status"
                value={values.status}
                onChange={(e: any) => {
                  setFieldValue(configJSON.statusText, e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText,
                  },
                  transformOrigin: {
                    vertical: configJSON.topText,
                    horizontal: configJSON.leftText,
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: classes.itemStatus,
                  },
                }}
              >
                {learningStatus.map((status: any) => {
                  const menuItemStat = status 
                  return (
                    <MenuItem value={status} key={status}>
                      {menuItemStat}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        {deleteIcon()}
        
      </div>
    )}
    </>
    )
  }
  
  render() {

    const { classes } = this.props
    const { campDetails, productMode, openDialog, loading } = this.state
    const campMode = (productMode == configJSON.createText) ? configJSON.createCampaignText : configJSON.editCampaignText
    const learningStatus = configJSON.campaignStatusMenu;
    const campaignDetails = campDetails?.attributes;
    const deleteIcon = () => (
      (productMode == configJSON.editText) && (
        <img src={DustbinIcon}
          className="campaign_delete_icon"
          onClick={() => {
            this.setState({
              removedProduct: campDetails.id,
            });
            this.handleDialog();
          }}
        />
      ) 
    )

    const renderHeader = () => (
      <div className="leftSideHeader">
      <IconButton onClick={() => this.props.history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
      <h4 data-test-id="campaignLabel">
        {campMode}
      </h4>
    </div>
    )
    return (
      <Formik
          enableReinitialize
          initialValues={{
            heading: (campaignDetails?.heading) || "",
            tagline : (campaignDetails?.tagline) || "",
            description: (campaignDetails?.description) || "",
            status: (campaignDetails?.status) || configJSON.activeText,
            banner: (campaignDetails?.banner) || "",
            campaign_id: (this.state.campDetails?.id) || "",
            required_donation:
              (campaignDetails?.required_donation),
          }}
          validationSchema={this.Schema}
          onSubmit={(values) => {
            this.createCampaign(values);
          }}
        >
        {(formikProps) => {
          const { values, setFieldValue, handleChange, touched, errors } =
            formikProps;
          const campStatClass = (values.status == configJSON.draftStatus) && configJSON.warningVariantText
          return (
            <Form noValidate autoComplete="off">
              <Box className="restaurantForumsEditContainer">
                {/* header start */}
                <div className="header">
                 {renderHeader()}
                  {this.CampHeaderContent(loading, classes,campStatClass,values, setFieldValue, learningStatus, deleteIcon)}
                  
                </div>

                {/* header start */}
                {/* main wrapper start */}
                <CampaignDetailsContainer
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  loading={loading}
                  campDetails={campDetails}
                  productMode={productMode}
                  handleDonationValueChange={this.handleDonationValueChange}
                  endDecorator={this.handleEndDecorator}/>
                {/* remove modal */}
                <div>
                  <Dialog
                    open={openDialog}
                    onClose={this.handleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Are you sure you want to delete the campaign ?
                    </DialogTitle>
                    <DialogActions>
                      <span className="redContained customCancelBtn">
                        <CustomButton
                          color="secondary"
                          label="No"
                          onClick={this.handleDialog}
                        />
                      </span>
                      <span className="greenContained">
                        <CustomButton
                        data-test-id="yesBtn"
                          color="secondary"
                          label="Yes"
                          onClick={() => {
                            this.handleDialog();
                            this.deleteCampaign();
                          }}
                          autoFocus
                        />
                      </span>
                    </DialogActions>
                  </Dialog>
                </div>
                {/* main wrapper end */}
              </Box>
            </Form>
          )
        }}
      </Formik>
    )
    
  }
}

//@ts-ignore
const CampRouter:any = withRouter(withStyles(commonCmptStyles)(AdminLearningEditCampaign))
export default CampRouter;
// Customizable Area End