// Customizable Area Start
import { Box, Grid, InputAdornment, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import ReactPlayer from "react-player";
import { PlayButton,RightArrow, searchIcon } from "./assets";
import LearningDiscussions from '../../categoriessubcategories/src/LearningDiscussions.web';
import { LessonTask } from "./LessonTask.web";
import Lazyload from "react-lazy-load";

export function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
export class SectionTabs extends React.Component<any,any>{
  playerRef : any
    constructor(props:any){
      super(props);
      this.state = {
        value : 0,
        isPlayVideo : false,
        playTime : '',
        duration : '',
        loading : false,
        searchDiscText : ''
      }
      this.playerRef = React.createRef()
    }

    handleChange = ((event:any, newValue: number) => {
      this.setState({value : newValue});
    });
  
    handleClick = () => {
  
      if (this.state.isPlayVideo) {
        this.setState({isPlayVideo : false})
      } else {
        this.setState({isPlayVideo : true})
      }
      this.setState({isPlayVideo : !this.state.isPlayVideo})
    }
  
    handleProgress = ((state:any) => {
      if(state && state.playedSeconds) {
        this.setState({playTime : state.playedSeconds})
      }
    })
  
    handlePause = (() => {
      this.setState({isPlayVideo : false})
    })
  
    handleDuration = ((e:any) => {
      this.setState({duration : e})
    }) 
  
    handleSeek = () => {
      this.setState({isPlayVideo: true})
    }

    handleReady = (event:any) => {
      const player = this.playerRef.current.getInternalPlayer()
    const metadata = player.videoWidth;
    console.log("Meta data", metadata)
  
    }

    handleError = (error:any) => {
      console.error(error);
    };
    render() {
      const lessonDescription = this.props.lessonInfo?.filter((point:any) => point.trim() !== '') || []
      const {detail, courseDetail, list, lessonNum,partNum, partName, isOwner,isVideoComplete,isLessonComplete} = this.props
      return (
      <Box sx={{ width: '100%' }}>
        <Box className="lessonTabSection">
          <Tabs TabIndicatorProps={{ style: { background: '#169d4e' } }}
            value={this.state.value} 
            aria-label="basic tabs example"
            data-test-id="tabChange"
            onChange={this.handleChange}
            className="enrolled-tabs"
          >
            <Tab label="Lesson" {...a11yProps(0)} />
            <Tab label="Discussion"  {...a11yProps(1)} />
          </Tabs>
          <div className="customTextfield searchWrapper" style={{ display: this.state.value === 0 ? 'none' : 'flex', marginRight:'80px'}} >
          <TextField
          className="searchBox"
              id="input-with-searchIcon-textfield"
              value={this.state.searchDiscText}
              data-test-id="searchDisc"
              onChange={(e:any) => this.setState({
                searchDiscText: e?.target.value,
              })}
              variant="standard"
              placeholder="Search here... "
              InputLabelProps={{
                  shrink: true,
              }}
              InputProps={{
                  startAdornment: (
                      <InputAdornment position="start" >
                          <img style={{width:'20px', height:'20px'}} src={searchIcon}/>
                      </InputAdornment>
                  ),
              }}
          />
        </div>
  
        </Box>
        <TabPanel value={this.state.value} index={0}>
          <Grid className="imageHeaderContainer" container >
            <Grid item className='image-1' xs={12} md={12}>
                <h1 style={{ textTransform: 'capitalize' }}>{detail.attributes && detail.attributes.heading}</h1>
                {detail.attributes?.video && <Lazyload offset={100} >
                  <Grid item xs={12} lg={12} md={12} className="video-player1">
                    <ReactPlayer
                      className="reactPlayerWrapper"
                      ref={this.playerRef}
                      playing={this.state.isPlayVideo}
                      controls={this.state.isPlayVideo}
                      url={detail.attributes?.video.url}
                      onProgress={this.handleProgress}
                      onPause={this.handlePause}
                      onDuration={this.handleDuration}
                      onSeek={this.handleSeek}
                      onReady={this.handleReady}
                      onError={this.handleError}
                      preload="auto"
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload',
                          },
                        },
                      }}
                      width="100%"
                      height="100%"
                      volume={1}
                      style={{ position: 'relative' }}
                      playsinline
                      webkit-playsinline
                    />


                    {this.state.isPlayVideo ?
                      <div>
                        <img data-test-id="playBtn" onClick={this.handleClick} src={PlayButton} style={{ display: 'none' }} />
                      </div>
                      :
                      <div data-test-id="playerPaused" className='player-controls'>
                        <img onClick={this.handleClick} src={PlayButton} />

                      </div>
                    }
                  </Grid>
                </Lazyload>}
              </Grid>  
          </Grid>
          
  
          <Grid className="lessonInfoContainer" container>
            <Grid item xs={12}>
              <h2 className="section-title" style={{ marginBottom: '-10px', textTransform:'capitalize' }}>
                Welcome to {detail.attributes && detail.attributes.heading}
              </h2>
            </Grid>
  
            <Grid item xs={12} className="marginTwo">
              {lessonDescription.map((text:any) => {
                return(
                  <div key={text} className='flexOne'>
                    <img
                        src={RightArrow} alt="RightIcon"
                        className="rightArrowIcon" />

                    <p className="para" style={{color:'#9098b1'}}>
                    {text}
                    </p>
                  </div>
                  )
                })}
  
            </Grid>
  
          </Grid>
  
          
          <LessonTask
          list={list} taskData={undefined}
          isVideoComplete={isVideoComplete}
          isLessonComplete={isLessonComplete}/>
        </TabPanel>
        <TabPanel class="DiscussionSection" value={this.state.value} index={1}>
          
          <LearningDiscussions
              searchDiscText={this.state.searchDiscText} 
              isOwner={isOwner}
              navigation={undefined} courseDetail={courseDetail} heading={detail.attributes?.heading}
              partNum={partNum}
              partName={partName}
              lessonNum={lessonNum}
               id={""}/>
            
        </TabPanel>
  
      </Box>
      
    );
    }
  }
// Customizable Area End