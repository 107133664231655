const farmProductDetailsInitialState = {
    available_quantity: 0,
    average_rating: 0,
    categories: [],
    compare_at_price: "",
    continue_sell_when_out_of_stock: false,
    description: "",
    farm: {
        id: 0,
        name: "",
        image: null,
        description: ""
    },
    favourites: false,
    icons: { data: [] },
    images: [],
    name: "",
    price: "",
    product_status: "",
    product_type: "",
    recently_added_products: { data: [] },
    reviews: { data: [] },
    sku: "",
    tags: { data: [] },
    track_quantity: false,
    unit: "",
    unit_type: ""
}

const farmCampaignInitialState = {
    campaign_id: 0,
    campaign_image: null,
    description: "",
    donation_raised: "",
    donation_raised_percentage: "",
    farmer_name: "",
    heading: "",
    required_donation: "",
    tagline: ""
}

const initialState = {
    farmsProduct: [],
    forums: [],
    campaign: { ...farmCampaignInitialState },
    farmProductDetails: { ...farmProductDetailsInitialState },
    farmRatings: [],
    prodRatings: [],
    pagination: {
        current_page: 0,
        next_page: 0,
        per_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0
    },
    currentPage: 1,
    showCount: 6,
    isLoading: false,
    startPrice: 0,
    endPrice: 0,
    minPrice: 0,
    maxPrice: 0,
    filterName: "",
    filterByTagName: "",
    filterByCategoryName: "",
    farmsCategories: [],
    farmsTags: [],
    selectedCategory: "",
    selectedTag: "",
    searchText: "",
    lat: "",
    lng: "",
    nearByFilter: false,
    isLoadingWrite: false,
    filterType: "",
}

export const farmProductsCatalogueReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_FARM_PRODUCT_LIST':
            return {
                ...state,
                farmsProduct: payload
            }
        case 'SET_FARM_PRODUCT_RATINGS':
            return {
                ...state,
                farmRatings: payload
            }
        case 'SET_FARM_PRODUCT_FORUM':
            return {
                ...state,
                forums: payload
            }
        case 'SET_FARM_DONATION':
            return {
                ...state,
                campaign: payload
            }
        case 'SET_FARM_PRODUCT_DETAILS':
            return {
                ...state,
                farmProductDetails: payload,
                prodRatings: payload?.reviews?.data
            }
        case 'SET_PAGINATION_FOR_FARM_PRODUCT_LIST':
            return {
                ...state,
                pagination: payload
            }
        case 'SET_IS_FARM_PRODUCT_LOADING_TRUE':
            return {
                ...state,
                isLoading: true
            }
        case 'SET_IS_FARM_PRODUCT_LOADING_FALSE':
            return {
                ...state,
                isLoading: false
            }
        case 'SET_FARMS_PRODUCT_CURRENT_PAGE':
            return {
                ...state,
                currentPage: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_START_PRICE':
            return {
                ...state,
                startPrice: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_END_PRICE':
            return {
                ...state,
                endPrice: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_PRICES':
            const { min_price, max_price } = payload
            return {
                ...state,
                minPrice: Number(min_price) || 0,
                maxPrice: Number(max_price) || 0
            }
        case 'SET_FARMS_PRODUCT_FILTER_CATEGORIES':
            return {
                ...state,
                farmsCategories: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_TAGS':
            return {
                ...state,
                farmsTags: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_SELECTED_CATEGORY':
            if (state.selectedCategory === payload) {
                return {
                    ...state,
                    selectedCategory: ""
                }
            } else {
                return {
                    ...state,
                    selectedCategory: payload
                }
            }
        case 'FARM_ADD_TO_FAVORITE_PROD_BUTTON':
            let updatedFev = {
                ...state.farmProductDetails,
                favourites: !state.farmProductDetails.favourites
            }
            return {
                ...state,
                farmProductDetails: updatedFev
            }
        case 'SET_FARMS_PRODUCT_FILTER_SELECTED_TAG':
            if (state.selectedTag === payload) {
                return {
                    ...state,
                    selectedTag: ""
                }
            } else {
                return {
                    ...state,
                    selectedTag: payload
                }
            }
        case 'SET_FARMS_PRODUCT_FILTER_LAT':
            return {
                ...state,
                lat: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_LNG':
            return {
                ...state,
                lng: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_SEARCH_TEXT':
            return {
                ...state,
                searchText: payload
            }
        case 'SET_FARMS_PRODUCT_FILTER_TYPE':
            return {
                ...state,
                filterType: payload
            }
        case 'CLEAR_FARMS_PRODUCT_FILTER_SEARCH_AND_LATLNG_FILTERS':
            return {
                ...state,
                searchText: "",
                lat: "",
                lng: "",
            }
        case 'ADD_FARM_PRODUCT_REVIEW':
            let ratings = state.farmRatings
            ratings.push(payload)
            return {
                ...state,
                farmRatings: ratings
            }
        case 'ADD_FARM_ITEM_REVIEW':
            let ratings_item = state.prodRatings
            ratings_item.push(payload)

            let details = state.farmProductDetails

            const allRatings = details.reviews.data.map(rat => rat.attributes.rating)
            const sum = allRatings.reduce((partialSum, a) => partialSum + a, 0)
            const newRating = sum / allRatings.length

            details.average_rating = newRating.toFixed(1)

            return {
                ...state,
                farmProductDetails: details,
                prodRatings: ratings_item
            }
        case 'SET_IS_FARM_PRODUCT_REVIEW_LOADING_TRUE':
            return {
                ...state,
                isLoadingWrite: true
            }
        case 'SET_IS_FARM_PRODUCT_REVIEW_LOADING_FALSE':
            return {
                ...state,
                isLoadingWrite: false
            }
        case 'SET_NEAR_BY_FARM_PRODUCT_FILTER':
            return {
                ...state,
                nearByFilter: payload
            }
        case 'SET_FARM_FORUM_LIKE':
            const newForum = [...state.forums]?.map((forum) => {
                const { id, type, attributes } = forum
                if (payload.id === id) {
                    return {
                        id,
                        type,
                        attributes: {
                            ...attributes,
                            is_like: payload.isLiked,
                            likes: payload.likeCount
                        }
                    }
                }
                return forum
            })
            return {
                ...state,
                forums: newForum
            }
        case 'SET_FARM_FORUM_COMMENT':
            const forums = [...state.forums]?.map((forum) => {
                const { id, type, attributes } = forum
                if (payload.id.toString() === id.toString()) {
                    return {
                        id,
                        type,
                        attributes: {
                            ...attributes,
                            comments: payload.count
                        }
                    }
                }
                return forum
            })
            return {
                ...state,
                forums
            }
        case 'RESET_FARM_PRODUCT_FILTERS':
            return {
                ...state,
                currentPage: 1,
                showCount: 6,
                isLoading: false,
                startPrice: 0,
                endPrice: 0,
                minPrice: 0,
                maxPrice: 0,
                filterName: "",
                filterByTagName: "",
                filterByCategoryName: "",
                selectedCategory: "",
                selectedTag: "",
                filterType: "",
                searchText: "",
                campaign: {}
            }
        default:
            return state
    }
}