import React from 'react';
import {getRestaurantsList,getCategories,getTags,getRestaurantsDetails,addRatingRestaurants,getPopularCategories, getResMinMaxPrices } from "./RestaurantAPI";
import {toast} from "react-toastify";
import store from "../store"
import { isTokenExpired } from "../../utility/helper"


// Action for Get Restaurants list with filters
export const getRestaurantsListAction = () => async (dispatch) => {
  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_TRUE'});
  const restaurantData = store.getState().restaurants
  if(restaurantData.restaurantsCategories.length <= 0){
    dispatch(getRestaurantsCategoriesAndTagsAction())
  }
  if(restaurantData.minPrice === 0 || restaurantData.maxPrice === 0) dispatch(getRestaurantsFilterPrices())

  try {
    const payload = {
      page: restaurantData.currentPage,
      count: restaurantData.showCount,
      filterByCategoryName: restaurantData.selectedCategory,
      filterByTagName: restaurantData.selectedTag,
      startPrice: restaurantData.startPrice,
      endPrice: restaurantData.endPrice,
      searchText: restaurantData.searchText,
      latitude: restaurantData.lat,
      longitude: restaurantData.lng,
      filterType: restaurantData.filterType
    }
    const response = await getRestaurantsList(payload)    
    if(response.data && response.meta.pagination.total_count > 0){
      dispatch({type: 'SET__RESTAURANTS_LIST',payload:response?.data});
      dispatch({type: 'SET_PAGINATION_FOR_RESTAURANTS_LIST',payload:response?.meta?.pagination});
    } else{
      response?.errors?.map((err)=>{
        toast.error(err?.message)
      })
      if (response.meta.pagination.total_count === 0) {
        toast.error("No restaurant available")
        dispatch({type: 'SET__RESTAURANTS_LIST',payload: []})
        dispatch({type: 'SET_PAGINATION_FOR_RESTAURANTS_LIST',payload: {}});
      }
    }
  }catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_FALSE'});
}

// current page pagination
export const setRestaurantsCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:currentPage});
  dispatch(getRestaurantsListAction())
}

export const getRestaurantsPopularCategories = () => async (dispatch) => {
  const categories = store.getState().restaurants.popularCategories
  categories?.length === 0 && dispatch({type: 'SET_RESTAURANTS_CATEGORY_IS_LOADING_TRUE'});
  try {
    // get Popular Categories of restaurants and set in state
    const res1 = await getPopularCategories()
    if(res1.data){
      dispatch({type:"SET_POPULAR_CATEGORY_LIST",payload:res1.data})
    }else{
      res1.errors?.map((err)=>{
        toast.error(err?.message)
      })
    }
  }catch (e) {
    toast.error("Something went wrong..")
  }
  dispatch({type: 'SET_RESTAURANTS_CATEGORY_IS_LOADING_FALSE'});
}


// current
export const getRestaurantsCategoriesAndTagsAction = () => async (dispatch) => {
  try {
    // get Categories of restaurants and set in state
    const res1 = await getCategories()
    dispatch({type:"SET_RESTAURANTS_FILTER_CATEGORIES",payload:res1})
    // get Tags of restaurants and set in state
    const res2 = await getTags()
    dispatch({type:"SET_RESTAURANTS_FILTER_TAGS",payload:res2})
  }catch (e) {
    toast.error("Something went wrong..")
  }
}

export const getRestaurantsFilterPrices = () => async (dispatch) => {
  try {
    const res = await getResMinMaxPrices()
    dispatch({ type: "SET_RESTAURANTS_FILTER_PRICES", payload: res })
  } catch (e) {
    toast.error("Something went wrong")
  }
}


export const setRestaurantsSelectedCategory = (name) => async (dispatch) => {
  dispatch({type:"SET_RESTAURANTS_FILTER_SELECTED_CATEGORY", payload: name})
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:1});
  dispatch(getRestaurantsListAction())
}

export const setRestaurantsSelectedTag = (name) => async (dispatch) => {
  dispatch({type:"SET_RESTAURANTS_FILTER_SELECTED_TAG", payload: name})
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:1});
  dispatch(getRestaurantsListAction())
}

export const setRestaurantFilterByPrice = (array) => async (dispatch) => {

  dispatch({type:"SET_RESTAURANTS_FILTER_START_PRICE", payload: array[0]})
  dispatch({type:"SET_RESTAURANTS_FILTER_END_PRICE", payload: array[1]})
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:1});
  dispatch(getRestaurantsListAction())
}

export const setRestaurantFilterByTextAndLatLng = (latLng,text, address) => async (dispatch) => {
  const { nearByFilter } = store.getState().restaurants
  address && nearByFilter && dispatch({type:"SET_NEAR_BY_RESTAURANTS_FILTER", payload: false })
  dispatch({type:"SET_RESTAURANTS_FILTER_LAT", payload: latLng?.lat})
  dispatch({type:"SET_RESTAURANTS_FILTER_LNG", payload: latLng?.lng})
  dispatch({type:"SET_RESTAURANTS_FILTER_SEARCH_TEXT", payload: text})
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:1});
  dispatch(getRestaurantsListAction())
}

export const clearRestaurantFilterByTextAndLatLng = () => async (dispatch) => {
  dispatch({type:"CLEAR_RESTAURANTS_FILTER_SEARCH_AND_LATLNG_FILTERS"})
  dispatch({type: 'SET_RESTAURANTS_CURRENT_PAGE',payload:1});
  dispatch(getRestaurantsListAction())
}

export const getRestaurantDetailsAction = (id) => async (dispatch) => {
  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_TRUE'});
  try {
    const res = await getRestaurantsDetails(id)
    if(res.hasOwnProperty('restaurant') && res.hasOwnProperty('account')) {
      dispatch({type:'SET_RESTAURANTS_DETAILS', payload:res })
    } else {
      if(await isTokenExpired(res)) return
      toast.error("Restaurant not found")
    }
  } catch (e) {
    console.log("error", e);
    toast.error("Something went wrong!!")
  }

  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_FALSE'});
}

export const addRatingRestaurantAction = (formData,handleClear) => async (dispatch) => {
  dispatch({type: 'SET_IS_RESTAURANTS_REVIEW_LOADING_TRUE'});
  try {
    if(formData.rating &&  formData.comment && formData.id){
      const res = await addRatingRestaurants({
        review:{
          rating:formData.rating,
          comment:formData.comment,
          reviewable_id:formData.id,
          reviewable_type:"BxBlockCatalogue::Restaurant"
        }
      })
      if(!res.hasOwnProperty("errors")){
        dispatch({ type: 'ADD_RESTAURANTS_REVIEW', payload:res.data })
        toast.success("Comment added successfully")
        handleClear()
      } else if(res?.errors[0]?.message === "Invalid token") {
        toast.error("Please login to add a review")
      } else {
        await isTokenExpired(res)
      }
    } else {
      if(!formData.rating){
        toast.error("Please add a rating")
      }
      if(!formData.comment){
        toast.error("Please write a comment")
      }
    }
  } catch (e) {
    toast.error("Something went wrong!!")
  }
  dispatch({ type: 'SET_IS_RESTAURANTS_REVIEW_LOADING_FALSE'});
}

export const setRestaurantSearchAddress = (value) => async (dispatch) => {
  dispatch({type:"SET_RESTAURANT_SEARCH_ADDRESS", payload: value })
}

export const setRestaurantNearByFilter = (lat,lng, categoryFilter = null) => async (dispatch) => {
  const restaurantData = store.getState().restaurants
  categoryFilter && dispatch({type:"SET_RESTAURANTS_FILTER_SELECTED_CATEGORY", payload: categoryFilter })
  if(restaurantData.nearByFilter){
    dispatch({type:"SET_RESTAURANTS_FILTER_LAT", payload: ""})
    dispatch({type:"SET_RESTAURANTS_FILTER_LNG", payload: ""})
    dispatch({type:"SET_NEAR_BY_RESTAURANTS_FILTER", payload: !restaurantData.nearByFilter})
  } else{
    if(lat && lng){
      dispatch({type:"SET_RESTAURANTS_FILTER_LAT", payload: lat})
      dispatch({type:"SET_RESTAURANTS_FILTER_LNG", payload: lng})
      dispatch({ type: 'SET_RESTAURANTS_CURRENT_PAGE', payload: 1 })
      dispatch({type:"SET_RESTAURANT_SEARCH_ADDRESS", payload: "" })
      dispatch({type:"SET_NEAR_BY_RESTAURANTS_FILTER", payload: !restaurantData.nearByFilter})
    }
  }
  dispatch(getRestaurantsListAction())
}

export const addToFavoriteRestaurantAction = (id) => async (dispatch) => {
  try {
    const res = await addToFavorite({
      favouriteable_id:id,
      favouriteable_type:"BxBlockCatalogue::Restaurant"
    })
    if(res.hasOwnProperty("data")){
      dispatch({type:"RESTAURANTS_ADD_TO_FAVORITE_BUTTON"})
    }
  }catch (e) {
    toast.error("Something went wrong!!")
  }
}

export const setRestaurantFilterPrice = (filter) => async (dispatch) => {
  if (filter === "default") filter = ""

  dispatch({type:"SET_RESTAURANT_FILTER_TYPE", payload: filter})
  dispatch(getRestaurantsListAction())
}

export const getMapViewRestaurants = () => async (dispatch) => {
  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_TRUE'});
  const searchValue = store.getState().restaurants.searchRestaurantValue
  const filterValue = store.getState().restaurants.restaurantMapFilter
  try {
    const payload = {
      page: "",
      count: "",
      filterByCategoryName: "",
      filterByTagName: "",
      startPrice: "",
      endPrice: "",
      searchText: searchValue,
      latitude: "",
      longitude: "",
      filterType: filterValue
    }
    const response = await getRestaurantsList(payload)
    if(response.data?.length > 0 && response.meta.pagination.total_count > 0){
      dispatch({type: 'SET_MAP_VIEW_RESTAURANTS', payload: response?.data});
    } else{
      response?.errors?.map((err)=>{
        toast.error(err?.message)
      })
      if (response.meta.pagination.total_count === 0) {
        toast.error("No restaurant available")
        dispatch({type: 'SET_MAP_VIEW_RESTAURANTS',payload: []})
      }
    }
  }catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({type: 'SET_RESTAURANTS_IS_LOADING_FALSE'});
}

export const setSearchValueForRestaurant = (text) => async (dispatch) => {
  dispatch({ type: 'SET_SEARCH_RESTAURANT_TEXT', payload: text });
}

export const resetMapViewRestaurant = () => async (dispatch) => {
  dispatch({ type: 'RESET_MAP_VIEW_RESTAURANT' });
}

export const resetRestaurantFilters = () => async (dispatch) => {
  dispatch({ type: 'RESET_RESTAURANTS_FILTERS' })
}

export const resetRestaurantDetails = () => async (dispatch) => {
  dispatch({ type: "RESET_RESTAURANT_DETAILS" })
}

export const toggleRestaurantForumLike = (id, isLiked, likeCount) => async (dispatch) => {
  dispatch({ type: 'SET_RES_FORUM_LIKE', payload: { id, isLiked, likeCount }})
}

export const handleResForumCommentCount = (id, count) => async (dispatch) => {
  dispatch({ type: 'SET_RES_FORUM_COMMENT', payload: { id, count }})
}