import { baseURL } from "../../../../framework/src/config"
import StorageProvider from "framework/src/StorageProvider";
const configJSON = require("../../config.js")

export const getFarmList = async (payload) => {
    try {
        const {
            page,
            count,
            filterByCategoryName,
            filterByTagName,
            searchText,
            latitude,
            longitude
        } = payload
        let {
            startPrice,
            endPrice
        } = payload

        if (startPrice === 0 && endPrice === 0) {
            startPrice = ""
            endPrice = ""
        }
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        let headers = {
          "Content-Type": "application/json"
        }
        if(token) {
          headers = {
            ...headers,
            token
          }
        }
        const response = await fetch(
            `${baseURL}/bx_block_catalogue/farms?page=${page}&per=${count}&filter_by_category_name=${filterByCategoryName || ""
            }&filter_by_tag_name=${filterByTagName || ""}&filter_start_price=${startPrice || ""
            }&filter_end_price=${endPrice || ""}&latitude=${latitude || ""
            }&longitude=${longitude || ""}&search=${searchText}`,
            {
                method: "GET",
                headers: headers
            }
        )
        return response.json()
    } catch (e) {
        return e
    }
}

export const getCategories = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/categories?category_type=farm`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const getTags = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/tags?tags_type=farm`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const getFarmDetails = async (id) => {
    try {
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const response = await fetch(`${baseURL}/bx_block_catalogue/farms/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                token: token || ""
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const getFarmCatalogue = async (id) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/farm_catalogues?farm_id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const addRatingFarm = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/catalogue/reviews`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: Token || ""
            },
            body: JSON.stringify(data)
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const addToFavorite = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_favourites/favourites/favourites`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                token: Token || ""
            },
            body: JSON.stringify(data)
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const getFarmsMinMaxPrice = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/min_max_price?type=Farm`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}
