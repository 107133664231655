// Customizable Area Start
import React, { Component } from "react"
import clsx from "clsx"
import { Button, CircularProgress } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"

interface IProps extends WithStyles<typeof styles> {
  title: string
  type?: "submit" | "button"
  isLoading: boolean
  buttonType: "success" | "error" | "close"
  handleClick: () => void
}

export class CustomCancelFlowButton extends Component<IProps> {
  render() {
    const {
      buttonType,
      classes,
      type,
      isLoading,
      title,
      handleClick,
    } = this.props
    const buttonBehaviorType = type ?? "button"

    return (
      <Button
        disabled={isLoading}
        type={buttonBehaviorType}
        className={clsx(classes.button, {
          [classes.successButton]: buttonType === "success",
          [classes.errorButton]: buttonType === "error",
          [classes.closeButton]: buttonType === "close",
        })}
        onClick={handleClick}
      >
        {isLoading ? <CircularProgress size={24} /> : title}
      </Button>
    )
  }
}

export const styles = () =>
  createStyles({
    button: {
      width: "80%",
      padding: "8px 25px",
      borderRadius: "8px",
      textTransform: "none",
      fontFamily: "'Montserrat', sans-serif",
      "& span": {
        color: "#fff",
        fontSize: "16px",
      },
      "&:hover": {
        opacity: 0.9,
      },
      "& svg": {
        color: "#fff",
      },
      "@media screen and (max-width: 480px)": {
        fontSize: "15px",
      },
    },
    errorButton: {
      backgroundColor: "#e74747",
      border: "1px solid #e74747",
      "&:hover": {
        backgroundColor: "#e74747",
      },
    },
    successButton: {
      backgroundColor: "#20a258",
      border: "1px solid #20a258",
      "&:hover": {
        backgroundColor: "#20a258",
      },
    },
    closeButton: {
      border: "1px solid #000",
      "& span": {
        color: "#000 !important",
      },
    },
  })

export default withStyles(styles)(CustomCancelFlowButton)
// Customizable Area End
