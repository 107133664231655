// Customizable Area Start
import React, { Component } from "react"
import { Grid, Box } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Logo } from "./assets"
import { AppRoutings } from "./utility/app-routing"
import "./assets/css/topbar.scss"

interface Props extends RouteComponentProps { }

class TopBar extends Component<Props> {
  handleRedirect = (url: string) => {
    this.props.history.push(url)
  }

  render() {
    return (
      <div className="topbar_container">
        <Grid container>
          <Grid item xs={12}>
            <Box className="topbar-wrapper">
              <Box onClick={() => this.handleRedirect(AppRoutings.Home)}>
                <img src={Logo} width="100%" alt="logo" />
              </Box>
              <Box
                className="question_wrapper"
                onClick={() => this.handleRedirect(AppRoutings.WebFAQ)}
              >
                ?
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(TopBar)
// Customizable Area End
