// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import { isTokenExpired } from "../../../components/src/utility/helper";
import { toast } from "react-toastify";
import {
  ISearchCourse,
  ISearchFarm,
  ISearchProduct,
  ISearchDish,
  ISearchRestaurant
} from "./interfaces/search";
const configJSON = require("./config.js");

interface IViewAllList {
  courses_view_all: boolean
  dishes_view_all: boolean
  farm_catalogues_view_all: boolean
  farms_view_all: boolean
  restaurants_view_all: boolean
}

export interface Props extends RouteComponentProps { }

interface S {
  loader: boolean;
  searchQuery: string;
  products: ISearchProduct[];
  dishes: ISearchDish[];
  farms: ISearchFarm[];
  restaurants: ISearchRestaurant[];
  courses: ISearchCourse[];
  viewAllList: IViewAllList;
}

interface SS {
  id: any;
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  searchApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loader: false,
      searchQuery: "",
      products: [],
      dishes: [],
      farms: [],
      restaurants: [],
      courses: [],
      viewAllList: {
        courses_view_all: false,
        dishes_view_all: false,
        farm_catalogues_view_all: false,
        farms_view_all: false,
        restaurants_view_all: false,
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.handleSearch();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.handleSearch();
    }
  }

  enableSearchLoader = () => {
    this.setState({ loader: true });
  };

  disableSearchLoader = () => {
    this.setState({ loader: false });
  };

  resetResultsState = () => {
    this.setState({
      products: [],
      farms: [],
      restaurants: [],
      courses: []
    });
  };

  handleSearch = () => {
    const { search } = this.props.location;
    const queryParams = new window.URLSearchParams(search);
    const query = queryParams.get("query");

    if (query) {
      const queryString = `?query=${query}`;
      this.setState({ searchQuery: query });
      this.searchApi(queryString);
    } else {
      this.props.history.goBack();
    }
  };

  searchApi = async (queryString: string) => {
    this.enableSearchLoader();

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchApiEndPoint + queryString
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.searchApiCallId) {
      if (responseJson.status === "ok") {
        const {
          products: { data: products },
          dishes: { data: dishes },
          farms: { data: farms },
          restaurants: { data: restaurants },
          courses: { data: courses },
          courses_view_all,
          dishes_view_all,
          farm_catalogues_view_all,
          farms_view_all,
          restaurants_view_all,
        } = responseJson;

        this.setState({
          products,
          dishes,
          farms,
          restaurants,
          courses,
          viewAllList: {
            courses_view_all,
            dishes_view_all,
            farm_catalogues_view_all,
            farms_view_all,
            restaurants_view_all
          }
        });
      }
      this.disableSearchLoader();
    }
  };

  handleErrorApiResponse = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.searchApiCallId) {
      this.disableSearchLoader();
      this.resetResultsState();
      toast.error(configJSON.SOMETHING_WENT_WRONG);
    }
  };

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson);
      } else {
        if (await isTokenExpired(responseJson)) {
          return;
        }
        this.handleErrorApiResponse(apiRequestCallId, responseJson);
      }
    }
  }
}
// Customizable Area End
