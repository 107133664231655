import React from "react";
import { Grid, Typography, Box, Button, InputBase } from "@material-ui/core";
import "../assets/css/farmcatelogtopbanner.scss";

const FarmCatelogTopBanner = (props:any) => {
  return (
    <>
      <Box className="farm_catelog_top_banner">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h2 style={{textAlign:"center"}}>Freshly picked from {props.name || ""}'s Farm</h2>
          </Grid>
          {/*<Grid item xs={12} sm={12} md={7} lg={6}>*/}
          {/*  <Box style={{ display: "flex" }}>*/}
          {/*    <InputBase*/}
          {/*      placeholder="Search vegetables,fruits etc"*/}
          {/*      // inputProps={{ "aria-label": "naked" }}*/}
          {/*      className="farm_catelog_banner_input"*/}
          {/*    />*/}
          {/*    <Button*/}
          {/*      //className="farm_catelog_search_btn"*/}
          {/*      classes={{ label: "farm_catelog_search_btn" }}*/}
          {/*    >*/}
          {/*      Search*/}
          {/*    </Button>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </>
  );
};

export default FarmCatelogTopBanner;
