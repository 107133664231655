import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props extends  RouteComponentProps {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  close?: any;
  open?: any;
  setIsAuth?: any;
  isAuth?: boolean;
  userInfo?: any
  updateActiveTab?: (tabName: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
   
    // Customizable Area Start
    this.state = {
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      console.log(message.id)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    !!this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Home)
  }
  // Customizable Area End

}