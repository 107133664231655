Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderDetailView";
exports.labelBodyText = "OrderDetailView Body";
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"


// Add New Address
exports.addAddressMethod = "POST";
exports.addAddressApi = "address/addresses";
exports.addApiContentType = "multipart/form-data";

// View Address
exports.getAddressApi = "address/addresses";
exports.getAddressMethod = "GET";

// Update Address
exports.updateAddressMethod = "PUT";
exports.updateAddressApi = "address/addresses/:id";
exports.addApiContentType = "multipart/form-data";

// Delete Address
exports.deleteAddressMethod = "DELETE";
exports.deleteAddressApi = "address/addresses/id";
exports.deleteApiContentType = "application/json";


exports.btnExampleTitle = "CLICK ME";
exports.InvoiceFileName = "Invoice.pdf"
exports.AUTH_TOKEN = "authToken";
exports.CARTABLE_TYPE_MENU = "BxBlockCatalogue::Menu"
exports.orderHistoryApiEndPoint = 'bx_block_order_management/orders/order_history?type='
exports.orderDetailsApiEndPoint = 'bx_block_order_management/orders/order_details?id='
exports.orderTrackingApiEndPoint = 'bx_block_order_management/orders/order_tracking?id='
exports.addToCartApiEndPoint= "bx_block_order_management/cart_items/add_to_cart"
exports.cancellationApiEndPoint = "bx_block_order_management/orders/order_cancel"
exports.orderCancellationStatusApiEndPoint = "bx_block_order_management/orders/get_order_details"

exports.purchasedCourseText = "Purchased Course";
exports.viewInvoiceText = "View Invoice";
exports.goToCourseBtnText = "Go to course";
exports.noPurchasedCourseText = "Looks like you don't have purchased course."
exports.draftText = "draft";
exports.deactivateText = "deactivate";
exports.courseNotAvailableText = "The Course is not available now";
exports.farmText = "farm";
exports.restaurantText = "restaurant";
exports.courseText = "course";

exports.errorText = "error"
exports.cancelOrderText = "Cancel Order"
exports.cancellationStatusText = "Cancellation status:"
exports.refundStatusText = "Refund status:"
exports.confirmCancellationText = "Confirm Cancellation"
exports.orderCancelledMessage = "Order cancelled successfully"
exports.cancellationRequestCreatedMessage = "Cancellation request created successfully"
exports.cancelOrderTitle1 = "Please confirm that you would like to cancel this order. If the Seller has not fulfilled or shipped your order, then this request will be honoured automatically."
exports.cancelOrderTitle2 = "If the Seller is able to accept this request before it is shipped, then your request will be honoured."
exports.cancelOrderTitle3 = "Otherwise, you will have to receive the order and then request a refund, where you will be liable for the cost of return shipping."
exports.cancelledMessages = {
  "order cancelled": "Order cancelled successfully",
  "order cancellation pending": "Cancellation request created successfully"
}
// Customizable Area End