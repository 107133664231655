import React from "react"
import {
  Grid,
  Box,
  Button,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core"
import { withRouter } from "react-router-dom";
import "../../assets/css/checkout/checkoutdelivery.scss"
import AddAddress from "../../../../OrderDetailView/src/AddAddress.web"
import EditAddress from "../../../../OrderDetailView/src/EditAddress.web"
import "../../../../../web/src/assets/css/webstyle.scss"
import PickUpWebController from "./pickUpWebController.web"
import { connect } from "react-redux"
import {
  getAddressListAction,
  setOrderAddress,
  setOrderReceiveType,
  setCheckoutOrder
} from "../../assets"
import OrderDeliveryAddresses from "./OrderDeliveryAddresses.web"
import OrderPickUpAddresses from "./OrderPickUpAddresses.web"

class CheckOutPickUpWeb extends PickUpWebController {

  render() {
    const {
      selectedAddress,
      orderReceiveType,
      orderType,
      checkoutOrders,
      addresses,
    } = this.props
    const { openModal, editAddressModal, editAddressData, pickUpAddresses } =
      this.state
    const isPickUp = orderType === "farm" && checkoutOrders.data.length === 1

    return (
      <Box mb={5}>
        <Box className="check_out_delivery_cards_container check_out_left_container">
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} sm={9}>
                <h2 className="check_out_heading">Pick up/Delivery Address</h2>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  classes={{
                    root: "check_out_delivery_card_btn_root",
                    label: "btn-label",
                  }}
                  onClick={this.showModal}
                  hidden={orderReceiveType === "pickup"}
                >
                  Add Address
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={orderReceiveType}
                    onChange={this.handleChangeReceiveType}
                    name="receive-type"
                  >
                    <FormControlLabel
                      value="delivery"
                      control={
                        <Radio
                          color="primary"
                          classes={{ checked: "radio_checked" }}
                        />
                      }
                      label="Delivery to Address"
                      classes={{
                        root: "radio",
                        label:
                          orderReceiveType === "delivery"
                            ? "checked_radio_label"
                            : "radio_label",
                      }}
                    />
                    <FormControlLabel
                      value="pickup"
                      control={
                        <Radio
                          color="primary"
                          classes={{ checked: "radio_checked" }}
                        />
                      }
                      hidden={!isPickUp}
                      label="Pick up"
                      classes={{
                        root: "radio",
                        label:
                          orderReceiveType === "pickup"
                            ? "checked_radio_label"
                            : "radio_label",
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {this.loaderCondition() ? (
                <Box className="checkout_address_loader">
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <FormControl component="fieldset">
                  {!this.isPickUp() ? (
                    <OrderDeliveryAddresses
                      selectedAddress={selectedAddress}
                      addresses={addresses}
                      handleChangeAddress={this.handleChangeAddress}
                      showEditAddressModal={this.showEditAddressModal}
                    />
                  ) : (
                    <OrderPickUpAddresses
                      selectedAddress={selectedAddress}
                      pickUpAddresses={pickUpAddresses}
                      handleChangeAddress={this.handleChangeAddress}
                      showEditAddressModal={this.showEditAddressModal}
                    />
                  )}
                </FormControl>
              )}
            </>
          </Grid>
        </Box>

        {editAddressModal && (
          <EditAddress
            isOpen={editAddressModal}
            id={editAddressData?.id}
            data={editAddressData}
            close={this.closeEditAddressModal}
          />
        )}

        <AddAddress isOpen={openModal} close={this.closeModal} />
      </Box>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    addresses: state.user.address,
    selectedAddress: state.user.checkoutOrder?.address_id,
    orderReceiveType: state.user.checkoutOrder?.address_type,
    checkoutOrders: state.user.checkoutOrder?.orders,
    orderType: state.user.checkoutOrder?.type,
  }
}

export default withRouter(connect(mapStateToProps, {
  getAddressListAction,
  setOrderAddress,
  setOrderReceiveType,
  setCheckoutOrder
})(CheckOutPickUpWeb))
