// Customizable Area Start
import React from "react"
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextareaAutosize,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded"
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { NavLink, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import Rating from "@mui/material/Rating"
import "../assets/css/productDetails.scss"
import {
  getFarmProductsDetailsAction,
  addRatingFarmItemAction,
  addToFavoriteProdAction,
  brocali,
  updateActiveTab,
  getCartItems,
  setCartBadge,
} from "../assets"
import GreenBanner from "../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../components/src/WebFooter.web"
import WebReviewCard from "../../../../components/src/WebReviewCard.web"
import LeafImgWithTextBanner from "../../../../components/src/WebLeafWithTextBanner.web"
import FarmsCard from "../../../../components/src/FarmsSectionsImgCard.web"
import FarmSectionImageRenderComponent from "../../../../components/src/FarmSectionImageRenderComponent.web"
import WebCartNumberInput from "../../../../components/src/webCartNumberInput.web"
import BreadCrumb from "../../../../components/src/BreadCrumb.web"
import ProductDetailsLayoutController, {
  configJSON,
} from "./ProductDetailsLayoutController.web"
import LoginSignUpDialog from "../langinpageLoggedIn/LoginSignUpDialog.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { LESS_QUANTITY_NUMBER } from "../../../../components/src/utility/constants"
import {
  ICertifiedTag,
  IFarmTag,
  IReview,
} from "../../../../components/src/interfaces/farm"
import { variantPriceInfo } from "../../../../components/src/utility/helper"

export const styles = () =>
  createStyles({
    farmProductName: {
      fontFamily: "AllRoundGothicW01-Book !important",
      fontSize: "24px",
    },
    tagsName: {
      margin: "0px 8px",
    },
    writeReview: {
      float: "right",
      color: "#20a258",
      textDecorationLine: "underline",
      marginBottom: "10px",
      marginRight: "10px",
      fontSize: "18px",
      fontWeight: 600,
      position: "relative",
      fontFamily: "Montserrat",
    },
    itemStatus: {
      marginTop: "5px",
      borderRadius: "8px",
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px"
      }
    }
  })
// Customizable Area End

class ProductDetailsLayout extends ProductDetailsLayoutController {
  render() {
    // Customizable Area Start
    const { products, classes } = this.props
    const { id, counter, rating, review, loginDialog, addCartLoader, selectedVariant, currentPrice } =
      this.state

    const {
      isLoading,
      prodRatings,
      isLoadingWrite,
      farmProductDetails: {
        sku,
        name,
        farm: { id: farmId, name: farmName, description: farmDescription },
        unit,
        tags,
        price,
        images,
        icons: { data: certifiedTags },
        reviews,
        unit_type,
        categories,
        favourites,
        description,
        average_rating,
        compare_at_price,
        recently_added_products,
        track_quantity: isTrackQuantity,
        available_quantity: availableQuantity,
        variant_description,
        variant_options
      },
    } = products

    const isDiscount = Number(compare_at_price) < Number(price)
    const isOutOfStock = isTrackQuantity && availableQuantity === 0
    const isToShowStockInfo =
      isTrackQuantity &&
      availableQuantity > 0 &&
      availableQuantity < LESS_QUANTITY_NUMBER
    const addToCartBtnText = isOutOfStock
      ? configJSON.outOfStockText
      : configJSON.addToCartText
    const unitType =
      unit_type === configJSON.perItemText ? configJSON.unitText : unit
    const isToShowVariant =
      !isOutOfStock &&
      variant_description &&
      variant_description.data &&
      !Array.isArray(variant_description.data) &&
      variant_options.length > 0
    

    return (
      <>
        <Box mb={5} id="FarmsProducts">
          {isLoading ? (
            <Container>
              <Box className="custom_loader_container">
                <CircularProgress color="inherit" size={40} />
              </Box>
            </Container>
          ) : (
            <Container>
              <BreadCrumb
                threePage={true}
                twoPage={false}
                firstPage="Home"
                firstPageLink={AppRoutings.Home}
                secondPage={`${this.capitalizeValue(farmName)}'s Catalogue`}
                secondPageLink={`${AppRoutings.FarmDetailsCatelog}/${farmId}`}
                thirdPage={this.capitalizeValue(name)}
                thirdPageLink={`${AppRoutings.FarmProductDetails}/${id}`}
              />
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Box>
                    <FarmSectionImageRenderComponent
                      imagesArray={images || []}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="product_details">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.farmProductName}>
                            {this.capitalizeValue(name)}
                          </Typography>
                          <IconButton
                            className="product_fav"
                            onClick={this.handleAddToFavorite}
                          >
                            {favourites ? (
                              <FavoriteIcon className="fav_fill_icon" />
                            ) : (
                              <FavoriteBorderRoundedIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Box className="product_price_tags_wrapper">
                          <Box className="product_price_wrapper">
                            <Typography className="product_price farm_product_price">
                              {isToShowVariant ? `$ ${currentPrice}` : `$ ${compare_at_price} per ${unitType}`}
                            </Typography>
                            {(isDiscount && !isToShowVariant) && (
                              <Typography className="product_compare_at_price farm_product_price">
                                ${price} per {unitType}
                              </Typography>
                            )}
                          </Box>
                          <Box className="farm_certified_tags_container">
                            {certifiedTags.map((tag: ICertifiedTag) => {
                              const {
                                id,
                                attributes: { icon },
                              } = tag
                              return <img key={id} src={icon?.url} alt="tag" />
                            })}
                          </Box>
                        </Box>
                        <Typography className="farm_details_context">
                          {description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <Box>
                            {isToShowVariant && <Typography className="product_details_label product_details_user_review">
                              {!Array.isArray(variant_description.data) &&
                                variant_description.data.attributes.description}{" "}
                              :
                            </Typography>}
                            <Box className="cart_action_container">
                              {isToShowVariant && <Box className="product_variant_wrapper">
                                <Select
                                  displayEmpty
                                  disableUnderline
                                  variant="filled"
                                  value={selectedVariant}
                                  onChange={this.handleVariantChange}
                                  className="product_variant_select"
                                  IconComponent={ExpandMoreRoundedIcon}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                  }}
                                >
                                  {variant_options.map((option) => {
                                    const {
                                      id,
                                      attributes: { option_name, price },
                                    } = option

                                    return <MenuItem key={id} value={id}>{`${option_name} ($${price})`}</MenuItem>
                                  })}
                                </Select>
                              </Box>}

                              <WebCartNumberInput
                                count={counter}
                                isDisable={isOutOfStock}
                                addCount={this.increaseQuantity}
                                minusCount={this.decreaseQuantity}
                              />
                            </Box>
                          </Box>
                        <Box className="cart_btn_container">
                          <Button
                            onClick={this.handleAddToCart}
                            classes={{
                              root: "product_add_cart",
                              label: "btn-label",
                            }}
                            disabled={addCartLoader || isOutOfStock}
                          >
                            {addCartLoader ? (
                              <CircularProgress
                                size={24}
                                className="add_cart_loader"
                              />
                            ) : (
                              addToCartBtnText
                            )}
                          </Button>
                        </Box>
                        {isToShowStockInfo && (
                          <Typography className="product_stock_info">
                            Only {availableQuantity} quantity left!
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={6}
                        className="product_details_review_box"
                      >
                        <Typography className="product_details_label product_details_user_review">
                          {configJSON.reviewsText} :{" "}
                        </Typography>
                        <Rating
                          classes={{
                            iconFilled: "iconFilled",
                            iconHover: "iconHover",
                          }}
                          name="customized-empty"
                          defaultValue={0}
                          value={average_rating}
                          precision={0.5}
                          size="medium"
                          readOnly
                          onChange={this.handleRating}
                          emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5}
                        lg={6}
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <Typography className="product_details_customer_review_count">
                          {reviews?.data?.length}{" "}
                          {configJSON.customerReviewsText}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography className="product_details_label">
                            {configJSON.skuText}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className="product_details_label">
                            {sku}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        className="product_details_info"
                      >
                        <Grid item>
                          <Typography className="product_details_label">
                            {configJSON.productCategoryText}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className="product_category">
                            {categories
                              ?.map((cat: { name: string }) => cat?.name)
                              ?.join(", ")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        className="product_details_info"
                      >
                        <Grid item>
                          <Typography className="product_details_label">
                            {configJSON.tagsText}
                          </Typography>
                        </Grid>
                        {tags?.data?.map((tag: IFarmTag) => {
                          const {
                            id,
                            attributes: { name },
                          } = tag

                          return (
                            <Grid item key={id} className={classes.tagsName}>
                              <Typography className="product_tags">
                                {name}
                              </Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="product_details_tabs">
                    <Box>
                      <NavLink
                        exact
                        to={`${AppRoutings.FarmDetails}/${farmId}`}
                        className="product_details_tab"
                      >
                        {this.capitalizeValue(farmName)}
                      </NavLink>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box className="product_details_children_wrapper">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className="product_details_grey_txt">
                          {farmDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Box mt="50px">
                      <Box className="product_details_top_right_heading">
                        <Typography className={classes.writeReview}>
                          {configJSON.writeReviewText}
                        </Typography>
                      </Box>
                      <Box className="product_details_children_wrapper">
                        <Grid container spacing={2} item xs={12}>
                          <Grid item xs={12}>
                            <p className="review_label">
                              {configJSON.reviewsText} ({prodRatings?.length})
                            </p>
                          </Grid>

                          {prodRatings.map((item: IReview) => {
                            const {
                              id,
                              attributes: {
                                full_name,
                                image,
                                date,
                                comment,
                                rating,
                              },
                            } = item
                            return (
                              <Grid item xs={12} key={id}>
                                <Box>
                                  <WebReviewCard
                                    name={full_name}
                                    date={date}
                                    review={comment}
                                    rating={rating}
                                    profileImg={image?.url}
                                  />
                                </Box>
                              </Grid>
                            )
                          })}

                          <Grid item xs={12}>
                            <p className="review_label">
                              {configJSON.writeReviewText}
                            </p>
                            <Rating
                              className="StyledRating"
                              classes={{
                                iconFilled: "iconFilled",
                                iconHover: "iconHover",
                              }}
                              name="customized-empty"
                              defaultValue={0}
                              value={rating}
                              precision={0.5}
                              size="large"
                              onChange={this.handleRating}
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                            <Box>
                              <TextareaAutosize
                                aria-label="minimum height"
                                minRows={4}
                                value={review}
                                placeholder="Write comment here..."
                                className="write_review_text_area"
                                onChange={this.handleWriteText}
                              />
                            </Box>
                            <Box mt="10px">
                              <Button
                                classes={{
                                  root: "green-btn",
                                  label: "btn-label",
                                }}
                                disabled={isLoadingWrite}
                                onClick={this.handleReviewSubmit}
                              >
                                {isLoadingWrite ? (
                                  <CircularProgress size={25} color="inherit" />
                                ) : (
                                  configJSON.submitText
                                )}
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12}></Grid>
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <LeafImgWithTextBanner
                    heading={configJSON.leafBannerHeading}
                    text={configJSON.leafBannerText}
                    linkText=""
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box marginTop="50px">
                  <Grid container spacing={3} justifyContent="center">
                    {recently_added_products?.data?.map((item: any) => {
                      const {
                        id,
                        attributes: {
                          name,
                          image,
                          description,
                          average_rating,
                          price,
                          compare_at_price,
                          variant_options
                        },
                      } = item
                      const { displayPrice, isVariantExist } = variantPriceInfo(compare_at_price, variant_options)
                      const discountPrice = isVariantExist ? "" : price

                      return (
                        <Grid item xs={6} md={4} lg={3} key={id}>
                          <FarmsCard
                            cardName={name || " "}
                            thumbnail={image?.url || brocali}
                            description={description}
                            displayPrice={configJSON.blockText}
                            displayButton={configJSON.noneText}
                            displayDuration={configJSON.noneText}
                            displayRating={configJSON.blockText}
                            average_rating={average_rating}
                            amount={discountPrice}
                            discountPrice={displayPrice}
                            boxShadow={false}
                            path={`${AppRoutings.FarmProductDetails}/${id}`}
                            imgPath={`${AppRoutings.FarmProductDetails}/${id}`}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Container>
          )}
        </Box>
        <LoginSignUpDialog
          isOpen={loginDialog}
          onClose={this.handleCloseLoginDialog}
          onLoginSuccess={this.handleOnLogin}
        />
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    products: state.farmProducts,
    activeTab: state.user.activeTab,
    userInfo: state.user,
    cartId: state.user.cartId,
  }
}
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      getCartItems,
      getFarmProductsDetailsAction,
      addRatingFarmItemAction,
      addToFavoriteProdAction,
      updateActiveTab,
      setCartBadge,
    })(ProductDetailsLayout)
  )
)
// Customizable Area End
