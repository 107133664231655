import React, { Component } from "react";
import {
  Grid,
  Box,
  Button,
  TextareaAutosize,
  FilledInput,
  FormControl,
  Select,
} from "@material-ui/core";
import "../../assets/css/shoppingcartlayout.scss";
import { Link } from "react-router-dom";

class RestaurantCartLayout extends Component {
  render() {
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
            <Box className="shopping_cart_wrapper_box">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={7} md={12} lg={6} xl={7}>
                  <Box className="shopping_cart_details">
                    <Box>
                      {/* <img src={veggies} width="70px" height="70px" /> */}
                      <h3>Rencho Shopping Cart</h3>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={12} lg={6} xl={5}>
                  <Box>
                    <Box>
                      {" "}
                      <FormControl
                        variant="outlined"
                        classes={{
                          root: "shopping_cart_select_root",
                        }}
                      >
                        <Select
                          native
                          inputProps={{
                            name: "age",
                            id: "outlined-age-native-simple",
                          }}
                          classes={{
                            root: "shopping_cart_select",
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value={10}>Ten</option>
                          <option value={20}>Twenty</option>
                          <option value={30}>Thirty</option>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                  </Box>
                  <Box>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={5}>
                  <Box className="apply_coupon_wrapper web_input_field">
                    <p>have coupen code</p>
                    <FilledInput
                      placeholder="add coupen code"
                      classes={{
                        root: "add_coupon_input_root",
                        input: "add_coupon_input",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={7}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Box>
                    <Button
                      classes={{
                        root: "apply_coupon_btn",
                        label: "btn-label",
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Box className="cart_total_price_wrapper">
                    <h3>Total:</h3>
                    <h4>$3.80</h4>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <p className="note_to_order_label">
                      Add a note to your order
                    </p>
                    <TextareaAutosize
                      placeholder="Add your note here..."
                      minRows={5}
                      className="note_to_order_textarea"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <Box className="checkout_btn">
              <Link to="/check-out-delivery">
                <Button>Proceed to Check Out</Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        
      </>
    );
  }
}

export default RestaurantCartLayout;
