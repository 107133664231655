// Customizable Area Start
import React from "react"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import { Message } from "../../../framework/src/Message"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { IBlock } from "../../../framework/src/IBlock"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import StorageProvider from "framework/src/StorageProvider"
export const configJSON = require("./config")

export interface IInitialValues {
    name: string
    email: string
    phoneNumber: string
    comments: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    isPostingReply: boolean
    isLoggedInDialog : boolean
    isDonationDialog : boolean
    donationAmount: string
    donationDetails: {
        name: string
        tagline: string
        heading: string
        description: string
        campaign_image: {
            id: number
            filename: string
            url: string
        }
    }
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class DonateController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start 
    getDonationDetailsApiCallId: string = ""
    leaveAReplyApiCallId: string = ""
    leaveReplyFormRef: any = React.createRef()
    initialValues: IInitialValues = {
        name: '',
        email: '',
        phoneNumber: '',
        comments: ''
    }
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            loader: false,
            isPostingReply: false,
            isLoggedInDialog: false,
            isDonationDialog: false,
            donationAmount: "",
            donationDetails: {
                name: "",
                tagline: "",
                heading: "",
                description: "",
                campaign_image: {
                    id: 0,
                    filename: "",
                    url: ""
                }
            }
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { match }: any = this.props
        const { id } = match.params

        if (id) this.getDonationDetailsApi(id)
        else this.props.history.goBack()
    }

    handleDonationValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        const isNumber = !isNaN(Number(value))
        isNumber && this.setState({ donationAmount: value })
    }

    handleDonationDialogOpen = async () => {
        this.setState({ isDonationDialog: true })
    }

    handleDonationDialogClose = () => {
        this.setState({ isDonationDialog: false })
    }

    handleLeaveReplySubmit = async (replyValues: IInitialValues) => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)

        if(!isLoggedIn) {
            this.setState({ isLoggedInDialog: true })
            return
        }
        this.postReplyForCampaign(replyValues)
    }

    getDonationDetailsApi = async (campaignId: string) => {
        this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.productApiContentType
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getDonationDetailsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDonationDetailsApiEndPoint}${campaignId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    postReplyForCampaign = async (replyValues: IInitialValues) => {
        this.setState({ isPostingReply: true })

        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const { name, phoneNumber, email, comments } = replyValues
        const httpBody = {
            leave_reply: {
                name,
                email,
                phone_number: phoneNumber,
                comment: comments
            }
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.leaveAReplyApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.donationLeaveReplyApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDonationDetailsApiCallId) {
            this.setState({
                loader: false,
                donationDetails: responseJson
            })
        }

        if (apiRequestCallId === this.leaveAReplyApiCallId) {
            this.setState({ isPostingReply: false })
            if(this.leaveReplyFormRef.current) this.leaveReplyFormRef.current.resetForm()
            toast.success("Reply added successfully")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if (apiRequestCallId === this.getDonationDetailsApiCallId) {
                    toast.error("Something went wrong")
                    this.props.history.goBack()
                }

                if (apiRequestCallId === this.leaveAReplyApiCallId) {
                    this.setState({ isPostingReply: false })
                    toast.success("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
