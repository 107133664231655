// Customizable Area Start
import React, { useState } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { isTokenExpired } from "../../../components/src/utility/helper"



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  routeUrl: any;
  history?: any;
  classes?: any;
  tabValue?: any;
  logout: any
}

interface S {
  arrayHolder: any;
  token: string;
  loading: boolean;
  mode: any;
  task_type: any;
  course_id: any;
  lesson_id: any;
  task_id: any;
  myRef: any;
  taskDetails: any;
  openDialog: any;
  schema: any;
  contributer_id: any;





}

interface SS {
  id: any;
}

export default class AdminLearningCourseContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  createMcqTaskApiCallId: string = "";
  getTaskDetailsApiCallId: string = "";
  deletePartApiCallId: string = "";
  deleteOptionApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      loading: false,
      token: "",
      mode: "",
      task_type: "",
      course_id: "",
      lesson_id: "",
      task_id: "",
      myRef: React.createRef(),
      taskDetails: "",
      openDialog: false,
      schema: "",
      contributer_id: ""



    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {


    let query = this.getQuery()
    let mode = query.get("mode")
    let course_id = query.get("course_id")
    let task_type = query.get("type")

    this.setState({
      mode: query.get("mode"),
      task_type: task_type,
      course_id: query.get("course_id"),
      lesson_id: query.get("lesson_id"),
      task_id: query.get("task_id"),
      contributer_id: query.get("contributer_id")
    })

    this.settingSchema(task_type)

    if (mode == "edit") {
      this.getTaskDetails(query.get("task_id"))
    }


  }





  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.successApiCall(responseJson, apiRequestCallId)


      }
      if (responseJson?.errors) {
        if (await isTokenExpired(responseJson)) return
        else {
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading: false
          })
        }
      }
    }

  }

  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId != null) {

      ///create task
      if (apiRequestCallId === this.createMcqTaskApiCallId) {
        const path = window.location.pathname.split("/")[4]
        const mode = this.state.mode == "create"
        toast.success(`Task ${mode ? "Created" : "Updated"} Successfully`)

        this.setState({
          loading: false,
        });
        this.props.history.push(
          `/admin/learning/add-course?mode=edit&course_id=${this.state.course_id}&contributer_id=${this.getQuery().get("contributer_id")}&lesson_id=${this.state.lesson_id}&tab=1`
        );


      }


      //get task details

      if (apiRequestCallId === this.getTaskDetailsApiCallId) {
        this.setState({
          taskDetails: responseJson.data,
          loading: false,

        })

      }

      //deleting task deletePartApiCallId
      if (apiRequestCallId === this.deletePartApiCallId) {
        this.setState({
          loading: false,
        })
        toast.success("Task Deleted Successfully")
        //@ts-ignore
        this.props.history.goBack();

      }

      //deleting option 
      if (apiRequestCallId === this.deleteOptionApiCallId) {
        //   let query = this.getQuery()
        // this.getTaskDetails(query.get("task_id"))
        this.setState({
          loading: false,
        })



      }





    }
  }


  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();

    return query
  }

  //creating createMcqTask  
  createMcqTask = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      token:
        token
    };
    const mode = this.state.mode == "create"
    const endPoint = configJSON.createMcqTaskAPiEndPoint + `${!mode ? `/${this.state.task_id}` : ""}`
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("points", values.task_point);
    values.titleImage.name && formdata.append("title_image", values.titleImage);
    formdata.append("task_status", values.task_status);
    formdata.append("course_sub_section_id", this.state.lesson_id);
    [...values.options].forEach((option: any, index: any) => {
      const isInteger = Number.parseInt(
        option.id
      );

      !option.attributes.option_image ?
        formdata.append(
          `options_attributes[${index}][${"title_option"}]`,
          option.attributes.title_option
        ) :
        (!option.attributes.option_image.url && formdata.append(
          `options_attributes[${index}][${"option_image"}]`,
          option.attributes.option_image[0]

        ))

      isInteger && formdata.append(
        `options_attributes[${index}][${"id"}]`,
        option.id
      )
    })
    const rightAnswer: any = []
    values.options.forEach((option: any, index: any) => {
      if (values.rightAnswer.includes(option.id)) {
        rightAnswer.push(index)
      }
    })

    rightAnswer.length > 1 ? rightAnswer.forEach((answer: any, index: any) => {
      formdata.append("right_answer[]",
        answer

      );


    }) :
      formdata.append("single_option_right_answer",
        rightAnswer[0]
      );

    //@ts-ignore
    formdata.append("multiple_answers", rightAnswer.length > 1);
    formdata.append("task_type", rightAnswer.length > 1 ? "options" : "single_option");


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMcqTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //creating Boolean Task  
  createBoolTask = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      // "Content-Type": configJSON.productApiContentType,
      token:
        token
    };
    const mode = this.state.mode == "create"
    const boolAnsValue = values.bool_value == "1" ? true : false
    const endPoint = configJSON.createMcqTaskAPiEndPoint + `${!mode ? `/${this.state.task_id}` : ""}`
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("points", values.task_point);
    values.titleImage.name && formdata.append("title_image", values.titleImage);
    formdata.append("task_type", "bool_answer");
    formdata.append("task_status", values.task_status);
    formdata.append("bool_answer", boolAnsValue.toString());
    formdata.append("course_sub_section_id", this.state.lesson_id);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMcqTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // creating long short answer
  createLongTask = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      // "Content-Type": configJSON.productApiContentType,
      token:
        token
    };
    const mode = this.state.mode == "create"
    const endPoint = configJSON.createMcqTaskAPiEndPoint + `${!mode ? `/${this.state.task_id}` : ""}`
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("points", values.task_point);
    values.titleImage.name && formdata.append("title_image", values.titleImage);
    formdata.append("task_type", this.state.task_type);
    formdata.append("task_status", values.task_status);
    formdata.append(`${this.state.task_type}`, values.answer);
    formdata.append("course_sub_section_id", this.state.lesson_id);
    formdata.append("word_limit", values.word_limit);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMcqTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //creating action Task  
  createActionTask = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      // "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const mode = this.state.mode == "create"
    const endPoint = configJSON.createMcqTaskAPiEndPoint + `${!mode ? `/${this.state.task_id}` : ""}`
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("title", values.title);
    values.titleImage.name && formdata.append("title_image", values.titleImage);
    formdata.append("task_status", values.task_status);
    formdata.append("task_type", this.state.task_type);
    formdata.append("course_sub_section_id", this.state.lesson_id);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createMcqTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  // get task details

  getTaskDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createMcqTaskAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //deleting task
  deletingtask = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createMcqTaskAPiEndPoint + `/${this.state.task_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  //deleting Option
  deletingOption = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteOptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteOptionAPiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleDialog = () => {

    this.setState({
      openDialog: !this.state.openDialog
    })
  }


  settingSchema = (type: any) => {

    const schemaMcq = Yup.object().shape({
      title: Yup.string().required("This field is required.").test("required", 'This field is required.', (value) => value != "<p><br></p>"),
      options: Yup.array()
        .required("Options are required.")
        .min(4),
      allOptiosFilled: Yup.string().when("options", {
        is: (options) => {
          const isEmpty = options.filter(
            (obj: any) =>
              !obj.attributes.title_option && !obj.attributes.option_image
          );
          return isEmpty.length > 0;
        },
        then: Yup.string().required("Options cannot be empty"),
      }),
      rightAnswer: Yup.array()
        .required("Please select right answer.")
        .min(1),
      task_point: Yup.number()
        .typeError("Points not valid")
        .required('This field is required.')
        .test(
          'Is positive?',
          'Points must be Positive',
          (value) => value > 0
        )
    });


    const schemaBool = Yup.object().shape({
      title: Yup.string().required("This field is required.").test("required", 'This field is required.', (value) => value != "<p><br></p>"),
      task_point: Yup.number()
        .typeError("Points not valid")
        .required('This field is required.')
        .test(
          'Is positive?',
          'Points must be Positive',
          (value) => value > 0
        )

    });

    const schemaLong = Yup.object().shape({
      title: Yup.string().required("This field is required.").test("required", 'This field is required.', (value) => value != "<p><br></p>"),
      task_type: Yup.string(),
      task_point: Yup.number()
        .typeError("Points not valid")
        .required('This field is required.')
        .test(
          'Is positive?',
          'Points must be Positive',
          (value) => value > 0
        ),
      word_limit: Yup.number()
        .typeError("Word limit not valid")
        .required('This field is required.')
        .test(
          'Is positive?',
          `Word limit must be in range of ${(type == "short_answer") ? "50-250" : "250-1000"}`,
          (value) => (type == "short_answer") ? (value > 50 && value <= 250) : (value > 250 && value <= 1000)
        ),
      answer: Yup.string().required("This field is required.").test({
        name: 'answer',
        exclusive: false,
        params: {},
        message: `Length must be less than or equal to word limit, Minimum words count should be more than ${(type == "short_answer") ? 50 : 250}`,
        test: function (value) {
          return value && (value.split(' ').length >= (this.parent.task_type == "short_answer" ? 0 : 251) && value.split(' ').length <= this.parent.word_limit)
        },
      }),
      // Yup.string()

    });
    const schemaAction = Yup.object().shape({
      title: Yup.string().required("This field is required.").test("required", 'This field is required.', (value) => value != "<p><br></p>"),
    });


    let newType = ""
    if (type == "mcq") {
      //@ts-ignore
      newType = schemaMcq
    }
    if (type == "true_false") {
      //@ts-ignore
      newType = schemaBool

    }
    if (type == "long_answer" || type == "short_answer") {
      //@ts-ignore
      newType = schemaLong

    }
    if (type == "action") {
      //@ts-ignore
      newType = schemaAction
    }
    this.setState({
      schema: newType
    })
  }

  taskInitialValue = (taskDetails: any) => {
    return (
      {
        title: taskDetails?.title || "",
        titleImage: taskDetails?.title_image || "",
        isMultiple:
          taskDetails?.multiple_answers || false,
        options: (taskDetails && taskDetails.options) || [],
        allOptiosFilled: "",
        isOptionIsImage: false,
        rightAnswer:
          (taskDetails &&
            (!taskDetails.multiple_answers
              ? [`${taskDetails.single_option_right_answer}`]
              : taskDetails.right_answer_image_id
                .toString()
                .split(","))) ||
          [],
        task_status: taskDetails?.task_status || "active",
        task_type:
          (taskDetails && taskDetails.task_type) ||
          this.getQuery().get("type"),

        task_point: taskDetails?.points || "",
        bool_value: this.gettingBoolValue(taskDetails),
        word_limit:
          taskDetails && taskDetails.word_limit,
        answer:
          (taskDetails && taskDetails[`${taskDetails.task_type}`]) || "",
      }
    )

  }

  gettingBoolValue = (taskDetails: any) => {
    if (taskDetails) {
      return taskDetails?.bool_answer ? "1" : "2"
    } else {
      return "1"
    }

  }

  handleTaskSubmit = (values: any) => {
    if (this.state.task_type == "mcq") {
      this.createMcqTask(values);
    }
    else if (this.state.task_type == "true_false") {
      this.createBoolTask(values);
    }
    else if (["long_answer", "short_answer"].includes(this.state.task_type)
    ) {
      this.createLongTask(values);
    }
    else if (this.state.task_type == "action") {
      this.createActionTask(values);
    }
  }


  optionsClick = (values: any, option: any, setFieldValue: any) => {
    const newArr = values.options.map(
      (obj: any) => {
        if (
          obj.id ==
          option.id
        ) {
          return {
            ...obj,
            attributes: {
              option_image:
                "",
            },
          };
        }

        return obj;
      }
    );

    setFieldValue(
      "options",
      newArr
    );
  }

  imageUploadChange = (e: any, values: any, option: any, setFieldValue: any) => {
    if (
      e
        .currentTarget
        .files
        .length > 0
    ) {
      const newArr = values.options.map(
        (
          obj: any
        ) => {
          if (
            obj.id ==
            option.id
          ) {
            return {
              ...obj,
              attributes: {
                option_image:
                  e
                    .currentTarget
                    .files,
                title_option:
                  "",
              },
            };
          }

          return obj;
        }
      );

      setFieldValue(
        "options",
        newArr
      );
    }
  }

  checkBoxChange = (e: any, values: any, option: any, setFieldValue: any) => {
    const filtered = values.rightAnswer.filter(
      (x: any) =>
        x !=
        option.id
    );
    if (values.isMultiple) {
      setFieldValue(
        "rightAnswer",
        e.target
          .checked
          ? [
            ...values.rightAnswer,
            option.id,
          ]
          : filtered
      )

    } else {
      setFieldValue(
        "rightAnswer",
        e.target
          .checked
          ? [
            option.id,
          ]
          : []
      );
    }

  }
  addOptionClick = (values: any, setFieldValue: any) => {
    values.options.length < 5 &&
      setFieldValue("options", [
        ...values.options,
        {
          id: new Date().toString(),
          attributes: {
            title_option: "",
            option_image: "",
          },
        },
      ]);
  }
  handleTaskHeadingText = () => {
    return this.state.mode == "create" ? "Create" : "Edit"
  }
  handlebuttonLabel = () => {
    return this.state.mode == "create"
      ? "Create Task"
      : "Save"
  }
  handleMultipleCheckSwitch = (e: any, setFieldValue: any) => {
    setFieldValue(
      "isMultiple",
      e.target.checked
    );
    !e.target.checked &&
      setFieldValue(
        "rightAnswer",
        []
      );

  }
  handleTexteditorchange = (values: any, option: any, setFieldValue: any, e: any) => {
    const newArr = values.options.map(
      (obj: any) => {
        if (
          obj.id == option.id
        ) {
          return {
            ...obj,
            attributes: {
              title_option:
                e.target
                  .value,
              option_image:
                "",
            },
          };
        }

        return obj;
      }
    );

    setFieldValue(
      "options",
      newArr
    );
  }
  handleContentCSS = (option: any) => {
    return option && "contentContainer1"
  }
  handleImageSrc = (option: any) => {
    return (
      option.attributes
        .option_image &&
      option.attributes
        .option_image[0] &&
      (
        window.URL ||
        window.webkitURL
      ).createObjectURL(
        option
          .attributes
          .option_image[0]
      )
    )
  }
  handleImageTitle = (option: any) => {
    if (option.option_image.url) {
      return option.option_image.filename
    } else {
      return option
        .option_image &&
        option
          .option_image[0]
          .name
    }
  }
  handleOptionDeleteClick = (option: any, values: any, setFieldValue: any) => {
    const isInteger = Number.parseInt(
      option.id
    );

    const filtered = values.options.filter(
      (item: any) =>
        item.id != option.id
    );

    setFieldValue(
      "options",
      filtered
    );

    isInteger &&
      this.deletingOption(
        option.id
      );
    toast.success(
      "Option Deleted Successfully"
    );
  }
  handlecharacterLength = (values: any) => {
    let maxLength = values.word_limit || 0
    let minLength = values.answer ? values.answer.split(' ').length : 0
    return `${minLength}/${maxLength}`
  }
  handleDraftCss = (status: any) => {
    return status ==
      "draft" &&
      "warning-variant"
  }
  handleDeactiveCss = (status: any) => {
    return status ==
      "deactive" &&
      "danger-variant"
  }
  handleStatus = (status: string) => {
    return status
  }
  handleShowAns = (type: any) => {
    if (this.state.mode == "edit") {
      return (this.state.task_type == type)
    } else {
      return true
    }
  }
  handleRadioToggle = (event: any, setFieldValue: any) => {
    this.props.history.push(`add-task?mode=create&course_id=${this.state.course_id}&lesson_id=${this.state.lesson_id}&contributer_id=${this.state.contributer_id}&type=${event}`)
    this.setState({
      task_type: event
    })
    setFieldValue("word_limit", "")
    setFieldValue("title", "")
    setFieldValue("task_point", "")
    setFieldValue("title_image", "")
    setFieldValue("task_type", event)
    this.settingSchema(event)
  }
  handleBackButton = () => {
    if (this.state.mode == "create") {
      this.props.history.push(`/admin/learning/add-course?mode=edit&course_id=${this.state.course_id}&contributer_id=${this.state.contributer_id}&tab=1`)
    } else {
      this.props.history.goBack()
    }
  }
}
    // Customizable Area End