// Customizable Area Start
import React from "react";
import { EditIcon, DeleteIcon, ProfileUpload } from "./assets";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  Typography,
  withStyles,
  TextField,
  TextareaAutosize,
  Modal,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import AdminLearningCourseContentController from "./AdminLearningCourseContentController.web";
import { commonCmptStyles } from "./dropDownCss";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//@ts-ignore
import McqImg from "../assets/images/image-mcq.png";
//@ts-ignore
import TrueFalseImg from "../assets/images/image-truefalse.png";
//@ts-ignore
import ShortAnsImg from "../assets/images/image-shortanswer.png";
//@ts-ignore
import LongAnsImg from "../assets/images/image-longanswer.png";
//@ts-ignore
import actionImg from "../assets/images/image-action.png";
import Loader from "../../../components/src/Loader.web";
import VideoInput from "../../../components/src/videoInput.web";

class AdminLearningCourseContentWeb extends AdminLearningCourseContentController {
  constructor(props: any) {
    super(props);
  }
  render() {
    const courseContent =
      this.state.courseContent &&
      this.state.courseContent.attributes.course_sections.data;
    const editDetails =
      this.state.editPartDetails && this.state.editPartDetails.attributes;
    const TaskOptions = [
      { name: "mcq", banner: McqImg },
      { name: "true_false", banner: TrueFalseImg },
      { name: "short_answer", banner: ShortAnsImg },
      { name: "long_answer", banner: LongAnsImg },
      { name: "action", banner: actionImg },
    ];
    return (
      <>
        <Loader loading={this.state.loading} />
        <Box className="productTitleContainer learningCourseContainer contentContainerScroll">
          <Box className="learningCourseContent">
            <Typography>
              Here's where you add course content-like course sections. Click a
              + icon to get started.
            </Typography>
            {courseContent.length > 0 &&
              courseContent.map((part: any, index: any) => {
                return (
                  <Box key={part.id} className="partContainerScroll">
                    <Box className="header-content">
                      <div className="left-block">
                        <h4>
                          Part {index + 1}: [ {`${part.attributes.name}`} ]
                        </h4>
                        <Button
                        data-testid="createPart"
                          onClick={() => {
                            this.setState({
                              editPart: true,
                              editPartDetails: part,
                            });
                            this.handleOpenCreatePartModal();
                          }}
                        >
                          <img
                            src={EditIcon}
                            className="edit-icon"
                            alt="Edit Icon"
                          />
                        </Button>
                      </div>
                      <Button
                      id="deletePart"
                        onClick={() => {
                          this.setState({
                            editPartDetails: part,
                          });
                          this.handleDialog();
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          className="edit-icon"
                          alt="Edit Icon"
                        />
                      </Button>
                    </Box>
                    <Box className="lessonAccordianContainer">
                      {part.attributes.course_sub_sections.data.length > 0 &&
                        part.attributes.course_sub_sections.data.map(
                          (lesson: any, index: any) => {
                            return (
                              <Accordion
                                className="lessonAccordian"
                                key={lesson.id}
                                ref={this.settingRefs(lesson.id)}
                                expanded={this.state.expandedLessonIds.includes(lesson.id)}
                                onChange={(e:any,expanded:any)=>{
                                  this.settingAccordian(expanded,lesson.id)
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Box className="accordionHeader">
                                    <h6 className="lessonHead">
                                      Lesson {index + 1} -
                                      {lesson.attributes.heading}
                                    </h6>
                                    <div
                                      className="right-block"
                                      id="rightBlock"
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Button
                                      id="createlesson"
                                        onClick={() => {
                                          this.setState({
                                            editPart: true,
                                            editPartDetails: lesson,
                                          });
                                          this.handleOpenLessonModal();
                                        }}
                                      >
                                        <img
                                          src={EditIcon}
                                          className="edit-icon"
                                          alt="Edit Icon"
                                        />
                                      </Button>
                                      <h6>Edit Lesson</h6>
                                      <Button
                                       id="deletelesson"
                                        onClick={() => {
                                          this.setState({
                                            editPartDetails: lesson,
                                          });
                                          this.handleDialog();
                                        }}
                                      >
                                        <img
                                          src={DeleteIcon}
                                          className="edit-icon"
                                          alt="Edit Icon"
                                        />
                                      </Button>
                                    </div>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography className="lessonDescription">
                                    {lesson.attributes.description}
                                  </Typography>
                                  <Box className="titleBox">
                                    <h6>Video</h6>
                                  </Box>
                                  <Box className="video-imgblock">
                                    <VideoInput
                                      width="100px"
                                      height="100px"
                                      fileData={lesson.attributes?.video?.url}
                                    />
                                    <Box className="captionBlock">
                                <h6 className="img-title">
                                  {lesson.attributes?.video?.filename}
                                </h6>
                                </Box>
                                  </Box>
                                  <Box className="titleBox">
                                    <h6>Resource(Optional):</h6>
                                  </Box>

                                  <Box className="lesson-row chipContainer">
                                    <Box className="upload-chip">
                                      <h6>
                                        {lesson.attributes?.attachment?.filename ||
                                          "No resources found"}
                                      </h6>
                                    </Box>
                                  </Box>
                                  <Box className="titleBox">
                                    <h6>Task (Optional):</h6>
                                  </Box>
                                  <Box className="taskOptionBlock">
                                    {lesson.attributes.tasks.data.length > 0 &&
                                      lesson.attributes.tasks.data.map(
                                        (task: any, index: any) => {
                                        let wrapper= document.createElement('span');
                                           wrapper.innerHTML= task.attributes.title 
                                        return (
                                            <div key={task.id}>
                                              <Box
                                                className="task-content"
                                                key={task.id}
                                              >
                                                <div className="left-block" key={task.id}>
                                                  <h6>Task {index + 1}</h6>
                                                  <Button
                                                    data-testid="task-redirection"
                                                    onClick={() => {
                                                      this.props.history.push(
                                                        `/admin/learning/add-task?mode=edit&course_id=${this.state.course_id
                                                        }&lesson_id=${lesson.id
                                                        }&contributer_id=${this.getQuery().get('contributer_id')}&task_id=${task.id
                                                        }&type=${this.findTaskType(
                                                          task.attributes
                                                            .task_type
                                                        )}`
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={EditIcon}
                                                      className="edit-icon"
                                                      alt="Edit Icon"
                                                    />
                                                  </Button>
                                                </div>
                                                <Button
                                                  onClick={() => {
                                                    this.handleDialog();
                                                    this.setState({
                                                      editPartDetails: task,
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src={DeleteIcon}
                                                    className="edit-icon"
                                                    alt="Edit Icon"
                                                  />
                                                </Button>
                                              </Box>
                                              <div
                                                className="customTextAreafield"
                                                style={{
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                <div className="taskDescrip" dangerouslySetInnerHTML={{__html: task.attributes.title}}></div>

                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Box>
                                  <span className="greenOutlineButton">
                                    <CustomButton
                                      onClick={() => {
                                        this.handleOpenAddTaskModal();
                                        this.setState({
                                          lesson_id: lesson.id,
                                        });
                                      }}
                                      color="secondary"
                                      label="Add Task"
                                    />
                                  </span>
                                </AccordionDetails>
                              </Accordion>
                            );
                          }
                        )}
                    </Box>
                    <Box className="learningCourseFooter">
                      {courseContent && (
                        <span className="greenContained">
                          <CustomButton
                            color="secondary"
                            onClick={() => {
                              this.setState({
                                editPartDetails: part,
                              });
                              this.handleOpenLessonModal();
                            }}
                            label="Add Lesson"
                          />
                        </span>
                      )}
                    </Box>
                  </Box>
                );
              })}

            <Box className="learningCourseFooter">
              <Box>
                <Button
                  onClick={this.handleOpenCreatePartModal}
                  className="add-part-row"
                >
                  <span className="plus-icon">+</span>
                  <span>Add Part</span>
                </Button>
              </Box>
            </Box>
          </Box>

          {/* add part modal */}
          <Modal
            open={this.state.creatPartModal}
            onClose={this.handleCloseCreatePartModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Formik
              id="submitPart"
              enableReinitialize
              initialValues={{
                name:
                  (this.state.editPartDetails &&
                    this.state.editPartDetails.attributes.name) ||
                  "",
                course_id: this.state.courseContent.id,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("This field is required."),
              })}
              onSubmit={async (values: any) => {
                await this.createPart(values);
                this.handleCloseCreatePartModal();
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  handleChange,
                } = formikProps;
                return (
                  <Box className="mainModal modal-md">
                    {/* <h1>modal open </h1> */}
                    <Form noValidate autoComplete="off">
                      <div className="subModalBlock">
                        <div className="modalHeader">
                          <h4>
                            {this.handleLessonText()} Part
                          </h4>
                          <CloseIcon
                            className="closeIcon"
                            onClick={this.handleCloseCreatePartModal}
                          />
                        </div>
                        <div className="modalContent learningAddPartModalContent">
                          <div className="formContainer">
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  id="name"
                                  variant="standard"
                                  label="Part name"
                                  placeholder="Enter part name"
                                  value={values.name}
                                  onChange={handleChange}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="name" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="btnContainer">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={5}>
                                <span className="redOutlineButton">
                                  <CustomButton
                                    id="cancelPart"
                                    color="secondary"
                                    onClick={() =>
                                      this.handleCloseCreatePartModal()
                                    }
                                    label="Cancel"
                                  />
                                </span>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <span className="greenContained">
                                  <CustomButton
                                    color="secondary"
                                    label={this.handlePartModalbuttonText()}
                                    type="submit"
                                  />
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Modal>
          {/* add part modal  end*/}

          {/* add lesson modal */}

          <Modal
            open={this.state.creatLessonModal}
            onClose={this.handleCloseLessonModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
          >
            <Formik
            id="submitlesson"
              enableReinitialize
              initialValues={this.courseDetailInitialValues(editDetails)}
              validationSchema={Yup.object().shape({
                heading: Yup.string().required("This field is required."),
                description: Yup.string().required("This field is required."),
                attachment: Yup.string(),
                video: Yup.string(),
              })}
              onSubmit={(values: any) => {
                this.createLesson(values);
                this.setState({
                  creatLessonModal: false,
                })
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  setFieldValue,
                  handleChange,
                } = formikProps;
                return (
                  <Box className="mainModal modal-md">
                    <Form noValidate autoComplete="off">
                      <div className="subModalBlock">
                        <div className="modalHeader">
                          <h4>
                            {" "}
                            {this.handleLessonText()}{" "}
                            Lesson
                          </h4>
                          <CloseIcon
                            className="closeIcon"
                            onClick={this.handleCloseLessonModal}
                          />
                        </div>
                        <div className="modalContent learningAddPartModalContent">
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="heading"
                                variant="standard"
                                label="Lesson Heading"
                                placeholder="Enter Lesson Heading..."
                                value={values.heading}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="heading" />
                              </span>
                            </div>
                          </div>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield customTextAreafield">
                              <br />
                              <label>Lesson Description</label>
                              <TextareaAutosize
                                id="description"
                                className="description"
                                minRows={2}
                                value={values.description}
                                onChange={handleChange}
                                placeholder="Description"
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="description" />
                              </span>
                            </div>
                          </div>
                          <Box className="titleBox">
                            <h6>Video</h6>
                          </Box>
                          {values.video && (
                            <Box className="video-imgblock">
                              <VideoInput
                                width="100px"
                                height="100px"
                                fileData={values.videoUrl}
                                mode="create"
                                duration={values.videoDuration}
                                setFieldValue={setFieldValue}
                              />
                              <Box className="captionBlock">
                                <h6 className="img-title">
                                  {values.videoName}
                                </h6>
                                <img
                                  id="deleteVideo"
                                  src={DeleteIcon}
                                  className="deleteIcon"
                                  alt="Delete Img"
                                  onClick={() => {
                                    setFieldValue("video", "");
                                    setFieldValue("videoName", "");
                                    setFieldValue("videoUrl", "");
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                          {!values.video && (
                            <Box className="lesson-row">
                              <div className="mediaContainer forumsMediaContainer">
                                <div className="mediaUploader videoMediaUploader">
                                  <label>
                                    <input
                                      id="banner"
                                      name="banner"
                                      data-testid="mp4"
                                      type="file"
                                      hidden
                                      accept="video/mp4"
                                      onChange={(event: any) => {
                                        const file = event.target.files[0];
                                        const url = URL.createObjectURL(file);
                                        setFieldValue("videoUrl", url);
                                        setFieldValue("video", file);
                                        setFieldValue("videoName", file.name);
                                      }}
                                    />

                                    <img
                                      src={ProfileUpload}
                                      className="imageUploadIcon"
                                    />

                                    <span>Upload Video</span>
                                  </label>
                                </div>
                              </div>
                              <p>(Upload Only mp4)</p>
                            </Box>
                          )}
                          <span className="errorMsg">
                            <ErrorMessage name="video" />
                          </span>
                          <Box className="titleBox">
                            <h6>Resource(Optional):</h6>
                          </Box>
                          {!values.attachment && (
                            <Box className="lesson-row">
                              <div className="mediaContainer forumsMediaContainer">
                                <div className="mediaUploader videoMediaUploader">
                                  <label>
                                    <input
                                    data-testid="uploadImg"
                                      id="banner"
                                      name="banner"
                                      type="file"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg,.pdf,.docx,.doc"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "attachment",
                                          e.currentTarget.files
                                        );
                                        setFieldValue(
                                          "attachmentName",
                                          e.currentTarget.files[0].name
                                        );
                                      }}
                                    />
                                    <img
                                      src={ProfileUpload}
                                      className="imageUploadIcon"
                                    />
                                    <span>Upload File</span>
                                  </label>
                                </div>
                              </div>
                              <p>(Upload Only .png, .pdf, .jpeg, or .docx)</p>
                            </Box>
                          )}
                          {values.attachment && (
                            <Box className="lesson-row chipContainer">
                              <Box className="upload-chip">
                                <h6>{values.attachmentName}</h6>
                                <CloseIcon
                                  className="closeIcon"
                                  data-testid="uploadCancelImg"
                                  onClick={() => {
                                    setFieldValue("attachment", "");
                                    setFieldValue("attachmentName", "");
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                          <span className="errorMsg">
                            <ErrorMessage name="attachment" />
                          </span>
                          <div className="btnContainer">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={5}>
                                <span className="redOutlineButton">
                                  <CustomButton
                                    color="secondary"
                                    onClick={this.handleCloseLessonModal}
                                    label="Cancel"
                                  />
                                </span>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <span className="greenContained">
                                  <CustomButton
                                    color="secondary"
                                    label={this.handlePartModalbuttonText()}
                                    type="submit"
                                  />
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Modal>
          {/* add lesson modal end */}

          <Modal
            open={this.state.addTaskModal}
            onClose={this.handleCloseAddTaskModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="mainModal modal-md">
              <div className="subModalBlock">
                <div className="modalHeader selectTypeHeader ">
                  <h4>Select Task Type</h4>
                </div>
                <div className="modalContent learningAddPartModalContent selectTaskModalContent ">
                  <div className="carContainer learningTaskCheckbox">
                    <Grid container spacing={5}>
                      {TaskOptions.map((task: any) => {
                        return (
                          <Grid item xs={12} sm={6} lg={4} key={task.id}>
                            <input
                              type="checkbox"
                              id={task.name}
                              checked={this.handleTaskCheckBox(task)
                              }
                              onChange={(e: any) => {
                                this.setState({
                                  task_checkbox: e.target.id,
                                });
                              }}
                            />
                            <label
                              htmlFor={task.name}
                              className="card"
                              onClick={this.handleOpenAddMcqQAModal}
                            >
                              <div className="imgContainer">
                                <img src={task.banner} alt="img" />
                              </div>
                              <p>{this.findTaskLabel(task.name)}</p>
                            </label>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                  <div className="btnContainer">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <span className="redOutlineButton">
                          <CustomButton
                            color="secondary"
                            onClick={() => {
                              this.handleCloseAddTaskModal();
                              this.setState({
                                task_checkbox: "mcq",
                              });
                            }}
                            label="Cancel"
                          />
                        </span>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <span className="greenContained">
                          <CustomButton
                            color="secondary"
                            onClick={() => {
                              this.props.history.push(
                                `/admin/learning/add-task?mode=create&course_id=${this.state.course_id
                                }&lesson_id=${this.state.lesson_id
                                }&contributer_id=${this.getQuery().get('contributer_id')}&type=${this.findTaskType(
                                  this.state.task_checkbox
                                )}`
                              );
                            }}
                            disabled={!this.state.task_checkbox}
                            label="Create"
                          />
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* remove modal */}
          <div>
            <Dialog
              data-testid="dialog"
              open={this.state.openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete this ?
              </DialogTitle>
              <DialogActions>
                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    data-testid="cancelBtn"
                    onClick={() => this.handleDialog()}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-testid="greenBtn"
                    color="secondary"
                    label="Yes"
                    onClick={() => {
                      this.handleDialog();
                      this.deletePart();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </>
    );
  }
}

export{AdminLearningCourseContentWeb}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningCourseContentWeb)
);
// Customizable Area End
