Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpPostMethod = "POST";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.btnExampleTitle = "CLICK ME";

exports.SQUARE_TOKEN_KEY = 'code'
exports.SQUARE_ERROR_KEY = 'error'
exports.AUTH_TOKEN = "authToken"
exports.SOMETHING_WENT_WRONG_MESSAGE = "Something went wrong"
exports.SQUARE_CONNECT_SUCCESS_MESSAGE = "Square account connected successfully"
exports.ACCESS_DENIED_ERROR_MESSAGE = "Square connection access was denied"

exports.squareTokenApiEndPoint = "account_block/accounts/set_seller_refresh_token"
// Customizable Area End