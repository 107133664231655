// Customizable Area Start
import React from "react"
import "web/src/assets/css/style.scss"
import AddCardDialogController, { Props } from "./AddCardDialogController.web"
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Dialog,
} from "@material-ui/core"
import "../../assets/css/checkout/checkoutccard.scss"
import { connect } from "react-redux"
import {
  PaymentForm,
  CreditCard,
  CreditCardPayButtonProps,
} from "react-square-web-payments-sdk"
import clsx from "clsx"
import CheckIcon from "@material-ui/icons/CheckRounded"
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom"
const configJSON = require("../../config.js")

const CardComponentStyle = {
  ".input-container.is-focus": {
    borderColor: "#20a258",
  },
  ".input-container.is-error": {
    borderColor: "#cc0023",
  },
  input: {
    fontSize: "16px",
    color: "#394053",
  }
}

class AddCardDialog extends AddCardDialogController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { isOpen, isOnlySave, handleClose } = this.props
    const { isCardLoader, isSaveCard, errorMsg } = this.state
    const btnText = isSaveCard ? "Add Card" : "Proceed to Pay"

    return (
      <>
        <Dialog
          maxWidth="xs"
          fullWidth
          open={isOpen}
          onClose={() => handleClose()}
          classes={{
            paper: "shopping_cart_address_dialog",
          }}
        >
          <Box className="add_card_form_wrapper">
            <Box className="forgot_PW_tab_container">
              <Typography variant="subtitle1" className="add_card_heading">
                Add New Card
              </Typography>
            </Box>
            <Box mt={5}>
              <PaymentForm
                applicationId={configJSON.SQUARE_SANDBOX_API_KEY}
                locationId={configJSON.SQUARE_LOCATION_ID}
                cardTokenizeResponseReceived={this.handleCardTokenizeResponse}
              >
                <CreditCard
                  style={CardComponentStyle}
                  render={(Button: React.FC<CreditCardPayButtonProps>) => (
                    <Box mt={1}>
                      {!isOnlySave && <Box mb={2} display="flex" alignItems="center">
                        <IconButton
                          className={clsx("custom_save_radio", {
                            ["custom_check_save_radio"]: isSaveCard,
                          })}
                          onClick={this.toggleSaveCardCheckbox}
                        >
                          {isSaveCard && <CheckIcon />}
                        </IconButton>
                        <Typography className="save_card_header">
                          Save card
                        </Typography>
                      </Box>}

                      <Button
                        isLoading={isCardLoader}
                        className="btn card_form_btn"
                      >
                        {isCardLoader ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          btnText
                        )}
                      </Button>
                    </Box>
                  )}
                />
              </PaymentForm>

              {!!errorMsg && <Box mt={2}>
                <Alert severity="error">
                  {errorMsg}
                </Alert>
              </Box>}
            </Box>
          </Box>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    checkoutOrder: state.user.checkoutOrder
  }
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(AddCardDialog))
// Customizable Area End
