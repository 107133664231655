// Customizable Area Start
import React from "react"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  button: {
    padding: "12px 10px",
    textTransform: "none",
    backgroundColor: "#f7f7f7",
    borderRadius: "8px",
    width: "100%",
    "& .MuiButton-startIcon": {
      marginRight: "5px"
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#263c78",
    },
  },
  socialImg: {
    width: "21px",
  }
})

interface ICustomShareButtonProps {
  icon: string
  btnText: string
  onClick?: () => void
}

const CustomShareButton = ({
  icon,
  btnText,
  onClick,
}: ICustomShareButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      startIcon={<img src={icon} alt="icon" className={classes.socialImg} />}
      className={classes.button}
      onClick={() => onClick && onClick()}
    >
      {btnText}
    </Button>
  )
}

export default CustomShareButton
// Customizable Area End
