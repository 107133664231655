import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import StorageProvider from "../../../../framework/src/StorageProvider";
import { isTokenExpired } from "../../../../components/src/utility/helper";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    userInfo: any
    LPIsReOrder: boolean
    LPLatestUpdatesDesc: string
    LPBodyDesc: string
    setLPData: (data: any) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LandingPageLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    llpApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            loader: false
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.lpApi()
    }

    handleReOrderRedirect = () => {
        this.props.history.push("/order-history")
    }

    lpApi = async () => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.llpApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.llpApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.llpApiCallId) {
            if(!!responseJson?.data?.attributes) {
                const { setLPData } = this.props
                setLPData(responseJson.data.attributes)
                this.setState({ loader: false })
                return
            }
            toast.error("Something went wrong")
        }
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(await isTokenExpired(responseJson)) return

                if (apiRequestCallId === this.llpApiCallId) {
                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
}