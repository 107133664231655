const initialState = {
  dishCategoriesLoader: false,
  dishCategories: [],
};

export const userAdminRestaurantReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "SET_DISH_CATEGORIES":
      return {
        ...state,
        dishCategories: payload,
      };
    case "SET_DISH_CATEGORIES_LOADER":
      return {
        ...state,
        dishCategoriesLoader: payload,
      };
    default:
      return state;
  }
};
