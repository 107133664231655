// Customizable Area Start
import React from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

interface IProps {
  heading: string
  children: any
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    borderRadius: '6px',
    border: '2px solid #f7f5f9',
    padding: '20px 25px',
    margin: '15px 0px'
  },
  heading: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
}))

const WishlistContentWrapper = ({ heading, children }: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.contentWrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.heading}>{heading}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default WishlistContentWrapper
// Customizable Area End
