// Customizable Area Start
import React from "react"
import AdminSidebarController, { Props } from "./AdminSidebarController.web"
import { withRouter } from "react-router-dom"
import "./assets/css/sidebar.scss"
import "../../web/src/assets/css/content/list.scss"
import {
  activeLeaf,
  restaurantIcon,
  activeRestIcon,
  leaf,
  book,
  activeBook,
} from "./assets"
import CloseIcon from "@material-ui/icons/Close"
import CustomTextfield from "./CustomTextfield.web"
import CustomList from "./AdminList.web"
import { InputAdornment, ListItemText } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import AdminSearch from "../../blocks/Search/src/AdminSearch/AdminSearch.web"

const AD_CAMPAIGN_PATHS = ["ad-campaigns", "ad-campaign"]

interface IMenu {
  type: string
  name: string
  subdomain: string
  subMenu: {
    name: string
    route: string
  }[]
  icon: any
  activeIcon: any
  subMenuClick: (route: string) => Promise<void> | void
}

class AdminSidebar extends AdminSidebarController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const path = window.location.pathname.split("/")[2]
    const wholePath = window.location.pathname.split("/")
    const { roles } = this.state
    const { toggle, history, sidebarToggle } = this.props

    const restaurantSubMenu = [
      {
        name: "orders",
        route: "orders",
      },
      {
        name: "dishes",
        route: "dishes",
      },
      {
        name: "details",
        route: "details",
      },
      {
        name: "forums",
        route: "forums",
      },
      {
        name: "campaigns",
        route: "campaigns",
      },
    ]

    const farmSubMenu = [
      {
        name: "orders",
        route: "orders/00",
      },
      {
        name: "Weight & Dimensions",
        route: "weight-dimensions",
      },
      {
        name: "products",
        route: "products",
      },

      {
        name: "details",
        route: "details",
      },
      {
        name: "forum",
        route: "forums",
      },
      {
        name: "campaigns",
        route: "campaigns",
      },
    ]

    const learningSubMenu = [
      {
        name: "details",
        route: "details",
      },
      {
        name: "my courses",
        route: "course-list",
      },
      {
        name: "students",
        route: "students",
      },
      {
        name: "purchases",
        route: "purchases",
      },
      {
        name: "campaigns",
        route: "campaigns",
      },
      {
        name: "discussion",
        route: "discussion",
      },
    ]

    const handleClickSubmenu = async (value: string) => {
      switch (value) {
        case "orders":
        case "details":
        case "campaigns":
          history.push(`/admin/restaurant/${value}`)
          break
        case "dishes":
        case "forums":
          history.push(`/admin/restaurant/${value}/00`)
          break
        default:
          break
      }
    }

    const handleClickFarmSubmenu = (value: string) => {
      switch (value) {
        case "customers":
        case "orders/00":
        case "details":
        case "campaigns":
        case "weight-dimensions":
          history.push(`/admin/farm/${value}`)
          break
        case "products":
        case "forums":
          history.push(`/admin/farm/${value}/00`)
          break
        default:
          break
      }
    }

    const handleClickLearningMenu = (value: string) => {
      switch (value) {
        case "purchases":
          this.props.history.push(`/admin/learning/${value}`)
          break
        case "details":
          this.getContibuterdetails()
          break
        case "course-list":
          this.getContibuter()
          break
        case "students":
          this.getContibuterstudents()
          break
        case "campaigns":
          history.push(`/admin/learning/${value}`)
          break
        case "discussion":
          this.getContibuterdiscussion()
          break
        default:
          break
      }
    }

    const MENU_LIST: IMenu[] = [
      {
        type: "Farmer/merchant",
        name: "My Farm",
        subdomain: "farm",
        subMenu: farmSubMenu,
        icon: leaf,
        activeIcon: activeLeaf,
        subMenuClick: handleClickFarmSubmenu,
      },
      {
        type: "Restaurateur",
        name: "Restaurant",
        subdomain: "restaurant",
        subMenu: restaurantSubMenu,
        icon: restaurantIcon,
        activeIcon: activeRestIcon,
        subMenuClick: handleClickSubmenu,
      },
      {
        type: "Contributor",
        name: "Contributors",
        subdomain: "learning",
        subMenu: learningSubMenu,
        icon: book,
        activeIcon: activeBook,
        subMenuClick: handleClickLearningMenu,
      },
    ]

    return (
      <div className={`sidebar  ${toggle && "toggleSidebar"}`}>
        <div className="close-icon-wrapper">
          <CloseIcon
            className="closeIcon"
            onClick={() => {
              sidebarToggle()
            }}
          />
        </div>
        <div className="headerContentSm">
          <div className="blocks block2">
            <div className="form-group">
              <AdminSearch isMobileSearchPage navigation={this.props.navigation} />
            </div>
          </div>
        </div>
        <ul className="nav nav-pills flex-column" id="pills-tab" role="tablist">
          {MENU_LIST.filter((menu: IMenu) => roles.includes(menu.type)).map(
            (menu: IMenu) => {
              const {
                name,
                subdomain,
                subMenu,
                icon,
                activeIcon,
                subMenuClick,
              } = menu
              const isActive = subdomain === path

              return (
                <li className="nav-item" role="presentation" key={name}>
                  <CustomList
                    className={`nav-link ${isActive ? "active" : ""}`}
                    route={wholePath}
                    component={name}
                    subMenu={subMenu}
                    subMenuClick={subMenuClick}
                    toggle={toggle}
                    sidebarToggle={sidebarToggle}
                    subdomain={subdomain}
                    leftIcon={isActive && "active" ? activeIcon : icon}
                    getContibuterdetails={this.getContibuterdetails}
                  />
                </li>
              )
            }
          )}
          {roles.length > 0 && (
            <div className="customList" onClick={this.handleAdCampaignRoute}>
              <li
                className={`listItems ${
                  AD_CAMPAIGN_PATHS.includes(path) ? "listItemsActive" : ""
                }`}
              >
                <button className="ad-campaign-sidebar-btn">
                  <ListItemText primary="Ad campaigns" />
                </button>
              </li>
            </div>
          )}
        </ul>
      </div>
    )
  }
}

//@ts-ignore
export default withRouter(AdminSidebar)
// Customizable Area End
