// Customizable Area Start
import React, { Component } from "react"
import { Grid, Box, Button, Container } from "@material-ui/core"
import { connect } from "react-redux"
import "./assets/css/greenbanner.scss"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "./utility/app-routing"

interface IProps extends RouteComponentProps {
  FooterBannerDescription: string
}

class GreenBanner extends Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  handleStartShoppingRedirect = () => {
    this.props.history.push(AppRoutings.FarmsMarket)
  }

  render() {
    const { FooterBannerDescription } = this.props

    return (
      <>
        <Box className="green_banner_new">
          <Container>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={7} lg={8}>
                <h3 className="banner_text">{FooterBannerDescription}</h3>
              </Grid>
              <Grid item xs={12} sm={5} lg={4} className="btnBlock">
                <Button
                  classes={{ root: "green_banner_btn", label: "btn-label" }}
                  onClick={this.handleStartShoppingRedirect}
                >
                  Start Shopping
                </Button>
              </Grid>
            </Grid>
            <Grid />
          </Container>
        </Box>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    FooterBannerDescription: state.cms.FooterBannerDescription,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(GreenBanner))
// Customizable Area End
