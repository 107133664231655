// Customizable Area Start
import React from "react"
import { Box, Button, Grid } from "@material-ui/core"
import { womenChefImg } from "../../assets"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

interface IRLPSubmitRestaurant extends RouteComponentProps {
    RLPSubmitHeading: string
    RLPSubmitDescription: string
}

const RLPSubmitRestaurant = ({
    history,
    RLPSubmitHeading,
    RLPSubmitDescription,
}: IRLPSubmitRestaurant) => {
    const handleSubmitRestaurant = () => {
        history.push(AppRoutings.CreateRestaurantProfile)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={5} lg={7} xl={6}>
                <Box className="woman_chef_img_wrapper">
                    <img src={womenChefImg} alt="woman_chef_img" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={7} lg={5} xl={6}>
                <Box className="restaurant_list_content_wrapper">
                    <Button
                        className="restaurant_submit_green_btn"
                        classes={{ label: "restaurant_submit_green_btn_color" }}
                    >
                        Submit
                    </Button>
                    <h1 className="submit_restaurant_heading">{RLPSubmitHeading}</h1>
                    <p className="res_bottom_text">{RLPSubmitDescription}</p>
                    <Button
                        className="rest_submit_orange_btn"
                        classes={{ label: "restaurant_submit_green_btn_color" }}
                        onClick={handleSubmitRestaurant}
                    >
                        Submit
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: any) => {
    return {
        RLPSubmitHeading: state.cms.RLPSubmitHeading,
        RLPSubmitDescription: state.cms.RLPSubmitDescription,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(RLPSubmitRestaurant)
)
// Customizable Area End
