// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import StorageProvider from "../../../../../framework/src/StorageProvider"
export const configJSON = require("../../config.js")

export interface IDiscussion {
    id: string
    type: string
    attributes: {
        account_id: number
        author_name: string
        comment_count: number
        date: string
        description: string
        discussion_heading: string
        follows: boolean
        forum_comments: any[]
        topic_id: number
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    communityLatestForumsDesc: string
    communityLatestForums: IDiscussion[]
    setCommunityLatestForums: (discussions: IDiscussion[]) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    isLoginDialog: boolean
    isNewDiscussionDialog: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityLatestForumsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    latestForumsApiCallId: string = ""
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            isLoginDialog: false,
            isNewDiscussionDialog: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getLatestForumsApi()
    }

    openDiscussionDialog = () => {
        this.setState({ isNewDiscussionDialog: true })
    }

    closeDiscussionDialog = () => {
        this.setState({ isNewDiscussionDialog: false })
    }

    handleAddNewDiscussion = async () => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
        if(!isLoggedIn) {
            this.handleLoginDialogOpen()
            return
        }
        this.openDiscussionDialog()
    }

    handleLoginDialogOpen = () => {
        this.setState({ isLoginDialog: true })
    }

    handleLoginDialogClose = () => {
        this.setState({ isLoginDialog: false })
    }

    handleForumViewAll = async () => {
        const isLoggedIn = await StorageProvider.get(configJSON.AUTH_TOKEN)
        if(!isLoggedIn) {
            this.setState({ isLoginDialog: true })
            return
        }
        this.props.history.push(AppRoutings.CommunityDiscussion)
    }

    getLatestForumsApi = async () => {
        const { communityLatestForums } = this.props
        const isForumsExist = communityLatestForums?.length > 0
        !isForumsExist && this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.productApiContentType
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.latestForumsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.communityDiscussionsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.latestForumsApiCallId) {
                    const { setCommunityLatestForums } = this.props
                    setCommunityLatestForums(responseJson?.data || [])
                    this.setState({ loader: false })
                }
            } else {
                if (apiRequestCallId === this.latestForumsApiCallId) {
                    this.setState({ loader: false })
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}

