import React from "react"
import { Grid, Box } from "@material-ui/core"
import "../assets/css/FarmsPage.scss"
import Heading from "../../../../components/src/HeadingComponent.web"

type Props = {
  heading: string
}

const FarmHeadingWithFilterInput = (props: Props) => {
  const { heading } = props

  return (
    <Grid item xs={12} sm={8} md={6} lg={4}>
      <Box className="filter_heading_wrapper">
        <Heading>{heading}</Heading>
      </Box>
    </Grid>
  )
}

export default FarmHeadingWithFilterInput
