// Customizable Area Start
import { Dialog, DialogTitle, DialogActions, InputAdornment, TextField, Box } from "@material-ui/core";
import * as React from "react";
import useState from "react";
import CustomButton from "../../../components/src/CustomButton.web";
import "../../../components/src/assets/css/header.scss";
import Loader from "../../../components/src/Loader.web";
import SearchIcon from "@material-ui/icons/Search";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminFarmlist.scss";
import { withRouter } from "react-router-dom";
import AdminRestaurantAddDetailsController, { Props } from "./AdminRestaurantAddDetailsController.web";
import CustomRestDetailsTab from "../../../components/src/CustomRestDetailsTab.web";
import Button from "../../../components/src/Button";
import { User_Avtar1 } from "../../../components/src/assets";
import "../../../web/src/assets/css/content/dropdown.scss"
import { MoreHoriz } from "@material-ui/icons";
import BasicMenu from "../../../components/src/dropdownMenu.web";
class RestaurantListing extends AdminRestaurantAddDetailsController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        const menu = ["edit"];
        
        return (
            <>
                <Loader loading={this.state.loading} />

                <div className="tableContainer ">
                    <div className="headerListContainer">
                        <div className="prodListHeader">
                            <div className="blocks">
                                <h4 data-testid="RestaurantListing">Restaurant List</h4>
                            </div>
                            <div className="blocks">
                                <div className="block1">
                                    <div className="addButton">
                                        <CustomButton
                                        data-test-id='createRestaurantBtn'
                                            label="New Restaurant"
                                            onClick={() => {
                                                //@ts-ignore
                                                this.props.history.push(
                                                    `/admin/restaurant/details/register-form`
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="prodListHeader prodListBottomRow">
                            <div className="outlineTextfield">
                                <div className="customTextfield">
                                    <TextField
                                        data-test-id='searchBox'
                                        id="input-with-icon-textfield"
                                        value={this.state.search}
                                        onChange={(e: any) => {
                                            this.setState({
                                                search: e.target.value,
                                            });
                                            this.SearchDebounceUpdate(
                                                e.target.value
                                            );
                                        }}
                                        variant="standard"
                                        placeholder="Search by Name, type, Id, etc... "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon className="searchIcon" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="farmListsContainer">

                        {(this.state.restaurants &&
                            this.state.restaurants.length > 0) ?

                            this.state.restaurants.map((restaurant: any, index: any) => {
                                return (

                                    <div className="farmContainer" key={restaurant.id}>
                                        <h4 data-test-id='restaurantName'>{restaurant.attributes.name}</h4>
                                        <div className="option-dropDown">
                                            <BasicMenu
                                            data-testid="basicMenu"
                                                item={restaurant}
                                                menu={menu}
                                                handleMenuClick={this.handleMenuClick}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                            :
                            (
                                <Box className="no_dishes_container">
                                    <h4>No Restaurants listed</h4>
                                    <p>Please Register Restaurant by clicking<br />&nbsp;&nbsp;"+ New Restaurant" button</p>
                                    <div className="addButton" >
                                        <CustomButton
                                            label="New Restaurant"
                                            onClick={() => {
                                                //@ts-ignore
                                                this.props.history.push(
                                                    `/admin/restaurant/details/register-form`
                                                );
                                            }}
                                        />
                                    </div>
                                </Box>
                            )}

                    </div>
                </div>
                <div>
                    <Dialog
                        open={this.state.openDialog}
                        onClose={() => this.handleDialog()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to delete the restaurant ?
                        </DialogTitle>
                        <DialogActions>
                            {/* //@ts-ignore */}
                            <span className="redContained customCancelBtn">
                                <CustomButton
                                    color="secondary"
                                    label="No"
                                    onClick={() => this.handleDialog()}
                                />
                            </span>

                            <span className="greenContained">
                                <CustomButton
                                    label="Yes"
                                    onClick={() => {
                                        this.handleDialog();
                                        this.handledeleteRestaurant(this.state.removedRest);
                                    }}
                                    autoFocus
                                />
                            </span>
                        </DialogActions>
                    </Dialog>
                </div>

            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start
export {RestaurantListing}
//@ts-ignore
export default withRouter(RestaurantListing);
  // Customizable Area End