import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from 'react-router-dom'
import StorageProvider from "framework/src/StorageProvider.web";
import { isTokenExpired } from "../../../../components/src/utility/helper";
import { toast } from "react-toastify";
import { IProductVariant } from "../../../../components/src/interfaces/shoppingCart"
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps {
  history: any
  isProductActive: boolean
  isAcceptingOrder: boolean
  isTrackQuantity: boolean
  availableQuantity: number
  name: string
  image: any
  description: string
  price: string
  discountPrice: string
  quantity: number
  productId: number
  cartId: string
  cookingInstruction: string
  addOns: string
  isLearningOrder: boolean
  variantDescription: string
  variants: IProductVariant[]
  selectedVariantId: string
  getAddressAndItems: (notLoader?: boolean) => void
  removeCartItem: (id: string) => Promise<void>
  setCartBadge: (count: number) => void
  updateItemQuantity: (id: string, quantity: number) => Promise<void>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  removeItemDialog: boolean
  isRemoving: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateVariantSelectionApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      removeItemDialog: false,
      isRemoving: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  isLoggedIn = async () => {
    return !!await StorageProvider.get(configJSON.AUTH_TOKEN)
  }

  openRemoveDialog = () => {
    this.setState({ removeItemDialog: true })
  }

  closeRemoveDialog = () => {
    this.setState({ removeItemDialog: false })
  }

  getPrice = () => {
    const { discountPrice, selectedVariantId, variants } = this.props
    if(!selectedVariantId) {
      return discountPrice
    } else {
      const data = variants.find(variant => variant.id === String(selectedVariantId))
      if(data) {
        return data.attributes.price
      }
      return ""
    }
  }

  handleLocalItemRemove = async () => {
    const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJson = JSON.parse(orders)

    const { cartId, getAddressAndItems, setCartBadge } = this.props

    const newOrder = ordersJson.filter((order: any) => order.uniqueId !== cartId)
    await StorageProvider.set(configJSON.ORDERS_STORAGE_KEY, JSON.stringify(newOrder))
    getAddressAndItems()
    setCartBadge(newOrder.length)

  }

  removeItem = async () => {
    const { cartId, removeCartItem } = this.props
    this.setState({ isRemoving: true })
    if (await this.isLoggedIn()) await removeCartItem(cartId)
    else await this.handleLocalItemRemove()
    this.setState({ isRemoving: false, removeItemDialog: false })
  }

  handleLocalQuantityChange = async (quantity: number) => {
    const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJson = JSON.parse(orders)

    const { cartId, getAddressAndItems } = this.props

    const newOrder = ordersJson.map((order: any) => {
      if (order.uniqueId === cartId) {
        return {
          ...order,
          quantity
        }
      }
      return order
    })
    await StorageProvider.set(configJSON.ORDERS_STORAGE_KEY, JSON.stringify(newOrder))
    getAddressAndItems(false)
  }

  decreaseQuantity = async () => {
    const { quantity, cartId, updateItemQuantity } = this.props
    if (quantity > 1) {
      const updatedQuantity = quantity - 1
      if (await this.isLoggedIn()) updateItemQuantity(cartId, updatedQuantity)
      else this.handleLocalQuantityChange(updatedQuantity)
    }
  }

  increaseQuantity = async () => {
    const {
      isTrackQuantity,
      availableQuantity,
      quantity,
      cartId,
      updateItemQuantity,
    } = this.props
    if (!isTrackQuantity || quantity < availableQuantity) {
      const updatedQuantity = quantity + 1
      if (await this.isLoggedIn()) updateItemQuantity(cartId, updatedQuantity)
      else this.handleLocalQuantityChange(updatedQuantity)
    }
  }

  isDiscountedPrice = () => {
    return Number(this.props.discountPrice) < Number(this.props.price)
  }

  productQuantityError = () => {
    const { isTrackQuantity, availableQuantity, quantity } = this.props

    if (isTrackQuantity) {
      if (availableQuantity === 0) {
        return 'Out of stock!'
      }

      if (availableQuantity < quantity) {
        const quantityText = availableQuantity > 1 ? 'quantities are' : 'quantity is'
        return `Only ${availableQuantity} ${quantityText} available to purchase!`
      }
    }

    return ''
  }

  updateVariantSelectionApi = async (variantId: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }

    const { cartId } = this.props
    const httpBody = {
      cart_item: {
        id: cartId,
        selected_variant_option_id: variantId,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.updateVariantSelectionApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateVariantSelectionApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  errorApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    if (await isTokenExpired(responseJson)) return

    if(this.updateVariantSelectionApiCallId === apiRequestCallId) {
      if (Array.isArray(responseJson.errors)) {
        responseJson.errors.forEach((error: any) => {
          toast.error(typeof error === "object" ? error.message : error)
        })
      } else {
        toast.error("Something went wrong")
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.updateVariantSelectionApiCallId) {
          this.props.getAddressAndItems(false)
        }
      } else {
        this.errorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  handleVariantSelection = async (variantId: string) => {
    const isUserLoggedIn = await this.isLoggedIn()
  
    if (!isUserLoggedIn) {
      const orders =
        (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]"
      const ordersJson = JSON.parse(orders)
  
      const { cartId, getAddressAndItems } = this.props
  
      const newOrder = ordersJson.map((order: any) => {
        if (order.uniqueId === cartId) {
          return {
            ...order,
            selectedVariantId: variantId,
          }
        }
        return order
      })
      await StorageProvider.set(
        configJSON.ORDERS_STORAGE_KEY,
        JSON.stringify(newOrder)
      )
      getAddressAndItems(false)
    } else {
      const isSameVariantSelected =
        variantId === String(this.props.selectedVariantId)
        !isSameVariantSelected && this.updateVariantSelectionApi(variantId)
    }
  }  
  // Customizable Area End

}
