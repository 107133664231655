import React, { useState } from "react";
// custom components
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Avatar,
  Collapse,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import "../../../packages/blocks/catalogue/assets/css/adminRestDishes.scss"
import "../../web/src/assets/css/content/button.scss";
import "../../web/src/assets/css/content/tab.scss";
import "../../web/src/assets/css/content/table.scss";
import CustomButton from "./CustomButton.web";
import CustomPagination from "./CustomPagination.web";
import CustomTextfield from "./CustomTextfield.web";
import CustomCheckboxField from "./CustomCheckboxField.web";
import CustomTextAreafield from "./CustomTextAreafield.web";
import Modal from '@mui/material/Modal';
import CustomCenterWrapper from "./CustomCenterWrapper.web";
import { formatPhoneNumber } from "./utility/helper";
import { MoreVertRounded } from "@material-ui/icons";
import CustomOrderCancellationMenu from "../../blocks/ordermanagement/src/CancellationComponents/CustomOrderCancellationMenu.web";

interface IMenuOptionUtility {
  isCancelled: boolean;
  isRefunded: boolean;
}

function CollapsRow(props: any) {
  const { item, details, tabChange, orderReady } = props;
  const [open, setOpen] = React.useState(false);
  const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
  const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
  const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
  const isInitiateDisabled = !isCancelled || isRefunded
  const orderMenuUtility = {
    isCancelled,
    isRefunded: isInitiateDisabled
  }

  const handleOrderReady = () => {
    orderReady(item.id)
    setOpen(false)
    tabChange("", 3)
  }


  return (
    <React.Fragment>
      <TableRow key={item.id} data-test-id="preparing" className="orderPreparingRow" onClick={() => setOpen(!open)}>
        <TableCell component={"th" as any} scope="row" align="left" style={{ paddingLeft: '40px' }}>
          <div className="customerCell">
            <Avatar
              alt="Remy Sharp"
              className="customerImage"
              src={item.attributes?.image}
            />
            <h5>{item.attributes?.customer_name}</h5>
          </div>
        </TableCell>
        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
        <TableCell align="center"> {item.attributes?.phone_number} </TableCell>
        <TableCell align="center">{item.attributes?.order_number}</TableCell>
        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
        <TableCell align="center" onClick={props.openOrderDetails}>
          <span className="viewLink">View details</span>
        </TableCell>
        <TableCell align="center">{
          item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">
            <CustomCheckboxField id={item.id} checked={true} /></div>
        }</TableCell>
        <TableCell align="center" className="processCell">
          <Button
            className="cancel-order-button"
            hidden={!isCancellationRequest}
            onClick={(e) => props.openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
            Cancel
          </Button>
          {!isCancellationRequest && item.attributes?.food_procces}
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={(e) => props.openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse data-test-id="orderDetail" in={open} timeout="auto" unmountOnExit>
            <div className="cookingList accordian">
              <div className="scrollBlock">
                {details.data.map((i: any, index: number) => {
                  return (
                    <>
                      <div className="cookingrow">
                        <Grid item xs={1} sm={1} md={1} lg={1} className="blocks">
                          <img
                            src={i.attributes.image}
                            className="orderImg"
                            alt="Order Img"
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} className="blocks">
                          <h5>{i.attributes.name}</h5>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} className="blocks">
                          <h6>Quantity: {i.attributes.quantity}</h6>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} className="blocks">
                          {i.attributes.addons!== null ? <h6 className="addOns">Extra: {i.attributes.addons}</h6> : '' }
                        </Grid>
                      </div>
                      {i.attributes.special_cooking_instruction ? 
                      <>
                      <h6 className="title">Cooking Instruction:</h6>
                      <div className="outlineTextfield">
                      
                        <CustomTextfield className="cookingDes" placeholder="Search customer... "
                          defaultValue={i.attributes.special_cooking_instruction} InputProps={{ readOnly: true }} />
                      </div></>
                      :
                      null} 
                    </>
                  )
                })}
                  
              </div>
              <div className="greenContained">
                <CustomButton
                  data-test-id="orderReadyBtn"
                  label="Order Ready"
                  onClick={handleOrderReady}
                />
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapseRowReady(props:any) {
  const { item, details, tabChange, orderPickUp } = props;
  const [open, setOpen] = React.useState(false);
  const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
  const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
  const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
  const isInitiateDisabled = !isCancelled || isRefunded
  const orderMenuUtility = {
    isCancelled,
    isRefunded: isInitiateDisabled
  }

  return (
    <React.Fragment>
      <TableRow key={item.id} className="orderPreparingRow" onClick={() => setOpen(!open)}>
        <TableCell component={"th" as any} scope="row" align="left" style={{ paddingLeft: '40px' }}>
          <div className="customerCell">
            <Avatar
              alt="Remy Sharp"
              className="customerImage"
              src={item.attributes?.image}
            />
            <h5>{item.attributes?.customer_name}</h5>
          </div>
        </TableCell>
        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
        <TableCell align="center"> {item.attributes?.phone_number} </TableCell>
        <TableCell align="center">{item.attributes?.order_number}</TableCell>
        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
        <TableCell align="center" onClick={props.openOrderDetails}>
          <span className="viewLink">View details</span>
        </TableCell>
        <TableCell align="center">{
          item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">
            <CustomCheckboxField id={item.id} checked={true} /></div>
        }</TableCell>
        <TableCell align="center" className="processCell">
          <Button
            className="cancel-order-button"
            hidden={!isCancellationRequest}
            onClick={(e) => props.openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
            Cancel
          </Button>
          {!isCancellationRequest && item.attributes?.food_procces}
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={(e) => props.openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="cookingList accordian">
              <div className="scrollBlock">
                {details.data.map((i: any, index: number) => {
                  return (
                    <>
                      <div className="cookingrow">
                        <Grid item xs={1} sm={1} md={1} lg={1} className="blocks">
                          <img
                            src={i.attributes.image}
                            className="orderImg"
                            alt="Order Img"
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} className="blocks">
                          <h5>{i.attributes.name}</h5>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} className="blocks">
                          <h6>Quantity: {i.attributes.quantity}</h6>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} className="blocks">
                          {i.attributes.addons!== null ? <h6 className="addOns">Extra: {i.attributes.addons}</h6> : '' }
                        </Grid>
                      </div>
                      {i.attributes.special_cooking_instruction ? 
                      <>
                      <h6 className="title">Cooking Instruction:</h6>
                      <div className="outlineTextfield">
                      
                        <CustomTextfield className="cookingDes" placeholder="Search customer... "
                          defaultValue={i.attributes.special_cooking_instruction} InputProps={{ readOnly: true }} />
                      </div></>
                      :
                      null} 
                    </>
                  )
                })}
                  
              </div>
              <div className="greenContained">
                <CustomButton label="Pick Up Order" onClick={() => {
                orderPickUp(item.id)
                 setOpen(false) 
                tabChange("",4)
                }}/>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapseRowPickedUp(props:any) {
  const { item, details, tabChange, orderDeliver } = props;
  const [open, setOpen] = React.useState(false);
  const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
  const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
  const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
  const isInitiateDisabled = !isCancelled || isRefunded
  const orderMenuUtility = {
    isCancelled,
    isRefunded: isInitiateDisabled
  }

  return (
    <React.Fragment>
      <TableRow key={item.id} className="orderPreparingRow" onClick={() => setOpen(!open)}>
        <TableCell component={"th" as any} scope="row" align="left" style={{ paddingLeft: '40px' }}>
          <div className="customerCell">
            <Avatar
              alt="Remy Sharp"
              className="customerImage"
              src={item.attributes?.image}
            />
            <h5>{item.attributes?.customer_name}</h5>
          </div>
        </TableCell>
        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
        <TableCell align="center"> {item.attributes?.phone_number} </TableCell>
        <TableCell align="center">{item.attributes?.order_number}</TableCell>
        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
        <TableCell align="center" onClick={props.openOrderDetails}>
          <span className="viewLink">View details</span>
        </TableCell>
        <TableCell align="center">{
          item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">
            <CustomCheckboxField id={item.id} checked={true} /></div>
        }</TableCell>
        <TableCell align="center" className="processCell">
          <Button
            className="cancel-order-button"
            hidden={!isCancellationRequest}
            onClick={(e) => props.openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
            Cancel
          </Button>
          {!isCancellationRequest && item.attributes?.food_procces}
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={(e) => props.openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="cookingList accordian">
              <div className="scrollBlock">
                {details.data.map((i: any, index: number) => {
                  return (
                    <>
                      <div className="cookingrow">
                        <Grid item xs={1} sm={1} md={1} lg={1} className="blocks">
                          <img
                            src={i.attributes.image}
                            className="orderImg"
                            alt="Order Img"
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} className="blocks">
                          <h5>{i.attributes.name}</h5>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} className="blocks">
                          <h6>Quantity: {i.attributes.quantity}</h6>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} className="blocks">
                          {i.attributes.addons!== null ? <h6 className="addOns">Extra: {i.attributes.addons}</h6> : '' }
                        </Grid>
                      </div>
                      {i.attributes.special_cooking_instruction ? 
                      <>
                      <h6 className="title">Cooking Instruction:</h6>
                      <div className="outlineTextfield">
                      
                        <CustomTextfield className="cookingDes" placeholder="Search customer... "
                          defaultValue={i.attributes.special_cooking_instruction} InputProps={{ readOnly: true }} />
                      </div></>
                      :
                      null} 
                    </>
                  )
                })}
                  
              </div>
              <div className="greenContained">
                <CustomButton label="Deliver Order" onClick={() => {
                 orderDeliver(item.id)
                 setOpen(false) 
                tabChange("",5)
                }}/>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function OrderDetails(props: any) {
  const { item, setTime, acceptOrder,time, tabChange} = props
  const [data,setData] = React.useState([])
  const [modal,setModal] = React.useState(false)
  const [id,setId] = React.useState('')
  const [delTime, setDelTime] = React.useState(false)

  const handleOpenModal = (id: any, item: any) => {
      setModal(true)
      setId(id)
      setData(item.attributes?.order_details?.data)
  }

  const handleCloseModal = () => {
  setModal(false)}

  const handleTime = (time:any, id:any) => {
    setTime(time,id)
    setDelTime(true)
  }
  const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
  const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
  const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
  const isInitiateDisabled = !isCancelled || isRefunded
  const orderMenuUtility = {
    isCancelled,
    isRefunded: isInitiateDisabled
  }
  const isSetTimeStatus = item.attributes?.food_procces === 'Set Time'

  return (
    <>
      <TableRow key={item.id}>
        <TableCell style={{ paddingLeft: '40px' }} component={"th" as any} scope="row" align="left" >
          <div className="customerCell">
            <Avatar
              alt="Remy Sharp"
              className="customerImage"
              src={item.attributes?.image}
            />
            <h5>{item.attributes?.customer_name}</h5>
          </div>
        </TableCell>
        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
        <TableCell align="center"> {formatPhoneNumber(item.attributes?.phone_number)} </TableCell>
        <TableCell align="center">{item.attributes?.order_number}</TableCell>
        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
        <TableCell align="center" onClick={props.openOrderDetails}>
          <span className="viewLink">View details</span>
        </TableCell>
        <TableCell align="center">
          {item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">

            <CustomCheckboxField id={item.id} checked={true} /></div>
          }
        </TableCell>

        <TableCell align="center" className="processCell">
          <Button
            className="cancel-order-button"
            hidden={!isCancellationRequest}
            onClick={(e) => props.openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
            Cancel
          </Button>
          {!isCancellationRequest && !isSetTimeStatus && 
            (item.attributes?.food_procces === "Rejected" ? "Cancelled" : item.attributes?.food_procces)}
          {isSetTimeStatus && <div className="greenContained setItemBtn">
            <CustomButton data-test-id="setTimeBtn" label="Set Time" onClick={() => handleOpenModal(item.id, item)} />
          </div>}

        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={(e) => props.openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
        </TableCell>
      </TableRow>

      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mainModal modal-md restDetailsModal">
          <div className="subModalBlock">
            <div className="modalHeader" style={{ justifyContent: 'center' }} >
              <h4 style={{ marginLeft: '0' }}>Order Details</h4>
            </div>
            <div className="modalContent restModalContent">
              {data.map((i: any, index: number) => {
                return (<>
                  <div key={index} className="orderCard" >
                    <div className="topRow" style={{margin:'0',paddingBottom:'0'}}>
                      <div className="leftBlock">
                        <img src={i.attributes?.image} className="OrderImg" alt="OrderImg" />
                        <div className="leftContentBlock">
                          <h5>{i.attributes?.name}</h5> 
                          <h6>Quantity: <span>{i.attributes?.quantity}</span></h6>
                        </div>
                      </div>
                      <h5>${i.attributes?.price}</h5>
                    </div>
                    {i.attributes.addons ?
                    <div className="topRow">
                         <h6>Extra: {i.attributes?.addons}</h6> 
                    </div>
                    : <div className="bottomPadding"></div>}
                  </div>
                  {i.attributes.special_cooking_instruction ?
                  <CustomTextAreafield 
                   label="Cooking Instruction: " placeholder="Description" defaultValue={i.attributes?.special_cooking_instruction}
                    InputProps={{
                      readOnly: true
                    }} />
                    :
                    null
                  }

                </>)
              })}


              <div className="deliveryTimeRow">
                <h5>Set Delivery Time:</h5>
                <h5><span data-test-id="time">{delTime ? time : item.attributes?.set_delivery_time}</span></h5>
              </div>
              <h6>Add Time</h6>
              <div className="addTimeRow">
                <div className="timeMinRow" onClick={() => handleTime(5, id)}>
                  +5
                </div>
                <div className="timeMinRow" onClick={() => handleTime(15, id)}>
                  +15
                </div>
                <div className="timeMinRow" onClick={() => handleTime(20, id)}>
                  +20
                </div>
              </div>

              <div className="btnContainer">

                <div className="redOutlineButton">
                  <CustomButton color="secondary" label="Reject" onClick={() =>{
                   acceptOrder(id,"rejected")
                   handleCloseModal()
                    }}/>
                </div>
                <div className="greenFilledButton">
                  <CustomButton data-test-id="acceptOrderBtn" color="secondary" label="Accept Order" onClick={() =>{ 
                  acceptOrder(id,"preparing")
                  tabChange("",2)
                  handleCloseModal()
                  }}/>
                </div>

              </div>

            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = (props: any) => {
  const [value, setValue] = useState(0);
  const [menuId, setMenuId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [requestAnchorEl, setRequestAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [isRefunded, setIsRefunded] = useState(false);
  const {
    orderdeliveryTime,
    handleStatus,
    orderList,
    handleOrderTime,
    handleAccept,
    handleOrderReady,
    handleChangeTablePage,
    handleIncreasePageView,
    handleDecreasePageView,
    currentPage,
    perPage,
    totalOrderPage,
    totalOrderCount,
    orderCount,
    handlePickOrder,
    handlePagination,
    handleOrderDeliver,
    openOrderCancelApproveDialog,
    openOrderCancelRejectDialog
  } = props  


  const handleChange = (event:any,newValue: any) => {
    handleStatus(newValue);
    setValue(newValue);
    handlePagination()
  };

  const showCount = (count: number) => {
    return count > 0 ? count : ''
  }

  const handleCancellationByAdmin = (callback: () => void) => {
    setAnchorEl(null)
    callback()
  }

  const handleCancellationRequest = (callback: () => void) => {
    setRequestAnchorEl(null)
    callback()
  }

  const startingIndexOrders = (currentPage - 1) * perPage + 1
  const adminCancelMenus = [{
    label: "Cancel order",
    disabled: isOrderCancelled,
    onClick: () => handleCancellationByAdmin(() => openOrderCancelApproveDialog(menuId, false, true))
  },
  {
    label: "Initiate refund",
    disabled: isRefunded,
    onClick: () => handleCancellationByAdmin(() => openOrderCancelApproveDialog(menuId, true, true))
  }]

  const requestCancelMenus = [{
    label: "Accept cancellation",
    onClick: () => handleCancellationRequest(() => openOrderCancelApproveDialog(menuId, false))
  },
  {
    label: "Reject cancellation",
    onClick: () => handleCancellationRequest(() => openOrderCancelRejectDialog(menuId))
  },
  {
    label: "Initiate refund",
    disabled: isRefunded,
    onClick: () => handleCancellationRequest(() => openOrderCancelApproveDialog(menuId, true))
  }]

  const openOrderMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string, { isCancelled, isRefunded }: IMenuOptionUtility) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setMenuId(id)
    setIsOrderCancelled(isCancelled)
    setIsRefunded(isRefunded)
  }

  const closeOrderMenu = () => {
    setAnchorEl(null)
    setMenuId("")
    setIsOrderCancelled(false)
    setIsRefunded(false)
  }

  const openCancelRequestMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string, isRefunded: boolean) => {
    e.stopPropagation()
    setRequestAnchorEl(e.currentTarget)
    setMenuId(id)
    setIsRefunded(isRefunded)
  }

  const closeCancelRequestMenu = () => {
    setRequestAnchorEl(null)
    setMenuId("")
    setIsOrderCancelled(false)
    setIsRefunded(false)
  }

  const openOrderDetails = (e: React.MouseEvent<HTMLTableCellElement>, orderDetails: any) => {
    e.stopPropagation()
    props.openOrderDetailsDialog(orderDetails)
  }

  return (
    <div className="customTab">
      <Tabs
        value={value}
        scrollButtons="on"
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: "green"
          }
        }}
        className="restOrderScroll"

      >
        <Tab label={<span className="title">All</span>} {...a11yProps(0)} />
        <Tab label={<span className="title">New Order</span>} icon={<span className="badge">
        {showCount(orderCount.new_order)}
        </span>} {...a11yProps(1)} />
        <Tab label={<span className="title">Preparing</span>} icon={<span className="badge">
          {showCount(orderCount.preparing)}</span>} {...a11yProps(2)} />
        <Tab label={<span className="title">Ready</span>} icon={<span className="badge">
          {showCount(orderCount.ready)}</span>} {...a11yProps(3)} />
        <Tab label={<span className="title">Picked Up</span>} icon={<span className="badge">
          {showCount(orderCount.picked_up)}</span>} {...a11yProps(4)} />
        <Tab label={<span className="title">Delivered</span>} icon={<span className="badge">
        {showCount(orderCount.delivered)}
        </span>} {...a11yProps(5)} />
        <Tab label={<span className="title">Rejected</span>} icon={<span className="badge">
        </span>} {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0} className="orderTab tabPanel">
        <TableContainer className="tableContainer" component={Paper}>
          { orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" className="firstElem">Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {orderList.map((item: any) => {
                    return (
                      <OrderDetails
                        data-test-id="allTab"
                        item={item}
                        key={item.id}
                        setTime={handleOrderTime}
                        acceptOrder={handleAccept} 
                        time={orderdeliveryTime}
                        tabChange={handleChange}
                        openOrderMenu={openOrderMenu}
                        openCancelRequestMenu={openCancelRequestMenu}
                        openOrderDetails={(e: React.MouseEvent<HTMLTableCellElement>) => openOrderDetails(e, item)}
                      />
                    )
                  })}

                </TableBody>
              </Table>
            )
            : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>

        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <Grid item xs={12}>
        <div data-test-id="allOrders">
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination
            data-test-id="pagination"
           count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
            </Grid>
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={1} className="orderTab tabPanel" >
        <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    return (
                      <OrderDetails
                        item={item}
                        key={item.id}
                        setTime={handleOrderTime}
                        acceptOrder={handleAccept}
                        time={orderdeliveryTime}
                        tabChange={handleChange}
                        openOrderMenu={openOrderMenu}
                        openCancelRequestMenu={openCancelRequestMenu}
                        openOrderDetails={(e: React.MouseEvent<HTMLTableCellElement>) => openOrderDetails(e, item)}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>
        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={2} className="orderTab tabPanel">
        <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    return (
                      <CollapsRow
                        data-test-id="collapsRow"
                        item={item} key={item.id}
                        details={item.attributes.order_details}
                        orderReady={handleOrderReady}
                        tabChange={handleChange}
                        openOrderMenu={openOrderMenu}
                        openCancelRequestMenu={openCancelRequestMenu}
                        openOrderDetails={(e: React.MouseEvent<HTMLTableCellElement>) => openOrderDetails(e, item)}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>

        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={3} className="orderTab tabPanel">
        <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    return (
                        <CollapseRowReady
                          data-test-id="readyOrderList"
                          item={item}
                          key={item.id} 
                          details={item.attributes.order_details}
                          orderPickUp={handlePickOrder}
                          tabChange={handleChange}
                          openOrderMenu={openOrderMenu}
                          openCancelRequestMenu={openCancelRequestMenu}
                          openOrderDetails={(e: React.MouseEvent<HTMLTableCellElement>) => openOrderDetails(e, item)}
                        />
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>
        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={4} className="orderTab tabPanel">
        <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    return (
                      <CollapseRowPickedUp
                        item={item} key={item.id}
                        details={item.attributes.order_details}
                        orderDeliver={handleOrderDeliver}
                        tabChange={handleChange}
                        openOrderMenu={openOrderMenu}
                        openCancelRequestMenu={openCancelRequestMenu}
                        openOrderDetails={(e: React.MouseEvent<HTMLTableCellElement>) => openOrderDetails(e, item)}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>
        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={5} className="orderTab tabPanel">
      <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
                    const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
                    const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
                    const isInitiateDisabled = !isCancelled || isRefunded
                    const orderMenuUtility = {
                      isCancelled,
                      isRefunded: isInitiateDisabled
                    }

                    return (
                      <TableRow key={item.id}>
                        <TableCell style={{ paddingLeft: '40px' }} component={"th" as any} scope="row" align="left" >
                          <div className="customerCell">
                            <Avatar
                              alt="Remy Sharp"
                              className="customerImage"
                              src={item.attributes?.image}
                            />
                            <h5>{item.attributes?.customer_name}</h5>
                          </div>
                        </TableCell>
                        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
                        <TableCell align="center"> {item.attributes?.phone_number} </TableCell>
                        <TableCell align="center">{item.attributes?.order_number}</TableCell>
                        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
                        <TableCell align="center" onClick={(e) => openOrderDetails(e, item)}>
                          <span className="viewLink">View details</span>
                        </TableCell>
                        <TableCell align="center">
                          {item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">
                            <CustomCheckboxField id={item.id} checked={true} /></div>
                          }
                        </TableCell>

                        <TableCell align="center" className="processCell">
                          <Button
                            className="cancel-order-button"
                            hidden={!isCancellationRequest}
                            onClick={(e) => props.openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
                            Cancel
                          </Button>
                          {!isCancellationRequest && item.attributes?.food_procces}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton size="small" onClick={(e) => openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>
        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>
      <TabPanel value={value} index={6} className="orderTab tabPanel">
      <TableContainer className="tableContainer" component={Paper}>
          {orderList?.length > 0 ?
            (
              <Table aria-label="simple table" stickyHeader>
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" style={{ paddingLeft: '60px' }}>Customer name</TableCell>
                    <TableCell align="center">Payment type</TableCell>
                    <TableCell align="center">phone number</TableCell>
                    <TableCell align="center">#id</TableCell>
                    <TableCell align="center">order date & time</TableCell>
                    <TableCell align="center">View-Details</TableCell>
                    <TableCell align="center">payment</TableCell>
                    <TableCell align="center">food Process</TableCell>
                    <TableCell align="center" className="tableHead-menu-column"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderList.map((item: any) => {
                    const isCancellationRequest = item.attributes?.order_cancel_status === "pending"
                    const isCancelled = item.attributes?.order_cancel_status === "approved" || item.attributes?.cancelled_by_owner
                    const isRefunded = isCancelled && item.attributes?.refund_payment_status === "completed"
                    const isInitiateDisabled = !isCancelled || isRefunded
                    const orderMenuUtility = {
                      isCancelled,
                      isRefunded: isInitiateDisabled
                    }

                    return (
                      <TableRow key={item.id}>
                        <TableCell style={{ paddingLeft: '40px' }} component={"th" as any} scope="row" align="left" >
                          <div className="customerCell">
                            <Avatar
                              alt="Remy Sharp"
                              className="customerImage"
                              src={item.attributes?.image}
                            />
                            <h5>{item.attributes?.customer_name}</h5>
                          </div>
                        </TableCell>
                        <TableCell align="center">{item.attributes?.payment_type}</TableCell>
                        <TableCell align="center"> {item.attributes?.phone_number} </TableCell>
                        <TableCell align="center">{item.attributes?.order_number}</TableCell>
                        <TableCell align="center"> {item.attributes?.order_date_and_time}</TableCell>
                        <TableCell align="center" onClick={(e) => openOrderDetails(e, item)}>
                          <span className="viewLink">View details</span>
                        </TableCell>
                        <TableCell align="center">
                          {item.attributes?.payment === "pending" ? item.attributes?.payment : <div className="dangerCheckbox">
                            <CustomCheckboxField id={item.id} checked={true} /></div>
                          }
                        </TableCell>

                        <TableCell align="center" className="processCell">
                          <Button
                            className="cancel-order-button"
                            hidden={!isCancellationRequest}
                            onClick={(e) => openCancelRequestMenu(e, item.id, isInitiateDisabled)}>
                            Cancel
                          </Button>
                          {!isCancellationRequest && 
                            (item.attributes?.food_procces === "Rejected" ? "Cancelled" : item.attributes?.food_procces)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton size="small" onClick={(e) => openOrderMenu(e, item.id, orderMenuUtility)}><MoreVertRounded /></IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (<CustomCenterWrapper>No Orders Found</CustomCenterWrapper>)}
        </TableContainer>
        {orderList?.length > 0 && <Grid item xs={12} className="paginationRow restOrderPagination">
        <div>
          {startingIndexOrders}-{startingIndexOrders + orderList.length - 1} of {totalOrderCount} items
        </div>
          <CustomPagination count={totalOrderPage}
            page={currentPage}
            pagePerView={perPage}
            handlePageChange={handleChangeTablePage}

            shape="rounded" siblingCount={1}
            increasePagePerView={handleIncreasePageView}
            decreasePagePerView={handleDecreasePageView}
            boundaryCount={1} />
        </Grid>}
      </TabPanel>

      <CustomOrderCancellationMenu
        anchorEl={anchorEl}
        menus={adminCancelMenus}
        closeOrderMenu={closeOrderMenu}
      />

      <CustomOrderCancellationMenu
        anchorEl={requestAnchorEl}
        menus={requestCancelMenus}
        closeOrderMenu={closeCancelRequestMenu}
      />
    </div>
  );
};
export default CustomTab;
