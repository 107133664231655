import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import {Redirect} from "react-router-dom";

// Customizable Area Start
import * as Yup from "yup";
import { URLSearchParams } from "url";
import Location from 'react-router-dom';
import {toast} from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");
const queryString = require('query-string');


export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accountType: string;
    emailSchema: any;
    phoneSchema: any;
    otpSchema: any;
    passwordSchema: any;
    accountStatus: any;
    passwordRules: any;
    emailValue: any;
    phoneValue: any;
    countryCodeSelected: any;
    token: any;
    enablePasswordField: Boolean;
    btnConfirmPasswordShowHide: Boolean;
    changePasswordSchema:any;
    statusMsg:any;
    isLoading:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    validationAPICallId: any;
    requestEmailOtpCallId: any;
    requestPhoneOtpCallId: any;
    requestChangePasswordCallId: any;
    requestGoToConfirmationCallId: any;
    otpToken: any;
    isChangePassword: boolean;
    apiChangePWApiCallId:any;
    //Properties from config
    labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
    secondLabelText: string = configJSON.secondLabelText;
    thirdLabelText: string = configJSON.thirdLabelText;
    forthLabelText: string = configJSON.forthLabelText;
    fifthLabelText: string = configJSON.fifthLabelText;
    sixthLabelText: string = configJSON.sixthLabelText;
    firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
    firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
    firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
    firstInputErrorColor: any = configJSON.firstInputErrorColor;
    buttonTextIsNext: string = configJSON.buttonTextIsNext;
    buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
    secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
    secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
    secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
    secondInputErrorColor: any = configJSON.secondInputErrorColor;
    thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
    thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
    buttonTitleIsSMSPhoneAccount: string =
        configJSON.buttonTitleIsSMSPhoneAccount;
    buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
    labelTextIsPleaseEnterYourNewPassword: string =
        configJSON.labelTextIsPleaseEnterYourNewPassword;
    labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
        configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
    placeholderIsPassword: string = configJSON.placeholderIsPassword;
    passwordVisibleImage: any = passwordVisibleImage;
    passwordInvisibleImage: any = passwordInvisibleImage;
    placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
    buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
    buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
    countryCodeSelectorPlaceholder: string =
        configJSON.countryCodeSelectorPlaceholder;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        //email schema
        let emailSchema = {
            email: Yup.string()
                .email(configJSON.pleaseEnterAValidEmail)
                .required(configJSON.emailIsRequired)
        };

      let  changePasswordSchema =Yup.object().shape({
        // email: Yup.string()
        //   .trim()
        //   .email("Invalid email address format")      
        //   .required("Email is required"),
    
        password: Yup.string()   
          .min(3, "Password must be 3 characters at minimum")
          .required("Password is required")
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,"Password must contain at least 8 characters, one lower, one uppercase, one number and one special case character"),
        
        repeatPassword: Yup.string()   
        .min(3, "Password must be 3 characters at minimum")
        .required("Password is required").oneOf([Yup.ref("password"),null],"Confirm password and password does not match")
          .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,"Password must contain at least 8 characters, one lower, one uppercase, one number and one special case character")
      });

        //phone schema
        let phoneSchema = {
            // countryCode: Yup.number()
            // .required("Country code is required"),

            phone: Yup.string()
                .matches(phoneRegExp, configJSON.phoneNumberIsNotValid)
                .required(configJSON.phoneNumberIsRequired)
        };

        //otpSchema
        let otpSchema = {
            otpCode: Yup.number()
                .min(4)
                .required(configJSON.otpCodeIsRequired)
        };

        //passwordSchema
        let passwordSchema = {
            password: Yup.string()
                .required(configJSON.pleaseEnterAPassword)
                .min(2, configJSON.passwordMustBeAtLeast2Characters),
            confirmPassword: Yup.string()
                .required(configJSON.pleaseConfirmYourPassword)
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        configJSON.passwordsMustMatch
                    )
                })
        };

        this.state = {
            accountType: "sms",
            accountStatus: "ChooseAccountType",
            emailValue: "",
            phoneValue: "",
            countryCodeSelected: "",
            passwordRules: "",
            emailSchema: emailSchema,
            phoneSchema: phoneSchema,
            otpSchema: otpSchema,
            passwordSchema: passwordSchema,
            token: "",
            enablePasswordField: true,
            btnConfirmPasswordShowHide: true,
            changePasswordSchema:changePasswordSchema,
            statusMsg:"",
            isLoading:false
        };
        // Customizable Area End
    }

    // async componentDidMount() {
    //     super.componentDidMount();
    //     this.validationRulesRequest();
    // }

    doChangePassword= async(values: {password:string, repeatPassword:string}): Promise<boolean> => {
        console.log('password',values.password);
        this.setState({isLoading:true})
        let search = location.search.split("=")[1];
        console.log("token",search);
      
          const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
          };
      
          const data = {
            token: search,
            new_password: values.password,
          };
      
          const httpBody = {
            data: data,
          };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.apiChangePWApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changePasswordEndpoint
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);   
        //   console.log("data", data);
           return true;
        }
      

    validationRulesRequest = () => {
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validationAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileValidationSettingsAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );

        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
            console.log("connected rsponse", responseJson);
            this.setState({
                isLoading:false
            })
            if(responseJson?.data !== null ) {
                this.setState ({
                    statusMsg : true
                })
                toast.success("Password Updated Successfully")

                // window.location.href ="/landing-page-logged-in"
                // @ts-ignore
                this.props.history.push("/")
            }

        //   if (
        //     responseJson &&
        //     this.state.createProfile &&
        //     !responseJson.hasOwnProperty("errors") &&
        //     responseJson.hasOwnProperty("data") &&
        //     responseJson.data?.type === "farm"
        //   ) {
        //     this.setState({ openModal: true });
        //   }
    
        //   if(responseJson?.data?.attributes?.is_admin == true) {
        //     await StorageProvider.set("AdminAuth", responseJson?.data?.attributes?.is_admin);
        //     console.log("admin activated ", responseJson?.data?.attributes?.is_admin)
        //   }
    
        //   if (responseJson.hasOwnProperty("errors")) {
        //     // console.log("item", responseJson.errors.org_contact_number[0]);
        //     if (
        //       responseJson.errors.hasOwnProperty("org_contact_number") &&
        //       responseJson.errors.org_contact_number[0] === "has already been taken"
        //     ) {
        //       this.setState({
        //         orgContactNumberError: "this mobile number has already been taken",
        //       });
        //     } else {
        //       this.setState({
        //         orgContactNumberError: "",
        //       });
        //     }
    
        //     if (
        //       responseJson.errors.hasOwnProperty("org_owner_contact_number") &&
        //       responseJson.errors.org_owner_contact_number[0] ===
        //         "has already been taken"
        //     ) {
        //       this.setState({
        //         orgOwnerContactNumberError:
        //           "this mobile number has already been taken",
        //       });
        //     } else {
        //       this.setState({
        //         orgOwnerContactNumberError: "",
        //       });
        //     }
        //   }
        //   var errorReponse = message.getData(
        //     getName(MessageEnum.RestAPIResponceErrorMessage)
        //   );
        //   console.log("err", errorReponse);
    
        //   //custom
    
        //   // console.log('getResmsg',getResMsg);
    
        //   if (apiRequestCallId != null) {
        //     if (
        //       apiRequestCallId === this.validationApiCallId &&
        //       responseJson !== undefined
        //     ) {
        //       var arrayholder = responseJson.data;
    
        //       if (arrayholder && arrayholder.length !== 0) {
        //         let regexData = arrayholder[0];
    
        //         if (regexData && regexData.email_validation_regexp) {
        //           this.emailReg = new RegExp(regexData.email_validation_regexp);
        //         }
        //       }
        //     }
    
        //     // console.log("successss msg",  this.sendLoginSuccessMessage());
        //   }
        // }
        // // Customizable Area End
    
    // startForgotPassword(accountType: String) {
    //     this.setState({
    //         accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    //     });
    // }

    // goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //     //change status to OTP
    //     const header = {
    //         "Content-Type": configJSON.forgotPasswordAPiContentType
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );
    //     //GO TO REQUEST STATE
    //     this.requestEmailOtpCallId = requestMessage.messageId;
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.passwordRecoveryStartOtpAPiEndPoint
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     this.setState({
    //         emailValue: values.email ? values.email : ""
    //     });

    //     const data = {
    //         type: values.accountType ? values.accountType : "email_account",
    //         attributes: {
    //             email: values.email ? values.email : ""
    //         }
    //     };

    //     const httpBody = {
    //         data: data
    //     };

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.httpPostMethod
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    // }

    // goToOtpAfterPhoneValidation(values: { phone: string }) {
    //     // console.log("entered phone validation code");
    //     if (
    //         !this.state.countryCodeSelected ||
    //         this.state.countryCodeSelected.length === 0
    //     ) {
    //         this.showAlert(
    //             configJSON.goToOtpAfterPhoneValidationErrorTitle,
    //             configJSON.goToOtpAfterPhoneValidationErrorBody
    //         );
    //         return;
    //     }
    //     console.log(this.state.countryCodeSelected);
    //     //change status to OTP
    //     const header = {
    //         "Content-Type": configJSON.forgotPasswordAPiContentType
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );
    //     //GO TO REQUEST STATE
    //     this.requestPhoneOtpCallId = requestMessage.messageId;
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.passwordRecoveryStartOtpAPiEndPoint
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     this.setState({
    //         phoneValue:
    //             this.state.countryCodeSelected && values.phone
    //                 ? this.state.countryCodeSelected + values.phone
    //                 : ""
    //     });

    //     const data = {
    //         type: "sms_account",
    //         attributes: {
    //             full_phone_number: this.state.phoneValue
    //         }
    //     };

    //     const httpBody = {
    //         data: data
    //     };

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.httpPostMethod
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    // }

    // goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //     //change status to change password
    //     //change status to OTP
    //     const header = {
    //         "Content-Type": configJSON.forgotPasswordAPiContentType
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );
    //     //GO TO REQUEST STATE
    //     this.requestChangePasswordCallId = requestMessage.messageId;

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     const data = {
    //         token: this.otpToken ? this.otpToken : "",
    //         otp_code: values.otpCode ? values.otpCode : ""
    //     };

    //     const httpBody = {
    //         data: data
    //     };

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.httpPostMethod
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    // }

    // goToConfirmationAfterPasswordChange(values: {
    //     password: any;
    //     confirmPassword: any;
    // }) {
    //     const header = {
    //         "Content-Type": configJSON.forgotPasswordAPiContentType
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );
    //     //GO TO REQUEST STATE
    //     this.requestGoToConfirmationCallId = requestMessage.messageId;

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.passwordRecoveryChangePasswordAPiEndPoint
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     const data = {
    //         token: this.otpToken ? this.otpToken : "",
    //         new_password: values.password
    //     };

    //     const httpBody = {
    //         data: data
    //     };

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.httpPostMethod
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    // }

    // goToHome() {
    //     const msg: Message = new Message(
    //         getName(MessageEnum.NavigationHomeScreenMessage)
    //     );
    //     msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    //     this.send(msg);
    // }

    // goToLogin() {
    //     const msg: Message = new Message(
    //         getName(MessageEnum.NavigationEmailLogInMessage)
    //     );
    //     msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    //     this.send(msg);
    // }
}
    }}