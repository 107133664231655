import { IBlock } from "./../../../framework/src/IBlock";
import { Message } from "./../../../framework/src/Message";
import { BlockComponent } from "./../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "./../../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
import { toast } from "react-toastify";

// Customizable Area Start
export const configJSON = require("./config");
import { RouteComponentProps } from "react-router-dom";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import { isTokenExpireResponse } from "../../../components/src/utility/helper";
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  history: any;
  id: string;
  productId: number;
  image: string;
  name: string;
  sellerId: string;
  sellerName: string;
  price: number;
  cartableType: "farm" | "restaurant";
  cartId: string;
  getCartItems: () => void;
  handleRemoveItemLocal: (sellerName: string, itemId: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAddingToCart: boolean;
  isRemoving: boolean;
  quantity: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WishlistContentBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addToCartApiCallId: string = "";
  removeFavouriteApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isAddingToCart: false,
      isRemoving: false,
      quantity: 1
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  increaseQuantity = () => {
    this.setState(prevState => ({
      quantity: prevState.quantity + 1
    }));
  };

  decreaseQuantity = () => {
    this.setState(prevState => ({
      quantity:
        prevState.quantity > 1 ? prevState.quantity - 1 : prevState.quantity
    }));
  };

  handleAddToCart = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN));
    if (!isLoggedIn) {
      toast.error(
        "You are not logged in, please login to add a product to your cart."
      );
      return;
    }
    const { quantity } = this.state;
    const { productId, cartableType, cartId } = this.props;

    let order: any = {
      cartable_id: productId,
      cartable_type: configJSON.CARTABLE_TYPE_FARM,
      cart_id: cartId,
      quantity
    };
    if (cartableType === "restaurant") {
      order = {
        ...order,
        cartable_type: configJSON.CARTABLE_TYPE_MENU,
        addons_ids: [],
        special_cooking_instruction: ""
      };
    }
    this.addToCartApi(order);
  };

  handleRemoveFromFav = async () => {
    const { productId, cartableType } = this.props;
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN));
    if (!isLoggedIn) {
      toast.error(
        "You are not logged in, please login to remove a product from your wishlist."
      );
      return;
    }

    let favType = "";
    switch (cartableType) {
      case "farm":
        favType = configJSON.CARTABLE_TYPE_FARM;
        break;
      case "restaurant":
        favType = configJSON.CARTABLE_TYPE_MENU;
        break;
      default:
        break;
    }

    const details = {
      favouriteable_id: productId,
      favouriteable_type: favType
    };
    this.removeFromFavouriteApi(details);
  };

  handleProductDetailsRedirect = () => {
    const { cartableType, productId, sellerId, history } = this.props;
    if (cartableType === "farm") {
      history.push(`${AppRoutings.FarmProductDetails}/${productId}`);
    } else {
      history.push(`${AppRoutings.RestaurantDetails}/${sellerId}`);
    }
  };

  handleSuccessApiResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.addToCartApiCallId) {
      this.setState({ isAddingToCart: false, quantity: 1 });
      toast.success("Item added to cart");
      this.props.getCartItems();
    }

    if (apiRequestCallId === this.removeFavouriteApiCallId) {
      const { sellerName, id, handleRemoveItemLocal } = this.props;
      toast.success("Item removed from wishlist");
      handleRemoveItemLocal(sellerName, id);
    }
  };

  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (isTokenExpireResponse(responseJson)) {
      return;
    }

    if (apiRequestCallId === this.addToCartApiCallId) {
      this.setState({ isAddingToCart: false })
      const errorResponse = responseJson?.errors ?? []
      if (errorResponse.length > 0) {
        if (Array.isArray(errorResponse)) {
          errorResponse.forEach((error: { message: string }) => {
            toast.error(error.message)
          })
        } else {
          toast.error(errorResponse)
        }
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId);
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  addToCartApi = async (orderDetails: any) => {
    this.setState({ isAddingToCart: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(orderDetails)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeFromFavouriteApi = async (body: any) => {
    this.setState({ isRemoving: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFavouriteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.toggleFavouriteApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
