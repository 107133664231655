import React from "react";
import {
  convertToRaw,
  EditorState,
  AtomicBlockUtils,
  RichUtils,
  ContentState,
} from "draft-js";


import createImagePlugin from "draft-js-image-plugin";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import "../../web/src/assets/css/content/textEditorStyles.scss";
import "draft-js-image-plugin/lib/plugin.css";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const imagePlugin = createImagePlugin();
const plugins = [imagePlugin];

const CustomToolbar = () => (
    <div id="toolbar">
      <button className="ql-bold" />
      <button className="ql-italic" />
    </div>
  );

class Editor extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
    this.state = { editorHtml: "" ,image:""};
    this.handleChange = this.handleChange.bind(this);
    this.extractContent = this.extractContent.bind(this);
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      // !prevProps.values.title &&
      this.props.values.title != prevProps.values.title
    ) {
      this.setState({
        editorHtml:this.props.values.title,
        image: this.props.values.titleImage,
      });
    }
  }

  extractContent=(s:any)=>{
    let p = document.createElement('p');
    p.innerHTML = s;
    this.props.setFieldValue(
      "title",s
      );
    };
    
    handleChange(html:any) {
      this.setState({ editorHtml: html });
    this.extractContent(html)
    
  }
 


  onImageChange = (e: any) => {
    this.props.setFieldValue("titleImage", e.target.files[0]);
    this.setState({ image: e.target.files[0] });
  };

  render() {
    const Createpath =
      window.location.pathname.split("/")[1] == "create-educator-profile" || window.location.pathname.split("/")[3] == "details";
    
    return (
      <>
        <div
          className={`toolBarContainer ${this.state.className}`}
          style={this.props.style}
    >
          <div className="text-editor taskEditor">
        <CustomToolbar />
        <ReactQuill
        value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          //@ts-ignore
          modules={Editor.modules}
          //@ts-ignore
          formats={Editor.formats}
          theme={"snow"} // pass false to use minimal theme
        />
      </div>
          {!Createpath && (
            <div className="imgUploadContainer">
              <IconButton aria-label="upload picture" component="label">
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={this.onImageChange}
                />
                <AttachFileIcon />
              </IconButton>
              <div className="imgContainer">
                {this.state.image && (
                  <>
                    <Box className="lesson-row chipContainer">
                      <Box className="upload-chip">
                        <h6>
                          {this.state.image &&
                            (this.state.image?.name
                              ? this.state.image.name
                              : this.state.image.filename)}
                        </h6>
                        <CloseIcon
                          className="closeIcon"
                          onClick={() => {
                            this.setState({
                              image: "",
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
 //@ts-ignore
Editor.modules = {
    toolbar: {
      container: "#toolbar",
    },
    clipboard: {
      matchVisual: false,
    }
  };
  
 //@ts-ignore
  Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
  ];
  


  export default Editor
