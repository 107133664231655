
// Customizable Area Start
import React, { useState } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isTokenExpired} from "../../../components/src/utility/helper"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  classes?: any;
  logout:any
 
}

interface S {
  arrayHolder: any;
  token: string;
  tableData: any[];
  selected: any[];
  prodPerPage: any;
  currentPage: any;
  loading: any;
  openDialog: any;
  removedProduct: any;
  search: any;
  courseDetails: any;
  dateTimepickerValue: any
  productMode: any;
  bulkAction: any;
  restOrderModal: boolean;
  educatorsList: any;
  educatorsRef: any;
  myRef: any;
  courseList: any;
  course_id: any;
  studentList: any;
  total_count: any;
  total_pages: any,

}

interface SS {
  id: any;
}

export default class AdminProductListController extends BlockComponent<
  Props,
  S,
  SS
> {

  getCourseListApiCallId: string = "";
  getStudentListApiCallId: string = "";
  updateStudentStatusApiCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      tableData: [],
      selected: [],
      restOrderModal: false,
      prodPerPage: 5,
      currentPage: 1,

      loading: false,

      openDialog: false,
      removedProduct: "",
      search: "",
      courseDetails: "",
      dateTimepickerValue: new Date("2018-01-01T00:00:00.000Z"),
      educatorsRef: React.createRef(),
      productMode: "",
      bulkAction: "",
      educatorsList: "",
      myRef: React.createRef(),
      courseList: "",
      course_id: "",
      studentList: "",
      total_count: 0,
      total_pages: 0,


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode")
    this.setState({
      productMode: mode
    })
    this.getCourseListData(window.location.pathname.split("/")[4])

  }

  async componentDidUpdate() {
    if (this.state.course_id) {

      if (window.location.pathname.split("/")[5] != this.state.course_id) {
        const path = window.location.pathname.split("/")[5]
        this.setState({
          course_id: path
        })



      }
    }
  }

  async receive(from: string, message: Message) {
    this.handleReceive(message)
  }

  handleReceive = (message:any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response?.errors) {
        toast.error(response?.errors[0].message)
        this.setState({
          loading: false
        })
        if (response?.errors[0] && ((response?.errors[0].message == configJSON.tokenExpiredText) || (response?.errors[0].message == configJSON.invalidTokenText))) {
          this.props.logout()
        }
      }

      if (response && !response.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {
          this.successApiCall(response, apiRequestCallId)
        }
      }
  
    }
  }

  // success api call 
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    //get course list       
    if (apiRequestCallId === this.getCourseListApiCallId) {
      this.getStudentList(window.location.pathname.split("/")[5], this.state.search, this.state.prodPerPage, this.state.currentPage)
      this.setState({
        course_id: window.location.pathname.split("/")[5],
        courseList: responseJson.data,
        loading: false,

      });

    }

    // get students list 
    if (apiRequestCallId === this.getStudentListApiCallId) {
      this.setState({
        studentList: responseJson?.data,
        total_pages: responseJson?.meta?.pagination?.total_pages,
        total_count: responseJson?.meta?.pagination.total_count,
        loading: false,

      });

    }
    // update student statsu 
    if (apiRequestCallId === this.updateStudentStatusApiCallId) {
      this.setState({
        loading: false,
      });
      this.getStudentList(window.location.pathname.split("/")[5], this.state.search, this.state.prodPerPage, this.state.currentPage)
      toast.success(`${responseJson?.message || "Student Status Updated Successfully"}`)
    }
  }

  handleMenuClick = (value: any, item: any) => {
   let status= (value == "Block") ? "blocked": "active"
    this.updateStudentStatus(item.id,status)
  }

  // get course list
  getCourseListData = async (id: any) => {

    const accessToken = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: accessToken
    };

    this.setState({
      loading: true,
    });

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseListApiCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseListInfoAPiEndPoint + `?course[educational_contributor_id]=${id}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(message.id, message);

    return true;
  };

  // get student list
  getStudentList = async (id: any, search: any, per: any, page: any) => {

    this.setState({
      loading: true,
    });

const gettingId=()=>{
if(id != 0){
return `?course_id=${id}&`
    }else{
      return "?course_id=&"
    }
}
    
    

    let endpoint = `${gettingId()}search=${search}&per=${per}&page=${page}`
    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentsAPiEndPoint + endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

   // update student status
  updateStudentStatus = async (id: any, status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const httpBody = {
      account:{
        student_status:status
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateStudentStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentsAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);

      }, delay);
    };
  }
  SearchDebounceUpdate = this.SearchDebounce(this.getStudentList,
    1000)


    handleMenu=(status:any)=>{
      if(status=="blocked"){
        return ["Reactive"]
      }
      else {
        return ["Block"]
      }
    }
  }
// Customizable Area End