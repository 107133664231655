// Customizable Area Start
import React from "react"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import Image from "material-ui-image"
import { withRouter, RouteComponentProps } from "react-router-dom"

interface IProps extends RouteComponentProps {
  courseId: number
  courseName: string
  authorName: string
  courseImage: string
  history: any
}

const CourseCard = ({
  courseId,
  courseName,
  authorName,
  courseImage,
  history,
}: IProps) => {
  const handleCourseDetailsRedirect = () => {
    history.push(`/learning/courses/single?id=${courseId}`)
  }

  return (
    <Box className="cc_container" onClick={handleCourseDetailsRedirect}>
      {!!courseImage ? (
        <Image
          src={courseImage}
          alt="course-image"
          style={{
            width: "100%",
            height: "200px",
            padding: "20px",
            borderRadius: "12px",
          }}
          imageStyle={{
            borderRadius: "12px"
          }}
          animationDuration={500}
          loading={<CircularProgress color="inherit" size={24} />}
        />
      ) : (
        <Box className="default_course_container">
          <Typography>COURSE</Typography>
        </Box>
      )}
      {!!courseName && <Typography variant="h5" className="cc_course_name">
        {courseName}
      </Typography>}
      {!!authorName && <Typography variant="h6" className="cc_author_name">
        {authorName}
      </Typography>}
    </Box>
  )
}

export default withRouter(CourseCard)
// Customizable Area End
