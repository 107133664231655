import { combineReducers } from "redux";
import { userReducer } from "./Users/userReducer";
import { farmReducer } from "./Farms/farmReducer";
import { farmProductsCatalogueReducer } from "./FarmsCatalogue/farmCatalogueReducer";
import { restaurantsReducer } from "./Restaurant/RestaurantReducer";
import { userAdminRestaurantReducer } from "./UserAdminRestaurant/userAdminRestaurantReducer";
import { elearningReducer } from "./ELearning/ELearningReducer";
import { cmsReducer } from "./CMS/cmsReducer"
import { communityReducer } from "./Community/communityReducer"

export const reducers = combineReducers({
  user: userReducer,
  farms: farmReducer,
  farmProducts: farmProductsCatalogueReducer,
  restaurants: restaurantsReducer,
  userAdminRestaurant: userAdminRestaurantReducer,
  courses : elearningReducer,
  cms: cmsReducer,
  community: communityReducer
});
