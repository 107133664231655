// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface ICustomCheckBoxProps {
  name: string
  outlet: string[]
  disabled?: boolean
  type: string
  setValue: (field: string, value: string[]) => void
}

const CustomCheckBox = ({ name, outlet, disabled, type, setValue }: ICustomCheckBoxProps) => {

  return (
    <div className="customLabelCheckbox">
      <FormControlLabel
        control={
          <Checkbox
             name={name}
            checked={
              outlet && outlet.includes(name)
            }
            onChange={(e: any) => {
              const removedData =
                outlet && outlet.filter((x: any) => x != name);
              e.target.checked
                ? setValue(type, [...outlet, name])
                : setValue(type, removedData);
            }}
            disabled={disabled}
          />
        }
        label={name}
      />

    </div>
  );
};
export default CustomCheckBox;
// Customizable Area End
