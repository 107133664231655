import React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer"
import {
    WTTLogo,
    PoppinsRegularFont,
    PoppinsMediumFont,
    PoppinsSemiBoldFont,
} from "../assets"
import { formatPhoneNumber } from '../../../../components/src/utility/helper'


Font.register({
    family: "Poppins",
    fonts: [
        {
            src: PoppinsRegularFont,
        },
        {
            src: PoppinsSemiBoldFont,
            fontWeight: 600,
        },
        {
            src: PoppinsMediumFont,
            fontWeight: 500,
        },
    ],
})

const styles = StyleSheet.create({
    pageStyle: {
        paddingVertical: 10,
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "10px 25px 15px",
        borderBottom: "2px solid #20a258",
    },
    logo: {
        height: "30px",
    },
    mainContainer: {
        padding: "10px 30px 20px",
    },
    borderContainer: {
        border: "1px solid #d3c8cc",
        borderRadius: "20px",
        padding: 20,
        marginBottom: 20,
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    mainHeading: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#3a3a3a",
        textAlign: "center",
        fontFamily: "Poppins",
    },
    subHeading: {
        fontSize: "17px",
        fontWeight: 600,
        color: "#3a3a3a",
        margin: "8px 0px",
        paddingLeft: 3,
        fontFamily: "Poppins",
    },
    lightText: {
        fontSize: "15px",
        fontWeight: 500,
        color: "#8b8b8b",
        paddingVertical: 1,
        fontFamily: "Poppins",
        paddingHorizontal: 1
    },
    lightDarkText: {
        fontSize: "15px",
        fontWeight: 500,
        color: "#484848",
        paddingVertical: 1,
        fontFamily: "Poppins",
        paddingHorizontal: 1
    },
    darkText: {
        fontSize: "17px",
        fontWeight: 500,
        color: "#000",
        paddingVertical: 1,
        fontFamily: "Poppins",
        paddingHorizontal: 1
    },
    totalText: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#000",
        paddingVertical: 1,
        fontFamily: "Poppins",
        paddingHorizontal: 1
    },
    paymentMethodContainer: {
        border: "1px solid #d3c8cc",
        borderRadius: "20px",
        marginBottom: 10,
    },
    paymentMethodSubContainer: {
        padding: 20,
    },
    billingAddContainer: {
        borderTop: "1px solid #d3c8cc",
        padding: 20,
    },
})

interface IInvoicePdfTemplateProps {
    isNonDeliverInvoice?: boolean
    orderDate: string
    orderId: string
    orderTotal: string
    paymentMethod: string
    price: string
    fullName: string
    billingAddress: string
    number: string
    sFullName: string
    sAddressLine1: string
    sAddressLine2: string
    sCity: string
    sState: string
    sCountry: string
    items: string
    discount: number
    loyaltyPoints: number
    subTotal: string
    shipping: number
    transactionFees: number
    total: string
}

const InvoicePdfTemplate = ({
    isNonDeliverInvoice,
    orderDate,
    orderId,
    orderTotal,
    paymentMethod,
    price,
    fullName,
    billingAddress,
    number,
    sFullName,
    sAddressLine1,
    sAddressLine2,
    sCity,
    sState,
    sCountry,
    items,
    discount,
    loyaltyPoints,
    subTotal,
    shipping,
    transactionFees,
    total
}: IInvoicePdfTemplateProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.pageStyle}>
                <View style={styles.logoContainer}>
                    <Image src={WTTLogo} style={styles.logo} />
                    <Text style={styles.mainHeading}>Order details</Text>
                </View>
                <View style={styles.mainContainer}>
                    <View>
                        <Text style={styles.subHeading}>View order details</Text>
                        <View style={styles.borderContainer}>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightText}>Order date</Text>
                                <Text style={styles.lightDarkText}>{orderDate}</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightText}>Order ID</Text>
                                <Text style={styles.lightDarkText}>{orderId}</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightText}>Order total</Text>
                                <Text style={styles.lightDarkText}>{orderTotal}</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.subHeading}>Payment information</Text>
                        <View style={styles.paymentMethodContainer}>
                            <View style={styles.paymentMethodSubContainer}>
                                <Text style={styles.darkText}>Payment method</Text>
                                <Text style={styles.lightDarkText}>{paymentMethod}</Text>
                                <Text style={styles.lightDarkText}>{price}</Text>
                            </View>
                            {!isNonDeliverInvoice && (
                                <View style={styles.billingAddContainer}>
                                    <Text style={styles.darkText}>Billing address</Text>
                                    <Text style={styles.lightDarkText}>{fullName}</Text>
                                    <Text style={styles.lightDarkText}>{billingAddress}</Text>
                                    <Text style={styles.lightDarkText}>{formatPhoneNumber(number)}</Text>
                                </View>
                            )}
                        </View>
                    </View>

                    {!isNonDeliverInvoice && (
                        <View>
                            <Text style={styles.subHeading}>Shipping address</Text>
                            <View style={styles.borderContainer}>
                                <Text style={styles.lightDarkText}>{sFullName}</Text>
                                <Text style={styles.lightDarkText}>
                                    {sAddressLine1},{sAddressLine2}
                                </Text>
                                <Text style={styles.lightDarkText}>{sCity}</Text>
                                <Text style={styles.lightDarkText}>{sState}</Text>
                                <Text style={styles.lightDarkText}>{sCountry}</Text>
                            </View>
                        </View>
                    )}

                    <View wrap={false}>
                        <Text style={styles.subHeading}>Order summary</Text>
                        <View style={styles.borderContainer}>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Items</Text>
                                <Text style={styles.lightDarkText}>{items} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Discount</Text>
                                <Text style={styles.lightDarkText}>-{discount} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Loyalty points</Text>
                                <Text style={styles.lightDarkText}>-{loyaltyPoints} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Sub total</Text>
                                <Text style={styles.lightDarkText}>{subTotal} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Transaction fees</Text>
                                <Text style={styles.lightDarkText}>{transactionFees} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.lightDarkText}>Shipping</Text>
                                <Text style={styles.lightDarkText}>{shipping} $</Text>
                            </View>
                            <View style={styles.flexContainer}>
                                <Text style={styles.totalText}>Order total</Text>
                                <Text style={styles.totalText}>{total} $</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default InvoicePdfTemplate
