import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "framework/src/StorageProvider";
import { isTokenExpireResponse } from "../../../../components/src/utility/helper";
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ICartProductDetails } from "../../../../components/src/interfaces/shoppingCart";
// Customizable Area End

export const configJSON = require("../config");

export interface Props extends RouteComponentProps {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    history: any
    orderType: string
    deliveryTime: string
    isAcceptingOrder: boolean
    cartDetails: any,
    cartOrderMeta: any
    getCartItems: () => void
    getAddressAndItems: (notLoader?: boolean) => void
    setCheckoutOrder: (order: any) => Promise<void>
    setCartOrderMeta: (metaDetails: {
        sellerId: string,
        itemsIds: string[],
        orderNote: string
    }) => Promise<void>
    resetCartOrderMeta: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    address: string
    orderNote: string
    loginDialog: boolean
    checkOutLoader: boolean
    routeDetails : string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ShoppingCartLayoutController extends BlockComponent<
    Props,
    S,
    SS
> {

    timer: any;
    createOrderApiCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            address: "",
            orderNote: "",
            loginDialog: false,
            checkOutLoader: false,
            routeDetails : ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.handleRoute()
    }

    async componentWillUnmount(): Promise<void> {
        this.props.resetCartOrderMeta()
    }

    handleChangeOrderNote = (e: any) => {
        this.setState({ orderNote: e.target.value })
    }

    handleSetOrderNoteGlobal = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.setOrderMeta()
        }, 500)
    }

    setOrderMeta = async () => {
        const { product_owner_details, cart_items } = this.props.cartDetails
        const sellerId = product_owner_details?.data?.id
        const itemsIds = cart_items?.data?.map((cart: { id: string }) => +cart.id).sort()
        const { orderNote } = this.state
        await this.props.setCartOrderMeta({ sellerId, itemsIds, orderNote })
    }

    openLoginDialog = () => {
        this.setState({ loginDialog: true })
    }

    closeLoginDialog = () => {
        this.setState({ loginDialog: false })
    }

    isAnyProductOutStock = () => {
      const {
        cartDetails: {
          cart_items: { data: cartItems },
        },
      } = this.props
    
      return cartItems.some((item: ICartProductDetails) => {
        const { type } = item
        if (type === "farm_cart_item") {
          const {
            //@ts-ignore
            attributes: { track_quantity, available_quantity },
          } = item
          return track_quantity && available_quantity === 0
        }
        return false
      })
    }
    
    isAnyProductNotAvailable = () => {
      const {
        cartDetails: {
          cart_items: { data: cartItems },
        },
      } = this.props
    
      return cartItems.some((item: ICartProductDetails) => {
        const {
          attributes: { product_active },
        } = item
        return !product_active
      })
    }    

    isQuantityMoreThanAvailable = () => {
      const {
        cartDetails: {
          cart_items: { data: cartItems },
        },
      } = this.props
    
      return cartItems.some((item: ICartProductDetails) => {
        const { type } = item
        if (type === "farm_cart_item") {
          const {
            //@ts-ignore
            attributes: { track_quantity, available_quantity, quantity },
          } = item
          return track_quantity && Number(available_quantity) < quantity
        }
        return false
      })
    }    

    isCheckoutBtnDisabled = () => {
      const { checkOutLoader } = this.state
      const { isAcceptingOrder } = this.props
      const isOutOfStock = this.isAnyProductOutStock()
      const isProductIsNotAvailable = this.isAnyProductNotAvailable()
      const isLessAvailableQuantity = this.isQuantityMoreThanAvailable()
    
      return (
        checkOutLoader ||
        !isAcceptingOrder ||
        isOutOfStock ||
        isLessAvailableQuantity ||
        isProductIsNotAvailable
      )
    }    

    handleCheckoutOrder = async () => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
        if(!isLoggedIn) this.openLoginDialog()
        else {
            const { orderNote } = this.state
            const { cart_detail, cart_item_ids, coupon_details } = this.props.cartDetails
            const { total, shipping, sub_total, total_item, discount, item_count } = cart_detail
            const coupon_code_id = coupon_details.data?.id || null


            const payload = {
                payment_method: "credit_or_debit_card",
                address_id: "",
                orders: [
                    {
                        item_count,
                        total_item,
                        discount,
                        sub_total,
                        shipping,
                        total,
                        coupon_code_id,
                        note: orderNote,
                        cart_item_ids
                    }]
            }
            this.createOrderApi(payload)
        }
    }

    createOrderApi = async (orderDetails: any) => {
        this.setState({ checkOutLoader: true })

        const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createOrderApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createOrderApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(orderDetails)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if(apiRequestCallId === this.createOrderApiCallId) {
            this.setState({ checkOutLoader: false })
            const isQuantityError = responseJson?.quantity_error
            const isAvailabilityError = responseJson?.product_unavailable
            if(responseJson?.cart_item_ids?.length > 0) {
                const { orders} = responseJson
                const orderIds = orders.data.map((order: any) => order.id)

                this.props.history.push({
                    pathname: AppRoutings.CheckOutOrder, state: {
                        orderType : this.props.orderType,
                        cartIds: responseJson.cart_item_ids,
                        orderIds
                    }
                })
            } else if(isQuantityError || isAvailabilityError) {
              if(isQuantityError) toast.error(configJSON.quantityErrorText)
              else if(isAvailabilityError) toast.error(configJSON.availabilityErrorText)
              this.props.getCartItems()
            }
        }
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson && !responseJson.errors && !responseJson.error) {
               this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(isTokenExpireResponse(responseJson)) return

                if(apiRequestCallId === this.createOrderApiCallId) {
                    this.setState({ checkOutLoader: false })
                    toast.error(configJSON.errorText);
                }

                if (responseJson?.errors) {
                    responseJson.errors?.forEach((err: any) => {
                        toast.error(err?.message);
                    });
                }
            }
        }
        // Customizable Area End
    }

    handleRoute = () => {
        const sellerId = this.props.cartDetails.product_owner_details.data.attributes.id 
        if(this.props.orderType === configJSON.restaurantText){
            this.setState({routeDetails : `${AppRoutings.RestaurantDetails}/${sellerId}`})
        }
        else if(this.props.orderType === configJSON.courseText){
            this.setState({routeDetails :`${AppRoutings.CourseDetails}${sellerId}`})
        }
        else this.setState({routeDetails: `${AppRoutings.FarmDetails}/${sellerId}`})
    }
    // Customizable Area End

}
