// Customizable Area Start
import * as React from "react";
import PoliciesController, { Props } from "./PoliciesController.web";
import Loader from "../../../components/src/Loader.web";
import "../assets/css/policies.scss";
import { Container, Box, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import WebHeader from "../../../components/src/WebHeader.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenTopBanner from "../../../components/src/GreenTopBanner.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import { withRouter } from "react-router-dom";
import {
  updateActiveTab,
} from "./assets";
import { connect } from "react-redux";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
class Policies extends PoliciesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />

        <GreenTopBanner bannerHeading={`${this.settingBreadCrumb(this.state.value)}`} pageName={`${this.settingBreadCrumb(this.state.value)}`} />
        <Box style={{ marginBottom: "50px" }}>
          <Container className="policy_wrapper">
            {/* <BreadCrumb
              twoPage={true}
              threePage={false}
              firstPage="Home"
              secondPage={`${this.settingBreadCrumb(this.state.value)}`}
              firstPageLink="/"
              secondPageLink="#"
              thirdPage=""
              thirdPageLink=""
            /> */}
            <Grid container className="policy_container">
              <Grid item className="policy_tab_box" xs={12}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={this.state.value}
                      //@ts-ignore
                      onChange={this.handleChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                    >
                      <Tab label="Privacy Policy" {...this.a11yProps(0)} />
                      <Tab label="Terms And Conditions" {...this.a11yProps(1)} />
                      <Tab label="Policy For Buyer" {...this.a11yProps(2)} />
                      <Tab label="Policy For Seller" {...this.a11yProps(3)} />
                    </Tabs>
                  </Box>
                  <TabPanel
                    value={this.state.value}
                    index={0}
                    className="tabPanel"
                  >
                    {this.state.policies && this.state.policies.privacy_policy}
                  </TabPanel>
                  <TabPanel
                    value={this.state.value}
                    index={1}
                    className="tabPanel"
                  >
                    {this.state.policies && this.state.policies.terms_of_use}
                  </TabPanel>
                  <TabPanel
                    value={this.state.value}
                    index={2}
                    className="tabPanel"
                  >
                    {this.state.policies &&
                      this.state.policies.policy_for_buyer}
                  </TabPanel>
                  <TabPanel
                    value={this.state.value}
                    index={3}
                    className="tabPanel"
                  >
                    {this.state.policies &&
                      this.state.policies.policy_for_seller}
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};
export default connect(
  mapStateToProps,
  { updateActiveTab }
  //@ts-ignore
)(withRouter(Policies));

// Customizable Area End
