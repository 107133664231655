import React, { Component } from "react";
import { Grid, Box, Container } from "@material-ui/core";
import "./assets/css/settinglayout.scss";
import BreadCrumb from "./BreadCrumb.web";
import WebSideBar from "./WebSideBar.web";

class SettingLayout extends Component {
  render() {
    return (
      <>
        <Box className="setting_layout_container">
          <Container maxWidth="lg">
            <Grid container>
              <Grid item>
                <BreadCrumb
                  twoPage={true}
                  threePage={false}
                  firstPage="Home"
                  secondPage="Account Setting"
                  firstPageLink="/"
                  secondPageLink="/account-setting"
                  thirdPageLink=""
                  thirdPage=""
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="setting_layout_wrapper">
                    <Grid container style={{ height: "auto" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={3}
                        className="web_sidenav"
                      >
                        <WebSideBar />
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={9}>
                        {this.props.children}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default SettingLayout;
