import { Box, IconButton } from "@material-ui/core"
import React from "react"
import CloseIcon from "@material-ui/icons/CloseRounded"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  mainCloseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeBtnWrapper: {
    padding: theme.spacing(0.5),
    "& svg": {
      color: "#000",
    },
  },
}))

interface ICustomToastCloseIconProps {
  closeToast: () => void
}

const CustomToastCloseIcon = ({ closeToast }: ICustomToastCloseIconProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.mainCloseContainer}>
      <IconButton onClick={closeToast} className={classes.closeBtnWrapper}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default CustomToastCloseIcon
