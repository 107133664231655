import './assets/css/HeadingComponent.scss';

import React, { Component } from 'react'

class Heading extends Component {
    render() {
        return (
            <h3 className="common_heading">{this.props.children}</h3>
        );
    }
}

export default Heading;