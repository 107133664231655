import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import * as Yup from "yup";
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  classes?: any;
  logout : any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  campaignList: any[];
  currentRestId: string;
  search: string;
  searchVal: string;
  openDialog: boolean;
  removedProduct: string;
  bulkAction: string;
  productMode: string | null;
  campDetails: any;
  replyId: string;
  replyEditId: string;
  selectedCampaigns: string[];
  campaignsPerPage: number
  campaignsCurrentPage: number
  totalCampaignPage: number
  totalCampaignCount: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestCampaignsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  lineChart: any;
  getDropdownApiCallId: string = "";
  getCampaignListApiCallId: string = "";
  updateForumStatusApiCallId: string = "";
  deleteCampaignApiCallId: string = "";
  bulkActionsForumApiCallId: string = "";
  createForumApiCallId: string = "";
  getCampaignDetailsApiCallId: string = ""
  deleteReviewApiCallId: string = ""
  addReplyApiCallId: string = "";
  deleteReplyReviewApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      campaignList: [],
      currentRestId: "",
      search: "",
      searchVal: "",
      openDialog: false,
      removedProduct: "",
      bulkAction: configJSON.actionsText,
      productMode: "",
      campDetails: "",
      replyId: "",
      replyEditId: "",
      selectedCampaigns: [],
      campaignsPerPage: 5,
      campaignsCurrentPage: 1,
      totalCampaignPage: 0,
      totalCampaignCount: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount():any { 
    const query = new URLSearchParams(window.location.search)
    const mode = query.get(configJSON.modeText)

    this.setState({
      productMode: mode,
    });

    if (window.location.pathname === configJSON.pathName) {
      this.getCampaignList(this.state.search);
    }
    if (mode === configJSON.editText) {
      this.getCampDetails(query.get(configJSON.campaignIdText));
    }

  }

  async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>) {
    const {
      campaignsCurrentPage,
      campaignsPerPage,
      currentRestId,
      searchVal
    } = this.state

    if (
      prevState.campaignsCurrentPage !== campaignsCurrentPage ||
      prevState.campaignsPerPage !== campaignsPerPage ||
      prevState.currentRestId !== currentRestId ||
      prevState.searchVal !== searchVal
    ) {
      this.getCampaignList(searchVal)
    }
  }

  Schema = () => {
    const donationAmount = /^(?:\d{1,5}(?:\.\d{1,2})?|99999(?:\.00)?)$/;
    return Yup.object().shape({
      heading: Yup.string().required(configJSON.fieldValidateText),
      tagline: Yup.string().required(configJSON.fieldValidateText),
      description: Yup.string()
      .max(1000, configJSON.descValidateText)
      .required(configJSON.fieldValidateText),
      banner: Yup.string().required(configJSON.fieldValidateText),
      required_donation: Yup.string()
        .test(
          configJSON.isPositiveLabel, 
          configJSON.numberGreaterThanZero, 
          (value) => value > 0
        )
        .matches(donationAmount,configJSON.donationValidateText)
        .required(configJSON.fieldValidateText),
    });
}

handleAddCampaignBtn = () => {
  this.props.history.push(configJSON.routeURL);
}

handleSearchBtn = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    search: e.target.value,
  });
  this.SearchDebounceUpdate(e.target.value);
}

handleEndDecorator = (values: string) => {
  return values ? values?.trim()?.split(" ").length : "0"
}
 // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else if (responseJson?.errors) {
        toast.error(responseJson?.errors[0].message)
        this.setState({
          loading: false
        })
        if (responseJson?.errors[0] && ((responseJson?.errors[0].message == configJSON.tokenExpiredText) || (responseJson?.errors[0].message == configJSON.invalidTokenText))) {
          this.props.logout()
        }
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    //get Forums list
    if (apiRequestCallId === this.getCampaignListApiCallId) {
      const {
        total_count,
        total_pages
      } = responseJson?.meta?.pagination
      const { campaignsCurrentPage } = this.state

      this.setState({
        campaignList: responseJson.data,
        loading: false,
        totalCampaignCount: total_count,
        totalCampaignPage: total_pages
      });

      if (responseJson?.data?.length === 0 && campaignsCurrentPage > 1) this.setState({ campaignsCurrentPage: campaignsCurrentPage - 1 })
    }
    //update forum status
    if (apiRequestCallId === this.updateForumStatusApiCallId) {
      this.getCampaignList(this.state.search);
      this.setState({
        loading: false,
      });
      toast.success(configJSON.campaignUpdateMsg);

    }

    //delete forum
    if (apiRequestCallId === this.deleteCampaignApiCallId) {
      const mode = this.state.productMode == configJSON.editText
      this.setState({
        loading: false,
      });
      toast.success(configJSON.campaignRemovalMsg);
      mode && this.props.history.goBack()
    }

    // forum bulk actions
    if (apiRequestCallId === this.bulkActionsForumApiCallId) {
      this.getCampaignList(this.state.search);
      this.setState({
        selectedCampaigns: [],
        loading: false,
      });
      toast.success(configJSON.campaignUpdateMsg);
    }
    // createForumApiCallId
    if (apiRequestCallId === this.createForumApiCallId) {
      const mode = this.state.productMode == configJSON.createText
      toast.success(`${configJSON.campaignSuccessText} ${mode ? configJSON.addedText : configJSON.updatedText}`)
      this.setState({
        loading: false,
      });
      this.props.history.goBack()
    }

    // get forum details

    if (apiRequestCallId === this.getCampaignDetailsApiCallId) {
      this.setState({
        campDetails: responseJson.data,
        loading: false,
      });

    }
  }

  //get Forums list
  getCampaignList = async (search: any) => {
    this.setState({
      loading: true,
    })
    const {
      campaignsPerPage,
      campaignsCurrentPage
    } = this.state
    const endpoint = `?search=${search}&campaign_type=Restaurant&page=${campaignsCurrentPage}&per=${campaignsPerPage}`;

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createcampaignadmin + endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleUpdateCampaign = (id:any,event: React.ChangeEvent<{value:any}> ) => {
    this.updateForumStatus(event.target.value,id)
  }

  //updating status
  updateForumStatus = async (status:string,id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      campaign: {
        id: id,
        status: status,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateForumStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //remove product
  deleteCampaign = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCampaignApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `/${this.state.removedProduct}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //bulk actions
  bulkActions = async (event :React.ChangeEvent<{value:unknown}>) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status: event.target.value,
      ids: this.state.selectedCampaigns,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsCampaignsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //create forum
  createForum = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token: token,
    };
    const mode = this.state.productMode == "create"

    const endPoint = mode ? configJSON.getCampaignListAPiEndPoint : (configJSON.getCampaignListAPiEndPoint + `/${values.forumable_id}`)
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("campaign[tagline]", values.tagline);
    formdata.append("campaign[heading]", values.heading);
    formdata.append("campaign[description]", values.description);
    formdata.append("campaign[status]", values.status);

    if (values.banner?.length > 0) {
      values.banner[0].name && formdata.append("campaign[banner]", values.banner[0],
        values.banner[0].name)
    }

    formdata.append("campaign[campaign_type]", configJSON.RESTAURANT_CAMPAIGN_TYPE)
    formdata.append("campaign[required_donation]", values.required_donation)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Forum details
  getCampDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createcampaignadmin + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleClickCampaignsRow = (num: any,e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    const selectedIndex = this.state.selectedCampaigns.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedCampaigns, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedCampaigns.slice(1));
    } else if (selectedIndex === this.state.selectedCampaigns.length - 1) {
      newSelected = newSelected.concat(
        this.state.selectedCampaigns.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedCampaigns.slice(0, selectedIndex),
        this.state.selectedCampaigns.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selectedCampaigns: newSelected,
    });
  };

  isSelectedCampaigns = (num: any) =>
    this.state.selectedCampaigns.indexOf(num) !== -1;

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id,
    });
    if (value == configJSON.editText) {
      this.props.history.push(
       configJSON.editCampaignURL + `${item.id}`
      );
      this.getCampDetails(item.id)
    }
    if (value == configJSON.removeText) {
      this.handleDialog();
    }
  };

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  handleSearchValChange = (value: string) => {
    this.setState({ searchVal: value, campaignsCurrentPage: 1, selectedCampaigns: [] })
  }

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return async function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await call(...args)



      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.handleSearchValChange, 1000)

  resettingPagination = () => {
    this.setState({
      campaignsCurrentPage: 1,
    })
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = this.state.campaignList.map((n: { id: string }) => n.id)
      this.setState({ selectedCampaigns: newSelected });
      return;
    }
    this.setState({ selectedCampaigns: [] })
  }

  handleChangeTablePage = (value: number) => {
    this.setState({ campaignsCurrentPage: value, selectedCampaigns: [] });
  }

  handleIncreasePageView = () => {
    if (this.state.campaignsPerPage < 25) {
      this.setState({ campaignsPerPage: this.state.campaignsPerPage + 5, selectedCampaigns: [] });
    }
    this.resettingPagination()
  }

  handleDecreasePageView = () => {
    if (this.state.campaignsPerPage > 5) {
      this.setState({ campaignsPerPage: this.state.campaignsPerPage - 5, selectedCampaigns: [] });
    }
    this.resettingPagination()
  }

  handleDonationValueChange = (value: any, setFieldValue: (field: string, value: number) => void) => {
    const isNumber = !isNaN(value)
    if(isNumber) setFieldValue(configJSON.requiredDonationText, value)
  }
  // Customizable Area End
}
