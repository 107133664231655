// Customizable Area Start
import React, { useState } from "react"
import { Box, Grid, CircularProgress } from "@material-ui/core"
import Image from "material-ui-image"
import { RestaurantCardImg } from "../../assets"
import Carousel, { Modal, ModalGateway } from "react-images"

interface IRestaurantDetailsImageProps {
  restaurantImages: any[]
}

const customStyles = {
  container: (base: any) => ({
    ...base,
    "& img": {
      height: "85vh",
      borderRadius: "15px",
      objectFit: "contain"
    },
  }),
}

const RestaurantDetailsImages = ({
  restaurantImages,
}: IRestaurantDetailsImageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  const closeImageModal = () => {
    setModalIsOpen(false)
    setCurrentImageIndex(0)
  }

  const openImageModal = (imageIndex: number) => {
    setModalIsOpen(true)
    setCurrentImageIndex(imageIndex)
  }

  const formatImages = () => {
    const images =
      restaurantImages?.length > 0
        ? restaurantImages
        : [{ url: RestaurantCardImg }]
    return images.map((image: { url: string }) => {
      return { source: image.url }
    })
  }

  return (
    <>
      <Box className="restaurant_details_images">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box>
              <Image
                src={
                  restaurantImages.length > 0
                    ? restaurantImages[0]?.url
                    : RestaurantCardImg
                }
                style={{
                  paddingTop: "0px",
                  height: "448px",
                  borderRadius: "15px",
                }}
                iconContainerStyle={{
                  boxShadow: "0 2px 12px 0 #b7bdc480",
                  borderRadius: "12px",
                }}
                onClick={() => openImageModal(0)}
                loading={<CircularProgress className="image_loader" />}
              />
            </Box>
          </Grid>
          <Grid item container md={6} spacing={3} justifyContent="center">
            {restaurantImages.length > 1 &&
              restaurantImages
                ?.slice(1, 5)
                ?.map((restaurantImage: any, index: number) => {
                  if (index < 3 || restaurantImages.length === 5) {
                    return (
                      <Grid item xs={6} sm={6} key={restaurantImage?.id}>
                        <Box className="other_restaurant_images_container">
                          <Image
                            src={restaurantImage.url}
                            style={{
                              height: "208px",
                              width: "100%",
                              paddingTop: "0px",
                              borderRadius: "12px",
                              objectFit: "cover"
                            }}
                            iconContainerStyle={{
                              boxShadow: "0 2px 12px 0 #b7bdc480",
                              borderRadius: "12px",
                            }}
                            className="other_restaurant_images"
                            onClick={() => openImageModal(index + 1)}
                            loading={
                              <CircularProgress className="image_loader" />
                            }
                          />
                        </Box>
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item xs={6} sm={6} key={restaurantImage?.id}>
                        <Box
                          style={{
                            backgroundImage: `url(${restaurantImage.url})`,
                          }}
                          className="hover_effect_container"
                          onClick={() => openImageModal(index + 1)}
                        >
                          <Box className="last_image_hover">
                            <span>{restaurantImages.length - 4}+</span>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  }
                })}
          </Grid>
        </Grid>
      </Box>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal closeOnBackdropClick={false} onClose={() => closeImageModal()}>
            <Carousel
              styles={customStyles}
              currentIndex={currentImageIndex}
              views={formatImages()}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default RestaurantDetailsImages
// Customizable Area End
