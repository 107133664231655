// Customizable Area Start
import React from "react"

interface IStarIcon {
    fill?: string
}

const StarIcon = ({ fill = "#000000" }: IStarIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="30"
            height="30"
            x="0"
            y="0"
            viewBox="0 0 32 32"
            className=""
        >
            <g>
                <path
                    d="M23.479 29.691a2.325 2.325 0 0 1-1.089-.27l-6.233-3.276a.333.333 0 0 0-.314 0l-6.232 3.276a2.338 2.338 0 0 1-3.393-2.465l1.19-6.939a.337.337 0 0 0-.1-.3L2.27 14.8a2.338 2.338 0 0 1 1.3-3.989L10.533 9.8a.337.337 0 0 0 .254-.185L13.9 3.3a2.337 2.337 0 0 1 4.193 0l3.115 6.313a.34.34 0 0 0 .254.185l6.969 1.012a2.339 2.339 0 0 1 1.3 3.989l-5.043 4.914a.339.339 0 0 0-.1.3l1.19 6.939a2.341 2.341 0 0 1-2.3 2.735zM16 24.105a2.325 2.325 0 0 1 1.088.27l6.232 3.275a.321.321 0 0 0 .356-.025.325.325 0 0 0 .135-.331l-1.191-6.94a2.343 2.343 0 0 1 .672-2.069l5.043-4.915a.338.338 0 0 0-.188-.576l-6.968-1.013a2.335 2.335 0 0 1-1.76-1.279L16.3 4.188a.338.338 0 0 0-.606 0L12.581 10.5a2.335 2.335 0 0 1-1.761 1.279l-6.967 1.015a.337.337 0 0 0-.187.576l5.042 4.915a2.343 2.343 0 0 1 .672 2.069l-1.191 6.94a.338.338 0 0 0 .492.356l6.231-3.276A2.335 2.335 0 0 1 16 24.105z"
                    fill={fill}
                    data-original="#000000"
                    className=""
                ></path>
            </g>
        </svg>


    )
}

export default StarIcon
// Customizable Area End
