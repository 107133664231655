// Customizable Area Start
import React from "react"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { createStyles, withStyles } from "@material-ui/core/styles"
import OrderCancelRequestDialogController, {
  Props,
} from "./OrderCancelRequestDialogController.web"
import CustomCancelFlowButton from "./CustomCancelFlowButton.web"

export class OrderCancelRequestDialog extends OrderCancelRequestDialogController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      classes,
      isOpen,
      titleList,
      buttonTitle,
      isButtonLoading,
      handleButtonClick,
    } = this.props

    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={isOpen}
        onClose={this.handleDialogClose}
      >
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.titlesWrapper}>
              {titleList.map((title: string) => (
                <Typography key={title} className={classes.title}>
                  {title}
                </Typography>
              ))}
            </Box>
            <IconButton data-testid="cancel-order-close-button" size="small" onClick={this.handleDialogClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CustomCancelFlowButton
            buttonType="error"
            title={buttonTitle}
            isLoading={isButtonLoading}
            handleClick={handleButtonClick}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export const styles = () =>
  createStyles({
    dialogPaper: {
      borderRadius: "30px",
    },
    dialogContent: {
      padding: "25px 30px !important",
      "@media screen and (max-width: 480px)": {
        padding: "25px 25px 15px !important",
      },
    },
    dialogActions: {
      padding: "15px 30px 30px",
      gap: "20px",
      justifyContent: "center",
      "@media screen and (max-width: 480px)": {
        padding: "10px 25px 25px",
      },
    },
    contentWrapper: {
      display: "flex",
      alignItems: "flex-start",
      gap: "5px",
      "& button": {
        marginTop: "-2px",
        backgroundColor: "#f0f0f0",
      },
      "& svg": {
        color: "#000",
      },
    },
    titlesWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    title: {
      color: "#000",
      fontSize: "17px",
      fontFamily: "'Montserrat', sans-serif",
      "@media screen and (max-width: 480px)": {
        fontSize: "15px",
      },
    },
    button: {
      width: "90%",
      padding: "8px 20px",
      borderRadius: "8px",
      textTransform: "none",
      fontFamily: "'Montserrat', sans-serif",
      backgroundColor: "#e74747",
      border: "1px solid #e74747",
      "& span": {
        color: "#fff",
        fontSize: "16px",
      },
      "&:hover": {
        opacity: 0.9,
        backgroundColor: "#e74747",
      },
      "& svg": {
        color: "#fff",
      },
      "@media screen and (max-width: 480px)": {
        fontSize: "15px",
      },
    },
  })

export default withStyles(styles)(OrderCancelRequestDialog)
// Customizable Area End
