import { getDishCategories } from "./userAdminRestaurantAPI";
import { toast } from "react-toastify";
import store from "../store";

export const getDishAllCategories = () => async (dispatch) => {
  const adminRestaurant = store.getState().userAdminRestaurant;

  adminRestaurant.dishCategories.length === 0 &&
    dispatch({ type: "SET_DISH_CATEGORIES_LOADER", payload: true });
  try {
    const response = await getDishCategories();
    if (response.length > 0) {
      dispatch({ type: "SET_DISH_CATEGORIES", payload: response });
    }
  } catch (e) {
    toast.error("Something went wrong");
  }
  adminRestaurant.dishCategoriesLoader &&
    dispatch({ type: "SET_DISH_CATEGORIES_LOADER", payload: false });
};
