// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { Message } from "framework/src/Message"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper"
export const configJSON = require("../../config.js")
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    topicId: string
    heading: string
    authorName: string
    date: string
    isFollowed: boolean
    description: string
    isSearchCard?: boolean
    searchValue: string
    setCommunitySearchDialog: (isOpen: boolean) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    isFollowing: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class SearchDiscussionCardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    toggleFollowApiCallId: string = ""
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            isFollowing: this.props.isFollowed
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    handleDetailsRedirect = () => {
        const { topicId, history } = this.props
        history.push(`${AppRoutings.CommunityDiscussionDetails}/${topicId}`)
        this.props.setCommunitySearchDialog(false)
    }

    handleToggleFollow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        this.toggleFollowApi()
    }

    toggleFollowApi = async () => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const httpBody ={
            community_discussion_id: this.props.topicId
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.toggleFollowApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.toggleFollowDiscussionApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.toggleFollowApiCallId) {
            if(isTokenExpireResponse(responseJson)) return
            
            this.setState({ loader: false })
            toast.error("Something went wrong")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.toggleFollowApiCallId) {
                    if (!!responseJson?.data) {
                        this.setState({ loader: false, isFollowing: !this.state.isFollowing })
                        return
                    }
                    toast.error("Something went wrong")
                }
            } else {
                this.handleErrorApiResponse(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}

