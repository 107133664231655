// Customizable Area Start
import React from "react"
import { Box, Button, CircularProgress, Grid, IconButton } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { RestaurantCardImg, setRestaurantsCurrentPage } from "../../assets"
import { connect } from "react-redux"
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded"
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded"
import StarOutlineIcon from "@material-ui/icons/StarOutline"
import Image from "material-ui-image"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import AdCampaignWrapper from "../../../../LocalListingAds/src/AdCampaignWrapper.web"
import { IImage } from "../../../../../components/src/interfaces/common"
const { RESTAURANT_PAGE_MAX_VIEW } = require("../../config.js")

interface IRestaurant {
    id: string
    type: string
    attributes: {
        account_id: number
        advertisement_banner: IImage | null
        average_rating: number
        categories: string[]
        description: string
        dish_name: string
        images: IImage
        is_ad: boolean
        is_same_number: boolean
        latitude: number | null
        longitude: number | null
        name: string
        org_address: string
        org_contact_number: string
        org_owner_address: string
        org_owner_contact_number: string
        org_owner_name: string
        price: string
        quote_time: string
        reviews: { data: any[] }
        role_id: number
        status: string
        tags: string[]
        third_party_ad_url: string | null
        banner: IImage | null
    }
}

interface IRLPRestaurantsList extends RouteComponentProps {
    isLoading: boolean
    selectedCategory: string
    currentPage: number
    pagination: {
        total_pages: number
    }
    restaurants: IRestaurant[]
    RLPDescription: string
    setRestaurantsCurrentPage: (pageNo: number) => void
}

const RLPRestaurantsList = ({
    history,
    isLoading,
    selectedCategory,
    currentPage,
    pagination,
    restaurants,
    RLPDescription,
    setRestaurantsCurrentPage,
}: IRLPRestaurantsList) => {
    const restaurantDetailsRedirect = (id: string) => {
        history.push(`${AppRoutings.RestaurantDetails}/${id}`)
    }

    const viewAllRestaurantRedirect = () => {
        history.push({
            pathname: AppRoutings.RestaurantCatelog,
            state: selectedCategory,
        })
    }

    const capitalizeValue = (value: string) => {
        if (!value) return ""
        return `${value[0].toUpperCase()}${value.slice(1)}`
    }

    const managePageNext = () => {
        setRestaurantsCurrentPage(currentPage + 1)
    }

    const managePagePrevious = () => {
        setRestaurantsCurrentPage(currentPage - 1)
    }

    return (
        <Box className="restaurant_middle_content_wrapper">
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box className="heading_wrapper_res">
                        <p className="restaurant_green_background_heading_new">
                            Restaurants
                        </p>
                        <h1 className="restaurant_big_heading">Restaurants</h1>
                    </Box>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="restaurant_list_heading_wrapper"
                >
                    <Grid item xs={12} md={9}>
                        <p className="restaurant_middle_text">{RLPDescription}</p>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className="restaurant_view_all_content">
                            <Button className="restaurant_view_all_btn" onClick={viewAllRestaurantRedirect}>
                                View All
                            </Button>
                                <IconButton
                                    className="restaurants_arrow_icons_wrapper"
                                    disabled={currentPage <= 1}
                                    onClick={managePagePrevious}
                                >
                                    <ArrowBackIosRoundedIcon
                                        fontSize="small"
                                        className="restaurants_arrow_icons"
                                    />
                                </IconButton>
                            
                                <IconButton
                                    className="restaurants_arrow_icons_wrapper"
                                    disabled={[
                                        pagination?.total_pages,
                                        RESTAURANT_PAGE_MAX_VIEW,
                                    ].includes(currentPage)}
                                    onClick={managePageNext}
                                >
                                    <ArrowForwardIosRoundedIcon
                                        fontSize="small"
                                        className="restaurants_arrow_icons"
                                    />
                                </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {isLoading ? (
                <Grid
                    container
                    spacing={4}
                    className="custom_loader_container"
                >
                    <CircularProgress size={40} />
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    {restaurants?.map((item: IRestaurant) => {
                        const {
                            id,
                            attributes: {
                                images,
                                average_rating,
                                name,
                                quote_time,
                                dish_name,
                                is_ad,
                                third_party_ad_url,
                                advertisement_banner,
                                banner
                            },
                        } = item
                        const imageUrl = images ? images.url : undefined
                        const adImage = banner ? banner.url : imageUrl
                        const thirdPartyImageUrl = advertisement_banner
                            ? advertisement_banner.url
                            : undefined

                        return (
                            <Grid item xs={6} md={4} key={id}>
                              <AdCampaignWrapper
                                isAd={is_ad}
                                thirdPartyImageUrl={thirdPartyImageUrl}
                                thirdPartyUrl={third_party_ad_url}
                              >
                                <Box
                                    className="restaurant_famous_dish_card"
                                    onClick={() => restaurantDetailsRedirect(id)}
                                >
                                    <Box className="famous_dish_img_wrapper">
                                        <Image
                                            src={adImage || RestaurantCardImg}
                                            alt="restaurant banner"
                                            style={{
                                                borderRadius: "15px",
                                            }}
                                            loading={
                                                <CircularProgress className="image_loader" size={25} />
                                            }
                                        />
                                        <Box className="restaurant_star_rating_and_text_wrapper">
                                            <StarOutlineIcon className="star_icon_resto" />
                                            <p>{average_rating}</p>
                                        </Box>
                                    </Box>
                                    <Box className="famous_dish_contents">
                                        <h6>{capitalizeValue(name)}</h6>
                                        <Box className="famous_dish_timing_and_name_wrapper">
                                            <p>{quote_time || "No estimated time"}</p>
                                            <span>{capitalizeValue(dish_name)}</span>
                                        </Box>
                                    </Box>
                                </Box>
                              </AdCampaignWrapper>
                            </Grid>
                        )
                    })}

                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                className="rest_submit_orange_btn"
                                classes={{ label: "restaurant_submit_green_btn_color" }}
                                onClick={viewAllRestaurantRedirect}
                            >
                                View All
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.restaurants.isLoading,
        selectedCategory: state.restaurants.selectedCategory,
        currentPage: state.restaurants.currentPage,
        pagination: state.restaurants.pagination,
        restaurants: state.restaurants.restaurants,
        RLPDescription: state.cms.RLPDescription
    }
}

export default withRouter(
    connect(mapStateToProps, { setRestaurantsCurrentPage })(RLPRestaurantsList)
)
// Customizable Area End
