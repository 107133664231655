import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import StorageProvider from "framework/src/StorageProvider";
import { isTokenExpireResponse } from "../../../../components/src/utility/helper";
import { createRef } from "react";
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  isOpen: boolean;
  orderDetailsId: string;
  handleClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderDetailsLoader: boolean;
  orderDetails: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderDetailsDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  orderDetailsApiCallId: string = "";
  orderDetailsComponentRef: any = createRef();
  DEFAULT_ORDER_DETAILS = {
    attributes: {
      type: "",
      order_date: "",
      order_id: "",
      order_total: "",
      payment_information: {
        payment_method: "",
        price: ""
      },
      billing_address: {
        address_line1: "",
        address_line2: "",
        city: "",
        country: "",
        full_name: "",
        number: "",
        state: ""
      },
      order_summary: {
        items: "",
        discount: 0,
        loyalty_points: 0,
        sub_total: "",
        shipping: 0,
        transaction_fees: 0,
        total: ""
      },
      shipping_address: {
        address_line1: "",
        address_line2: "",
        city: "",
        country: "",
        full_name: "",
        number: "",
        state: ""
      }
    }
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      orderDetailsLoader: false,
      orderDetails: { ...this.DEFAULT_ORDER_DETAILS }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getOrderDetails();
  }

  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (isTokenExpireResponse(responseJson)) {
      return;
    }

    if (apiRequestCallId === this.orderDetailsApiCallId) {
      this.setState({
        orderDetails: { ...this.DEFAULT_ORDER_DETAILS },
        orderDetailsLoader: false
      });
      this.props.handleClose();
      const isManyErrors =
        Array.isArray(responseJson.errors) && responseJson.errors.length > 0;
      if (!isManyErrors) {
        toast.error("Something went wrong");
      }
    }

    if (responseJson.errors) {
      responseJson.errors.forEach((err: any) => {
        toast.error(err.message);
      });
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.orderDetailsApiCallId) {
          this.setState({
            orderDetails: responseJson.data,
            orderDetailsLoader: false
          });
        }
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCombineAddress = (addressList: string[]) => {
    const newAdd = addressList.filter((add: string) => !!add);
    return newAdd.join(", ") || "";
  };

  getOrderDetails = async () => {
    this.setState({ orderDetailsLoader: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.orderDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.orderDetailsApiEndPoint}${this.props.orderDetailsId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
