// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import HeadingWithButton from "../components/HeadingWithButton.web"

interface ISearchCategoryLayoutProps {
  heading: string
  viewAllRedirectUrl: string
  children: any
}

const SearchCategoryLayout = ({
  heading,
  viewAllRedirectUrl,
  children,
}: ISearchCategoryLayoutProps) => {
  return (
    <Box pb={3}>
      <Box pb={2}>
        <HeadingWithButton
          heading={heading}
          viewAllRedirectUrl={viewAllRedirectUrl}
        />
      </Box>
      {children}
    </Box>
  )
}

export default SearchCategoryLayout
// Customizable Area End
