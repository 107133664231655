import { getFarmList, getCategories, getTags, getFarmDetails, addRatingFarm, addToFavorite, getFarmsMinMaxPrice } from "./farmsAPI";
import { toast } from "react-toastify";
import store from "../store"
import { isTokenExpired } from "../../utility/helper"
import history from "../../utility/useHistory";


// Action for Get Farm list with filters
export const getFarmListAction = () => async (dispatch) => {
  dispatch({ type: "SET_IS_LOADING_TRUE" })
  const farmData = store.getState().farms
  if (farmData.farmsCategories.length <= 0)
    dispatch(getFarmCategoriesAndTagsAction())

  if (farmData.minPrice === 0 || farmData.maxPrice === 0)
    dispatch(getFarmPrices())

  try {
    const payload = {
      page: farmData.currentPage,
      count: farmData.showCount,
      filterByCategoryName: farmData.selectedCategory,
      filterByTagName: farmData.selectedTag,
      startPrice: farmData.startPrice,
      endPrice: farmData.endPrice,
      searchText: farmData.searchText,
      latitude: farmData.lat,
      longitude: farmData.lng
    }
    const response = await getFarmList(payload)
    if (response.data && response.meta.pagination.total_count > 0) {
      dispatch({ type: "SET_FARM_LIST", payload: response?.data })
      dispatch({
        type: "SET_PAGINATION_FOR_FARMERS_LIST",
        payload: response?.meta?.pagination,
      })
    } else {
      response?.errors?.map((err) => {
        toast.error(err?.message)
      })
      if (response?.meta?.pagination?.total_count === 0) {
        toast.error("No farms available")
        dispatch({ type: "SET_FARM_LIST", payload: [] })
        dispatch({ type: "SET_PAGINATION_FOR_FARMERS_LIST", payload: {} })
      }
    }
  } catch (e) {
    console.log("ERROR", e)
    toast.error("Something went wrong")
  }
  dispatch({ type: "SET_IS_LOADING_FALSE" })
}

export const setFarmsCurrentPage = (currentPage,showCount) => async (dispatch) => {
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:currentPage});
  dispatch(getFarmListAction())
}

export const getFarmCategoriesAndTagsAction = () => async (dispatch) => {
  try {
    const res1 = await getCategories()
    dispatch({ type:"SET_FARMS_FILTER_CATEGORIES", payload: res1 })

    const res2 = await getTags()
    dispatch({ type:"SET_FARMS_FILTER_TAGS", payload: res2.data })
  } catch (e) {
    toast.error("Something went wrong..")
  }
}

export const getFarmPrices = () => async (dispatch) => {
  try {
    const res = await getFarmsMinMaxPrice()
    dispatch({ type:"SET_FARMS_FILTER_PRICES", payload: res })
  } catch(e) {
    toast.error("Something went wrong!")
  }
}

export const setFarmSelectedCategory = (name) => async (dispatch) => {
  dispatch({type:"SET_FARMS_FILTER_SELECTED_CATEGORY", payload: name})
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:1});
  dispatch(getFarmListAction())
}

export const setFarmSelectedTag = (name) => async (dispatch) => {
  dispatch({type:"SET_FARMS_FILTER_SELECTED_TAG", payload: name})
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:1});
  dispatch(getFarmListAction())
}

export const setFilterByPrice = (array) => async (dispatch) => {
  dispatch({type:"SET_FARMS_FILTER_START_PRICE", payload: array[0]})
  dispatch({type:"SET_FARMS_FILTER_END_PRICE", payload: array[1]})
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:1});
  dispatch(getFarmListAction())
}

export const setFilterByTextAndLatLng = (latLng,text, address) => async (dispatch) => {
  const { nearByFilter } = store.getState().farms
  address && nearByFilter && dispatch({type:"SET_NEAR_BY_FARM_FILTER", payload: false })
  dispatch({type:"SET_FARMS_FILTER_LAT", payload: latLng?.lat})
  dispatch({type:"SET_FARMS_FILTER_LNG", payload: latLng?.lng})
  dispatch({type:"SET_FARMS_FILTER_SEARCH_TEXT", payload: text})
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:1});
  dispatch(getFarmListAction())
}

export const clearFilterByTextAndLatLng = () => async (dispatch) => {
  dispatch({type:"CLEAR_FARMS_FILTER_SEARCH_AND_LATLNG_FILTERS"})
  dispatch({type: 'SET_FARMS_CURRENT_PAGE',payload:1});
  dispatch(getFarmListAction())
}

export const getFarmDetailsAction = (id) => async (dispatch) => {
  dispatch({type: 'SET_IS_LOADING_TRUE'});
  try {
    const res = await getFarmDetails(id)
    if(res.hasOwnProperty('data')) {
      const { attributes } = res.data
      dispatch({ type: 'SET_FDP_DATA', payload: attributes?.popular_products_description || "" })
      dispatch({ type:'SET_FARM_DETAILS', payload: attributes })
    } else {
      if(await isTokenExpired(res)) return
      toast.error("Something went wrong")
      history.goBack()
    }
  } catch (e) {
    toast.error("Something went wrong")
  }

  dispatch({type: 'SET_IS_LOADING_FALSE'});
}

export const addRatingFarmAction = (formData,handleClear) => async (dispatch) => {
  dispatch({type:"SET_IS_FARM_REVIEW_LOADING_TRUE"})
  try {
    if(formData.rating &&  formData.comment && formData.id){
      const res = await addRatingFarm({
        review:{
          rating:formData.rating,
          comment:formData.comment,
          reviewable_id:formData.id,
          reviewable_type:"BxBlockCatalogue::Farm"
        }
      })
      if(!res.hasOwnProperty("errors")){
        dispatch({type:'ADD_FARM_REVIEW',payload:res.data})
        toast.success("Comment added successfully")
        handleClear()
      } else {
        await isTokenExpired(res)
      }
    } else {
      if(!formData.rating || !formData.comment){
        toast.error("Please fill the rating and review details")
      }
    }
  }catch (e) {
    toast.error("Something went wrong!!")
  }
  dispatch({type:"SET_IS_FARM_REVIEW_LOADING_FALSE"})
}

export const setFarmSearchAddress = (value) => async (dispatch) => {
  dispatch({type:"SET_FARM_SEARCH_ADDRESS", payload: value })
}

export const setNearByFilter = (lat,lng) => async (dispatch) => {
  const farmData = store.getState().farms
  if(farmData.nearByFilter){
    dispatch({type:"SET_FARMS_FILTER_LAT", payload: ""})
    dispatch({type:"SET_FARMS_FILTER_LNG", payload: ""})
    dispatch({type:"SET_NEAR_BY_FARM_FILTER", payload: !farmData.nearByFilter})
  }else{
    if(lat && lng){
      dispatch({type:"SET_FARMS_FILTER_LAT", payload: lat})
      dispatch({type:"SET_FARMS_FILTER_LNG", payload: lng})
      dispatch({ type: 'SET_FARMS_CURRENT_PAGE', payload: 1 })
      dispatch({type:"SET_FARM_SEARCH_ADDRESS", payload: "" })
      dispatch({type:"SET_NEAR_BY_FARM_FILTER", payload: !farmData.nearByFilter})
    }
  }
  dispatch(getFarmListAction())
}

export const addToFavoriteAction = (id) => async (dispatch) => {
  try {
    const res = await addToFavorite({
      favouriteable_id:id,
      favouriteable_type:"BxBlockCatalogue::Farm"
    })
    if(res.hasOwnProperty("data")){
      dispatch({type:"FARM_ADD_TO_FAVORITE_BUTTON"})
    }
  }catch (e) {
    toast.error("Something went wrong!!")
  }
}

export const resetFarmFilters = () => async (dispatch) => {
  dispatch({ type: 'RESET_FARMS_FILTERS' })
}