// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { toast } from "react-toastify";
import StorageProvider from "framework/src/StorageProvider";
import { AppRoutings } from "../../../../components/src/utility/app-routing";
import { isTokenExpired } from "../../../../components/src/utility/helper";
import {
  IAddonsObject,
  IRestaurantDetails,
  IRestaurantDonation,
  IRestaurantMenu,
  IRestaurantTag,
} from "../../../../components/src/interfaces/restaurant"
import { IForum } from "../../../../components/src/interfaces/common";
import { ICart } from "../../../../components/src/interfaces/shoppingCart";
export const configJSON = require("../config")

interface IActiveOrder {
    id: string
    price: string
    compareAtPrice: string
    image: string
    dishName: string
    description: string
    addons: IAddonsObject
}
// Customizable Area End


export interface Props {
  navigation: any
  id: string
  // Customizable Area Start
  cart: ICart
  match: any
  history: any
  userInfo: any
  activeTab: string
  isLoading: boolean
  restaurantForums: IForum[]
  restaurantsTags: IRestaurantTag[]
  restaurantMenu: IRestaurantMenu[]
  restaurantDetails: IRestaurantDetails
  restaurantDonation: IRestaurantDonation
  getCartItems: () => Promise<void>
  resetRestaurantDetails: () => void
  setCartBadge: (count: number) => void
  setCartItems: (cartItems: any) => void
  removeCartItem: (cartId: string) => void
  updateActiveTab: (tabName: string) => void
  getRestaurantDetailsAction: (id: string) => void
  handleCartLoader: (loading: boolean) => Promise<void>
  updateItemQuantity: (cartId: string, quantity: number) => void
  toggleRestaurantForumLike: (
    forumId: string,
    isLiked: boolean,
    likeCount: number
  ) => void
  handleResForumCommentCount: (forumId: string, count: number) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean
  activeOrder: IActiveOrder | null
  isFavorite: boolean
  isToggleFav: boolean
  isLoginDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RestaurantDetailsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  toggleFavouriteApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      openModal: false,
      activeOrder: null,
      isFavorite: false,
      isToggleFav: false,
      isLoginDialog: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const { activeTab, updateActiveTab } = this.props
    activeTab !== ActiveTabEnum.Restaurant && updateActiveTab(ActiveTabEnum.Restaurant)
    this.getResDetails()
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { restaurantDetails } = this.props
    if(prevProps.restaurantDetails?.attributes?.favourites !== restaurantDetails?.attributes?.favourites) {
      this.setState({
        isFavorite: restaurantDetails?.attributes?.favourites
      })
    }
  }

  async componentWillUnmount(): Promise<void> {
    !!this.props.resetRestaurantDetails && this.props.resetRestaurantDetails()
  }

  getResDetails = () => {
    const { match, history, getRestaurantDetailsAction } = this.props
    const { id } = match.params

    if (+id > 0) {
      getRestaurantDetailsAction(id)
      this.getDisplayCartItems()
      return
    }
    history.goBack()
  }

  getDisplayCartItems = async (notLoading: boolean = true) => {
    const { getCartItems, handleCartLoader, setCartItems } = this.props
    notLoading && await handleCartLoader(true)
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
    if (token) {
      await getCartItems()
    } else {
      const localItems = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
      const localItemsJson = JSON.parse(localItems)
      if (localItemsJson.length > 0) {
        const orders = this.groupingOrderFromSame(localItemsJson)
        setCartItems(orders)
      } else {
        setCartItems({})
      }
      await handleCartLoader(false)
    }
  }

  groupingOrderFromSame = (items: any) => {
    const orderType = items[0].orderCategory === configJSON.CARTABLE_TYPE_FARM ? "Farm" : "Restaurant"

    let formattedItems = items?.reduce((group: any, product: any) => {
      const {
        sellerId,
        productImage,
        orderCategory,
        sellerName,
        sellerImage,
        cartableId,
        name,
        price,
        comparePrice,
        quantity,
        description,
      } = product;

      const isFarmOrder = orderCategory === configJSON.CARTABLE_TYPE_FARM
      const amount = isFarmOrder ? Number(comparePrice) : (Number(comparePrice) + product.addOnsPrice)

      const orderType = isFarmOrder ? "farm_cart_item" : "restaurant_cart_item"
      const restaurantOrderData = !isFarmOrder ? {
        special_cooking_instruction: product.cookingInstruction,
        addons: product.extraThings.map((addon: any) => addon.name).join(', ')
      } : {}

      const itemDetails = {
        id: cartableId,
        type: orderType,
        attributes: {
          name,
          description,
          image: productImage,
          cart_price: price,
          quantity,
          cart_compare_at_price: comparePrice,
          product_active: true,
          discount: null,
          after_discount_price: null,
          listing_price: null,
          sgst: null,
          cgst: null,
          coupon_discount: null,
          discount_on_delivery: null,
          ...restaurantOrderData
        }
      }
      let cartDetail = {}
      if (group[sellerId]) {
        const { item_count, total_item, quantity: oldQuantity } = group[sellerId].cart_detail
        cartDetail = {
          item_count: item_count + 1,
          total_item: total_item + (amount * quantity),
          discount: 0,
          sub_total: total_item + (amount * quantity),
          shipping: 0,
          total: total_item + (amount * quantity),
          quantity: oldQuantity + quantity
        }
      } else {
        cartDetail = {
          item_count: 1,
          total_item: amount * quantity,
          discount: 0,
          sub_total: amount * quantity,
          shipping: 0,
          total: amount * quantity,
          quantity
        }
      }

      group[sellerId] = group[sellerId] ?? {
        product_owner_details: {
          data: {
            id: sellerId,
            type: "product_owner_details",
            attributes: {
              id: sellerId,
              name: sellerName,
              image: sellerImage,
              type: "Farm"
            }
          }
        },
        cart_items: {
          data: []
        },
        cart_detail: {},
        coupon_details: {
          data: null
        }
      }
      group[product.sellerId].cart_items.data.push(itemDetails)
      group[product.sellerId].cart_detail = cartDetail
      return group;
    }, {});


    const totalCart = Object.keys(formattedItems).reduce((mainCart: any, key: any) => {
      const { total_count, total_item, discount, sub_total, shipping, total, quantity } = mainCart
      const cartDetail = formattedItems[key].cart_detail
      return {
        total_count: total_count + cartDetail.item_count,
        total_item: total_item + cartDetail.total_item,
        discount: discount + cartDetail.discount,
        sub_total: sub_total + (cartDetail.sub_total - cartDetail.discount),
        shipping: shipping + cartDetail.shipping,
        total: total + cartDetail.total,
        quantity: quantity + cartDetail.quantity,
        coupon: {
          data: null
        }
      }
    }, {
      total_count: 0,
      total_item: 0,
      discount: 0,
      sub_total: 0,
      shipping: 0,
      total: 0,
      quantity: 0,
      coupon: {
        data: null
      }
    })

    return {
      product_cart_items: { ...formattedItems },
      type: orderType,
      order_accepted: true,
      cart_details: {
        data: {
          attributes: totalCart
        }
      }
    }

  }

  showModal= () => {
    this.setState({ openModal: true });
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  openLoginDialog = () => {
    this.setState({ isLoginDialog: true })
  }

  closeLoginDialog = () => {
    this.setState({ isLoginDialog: false })
  }

  handleActiveOrder = (
    id: string,
    price: string,
    compareAtPrice: string,
    image: string,
    dishName: string,
    description: string,
    addons: any
  ) => {
    const activeList = {
      id,
      price,
      compareAtPrice,
      image,
      dishName,
      description,
      addons,
    }
    this.setState({
      activeOrder: activeList,
      openModal: true,
    })
  }  

  handleLocalQuantityChange = async (
    productId: string,
    quantity: number,
    index: number
  ) => {
    const orders =
      (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]"
    const ordersJson = JSON.parse(orders)
  
    const newOrder = ordersJson.map((order: any, ind: number) => {
      if (order.cartableId === productId && ind === index) {
        return {
          ...order,
          quantity,
        }
      }
      return order
    })
    await StorageProvider.set(
      configJSON.ORDERS_STORAGE_KEY,
      JSON.stringify(newOrder)
    )
    this.getDisplayCartItems()
  }
  
  updateQuantity = async (
    cartId: string,
    prevQuantity: number,
    updateType: string,
    index: number
  ) => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    const newQuantity = updateType === "inc" ? prevQuantity + 1 : prevQuantity - 1
    if (newQuantity > 0) {
      if (isLoggedIn) this.props.updateItemQuantity(cartId, newQuantity)
      else {
        this.handleLocalQuantityChange(cartId, newQuantity, index)
      }
    }
  }  

  handleLocalItemRemove = async (index: number) => {
    const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJson = JSON.parse(orders)

    const newOrder = ordersJson.filter((_: any, ind: number) => {
      return ind !== index
    })
    await StorageProvider.set(configJSON.ORDERS_STORAGE_KEY, JSON.stringify(newOrder))
    this.props.setCartBadge(newOrder.length)
    this.getDisplayCartItems()
  }

  removeItem = async (cartId: string, index: number) => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(isLoggedIn) this.props.removeCartItem(cartId)
    else this.handleLocalItemRemove(index)
  }

  showOrder = () => {
    const { cart } = this.props;
    const { id } = this.props.match.params;
    const isCurrentRestaurantOrder =
      cart?.product_cart_items &&
      Object.keys(cart?.product_cart_items).some((key: string) => key === id);
    return (
      cart?.cart_details?.data?.attributes?.total_count > 0 &&
      cart?.type === "Restaurant" &&
      isCurrentRestaurantOrder
    )
  }

  getOrderDishes = () => {
    const { cart, match: { params: { id }} } = this.props
    return cart?.product_cart_items[id]?.cart_items?.data
  }

  handlePlaceOrder = () => {
    this.props.history.push(AppRoutings.ShoppingCart)
  }

  toggleFavorite = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(!isLoggedIn) {
      this.openLoginDialog()
      return
    }
    this.toggleFavoriteApi()
  }

  toggleFavoriteApi = async () => {
    this.setState({ isToggleFav: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const { restaurantDetails } = this.props

    const httpBody = {
      favouriteable_id: restaurantDetails?.id || "",
      favouriteable_type: configJSON.CARTABLE_TYPE_RESTAURANT
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.toggleFavouriteApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.toggleFavouriteApiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePatch
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.toggleFavouriteApiCallId) {
          this.setState({ isFavorite: !!responseJson?.meta?.favourite, isToggleFav: false })
        }

      } else {
        if(await isTokenExpired(responseJson)) return

        if (apiRequestCallId === this.toggleFavouriteApiCallId) {
          this.setState({ isToggleFav: false })
          toast.error("something went wrong")
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
