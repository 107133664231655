import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import { formatCoord, getCoords } from "../../../components/src/utility/helper";
export const configJSON = require("./config.js");
import React from "react";
import { clearTimeout } from "timers";
import { styles } from "./RestaurantRoleProfileLayoutWeb.web";
import { WithStyles } from "@material-ui/core/styles";
// Customizable Area End

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  close?: any;
  open?: any;
  history: any;
  userInfo: any;
  changeOTP1Verify: (isVerified: boolean) => void;
  resatOTPFlags: () => void;
  verifyUserAction: (history: object) => void;
  sentOTP1Action: (email: string) => void;
  verifyOTP1Action: (data: object) => void;
  setSameContactDetails: (value: boolean) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  latitude: number;
  longitude: number;
  mapCoOrdinates: {
    lat: number;
    lng: number;
  };
  address: string;
  detectedLatitude: number;
  detectedLongitude: number;
  detectLocation: boolean;
  createFarmerProfileApiResponse: object;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  openModal: boolean;
  closeModal: boolean;
  token: string;
  createProfile: boolean;
  display: string;
  verify_OTP_click: boolean;
  verify_OTP_click1: boolean;
  isVerify_OTP_disable: boolean;
  OTP_Error: string;
  OTP_Error_msg: string;
  isChecked: boolean;
  OTP_header_diplay: string;
  isValid: boolean;
  isOwner: boolean;
  OTP9: string;
  Onwer_OTP_header_diplay: string;
  Owner_isValid: boolean;
  Owner_OTP_Error: boolean;
  Owner_isDisable: boolean;
  otpSuccess: boolean;
  orgContactNumberError: string;
  orgOwnerContactNumberError: string;
  createProfileSchema: object;
  mobileType: string;
  otpclick: boolean;
  ownerOtp: boolean;
  isLoading: boolean;
  createError: any[];
  disableNextBtn: boolean;
  emailErrMsg: string | number;
  timeOutId: any;
  emailOTP: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateFarmerProfileWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  PathRoleID: string = "";
  formikRef: any = React.createRef()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    let CreateProfileSchema = Yup.object().shape({
      org_name: Yup.string().required(),
      org_address: Yup.string().required(configJSON.validateText),
      org_owner_name: Yup.string().required(configJSON.validateText),
      org_owner_address: Yup.string()
        .email(configJSON.emailValidateText)
        .required(configJSON.validateText),
      org_contact_number: Yup.string()
        .required(configJSON.validateText)
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, configJSON.numberValidateText),
      org_owner_contact_number: Yup.string()
        .required(configJSON.validateText)
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, configJSON.numberValidateText),
      latitude: Yup.string().required(configJSON.validateText),
      longitude: Yup.string().required(configJSON.validateText)
    });

    this.state = {
      latitude: 0,
      longitude: 0,

      mapCoOrdinates: {
        lat: 0,
        lng: 0
      },
      address: "",
      detectedLatitude: 0,
      detectedLongitude: 0,
      detectLocation: false,
      createFarmerProfileApiResponse: {},
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      openModal: false,
      closeModal: false,
      token: "",
      createProfile: false,
      createProfileSchema: CreateProfileSchema,
      display: "",
      OTP_Error: "",
      OTP_Error_msg: "",
      isChecked: false,
      OTP_header_diplay: configJSON.blockText,
      verify_OTP_click: false,
      verify_OTP_click1: false,
      isVerify_OTP_disable: false,
      isValid: true,
      otpSuccess: false,
      OTP9: "",
      Onwer_OTP_header_diplay: configJSON.blockText,
      Owner_isValid: false,
      Owner_OTP_Error: false,
      Owner_isDisable: false,
      orgContactNumberError: "",
      orgOwnerContactNumberError: "",
      mobileType: "",
      otpclick: false,
      ownerOtp: false,
      isOwner: false,
      isLoading: false,
      createError: [],
      disableNextBtn: false,
      emailErrMsg: "",
      timeOutId: "",
      emailOTP: ""
    };

    this.PathRoleID = window.location.pathname.split(":")[1];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): Promise<void> {
    const { isChecked } = this.state
    
    if(prevState.isChecked !== isChecked) {
      const orgContactNumber = this.getFormikValue('org_contact_number')
      const phoneNo = isChecked ? orgContactNumber : ""
      this.setFormikValue("org_owner_contact_number", phoneNo)
    }
  }

  setFormikValue = (key: string, value: any) => {
    if (this.formikRef.current) {
      this.formikRef.current.setFieldValue(key, value)
    }
  }

  getFormikValue = (key: string) => {
    let value = ''
    try {
      if(this.formikRef.current) {
        value = this.formikRef.current.values[key]
      }
    } catch(e) {
      console.log("error", e)
    }
    return value
  }

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0 || str === undefined || str === ""
  }
  
  isNumberNullOrBlank = (number: number) => {
    return number === null || number === undefined || number === 0
  }
  
  handleBack = () => {
    this.props.history.goBack()
  }
  
  SearchDebounce = (call: any, delay: number) => {
    let timer: any
    return function (...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }
  
  handleEmailOTPChange = (otpValue: string) => {
    const deb = this.SearchDebounce(this.OwnerOTPValidation, 1000)
    this.setState({ emailOTP: otpValue })
    otpValue.length === 4 && deb()
  }  

  async componentWillUnmount() {
    this.props.resatOTPFlags();
  }

  handleEmailValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { userInfo, changeOTP1Verify } = this.props
    const { value } = e.target
    if (userInfo.verifiedOTP1) {
      this.setState({ emailOTP: "" })
      changeOTP1Verify(false)
    }
    this.setFormikValue("org_owner_address", value)
  }
  
  handleDialogOnClose = (reason: string) => {
    const isValidClose =
      reason !== configJSON.escKeyDownText &&
      reason !== configJSON.backDropClickText
    if (isValidClose) {
      this.closeModal()
    }
  }  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        !responseJson.errors &&
        !responseJson.error
      ) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.setState({
            isLoading: false,
            openModal: true
          });
          this.props.verifyUserAction(this.props.history);
        }
      } else {
        this.handleErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleErrorResponse = (responseJson: any) => {
    this.setState({
      isLoading: false,
      createError: responseJson.errors,
    })
  
    let orgContactError = ""
    let orgOwnerContactError = ""
  
    if (
      `${configJSON.orgContactNumberText}` in responseJson.errors &&
      responseJson.errors.org_contact_number[0] ===
      configJSON.hasAlreadyBeenTakenText
    ) {
      orgContactError = configJSON.mobileAlreadyTakenText
    }
  
    if (
      `${configJSON.orgOwnerNumberText}` in responseJson.errors &&
      responseJson.errors.org_owner_contact_number[0] ===
      configJSON.hasAlreadyBeenTakenText
    ) {
      orgOwnerContactError = configJSON.mobileAlreadyTakenText
    }
  
    if (responseJson.status === 500) {
      toast.error(configJSON.somethingWentWrongText)
    }
  
    this.setState({
      orgContactNumberError: orgContactError,
      orgOwnerContactNumberError: orgOwnerContactError,
    })
  
    this.state.createError.length > 0 &&
      this.state.createError.map((err: { message: string }) => {
        toast.error(err.message)
      })
  }
  
  changeMobileNumber = () => {
    if (!this.props.userInfo.isOTP1Sent) {
      this.setState({
        otpSuccess: false,
        verify_OTP_click: false,
        orgContactNumberError: "",
      })
    }
  }
  
  handleBlurOrgNumber = () => {
    if (this.state.isChecked) {
      const orgContactNumber = this.getFormikValue("org_contact_number")
      this.setFormikValue(configJSON.orgOwnerNumberText, orgContactNumber)
    }
  }
  
  changeMobileNumber1 = () => {
    if (!this.props.userInfo.isOTP2Sent) {
      this.setState({
        Owner_isValid: false,
        verify_OTP_click1: false,
        orgOwnerContactNumberError: "",
      })
    }
  }
  
  doVerifyMobile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const value = this.getFormikValue("org_owner_address")
    if (value) {
      this.setState({
        orgContactNumberError: "",
        verify_OTP_click: true,
      })
      this.props.sentOTP1Action(value)
    } else {
      toast.error(configJSON.enterEmailAddressText)
    }
    this.setState({
      mobileType: configJSON.restaurantText,
    })
    return true
  }
  
  OwnerOTPValidation = async () => {
    if (this.state.emailOTP.length === 4) {
      this.setState({
        ownerOtp: true,
        isOwner: true,
      })
      if (this.props.userInfo.isOTP1Sent) {
        this.props.verifyOTP1Action({
          email_otp_id: this.props.userInfo.email_otp_id,
          pin: this.state.emailOTP,
        })
      }
      return true
    }
  }
  
  sameAsPrevious = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const { display, isChecked } = this.state
  
    if (display === configJSON.noneText && isChecked) {
      this.setState({
        display: configJSON.blockText,
        isChecked: false,
        Owner_isDisable: false,
      })
    } else {
      this.setState({
        display: configJSON.noneText,
        isChecked: true,
        Owner_isDisable: true,
      })
    }
    this.props.setSameContactDetails(isChecked)
  }
  
  doCreateProfile = async (values: any) => {
    this.setState({ createProfile: true })
    if (this.props.userInfo.verifiedOTP1) {
      this.setState({
        isLoading: true,
        emailErrMsg: "",
        timeOutId: null,
      })
      this.doCreateRestaurantProfile(values)
    } else if (this.state.timeOutId) {
      clearTimeout(this.state.timeOutId)
      this.setState({ emailErrMsg: "" })
    } else {
      const msg = toast.error(configJSON.emailNotVerifiedText)
      if (msg) {
        this.setState({ emailErrMsg: msg })
        const timeOutId = setTimeout(() => {
          this.setState({ emailErrMsg: "", timeOutId: null })
        }, 5000)
        this.setState({ timeOutId: timeOutId })
      }
    }
  }
  
  doCreateRestaurantProfile = async (values: {
    org_name: string
    org_address: string
    org_owner_name: string
    org_owner_address: string
    org_contact_number: string
    org_owner_contact_number: string
    latitude: string
    longitude: string
    purchase_limit_percentage: number
  }): Promise<boolean> => {
    this.setState({
      orgOwnerContactNumberError: "",
      orgContactNumberError: "",
    })
  
    const Token = await StorageProvider.get(configJSON.AUTH_TOKEN)
  
    const header = {
      "Content-Type": configJSON.createProfileContentType,
      token: Token,
    }
  
    const restaurant = {
      name: values.org_name,
      org_address: values.org_address,
      org_owner_name: values.org_owner_name,
      org_owner_address: values.org_owner_address,
      org_contact_number: `1${values.org_contact_number}`,
      org_owner_contact_number: `1${values.org_owner_contact_number}`,
      latitude: values.latitude,
      longitude: values.longitude,
      role_id: this.PathRoleID,
      purchase_limit_percentage: values.purchase_limit_percentage
    }
  
    const httpBody = {
      restaurant: restaurant,
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
  
    this.apiEmailLoginCallId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRestaurantProfileEndPoint
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodCreateRestaurantProfile
    )
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
  
    return true
  }
  
  showModal = () => {
    this.setState({ openModal: true })
  }
  
  closeModal = () => {
    this.setState({ openModal: false })
  }
  
  handlePlacesAutocompleteChange = (address: string) => {
    this.setState({ address: address })
  }
  
  handlePlacesAutocompleteSelect = (lat: number, lng: number) => {
    this.setFormikValue(configJSON.latitudeText, lat)
    this.setFormikValue(configJSON.longitudeText, lng)
    this.setState({ mapCoOrdinates: { lat, lng } })
  }
  
  handleChangeLat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const longitude = this.getFormikValue(configJSON.longitudeText)
    const latLng = {
      lat: Number(targetValue),
      lng: Number(longitude),
    }
    this.setFormikValue(configJSON.latitudeText, targetValue)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }
  
  handleChangeLng = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latitude = this.getFormikValue(configJSON.latitudeText)
    const latLng = {
      lat: Number(latitude),
      lng: Number(targetValue),
    }
    this.setFormikValue(configJSON.longitudeText, targetValue)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }
  
  handleChangeLatLng = (lat: number, lng: number) => {
    const latLng = { lat, lng }
    this.setFormikValue(configJSON.latitudeText, lat)
    this.setFormikValue(configJSON.longitudeText, lng)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }
  
  detectPlace = async () => {
    try {
      const coords: any = await getCoords()
      const { latitude, longitude } = coords
      this.setFormikValue(configJSON.latitudeText, latitude)
      this.setFormikValue(configJSON.longitudeText, longitude)
      this.setState({
        mapCoOrdinates: {
          lat: latitude,
          lng: longitude,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }  
  // Customizable Area End
}
