// Customizable Area Start
import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Grid, Box, Slider, CircularProgress } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"
import queryString from "query-string"
import { getCoords } from "../../../../components/src/utility/helper"
import { ICategory, ITag } from "../../../../components/src/interfaces/common"
import { SLIDER_STEP } from "../../../../components/src/utility/constants"
const configJSON = require("../config.js")

export type StateValue =
  | string
  | string[]
  | number
  | undefined
  | null
  | boolean
  | number[]

export interface KeyValue {
  [key: string]: StateValue
}

interface ICurrentPosition {
  latitude: number
  longitude: number
}

interface ISearchProductsFilters extends RouteComponentProps {
  categoryTitle: string
  categories: ICategory[]
  isToShowPriceFilter: boolean
  minPrice?: number
  maxPrice?: number
  isToShowNearByFilter: boolean
  nearByText?: string
  tags: ITag[]
  perPage?: number
  isLoading?: boolean
}

const SearchResultFilters = ({
  location,
  history,
  categoryTitle,
  categories,
  isToShowPriceFilter,
  minPrice = 0,
  maxPrice = 0,
  isToShowNearByFilter,
  nearByText = configJSON.nearByText,
  tags,
  perPage = 16,
  isLoading = false,
}: ISearchProductsFilters) => {
  const { pathname, search } = location
  const [selectedCategory, setSelectedCategory] = useState("")
  const [isNearByEnabled, setIsNearByEnabled] = useState(false)
  const [priceRange, setPriceRange] = useState<number[]>([0, 0])
  const [selectedTag, setSelectedTag] = useState("")

  const isPriceFilterAvailable =
    isToShowPriceFilter && Number(maxPrice) > 0

  const getParsedQueryString = () => {
    return queryString.parse(search)
  }

  const getValueFromParsedQueryString = (key: string) => {
    const parsedQueryString = getParsedQueryString()
    if (parsedQueryString && parsedQueryString[key]) {
      return parsedQueryString[key] as string;
    }
    return ""
  }

  const setPriceValues = () => {
    const filter_start_price = getValueFromParsedQueryString(
      configJSON.filterStartPriceText
    )
    const filter_end_price = getValueFromParsedQueryString(
      configJSON.filterEndPriceText
    )
    const startPrice = filter_start_price ? Number(filter_start_price) : minPrice
    const endPrice = filter_end_price ? Number(filter_end_price) : maxPrice
    setPriceRange([startPrice, endPrice])
  }

  useEffect(() => {
    setPriceValues()
  }, [minPrice, maxPrice])

  useEffect(() => {
    const latitude = getValueFromParsedQueryString(configJSON.latitudeText)
    const longitude = getValueFromParsedQueryString(configJSON.longitudeText)
    const filter_by_category_name = getValueFromParsedQueryString(
      configJSON.filterByCategoryNameText
    )
    const filter_by_tag_name = getValueFromParsedQueryString(
      configJSON.filterByTagNameText
    )
    setPriceValues()

    setIsNearByEnabled(Boolean(latitude && longitude))
    setSelectedCategory(filter_by_category_name)
    setSelectedTag(filter_by_tag_name)
  }, [search, pathname])

  const updateQueryStringAndPush = (keyValuePair: KeyValue) => {
    const parsedQueryString = getParsedQueryString()

    const newQueryString = queryString.stringify({
      ...parsedQueryString,
      ...keyValuePair,
      page: 1,
      per: perPage,
    })
    history.push(`${pathname}?${newQueryString}`)
  }

  const handleCategorySelect = (category: string) => {
    const categoryName = category === selectedCategory ? "" : category
    setSelectedCategory(categoryName)
    updateQueryStringAndPush({
      filter_by_category_name: categoryName,
    })
  }

  const handleNearByFilter = async () => {
    if (isNearByEnabled) {
      setIsNearByEnabled(false)
      updateQueryStringAndPush({
        latitude: "",
        longitude: "",
      })
    } else {
      try {
        const { latitude, longitude } = (await getCoords()) as ICurrentPosition
        setIsNearByEnabled(true)
        updateQueryStringAndPush({
          latitude,
          longitude,
        })
      } catch (err) {
        console.log(configJSON.errorText, err)
      }
    }
  }

  const handlePriceRangeChange = (
    _: React.ChangeEvent<{}>,
    newRange: number | number[]
  ) => {
    if (Array.isArray(newRange)) setPriceRange(newRange)
  }

  const handlePriceRangeChangeCommitted = () => {
    updateQueryStringAndPush({
      filter_start_price: priceRange[0],
      filter_end_price: priceRange[1],
    })
  }

  const handleTagSelect = (tag: string) => {
    const tagName = tag === selectedTag ? "" : tag
    setSelectedTag(tagName)
    updateQueryStringAndPush({
      filter_by_tag_name: tagName,
    })
  }

  return (
    <>
      {isLoading ? (
        <Box style={styles.loaderWrapper}>
          <CircularProgress style={styles.loader} size={24} />
        </Box>
      ) : (
        <Box className="farm_sidebar_container">
          <Grid container spacing={2}>
            {categories.length > 0 && (
              <Grid item xs={12} sm={6} md={12}>
                <Box className="farm_sidebar_common_box">
                  <Box className="farm_sidebar_heading">
                    <p className="farm_sidebar_box_heading">{categoryTitle}</p>
                  </Box>
                  <Box className="related_category_contents">
                    <Box className="sidebar_text_and_content_wrapper">
                      <Box>
                        {categories.map((item) => {
                          const { id, name } = item
                          return (
                            <p
                              key={id}
                              className={clsx({
                                ["selected"]: selectedCategory === name,
                              })}
                              onClick={() => handleCategorySelect(name)}
                            >
                              {`${name[0].toUpperCase()}${name.slice(1)}`}
                            </p>
                          )
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            {isToShowNearByFilter && (
              <Grid item xs={12} sm={6} md={12}>
                <Box className="farm_sidebar_common_box">
                  <p className="farm_sidebar_box_heading">
                    {configJSON.refineBy}
                  </p>
                  <Box
                    className={clsx("refine_by_content", {
                      ["refine_by_content_active"]: isNearByEnabled,
                    })}
                    onClick={handleNearByFilter}
                    style={{ cursor: "pointer" }}
                  >
                    <p>{nearByText}</p>
                    <CloseIcon
                      className="side_bar_close_icon_active"
                      fontSize="small"
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {isPriceFilterAvailable && (
              <Grid item xs={12} sm={6} md={12}>
                <Box className="farm_sidebar_common_box">
                  <p className="farm_sidebar_box_heading">
                    {configJSON.priceText}
                  </p>
                  <Box className="farm_sidebar_range">
                    <Slider
                      min={minPrice}
                      step={SLIDER_STEP}
                      max={maxPrice}
                      value={priceRange}
                      onChange={handlePriceRangeChange}
                      onChangeCommitted={handlePriceRangeChangeCommitted}
                      valueLabelDisplay="auto"
                    />
                  </Box>
                  <p className="price_container">
                    {configJSON.priceText} :
                    <span>
                      ${priceRange[0]} - ${priceRange[1]}
                    </span>
                  </p>
                </Box>
              </Grid>
            )}

            {tags.length > 0 && (
              <Box className="product_tag_content_wrapper">
                <p className="product_tag_header">
                  {configJSON.productTagText}
                </p>
                <Grid container spacing={2} className="product_tag_content">
                  {tags.map((tags) => {
                    const { id, name } = tags.attributes
                    return (
                      <Grid item xs={12} sm={6} md={12} lg={6} key={id}>
                        <p
                          className={clsx({
                            ["selected"]: selectedTag === name,
                          })}
                          onClick={() => handleTagSelect(name)}
                        >
                          {`${name[0].toUpperCase()}${name.slice(1)}`}
                        </p>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}

const styles = {
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "60vh",
    border: "1px solid #e6e6e6",
    borderRadius: "12px",
  },
  loader: {
    color: "#20a258",
  },
}

export default withRouter(SearchResultFilters)
// Customizable Area End
