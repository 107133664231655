// Customizable Area Start
import React, { Component } from "react"
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core"
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web"
const configJSON = require("./config.js")

interface Props {
  isOpen: boolean
  orderDetails: any
  handleClose: () => void
}

export class AdminRestaurantOrderDetailsDialog extends Component<Props> {
  render() {
    const { isOpen, orderDetails, handleClose } = this.props

    const orderDetailsAtt = orderDetails ? orderDetails.attributes : null
    const details = orderDetailsAtt
      ? {
          note: orderDetailsAtt.note,
          profileImage: orderDetailsAtt.image
            ? orderDetailsAtt.image.url
            : "",
          fullName: orderDetailsAtt.customer_name,
          orderStatus:
            orderDetailsAtt.status === configJSON.rejectedText
              ? configJSON.cancelledText
              : orderDetailsAtt.status,
          phoneNumber: orderDetailsAtt.phone_number,
          orderProductsList: orderDetailsAtt.order_details.data,
        }
      : {
          note: "",
          profileImage: "",
          fullName: "",
          orderStatus: "",
          phoneNumber: "",
          orderProductsList: [],
        }

    const {
      note,
      fullName,
      orderStatus,
      phoneNumber,
      profileImage,
      orderProductsList,
    } = details

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        classes={{
          paper: "custom_dialog_paper",
        }}
      >
        <CustomDialogTitle
          rootClassName="orderDialogTitle"
          onClose={handleClose}
        >
          <h4>{configJSON.orderDetailsLabel}</h4>
        </CustomDialogTitle>
        <DialogContent>
          <Box className="adminOrderModal">
            {orderProductsList.length > 0 && (
              <div className="subModalBlock">
                <div className="modalContent adminOrderModalContent">
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={7}>
                      {orderProductsList.map((product: any) => {
                        const {
                          id,
                          attributes: {
                            name,
                            addons,
                            special_cooking_instruction,
                            image,
                            price,
                            quantity,
                          },
                        } = product
                        const imageUrl = image ?? ""

                        return (
                          <div className="farmCard" key={id}>
                            <h3>{name}</h3>
                            <div className="farmCardContent">
                              <div className="imgBlock">
                                <img
                                  src={imageUrl}
                                  className="farmSubProfile"
                                  alt="product"
                                />
                              </div>
                              <div className="rightBlock">
                                {addons && <p>Extra: {addons}</p>}
                                {special_cooking_instruction && (
                                  <p>
                                    Cooking instruction:{" "}
                                    {special_cooking_instruction}
                                  </p>
                                )}
                                <h5>
                                  {configJSON.priceLabel} :{" "}
                                  <span>${price}</span>
                                </h5>
                                <h5>
                                  {configJSON.quantityLabel} :{" "}
                                  <span>{quantity}</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <Box>
                        <Card className="priceCard">
                          <CardContent className="cardContent">
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.totalItemLabel}
                              </Typography>
                              <Typography variant="h6">
                                $
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .total_item
                                }
                              </Typography>
                            </div>
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.discountLabel}
                              </Typography>
                              <Typography variant="h6">
                                -$
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .discount
                                }
                              </Typography>
                            </div>
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.loyaltyPointsLabel}
                              </Typography>
                              <Typography variant="h6">
                                -$
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .loyalty_points
                                }
                              </Typography>
                            </div>
                            <hr />
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.subTotalLabel}
                              </Typography>
                              <Typography variant="h6">
                                $
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .sub_total
                                }
                              </Typography>
                            </div>
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.transactionFeesLabel}
                              </Typography>
                              <Typography variant="h6">
                                $
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .transaction_fees
                                }
                              </Typography>
                            </div>
                            <div className="cardRow">
                              <Typography variant="h6">
                                {configJSON.shippingLabel}
                              </Typography>
                              <Typography variant="h6">
                                $
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .shipping
                                }
                              </Typography>
                            </div>
                            <hr />
                            <div className="cardRow cardTotalRow">
                              <Typography
                                variant="h6"
                                data-testid="item-count-heading"
                              >
                                {`${configJSON.totalLabel}(${orderDetailsAtt.order_details.billing_details
                                  .item_count
                                  }
                                  ${orderDetailsAtt.order_details
                                    .billing_details.item_count > 1
                                    ? configJSON.itemsLabel
                                    : configJSON.itemLabel
                                  })`}
                              </Typography>
                              <Typography variant="h6">
                                $
                                {
                                  orderDetailsAtt.order_details.billing_details
                                    .total
                                }
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      {note && (
                        <Box
                          mb={2}
                          className="farmOrderCard farmOrderNotesWrapper"
                        >
                          <Typography variant="h6">
                            {configJSON.notesLabel}
                          </Typography>
                          <textarea disabled value={note} />
                        </Box>
                      )}

                      <Box className="farmOrderCard farmOrderTrackingWrapper">
                        <Box>
                          <Typography className="orderTrackingStatus">
                            {orderStatus}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="orderDeliverText">
                            {configJSON.customerLabel}
                          </Typography>
                          <Box className="orderUserDetails">
                            <Avatar
                              src={profileImage}
                              className="orderUserAvatar"
                            />
                            <Box className="orderUserInfoDetails">
                              <Typography>{fullName}</Typography>
                              <Typography>{phoneNumber}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
}

export default AdminRestaurantOrderDetailsDialog
// Customizable Area End
