// Customizable Area Start
import React from "react"
import { Route } from "react-router-dom"
import CommunityHomePage from "../../info-page/src/Community/CommunityHomePage/CommunityHomePage.web"
import { AppRoutings } from "../../../components/src/utility/app-routing"

const CommunityRoutes = () => {
    return (
        <>
            <Route
                path={AppRoutings.Community}
                render={(routeProps: any) => <CommunityHomePage {...routeProps} />}
                exact
            />
        </>
    )
}

export default CommunityRoutes
// Customizable Area End
