// Customizable Area Start
import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ShareIcon from "@material-ui/icons/ShareOutlined"
import SocialShareDialog from "./SocialShareDialog.web"
import { isDesktop } from 'react-device-detect'

const useStyles = makeStyles({
    shareButton: {
        padding: "5.6px 15px",
        border: "1px solid #20a258",
        borderRadius: "24px",
        textTransform: "none",
        "& span": {
            color: "#000",
            fontSize: "16px",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 500,
        },
    },
})

interface IShareButtonProps {
    link: string
    title: string
}

const ShareButton = ({ link, title }: IShareButtonProps) => {
    const classes = useStyles()
    const [isShareDialog, setIsShareDialog] = useState(false)

    const openShareDialog = () => {
        setIsShareDialog(true)
    }

    const closeShareDialog = () => {
        setIsShareDialog(false)
    }

    const handleShare = () => {
        if (!isDesktop && navigator.share) {
            const shareOptions = {
                title,
                text: title,
                url: link,
            }
            navigator.share(shareOptions)
            return
        }
        openShareDialog()
    }

    return (
        <>
            <Box>
                <Button
                    startIcon={<ShareIcon />}
                    className={classes.shareButton}
                    onClick={handleShare}
                >
                    Share
                </Button>
            </Box>
            <SocialShareDialog
                link={link}
                title={title}
                isOpen={isShareDialog}
                handleClose={closeShareDialog}
            />
        </>
    )
}

export default ShareButton
// Customizable Area End
