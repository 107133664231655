const restaurantDetails = {
    id: "",
    type: "",
    attributes: {
        average_rating: 0,
        delivery_time_setting: "",
        description: "",
        dine_in_only: false,
        dish_name: "",
        favourites: false,
        images: [],
        latitude: 0,
        longitude: 0,
        name: "",
        order_accepted: false,
        reviews: { data: [] },
        status: ""
  }
}

const restaurantDetailsInfoState = {
    account: null,
    donation: null,
    learn_something_new: { data: [] },
    menus: { data: [] },
    restaurant: { data: { ...restaurantDetails } },
    tags: { data: [] }
}

const initialState = {
    restaurants: [],
    popularCategories: [],
    isCategoriesLoading: false,
    restaurantsDetails: { ...restaurantDetailsInfoState },
    restaurantsRatings: [],
    pagination: {
        current_page: 0,
        next_page: 0,
        per_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0
    },
    currentPage: 1,
    showCount: 12,
    isLoading: false,
    startPrice: 0,
    endPrice: 0,
    minPrice: 0,
    maxPrice: 0,
    filterType: "",
    filterName: "",
    filterByTagName: "",
    filterByCategoryName: "",
    restaurantsCategories: [],
    restaurantsTags: { data: [] },
    selectedCategory: "",
    selectedTag: "",
    searchText: "",
    lat: "",
    lng: "",
    restaurantSearchAddress: "",
    nearByFilter: false,
    isLoadingWrite: false,
    mapViewRestaurants: [],
    searchRestaurantValue: "",
}

export const restaurantsReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case 'SET__RESTAURANTS_LIST':
            return {
                ...state,
                restaurants: payload
            }
        case 'SET_POPULAR_CATEGORY_LIST':
            return {
                ...state,
                popularCategories: payload
            }
        case 'SET_RESTAURANTS_CATEGORY_IS_LOADING_TRUE':
            return {
                ...state,
                isCategoriesLoading: true
            }
        case 'SET_RESTAURANTS_CATEGORY_IS_LOADING_FALSE':
            return {
                ...state,
                isCategoriesLoading: false
            }
        case 'SET_RESTAURANTS_DETAILS':
            return {
                ...state,
                restaurantsDetails: payload,
                restaurantsRatings:payload?.restaurant?.data?.attributes?.reviews?.data
            }
        case 'SET_PAGINATION_FOR_RESTAURANTS_LIST':
            return {
                ...state,
                pagination: payload
            }
        case 'SET_RESTAURANTS_IS_LOADING_TRUE':
            return {
                ...state,
                isLoading: true
            }
        case 'SET_RESTAURANTS_IS_LOADING_FALSE':
            return {
                ...state,
                isLoading: false
            }
        case 'SET_RESTAURANTS_CURRENT_PAGE':
            return {
                ...state,
                currentPage: payload
            }
        case 'SET_RESTAURANTS_FILTER_START_PRICE':
            return {
                ...state,
                startPrice: payload
            }
        case 'SET_RESTAURANTS_FILTER_END_PRICE':
            return {
                ...state,
                endPrice: payload
            }
        case 'SET_RESTAURANTS_FILTER_PRICES':
            const { min_price, max_price } = payload
            return {
                ...state,
                minPrice: Number(min_price) || 0,
                maxPrice: Number(max_price) || 0
            }
        case 'SET_RESTAURANTS_FILTER_CATEGORIES':
            return {
                ...state,
                restaurantsCategories: payload
            }
        case 'SET_RESTAURANTS_FILTER_TAGS':
            return {
                ...state,
                restaurantsTags: payload
            }
        case 'SET_RESTAURANTS_FILTER_SELECTED_CATEGORY':
            if(state.selectedCategory === payload){
                return {
                    ...state,
                    selectedCategory: ""
                }
            }else{
                return {
                    ...state,
                    selectedCategory: payload
                }
            }
        case 'SET_RESTAURANTS_FILTER_SELECTED_TAG':
            if(state.selectedTag === payload){
                return {
                    ...state,
                    selectedTag: ""
                }
            }else{
                return {
                    ...state,
                    selectedTag: payload
                }
            }
        case 'SET_RESTAURANTS_FILTER_LAT':
            return {
                ...state,
                lat: payload
            }
        case 'SET_RESTAURANTS_FILTER_LNG':
            return {
                ...state,
                lng: payload
            }
        case 'SET_RESTAURANTS_FILTER_SEARCH_TEXT':
            return {
                ...state,
                searchText: payload
            }
        case 'CLEAR_RESTAURANTS_FILTER_SEARCH_AND_LATLNG_FILTERS':
            return {
                ...state,
                searchText: "",
                lat:"",
                lng:"",
            }
        case 'ADD_RESTAURANTS_REVIEW':
            let ratings = state.restaurantsRatings
            ratings.push(payload)

            let details = state.restaurantsDetails

            const allRatings = details.restaurant.data.attributes.reviews.data?.map(rat => rat.attributes.rating)
            const sum = allRatings.reduce((partialSum, a) => partialSum + a, 0)
            const newRating = sum / allRatings.length
            
            details.restaurant.data.attributes.average_rating = newRating.toFixed(1)

            return {
                ...state,
                restaurantsDetails: details,
                restaurantsRatings: ratings
            }
        case 'SET_IS_RESTAURANTS_REVIEW_LOADING_TRUE':
            return {
                ...state,
                isLoadingWrite: true
            }
        case 'SET_IS_RESTAURANTS_REVIEW_LOADING_FALSE':
            return {
                ...state,
                isLoadingWrite: false
            }
        case 'SET_RESTAURANT_SEARCH_ADDRESS':
          return {
              ...state,
              restaurantSearchAddress: payload
          }
        case 'SET_NEAR_BY_RESTAURANTS_FILTER':
            return {
                ...state,
                nearByFilter: payload
            }
        case 'RESTAURANTS_ADD_TO_FAVORITE_BUTTON':
            let updatedFev = {
                ...state.restaurantsDetails,
                favourites:!state.restaurantsDetails.favourites
            }
            return {
                ...state,
                restaurantsDetails: updatedFev
            }
        case 'SET_RESTAURANT_FILTER_TYPE':
            return {
                ...state,
                filterType: payload
            }
        case 'SET_MAP_VIEW_RESTAURANTS':
            return {
                ...state,
                mapViewRestaurants: payload
            }
        case 'SET_SEARCH_RESTAURANT_TEXT':
            return {
                ...state,
                searchRestaurantValue: payload
            }
        case "RESET_MAP_VIEW_RESTAURANT":
            return {
                ...state,
                mapViewRestaurants: [],
                searchRestaurantValue: ""
            }
        case 'RESET_RESTAURANT_DETAILS':
            return {
                ...state,
                restaurantsDetails: {},
                restaurantsRatings: []
            }
        case 'SET_RES_FORUM_LIKE':
            const newForum = [...state.restaurantsDetails?.learn_something_new?.data]?.map((forum) => {
                const { id, type, attributes } = forum
                if(payload.id === id) {
                    return {
                        id,
                        type,
                        attributes: {
                            ...attributes,
                            is_like: payload.isLiked,
                            likes: payload.likeCount
                        }
                    }
                }
                return forum
            })
            return {
                ...state,
                restaurantsDetails: {
                    ...state.restaurantsDetails,
                    learn_something_new: {
                        ...state.restaurantsDetails.learn_something_new,
                        data: newForum
                    }
                }
            }
        case 'SET_RES_FORUM_COMMENT':
            const forums = [...state.restaurantsDetails?.learn_something_new?.data]?.map((forum) => {
                const { id, type, attributes } = forum
                if(payload.id.toString() === id.toString()) {
                    return {
                        id,
                        type,
                        attributes: {
                            ...attributes,
                            comments: payload.count
                        }
                    }
                }
                return forum
            })
            return {
                ...state,
                restaurantsDetails: {
                    ...state.restaurantsDetails,
                    learn_something_new: {
                        ...state.restaurantsDetails.learn_something_new,
                        data: forums
                    }
                }
            }
        case 'RESET_RESTAURANTS_FILTERS':
            return {
                ...state,
                currentPage:1,
                showCount:12,
                isLoading:false,
                startPrice:0,
                endPrice:0,
                minPrice: 0,
                maxPrice: 0,
                filterType: "",
                filterName:"",
                filterByTagName:"",
                filterByCategoryName:"",
                selectedCategory:"",
                selectedTag:"",
                pagination:{},
                searchText:"",
                lat:"",
                lng:"",
                nearByFilter:false,
            }
        default:
            return state
    }
}