Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.apiMethodGetRoles = "GET";
exports.httpPostMethod = "POST"

exports.getRolesApiEndPoint = "/account_block/accounts/remaining_roles";

exports.apiMethodUpdateRoles = "POST";
exports.updateRolesApiEndPoint = "/account_block/accounts/update_roles";

exports.TOKEN = "token"
exports.AUTH_TOKEN = "authToken"
exports.PROFILE_KEY = "profile"
exports.FIRST_NAME_KEY = "firstname"
exports.LAST_NAME_KEY = "lastname"
exports.ADMIN_AUTH_KEY = "AdminAuth"
exports.CART_ID_KEY = "cartId"
exports.ORDERS_STORAGE_KEY = "orders"
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue"
exports.getRolesApiEndPoint = "account_block/accounts/remaining_roles";
exports.bulkAddToCartApiEndPoint = "bx_block_order_management/cart_items/bulk_add_to_cart"
