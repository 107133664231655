// Customizable Area Start
import React from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { connect } from "react-redux";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@mui/material/Rating";
import Dialog from '@material-ui/core/Dialog';
import RemoveTwoToneIcon from "@material-ui/icons/RemoveTwoTone";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import {
  getCartItems,
  handleCartLoader,
  setCartItems,
  updateItemQuantity,
  removeCartItem,
  RestaurantCardImg,
  updateActiveTab,
  getRestaurantDetailsAction,
  toggleRestaurantForumLike,
  handleResForumCommentCount,
  resetRestaurantDetails,
  setCartBadge,
  GreenCart
} from "../assets";
import RestaurantReviews from "./RestaurantDetails/RestaurantReviews.web";
import ShowMenuItemModal from "./ShowMenuItemModalWeb.web";
import "../assets/css/restaurant.scss";
import "../assets/css/restaurantdetails.scss";
import RestaurantDetailsController from "./RestaurantDetailsWebController.web";
import ReceiptCardWeb from "../FarmCatelog/ReceiptCard.web";
import RestaurantDetailsImages from "./RestaurantDetails/RestaurantDetailsImages.web"
import RestaurantDetailsMenu from './RestaurantDetails/RestaurantDetailsMenu.web'
import RestaurantCampaignBanner from "./RestaurantDetails/RestaurantCampaignBanner.web";
import LoginSignUpDialogWeb from "../langinpageLoggedIn/LoginSignUpDialog.web";
import CustomFavoriteIcon from "./CustomFavoriteIcon.web"
import ShareButton from "../../../../components/src/ShareButton.web";
import { getShareTitle } from "../../../../components/src/utility/helper";
import { IRestaurantTag } from "../../../../components/src/interfaces/restaurant";
import { IForum } from "../../../../components/src/interfaces/common";
import WebFooter from "../../../../components/src/WebFooter.web";
import GreenBanner from "../../../../components/src/GreenBanner.web";
const { GOOGLE_MAP_REDIRECTION_API } = require("../config.js");
// Customizable Area End

class RestaurantDetails extends RestaurantDetailsController {

  render() {

    // Customizable Area Start
    const {
      activeOrder,
      openModal,
      isFavorite,
      isToggleFav,
      isLoginDialog
    } = this.state

    const {
      match,
      cart,
      isLoading,
      restaurantDetails,
      restaurantDonation,
      restaurantMenu,
      restaurantsTags,
      restaurantForums,
      toggleRestaurantForumLike,
      handleResForumCommentCount
    } = this.props

    const restaurantName =  restaurantDetails?.attributes?.name ?? ""
    const restaurantImages = restaurantDetails?.attributes?.images || []
    const isAccepting = restaurantDetails?.attributes?.order_accepted
    const isDineInOnly = restaurantDetails?.attributes?.dine_in_only

    return (
      <>
        {isLoading ? (
          <Container fixed className="section">
            <Box className="custom_loader_container">
              <CircularProgress color="inherit" size={40} />
            </Box>
          </Container>
        ) : (
          <>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RestaurantDetailsImages restaurantImages={restaurantImages} />
                </Grid>

                <Grid item xs={12} md={7} lg={8}>
                  <Box className="restaurant_details_wrapper">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box className="restaurant_details_wrapper_header">
                          <Box className="res_details_info_box">
                            <div className="rating_number_box">
                              <span>
                                <StarBorderTwoToneIcon />
                              </span>
                              <p>{restaurantDetails?.attributes?.average_rating}</p>
                            </div>
                            <h6>{restaurantDetails?.attributes?.dish_name || ""}</h6>
                            <Box>
                              <CustomFavoriteIcon
                                isFavorite={isFavorite}
                                isToggleFav={isToggleFav}
                                toggleFavorite={this.toggleFavorite}
                              />
                            </Box>
                            <ShareButton
                              link={window.location.href}
                              title={getShareTitle(`${restaurantName} Restaurant`)}
                            />
                          </Box>
                          <div>
                            <a
                              target="_blank"
                              href={`${GOOGLE_MAP_REDIRECTION_API}${restaurantDetails?.attributes?.latitude},${restaurantDetails?.attributes?.longitude}`}
                            >
                              <p className="get_direction">Get Direction</p>
                            </a>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="restaurant_details_wrapper_details">
                          <h1>{restaurantName}</h1>
                          <Box>
                            <Rating
                              classes={{
                                iconFilled: "iconFilled",
                                iconHover: "iconHover",
                              }}
                              name="customized-empty"
                              defaultValue={0}
                              value={restaurantDetails?.attributes?.average_rating}
                              precision={0.5}
                              size="large"
                              readOnly
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <p>{restaurantDetails?.attributes?.description}</p>
                        </Box>
                      </Grid>
                      <Grid item container spacing={3}>
                        {restaurantsTags?.map(
                          (tag: IRestaurantTag) => {
                            const { name } = tag.attributes
                            return (
                              <Grid item key={name}>
                                <span className="tag">{name}</span>
                              </Grid>
                            )
                          }
                        )}
                      </Grid>

                      <RestaurantDetailsMenu
                        isAccepting={isAccepting}
                        isDineInOnly={isDineInOnly}
                        restaurantMenu={restaurantMenu}
                        handleActiveOrder={this.handleActiveOrder}
                      />
                    </Grid>
                  </Box>
                </Grid>

                {this.showOrder() && (
                  <Grid item xs={12} md={5} lg={4}>
                    <Box className="restaurant_details_place_order_wrapper_box">
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="restaurant_order_heading">
                          <Box>
                            <h4>Order</h4>
                          </Box>
                          <Box>
                            <img src={GreenCart} alt="cart-icon" />
                          </Box>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                          {this.getOrderDishes()?.map((order: any, index: number) => {
                            const {
                              id,
                              attributes: {
                                image,
                                name,
                                cart_price,
                                cart_compare_at_price,
                                quantity,
                                addons,
                                special_cooking_instruction
                              }
                            } = order

                            const isDiscount = Number(cart_compare_at_price) < Number(cart_price)

                            return (
                              <Grid
                                item
                                xs={12}
                                container
                                justifyContent="space-between"
                                className="restaurant_details_order"
                                key={id}
                              >
                                <Grid
                                  item
                                  xs={7}
                                  sm={8}
                                  md={7}
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={2} className="remove_btn_container">
                                    <IconButton
                                      className="remove_order"
                                      onClick={() => this.removeItem(id, index)}
                                    >
                                      <RemoveTwoToneIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Box className="restaurant_details_dish_details">
                                      <img src={image?.url || image || RestaurantCardImg} alt="dish" />
                                      <p>{name}</p>
                                    </Box>
                                    <Box className="restaurant_details_order_addons">
                                      <p>{!!addons && `Extra: ${addons}`}</p>
                                      {!!special_cooking_instruction && (
                                        <>
                                          <p>Cooking Instruction:</p>
                                          <span>{special_cooking_instruction}</span>
                                        </>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid item xs={3} sm={2} md={3}>
                                  <Box className="restaurant_details_quantity_box">
                                    <button
                                      disabled={quantity === 1}
                                      onClick={() =>
                                        this.updateQuantity(
                                          id,
                                          quantity,
                                          "dec",
                                          index
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <span>{quantity}</span>
                                    <button
                                      onClick={() =>
                                        this.updateQuantity(
                                          id,
                                          quantity,
                                          "inc",
                                          index
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <span className="res_details_price">
                                    ${cart_compare_at_price}
                                  </span>
                                  <del className="res_details_price">
                                    {isDiscount && `$${cart_price}`}
                                  </del>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                        <Grid item xs={12}>
                          <p className="deliver">Delivery</p>
                        </Grid>

                        <Grid item xs={12}>
                          <Box className="place_order_flex">
                            <h5>Total</h5>
                            <h2>${cart?.cart_details?.data?.attributes?.total}</h2>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            classes={{
                              root: "place_order_btn",
                              label: "btn-label",
                            }}
                            onClick={this.handlePlaceOrder}
                          >
                            Place Order
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Container>

            {
              restaurantDonation?.campaign_id > 0 && (
                <RestaurantCampaignBanner
                  campaignId={restaurantDonation.campaign_id}
                  heading={`Help ${restaurantDonation.restauranter_name}'s Restaurant`}
                  description={restaurantDonation.tagline}
                  backgroundImage={restaurantDonation.campaign_image?.url}
                  raisedDonation={restaurantDonation.donation_raised}
                  requiredDonation={restaurantDonation.required_donation}
                />
              )
            }

            <Container className="restaurant_lsn_wrapper">
              {restaurantForums?.length > 0 && (
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <h3 className="h3 restaurant_details_lsn_heading">
                      Learn Something New
                    </h3>
                  </Grid>
                  <Grid item container xs={12} spacing={4} justifyContent="center">
                    {restaurantForums?.map((forum: IForum) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} key={forum?.id}>
                          <ReceiptCardWeb
                            info={forum}
                            onLoginSuccess={this.getResDetails}
                            onLikeChange={toggleRestaurantForumLike}
                            onCommentUpdate={handleResForumCommentCount}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              )}
              
              <Box mt={5}>
                <RestaurantReviews />
              </Box>
            </Container>
          </>
        )}

        {activeOrder && <Dialog
          open={openModal}
          onClose={() => this.closeModal()}
          classes={{
            paper: "restaurant_add_on_dialog_paper",
          }}
        >
          <ShowMenuItemModal
            restaurantId={match.params.id}
            restaurantName={restaurantDetails?.attributes?.name}
            restaurantImages={restaurantImages}
            deliveryTime={restaurantDetails?.attributes?.delivery_time_setting}
            id={activeOrder.id}
            price={activeOrder.price}
            discountedPrice={activeOrder.compareAtPrice}
            description={activeOrder.description}
            dishName={activeOrder.dishName}
            addons={activeOrder.addons}
            image={activeOrder.image}
            close={this.closeModal}
            getDisplayCartItems={this.getDisplayCartItems}
          />
        </Dialog>}

        <LoginSignUpDialogWeb
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
          onLoginSuccess={this.getResDetails}
        />
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    userInfo: state.user,
    cart: state.user.cart,
    isLoading: state.restaurants.isLoading,
    restaurantDetails: state.restaurants.restaurantsDetails?.restaurant?.data,
    restaurantsTags: state.restaurants.restaurantsDetails?.tags?.data,
    restaurantMenu: state.restaurants.restaurantsDetails?.menus?.data,
    restaurantDonation: state.restaurants.restaurantsDetails?.donation,
    restaurantForums: state.restaurants.restaurantsDetails?.learn_something_new?.data,
    rating: state.restaurants.restaurantsDetails?.restaurant?.data?.attributes?.average_rating || 0
  };
}
// Customizable Area End

// Customizable Area Start
export default connect(
  mapStateToProps,
  {
    getCartItems,
    handleCartLoader,
    setCartItems,
    updateActiveTab,
    getRestaurantDetailsAction,
    resetRestaurantDetails,
    updateItemQuantity,
    removeCartItem,
    setCartBadge,
    toggleRestaurantForumLike,
    handleResForumCommentCount
  }
)(RestaurantDetails);
// Customizable Area End

