import React, { Component } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ProductDetailsLayout from "./productDetailsLayout.web";

class ProductDescriptionWeb extends Component {
  render() {
    return (
      <ProductDetailsLayout>
        <Box className="product_details_children_wrapper">
          <Grid container>
            <Grid item xs={12}>
              <Typography className="grey_text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                sequi eius quaerat ad facilis, voluptas perferendis sint saepe
                tempora eos odio minima aperiam velit natus nam explicabo
                repudiandae quibusdam iure!
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ProductDetailsLayout>
    );
  }
}

export default ProductDescriptionWeb;
