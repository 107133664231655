// Customizable Area Start
import React, { Component } from "react"
import { Grid, Container, Box, CircularProgress } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { connect } from "react-redux"
import WebFooter from "../../../components/src/WebFooter.web"
import GreenBanner from "../../../components/src/GreenBanner.web"
import FarmsPageSearchFilterComponent from "../../../components/src/FarmsPageSearchFilterComponent.web"
import FarmPageSideBar from "../../../components/src/FarmPageSideBar.web"
import FarmsCard from "../../../components/src/FarmsSectionsImgCard.web"
import FarmHeadingWithFilterInput from "./Farm/FarmHeadingWithFilterInput.web"
import {
  farm1,
  setFarmsCurrentPage,
  resetFarmFilters,
  updateActiveTab,
} from "./assets"
import "./assets/css/FarmsPage.scss"
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums"
import { AppRoutings } from "../../../components/src/utility/app-routing"
import {
  IFarm,
  IFarmReducerState,
} from "../../../components/src/interfaces/farm"
import AdCampaignWrapper from "../../LocalListingAds/src/AdCampaignWrapper.web"
import MobileFilterWrapper from "../../../components/src/MobileFilterWrapper.web"
import { scrollToTop } from "../../../components/src/utility/helper"
// Customizable Area End

// Customizable Area Start
interface Props {
  activeTab: string
  farms: IFarmReducerState
  updateActiveTab: (tab: string) => void
  resetFarmFilters: () => void
  setFarmsCurrentPage: (pageNumber: number, showCount: number) => void
}
// Customizable Area End

// Customizable Area Start
interface S {
  isMobileScreen: boolean
  isFiltersOpen: boolean
}
// Customizable Area End

class FarmsPage extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state ={
      isMobileScreen: window.innerWidth <= 768,
      isFiltersOpen: true,
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getWindowSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 768 })
  }

  handleFiltersClick = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen })
    if(!this.state.isFiltersOpen) scrollToTop("farm_page_top", "end")
  }

  componentDidMount() {
    const { activeTab, updateActiveTab } = this.props
    window.addEventListener('resize', this.getWindowSize)
    activeTab !== ActiveTabEnum.Market && updateActiveTab(ActiveTabEnum.Market)
  }

  componentWillUnmount() {
    const { resetFarmFilters } = this.props
    resetFarmFilters()
    window.removeEventListener('resize', this.getWindowSize)
  }

  handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const {
      farms: { showCount },
    } = this.props
    const headingElement = document.getElementById("farmer-market-heading")
    if(headingElement) headingElement.scrollIntoView()
    this.props.setFarmsCurrentPage(value, showCount)
  }
  // Customizable Area End

    render() {

      // Customizable Area Start
      const { farms } = this.props
      const { isMobileScreen, isFiltersOpen } = this.state
      const {
        isLoading,
        farms: farmListing,
        currentPage,
        pagination
      } = farms
      const isFiltersVisible = isMobileScreen ? isFiltersOpen : true

    return (
      <>
        <Box className="farms_page_content_wrapper">
          <Box className="farm_page_top" />
          <Container fixed>
            {isFiltersVisible && <FarmsPageSearchFilterComponent />}
            <Box className="farms_page_main_section">
              <Grid container spacing={2}>
                {isFiltersVisible && <Grid item xs={12} md={4} lg={3}>
                   <FarmPageSideBar categoryTitle="Related Categories" />
                </Grid>}
                <Grid item xs={12} md={8} lg={9}>
                  <Grid container spacing={3} id="farmer-market-heading">
                    <FarmHeadingWithFilterInput heading="Farmers Market" />
                  </Grid>
                  {isLoading ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="custom_loader_container">
                        <CircularProgress size={40} color="inherit" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      {farmListing?.length > 0 &&
                        farmListing.map((item: IFarm) => {
                          const {
                            id,
                            attributes: {
                              advertisement_banner,
                              name,
                              description,
                              images,
                              average_rating,
                              is_ad,
                              third_party_ad_url,
                              banner
                            },
                          } = item
                          const imageUrl = images ? images.url : undefined
                          const adImage = banner ? banner.url : imageUrl
                          const thirdPartyImageUrl = advertisement_banner
                            ? advertisement_banner.url
                            : undefined                          

                          return (
                            <Grid item xs={6} md={4} lg={3} key={id}>
                              <AdCampaignWrapper
                                isAd={is_ad}
                                thirdPartyImageUrl={thirdPartyImageUrl}
                                thirdPartyUrl={third_party_ad_url}
                              >
                                <FarmsCard
                                  cardName={name || "FARM"}
                                  description={description}
                                  thumbnail={adImage || farm1}
                                  displayPrice="none"
                                  displayButton="block"
                                  displayDuration="none"
                                  displayRating="block"
                                  average_rating={average_rating}
                                  boxShadow={false}
                                  path={`${AppRoutings.FarmDetails}/${id}`}
                                  imgPath={`${AppRoutings.FarmDetails}/${id}`}
                                />
                              </AdCampaignWrapper>
                            </Grid>
                          )
                        })}
                    </Grid>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4} />
                  </Grid>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      {pagination.total_pages > 1 && (
                        <Box className="web_pagination_container">
                          <Pagination
                            count={pagination.total_pages}
                            onChange={this.handlePaginationChange}
                            onClick={() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            }
                            page={currentPage}
                            variant="outlined"
                            shape="rounded"
                            classes={{
                              root: "farm_pagination",
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <GreenBanner />
        <WebFooter />
        <MobileFilterWrapper
          hideSort
          isFiltersOpen={isFiltersOpen}
          handleFiltersClick={this.handleFiltersClick}
        />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {
    farms: state.farms,
    activeTab: state.user.activeTab
  }
}
// Customizable Area End

// Customizable Area Start
export default connect(mapStateToProps, {
  setFarmsCurrentPage,
  updateActiveTab,
  resetFarmFilters,
})(FarmsPage)
// Customizable Area End
