// Customizable Area Start
import React from "react"
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core"
import "web/src/assets/css/webstyle.scss"
import "../../assets/css/shoppingcart.scss"

interface IShoppingCartTotalAmountProps {
    cart: any
    displayItems: any
    isCheckoutDisabled?: boolean
    handleCheckoutOrder: () => void
}

const ShoppingCartTotalAmount = ({
    cart,
    displayItems,
    isCheckoutDisabled,
    handleCheckoutOrder,
}: IShoppingCartTotalAmountProps) => {
    const {
      product_cart_items,
      cart_details: {
        data: {
          attributes: {
            total_item: totalItem,
            discount,
            sub_total,
            transaction_fees,
            shipping,
            total_count,
            total
          }
        }
      }
    } = cart

    return Object.keys(displayItems)?.length > 1 ? (
      <Box mt={5} className="all_cart_detail_container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              {Object.keys(displayItems).map((key: string, index: number) => {
                const { total_item } = product_cart_items[key].cart_detail
                return (
                  <Box mb={2} key={key}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className="all_cart_detail_grid_heading"
                      >
                        <Typography className="all_cart_detail_heading">
                          {`Cart ${index + 1}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className="all_cart_detail_grid">
                        <Typography className="all_cart_detail_content">
                          Total Item
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className="all_cart_detail_grid">
                        <Typography className="all_cart_detail_content">
                          $ {total_item}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} style={{ paddingRight: "10px" }}>
              <Grid item xs={12} className="all_cart_detail_grid_heading">
                <Typography className="all_cart_detail_heading">
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content">
                  Total Item
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content" align="right">
                  $ {totalItem}
                </Typography>
              </Grid>

              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content">
                  Discount
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content" align="right">
                  -$ {discount}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box></Box>
              </Grid>
              <Grid item xs={12}>
                <Box></Box>
              </Grid>

              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content">
                  Sub Total
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content" align="right">
                  $ {sub_total}
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content">
                  Transaction fees
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content" align="right">
                  $ {transaction_fees}
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content">
                  Shipping
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_content" align="right">
                  {cart.type === "Farm" ? "--" : `${shipping}`}
                </Typography>
              </Grid>
              <Grid item xs={12} className="all_cart_detail_grid">
                <hr />
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_heading">
                  {`Total (${total_count}
                      ${total_count > 1 ? "Items" : "Item"})`}
                </Typography>
              </Grid>
              <Grid item xs={6} className="all_cart_detail_grid">
                <Typography className="all_cart_detail_heading" align="right">
                  $ {total}
                </Typography>
              </Grid>
              <Grid xs={12} className="all_cart_detail_grid">
                <Box className="checkout_btn" mb={2} mt={4}>
                  <Button
                    data-testid="carts_checkout_btn"
                    classes={{
                      disabled: "disable_btn",
                    }}
                    disabled={isCheckoutDisabled}
                    onClick={handleCheckoutOrder}
                  >
                    Proceed to Check Out
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ) : null
}

export default ShoppingCartTotalAmount
// Customizable Area End
