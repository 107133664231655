// Customizable Area Start
import * as React from 'react';
import webEducationCourseDetailsController, {
  Props,
} from "./webEducationCourseDetailsController.web";
import { withRouter } from "react-router-dom";
import '../../../web/src/assets/css/style.scss'
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import "../assets/css/accordion.scss";
import "../../info-page/src/assets/css/comment.scss";
import {
  Grid,
  Typography,
  Box,
  Menu, 
  MenuItem,
  Modal} from "@material-ui/core";
import { LeftIcon } from "./assets";
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import { SectionTabs } from './SectionTabs.web';
import { TextField } from '@mui/material';
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import WebHeader from "../../../components/src/WebHeader.web";

export class LandingPage5 extends webEducationCourseDetailsController {
  video1Ref: any;
  constructor(props: Props) {
    super(props);
    this.video1Ref = React.createRef();
  }
  handleBack = () => {
    history.back();
  }

  render() {
    const CourseDetail = this.props.location.state.content
    const partNum = this.props.location.state.partNum
    const partName = this.props.location.state.partName
    const lessonNum = this.props.location.state.lessonNum
    return (
      <>
        <WebHeader/>
        <WebNewRoutingTabs/>
        <Grid
          container
          className="courseHeaderBox"
          style={{ border: '1px solid #92929d' }}
        >
          <Grid item xs={12}>
            <div
              className="flex"
              style={{ justifyContent: "unset", gap: "1rem", alignItems: 'center' }}
            >
              <img
                className="backIcon"
                style={{ cursor:'pointer'}}
                src={LeftIcon}
                alt=""
                onClick={this.handleBack}
              />
              <h5 style={{ color: '#169d4e', paddingLeft: '15px', fontWeight: 700, wordSpacing: '4px' }}>{CourseDetail.attributes?.title}</h5>
              <MoreHorizRoundedIcon className="threeDots" onClick={this.handleOptions} />
                <Menu
                id="basic-menu"
                style={{position:'absolute', top:'11%', padding:'20px'}}
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseOptions}
          className="menu-customize"
        >
          <MenuItem style={{backgroundColor:'#fff'}} onClick={this.handleShare}>Share</MenuItem>
        </Menu>
        <Modal disableEnforceFocus open={this.state.modalOpen} onClose={this.handleModalClose} >
          <Box className="shareModal"> 
            <Typography className="shareCourseText">Share this course</Typography>
            <Grid container className="shareContainer">
            <div style={{display:'flex', width:'310px'}}>
                <TextField 
                className="sharelink" 
                disabled value={window.location.href} />
                
            </div>
            <div style={{display:'flex'}}><button className="copyBtn"
            onClick={this.handleCopy}>Copy</button></div>
            
            </Grid> 
            {this.state.copy && <div className="copiedTxt">Link Copied!</div> }
          </Box>
        </Modal>
            </div>

          </Grid>
        </Grid>

        <div className="margin-2">

          <SectionTabs
            partNum={partNum}
            partName={partName}
            lessonNum={lessonNum}
            courseDetail={CourseDetail}
            detail={this.state.lessonContent}
            isOwner={this.state.isOwner}
            isVideoComplete={this.state.isVideoComplete}
            isLessonComplete={this.state.isLessonComplete}
            list={this.state.listOfLesson} navigation={undefined} id={''} lessonInfo={this.state.lessonInfo} />
        </div>
      </>
    );
  }
}

//@ts-ignore
const routerLandingPage5:any = withRouter(LandingPage5)

export default routerLandingPage5;
// Customizable Area End