import React, { Component } from "react"
import { styled } from "@mui/system"
import { Box } from "@material-ui/core"
const configJSON = require("./config.js")

const FiltersWrapper = styled(Box)({
  display: "none",
  "@media (max-width: 768px)": {
    display: "flex",
    alignItems: "center",
    position: "sticky",
    bottom: "0px",
    left: "0px",
    width: "100%",
    zIndex: 9,
    backgroundColor: "#fff",
    boxShadow: "0 -2px 4px 0 rgba(198, 197, 197, 0.25)",
  },
})

const CustomButton = styled("button")(
  ({ isActive }: { isActive?: boolean }) => ({
    border: "none",
    padding: "12px 8px",
    color: "#20a258",
    width: "100%",
    backgroundColor: "#fff",
    fontWeight: isActive ? "bold" : "normal",
  })
)

interface CommonProps {
  isFiltersOpen: boolean
  handleFiltersClick: () => void
}

interface WithSortProps extends CommonProps {
  hideSort: false
  handleSortClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface WithoutSortProps extends CommonProps {
  hideSort?: true
  handleSortClick?: never
}

type Props = WithSortProps | WithoutSortProps

export class MobileFilterWrapper extends Component<Props> {
  render() {
    const { isFiltersOpen, hideSort, handleFiltersClick, handleSortClick } = this.props
    return (
      <FiltersWrapper>
        <CustomButton
          hidden={hideSort}
          onClick={handleSortClick}
        >
          {configJSON.sortText}
        </CustomButton>
        <CustomButton isActive={isFiltersOpen} onClick={handleFiltersClick}>
          {configJSON.filterText}
        </CustomButton>
      </FiltersWrapper>
    )
  }
}

export default MobileFilterWrapper
