// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import { Box, CircularProgress, Container } from "@material-ui/core"
import GreenTopBanner from "../../../components/src/GreenTopBanner.web"
import GreenBanner from "../../../components/src/GreenBanner.web"
import WebFooter from "../../../components/src/WebFooter.web"
import HyperLinkContentController from "./HyperLinkContentController.web"

export class HyperLinkContent extends HyperLinkContentController {
  render() {
    const { loader, heading, content } = this.state

    return (
      <>
        <GreenTopBanner bannerHeading={heading} pageName={heading} />
        <Container className="policy_wrapper">
          <Box className="policy_container" marginBottom="50px" p={3}>
            {loader ? (
              <Box className="custom_loader_container">
                <CircularProgress size={28} />
              </Box>
            ) : (
              <Box dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withRouter(HyperLinkContent)
// Customizable Area End
