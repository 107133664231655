// Customizable Area Start
import React from "react"
import {
  Grid,
  Box,
  TextareaAutosize,
  Button,
  CircularProgress,
  Typography,
  DialogContent,
} from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import { connect } from "react-redux"
import "../assets/css/showmenuitemmodal.scss"
import ShowMenuItemModalWebController, {
  Props,
} from "./ShowMenuItemModalWebController.web"
import { getCartItems, setCartBadge } from "../assets"
import CustomDialogTitle from "../../../../components/src/CustomDialogTitle.web"
import CustomDialogActions from "../../../../components/src/CustomDialogActions.web"
// Customizable Area End

class ShowMenuItemModalWeb extends ShowMenuItemModalWebController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    // Customizable Area Start
    const { dishName, addons, description, close } = this.props
    const { addToCartLoader, cookingInstruction, totalAmount, quantity } =
      this.state

    return (
      <>
        <CustomDialogTitle onClose={close}>
          <Typography variant="h5" className="menu_item_title">
            {this.capitalizeValue(dishName)}
          </Typography>
        </CustomDialogTitle>
        <DialogContent>
          <Box className="menu_item_modal_wrapper">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography className="menu_item_description">
                  {description}
                </Typography>
              </Grid>

              {Object.keys(addons)?.map((addonCategory: string) => {
                return (
                  <Grid item container xs={12} key={addonCategory}>
                    <Grid item xs={12}>
                      <p>{addonCategory}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <span>You can choose upto 1 options</span>
                    </Grid>
                    {addons[addonCategory]?.addons?.data?.map(
                      (addonList: any) => {
                        const { id, attributes } = addonList
                        const { name, price } = attributes

                        return (
                          <Grid item xs={12} key={id}>
                            <Box className="choose_item_check_wrapper">
                              <p>{name}</p>
                              <div>
                                <p>${price}</p>
                                <Checkbox
                                  color="primary"
                                  checked={this.isExtraThings(
                                    addonCategory,
                                    id
                                  )}
                                  onClick={() =>
                                    this.toggleExtraThings(
                                      addons[addonCategory].id,
                                      addonCategory,
                                      name,
                                      price,
                                      id
                                    )
                                  }
                                />
                              </div>
                            </Box>
                          </Grid>
                        )
                      }
                    )}
                  </Grid>
                )
              })}

              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <p className="instruction_title">
                    Special Cooking Instruction
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className="instruction_description">
                    The restaurant will follow your instructions on the best
                    effort basis. No refunds or cancellation will be processed
                    based on failure to comply with the requests for special
                    instructions.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TextareaAutosize
                      value={cookingInstruction}
                      onChange={(e: any) =>
                        this.setState({ cookingInstruction: e.target.value })
                      }
                      placeholder="Add your note here..."
                      minRows={4}
                      maxRows={4}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <CustomDialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box className="choose_number_of_item">
                <span
                  onClick={this.decreaseQuantity}
                  className="quantity_change_icon"
                >
                  <RemoveIcon />
                </span>
                <p>{quantity}</p>
                <span
                  onClick={this.increaseQuantity}
                  className="quantity_change_icon"
                >
                  <AddIcon />
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Button
                classes={{ root: "add_to_order_btn" }}
                onClick={this.addOrders}
                disabled={addToCartLoader}
              >
                {addToCartLoader ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  `Add to Order - $${totalAmount}`
                )}
              </Button>
            </Grid>
          </Grid>
        </CustomDialogActions>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    cartId: state.user.cartId,
  }
}
// Customizable Area End

export default connect(mapStateToProps, {
  getCartItems,
  setCartBadge,
})(ShowMenuItemModalWeb)
