import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import { AppRoutings } from "../../../components/src/utility/app-routing";

interface IVariantDetails {
  id: string
  type: string
  attributes: {
    is_active: boolean
    option_name: string
    price: string
    weight: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  history: any;
  orderType: "previous" | "current";
  orderList: any[];
  cartId: string;
  showOrderDetails: (orderId: string) => void;
  showOrderTrackingDetails: (trackingURL: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reOrderLoader: boolean;
  reOrderId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderHistorySubCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  reOrderApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      reOrderLoader: false,
      reOrderId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.reOrderApiCallId) {
      this.setState({ reOrderLoader: false, reOrderId: "" });
      if (Array.isArray(responseJson.errors)) {
        responseJson.errors.forEach((err: any) => {
          toast.error(err.message);
        });
      } else {
        toast.error(responseJson.errors || "Something went wrong")
      }
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.reOrderApiCallId) {
          this.setState({ reOrderLoader: false, reOrderId: "" });
          this.props.history.push(AppRoutings.ShoppingCart);
        }
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getPrice = (price: string, variantDetails: IVariantDetails | null) => {
    if(variantDetails) {
      return variantDetails.attributes.price
    }
    return price
  }

  handleOrderAction = (
    orderId: string,
    productId: string,
    quantity: number,
    productType: string,
    addonIds: number[],
    cookingInstruction: string,
    purchaseId: string
  ) => {
    const { orderType } = this.props;

    if (orderType === "current" && !!orderId) {
      this.handleTrackOrder(orderId);
    } else if (orderType === "previous") {
      this.setState({ reOrderId: purchaseId });
      this.handleReOrder(
        productId,
        quantity,
        productType,
        addonIds,
        cookingInstruction
      );
    }
  };

  handleTrackOrder = (orderId: string) => {
    const { showOrderTrackingDetails } = this.props;
    showOrderTrackingDetails(orderId)
  }

  handleReOrder = async (
    cartable_id: string,
    quantity: number,
    cartable_type: string,
    addonIds: number[],
    cookingInstruction: string
  ) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
    const { cartId: cart_id } = this.props;

    if (token) {
      let order: any = {
        cartable_id,
        cartable_type,
        cart_id,
        quantity
      };

      if (cartable_type === configJSON.CARTABLE_TYPE_MENU) {
        order = {
          ...order,
          special_cooking_instruction: cookingInstruction.trim(),
          addons_ids: addonIds
        };
      }
      this.addToCartApi(order);
    }
  };

  addToCartApi = async (orderDetails: any) => {
    this.setState({ reOrderLoader: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const httpBody = { ...orderDetails };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
