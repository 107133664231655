// Customizable Area Start
import { Box, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import React from "react";
import "../../info-page/src/assets/css/receiptcard.scss";
import { expandArrow } from './assets';
import { makeStyles } from '@material-ui/core/styles';
import Image from "material-ui-image";
import LazyLoad from "react-lazy-load";
// Customizable Area End

//Customizable Area Start
const useStyles = makeStyles({
    short: {
        height: '124px',
        overflowX: 'hidden',
        overflowY : 'scroll',
        position: 'relative',
        textAlign:'justify',
        fontFamily : 'Poppins, sans-serif',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px'
        },
        '&::-webkit-scrollbar-thumb' : {
            backgroundColor: '#e4e4e4'
          },
          '&::-webkit-scrollbar-track' : {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: 'transparent'
          }
      },
      content: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
      },
    // short: {
    //     width: '100%',
    //     height: '100%',
    //     overflow: 'hidden',
    //     '& .MuiInputBase-input': {
    //         width: '100%',
    //         height: '100%',
    //         overflow: 'auto',
    //         resize: 'none',
    //         scrollbarWidth: 'none',
    //         '-ms-overflow-style': 'none',
    //         '&::-webkit-scrollbar': {
    //             width: '0px',
    //             background: 'transparent',
    //         },
    //     },
    // },
});
//Customizable Area End

//Customizable Area Start
export function ShortAnsTask({ loader,data, shortAnswer, handleButtonClick, handleModalClose, isModalOpen }: any) {
    const [shortAns, setShortAns] = React.useState('')
    const [words, setWords] = React.useState(0)
    const accountId = localStorage.getItem('cartId')
    return (
        <>
            

            {data.attributes.title_image &&
            <Grid item xs={12} style={{ display: 'block', marginBottom:'20px' }} className="taskImageContainer">
             <LazyLoad offset={100}>  
            <Image
              style={{position:'unset', paddingTop:'0'}}
              src={data?.attributes?.title_image?.url}
              alt="Title Image"
              imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
              animationDuration={1500}
              loading={
                  <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
              }
              />
              </LazyLoad> 
              </Grid>
            
            }
            
            <Grid container>
            {loader && !isModalOpen ? 
                <Box className="task_result_loader">
                    <CircularProgress size={35} />
                </Box> :
            <>
                {data.attributes.task_results.length === 0 ?
                    <>
                        <TaskShortAnswer data={data} shortAns={shortAns} setShortAns={setShortAns} shortAnswer={shortAnswer}
                            handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} 
                            words={words} setWords={setWords} loader={loader}/>
                    </>
                    :
                    <>
                        {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                            <>
                                <TaskShortAnswer data={data} shortAns={shortAns} setShortAns={setShortAns} shortAnswer={shortAnswer}
                                    loader={loader} words={words} setWords={setWords} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                    return (
                                        <>
                                            <TaskShortAnswerWithResults data={data} item={item} handleButtonClick={handleButtonClick}
                                                loader={loader} handleModalClose={handleModalClose} isModalOpen={isModalOpen} shortAnswer={shortAnswer}
                                                shortAns={shortAns} setShortAns={setShortAns} />
                                        </>
                                    )
                                })}
                            </>
                        }
                    </>
                }
            </>
            }    
            </Grid>
        </>
    )
}

const TaskShortAnswer = ({ loader,setWords, words, data, shortAnswer, shortAns, setShortAns, handleButtonClick, handleModalClose, isModalOpen }: any) => {
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const handleInputChange = (event:any) => {
        const value = event.target.value;
        const words = value.trim().split(/\s+/); 
        if (words.length <= 250) { 
          setWords(words.length)
          setShortAns(value);
        }
        if (!value){
            setWords(0)
        }
    };
    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Write Answer</Typography>
                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                    return (
                        <>
                            {JSON.stringify(data.attributes.short_answer) !== JSON.stringify(item.attributes.short_answer) ?
                                <Typography className='select-option' style={{ color: '#d16565', marginRight: '30px' }}>
                                    Wrong Answer
                                </Typography> :
                                <Typography className='select-option' style={{ color: '#147620', marginRight: '30px' }}>
                                    Correct Answer
                                </Typography>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container style={{ height: '160px', textAlign: 'right', boxSizing: 'content-box' }}>
                <Grid style={{ border: '1px solid #979797', width: '100%', height: '100%', padding: '20px', borderRadius: '5px' }}>

                    <TextField placeholder="Write your answer here..."
                        value={shortAns}
                        onChange={handleInputChange}
                        multiline 
                        InputProps={{ disableUnderline: true}}
                        className={classes.short}
                         />
                    <p style={{ marginTop: '0px' }}>{words}/250</p>
                </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', margin: '20px 0', justifyContent: 'space-between' }}>
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className='cancelBtn' onClick={() => { setShortAns('')
                    setWords(0)}}>Cancel</button>
                    <button className='submitBtn' onClick={() => shortAnswer(shortAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                </Grid>
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className='fullBtnContainer'
                        onClick={handleButtonClick}>
                        <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog'>
                        <ShortAnsTaskPopUp loader={loader} words={words} setWords={setWords} data={data} shortAnswer={shortAnswer} setShortAns={setShortAns} shortAns={shortAns} />
                    </Box>
                </Grid>
            </Modal>
        </>
    )
}

const TaskShortAnswerWithResults = ({ loader,data, item, handleButtonClick, handleModalClose, isModalOpen, shortAnswer, shortAns, setShortAns }: any) => {
    const [scrollPosition, setScrollPosition] = React.useState(0);

    const handleWheel = (event:any) => {
        event.preventDefault();
        const delta = event.deltaY;
        const newScrollPosition = scrollPosition + delta;
        setScrollPosition(newScrollPosition);
        event.currentTarget.scrollTop = newScrollPosition;
    };
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const points = JSON.stringify(data?.attributes?.short_answer) === JSON.stringify(item?.attributes?.short_answer)
    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Write Answer</Typography>
                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                    return (
                        <>
                            {(data.attributes.short_answer).trim() !== (item.attributes.short_answer).trim() ?
                                <Typography className='select-option' style={{ color: '#d16565' }}>
                                    Wrong Answer
                                </Typography> :
                                <Typography className='select-option' style={{ color: '#147620' }}>
                                    Correct Answer
                                </Typography>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container style={{ height: '160px', textAlign: 'right', boxSizing: 'content-box' }}>
                <Grid style={{ border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                <Typography
                className={classes.short}
                onWheel={handleWheel}
                >
                <div className={classes.content}>
                    {item.attributes?.short_answer}
                </div>
                </Typography>
                {/* <TextField 
                    defaultValue={item.attributes.short_answer}
                    multiline 
                    InputProps={{ disableUnderline: true}}
                    className={classes.short}
                    style={{height: '135px'}}/> */}
                </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                <Grid className="pointContainer">
                    <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
                </Grid>
                <Grid style={{ margin: '30px 0 20px' }}>
                    <button className='fullBtnContainer' style={{ margin: '0', padding: '16px 22px 16.2px' }} onClick={handleButtonClick}>
                        <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                        <ShortAnsTaskPopUp loader={loader} data={data} shortAnswer={shortAnswer} setShortAns={setShortAns} shortAns={shortAns} />
                    </Box>
                </Grid>
            </Modal>
        </>
    )
}

function ShortAnsTaskPopUp({ loader,data, shortAnswer, setShortAns, shortAns, words, setWords }: any) {
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const handleShortAnsChange = (event:any) => {
        const val = event.target.value;
        const words = val.trim().split(/\s+/); 
        if (words.length <= 250) { 
          setWords(words.length)
          setShortAns(val);
        }
        if (!val){
            setWords(0)
        }
    };

    const handleWheelChange = (event:any) => {
        event.preventDefault();
        const deltaY = event.deltaY;
        const newScrollPosition = scrollPosition + deltaY;
        setScrollPosition(newScrollPosition);
        event.currentTarget.scrollTop = newScrollPosition;
    };
    
    return (
        <>
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                    <Typography className="select-text">Give Short Answer</Typography>
                    <div className='horizontal-line'></div>
                    <Typography className='task-title'>Task Title</Typography>
                    <Box className='task-question'>
                        <Typography variant="body1" component="div" >
                            <div  className="question" dangerouslySetInnerHTML={{ __html:  data.attributes.title }} />
                        </Typography>
                    </Box>
                    {data.attributes.title_image &&
                        <Grid item xs={12} style={{ display: 'block', margin:'20px 0' }} className="taskImageContainer">
                        <LazyLoad offset={100}> 
                        <Image
                        style={{position:'unset', paddingTop:'0'}}
                        src={data?.attributes?.title_image?.url}
                        alt="Title Image"
                        imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
                        animationDuration={500}
                        loading={
                            <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
                        }
                        />
                        </LazyLoad> 
                        </Grid>
            
                    }
                    <Grid container className="ansWrapper">
                        {loader ?
                            <Box className="task_result_loader">
                                <CircularProgress size={35} />
                            </Box> :
                        <>
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='task-option'>Answer</Typography>
                            {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                return (
                                    <>
                                        {JSON.stringify(data.attributes.short_answer) !== JSON.stringify(item.attributes.short_answer) ?
                                            <Typography className='task-option' style={{ color: '#d16565' }}>
                                                Wrong Answer
                                            </Typography> :
                                            <Typography className='task-option' style={{ color: '#147620' }}>
                                                Correct Answer
                                            </Typography>
                                        }
                                    </>
                                )
                            })}
                        </Grid>
                        {data.attributes.task_results.length === 0 ?
                            <>
                                <Grid container style={{ height: '160px', textAlign: 'right', boxSizing: 'content-box' }}>
                                    <Grid className="ansContainer" >

                                        <TextField placeholder="Write your answer here..."
                                            value={shortAns}
                                            onChange={handleShortAnsChange}
                                            multiline 
                                            InputProps={{ disableUnderline: true }}
                                            className={classes.short}/>
                                        <p style={{ marginTop: '0px' }}>{`${words}/250`}</p>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ display: 'flex', margin: '20px 0', justifyContent: 'space-between' }}>
                                    <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                        <button className='cancelBtn' onClick={() => setShortAns('')}>Cancel</button>
                                        <button className='submitBtn' onClick={() => shortAnswer(shortAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                                    <>
                                        <Grid container style={{ height: '160px', textAlign: 'right', boxSizing: 'content-box' }}>
                                            <Grid className="ansContainer" >
                                                <TextField placeholder="Write your answer here..."
                                                    value={shortAns}
                                                    onChange={handleShortAnsChange}
                                                    multiline 
                                                    InputProps={{ disableUnderline: true }}
                                                    className={classes.short}
                                                    />
                                                <p style={{ marginTop: '0px' }}>{`${words}/250`}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ display: 'flex', margin: '20px 0', justifyContent: 'space-between' }}>
                                            <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                                <button className='cancelBtn' onClick={() => setShortAns('')}>Cancel</button>
                                                <button className='submitBtn' onClick={() => shortAnswer(shortAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                            const points = JSON.stringify(data?.attributes?.short_answer) === JSON.stringify(item?.attributes?.short_answer)
                                            return (
                                                <>
                                                    <Grid container style={{ height: '160px', textAlign: 'right', boxSizing: 'content-box' }}>
                                            <Grid className="ansContainer" >
                                            <Typography
                                                className={classes.short}
                                                onWheel={handleWheelChange}
                                                >
                                                <div className={classes.content}>
                                                    {item.attributes?.short_answer}
                                                </div>
                                            </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid className="pointContainer">
                                            <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
                                        </Grid>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                        </>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}

//Customizable Area End