// Customizable Area Start
import React from "react"
import { Redirect, Route } from "react-router-dom"
const configJSON = require("../config.js")

const WebProtectedRoute = ({ ...routeProps }) => {

  const isUserLoggedIn = !!localStorage.getItem(configJSON.AUTH_TOKEN)

  return (
    <>
      {isUserLoggedIn ? (
        <Route {...routeProps} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  )
}

export default WebProtectedRoute
// Customizable Area End
