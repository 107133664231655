// Customizable Area Start
import React from "react"

interface IProps {
  error: string | undefined
  isTouched: boolean | undefined
  extraError?: string
}

const FormikErrorMessage = ({ error, isTouched, extraError }: IProps) => {
  return (
    <span className="error">
      {extraError || (error && isTouched ? error : null)}
    </span>
  )
}

export default FormikErrorMessage
// Customizable Area End
