import {baseURL} from "../../../../framework/src/config"
import StorageProvider from "framework/src/StorageProvider";
const configJSON = require("../../config.js")

export const getFarmProductList = async (payload) => {
    try {
        const {
            id,
            page,
            count,
            filterByCategoryName,
            filterByTagName,
            searchText,
            latitude,
            longitude,
            filter
        } = payload
        let {
            startPrice,
            endPrice,
        } = payload

        if(startPrice === 0 && endPrice === 0) {
            startPrice = ""
            endPrice = ""
        }

      const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
      const response = await fetch(
        `${baseURL}/bx_block_catalogue/farm_catalogues?farm_id=${id}&page=${page}&per=${count}&filter_by_category_name=${
          filterByCategoryName || ""
        }&filter_by_tag_name=${
          filterByTagName || ""
        }&filter_start_price=${startPrice}&filter_end_price=${endPrice}&latitude=${
          latitude || ""
        }&longitude=${
          longitude || ""
        }&search=${searchText}&price_sorting=${filter}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token ?? ""
          },
        }
      )
      return response.json()
    } catch (e) {
      return e
    }
}  

export const getCategories = async (id) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/categories?category_type=farm&category_type_id=${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getTags = async (id) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/tags?tags_type=farm&tag_type_id=${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getProductDetails = async (id) => {
    try {
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const response = await fetch(`${baseURL}/bx_block_catalogue/farm_catalogues/${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token: token || ""
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const addRatingFarm = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/catalogue/reviews`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token || ""
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const addToFavorite = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_favourites/favourites/favourites`,{
            method:'PATCH',
            headers:{
                'Content-Type': 'application/json',
                token:Token || ""
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getFarmsProductMinMaxPrice = async (id) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/min_max_price?type=Farm&id=${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

