import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
export const configJSON = require("./config");

interface IAddress {
  first_name: string;
  last_name: string;
  company_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  phone_number: string;
  address_type: string;
}

interface IError {
  message: string;
}
// Customizable Area End

export interface Props {
  navigation?: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  data: any;
  userInfo: any;
  apiErrors: IError[];
  close: () => void;
  setEditAddressErrors: (errors: IError[]) => void;
  updateAddressAction: (
    payload: { address: IAddress },
    id: string,
    close: () => void
  ) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditAddressWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editAddressRef: any = React.createRef();
  errorElementRef: any = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {};
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log("here", responseJson);
    }
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { apiErrors } = this.props;
    const { apiErrors: prevApiErrors } = prevProps;

    const hasNewErrors = apiErrors.length > prevApiErrors.length;

    if (hasNewErrors && this.errorElementRef.current) {
      this.errorElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  async componentWillUnmount(): Promise<void> {
    this.props.setEditAddressErrors([]);
  }

  async editAddress(values: IAddress): Promise<boolean> {
    const {
      first_name,
      last_name,
      company_name,
      address,
      address2,
      city,
      state,
      country,
      postal_code,
      phone_number
    } = values

    const httpBody = {
      address: {
        first_name,
        last_name,
        company_name,
        address,
        address2,
        city,
        state,
        country,
        postal_code,
        phone_number: `+1${phone_number}`,
        address_type: "home"
      }
    };

    this.props.updateAddressAction(httpBody, this.props.id, this.props.close);
    return true;
  }

  handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    const { apiErrors, setEditAddressErrors } = this.props;
    handleChange(e);
    if (apiErrors.length > 0) {
      setEditAddressErrors([]);
    }
  };

  handleFormSubmit = () => {
    if (this.editAddressRef.current) {
      this.editAddressRef.current.handleSubmit();
    }
  };

  getPhoneNumberWithoutPrefix = (phoneNumber: string) => {
    const withoutPrefix = phoneNumber.replace(/\+1/g, "");
    return withoutPrefix;
  };

  handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    const { value } = e.target;
    const { apiErrors, setEditAddressErrors } = this.props;

    if (value.length < 11 && !isNaN(Number(value))) {
      handleChange(e);
    }
    if (apiErrors.length > 0) {
      setEditAddressErrors([]);
    }
  };

  handlePostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    const { value } = e.target;
    const { apiErrors, setEditAddressErrors } = this.props;

    if (value.length < 6 && !isNaN(Number(value))) {
      handleChange(e);
    }
    if (apiErrors.length > 0) {
      setEditAddressErrors([]);
    }
  };
}
