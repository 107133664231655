// Customizable Area Start
import React, { useState, useEffect } from "react";
// custom components
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import "../../../web/src/assets/css/content/button.scss";
import "../../../web/src/assets/css/content/tab.scss";
import AdminRestaurantAddDetailsWeb from "./AdminRestaurantAddDetails.web";
import AdminRestaurantRegisterInfoWeb from "./AdminRestaurantRegisterInfo.web";
import AdminLearningCourseDetailWeb from "./AdminLearningCourseDetail.web";
import AdminLearningCourseContentWeb from "./AdminLearningCourseContent.web";
import { withRouter, useLocation } from "react-router-dom";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box sx={{ p: 0 }}>
        <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomLearningCreateCourseTab = (props: any) => {
  const [value, setValue] = useState(0);

  const getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return query;
  };
  const isDisabled = () => {
    let query = getQuery();
    const id = query.get("course_id");
    return id;
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    let query = getQuery();
    let lessonId=query.get("lesson_id") || ""
    query.get("mode") == "edit" &&
      props.history.push(
        `/admin/learning/add-course?mode=edit&course_id=${query.get(
          "course_id"
        )}&contributer_id=${query.get("contributer_id")}&lesson_id=${lessonId}&tab=${newValue}`
      );
  };

  useEffect(() => {
    let query = getQuery();
    let tab = query.get("tab");
    //@ts-ignore
    handleChange("event", parseInt(tab));
  }, []);
  return (
    <div className="customTab learningCourseDetailTab">
      {/* <div className="customTab restDetailTab"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#20a258",
            bottom: "2px",
            height: "5px",
          },
        }}
      >
        <Tab label=" Course Details " {...a11yProps(0)} />
        <Tab
          label="Course Content"
          {...a11yProps(1)}
          disabled={!isDisabled()}
        />
      </Tabs>
      <TabPanel value={value} index={0} className="tabPanel">
        <AdminLearningCourseDetailWeb
        data-testid="courseDetail"
          //@ts-ignore
          rightSidevalues={props.rightSidevalues}
          handleTabChange={handleChange}
        />
      </TabPanel>

      <TabPanel value={value} index={1} className="tabPanel">
        <AdminLearningCourseContentWeb
        data-testid="courseContent"
          //@ts-ignore
          rightSidevalues={props.rightSidevalues}
          tabValue={value}
        />
      </TabPanel>
    </div>
  );
};
export {CustomLearningCreateCourseTab}
export default withRouter(CustomLearningCreateCourseTab);
// Customizable Area End