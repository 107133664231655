// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/forgotPassword.scss";
import { Logo } from "../src/assets";
import OnlineResetPasswordController, {
  Props
} from "./OnlineResetPasswordController.web";
import {
  Box,
  Grid,
  Typography,
  FilledInput,
  Button,
  IconButton
} from "@material-ui/core";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Formik } from "formik";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// Customizable Area End
class OnlineResetPassword extends OnlineResetPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { backToParent } = this.props;
    const {
      isLoading,
      sentResetPWEmail,
      resetPWError,
      resetPWSchema
    } = this.state;

    return (
      <Box>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={resetPWSchema}
          onSubmit={values => this.doResetPassword(values)}
        >
          {({ handleChange, handleSubmit, errors, touched }) => {
            return (
              <Grid container>
                <Grid item xs={12} style={{ padding: "0px" }}>
                  <Box className="layout_wrapper">
                    <Box className="logo_wrapper forgot_password_header">
                      <IconButton onClick={backToParent}>
                        <ArrowBackIcon />
                      </IconButton>
                      <img src={Logo} alt="logo" width="200px" />
                    </Box>
                    <Box className="fp_content_wrapper">
                      <Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className="forgot_PW_tab_container">
                              <Typography
                                variant="subtitle1"
                                className="forgot_PW_tab"
                              >
                                Reset Password
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography className="reset_pwd_text">
                                We will send you password reset link to your
                                email address
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box
                                style={{ padding: "20px 0px" }}
                                className="web_input_field"
                              >
                                <FilledInput
                                  placeholder="Email"
                                  startAdornment={
                                    <InputAdornment position="end">
                                      <DraftsOutlinedIcon className="login_input_icons" />
                                    </InputAdornment>
                                  }
                                  name="email"
                                  classes={{
                                    input: "Login_Input",
                                    root: "Login_Input_Field",
                                    focused: "Login_Input_Field_Focus"
                                  }}
                                  onChange={handleChange}
                                  onFocus={() => {
                                    this.setState({
                                      sentResetPWEmail: false,
                                      resetPWError: []
                                    });
                                  }}
                                />
                              </Box>

                              <span className="error">
                                {touched.email && errors.email
                                  ? errors.email
                                  : null}
                              </span>
                            </Grid>

                            <Grid item xs={12}>
                              <Box style={{ marginBottom: 20 }}>
                                {sentResetPWEmail && (
                                  <Alert severity="success">
                                    Your password reset email was sent successfully.
                                  </Alert>
                                )}

                                {resetPWError.length > 0 &&
                                  resetPWError.map((err: any) => {
                                    return (
                                      <Alert key={err.message} severity="error">
                                        {err.message}
                                      </Alert>
                                    );
                                  })}
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Button
                                className="login_signup_btn"
                                classes={{
                                  label: "normal_login_btn"
                                }}
                                onClick={() => handleSubmit()}
                              >
                                {isLoading ? (
                                  <CircularProgress
                                    size={25}
                                    className="login_signup_loader"
                                  />
                                ) : (
                                  "Send Mail"
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </Box>
    );
    // Customizable Area End
  }
}

export default OnlineResetPassword;
