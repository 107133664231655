import React, { useState } from "react";
import { Apple_Icon } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";
// custom components
import CustomSelectField from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";
import CustomChips from "../../../components/src/chip";
import { withRouter } from "react-router-dom";
// components
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";

//images
import { FilterIcon } from "./assets";

// icons
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search"
import AdminCustomerListController, {
  Props,
} from "./AdminCustomerListController.web";



interface EnhancedTableProps {

  numSelected?: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const [cheked, setChecked] = useState<boolean>(false)
  const { onSelectAllClick, numSelected, rowCount } = props;
  console.log("table head", numSelected, rowCount)

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
              checked={rowCount > 0 && numSelected === rowCount}
              // checked={cheked}
              onChange={onSelectAllClick}
              id={34}

            />
            <span>customer name</span>
          </div>
        </TableCell>
        <TableCell align="center">orders</TableCell>
        <TableCell align="center">#id</TableCell>
        <TableCell align="center">purchase amount</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const rows = [
  {
    id: 0,
    customerName: "Jhon Deo",
    orders: "40",
    customerId: 123,
    purchaseAmt: "$34,349.000",


  },
  {
    id: 1,
    customerName: "Jhon Abrom",
    orders: "40",
    customerId: 124,
    purchaseAmt: "$34,349.000",

  }


]


class CustomerList extends AdminCustomerListController {
  constructor(props: Props) {
    super(props);
  }


  handleSelectAllClick = (event: any) => {

    console.log("changes")
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.customerId);
      this.setState({ selected: newSelected });
      console.log("true")

      return;
    } else {
      console.log("false")
      this.setState({ selected: [] })
    }
  };
  render() {
    return (
      <>
        {/* main card container */}
        <div className="tableContainer">
          {/* filter section */}
          <div className="headerListContainer">
            <div className="prodListHeader">
              <div className="blocks">
                <h4>Customer List</h4>
                <div className="block1">
                  <span className="text">Show: </span>
                  <CustomSelectField label="All Customers" />
                </div>
              </div>
              <div className="blocks">
                <div className="block1">
                  <span className="text">Sort by: </span>
                  <CustomSelectField label="Default" />
                </div>
                <div className="block1">
                  <a href="#"><img src={FilterIcon} className="FilterIcon" alt="Filter Icon" /></a>
                  <div className="addButton">
                    <CustomButton label="New Product" />
                  </div>
                  {/* <CustomButton variant="contained" className="btn btn-primary">Contained</Button> */}
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield placeholder="Search customer... " InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="searchIcon" />
                    </InputAdornment>
                  ),
                }} />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <CustomSelectField label="Action" />
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block custListTable">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>

                <EnhancedTableHead
                  numSelected={this.state.selected.length}
                  onSelectAllClick={this.handleSelectAllClick}
                  rowCount={rows.length}
                />
                <TableBody>



                  {rows.map((item) => {
                    const isItemSelected = this.isSelected(item.customerId);

                    return (

                      <TableRow key={item.id} onClick={(event) => this.handleClick(event, item.customerId)}  >
                        <TableCell className="BoldCell " >
                          <div className="checkboxnameCell">
                            <CustomCheckboxField id={item.id} checked={isItemSelected} />
                            <span>{item.customerName}</span>
                          </div>
                        </TableCell>
                        <TableCell align="center">{item.orders}</TableCell>
                        <TableCell align="center" className="BoldCell">{item.customerId}</TableCell>
                        <TableCell align="center">{item.purchaseAmt}</TableCell>

                        <TableCell align="right" className="BoldCell">
                          <div className="option-dropDown">

                            <BasicMenu />
                          </div>
                        </TableCell>
                      </TableRow>)
                  })}


                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    );
  }
}

// Customizable Area Start
//@ts-ignorei
export default withRouter(CustomerList);
// Customizable Area End
