// Customizable Area Start
import React from "react"
import { connect } from "react-redux"
import CheckoutPaymentController, {
  Props,
} from "./CheckoutPaymentController.web"
import "../../../../../components/src/assets/css/webHeader.scss"
import "../../assets/css/shoppingcartcheckout.scss"
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import {
  setOrderPaymentType,
  setCheckoutOrder,
  getCartItems,
} from "../../assets"
import CardListing from "../checkOutCCard/CardListing.web"
import PaymentDoneDialog from "../checkoutBitcoin/PaymentDoneDialog.web"

class CheckoutPayment extends CheckoutPaymentController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      type,
      selectedCard,
      enteredPoint,
      isLoyaltyPoint,
      toggleIsLoyaltyPoint,
    } = this.props
    const {
      transactionId,
      isPaymentDoneDialog,
      loyaltyPointBalance,
      applicablePoints,
      loading,
    } = this.state

    return (
      <Grid item xs={12}>
        <Box
          mb={5}
          className="check_out_delivery_cards_container left_container"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 className="check_out_heading">Payment Option</h2>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={<Checkbox checked disabled />}
                  label="Credit or debit card"
                  classes={{
                    root: "payment_option_checkbox",
                    label: "radio_label",
                  }}
                />
                <CardListing
                  selectedCardId={selectedCard}
                  onSelectedCardChange={this.handleChangeCard}
                  handlePaymentOnUnSaveCard={this.handleCardPaymentApi}
                />

                <FormControlLabel
                  control={<Checkbox checked={isLoyaltyPoint} />}
                  label={
                    <div className="reward_payment_header">
                      <span className="reward_heading">Loyalty Points</span>
                      <span className="reward_price">
                        ${loyaltyPointBalance}
                      </span>
                    </div>
                  }
                  onChange={toggleIsLoyaltyPoint}
                  classes={{
                    root: "payment_option_checkbox",
                    label: "radio_label reward_radio_label",
                  }}
                />
              </FormControl>
              {isLoyaltyPoint && (
                <Box className="loyalty_point_wrapper">
                  <Typography variant="h6">
                    Enter the number of points
                  </Typography>
                  <TextField
                    autoFocus
                    variant="filled"
                    value={enteredPoint}
                    onChange={this.handlePointValueChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {loading ? (
                    <Box className="applicable_points_loader_wrapper">
                      <Skeleton height="12px" borderRadius="8px" />
                    </Box>
                  ) : (
                    <>
                      {applicablePoints !== null ? (
                        <Typography className="applicable_point_message">
                          You can apply upto {applicablePoints} points on this
                          purchase
                        </Typography>
                      ) : null}
                    </>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>

        <PaymentDoneDialog
          isOpen={isPaymentDoneDialog}
          isRestaurantOrder={type === "restaurant"}
          transactionId={transactionId}
          onAccept={this.onPaymentDoneAccept}
        />
      </Grid>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    checkoutOrder: state.user.checkoutOrder,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      setOrderPaymentType,
      setCheckoutOrder,
      getCartItems,
    }
  )(CheckoutPayment)
)
// Customizable Area End
