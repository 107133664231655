import React from "react";
import WebHeaderController from "./WebHeaderController.web";
import { Grid, Box, Drawer } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import "./assets/css/webrouting.scss";
import {connect} from "react-redux";
import {
  getUserDetails,
  setLoginModalFalse,
  setLoginModalTrue,
  updateActiveTab,
  toggleSidebar,
  resetUserDetails,
  getFarmCategoriesAndTagsAction,
  setCartBadge,
  getCartItems,
} from "./assets";
import { ActiveTabEnum } from '../src/redux/Users/userEnums'
import { AppRoutings } from "./utility/app-routing"

const Tabs = [{
  tabName: 'Home',
  route: AppRoutings.Home,
  activeEnum: ActiveTabEnum.Home
},
{
  tabName: 'Farmers Market',
  route: AppRoutings.FarmsMarket,
  activeEnum: ActiveTabEnum.Market
},
{
  tabName: 'Restaurants',
  route: AppRoutings.RestaurantLandingPage,
  activeEnum: ActiveTabEnum.Restaurant
},
{
  tabName: 'Learning',
  route: AppRoutings.Learning,
  activeEnum: ActiveTabEnum.Learning
},
{
  tabName: 'Community',
  route: AppRoutings.Community,
  activeEnum: ActiveTabEnum.Community
},
{
  tabName: 'About Us',
  route: AppRoutings.AboutUs,
  activeEnum: ActiveTabEnum.AboutUs
}]

class WebNewRoutingTabs extends WebHeaderController {
  render() {

    return (
      <>
        <Box className="routesWrapper">
          <Grid
            container
            className="web_routing_tabs_container"
            style={{ display: this.props.displayProp }}
          >
            {Tabs.map((tab) => {
              const { tabName, route, activeEnum } = tab

              return (
                <Grid key={tabName} item xs={12} sm={3} md={2} className="web_routing_tab_wrapper">
                  <NavLink
                    exact
                    to={route}
                    className={this.isActive(activeEnum)}
                  >
                    {tabName}
                  </NavLink>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Drawer
          anchor="left"
          open={this.props.isSidebarOpen}
          onClose={this.toggleDrawer}
        >
          <Box className="web_routing_tabs_sidebar">
            <Grid container>
              {Tabs.map((tab) => {
                const { tabName, route, activeEnum } = tab

                return (
                  <Grid key={tabName} item xs={12}>
                    <NavLink
                      exact
                      to={route}
                      className={this.isActive(activeEnum)}
                    >
                      {tabName}
                    </NavLink>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Drawer>
      </>
    );
  }
}



const mapStateToProps = (state:any) => {
  return { 
    userInfo: state.user,
    isSidebarOpen: state.user.isSidebarOpen,
    cartBadge: state.user.cartBadge
   };
};

export default withRouter(
  connect(mapStateToProps, {
    getUserDetails,
    setLoginModalFalse,
    setLoginModalTrue,
    updateActiveTab,
    toggleSidebar,
    resetUserDetails,
    getFarmCategoriesAndTagsAction,
    setCartBadge,
    getCartItems
  })(WebNewRoutingTabs)
);

    