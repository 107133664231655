// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import React from "react"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
export const configJSON = require("../../config.js")

export interface ICommentReply {
    id: string
    type: string
    attributes: {
        account_id: number
        date: string
        description: string
        review_id: number
        full_name: string
        image: {
            id: number
            filename: string
            url: string
        }
        report_spam: boolean
    }
}

export interface IComment {
    id: string
    type: string
    attributes: {
        account_id: number
        comment: string
        full_name: string
        date: string
        image: {
            id: number
            filename: string
            url: string
        }
        report_spam: boolean
        replies: {
            data: ICommentReply[]
        }
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    addCommentLoader: boolean
    commentText: string
    discussionDetails: {
        id: string
        type: string
        attributes: {
            discussion_heading: string
            description: string
            forum_comments: {
                data: IComment[]
            }
        }
    }
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityDiscussionDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDiscussionDetailsApiCallId: string = ""
    addDiscussionCommentApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            addCommentLoader: false,
            commentText: "",
            discussionDetails: {
                id: "",
                type: "",
                attributes: {
                    discussion_heading: "",
                    description: "",
                    forum_comments: {
                        data: []
                    }
                }
            }
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { history, match: { params } }: any = this.props
        const id = params?.id
        if (id) {
            this.getDiscussionDetailsApi(id)
            return
        }
        toast.error("Something went wrong")
        history.goBack()
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        const { match: { params } }: any = this.props
        const id = params?.id
        const { match: { params: prevParams } }: any = prevProps

        if (prevParams?.id !== id) {
            this.getDiscussionDetailsApi(id)
        }
    }

    handleCommentTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ commentText: e.target.value })
    }

    handleAddNewReview = (reviewId: string, replyDetails: ICommentReply) => {
        const { discussionDetails: { attributes: { forum_comments } } } = this.state
        const updatedComments = forum_comments.data.map((comment: IComment) => {
            const { id, attributes } = comment
            const { replies } = attributes

            if (id === reviewId) {
                return {
                    ...comment,
                    attributes: {
                        ...attributes,
                        replies: {
                            data: [...replies.data, replyDetails]
                        }
                    }
                }
            }
            return comment
        })
        this.setUpdatedComments(updatedComments)
    }

    handleRemoveComment = (reviewId: string) => {
        const { discussionDetails: { attributes: { forum_comments } } } = this.state
        const updatedComments = forum_comments.data.filter((comment: IComment) => comment.id !== reviewId)
        this.setUpdatedComments(updatedComments)
    }

    handleUpdateComment = (reviewId: string, updatedComment: string) => {
        const { discussionDetails: { attributes: { forum_comments } } } = this.state
        const updatedComments = forum_comments.data.map((comment: IComment) => {
            const { id, attributes } = comment

            if (id === reviewId) {
                return {
                    ...comment,
                    attributes: {
                        ...attributes,
                        comment: updatedComment
                    }
                }
            }
            return comment
        })
        this.setUpdatedComments(updatedComments)
    }

    handleEditReply = (reviewId: string, replyId: string, newReview: string) => {
        const { discussionDetails: { attributes: { forum_comments } } } = this.state

        const updatedComments = forum_comments.data.map((comment: IComment) => {
            const { id } = comment

            if(id === reviewId) {
                const { attributes } = comment
                const { replies: { data } } = attributes

                const newReplies = data.map((reply: ICommentReply) => {
                    const { id, type, attributes } = reply
                    if(id === replyId) {
                        return {
                            id,
                            type,
                            attributes: {
                              ...attributes,
                              description: newReview
                            }
                        }
                    }
                    return reply
                })

                return {
                    ...comment,
                    attributes: {
                        ...attributes,
                        replies:{
                            data: newReplies
                        }
                    }
                }
            }
            return comment
        })
        this.setUpdatedComments(updatedComments)
    }

    removeReply = (reviewId: string, replyId: string) => {
        const { discussionDetails: { attributes: { forum_comments } } } = this.state

        const updatedComments = forum_comments.data.map((comment: IComment) => {
            const { id, attributes } = comment
            if(id === reviewId) {
                const { attributes: { replies: { data } } } = comment
                const newReplies = data.filter((reply: ICommentReply) => reply.id !== replyId)

                return {
                    ...comment,
                    attributes: {
                        ...attributes,
                        replies: {
                            data: newReplies
                        }
                    }
                }
            }
            return comment
        })
        this.setUpdatedComments(updatedComments)
    }

    setUpdatedComments = (updatedComments: IComment[]) => {
        const { discussionDetails } = this.state
        const { attributes } = discussionDetails

        this.setState({
            discussionDetails: {
                ...discussionDetails,
                attributes: {
                    ...attributes,
                    forum_comments: {
                        data: updatedComments
                    }
                },
            },
        })
    }

    getDiscussionDetailsApi = async (id: string) => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getDiscussionDetailsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.communityDiscussionsApiEndPoint}/${id}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    addDiscussionCommentApi = async () => {
        this.setState({ addCommentLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const { match: { params } }: any = this.props
        const { commentText } = this.state
        const httpBody = {
            description: commentText,
            community_discussion_id: params.id
        }

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.addDiscussionCommentApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addDiscussionCommentApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDiscussionDetailsApiCallId) {
            if (!!responseJson?.data) {
                this.setState({ loader: false, discussionDetails: responseJson.data })
                return
            }
            toast.error("Something went wrong")
            this.props.history.goBack()
        }

        if (apiRequestCallId === this.addDiscussionCommentApiCallId) {
            const { discussionDetails: { attributes: { forum_comments: { data } }}} = this.state
            const updatedComments = [...data, responseJson.data]

            this.setState({
                addCommentLoader: false,
                commentText: ""
            })
            this.setUpdatedComments(updatedComments)
            toast.success("Comment added successfully")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(await isTokenExpired(responseJson)) return

                if (apiRequestCallId === this.getDiscussionDetailsApiCallId) {
                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }

                if (apiRequestCallId === this.addDiscussionCommentApiCallId) {
                    this.setState({ addCommentLoader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}

