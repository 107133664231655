// Customizable Area Start
import { CommunityEnum } from "./communityEnum"

export const setCommunitySearchDialog = (isOpen) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_SEARCH_DIALOG,
        payload: isOpen
    })
}

export const setCommunityHomeDesc = (payload) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_HOME_DESC,
        payload: payload
    })
}

export const setCommunityLatestForums = (forums) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_LATEST_FORUMS,
        payload: forums
    })
}

export const setDiscussionFilter = (filter) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_DISCUSSION_FILTER,
        payload: filter
    })
}

export const setDiscussionTopics = (topics) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_DISCUSSION_TOPICS,
        payload: topics
    })
}

export const setDiscussionActiveTopic = (topicId) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_ACTIVE_TOPIC,
        payload: topicId
    })
}

export const setCommunityDiscussions = (discussions) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_DISCUSSIONS,
        payload: discussions
    })
}

export const setCommunityTagFilterDiscussions = (discussions) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_TAG_FILTER_DISCUSSIONS,
        payload: discussions
    })
}

export const setCommunityExpertsList = (expertsList) => async (dispatch) => {
    dispatch({
        type: CommunityEnum.SET_COMMUNITY_EXPERTS_LIST,
        payload: expertsList
    })
}
// Customizable Area End
