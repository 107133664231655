// Customizable Area Start
import React, { useEffect, useState } from "react"
import { Box, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import BubbleChatIcon from "../CommunityCustomComponents/BubbleChatIcon.web"
import StartIcon from "../CommunityCustomComponents/StarIcon.web"
import TagIcon from "../CommunityCustomComponents/TagIcon.web"
import { useDispatch, useSelector } from "react-redux"
import { setDiscussionFilter, setDiscussionActiveTopic } from "../../assets"
import clsx from "clsx"
import NewDiscussionDialog from "../CommunityCustomComponents/NewDiscussionDialog.web"
import { CommunityFilterEnum } from "../../../../../components/src/redux/Community/communityEnum"

const useStyles = makeStyles(() => ({
    mainContainer: {
        width: "100%"
    },
    newDiscussionBtn: {
        padding: "12px 34px",
        borderRadius: "8px",
        backgroundImage: "linear-gradient(104deg, #2dae43 -27%, #147620 151%)",
        textTransform: "none",
        width: "100%",
        "& span": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: "18px",
            fontWeight: 500,
            color: "#fff",
        },
        "@media(max-width: 400px)": {
            padding: "10px 25px",
            "& span": {
                fontSize: "16px"
            }
        },
    },
    filtersContainer: {
        backgroundColor: "#fff",
        position: "relative",
        borderRadius: "8px",
        boxShadow: "3px 3px 5px 0 rgba(29, 139, 45, 0.09)",
        marginTop: "25px"
    },
    indicatorSlide: {
        width: "5px",
        backgroundColor: "#f6faf7",
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        borderRadius: "5px"
    },
    btnContainer: {
        display: "flex",
        flexDirection: "column",
    },
    btnWrapper: {
        padding: "10px 0px",
        zIndex: 1,
        display: "flex",
        gap: "10px",
        border: "solid 2px #f9f8fd",
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        },
        "@media(max-width: 400px)": {
            padding: "8px 0px"
        },
    },
    indicator: {
        width: "5px",
        borderRadius: "5px"
    },
    activeBtnIndicator: {
        backgroundColor: "#35a042  ",
        transition: 'all 0.7s ease-in'
    },
    filterWrapper: {
        textTransform: "none",
        "&:hover": {
            backgroundColor: "transparent"
        },
        "& div": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "25px"
        },
        "& p": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: "16px",
            fontWeight: 500,
            opacity: 0.5,
            color: "#000",
        },
        "@media(max-width: 400px)": {
            "& p": {
                fontSize: "14px"
            },
            "& svg": {
                height: "25px",
                width: "25px"
            }
        },
    },
    activeFilterWrapper: {
        "& p": {
            opacity: 1,
        },
    },
}))

const CommunityDiscussionSidebar = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { discussionFilter, discussionTopics } = useSelector((state: any) => state.community)
    const [isNewDiscussionDialog, setIsNewDiscussionDialog] = useState(false)

    const isActive = (filter: string) => {
        return discussionFilter === filter
    }

    const handleFilterChange = (filter: CommunityFilterEnum) => {
        dispatch(setDiscussionFilter(filter))
    }

    const handleDialogOpen = () => {
        setIsNewDiscussionDialog(true)
    }

    const handleDialogClose = () => {
        setIsNewDiscussionDialog(false)
    }

    useEffect(() => {
        return () => handleFilterChange(CommunityFilterEnum.ALL)
    }, [])

    return (
        <>
            <Box className={classes.mainContainer}>
                <Button className={classes.newDiscussionBtn} onClick={handleDialogOpen}>Start a Discussion</Button>
                <Box className={classes.filtersContainer}>
                    <Box className={classes.indicatorSlide} />
                    <Box className={classes.btnContainer}>
                        <Box className={classes.btnWrapper} onClick={() => handleFilterChange(CommunityFilterEnum.ALL)}>
                            <Box
                                className={clsx(classes.indicator, {
                                    [classes.activeBtnIndicator]: isActive(CommunityFilterEnum.ALL)
                                })}
                            />
                            <Button
                                className={clsx(classes.filterWrapper, {
                                    [classes.activeFilterWrapper]: isActive(CommunityFilterEnum.ALL),
                                })}
                            >
                                <Box>
                                    <BubbleChatIcon fill={isActive(CommunityFilterEnum.ALL) ? "#000000" : "#00000080"} />
                                    <Typography>All Discussion</Typography>
                                </Box>
                            </Button>
                        </Box>
                        <Box className={classes.btnWrapper} onClick={() => handleFilterChange(CommunityFilterEnum.FOLLOWING)}>
                            <Box
                                className={clsx(classes.indicator, {
                                    [classes.activeBtnIndicator]: isActive(CommunityFilterEnum.FOLLOWING),
                                })}
                            />
                            <Button
                                className={clsx(classes.filterWrapper, {
                                    [classes.activeFilterWrapper]: isActive(CommunityFilterEnum.FOLLOWING),
                                })}
                            >
                                <Box>
                                    <StartIcon fill={isActive(CommunityFilterEnum.FOLLOWING) ? "#000000" : "#00000080"} />
                                    <Typography>Following</Typography>
                                </Box>
                            </Button>
                        </Box>
                        <Box className={classes.btnWrapper} onClick={() => handleFilterChange(CommunityFilterEnum.TAGS)}>
                            <Box
                                className={clsx(classes.indicator, {
                                    [classes.activeBtnIndicator]: isActive(CommunityFilterEnum.TAGS),
                                })}
                            />
                            <Button
                                className={clsx(classes.filterWrapper, {
                                    [classes.activeFilterWrapper]: isActive(CommunityFilterEnum.TAGS),
                                })}
                            >
                                <Box>
                                    <TagIcon fill={isActive(CommunityFilterEnum.TAGS) ? "#000000" : "#00000080"} />
                                    <Typography>Tags</Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {isNewDiscussionDialog && <NewDiscussionDialog
                isOpen={isNewDiscussionDialog}
                handleClose={handleDialogClose}
            />}
        </>
    )
}

export default CommunityDiscussionSidebar
// Customizable Area End
