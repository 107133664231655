import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config.js");
import * as Yup from "yup";
import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  history: any
  children?: any
  close: () => void
  getCartItems: () => Promise<void>
  getUserDetails: (userDetails: any) => void
  setUserCartId: (id: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string
  email: string
  openSnackBar: boolean
  loginFailedError: string
  loginSuccess: string
  loginSchema: any
  formType: any
  isLoading: boolean
  // Customizable Area End 
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  getRolesApiCallId: string = "";
  bulkAddToCartApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    
    // Customizable Area Start
   
    //email schema
    const LoginSchema = Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Invalid email address format")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
    })

    this.state = {
      email: "",
      password: "",
      openSnackBar: false,
      loginFailedError: "",
      loginSuccess: "",
      formType: "",
      isLoading: false,
      loginSchema: LoginSchema,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0||str===undefined||str==="";
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage));

      if (responseJson) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson.meta && responseJson.meta.token) {
            this.handleLoginSuccessResponse(responseJson)
          } else {
            this.handleLoginFailResponse(responseJson)
          }
          this.parseApiCatchErrorResponse(errorResponse);
        }

        if (apiRequestCallId === this.bulkAddToCartApiCallId) {
          await StorageProvider.remove(configJSON.ORDERS_STORAGE_KEY)
          await this.props.getCartItems()
          this.props.close()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleLoginSuccessResponse = async (responseJson: any) => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    this.saveLoggedInUserData(responseJson);
    this.sendLoginSuccessMessage();
    this.openInfoPage();

    const msg: Message = new Message(
      getName(MessageEnum.AccoutLoginSuccess)
    );

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    if (responseJson.meta) {
      const {
        formType
      } = this.state

      if (!formType) {
        const { close, getUserDetails, setUserCartId, getCartItems } = this.props
        const { account, cart, token } = responseJson.meta
        const { is_admin, first_name, last_name } = account

        await StorageProvider.set(configJSON.ADMIN_AUTH_KEY, is_admin)
        await StorageProvider.set(configJSON.TOKEN, token)
        await StorageProvider.set(configJSON.FIRST_NAME_KEY, first_name)
        await StorageProvider.set(configJSON.LAST_NAME_KEY, last_name)
        await StorageProvider.set(configJSON.AUTH_TOKEN, token)
        localStorage.setItem(configJSON.AUTH_TOKEN, token)
        localStorage.setItem(configJSON.PROFILE_KEY, JSON.stringify(account))
        localStorage.setItem(configJSON.CART_ID_KEY, JSON.stringify(cart.id))

        getUserDetails(account)
        setUserCartId(cart.id)
        const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
        const orderLength = JSON.parse(orders).length

        orderLength > 0 ? await this.uploadLocalCartItems(responseJson.meta.token) : await getCartItems()
        this.setState({ loginSuccess: "Logged in successfully" })
        if (orderLength <= 0) {
          close()
        }
      }
    }
    this.send(msg)
  }

  handleLoginFailResponse = (responseJson: any) => {
    this.setState({ isLoading: false })
    const errorMessage = Object.values(responseJson.errors[0])[0]

    if (errorMessage === "Login Failed") {
      this.setState({ loginFailedError: "Invalid credentials, Login failed!" })


    } else if (errorMessage === "Account not found, or not activated") {
      this.setState({ loginFailedError: "Account not found, or not activated" })
    } else {
      this.setState({ loginFailedError: "" })
    }

    this.parseApiErrorResponse(responseJson.errors);
    this.sendLoginFailMessage();
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));
    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
   
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    this.send(msg);    
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);    
  }

  doEmailLogIn = async (values: { email: string, password: string }): Promise<boolean> => {
    this.setState({
      loginFailedError: "",
      isLoading: true,
    })

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email.trim() ? values.email.trim() : "",
      password: values.password ? values.password : "",
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  uploadLocalCartItems = async (token: string) => {
    const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJSON = JSON.parse(orders)

    if(ordersJSON.length > 0) {
      const orderType = ordersJSON[0].orderCategory
      const apiOrders = ordersJSON.map((order: any) => {
        const { cartableId, quantity, selectedVariantId } = order
        if(order.orderCategory === configJSON.CARTABLE_TYPE_FARM) {
          return {
            cartable_id: cartableId,
            selected_variant_option_id: selectedVariantId,
            quantity,
          }
        } else {
          const { cookingInstruction, extraThings } = order
          const addOnsIds = extraThings.map((addon: any) => addon.id)
          return {
            cartable_id: cartableId,
            addon_ids: addOnsIds,
            quantity,
            special_cooking_instruction: cookingInstruction,
          }
        }
      })
      await this.bulkAddToCartApi({
        cartable_type: orderType,
        cartable_params: apiOrders
      }, token)
    }
  }

  bulkAddToCartApi = async (orders: any, token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.bulkAddToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkAddToCartApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(orders)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }
  // Customizable Area End
}