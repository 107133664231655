import React from "react";
import "../../../web/src/assets/css/style.scss";
import EditAddressWebController from "./EditAddressWebController.web";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  FilledInput,
  DialogContent,
  Dialog,
  CircularProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "../assets/css/addAddress.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { updateAddressAction, setEditAddressErrors } from "./assets";
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web";
import CustomDialogActions from "../../../components/src/CustomDialogActions.web";
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web";

const AddressSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_name: Yup.string(),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  postal_code: Yup.string()
    .required("Postal code is required")
    .length(5)
    .matches(/^\d{5}(?:-\d{4})?$/, "Please enter valid pin code"),
  phone_number: Yup.string().required("Phone number is required")
});

interface IAddress {
  first_name: string;
  last_name: string;
  company_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  phone_number: string;
}

export class EditAddress extends EditAddressWebController {
  render() {
    const { isOpen, userInfo, data, apiErrors, close } = this.props;
    const {
      first_name,
      last_name,
      company_name,
      address,
      address2,
      city,
      state,
      country,
      postal_code,
      phone_number
    }: IAddress = data.attributes;
    const phoneNumber = this.getPhoneNumberWithoutPrefix(phone_number);

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="xs"
        classes={{
          paper: "custom_roundish_dialog_paper"
        }}
        onClose={close}
      >
        <CustomDialogTitle onClose={close}>
          <Box className="forgot_PW_tab_container">
            <Typography variant="subtitle1" className="forgot_PW_tab">
              Edit Address
            </Typography>
          </Box>
        </CustomDialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.editAddressRef}
            initialValues={{
              first_name,
              last_name,
              company_name,
              address,
              address2,
              city,
              state,
              country,
              postal_code,
              phone_number: phoneNumber,
              address_type: "home"
            }}
            validationSchema={AddressSchema}
            onSubmit={values => this.editAddress(values)}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ touched, errors, values, handleChange }) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="First Name"
                        name="first_name"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.first_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.first_name}
                        error={errors.first_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Last Name "
                        name="last_name"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.last_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.last_name}
                        error={errors.last_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Company Name "
                        name="company_name"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.company_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <span className="error">
                        {errors.company_name ? errors.company_name : null}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Address Line 1"
                        name="address"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.address}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.address}
                        error={errors.address}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Address Line 2"
                        name="address2"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.address2}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.address2}
                        error={errors.address2}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="City "
                        name="city"
                        autoComplete="off"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.city}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.city}
                        error={errors.city}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="State"
                        name="state"
                        autoComplete="off"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.state}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.state}
                        error={errors.state}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Country"
                        name="country"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.country}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.country}
                        error={errors.country}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Postal Code"
                        name="postal_code"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.postal_code}
                        onChange={e => {
                          this.handlePostalCodeChange(e, handleChange);
                        }}
                      />
                      <FormikErrorMessage
                        isTouched={touched.postal_code}
                        error={errors.postal_code}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Phone Number"
                        name="phone_number"
                        classes={{
                          input: "Login_Input phone_number_input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.phone_number}
                        onChange={e => {
                          this.handlePhoneNumberChange(e, handleChange);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Typography
                              className={`address_phone_number_prefix ${
                                values.phone_number.length > 0
                                  ? "value_contain_phone_prefix"
                                  : ""
                              }`}
                            >
                              +1
                            </Typography>
                          </InputAdornment>
                        }
                      />
                      <FormikErrorMessage
                        isTouched={touched.phone_number}
                        error={errors.phone_number}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>

          {apiErrors.length > 0 && (
            <div ref={this.errorElementRef}>
              {apiErrors.map((error: { message: string }) => {
                const { message } = error;
                return (
                  <Box mt={2} key={message} id={message}>
                    <Alert severity="error">{message}</Alert>
                  </Box>
                );
              })}
            </div>
          )}
        </DialogContent>
        <CustomDialogActions>
          <Box width="100%">
            <Button
              className="btn"
              classes={{
                label: "normal_login_btn"
              }}
              onClick={this.handleFormSubmit}
              disabled={userInfo.isAddressModalLoading}
            >
              {userInfo.isAddressModalLoading ? (
                <CircularProgress color={"inherit"} size={25} />
              ) : (
                " Update Address"
              )}
            </Button>
          </Box>
        </CustomDialogActions>
      </Dialog>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.user,
    apiErrors: state.user.editAddressApiErrors || []
  };
};

export default connect(
  mapStateToProps,
  {
    updateAddressAction,
    setEditAddressErrors
  }
)(EditAddress);
