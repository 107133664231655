// Customizable Area Start
import React from "react"
import { Grid } from "@material-ui/core"
import SearchCategoryLayout from "./SearchCategoryLayout.web"
import FarmsProductsImgCard from "../../../../components/src/FarmsProductsImgCard.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ISearchProduct } from "../interfaces/search"
const configJSON = require("../config.js")

interface ISearchProductsList {
  searchQuery: string
  isViewAll: boolean
  productsList: ISearchProduct[]
}

const SearchProductsList = ({
  searchQuery,
  isViewAll,
  productsList,
}: ISearchProductsList) => {
  const redirectUrl = isViewAll
    ? `${AppRoutings.SearchProducts}?${configJSON.queryText}=${searchQuery}`
    : ""

  return productsList.length > 0 ? (
    <SearchCategoryLayout
      heading={configJSON.productsText}
      viewAllRedirectUrl={redirectUrl}
    >
      <Grid container spacing={3}>
        {productsList.map((product: ISearchProduct) => {
          const {
            id,
            attributes: { name, description, images, price, compare_at_price },
          } = product
          const imageUrl =
            Array.isArray(images) && images.length > 0 ? images[0].url : ""
          const redirectUrl = `${AppRoutings.FarmProductDetails}/${id}`

          return (
            <Grid item xs={12} sm={6} md={3} key={id}>
              <FarmsProductsImgCard
                cardName={name}
                thumbnail={imageUrl}
                description={description}
                displayPrice={configJSON.blockText}
                displayButton={configJSON.noneText}
                displayDuration={configJSON.noneText}
                displayRating={configJSON.noneText}
                boxShadow={false}
                amount={price}
                discountPrice={compare_at_price}
                path=""
                imgPath={redirectUrl}
              />
            </Grid>
          )
        })}
      </Grid>
    </SearchCategoryLayout>
  ) : null
}

export default SearchProductsList
// Customizable Area End
