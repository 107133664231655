// Customizable Area Start
import { Box } from "@material-ui/core"
import React from "react"
import { leaveImg } from "./assets"
import "./assets/css/leaveImgWithText.scss"

interface IProps {
  heading: string
  description: string
}

const LeafHeadingBanner = ({
  heading,
  description
}: IProps) => {
  return (
    <Box className="leave_img_with_text">
      <Box>
        <img src={leaveImg} alt="leaveImg" />
      </Box>
      <h3>{heading}</h3>
      <p>
        {description}
      </p>
    </Box>
  )
}

export default LeafHeadingBanner
// Customizable Area End
