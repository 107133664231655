// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import StorageProvider from "../../../../framework/src/StorageProvider"
export const configJSON = require("../config.js")

export interface IExpert {
    id: string
    type: string
    attributes: {
        first_name: string
        last_name: string
        headline: string
        educator_image: {
            id: number
            url: string
            filename: string
        }
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    communityExperts: IExpert[]
    expertsList: IExpert[]
    setCommunityExpertsList: (expertsList: IExpert[]) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    anchorEl: any
    expertListFilter: "" | "alphabet"
    isExpertDialog: boolean
    expertId: string
    currentPage: number
    totalPages: number
    // Customizable Area End
}

interface SS {
    id: any
}

export default class ExpertsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    allExpertsApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            anchorEl: null,
            isExpertDialog: false,
            expertListFilter: "",
            expertId: "",
            currentPage: 1,
            totalPages: 0
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getExpertsApi()
    }

    async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): Promise<void> {
        const { currentPage, expertListFilter } = this.state
        if(currentPage !== prevState.currentPage || expertListFilter !== prevState.expertListFilter){
            document.getElementById("expertBox")?.scrollIntoView({ behavior: "smooth" })
            this.getExpertsApi()
        }
    }

    handlePageChange = (pageNo: number) => {
        this.setState({
            currentPage: pageNo
        })
    }

    handlePopoverOpen = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    
    handlePopoverClose = () => {
        this.setState({ anchorEl: null })
    }

    handleFilter = (filter: "" | "alphabet") => {
        this.setState({ anchorEl: null, expertListFilter: filter })
    }

    openExpertDialog = (expertId: string) => {
        this.setState({
            isExpertDialog: true,
            expertId
        })
    }

    closeExpertDialog = () => {
        this.setState({
            isExpertDialog: false,
            expertId: ""
        })
    }

    getExpertsApi = async () => {
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const { currentPage, expertListFilter } = this.state
        this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.productApiContentType,
            "token" : token
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.allExpertsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.expertsApiEndPoint}?page=${currentPage}&per=16&sort_by=${expertListFilter}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.allExpertsApiCallId) {
                    if (responseJson?.data) {
                        const { data, meta: { pagination: { total_pages } } } = responseJson
                        const { setCommunityExpertsList } = this.props
                        setCommunityExpertsList(data)
                        this.setState({ loader: false, totalPages: total_pages })
                        return
                    }
                    toast.error("Something went wrong")
                }
            } else {
                if (apiRequestCallId === this.allExpertsApiCallId) {
                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
