// Customizable Area Start
import React from "react"
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Hidden,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import ArrowBackRounded from "@material-ui/icons/ArrowBackRounded"
import { Form, Formik } from "formik"
import { ClassNameMap } from "@material-ui/styles"
import clsx from "clsx"
import { withRouter } from "react-router-dom"
import * as Yup from "yup"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { isToday } from "date-fns"
import MuiTextField from "@mui/material/TextField"
import dayjs, { Dayjs } from "dayjs"
import AdminAdCampaignDetailsController, {
  Props,
  IInitialValues,
  IStatus,
} from "./AdminAdCampaignDetailsController.web"
import CustomUploadImage from "./CustomUploadImage.web"
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web"
import {
  AdvertisementTypeEnum,
  BusinessTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from "./AdCampaignEnums.web"
const configJSON = require("./config.js")

const EDIT_MODE_OPTIONS = [
  CampaignStatusEnum.Expired,
  CampaignStatusEnum.Scheduled,
]

interface ICustomLineHeading {
  classes: ClassNameMap<"lineHeadingWrapper" | "fieldHeading">
  heading: string
}

interface ICustomTimePicker {
  dataTestId: string
  className: string
  disabled: boolean
  value: Dayjs | null
  handleDateChange: (date: unknown) => void
  onAccept: (date: Dayjs | null) => void
  handleDateTimeBlur: () => void
}

interface ISubmitButton {
  isSaving: boolean
  className: string
}

const CustomLineHeading = ({ classes, heading }: ICustomLineHeading) => {
  return (
    <Box className={classes.lineHeadingWrapper}>
      <Typography variant="h6" className={classes.fieldHeading}>
        {heading}
      </Typography>
      <hr />
    </Box>
  )
}

const CustomTimePicker = ({
  value,
  disabled,
  className,
  dataTestId,
  onAccept,
  handleDateChange,
  handleDateTimeBlur
}: ICustomTimePicker) => {
  return (
    <TimePicker
      data-testid={dataTestId}
      value={value}
      disabled={disabled}
      onChange={handleDateChange}
      views={configJSON.TIME_VIEWS}
      className={className}
      inputFormat={configJSON.TIME_FORMAT}
      renderInput={(params) => <MuiTextField {...params} onBlur={handleDateTimeBlur} />}
      onAccept={onAccept}
    />
  )
}

const SubmitButton = ({ isSaving, className }: ISubmitButton) => {
  return (
    <Box mt={6} mb={2} display="flex" justifyContent="center">
      <Button type="submit" disabled={isSaving} className={className}>
        {isSaving ? <CircularProgress size={25} /> : configJSON.submitLabel}
      </Button>
    </Box>
  )
}

const getSchema = (isEditMode: boolean, startTimeValidation: (fromDate: Date | null, startTime: Date | null) => boolean, fromDateValidation: (fromDate: Date | null) => boolean) => {
  return Yup.object().shape({
    bannerImage: Yup.mixed().required("This field is required"),
    campaignName: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    businessType: Yup.string().required("This field is required"),
    catalogueId: Yup.string().required("This field is required"),
    campaignType: Yup.string().required("This field is required"),
    advertisementTileType: Yup.string().required("This field is required"),
    advertisementTileAsset: Yup.mixed().when("advertisementTileType", {
      is: "creative",
      then: Yup.mixed().required("This field is required"),
      otherwise: Yup.mixed(),
    }),
    fromDate: Yup.date()
      .nullable()
      .typeError("Please enter a valid date")
      .required("This field is required")
      .test(
        'startDateGreater',
        'Past date is not allowed',
        function() {
          const { fromDate } = this.parent
          const isValid = fromDateValidation(fromDate)
          return isValid
        }
      ),
    toDate: Yup.date()
      .nullable()
      .typeError("Please enter a valid date")
      .required("This field is required")
      .when("fromDate", {
        is: (fromDate: Date | null) => {
          return (
            !isEditMode &&
            fromDate instanceof Date &&
            !isNaN(fromDate.getTime())
          )
        },
        then: (schema: Yup.DateSchema) =>
          schema.min(
            Yup.ref("fromDate"),
            "To Date must be greater than From Date"
          ),
        otherwise: (schema: Yup.DateSchema) => schema,
      }),
    startTime: Yup.date()
      .nullable()
      .typeError("Please enter a valid time")
      .required("This field is required")
      .test(
        'startTimeGreater',
        `Since you selected today's date in the date field, the start time must be greater than the current time`,
        function() {
          const { fromDate, startTime } = this.parent
          const isValidDateAndTime = startTimeValidation(fromDate, startTime)
          return isValidDateAndTime
        }
      ),
    endTime: Yup.date()
      .nullable()
      .typeError("Please enter a valid time")
      .required("This field is required")
      .when(["startTime", "fromDate", "toDate"], (startTime: Date, fromDate: Date, toDate: Date, schema: Yup.DateSchema) => {
        return schema.test({
          name: 'end-time-validation',
          message: "End Time must be greater than Start Time if From Date and To Date are the same",
          test: function(endTime: Date) {
            if (!startTime || !endTime || !fromDate || !toDate) return true;
            if (fromDate.toDateString() === toDate.toDateString()) {
              return endTime > startTime;
            }
            return true;
          }
        });
      }),
    status: Yup.string().required("This field is required"),
  })
}

export class AdminAdCampaignDetails extends AdminAdCampaignDetailsController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    const {
      cpcRate,
      cpmRate,
      catelogListing,
      isGettingList,
      isGettingRates,
      currentMode,
      isSaving,
      isLoading,
      adCampaignDetails,
    } = this.state

    const {
      id,
      attributes: {
        advertisement_banner,
        advertisement_type,
        banner,
        campaign_ad_business_type,
        campaign_ad_status,
        campaign_type,
        description,
        end_day,
        end_time,
        estimated_cost,
        name,
        catalogue_id,
        start_day,
        start_time,
        total_cost,
      },
    } = adCampaignDetails

    const initialValues: IInitialValues = {
      id,
      bannerImage: banner,
      campaignName: name,
      description: description,
      businessType: campaign_ad_business_type,
      catalogueId: catalogue_id,
      campaignType: campaign_type,
      advertisementTileType: advertisement_type,
      advertisementTileAsset: advertisement_banner,
      fromDate: start_day,
      toDate: end_day,
      startTime: start_time,
      endTime: end_time,
      estimatedCost: estimated_cost,
      totalCost: total_cost,
      status: campaign_ad_status,
    }
    const isEditMode = currentMode === configJSON.editText

    return (
      <Box className={classes.mainWrapper}>
        {isLoading ? (
          <Box className={classes.loaderWrapper}>
            <CircularProgress size={28} />
          </Box>
        ) : (
          <Formik
            innerRef={this.formikRef}
            validationSchema={getSchema(isEditMode, this.startTimeValidation, this.fromDateValidation)}
            initialValues={initialValues}
            onSubmit={this.handleCreateAdCampaign}
          >
            {({ values, touched, errors, setFieldValue, handleChange }) => {
              const isRestaurantBusiness =
                values.businessType === configJSON.restaurantLabel
              const isGlobalCampaignTypeDisabled =
                isRestaurantBusiness || isEditMode
              const isStatusDisabled = EDIT_MODE_OPTIONS.includes(values.status)

              return (
                <Form noValidate autoComplete="off">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className={classes.contentWrapper}>
                      <Box className={classes.wrapper}>
                        <Box className={classes.headingWrapper}>
                          <IconButton onClick={this.handleGoBack}>
                            <ArrowBackRounded />
                          </IconButton>
                          <Typography
                            variant="h4"
                            className={classes.mainHeading}
                          >
                            {configJSON.adCampaignLabel}
                          </Typography>
                        </Box>
                        <Box className={classes.leftContentWrapper}>
                          <Box>
                            <CustomUploadImage
                              value={values.bannerImage}
                              heading={configJSON.dragFileText}
                              handleImageAdded={(file: File | File[]) =>
                                setFieldValue("bannerImage", file)
                              }
                            />
                            <FormikErrorMessage
                              error={errors.bannerImage}
                              isTouched={touched.bannerImage}
                            />
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <Typography
                              variant="h6"
                              className={classes.fieldHeading}
                            >
                              {configJSON.campaignNameText}
                            </Typography>
                            <TextField
                              name="campaignName"
                              variant="outlined"
                              className={classes.textField}
                              value={values.campaignName}
                              onChange={handleChange}
                            />
                            <FormikErrorMessage
                              error={errors.campaignName}
                              isTouched={touched.campaignName}
                            />
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <Typography
                              variant="h6"
                              className={classes.fieldHeading}
                            >
                              {configJSON.descriptionText}
                            </Typography>
                            <TextareaAutosize
                              name="description"
                              minRows={6}
                              className={`${classes.textField} ${classes.textArea}`}
                              value={values.description}
                              onChange={handleChange}
                            />
                            <FormikErrorMessage
                              error={errors.description}
                              isTouched={touched.description}
                            />
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <CustomLineHeading
                              classes={classes}
                              heading={configJSON.selectBusinessText}
                            />
                            <RadioGroup
                              row
                              name="businessType"
                              value={values.businessType}
                              onChange={this.handleBusinessTypeChange}
                            >
                              <FormControlLabel
                                value={BusinessTypeEnum.Farm}
                                className={classes.disableElement}
                                control={<Radio className={classes.radio} />}
                                label={configJSON.farmTitle}
                                disabled={isEditMode}
                              />
                              <FormControlLabel
                                value={BusinessTypeEnum.Restaurant}
                                className={classes.disableElement}
                                control={
                                  <Radio
                                    className={classes.radio}
                                    data-testid="restaurant-business"
                                  />
                                }
                                label={configJSON.restaurantTitle}
                                disabled={isEditMode}
                              />
                            </RadioGroup>
                            <FormikErrorMessage
                              error={errors.businessType}
                              isTouched={touched.businessType}
                            />
                            <Box>
                              <Select
                                name="catalogueId"
                                disableUnderline
                                displayEmpty
                                disabled={isEditMode || isGettingList}
                                className={classes.catelogDropdown}
                                defaultValue={
                                  catelogListing.length > 0
                                    ? catelogListing[0].id
                                    : ""
                                }
                                value={values.catalogueId}
                                MenuProps={configJSON.MenuProps}
                                onChange={handleChange}
                                IconComponent={ExpandMoreRoundedIcon}
                              >
                                {catelogListing.map((item) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.attributes.name}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                              <FormikErrorMessage
                                error={errors.catalogueId}
                                isTouched={touched.catalogueId}
                              />
                            </Box>
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <CustomLineHeading
                              classes={classes}
                              heading={configJSON.selectCampaignText}
                            />
                            <RadioGroup
                              row
                              name="campaignType"
                              value={values.campaignType}
                              onChange={this.handleCampaignTypeChange}
                            >
                              <FormControlLabel
                                value={CampaignTypeEnum.LocalCampaign}
                                className={classes.disableElement}
                                control={<Radio className={classes.radio} />}
                                label={configJSON.localCampaignText}
                                disabled={isEditMode}
                              />
                              <FormControlLabel
                                value={CampaignTypeEnum.GlobalCampaign}
                                className={classes.disableElement}
                                disabled={isGlobalCampaignTypeDisabled}
                                control={<Radio className={classes.radio} />}
                                label={configJSON.globalCampaignText}
                              />
                            </RadioGroup>
                            <FormikErrorMessage
                              error={errors.campaignType}
                              isTouched={touched.campaignType}
                            />
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <CustomLineHeading
                              classes={classes}
                              heading={configJSON.ratesText}
                            />
                            <Box display="flex" gridGap="60px">
                              <Typography
                                variant="h5"
                                className={clsx(classes.fieldHeading, {
                                  [classes.disabledEffect]: isGettingRates,
                                })}
                              >
                                ${cpmRate} {configJSON.cpmText}
                              </Typography>
                              <Typography
                                variant="h5"
                                className={clsx(classes.fieldHeading, {
                                  [classes.disabledEffect]: isGettingRates,
                                })}
                              >
                                ${cpcRate} {configJSON.cpcText}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <CustomLineHeading
                              classes={classes}
                              heading={configJSON.advertisementCreativeText}
                            />
                            <RadioGroup
                              row
                              name="advertisementTileType"
                              value={values.advertisementTileType}
                              onChange={this.handleAdvertisementTypeChange}
                            >
                              <FormControlLabel
                                value={AdvertisementTypeEnum.BusinessTile}
                                control={<Radio className={classes.radio} />}
                                label={configJSON.selectBusinessTileText}
                              />
                              <FormControlLabel
                                value={
                                  AdvertisementTypeEnum.UploadCreativeAssets
                                }
                                control={<Radio className={classes.radio} />}
                                label={configJSON.uploadCreativeAssetsText}
                              />
                            </RadioGroup>
                            <FormikErrorMessage
                              error={errors.advertisementTileType}
                              isTouched={touched.advertisementTileType}
                            />
                            {values.advertisementTileType ===
                              AdvertisementTypeEnum.UploadCreativeAssets && (
                              <>
                                <CustomUploadImage
                                  value={values.advertisementTileAsset}
                                  heading={configJSON.addMediaText}
                                  handleImageAdded={(file: File | File[]) =>
                                    setFieldValue(
                                      "advertisementTileAsset",
                                      file
                                    )
                                  }
                                />
                                <FormikErrorMessage
                                  error={errors.advertisementTileAsset}
                                  isTouched={touched.advertisementTileAsset}
                                />
                              </>
                            )}
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <CustomLineHeading
                              classes={classes}
                              heading={configJSON.operationalHoursText}
                            />
                            <Box className={classes.dateTimePickerWrapper}>
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.fromText}
                                </Typography>
                                <DatePicker
                                  data-testid="ad-from-date"
                                  disabled={isEditMode}
                                  minDate={dayjs(this.getUTCDateTime())}
                                  inputFormat={configJSON.DATE_FORMAT}
                                  className={classes.textField}
                                  value={values.fromDate}
                                  onChange={this.customValuePasser("fromDate")}
                                  renderInput={(params) => (
                                    <MuiTextField {...params} onBlur={this.handleDateTimeBlur} />
                                  )}
                                  onAccept={this.dateTimeAcceptParser("fromDate")}
                                />
                                <FormikErrorMessage
                                  error={errors.fromDate}
                                  isTouched={touched.fromDate}
                                />
                              </Box>
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.toText}
                                </Typography>
                                <DatePicker
                                  disablePast
                                  data-testid="ad-to-date"
                                  value={values.toDate}
                                  className={classes.textField}
                                  inputFormat={configJSON.DATE_FORMAT}
                                  onChange={this.customValuePasser("toDate")}
                                  renderInput={(params) => (
                                    <MuiTextField {...params} onBlur={this.handleDateTimeBlur} />
                                  )}
                                  onAccept={this.dateTimeAcceptParser("toDate")}
                                />
                                <FormikErrorMessage
                                  error={errors.toDate}
                                  isTouched={touched.toDate}
                                />
                              </Box>
                            </Box>
                            <Box className={classes.dateTimePickerWrapper}>
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.startsAtText}
                                </Typography>
                                <CustomTimePicker
                                  value={values.startTime}
                                  dataTestId="ad-start-time"
                                  disabled={isEditMode}
                                  handleDateChange={this.customValuePasser(
                                    "startTime"
                                  )}
                                  onAccept={this.dateTimeAcceptParser("startTime")}
                                  handleDateTimeBlur={this.handleDateTimeBlur}
                                  className={classes.textField}
                                />
                                <FormikErrorMessage
                                  error={errors.startTime}
                                  isTouched={touched.startTime}
                                />
                              </Box>
                              <Box marginTop="34px">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.toText}
                                </Typography>
                              </Box>
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.endsAtText}
                                </Typography>
                                <CustomTimePicker
                                  value={values.endTime}
                                  dataTestId="ad-end-time"
                                  disabled={isEditMode}
                                  className={classes.textField}
                                  handleDateChange={this.customValuePasser(
                                    "endTime"
                                  )}
                                  handleDateTimeBlur={this.handleDateTimeBlur}
                                  onAccept={this.dateTimeAcceptParser("endTime")}
                                />
                                <FormikErrorMessage
                                  error={errors.endTime}
                                  isTouched={touched.endTime}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.formGroupWrapper}>
                            <Box
                              className={classes.dateTimePickerWrapper}
                              mt={5}
                            >
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.estimatedCostText}
                                </Typography>
                                <Box data-testid="ad-estimated-cost" className={classes.costContainer}>
                                  ${values.estimatedCost}
                                </Box>
                              </Box>
                              <Box width="100%">
                                <Typography
                                  variant="h6"
                                  className={classes.fieldSubHeading}
                                >
                                  {configJSON.totalCostText}
                                </Typography>
                                <Box className={classes.costContainer}>
                                  ${values.totalCost}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Hidden mdDown>
                            <SubmitButton
                              isSaving={isSaving}
                              className={classes.submitButton}
                            />
                          </Hidden>
                        </Box>
                      </Box>
                      <Box className={classes.rightWrapper}>
                        <Typography
                          variant="h5"
                          className={classes.statusHeading}
                        >
                          {configJSON.campaignStatusText}
                        </Typography>
                        <Select
                          disableUnderline
                          name="status"
                          value={values.status}
                          disabled={isStatusDisabled}
                          className={clsx(classes.status, {
                            [classes.activeSelect]:
                              values.status === CampaignStatusEnum.Active,
                            [classes.pauseSelect]:
                              values.status === CampaignStatusEnum.Pause,
                            [classes.deActiveSelect]:
                              values.status === CampaignStatusEnum.Expired,
                          })}
                          onChange={handleChange}
                          IconComponent={ExpandMoreRoundedIcon}
                          MenuProps={{
                            ...configJSON.MenuProps,
                            classes: {
                              paper: classes.statusMenu,
                            },
                          }}
                        >
                          {configJSON.adCampaignStatus.map(
                            (status: IStatus) => {
                              const { value, label, className } = status
                              const isHidden = EDIT_MODE_OPTIONS.includes(
                                value as CampaignStatusEnum
                              )

                              return (
                                <MenuItem
                                  key={value}
                                  hidden={isHidden}
                                  className={classes[className]}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              )
                            }
                          )}
                        </Select>
                        <Hidden lgUp>
                          <SubmitButton
                            isSaving={isSaving}
                            className={classes.submitButton}
                          />
                        </Hidden>
                      </Box>
                    </Box>
                  </LocalizationProvider>
                </Form>
              )
            }}
          </Formik>
        )}
      </Box>
    )
  }
}

export const styles = () =>
  createStyles({
    mainWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#e3e9f6",
    },
    loaderWrapper: {
      flex: 1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "20px",
      backgroundColor: "#fff",
      "& svg": {
        color: "#20a258",
      },
    },
    contentWrapper: {
      display: "flex",
      gap: "20px",
      "& > div:nth-child(1)": {
        width: "65%",
      },
      "& > div:nth-child(2)": {
        width: "35%",
      },
      "@media(max-width: 1279px)": {
        flexDirection: "column",
        "& > div": {
          width: "100% !important",
        },
      },
    },
    wrapper: {
      backgroundColor: "#fff",
      borderRadius: "20px",
    },
    headingWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "20px",
      borderRadius: "20px 20px 0px 0px",
      borderBottom: "1px solid #e2e2ea",
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      zIndex: 1,
      "& button": {
        padding: "10px",
      },
      "& svg": {
        color: "#394053",
      },
    },
    mainHeading: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#394053",
      fontFamily: "'Montserrat', sans-serif",
    },
    leftContentWrapper: {
      padding: "20px",
    },
    fieldHeading: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: 2.2,
      color: "#464a53",
    },
    disabledEffect: {
      opacity: 0.5,
    },
    formGroupWrapper: {
      marginTop: "15px",
    },
    lineHeadingWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& hr": {
        flex: 1,
      },
    },
    catelogDropdown: {
      width: "100%",
      borderRadius: "8px",
      marginTop: "8px",
      border: "1px solid #c1c1c1",
      fontFamily: "'Poppins', sans-serif",
      "& .MuiSelect-select": {
        borderRadius: "8px",
        padding: "11px 32px 11px 11px",
        fontSize: "15px",
      },
      "&.Mui-focused": {
        backgroundColor: "#f4f5f5",
        borderRadius: "8px !important",
      },
    },
    textField: {
      width: "100%",
      border: "1px solid #c1c1c1 !important",
      borderRadius: "8px !important",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
        height: "auto",
        lineHeight: "normal",
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    textArea: {
      width: "100%",
      resize: "none",
      outline: "none",
      padding: "12px",
    },
    radio: {
      color: "#20a258",
      "&$checked": {
        color: "#20a258",
      },
      "& svg": {
        color: "#20a258",
      },
    },
    disableElement: {
      "& .Mui-disabled": {
        color: "rgba(0,0,0,0.38) !important",
        "& svg": {
          color: "rgba(0,0,0,0.38)",
        },
      },
    },
    timePickerWrapper: {
      width: "100%",
    },
    fieldSubHeading: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.8,
      color: "#464a53",
      marginTop: "5px",
    },
    dateTimePickerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      gap: "15px",
      margin: "5px 0px",
    },
    costContainer: {
      padding: "8px 10px",
      borderRadius: "8px",
      border: "1px solid #c1c1c1",
      marginTop: "5px",
      fontSize: "15px",
      fontFamily: "'Poppins', sans-serif",
    },
    submitButton: {
      backgroundColor: "#20a258",
      borderRadius: "8px",
      padding: "10px",
      width: "65%",
      "& span, svg": {
        color: "#fff",
        textTransform: "none",
      },
      "&:hover": {
        backgroundColor: "#20a258",
        opacity: 0.9,
        "& span": {
          color: "#fff",
        },
      },
    },
    rightWrapper: {
      height: "fit-content",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "25px",
    },
    statusHeading: {
      color: "#20a258",
      fontFamily: "'Poppins', sans-serif",
      marginBottom: "15px",
      fontSize: "22px",
      fontWeight: 600,
    },
    status: {
      width: "100%",
      fontWeight: 600,
      borderRadius: "8px",
      color: "#4f4f4f",
      border: "1px solid #4f4f4f",
      backgroundColor: "rgba(0,0,0,0.05)",
      "& svg": {
        color: "#4f4f4f",
      },
      "& .MuiSelect-select": {
        padding: "12px 14px",
      },
      "& .MuiSelect-icon": {
        right: "5px",
      },
    },
    statusMenu: {
      borderRadius: "4px",
      marginTop: "2px",
      border: "1px solid #edf8f2",
      backgroundColor: "#edf8f2",
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
      },
    },
    activeSelect: {
      color: "#20a258",
      border: "1px solid #20a258 !important",
      backgroundColor: "rgba(32, 162, 88, 0.08)",
      "& svg": {
        color: "#20a258",
      },
    },
    pauseSelect: {
      color: "#d98f08",
      border: "1px solid #d98f08 !important",
      backgroundColor: "rgba(217, 143, 8, 0.08)",
      "& svg": {
        color: "#d98f08",
      },
    },
    deActiveSelect: {
      color: "#fc4b4e",
      border: "1px solid #fc4b4e !important",
      backgroundColor: "rgba(252, 75, 78, 0.08)",
      "& svg": {
        color: "#fc4b4e",
      },
    },
    activeMenu: {
      color: "#20a258"
    },
    scheduledMenu: {
      color: "#808080"
    },
    expiredMenu: {
      color: "#fc4b4e",
    },
    pauseMenu: {
      color: "#d98f08",
    },
    removeButton: {
      textTransform: "none",
      "& span": {
        color: "#f90202",
        fontSize: "16px",
        fontWeight: 600,
      },
    },
  })

export default withStyles(styles)(withRouter(AdminAdCampaignDetails))
// Customizable Area End
