// Customizable Area Start
import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenTopBanner from "../../../components/src/GreenTopBanner.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import WebHeader from "../../../components/src/WebHeader.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import "../assets/css/faq.scss";
import { Container, Box, Grid, Typography } from "@material-ui/core";
import webFaqController, { Props } from "./webFAQController.web";
import Loader from "../../../components/src/Loader.web";

class FAQWeb extends webFaqController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />

        <GreenTopBanner bannerHeading="FAQ's" pageName="faq" />
        <Container fixed className="faq_container">
          <Grid
            container
            justifyContent="center"
            spacing={6}
            className="faqSpacing"
          >
            <Grid item xs={12} md={4}>
              <Box className="faqside_image" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                {/* //@ts-ignore */}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className="faq_heading">
                      Frequently asked questions
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.FAQdata &&
                      this.state.FAQdata.length > 0 &&
                      this.state.FAQdata.map((faq: any, index: any) => {
                        return (
                          <Accordion
                            expanded={this.state.expanded === faq.id}
                            className="rootAccordian"
                            style={{ paddingTop: 0 }}
                            key={faq.id}
                          >
                            <AccordionSummary
                              expandIcon={
                                this.state.expanded === faq.id ? (
                                  <RemoveIcon
                                    className="removeIcon"
                                    onClick={() => this.handleChange(false)}
                                  />
                                ) : (
                                  <AddIcon
                                    className="addIcon"
                                    onClick={() => this.handleChange(faq.id)}
                                  />
                                )
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography className="question">
                                {faq.attributes.question}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography className="answer">
                                {faq.attributes.answer}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}

export default FAQWeb;
// Customizable Area End
