// Customizable Area Start
import React from "react"
import { Box, CircularProgress, Container } from "@material-ui/core"
import WebFooter from "../../../../components/src/WebFooter.web"
import GreenBanner from "../../../../components/src/GreenBanner.web"
import "web/src/assets/css/webstyle.scss"
import BreadCrumb from "../../../../components/src/BreadCrumb.web"
import "../../assets/css/shoppingcart.scss"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getCartItems,
  handleCartLoader,
  updateActiveTab,
  getAddressListAction,
  setCartItems,
} from "../assets"
import ShoppingCartController, { Props } from "./ShoppingCartController.web"
import LoginSignUpDialog from "../../../info-page/src/langinpageLoggedIn/LoginSignUpDialog.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import ShoppingCartContent from "./ShoppingCartContent.web"
// Customizable Area End

class ShoppingCart extends ShoppingCartController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { cartLoader, cart } = this.props
    const { paymentMethod, couponCode, couponLoader, loginDialog } = this.state

    return (
      <>
        <Box mt={2} mb={7}>
          <Container>
            <BreadCrumb
              twoPage={true}
              threePage={false}
              firstPage="Home"
              secondPage="Shopping Cart"
              firstPageLink={AppRoutings.Home}
              secondPageLink={AppRoutings.ShoppingCart}
              thirdPage=""
              thirdPageLink=""
            />
            {
              cartLoader ? (
                <Box className="shopping_cart_loader_container">
                  <CircularProgress size={40} />
                </Box>
              ) : 
                <ShoppingCartContent
                  cart={cart}
                  couponLoader={couponLoader}
                  couponCode={couponCode}
                  getAddressAndItems={this.getAddressAndItems}
                  handleCheckoutOrder={this.handleCheckoutOrder}
                  handleApplyRemoveCoupon={this.handleApplyRemoveCoupon}
                  handleCouponCodeChange={this.handleCouponCodeChange}
                />
            }
          </Container>
        </Box>
        <LoginSignUpDialog
          isOpen={loginDialog}
          onClose={this.closeLoginDialog}
        />
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    cart: state.user.cart,
    cartOrderMeta: state.user.cartOrderMeta,
    cartLoader: state.user.cartLoader,
  }
}
// Customizable Area End

// Customizable Area Start
const mapDispatchToProps = (dispatch: any) => {
  return {
    handleCartLoader: bindActionCreators(handleCartLoader, dispatch),
    getCartItems: bindActionCreators(getCartItems, dispatch),
    updateActiveTab: bindActionCreators(updateActiveTab, dispatch),
    getAddressListAction: bindActionCreators(getAddressListAction, dispatch),
    setCartItems: bindActionCreators(setCartItems, dispatch),
  }
}
// Customizable Area End

// Customizable Area Start
export { ShoppingCart }
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
// Customizable Area End
