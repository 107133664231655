import React, { useState } from "react";
// custom components
import VideoInput from "./videoInput.web";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";

import "../../web/src/assets/css/content/button.scss";
import "../../web/src/assets/css/content/tab.scss";
import "../../web/src/assets/css/content/table.scss";
import CustomPagination from "./CustomPagination.web";
import BasicMenu from "./dropdownMenu.web";
import {DefaultProfileImg,DefaultCourseImg} from "../../blocks/catalogue/src/assets"
const configJSON = require('../src/config.js');

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const CustomOrderTab = (props: any) => {
  const [value, setValue] = useState(0);
  const {
    productList,
    thisObject,
    getProductList,
    filterValue,
    search,
    sortValue,
    handleStatus,
    handleMenuClick,
    prodPerPage,
    currentPage,
    total_count,
    total_pages
  } = props;
  
  const menu = configJSON.purchasesMenu;
  const handleChange = (event: any, newValue: any) => {
    const value=!newValue? configJSON.allText : configJSON.placedText
    handleStatus(value)
    getProductList(sortValue, value, search, prodPerPage, 1);
    setValue(newValue);
  };

  const handleChangeTablePage = (value: number) => {
    getProductList(sortValue, filterValue, search, prodPerPage, value);
    thisObject.setState({ currentPage: value });

  };

  const handleIncreasePageView = () => {
    if (prodPerPage < 25) {
      getProductList(sortValue, filterValue, search, prodPerPage+5, 1);
      thisObject.setState({
        prodPerPage: thisObject.state.prodPerPage + 5,
        currentPage: 1,
      });
    }
  };

  const handleDecreasePageView = () => {
    if (prodPerPage > 5) {
      getProductList(sortValue, filterValue, search, prodPerPage-5, 1);
      thisObject.setState({
        prodPerPage: thisObject.state.prodPerPage - 5,
        currentPage: 1,
      });
    }
    }
  
  const indexOfLastList = currentPage * prodPerPage;
  const indexOfFirstList = indexOfLastList - prodPerPage;
  const cureentTableList = productList || "";
  const roundPages =(total_count / prodPerPage);
  let totalListCount = 0;
  if (currentPage > roundPages) {
    totalListCount = total_count;
  } else {
    totalListCount = indexOfLastList;
  }
  return (
    <div className="customTab">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: "green",
          },
        }}
      >
        <Tab label="All " {...a11yProps(0)} />
        <Tab
          label={<span className="title">{configJSON.newPurchaseLabel}</span>}
          icon={<span className="badge">{props.count}</span>}
          {...a11yProps(1)}
        />
      </Tabs>
      {[...Array(2)].map((tab: any, index: any) => {
        return (
          <TabPanel
            key={tab}
            value={value}
            index={index}
            className="adminOrderTab tabPanel"
          >
            <TableContainer
              className="tableContainer learningPurchasesContainer restOrderListContainer purchaseTable"
              component={Paper}
            >
              {productList && productList.length > 0 ? (
                <Table aria-label="simple table" stickyHeader>
                  <TableHead className="tableHead">
                    <TableRow>
                      <TableCell align="left">{configJSON.coursePurchasedLabel}</TableCell>
                      <TableCell align="center">{configJSON.customerNameLabel}</TableCell>
                      <TableCell align="center">{configJSON.paymentTypeLabel}</TableCell>
                      <TableCell align="center">{configJSON.priceLabel}</TableCell>
                      <TableCell align="center">{configJSON.idLabel}</TableCell>
                      <TableCell align="center">{configJSON.orderDateTimeLabel}</TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productList.map((item: any) => {
                      let x = item.attributes;
                      return (
                        <TableRow key={item.id}>
                          <TableCell
                            component={"th" as any}
                            scope="row"
                          >
                            <div className="customerCell">
                              <div className="course-purchase-row ">
                               { x.video ?<VideoInput
                                  className="techPurchase"
                                  fileData={x.video}
                                />:
                                <img
                                  src={DefaultCourseImg}
                                  className="customerPurchaseDefaultCourse"
                                  alt={configJSON.courseAltText}
                                />}
                                <h5>{x?.purchase_details?.course_name}</h5>
                              </div>
                            </div>
                          </TableCell>                       
                          <TableCell align="center">
                            <div className="customerCell">
                              <div className="course-purchase-row">
                                <img
                                  src={x.profile_pic || DefaultProfileImg}
                                  className="customerPurchase"
                                  alt="Farm Profile"
                                />
                                <h5>{x.customer_name}</h5>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            {x.payment_type}
                          </TableCell>
                          <TableCell align="center">
                            {x?.purchase_details?.payment_amount}
                          </TableCell>
                          <TableCell align="center">
                            <div className="customerCell">
                              #{x.order_number}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="customerCell">
                                {x.order_date_and_time}
                            </div>
                          </TableCell>
                          <TableCell
                            align="right"
                            className="dropDownCell"
                          >
                            <div className="option-dropDown">
                              .{" "}
                              <BasicMenu
                                item={item}
                                menu={menu}
                                handleMenuClick={
                                  handleMenuClick
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                "No orders found"
              )}
            </TableContainer>
            {cureentTableList?.length > 0 &&<div className="paginationRow restOrderPagination">
              <div>
                {indexOfFirstList + 1}- {totalListCount} of{" "}
                {total_count} items
              </div>
              <CustomPagination
                count={total_pages}
                page={currentPage}
                pagePerView={prodPerPage}
                handlePageChange={handleChangeTablePage}
                shape="rounded"
                siblingCount={1}
                increasePagePerView={handleIncreasePageView}
                decreasePagePerView={handleDecreasePageView}
                boundaryCount={1}
              />
            </div>}
          </TabPanel>
        );
      })}
    </div>
  );
};
export default CustomOrderTab;
