// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IResolveParams } from "reactjs-social-login";
import { toast } from "react-toastify";
export const configJSON = require('../config.js')

export interface Props {
    navigation: any;
    id: string;
    close : () => void;
    history: any;
    facebookInfo: {
      isFacebookLoading: boolean
    };
    facebookLoginAction : (
      data:any,
      history: () => void,
      closeFn: () => void) => void
}
interface S {
}
 
interface SS {
    id: any;
}

export default class FacebookButtonController extends BlockComponent<
  Props,
  S,
  SS
> {
    postFacebookLoginId : string = ""
    getLandingPageId : string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    onLoginSuccessHandle = ({ data }: IResolveParams) => {
      const { history,close }= this.props;
      this.props.facebookLoginAction(data, history, close)
    }

    handleErrorResponse = (error:any) => {
        if(error){
          toast.error(configJSON.facebookLoginFailedMsg)
        }
    }
}
// Customizable Area End