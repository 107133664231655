// Customizable Area Start
import { IBlock } from "./../../framework/src/IBlock";
import { BlockComponent } from "./../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "./../../framework/src/Messages/MessageEnum";
import { Message } from "./../../framework/src/Message"
import { runEngine } from "./../../framework/src/RunEngine";
import StorageProvider from "./../../framework/src/StorageProvider";
import { isTokenExpired } from "./utility/helper";
import { AppRoutings } from "./utility/app-routing";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  toggle: any;
  classes: any;
  history: any;
  logout: () => void
  sidebarToggle: () => void
}

interface S {
  loading: boolean
  accountDetails: any
  isSquareConnected: boolean
  squareConnectionUrl: string
}
interface SS {
  id: any;
}

export default class AdminHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSquareStatusApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      accountDetails: localStorage.getItem("profile"),
      isSquareConnected: true,
      squareConnectionUrl: ""
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getSquareStatusApi()
  }

  handleMenuClick = (value: any) => {
    if (value == "Home") {
      this.props.history.push("/");
    }
    if (value == "Account Setting") {
      this.props.history.push("/account-setting");
    }
    if (value == "Log Out") {
      this.props.logout();
    }
  }

  handleHomePageRedirect = () => {
    this.props.history.push(AppRoutings.Home)
  }
  
  getSquareStatusApi = async () => {
    this.setState({ loading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
  
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
  
    this.getSquareStatusApiCallId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSquareStatusEndPoint
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
  
    return true
  }  
  
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSquareStatusApiCallId) {
      if (responseJson?.success) {
        const { square_authorized, authorization_url } = responseJson
        this.setState({
          loading: false,
          isSquareConnected: square_authorized,
          squareConnectionUrl: authorization_url,
        })
      }
    }
  }

  handleErrorApiResponse = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.getSquareStatusApiCallId) {
        this.setState({ loading: false })
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        if (await isTokenExpired(responseJson)) return
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
  }
}
// Customizable Area End
