import React from "react";
// Customizable Area Start
import { Switch, Route, withRouter } from "react-router-dom";
import WebDashboardController, { Props } from "./WebDashboardController.web";
import NewWebHeader from "../../../components/src/WebHeader.web";
import ShoppingCart from "../../ShoppingCart/src/shoppingCartWeb/ShoppingCart.web";
import FarmsPage from "../../info-page/src/FarmsPage.web";
import LandingPage from "../../info-page/src/langinpageLoggedIn/LandingPage.web";
import FarmDetails from "../../info-page/src/FarmDetails.web";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import FarmProductCatelog from "../../info-page/src/FarmProductCateloge.web";
import ProductDescriptionWeb from "../../info-page/src/productDetails/productDescription.web";
import WebReviews from "../../info-page/src/productDetails/WebReviews.web";
import Donate from "../../info-page/src/Donate.web";
import AccountSetting from "../../../blocks/Settings/src/AccountSetting.web";
import AllFavourites from "../../../blocks/Favourites/src/AllFavourites.web"
import ContentManagementVendor from "../../../blocks/Favourites/src/ContentManagementVendor.web";
import ViewAddresses from "../../OrderDetailView/src/ViewOrderAddresses.web";
import CommunityRoutes from "./CommunityRoutes.web";
import "../../../web/src/assets/css/style.scss";
import ProtectedRoute from "../../../components/src/ProtectedRoute/WebProtectedRoute.web";
import WishListWeb from "../../Wishlist/src/Wishlist.web";
import CheckoutOrder from "../../info-page/src/checkOut/CheckoutOrder/CheckoutOrder.web";
import Experts from "../../info-page/src/Experts/Experts.web"
import ExpertDetails from "../../info-page/src/Experts/ExpertDetails.web"
import { AppRoutings } from "../../../components/src/utility/app-routing";
import OrderHistoryWeb from "../../OrderDetailView/src/OrderHistory.web";
import { ScrollView } from "react-native"
import AboutUs from "../../../blocks/contactus/src/AboutUs.web"
import ContactUsUser from "../../../blocks/contactus/src/ContactUsUser.web"
import WebFAQ from "../../../blocks/contactus/src/WebFAQ.web"
import Policies from "../../../blocks/contactus/src/Policies.web"
import Search from "../../Search/src/Search.web"
import SearchFarms from "../../Search/src/components/SearchFarms/SearchFarms.web"
import SearchProducts from "../../Search/src/components/SearchProducts/SearchProducts.web"
import SearchDishes from "../../Search/src/components/SearchDishes/SearchDishes.web"
import SearchRestaurants from "../../Search/src/components/SearchRestaurants/SearchRestaurants.web"
import SearchCourses from "../../Search/src/components/SearchCourses/SearchCourses.web";
import HyperLinkContent from "../../TermsAndConditions/src/HyperLinkContent.web";
import Wallet from "../../info-page/src/Wallet/Wallet.web"
import TransactionHistory from "../../info-page/src/Wallet/TransactionHistory/TransactionHistory.web"
// Customizable Area End

class WebDashboard extends WebDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ScrollView>
        <NewWebHeader />
        <WebNewRoutingTabs />

        <Switch>
          <Route
            path={AppRoutings.Home}
            render={(routeProps: any) => (
              <LandingPage {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.LandingPageLoggedIn}
            render={(routeProps: any) => (
              <LandingPage {...routeProps} {...this.state} />
            )}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.AccountSetting}
            render={(routeProps: any) => (
              <AccountSetting {...routeProps} {...this.state} />
            )}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.FavoritePage}
            render={(routeProps: any) => <AllFavourites {...routeProps} />}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.CmsVendorPage}
            render={(routeProps: any) => <ContentManagementVendor {...routeProps} />}
            exact
          />

          <ProtectedRoute
            path={AppRoutings.ViewAddresses}
            render={(routeProps: any) => (
              <ViewAddresses {...routeProps} {...this.state} />
            )}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.WishList}
            render={(routeProps: any) => (
              <WishListWeb {...routeProps} {...this.state} />
            )}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.OrderHistory}
            render={(routeProps: any) => (
              <OrderHistoryWeb {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.FarmsMarket}
            render={(routeProps: any) => (
              <FarmsPage {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.FarmDetailsId}
            render={(routeProps: any) => (
              <FarmDetails {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.FarmDetailsCatelogId}
            render={(routeProps: any) => (
              <FarmProductCatelog {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.FarmProductDetailsId}
            render={(routeProps: any) => (
              <ProductDescriptionWeb {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.ProductDetailsReviewsId}
            render={(routeProps: any) => (
              <WebReviews {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.ShoppingCart}
            render={(routeProps: any) => (
              <ShoppingCart {...routeProps} {...this.state} />
            )}
            exact
          />
          <ProtectedRoute
            path={AppRoutings.CheckOutOrder}
            render={(routeProps: any) => (
              <CheckoutOrder {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.DonateId}
            render={(routeProps: any) => <Donate {...routeProps} {...this.state} />}
            exact
          />
          <Route
            path={AppRoutings.Experts}
            render={(routeProps: any) => <Experts {...routeProps} />}
            exact
          />
          <Route
            path={AppRoutings.ExpertsDetailsId}
            render={(routeProps: any) => <ExpertDetails {...routeProps} />}
            exact
          />
          <Route
            path={AppRoutings.AboutUs}
            render={(routeProps: any) => (
              <AboutUs {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.ContactUs}
            render={(routeProps: any) => (
              <ContactUsUser {...routeProps} {...this.state} />
            )}
            exact
          />
            <Route
            path={AppRoutings.WebFAQ}
            render={(routeProps: any) => (
              <WebFAQ {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.PolicyAndInfo}
            render={(routeProps: any) => (
              <Policies {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.HyperLinkContentId}
            render={(routeProps: any) => (
              <HyperLinkContent {...routeProps} />
            )}
            exact
          />
          <Route
            path={AppRoutings.Search}
            render={() => <Search />}
            exact
          />
          <Route
            path={AppRoutings.SearchFarms}
            render={() => <SearchFarms />}
            exact
          />
          <Route
            path={AppRoutings.SearchProducts}
            render={() => <SearchProducts />}
            exact
          />
          <Route
            path={AppRoutings.SearchDishes}
            render={() => <SearchDishes />}
            exact
          />
          <Route
            path={AppRoutings.SearchRestaurants}
            render={() => <SearchRestaurants />}
            exact
          />
          <Route
            path={AppRoutings.SearchCourses}
            render={() => <SearchCourses />}
            exact
          />
          <Route
            path={AppRoutings.Wallet}
            render={() => <Wallet />}
            exact
          />
          <Route
            path={AppRoutings.TransactionHistory}
            render={() => <TransactionHistory />}
            exact
          />
          <CommunityRoutes />
        </Switch>
      </ScrollView>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
//@ts-ignore
export default withRouter(WebDashboard);
// Customizable Area End