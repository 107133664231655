// Customizable Area Start
import React from "react"
import moment from "moment"
import Autocomplete from "@mui/material/Autocomplete"
import Chip from "@mui/material/Chip"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { PencilIcon } from "./assets"
import "../assets/css/adminAddProduct.scss"
import "../../../web/src/assets/css/style.scss"
import "../assets/css/analytics.scss"
import CustomButton from "../../../components/src/CustomButton.web"
import { countries } from "../../../components/src/CountriesList.web"
import {
  Button,
  MenuItem,
  Select,
  Grid,
  FormLabel,
  FormControl,
  withStyles,
  TextField,
  InputAdornment,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuProps,
  Hidden,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined"
import { withRouter } from "react-router-dom"
import Box from "@mui/material/Box"
import CustomTextfield from "../../../components/src/CustomTextfield.web"
import AdminProductListController from "./AdminProductListController.web"
import CustomProgressbar from "../../../components/src/CustomProgressbar.web"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { commonCmptStyles } from "./dropDownCss"
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import TagsSuggestion from "../../../components/src/TagsSuggestion.web"
import AdminProductVariantAccordion from "./AdminProductVariantAccordion.web"
const configJSON = require("./config.js")

interface IStatusMenu {
  value: string
  label: string
}

const weightUnits = ["Kg", "Lb"]

const Schema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string()
    .max(1000, "Description must be at most 1000 characters")
    .required("This field is required."),
  editImages: Yup.array(),
  images: Yup.string().when("editImages", {
    is: (editImages) => editImages.length < 1,
    then: Yup.string().required("This field is required."),
  }),
  price_per_unit: Yup.number()
    .typeError("This field must be a number")
    .positive("Price must be Positive")
    .required("This field is required."),
  compare_at_price: Yup.number()
    .typeError("This field must be a number")
    .positive("Price must be Positive"),
  cost_per_item: Yup.number()
    .typeError("This field must be a number")
    .positive("Price must be Positive"),
  sku: Yup.string(),
  barcode: Yup.string(),
  available_quantity: Yup.number()
    .nullable()
    .when("track_quantity", {
      is: (track_quantity) => track_quantity,
      then: Yup.number()
        .required("This field is required.")
        .typeError("This field must be a number")
        .positive("Quantity must be Positive"),
    }),
  weight: Yup.number().when("variants", {
    is: (variants) => variants.length > 0,
    then: Yup.number().nullable(),
    otherwise: Yup.number()
    .typeError("This field must be a number")
    .positive("Weight must be Positive")
    .required("Weight is required"),
  }),
  variants: Yup.array().of(
    Yup.object().shape({
      variantHeading: Yup.string().required("Variant heading is required"),
      variantOptions: Yup.array()
        .of(
          Yup.object().shape({
            optionHeading: Yup.string().required("Required"),
            optionPrice: Yup.number()
              .required("Required")
              .typeError("Option price must be a number")
              .positive("Option price must be positive"),
            optionWeight: Yup.number()
              .required("Required")
              .typeError("Option weight must be a number")
              .positive("Option weight must be positive"),
          })
        )
        .min(1, "A minimum of one variant option is required"),
    })
  ),
})

const CommonMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
}

const handleDelete = (setFieldValue: any, url: any, images: any) => {
  const filteredUrls = images.filter((image: any) => image != url)
  setFieldValue("images", filteredUrls)
}

class AdminProductListAddProduct extends AdminProductListController {
  render() {
    const { classes } = this.props
    const { weightDimensions, isVariantConfirmationDialog, isRemovingVariant } = this.state
    const mode = this.state.productMode
    const details = this.state?.productDetails?.attributes
    const inventory = details?.inventory
    const customerInfo = details?.customer_information
    const searchEngine = details?.search_engine_listing
    const availability =
      details?.schedule_availability &&
      moment(details.schedule_availability.split("IST")[0]).format()
    const priceExit =
      details &&
      details.compare_at_price &&
      details.cost_per_item &&
      (Number(details.compare_at_price) - Number(details.cost_per_item)) /
        Number(details.cost_per_item)
    
    return (
      <Box className="adminAddProductWrapper">
        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={this.gettingInitialValue(
            details,
            inventory,
            customerInfo,
            searchEngine,
            availability,
            priceExit
          )}
          validationSchema={Schema}
          onSubmit={(values) => this.createProduct(values)}
        >
          {(formikProps) => {
            const {
              values,
              errors,
              setFieldValue,
              handleChange,
              resetForm,
            } = formikProps

            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <div className="productDetailsContainer">
                      {/* header start */}
                      <div className="header">
                        <div
                          data-testid="arrowBtn"
                          onClick={() => this.props.history.goBack()}
                        >
                          <ArrowBackIcon />
                        </div>
                        <h4>{this.handlePageHeading(mode)} Product</h4>
                      </div>
                      {/* header end */}
                      {/* title start */}
                      <div className="productTitleContainer">
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>Product Title</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="name"
                              value={values.name}
                              variant="standard"
                              placeholder="Apples, Grapes...."
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="name" />
                            </span>
                          </div>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder textarea-wrapper">
                          <label>Description</label>
                          <br />
                          <div className="customTextAreafield">
                            <TextareaAutosize
                              id="description"
                              className="description"
                              minRows={8}
                              value={values.description}
                              onChange={handleChange}
                            />

                            <span className="errorMsg">
                              <ErrorMessage name="description" />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* title end */}

                      {/* media start*/}
                      <div className="mediaContainer">
                        <div className="mediaHeader">
                          <h4>Media</h4>
                          <div className="green-outline">
                            <div className="customSelect itemStatus">
                              <FormControl>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  id="media_mode"
                                  data-testid="mediaMode"
                                  className="dragdropUrl"
                                  value={values.media_mode}
                                  onChange={(e: any) => {
                                    setFieldValue("images", [])
                                    setFieldValue("media_mode", e.target.value)
                                  }}
                                  MenuProps={{
                                    ...CommonMenuProps,
                                    classes: {
                                      paper: `${classes.itemStatus} ${classes.dropStatus}`,
                                    },
                                  }}
                                >
                                  <MenuItem value="drag">
                                    Drag and drop a file
                                  </MenuItem>
                                  <MenuItem value="url">
                                    Upload from URL
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        {values.media_mode == "drag" ? (
                          <>
                            <div
                              className="mediaUploader"
                              data-testid="dragOptions"
                              onDragOver={(e: any) => this.handleDragOver(e)}
                              onDrop={(e: any) =>
                                this.handleDragDrop(e, values, setFieldValue)
                              }
                            >
                              <label>
                                <input
                                  id="images"
                                  name="images"
                                  data-testid="mediaInput"
                                  type="file"
                                  hidden
                                  multiple
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={(e: any) => {
                                    this.multipleUploadImage(
                                      values,
                                      e,
                                      setFieldValue
                                    )
                                  }}
                                />
                                <BackupOutlinedIcon />
                                <span>Drag and drop a file or click here</span>
                              </label>
                            </div>
                            {values?.images?.length != 0 && (
                              <div className="uploadSection upload-mt">
                                {values?.images.map((image: any) => {
                                  return (
                                    <div
                                      data-testid="uploadImg"
                                      className="uploadImgbg"
                                      key={image}
                                    >
                                      <img
                                        src={(
                                          window.URL || window.webkitURL
                                        ).createObjectURL(image)}
                                        alt="banner"
                                      />
                                      <span
                                        className="close-icon-bg"
                                        data-testid="closeImage"
                                        onClick={(e: any) => {
                                          const filteredImages = values.images.filter(
                                            (x: any) => {
                                              return x.name != image.name
                                            }
                                          )
                                          setFieldValue(
                                            "images",
                                            filteredImages
                                          )
                                        }}
                                      >
                                        <span className="close-icon">+</span>
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="media-main-block outlineTextfield boxRadiusBorder">
                            <div className="media-chip-block">
                              <div className="customUrlrow">
                                <div className="chipRow">
                                  {values.images.map((url: string) => {
                                    return (
                                      <Chip
                                        key={url}
                                        label={url}
                                        variant="outlined"
                                        onDelete={() =>
                                          handleDelete(
                                            setFieldValue,
                                            url,
                                            values.images
                                          )
                                        }
                                      />
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="inputrow customTextfield">
                              <TextField
                                id="images_url"
                                name="images_url"
                                value={values.images_url}
                                variant="standard"
                                placeholder="Please Enter URL"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e: any) => {
                                  setFieldValue("images_url", e.target.value)
                                }}
                              />

                              <div className="btn-container">
                                <Button
                                  id="addUrl"
                                  className="save"
                                  disabled={values.images_url == ""}
                                  onClick={() => {
                                    const isUrlExist = values.images.includes(
                                      values.images_url
                                    )
                                    this.imageUrlExit(
                                      isUrlExist,
                                      setFieldValue,
                                      values
                                    )
                                  }}
                                >
                                  Add URL
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.editImages.length > 0 && (
                          <div className="uploadSection">
                            {values?.editImages.map((image: any) => {
                              return (
                                <div className="uploadImgbg" key={image.id}>
                                  <img src={image.url} alt="banner" />
                                  <span
                                    className="close-icon-bg"
                                    data-testid="imageCloseIcon"
                                    onClick={(e: any) => {
                                      let imagesLeft = values?.editImages.filter(
                                        (img: any) => img.id != image.id
                                      )
                                      setFieldValue("editImages", imagesLeft)
                                      this.deleteImage(image.id)
                                    }}
                                  >
                                    <span className="close-icon">+</span>
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        )}
                        <span className="errorMsg">
                          <ErrorMessage name="images" />
                        </span>
                      </div>

                      {/* media  end*/}

                      {/* price start */}
                      <div className="priceInfo">
                        <div className="customheading">
                          <h4>Pricing</h4>
                          <hr />
                        </div>
                        <Grid
                          container
                          spacing={2}
                          className="priceCard"
                        >
                          <Grid item md={6} xs={12}>
                            <div className="outlineTextfield boxRadiusBorder">
                              <label>Current Price (Per unit)</label>
                              <br />

                              <div className="customTextfield">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  id="price_per_unit"
                                  value={values.price_per_unit}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "price_per_unit",
                                      e.target.value
                                    )
                                    this.PriceDebounceUpdate(
                                      e.target.value,
                                      values.cost_per_item,
                                      setFieldValue
                                    )
                                  }}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="price_per_unit" />
                                </span>
                              </div>
                            </div>

                            <div className="outlineTextfield boxRadiusBorder">
                              <label>Standard Price</label>
                              <br />
                              <div className="customTextfield">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  id="compare_at_price"
                                  value={values.compare_at_price}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="compare_at_price" />
                                </span>
                              </div>
                            </div>
                            <div className="outlineTextfield boxRadiusBorder">
                              <label>Cost per item</label>
                              <br />
                              <div className="customTextfield">
                                <TextField
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  id="cost_per_item"
                                  value={values.cost_per_item}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "cost_per_item",
                                      e.target.value
                                    )
                                    this.PriceDebounceUpdate(
                                      values.price_per_unit,
                                      e.target.value,
                                      setFieldValue
                                    )
                                  }}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="cost_per_item" />
                                </span>
                              </div>
                              <p>Customer won't see this price</p>
                            </div>
                          </Grid>
                          {String(values.margin) &&
                            values.cost_per_item &&
                            values.price_per_unit && (
                              <Grid item md={6} xs={12}>
                                {/* //@ts-ignore */}
                                <CustomProgressbar
                                  margin={values.margin}
                                  heading=""
                                />
                              </Grid>
                            )}
                        </Grid>
                        <div />
                      </div>
                      {/* price end */}

                      <div className="inventoryContainer">
                        <div className="customheading">
                          <h4>Variants</h4>
                          <hr />
                        </div>
                        <div className="variantDetailsWrapper">
                          {values.variants.length > 0 ? (
                            <AdminProductVariantAccordion
                              formikErrors={errors}
                              variants={values.variants}
                              weightDimensions={weightDimensions}
                              isRemovingVariant={isRemovingVariant}
                              isVariantConfirmationDialog={isVariantConfirmationDialog}
                              handleChange={handleChange}
                              handleRemoveVariant={this.handleRemoveVariant}
                              handleRemoveVariantDialog={this.handleVariantConfirmationDialog}
                            />
                          ) : (
                            <div className="add-variant-wrapper">
                              <button
                                type="button"
                                onClick={this.handleAddVariant}
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* inventory start */}
                      <div className="inventoryContainer">
                        <div className="customheading">
                          <h4>
                            Inventory
                            <span className="optionalTitle">(optional)</span>
                          </h4>
                          <hr />
                        </div>
                        <div className="inventoryBlocks">
                          <div className="block1">
                            <div className="outlineTextfield boxRadiusBorder">
                              <label>SKU (Stock Keeping Unit)</label>
                              <br />
                              <div className="customTextfield">
                                <TextField
                                  id="sku"
                                  value={values.sku}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="outlineTextfield boxRadiusBorder">
                              <label>Barcode (ISBN, UPC, GTIN, etc.)</label>
                              <br />
                              <div className="customTextfield">
                                <TextField
                                  id="barcode"
                                  value={values.barcode}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="block2">
                            <div className="checkBoxContainer">
                              {" "}
                              <div className="customCheckbox">
                                <input
                                  type="checkbox"
                                  name="track_quantity"
                                  id="track_quantity"
                                  value="track_quantity"
                                  checked={values.track_quantity}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "track_quantity",
                                      e.target.checked
                                    )
                                    this.handleTrackQuantity(
                                      "track_quantity",
                                      e.target.checked,
                                      setFieldValue
                                    )
                                  }}
                                />
                                <label htmlFor="track_quantity" />
                              </div>
                              <FormLabel component="legend">
                                Track Quantity
                              </FormLabel>
                            </div>
                            <div className="checkBoxContainer">
                              <div className="customCheckbox">
                                <input
                                  type="checkbox"
                                  name="selling_out_of_stock"
                                  id="selling_out_of_stock"
                                  value="selling_out_of_stock"
                                  checked={values.selling_out_of_stock}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      "selling_out_of_stock",
                                      e.target.checked
                                    )
                                    this.handleTrackQuantity(
                                      "selling_out_of_stock",
                                      e.target.checked,
                                      setFieldValue
                                    )
                                  }}
                                />
                                <label htmlFor="selling_out_of_stock" />
                              </div>
                              <FormLabel component="legend">
                                Continue sell when out of stock
                              </FormLabel>
                            </div>
                          </div>
                          <div className="block3 track_quan">
                            <div className="outlineTextfield inventoryTextbox boxRadiusBorder">
                              <label>Available Quantity</label>

                              <div className="customTextfield">
                                <TextField
                                  id="available_quantity"
                                  value={values.available_quantity}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                  disabled={values.selling_out_of_stock}
                                />
                              </div>
                              {values.track_quantity && (
                                <span className="errorMsg">
                                  <ErrorMessage name="available_quantity" />
                                </span>
                              )}
                            </div>
                            <div className="selectContainer">
                              <div className="filledSelect leftIcon">
                                <div className="customSelect ">
                                  <FormControl>
                                    <Select
                                      labelId="demo-controlled-open-select-label"
                                      id="demo-controlled-open-select"
                                      data-testid="quantity_unit"
                                      value={values.quantity_unit}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "quantity_unit",
                                          e.target.value
                                        )
                                        setFieldValue(
                                          "weight_unit",
                                          e.target.value
                                        )
                                      }}
                                      disabled={values.selling_out_of_stock}
                                      MenuProps={{ ...CommonMenuProps }}
                                    >
                                      {weightUnits.map((value: string) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* inventory end */}

                      {/* shipping start */}
                      <div className="shippingContainer">
                        <div className="customheading">
                          <h4>Shipping</h4>
                          <hr />
                        </div>
                        <div className="productState">
                          <div className="customCheckbox">
                            <input
                              type="checkbox"
                              name="physical_product"
                              id="physical_product"
                              value="physical_product"
                              checked={values.physical_product}
                              onChange={(e: any) => {
                                setFieldValue(
                                  "physical_product",
                                  e.target.checked
                                )
                              }}
                            />
                            <label htmlFor="physical_product" />
                          </div>

                          <FormLabel component="legend">
                            This is a physical product
                          </FormLabel>
                        </div>
                        <div className="productWeight">
                          <div className="weightDetails track_quan">
                            <div className="outlineTextfield inventoryTextbox boxRadiusBorder">
                              <label>WEIGHT</label>

                              <div className="customTextfield">
                                <TextField
                                  id="weight"
                                  value={values.weight}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="weight" />
                                </span>
                              </div>
                            </div>
                            <div className="selectContainer">
                              <div className="filledSelect leftIcon">
                                <div className="customSelect ">
                                  <FormControl>
                                    <Select
                                      labelId="demo-controlled-open-select-label"
                                      id="demo-controlled-open-select"
                                      data-testid="units"
                                      value={values.weight_unit}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "weight_unit",
                                          e.target.value
                                        )
                                        setFieldValue(
                                          "quantity_unit",
                                          e.target.value
                                        )
                                      }}
                                      MenuProps={{ ...CommonMenuProps }}
                                    >
                                      {weightUnits.map((value: string) => {
                                        return (
                                          <MenuItem value={value} key={value}>
                                            {value}
                                          </MenuItem>
                                        )
                                      })}
                                      <span className="errorMsg">
                                        <ErrorMessage name="weight_unit" />
                                      </span>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            Used to calculate shipping rates at checkout and
                            label prices during fulfillment.
                          </p>
                          <hr />
                          <div className="customsInfo autocompBlock">
                            <h4>CUSTOMS INFORMATION </h4>
                            <p>
                              Customs authorities use this information to
                              calculate duties when shipping internationally.
                              Shown on printed customs forms.
                            </p>
                            <div className="outlineSelect boxRadiusBorder">
                              <label className="codeTitle">
                                Country / Region of origin
                              </label>
                              <br />
                              <Autocomplete
                                id="country-select-demo"
                                // sx={{ width: 300 }}
                                options={countries}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    "country_name",
                                    value?.label ? value?.label : ""
                                  )
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) =>
                                  this.renderOptions(props, option)
                                }
                                renderInput={(params) => (
                                  <div className="outlineTextfield boxRadiusBorder">
                                    <div className="customTextfield">
                                      <TextField
                                        {...params}
                                        className="autocompInput"
                                        placeholder="Select a country/region"
                                        inputProps={{
                                          ...params.inputProps,
                                          // autoComplete: "India", // disable autocomplete and autofill
                                          value: `${values.country_name}`,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="country_name" />
                              </span>
                              <p>
                                In most cases, where the product is
                                manufactured.
                              </p>
                            </div>

                            <div className="outlineTextfield boxRadiusBorder">
                              <label>Hs (Harmonised System) code</label>
                              <br />

                              <div className="customTextfield">
                                <TextField
                                  id="hsc"
                                  value={values.hsc}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="hsc" />
                                </span>
                              </div>
                              <p>
                                Manually enter codes that are longer than 6
                                numbers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* shipping end */}

                      {/* preview start */}
                      <div className="previewContainer">
                        <div className="previewHeader">
                          <h4>Search engine listing preview</h4>
                          <hr />
                        </div>
                        <p>
                          Add a title and description to see how this product
                          might appear in a search engine listing
                        </p>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>Page Title</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="page_title"
                              value={values.page_title}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="page_title" />
                            </span>
                          </div>
                          <p>0 of 70 characters used </p>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>Description</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="page_description"
                              value={values.page_description}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="page_description" />
                            </span>
                          </div>
                          <p>0 of 320 characters used</p>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>URL and handle</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="url_handle"
                              value={values.url_handle}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="url_handle" />
                            </span>
                          </div>
                        </div>
                        <Hidden mdDown>
                          <div className="btn-container">
                            <Button
                              data-testid="btnCancel"
                              className="cancel"
                              onClick={() => {
                                resetForm()
                                this.props.history.goBack()
                              }}
                            >
                              Cancel
                            </Button>
                            <Button className="save" type="submit">
                              {this.handleButtonText()}
                            </Button>
                          </div>
                        </Hidden>
                      </div>
                      {/* preview end */}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="productStatusContainer commonDropdown">
                      {/* product status start */}
                      <div className="productStatus">
                        <h4>Product status</h4>

                        <div className="outlineSelect">
                          <div
                            className={`customSelect itemStatus ${this.handleDraftCss(
                              values.farm_status
                            )} ${this.handleDeactiveCss(values.farm_status)}`}
                          >
                            <FormControl>
                              <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                data-testid="farmStatus"
                                value={values.farm_status}
                                onChange={(e: any) => {
                                  setFieldValue("farm_status", e.target.value)
                                }}
                                MenuProps={{
                                  ...CommonMenuProps,
                                  classes: {
                                    paper: classes.itemStatus,
                                  },
                                }}
                                // IconComponent={() => <ExpandMoreIcon />}
                              >
                                {configJSON.statusList.map((status: IStatusMenu) => {
                                  const { label, value } = status

                                  return (
                                    <MenuItem value={value} key={value}>
                                      {label}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <p>
                          This product will be hidden from all sales channels
                        </p>
                        <h6>SALES CHANNELS AND APPS</h6>
                        <div className="checkboxContainer">
                          <div className="salesCheckbox customCheckbox">
                            <input
                              type="checkbox"
                              name="online_store"
                              //@ts-ignore
                              id="online_store"
                              value="online_store"
                              checked={values.online_store}
                              onChange={(e: any) => {
                                setFieldValue("online_store", e.target.checked)
                              }}
                            />
                            <label
                              htmlFor="online_store"
                              className="Online-Store-label"
                            >
                              Online store
                            </label>
                          </div>
                        </div>
                        {values.farm_status != "active" && (
                          <>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <div className="dateTimePickerConatiner">
                                <MobileDateTimePicker
                                  data-testid="timePicker"
                                  value={values.schedule_availability}
                                  onChange={(newValue: any) => {
                                    setFieldValue(
                                      "schedule_availability",
                                      newValue
                                    )
                                  }}
                                  renderInput={(params: any) => {
                                    params.inputProps.value =
                                      "Schedule Availability"
                                    return (
                                      <div className="datepicker_row">
                                        <TextField {...params} />
                                        <div className="pencil_icon">
                                          {/* <EditOutlinedIcon /> */}
                                          <img
                                            src={PencilIcon}
                                            alt="Pencil Icon"
                                          />
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                            </LocalizationProvider>
                            {/* </div> */}
                            <h6>Scheduled</h6>
                            {values.schedule_availability && (
                              <h5>
                                {" "}
                                {moment(values.schedule_availability).format(
                                  "DD MMMM YYYY  LT"
                                )}{" "}
                              </h5>
                            )}
                            <p>
                              Scheduling won't apply until product status is set
                              as active
                            </p>
                          </>
                        )}
                        {this.deleteDisplay() ? (
                          <div
                            className="btns"
                            data-testid="DeleteBtn"
                            onClick={() => {
                              this.setState({
                                removedProduct: this.getQuery().get(
                                  "product_id"
                                ),
                              })
                              this.handleDialog()
                            }}
                          >
                            <Button>Delete</Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* product status end */}
                      {/* product type start */}

                      <div className="productTypeContainer">
                        <h5>product type</h5>
                        <div className="green-outline">
                          <div className="customSelect ">
                            <FormControl>
                              <Select
                                data-testid="categories"
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                value={this.getCategoryId(values)}
                                onChange={(e: any) => {
                                  setFieldValue("category_id", e.target.value)
                                }}
                                MenuProps={{
                                  ...CommonMenuProps,
                                  classes: {
                                    paper: classes.itemStatus,
                                  },
                                }}
                              >
                                <MenuItem disabled value="default">
                                  Select type
                                </MenuItem>
                                {this.state.categories ? (
                                  this.state.categories.length > 0 &&
                                  this.state.categories.map(
                                    (item: any, index: any) => {
                                      return (
                                        <MenuItem value={item.id} key={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      )
                                    }
                                  )
                                ) : (
                                  <MenuItem value="none">
                                    No category Found
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <p>Improve buyer discovery , analytics & work flows</p>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>Collection</label>
                          <br />
                          <CustomTextfield placeholder="" />
                          <p>
                            Add this product to a collection so it's easy to
                            find in your store.
                          </p>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>TAGS</label>
                          <br />
                          <TagsSuggestion
                            name="tags"
                            placeholder="Fresh fruit Healthy food"
                            value={values.tags}
                            handleValueChange={(value: string) =>
                              setFieldValue("tags", value)
                            }
                          />
                        </div>
                      </div>
                      {/* product type end */}

                      <Hidden lgUp>
                          <div className="sm-screen-btn-container">
                            <Button
                              data-testid="btnCancel"
                              className="cancel"
                              onClick={() => {
                                resetForm()
                                this.props.history.goBack()
                              }}
                            >
                              Cancel
                            </Button>
                            <Button className="save" type="submit">
                              {this.handleButtonText()}
                            </Button>
                          </div>
                        </Hidden>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
        {/* remove modal */}
        <div>
          <Dialog
            data-testid="dialog"
            open={this.state.openDialog}
            onClose={() => this.handleDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete the product ?
            </DialogTitle>
            <DialogActions>
              {/* //@ts-ignore */}
              {/* <Button onClick={() => this.handleDialog()}>No</Button> */}
              {/* //@ts-ignore */}
              <span className="redContained customCancelBtn">
                <CustomButton
                  color="secondary"
                  label="No"
                  onClick={() => this.handleDialog()}
                />
              </span>
              <span className="greenContained">
                <CustomButton
                  color="secondary"
                  label="Yes"
                  onClick={() => {
                    this.handleDialog()
                    this.deleteProduct()
                  }}
                  autoFocus
                />
              </span>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    )
  }
}

export { AdminProductListAddProduct }
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminProductListAddProduct)
)
// Customizable Area End
