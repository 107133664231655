import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history?: any;
  classes?: any;
  logout : () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean
  restaurants: any[]
  currentRestId: string
  productMode: any
  restForumDetails: any
  search: string
  searchVal: string
  forumsList: any
  forumsPerPage: number
  forumsCurrentPage: number
  totalForumsPage: number
  totalForumCount: number
  selectedForum : any[]
  removedProduct : any
  openDialog : boolean
  bulkAction : string
  replyId: string
  replyEditId: string
  forumId : number | string | null
  replyStatus : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestaurantForumsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRestaurantsApiCallId: string = ""
  getRestForumDetailsApiCallId: string = ""
  getRestForumsListApiCallId: string = ""
  createForumApiCallId : string = ""
  updateForumStatusApiCallId : string = ""
  bulkActionsForumApiCallId : string = ""
  deleteRestForumApiCallId : string = ""
  deleteReplyReviewApiCallId : string = ""
  deleteReviewApiCallId : string = ""
  addReplyApiCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      restaurants: [],
      currentRestId: "",
      productMode: "",
      restForumDetails: [],
      search: '',
      searchVal: '',
      forumsList: [],
      forumsPerPage: 5,
      forumsCurrentPage: 1,
      totalForumsPage: 0,
      totalForumCount: 0,
      selectedForum: [],
      removedProduct : '',
      openDialog : false,
      bulkAction : configJSON.actionsText,
      replyId: '',
      replyEditId: '',
      forumId: 0,
      replyStatus : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {

    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get(configJSON.modeText);
    this.setState({
      productMode: mode,
      forumId : query.get(configJSON.forumIdText)
    });
    const path = window.location.pathname.split("/")[3] == configJSON.forumsText
    if (path) {
      this.getAllRestaurants();
    }
    if (mode == configJSON.editText) {
      this.getRestForumDetails(query.get(configJSON.forumIdText));
    }
  }

  async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>) {
    const {
      forumsCurrentPage,
      forumsPerPage,
      currentRestId,
      searchVal
    } = this.state

    if (
      prevState.forumsCurrentPage !== forumsCurrentPage ||
      prevState.forumsPerPage !== forumsPerPage ||
      prevState.searchVal !== searchVal ||
      prevState.currentRestId !== currentRestId
    ) {
      this.getRestForumsList(currentRestId, searchVal);
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
        
      } else if (responseJson?.errors) {
        toast.error(responseJson?.errors[0].message)
        if (responseJson?.errors[0] && ((responseJson?.errors[0].message == configJSON.tokenExpiredText) || (responseJson?.errors[0].message == configJSON.invalidTokenText))) {
          this.props.logout()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {

    switch (apiRequestCallId) {
      case this.getAllRestaurantsApiCallId : this.handleGetAllRestaurant(responseJson)
            break;
      case this.getRestForumsListApiCallId :  
          const {
            total_count,
            total_pages
          } = responseJson?.meta?.pagination
          const { forumsCurrentPage } = this.state

          this.setState({
            forumsList: responseJson.data,
            loading: false,
            totalForumCount: total_count,
            totalForumsPage: total_pages
          })

          if(responseJson?.data?.length === 0 && forumsCurrentPage > 1) this.setState({ forumsCurrentPage: forumsCurrentPage - 1 })     
          break;

      case  this.updateForumStatusApiCallId :  
              const path = window.location.pathname.split("/")[4];
              this.getRestForumsList(path, this.state.search);
              this.setState({
                loading: false,
              });
              toast.success(configJSON.forumStatusUpdateText);
              break;

      case  this.bulkActionsForumApiCallId :
              const path1 = window.location.pathname.split("/")[4];
              this.getRestForumsList(path1, this.state.search);
              this.setState({
                selectedForum: [],
                loading: false,
              });
              toast.success(configJSON.forumsUpdatedText);
              break;
      
      case this.createForumApiCallId :  
              const mode1 = this.state.productMode == configJSON.createText
              toast.success(configJSON.forumSuccessText + `${mode1 ? configJSON.addedText : configJSON.updatedText}`)
              this.setState({
                loading: false,
              });
              this.props.history.goBack()
              break;

      case this.deleteRestForumApiCallId : 
              const path2 = window.location.pathname.split("/")[4];
              const mode2 = this.state.productMode == configJSON.editText
              !mode2 && this.getRestForumsList(path2, this.state.search);
              this.setState({
                loading: false,
              });
              toast.success(configJSON.forumRemovalMsg);
              mode2 && this.props.history.goBack()
              break;

      case this.getRestForumDetailsApiCallId : 
              this.setState({
                restForumDetails: responseJson.data,
                loading: false
              });     
              break;
              
      case this.deleteReviewApiCallId : 
              toast.success(configJSON.deleteReviewMsg)
              this.getRestForumDetails(this.state.forumId);
              break;
      case this.deleteReplyReviewApiCallId: 
              toast.success(configJSON.replyRemovalMsg)
              this.getRestForumDetails(this.state.forumId);
              break;
      case this.addReplyApiCallId: 
              toast.success(`Reply ${this.state.replyStatus} Successfully`)
              this.getRestForumDetails(this.state.forumId);
              break;
      default:
            break;       
    }

  }

  Schema = Yup.object().shape({
    heading: Yup.string().required(configJSON.fieldValidateText),
    description: Yup.string()
    .max(1000, configJSON.descValidateText)
    .required(configJSON.fieldValidateText),
    banner: Yup.string().required(configJSON.fieldValidateText),
  });

  handleGetAllRestaurant = (responseJson: any) => {

    const restId: string | undefined = window.location.pathname.split("/")?.pop()

    if(responseJson.data.length > 0) {
      const pathRestId = responseJson.data[0].id
      this.setState({
        restaurants: responseJson.data,
        currentRestId: pathRestId,
        loading: false
      })
      this.props.history.push(configJSON.forumEndPoint + `${pathRestId}`)
    }
    else if(!!restId && restId !== "00") {
      this.setState({
        currentRestId: restId,
        restaurants: responseJson.data
      })
    }  else {
      this.setState({
        loading: false
      })
    }
  }

  handleCreateForumBtn = () => {
    this.props.history.push(
      `/admin/restaurant/add-forum/${window.location.pathname.split("/")[4]
      }?mode=create`
    );
  }

  // Get all restaurants list
  getAllRestaurants = async () => {
    this.setState({
      loading: true
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRestaurantsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getRestForumDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRestForumDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getForumDetailsAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Forums list
  getRestForumsList = async (id: string, search: string) => {
    this.setState({
      loading: true,
    });
    const { forumsPerPage, forumsCurrentPage } = this.state;
  
    const endpoint = `forum[forum_type]=Restaurant&restaurant_id=${id}&search=${search}&page=${forumsCurrentPage}&per=${forumsPerPage}`;
    this.setState({
      loading: true,
    });
  
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getRestForumsListApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getForumsListAPiEndPoint + `${endpoint}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }  

  //create Rest forum
  createRestForum = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token: token,
    };
    const mode = this.state.productMode == configJSON.createText
    const endPoint = mode ? configJSON.createForumAPiEndPoint : (configJSON.createForumAPiEndPoint + `/${this.state.restForumDetails.id}`)
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("forum[heading]", values.heading);
    formdata.append("forum[description]", values.description);
    formdata.append("forum[status]", values.status);
    if (values.banner.length > 0) {
      values.banner[0].name && formdata.append("forum[banner]",
        values.banner[0])
    }
    formdata.append("forum[forum_type]", values.forumable_type);
    formdata.append("forum[forumable_id]", values.forumable_id);
    formdata.append("forum[forumable_type]", configJSON.restaurantEndPoint);



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //updating status
  updateForumStatus = async (id: any, event: React.ChangeEvent<{value:any}>) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      forum: {
        id: id,
        status: event.target.value,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateForumStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateForumStatusAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //bulk actions
  bulkActions = async (event: React.ChangeEvent<{value:any}>) => {
    const status = event.target.value
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      "status": status,
      "ids": this.state.selectedForum,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteRestForum = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRestForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateForumStatusAPiEndPoint + `${this.state.removedProduct}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //add reply
  addReply = async (reply: any, id: any, mode: any) => {
    this.setState({
      loading: true,
    });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
    const isCreate = (mode == configJSON.createText) ? true : false
    const EndPoint = configJSON.addReplyAPiEndPoint + (!isCreate ? `/${id}` : "")
    const method = isCreate ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    this.setState({ replyStatus : method === configJSON.apiMethodTypePut ? configJSON.UpdatedText : configJSON.AddedText})

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      "description": reply,
      ...(isCreate && { "review_id": id })
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReplyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      EndPoint

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //delete review
  deleteReview = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteReviewAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //delete REply review
  deleteReplyReview = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReplyReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReplyAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  isSelectedForum = (num: any) => this.state.selectedForum.indexOf(num) !== -1;

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id,
    });
    if (value == configJSON.editText) {
      this.props.history.push(
        `/admin/restaurant/add-forum/${window.location.pathname.split("/")[4]
        }?mode=edit&forum_id=${item.id}`
      );
    }
    if (value == configJSON.removeText) {
      this.handleDialog();
    }
  };

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog
    });
  };

  handleClickForumRow = (num: any, event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    const selectedIndex = this.state.selectedForum.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedForum, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedForum.slice(1));
    } else if (selectedIndex === this.state.selectedForum.length - 1) {
      newSelected = newSelected.concat(this.state.selectedForum.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedForum.slice(0, selectedIndex),
        this.state.selectedForum.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedForum: newSelected });
  };

  handleYesBtn = () => {
    this.handleDialog();
    this.deleteRestForum();
  }

  handleSearchValChange = (value: string) => {
    this.setState({ searchVal: value, forumsCurrentPage: 1, selectedForum: [] })
  }

  //search debouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.handleSearchValChange, 1000)

  handleRestaurantSelection = (event: React.ChangeEvent<{value:any}>) => {
    const { history } = this.props

    if (event.target.value !== "0" && event.target.value !== "00") {
      this.setState({
        currentRestId: event.target.value,
      })
      this.resetFilters()

      history.push(
        configJSON.forumEndPoint + `${event.target.value}`
      )
    }
  }

  handleSelectAllClick = (event: any) => {
    let newSelected = []
    if (event.target.checked) {
      newSelected = this.state.forumsList?.map((forum: { id: number }) => forum.id) || []
    }
    this.setState({ selectedForum: newSelected });
  }
  
  handleChangeTablePage = (value: number) => {
    this.setState({ forumsCurrentPage: value, selectedForum: [] });
  }

  resettingPagination = () => {
    this.setState({
      forumsCurrentPage: 1,
    })
  }

  handleIncreasePageView = () => {
    if (this.state.forumsPerPage < 25) {
      this.setState({ forumsPerPage: this.state.forumsPerPage + 5, selectedForum: [] });
      this.resettingPagination()
    }
  }

  handleDecreasePageView = () => {
    if (this.state.forumsPerPage > 5) {
      this.setState({ forumsPerPage: this.state.forumsPerPage - 5, selectedForum: [] });
      this.resettingPagination()
    }
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      search: event.target.value
    })

    this.SearchDebounceUpdate(event.target.value)
  }

  resetFilters = () => {
    this.setState({
      forumsCurrentPage: 1,
      forumsPerPage: 5,
      search: "",
      searchVal: "",
      selectedForum: []
    })
  }

  handleEditForumHeading = (
    newVal: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const words = newVal.split(" ")
    if (words.length < 26) setFieldValue("heading", newVal)
  }
  
  handleCommentReply = (
    reviewId: string,
    reply: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    if (!!reply) {
      this.addReply(reply, reviewId, configJSON.createText)
      this.setState({
        replyId: "",
      })
      setFieldValue(configJSON.replyText, "")
    }
  }

  handleEditCommentReply = (
    editedReply: string,
    replyId: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    if (editedReply) {
      this.addReply(
        editedReply,
        replyId,
        configJSON.editText
      );
      setFieldValue(configJSON.replyEditText, "");
      this.setState({replyEditId:''})
    }
  }

  getCount = (val: number) => {
    return val || 0
  }

  getHeading = () => {
    const isEdit = this.state.productMode === configJSON.editText
    return !isEdit ? configJSON.createForumText : configJSON.editForumText
  }

  handleReviewReply = (id: any, setFieldValue: any) => {
    this.setState({
      replyId: id
    })
    setFieldValue(configJSON.replyText, "")
  }

  handleReplyEditId = (id: any, setFieldValue: any) => {
    this.setState({
      replyEditId: id
    })
  }
  // Customizable Area End
}