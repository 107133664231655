// Customizable Area Start
import React from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import LPDetailsCard from "./LPDetailsCard.web"
import { connect } from "react-redux"
import LPCustomCarousel from "./LPCustomCarousel.web"

interface IFarmCard {
    id: number
    type: string
    attributes: {
        id: number
        name: string
        description: string
        image: {
            id: number
            filename: string
            url: string
        }
        favourite: boolean
        rating: number
    }
}

interface ILPLikedFarms {
    LPLikedFarmDesc: string
    LPLikedFarms: IFarmCard[]
}

const CAROUSEL_BREAKPOINTS = [
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        },
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        },
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        },
    }
]

const LPLikedFarms = ({ LPLikedFarmDesc, LPLikedFarms }: ILPLikedFarms) => {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box className="landing_page_section_details">
                        <h1 className="h1">Farms which you liked</h1>
                        <Typography align="center" className="grey_text">
                            {LPLikedFarmDesc}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <LPCustomCarousel
                type="farm"
                slidesToShow={4}
                emptyMessage="No favourite farms found"
                sliderProps={{ responsive: CAROUSEL_BREAKPOINTS }}
            >
                {LPLikedFarms.map((farmDetails: IFarmCard) => {
                    const {
                        id,
                        attributes: { id: farmId, name, image, description, rating },
                    } = farmDetails
                    return (
                        <LPDetailsCard
                            key={id}
                            id={farmId}
                            name={name}
                            description={description}
                            image={image?.url}
                            rating={rating}
                            type="farm"
                        />
                    )
                })}
            </LPCustomCarousel>
        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        LPLikedFarmDesc: state.cms.LPLikedFarmDesc,
        LPLikedFarms: state.cms.LPLikedFarms,
    }
}

export default connect(mapStateToProps, {})(LPLikedFarms)
// Customizable Area End
