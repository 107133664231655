// Customizable Area Start
import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Paper, Typography } from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import { ActionsTask } from "./ActionsTask.web";
import { emptyCheckbox, greenTick } from "./assets";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { BoolOptionTask } from "./BoolOptionTask.web";
import { LongAnswerTask } from "./LongAnswerTask.web";
import { MultipleOptionTask } from "./MultipleOptionTask.web";
import { ShortAnsTask } from "./ShortAnsTask.web";
import { SingleOptionTask } from "./SingleOptionTask.web";
import TaskController, {Props} from "./TaskController.web";
import LessonCompletedModal from './LessonCompletedModal.web';
// Customizable Area End

// Customizable Area Start
export class LessonTask extends TaskController{
  constructor(props:Props){
    super(props);
  }


  handleModalClose = () => {
    this.setState({isModalOpen : false});
  };
  handleButtonClick = () => {
    this.setState({isModalOpen : true});
  };


  handleChange = (panel: any, id: any) => (event: any, isExpanded: any) => {
    this.setState({expanded : isExpanded ? panel : ""});
    this.setState({taskId:id})
  };

  filterTaskResults = (taskResults: any, accountId: any) => {
    return taskResults?.filter((item: any) => item.attributes?.account_id === Number(accountId));
  };

  isTaskResultChecked = (i: any, accountId: any) => {
    const taskResults = i?.attributes?.task_results;
    return taskResults?.length > 0 && this.filterTaskResults(taskResults, accountId).length === 1;
  };

  render(){
    const {taskId,expanded,isModalOpen,taskResult,taskData, accountId} = this.state
  return (
    <>
    {taskData.length > 0 &&
    <>
      <Grid container style={{ marginTop: '3rem', backgroundColor: 'rgba(20, 118, 32, 0.04)' }}>
          <Grid item xs={12} style={{ margin: '0.2rem' }}>
            <h3 className="exercise-title">Exercise:</h3>
          </Grid>
      </Grid>
      <Grid container style={{backgroundColor: 'rgba(20, 118, 32, 0.04)'}}>
        <Grid xs={12} >

          <div className="margin-2">
            <Typography className="taskCount" variant="h4" >
              {taskResult.length}/{taskData.length} Tasks Completed</Typography>
          </div>

          {taskData.map((i: any, index: any) => {
            let x = i.attributes.options;
            const taskTitleClass = expanded === index 
            console.log(taskTitleClass)
            return (
              <div key={i} className="task-wrapper">
                <div key={i} className="customAccordian6">
                  
                  <Paper variant="outlined" className="paper" style={{ border: 'none' }}>
                    <Accordion
                      data-test-id="taskAccord"
                      expanded={expanded === index}
                      onChange={this.handleChange(index, i.id)}

                    >
                      <AccordionSummary
                        expandIcon={<ArrowForwardIosIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="taskAccordion"
                        style={{ backgroundColor: '#fff' }}

                      >
                        {this.isTaskResultChecked(i, accountId) ?
                          <Grid className="taskTitleWrapper">
                            <Grid>
                              <img className="greenTick" src={greenTick} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'block'}}>
                            <Typography variant="body1" component="div" >
                              <div  className={taskTitleClass ? "expandedTitle" : "taskTitle" } dangerouslySetInnerHTML={{ __html:  i.attributes.title }} />
                            </Typography>
                            </Grid>
                          </Grid>
                          :
                          <Grid className="taskTitleWrapper">
                            <Grid>
                              <img className="greenTick" src={emptyCheckbox} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'block'}}>
                              <Typography variant="body1" component="div" >
                                <div  className={taskTitleClass ? "expandedTitle" : "taskTitle" } dangerouslySetInnerHTML={{ __html:  i.attributes.title }} />
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      </AccordionSummary>
                      {i.attributes.task_type === 'short_answer' &&
                        
                          (i.id === taskId &&
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid className="ques" container>
                                  <ShortAnsTask dataTestId="shortAnsType"
                                  data={i} shortAnswer={this.handleShortAnswer} handleButtonClick={this.handleButtonClick}
                                  loader={this.state.loader} handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}/>
                                </Grid>
                              </AccordionDetails>
                          )
                        
                      }

                      {i.attributes?.task_type === 'long_answer' &&
                        
                          (i.id === taskId &&
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid container className="ques">
                                  <LongAnswerTask loader={this.state.loader} data={i} longAnswer={this.handleLongAnswer} handleButtonClick={this.handleButtonClick}
                                   handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}/>
                                </Grid>
                              </AccordionDetails>
                          )
                        
                      }

                      {i.attributes?.task_type === 'bool_answer' &&
                      
                          (i.id === taskId &&
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid container className="ques">
                                  <BoolOptionTask data={i} boolAnswer={this.handleBoolAns} loader={this.state.loader}
                                  handleButtonClick={this.handleButtonClick} handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}/>
                                </Grid>
                              </AccordionDetails>
                          )
                        
                      }

                      {
                        i.attributes?.task_type === 'action' &&
                          (i.id === taskId &&
                            <>
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid container className="ques">
                                  <ActionsTask option={x} data={i} accountId={accountId} taskAns={this.handleTaskAns}
                                  loader={this.state.loader} handleButtonClick={this.handleButtonClick} handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}
                                  />
                                </Grid>
                              </AccordionDetails>
                            </>
                          )
                      }

                      {
                        i.attributes?.task_type === 'single_option' &&
                          (i.id === taskId &&
                            <>
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid container className="ques">
                                  <SingleOptionTask loader={this.state.loader} data={i} option={x} accountId={accountId} taskAns={this.handleTaskAns}
                                  handleButtonClick={this.handleButtonClick} handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}/>
                                </Grid>
                              </AccordionDetails>
                            </>
                          )
                      }
                      {
                        i.attributes?.task_type === 'options' &&
                          (i.id === taskId &&
                            <>
                              <AccordionDetails style={{ backgroundColor: '#fff' }}>
                                <Grid container className="ques">
                                  <MultipleOptionTask loader={this.state.loader} data={i} option={x} accountId={accountId} taskAns={this.handleTaskAns}
                                  handleButtonClick={this.handleButtonClick} handleModalClose={this.handleModalClose} isModalOpen={isModalOpen}/>
                                </Grid>
                              </AccordionDetails>
                            </>
                          )
                      }
                    </Accordion>
                  </Paper>
                  </div>
                </div>
            )
          })}
        </Grid>
      </Grid>
      </>
    }  
      <Grid container>
        <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          {this.props.isLessonComplete !== true && this.state.lessonStat === false?
          <div className="circleBtn flex" >
            <CustomButton className='btn-2' onClick={this.handleComplete} label="Mark As Complete"/>
          </div>
          : 
          <div className="completedBtn" >
            <h5>Completed</h5>
          </div>
          }
        </Grid>
      </Grid>
      <Dialog
            open={this.state.openDialog}
            maxWidth="xs"
            classes={{
              paper: "checkout_order_dialog_paper"
            }}>
            <LessonCompletedModal />
      </Dialog>
      
    </>
  )
  }
}
// Customizable Area End

// Customizable Area Start
const webStyles = {
  taskContainer: {
    margin: '2rem 2rem', border: '1px solid white', padding: '1rem'
    , backgroundColor: 'white', display: 'flex', alignItems: 'center'
  }
}
// Customizable Area End