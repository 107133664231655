// Customizable Area Start
import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminRegisterstepper.scss";
import { withRouter } from "react-router-dom";
import CustomCheckBox from "./AdminCheckBox.web";
import {
  correct_icon,
  download_icon,
  FileUploadIcon,
  pdf_icon,
} from "../../../components/src/assets";
import { Modal_Picture } from "./assets";
import CustomStepper from "../../../components/src/CustomStepper.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import AdminRestaurantAddDetailsController, {
  Props,
} from "./AdminRestaurantAddDetailsController.web";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomFileUpload from "../../../components/src/CustomFileUpload.web";
import { commonCmptStyles } from "./dropDownCss";
import { ErrorMessage, Form, Formik } from "formik";
import OtpInput from "react-otp-input";
import CustomGoogleMap from "../../../components/src/CustomGoogleMap.web";
import CustomGooglePlaceAutoComplete from "../../../components/src/CustomGooglePlaceAutoComplete.web";
import CustomLocationErrorMessage from "../../../components/src/CustomLocationErrorMessage.web";
import RewardAcceptanceSelect from "./RewardAcceptanceSelect/RewardAcceptanceSelect.web";
import AdminTransactionHistoryButton from "./AdminTransactionHistory/AdminTransactionHistoryButton.web";
// Customizable Area End

class AdminRestaurantRegisterStepper extends AdminRestaurantAddDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start
  otpWrapper=(isOtpVerifying:any,values:any,setFieldValue:any,otpId:any,isOtpIncorrect:any)=>{
    if(isOtpVerifying){
    return (
      <Box style={{ width: "100%" }} data-testid="otpVerifying">
        <CircularProgress
          size={25}
          style={{ color: "#20a258" }}
        />
      </Box>
    )
    }else{
       return (
        <div className="phoneDetailsBlock phoneDetailsRightBlock">
        <div className="otpContainer customOtpContainer">
          <div className="otpForm">
            <p className="otpText">Enter Otp</p>
            <OtpInput
            data-testid="otpInput"
              value={values.owner_otp}
                onChange={(e: any) => {
                setFieldValue("owner_otp", e);
                if(e.length==4){
                  this.postVerifyOtp(e)
                }
              }}
              numInputs={4}
              separator={<span> </span>}
              isDisabled={!otpId}
              disabledStyle={"disabledInput"}
              hasErrored={isOtpIncorrect}
              errorStyle={"errorInput"}
            />
            <span className="errorMsg">
              <ErrorMessage name="owner_otp" />
            </span>
          </div>
        </div>
      </div>
       )
    }
  }
// Customizable Area End
  render() {
    // Customizable Area Start
    const { classes} = this.props
    const {
      restStepperIndex,
      isOtpVerified,
      mapCenter,
      isOtpSending,
      isOtpVerifying,
      otpId,
      isOtpIncorrect,
      modalOpen,
      createResLoader
    } = this.state    


    return (
      <>
        <Formik
        data-testid="formik"
          enableReinitialize
          initialValues={{
            name: "",
            org_address: "",
            phoneNumber: "",
            ownerName: "",
            ownerEmail: "",
            ownerNumber: "",
            sameAddress: false,
            otp: "",
            owner_otp: "",
            restaurant_type: "",
            outlet: [],
            cuisines: [],
            days: [],
            opensAt: "",
            closesAt: "",
            latitude: "",
            longitude: "",
            description: "",
            bankName: "",
            accountNumber: "",
            ibanNumber: "",
            documents: [],
            acceptTerms: false,
            bannerImages: [],
            purchase_limit_percentage: 0
          }}
          validationSchema={this.formSchema()}
          onSubmit={this.handleResRegistration}
        >
          {({
              values,
              setFieldValue,
              handleChange,
              touched,
              errors,
            }) => {

            return (
              <div className="regStepperContainer farmDetailsTabPanel">
                <div className="regStepperHeader">
                  <div className="header">
                    <h4 data-testid="registerheading">Register Restaurant</h4>
                  </div>
                  <div className="stepperContainer">
                    <CustomStepper
                    data-testid="stepper"
                      changeStepper={this.handleChangeStepper}
                      step={restStepperIndex}
                    />
                  </div>
                </div>

                <Form noValidate autoComplete="off">

                  {restStepperIndex === 0 && (
                    <div className="regInfoContainer">
                      <div className="regAdress">
                        <h4>Register Information</h4>
                        <div className="regRestInfoForm">
                          <h6>Restaurant Name</h6>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  id="name"
                                  value={values.name}
                                  variant="standard"
                                  placeholder="Enter Restaurant Name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e:any)=>{
                                    setFieldValue("name",e.target.value)
                                  }}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="name" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="regRestInfoForm">
                          <h6>Restaurant Complete Address</h6>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="org_address"
                                onChange={(e:any)=>{
                                  setFieldValue("org_address",e.target.value)
                                }}
                                value={values.org_address}
                                variant="standard"
                                placeholder="Enter Restaurant Address"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="org_address" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="regRestInfoForm">
                          <h6>
                            Please place the pin accurately at your outlet's
                            location on the map
                          </h6>
                          <p>
                            {" "}
                            This will help your customers to locate your store
                          </p>
                          <CustomLocationErrorMessage
                            data-testid="mapError"
                            isLatError={errors.latitude?.toString()}
                            isLatTouched={!!touched.latitude}
                            isLngError={errors.longitude?.toString()}
                            isLngTouched={!!touched.longitude}
                          />

                          <CustomGooglePlaceAutoComplete
                          data-testid="autocomplete"
                            placeholder="Enter your restaurant locality"
                            lat={Number(mapCenter.lat)}
                            lng={Number(mapCenter.lng)}
                            detectPlace={this.detectPlace}
                            setFieldValue={setFieldValue}
                            handlePlaceSelect={this.handlePlacesAutocompleteSelect}
                          />
                        </div>
                      </div>

                      <div className="iframeContainer">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <CustomGoogleMap
                              data-testid="mapContainer"
                              lat={mapCenter.lat}
                              lng={mapCenter.lng}
                              handleDragEnd={(lat, lng) => this.handleChangeLatLng(lat, lng, setFieldValue)}
                            />
                          </div>
                        </div>
                        <div className="coOrdinateContainer">
                          <h6 className="ordinateHeader">
                            <span>or directly enter the co-ordinates</span>
                          </h6>
                          <div className="coOrdinate-form">
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  value={values.latitude}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.handleChangeLat(e, values.longitude, setFieldValue)
                                  }
                                  variant="standard"
                                  placeholder="Latitude"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  value={values.longitude}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.handleChangeLng(e, values.latitude, setFieldValue)
                                  }
                                  variant="standard"
                                  placeholder="Longitude"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h6>Restaurant Description</h6>
                      <TextField
                        id="description"
                        className="textFieldMultiline"
                        placeholder="Enter Details"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={values.description}
                        onChange={(e:any)=>{
                          setFieldValue("description",e.target.value)
                        }}
                        style={{ marginTop: 8, borderRadius: "8px !important" }}
                      />
                      <span className="errorMsg">
                        <ErrorMessage name="description" />
                      </span>

                      <div className="restContactNumber">
                        <div className="contact">
                          <div className="conactNumber">
                            <h6>Contact number at restaurant</h6>
                            <p>
                              Your customers will call on this number for
                              general enquiries
                            </p>
                            <div className="conactNumberForm">
                              <div className="outlineTextfield boxRadiusBorder numberField">
                                <div className="customTextfield">
                                  <TextField
                                    id="phoneNumber"
                                    value={values.phoneNumber}
                                    variant="standard"
                                    placeholder="Mobile Number at restaurant"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton aria-label="toggle password visibility">
                                            <span className="countryCodeIcon">
                                              <p>+ 1</p>
                                            </span>
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                      this.handleOrgNumberChange(e, values.sameAddress, setFieldValue)
                                    }
                                  />
                                  <span className="errorMsg">
                                    <ErrorMessage name="phoneNumber" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="ownerDetailsContainer">
                        <h4>Register Owner Details</h4>
                        <p>Restaurant owner details</p>
                        <div className="nameEmails ownerDetails">
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="ownerName"
                                value={values.ownerName}
                                variant="standard"
                                placeholder="Owner Name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e:any)=>{
                                  setFieldValue("ownerName",e.target.value)
                                }}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="ownerName" />
                              </span>
                            </div>
                          </div>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield contactRestInput">
                              <TextField
                                id="ownerNumber"
                                value={values.ownerNumber}
                                variant="standard"
                                placeholder="Owner's Mobile Number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton aria-label="toggle password visibility">
                                        <span className="countryCodeIcon">
                                          <p className="inputAdornment">+ 1</p>
                                        </span>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                  this.handleOwnerNumberChange(e, setFieldValue)
                                }
                                disabled={values.sameAddress}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="ownerNumber" />
                              </span>
                            </div>
                          </div>
                          <div className="restOwnerCheckBox customCheckbox" >
                            <input
                              type="checkbox"
                              id="is_same_number"
                              checked={values.sameAddress}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                this.toggleIsSameNumber(e, values.phoneNumber, setFieldValue)
                              }
                            />
                            <label
                              className="is_same_number"
                              htmlFor="is_same_number"
                            >
                              Same as Restaurant
                            </label>
                          </div>
                        </div>
                        <div className="phoneDetails" style={{ margin: "15px 0" }}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="ownerEmail"
                                value={values.ownerEmail}
                                variant="standard"
                                placeholder="Owner Email address"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e: any) => this.handleRegistrationEmailChange(e.target.value, setFieldValue)}
                               
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="ownerEmail" />
                              </span>
                            </div>
                          </div>
                          <div className="greenContained restDetailsContact">
                            <CustomButton
                              color="secondary"
                              data-testid="otpsend"
                              isLoading={isOtpSending}
                              label={"Verify"}
                              disabled={
                                !values.ownerEmail || isOtpVerified || isOtpVerifying
                              }
                              onClick={() => this.postSendOtp(values.ownerEmail)}
                            />
                          </div>
                          {isOtpVerified ? (
                            <Box
                              className="web_input_field"
                              data-testid="verified"
                              style={{ width: "100%" }}
                            >
                              <p className="OTP_Success_Msg">
                                <CheckCircleOutlineOutlined /> Verified
                              </p>
                            </Box>
                          ) : this.otpWrapper(isOtpVerifying,values,setFieldValue,otpId,isOtpIncorrect)}
                        </div>
                      </div>

                      <div>
                        <hr />
                        <RewardAcceptanceSelect
                          handleValueChange={(value: number) => setFieldValue("purchase_limit_percentage", value)}
                          name="purchase_limit_percentage"
                          value={values.purchase_limit_percentage}
                        />
                        <AdminTransactionHistoryButton />
                      </div>
                      {/* register information end */}
                    </div>
                  )}

                  {restStepperIndex === 1 && (
                    <div className="restTypeTimingsContainer" data-testid="secondTab">
                      <div className="restTypeCommonHeader">
                        <h4>Restaurant Type & Timings</h4>
                        <p>
                          Select most relevant category for your restaurant type
                        </p>
                      </div>
                      <div className="radioBoxContainer">
                        <div className="customRadio">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="quiz"
                              name="radio"
                              data-testid="radioBtn"
                              value={values.restaurant_type}
                              onChange={(e) => {
                                setFieldValue(
                                  "restaurant_type",
                                  e.target.value
                                );
                              }}
                            >
                              <FormControlLabel
                                value="both_delivery_and_dine_in_available"
                                control={<Radio />}
                                label="Both, delivery and dine-in  available"
                              />
                              <FormControlLabel
                                value="dine_in_only"
                                control={<Radio />}
                                label="Dine-in only"
                              />
                              <FormControlLabel
                                value="delivery_only"
                                control={<Radio />}
                                label="Delivery only"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <span className="errorMsg">
                          <ErrorMessage name="restaurant_type" />
                        </span>
                      </div>
                      <div className="outletType">
                        <div className="block">
                          <h6>
                            Select options which best describe your outlet
                          </h6>
                          <Grid container>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Bakery"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Beverage Shop"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Butcher Shop"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Cafe"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Dessert Parlour"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Lounge"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Sweet Shop"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Pub"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Microbrewery"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>

                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Food Truck"
                                type="outlet"
                                outlet={values.outlet}
                                setValue={setFieldValue}
                              />
                            </Grid>
                          </Grid>
                          <span className="errorMsg">
                            <ErrorMessage name="outlet" />
                          </span>
                        </div>
                        <div className="block">
                          <h6>Type of cuisines</h6>
                          <Grid container xs={12}>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Bakery"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Beverage Shop"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Butcher Shop"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Cafe"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Dessert Parlour"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <CustomCheckBox
                                name="Lounge"
                                type="cuisines"
                                outlet={values.cuisines}
                                setValue={setFieldValue}
                              />
                            </Grid>
                          </Grid>
                          <span className="errorMsg">
                            <ErrorMessage name="cuisines" />
                          </span>
                        </div>
                      </div>
                      <div className="restOperationTime">
                        <div className="restTypeCommonHeader">
                          <h4>Restaurant operational hours</h4>
                          <p>Mark restaurant opening and closing hours</p>
                        </div>

                        <div className="restOpenCLose">
                          <div className="timingsFrom">
                            <span className="outlineTextfield boxRadiusBorder">
                              <h6>Opens at</h6>
                              <div className="customTextfield">
                                <TextField
                                  id="opensAt"
                                  type="time"
                                  variant="standard"
                                  placeholder=""
                                  value={values.opensAt}
                                  onChange={handleChange}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="opensAt" />
                                </span>
                              </div>
                            </span>
                            <span>To</span>
                            <span className="outlineTextfield boxRadiusBorder">
                              <h6>Closes at</h6>
                              <div className="customTextfield">
                                <TextField
                                  id="closesAt"
                                  type="time"
                                  variant="standard"
                                  placeholder=""
                                  value={values.closesAt}
                                  onChange={handleChange}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="closesAt" />
                                </span>
                              </div>
                            </span>
                          </div>
                          <div className="openDaysCheckBox">
                            <h6>Mark Open Days</h6>
                            <Grid container xs={12}>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Monday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Tuesday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Wednesday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Thursday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Friday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Saturday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <div className="customLabelCheckbox">
                                  <CustomCheckBox
                                    name="Sunday"
                                    type="days"
                                    outlet={values.days}
                                    setValue={setFieldValue}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <span className="errorMsg">
                              <ErrorMessage name="days" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="bannerImgContainer">
                        <h6>Banner Images</h6>
                        <div className="fileUploadContainer" data-testid="fileContainer">
                          {values.bannerImages.map(
                            (banner: any) => {
                              return (
                                <div
                                  className="headerImageBlock"
                                  key={banner[0].lastModified}
                                  data-testid="imagesId"
                                >
                                  <div className="headerImage">
                                    <img
                                      src={
                                        banner[0] &&
                                        (
                                          window.URL || window.webkitURL
                                        ).createObjectURL(banner[0])
                                      }
                                      className="farmImg"
                                      alt="image"
                                    />
                                    <input
                                      type="checkbox"
                                      id={banner[0].lastModified}
                                      className="correctIconInput"
                                      defaultChecked={false}
                                      onChange={(e: any) => {
                                        !e.target.checked
                                          ? setFieldValue("bannerImages", [
                                            ...values.bannerImages,
                                            banner,
                                          ])
                                          : setFieldValue(
                                            "bannerImages",
                                            values.bannerImages.filter(
                                              (x: any) => x != banner
                                            )
                                          );
                                      }}
                                    />
                                    <label
                                      htmlFor={banner[0].lastModified}
                                      className="correctIconLabel"
                                    >
                                      <img
                                        src={correct_icon}
                                        className="correct_icon"
                                        alt="Correct Icon"
                                      />
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          )}

                          <div className="FileUpload">
                            <div className="customFileUpload">
                              <input
                                type="file"
                                id="fileUpload"
                                accept="image/png, image/jpeg, image/jpg"
                                className="fileUploadInput"
                                onChange={(e: any) =>
                                  this.handleFileUploadCreate(e.currentTarget.files, values, setFieldValue)
                                }
                              />
                              <label
                                htmlFor="fileUpload"
                                className="fileUploadLabel"
                              >
                                <img src={FileUploadIcon} alt="carrot" />
                                <span>Add Photos</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <span className="errorMsg">
                          <ErrorMessage name="bannerImages" />
                        </span>
                      </div>
                    </div>
                  )}

                  {restStepperIndex === 2 && (
                    <div className="bankDetaisCOntainer" data-testid="thirdTab">
                      <h4>Bank Details</h4>
                      <div className="bankDetalsFormContainer">
                        <div className="formContainer">
                          <h6>Select Bank</h6>
                          <div className="outlineSelect">
                            {/* <CustomSelectField /> */}
                            <div className="customSelect dishCategorySelect">
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="bankName"
                                  className="menu-customize"
                                  value={values.bankName}
                                  onChange={handleChange("bankName")}
                                  displayEmpty
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: {
                                      paper: classes.categoryDropDown,
                                    },
                                  }}
                                >
                                  <MenuItem value="">Select Bank</MenuItem>
                                  <MenuItem value="JPMorgan Chase & Co.">
                                    JPMorgan Chase & Co.
                                  </MenuItem>
                                  <MenuItem value="Bank of America Corp.">
                                    Bank of America Corp.
                                  </MenuItem>
                                  <MenuItem value="Wells Fargo & Co.">
                                    Wells Fargo & Co.
                                  </MenuItem>
                                  <MenuItem value="Citigroup Inc.">
                                    Citigroup Inc.
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <span className="errorMsg">
                              <ErrorMessage name="bankName" />
                            </span>
                          </div>
                        </div>
                        <div className="formContainer">
                          <h6>Account Number</h6>
                          <div className="outlineTextfield boxRadiusBorder">
                            <CustomTextfield
                              id="accountNumber"
                              placeholder="Enter Account Number"
                              value={values.accountNumber}
                              onChange={handleChange("accountNumber")}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="accountNumber" />
                            </span>
                          </div>
                        </div>
                        <div className="formContainer">
                          <h6>IBAN</h6>
                          <div className="outlineTextfield boxRadiusBorder">
                            <CustomTextfield
                              id="ibanNumber"
                              placeholder="Enter IBAN Number"
                              value={values.ibanNumber}
                              onChange={handleChange("ibanNumber")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="bannerImgContainer">
                        <h4>Registration Documents</h4>
                        <div className="fileUploadContainer pdfFileUploadContainer">
                          {[...values.documents].map((doc: any) => (
                            <div
                              className="headerImageBlock headerPdfFileBlock"
                              key={doc.lastModified}
                              data-testid="documentsId"
                            >
                              <div className="headerImage">
                                <div className="pdfFileBlock">
                                  <div className="farmImg">
                                    <img
                                      src={pdf_icon}
                                      className="pdf_icon"
                                      alt="Correct Icon"
                                    />
                                    <button
                                      type="button"
                                      className="close_icon remove_icon"
                                      onClick={() => {
                                        const updatedDocs = [
                                          ...values.documents,
                                        ].filter(
                                          (item: any) =>
                                            item.lastModified !==
                                            doc.lastModified
                                        );
                                        setFieldValue("documents", updatedDocs);
                                      }}
                                    ></button>
                                    {/* Show download button only for uploaded files */}
                                    {!doc.lastModified && (
                                      <button
                                        type="button"
                                        className="download_icon"
                                      >
                                        <img
                                          src={download_icon}
                                          alt="Correct Icon"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <h6>{doc.name}</h6>
                            </div>
                          ))}
                          <div className="FileUpload">
                            <CustomFileUpload
                              label="Add Documents"
                              id="documents"
                              allowMultipleFiles={true}
                              handleChange={(event: any) => {
                                setFieldValue(
                                  "documents",
                                  [...values.documents, ...event.currentTarget.files]
                                  // Array.from(event.currentTarget.files)
                                );
                              }}
                              handleClick={(event: any) => {
                                event.target.value = null;
                              }}
                            />
                          </div>
                        </div>
                        <span className="errorMsg">
                          <ErrorMessage name="documents" />
                          {errors?.documents && <br />}
                        </span>
                        <span className="uploadSubtitle">
                          Ex. Restaurant registration Documents, etc...
                        </span>
                      </div>

                      <div className="checkBoxContainer checkBoxRegContainer">
                        <CustomCheckboxField
                          id="acceptTerms"
                          onChange={handleChange}
                          checked={values.acceptTerms}
                        />
                        <label htmlFor="acceptTerms">
                          By clicking Finish you are agreeing to our
                          <a
                            href="#"
                            style={{ color: "#20a258", fontWeight: "bold" }}
                          >
                            &nbsp;terms and conditions.
                          </a>
                        </label>
                      </div>
                      <span className="errorMsg">
                        <ErrorMessage name="acceptTerms" />
                      </span>
                    </div>
                  )}

                  <Grid container spacing={2} className="regBtnContainer btnContainer">
                    {restStepperIndex !== 0 && (
                      <Grid item xs={6} sm={4} md={3} lg={2}>
                      <span
                        className="grayButton"
                      >
                        <CustomButton
                          className="custom_action_btn"
                          color="secondary"
                          label="Back"
                          data-testid="back"
                          onClick={this.handleChangePrevStepper}
                          disabled={createResLoader}
                        />
                      </span>
                      </Grid>
                    )}
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                    <span className="greenContained">
                      <CustomButton
                        data-testid="next-button"
                        type="submit"
                        className="custom_action_btn"
                        label={
                          restStepperIndex < 2 ? "Next" : "Finish"
                        }
                        isLoading={createResLoader}
                        disabled={createResLoader}
                      />
                    </span>
                    </Grid>
                  </Grid>

                </Form>
              </div>
            );
          }}
        </Formik>

        <Modal
          open={modalOpen}
          onClose={this.handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalBox">
            <div className="modalImgContainer">
              <img
                src={Modal_Picture}
                alt="Registration done"
                height="100%"
                width="100%"
              />
            </div>
            <Typography className="modalHeading" component="h2" variant="h6">
              Registration Successful
            </Typography>
            <Typography id="modal-modal-description" className="content">
              Your registration is successful you can create and upload dishes
              once our team verify your details
            </Typography>
            <div
            data-testid="okBtn"
              className="greenContained"
              onClick={() => {
                this.handleCloseModal();
                this.handleRegisterRest();
              }}
            >
              <CustomButton color="secondary" label="Ok" />
            </div>
          </Box>
        </Modal>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantRegisterStepper}
// @ts-ignore
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminRestaurantRegisterStepper)
);
// Customizable Area End
