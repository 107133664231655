// import * as React from "react";
import React, { useState } from "react";
// custom components
import {
  Box,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import "../../web/src/assets/css/content/button.scss";
import "../../web/src/assets/css/content/tab.scss";
import AdminRestaurantAddDetailsWeb from "../../blocks/catalogue/src/AdminRestaurantAddDetails.web";
import AdminRestaurantRegisterInfoWeb from "../../blocks/catalogue/src/AdminRestaurantRegisterInfo.web";
import AdminRestaurantAvailability from "../../blocks/catalogue/src/AdminRestauranAvailability.web";
import AdminRestaurantAccountDetails from "../../blocks/catalogue/src/AdminRestaurantAccountDetails.web";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomRestDetailsTab = (props: any) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  
  return (
    <div className="customTab restDetailTab">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#20a258",
            bottom: "2px",
            height: "5px",
          }
        }}
      >
        <Tab label=" Restaurant Details " {...a11yProps(0)} />
        <Tab label=" Delivery Time Setting" {...a11yProps(1)} />
        <Tab label=" Availability " {...a11yProps(2)} />
        {/* <Tab label="Account Details" {...a11yProps(3)} /> */}
      </Tabs>
      <TabPanel value={value} index={0} className="tabPanel">
        <AdminRestaurantAddDetailsWeb 
        //@ts-ignore
        handleTabChange={handleChange}/>
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <AdminRestaurantRegisterInfoWeb 
        //@ts-ignore
        handleTabChange={handleChange}/>
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <AdminRestaurantAvailability/>
      </TabPanel>
      

    </div>
  );
};
export default CustomRestDetailsTab;
