// Customizable Area Start
import { IBlock } from "./../../../framework/src/IBlock"
import { Message } from "./../../../framework/src/Message"
import { BlockComponent } from "./../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "./../../../framework/src/Messages/MessageEnum"
import { runEngine } from "./../../../framework/src/RunEngine"
import StorageProvider from "../../../framework/src/StorageProvider"
import { toast } from "react-toastify"
export const configJSON = require("./config")
import { RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../components/src/utility/app-routing"
import useLogout from "../../../components/src/utility/logout"
// Customizable Area End

// Customizable Area Start
export interface Props extends RouteComponentProps {
    history: any
    id: string
    courseId: number
    image: string
    name: string
    sellerName: string
    status: string
    handleRemoveItemLocal: (wishlistId: string) => void
}
// Customizable Area End

interface S {
    // Customizable Area Start
    isRemoving: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class WishlistCourseCardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    removeFavouriteApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            isRemoving: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    handleRemoveFromFav = async () => {
        const { courseId } = this.props
        const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
        if (!isLoggedIn) {
            toast.error(configJSON.removeFromFavErrorText)
            await useLogout()
            return
        }

        const details = {
            favouriteable_id: courseId,
            favouriteable_type: configJSON.CARTABLE_TYPE_COURSE
        }
        this.removeFromFavouriteApi(details)
    }

    handleBuyCourse = (status:string) => {
        const { history, courseId } = this.props
        if(status === configJSON.activeText){
        history.push(`${AppRoutings.CourseDetails}${courseId}`)
        }
        else toast.error(configJSON.courseNotAvailableErrorMsg)
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.removeFavouriteApiCallId) {
                    const { id, handleRemoveItemLocal } = this.props
                    handleRemoveItemLocal(id)
                    toast.success(configJSON.removedFromWishlistErrorMsg)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    removeFromFavouriteApi = async (body: object) => {
        this.setState({ isRemoving: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.removeFavouriteApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.toggleFavouriteApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPatchMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    // Customizable Area End
}
