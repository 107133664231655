// Customizable Area Start
import React, { useState } from "react"
import { Box, Button, Grid, InputBase } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

interface IRLPTopBanner extends RouteComponentProps {
    RLPHeading: string
    footerDetails: {
        facebook: string
        instagram: string
        twitter: string
        phone_number: string
        email: string
        subscribe_now_description: string
        footer_description: string
    } | null
}

const RLPTopBanner = ({ history, RLPHeading, footerDetails }: IRLPTopBanner) => {
    const [mapViewSearchValue, setMapViewSearchValue] = useState("")

    const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMapViewSearchValue(e.target.value)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && !!mapViewSearchValue) handleMapViewSearch()
    }

    const handleMapViewSearch = () => {
        if (mapViewSearchValue) {
            history.push({
                pathname: AppRoutings.RestaurantMapView,
                state: mapViewSearchValue
            })
        }
    }

    return (
        <Box className="restaurant_landing_page_banner">
            <Grid container spacing={3}>
                <Grid item container xs={12} sm={10} md={6}>
                    <Box className="restaurant_landing_page_banner_content">
                        <h1 className="restaurant_landing_page_banner_two">
                            {RLPHeading}
                        </h1>
                    </Box>
                    <Box className="Food_resto_input_btn_wrappper">
                        <InputBase
                            placeholder="Food and Restaurants..."
                            className="Food_resto_input"
                            value={mapViewSearchValue}
                            onChange={handleSearchValueChange}
                            onKeyDown={handleKeyDown}
                        />
                        <Button
                            disabled={!mapViewSearchValue}
                            className="Food_resto_btn"
                            classes={{ label: "restaurant_submit_green_btn_color" }}
                            onClick={handleMapViewSearch}
                        >
                            Search
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        RLPHeading: state.cms.RLPHeading,
        footerDetails: state.cms.FooterDescription
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(RLPTopBanner)
)
// Customizable Area End
