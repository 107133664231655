// Customizable Area Start
import React from "react"
import FavouritePanelController, { Props, IFavDetails } from "./FavouritePanelController.web"
import { Box, CircularProgress, Grid } from "@material-ui/core"
import LeafHeadingBanner from "../../../../components/src/LeafHeadingBanner.web"
import FarmsCard from "../../../../components/src/FarmsSectionsImgCard.web"
import { farm1 } from "../../../info-page/src/assets"
import CustomFavEmptyCardWeb from "../CustomFavEmptyCard.web"
import { connect } from "react-redux"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import AdCampaignWrapper from "../../../LocalListingAds/src/AdCampaignWrapper.web"

class FarmsFavouritePanel extends FavouritePanelController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, favouriteList, FPFarmDesc } = this.props


    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
              {favouriteList.length > 0 ? (
                <>
                  <Grid item xs={12} sm={10} md={8}>
                    <LeafHeadingBanner
                      heading={`${this.settingHeading()} Farms`}
                      description={FPFarmDesc}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={3}>
                    {favouriteList.map((item: IFavDetails) => {
                      const { id, attributes } = item
                      const {
                        average_rating,
                        name,
                        description,
                        is_ad,
                        third_party_ad_url
                      } = attributes
                      const { adBanner, thirdPartyImageUrl } = this.getImages(item)

                      return (
                        <Grid item xs={6} md={4} lg={3} key={id}>
                          <AdCampaignWrapper
                            isAd={is_ad}
                            thirdPartyImageUrl={thirdPartyImageUrl}
                            thirdPartyUrl={third_party_ad_url}
                          >
                            <FarmsCard
                              cardName={name}
                              description={description}
                              thumbnail={adBanner || farm1}
                              displayPrice="none"
                              displayButton="block"
                              displayDuration="none"
                              displayRating="block"
                              average_rating={average_rating}
                              boxShadow={false}
                              path={`${AppRoutings.FarmDetails}/${id}`}
                              imgPath={`${AppRoutings.FarmDetails}/${id}`}
                            />
                          </AdCampaignWrapper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <CustomFavEmptyCardWeb
                    message={`No ${this.settingHeading(true)} farms found`}
                    buttonText="Explore Farms now"
                    redirectLink={AppRoutings.FarmsMarket}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    FPFarmDesc: state.cms.FPFarmDesc,
    FPRestaurantDesc: state.cms.FPRestaurantDesc,
    FPCourseDesc: state.cms.FPCourseDesc
  }
}

export { FarmsFavouritePanel }
export default connect(mapStateToProps, {})(FarmsFavouritePanel)
// Customizable Area End
