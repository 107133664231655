// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminRegisterRestaurant.scss";
import "../assets/css/adminOrders.scss";
import {
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownRounded';
import { commonCmptStyles } from "./dropDownCss";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import "../assets/css/rest-order-list.scss";
import AdminOrdersController from "./AdminOrdersController.web";
import CustomOrderTab from "../../../components/src/CustomOrderTab.web";
import Loader from "../../../components/src/Loader.web";
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web";
import AdminOrderDetailsDialogContent from "./AdminOrderDetailsDialogContent.web";
import OrderCancelApproveDialog from "../../ordermanagement/src/OrderCancelApproveDialog.web";
import OrderCancelRejectDialog from "../../ordermanagement/src/OrderCancelRejectDialog.web";
const configJSON = require("./config.js")

export class AdminOrders extends AdminOrdersController {

  render() {
    const { classes } = this.props;
    const {
      orderDetailsModal,
      orderDetails,
      orderStatus,
      farmsList,
      isInitiateRefund,
      isOrderCancelApproveDialog,
      isOrderCancelRejectDialog,
      isUserAdminCancelling,
      cancellationOrderId
    } = this.state;  
    const allSorts = ["default", "high to low", "low to high", "latest"];
    const activeStep = isInitiateRefund ? 2 : 0

    return (
      <>
        <main className="restOrderList commonDropdown farmOrderList">
          <div className="tableContainer">
            <Loader loading={this.state.loading} />

            <div className="headerListContainer">
              <div className="prodListHeader">
                <div className="blocks">
                  <h4>Orders</h4>
                </div>
                <div className="blocks">
                  <div className="block1">
                    <div className="prodFarmSelect customSelect farmOrdersDropDown dishCategorySelect">
                      <FormControl id="formControlar-farms">
                        <Select
                          data-test-id="selectFarm"
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="menu-customize"
                          IconComponent={ArrowDropDownIcon}
                          value={this.state.currentFarmId}
                          onChange={(e: any) => {
                            const targetValue = e.target.value;

                            targetValue !== "0" &&
                              targetValue !== "00" &&
                              this.getProductList(
                                targetValue,
                                this.state.sortValue,
                                this.state.search,
                                this.state.status,
                                this.state.farmOrdersPerPage,
                                this.state.farmOrdersCurrentPage
                              );
                            if (targetValue !== "0") {
                              this.setState({
                                currentFarmId: targetValue
                              });
                              this.props.history.push(
                                `/admin/farm/orders/${targetValue}`
                              );
                            }
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: classes.farmOrdersDropDown
                            }
                          }}
                        >
                          <MenuItem value={0} className="" disabled>
                            <span>No Farms Listed</span>
                            <span className="checkmark">
                              <div className="checkmark_circle" />
                              <div className="checkmark_stem" />
                              <div className="checkmark_kick" />
                            </span>
                          </MenuItem>
                          {
                            farmsList.length > 0 &&
                            farmsList.map((item: any) => {
                              return (
                                <MenuItem value={item.id} key={item.id}>
                                  <span>{item.attributes.name}</span>
                                  <span className="checkmark">
                                    <div className="checkmark_circle" />
                                    <div className="checkmark_stem" />
                                    <div className="checkmark_kick" />
                                  </span>
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="block1">
                    <div className="customSelect dishSortSelect">
                      <FormControl>
                        <Select
                          data-test-id="sortValue"
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="menu-customize"
                          IconComponent={ArrowDropDownIcon}
                          value={this.state.sortValue}
                          onChange={e => {
                            const id = this.state.currentFarmId;
                            this.setState({
                              sortValue: e.target.value
                            });
                            id !== "0" &&
                              id !== "00" &&
                              this.getProductList(
                                id,
                                e.target.value,
                                this.state.search,
                                this.state.status,
                                this.state.farmOrdersPerPage,
                                this.state.farmOrdersCurrentPage
                              );
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper:
                                classes.sortDropDown +
                                " " +
                                classes.commonSelectui
                            }
                          }}
                          autoWidth={true}
                        >
                          {allSorts.map((sort: any) => {
                            return (
                              <MenuItem value={sort} key={sort}>
                                {sort}
                                <span className="checkmark">
                                  <div className="checkmark_circle" />
                                  <div className="checkmark_stem" />
                                  <div className="checkmark_kick" />
                                </span>{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prodListHeader prodListBottomRow">
                <div className="outlineTextfield ">
                  <div className="customTextfield">
                    <TextField
                      data-test-id="searchFarmOrders"
                      id="input-with-icon-textfield"
                      value={this.state.search}
                      onChange={(e: any) => {
                        this.setState({
                          search: e.target.value
                        });
                        this.SearchDebounceUpdate(
                          this.state.currentFarmId,
                          this.state.sortValue,
                          e.target.value,
                          this.state.status
                        );
                      }}
                      variant="standard"
                      placeholder="Search by Name, type, Id, etc... "
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="searchIcon" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabContainer">
            <CustomOrderTab
              data-test-id="orders"
              handleOpenModal={this.handleOrderDetailOpenModal}
              productList={this.state.productList ? this.state.productList : ""}
              getProductList={this.getProductList}
              status={this.state.status}
              search={this.state.search}
              sortValue={this.state.sortValue}
              currentFarmId={this.state.currentFarmId}
              handleStatus={this.handleStatus}
              updateOrderStatus={this.updateOrderStatus}
              total_count={this.state.total_count}
              total_pages={this.state.total_pages}
              farmOrdersPerPage={this.state.farmOrdersPerPage}
              farmOrdersCurrentPage={this.state.farmOrdersCurrentPage}
              pageChange={this.pageChange}
              order_count={this.state.order_count}
              openOrderCancelApproveDialog={this.openOrderCancelApproveDialog}
              openOrderCancelRejectDialog={this.openOrderCancelRejectDialog}
            />
          </div>
        </main>

        <Dialog
          open={orderDetailsModal}
          onClose={this.handleOrderDetailCloseModal}
          maxWidth="md"
          fullWidth
          classes={{
            paper: "custom_dialog_paper"
          }}
        >
          <CustomDialogTitle
            rootClassName="orderDialogTitle"
            onClose={this.handleOrderDetailCloseModal}
          >
            <h4>{configJSON.orderDetailsLabel}</h4>
          </CustomDialogTitle>
          <DialogContent>
            <AdminOrderDetailsDialogContent
              orderStatus={orderStatus}
              orderDetails={orderDetails}
              orderStatusLabel={this.getOrderStatus(orderStatus)}
              orderStatusButtonLabel={this.getButtonOrderStatus(orderStatus)}
              handleStatusChange={this.handleStatusChange}
            />
          </DialogContent>
        </Dialog>

        <OrderCancelApproveDialog
          currentStep={activeStep}
          isOpen={isOrderCancelApproveDialog}
          orderId={cancellationOrderId}
          cancellationByUserAdmin={isUserAdminCancelling}
          onCancellationSuccess={this.getProductListing}
          handleClose={this.closeOrderCancelApproveDialog}
        />

        <OrderCancelRejectDialog
          isOpen={isOrderCancelRejectDialog}
          orderId={cancellationOrderId}
          onCancellationRejection={this.getProductListing}
          handleClose={this.closeOrderCancelRejectDialog}
        />
      </>
    );
  }
}

export default withRouter(withStyles(commonCmptStyles)(AdminOrders));
// Customizable Area End
