// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock"
import { Message } from "../../framework/src/Message"
import { BlockComponent } from "../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
export const configJSON = require("./config.js")

interface IFooterDetails {
  email: string
  facebook: string
  footer_description: string
  instagram: string
  phone_number: string
  subscribe_now_description: string
  twitter: string
  hyperlink_heading: string
  hyperlinks: {
    id: number
    heading: string
  }[]
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  FooterBannerDescription: string
  FooterDescription: IFooterDetails
  setGreenBannerDesc: (desc: string) => void
  setFooterDesc: (desc: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subscribeEmail: string
  subscribeEmailError: string
  // Customizable Area End
}

interface SS {
  id: any
}

export default class WebFooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  footerDetailsApiCallId: string = ""
  subscribeApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      subscribeEmail: "",
      subscribeEmailError: ""
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const { FooterBannerDescription } = this.props
    !FooterBannerDescription && this.footerDetailsApi()
  }

  handleEmailValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({ subscribeEmail: value, subscribeEmailError: "" })
  }

  handleKeyDownEmailField = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    if (key === configJSON.ENTER_KEY) {
      this.handleSubscribe()
    }
  }

  handleSubscribe = () => {
    const isValid = this.isEmailValid()
    isValid && this.subscribeApi()
  }

  footerDetailsApi = async () => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.footerDetailsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.footerDetailsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  subscribeApi = async () => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    }

    const httpBody = {
      email: this.state.subscribeEmail
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.subscribeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribeApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.footerDetailsApiCallId) {
          const { setGreenBannerDesc, setFooterDesc } = this.props

          setGreenBannerDesc(responseJson?.footer_description)
          setFooterDesc(responseJson)
        }
      }
      if (apiRequestCallId === this.subscribeApiCallId) {
        this.setState({ subscribeEmail: "" })
        toast.success("Email subscribed successfully")
      }
    }
    // Customizable Area End
  }

  isEmailValid = () => {
    const { subscribeEmail } = this.state
    const emailValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).exec(subscribeEmail);
    const fieldValidationErrors = emailValid ? '' : 'Invalid email';

    this.setState({ subscribeEmailError: fieldValidationErrors })
    return emailValid
  }
  // Customizable Area End
}

