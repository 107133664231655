// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
import { toast } from "react-toastify"
import { ISearchProduct } from "../../interfaces/search"
import queryString from "query-string"
import { ICategory, ITag } from "../../../../../components/src/interfaces/common"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps {}

interface S {
  loader: boolean
  categoriesLoader: boolean
  priceRangeLoader: boolean
  tagsLoader: boolean
  categories: ICategory[]
  minPrice: number
  maxPrice: number
  tags: ITag[]
  products: ISearchProduct[]
  totalPages: number
}

interface SS {
  id: any
}

export default class SearchProductsController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchProductsApiCallId: string = ""
  productsCategoriesApiCallId: string = ""
  productsPriceRangeApiCallId: string = ""
  productsTagsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      categoriesLoader: false,
      priceRangeLoader: false,
      tagsLoader: false,
      categories: [],
      minPrice: 0,
      maxPrice: 0,
      tags: [],
      products: [],
      totalPages: 0,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    await this.productsCategoriesApi()
    await this.productsPriceRangeApi()
    await this.productsTagsApi()
    this.handleSearchProducts()
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.handleSearchProducts()
    }
  }

  scrollToTop = () => {
    const element = document.getElementsByClassName("web_header")
    if (element && element.length > 0)
      element[0].scrollIntoView({ behavior: "smooth" })
  }

  enableSearchLoader = () => {
    this.setState({ loader: true })
  }

  disableSearchLoader = () => {
    this.setState({ loader: false })
  }

  handleSearchProducts = () => {
    this.scrollToTop()
    const { search } = this.props.location
    const parsedQueryString = queryString.parse(search)
    const { query, ...rest } = parsedQueryString
    const page = rest.page ?? 1
    const per = rest.per ?? 16

    const updatedQueryString = queryString.stringify({
      ...parsedQueryString,
      page,
      per
    })

    if (query) {
      this.searchProductsApi(`?${updatedQueryString}`)
    } else {
      this.props.history.goBack()
    }
  }

  searchProductsApi = async (queryString: string) => {
    this.enableSearchLoader()

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchProductsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchProductsApiEndPoint + queryString
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  productsCategoriesApi = async () => {
    this.setState({ categoriesLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.productsCategoriesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndPoint + configJSON.categoryTypeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  productsPriceRangeApi = async () => {
    this.setState({ priceRangeLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.productsPriceRangeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceRangeApiEndPoint + configJSON.typeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  productsTagsApi = async () => {
    this.setState({ tagsLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.productsTagsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsApiEndPoint + configJSON.tagsTypeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleSuccessResponseData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.searchProductsApiCallId) {
      if (responseJson.data) {
        const { data } = responseJson

        this.setState({ products: data })
      } else {
        toast.error(configJSON.SOMETHING_WENT_WRONG)
      }
      this.disableSearchLoader()
    }

    if (apiRequestCallId === this.productsCategoriesApiCallId) {
      this.setState({ categoriesLoader: false, categories: responseJson })
    }

    if (apiRequestCallId === this.productsPriceRangeApiCallId) {
      const { min_price, max_price } = responseJson
      this.setState({
        priceRangeLoader: false,
        minPrice: Number(min_price),
        maxPrice: Number(max_price),
      })
    }

    if (apiRequestCallId === this.productsTagsApiCallId) {
      this.setState({ tagsLoader: false, tags: responseJson.data ?? [] })
    }
  }

  handleErrorResponseData = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.searchProductsApiCallId) {
      this.disableSearchLoader()
      this.setState({ products: [] })
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseData && !responseData.errors) {
        this.handleSuccessResponseData(apiCallId, responseData)
      } else {
        if (await isTokenExpired(responseData)) {
          return
        }
        this.handleErrorResponseData(apiCallId, responseData)
      }
    }
  }
}
// Customizable Area End
