// Customizable Area Start
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withStyles} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import OtpInput from "react-otp-input";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import "../../../blocks/catalogue/assets/css/farmDetails.scss";
import {
  correct_icon,
  download_icon,
  FileUploadIcon,
  pdf_icon
} from "../../../components/src/assets";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFileUpload from "../../../components/src/CustomFileUpload.web";
import CustomGoogleMap from "../../../components/src/CustomGoogleMap.web";
import CustomGooglePlaceAutoComplete from "../../../components/src/CustomGooglePlaceAutoComplete.web"
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import "../../../web/src/assets/css/content/checkbox.scss";
//css
import "../../../web/src/assets/css/content/tab.scss";
import "../../../web/src/assets/css/content/textfield.scss";
// common css
import "../../../web/src/assets/css/style.scss";
import CustomCheckBox from "./AdminCheckBox.web";
//components
import AdminRestaurantAddDetailsController, {
  Props
} from "./AdminRestaurantAddDetailsController.web";
import { commonCmptStyles } from "./dropDownCss";
import CustomLocationErrorMessage from "../../../components/src/CustomLocationErrorMessage.web";
import RewardAcceptanceSelect from "./RewardAcceptanceSelect/RewardAcceptanceSelect.web";
import AdminTransactionHistoryButton from "./AdminTransactionHistory/AdminTransactionHistoryButton.web";

const phoneRegExp = /^\(?([2-9]\d{2})\)?([2-9]\d{2})?(\d{4})$/g;

const Schema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  org_address: Yup.string().required("This field is required."),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required."),
  ownerName: Yup.string()
    .required("This field is required."),
  ownerEmail: Yup.string().required("This field is required."),
  ownerNumber: Yup.string()
  .matches(phoneRegExp, "Phone number is not valid")
  .required("This field is required."),
  description: Yup.string()
    .max(1000, "Description must be at most 1000 characters")
    .required("This field is required."),
  latitude: Yup.string().required("This field is required"),
  longitude: Yup.string().required("This field is required"),
  cuisines: Yup.array()
    .required("This field is required.")
    .min(1),
  outlet: Yup.array()
    .required("This field is required.")
    .min(1),
  selectedImages: Yup.array().when("bannerImages", {
    is: (bannerImages) => (bannerImages.length === 0),
    then: Yup.array()
      .required("This field is required.")
      .min(1),
  }),
  uploadedDoc : Yup.array().when("documents", {
    is: (documents) => (documents.length === 0),
    then:Yup.array().required("This field is required.").min(1)}),
  bankName: Yup.string().required("This field is required."),
  accountNumber: Yup.number()
    .typeError("Account number must be a number")
    .required("This field is required."),
  owner_otp: Yup.string()
})

// Customizable Area End

class AdminRestaurantAddDetails extends AdminRestaurantAddDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const {
      restStatus,
      loading,
      isOtpVerified,
      mapCenter,
      images,
      bankDoc,
      otpId,
      createResLoader
    } = this.state  

    return (
      <>
        <main className="regStepperContainer restAddDetailsWrapper">
          
            <Loader loading={loading} />
            <div className="farmDetailsTabPanel">
              <div className="headerFarm" >
                <h4 data-testid="registerheading">
                  Restaurant Details{" "}
                  <span>
                    {restStatus}
                  </span>
                </h4>
              </div>
              <Formik
              data-testid="formik"
                enableReinitialize
                initialValues={this.getFormInitialValues()}
                validationSchema={Schema}
                onSubmit={(values: any) => 
                  this.handleResEditSubmit(values)
                }
              >
                {({ values, touched, errors, setFieldValue, handleChange }) => {
                  const otpMargin = this.isOwnerVerified(values.isOwnerVerified, isOtpVerified) ? '0' : '26px';
                  return (
                    <Form noValidate autoComplete="off">
                      <div className="regInfoContainer">
                        <div className="regAdress">
                          <h4>Register Information</h4>
                          <div className="regRestInfoForm">
                            <h6 className="headingDetails">Restaurant Name</h6>
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  className="textFields"
                                  id="name"
                                  value={values.name}
                                  variant="standard"
                                  placeholder="Enter Restaurant Name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                  error={this.isError(errors.name, touched.name)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="name" />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="regRestInfoForm">
                            <h6 className="headingDetails">Restaurant Complete Address</h6>
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  id="org_address"
                                  onChange={handleChange}
                                  value={values.org_address}
                                  variant="standard"
                                  placeholder="Enter Restaurant Address"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={this.isError(errors.org_address, touched.org_address)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="org_address" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="regRestInfoForm">
                            <h6 className="headingDetails">
                              Please place the pin accurately at your outlet's
                              location on the map
                            </h6>
                            <p> This will help your customer to locate your store</p>

                            <CustomLocationErrorMessage
                              isLatError={errors.latitude?.toString()}
                              isLatTouched={!!touched.latitude}
                              isLngError={errors.longitude?.toString()}
                              isLngTouched={!!touched.longitude}
                            />

                            <CustomGooglePlaceAutoComplete
                              data-testid="autocomplete"
                              placeholder="Enter your restaurant locality"
                              lat={Number(mapCenter.lat)}
                              lng={Number(mapCenter.lng)}
                              detectPlace={this.detectPlace}
                              setFieldValue={setFieldValue}
                              handlePlaceSelect={this.handlePlacesAutocompleteSelect}
                            />
                          </div>
                        </div>
                        <div className="iframeContainer">
                          <div className="mapouter">
                            <div className="gmap_canvas">
                              <CustomGoogleMap
                              data-testid="mapContainer"
                                lat={mapCenter.lat}
                                lng={mapCenter.lng}
                                handleDragEnd={(lat, lng) => this.handleChangeLatLng(lat, lng, setFieldValue)}
                              />
                            </div>
                          </div>
                          <div className="coOrdinateContainer">
                            <h6 className="ordinateHeader">
                              <span>or directly enter the co-ordinates</span>
                            </h6>
                            <div className="coOrdinate-form">
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <TextField
                                    id="input-with-icon-textfield"
                                    value={values.latitude}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                      this.handleChangeLat(e, values.longitude, setFieldValue)
                                    }
                                    variant="standard"
                                    placeholder="Latitude"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <TextField
                                    id="input-with-icon-textfield"
                                    value={values.longitude}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                      this.handleChangeLng(e, values.latitude, setFieldValue)
                                    }
                                    variant="standard"
                                    placeholder="Longitude"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h6 className="resDesc">Restaurant Description</h6>
                        <TextField
                          id="description"
                          className="textFieldMultiline"
                          placeholder="Enter Details"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          margin="normal"
                          error={this.isError(errors.description, touched.description)}
                          value={values.description}
                          onChange={handleChange}
                          style={{ marginTop: 8, borderRadius: "8px !important" }}
                        />
                        <span className="errorMsg">
                          <ErrorMessage name="description" />
                        </span>

                        <div className="restContactNumber">
                          <div className="contact">
                            <div className="conactNumber">
                              <h6>Contact number at restaurant</h6>
                              <p>
                                Your customers will call on this number for general
                                enquiries
                              </p>
                              <div className="conactNumberForm">
                                <div
                                  className="outlineTextfield boxRadiusBorder numberField"
                                >
                                  <div className="customTextfield">
                                    <TextField
                                      id="phoneNumber"
                                      value={values.phoneNumber}
                                      variant="standard"
                                      placeholder="Mobile Number at Restaurant"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton aria-label="toggle password visibility">
                                              <span className="countryCodeIcon">
                                                <p>+ 1</p>
                                              </span>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        this.handleOrgNumberChange(
                                          e,
                                          values.sameAddress,
                                          setFieldValue
                                        )
                                      }
                                      error={this.isError(errors.phoneNumber, touched.phoneNumber)}
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name="phoneNumber" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ownerDetailsContainer">
                          <h4 className="detailsHeader">Register Owner Details</h4>
                          <p>Restaurant owner details</p>
                          <div className="nameEmails ownerDetails">
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  id="ownerName"
                                  value={values.ownerName}
                                  variant="standard"
                                  placeholder="Owner Name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                  error={this.isError(errors.ownerName, touched.ownerName)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="ownerName" />
                                </span>
                              </div>
                            </div>
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="phoneDetailsBlock">
                                <div className="outlineTextfield boxRadiusBorder">
                                  <div className="customTextfield contactRestInput">
                                    <TextField
                                      id="ownerNumber"
                                      value={this.mobileNumber(values)}
                                      variant="standard"
                                      placeholder="Owner's Mobile Number"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton aria-label="toggle password visibility">
                                              <span className="countryCodeIcon">
                                                <p className="inputAdornment">+ 1</p>
                                              </span>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        this.handleOwnerNumberChange(e, setFieldValue)
                                      }
                                      disabled={values.sameAddress}
                                      error={this.isError(errors.ownerNumber, touched.ownerNumber)}
                                    />
                                    {!values.sameAddress && (
                                      <span className="errorMsg">
                                        <ErrorMessage name="ownerNumber" />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="restOwnerCheckBox customCheckbox">
                              <input
                                type="checkbox"
                                id="is_same_number"
                                checked={values.sameAddress}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  this.toggleIsSameNumber(
                                    e,
                                    values.phoneNumber,
                                    setFieldValue
                                  )
                                }
                              />
                              <label
                                className="is_same_number"
                                htmlFor="is_same_number"
                              >
                                Same as Restaurant
                              </label>
                            </div>
                          </div>
                          <div className="phoneDetails">
                            <div
                              className="outlineTextfield ownerEmailField boxRadiusBorder restPhone"
                            >
                              <div className="customTextfield">
                                <TextField
                                  id="ownerEmail"
                                  value={values.ownerEmail}
                                  variant="standard"
                                  placeholder="Enter Owner Email address"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e: any) =>
                                    this.handleResEditEmailChange(
                                      e.target.value,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  error={this.isError(errors.ownerEmail, touched.ownerEmail)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="ownerEmail" />
                                </span>
                              </div>
                            </div>

                            <div
                              className="greenContained restVerifyBtn"
                            >
                              <CustomButton style={{ marginTop: otpMargin}}
                              data-testid="otpsend"
                                color="secondary"
                                label="Verify"
                                disabled={this.isOwnerVerified(values.isOwnerVerified, isOtpVerified)}
                                onClick={() => this.postSendOtp(values.ownerEmail)}
                              />
                            </div>

                            {this.isOwnerVerified(values.isOwnerVerified, isOtpVerified) ? (
                              <Grid item xs={12} sm={4} style={{marginLeft: '30px' }}>
                                <div className="verify-block">
                                  <span className="verify-icon" />
                                  <h5 className="text-verify">verified</h5>
                                </div>
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={7}>
                                <div className="otpContainer customOtpContainer">
                                  <div className="otpForm">
                                    <p className="otpText">Enter Otp</p>
                                    <OtpInput
                                     data-testid="otpInput"
                                      value={values.owner_otp}
                                      onChange={(otp: string) =>
                                        this.handleOTPChange(otp, setFieldValue)
                                      }
                                      numInputs={4}
                                      separator={<span> </span>}
                                      isDisabled={!otpId}
                                      isInputNum={true}
                                      disabledStyle={"disabledInput"}
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name="owner_otp" />
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            )}
                          </div>
                        </div>
                        <hr />

                        <div className="restTypeTimingsContainer">
                          <div className="restTypeCommonHeader">
                            <h4>Restaurant Type & Timings</h4>
                            <p>
                              Select most relevant category for your restaurant type
                            </p>
                          </div>
                          <div className="radioBoxContainer">
                            <div className="customRadio">
                              <FormControl component="fieldset">
                                <RadioGroup
                                data-testid="radioBtn"
                                  aria-label="quiz"
                                  name="radio"
                                  value={values.restaurant_type}
                                  onChange={(e) =>
                                    setFieldValue("restaurant_type", e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="both_delivery_and_dine_in_available"
                                    control={<Radio />}
                                    label="Both, delivery and dine-in  available"
                                  />
                                  <FormControlLabel
                                    value="dine_in_only"
                                    control={<Radio />}
                                    label="Dine-in only"
                                  />
                                  <FormControlLabel
                                    value="delivery_only"
                                    control={<Radio />}
                                    label="Delivery Only"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                          <div className="outletType">
                            <div className="block">
                              <h6>Select options which best describe your outlet</h6>
                              <Grid container xs={12} className="outletContainer">
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Bakery"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Beverage Shop"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Butcher Shop"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Cafe"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Dessert Parlour"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Lounge"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Sweet Shop"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Pub"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Microbrewery"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>

                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Food Truck"
                                    type="outlet"
                                    outlet={values.outlet}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                              </Grid>
                              <span className="errorMsg">
                                <ErrorMessage name="outlet" />
                              </span>
                            </div>
                            <div className="block">
                              <h6>Type of cuisines</h6>
                              <Grid container xs={12}>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Bakery"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Beverage Shop"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Butcher Shop"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Cafe"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Dessert Parlour"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                  <CustomCheckBox
                                    name="Lounge"
                                    type="cuisines"
                                    outlet={values.cuisines}
                                    setValue={setFieldValue}
                                  />
                                </Grid>
                              </Grid>
                              <span className="errorMsg">
                                <ErrorMessage name="cuisines" />
                              </span>
                            </div>
                          </div>
                          <div className="restOperationTime">
                            <div className="restTypeCommonHeader">
                              <h4>Restaurant operational hours</h4>
                              <p>
                                Mark restaurant opening and closing hours and open
                                days
                              </p>
                            </div>

                            <Grid item xs={12} sm={6} md={4}>
                              <div className="res_edit_green_contained res_edit_avail_wrapper">
                                <CustomButton
                                  label="Change restaurant availability"
                                  className="custom_action_btn"
                                  onClick={this.handleAvailRedirect}
                                />
                              </div>
                            </Grid>
                          </div>
                        </div>
                        <div className="bannerImgContainer">
                          <h6>Banner Images</h6>
                          <div className="fileUploadContainer">
                            {images?.map((banner: any) => {
                              return (
                                <div className="headerImage" key={banner.id}>
                                  <div className="pdfFileBlock">
                                    <div className="farmImg">
                                      <img
                                        src={banner.url}
                                        className="farmImg"
                                        alt="image"
                                      />

                                      <button
                                        type="button"
                                        data-testid="bannerImg"
                                        className="close_icon"
                                        onClick={() => {
                                          this.handleImages(banner.id)
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <h6>Header Image</h6>
                                </div>
                              )
                            })}

                            {values?.uploadedBanners?.map((banner: any) => {
                                return (
                                  <div
                                    className="headerImageBlock"
                                    key={banner.lastModified}
                                  >
                                    <div className="headerImage">
                                      <img
                                        src={
                                          banner &&
                                          (
                                            window.URL || window.webkitURL
                                          ).createObjectURL(banner)
                                        }
                                        className="farmImg"
                                        alt="image"
                                      />
                                      <input
                                        type="checkbox"
                                        id={banner.lastModified}
                                        className="correctIconInput"
                                        defaultChecked={false}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                          this.toggleImageSelect(
                                            e,
                                            values.selectedImages,
                                            banner,
                                            setFieldValue
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={banner.lastModified}
                                        className="correctIconLabel"
                                      >
                                        <img
                                          src={correct_icon}
                                          className="correct_icon"
                                          alt="Correct Icon"
                                        />
                                      </label>
                                    </div>
                                    <h6>Header Image</h6>
                                  </div>
                                )
                              })}

                            <div className="FileUpload">
                              <div className="customFileUpload">
                                <input
                                  type="file"
                                  id="fileUpload"
                                  multiple
                                  accept="image/png, image/jpeg, image/jpg"
                                  className="fileUploadInput"
                                  onChange={(e: any) =>
                                    this.handleFileUploadEdit(
                                      e.currentTarget.files,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                />
                                <label
                                  htmlFor="fileUpload"
                                  className="fileUploadLabel"
                                >
                                  <img src={FileUploadIcon} alt="image" />
                                  <span>Add Photos</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <span className="errorMsg">
                            <ErrorMessage name="selectedImages" />
                          </span>
                        </div>
                        <hr />
                        <div className="bankDetaisCOntainer">
                          <h4>Bank Details</h4>
                          <div className="bankDetalsFormContainer">
                            <div className="formContainer">
                              <h6>Select Bank</h6>
                              <div className="outlineSelect">
                                <div className="customSelect restaurant_edit_bank_select">
                                  <FormControl>
                                    <Select
                                      id="bankName"
                                      className="menu-customize"
                                      value={values.bankName}
                                      onChange={handleChange("bankName")}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                        classes: {
                                          paper: classes.categoryDropDown,
                                        },
                                      }}
                                    >
                                      <MenuItem value="">Select Bank</MenuItem>
                                      <MenuItem value="JPMorgan Chase & Co.">
                                        JPMorgan Chase & Co.
                                      </MenuItem>
                                      <MenuItem value="Bank of America Corp.">
                                        Bank of America Corp.
                                      </MenuItem>
                                      <MenuItem value="Wells Fargo & Co.">
                                        Wells Fargo & Co.
                                      </MenuItem>
                                      <MenuItem value="Citigroup Inc.">
                                        Citigroup Inc.
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <span className="errorMsg">
                                  <ErrorMessage name="bankName" />
                                </span>
                              </div>
                            </div>
                            <div className="formContainer">
                              <h6>Account Number</h6>
                              <div className="outlineTextfield boxRadiusBorder">
                                <CustomTextfield
                                  id="accountNumber"
                                  placeholder="Enter Account Number"
                                  value={values.accountNumber}
                                  onChange={handleChange("accountNumber")}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="accountNumber" />
                                </span>
                              </div>
                            </div>
                            <div className="formContainer">
                              <h6>IBAN</h6>
                              <div className="outlineTextfield boxRadiusBorder">
                                <CustomTextfield
                                  id="ibanNumber"
                                  placeholder="Enter IBAN Number"
                                  value={values.ibanNumber}
                                  onChange={handleChange("ibanNumber")}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="ibanNumber" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="bannerImgContainer">
                            <h4>Registration Documents</h4>
                            <div className="fileUploadContainer pdfFileUploadContainer">
                              {
                                bankDoc?.map((doc: any) => (
                                  <div
                                    className="headerImageBlock headerPdfFileBlock"
                                    key={doc.lastModified}
                                  >
                                    <div className="headerImage">
                                      <div className="pdfFileBlock">
                                        <div className="farmImg">
                                          <img
                                            src={pdf_icon}
                                            className="pdf_icon"
                                            alt="Correct Icon"
                                          />
                                          <button
                                            type="button"
                                            className="close_icon remove_icon"
                                            data-testid="remove_icon"
                                            onClick={() =>
                                              this.handlePDF(doc.id)
                                            }
                                          ></button>
                                          {!doc.lastModified && (
                                            <button
                                              type="button"
                                              className="download_icon"
                                              data-testid="download_icon"
                                              onClick={() =>
                                                saveAs(doc.url, doc.filename)
                                              }
                                            >
                                              <img
                                                src={download_icon}
                                                alt="Correct Icon"
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <h6>{this.getDocName(doc.name, doc.filename)}</h6>
                                  </div>
                                ))}

                              {[...values.uploadedDoc].map((doc: any) => {
                                const updatedDocs = [...values.uploadedDoc].filter(
                                  (item: any) =>
                                    item.lastModified !== doc.lastModified
                                )
                                return (
                                  <div
                                    className="headerImageBlock headerPdfFileBlock"
                                    key={doc.lastModified}
                                  >
                                    <div className="headerImage">
                                      <div className="pdfFileBlock">
                                        <div className="farmImg">
                                          <img
                                            src={pdf_icon}
                                            className="pdf_icon"
                                            alt="Correct Icon"
                                          />
                                          <button
                                            type="button"
                                            className="close_icon remove_icon"
                                            onClick={() => 
                                              this.handleDocRemove(
                                                doc.lastModified,
                                                values,
                                                updatedDocs,
                                                setFieldValue
                                              )
                                            }
                                          ></button>
                                          {!doc.lastModified && (
                                            <button
                                              id="button"
                                              type="button"
                                              className="download_icon"
                                              onClick={() => saveAs(doc.name)}
                                            >
                                              <img
                                                src={download_icon}
                                                alt="Correct Icon"
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <h6>{this.getDocName(doc.name, doc.filename)}</h6>
                                  </div>
                                )
                              })}
                              <div className="FileUpload">
                                <CustomFileUpload
                                  label="Add Documents"
                                  id="documents"
                                  allowMultipleFiles={true}
                                  name="documents"
                                  handleChange={(event: any) => {
                                    setFieldValue("uploadedDoc", [
                                      ...values.uploadedDoc,
                                      ...event.currentTarget.files,
                                    ])
                                  }}
                                  handleClick={(event: any) => {
                                    event.target.value = null
                                  }}
                                />
                              </div>
                            </div>

                            <span className="errorMsg">
                              <ErrorMessage name="uploadedDoc" />
                              {errors?.uploadedDoc && <br />}
                            </span>
                            <span className="uploadSubtitle">
                              Ex. Restaurant registration Documents, etc...
                            </span>

                            <Box mt={2}>
                              <hr />
                              <RewardAcceptanceSelect
                                name="purchase_limit_percentage"
                                handleValueChange={(value: number) => setFieldValue("purchase_limit_percentage", value)}
                                value={values.purchase_limit_percentage}
                              />
                              <AdminTransactionHistoryButton />
                            </Box>

                            <Grid container spacing={2} className="btnContainer">
                              <Grid item xs={6} sm={4} md={3} lg={2}>
                                <span className="grayButton">
                                  <CustomButton
                                    color="secondary"
                                    label="Cancel"
                                    className="custom_action_btn"
                                    onClick={() => this.props.history.goBack()}
                                    disabled={createResLoader}
                                  />
                                </span>
                              </Grid>

                              <Grid item xs={6} sm={4} md={3} lg={2}>
                                <span className="res_edit_green_contained">
                                  <CustomButton
                                    label="Save"
                                    type="submit"
                                    className="custom_action_btn"
                                    isLoading={createResLoader}
                                    disabled={createResLoader}
                                  />
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
        </main>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantAddDetails}
//@ts-ignore
const AdminRestaurantAddDetailsRouter:any = withRouter(AdminRestaurantAddDetails);
export default withStyles(commonCmptStyles)(AdminRestaurantAddDetailsRouter);
// Customizable Area End
