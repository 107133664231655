Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.clientID =
  "350052156634-f6vcski427mlqkn299dquikiak2igact.apps.googleusercontent.com";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";

exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";

exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";

exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";

exports.instagramText = "Instagram"
exports.INSTAGRAM_APP_ID = "699832571029994"
exports.INSTAGRAM_APP_SECRET = "74b3a13a80dc40483367920ef0d73df5"
exports.TOKEN = "token"
exports.AUTH_TOKEN = "authToken"
exports.PROFILE_KEY = "profile"
exports.FIRST_NAME_KEY = "firstname"
exports.LAST_NAME_KEY = "lastname"
exports.ADMIN_AUTH_KEY = "AdminAuth"
exports.CART_ID_KEY = "cartId"
exports.ORDERS_STORAGE_KEY = "orders"
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue"
exports.getRolesApiEndPoint = "account_block/accounts/remaining_roles";
exports.SOMETHING_WENT_WRONG = "Something went wrong"
exports.bulkAddToCartApiEndPoint = "bx_block_order_management/cart_items/bulk_add_to_cart"
exports.instagramLoginRegistrationApiEndPoint = "bx_block_login/social_login"
// Customizable Area End