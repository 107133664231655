import React from "react";
import { Button } from "@material-ui/core";
import "./assets/css/greenroundbutton.scss";
import { Link } from "react-router-dom";
type Props = {
  buttonText: String;
  path: string | "";
};

const GreenRoundedButton = (props: Props) => {
  return (
    <Link to={props.path}>
      <Button
        className="custom_green_round_btn"
        classes={{ root: "green_btn_root", label: "green_btn_label" }}
      >
        {props.buttonText}
      </Button>
    </Link>
  );
};

export default GreenRoundedButton;
