// Customizable Area Start
import React, { Component } from "react"
import { Map, Marker, GoogleApiWrapper, IMapProps } from "google-maps-react"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
const configJSON = require("./config.js")

const styles = () =>
  createStyles({
    mapWrapper: {
      position: "relative",
      height: "300px",
    },
  })

interface ICoords {
  latLng: {
    toJSON(): {
      lat: number
      lng: number
    }
  }
}

interface Props extends IMapProps, WithStyles<typeof styles> {
  google: typeof google
  lat: number
  lng: number
  handleDragEnd: (lat: number, lng: number) => void
}

interface S { }

export class CustomGoogleMap extends Component<Props, S> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  onMarkerDragEnd = (_: any, __: any, coords: ICoords) => {
    const { handleDragEnd } = this.props
    const { lat, lng } = coords.latLng.toJSON()
    handleDragEnd(lat, lng)
  }

  render() {
    const { classes, lat, lng, google } = this.props
    const center = { lat, lng }

    return (
      <div className={classes.mapWrapper}>
        <Map
          google={google}
          initialCenter={center}
          center={center}
          zoom={10}
          clickableIcons
        >
          <Marker
          data-testid="marker"
            draggable
            optimized
            crossOnDrag={false}
            position={center}
            //@ts-ignore
            onDragend={this.onMarkerDragEnd}
          />
        </Map>
      </div>
    )
  }
}

export default withStyles(styles)(
  GoogleApiWrapper({
    apiKey: configJSON.GOOGLE_MAP_API_KEY,
  })(CustomGoogleMap)
)
// Customizable Area End
