import * as React from 'react';
import webPurchasedProductController, {
  Props,
} from "./webPurchasedProductController.web";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import { withRouter } from "react-router-dom";
import "../assets/css/purchase-courses.scss";
import "../../../web/src/assets/css/content/breadcrumb.scss";
import {
  Link,
  Button,
  MenuItem,
  Select,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Breadcrumbs,
} from "@material-ui/core";
//images
import {
  CourseImg1,
  CourseImg2,
  CourseImg3,
  CourseImg4,
  CourseImg5,
  CourseImg6,
} from "./assets";
import Rating from "@material-ui/lab/Rating";
import CustomBorderLinearProgressbar from "../../../components/src/CustomBorderLinearProgressbar.web";
import CustomButton from "../../../components/src/CustomButton.web";

function handleClick(event: any) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

class webPurchasedProduct extends webPurchasedProductController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // const [value, setValue] = React.useState(2);
    const breadcrumbs = [
      <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
        Home
      </Link>,
      <Typography key="3">
        Purchased Courses
      </Typography>,
    ];
    return (
      <>
        {/* <div className="Header"> */}
        <WebHeader />
        <WebRoutingTab />
        {/* purchase course container */}
        <main className="purchaseCourseWapper">
          <div className="subContentWrapper">
            {/* Breadcrumbs  */}
            <div className="breadCrumbsrow">
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </div>
            <section className="purchaseCourseSection commonSection">
              <h4>Purchased Courses</h4>
              <Grid container spacing={4} alignItems="stretch" className="cardContainer">
                <Grid item xs={12} sm={4}>
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg1}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        New Agricultural Techniques
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                      <div className="customProgressbar">
                        <CustomBorderLinearProgressbar />
                      </div>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Rating
                        name="simple-controlled"
                        value={3}
                      />
                      <h6>70%</h6>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg2}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        How to do Sustainable Farming
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                      <div className="customProgressbar">
                        <CustomBorderLinearProgressbar />
                      </div>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Rating
                        name="simple-controlled"
                        value={3}
                      />
                      <h6>23%</h6>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg3}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        How to increase Soil Health
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                      <div className="customProgressbar">
                        <CustomBorderLinearProgressbar />
                      </div>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Rating
                        name="simple-controlled"
                        value={3}
                      />
                      <h6>46%</h6>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </section>
            <section className="subCourseSection commonSection">
              <h2>Other Courses You May like</h2>
              <Grid container spacing={4} className="cardContainer">
                <Grid item xs={12} sm={4}>
                  <Card className="card purchaseCard">
                    <div className="imgContainer">

                      <Rating
                        name="simple-controlled"
                        value={3}
                        className="rating"
                      />
                      <CardMedia
                        component="img"
                        image={CourseImg4}
                        alt="Course Img"
                        className="courseImg"
                      />
                    </div>
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Foods with Nutrition
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <h4>$130</h4>
                      <div className="greenFilledButton">
                        <CustomButton label="Buy Now" />
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card purchaseCard">

                    <div className="imgContainer">

                      <Rating
                        name="simple-controlled"
                        value={3}
                        className="rating"
                      />
                      <CardMedia
                        component="img"
                        image={CourseImg5}
                        alt="Course Img"
                        className="courseImg"
                      />

                    </div>
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Environment Science
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <h4>$130</h4>
                      <div className="greenFilledButton">
                        <CustomButton label="Buy Now" />
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card purchaseCard">
                    <div className="imgContainer">

                      <Rating
                        name="simple-controlled"
                        value={3}
                        className="rating"
                      />
                      <CardMedia
                        component="img"
                        image={CourseImg6}
                        alt="Course Img"
                        className="courseImg"
                      />

                    </div>
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Carbon Forestry
                      </Typography>
                      <Typography variant="body2">
                        By John Doe
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <h4>$130</h4>
                      <div className="greenFilledButton">
                        <CustomButton label="Buy Now" />
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </section>
          </div>
        </main>
        {/* ends: purchase course container */}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}
//@ts-ignore
export default withRouter(webPurchasedProduct);
// Customizable Area Start

// Customizable Area End
