import React from 'react';
import {getFarmProductList, getCategories, getTags, getProductDetails,addRatingFarm,addToFavorite, getFarmsProductMinMaxPrice} from "./farmsCatalogueAPI";
import {toast} from "react-toastify";
import store from "../store"
import { isTokenExpired } from "../../utility/helper"
import history from '../../utility/useHistory';


// Action for Get Farm list with filters
export const getFarmProductListAction = (id) => async (dispatch) => {
  dispatch({ type: "SET_IS_FARM_PRODUCT_LOADING_TRUE" })
  const farmData = store.getState().farmProducts
  if (farmData?.farmsProduct?.farm?.id !== id) {
    if (farmData.farmsCategories.length <= 0)
      dispatch(getFarmProductsCategoriesAndTagsAction(id))
    if (farmData.minPrice === 0 || farmData.maxPrice === 0)
      dispatch(getFarmProductsPrices(id))
  }

  try {
    const payload = {
      id,
      page: farmData.currentPage,
      count: farmData.showCount,
      filterByCategoryName: farmData.selectedCategory,
      filterByTagName: farmData.selectedTag,
      startPrice: farmData.startPrice,
      endPrice: farmData.endPrice,
      searchText: farmData.searchText,
      latitude: farmData.lat,
      longitude: farmData.lng,
      filter: farmData.filterType
    }
    const response = await getFarmProductList(payload)
    if (response.data && response.meta.pagination.total_count > 0) {
      dispatch({ type: "SET_FARM_PRODUCT_LIST", payload: response })
      dispatch({
        type: "SET_FARM_PRODUCT_FORUM",
        payload: response?.forums?.data,
      })
      dispatch({
        type: "SET_PAGINATION_FOR_FARM_PRODUCT_LIST",
        payload: response?.meta?.pagination,
      })
      dispatch({
        type: "SET_FARM_PRODUCT_RATINGS",
        payload: response?.reviews?.data,
      })
      dispatch({ type: "SET_FARM_DONATION", payload: response?.campaign })
    } else {
      response?.errors?.map((err) => {
        toast.error(err?.message)
      })
      if (response?.meta?.pagination?.total_count === 0) {
        toast.error("No products available")
        dispatch({ type: "SET_FARM_PRODUCT_LIST", payload: [] })
        dispatch({
          type: "SET_PAGINATION_FOR_FARM_PRODUCT_LIST",
          payload: response?.meta?.pagination,
        })
      }
    }
  } catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({ type: "SET_IS_FARM_PRODUCT_LOADING_FALSE" })
}

export const setFarmsProductsCurrentPage = (id,currentPage) => async (dispatch) => {
  dispatch({type: 'SET_FARMS_PRODUCT_CURRENT_PAGE',payload:currentPage});
  dispatch(getFarmProductListAction(id))
}

export const getFarmProductsCategoriesAndTagsAction = (id) => async (dispatch) => {
  try {
    const res1 = await getCategories(id)
    dispatch({type:"SET_FARMS_PRODUCT_FILTER_CATEGORIES",payload:res1})

    const res2 = await getTags(id)
    dispatch({type:"SET_FARMS_PRODUCT_FILTER_TAGS",payload:res2.data})
  }catch (e) {
    toast.error("Something went wrong..")
  }
}

export const getFarmProductsPrices = (id) => async (dispatch) => {
  try {
    const res = await getFarmsProductMinMaxPrice(id)
    dispatch({ type:"SET_FARMS_PRODUCT_FILTER_PRICES", payload: res })
  } catch(e) {
    toast.error("Something went wrong!")
  }
}

export const setFarmProductsSelectedCategory = (name,id) => async (dispatch) => {
  dispatch({type:"SET_FARMS_PRODUCT_FILTER_SELECTED_CATEGORY", payload: name})
  dispatch({type: 'SET_FARMS_PRODUCT_CURRENT_PAGE',payload:1});
  dispatch(getFarmProductListAction(id))
}

export const setFarmProductsSelectedTag = (name,id) => async (dispatch) => {
  dispatch({type:"SET_FARMS_PRODUCT_FILTER_SELECTED_TAG", payload: name})
  dispatch({type: 'SET_FARMS_PRODUCT_CURRENT_PAGE',payload:1});
  dispatch(getFarmProductListAction(id))
}

export const setProductsFilterByPrice = (array,id) => async (dispatch) => {
  dispatch({type:"SET_FARMS_PRODUCT_FILTER_START_PRICE", payload: array[0]})
  dispatch({type:"SET_FARMS_PRODUCT_FILTER_END_PRICE", payload: array[1]})
  dispatch({type: 'SET_FARMS_PRODUCT_CURRENT_PAGE',payload:1});
  dispatch(getFarmProductListAction(id))
}

export const setProductsFilterBySearch = (id) => async (dispatch) => {
  dispatch({type: 'SET_FARMS_PRODUCT_CURRENT_PAGE',payload:1});
  dispatch(getFarmProductListAction(id))
}

export const setProductsFilterSearchText = (text) => async (dispatch) => {
  dispatch({type:"SET_FARMS_PRODUCT_FILTER_SEARCH_TEXT", payload: text})
}

export const setProductsFilterPrice = (id,key) => async (dispatch) => {

  if (key === "default") key = ""

  dispatch({type:"SET_FARMS_PRODUCT_FILTER_TYPE", payload: key})
  dispatch(getFarmProductListAction(id))
}

export const clearProductsFilterByTextAndLatLng = (id) => async (dispatch) => {
  dispatch({type:"CLEAR_FARMS_PRODUCT_FILTER_SEARCH_AND_LATLNG_FILTERS"})
  dispatch(getFarmProductListAction(id))
}

export const getFarmProductsDetailsAction = (id) => async (dispatch) => {
  dispatch({type: 'SET_IS_FARM_PRODUCT_LOADING_TRUE'});
  try {
    const res = await getProductDetails(id)
    if(res.hasOwnProperty('data')){
      dispatch({type:'SET_FARM_PRODUCT_DETAILS',payload:res.data.attributes})
    } else {
      if(await isTokenExpired(res)) return
      toast.error("Something went wrong")
      history.goBack()
    }
  } catch (e) {
    toast.error("Something went wrong!!")
  }

  dispatch({type: 'SET_IS_FARM_PRODUCT_LOADING_FALSE'});
}

export const addRatingFarmProductsAction = (formData,handleClear) => async (dispatch) => {
  dispatch({type:"SET_IS_FARM_PRODUCT_REVIEW_LOADING_TRUE"})
  try {
    if(formData.rating &&  formData.comment && formData.id){
      const res = await addRatingFarm({
        review:{
          rating:formData.rating,
          comment:formData.comment,
          reviewable_id:formData.id,
          reviewable_type:"BxBlockCatalogue::Farm"
        }
      })
      if(!res.hasOwnProperty("errors")){
        dispatch({type:'ADD_FARM_PRODUCT_REVIEW',payload:res.data})
        toast.success("Comment added successfully")
        handleClear()
      } else {
        await isTokenExpired(res)
      }
    }else{
      if(!formData.rating || !formData.comment){
        toast.error("Please fill the rating and review details")
      }
    }
  }catch (e) {
    console.log("ERROR",e)
    toast.error("Something went wrong!!")
  }
  dispatch({type:"SET_IS_FARM_PRODUCT_REVIEW_LOADING_FALSE"})
}

export const setNearByProductsFilter = (lat,lng) => async (dispatch) => {
  const farmData = store.getState().farmProducts
  if(farmData.nearByFilter){
    dispatch({type:"SET_FARMS_FILTER_LAT", payload: ""})
    dispatch({type:"SET_FARMS_FILTER_LNG", payload: ""})
    dispatch({type:"SET_NEAR_BY_FARM_FILTER", payload: !farmData.nearByFilter})
  }else{
    if(lat && lng){
      dispatch({type:"SET_FARMS_FILTER_LAT", payload: lat})
      dispatch({type:"SET_FARMS_FILTER_LNG", payload: lng})
      dispatch({type:"SET_NEAR_BY_FARM_FILTER", payload: !farmData.nearByFilter})
    }
  }
  dispatch(getFarmProductListAction())
}

export const addRatingFarmItemAction = (formData,handleClear) => async (dispatch) => {
  dispatch({type:"SET_IS_FARM_PRODUCT_REVIEW_LOADING_TRUE"})
  try {
    if(formData.rating &&  formData.comment && formData.id){
      const res = await addRatingFarm({
        review:{
          rating:formData.rating,
          comment:formData.comment,
          reviewable_id:formData.id,
          reviewable_type:"BxBlockCatalogue::FarmCatalogue"
        }
      })
      if(!res.hasOwnProperty("errors")){
        dispatch({type:'ADD_FARM_ITEM_REVIEW',payload:res.data})
        toast.success("Comment added successfully")
        handleClear()
      } else {
        await isTokenExpired(res)
      }
    }else{
      if(!formData.rating || !formData.comment){
        toast.error("Please fill the rating and review details")
      }
    }
  }catch (e) {
    console.log("ERROR",e)
    toast.error("Something went wrong!!")
  }
  dispatch({type:"SET_IS_FARM_PRODUCT_REVIEW_LOADING_FALSE"})
}

export const addToFavoriteProdAction = (id) => async (dispatch) => {
  try {
    const res = await addToFavorite({
      favouriteable_id:id,
      favouriteable_type:"BxBlockCatalogue::FarmCatalogue"
    })
    if(res.hasOwnProperty("data")){
      dispatch({type:"FARM_ADD_TO_FAVORITE_PROD_BUTTON"})
    }
  }catch (e) {
    toast.error("Something went wrong!!")
  }
}

export const resetProductCatalogueFilters = () => async (dispatch) => {
  dispatch({ type: 'RESET_FARM_PRODUCT_FILTERS' })
}

export const toggleFarmForumLike = (id, isLiked, likeCount) => async (dispatch) => {
  dispatch({ type: 'SET_FARM_FORUM_LIKE', payload: { id, isLiked, likeCount }})
}

export const handleFarmForumCommentCount = (id, count) => async (dispatch) => {
  dispatch({ type: 'SET_FARM_FORUM_COMMENT', payload: { id, count }})
}