// Customizable Area Start
import React from "react"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Pagination from "@material-ui/lab/Pagination"
import TransactionHistoryTableController, {
  Props,
} from "./TransactionHistoryTableController.web"
import moment from "moment"

export const styles = () =>
  createStyles({
    tableWrapper: {
      overflowX: "auto",
      marginTop: "35px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    tableContainer: {
      width: "100%",
      textAlign: "center",
      fontFamily: "'Montserrat', sans-serif",
      minWidth: "650px",
      "& th": {
        paddingBottom: "10px",
        color: "#000",
        fontWeight: 600,
      },
      "& td": {
        paddingTop: "10px",
        color: "#979797",
      },
    },
    pointsInText: {
      color: "#20a258 !important",
      fontWeight: 600,
    },
    pointsOutText: {
      color: "#ED5959 !important",
      fontWeight: 600,
    },
    emptyHistoryWrapper: {
      minHeight: "40vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #f0f0f0",
      marginTop: "15px",
      borderRadius: "12px",
      "& p": {
        color: "#333",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    loaderWrapper: {
      minHeight: "40vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        color: "#20a258",
      },
    },
  })

class TransactionHistoryTable extends TransactionHistoryTableController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    const {
      isLoading,
      currentPage,
      totalPages,
      transactionHistory,
    } = this.state
    const historyExists = transactionHistory.length > 0
    const isFirstLoading = isLoading && currentPage === 1

    if (isFirstLoading) {
      return (
        <Box className={classes.loaderWrapper}>
          <CircularProgress size={26} />
        </Box>
      )
    }

    return (
      <>
        {historyExists ? (
          <Box className={classes.tableWrapper}>
            <table className={classes.tableContainer}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Username or purchase</th>
                  <th>Points In/Out</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={4} className={classes.loaderWrapper}>
                      <CircularProgress size={26} />
                    </td>
                  </tr>
                ) : (
                  <>
                    {transactionHistory.map((history) => {
                      const {
                        id,
                        attributes: {
                          points,
                          transfer_from,
                          transfer_type,
                          created_at,
                        },
                      } = history

                      const isAdded = points.startsWith("+")
                      const date = moment(created_at).format("MM/DD/YYYY")

                      return (
                        <tr key={id}>
                          <td>{date}</td>
                          <td>{transfer_type}</td>
                          <td>{transfer_from}</td>
                          <td
                            className={
                              isAdded
                                ? classes.pointsInText
                                : classes.pointsOutText
                            }
                          >
                            {points}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )}
              </tbody>
            </table>
            <Box mt={4} className="web_pagination_container">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={this.handlePaginationChange}
                variant="outlined"
                shape="rounded"
                classes={{
                  root: "farm_pagination",
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.emptyHistoryWrapper}>
            <Typography>You don't have any transaction history</Typography>
          </Box>
        )}
      </>
    )
  }
}

export default withStyles(styles)(withRouter(TransactionHistoryTable))
// Customizable Area Start
