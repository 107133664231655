import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  // Customizable Area End
  close?: any;
  backToParent?: any
  open?: any;
  closeInnerModal?: any;
}

interface S {
  // Customizable Area Start
  // Customizable Area End
  resetPWSchema: any;
  resetPWError: Array<Object>;
  sentResetPWEmail:boolean
  isLoading:boolean;
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiResetPWApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    const resetPWSchema = Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Invalid email address format")
        .required("Email is required")
    })

    this.state = {
      resetPWSchema: resetPWSchema,
      sentResetPWEmail: false,
      resetPWError: [],
      isLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.apiResetPWApiCallId) {
          if (
            responseJson.message &&
            responseJson.message ===
              "For reset password email sent successfully"
          ) {
            this.setState({ sentResetPWEmail: true });
          } else if (responseJson.hasOwnProperty("errors")) {
            this.setState({
              sentResetPWEmail: false,
              resetPWError: responseJson.errors
            });
          }
          this.setState({ isLoading: false });
        }
      }
    }
    // Customizable Area End
  }

  doResetPassword = async (values: { email: string }): Promise<boolean> => {
    this.setState({ isLoading:true })

    const header = {
      "Content-Type": configJSON.restPWApiContentType,
    }

    const httpBody = {
      email: values.email.trim() ? values.email.trim() : "",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPWApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPWAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetPWAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
