// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import Setting from "../../Settings/src/Setting.web";
import OrderHistoryController, { Props } from "./OrderHistoryController.web";
import "../assets/css/orderHistory.scss";
import CustomizedTabs from "./CustomizedTabs.web";

class OrderHistory extends OrderHistoryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Setting>
          <CustomizedTabs />
        </Setting>
      </>
    );
  }
}

export default OrderHistory;
// Customizable Area End
