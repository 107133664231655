// Customizable Area Start
import React from "react"
import { Grid } from "@material-ui/core"
import SearchCategoryLayout from "./SearchCategoryLayout.web"
import FarmsSectionsImgCard from "../../../../components/src/FarmsSectionsImgCard.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ISearchFarm } from "../interfaces/search"
const configJSON = require("../config.js")

interface ISearchFarmsList {
  searchQuery: string
  isViewAll: boolean
  farmsList: ISearchFarm[]
}

const SearchFarmsList = ({
  searchQuery,
  isViewAll,
  farmsList,
}: ISearchFarmsList) => {
  const redirectUrl = isViewAll
    ? `${AppRoutings.SearchFarms}?${configJSON.queryText}=${searchQuery}`
    : ""

  return farmsList.length > 0 ? (
    <SearchCategoryLayout
      heading={configJSON.farmsText}
      viewAllRedirectUrl={redirectUrl}
    >
      <Grid container spacing={3}>
        {farmsList.map((farm: ISearchFarm) => {
          const {
            id,
            attributes: { name, description, images, average_rating },
          } = farm
          const imageUrl = images && images.url ? images.url : ""
          const redirectUrl = `${AppRoutings.FarmDetails}/${id}`

          return (
            <Grid item xs={12} sm={6} md={3} key={id}>
              <FarmsSectionsImgCard
                cardName={name}
                description={description}
                thumbnail={imageUrl}
                displayPrice={configJSON.noneText}
                displayButton={configJSON.blockText}
                displayDuration={configJSON.noneText}
                displayRating={configJSON.blockText}
                average_rating={average_rating}
                boxShadow={false}
                path={redirectUrl}
                imgPath={redirectUrl}
              />
            </Grid>
          )
        })}
      </Grid>
    </SearchCategoryLayout>
  ) : null
}

export default SearchFarmsList
// Customizable Area End
