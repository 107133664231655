import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface IWeekDays {
  openDay:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";
}

export interface IDay {
  id: string
  opensAt: string | null
  closesAt: string | null
  openDay: string
  isOpen: boolean
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  location?: any;
  history?: any;
  classes?: any;
  logout : any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean
  isLoading: boolean
  allDays: IDay[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestaurantAvailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  createRestAvailApiCallId: any
  createBulkRestAvailCallId: any
  getAllAvailabilityApiCallId: any
  updateResAvailApiCallId: any
  weekDays = {
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
    'Sunday': 7
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loading: false,
      isLoading: false,
      allDays: []
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    if (!!id) this.getAllAvailabilities(id)
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !(responseJson.error || responseJson.errors)) {
        //get availabilities
        if (apiRequestCallId === this.getAllAvailabilityApiCallId) {
          const Days: IDay[] = responseJson.data.map((day: any) => {
            const { is_open, opens_at, closes_at, open_day } = day.attributes
            return {
              id: day.id,
              isOpen: is_open,
              opensAt: opens_at,
              closesAt: closes_at,
              openDay: open_day
            }
          }).sort((a: IWeekDays, b: IWeekDays) => this.weekDays[a.openDay] - this.weekDays[b.openDay])

          this.setState({
            isLoading: false,
            allDays: Days
          })
        }

        //single availability update 
        if (apiRequestCallId === this.updateResAvailApiCallId) {
          toast.success("Timings Updated Successfully")
          this.props.history.push('/admin/restaurant/details')
        }
      } else {
        this.setState({
          loading: false,
          isLoading: false
        })
        if (responseJson?.errors) {
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })
          if(responseJson?.errors[0] && ((responseJson?.errors[0].message.includes(["Token has Expired","Invalid token"])))) {
             this.props.logout()
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  showErrors(errors: any) {
    errors?.forEach((err: any) => {
      toast.error(err?.message);
    });
  }

  getAllAvailabilities = async (id: any) => {
    this.setState({
      isLoading: true
    });

    const headers = {
      // "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllAvailabilityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAvailabilities + `?restaurant_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateAvailability = async (availabilities: any) => {
    this.setState({
      loading: true,
    })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateResAvailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionResAvailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(availabilities)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleAvailabilityUpdate = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    const formatDays = this.state.allDays
      .filter((day: IDay) => day.isOpen)
      .map((day: IDay) => {
        const { id, opensAt, closesAt } = day;

        return {
          id,
          opens_at: opensAt,
          closes_at: closesAt,
        };
      });

    const payload = {
      restaurant_id: id,
      restaurant_availabilities: formatDays,
    };
    this.updateAvailability(payload);
  }  

  isAllDayOpen = () => {
    return this.state.allDays.every((day: IDay) => day.isOpen)
  }

  toggleAllDayAvailability = () => {
    const isAllDayOpen = this.isAllDayOpen()

    const newList = this.state.allDays.map((day: IDay) => {
      return {
        ...day,
        isOpen: !isAllDayOpen
      }
    })
    this.setState({ allDays: newList })
  }

  handleChange = (dayId: string, key: string, value: string | boolean) => {
    const newList = this.state.allDays.map((day: IDay) => {
      if (day.id === dayId) {
        return {
          ...day,
          [key]: value
        }
      }
      return day
    })
    this.setState({ allDays: newList })
  }
  // Customizable Area End

}