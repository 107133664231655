// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { isTokenExpired } from "../../../components/src/utility/helper";
const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
}

interface S {
  loader: boolean;
  heading: string;
  content: string;
}

interface SS {
  id: any;
}

export default class HyperLinkContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getHyperLinkContentApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loader: false,
      heading: "",
      content: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.handleHyperLinkContent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { match: { params } } = this.props
    if(prevProps.match.params.id !== params.id) {
      this.handlePageTopScroll()
      this.handleHyperLinkContent()
    }
  }

  handlePageTopScroll = () => {
    const headerElement = document.getElementsByClassName("web_header")

    if(headerElement && headerElement.length > 0) {
      headerElement[0].scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleHyperLinkContent = () => {
    const {
      location: { state },
      match: { params }
    }: any = this.props;
    this.setState({ heading: state });
    this.getHyperLinkContentApi(params.id);
  };

  getHyperLinkContentApi = async (id: string) => {
    this.setState({ loader: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHyperLinkContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hyperLinkContentApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleApiError = () => {
    toast.error("Something went wrong");
    this.handleGoBack();
  };

  handleSuccessApiResponseData = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getHyperLinkContentApiCallId) {
      if (responseJson.data) {
        this.setState({
          loader: false,
          content: responseJson.data.attributes.description
        });
        return;
      }
      this.handleApiError();
    }
  };

  handleErrorApiResponseData = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.getHyperLinkContentApiCallId) {
      this.handleApiError();
    }
  };

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseData && !responseData.errors) {
        this.handleSuccessApiResponseData(apiCallId, responseData);
      } else {
        if (await isTokenExpired(responseData)) {
          return;
        }
        this.handleErrorApiResponseData(apiCallId, responseData);
      }
    }
  }
}
// Customizable Area End
