// Customizable Area Start
import React from "react";
import { Like_Icon, Chat_Icon, DustbinIcon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import CustomButton from "../../../components/src/CustomButton.web";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { commonCmptStyles } from "./dropDownCss";
import { withRouter } from "react-router-dom";
import AdminRestaurantForumsController, {
  Props, configJSON
} from "./AdminRestaurantForumsController.web";
import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
  IconButton,
  CircularProgress,
  Grid
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../assets/css/adminRestaurantForumsEdit.scss";
import RestaurantReviewAndReply from "../../../components/src/RestaurantReviewandReply.web";
import CustomTextAreaField from "../../../components/src/CustomTextAreafield.web";
import { Formik, Form, ErrorMessage } from "formik";
import clsx from 'clsx';

// Customizable Area End

class AdminRestaurantForumsEdit extends AdminRestaurantForumsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  getImageAtt = (banner: any, setFieldValue: (field: string, value: any) => void) => {
    return (
      <>
        {banner ? (
          <div className="imageContainer">
            <div className="image">
              {banner?.length > 0 &&
                banner[0].name ? (
                <img
                  src={(window.URL || window.webkitURL).createObjectURL(
                    //@ts-ignore
                    banner[0]
                  )}
                  alt="banner"
                />
              ) : (
                <img src={banner.url} alt="banner" />
              )}
            </div>
            <label className="editBannerBtn">
              <input
                id="images"
                name="images"
                type="file"
                hidden
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e: any) => setFieldValue("banner", e.currentTarget.files)}
              />
              {configJSON.editBannerText}
            </label>
          </div>
        ) : (
          <div className="mediaContainer forumsMediaContainer">
            <div className="mediaUploader">
              <label>
                <input
                  id="banner"
                  name="banner"
                  type="file"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e: any) => setFieldValue("banner", e.currentTarget.files)}
                />
                <BackupOutlinedIcon />
                <span>{configJSON.dragFileText}</span>
              </label>
            </div>
          </div>
        )}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const {
      productMode,
      replyEditId,
      openDialog,
      loading
    } = this.state
    const restStatus = configJSON.campaignStatusMenu
    const isEdit = productMode === configJSON.editText
    const restForumDetails = this.state.restForumDetails?.attributes

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            heading: restForumDetails?.heading || "",
            description: restForumDetails?.description || "",
            status: restForumDetails?.status || configJSON.activeText,
            banner: restForumDetails?.banner || "",
            forumable_type: configJSON.restaurantLabel,
            forumable_id: window.location.pathname.split("/")[4],
            reply: "",
            replyEdit: "",
            review : ""
          }}
          validationSchema={this.Schema}
          onSubmit={(values: any) => this.createRestForum(values)}
        >
          {({ values, setFieldValue, handleChange }) => {
            return (
              <Form>
                <Box className="restaurantForumsEditContainer">
                  <Grid item xs={12} className="header">
                    <div className="leftSideHeader">
                        <IconButton data-test-id="backBtn" onClick={() => this.props.history.goBack()}>
                          <ArrowBackIcon />
                        </IconButton>
                      <h4>
                        {this.getHeading()}
                      </h4>
                    </div>
                    {!loading && <Grid item xs={6} className="rightSideHeader commonDropdown">
                      <div
                          className={clsx('customSelect campaignSelect itemStatus', {
                            ['warning-variant']: values.status == configJSON.draftStatus
                          })}
                      >
                        <FormControl>
                          <Select
                            data-test-id="setStatus"
                            className="menu-customize"
                            value={values.status}
                            onChange={(e: any) => setFieldValue(configJSON.statusText, e.target.value)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: configJSON.bottomText,
                                horizontal: configJSON.leftText,
                              },
                              transformOrigin: {
                                vertical: configJSON.topText,
                                horizontal: configJSON.leftText,
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: classes.itemStatus,
                              },
                            }}
                          >
                            {restStatus.map((status: string) => {
                              return (
                                <MenuItem value={status} key={status}>
                                  {status}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      {isEdit ? (
                        <img
                          src={DustbinIcon}
                          className="delIconSize"
                          onClick={() => {
                            this.setState({
                              removedProduct: this.state.restForumDetails.id,
                            });
                            this.handleDialog();
                          }}
                        />
                      ) : null}
                    </Grid>}
                  </Grid>
                  {loading ? (
                    <div className="loader_container">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="forumMainWrapper">
                      {this.getImageAtt(values.banner, setFieldValue)}
                      <span className="errorMsg">
                        <ErrorMessage name="banner" />
                      </span>

                      <div className="forumResponseContainer">
                        {isEdit && (
                          <div className="likesCommentsContainer">
                            <div className="likeComments">
                              <img src={Like_Icon} alt="Like_Icon" />
                              <span className="count">
                                {this.getCount(restForumDetails?.likes)}
                              </span>
                              <span>{configJSON.likesText}</span>
                            </div>
                            <div className="likeComments">
                              <img src={Chat_Icon} alt="Like_Icon" />
                              <span className="count">
                                {this.getCount(restForumDetails?.comments)}
                              </span>
                              <span>{configJSON.commentsText}</span>
                            </div>
                          </div>
                        )}
                        <div className="buttonContainer">
                          <Button className="save" type="submit">
                            {!isEdit ? configJSON.createButtonText : configJSON.saveChangesText}
                          </Button>
                        </div>
                      </div>

                      <div className="forumHeaderDescription">
                        <h4>{configJSON.headingText}</h4>
                        <div className="customTextArea">
                          <textarea
                            cols={30}
                            rows={2}
                            id="heading"
                            value={values.heading}
                            placeholder={configJSON.forumHeadingPlaceholder}
                            onChange={(e: any) => this.handleEditForumHeading(e.target.value, setFieldValue)}
                          />
                          <span className="textCount">
                            {values.heading.split(" ").length}/25
                          </span>
                        </div>
                        <span className="errorMsg">
                          <ErrorMessage name="heading" />
                        </span>
                      </div>
                      <div className="forumHeaderDescription">
                        <h4>{configJSON.descriptionText}</h4>
                        <textarea
                          cols={30}
                          rows={10}
                          id="description"
                          value={values.description}
                          placeholder={configJSON.forumDescPlaceholder}
                          onChange={handleChange}
                          name="description"
                        />
                        <span className="errorMsg">
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                      {isEdit && (
                        <div className="commentContainer">
                          {restForumDetails?.comments > 0 && <h4>{configJSON.commentsText}</h4>}

                          {restForumDetails?.comments > 0 &&
                            restForumDetails?.reviews?.data?.map((review: any) => {
                              return (
                                <>
                                  <RestaurantReviewAndReply
                                    data-test-id="review_reply"
                                    user={true}
                                    reviewdata={review}
                                    setFieldValue={setFieldValue}
                                    deleteReview={this.deleteReview}
                                    handleReviewReply={this.handleReviewReply}
                                    handleReplyEditId={this.handleReplyEditId}
                                  />

                          {review.id == this.state.replyId && (
                            <div className="newComment">
                                    <CustomTextAreaField
                                      data-test-id="replyBox"
                                      name="replyBox"
                                      cols={50}
                                      rows={4}
                                      placeholder={configJSON.commentPlaceholder}
                                      value={values.reply}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          configJSON.replyText,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <div className="commentbtn">
                                    <Button
                                      data-test-id="cancelBtn"
                                        variant="contained"
                                        onClick={() => this.handleReviewReply("", setFieldValue)}
                                      >
                                       {configJSON.cancelText} 
                                      </Button>
                                          <Button
                                            data-test-id="sendButton"
                                           variant="contained"
                                           className="sendBtn"
                                          onClick={() => this.handleCommentReply(review.id, values.reply, setFieldValue)}
                                         >
                                           {configJSON.sendBtnText}
                                         </Button>
                                    </div> 
                            </div>
                          )}  
                                  
                                  {review?.attributes?.replies?.data.map(
                                    (reply: any) => {
                                      return (
                                        <>
                                          {reply.id == replyEditId ? 
                                          <>
                                            <div className="newComment newCommentreply" >
                                                    <CustomTextAreaField
                                                      data-test-id="replyDesc"
                                                      cols={50}
                                                      rows={4}
                                                      defaultValue={reply.attributes.description}
                                                      value={values.replyEdit}
                                                      onChange={(e: any) => {
                                                        setFieldValue(
                                                         configJSON.replyEditText,
                                                          e.target.value
                                                        );
                                                      }}
                                                    />

                                                  
                                                <div className="commentbtn farmforumreply">
                                                <Button variant="contained" className="cancelBtn" onClick={() => this.handleReplyEditId("", setFieldValue)}>
                                                {configJSON.cancelText}
                                                </Button>
                                                <Button
                                                data-test-id="sendEditReply"
                                                  variant="contained"
                                                  onClick={() => this.handleEditCommentReply(values.replyEdit, reply.id, setFieldValue)}
                                                >
                                                  {configJSON.sendBtnText}
                                                </Button>
                                                </div>
                                                </div>
                                        </> : (
                                            <div className="replyComment" key={reply.id}>
                                              <RestaurantReviewAndReply
                                                data-test-id="replySection"
                                                user={false}
                                                replyData={reply}
                                                setFieldValue={setFieldValue}
                                                deleteReplyReview={this.deleteReplyReview}
                                                handleReplyEditId={this.handleReplyEditId}
                                                handleReviewReply={this.handleReviewReply}
                                              />
                                            </div>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              );
                            })}  
                        </div>
                      )}
                    </div>)}
                  <div data-test-id="dialogBoxForum">
                    <Dialog
                      data-test-id="delForumDialog"
                      open={openDialog}
                      onClose={() => {this.handleDialog()}}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {configJSON.deleteForumText}
                      </DialogTitle>
                      <DialogActions>
                        <span className="redContained customCancelBtn">
                          <CustomButton
                            data-test-id="noDelForum"
                            color="secondary"
                            label="No"
                            onClick={() => this.handleDialog()}
                          />
                        </span>
                        <span className="greenContained">
                          <CustomButton
                            color="secondary"
                            label="Yes"
                            onClick={() => {
                              this.handleDialog();
                              this.deleteRestForum();
                            }}
                            autoFocus
                          />
                        </span>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantForumsEdit}
export default withRouter(withStyles(commonCmptStyles)(AdminRestaurantForumsEdit));
// Customizable Area End
