import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { toast } from "react-toastify";
import { isTokenExpired } from "../../../components/src/utility/helper";
import { RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../components/src/utility/app-routing";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    activeTab: number
    history :any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    orderHistory: any
    loader: boolean
    isInvoiceDialog: boolean
    orderInvoiceId: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AllOrderHistoryController extends BlockComponent<
    Props,
    S,
    SS
> {

    orderHistoryApiCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            orderHistory: {},
            loader: false,
            isInvoiceDialog: false,
            orderInvoiceId: ''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.handleChangeOrderHistoryType()
    }

    async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
        if(prevProps.activeTab !== this.props.activeTab) this.handleChangeOrderHistoryType()
    }

    handleCourseDetailsRedirect = (status:string,id: string) => {
        if(status === configJSON.draftText || status === configJSON.deactivateText){
            toast.error(configJSON.courseNotAvailableText)
        }
        else this.props.history.push(`${AppRoutings.CourseDetails}${id}`)
    }

    openInvoiceDialog = (id: string) => {
        this.setState({ isInvoiceDialog: true, orderInvoiceId: id })
    }

    closeInvoiceDialog = () => {
        this.setState({ isInvoiceDialog: false, orderInvoiceId: '' })
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors && !responseJson.error) {

                if (apiRequestCallId === this.orderHistoryApiCallId) {
                    this.setState({ orderHistory: responseJson, loader: false })
                }

            } else {
                if(await isTokenExpired(responseJson)) return

                if (apiRequestCallId === this.orderHistoryApiCallId) {
                    this.setState({ orderHistory: {}, loader: false })
                    responseJson?.errors?.forEach((err: any) => {
                        toast.error(err?.message)
                    })
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleChangeOrderHistoryType = () => {
        let activeOrder = ""

        switch(this.props.activeTab) {
            case 0: 
                activeOrder = configJSON.farmText
                break;
            case 1:
                activeOrder = configJSON.restaurantText
                break;
            case 2:
                activeOrder = configJSON.courseText
                break;
            default:
                activeOrder = configJSON.farmText
                break;
        }

        this.getOrderHistory(activeOrder)
    }

    getOrderHistory = async (activeTab: string) => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.orderHistoryApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.orderHistoryApiEndPoint}${activeTab}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    // Customizable Area End
}
