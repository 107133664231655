// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  FilledInput,
  Button,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Dialog,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import TopBar from "../../../components/src/TopBar.web";
import WhiteBackgroundContainer from "../../../components/src/WhiteBackgroundContainer.web";
import "../assets/css/createroleprofile.scss";
import { withRouter } from "react-router-dom";
import CreateEducatorProfileWebController, {
  Props,
} from "./CreateEducatorProfileWebController.web";
import EducatorDonePage from "./EducatorDonePage.web";
import { Formik, Form, ErrorMessage } from "formik";
import Editor from "../../../components/src/TaskTextEditor";
import {
  sentOTP1Action,
  sentOTP2Action,
  verifyOTP1Action,
  verifyOTP2Action,
  setSameContactDetails,
  resatOTPFlags,
  verifyUserAction,
  ProfileImg,
} from "./assets";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import * as Yup from "yup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// Customizable Area End

const Schema = Yup.object().shape({
  firstName: Yup.string().required("This field is required."),
  lastName: Yup.string().required("This field is required."),
  heading: Yup.string().required("This field is required."),
  title: Yup.string().min(50, "Atleast 50 characters required"),
  image: Yup.mixed().required("This field is required.").test("fileSize", "Maximum 512kb required",
  //@ts-ignore
  (value:any) =>{
    if(value){
      if(value[0] && value[0]!="h"){
        return value[0]?.size <= 512000
      }else{
         return true
      }
    }})
  .test("fileSize", "Minimum 20kb required",
  //@ts-ignore
   (value:any) =>{
    if(value){
      if(value[0] && value[0]!="h"){
        return value[0]?.size >= 20000
      }else{
         return true
      }
    }}),

});



class EducatorRoleProfileLayout extends CreateEducatorProfileWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {
      verify_OTP_click1,
      createError,
      isLoading,
      openModal
    } = this.state
    const { userInfo } = this.props

    return (
      <>
        <Formik
          enableReinitialize
          // innerRef={this.state.myRef}
          initialValues={{
            firstName: "",
            lastName: "",
            heading: "",
            title: "",
            website: "",
            twitter: "",
            facebook: "",
            linkedIn: "",
            youTube: "",
            twitterUsername: "",
            facebookUsername: "",
            linkedInUsername: "",
            youTubeUsername: "",
            image: ""


          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.createContributor(values);
          }}
        >
          {(formikProps) => {
            const {
              values,
              setFieldValue,
              handleChange,
              touched,
              errors,
              handleSubmit,
            } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <TopBar />
                <WhiteBackgroundContainer>
                  <Box className="create_role_main_wrapper">
                    <Button
                      classes={{
                        root: "create_profile_nav_btn create_profile_back",
                        disabled: "select_role_move_disabled"
                      }}
                      onClick={() => this.handleBack()}
                    >
                      <ArrowBackIcon />
                    </Button>
                    {console.log(values, "errors")}
                    <Container className="create_profile_container">
                      <Box className="create_role_profile_form_container">

                        <Grid container>
                          <Grid item xs={12}>
                            <h3>Create Educational Contributor Profile</h3>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="headerLearning">
                              <h4>
                                Profile & Settings
                              </h4>
                            </div>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                First Name
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  id="firstName"
                                  value={values.firstName}
                                  placeholder="Enter First Name"
                                  inputProps={{ maxLength: 100 }}
                                  onChange={handleChange}
                                  error={touched.firstName && Boolean(errors.firstName)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="firstName" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Last Name
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  id="lastName"
                                  value={values.lastName}
                                  placeholder="Enter Last Name"
                                  inputProps={{ maxLength: 100 }}
                                  onChange={handleChange}
                                  error={touched.lastName && Boolean(errors.lastName)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="lastName" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Headline
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  id="heading"
                                  value={values.heading}
                                  placeholder="Instructor for at"
                                  inputProps={{ maxLength: 200 }}
                                  onChange={handleChange}
                                  error={touched.heading && Boolean(errors.heading)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="heading" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Biography
                              </h6>
                              <Box className="web_input_field" style={{
                                "marginLeft": "-19px",
                                "marginRight": "-16px"
                              }}>
                                <Editor placeholder="" values={values}
                                  setFieldValue={setFieldValue} />
                                <p className="helperText">
                                  Your biography should have at least 50 characters, links and coupons code are not permitted.
                                </p>
                                <span className="errorMsg"
                                  style={{
                                    "marginLeft": "24px",
                                  }}>
                                  <ErrorMessage name="title" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Website
                              </h6>
                              <Box className="web_input_field">
                                <FilledInput
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  id="website"
                                  value={values.website}
                                  placeholder="URL"
                                  inputProps={{ maxLength: 100 }}
                                  onChange={handleChange}
                                  error={touched.website && Boolean(errors.website)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="website" />
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Twitter
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.twitter.com/"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root urlClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="twitter"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={values.twitter}

                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root userNameClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="twitterUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={values.twitterUsername}

                                />

                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Facebook
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.facebook.com/"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root urlClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="facebook"
                                  onChange={handleChange}
                                  value={values.facebook}
                                  aria-describedby="filled-weight-helper-text"
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root userNameClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="facebookUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={values.facebookUsername}

                                />

                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Linkedin
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.linkedin.com/"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root urlClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="linkedIn"
                                  value={values.linkedIn}
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root userNameClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="linkedInUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={values.linkedInUsername}

                                />

                              </Box>
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box>
                              <h6 className="create_profile_label">
                                Youtube
                              </h6>
                              <Box className="web_input_field endorementUser">
                                <FilledInput
                                  placeholder="http:www.youtube.com/"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root urlClass",
                                    input: "create_role_profile_input",
                                  }}
                                  aria-describedby="filled-weight-helper-text"
                                  id="youTube"
                                  onChange={handleChange}
                                  value={values.youTube}

                                />
                                <FilledInput
                                  placeholder="Username"
                                  inputProps={{ maxLength: 200 }}
                                  classes={{
                                    root: "create_role_profile_input_root userNameClass",
                                    input: "create_role_profile_input",
                                  }}
                                  id="youTubeUsername"
                                  onChange={handleChange}
                                  aria-describedby="filled-weight-helper-text"
                                  value={values.youTubeUsername}

                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="headerLearning">
                              <h4>
                                Profile & Settings
                              </h4>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <h6 className="create_profile_label">
                                Image preview
                              </h6>
                              <p className="helperText helperText2">
                              Minimum 20kb, Maximum 512kb
                              </p>
                              <Box className="web_input_field endorment">
                                <div className="mediaUploaderLearning">

                                  <img
                                    src={
                                      values.image[0] ?
                                        (
                                          window.URL ||
                                          window.webkitURL
                                          //@ts-ignore
                                        ).createObjectURL(values.image[0]) : ProfileImg
                                    }

                                    className="no-profile" alt="no-profile" />
                                </div>


                                <Box className="educationalCartUpload">
                                  {/* @ts-ignore */}
                                  <div className="uploadTitle">{values.image[0] ? values.image[0].name : "No file selected"}</div>
                                  <input type="file" name="image" accept="image/png, image/jpeg, image/jpg" id="image" hidden className="inputfile"
                                    onChange={(e: any) => {
                                      e.target.files.length>0 && setFieldValue("image", e.target.files)
                                    }}
                                  />
                                  <label htmlFor="image">Upload</label>

                                </Box>
                                <span className="errorMsg">
                                  <ErrorMessage name="image" />
                                </span>



                              </Box>
                            </Box>
                          </Grid>
                          {createError?.length > 0 &&
                            createError.map((err: { message: string }, key: number) => {
                              return (
                                <Grid item xs={12}>
                                  <Alert color="error" key={key}>
                                    {
                                      err.message
                                    }
                                  </Alert>
                                </Grid>
                              );
                            })}
                          <Grid item xs={12} sm={4} md={2}>
                            <Button
                              classes={{ root: "create_role_profile_btn" }}
                              disabled={isLoading}
                              onClick={() => {
                                handleSubmit();
                                this.setState({ createProfile: true });
                              }}
                            >
                              {isLoading ? (
                                <CircularProgress size={30} color="inherit" />
                              ) : (
                                "Save"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                    <Button
                      disabled
                      classes={{
                        root: "create_profile_nav_btn create_profile_right",
                        disabled: "select_role_move_disabled"
                      }}
                    >
                      <ArrowForwardIcon />
                    </Button>
                  </Box>
                </WhiteBackgroundContainer>
                <Dialog
                  disableBackdropClick
                  maxWidth="sm"
                  open={openModal}
                  onClose={() => this.closeModal()}
                  classes={{
                    paper: "custom_dialog_paper"
                  }}
                >
                  <EducatorDonePage />
                </Dialog>
              </Form>
            )
          }}
        </Formik>

      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, {
    sentOTP1Action,
    sentOTP2Action,
    verifyOTP1Action,
    verifyOTP2Action,
    setSameContactDetails,
    resatOTPFlags,
    verifyUserAction,
  })(EducatorRoleProfileLayout)
)
// Customizable Area End
