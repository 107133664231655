// Customizable Area Start
import React from "react";
import Setting from "../../Settings/src/Setting.web";
import WishlistController, { Props } from "./WishListController.web";
import "../assets/css/wishlist.scss";
import WebHeaderWeb from "../../../components/src/WebHeader.web";
import WebRoutingTab from "../../../components/src/WebNewRoutingTabs.web";
import WishlistTabs from "./WishlistTabs.web"

export default class WishListWeb extends WishlistController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <WebHeaderWeb />
        <WebRoutingTab />
        <Setting>
          <WishlistTabs />
        </Setting>
      </div>
    );
  }
}
// Customizable Area End
