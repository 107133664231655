// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import CustomButton from "../../../components/src/CustomButton.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  TableContainer,
  Paper,
  Table,
  TextField,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import AdminProductListController, {
  Props,
} from "./AdminProductListController.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";
import {
  sentOTP1Action,
  sentOTP2Action,
  verifyOTP1Action,
  verifyOTP2Action,
  setSameContactDetails,
  resatOTPFlags,
  verifyUserAction,
} from "./assets";
import { connect } from "react-redux";
import Loader from "../../../components/src/Loader.web"
const configJSON = require("./config.js")

interface IStatus {
  label: string
  value: string
}

interface EnhancedTableProps {
  numSelected?: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
            data-testid="allcheckBox"
              checked={rowCount > 0 && numSelected === rowCount}
              // checked={cheked}
              onChange={(e: any) => {
                onSelectAllClick(e);
              }}
              id={34}
            />
            <span>PRODUCT NAME</span>
          </div>
        </TableCell>
        <TableCell align="center">PRODUCT TYPE</TableCell>
        <TableCell align="center">STATUS</TableCell>
        <TableCell align="center">#ID</TableCell>
        <TableCell align="center">STOCK</TableCell>
        <TableCell align="center">PRICE</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export class ProductList extends AdminProductListController {
  constructor(props: Props) {
    super(props);
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected =
        this.state.productList && this.state.productList.map((n: any) => n.id);
      this.setState({ selected: newSelected });

      return;
    } else {
      this.setState({ selected: [] });
    }
  };

  handleChangeTablePage = (value: number) => {
    this.setState({ currentPage: value });
    this.getProductList(
      this.state.currentFarmId,
      this.state.sortValue,
      this.state.categorySelected,
      this.state.search,
      this.state.prodPerPage,
      value
    );
  };

  handleIncreasePageView = () => {
    if (this.state.prodPerPage < 25) {
      this.setState({
        prodPerPage: this.state.prodPerPage + 5,
        currentPage: 1,
      });
      this.getProductList(
        this.state.currentFarmId,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage + 5,
        1
      );
    }
  };

  handleDecreasePageView = () => {
    if (this.state.prodPerPage > 5) {
      this.setState({
        prodPerPage: this.state.prodPerPage - 5,
        currentPage: 1,
      });
      this.getProductList(
        this.state.currentFarmId,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage - 5,
        1
      );
    }
  };

  render() {
    const indexOfLastList = this.state.currentPage * this.state.prodPerPage;
    const indexOfFirstList = indexOfLastList - this.state.prodPerPage;
    const cureentTableList = this.state.productList
      ? this.state.productList
      : "";
    const roundPages =
      cureentTableList &&
      Math.floor(this.state.total_count / this.state.prodPerPage);
    const allSorts = ["default", "high to low", "low to high", "latest"];
    let totalListCount;
    if (this.state.currentPage > roundPages) {
      totalListCount = this.state.total_count;
    } else {
      totalListCount = indexOfLastList;
    }
    const { classes } = this.props;
    const menu = ["edit", "remove"];
    //@ts-ignore
    const farmExit = window.location.pathname.split("/")[4] == 0;
    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown adminProdListContainer">
          {/* filter section */}
          <Loader loading={this.state.loading} />

          <div className="headerListContainer">
            <div className="prodListHeader">
              <div className="blocks">
                <h4>Products List</h4>
                <div className="block1">
                  <div className="customSelect farmOrdersDropDown dishCategorySelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="menu-customize"
                        data-testid="categories"
                        value={this.state.categorySelected}
                        onChange={(e: any) => {
                          this.setState({
                            categorySelected: e.target.value,
                            currentPage: 1,
                          });
                          this.getProductList(
                            this.state.currentFarmId,
                            this.state.sortValue,
                            e.target.value,
                            this.state.search,
                            this.state.prodPerPage,
                            1
                          );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown,
                          },
                        }}
                      >
                        <MenuItem value="all">
                          <span>All Products</span>
                          <span className="checkmark">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {this.state.categories &&
                          (this.state.categories.length > 0 &&
                            this.state.categories.map(
                              (item: any, index: any) => {
                                return (
                                  <MenuItem value={item.name} key={item.id}>
                                    <span>{item.name}</span>
                                    <span className="checkmark">
                                      <div className="checkmark_circle" />
                                      <div className="checkmark_stem" />
                                      <div className="checkmark_kick" />
                                    </span>
                                  </MenuItem>
                                );
                              }
                            ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="prodFarmSelect customSelect farmOrdersDropDown dishCategorySelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="farms"
                        className="menu-customize"
                        value={this.state.currentFarmId}
                        disabled={farmExit}
                        onChange={(e: any) => {
                          const targetValue = e.target.value;
                          this.setState({
                            currentPage: 1,
                          });
                          this.handleFarmsOnChange(targetValue)
                          
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown,
                          },
                        }}
                      >
                        <MenuItem value={0} className="">
                          <span>No Farms Listed</span>
                          <span className="checkmark">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {this.state.farmsList &&
                          this.state.farmsList.length > 0 &&
                          this.state.farmsList.map(
                            (item: any, index: any) => {
                              return (
                                <MenuItem value={item.id} key={item.id}>
                                  <span>{item.attributes.name}</span>
                                  <span className="checkmark">
                                    <div className="checkmark_circle" />
                                    <div className="checkmark_stem" />
                                    <div className="checkmark_kick" />
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                  <div className="customSelect dishSortSelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="menu-customize"
                        data-testid="sort"
                        value={this.state.sortValue}
                        onChange={(e) => {
                          const id = this.state.currentFarmId;
                          this.setState({
                            sortValue: e.target.value,
                            currentPage: 1,
                          });
                          id != "0" &&
                            id != "00" &&
                            this.getProductList(
                              id,
                              e.target.value,
                              this.state.categorySelected,
                              this.state.search,
                              this.state.prodPerPage,
                              this.state.currentPage
                            );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper:
                              classes.sortDropDown +
                              " " +
                              classes.commonSelectui,
                          },
                        }}
                        autoWidth={true}
                      >
                        {allSorts.map((sort: any, index: any) => {
                          return (
                            <MenuItem value={sort} key={sort}>
                              {sort}
                              <span className="checkmark">
                                <div className="checkmark_circle" />
                                <div className="checkmark_stem" />
                                <div className="checkmark_kick" />
                              </span>{" "}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                  {/* <a href="#"><img src={FilterIcon} className="FilterIcon" alt="Filter Icon" /></a> */}
                  {/* <CustomButton>
                    Save Changes
                  </CustomButton> */}
                  <div
                    className="addButton"
                    data-testid='addBtn'
                    onClick={() => {
                      this.props.history.push(
                        `/admin/farm/add-product/${
                          window.location.pathname.split("/")[4]
                        }?mode=create`
                      );
                    }}
                  >
                    <CustomButton label="New Product" disabled={farmExit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <div className="customTextfield">
                  <TextField
                  data-testid="search"
                    id="input-with-icon-textfield"
                    value={this.state.search}
                    onChange={(e: any) => {
                      this.setState({
                        search: e.target.value,
                      });
                      this.SearchDebounceUpdate(
                        this.state.currentFarmId,
                        this.state.sortValue,
                        this.state.categorySelected,
                        e.target.value,
                        this.state.prodPerPage,
                        1
                      );
                    }}
                    variant="standard"
                    placeholder="Search by Name, type, Id, etc... "
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className="searchIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="block1 commonDropdown contentHeaderDropdown">
                <div className="outlineSelect">
                  <div className="customSelect categoryDropDown dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label" style={{"color":`${this.state.selected.length > 1 ? "black":"#ccc"}`}}>
                        Actions
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="bulkAction"
                        className="menu-customize"
                        value={this.state.bulkAction}
                        disabled={this.state.selected.length < 2}
                        onChange={(e: any) => {
                          this.bulkActions(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.categoryDropDown,
                          },
                        }}
                      >
                        {configJSON.statusList.map((status: IStatus) => {
                          const { value, label } = status

                          return (
                            <MenuItem value={value} key={value}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block prodListTabel">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" style={{ height: "100%" }}>
                {cureentTableList && cureentTableList.length > 0 && (
                  <EnhancedTableHead
                  data-testid="tableHead"
                    numSelected={this.state.selected.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={cureentTableList && cureentTableList.length}
                  />
                )}
                <TableBody>
                  {cureentTableList?.length > 0 ? (
                    cureentTableList.map((item: any) => {
                      const isItemSelected = this.isSelected(item.id);
                      const x = item.attributes;
                      return (
                        <TableRow key={item.id}>
                          <TableCell
                            component={"th" as any}
                            scope="row"
                            align="center"
                          >
                            <div
                              className="productCell"
                              data-testid="productCell1"
                              onClick={(event: any) =>
                                this.handleClick(event, item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={
                                  x.images &&
                                  x.images.length > 0 &&
                                  x.images[0].url
                                }
                                className="prodImage"
                                alt="image"
                              />
                              <h5>{x.name}</h5>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {x.product_type}
                          </TableCell>
                          <TableCell align="center">
                            {/* itemStatus */}
                            <div
                              className={`customSelect itemStatus ${x.product_status ==
                                "draft" &&
                                "warning-variant"} ${x.product_status ==
                                "deactive" && "danger-variant"}`}
                            >
                              {/* class for red: danger-variant warning-variant*/}
                              <FormControl>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  id="demo-controlled-open-select"
                                  data-testid="status"
                                  value={x.product_status}
                                  onChange={(e: any) => {
                                    this.updateProductStatus(
                                      item.id,
                                      e.target.value
                                    );
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    classes: {
                                      paper: classes.itemStatus,

                                      // class for red: ${classes.dangerVariant}
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  // IconComponent={() => <ExpandMoreIcon />}
                                >
                                  {configJSON.statusList.map(
                                    (status: IStatus) => {
                                      const { label, value } = status

                                      return (
                                        <MenuItem
                                          value={value}
                                          key={value}
                                          style={{
                                            textTransform: "none",
                                          }}
                                          className={` dish_list_status_menus ${this.getMenuStyleCss(value)}`}
                                        >
                                          {label}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {x.product_id}
                          </TableCell>
                          <TableCell align="center">
                            {this.handleInventoryCell(x)}
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            ${x.price_per_unit}/{" "}
                            {x.inventory.available_quantity_unit}
                          </TableCell>
                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                              data-testid="basicMenu"
                                item={item}
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0" }}
                      >
                        <CustomNoRecordsFound
                          routeUrl={`/admin/farm/add-product/${
                            window.location.pathname.split("/")[4]
                          }?mode=create`}
                          title="Product"
                          disable={farmExit}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {cureentTableList && cureentTableList.length > 0 && (
              <div className="paginationRow restOrderPagination">
                <div>
                  {indexOfFirstList + 1}- {totalListCount} of{" "}
                  {this.state.total_count} items
                </div>
                <CustomPagination
                data-testid="customPagination"
                  count={this.state.total_pages}
                  page={this.state.currentPage}
                  pagePerView={this.state.prodPerPage}
                  handlePageChange={this.handleChangeTablePage}
                  shape="rounded"
                  siblingCount={1}
                  increasePagePerView={this.handleIncreasePageView}
                  decreasePagePerView={this.handleDecreasePageView}
                  boundaryCount={1}
                />
              </div>
            )}
          </div>

          {/* remove modal */}
          <div>
            <Dialog
              open={this.state.openDialog}
              data-testid="dialog"
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete the product ?
              </DialogTitle>
              <DialogActions>
                {/* //@ts-ignore */}
                {/* <Button onClick={() => this.handleDialog()}>No</Button> */}
                {/* //@ts-ignore */}
                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    data-testid="dialogNo"
                    onClick={() => this.handleDialog()}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                    color="secondary"
                    label="Yes"
                    data-testid="dialogYes"
                    onClick={() => {
                      this.handleDialog();
                      this.deleteProduct();
                    }}
                    autoFocus
                  />
                </span>
                {/* <Button
                  onClick={() => {
                    this.handleDialog();
                    this.deleteProduct();
                  }}
                  autoFocus
                >
                  Yes
                </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
};

const productCompStyles = {
  productsDropDown: {
    border: 0,
    borderRadius: 5,
    padding: "20px 0",
    "&.MuiList-root": {},
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "white",

      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "white",
      },
    },

    "& .MuiMenuItem-root": {
      padding: "0 30px",
      margin: "10px 0",
      color: "#c1c1c4",
    },
  },
  sortDropDown: {
    borderRadius: "0px 0px 5px 5px",
    boxShadow: "none",
    border: "1px solid #f0f0f0",
    // padding: "20px 0",
    // minWidth: "179px",
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-root": {
      textTransform: "capitalize",
      color: "#212121",
      fontSize: "14px",
      padding: "0 30px",
      margin: "10px 10px",
      fontWeight: 500,
      "&:hover": {
        color: "#20a258",
      },
    },
    "& .MuiMenu-list": {
      minWidth: "200px",
    },
  },
  farmOrdersDropDown: {
    "& .MuiListItem-root": {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      "& span": {
        color: "#4b4b4b",
        textTransform: "capitalize",
        paddingLeft: "25px",
      },
      "&.Mui-selected": {
        "& .checkmark": {
          display: "inline-block",
          width: "19px",
          height: "19px",
          top: "auto",
          transform: "rotate(45deg)",
        },
        "& .checkmark_circle": {
          position: "absolute",
          width: "19px",
          height: "19px",
          backgroundColor: "green",
          borderRadius: "11px",
          left: 0,
          top: 0,
        },
        "& .checkmark_stem": {
          position: "absolute",
          width: "3px",
          height: "11px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "10px",
          top: "3px",
        },
        "& .checkmark_kick": {
          position: "absolute",
          width: "6px",
          height: "3px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "6px",
          top: "11px",
        },
      },
    },
  },
  commonSelectui1: {
    "& .MuiListItem-root": {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 15px",
      margin: "0",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "& .checkmark": {
          display: "inline-block",
          width: "19px",
          height: "19px",
          left: "5px",
          top: "auto",
          transform: "rotate(45deg)",
        },
        "& .checkmark_circle": {
          position: "absolute",
          width: "19px",
          height: "19px",
          backgroundColor: "green",
          borderRadius: "11px",
          left: 0,
          top: 0,
        },
        "& .checkmark_stem": {
          position: "absolute",
          width: "3px",
          height: "11px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "10px",
          top: "3px",
        },
        "& .checkmark_kick": {
          position: "absolute",
          width: "6px",
          height: "3px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "6px",
          top: "11px",
        },
      },
    },
  },
  itemStatus: {
    maxWidth: "176px !important",
    marginTop: "2px",
    border:"1px #20a258 solid",
    "& .MuiMenu-list": {
      backgroundColor: "#e7ffed",
      borderRadius: "5px",
    },
    "& .MuiListItem-root": {
      backgroundColor: "#e7ffed",
      "&.Mui-selected": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        fontSize:"18px"
      },
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#e7ffed",
      color: "#20a258",
      whiteSpace: "normal",
      textTransform: "capitalize !important",
    },

    "& .MuiFormControl-root": {
      marginBottom: "2px",
    },

    "& .MuiMenu-paper": {
      // boxShadow: "none !important",
      minWidth: "210px !important",
      maxWidth: "210px !important",
    },
  },
  listStatus: {
    borderRadius: "0px 0px 5px 5px",
    width: "150px",
    border: "1px solid #f0f0f0",
    padding: "0px 0",
    marginTop: "10px",
    // minWidth: "179px",

    "&.MuiList-root": {},
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "white",
      },
    },
    "& .MuiMenuItem-root": {
      padding: "0 30px",
      margin: "10px 0",
      color: "#c1c1c4",
    },
  },
  categoryDropDown: {
    border: 0,
    borderRadius: 5,
    // padding: "20px 0",
    "&.MuiList-root": {},
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#20a258",
        },
      },
    },
    "& .MuiListItem-button": {
      padding: "5px 30px",
      "&:hover": {
        textDecoration: "none",
        color: "#FFF",
        backgroundColor: "#20a258",
      },
    },
    "& .MuiMenuItem-root": {
      padding: "5px 30px",
      margin: "10px 0",
      color: "#757575",
      fontWeight: "500",
      whiteSpace: "normal",
      textTransform: "capitalize !important",
      "&:hover": {
        backgrounColor: "#20a258",
      },
    },
  },
};

//@ts-ignore
const productListInitial = withStyles(productCompStyles)(ProductList);
export default withRouter(
  //@ts-ignore
  connect(
    mapStateToProps,
    {
      sentOTP1Action,
      sentOTP2Action,
      verifyOTP1Action,
      verifyOTP2Action,
      setSameContactDetails,
      resatOTPFlags,
      verifyUserAction,
    }
  )(productListInitial)
);

// Customizable Area End
