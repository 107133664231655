// Customizable Area Start
import React from "react"
import {
  Box,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core"
import CallMadeRoundedIcon from "@material-ui/icons/CallMadeRounded"
import CloseIcon from "@material-ui/icons/CloseRounded"
import { styled } from "@mui/system"
import SearchIcon from "@material-ui/icons/Search"
import { withRouter } from "react-router-dom"
import AdminSearchController, {
  ISearchResult,
} from "./AdminSearchController.web"
import { DefaultImage } from "../assets"
const configJSON = require("../config.js")

const MainWrapper = styled(Box)(
  ({ isMobileSearchPage }: { isMobileSearchPage?: boolean }) => ({
    maxWidth: isMobileSearchPage ? "none" : "500px",
    width: "100%",
    position: "relative",
  })
)

const SearchField = styled(TextField)({
  backgroundColor: "#f0f0f0",
  borderRadius: "12px",
  width: "100%",
  color: "#969696",
  padding: "13px 15px 13px 4px",
  justifyContent: "flex-start !important",
  "& > div": {
    width: "100%",
  },
  "& input": {
    padding: "0px",
    color: "#969696",
  },
})

const CustomCircularProgress = styled(CircularProgress)({
  color: "#20a258",
})

const CustomRedirectIcon = styled(CallMadeRoundedIcon)({
  color: "#20a258",
})

const ListWrapper = styled(Box)({
  position: "absolute",
  top: "55px",
  padding: "15px 10px",
  backgroundColor: "#fff",
  borderRadius: "20px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
  width: "100%",
  display: "block !important",
  zIndex: 9,
})

const ListContainer = styled(Box)({
  display: "block !important",
  maxHeight: "350px",
  overflowY: "auto",
  padding: "0px 5px",
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "8px",
    borderRadius: "8px",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: "#c3c3c3",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "8px",
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    scrollbarFaceColor: "#f7f7f7",
    scrollbarTrackColor: "#f7f7f7",
  },
})

const ListCardContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "6px",
  cursor: "pointer",
  padding: "8px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
  },
})

const ImageNameWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start !important",
  gap: "10px",
  width: "100%",
  overflow: "hidden",
})

const ItemImage = styled("img")({
  borderRadius: "6px",
  height: "40px",
  width: "40px",
  overflow: "hidden",
})

const NameTagWrapper = styled(Box)({
  alignItems: "flex-start !important",
  flexDirection: "column",
  overflow: "hidden",
})

const NameTag = styled(Typography)({
  fontSize: "15px",
  fontFamily: "'Montserrat', sans-serif",
  lineHeight: 1.4,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
})

const TagField = styled(Typography)({
  borderRadius: "8px",
  color: "#20a258 !important",
  padding: "1px 5px",
  width: "fit-content",
  fontSize: "11px",
  backgroundColor: "rgba(32, 162, 88, 0.09)",
  fontFamily: "'Montserrat', sans-serif",
})

const CustomSearchIcon = styled(SearchIcon)({
  color: "#c1c1c1",
  marginRight: "4px",
  fontSize: "1.4rem",
})

export class AdminSearch extends AdminSearchController {
  render() {
    const { isMobileSearchPage } = this.props
    const {
      loader,
      searchValue,
      isSearchApiCalled,
      isListPopupOpen,
      searchResults,
    } = this.state
    const isSearchPopupOpen = isListPopupOpen && isSearchApiCalled

    const closeIcon = searchValue ? (
      <InputAdornment position="end">
        <IconButton size="small" onClick={this.handleResetSearch}>
          <CloseIcon fontSize="small" color="action" />
        </IconButton>
      </InputAdornment>
    ) : null

    return (
      <MainWrapper isMobileSearchPage={isMobileSearchPage}>
        <SearchField
          value={searchValue}
          inputRef={this.searchFieldRef}
          placeholder={configJSON.SearchSomethingHereText}
          onChange={this.handleSearchValueChange}
          onKeyDown={this.handleSearchFieldKeyDown}
          onClick={this.handleSearchPopupOpen}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <CustomSearchIcon />
              </InputAdornment>
            ),
            endAdornment: loader ? (
              <InputAdornment position="end">
                <CustomCircularProgress size={18} />
              </InputAdornment>
            ) : (
              closeIcon
            ),
            disableUnderline: true,
          }}
        />
        {isSearchPopupOpen && (
          <ClickAwayListener onClickAway={this.handleSearchPopupClose}>
            <ListWrapper>
              <ListContainer>
                {searchResults.length > 0 ? (
                  searchResults.map((result: ISearchResult) => {
                    const { id, type, name, image } = result
                    const imageUrl = image ? image.url : DefaultImage

                    return (
                      <ListCardContainer
                        key={`${id}${type}`}
                        data-testid={`search-${type}-${id}`}
                        onClick={() => this.handleRedirect(result)}
                      >
                        <ImageNameWrapper>
                          <ItemImage src={imageUrl} alt={type} />
                          <NameTagWrapper>
                            <NameTag variant="h6">{name}</NameTag>
                            <TagField>{type}</TagField>
                          </NameTagWrapper>
                        </ImageNameWrapper>
                        <IconButton size="small">
                          <CustomRedirectIcon fontSize="small" />
                        </IconButton>
                      </ListCardContainer>
                    )
                  })
                ) : (
                  <Box>
                    <NameTag
                      data-testid="empty-list"
                      align="center"
                      variant="h6"
                    >
                      {configJSON.noResultsFoundText}
                    </NameTag>
                  </Box>
                )}
              </ListContainer>
            </ListWrapper>
          </ClickAwayListener>
        )}
      </MainWrapper>
    )
  }
}

export default withRouter(AdminSearch)
// Customizable Area End
