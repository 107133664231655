// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
const configJSON = require("../config.js")

export interface Props {
  heading: string
  viewAllRedirectUrl: string
}

interface S {}

interface SS {
  id: any
}

export default class HeadingWithButtonController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {}

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }
}
// Customizable Area End
