// Customizable Area Start
import React from "react"
import WishlistContentBoxController, {
  Props,
} from "./WishlistContentBoxController.web"
import "./../assets/css/wishlistContentBox.scss"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import Image from "material-ui-image"
import FavoriteIcon from "@material-ui/icons/FavoriteRounded"
import { connect } from "react-redux"
import { getCartItems } from "./assets"
import { withRouter } from "react-router-dom"

export class WishlistContentBox extends WishlistContentBoxController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { image, name, price } = this.props
    const { quantity, isAddingToCart, isRemoving } = this.state
    const isBtnDisabled = isAddingToCart || isRemoving

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} lg={2}>
          <Image
            src={image}
            alt="item_image"
            style={{
              borderRadius: "20px",
              cursor: "pointer",
            }}
            imageStyle={{
              borderRadius: "20px",
            }}
            loading={<CircularProgress size={18} className="wcb_img_loader" />}
            onClick={this.handleProductDetailsRedirect}
          />
        </Grid>
        <Grid item xs={8} sm={4} lg={6}>
          <Box className="wcb_item_details">
            <Typography
              variant="h3"
              onClick={this.handleProductDetailsRedirect}
            >
              {name}
            </Typography>
            <Typography>${price}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          lg={4}
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={10} sm={9} md={12}>
            <Box className="wcb_quantity_container">
              <Box className="wcb_quantity">
                <button
                  onClick={this.decreaseQuantity}
                  disabled={quantity === 1}
                >
                  -
                </button>
                <span>{quantity}</span>
                <button onClick={this.increaseQuantity}>+</button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={3} md={2} lg={3}>
            <IconButton
              size="small"
              className="wcb_fav_icon"
              onClick={this.handleRemoveFromFav}
              disabled={isBtnDisabled}
            >
              {isRemoving ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                <FavoriteIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={12} md={9}>
            <Button
              className="wcb_cart_btn"
              disabled={isBtnDisabled}
              onClick={this.handleAddToCart}
            >
              {isAddingToCart ? (
                <CircularProgress
                  className="wcb_add_cart_loader"
                  color="inherit"
                  size={20}
                />
              ) : (
                "Add to Cart"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    cartId: state.user.cartId,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { getCartItems }
  )(WishlistContentBox)
)
// Customizable Area End
