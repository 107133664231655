// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import React from "react";
import { trash_red_icon } from "./assets";
import { TextField } from "@mui/material";
import Buttn from "@mui/material/Button";
import "../assets/css/landingpage.scss";
import LearningRepliesController, {configJSON, Props} from "./LearningRepliesController.web";

export class RepliesData extends LearningRepliesController {
    constructor(props:Props) {
      super(props);
    }
    render() {
      const { replyItem, name } = this.props;
      return (
        <>
          {!this.state.isEdit  || this.props.isEditSave?
            <>
              <Grid container className="discussion rightBox">
                <Grid item xs={12} sm={12} md={12} className="reply-wrapper">
                  
                  <Box>
                    <div className="replyDetail" >
                      <h5>{replyItem.attributes.full_name === (name) ? configJSON.yourReplyText : replyItem.attributes.full_name}</h5>
                      <p>{replyItem.attributes.date}</p>
                    </div>
                  </Box>
                  <Grid item xs={12}
                  className="replyContainer">
                    <pre  
                    className="replyDesc">
                      {replyItem?.attributes?.description}
                    </pre>
                  </Grid>
                  {name === replyItem.attributes?.full_name &&
                    <>
                      <Grid item xs={12} className="editDelContainer">
                        <div className="replyBtnWrapper">
                          <img src={trash_red_icon}
                            className="delIcon"
                            onClick={this.handleDelBtn}
                            />
                          <button className='edit_reply'
                            onClick={this.handleEdit}
                            >{configJSON.editText}</button>
                        </div>
                      </Grid>
                    </>
                  }
                </Grid>
  
              </Grid>
            </>
            :
            <>
              <Grid container className="discussion replyText" >
                <Grid item xs={12} md={12} className="reply-wrapper" >
                  <Grid item xs={12}>
                    <TextField multiline 
                    className="editText"
                  value={this.state.edittedReply} 
                  onChange={
                    this.handleEditReplyText}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ width: '100%' }}>
                    <div style={{cursor: 'pointer'}}>
                      <Buttn
                        onClick={this.handleSaveBtn}
                        className="send-btn saveButton"
                        variant="contained"
                        >
                        {configJSON.saveText}
                      </Buttn>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
  
        </>
      )
    }
  }

 export default RepliesData; 
// Customizable Area End