// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import { toast } from "react-toastify";
import StorageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;

    history?: any;
    location?: any
    updateActiveTab?: (activeTab: string) => void
    section : object
    content : object
    lesson : any
    lessonLength : number
    hours : string
    mins : string
    enrolled : string
    handleVideoStatus  : any
    lessonDetail : object
    Video :any
    classes : any
}

interface S {
    content : any
    section : any
    lessonDetail : any
    lessonNumber : string
    lessonContent : any
    partNum : string
    partName : string
    listOfLesson : any
    knowMore : boolean
    lessonCount : number
    progress :  number
    courseId : string
    value : number
    expanded : string
    open : boolean
    id: any
    duration : number
    currentTime : number
    isPlayVideo : boolean
    playTime : number
    anchorEl : any
    copy :  boolean
    modalOpen: boolean
    loading : boolean
    lessonData : any
    index : any
    totalLessons : number
    completedLessons : number
    courseCompleted : boolean
    lessonBtnText : string
    courseData : object
}

interface SS {
    id: any
}

export default class LandingPage4Controller extends BlockComponent<
    Props,
    S,
    SS
>{
    getAllContentCallId: any
    getLessonDetailCallId : any
    postVideoStatusCallId : any
    getProgressCallId : any
    getListingOfLessonCallId : any
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            content : [],
            section : [],
            lessonDetail: [],
            lessonContent : [],
            lessonNumber : '',
            partNum : '',
            partName : '',
            listOfLesson : [],
            knowMore : false,
            lessonCount : 0,
            progress : 0,
            courseId : '',
            value : 0,
            expanded : '',
            open : false,
            id : '',
            duration : 0,
            currentTime : 0,
            isPlayVideo : false,
            playTime : 0,
            copy : false,
            anchorEl : null,
            modalOpen : false,
            loading : false,
            lessonData : '',
            index : '',
            totalLessons : 0,
            completedLessons : 0,
            courseCompleted : false,
            lessonBtnText : '',
            courseData : []
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)

        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
            });
        }
        //---------------------
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        if(id) {
          this.setState({courseId : id})
          await this.getAllCourseContent(id)
          await this.getProgress(id)
        }

        await this.getListingOfLesson()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const data = !responseJson?.error && !responseJson?.errors;
        
            if (!data || apiRequestCallId === null) {
              return;
            }

            switch (apiRequestCallId) {
              case this.getAllContentCallId : 
                this.setState({
                  loading : false,
                  content : responseJson.data,
                  section : responseJson.data.attributes.course_sections.data
                })
                this.getLessonData(responseJson)
                break;
              case this.getLessonDetailCallId :
                this.setState({lessonContent : responseJson.data})
                break;
              case this.getListingOfLessonCallId :   
                this.setState({listOfLesson : responseJson.data})
                break;
              case this.getProgressCallId :
                const [progress,lessonCount ] = responseJson.message.match(/(\d+)\D+(\d+)/).slice(1)
                this.setState({
                  lessonCount : lessonCount,
                  progress : progress
                })
                break;
              case this.postVideoStatusCallId :
                await this.getAllCourseContent(this.state.courseId)
                break;
              default : break;
            }

        }
    }

    getLessonData = (responseJson : any) => {
      const filteredData:any = [];
      this.state.section?.forEach((item:any) => {
        const filteredSubSections = item?.attributes?.course_sub_sections?.data?.filter(
          (subSection:any) => subSection?.attributes?.lesson_completed !== true
        );
        if (filteredSubSections?.length > 0) {
          filteredData.push({
            name: item?.attributes?.name,
            course_sub_sections: filteredSubSections?.map((subSection:any) => subSection?.attributes),
          });
        }
      });

      if(filteredData[0]?.course_sub_sections?.length > 0){
        const sectionName = filteredData[0]?.name;
        const sectionIndex = filteredData?.findIndex((item:any) => item?.name === sectionName); 
        const targetData = filteredData[0]?.course_sub_sections[0]?.id;
      
        this.state.section?.findIndex((item:any) => {
        return item?.attributes?.course_sub_sections?.data?.some((subSection:any, index:any) => {
          if( subSection?.id === targetData.toString()) {
            this.setState({index: index+1})}
        });
      });
    this.setState({ lessonData : filteredData[0]?.course_sub_sections[0],
    partName : filteredData[0]?.name, partNum : sectionIndex+1 }) 
      } 
      else{
        this.setState({ 
          partName: this.state.section[0]?.attributes?.name,
          index : 1,
          lessonData: responseJson.data?.attributes?.course_sections.data[0]?.attributes?.course_sub_sections?.data[0]?.attributes})
      }

      let lengthOfLessons= 0 
      this.state.section && this.state.section.map((i:any)=> {
      lengthOfLessons += i.attributes.course_sub_sections.data.length 
      return lengthOfLessons
      })
    const completedLessons = this.state.content?.attributes?.course_sections?.data
    .flatMap((section:any) => section?.attributes?.course_sub_sections?.data)
    .filter((subsection:any) => subsection.attributes.lesson_completed).length;

    const courseCompleted = (completedLessons === lengthOfLessons)
    const courseData = responseJson?.data?.attributes?.course_sections?.data.filter((part:any) => part.attributes.course_sub_sections.data.length > 0).map((partInfo:any)=> partInfo)
    this.setState({
      totalLessons : lengthOfLessons,
      completedLessons : completedLessons,
      courseCompleted : courseCompleted,
      courseData : courseData
    })
    this.getLessonBtnText()
    }

    getLessonBtnText = () => {
      if(!this.state.courseCompleted){
        this.setState({lessonBtnText : `Start- Lesson ${this.state.index}`})
      }
      else return this.setState({lessonBtnText : configJSON.completedText})
    }

    getAllCourseContent = async (id: string) => {
      this.setState({loading: true})
        const token = await StorageProvider.get(configJSON.authToken);
        const header = {
            "Content-type": configJSON.categoryApiContentType,
            token: token
        };
        const contentMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllContentCallId = contentMessage.messageId;

        contentMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.coursesAPIEndpoint + `/${id}` +configJSON.courseContent
        );
        contentMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contentMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(contentMessage.id, contentMessage);
    }

    getLessonDetail = async(id: any) => {
        const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
        const lessonMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getLessonDetailCallId = lessonMessage.messageId;
    
        lessonMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getLessonDetailAPIEndpoint +`${id}`
        );
        lessonMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        lessonMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
    
        runEngine.sendMessage(lessonMessage.id, lessonMessage);
      }

    postVideoStatus = async(id:any, status:any) => {
        const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
  
        const videoMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.postVideoStatusCallId = videoMessage.messageId;
  
        videoMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.postVideoAPIEndpoint + `${id}` + configJSON.videoStatus + `${status}`
        );
        videoMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        videoMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostType
        );
  
        runEngine.sendMessage(videoMessage.id, videoMessage); 
    }

    getProgress = async(id:any) => {
        const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
    
        const createProgressMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getProgressCallId = createProgressMessage.messageId;
    
        createProgressMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.progressAPIEndPoint + `${id}`
        );
        createProgressMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        createProgressMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
    
        runEngine.sendMessage(createProgressMessage.id, createProgressMessage);
    } 

    getListingOfLesson = async() => {
      const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
        const listLessonMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getListingOfLessonCallId = listLessonMessage.messageId;
    
        listLessonMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getListingOfLessonEndpoint
        );
        listLessonMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        listLessonMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
    
        runEngine.sendMessage(listLessonMessage.id, listLessonMessage);
    }

      handleClick = (c: any, lessonNum: any, partNum:any, partName: any) => {
        this.setState({ lessonDetail: c ,lessonNumber: lessonNum , partNum:partNum, partName: partName })
      }

      handleVideoStatus = async(id:any, status:any) => {
        await this.postVideoStatus(id,status)
      }

      handleLessonButton = () => {
        this.props.history?.push(configJSON.lessonDetailURL + `${this.state.lessonDetail?.id}` , {content : this.state.content,
          partNum : this.state.partNum, partName: this.state.partName,lessonNum: this.state.lessonNumber})
      }

      handleLesson1 = () => {
        this.props.history?.push(configJSON.lessonDetailURL + `${this.state.lessonData?.id}` , 
                    {content : this.state.content, partNum : this.state.partNum , lessonNum: this.state.index, partName: this.state.partName} )
      }

      handleStartLessonBtn = (id:any,lessonNum:any,partNum:any, partName:any) => {
        this.props.history?.push(configJSON.lessonDetailURL + `${id}` , 
                    {content : this.state.content, lessonNum: lessonNum ,partNum : partNum, partName: partName} )
      }

      handleOptions = (event:any) => {
        this.setState({ anchorEl: event.currentTarget });
      };
  
      handleCloseOptions = () => {
        this.setState({ anchorEl: null });
      };

      handleModalClose = () => {
        this.setState({modalOpen : false})
      }
  
      handleShare = () => {
        this.setState({modalOpen : true, anchorEl: null})
      }
  
      handleCopy = async () => {
      const url = window.location.href
      try {
        await navigator.clipboard.writeText(url.substring(0, url.indexOf("enrolled")) + `single?id=${this.state.courseId}`);
        this.setState({copy : true})
        setTimeout(() => this.setState({ copy: false, modalOpen: false }), 1500);
      } catch (err) {
        toast.error("Link not copied!")
      }
      this.handleCloseOptions();
    }  
    
    handleBackIcon = () => {
      window.history.back()
    }
}
 // Customizable Area End