// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import {isTokenExpired} from "../../../components/src/utility/helper"



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
logout:any
  history?: any;
  classes?: any;
  
}

interface S {
  arrayHolder: any;
  token: string;
  tableData: any[];
  selected: any[];
  orderDetailsModal: boolean;
  sortValue: any;
  filterValue:any;
  loading: any;
  search: any;
  productList: any;
  status: any;
  orderDetails: any;
  openDialog:any;
  purchaseCount:any;
  purchaseDetails:any;
  total_count: any,
  total_pages: any,
  prodPerPage: any;
  currentPage: any;
}

interface SS {
  id: any;
}

export default class AdminLearningPurchasesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPurchaseListApiCallId: any;
  getDropdownApiCallId: string = "";
  getProductListApiCallId: string = ""
  getPurchaseCountApiCallId:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      tableData: [],
      selected: [],
      orderDetailsModal: false,
      sortValue: "default",
      filterValue:"all",
      loading: false,
      search: "",
      productList: [],
      status: "all",
      orderDetails: [],
      openDialog:false,
      purchaseCount:"",
      purchaseDetails:"",
      total_count: 0,
      total_pages: 0,
      prodPerPage: 5,
      currentPage: 1,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getPurchaseList(this.state.sortValue,this.state.filterValue,this.state.search, this.state.prodPerPage, this.state.currentPage)
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {

          this.successApiCall(responseJson,apiRequestCallId)


        }
      }
      if (responseJson?.errors) {
        toast.error(responseJson?.errors[0].message)
        this.setState({
          loading: false
        })
        if (responseJson?.errors[0] && ((responseJson?.errors[0].message == configJSON.tokenExpiredText) || (responseJson?.errors[0].message == configJSON.invalidTokenText))) {
          this.props.logout()
        }
      }
    }

  }

   // success api call 

  successApiCall = (responseJson: any, apiRequestCallId: any) => {
//get product list       
          if (apiRequestCallId === this.getPurchaseListApiCallId) {
            this.setState({
              productList: responseJson.orders?.data,
              purchaseCount:responseJson?.order_count?.new_order,
              total_pages: responseJson.meta.pagination.total_pages,
              total_count: responseJson.meta.pagination.total_count,
              loading: false,

            });

          }

  }


  

  //get purchase list 
  getPurchaseList = async (sort: any, filter:any,search: any, per: any, page: any) => {
    this.setState({
      loading: true,
    });
    function sortBy() {
      let newSort = sort
      if (newSort == "high to low") {
        newSort = "high_to_low"
      }
      else if (newSort == "low to high") {
        newSort = "low_to_high"
      }
      return newSort
    }
    
    const endpoint = `?product[product_type]=BxBlockLibrary::Course&sort_by=${sortBy()}&search=${search}&status=${filter}&per=${per}&page=${page}`

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPurchaseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRestOrderApiEndPoint
        + `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleStatus = (filter: any) => {
      this.setState({
        filterValue:filter
      })
  }


   handleMenuClick = (value: any, item: any) => {
    if (value == "view") {
      this.setState({
        purchaseDetails:item
      })
      this.handleDialog()
    
    }
  }
  handleDialog = () => {
    this.state.openDialog && this.setState({
      purchaseDetails:""
    })
    this.setState({
      openDialog: !this.state.openDialog
    })

    

  }

  //search decouncing
   SearchDebounce=(call:any, delay:any)=> {
  let timer: any;
  return function(...args: any) {
    clearTimeout(timer);
    timer=setTimeout(() => {
      call(...args);
      
    }, delay);
  };
}
SearchDebounceUpdate=this.SearchDebounce(this.getPurchaseList,
                        1000)
}
// Customizable Area End
