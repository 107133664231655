import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import StorageProvider from "../../../framework/src/StorageProvider";
import { isTokenExpireResponse } from "../../../components/src/utility/helper";

interface Order {
  id: number;
  order_number: string;
  amount: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  coupon_code_id: number;
  sub_total: string;
  total: string;
  status: string;
  applied_discount: string;
  cancellation_reason: string;
  order_date: string;
  is_gift: boolean;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string;
  delivered_at: string;
  cancelled_at: string;
  refunded_at: string;
  source: string;
  shipment_id: string;
  delivery_charges: number;
  tracking_url: string;
  schedule_time: string;
  payment_failed_at: string;
  returned_at: string;
  tax_charges: string;
  deliver_by: string;
  tracking_number: string;
  is_error: boolean;
  delivery_error_message: string;
  payment_pending_at: string;
  order_status_id: string;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: number;
  shipping_discount: number;
  shipping_net_amt: number;
  shipping_total: number;
  total_tax: number;
  razorpay_order_id: number;
  set_delivery_time: number;
  note: string;
  item_count: number;
  total_item: string;
  discount: string;
  shipping: number;
  quantity: number;
  payment_status: string;
  payment_method: string;
  product_owner_type: string;
  address_type: string;
  address_id: number;
  transaction_fees: number;
  loyalty_points: null;
  cancelled_by_owner: boolean;
  order_cancel_status: "pending" | "approved" | "rejected";
  refund_payment_status: "pending" | "completed";
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    isOpen: boolean
    orderId: string
    handleClose: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    trackingDetailsLoader: boolean
    currentStep: number
    isCancelDialog: boolean
    cancelOrderMessage: string
    isCreatingCancelRequest: boolean
    isGettingCancellationStatus: boolean
    cancellationStatusDetails: Order | null
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class OrderTrackingDialogController extends BlockComponent<
    Props,
    S,
    SS
> {
    timer: ReturnType<typeof setTimeout> | null = null
    orderCancellationApiCallId: string = ""
    orderCancellationStatusApiCallId: string = ""
    trackingDetailsApiCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            trackingDetailsLoader: false,
            currentStep: 0,
            isCancelDialog: false,
            cancelOrderMessage: "",
            isCreatingCancelRequest: false,
            isGettingCancellationStatus: false,
            cancellationStatusDetails: null
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getTrackingDetails()
        this.handleGetCancellationStatus()
    }

    async componentWillUnmount(): Promise<void> {
      this.clearRequestCreatedTimer()
    }

    clearRequestCreatedTimer = () => {
      !!this.timer && clearTimeout(this.timer)
    }

    openCancelDialog = () => {
      this.setState({ isCancelDialog: true })
    }

    closeCancelDialog = () => {
      this.setState({ isCancelDialog: false })
    }

    getCancellationStatus = () => {
      const { cancellationStatusDetails } = this.state
      if (cancellationStatusDetails) {
        const { cancelled_by_owner, order_cancel_status } =
          cancellationStatusDetails
        if (cancelled_by_owner) {
          return "Cancelled by owner"
        }
    
        switch (order_cancel_status) {
          case "pending":
            return "Pending"
          case "approved":
            return "Cancelled"
          case "rejected":
            return "Rejected"
          default:
            return ""
        }
      }
      return ""
    }
    
    getRefundStatus = () => {
      const { cancellationStatusDetails } = this.state
      if (cancellationStatusDetails && cancellationStatusDetails.refund_payment_status) {
        const { refund_payment_status } = cancellationStatusDetails
        return `${refund_payment_status
          .charAt(0)
          .toUpperCase()}${refund_payment_status.slice(1)}`
      }
      return "Pending"
    }    

    handleCancelOrder = () => {
      const httpBody = {
        id: this.props.orderId
      }
      this.orderCancelRequestApi(httpBody)
    }

    handleGetCancellationStatus = () => {
      this.orderCancellationStatusApi()
    }

    handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (isTokenExpireResponse(responseJson)) return

        if (apiRequestCallId === this.trackingDetailsApiCallId) {
            this.setState({ currentStep: 0, trackingDetailsLoader: false })
            this.props.handleClose()
            const isManyErrors = responseJson && responseJson.errors?.length > 0
            if (!isManyErrors) toast.error("Something went wrong!")
        }

        if (responseJson && Array.isArray(responseJson?.errors)) {
            responseJson.errors.forEach((err: any) => {
                toast.error(err?.message);
            });
        }
    }

    orderCancelRequestApi = async (httpBody: { id: string }) => {
      this.setState({ isCreatingCancelRequest: true });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.orderCancellationApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.cancellationApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    }

    orderCancellationStatusApi = async () => {
      this.setState({ isGettingCancellationStatus: true });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.orderCancellationStatusApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.orderCancellationStatusApiEndPoint}?id=${this.props.orderId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors && !responseJson.error) {
              if (apiRequestCallId === this.orderCancellationStatusApiCallId) {
                this.setState({
                  isGettingCancellationStatus: false,
                  cancellationStatusDetails: responseJson.order
                })
              }

              if (apiRequestCallId === this.trackingDetailsApiCallId) {
                const currentStep = this.getCurrentStep(responseJson)
                this.setState({ currentStep, trackingDetailsLoader: false })
              }

              if (apiRequestCallId === this.orderCancellationApiCallId) {
                this.setState({
                  isCreatingCancelRequest: false,
                  isCancelDialog: false,
                  cancelOrderMessage: configJSON.cancelledMessages[responseJson.message]
                })
                this.clearRequestCreatedTimer()
                this.timer = setTimeout(() => {
                  this.setState({ cancelOrderMessage: "" })
                }, 3000)
                this.handleGetCancellationStatus()
              }
            } else {
                this.handleErrorApiResponse(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getCurrentStep = (trackingDetails: any) => {
        const currentPosition = Object.keys(trackingDetails).find((key: string) => trackingDetails[key] === "current")

        switch(currentPosition) {
            case 'delivered': return 3
            case 'processed': return 2
            case 'confirmed': return 1
            case 'refunded':
            case 'rejected':
              return -1
            default: return 0
        }
    }

    getTrackingDetails = async () => {
        this.setState({ trackingDetailsLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.trackingDetailsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.orderTrackingApiEndPoint}${this.props.orderId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    // Customizable Area End
}
