// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import AdminRestCampaignsController, {
  Props, configJSON
} from "./AdminRestCampaignsController.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomBorderLinearProgressbar from "../../../components/src/CustomBorderLinearProgressbar.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";
import { GreenOverlayBanner } from "../../info-page/src/assets";
// Customizable Area End

// Customizable Area Start
interface EnhancedTableProps {
  numSelected: number
  orderBy?: string
  rowCount: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { numSelected, rowCount, onSelectAllClick } = props

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
              data-test-id="table-head"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={-1}
            />
            <span>{configJSON.campaignNameLabel}</span>
          </div>
        </TableCell>
        <TableCell align="center"> {configJSON.statusLabel}</TableCell>
        <TableCell align="center"> {configJSON.campaignIdLabel}</TableCell>
        <TableCell> {configJSON.raisedDonationLabel}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End

export class AdminRestaurantCampaigns extends AdminRestCampaignsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      loading,
      campaignsCurrentPage,
      campaignsPerPage,
      totalCampaignCount,
      totalCampaignPage,
      campaignList,
      search,
      bulkAction,
      selectedCampaigns,
      openDialog
    } = this.state

    const currentTableList = campaignList
    const { classes } = this.props;
    const menu = configJSON.options;
    const restStatus = configJSON.campaignStatusMenu;

    const startingIndex = (campaignsCurrentPage - 1) * campaignsPerPage + 1
    return (
      <>
        <div className="tableContainer">
          <Loader loading={loading} />
          <div className="headerListContainer">
            <div className="prodListHeader campaignListHeader">
              <div className="blocks">
                <h4>{configJSON.campaignsLabel}</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div
                    className="addButton"
                    onClick={this.handleAddCampaignBtn}
                  >
                    <CustomButton label="Create Campaign" />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                  id="input-with-icon-textfield"
                  value={search}
                  onChange={this.handleSearchBtn}
                  variant="standard"
                  placeholder={configJSON.restOrderPlaceholderText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect itemStatus dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">{configJSON.actionsLabel}</InputLabel>
                      <Select
                      data-test-id="actions"
                        className="menu-customize"
                        value={bulkAction}
                        disabled={selectedCampaigns.length < 2}
                        onChange={this.bulkActions}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText,
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText,
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.itemStatus,
                          },
                        }}
                      >
                        {restStatus.map((status: any, index: any) => {
                          return (
                            <MenuItem value={status} key={status}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-block commonDropdown  campTable">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                {currentTableList?.length > 0 && (
                  <EnhancedTableHead
                  data-test-id="table"
                    numSelected={selectedCampaigns.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={currentTableList?.length}
                  />
                )}
                <TableBody>
                  {currentTableList?.length > 0 ? (
                    currentTableList.map((item: any, index: any) => {
                      const isItemSelected = this.isSelectedCampaigns(item.id);
                      return (
                        <TableRow key={item.id} data-test-id="campaignList">
                          <TableCell scope="row">
                            <div
                              className="productCell"
                              onClick={
                                this.handleClickCampaignsRow.bind(this,item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={item.attributes.banner.url || GreenOverlayBanner}
                                className="prodImage"
                                alt="img"
                              />
                              <p data-test-id="campaignName">{item.attributes.heading}</p>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div
                              className={`customSelect itemStatus ${item.attributes.status == configJSON.draftStatus && configJSON.warningVariantText
                                }`}
                            >
                              <FormControl>
                                <Select
                                  data-test-id="selectStatus"
                                  value={item.attributes.status}
                                  onChange={
                                    this.handleUpdateCampaign.bind(this,item.id)
                                  }
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: configJSON.bottomText,
                                      horizontal: configJSON.leftText,
                                    },
                                    transformOrigin: {
                                      vertical: configJSON.topText,
                                      horizontal: configJSON.leftText,
                                    },
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {restStatus.map((status: any, index: any) => {
                                    return (
                                      <MenuItem value={status} key={status}>
                                        {status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            {item.attributes?.campaign_id}
                          </TableCell>

                          <TableCell align="center">
                            <div className="fundProgressBar">
                              <div className="fundScale">
                                <span>0</span>
                                <span>
                                  $
                                  {parseInt(
                                    //@ts-ignore
                                    item.attributes.required_donation / 2
                                  )}
                                </span>
                                <span>${item.attributes.required_donation}</span>
                              </div>
                              <CustomBorderLinearProgressbar
                                value={item.attributes.donation_raised}
                                max={item.attributes.required_donation}
                              />
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                              data-test-id="basic-menu"
                                item={item}
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} className={classes.noRecords}>
                        <CustomNoRecordsFound
                          routeUrl={configJSON.routeURL}
                          title={configJSON.campaignTitle}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {currentTableList?.length > 0 && <div className="paginationRow restOrderPagination">
              <div data-test-id="listInfo">
                {startingIndex}-{startingIndex + campaignList.length - 1} of{" "}
                {totalCampaignCount} {configJSON.itemsText}
              </div>
              <CustomPagination
              data-test-id="pagination"
                count={totalCampaignPage}
                page={campaignsCurrentPage}
                pagePerView={campaignsPerPage}
                handlePageChange={this.handleChangeTablePage}
                shape={configJSON.roundedText}
                siblingCount={1}
                increasePagePerView={this.handleIncreasePageView}
                decreasePagePerView={this.handleDecreasePageView}
                boundaryCount={1}
              />
            </div>}
          </div>

          <div>
            <Dialog
              open={openDialog}
              onClose={this.handleDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {configJSON.campaignDelDialogText}
              </DialogTitle>
              <DialogActions>
                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    onClick={this.handleDialog}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-test-id="yesBtn"
                    color="secondary"
                    label="Yes"
                    onClick={() => {
                      this.handleDialog();
                      this.deleteCampaign();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withRouter(withStyles(commonCmptStyles)(AdminRestaurantCampaigns));
// Customizable Area End
