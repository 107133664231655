// Customizable Area Start
import React, { useRef, useState } from "react"
import { Box } from "@material-ui/core"
import Slider, { CustomArrowProps } from "react-slick"
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIosRounded"
import ArrowBackIcon from "@material-ui/icons/ArrowBackIosRounded"
import FarmsCard from "../../../../components/src/FarmsSectionsImgCard.web"
import { brocali } from "../assets"
import "../assets/css/farmpopularproducts.scss"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { variantPriceInfo } from "../../../../components/src/utility/helper"

interface IVariantOption {
  id: string
  type: string
  attributes: {
    price: string
    option_name: string
    weight: string
    is_active: boolean
  }
}

interface IProductDetails {
    id: string
    type: string
    attributes: {
        name: string
        description: string
        average_rating: number
        compare_at_price: string
        image: {
            id: number
            filename: string
            url: string
        }
        price: string
        variant_description: {
          data: {
            id: string
            type: string
            attributes: {
              description: string
              is_active: true
            }
          } | []
        }
        variant_options: IVariantOption[] | null
    }
}

interface IProps {
    popularProducts: IProductDetails[]
}

interface ICustomNextArrowProps extends CustomArrowProps {
    slideToShow: number
}

const CustomNextArrow = ({
    className,
    currentSlide,
    slideCount,
    slideToShow,
    onClick,
}: ICustomNextArrowProps) => {
    return (
        <>
            {currentSlide !== Number(slideCount) - slideToShow && (
                <div className={className} onClick={onClick}>
                    <ArrowForwardIcon />
                </div>
            )}
        </>
    )
}

const CustomPrevArrow = ({
    className,
    currentSlide,
    onClick,
}: CustomArrowProps) => {
    return (
        <>
            {currentSlide !== 0 && (
                <div className={className} onClick={onClick}>
                    <ArrowBackIcon />
                </div>
            )}
        </>
    )
}

const FarmPopularProducts = ({ popularProducts }: IProps) => {
    const sliderRef: any = useRef(null)
    const [currentSlidesToShow, setCurrentSlidesToShow] = useState(5)
    const [isDragging, setIsDragging] = useState(false)

    const handleOnReInit = () => {
        if (sliderRef?.current) {
            const updatedSlideToShow =
                sliderRef.current.innerSlider.props.slidesToShow
            currentSlidesToShow !== updatedSlideToShow &&
                setCurrentSlidesToShow(updatedSlideToShow)
        }
    }

    const handleBeforeChange = () => {
        setIsDragging(true)
    }

    const handleAfterChange = () => {
        setIsDragging(false)
    }

    return (
        <Box>
            <Slider
                ref={sliderRef}
                className="farm_popular_products_slider"
                arrows={true}
                infinite={false}
                slidesToShow={5}
                speed={700}
                nextArrow={<CustomNextArrow slideToShow={currentSlidesToShow} />}
                prevArrow={<CustomPrevArrow />}
                responsive={[
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                ]}
                onReInit={handleOnReInit}
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
            >
                {popularProducts?.map((item: IProductDetails) => {
                    const {
                        id,
                        attributes: {
                            name,
                            image,
                            price,
                            description,
                            average_rating,
                            compare_at_price,
                            variant_options
                        },
                    } = item
                    const redirectPath = `${AppRoutings.FarmProductDetails}/${id}`
                    const { displayPrice, isVariantExist } = variantPriceInfo(compare_at_price, variant_options)
                    const discountPrice = isVariantExist ? "" : price

                    return (
                        <FarmsCard
                            key={id}
                            cardName={name || ""}
                            thumbnail={image?.url || brocali}
                            description={description}
                            displayPrice="block"
                            displayButton="none"
                            displayDuration="none"
                            displayRating="block"
                            average_rating={average_rating}
                            boxShadow={false}
                            amount={discountPrice}
                            discountPrice={displayPrice}
                            isNotRedirectable={isDragging}
                            path={redirectPath}
                            imgPath={redirectPath}
                        />
                    )
                })}
            </Slider>
        </Box>
    )
}

export default FarmPopularProducts
// Customizable Area End
