// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import React from "react";

import StorageProvider from "framework/src/StorageProvider.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import {isTokenExpired} from "../../../components/src/utility/helper"


export interface Props {
  navigation: any;
  id: string;
  history?: any;
  logout: any
  classes?: any;

}

interface S {
  loading: boolean;
  openDialog: any;
  productMode: any;
  courseList: any;
  course_id: any;
  lesson_id: any
  courseContent: any;
  expandedPartId: any;
  discussionList: any;
  error_text: any;
  reply_text: any;
  deleteId: any;
  edit_id: any;
  edit_ref: any

}

interface SS {
  id: any;
}

export default class AdminLearningDiscussionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  lineChart: any;
  getDropdownApiCallId: string = "";
  getCourseListApiCallId: string = "";
  getCourseContentApiCallId: string = ""
  createDiscussionApiCallId: string = ""
  deletecourseAPiEndPoint: string = ""
  updateDiscussionApiCallId: string = ""
  DiscussionNotificationApiCallId: string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      openDialog: false,
      productMode: "",
      courseList: "",
      course_id: 0,
      lesson_id: "",
      courseContent: "",
      expandedPartId: "",
      discussionList: "",
      error_text: "",
      reply_text: "",
      deleteId: "",
      edit_id: "",
      edit_ref: React.createRef()

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCourseList(window.location.pathname.split("/")[4])
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.lesson_id != this.state.lesson_id) {
      setTimeout(()=>{
      const getPartsData = this.state.courseContent.attributes.course_sections.data.filter((part: any) =>part.id == this.state.expandedPartId)[0]
      const getLessonsData = getPartsData?.attributes.course_sub_sections.data.filter((lesson: any) =>lesson.id == this.state.lesson_id)[0]
      if(getLessonsData?.attributes.discussion_notification.unread_count>0){
        //calling api
        this.putNotification(this.state.lesson_id)
      }
      },1000)
      
    }

  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {

          this.successApiCall(responseJson, apiRequestCallId)
        }
      }
      if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        }
      }
    }
  }

  // success api call 

  successApiCall = (responseJson: any, apiRequestCallId: any) => {

    //get course list       
    if (apiRequestCallId === this.getCourseListApiCallId) {
      this.setState({
        courseList: responseJson.data,
        loading: false,

      });
      if (responseJson.data.length > 0) {
        this.setState({
          course_id: responseJson.data[0].id,

        })

        this.getCourseContent(responseJson.data[0].id)

      }


    }

    // get course content 
    if (apiRequestCallId === this.getCourseContentApiCallId) {
      this.gettingContentfunction(responseJson)
      this.setState({
        courseContent: responseJson.data,
        loading: false,
      });
    }

    // creating discussion 
    if (apiRequestCallId === this.createDiscussionApiCallId) {
      this.getCourseContent(this.state.course_id)
      this.setState({
        lesson_id: responseJson.data.attributes.course_sub_section_id,
        loading: false,
      });
      toast.success("Reply Added Successfully")
    }

    // update discussion 
    if (apiRequestCallId === this.updateDiscussionApiCallId) {
      this.getCourseContent(this.state.course_id)
      this.setState({
        lesson_id: responseJson.data.attributes.course_sub_section_id,
        loading: false,
        edit_id: "",

      });
      this.state.edit_ref.current.setFieldValue(
        "reply",
        ""
      );
      toast.success("Reply Updated Successfully")
    }

    //deleteing discussion
    if (apiRequestCallId === this.deletecourseAPiEndPoint) {
      this.getCourseContent(this.state.course_id)
      this.setState({
        loading: false,
      });
      toast.success(responseJson.message)

    }
     if (apiRequestCallId === this.DiscussionNotificationApiCallId) {
      this.getCourseContent(this.state.course_id)
      this.setState({
        loading: false,
      });
    }



  }

  gettingContentfunction = (responseJson: any) => {
    if (responseJson.data &&
      responseJson.data.attributes.course_sections.data.length > 0) {
      let parts = responseJson.data.attributes.course_sections.data
      !this.state.expandedPartId && this.setState({
        expandedPartId: parts[0].id,
      })
      if (parts[0].attributes.course_sub_sections.data.length > 0) {
        !this.state.lesson_id ?
          this.setState({
            discussionList: parts[0].attributes.course_sub_sections.data[0].attributes.discussions.data,
            lesson_id: parts[0].attributes.course_sub_sections.data[0].id,
          })
          :
          this.settingDiscussion(parts, this.state.expandedPartId, this.state.lesson_id)
      }


    }
  }
  // get course list
  getCourseList = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseListInfoAPiEndPoint + `?course[educational_contributor_id]=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // get course content 

  getCourseContent = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseDetailsAPiEndPoint + `/${id}/course_content`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //creating discussion 
  creatediscussion = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };




    const httpBody = {
      discussion_type: "discussion_reply",
      course_sub_section_id: this.state.discussionList[0].attributes.course_sub_section_id,
      account_id: this.state.discussionList[0].attributes.account_id,
      comment: values.reply
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDiscussionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDiscussionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  // update discussion
  updatediscussion = async (id: any, values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };




    const httpBody = {
      discussion_type: "discussion_reply",
      course_sub_section_id: this.state.discussionList[0].attributes.course_sub_section_id,
      account_id: this.state.discussionList[0].attributes.account_id,
      comment: values.reply
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDiscussionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDiscussionAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //lesson notification 
  putNotification = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DiscussionNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiscussionNotificationAPiEndPoint + `course_sub_section_id=${id}&status=is_read`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };
  //remove discussion
  deleteDiscussion = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletecourseAPiEndPoint = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDiscussionAPiEndPoint + `/${this.state.deleteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  settingDiscussion = (parts: any, partId: any, lessonId: any) => {

    const getPart = parts.filter((part: any) => part.id == partId)[0]
    const getLesson = getPart && getPart.attributes.course_sub_sections.data.filter((lesson: any) => lesson.id == lessonId)[0]
    this.setState({
      discussionList: getLesson ? getLesson.attributes.discussions.data : []
    })

  }
  settingClass = (x: any) => {
    return x.discussion_type == "discussion"
      ? "sentChatBodyBox"
      : "receiveChatBodyBox"
  }
  settingClass2 = (x: any) => {
    return x.discussion_type == "discussion"
      ? "sentChatBox"
      : "receivedChatBox"
  }

  AccordianChange = (part: any) => {
    if (
      part.attributes.course_sub_sections.data
        .length > 0
    ) {
      this.setState({
        discussionList:
          part.attributes.course_sub_sections
            .data[0].attributes.discussions.data,
        lesson_id:
          part.attributes.course_sub_sections
            .data[0].id,
      });
    } else {
      this.setState({
        discussionList: [],
        lesson_id: "",
      });
    }
  }

  selectOnChange = (e: any) => {
    if (e.target.value != 0) {
      this.setState({
        course_id: e.target.value,
        expandedPartId: "",
        lesson_id: "",
        edit_id: "",
      });
      this.state.edit_ref.current &&
        this.state.edit_ref.current.setFieldValue(
          "reply",
          ""
        );
      this.getCourseContent(e.target.value);
    }
  }
  settingReplyName = (x: any) => {
    let reply = (x.discussion_type ==
      "discussion_reply") ? (
      <h3>You</h3>
    ) : (
      <h3>{`${x.first_name
        } ${x.last_name
        }`}</h3>
    )
    return reply
  }
  settingAccordianopen = () => {
    if (
      this.state.edit_ref &&
      this.state.edit_ref.current
    ) {
      this.state.edit_ref.current.setFieldValue(
        "reply",
        ""
      );
    }
  }
  displayDiscussionBadge = (notification: any) => {
    return notification.unread_count >0 && notification.unread_count
  }

}

// Customizable Area End
