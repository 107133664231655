// Customizable Area Start
import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    withStyles,
  } from "@material-ui/core";
  import React from "react";
  import Loader from "../../../components/src/Loader.web";
  import CustomButton from "../../../components/src/CustomButton.web";
  import "../assets/css/adminRestaurantDetails.scss";
  import "../assets/css/adminRestaurantAvailability.scss";
  import "../../../web/src/assets/css/content/card.scss";
  import "rc-time-picker/assets/index.css";
  import { withRouter } from "react-router-dom";
  import { commonCmptStyles } from "./dropDownCss";
  import "../../../web/src/assets/css/content/textfield.scss";
  import "../../../web/src/assets/css/content/checkbox.scss";
  import AdminRestaurantAvailController, {
    Props, IDay
  } from "./AdminRestaurantAvailController.web";
  import RemoveIcon from '@material-ui/icons/RemoveRounded';
// Customizable Area End

class AdminRestaurantAvailability extends AdminRestaurantAvailController {
    constructor(props: Props) {
        super(props)
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isLoading, loading, allDays } = this.state

        return (
            <>
                <Loader loading={loading} />
                    <div className="restDetailsContainer">
                        <div className="restDetailsHeader">
                            <h4 data-testid="registerheading">Availability</h4>
                            <div className="saveBtn">
                                <CustomButton
                                    label="Save"
                                    data-testid="saveBtn"
                                    isLoading={loading}
                                    disabled={loading}
                                    onClick={() => this.handleAvailabilityUpdate()}
                                />
                            </div>
                        </div>

                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={9}
                                lg={7}
                                container
                                justifyContent="center"
                                className="res_timing_wrapper"
                            >
                                <Grid item xs={12} container className="availSettingHeaderWrapper">
                                    <Grid item xs={12} sm={4}>
                                        <div className="customTimeCheckbox availSetting">
                                            <FormControlLabel
                                            data-testid="controlLabel"
                                                control={
                                                    <Checkbox
                                                    data-testid="headCheckbox"
                                                        checked={this.isAllDayOpen()}
                                                        onChange={() => this.toggleAllDayAvailability()}
                                                    />
                                                }
                                                label="Day"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <Typography className="availSettingHeader">Start Time</Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <Typography className="availSettingHeader">End Time</Typography>
                                    </Grid>
                                </Grid>

                                {allDays.map((day: IDay) => {
                                    const { id, isOpen, opensAt, closesAt, openDay } = day;

                                    return (
                                        <Grid item xs={12} container className="availSettingContentWrapper" key={id}>
                                            <Grid item xs={12} sm={4}>
                                            <div className="customTimeCheckbox availSetting">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                        data-testid="daysCheckbox"
                                                            checked={isOpen}
                                                            onChange={(e: any) =>
                                                                this.handleChange(id, "isOpen", e.target.checked)
                                                            }
                                                        />
                                                    }
                                                    label={openDay}
                                                />
                                            </div>
                                            </Grid>
                                            <Grid item xs={5} sm={3}>
                                            <div className="customTextfield availSettingCustomTime">
                                                <TextField
                                                    type="time"
                                                    variant="standard"
                                                    data-testid="opensAt"
                                                    placeholder=""
                                                    value={opensAt}
                                                    onChange={(e: any) =>
                                                        this.handleChange(id, "opensAt", e.target.value)
                                                    }
                                                />
                                            </div>
                                            </Grid>
                                            <Grid item xs={2} sm={1}>
                                                <div className="availSettingRemove"><RemoveIcon fontSize="small" /></div>
                                            </Grid>
                                            <Grid item xs={5} sm={3}>
                                            <div className="customTextfield availSettingCustomTime">
                                                <TextField
                                                    type="time"
                                                    variant="standard"
                                                    placeholder=""
                                                    data-testid="closesAt"
                                                    value={closesAt}
                                                    onChange={(e: any) =>
                                                        this.handleChange(id, "closesAt", e.target.value)
                                                    }
                                                />
                                            </div>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </div>
            </>
        )
        // Customizable Area End
    }
}



// Customizable Area Start
export {AdminRestaurantAvailability}
// Customizable Area End
const AdminRestaurantAvailabilityRouter = withRouter(AdminRestaurantAvailability);
export default withStyles(commonCmptStyles)(AdminRestaurantAvailabilityRouter);