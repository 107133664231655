// Customizable Area Start
import React, { useRef, useState } from "react"
import { Box, Button, IconButton } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBackRounded"
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardRounded"
import Slider from "react-slick"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter, RouteComponentProps } from "react-router-dom"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

const useStyles = makeStyles(() => ({
    navigationContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
        width: "100%",
    },
    withOutAllBtn: {
        justifyContent: "flex-end",
    },
    carouselNavBtn: {
        padding: "2px",
        margin: "0px 3px",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.04)",
        },
        "& svg": {
            color: "#000000",
            fontSize: "30px",
        },
    },
    carouselNavBtnDisabled: {
        "& svg": {
            color: "#aaaaaa",
            fontSize: "20px",
        },
    },
    emptyMessageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40vh",
        border: "2px solid #d3d3d3",
        borderRadius: "20px",
        padding: "15px",
        color: "#3bad6c",
        fontSize: "23px",
        fontFamily: "'Montserrat', sans-serif",
    },
}))

interface ILPCustomCarousel extends RouteComponentProps {
    type: "farm" | "restaurant" | "latest" | ""
    slidesToShow?: number
    showAllBtn?: boolean
    children: any
    emptyMessage: string
    className?: string
    sliderProps?: any
    handleBeforeChange?: () => void
    handleAfterChange?: () => void
}

const LPCustomCarousel = ({
    history,
    type,
    slidesToShow = 3,
    showAllBtn = true,
    children,
    className = "",
    sliderProps = {},
    emptyMessage,
    handleBeforeChange,
    handleAfterChange
}: ILPCustomCarousel) => {
    const classes = useStyles()
    const sliderRef: any = useRef(null)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [currentSlidesToShow, setCurrentSlidesToShow] = useState(slidesToShow)

    const handleBeforeSlideChange = (_: number, next: number) => {
        if(handleBeforeChange) {
          handleBeforeChange()
        }
        setCurrentSlide(next)
    }

    const handleAfterSlideChange = () => {
      if(handleAfterChange) {
        handleAfterChange()
      }
    }

    const handlePrevSlide = () => {
        if (sliderRef.current) sliderRef.current.slickPrev()
    }

    const handleNextSlide = () => {
        if (sliderRef.current) sliderRef.current.slickNext()
    }

    const handleViewAllRedirect = () => {
        const isFavSection = type === "farm" || type === "restaurant"
        if (isFavSection) {
            history.push({ pathname: AppRoutings.FavoritePage, state: type })
        }
        if (type === "latest") {
            history.push({ pathname: AppRoutings.CmsVendorPage, state: type })

        }
    }

    const handleOnReInit = () => {
        if (sliderRef && sliderRef.current) {
            const updatedSlideToShow =
                sliderRef.current.innerSlider.props.slidesToShow
            currentSlidesToShow !== updatedSlideToShow &&
                setCurrentSlidesToShow(updatedSlideToShow)
        }
    }

    return (
        <Box>
            {children?.length > 0 ? (
                <>
                    <Slider
                        speed={700}
                        ref={sliderRef}
                        arrows={false}
                        centerMode={false}
                        infinite={false}
                        slidesToShow={slidesToShow}
                        slidesToScroll={slidesToShow}
                        className={className}
                        lazyLoad="ondemand"
                        onReInit={handleOnReInit}
                        beforeChange={handleBeforeSlideChange}
                        afterChange={handleAfterSlideChange}
                        {...sliderProps}
                    >
                        {children}
                    </Slider>
                    <Box
                        className={clsx(classes.navigationContainer, {
                            [classes.withOutAllBtn]: !showAllBtn
                        })}
                    >
                        {showAllBtn && (
                            <Button
                                classes={{ root: "green-btn", label: "btn-label" }}
                                onClick={handleViewAllRedirect}
                            >
                                View All
                            </Button>
                        )}
                        <Box>
                            <IconButton
                                classes={{
                                    root: classes.carouselNavBtn,
                                    disabled: classes.carouselNavBtnDisabled,
                                }}
                                size="small"
                                disabled={currentSlide === 0}
                                onClick={handlePrevSlide}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <IconButton
                                classes={{
                                    root: classes.carouselNavBtn,
                                    disabled: classes.carouselNavBtnDisabled,
                                }}
                                size="small"
                                disabled={
                                    children.length < currentSlidesToShow ||
                                    currentSlide + currentSlidesToShow === children.length
                                }
                                onClick={handleNextSlide}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box className={classes.emptyMessageContainer}>{emptyMessage}</Box>
            )}
        </Box>
    )
}

export default withRouter(LPCustomCarousel)
// Customizable Area End
