// Customizable Area Start
import React from "react"
import CommunityLatestForumsController, {
    Props, IDiscussion
} from "./CommunityLatestForumsController.web"
import { connect } from "react-redux"
import { setCommunityLatestForums } from "../../assets"
import { bindActionCreators } from "redux"
import { Container, Box, Grid, Typography, Button, CircularProgress } from "@material-ui/core"
import CommunityCard from "./CommunityCard.web"
import { withRouter } from "react-router-dom"
import LoginSignUpDialog from "../../langinpageLoggedIn/LoginSignUpDialog.web"
import "../../assets/css/community/communityLatestForums.scss"
import AddIcon from '@material-ui/icons/AddRounded';
import NewDiscussionDialog from "../CommunityCustomComponents/NewDiscussionDialog.web"

class CommunityLatestForums extends CommunityLatestForumsController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            communityLatestForumsDesc,
            communityLatestForums
        } = this.props
        const {
            loader,
            isLoginDialog,
            isNewDiscussionDialog
        } = this.state

        return (
            <>
                <Container>
                    <Box className="clf_main_wrapper">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="clf_main_heading">
                                    Latest forums
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="clf_description">
                                    {communityLatestForumsDesc}
                                </Typography>
                            </Grid>
                            {communityLatestForums?.length > 0 ?
                                <>
                                    {communityLatestForums.slice(0, 3).map((discussion: IDiscussion) => {
                                        const { id, attributes: { author_name, date, discussion_heading } } = discussion
                                        return (
                                            <Grid item xs={12} key={id}>
                                                <CommunityCard
                                                    id={id}
                                                    name={discussion_heading}
                                                    authorName={author_name}
                                                    date={date}
                                                    openLoginDialog={this.handleLoginDialogOpen}
                                                />
                                            </Grid>
                                        )
                                    })}

                                    <Grid item xs={12}>
                                        <Box className="view_all_btn_container">
                                            <Button classes={{ root: "orange_btn" }} onClick={this.handleForumViewAll}>
                                                View All
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12}>
                                    <Box className="clf_empty_container">
                                        {loader ?
                                            <CircularProgress className="clf_loader" size={22} />
                                            :
                                            <>
                                                <Typography variant="h4">No discussion listed</Typography>
                                                <Button startIcon={<AddIcon />} onClick={this.handleAddNewDiscussion}>
                                                    New Discussion
                                                </Button>
                                            </>}
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                    <LoginSignUpDialog
                        isOpen={isLoginDialog}
                        onClose={this.handleLoginDialogClose}
                    />
                </Container>
                {isNewDiscussionDialog && <NewDiscussionDialog
                    isOpen={isNewDiscussionDialog}
                    handleClose={this.closeDiscussionDialog}
                />}
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        communityLatestForumsDesc: state.community.communityLatestForumsDesc,
        communityLatestForums: state.community.communityLatestForums
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCommunityLatestForums: bindActionCreators(setCommunityLatestForums, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityLatestForums))
// Customizable Area End
