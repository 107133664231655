import React, { useState, useEffect } from "react";
// custom components
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import "../../web/src/assets/css/content/select.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";

const styles = makeStyles({
    dropdownStyle: {
        width: "200px",
        height: "200px",
        overflowY: "scroll",
        backgroundColor: "red",
        "&::-webkit-scrollbar": {
            width: "5px",
            borderRadius: "50px",
        },
        /* Track */
        "&::-webkit-scrollbar-track": {
            background: "#ccc",
            borderRadius: "50px",
        },
        /* Handle */
        "&::-webkit-scrollbar-thumb ": {
            background: "#4aa258",
            borderRadius: "50px",
        },
        /* Handle on hover */
        "&::-webkit-scrollbar-thumb:hover ": {
            background: "#555",
        },

        "& .MuiMenuItem-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            // maxWidth: "200px"
            width: "100%",
            display: "block",
        },
    },
});

interface IOption {
    value: string | number
    label: string | number
}

interface ICustomDropDownProps extends RouteComponentProps {
    value: string | number
    options: IOption[]
    defaultRequire?: boolean
    defaultOption?: string
    headerSelect?: boolean
    handleChange: (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => void
}

const CustomDropDown = ({ value, headerSelect,defaultRequire = true, defaultOption, options, handleChange }: ICustomDropDownProps) => {

    const classes = styles();
    return (
        <div className="customSelect">
            <FormControl>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value || "default"}
                    className="menu-customize"
                    onChange={handleChange}
                    MenuProps={{
                        classes: { paper: headerSelect ? classes.dropdownStyle : "" },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {defaultRequire && <MenuItem disabled value="default">{defaultOption || "Select a option"}</MenuItem>}
                    {options?.map((option: IOption) => {

                        const { value, label } = option

                        return (
                            <MenuItem value={value} key={value}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};
export default withRouter(CustomDropDown);
