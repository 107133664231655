// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
    Box,
    CircularProgress,
    Container,
    Button,
    Popover,
    Grid,
} from "@material-ui/core"
import BreadCrumb from "../../../../components/src/BreadCrumb.web"
import ExpertsController, { Props, IExpert } from "./ExpertsController.web"
import { FilterIcon, setCommunityExpertsList } from "../assets"
import "../assets/css/experts/experts.scss"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import WebFooter from "../../../../components/src/WebFooter.web"
import GreenBanner from "../../../../components/src/GreenBanner.web"
import { bindActionCreators } from "redux"
import Pagination from "@material-ui/lab/Pagination"
import ExpertCardCarousel from "../../../../components/src/ExpertCardCarousel.web"
import ExpertDetailsDialog from "./ExpertDetailsDialog.web"
import clsx from "clsx"

class Experts extends ExpertsController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            loader,
            anchorEl,
            isExpertDialog,
            expertListFilter,
            expertId,
            currentPage,
            totalPages,
        } = this.state
        const { expertsList } = this.props

        const isExpertListEmpty = expertsList.length === 0
        const open = Boolean(anchorEl)
        const id = open ? "expert-filter-popover" : undefined

        return (
            <Box id="expertBox">
                <Box pt={3} pb={3}>
                    <Container>
                        <Box className="experts_upper_wrapper">
                            <Box>
                                <BreadCrumb
                                    twoPage={true}
                                    threePage={false}
                                    firstPage="Community"
                                    secondPage="Our Experts"
                                    firstPageLink={AppRoutings.Community}
                                    secondPageLink={AppRoutings.Experts}
                                    thirdPage=""
                                    thirdPageLink=""
                                />
                            </Box>
                            <Box>
                                <Button
                                    aria-describedby={id}
                                    disabled={loader || isExpertListEmpty}
                                    className={clsx("experts_filter_icon", {
                                        ["isFilterEnabled"]: expertListFilter,
                                    })}
                                    onClick={this.handlePopoverOpen}
                                >
                                    <img src={FilterIcon} alt="filter icon" />
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={this.handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <Box className="experts_filter_menu_wrapper">
                                        <Box
                                            className={clsx({ ["isExpertFilterSelected"]: !expertListFilter })}
                                            onClick={() => this.handleFilter("")}
                                        >
                                            Default
                                        </Box>
                                        <Box
                                            className={clsx({
                                                ["isExpertFilterSelected"]: expertListFilter === "alphabet",
                                            })}
                                            onClick={() => this.handleFilter("alphabet")}
                                        >
                                            Alphabetical
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>

                        {
                            loader ? (
                                <Box className="experts_loader">
                                    <CircularProgress size={25} />
                                </Box>
                            ) : (
                                <Box pt={4} pb={4}>
                                    <Grid container spacing={3}>
                                        {expertsList?.map((expert: IExpert) => {
                                            const {
                                                id,
                                                attributes: { first_name, last_name, educator_image, headline },
                                            } = expert
                                            return (
                                                <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                                                    <ExpertCardCarousel
                                                        id={id}
                                                        image={educator_image?.url || ""}
                                                        name={`${first_name} ${last_name}`}
                                                        skill={headline}
                                                        handleOnClick={this.openExpertDialog}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                        {!loader && totalPages > 1 && (
                                            <Grid item xs={12}>
                                                <Box className="web_pagination_container">
                                                    <Pagination
                                                        page={currentPage}
                                                        count={totalPages}
                                                        onChange={(_: any, value: number) =>
                                                            this.handlePageChange(value)
                                                        }
                                                        variant="outlined"
                                                        shape="rounded"
                                                        classes={{
                                                            root: "farm_pagination",
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            )
                        }
                    </Container>
                </Box>
                <GreenBanner />
                <WebFooter />
                {isExpertDialog && (
                    <ExpertDetailsDialog
                        isOpen={isExpertDialog}
                        expertId={expertId}
                        handleClose={this.closeExpertDialog}
                    />
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        expertsList: state.community.expertsList,
        communityExperts: state.community.communityExperts,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCommunityExpertsList: bindActionCreators(
            setCommunityExpertsList,
            dispatch
        ),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Experts))
// Customizable Area End
