// Customizable Area Start
import React from "react"
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import Alert from "@material-ui/lab/Alert"
import ExpandMoreRounded from "@material-ui/icons/ExpandMoreRounded"
import OrderCancelRejectDialogController, {
  Props,
  BUTTON_TYPES,
  BUTTON_BEHAVIOR_TYPES,
} from "./OrderCancelRejectDialogController.web"
import CustomCancelFlowButton from "./CustomCancelFlowButton.web"
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web"
const configJSON = require("./config.js")

const rejectCancelSchema = Yup.object().shape({
  rejectionReason: Yup.string().required("This field is required"),
})

export class OrderCancelRejectDialog extends OrderCancelRejectDialogController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes, isOpen } = this.props
    const {
      isLoading,
      currentStep,
      apiErrors,
      isReasonsLoading,
      reasonsList,
    } = this.state

    const STEPS_TITLES = [
      [configJSON.rejectCancellationRequestMessage],
      [configJSON.cancellationRejectedMessage],
    ]

    const STEP_BUTTONS = [
      [
        {
          type: "submit",
          buttonType: "success",
          isLoading: isLoading,
          title: configJSON.confirmText,
          onClick: () => {},
        },
      ],
      [
        {
          type: "button",
          buttonType: "success",
          isLoading: false,
          title: configJSON.closeText,
          onClick: this.handleDialogClose,
        },
      ],
    ]

    return (
      <Dialog
        fullWidth
        open={isOpen}
        classes={{ paper: classes.dialogPaper }}
        maxWidth="sm"
        onClose={this.handleDialogClose}
      >
        <IconButton
          className={classes.closeIcon}
          size="small"
          onClick={this.handleDialogClose}
        >
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={{ rejectionReason: "" }}
            validationSchema={rejectCancelSchema}
            onSubmit={(values) => this.handleCancelSubmit(values)}
          >
            {({ values, touched, errors, handleChange }) => (
              <Form>
                <Box className={classes.contentWrapper}>
                  {STEPS_TITLES[currentStep].map((title: string) => (
                    <Typography key={title} className={classes.title}>
                      {title}
                    </Typography>
                  ))}
                </Box>
                {currentStep === 0 && (
                  <Box mt={3}>
                    <Typography className={classes.title}>
                      {configJSON.selectReasonMessage}
                    </Typography>
                    <Select
                      disableUnderline
                      name="rejectionReason"
                      disabled={isReasonsLoading}
                      IconComponent={ExpandMoreRounded}
                      className={classes.rejectionReasonSelect}
                      value={values.rejectionReason}
                      onChange={handleChange}
                      MenuProps={{ ...configJSON.MenuProps }}
                    >
                      {reasonsList.map((reason) => {
                        const { id, rejection_reason } = reason
                        return (
                          <MenuItem key={id} value={rejection_reason}>
                            {rejection_reason}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <FormikErrorMessage
                      isTouched={touched.rejectionReason}
                      error={errors.rejectionReason}
                    />
                  </Box>
                )}

                <Box mt={2}>
                  {apiErrors.map((error) => {
                    const { message } = error
                    return (
                      <Alert key={message} severity="error">
                        {message}
                      </Alert>
                    )
                  })}
                </Box>

                <Box className={classes.buttonWrapper}>
                  {STEP_BUTTONS[currentStep].map((buttonDetails) => {
                    const {
                      type,
                      title,
                      isLoading,
                      buttonType,
                      onClick,
                    } = buttonDetails
                    return (
                      <CustomCancelFlowButton
                        key={title}
                        type={type as BUTTON_BEHAVIOR_TYPES}
                        title={title}
                        isLoading={isLoading}
                        buttonType={buttonType as BUTTON_TYPES}
                        handleClick={onClick}
                      />
                    )
                  })}
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export const styles = () =>
  createStyles({
    dialogPaper: {
      borderRadius: "30px",
      position: "relative",
      maxWidth: "420px",
    },
    closeIcon: {
      position: "absolute",
      right: "15px",
      top: "15px",
      backgroundColor: "#f0f0f0",
      "& svg": {
        color: "#000",
      },
    },
    dialogContent: {
      padding: "25px 30px !important",
      "@media screen and (max-width: 480px)": {
        padding: "25px 25px !important",
      },
    },
    contentWrapper: {
      width: "95%",
    },
    title: {
      fontSize: "17px",
      fontFamily: "'Montserrat', sans-serif",
      color: "#000",
      "@media screen and (max-width: 480px)": {
        fontSize: "15px",
      },
    },
    rejectionReasonSelect: {
      width: "100%",
      border: "1px solid #d1d1d1",
      borderRadius: "4px",
      marginTop: "5px",
      "& .MuiSelect-select": {
        padding: "9px 24px 9px 12px",
      },
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
  })

export default withStyles(styles)(OrderCancelRejectDialog)
// Customizable Area End
