import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCoord, getCoords } from "../../../components/src/utility/helper";
export const configJSON = require("./config");
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
// Customizable Area End


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history?: any;
  handleTabChange: (event: any, tab: number) => void
  logout : any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  arrayHolder: any;
  token: string;
  selected: any[];
  cardIndex: number;
  restStepperIndex: number;
  modalOpen: boolean;
  restOrderModal: boolean;
  seletedDish: any[];
  seletedForum: any[];
  seletedCampaigns: any[];
  restaurants: any[];
  restaurantDetails: any;
  restaurant: any;
  isEdit: boolean;
  restaurantId: any;
  otpId: any;
  isOtpVerified: boolean;
  isOtpSending: boolean;
  isOtpVerifying: boolean;
  isOtpIncorrect: boolean;
  isVerifyPhoneNumber: any;
  address: string;
  mapCenter: {
    lat: any;
    lng: any;
  }
  hour: any
  openMenu: boolean
  search: string
  openDialog: boolean
  removedRest: any
  deliverySetting: any
  isOn: boolean
  addPrice: boolean
  createOrderPriceRules: any
  OpenDays: any
  restAvail: any
  deliveryData: any
  day: any
  start: any
  end: string
  checked: boolean
  restOpenDays: any
  settingId: string
  orderPriceId: string
  restMode: string
  restAvailID: any
  restaurantID: any
  timingId: string,
  bankId: string,
  bankDoc: any,
  images: any,
  restOrderPrice : string,
  takeOutHour : string ,
  takeOutMin : string
  createResLoader: boolean
  restStatus : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestaurantAddDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  getRestaurantDetailsApiCallId: string = "";
  postSendOtpApiCallId: string = "";
  postVerifyOtpApiCallId: string = "";
  putRestaurantApiCallId: string = "";
  getAllRestaurantsApiCallId: string = "";
  deleteRestApiCallId: any
  deleteFileApiCallId : any
  priceRules:any = {
    orderPrice: '',
    hour: '',
    min: ''
  }
  deleteImageApiCallId: any
  postCreateRestaurantApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ]

    this.state = {
      arrayHolder: [],
      token: "",
      selected: [],
      cardIndex: 0,
      restStepperIndex: 0,
      modalOpen: false,
      restOrderModal: false,
      seletedDish: [],
      seletedForum: [],
      seletedCampaigns: [],
      restaurants: [],
      restaurantDetails: "",
      restaurant: "",
      isEdit: false,
      restaurantId: "",
      loading: false,
      otpId: "",
      isOtpVerified: false,
      isOtpSending: false,
      isOtpVerifying: false,
      isOtpIncorrect: false,
      isVerifyPhoneNumber: false,
      address: "",
      mapCenter: {
        lat: null,
        lng: null
      },
      hour: 0,
      openMenu: false,
      search: '',
      openDialog: false,
      removedRest: '',
      deliverySetting: 'manual',
      isOn: false,
      addPrice: false,
      createOrderPriceRules: [],
      OpenDays: [],
      restAvail: [],
      deliveryData: [],
      day: [],
      start: '',
      end: '',
      checked: true,
      restOpenDays: [],
      settingId: '',
      orderPriceId: '',
      restMode: "edit",
      restAvailID: [],
      restaurantID: '',
      timingId: '',
      bankId: '',
      bankDoc: [],
      images: [],
      restOrderPrice : '',
      takeOutHour : '',
      takeOutMin : '',
      createResLoader: false,
      restStatus : ''
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    this.setState({restaurantId : id})

    if (id) {
      await this.getRestaurantDetails(id)
    }
    if(window.location.pathname.split("/")[4] !== "register-form") {
      await this.getAllRestaurants(this.state.search);
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !(responseJson.error || responseJson.errors)) {
        await this.handleSuccessResponse(apiRequestCallId, responseJson)
      }

      if (responseJson?.errors) {
        this.setState({
          loading:false,
          createResLoader: false
        })
        if(responseJson?.errors[0] && responseJson?.errors[0].message.includes(["Token has Expired","Invalid token"])) {
           this.props.logout()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessResponse = async(apiRequestCallId: string, responseJson: any) => {
    // Restaurant details
    if (apiRequestCallId === this.getRestaurantDetailsApiCallId) {
      const {
        restaurant,
        restaurant_avalibility,
        bank_detail,
        restaurant_type_and_timing,
      } = responseJson;
      const { latitude, longitude, org_address } = restaurant.data.attributes;

      const openDays = restaurant_avalibility?.data
        .filter((i: { attributes: { is_open: boolean } }) => i.attributes.is_open)
        .map((i: { attributes: { open_day: string } }) => i.attributes.open_day)

      this.setState({
        loading: false,
        isOtpVerified: true,
        restaurantDetails: responseJson,
        restaurantId: restaurant?.data?.id,
        mapCenter: {
          lat: latitude,
          lng: longitude,
        },
        address: org_address,
        restOpenDays: openDays,
        bankId: bank_detail.data?.id,
        timingId: restaurant_type_and_timing.data?.id,
        images: restaurant_type_and_timing.data?.attributes?.banners,
        bankDoc: bank_detail.data?.attributes?.documents
      })

      const restStatus = this.state.restaurantDetails?.restaurant?.data?.attributes?.status === configJSON.pending && configJSON.VerificationPending  
      this.setState({restStatus : restStatus})
    }

    // send otp
    if (apiRequestCallId === this.postSendOtpApiCallId) {
      this.setState({
        isOtpSending: false,
        otpId: responseJson.email_otp_id
      });
      toast.success("Otp Sent Successfully");
    }

    // verify otp
    if (apiRequestCallId === this.postVerifyOtpApiCallId) {
      this.setState({
        loading: false,
        isOtpVerified: true,
        isOtpVerifying: false,
        isOtpIncorrect: false
      })
    }

    // update restaurant
    if (apiRequestCallId === this.putRestaurantApiCallId) {
      this.setState({
        loading: false,
        isEdit: false,
        createResLoader: false
      })
      toast.success("Restaurant updated successfully")
      this.props.handleTabChange("", 1)
    }

    // Get all restaurants
    if (apiRequestCallId === this.getAllRestaurantsApiCallId) {
      if (responseJson?.data?.length > 0) {
        this.setState({
          restaurants: responseJson.data,
          restaurant: responseJson.data[0].attributes.name
        })
      }
      this.setState({
        loading: false
      })
    }

    //create restaurant
    if (apiRequestCallId === this.postCreateRestaurantApiCallId) {
      this.handleOpenModal()
      this.setState({
        createResLoader: false,
        restaurantID: responseJson.restaurant.data.id
      })
    }


    await this.handleDeleteUpdateResponse(apiRequestCallId)
  }

  handleDeleteUpdateResponse = async(apiRequestCallId: string) => {
    

    //delete restaurant
    if (apiRequestCallId === this.deleteRestApiCallId) {
      toast.success("Restaurant deleted successfully");
      await this.getAllRestaurants(this.state.search);
    }

    //del restaurant image
    if (apiRequestCallId === this.deleteImageApiCallId) {
      toast.success("Image deleted successfully")
    }

    //del restaurant files
    if (apiRequestCallId === this.deleteFileApiCallId) {
      toast.success("File deleted successfully")
    }
  }

  handleFileUploadCreate = (
    targetFiles: any,
    values: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const bannerExist =
      targetFiles.length > 0
        ? values.bannerImages.filter((x: any) => {
            return x[0].name === targetFiles[0].name;
          })
        : [];
  
    if (targetFiles.length > 0 && bannerExist.length === 0) {
      setFieldValue("bannerImages", [...values.bannerImages, targetFiles]);
      setFieldValue("bannerImages", [...values.bannerImages, targetFiles]);
    }
  }

  handleFileUploadEdit = (
    targetFiles: any,
    values: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const bannerExist =
      targetFiles.length > 0 ?
      values.uploadedBanners.filter(
        (x: any) =>
          x.name === targetFiles[0].name
      ) : []

      if(targetFiles.length > 0 && bannerExist.length == 0) {
        setFieldValue("uploadedBanners", [...values.uploadedBanners, ...targetFiles])
        setFieldValue("selectedImages", [...values.selectedImages, ...targetFiles])
      }
  }

  //form Schema
  formSchema = () => {
    const phoneRegExp = /^\(?([2-9][0-9]{2})\)?([2-9][0-9]{2})?([0-9]{4})$/g;
    switch (this.state.restStepperIndex) {
      case 0:
        return Yup.object().shape({
          name: Yup.string().required("This field is required."),
          org_address: Yup.string().required("This field is required."),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("This field is required."),
          ownerName: Yup.string().required("This field is required."),
          ownerEmail: Yup.string()
            .email("This email is not valid")
            .required("This field is required."),
          ownerNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("This field is required."),
          description: Yup.string()
            .max(1000, "Description must be at most 1000 characters.")
            .required("This field is required."),
          owner_otp: Yup.string(),
          latitude: Yup.string().required("This field is required."),
          longitude: Yup.string().required("This field is required.")
        });
      case 1:
        return Yup.object().shape({
          restaurant_type: Yup.string().required("This field is required."),
          days: Yup.array()
            .required("This field is required.")
            .min(1),
          cuisines: Yup.array()
            .required("This field is required.")
            .min(1),
          outlet: Yup.array()
            .required("This field is required.")
            .min(1),
          opensAt: Yup.string().required("This field is required."),
          closesAt: Yup.string().required("This field is required."),
          bannerImages: Yup.array().required("This field is required."),
        });
      case 2:
        return Yup.object().shape({
          bankName: Yup.string().required("This field is required."),
          accountNumber: Yup.number()
            .typeError("Account number must be a number")
            .required("This field is required."),
          documents: Yup.array().required("This field is required."),
          acceptTerms: Yup.bool()
            .oneOf([true], "This field must be checked.")
            .required("This field is required."),
        });
      default:
        break;
    }
  };

  // create restaurant
  postCreateRestaurant = async (values: any) => {
    this.setState({
      createResLoader: true     
    })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token: token,
    }

    const formdata = new FormData()
    formdata.append("restaurant[name]", values.name)
    formdata.append("restaurant[description]", values.description)
    formdata.append("restaurant[org_address]", values.org_address)
    formdata.append("restaurant[org_owner_name]", values.ownerName)
    formdata.append("restaurant[org_owner_address]", values.ownerEmail)
    formdata.append("restaurant[org_contact_number]", `1${values.phoneNumber}`)

    formdata.append(
      "restaurant[org_owner_contact_number]",
      `1${values.ownerNumber}`
    )
    formdata.append("restaurant[purchase_limit_percentage]", values.purchase_limit_percentage)
    formdata.append("restaurant[is_same_number]", values.sameAddress)
    formdata.append("restaurant[latitude]", values.latitude)
    formdata.append("restaurant[longitude]", values.longitude)
    formdata.append("restaurant[role_id]", "2")

    formdata.append(
      "restaurant[bank_detail_attributes][bank_name]",
      values.bankName
    )

    formdata.append(
      "restaurant[bank_detail_attributes][account_number]",
      values.accountNumber
    )

    formdata.append(
      "restaurant[bank_detail_attributes][iban]",
      values.ibanNumber
    )

    values.documents.forEach((doc: any) => {
      formdata.append(
        "restaurant[bank_detail_attributes][documents][]",
        doc,
        doc.name
      )
    })

    formdata.append(
      "restaurant[restaurant_type_and_timing_attributes][restaurant_type]",
      values.restaurant_type
    )

    values.cuisines.forEach((item: any) => {
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][cuisines][]",
        item
      )
    })

    values.outlet.forEach((item: any) => {
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][outlet][]",
        item
      )
    })

    values.days.forEach((day: any) => {
      formdata.append(
        "restaurant[restaurant_availabilities_attributes][open_day][]",
        day
      )
    })

    formdata.append("restaurant[restaurant_availabilities_attributes][opens_at]", values.opensAt)
    formdata.append("restaurant[restaurant_availabilities_attributes][closes_at]", values.closesAt)

    values.bannerImages.forEach((banner: any) => {
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][banners][]",
        banner[0],
        banner[0].name
      );
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.postCreateRestaurantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postcreateRestaurantAPiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }



  // Get restaurant details
  getRestaurantDetails = async (id: any) => {
    this.setState({
      loading: true,
      isOtpVerified: false,
      isEdit: false
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRestaurantDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRestaurantDetailsApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Get all restaurants
  getAllRestaurants = async (search: any) => {
    this.setState({
      loading: true
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRestaurantsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants + `?search=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteRestaurant = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRestAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //delete image
  deleteImage = async (id: any) => {

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeImageApiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //delete file
  deleteFile = async (id: any) => {

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removePdfApiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  
  // send otp
  postSendOtp = async (email: any) => {

    this.setState({
      isOtpSending: true
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const httpBody = { email };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSendOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // verify otp
  postVerifyOtp = async (pin: any) => {
    this.setState({
      isOtpVerifying: true
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const httpBody = {
      email_otp_id: this.state.otpId,
      pin: pin
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVerifyOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postVerifyOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handlePlacesAutocompleteSelect = (
    lat: number,
    lng: number,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue("latitude", lat)
    setFieldValue("longitude", lng)
    this.setState({ mapCenter: { lat, lng } })
  }

  handleChangeLat = (
    e: React.ChangeEvent<HTMLInputElement>,
    longitude: string,
    setFieldValue: (key: string, value: any) => void
    ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latLng = {
      lat: Number(targetValue),
      lng: Number(longitude)
    }
    setFieldValue("latitude", targetValue)
    this.setState({ mapCenter: { ...latLng }})
  }

  handleChangeLng = (
    e: React.ChangeEvent<HTMLInputElement>,
    latitude: string,
    setFieldValue: (key: string, value: any) => void
  ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latLng = {
      lat: Number(latitude),
      lng: Number(targetValue)
    }
    setFieldValue("longitude", targetValue)
    this.setState({ mapCenter: { ...latLng }})
  }

  handleChangeLatLng = (
    lat: number,
    lng: number,
    setFieldValue: (key: string, value: number) => void
  ) => {
    const latLng = { lat, lng }
    setFieldValue("latitude", lat)
    setFieldValue("longitude", lng)
    this.setState({ mapCenter: { ...latLng } })
  }

  detectPlace = async (setFieldValue: (field: string, value: any) => void) => {
    try {
      const coords: any = await getCoords()
      const { latitude, longitude } = coords
      setFieldValue("latitude", latitude)
      setFieldValue("longitude", longitude)
      this.setState({
        mapCenter: {
          lat: latitude,
          lng: longitude
        }
      })
    } catch (e) {
      toast.error(e?.message)
    }
  }

  // Update restaurant
  putRestaurant = async (values: any) => {
    this.setState({ createResLoader: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token
    }

    const formdata = new FormData();
    formdata.append("restaurant[name]", values.name);
    formdata.append("restaurant[description]", values.description);
    formdata.append("restaurant[org_address]", values.org_address);
    formdata.append("restaurant[org_owner_name]", values.ownerName);
    formdata.append("restaurant[org_owner_address]", values.ownerEmail);
    formdata.append("restaurant[org_contact_number]", `1${values.phoneNumber}`);
    formdata.append(
      "restaurant[org_owner_contact_number]",
      `1${values.ownerNumber}`
    );
    formdata.append("restaurant[purchase_limit_percentage]", values.purchase_limit_percentage);
    formdata.append("restaurant[is_same_number]", values.sameAddress);
    formdata.append("restaurant[latitude]", values.latitude);
    formdata.append("restaurant[longitude]", values.longitude);
    formdata.append("restaurant[role_id]", "2");
    formdata.append("restaurant[bank_detail_attributes][id]", this.state.bankId)
    formdata.append(
      "restaurant[bank_detail_attributes][bank_name]",
      values.bankName
    );
    formdata.append(
      "restaurant[bank_detail_attributes][account_number]",
      values.accountNumber
    );
    formdata.append(
      "restaurant[bank_detail_attributes][iban]",
      values.ibanNumber || ''
    );
    [...values.uploadedDoc].forEach((doc: any) => {
      formdata.append(
        "restaurant[bank_detail_attributes][documents][]",
        doc
      )
    });
    formdata.append("restaurant[restaurant_type_and_timing_attributes][id]", this.state.timingId)

    formdata.append(
      "restaurant[restaurant_type_and_timing_attributes][restaurant_type]",
      values.restaurant_type
    );

    values.cuisines.forEach((item: any) => {
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][cuisines][]",
        item
      );
    });
    values.outlet.forEach((item: any) => {
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][outlet][]",
        item
      );
    });

    [...values.selectedImages].forEach((banner: any) => {
      // Send file name is banner is a file
      formdata.append(
        "restaurant[restaurant_type_and_timing_attributes][banners][]",
        banner
      )
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putRestaurantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postcreateRestaurantAPiEndPoint}/${this.state.restaurantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  


  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedRest: item.id,
    });
    if (value == "edit") {
      // this.setState({openMenu : true})
      //@ts-ignore
      this.props.history.push(
        '/admin/restaurant/details/edit?id=' + item.id
      );
    }
    if (value == "remove") {
      this.handleDialog();
    }
  };

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  handledeleteRestaurant = async(id: any) => {
    await this.deleteRestaurant(id)
  }

  handleToggle = () => {
    this.setState({ isOn: !this.state.isOn })
  }

  addPriceRules = () => {
    // this.setState({
    //   createOrderPriceRules:
    //     [...this.state.createOrderPriceRules, { ...this.priceRules }]
    // })
  }

  handleTime = (start: any, end: any, day: any) => {
    this.setState({
      start: start,
      end: end,
      day: [...this.state.day, day]
    })
  }

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getAllRestaurants,
    1000)

  handleChangeStepper = (index: number) => {
    this.setState({ restStepperIndex: index });
  };

  handleChangeNextStepper = () => {
    if (this.state.restStepperIndex <= 2) {
      this.setState({
        restStepperIndex: this.state.restStepperIndex + 1,
      });
    }
  };

  handleChangePrevStepper = () => {
    if (this.state.restStepperIndex >= 0) {
      this.setState({
        restStepperIndex: this.state.restStepperIndex - 1,
      });
    }
  };

  handleRegisterRest = () => {
    this.props.history.push(
      `/admin/restaurant/details/register-form?id=${this.state.restaurantID}`
    );

    this.props.handleTabChange("", 1)
  }

  handleImages = async(id: any) => {
    const updatedImages = this.state.images.filter((i: any) => i.id !== id)
    this.setState({ images: updatedImages })
    await this.deleteImage(
      id
    );
  }

  handlePDF = async(id: any) => {
    const updatedDoc = this.state.bankDoc.filter((i: any) => i.id !== id)
    this.setState({ bankDoc: updatedDoc })
    await this.deleteFile(id)
  }

  handleRegistrationEmailChange = (
    value: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    setFieldValue("ownerEmail", value)
    setFieldValue("owner_otp", "")
    if(this.state.isOtpVerified) this.setState({ isOtpVerified: false, isOtpVerifying: false })
  }

  handleResEditEmailChange = (newEmail: string, values: any, setFieldValue: (field: string, value: any) => void) => {
    const verifiedEmail = this.state.restaurantDetails?.restaurant?.data?.attributes?.org_owner_address
    const isPreviousEmail = verifiedEmail === newEmail
    const isOTPVerified = this.state.isOtpVerified || values.isOwnerVerified

    setFieldValue("ownerEmail", newEmail)

    if(isPreviousEmail) {
      this.setState({ isOtpVerified: true })
      setFieldValue("isOwnerVerified", true)
    } else if(!isPreviousEmail && isOTPVerified) {
      this.setState({ isOtpVerified: false })
      setFieldValue("isOwnerVerified", false)
      setFieldValue("owner_otp", "")
    }
  }

  handleResRegistration = async(values: any, actions: any) => {
    const { restStepperIndex, isOtpVerified } = this.state
    const isLastStep = restStepperIndex === 2

    if (isLastStep) {
      await this.postCreateRestaurant(values)
      return
    }
    actions.setTouched({})
    if (isOtpVerified) {
      this.handleChangeNextStepper()
      return
    }
    toast.error("Email not verified. Please verify Email")
  }

  handleResEditSubmit = async(values: any) => {
    if (!this.state.isOtpVerified && !values.isOwnerVerified) {
      toast.error("Email not verified. Please verify Email");
      return;
    }
    await this.putRestaurant(values)
  }

  handleAvailRedirect = () => {
    this.props.handleTabChange("", 2)
  }

  handleOrgNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isSameAddress: boolean,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const newVal = e.target.value
    if (newVal.length <= 10) {
      setFieldValue("phoneNumber", newVal)
      if (isSameAddress) {
        setFieldValue("ownerNumber", newVal)
      }
    }
  }
  
  handleOwnerNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const newVal = e.target.value
    if (newVal.length <= 10) {
      setFieldValue("ownerNumber", newVal)
    }
  }
  
  toggleIsSameNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    orgNumber: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const isChecked = e.target.checked
    setFieldValue("sameAddress", isChecked)
    setFieldValue("ownerNumber", !isChecked ? "" : orgNumber)
  }
  
  handleOTPChange = async(
    otp: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    try{
      setFieldValue("owner_otp", otp)
      if(otp.length == 4){
        await this.postVerifyOtp(otp)
      }
    }
    catch (e) {
      toast.error(e?.message)
    }
  }
  
  toggleImageSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedImages: any[],
    banner: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if(!e.target.checked) {
      setFieldValue("selectedImages", [...selectedImages, banner])
    } else {
      setFieldValue(
        "selectedImages",
        selectedImages.filter((x: any) => x.name !== banner.name)
      )
    }
  }

  handleDocRemove = (
    lastModified:number,
    values: any,
    updatedDocs: object,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newDoc = values.uploadedDoc?.filter((doc:any) => doc.lastModified !== lastModified)
    if (values?.documents?.length > 0) {
      setFieldValue("uploadedDoc", updatedDocs)
    } else {
      setFieldValue("uploadedDoc", newDoc)
    }
  }

  getFormInitialValues = () => {
    const { restaurantDetails, restMode } = this.state
    const restaurant =
      restaurantDetails?.restaurant?.data?.attributes

    const isEditMode = restMode === "edit"

    const timingAndBankDetails = this.getRestaurantTimingAndBankDetails()

    return {
      name: restaurant?.name || "",
      org_address: restaurant?.org_address || "",
      phoneNumber: restaurant?.org_contact_number?.slice(1) || "",
      ownerName: restaurant?.org_owner_name || "",
      ownerEmail: restaurant?.org_owner_address || "",
      isOwnerVerified: isEditMode,
      ownerNumber: restaurant?.org_owner_contact_number?.slice(1) || "",
      sameAddress: !!restaurant?.is_same_number,
      otp: "",
      owner_otp: "",
      latitude: restaurant?.latitude || "",
      longitude: restaurant?.longitude || "",
      description: restaurant?.description || "",
      purchase_limit_percentage: restaurant?.purchase_limit_percentage || 0,
      verifiedAddress: restaurant?.org_owner_address || "",
      ...timingAndBankDetails
    }
  }

  getRestaurantTimingAndBankDetails = () => {
    const { restaurantDetails } = this.state
    const timings =
      restaurantDetails?.restaurant_type_and_timing?.data?.attributes;
    const bankDetails =
      restaurantDetails?.bank_detail?.data?.attributes

    return {
      restaurant_type: timings?.restaurant_type || "",
      outlet: timings?.outlet || [],
      cuisines: timings?.cuisines || [],
      uploadedBanners: [],
      bannerImages: timings?.banners || [],
      selectedImages: [],
      bankName: bankDetails?.bank_name || "",
      accountNumber: bankDetails?.account_number || "",
      ibanNumber: bankDetails?.iban || "",
      uploadedDoc: [],
      documents: bankDetails?.documents || []
    }
  }

  isError = (
    isError:
      | string
      | string[]
      | FormikErrors<any>
      | FormikErrors<any>[]
      | undefined,
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  ) => {
    return isTouched && !!isError
  }

  isOwnerVerified = (isOwnerVerified: boolean, isOtpVerified: boolean) => {
    return isOwnerVerified || isOtpVerified
  }

  getDocName = (name: string | undefined, filename: string) => {
    return name || filename
  }

  mobileNumber = (values:any) => values.sameAddress ? values.phoneNumber : values.ownerNumber

  // Customizable Area End
}
