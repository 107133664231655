// Customizable Area Start
import React from "react"
import { connect } from "react-redux"
import CardListingController, {
    Props,
    ICardProps,
} from "./CardListingController.web"
import "../../../../../components/src/assets/css/webHeader.scss"
import "../../assets/css/shoppingcartcheckout.scss"
import {
    Grid,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    Typography,
    IconButton} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { RedDustbin, WhiteDustbin } from "../../assets"
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import AddCardDialog from "./AddCardDialog.web"
import clsx from "clsx"
import CustomActionDialog from "../../../../../components/src/CustomDialog.web"

class CardListing extends CardListingController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { isOnlySave, selectedCardId, onSelectedCardChange, handlePaymentOnUnSaveCard } = this.props
        const { loader, isRemoveCardDialog, isCardRemoving, isCardDialog, cards } = this.state

        return (
            <>
                {loader ? (
                    <Box className="checkout_payment_loader">
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    <Box>
                        <Grid item xs={12}>
                            <Box className="check_out_ccard_add_card_header">
                                <p>Saved Cards</p>
                                <Button onClick={() => this.openCardDialog()}>Add Card</Button>
                            </Box>
                        </Grid>
                        {cards?.length > 0 ? (
                            cards.map((card: ICardProps) => {
                                const { id, last4, exp_month, exp_year } = card
                                const isSelected = id === selectedCardId

                                return (
                                    <Grid item xs={12} key={id}>
                                        <Box
                                            className={clsx("check_out_ccard_add_card_row_item", {
                                                ["checkout_card_selected"]: isSelected,
                                            })}
                                            onClick={() => onSelectedCardChange(id)}
                                        >
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Box className="ccard_detail_wrapper">
                                                    {!isOnlySave && <Box className="check_Wrapper">
                                                        <Checkbox
                                                            checkedIcon={
                                                                <DoneRoundedIcon className="custom_card_select_checkbox" />
                                                            }
                                                            name="checkedI"
                                                            checked
                                                            style={{ visibility: isSelected ? "visible" : "hidden" }}
                                                            classes={{ root: "check_root" }}
                                                        />
                                                    </Box>}
                                                    <h6>XXXX XXXX XXXX {last4}</h6>
                                                </Box>
                                                <Box className="ccard_detail_wrapper">
                                                    <h6>
                                                        {exp_month}/{exp_year}
                                                    </h6>
                                                    <IconButton
                                                        className="ccard_card_row_action_icon"
                                                        onClick={() => this.openRemoveCardDialog(id)}
                                                    >
                                                        <img src={isSelected ? WhiteDustbin : RedDustbin} alt="remove" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        ) : (
                            <Grid item xs={12}>
                                <Box className="no_card_container">
                                    <Typography variant="h2">No card added</Typography>
                                    <Typography variant="h5">
                                        Click 'Add card' button above to add your card
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Box>
                )}

                <CustomActionDialog
                    open={isRemoveCardDialog}
                    title="Are you sure you want to remove the card?"
                    actionBtnTitle="Yes"
                    closeBtnTitle="No"
                    isLoading={isCardRemoving}
                    isActionDisabled={isCardRemoving}
                    isCloseDisabled={isCardRemoving}
                    handleClose={this.closeRemoveCardDialog}
                    handleAction={this.handleRemoveCard}
                />

                <AddCardDialog
                    isOnlySave={isOnlySave}
                    isOpen={isCardDialog}
                    handleClose={this.closeCardDialog}
                    onCardAdd={this.getCardListApi}
                    handlePayment={handlePaymentOnUnSaveCard}
                />
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

export default withRouter(connect(mapStateToProps, {})(CardListing))
// Customizable Area End
