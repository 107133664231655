import {baseURL} from "../../../../framework/src/config"
import StorageProvider from "framework/src/StorageProvider";

export const getPopularCourses = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_library/courses/get_popular_courses`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getCourseList = async (text,category,current,showCount) => {
    const Token =  await StorageProvider.get("authToken")
    let str = ''
    if(category.length > 0){
    category.forEach((i) => {
        str = str + `&filter_by_category_name[]=${i}`;
    })
}
    try {
        const response = await fetch(category.length > 0 ? `${baseURL}/bx_block_library/courses/course_list?search=${text}${str}&page=${current}&per=${showCount}` : `${baseURL}/bx_block_library/courses/course_list?search=${text}&page=${current}&per=${showCount}`, {
            method: 'GET',
            headers:{
                'Content-Type' : 'application/json',
                token :Token || ""
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getCategoriesOfCourses = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/categories?category_type=e-learning`, {
            method : 'GET',
            headers: {
                'Content-Type' : 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }   
}

export const getElearningDetails = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_settings/settings/learning_details`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}