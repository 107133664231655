// Customizable Area Start
import React from "react"
import { Box, CircularProgress, IconButton, Typography } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { cardBg, removeCMSFavFarm, removeCMSFavRestaurant } from "../../assets"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import Rating from "@material-ui/lab/Rating"
import StarIcon from '@material-ui/icons/Star';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import LPDetailsCardController, { Props } from "./LPDetailsCardController.web"

class LPDetailsCard extends LPDetailsCardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { name, image, description, rating } = this.props
        const { isRemoving } = this.state

        return (
            <>
                <Box className="farms_liked_card_wrapper">
                    <Box className="favourate_icon_wrapper">
                        <IconButton disabled={isRemoving} onClick={this.handleRemoveFromFav}>
                            {isRemoving ? <CircularProgress size={22} /> : <FavoriteIcon />}
                        </IconButton>
                    </Box>
                    <img src={image || cardBg} />
                    <Box className="farms_liked_card_name_wrapper">
                        <Typography className="farms_liked_card_name">{name}</Typography>
                        <Rating
                            className="StyledRating"
                            classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                            name="customized-empty"
                            defaultValue={rating}
                            precision={0.5}
                            readOnly
                            emptyIcon={<StarIcon className="empty_star_rating" fontSize="inherit" />}
                        />
                    </Box>
                    <Box className="card_hidden">
                        <Box className="card_hidden_wrapper">
                            <Box>
                                <Typography className="farms_liked_card_name">
                                    {name}
                                </Typography>
                                <Rating
                                    className="StyledRating"
                                    classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                                    name="customized-empty"
                                    defaultValue={rating}
                                    precision={0.5}
                                    readOnly
                                    emptyIcon={<StarIcon className="empty_star_rating" fontSize="inherit" />}
                                />
                                <p className="farms_liked_card_desc">{description}</p>
                            </Box>
                            <Box className="view_card_details_wrapper">
                                <p>View Details</p>
                                <IconButton
                                    disabled={isRemoving}
                                    onClick={this.handleViewDetails}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
}

export default withRouter(connect(null, { removeCMSFavFarm, removeCMSFavRestaurant })(LPDetailsCard))
// Customizable Area End
