// Customizable Area Start
import React from "react";
// common css
import "../../../web/src/assets/css/content/table.scss";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminRegisterRestaurant.scss";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Box, InputAdornment, Modal, TextField,
  withStyles,
} from "@material-ui/core";
import {DefaultProfileImg,DefaultCourseImg} from "./assets"
import { commonCmptStyles } from "./dropDownCss";
import { withRouter } from "react-router-dom";
// icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "../assets/css/rest-order-list.scss";
import AdminLearningPurchasesController, {
  Props, configJSON,
} from "./AdminLearningPurchasesController.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomOrderTab from "../../../components/src/customOrderTabPurchases.web";
import Loader from "../../../components/src/Loader.web";
import VideoInput from "../../../components/src/videoInput.web";

export class AdminLearningPurchases extends AdminLearningPurchasesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const allSorts = configJSON.sortType
    const purchaseDetails =
      this.state.purchaseDetails && this.state.purchaseDetails.attributes;
    return (
      <>
        {/* main wrapper */}
        <main className="restOrderList commonDropdown farmOrderList">
          {/* main card container */}
          <div className="tableContainer">
            <Loader loading={this.state.loading} />
            {/* filter section */}
            <div className="headerListContainer">
              <div className="prodListHeader">
                <div className="blocks">
                  <h4>{configJSON.purchasesText}</h4>
                </div>
                <div className="blocks">
                  <div className="block1">
                    <div className="customSelect dishSortSelect">
                      <FormControl>
                        <Select
                          data-test-id="sortValue"
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="menu-customize"
                          value={this.state.sortValue}
                          onChange={(e) => {
                            this.setState({
                              sortValue: e.target.value,
                            });
                            this.getPurchaseList(
                              e.target.value,
                              this.state.filterValue,
                              this.state.search,
                              this.state.prodPerPage, 1
                            );
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: configJSON.bottomText,
                              horizontal: configJSON.leftText,
                            },
                            transformOrigin: {
                              vertical: configJSON.topText,
                              horizontal: configJSON.leftText,
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper:
                                classes.sortDropDown +
                                " " +
                                classes.commonSelectui,
                            },
                          }}
                          autoWidth={true}
                        >
                          {allSorts.map((sort: any, index: any) => {
                            return (
                              <MenuItem value={sort} key={sort}>
                                {sort}
                                <span className="checkmark">
                                  <div className="checkmark_circle" />
                                  <div className="checkmark_stem" />
                                  <div className="checkmark_kick" />
                                </span>{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prodListHeader prodListBottomRow">
                <div className="outlineTextfield ">
                  <div className="customTextfield">
                    <TextField
                    data-test-id="searchCoursePurchased"
                      id="input-with-icon-textfield"
                      value={this.state.search}
                      onChange={(e: any) => {
                        this.setState({
                          search: e.target.value,
                        });
                        this.SearchDebounceUpdate(
                          this.state.sortValue,
                          this.state.filterValue,
                          e.target.value,
                          this.state.prodPerPage, 1
                        );
                      }}
                      variant="standard"
                      placeholder={configJSON.restOrderPlaceholderText}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="searchIcon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab section */}
          <div className="tabContainer">
            <CustomOrderTab
              data-test-id="ordersTab"
              productList={this.state.productList}
              getProductList={this.getPurchaseList}
              status={this.state.status}
              search={this.state.search}
              filterValue={this.state.filterValue}
              sortValue={this.state.sortValue}
              handleStatus={this.handleStatus}
              handleMenuClick={this.handleMenuClick}
              count={this.state.purchaseCount}
              prodPerPage={this.state.prodPerPage}
              currentPage={this.state.currentPage}
              total_count={this.state.total_count}
              total_pages={this.state.total_pages}
              thisObject={this}
            />
          </div>
          {/* tab section */}
        </main>
        {/* ends: main wrapper */}

        <Modal
          open={this.state.openDialog}
          onClose={this.handleDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="mainModal adminOrderModal">
            {/* <h1>modal open </h1> */}
            <div className="subModalBlock subModalBlockPurchase">
              <div className="modalHeader">
                <h4>{configJSON.purchaseDetailsText}</h4>
                <CloseIcon className="closeIcon" onClick={this.handleDialog} />
              </div>
              {this.state.purchaseDetails && (
                <div className="modalContent learningPurchaseModalContent">
                  <Grid container spacing={4} className="cardContainer">
                    <Grid item xs={12} sm={6}>
                      <div className="profile-row">
                        <img
                          src={purchaseDetails.profile_pic || DefaultProfileImg}
                          className="purchaseProfile"
                          alt="profile"
                        />
                        <h5>
                          {purchaseDetails.customer_name}
                        </h5>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="profile-row profile-right-row">
                        <h4>{configJSON.purchasedCourseText}</h4>
                        <div className="course-purchase-dialog-row">
                          {purchaseDetails.video ? <VideoInput
                            className="techPurchase"
                            fileData={purchaseDetails.video}
                          /> :
                            <img
                              src={DefaultCourseImg}
                              className="customerPurchaseDefaultCourse"
                              alt="course"
                            />}
                        </div>
                        <h4>{configJSON.courseNameText}</h4>
                        <h5>{purchaseDetails.course_purchased}</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Box className="purchaseForm">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>{configJSON.purchasedByLabel}</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="title"
                              variant="standard"
                              value={purchaseDetails.customer_name}
                              placeholder="John Doe"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>{configJSON.purchaseDateLabel}</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="title"
                              variant="standard"
                              value={purchaseDetails.purchase_details.purchase_date}
                              placeholder="dd/mm/yyy"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>{configJSON.paymentLabel}</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="title"
                              variant="standard"
                              value={purchaseDetails.payment_type}
                              placeholder="Netbanking"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>{configJSON.transactionIdLabel}</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="title"
                              variant="standard"
                              value={purchaseDetails.purchase_details.transaction_id}
                              placeholder="Enter Transaction ID:"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="outlineTextfield boxRadiusBorder">
                          <label>{configJSON.paymentAmountLabel}</label>
                          <br />
                          <div className="customTextfield">
                            <TextField
                              id="title"
                              variant="standard"
                              placeholder="Netbanking"
                              value={`${purchaseDetails.purchase_details.payment_amount}`}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      // spacing={4}
                      className="btnRow cardContainer"
                    >
                      <Grid
                      data-test-id="okBtn"
                        item
                        xs={12}
                        onClick={this.handleDialog}
                      >
                        <span className="greenContained">
                          <CustomButton
                            color="secondary"
                            label={"OK"}
                            type="submit"
                          />
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </>
    );
  }
}
//@ts-ignore
export default withRouter(withStyles(commonCmptStyles)(AdminLearningPurchases));
// Customizable Area End
