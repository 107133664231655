// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import { toast } from "react-toastify";
import StorageProvider from "../../../framework/src/StorageProvider.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  history?: any;
  location?:any
  updateActiveTab?: (activeTab: string) => void
  detail : any
  courseDetail : any
  list :any
  partNum : any
  partName : any
  handleVideoStatus : any
}

interface S {
  mobileOpen: boolean;
  lessonContent: any[],
  lessonId : string,
  listOfLesson : any[]
  value : number,
  playTime : string,
  isPlayVideo : boolean,
  duration : string
  lessonInfo : any
  anchorEl : any
  copy :  boolean
  modalOpen: boolean
  isOwner : boolean
  isVideoComplete : boolean
  isLessonComplete : boolean
}

interface SS {
  id: any;
}

export default class WebEducationCourseDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getLessonDetailCallId: any;
  getListingOfLessonCallId : any;
  postVideoStatusCallId : any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      lessonContent: [],
      lessonId : '',
      listOfLesson : [],
      value : 0,
      playTime : '',
      isPlayVideo : false,
      duration : '',
      lessonInfo : [],
      copy : false,
      anchorEl : null,
      modalOpen : false,
      isOwner : false,
      isVideoComplete : false,
      isLessonComplete : false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
    
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
    //---------------------
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    if (id) {
      this.getLessonDetail(id)
      this.getListingOfLesson()
      this.setState({lessonId : id})
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const data = responseJson && !(responseJson.error || responseJson.errors)

      
      if (data && apiRequestCallId != null && apiRequestCallId === this.getLessonDetailCallId){
        let lessonInfo = responseJson.data.attributes.description.trim()
        let isOwner = responseJson.data.attributes?.is_owner
        let isVideoComplete = responseJson.data.attributes.video_completed
        let isLessonComplete = responseJson.data.attributes.lesson_completed
        this.setState({ lessonContent: responseJson.data, lessonInfo : lessonInfo.split('.'), isOwner : isOwner,
           isVideoComplete: isVideoComplete, isLessonComplete : isLessonComplete })
      }

      if (data && apiRequestCallId != null && apiRequestCallId === this.getListingOfLessonCallId){
        const list = responseJson.data?.filter((i:any) => i.attributes.course_sub_section_id === Number(this.state.lessonId))
        this.setState({listOfLesson : list})
        this.getLessonDetail(this.state.lessonId)
      }

      if (data && apiRequestCallId != null && apiRequestCallId === this.postVideoStatusCallId){
        this.getLessonDetail(this.state.lessonId)
      }
      
    }

  }
  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  };
  

  getLessonDetail = async(id: any) => {
    const token = await StorageProvider.get("authToken");
    const header = {
      "Content-type": 'application/json',
      token : token
    };
    const lessonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLessonDetailCallId = lessonMessage.messageId;

    lessonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library/course_sub_sections/${id}`
    );
    lessonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    lessonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(lessonMessage.id, lessonMessage);
  }

  getListingOfLesson = async() => {
    const token = await StorageProvider.get("authToken");
      const header = {
        "Content-type": 'application/json',
        token : token
      };
      const listLessonMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getListingOfLessonCallId = listLessonMessage.messageId;
  
      listLessonMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library/course_sub_sections/listing_user_course_sub_section`
      );
      listLessonMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      listLessonMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(listLessonMessage.id, listLessonMessage);
  }

  postVideoStatus = async(id:any, status:any) => {
    const token = await StorageProvider.get("authToken");
    const header = {
      "Content-type": 'application/json',
      token : token
    };

    const videoMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVideoStatusCallId = videoMessage.messageId;

    videoMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library/courses/create_user_course_sub_section?course_sub_section_id=${id}&video_status=${status}`
    );
    videoMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    videoMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(videoMessage.id, videoMessage); 
}

  handleVideoStatus = (id:any, status:any) => {
    this.postVideoStatus(id,status)
  }

  handleOptions = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseOptions = () => {
    this.setState({ anchorEl: null });
  };

  handleModalClose = () => {
    this.setState({modalOpen : false})
  }

  handleShare = () => {
    this.setState({modalOpen : true, anchorEl: null})
  }

  handleCopy = async () => {
    const url = window.location.href
    try {
      await navigator.clipboard.writeText(url.substring(0, url.indexOf("sub_section")) + `single?id=${this.props.location.state.content.id}`);
      this.setState({copy : true})
      setTimeout(() => this.setState({ copy: false, modalOpen: false }), 1500);
    } catch (err) {
      toast.error("Link not copied!")
    }
    this.handleCloseOptions();
  }
}
// Customizable Area End