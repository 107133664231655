// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { IBlock } from "../../../../../framework/src/IBlock"
import { RouteComponentProps } from "react-router-dom"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./TransactionHistory.web"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface S {
  isLoading: boolean
  loyaltyPointBalance: number
}

interface SS {
  id: any
}

export default class TransactionHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getLoyaltyPointApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]
    this.receive = this.receive.bind(this)

    this.state = {
      isLoading: false,
      loyaltyPointBalance: 0,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    this.getLoyaltyPointsApi()
  }

  handleGoBack = () => {
    this.props.history.goBack()
  }

  getLoyaltyPointsApi = async () => {
    this.setState({ isLoading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getLoyaltyPointApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardPointsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const isRewardPointsApiResponse =
        apiRequestCallId === this.getLoyaltyPointApiCallId
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (isRewardPointsApiResponse) {
          this.setState({
            isLoading: false,
            loyaltyPointBalance: responseJson.reward_points,
          })
        }
      } else if (isRewardPointsApiResponse) {
        this.setState({
          isLoading: false
        })
        toast.error(configJSON.fetchBalanceErrorMessage)
      }
    }
  }
  // Customizable Area End
}
