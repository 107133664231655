// Customizable Area Start
import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Box } from "@material-ui/core"
import FarmWishlistPanel from "./WishlistPanels/FarmWishlistPanel.web"
import RestaurantWishlistPanel from "./WishlistPanels/RestaurantWishlistPanel.web"
import CourseWishlistPanel from "./WishlistPanels/CourseWishlistPanel.web"

const CustomTabs = withStyles({
  root: {
    backgroundColor: "#ffffff",
  },
  flexContainer: {
    flexWrap: "wrap",
    gap: "25px",
    "@media(max-width: 960px)": {
      justifyContent: "center !important",
    },
  },
  indicator: {
    display: "none",
    backgroundColor: "#fff",
  },
})(Tabs)

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    width: "138px",
    height: "25px",
    padding: "0px 20px",
    borderRadius: "30px",
    backgroundColor: "#f3f0f0",
    minWidth: 72,
    minHeight: '40px',
    "&>.MuiTab-wrapper": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#000 !important",
    },
    fontFamily: [
      "-apple-system",
      "Montserrat",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "#20a258",
      fontWeight: theme.typography.fontWeightMedium,
      "& > .MuiTab-wrapper": {
        color: "#fff !important",
      },
    },
    "&:focus": {
      color: "#fff",
    },
  },
  selected: {},
}))((props: any) => <Tab disableRipple {...props} />)

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={2}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "40px 50px",
    '@media(max-width: 786px)' : {
      margin: "30px 25px",
    },
    "@media(max-width: 400px)": {
      margin: "30px 15px",
    },
  },
  padding: {
    padding: theme.spacing(1),
  },
  tabsHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  wishlistHeading: {
    fontSize: '22px',
    fontWeight: 600,
    color: '#000',
    borderBottom: '1px solid #dfe8e1',
    padding: '10px 0px'
  }
}))

export default function CustomizedTabs() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabsHeader}>
        <CustomTabs value={value} onChange={handleChange}>
          <CustomTab label="Farm" />
          <CustomTab label="Restaurant" />
          <CustomTab label="Courses" />
        </CustomTabs>
        <Typography className={classes.padding} />
        <Typography className={classes.wishlistHeading}>
          Wishlist
        </Typography>
      </div>
      <div>
        <TabPanel value={value} index={0}>
          <FarmWishlistPanel currentTab={value}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RestaurantWishlistPanel currentTab={value}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CourseWishlistPanel currentTab={value}/>
        </TabPanel>
        <Typography className={classes.padding} />
      </div>
    </div>
  )
}
// Customizable Area End
