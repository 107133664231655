// Customizable Area Start
import React from "react"
import { Box, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CommunityNavigator from "../CommunityCustomComponents/CommunityNavigator.web"
import CommunityDiscussionSidebar from "./CommunityDiscussionSidebar.web"
import CommunityDiscussionTabs from "./CommunityDiscussionTabs.web"
import CommunityDiscussionList from "./CommunityDiscussionList.web"
import CommunityDiscussionTagsScreen from "./CommunityDiscussionTagsScreen.web"
import { useSelector } from "react-redux"
import { CommunityFilterEnum } from "../../../../../components/src/redux/Community/communityEnum"

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: "30px 60px",
    "@media screen and (max-width: 450px)": {
      padding: "30px",
    },
    "@media screen and (max-width: 300px)": {
      padding: "30px 15px",
    },
  },
}))

const CommunityDiscussion = () => {
  const classes = useStyles()
  const { discussionTopics, discussionFilter } = useSelector(
    (state: any) => state.community
  )

  const isTagsFilter = discussionFilter === CommunityFilterEnum.TAGS

  return (
    <Box>
      <CommunityNavigator navigationText="Community" />

      <Box className={classes.mainContainer}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} sm={7} md={4} lg={3}>
            <CommunityDiscussionSidebar />
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            {isTagsFilter ? (
              <CommunityDiscussionTagsScreen />
            ) : (
              <>
                <CommunityDiscussionTabs />
                {discussionTopics?.length > 0 && <CommunityDiscussionList />}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CommunityDiscussion
// Customizable Area End
