// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import React from "react"
export const configJSON = require("../../config.js")
import { IDiscussion } from "../CommunityDiscussion/CommunityDiscussionListController.web"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper"
import { CommunityFilterEnum } from "../../../../../components/src/redux/Community/communityEnum"

export interface ITopic {
    id: string
    type: string
    attributes: {
        name: string
        author_name: string
        community_forum_id: number
        created_at: string
        updated_at: string
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    isOpen: boolean
    firstName: string
    lastName: string
    profileImage: string
    topics: ITopic[]
    activeTopicId: string
    discussionFilter: string
    discussions: IDiscussion[]
    handleClose: () => void
    setDiscussionTopics: (topics: ITopic[]) => void
    setDiscussionActiveTopic: (topicId: string) => void
    setDiscussionFilter: (filter: string) => void
    setCommunityDiscussions: (discussions: IDiscussion[]) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    isLoggedInDialog: boolean
    topicsLoader: boolean
    discussionTopicId: string
    discussionHeading: string
    discussionDesc: string
    // Customizable Area End
}

interface SS {
    id: any
}

export default class NewDiscussionDialogController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDiscussionTopicsApiCallId: string = ""
    postNewDiscussionApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            isLoggedInDialog: false,
            topicsLoader: false,
            discussionTopicId: "",
            discussionHeading: "",
            discussionDesc: "",
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getDiscussionTopicsApi()
    }

    handleCloseDialog = () => {
        this.setState({
            discussionTopicId: "",
            discussionHeading: "",
            discussionDesc: "",
        })
        this.props.handleClose()
    }

    handleDiscussionTopicChange = (e: React.ChangeEvent<any>) => {
        this.setState({ discussionTopicId: e.target.value })
    }

    handleHeadingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ discussionHeading: e.target.value })
    }

    handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ discussionDesc: e.target.value })
    }

    handlePostNewDiscussion = async () => {
        const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
        if (!isLoggedIn) {
            this.setState({ isLoggedInDialog: true })
            return
        }
        this.postNewDiscussionApi()
    }

    getDiscussionTopicsApi = async () => {
        const { topics } = this.props
        const isTopicsExist = topics?.length > 0
        !isTopicsExist && this.setState({ topicsLoader: true })

        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getDiscussionTopicsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.communityTopicsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    postNewDiscussionApi = async () => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const { discussionTopicId, discussionHeading, discussionDesc } = this.state
        const httpBody = {
            topic_id: discussionTopicId,
            discussion_heading: discussionHeading,
            description: discussionDesc,
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.postNewDiscussionApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.communityDiscussionsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.postNewDiscussionApiCallId) {
            const {
              activeTopicId,
              discussionFilter,
              discussions,
              setCommunityDiscussions,
              setDiscussionActiveTopic,
              setDiscussionFilter,
            } = this.props
            const { discussionTopicId } = this.state
            if (
              activeTopicId === discussionTopicId &&
              discussionFilter === CommunityFilterEnum.ALL
            ) {
              setCommunityDiscussions([responseJson.data, ...discussions])
            } else {
              setDiscussionFilter(CommunityFilterEnum.ALL)
              setDiscussionActiveTopic(discussionTopicId)
            }
            this.handleCloseDialog()
          }          

        if (apiRequestCallId === this.getDiscussionTopicsApiCallId) {
            if (!!responseJson?.data) {
                const { setDiscussionTopics } = this.props
                setDiscussionTopics(responseJson.data)
                this.setState({ topicsLoader: false })
                return
            }
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if (apiRequestCallId === this.postNewDiscussionApiCallId) {
                    if(isTokenExpireResponse(responseJson)) return

                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
