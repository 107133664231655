import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
import StorageProvider from "../../../../framework/src/StorageProvider.web"
// Customizable Area Start
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router-dom"
import { IFarmProductReducerState } from "../../../../components/src/interfaces/farmProduct"
import { v4 as uuidv4 } from "uuid"
export const configJSON = require("../config")

interface IAddReview {
  id: string
  rating: number
  comment: string
}

interface IOrderPayload {
  cartable_id: number
  cartable_type: string
  cart_id: string
  quantity: number
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  navigation?: any
  id?: string
  // Customizable Area Start
  match: any
  activeTab: string
  userInfo: any
  products: IFarmProductReducerState
  cartId: string
  classes: any
  getCartItems: () => void
  updateActiveTab: (tabName: string) => void
  getFarmProductsDetailsAction: (tabName: string) => Promise<void>
  addToFavoriteProdAction: (id: string) => void
  setCartBadge: (count: number) => void
  addRatingFarmItemAction: (
    ratingDetails: IAddReview,
    clearFunction: () => void
  ) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string
  counter: number
  rating: number
  review: string
  loginDialog: boolean
  addCartLoader: boolean
  currentPrice: string
  selectedVariant: string
  // Customizable Area End
}

interface SS {
  id: any
}

export default class ProductDetailsLayoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  addToCartApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      id: "",
      counter: 1,
      rating: 0,
      review: "",
      loginDialog: false,
      addCartLoader: false,
      currentPrice: "",
      selectedVariant: "",
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const { match, activeTab, updateActiveTab, getFarmProductsDetailsAction } =
      this.props
    this.setState({
      id: match?.params?.id,
    })
    activeTab !== ActiveTabEnum.Market && updateActiveTab(ActiveTabEnum.Market)
    await getFarmProductsDetailsAction(match.params.id)
    this.setProductPriceByVariant()
  }

  async componentDidUpdate(prevProp: Readonly<Props>) {
    const { match, getFarmProductsDetailsAction } = this.props

    if (prevProp.match.params.id !== match.params.id) {
      this.setState({ id: match.params.id })
      await getFarmProductsDetailsAction(match.params.id)
      this.setProductPriceByVariant()
    }
  }

  setProductPriceByVariant = () => {
    const {
      products: {
        farmProductDetails: { variant_options },
      },
    } = this.props

    if (variant_options && variant_options.length > 0) {
      const {
        id,
        attributes: { price },
      } = variant_options[0]
      this.setState({ currentPrice: price, selectedVariant: id })
    }
  }
  
  handleVariantChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target
    const {
      products: {
        farmProductDetails: { variant_options },
      },
    } = this.props
  
    const variantDetails = variant_options.find((variant) => variant.id === value)
    if (variantDetails) {
      const {
        attributes: { price },
      } = variantDetails
      this.setState({ currentPrice: price, selectedVariant: value as string })
    }
  }  

  increaseQuantity = () => {
    const { counter } = this.state
    const {
      products: {
        farmProductDetails: {
          track_quantity: isTrackQuantity,
          available_quantity: availableQuantity,
        },
      },
    } = this.props

    if (!isTrackQuantity || counter < availableQuantity) {
      this.setState({ counter: counter + 1 })
    }
  }

  decreaseQuantity = () => {
    const { counter } = this.state
    if (counter > 1) {
      this.setState({ counter: counter - 1 })
    }
  }

  handleRating = (
    _: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    this.setState({ rating: value ?? 0 })
  }

  handleWriteText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ review: e.target.value })
  }

  handleClear = () => {
    this.setState({
      rating: 0,
      review: "",
    })
  }

  capitalizeValue = (value: string) => {
    if (!value) return ""
    return `${value[0].toUpperCase()}${value.slice(1)}`
  }

  handleAddToFavorite = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    if (isLoggedIn) this.props.addToFavoriteProdAction(this.state.id)
    else this.handleOpenLoginDialog()
  }

  handleReviewSubmit = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    const { id, rating, review } = this.state
    if (isLoggedIn)
      this.props.addRatingFarmItemAction(
        { id, rating, comment: review },
        this.handleClear
      )
    else this.handleOpenLoginDialog()
  }

  stopCartLoader = () => {
    this.setState({ addCartLoader: false })
  }

  startCartLoader = () => {
    this.setState({ addCartLoader: true })
  }

  handleCloseLoginDialog = () => {
    this.setState({ loginDialog: false })
  }

  handleOpenLoginDialog = () => {
    this.setState({ loginDialog: true })
  }

  handleOnLogin = () => {
    this.props.getFarmProductsDetailsAction(this.state.id)
  }

  handleLocalAddToCart = async () => {
    const uniqueId = uuidv4()
    const { id, counter, selectedVariant } = this.state
    const {
      products: { farmProductDetails },
      setCartBadge,
    } = this.props
  
    try {
      const ordersFromStorage =
        (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]"
      let orders = JSON.parse(ordersFromStorage)
  
      const {
        images,
        name,
        description,
        price,
        compare_at_price,
        variant_options,
        variant_description,
        farm: { id: sellerId, name: sellerName, image: sellerImage },
      } = farmProductDetails

      const variantDescription = Array.isArray(variant_description.data)
        ? ""
        : variant_description.data.attributes.description
  
      const order = {
        uniqueId,
        orderCategory: configJSON.CARTABLE_TYPE_FARM,
        cartableId: id,
        quantity: counter,
        productImage: images?.length > 0 ? images[0].url : "",
        name,
        description,
        price,
        comparePrice: compare_at_price,
        selectedVariantId: selectedVariant,
        variant_options,
        variant_description: variantDescription,
        sellerId,
        sellerName,
        sellerImage: sellerImage?.url || "",
      }
  
      const isLastRestaurantOrder = orders.some(
        (order: any) => order.orderCategory === configJSON.CARTABLE_TYPE_MENU
      )
  
      if (isLastRestaurantOrder || orders.length === 0) {
        orders = [order]
      } else {
        const existingOrderIndex = orders.findIndex(
          (order: any) =>
            order.cartableId === id &&
            String(order.selectedVariantId) === String(selectedVariant)
        )
  
        if (existingOrderIndex !== -1) {
          orders[existingOrderIndex].quantity += counter
        } else {
          orders.push(order)
        }
      }
  
      await StorageProvider.set(
        configJSON.ORDERS_STORAGE_KEY,
        JSON.stringify(orders)
      )
      setCartBadge(orders.length)
      toast.success("Item added to cart")
    } catch (error) {
      console.error("Error:", error)
      toast.error("Something went wrong")
    }
  }  

  handleAddToCart = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    const { id, counter, selectedVariant } = this.state
    const { cartId } = this.props

    if (isLoggedIn) {
      const orderDetails = {
        cartable_id: +id,
        cartable_type: configJSON.CARTABLE_TYPE_FARM,
        cart_id: cartId,
        quantity: counter,
        selected_variant_option_id: selectedVariant,
      }
      this.addToCartApi(orderDetails)
      return
    }
    this.handleLocalAddToCart()
  }

  addToCartApi = async (orderDetails: IOrderPayload) => {
    this.startCartLoader()
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    }

    const httpBody = { ...orderDetails }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.addToCartApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.addToCartApiCallId) {
      this.stopCartLoader()
    }

    if (responseJson?.errors) {
      if (Array.isArray(responseJson.errors)) {
        responseJson.errors.forEach((err: any) => {
          toast.error(err?.message)
        })
      } else {
        toast.error(responseJson.errors)
      }
    } else {
      toast.error("Something went wrong")
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.addToCartApiCallId) {
          toast.success("Item added to cart")
          this.stopCartLoader()
          this.props.getCartItems()
        }
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }
}
