// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
export const configJSON = require("./config.js"); 

export interface Props {
    navigation: any;
    id: string;
    updateActiveTab?: (activeTab: string) => void
    replyItem: any;
    name: string;
    del: (replyId : string) => void;
    SaveEditReply : (edittedReply:string, replyId: string, reviewId: string) => void
    isEditSave : boolean;
    classes: any;
}

export interface S {
    isEdit : boolean
    edittedReply : string
}

export interface SS {
    id: any;
}

export default class LearningRepliesController extends BlockComponent<
Props,
S,
SS
>{
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            isEdit : false,
            edittedReply : ''
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
            });
        }
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    }  
    handleDelBtn = () => {
        this.props.del(this.props.replyItem.id)
    }

    handleEdit = () => {
        this.setState({ isEdit: true, edittedReply : this.props.replyItem?.attributes?.description })
    }

    handleEditReplyText = (e:any) => {
        this.setState({ edittedReply: e.target.value })
    }

    handleSaveBtn = () => {
        this.props.SaveEditReply(this.state.edittedReply, this.props.replyItem?.id, this.props.replyItem?.attributes?.review_id)
        this.setState({isEdit : false})
    }
}

// Customizable Area End