Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPatchMethod = "PATCH";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist";
exports.labelBodyText = "Wishlist Body";

exports.btnExampleTitle = "CLICK ME";

exports.AUTH_TOKEN = "authToken";
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue"
exports.CARTABLE_TYPE_MENU = "BxBlockCatalogue::Menu"
exports.CARTABLE_TYPE_COURSE = "BxBlockLibrary::Course"
exports.wishlistApiEndPoint = "bx_block_favourites/favourites/wishlist?type=";
exports.addToCartApiEndPoint= "bx_block_order_management/cart_items/add_to_cart"
exports.toggleFavouriteApiEndPoint= "bx_block_favourites/favourites/favourites"
exports.validityText = "Valid Till: 10/10/2022"
exports.buyCourseBtnText = "Buy Course";
exports.removeFromFavErrorText = "You are not logged in, please login again to continue";
exports.activeText = "active";
exports.courseNotAvailableErrorMsg = "The Course is not available now"
exports.removedFromWishlistErrorMsg = "Item removed from wishlist";
exports.noCoursesText = "Looks like you don't have favorite courses"
// Customizable Area End