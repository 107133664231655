// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import React from "react"
export const configJSON = require("../../config.js")

export interface IExpert {
    id: string
    type: string
    attributes: {
        first_name: string
        last_name: string
        headline: string
        educator_image: {
            id: number
            filename: string
            url: string
        }
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    communityExpertDesc: string
    communityExperts: IExpert[]
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    isExpertDetailsDialog: boolean
    expertId: string
    currentSlidesToShow: number
    isDragging: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityExpertsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sliderRef: any = React.createRef()
    getExpertsApiCallId: string = ""
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            isExpertDetailsDialog: false,
            expertId: "",
            currentSlidesToShow: 5,
            isDragging: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    handleViewAllExperts = () => {
        this.props.history.push(AppRoutings.Experts)
    }

    handleOnReInit = () => {
        const { currentSlidesToShow } = this.state
        if (this.sliderRef?.current) {
            const updatedSlideToShow =
                this.sliderRef.current.innerSlider.props.slidesToShow
            currentSlidesToShow !== updatedSlideToShow &&
                this.setState({ currentSlidesToShow: updatedSlideToShow })
        }
    }

    handleBeforeChange = () => {
        this.setState({ isDragging: true })
    }

    handleAfterChange = () => {
        this.setState({ isDragging: false })
    }

    openExpertDetailsDialog = (id: string) => {
        !this.state.isDragging && this.setState({
            isExpertDetailsDialog: true,
            expertId: id
        })
    }

    closeExpertDetailsDialog = () => {
        this.setState({
            isExpertDetailsDialog: false,
            expertId: ""
        })
    }
    // Customizable Area End
}

