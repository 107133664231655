// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
import { IBlock } from "../../../../framework/src/IBlock"
import { RouteComponentProps } from "react-router-dom"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./AdminTransactionHistory.web"

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface S {
}

interface SS {
  id: any
}

export default class TransactionHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]
    this.receive = this.receive.bind(this)

    this.state = {
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  handleGoBack = () => {
    this.props.history.goBack()
  }
}
// Customizable Area End
