// Customizable Area Start
import React from "react"
import { Button } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/CheckRounded"

interface ICustomServiceButtonProps {
    icon: string
    btnText: string
    isConnected: boolean
    onClick?: () => void
}

const CustomServiceButton = ({
    icon,
    btnText,
    isConnected,
    onClick
}: ICustomServiceButtonProps) => {
    return (
        <Button
            variant="contained"
            disabled={isConnected}
            className="social_btn_account"
            classes={{ label: "btn_color_blue" }}
            startIcon={
                <img src={icon} alt="google_img" className="social_Img_account" />
            }
            endIcon={isConnected ? <CheckIcon className="check_icon" /> : null}
            onClick={onClick}
        >
            {btnText}
        </Button>
    )
}

export default CustomServiceButton
// Customizable Area End
