// Customizable Area Start
import { toast } from "react-toastify"
import userLogout from "./logout"
import { SHARE_TEXT_PREFIX, SHARE_TEXT_POSTFIX } from "./constants"

interface IVariantOption {
  id: string
  type: string
  attributes: {
    price: string
    option_name: string
    weight: string
    is_active: boolean
  }
}

export const getCoords = async () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"))
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords)
        },
        (err: any) => {
          if (err?.code === 1)
            reject(
              new Error(
                "Location permission blocked. Allow permission to detect your current location"
              )
            )
          else reject(new Error("Unable to retrieve your location"))
        }
      )
    }
  })
}

export const getUniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36);
    return dateString + randomness;
}

export const displayLocalTime = (id: any) => {
  const d = new Date(id);
  const arr = d.toLocaleTimeString('en-US').replace(/(.*)\D\d+/, '$1')
  return arr
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleanedNumber = phoneNumberString.replace(/\D/g, '')
  return cleanedNumber.replace(
    /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
    '+$1 ($2) $3-$4'
  )
}

export const variantPriceInfo = (price: string, variantOptions: IVariantOption[] | null) => {
  let displayPrice = price
  let isVariantExist = false
  if(Array.isArray(variantOptions) && variantOptions.length > 0) {
    isVariantExist = true
    displayPrice = variantOptions[0].attributes.price
  }

  return {
    displayPrice,
    isVariantExist
  }
}

export const formatCoord = (value: string | number) => {
  return value.toString().replace(/[^0-9.-]+/g, '')
}

export const getTokenFromURL = (tokenKey: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search)
  const authToken = urlParams.get(tokenKey)
  return authToken
}

export const getShareTitle = (value: string) => {
  return `${SHARE_TEXT_PREFIX} ${value} ${SHARE_TEXT_POSTFIX}`
}

export const isTokenExpireResponse = (response: any) => {
  const errors = response?.errors || response?.error
  const errMsg = errors?.length > 0 ? errors[0]?.message : ""
  return errMsg === "Token has Expired" || errMsg === "Invalid token"
}

export const isTokenExpired = async (response: any) => {
  const isTokenExpireError = isTokenExpireResponse(response)

  if (isTokenExpireError) {
    await userLogout()
    toast.error("Your session has expired. Please log in again to continue")
    return true
  }
  return false
}

export const scrollToTop = (className?: string, blockPosition?: ScrollLogicalPosition) => {
  const headerComponent = document.getElementsByClassName(className || "routesWrapper")
  if (headerComponent.length > 0) {
    headerComponent[0].scrollIntoView({ behavior: "smooth", block: blockPosition })
  }
}
// Customizable Area End