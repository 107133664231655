// Customizable Area Start
import React from 'react'
import { CircularProgress, IconButton } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import "../assets/css/customFavoriteIcon.scss"

interface IProps {
    isFavorite: boolean
    isToggleFav: boolean
    toggleFavorite: () => void
}

interface FavouriteIconProps {
    isFavorite: boolean
}

const FavouriteIconContainer = ({ isFavorite }: FavouriteIconProps) => {
    return (
        isFavorite ? (
            <FavoriteIcon className="fav_fill_icon" />
        ) : (
            <FavoriteBorderIcon className="fav_icon" />
        )
    )
}

const CustomFavoriteIcon = ({
    isFavorite,
    isToggleFav,
    toggleFavorite
}: IProps) => {
    return (
        <IconButton
            size="small"
            disabled={isToggleFav}
            onClick={toggleFavorite}
        >
            {isToggleFav ? (
                <CircularProgress size={18} className="fav_fill_icon" />
            ) : (
                <FavouriteIconContainer isFavorite={isFavorite} />
            )}
        </IconButton>
    )
}

export default CustomFavoriteIcon
// Customizable Area End
