// Customizable Area Start
import React from "react"
import { Box, Container, Grid } from "@material-ui/core"
import AllFavouritesController, { Props } from "./AllFavouritesController.web"
import FavouriteSearchFilter from "./FavouriteSearchFilter.web"
import { updateActiveTab, setFPData } from "./assets"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import FavouriteTabs from "./FavouriteTabs.web"
import Pagination from "@material-ui/lab/Pagination"
import GreenBanner from "../../../components/src/GreenBanner.web"
import WebFooter from "../../../components/src/WebFooter.web"
import { withRouter } from "react-router-dom"

export class AllFavourites extends AllFavouritesController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      loader,
      favSearch,
      lat,
      lng,
      currentTab,
      favouriteList,
      currentPage,
      totalPages,
    } = this.state

    return (
      <>
        <Container>
          <Box paddingTop="30px" paddingBottom="40px">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <FavouriteSearchFilter
                  currentTab={currentTab}
                  favSearch={favSearch}
                  lat={lat}
                  lng={lng}
                  handleSearch={this.handleSearch}
                />

              </Grid>
              <Grid item xs={12}>
                <FavouriteTabs
                  isLoading={loader}
                  favouriteList={favouriteList}
                  activeTab={currentTab}
                  handleTabChange={this.handleTabChange}
                />
              </Grid>
            </Grid>
            {!loader && totalPages > 1 && (
              <Grid container>
                <Grid item xs={12}>
                  <Box className="web_pagination_container">
                    <Pagination
                      page={currentPage}
                      count={totalPages}
                      onChange={(_: any, value: number) =>
                        this.handlePageChange(value)
                      }
                      variant="outlined"
                      shape="rounded"
                      classes={{
                        root: "farm_pagination",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return { activeTab: state.user.activeTab }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateActiveTab: bindActionCreators(updateActiveTab, dispatch),
    setFPData: bindActionCreators(setFPData, dispatch)
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFavourites))
// Customizable Area End
