// Customizable Area Start
import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core"
import FavouriteCustomLocation from "./FavouriteCustomLocation.web"
import { searchImg } from "../../info-page/src/assets"

interface ILocation {
  lat: number | null
  lng: number | null
}

interface IProps {
  favSearch: string
  lat: number | null
  lng: number | null
  currentTab: number
  handleSearch: (searchValue: string, lat: number | null, lng: number | null) => void
}

const FavouriteSearchFilter = ({
  favSearch,
  lat,
  lng,
  currentTab,
  handleSearch,
}: IProps) => {
  const [nearByVal, setNearByVal] = useState<string>("")
  const [searchValue, setSearchValue] = useState<string>("")
  const [position, setPosition] = useState<ILocation>({ lat: null, lng: null })
  const isSearched = !!lat || !!lng || !!favSearch

  useEffect(() => {
    resetFilters()
  }, [currentTab])

  const handleNearByValChange = (address: string) => {
    setNearByVal(address)
  }

  const handleSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const { lat, lng } = position
    setSearchValue(value)
    if(!value) handleSearch(value, lat, lng)
  }

  const handleSearchKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchWithValue()
    }
  }

  const handleAddressSelect = ({ lat, lng }: ILocation) => {
    setPosition({ lat, lng })
  }

  const resetFilters = () => {
    setSearchValue("")
    setPosition({ lat: null, lng: null })
    setNearByVal("")
  }

  const handleSearchWithValue = () => {
    const { lat, lng } = position
    handleSearch(searchValue, lat, lng)
  }

  const handleSubmit = () => {
    if(isSearched) {
      resetFilters()
      handleSearch("", null, null)
      return
    }
    handleSearchWithValue()
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <OutlinedInput
            placeholder="Search for product, farm and categories..."
            fullWidth={true}
            value={searchValue}
            onKeyDown={handleSearchKey}
            onChange={handleSearchValChange}
            classes={{
              root: "filter_input_root",
              input: "filter_input_input",
              focused: "filter_input_focus",
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src={searchImg}
                  alt="search_icon"
                  className="filter_input_icon"
                />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <FavouriteCustomLocation
            nearByVal={nearByVal}
            handleNearByValChange={handleNearByValChange}
            handleNearBySelect={handleAddressSelect}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={3}>
          <Button
            className="filter_advance_search_btn"
            classes={{
              label: "normal_login_btn",
            }}
            onClick={handleSubmit}
          >
            {isSearched ? "Clear Search" : "Advanced Search"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FavouriteSearchFilter
// Customizable Area End
