// Customizable Area Start
import React from "react";
import { WomanPicture, DustbinIcon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import { commonCmptStyles } from "./dropDownCss";
import { withRouter } from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import CustomProgressbar from "../../../components/src/CustomProgressbar.web";
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import AdminFarmCampaignsController, {
  Props,
} from "./AdminFarmCampaignsController.web";
import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  withStyles,
  IconButton,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../assets/css/adminRestaurantForumsEdit.scss";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// Customizable Area End

// Customizable Area Start
const regex = /^\d{0,5}(\.\d{1,2})?$/;
const Schema = Yup.object().shape({
  heading: Yup.string().required("This field is required."),
  tagline: Yup.string().required("This field is required."),
  description: Yup.string()
    .max(1000, "Description must be at most 1000 characters.")
    .required("This field is required."),
  banner: Yup.string().required("This field is required."),
  required_donation: Yup.string().test(
    'Is positive?', 
    'The number must be greater than 0!', 
    (value) => value > 0
    //@ts-ignore
  ).matches(regex,"Donation with five digits and two decimal points are allowed").test(
    'Is positive?', 
    'The donation must be greater than 0!', 
    (value:any) => value > 0
  ),
})
// Customizable Area End
class AdminFarmCampaignsEdit extends AdminFarmCampaignsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { forumDetails, productMode, openDialog, loading } = this.state

    const farmStatus = ["active", "draft"];
    const campaignDetails = forumDetails?.attributes;
    const donnerList = campaignDetails?.donations?.data || []

    return (
      <>
        {/* main card container */}
        <Formik
          enableReinitialize
          initialValues={this.gettingInitialValue(campaignDetails,forumDetails)}
          validationSchema={Schema}
          onSubmit={(values) => {
            this.createForum(values);
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, handleChange, touched, errors } =
              formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Box className="restaurantForumsEditContainer">
                  {/* header start */}
                  <div className="header">
                    <div className="leftSideHeader">
                      <IconButton onClick={() => this.props.history.goBack()}>
                        <ArrowBackIcon />
                      </IconButton>
                      <h4>
                        {productMode == "create"
                          ? "Create Campaign"
                          : "Edit Campaign"}
                      </h4>
                    </div>
                    {!loading &&
                      <div className="rightSideHeader commonDropdown">
                        <div className="">
                          <div
                            className={`customSelect campaignSelect itemStatus ${values.status == "draft" && "warning-variant"
                              }`}
                          >
                            <FormControl>
                              <Select
                                id="demo-simple-select-helper"
                                labelId="demo-simple-select-helper-label"
                                className="menu-customize campaign_status"
                                value={values.status}
                                name="status"
                                onChange={(e: any) => {
                                  setFieldValue("status", e.target.value);
                                }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  classes: {
                                    paper: classes.itemStatus,
                                  },
                                }}
                              >
                                {farmStatus.map((statusVal: string) => {
                                  return (
                                    <MenuItem value={statusVal} key={statusVal}>
                                      {statusVal}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                          {/* <CustomSelectField /> */}
                        </div>
                        {productMode == "edit" && (
                          <img src={DustbinIcon}
                            className="campaign_delete_icon"
                            onClick={() => {
                              this.setState({
                                removedProduct: forumDetails.id,
                              });
                              this.handleDialog();
                            }}
                          />
                        )}
                      </div>}
                  </div>

                  {/* header start */}
                  {/* main wrapper start */}
                  {loading ? <div className="loader_container">
                    <CircularProgress />
                  </div>
                    :
                    <div className="forumMainWrapper">
                      {/* image container start */}
                      {values.banner ? (
                        <div className="imageContainer">
                          <div className="image">
                            {this.settingBannerImage(values)}
                          </div>
                          {/* <Button>Edit Banner</Button> */}
                          <label className="editBannerBtn">
                            <input
                              id="images"
                              name="images"
                              type="file"
                              hidden
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e: any) => {
                                this.bannerUpload(e.currentTarget.files,setFieldValue)}}
                            />
                            Edit Banner
                          </label>
                        </div>
                      ) : (
                        <div className="mediaContainer forumsMediaContainer">
                          <div className="mediaUploader" onDragOver={(e:any)=>this.handleDragOver(e)} onDrop={(e:any)=>this.handleDragDrop(e,setFieldValue)}>
                            <label>
                              <input
                                id="banner"
                                name="banner"
                                type="file"
                                hidden
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={(e: any) => {
                                  e.currentTarget.files.length > 0 &&
                                    setFieldValue("banner", e.currentTarget.files);
                                }}
                              />
                              <BackupOutlinedIcon />
                              <span>Drag and drop a file or click here</span>
                            </label>
                          </div>
                        </div>
                      )}
                      <span className="errorMsg">
                        <ErrorMessage name="banner" />
                      </span>

                      {/* image container end */}

                      {/* likescomment conatiner start */}
                      <div className="forumResponseContainer">
                        <h4 className="campaignSubtitle">
                          Donation Raised
                          <span>${campaignDetails?.donation_raised || 0}</span>
                        </h4>
                        <div className="buttonContainer">
                          <Button type="submit">
                            {this.settingProductText(productMode)}
                          </Button>
                        </div>
                      </div>
                      {/* likescomment conatiner end */}
                     <div className="forumHeaderDescription">
                        <h4>Tagline</h4>
                        <div className="customTextArea customTextAutosize">
                          <Textarea
                            id="tagline"
                            data-test-id="tagline"
                            value={values.tagline}
                            placeholder="Write campaign tagline here..."
                            onChange={(e:any)=>this.textAreaChange(e,setFieldValue,"tagline")}
                            endDecorator={
                              <Typography level="body3" sx={{ ml: 'auto' }}>
                                {this.handleEndDecorator(values.tagline)}/6
                              </Typography>
                            }
                          />
                        </div>

                        <span className="errorMsg">
                          <ErrorMessage name="tagline" />
                        </span>
                      </div>
                      <div className="forumHeaderDescription">
                        <h4>Heading</h4>
                        <div className="customTextArea customTextAutosize">
                          <Textarea
                            id="heading"
                            value={values.heading}
                            placeholder="Write campaign heading here..."
                            onChange={(e: any) => {
                              this.textAreaChange(e,setFieldValue,"heading")                             
                            }}
                            endDecorator={
                              <Typography level="body3" sx={{ ml: 'auto' }}>
                                {this.handleEndDecorator(values.heading)}/25
                              </Typography>
                            }
                          />
                        </div>

                        <span className="errorMsg">
                          <ErrorMessage name="heading" />
                        </span>
                      </div>
                      <div className="forumHeaderDescription">
                        <h4>Description</h4>
                        <textarea
                          cols={30}
                          rows={10}
                          id="description"
                          value={values.description}
                          placeholder="Write campaign Description here..."
                          onChange={handleChange}
                        />
                        <span className="errorMsg">
                          <ErrorMessage name="description" />
                        </span>
                      </div>

                      <div className="donationContainer">
                        <div className="donationHeading">
                          <h4>Donation</h4> <hr />{" "}
                        </div>

                        <div className="donationInfo farmsDonation">
                          <div className="requiredDontation">
                            <h3>Required Donation</h3>
                            <div className="outlineTextfield boxRadiusBorder">
                              <div className="customTextfield">
                                <TextField
                                  id="required_donation"
                                  name="required_donation"
                                  variant="outlined"
                                  value={values.required_donation}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e: any) => this.handleDonationValueChange(e.target.value, setFieldValue)}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="required_donation" />
                                </span>
                              </div>
                            </div>
                          </div>
                          {productMode == "edit" && (
                            <>
                              <div className="donationRaised">
                                <h3>Donation Raised</h3>

                                <h4>
                                  $
                                  {this.handleCampaignDonationValue(campaignDetails)}
                                </h4>
                              </div>

                              <div className="donationRaisingpercentage">
                                <div className="donationBlock">
                                  <CustomProgressbar
                                    heading="Donation"
                                    margin={Number(campaignDetails.donation_raised_percentage.replace("%", ""))}
                                  />
                                  <h3>
                                    {campaignDetails &&
                                      campaignDetails.donation_raised_percentage}{" "}
                                    donation <br /> Raised
                                  </h3>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {productMode == "edit" && (
                          <div className="donnersContainer">
                            <h3>Donors</h3>
                            {donnerList.length > 0 ? (
                              <div className="donnersList">
                                {donnerList.map((donnerDetail: any) => {
                                  const { customer_name, amount, date, image } =
                                    donnerDetail.attributes;

                                  return (
                                    <div
                                      className="donnerContainer"
                                      key={donnerDetail.id}
                                    >
                                      <div className="profile">
                                        <img
                                          src={this.gettingImage(image,WomanPicture)}
                                          alt="profile"
                                        />
                                        <p>{customer_name}</p>
                                      </div>
                                      <p>{moment(date).format("DD MMM, YYYY")}</p>
                                      <h3>${amount}</h3>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="empty_donners_container">
                                <p>No donation received yet</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>}
                  {/* remove modal */}
                  <div>
                    <Dialog
                      open={openDialog}
                      onClose={() => this.handleDialog()}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Are you sure you want to delete the campaign ?
                      </DialogTitle>
                      <DialogActions>
                        {/* //@ts-ignore */}
                        {/* <Button onClick={() => this.handleDialog()}>No</Button>
                        <Button
                          onClick={() => {
                            this.handleDialog();
                            this.deleteForum();
                          }}
                          autoFocus
                        >
                          Yes
                        </Button> */}
                        <span className="redContained customCancelBtn">
                          <CustomButton
                            color="secondary"
                            label="No"
                            onClick={() => this.handleDialog()}
                          />
                        </span>
                        <span className="greenContained">
                          <CustomButton
                            data-test-id="yesBtn"
                            color="secondary"
                            label="Yes"
                            onClick={() => {
                              this.handleDialog();
                              this.deleteForum();
                            }}
                            autoFocus
                          />
                        </span>
                      </DialogActions>
                    </Dialog>
                  </div>
                  {/* main wrapper end */}
                </Box>
              </Form>
            );
          }}
        </Formik>
        {/* ends: main card container */}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminFarmCampaignsEdit}
//@ts-ignore
export default withRouter(withStyles(commonCmptStyles)(AdminFarmCampaignsEdit));
// Customizable Area End
