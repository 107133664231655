//Customizable Area Start
import React from "react"
import { Grid, Box, Typography } from "@material-ui/core"
import "../../assets/css/checkout/checkoutdelivery.scss"
import "../../../../../web/src/assets/css/webstyle.scss"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import OrderSummaryShoppingCard from "../../../../ShoppingCart/src/shoppingCartWeb/OrderSummaryShoppingCard.web"
import OrderSummaryWebController, {
  configJSON,
} from "./OrderSummaryWebController.web"
import { createStyles, withStyles } from "@material-ui/core/styles"

export const styles = () =>
  createStyles({
    total: {
      color: "#05172c",
      fontSize: "22px",
    },
    totalItem: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#05172c",
      fontFamily: "$PTSans",
    },
  })

export class CheckOutOrderSummaryWeb extends OrderSummaryWebController {
  render() {
    const { checkoutOrders, classes } = this.props

    return (
      <Box>
        {checkoutOrders?.map((order: any) => {
          const { product_owner_details, product_details, total_item } =
            order.attributes
          const {
            id: sellerId,
            name: sellerName,
            image: sellerImage,
            type,
          } = product_owner_details.data.attributes

          return (
            <Box className="shopping_cart_wrapper_box" mb={5} key={order.id}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={7} md={6} xl={7}>
                  <Box className="shopping_cart_details">
                    <Link to={this.handleRoute(type, sellerId)}>
                      <Box>
                        <img src={sellerImage} alt="seller" width="70px" height="70px" />
                        <h3>{sellerName}</h3>
                      </Box>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {product_details?.data?.map((product: any) => {
                    const {
                      name,
                      image,
                      order_price,
                      description,
                      order_compare_at_price,
                      quantity,
                      special_cooking_instruction,
                      addons,
                    } = product.attributes

                    const variantDetails =
                      type === configJSON.farmText
                        ? {
                          selectedVariantDetails: product.attributes.selected_variant_details,
                          variantDescription: product.attributes.variant_description,
                        }
                        : {
                          selectedVariantDetails: null,
                          variantDescription: "",
                        }

                    return (
                      <Box key={product.id} mb={8}>
                        <OrderSummaryShoppingCard
                          isFarmOrder={type === configJSON.farmText}
                          isLearningOrder={type === configJSON.courseText}
                          name={name}
                          image={image}
                          description={description}
                          price={order_price}
                          discountPrice={order_compare_at_price}
                          quantity={quantity}
                          productId={product.id}
                          addOns={addons}
                          {...variantDetails}
                          cookingInstruction={special_cooking_instruction}
                        />
                      </Box>
                    )
                  })}
                </Grid>

                <Grid item xs={12} lg={5}>
                  <Box className="order_summary_total_price">
                    <Typography variant="h3" className={classes.total}>
                      {configJSON.totalText}
                    </Typography>
                    <Typography variant="h4" className={classes.totalItem}>
                      ${total_item}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )
        })}
      </Box>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    checkoutOrders: state.user.checkoutOrder?.orders?.data || [],
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, {})(CheckOutOrderSummaryWeb)
)
//Customizable Area End
