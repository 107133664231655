// Customizable Area Start
import React, { Component } from "react"
import { Box, Typography } from "@material-ui/core"
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
const configJSON = require("./config.js")

interface IProps extends WithStyles<typeof styles> {
  heading: string
  value: { id: number; filename: string; url: string } | Blob | null
  handleImageAdded: (file: File | File[]) => void
}

export class CustomUploadImage extends Component<IProps> {
  bannerRef: React.RefObject<HTMLInputElement> = React.createRef()

  handleUploadBannerClick = () => {
    if (this.bannerRef.current) {
      this.bannerRef.current.click()
    }
  }

  validateAndSetUploadedImage = (file: File) => {
    if (configJSON.allowedExtensionText.includes(file.type)) {
      this.props.handleImageAdded(file)
      return
    }
    toast.error(configJSON.allowedImageExtensionsMessage)
  }

  handleBannerUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const file = e.currentTarget.files[0]
      this.validateAndSetUploadedImage(file)
    }
  }

  handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleBannerDragDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.validateAndSetUploadedImage(e.dataTransfer.files[0])
    }
  }

  render() {
    const { heading, classes, value } = this.props

    return (
      <>
        {value ? (
          <Box className={classes.editBannerWrapper}>
            <img
              src={"url" in value ? value.url : URL.createObjectURL(value)}
              alt="banner"
            />
            <button type="button" onClick={this.handleUploadBannerClick}>
              {configJSON.editCampaignMenu}
            </button>
          </Box>
        ) : (
          <Box
            data-testid="banner-upload-wrapper"
            className={classes.addBannerWrapper}
            onClick={this.handleUploadBannerClick}
            onDragOver={this.handleDragOver}
            onDrop={this.handleBannerDragDrop}
          >
            <BackupOutlinedIcon />
            <Typography>{heading}</Typography>
          </Box>
        )}
        <input
          hidden
          type="file"
          ref={this.bannerRef}
          accept={configJSON.allowedImageExtensions}
          onChange={this.handleBannerUpload}
        />
      </>
    )
  }
}

export const styles = () =>
  createStyles({
    editBannerWrapper: {
      height: "200px",
      borderRadius: "12px",
      position: "relative",
      overflow: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      "& button": {
        position: "absolute",
        top: "10px",
        right: "10px",
        borderRadius: "10px",
        padding: "8px 22px",
        backgroundColor: "#fff",
        border: "none",
        color: "#131313",
        fontFamily: "'Poppins', sans-serif",
      },
    },
    addBannerWrapper: {
      width: "100%",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      flexDirection: "column",
      border: "1px solid #c1c1c1",
      borderRadius: "12px",
      cursor: "pointer",
      "& svg": {
        color: "#20a258",
        fontSize: "4rem",
      },
      "& p": {
        fontSize: "18px",
        color: "#20a258",
        fontFamily: "'Poppins', sans-serif",
      },
    },
  })

export default withStyles(styles)(CustomUploadImage)
// Customizable Area End
