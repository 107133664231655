import * as React from "react";
// custom components
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepButton, StepConnector, SvgIcon } from "@material-ui/core";
import LensIcon from '@material-ui/icons/Lens';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import "../../web/src/assets/css/content/customStepper.scss";



const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#f8892b',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#f8892b',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);



const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#444444',
    backgroundColor: "#f0f0f0"
  },
  active: {
    color: '#ffffff',
    backgroundColor: "#f8892b",

  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});
const CustomStepper = (props: any) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [1, 2, 3]


  React.useEffect(() => {
    setActiveStep(props.step);
  }, [props.step])

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    props.changeStepper(step)
  };



  const StepIcon = (props: any) => {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;
    // console.log(props)
    return (
      <div className={`stepIcon ${active || completed ? classes.active : classes.root} `}>

        {icon}


        {/* <div style={{ position: 'absolute', top: 5, left: 0, width: '100%', height: '100%', textAlign: 'center', lineHeight: '24px', color: "red" }}>1</div> */}
      </div>
    );
  }
  return (
    <div className="customStepperContainer">
      <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
        {steps.map((label, index) => (
          <Step key={label} 
          // onClick={handleStep(index)}
           >
            {/* <StepLabel >{label}</StepLabel> */}
            {/* <StepButton
              icon={<StepIcon label={index} />}

            /> */}
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
            {/* <StepLabel >{label}</StepLabel> */}
          </Step>
        ))}
      </Stepper>
    </div >
  );
}
export default CustomStepper
