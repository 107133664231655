// Customizable Area Start
import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import LPLatestUpdateCard from "./LPLatestUpdatesCard.web"
import LPCustomCarousel from "./LPCustomCarousel.web"
import { connect } from "react-redux"
import AdCampaignWrapper from "../../../../LocalListingAds/src/AdCampaignWrapper.web"
import { IImage } from "../../../../../components/src/interfaces/common"

enum IType {
  FARM = "Farm",
  RESTAURANT = "Restaurant",
  COURSE = "Course"
}
interface ILatestUpdateCard {
  id: string
  type: string
  attributes: {
    type: IType
    name: string
    is_ad: boolean
    third_party_ad_url: string | null
    image: IImage
    advertisement_banner: IImage | null
    banner: IImage | null
  }
}

interface ILPLatestUpdates {
  LPLatestUpdatesDesc: string
  LPLatestUpdates: ILatestUpdateCard[]
}

const CAROUSEL_BREAKPOINTS = [
  {
    breakpoint: 950,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    },
  },
  {
    breakpoint: 550,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    },
  }
]

const LPLatestUpdates = ({
  LPLatestUpdatesDesc,
  LPLatestUpdates,
}: ILPLatestUpdates) => {
  const [isDragging, setIsDragging] = useState(false)

  const handleBeforeChange = () => {
    setIsDragging(true)
  }

  const handleAfterChange = () => {
    setIsDragging(false)
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box className="landing_page_section_details">
            <h1 className="h1">Latest Updates</h1>
            <Typography align="center" className="grey_text">
              {LPLatestUpdatesDesc}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <LPCustomCarousel
        type="latest"
        emptyMessage="No latest updates found"
        sliderProps={{ responsive: CAROUSEL_BREAKPOINTS }}
        handleBeforeChange={handleBeforeChange}
        handleAfterChange={handleAfterChange}
      >
        {LPLatestUpdates.map((card: ILatestUpdateCard) => {
          const {
            id,
            attributes: {
              type,
              name,
              image,
              is_ad,
              third_party_ad_url,
              advertisement_banner,
              banner,
            },
          } = card
          const imageUrl = image && image.url ? image.url : ""
          const adImage = banner ? banner.url : imageUrl
          const thirdPartyImageUrl = advertisement_banner
            ? advertisement_banner.url
            : undefined          

          return (
            <AdCampaignWrapper
              isAd={is_ad}
              isLatestUpdateAd
              thirdPartyImageUrl={thirdPartyImageUrl}
              thirdPartyUrl={third_party_ad_url}
            >
              <LPLatestUpdateCard
                key={id}
                id={id}
                isAd={is_ad}
                type={type}
                heading={name}
                image={adImage}
                isDragging={isDragging}
              />
            </AdCampaignWrapper>
          )
        })}
      </LPCustomCarousel>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    LPLatestUpdatesDesc: state.cms.LPLatestUpdatesDesc,
    LPLatestUpdates: state.cms.LPLatestUpdates,
  }
}

export default connect(
  mapStateToProps,
  {}
)(LPLatestUpdates)
// Customizable Area End
