// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import CommunityDiscussionTabsController, {
    Props,
    ITopic,
} from "./CommunityDiscussionTabsController.web"
import { connect } from "react-redux"
import { setDiscussionActiveTopic, setDiscussionTopics } from "../../assets"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import clsx from "clsx"
import "../../assets/css/community/communityDiscussionTabs.scss"

class CommunityDiscussionTabs extends CommunityDiscussionTabsController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { activeTopicId, discussionTopics } = this.props
        const { loader } = this.state

        return (
            <Box>
                {loader ? (
                    <Box className="cd_loader_container">
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    <Box className="cd_topics_main_wrapper">
                        <Box className="cd_topics_container">
                            {discussionTopics?.map((topic: ITopic) => {
                                const {
                                    id,
                                    attributes: { name },
                                } = topic

                                return (
                                    <Box
                                        key={id}
                                        className={clsx("cd_topic", {
                                            ["cd_topic_active"]: activeTopicId === id,
                                        })}
                                        onClick={() => this.handleTopicSelectionChange(id)}
                                    >
                                        <Typography>{name}</Typography>
                                        <Box />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        activeTopicId: state.community.activeTopicId,
        discussionTopics: state.community.discussionTopics,
    }
}

export default withRouter(
    connect(mapStateToProps, { setDiscussionActiveTopic, setDiscussionTopics })(CommunityDiscussionTabs)
)
// Customizable Area End
