// Customizable Area Start
import React from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import SearchProductsController from "./SearchProductsController.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import SearchResultFilters from "../SearchResultFilters.web"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import FarmsProductsImgCard from "../../../../../components/src/FarmsProductsImgCard.web"
import CustomHeading from "../../../../info-page/src/Farm/FarmHeadingWithFilterInput.web"
import { ISearchProduct } from "../../interfaces/search"
import SearchCustomLayout from "../SearchCustomLayout.web"
import { styles } from "../commonStyles"
const configJSON = require("../../config.js")

export class SearchProducts extends SearchProductsController {
  render() {
    const {
      loader,
      categoriesLoader,
      priceRangeLoader,
      tagsLoader,
      totalPages,
      categories,
      minPrice,
      maxPrice,
      tags,
      products,
    } = this.state
    const isFiltersLoading = categoriesLoader || priceRangeLoader || tagsLoader

    return (
      <>
        <Container fixed>
          <Box style={styles.mainWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <SearchResultFilters
                  categoryTitle={configJSON.relatedCategoriesText}
                  categories={categories}
                  isToShowPriceFilter={true}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  isToShowNearByFilter={false}
                  nearByText=""
                  tags={tags}
                  isLoading={isFiltersLoading}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <CustomHeading heading={configJSON.productsText} />
                <SearchCustomLayout
                  loader={loader}
                  totalPages={totalPages}
                  listLength={products.length}
                  emptyMessage={configJSON.noProductsFoundText}
                >
                  <Grid container spacing={3}>
                    {products.map((farm: ISearchProduct) => {
                      const {
                        id,
                        attributes: {
                          name,
                          description,
                          images,
                          price,
                          compare_at_price,
                        },
                      } = farm
                      const imageUrl =
                        Array.isArray(images) && images.length > 0
                          ? images[0].url
                          : ""
                      const redirectUrl = `${AppRoutings.FarmProductDetails}/${id}`

                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                          <FarmsProductsImgCard
                            cardName={name}
                            thumbnail={imageUrl}
                            description={description}
                            displayPrice={configJSON.blockText}
                            displayButton={configJSON.noneText}
                            displayDuration={configJSON.noneText}
                            displayRating={configJSON.noneText}
                            boxShadow={false}
                            amount={price}
                            discountPrice={compare_at_price}
                            path=""
                            imgPath={redirectUrl}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </SearchCustomLayout>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withRouter(SearchProducts)
// Customizable Area End
