// Customizable Area Start
import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import "../../web/src/assets/css/content/progressbar.scss";

const useStylesCircularProgress = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: "#e8e8e8",
    },
    topRight: {
      color: "#20a258",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    topLeft: {
      color: "red",
      animationDuration: "550ms",
      position: "absolute",
      right: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
)

interface ICustomProgressbarProps {
  heading: string
  margin: number
}

const CustomProgressbar = ({ heading = "Profit", margin }: ICustomProgressbarProps) => {
  const classes = useStylesCircularProgress()

  return (

    <div className="customProgressbar">
      <div className="circularprog">
        <div className="circularprogLabel">{heading}</div>
        <div className="circularProgressBar">
          <div className={classes.root}>
            <CircularProgress
              variant="determinate"
              className={classes.bottom}
              size={40}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              disableShrink
              className={`${margin>0 ? classes.topRight: classes.topLeft}`}
              size={40}
              thickness={4}
              value={Number(Math.abs(margin)>100 ? 100 :margin)}
            />
          </div>
          <p>{margin}%</p>
        </div>
        <h3>{margin}% Margin</h3>
      </div>
    </div>
  );
}

export default CustomProgressbar
// Customizable Area End
