import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  getCartItems: () => Promise<void>
  getUserDetails: (userDetails: any) => void
  setUserCartId: (id: string) => void
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  formType:any;
  rolesData: any[];
  roles: Array<string>;
  token: string;
  selectedRole1: boolean;
  selectedRole2: boolean;
  selectedRole3: boolean;
  isLoading: boolean;
  response: object;
  accountCreated: boolean;
  registerFailedError: string;
  registerSchema: any;
  createAccount: boolean;
  regErrors:Array<Object>;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id?: any;
  // Customizable Area End
}

export default class OnlineEmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  getRolesApiCallId: string = "";
  updateRolesApiCallId: string = "";
  bulkAddToCartApiId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this)
    // Customizable Area End

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    let RegisterSchema = Yup.object().shape({
      first_name: Yup.string().required("First name is required"),

      last_name: Yup.string().required("Last name is required"),

      email: Yup.string()
        .trim()
        .email("Invalid email address format")
        .required("Email is required"),
        

      password: Yup.string()
        .min(3, "Password must be 8 characters at minimum")
        .required("Password is required")
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special case character"
        ),
    })

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      formType:"",
      rolesData: [],
      roles: [],
      selectedRole1: false,
      selectedRole2: false,
      selectedRole3: false,
      isLoading: false,
      response: {},
      createAccount: false,
      accountCreated: false,
      registerFailedError: "",
      registerSchema: RegisterSchema,
      regErrors:[],
      token: "",
    }

    this.arrayholder = [];
    this.passwordReg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    this.emailReg = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
          console.log("arrholder", this.arrayholder);

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
        
        if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
            const { formType } = this.state

            if(!formType) {
              this.setState({ isLoading:false })
              if (responseJson.hasOwnProperty("meta")) {
                const { getUserDetails, setUserCartId, getCartItems } = this.props
                const { account, cart, token } = responseJson.meta
                const { is_admin, first_name, last_name } = account

                await StorageProvider.set(configJSON.TOKEN, token)
                await StorageProvider.set(configJSON.AUTH_TOKEN, token)
                await StorageProvider.set(configJSON.ADMIN_AUTH_KEY, is_admin)
                await StorageProvider.set(configJSON.FIRST_NAME_KEY, first_name)
                await StorageProvider.set(configJSON.LAST_NAME_KEY, last_name)
                localStorage.setItem(configJSON.AUTH_TOKEN, token)
                localStorage.setItem(configJSON.PROFILE_KEY, JSON.stringify(account))
                localStorage.setItem(configJSON.CART_ID_KEY, JSON.stringify(cart.id))

                getUserDetails(account)
                setUserCartId(cart.id)
                const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
                const orderLength = JSON.parse(orders).length
                orderLength > 0 ? await this.uploadAllLocalCartItems(responseJson.meta.token) : await getCartItems()
                
                if (await StorageProvider.get(configJSON.AUTH_TOKEN)) {
                  this.setState({ accountCreated: true });
                  orderLength <= 0 && this.props.history.push("/select-your-role");
                }
              }
            }

          } else {

            this.setState({ isLoading:false })
            if (responseJson.hasOwnProperty("errors")) {
              this.setState({
                regErrors:responseJson.errors
              })

              if (
                responseJson.hasOwnProperty("errors") &&
                responseJson.errors[0].account === "Already Exist"
              ) {
                this.setState({
                  registerFailedError: "This account already exists",
                });
              } else {
                this.setState({
                  registerFailedError: "",
                });
              }
            }
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.bulkAddToCartApiId) {
          await StorageProvider.remove(configJSON.ORDERS_STORAGE_KEY)
          await this.props.getCartItems()
          this.props.history.push("/select-your-role");
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }
  
  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0 || str === undefined || str === "";
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  handleSignUp = (values: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
  }) => {
    this.createAccount(values)
  }

  createAccount(values: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
  }): boolean {
    this.setState({
      registerFailedError: "",
      isLoading:true
    });

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email.trim(),
      password: values.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      roles: this.state.roles,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  // get all roles
  getRoles = async () => {
    console.log("hi roles");

    this.setState({ isLoading: true });

    const Token = await StorageProvider.get("authToken")
      ? await StorageProvider.get("authToken")
      : await StorageProvider.get("authToken")
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: Token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetRoles
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  uploadAllLocalCartItems = async (token: string) => {
    const orders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJSON = JSON.parse(orders)

    if(ordersJSON.length > 0) {
      const orderType = ordersJSON[0].orderCategory
      const apiOrders = ordersJSON.map((order: any) => {
        const { cartableId, quantity, selectedVariantId } = order
        if(order.orderCategory === configJSON.CARTABLE_TYPE_FARM) {
          return {
            cartable_id: cartableId,
            quantity,
            selected_variant_option_id: selectedVariantId
          }
        } else {
          const { cookingInstruction, extraThings } = order
          const addOnsIds = extraThings.map((addon: any) => addon.id)
          return {
            cartable_id: cartableId,
            quantity,
            addon_ids: addOnsIds,
            special_cooking_instruction: cookingInstruction,
          }
        }
      })
      await this.bulkAddToCart({
        cartable_type: orderType,
        cartable_params: apiOrders
      }, token)
    }
  }

  bulkAddToCart = async (orders: any, token: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.bulkAddToCartApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkAddToCartApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(orders)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }
  // Customizable Area End
}
