//Image
export const Logo = require("./assets/images/new_wtt_logo.png");
export const Search_Icon = require("./assets/images/search-icon.png");
export const Notification_Icon = require("./assets/images/notification-icon.png");
export const User_Avtar1 = require("./assets/images/user-profile.png");
export const DashboardActive = require("./assets/images/dashboard-active.png");
export const ProductsNormal = require("./assets/images/products-icon.png");
export const WhiteLogo = require("./assets/images/white-logo.png");

export const GrassBanner = require("./assets/images/grass-banner.png");
export const OldMan = require("./assets/images/oldman.jpeg");
export const DefaultProfileImg = require("./assets/images/default-profile.svg");
export const fbImg = require("./assets/images/facebook.png");
export const googleImg = require("./assets/images/google.png");
export const instaImg = require("./assets/images/instagram.png");
export const twitterImg = require("./assets/images/twitter.png");
export const gmailImg = require("./assets/images/gmail.png");
export const farm1Img = require("./assets/images/farm1.png");
export const womanImg = require("./assets/images/women.png");
export const arrowImg = require("./assets/images/image-arrow.png");
export const searchImg = require("./assets/images/search.png");
export const locationImg = require("./assets/images/placeholder.png");
export const leaveImg = require("./assets/images/leaveImg.png");
export const CopyIcon = require("./assets/images/copy.png");

//render img component img
export const farm1ImgSmall = require("./assets/images/image-farm-1.png");
export const farm1ImgLarge = require("./assets/images/image-farm-1@3x.png");
export const farm2ImgSmall = require("./assets/images/image-farm-2.png");
export const farm2ImgLarge = require("./assets/images/image-farm-2@3x.png");
export const farm3ImgSmall = require("./assets/images/image-farm-3.png");
export const farm3ImgLarge = require("./assets/images/image-farm-3@3x.png");

export const ReviewProfile = require("./assets/images/reviewprofile.jpg");
export const FileUploadIcon = require("./assets/images/upload-icon.png");
export const cart_add_btn = require("./assets/images/cart_add_btn.png");
export const correct_icon = require("./assets/images/checked-icon.png");
export const pdf_icon = require("./assets/images/pdf-icon.png");
export const download_icon = require("./assets/images/download-icon.png");

export const Apple_Icon = require("./assets/images/apple-icon.png");
export const OrderImg = require("./assets/images/order1.png");
export const CalendarIcon = require("./assets/images/calendar_icon.png");
export const activeLeaf = require("./assets/images/activeLeaf.png")
export const leaf = require("./assets/images/leaf.png")
export const activeBook = require("./assets/images/activeBook.png")
export const book = require("./assets/images/book.png")
export const send = require("./assets/images/send.png");

export const HarvestImage = require("./assets/images/harvest.png")
export const restaurantIcon = require("./assets/images/restaurant.png")
export const settingIcon = require("./assets/images/settings.svg")
export const logoutIcon = require("./assets/images/logout.svg")
export const activeRestIcon = require("./assets/images/restaurant-active.png")
export const techPurchase = require("./assets/images/tech-purchase.png")
export const AimIcon = require("./assets/images/aim_icon.png")
export const DustbinIcon = require("./assets/images/dustbin.png");
export const leafImg = require("./assets/images/image-fern.png")
export const XLineIcon = require("./assets/images/twitter-x-line.svg")
export const MenuIcon = require("./assets/images/menu.png")


// Redux Import
// Redux for User Related Management
export const {
    setLoginModalTrue,
    setLoginModalFalse,
    setUserDetailsFromLocal,
    getUserDetails,
    verifyUserAction,
    resetUserDetails,
    googleLoginAction,
    updateActiveTab,
    toggleSidebar,
    setCartBadge,
    getCartItems,
    setUserCartIdFromLocal,
    setWishlistCount,
    facebookLoginAction
  } = require("./redux/Users/userActions")
  // Redux for Farm Related Management
  export const {
    getFarmCategoriesAndTagsAction,
    setFarmSelectedCategory,
    setFarmSelectedTag,
    setFilterByPrice,
    setFilterByTextAndLatLng,
    clearFilterByTextAndLatLng,
    setNearByFilter,
    setFarmSearchAddress,
    getFarmListAction
  } = require("../src/redux/Farms/farmsActions")
  // Redux for Farm Product Related Management
  export const {
    setFarmProductsSelectedCategory,
    setFarmsProductsCurrentPage,
    getFarmProductListAction,
    setFarmProductsSelectedTag,
    setProductsFilterByPrice,
  } = require("../src/redux/FarmsCatalogue/farmsCatalogueActions")
  // Redux for Restaurant Related Management
  export const {
    setRestaurantFilterByTextAndLatLng,
    clearRestaurantFilterByTextAndLatLng,
    setRestaurantsSelectedCategory,
    setRestaurantsSelectedTag,
    setRestaurantFilterByPrice,
    setRestaurantNearByFilter,
    addRatingRestaurantAction,
    getRestaurantsListAction,
    setRestaurantSearchAddress
  } = require("./redux/Restaurant/RestaurantActions")

  export const {
           setGreenBannerDesc,
           setFooterDesc,
         } = require("../src/redux/CMS/cmsActions"); 
