// Customizable Area Start
import React from "react";
import { Button, CircularProgress, Grid, InputAdornment, TextField } from "@material-ui/core";
import { ErrorMessage } from "formik";
import { WomanPicture } from "./assets";
import moment from "moment";
import CustomProgressbar from "../../../components/src/CustomProgressbar.web";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined"; 
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Textarea, Typography } from "@mui/joy";
const configJSON = require("../src/config.js");

export default class CampaignDetailsContainer extends React.Component<any,any>{
    constructor(props:any){
        super(props);
    }

    donnerContainer = (values:any,touched:any,errors:any,setFieldValue:any,donnerList:any,campaignDetails:any) => (
        <>
        <div className="donationContainer">
              <div className="donationHeading">
                <h4>{configJSON.donationsLabel}</h4> <hr />{" "}
              </div>
    
              <div className="donationInfo">
                <Grid item xs={12} lg={4} className="requiredDontation">
                  <h3>{configJSON.requiredDonationLabel}</h3>
                  <div className="outlineTextfield boxRadiusBorder">
                    <div className="customTextfield">
                      <TextField
                        id="required_donation"
                        name="required_donation"
                        variant="outlined"
                        value={values.required_donation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e: any) => this.props.handleDonationValueChange(e.target.value, setFieldValue)}
                        error={
                          touched.required_donation &&
                          Boolean(errors.required_donation)
                        }
                      />
                      <span className="errorMsg">
                        <ErrorMessage name="required_donation" />
                      </span>
                    </div>
                  </div>
                </Grid>
                {this.props.productMode == configJSON.editText && (
                  <>
                    <Grid item xs={12} lg={2} className="donationRaised">
                      <h3>{configJSON.donationRaisedText}</h3>
    
                      <h4>
                        $
                        {(campaignDetails && campaignDetails.donation_raised) ||
                          "0"}
                      </h4>
                    </Grid>
    
                    <div className="donationRaisingpercentage">
                      <div className="donationBlock">
                        <CustomProgressbar
                          heading="Donation"
                          margin={Number(campaignDetails?.donation_raised_percentage.replace("%", ""))}
                        />
                        <h3>
                          {campaignDetails &&
                            campaignDetails.donation_raised_percentage}{" "}
                          {configJSON.donationsLabel} <br /> {configJSON.raisedText}
                        </h3>
                      </div>
                    </div>
                  </>
                )}
              </div>
    
              {this.props.productMode == configJSON.editText && (
                <div className="donnersContainer">
                  <h3>{configJSON.donnerLabel}</h3>
                  {donnerList.length > 0 ? (
                    <div className="donnersList">
                      {donnerList.map((donnerDetail: any) => {
                        const { customer_name, amount, date, image } =
                          donnerDetail.attributes;
    
                        return (
                          <div
                            className="donnerContainer"
                            key={donnerDetail.id}
                          >
                            <div className="profile">
                              <img
                                src={image?.url || WomanPicture}
                                alt="profile"
                              />
                              <p>{customer_name}</p>
                            </div>
                            <p>{moment(date).format("DD MMM, YYYY")}</p>
                            <h3>${amount}</h3>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="empty_donners_container">
                      <p>{configJSON.noDonationReceivedText}</p>
                    </div>
                  )}
                </div>
              )}
        </div>
        </>
    )

    donationRaised = (values:any,setFieldValue:any) => {
        return(
        <>
            {values.banner ? (
          <div className="imageContainer">
            <div className="image">
              {values.banner &&
                values.banner.length > 0 &&
                values.banner[0].name ? (
                <img
                  src={(
                    window.URL ||
                    // (window as any)
                    window.webkitURL
                  ).createObjectURL(
                    //@ts-ignore
                    values.banner[0]
                  )}
                  alt="banner"
                />
              ) : (
                <img src={values.banner.url} alt="banner" />
              )}
            </div>
            {/* <Button>Edit Banner</Button> */}
            <label className="editBannerBtn">
              <input
                id="images"
                name="images"
                type="file"
                hidden
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e: any) => {
                  e.currentTarget.files.length > 0 &&
                    setFieldValue("banner", e.currentTarget.files);
                }}
              />
              {configJSON.editBannerText}
            </label>
          </div>
        ) : (
          <div className="mediaContainer forumsMediaContainer">
            <div className="mediaUploader">
              <label>
                <input
                  id="banner"
                  name="banner"
                  type="file"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e:any) => {
                    e.currentTarget.files.length > 0 &&
                    setFieldValue("banner", e.currentTarget.files)}}
                />
                <BackupOutlinedIcon />
                <span>{configJSON.dragFileText}</span>
              </label>
            </div>
          </div>
        )}
        </>
        )
    }
    render() {
        const {values,setFieldValue,handleChange,touched,errors,loading,campDetails,productMode,endDecorator} = this.props;
        const campaignDetails = campDetails?.attributes;
        const donnerList = campaignDetails?.donations?.data || [] 
        const campEditMode = (productMode == configJSON.createText) ? configJSON.createCampaignText : configJSON.saveChangesText
        return(
            <>
            {loading ? (
      <div className="loader_container">
        <CircularProgress />
      </div>
    ) : (
      <div className="forumMainWrapper">
        {/* image container start */}
        {this.donationRaised(values,setFieldValue)}
        <span className="errorMsg">
          <ErrorMessage name="banner" />
        </span>

        {/* image container end */}

        {/* likescomment conatiner start */}
        <div className="forumResponseContainer">
          <h4 className="campaignSubtitle">
            {configJSON.donationRaisedText}
            <span>${campaignDetails?.donation_raised || 0}</span>
          </h4>
          <div className="buttonContainer">
            <Button type="submit">
              {campEditMode}
            </Button>
          </div>
        </div>
        {/* likescomment conatiner end */}
        <div className="forumHeaderDescription">
          <h4>{configJSON.taglineLabel}</h4>
          <div className="customTextArea">
            <Textarea
              data-test-id="tagline"
              className="campaignTextArea"
              id="tagline"
              value={values.tagline}
              placeholder={configJSON.taglinePlaceholderText} 
              onChange={(e:any) => {
                let words = e.target.value.split(" ");
                if (words.length < 7) setFieldValue("tagline", e.target.value)
              }}
              endDecorator={
                <Typography level="body3" sx={{ ml: 'auto' }}>
                  {endDecorator(values.tagline)}/6
                </Typography>
              }
            />
          </div>

          <span className="errorMsg">
            <ErrorMessage name="tagline" />
          </span>
        </div>
        <div className="forumHeaderDescription">
          <h4>{configJSON.headingText}</h4>
          <div className="customTextArea">
            <Textarea
              id="heading"
              className="campaignTextArea"
              value={values.heading}
              placeholder={configJSON.campaignHeadingPlaceholderText}
              onChange={(e: any) => {
                let words = e.target.value.split(" ");
                if (words.length < 26) {
                  setFieldValue("heading", e.target.value);
                }
              }}
              endDecorator={
                <Typography level="body3" sx={{ ml: 'auto' }}>
                  {endDecorator(values.heading)}/25
                </Typography>
              }
            />
          </div>

          <span className="errorMsg">
            <ErrorMessage name="heading" />
          </span>
        </div>
        
        <div className="forumHeaderDescription">
          <h4>{configJSON.descriptionText}</h4>
          <textarea
            cols={30}
            rows={10}
            id="description"
            className="campaignTextArea"
            value={values.description}
            placeholder={configJSON.campaignDescrPlaceholderText}
            onChange={handleChange}
          />
          <span className="errorMsg">
            <ErrorMessage name="description" />
          </span>
        </div>
        {this.donnerContainer(values,touched,errors,setFieldValue,donnerList,campaignDetails) }     
        
      </div>
    )}
            </>
        )
    }
    
} 
// Customizable Area End