// Customizable Area Start
import React from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  TextareaAutosize,
  InputBase,
  Select,
  MenuItem,
  CircularProgress,
  Menu
} from "@material-ui/core";
import { styled } from "@mui/system"
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import Pagination from "@material-ui/lab/Pagination";
import Rating from "@mui/material/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FarmsCard from "../../../components/src/FarmsProductsImgCard.web";
import WebReviewCard from "../../../components/src/WebReviewCard.web";
import FarmPageSideBar from "../../../components/src/FarmProductsPageSideBar.web";
import ReceiptCardWeb from "./FarmCatelog/ReceiptCard.web";
import {
  brocali,
  getFarmProductListAction,
  setFarmsProductsCurrentPage,
  addRatingFarmProductsAction,
  setProductsFilterBySearch,
  setProductsFilterPrice,
  setProductsFilterSearchText,
  updateActiveTab,
  resetProductCatalogueFilters,
  toggleFarmForumLike,
  handleFarmForumCommentCount
} from "./assets";
import FarmCatelogTopBanner from "./FarmCatelog/FarmCatelogTopBanner.web";
import "../../../web/src/assets/css/style.scss";
import "./assets/css/farmproductcatelog.scss";
import FarmCatelogWebController, { Props, configJSON, IMenu, IProduct } from "./FarmCatelogWebController.web";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import LoginSignUpDialogWeb from "./langinpageLoggedIn/LoginSignUpDialog.web";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import CustomDonationBanner from "../../../components/src/CustomDonationBanner.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import { variantPriceInfo } from "../../../components/src/utility/helper";
import MobileFilterWrapper from "../../../components/src/MobileFilterWrapper.web";
import FarmHeadingWithFilterInput from "./Farm/FarmHeadingWithFilterInput.web";

const CustomMenuItem = styled(MenuItem)(({ isSelected }: { isSelected: boolean }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "16px",
  fontWeight: isSelected ? "bold" : "normal",
  color: isSelected ? "#20a258" : "#000000de"
}))
// Customizable Area End

class FarmProductCatelog extends FarmCatelogWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    // Customizable Area Start
    const {
      products,
      farm,
      farmDetails,
      farmCampaign,
      toggleFarmForumLike,
      setProductsFilterPrice,
      setProductsFilterBySearch,
      setFarmsProductsCurrentPage,
      handleFarmForumCommentCount
    } = this.props

    const {
      id,
      rating,
      review,
      search,
      loginDialog,
      isFiltersOpen,
      isMobileScreen,
      sortAnchorEl
    } = this.state
    const isFiltersVisible = isMobileScreen ? isFiltersOpen : true

    return (
      <>
        {!isMobileScreen && <FarmCatelogTopBanner
          name={products?.farmsProduct?.farm?.farmer_name || "Farmer"}
        />}
        <Container>
          {(farm?.name || farmDetails?.name) && (
            <BreadCrumb
              twoPage={false}
              threePage={false}
              firstPage="Home"
              firstPageLink={AppRoutings.Home}
              secondPage={`Farm Details - ${this.capitalizeValue(
                farm?.name || farmDetails?.name
              )}`}
              secondPageLink={`${AppRoutings.FarmDetails}/${id}`}
              thirdPage={`${this.capitalizeValue(
                farm?.name || farmDetails?.name
              )}'s Products`}
              thirdPageLink={`${AppRoutings.FarmDetailsCatelog}/${id}`}
            />
          )}
          <Grid container spacing={4} >
            <Grid item xs={12} className="top_container">
              <Grid container spacing={2}></Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {isFiltersVisible && <><Grid item xs={12}>
              <Box style={{ display: "flex" }}>
                <InputBase
                  placeholder="Search vegetables,fruits etc"
                  className="farm_catelog_banner_input"
                  onChange={this.handleWriteSearch}
                  onKeyDown={this.handleSearchEnter}
                />
                <Button
                  className="farm_catelog_search"
                  classes={{ label: "farm_catelog_search_btn" }}
                  onClick={() => setProductsFilterBySearch(id, search)}
                >
                  Search
                </Button>
              </Box>
            </Grid>
              <Grid item xs={12} md={3}>
                <FarmPageSideBar categoryTitle="Product" />
              </Grid>
            </>
            }
            {isMobileScreen && <FarmHeadingWithFilterInput heading="Products" />}
            {products.isLoading ? (
              <Grid item xs={12} md={9}>
                <Grid item xs={12} className="custom_loader_container">
                  <CircularProgress size={40} color="inherit" />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} md={9}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box hidden={isMobileScreen} className="filter_input_wrapper_new">
                    <p>sort by :</p>
                    <Select
                      classes={{ root: "OutlinedInput-root", icon: "select_icon" }}
                      value={products.filterType || "default"}
                      onChange={(event: React.ChangeEvent<{ name?: string, value: unknown }>) =>
                        setProductsFilterPrice(id, event.target.value as string)
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null
                      }}
                    >
                        {configJSON.SORT_MENUS.map((menu: IMenu) => {
                          const { label, value } = menu

                          return (
                            <MenuItem key={value} className="select_menus" value={value}>
                              {label}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </Box>
                </Grid>
                <Grid id="FarmList" container spacing={2}>
                  {products?.farmsProduct?.data?.map((item: IProduct) => {
                    const {
                      id,
                      attributes: {
                        name,
                        image,
                        description,
                        average_rating,
                        price,
                        compare_at_price,
                        variant_options
                      },
                    } = item
                    const { displayPrice, isVariantExist } = variantPriceInfo(compare_at_price, variant_options)
                    const discountPrice = isVariantExist ? "" : price

                    return (
                      <Grid item xs={6} md={4} key={id}>
                        <FarmsCard
                          cardName={name || " "}
                          thumbnail={image?.url || brocali}
                          description={description}
                          displayPrice="block"
                          displayButton="none"
                          displayDuration="none"
                          displayRating="block"
                          boxShadow={false}
                          average_rating={average_rating}
                          amount={discountPrice}
                          discountPrice={displayPrice}
                          path=""
                          imgPath={`${AppRoutings.FarmProductDetails}/${id}`}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    {products?.pagination?.total_pages > 1 && (
                      <Box className="show_more_btn_wrapper">
                        <Box className="web_pagination_container">
                          <Pagination
                            count={products?.pagination?.total_pages}
                            page={products.currentPage}
                            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                              setFarmsProductsCurrentPage(id, value)
                            }
                            variant="outlined"
                            shape="rounded"
                            classes={{
                              root: "farm_pagination",
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} />
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={3} />
          </Grid>
        </Container>
        {!products.isLoading && (
          <>
            {farmCampaign?.campaign_id > 0 && (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <CustomDonationBanner
                    backgroundImage={farmCampaign.campaign_image?.url}
                    description={farmCampaign.tagline}
                    donatedAmount={Number(farmCampaign.donation_raised.replace("$", ""))}
                    donationId={farmCampaign?.campaign_id.toString()}
                    heading={`Help ${farmCampaign.farmer_name}'s Farm`}
                    requiredAmount={Number(farmCampaign.required_donation.replace("$", ""))}
                  />
                </Grid>
              </Grid>
            )}
            <Container>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  {products?.forums.length > 0 && (
                    <Box className="Learn_some_new_heading_wrapper">
                      <h4>Learn Something New</h4>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="center">
                {products?.forums?.map((item: any) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                      <ReceiptCardWeb
                        info={item}
                        onLikeChange={toggleFarmForumLike}
                        onCommentUpdate={handleFarmForumCommentCount}
                        onLoginSuccess={this.getFarmProductDetails}
                      />
                    </Grid>
                  )
                })}
              </Grid>
              <Grid container spacing={2} className="farm_details_review_block">
                <Grid item xs={6}>
                  <Box className="farm_details_review_heading">
                    <p>Reviews ({products?.farmRatings?.length})</p>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box className="farm_details_write_review_heading">
                    <p className="write_review_with_underline">Write review</p>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {products?.farmRatings?.map((item: any) => {
                  const { full_name, image, rating, date, comment } =
                    item.attributes
                  return (
                    <Grid item xs={12} key={item.id}>
                      <WebReviewCard
                        name={full_name}
                        rating={rating}
                        date={date}
                        review={comment}
                        profileImg={image?.url}
                      />
                    </Grid>
                  )
                })}
              </Grid>
              <Box mt={2} mb={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className="review_label">Write review</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Rating
                      className="StyledRating"
                      classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                      name="customized-empty"
                      defaultValue={0}
                      value={rating}
                      precision={0.5}
                      size="large"
                      onChange={this.handleRating}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                    <Box>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={4}
                        value={review}
                        placeholder="Write comment here..."
                        className="write_review_text_area"
                        onChange={this.handleWriteText}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="review_submit_btn"
                      disabled={products.isLoadingWrite}
                      onClick={() => this.handleReviewSubmit()}
                    >
                      {products.isLoadingWrite ? (
                        <CircularProgress size={25} color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </>
        )}
        <LoginSignUpDialogWeb
          isOpen={loginDialog}
          onClose={this.handleCloseLoginDialog}
        />
        <GreenBanner />
        <WebFooter />
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={sortAnchorEl}
          getContentAnchorEl={null}
          open={Boolean(sortAnchorEl)}
          onClose={this.handleCloseSortMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {configJSON.SORT_MENUS.map((menu: IMenu) => {
            const { label, value } = menu
            const validValue = value === "default" ? "" : value
            const isSelected = validValue === products.filterType

            return (
              <CustomMenuItem
                key={value}
                value={value}
                isSelected={isSelected}
                onClick={() => this.handleSortChange(value)}
              >
                {label}
              </CustomMenuItem>
            )
          })}
        </Menu>
        <MobileFilterWrapper
          hideSort={false}
          isFiltersOpen={isFiltersOpen}
          handleSortClick={this.handleOpenSortMenu}
          handleFiltersClick={this.handleFiltersClick}
        />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    products: state.farmProducts,
    activeTab: state.user.activeTab,
    farmDetails: state.farmProducts.farmsProduct.farm,
    farm: state.farms.farmDetails,
    farmCampaign: state.farmProducts.campaign
  };
};
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, {
    getFarmProductListAction,
    setFarmsProductsCurrentPage,
    addRatingFarmProductsAction,
    setProductsFilterBySearch,
    setProductsFilterPrice,
    setProductsFilterSearchText,
    updateActiveTab,
    resetProductCatalogueFilters,
    toggleFarmForumLike,
    handleFarmForumCommentCount
  })(FarmProductCatelog)
)
// Customizable Area End
