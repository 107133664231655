import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify";
const configJSON = require("../config.js")
// Customizable Area End

// Customizable Area Start
interface IImage {
    id: number
    filename: string
    url: string
}

interface IReply {
    id: string
    type: string
    attributes: {
        account_id: number
        date: string
        description: string
        full_name: string
        image: IImage
        review_id: number
        report_spam: boolean
    }
}

export interface IReview {
    id: string
    type: string
    attributes: {
        account_id: number
        comment: string
        date: string
        full_name: string
        image: IImage
        ratings: number
        report_spam: boolean
        replies: {
            data: IReply[]
        }
    }
}

interface IForum {
    forumId: string
    heading: string
    bannerImage: string
    likeCount: number
    commentCount: number
    isLiked: boolean
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    forumDetails: IForum | null
    onClose: () => void
    handleLike: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
    onCommentUpdate: (forumId: string, commentCount: number) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    description: string
    isLiked: boolean
    commentCount: number
    review: string
    addReviewLoader: boolean
    loginDialog: boolean
    reviews: IReview[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForumDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    addReviewApiCallId: string = ""
    getForumDetailsCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        const { forumDetails } = this.props
        this.state = {
            loader: false,
            description: '',
            isLiked: forumDetails?.isLiked || false,
            commentCount: forumDetails?.commentCount || 0,
            review: '',
            addReviewLoader: false,
            loginDialog: false,
            reviews: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getForumDetailsApi()
    }

    async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
        const { forumDetails } = this.props
        if (prevProps.forumDetails?.isLiked !== forumDetails?.isLiked) {
            this.setState({ isLiked: !!forumDetails?.isLiked })
        }
    }

    isUserLoggedIn = async () => {
        return !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    }

    handleLoginClose = () => {
        this.setState({ loginDialog: false })
    }

    handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ review: e.target.value })
    }

    handleReviewSubmit = async () => {
        const isLoggedIn = await this.isUserLoggedIn()

        if (!isLoggedIn) {
            this.setState({ loginDialog: true })
            return
        }
        this.addReviewApi()
    }

    removeComment = (reviewId: string) => {
        const newReviews = [...this.state.reviews].filter((review: IReview) => review.id !== reviewId.toString())
        const { commentCount } = this.state
        const { forumDetails, onCommentUpdate } = this.props
        const updatedCommentCount = commentCount - 1

        this.setState({
            reviews: newReviews,
            commentCount: updatedCommentCount,
        })
        onCommentUpdate(forumDetails?.forumId || "", updatedCommentCount)
    }

    editComment = (reviewId: string, updatedComment: string) => {
        const newReviews = [...this.state.reviews].map((review: IReview) => {
            if (review.id === reviewId.toString()) {
                const { id, type, attributes } = review
                return {
                    id,
                    type,
                    attributes: {
                        ...attributes,
                        comment: updatedComment
                    }
                }
            }
            return review
        })
        this.setState({ reviews: newReviews })
    }

    removeReply = (reviewId: string, replyId: string) => {
        const newReviews = [...this.state.reviews].map((review: IReview) => {
            if (review.id === reviewId.toString()) {
                const { id, type, attributes } = review
                const newReplies = [...attributes.replies.data].filter((reply: IReply) => reply.id !== replyId)

                return {
                    id,
                    type,
                    attributes: {
                        ...attributes,
                        replies: {
                            data: newReplies
                        }
                    }
                }
            }
            return review
        })
        this.setState({ reviews: newReviews })
    }

    editReply = (reviewId: string, replyId: string, newReview: string) => {
        const newReviews = [...this.state.reviews].map((review: IReview) => {
            if (review.id === reviewId.toString()) {
                const { id, type, attributes } = review
                const newReplies = [...attributes.replies.data].map((reply: IReply) => {
                    const { id, type, attributes } = reply
                    if (id === replyId) {
                        return {
                            id,
                            type,
                            attributes: {
                                ...attributes,
                                description: newReview
                            }
                        }
                    }
                    return reply
                })

                return {
                    id,
                    type,
                    attributes: {
                        ...attributes,
                        replies: {
                            data: newReplies
                        }
                    }
                }
            }
            return review
        })
        this.setState({ reviews: newReviews })
    }

    handleAddNewReview = (reviewId: string, newReview: IReply) => {
        const newReviews = [...this.state.reviews].map((review: IReview) => {
            if (review.id === reviewId.toString()) {
                const { id, type, attributes } = review
                const newReplies = [...attributes.replies.data, newReview]

                return {
                    id,
                    type,
                    attributes: {
                        ...attributes,
                        replies: {
                            data: newReplies
                        }
                    }
                }
            }
            return review
        })
        this.setState({ reviews: newReviews })
    }

    handleLikeChange = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const isLoggedIn = await this.isUserLoggedIn()
        if (!isLoggedIn) {
            this.setState({ loginDialog: true })
            return
        }
        this.props.handleLike(e)
    }

    addReviewApi = async () => {
        this.setState({ addReviewLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const { review } = this.state
        const { forumDetails } = this.props

        const httpBody = {
            review: {
                reviewable_id: forumDetails?.forumId,
                rating: 0,
                comment: review,
                reviewable_type: configJSON.FORUM_REVIEW_TYPE
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.addReviewApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addReviewApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getForumDetailsApi = async () => {
        this.setState({ loader: true })
        const { forumDetails } = this.props
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getForumDetailsCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getForumDetailsApiEndPoint}${forumDetails?.forumId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.addReviewApiCallId) {
            const { commentCount, reviews } = this.state
            const { forumDetails, onCommentUpdate } = this.props

            this.setState({
                addReviewLoader: false,
                review: '',
                commentCount: commentCount + 1,
                reviews: [...reviews, responseJson.data]
            })
            onCommentUpdate(forumDetails?.forumId || "", commentCount + 1)
        }

        if (apiRequestCallId === this.getForumDetailsCallId) {
            if (responseJson?.data?.attributes) {
                const { comments, description, reviews, is_like } = responseJson.data.attributes
                this.setState({
                    loader: false,
                    isLiked: is_like,
                    commentCount: comments,
                    description: description,
                    reviews: reviews?.data || []
                })
                return
            }
            toast.error("Something went wrong")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if (apiRequestCallId === this.addReviewApiCallId) {
                    this.setState({ addReviewLoader: false })
                }

                if (apiRequestCallId === this.getForumDetailsCallId) {
                    this.setState({ loader: false })
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End

}