import * as React from "react";
import { useState, useEffect } from "react";
import { Redirect, Route, RouteProps, withRouter } from "react-router-dom";
import StorageProvider from "../../framework/src/StorageProvider.web";

interface Props extends RouteProps {
  path: string;
}
let T = localStorage.getItem("authToken");
const getToken = async () => {
  await StorageProvider.get("authToken").then((value) => {
    console.log("value", value);
    // if (value) {
    T = value;
    console.log("T...", T);
    // }
  });
  return T;
};
const ProtecttedRoute = ({ ...routeProps }) => {
  const [token, setToken] = useState("");

  const printAddress1 = () => {
    getToken().then((a) => {
      if (!a) {
        return <Redirect to="/" />;
      }
    });
  };

  useEffect(() => {
    getToken();
    printAddress1();
    // printAddress2();
  }, [token]);
  console.log("token T", T);

  return (
    <>
      {T ? (
        <Route {...routeProps} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default ProtecttedRoute;
