// Customizable Area Start
import * as React from "react";
import ReactPlayer from "react-player";
import { PlayButton } from "./assets";
import Lazyload from "react-lazy-load";
export class VideoPlayer extends React.Component<any,any>{
    playerRef: any
    constructor(props:any){
      super(props);
      this.state = {
        isPlayVideo : false
      }
      this.playerRef = React.createRef();
  }

    handleClick = () => {
      if (this.state.isPlayVideo) {
        this.setState({isPlayVideo :false})
      } else {
        this.setState({isPlayVideo :true})
      }
      this.setState({isPlayVideo :!this.state.isPlayVideo})
    }
  
    handleProgress = ((state:any) => {
      this.setState({playTime :state?.playedSeconds})
    })
  
    handleEnded = () => {
      if(!this.props.isVideoComplete){
        this.props.handleVideoStatus && this.props.handleVideoStatus(this.props.lessonDetail?.id,'complete')
      }
    }
  
    handlePause = (() => {
      this.setState({isPlayVideo :false})
    })
  
    handleDuration = ((e:any) => {
      this.setState({duration:e})
    })

    handleSeek = () => {
        this.setState({isPlayVideo :true})
    }

    render(){

    return (
      <Lazyload offset={100}> 
        <div className='video-player'>
          <ReactPlayer
            ref={this.playerRef}
            playing={this.state.isPlayVideo}
            controls={this.state.isPlayVideo}
            url={this.props.Video?.url}
            onProgress={this.handleProgress}
            onPause={this.handlePause}
            onDuration={this.handleDuration}
            onEnded={this.handleEnded}
            onSeek={this.handleSeek}
            volume={0.5}
            playsinline
            preload="auto"
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            width="750px"
            height="500px"
            />
  
        {this.state.isPlayVideo ?
          <div>
              <img data-test-id="playBtn" onClick={this.handleClick} src={PlayButton} style={{display:'none'}} />
          </div>   
              :
              <div className='player-controls'>
              <img data-test-id="playerPaused" onClick={this.handleClick} src={PlayButton} />
          
              </div> 
        }
          </div>
      </Lazyload>
    )
    }
  }
// Customizable Area End