// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"

interface ICustomCenterWrapperProps {
  children: any
}

const CustomCenterWrapper = ({ children }: ICustomCenterWrapperProps) => {
  return (
    <Box className="custom_center_wrapper">
      {children}
    </Box>
  )
}

export default CustomCenterWrapper
// Customizable Area End
