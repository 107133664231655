// Customizable Area Start
import React, { Component } from "react"
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded"
import { IconButton } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import NavbarSearch from "../../Search/src/components/NavbarSearch/NavbarSearch.web"

export const styles = () =>
  createStyles({
    mainWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentWrapper: {
      flex: 1,
      padding: "15px 10px 0px",
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    backButton: {
      marginRight: "4px",
      "& svg": {
        color: "#333",
      },
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

export class MobileSearchPage extends Component<Props> {
  handleGoBack = () => {
    this.props.history.goBack()
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.mainWrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.headerWrapper}>
            <IconButton
              className={classes.backButton}
              onClick={this.handleGoBack}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
            <NavbarSearch isMobileSearchPage />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(MobileSearchPage))
// Customizable Area End
