import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify";
const configJSON = require("../config.js")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    isCommunity?: boolean
    replyId: string
    reviewId: string
    reviewerId: number
    fullName: string
    image: string
    comment: string
    reviewDate: string
    isSpamReported: boolean
    userInfo: {
      userDetails: any
    }
    handleReviewsRemove: (reviewId: string, replyId: string) => void
    handleReviewEdit: (reviewId: string, replyId: string, newReview: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    editReview: string
    isEditing: boolean
    editReviewLoader: boolean
    deleteReviewLoader: boolean
    contentFlagLoader: boolean
    isContentFlag: boolean
    isDeleteDialog: boolean
    isLoginDialog: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ReplyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    deleteReplyReviewApiCallId: string = ""
    editReplyReviewApiCallId: string = ""
    contentFlagApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
           isEditing: false,
           editReviewLoader: false,
           editReview: '',
           deleteReviewLoader: false,
           contentFlagLoader: false,
           isContentFlag: false,
           isDeleteDialog: false,
           isLoginDialog: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { comment, isSpamReported } = this.props
        this.setState({ editReview: comment, isContentFlag: isSpamReported })
    }

    isUserLoggedIn = async () => {
        return !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    }

    handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editReview: e.target.value })
    }

    enableEditReview = () => {
        this.setState({ isEditing: true })
    }

    cancelEditReview = () => {
        const { comment } = this.props
        this.setState({ isEditing: false, editReview: comment })
    }

    openConfirmationDialog = () => {
        this.setState({ isDeleteDialog: true })
    }

    closeConfirmationDialog = () => {
        this.setState({ isDeleteDialog: false })
    }

    openLoginDialog = () => {
        this.setState({ isLoginDialog: true })
    }

    closeLoginDialog = () => {
        this.setState({ isLoginDialog: false })
    }

    handleContentFlag = async () => {
        const isLoggedIn = this.isUserLoggedIn()
        if(!isLoggedIn) {
            this.openLoginDialog()
            return
        }
        this.markContentFlagApi()
    }

    ediReviewApi = async () => {
        this.setState({ editReviewLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
        const { isCommunity, replyId } = this.props
        const { editReview } = this.state

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const apiEndPoint = !!isCommunity
            ? configJSON.communityReplyReviewApiEndPoint
            : configJSON.replyReviewApiEndPoint

        const httpBody = { description: editReview }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.editReplyReviewApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${apiEndPoint}/${replyId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePut
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    deleteReviewApi = async () => {
        this.setState({ deleteReviewLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
        const { isCommunity,replyId } = this.props

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const apiEndPoint = !!isCommunity
            ? configJSON.communityReplyReviewApiEndPoint
            : configJSON.replyReviewApiEndPoint

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.deleteReplyReviewApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${apiEndPoint}/${replyId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDelete
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    markContentFlagApi = async () => {
        this.setState({ contentFlagLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const { replyId, isCommunity } = this.props
        const flagType = isCommunity ? "comment_reply" : 'reply_review'
        const httpBody: any = { type: flagType, id: replyId }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.contentFlagApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportSpamApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if(apiRequestCallId === this.deleteReplyReviewApiCallId) {
            this.setState({
                deleteReviewLoader: false,
                isDeleteDialog: false
            })
            const { reviewId, replyId, handleReviewsRemove } = this.props
            handleReviewsRemove(reviewId, replyId)
        }

        if(apiRequestCallId === this.editReplyReviewApiCallId) {
            const {  editReview } = this.state
            this.setState({
                editReviewLoader: false,
                isEditing: false
            })
            const { reviewId, replyId, handleReviewEdit } = this.props
            handleReviewEdit(reviewId, replyId, editReview)
        }

        if (apiRequestCallId === this.contentFlagApiCallId) {
            this.setState({ contentFlagLoader: false, isContentFlag: true })
            toast.success("Content marked as flagged successfully")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(apiRequestCallId === this.deleteReplyReviewApiCallId) {
                    this.setState({ deleteReviewLoader: false })
                }

                if(apiRequestCallId === this.editReplyReviewApiCallId) {
                    this.setState({ editReviewLoader: false })
                }

                if (apiRequestCallId === this.contentFlagApiCallId) {
                    this.setState({ contentFlagLoader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}