import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import * as Yup from "yup";
import { getCoords } from "../../../components/src/utility/helper";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  location?: any;
  history?: any;
  classes?: any;
  handleTabChange?: any;
  logout:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  avatar: any;
  selectedimg: any;
  isEdit: boolean;
  arrayHolder: any;
  token: string;
  cookingCapacity: boolean;
  cardIndex: number;
  restStepperIndex: number;
  modalOpen: boolean;
  otpId: any;
  isOtpVerified: boolean;
  isOtpSending: boolean;
  isOtpVerifying: boolean;
  isOtpIncorrect: boolean;
  isVerifyPhoneNumber: any;
  address: string;
  mapCenter: {
    lat: any;
    lng: any;
  };
  mapCoOrdinates: {
    lat: any;
    lng: any;
  };
  restaurantID: any;
  formdataID: any;
  openDialog: any;
  removedProduct: any;

  dishListLoader: boolean;
  activeRestaurant: number;
  restaurantsList: any;
  availabilityID: any
  opensAt: string
  closesAt: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestaurantController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  lineChart: any;
  postSendOtpApiCallId: string = "";
  postVerifyOtpApiCallId: string = "";
  postVerifyOtpApiCallIdForRestaurant: string = "";
  postVerifyOtpApiCallIdForRestaurantOwner: string = "";
  PostID: any;
  PostforumID: any;
  PosteditID: any;
  getAllRestaurantApiCallId: string = "";
  timer: any;
  createRestAvailApiCallId: any;
  putDeliveryTimeApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      cookingCapacity: false,
      cardIndex: 0,
      restStepperIndex: 0,
      modalOpen: false,
      loading: false,
      otpId: "",
      isOtpVerified: false,
      isOtpSending: false,
      isOtpVerifying: false,
      isVerifyPhoneNumber: false,
      isOtpIncorrect: false,
      address: "",
      selectedimg: "",
      mapCenter: {
        lat: null,
        lng: null,
      },
      mapCoOrdinates: {
        lat: null,
        lng: null,
      },
      restaurantID: "",
      formdataID: {},
      openDialog: false,
      removedProduct: "",
      avatar: "",
      isEdit: false,
      dishListLoader: false,
      activeRestaurant: 0,
      restaurantsList: [],
      availabilityID: [],
      opensAt: '',
      closesAt: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    if(window.location.pathname.split("/")[3] === "details"){
    this.getAllRestaurants()
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
          this.handleSuccessResponse(apiRequestCallId, responseJson)
      } else {

        if ((apiRequestCallId === this.getAllRestaurantApiCallId)) {
          this.setState({
            dishListLoader: false
          })          
          toast.error("Something went wrong!")
        }

        // Handle invalid otp error
        if (apiRequestCallId === this.postVerifyOtpApiCallId) {
          this.setState({
            isOtpVerifying: false,
            isOtpIncorrect: true
          });
          responseJson?.errors && this.showErrors(responseJson.errors);
        } else if (apiRequestCallId === this.postSendOtpApiCallId) {
          // Handle otp sending error
          this.setState({
            isOtpSending: false
          });
          responseJson?.errors && this.showErrors(responseJson.errors);
        }

        if (responseJson?.errors) {
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })
          if(responseJson?.errors[0] && ((responseJson?.errors[0].message.includes(["Token has Expired","Invalid token"])))) {
             this.props.logout()
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    // verify otp
    if (
      apiRequestCallId === this.postVerifyOtpApiCallId
    ) {
      this.setState({
        loading: false,
        isOtpVerified: true,
        isOtpVerifying: false,
        isOtpIncorrect: false
      });
      toast.success(responseJson.message);
    }

    // send otp
    if (apiRequestCallId === this.postSendOtpApiCallId) {
      this.setState({
        isOtpSending: false,
        otpId: responseJson.email_otp_id
      });
      toast.success("Otp send successfully");
    }

    if (apiRequestCallId === this.getAllRestaurantApiCallId) {

      this.setState({
        activeRestaurant: responseJson.data[0].attributes.id,
        restaurantsList: responseJson.data,
        dishListLoader: false
      });

      if (responseJson?.data?.length > 0) {
        this.setState({
          activeRestaurant: responseJson.data[0].attributes.id,
          restaurantsList: responseJson.data,
          dishListLoader: false
        })
      }
    }

  }

  showErrors(errors: [{ message: string }]) {
    errors.forEach((err: any) => {
      toast.error(err?.message);
    });
  }

  restDetailsCardShow = (index: number) => {
    this.setState({ cardIndex: index });
  };

  handleChangeStepper = (index: number) => {
    this.setState({ restStepperIndex: index });
  };

  handleChangeNextStepper = () => {
    if (this.state.restStepperIndex <= 2) {
      this.setState({
        restStepperIndex: this.state.restStepperIndex + 1,
      });
    }
  };
  handleChangePrevStepper = () => {
    if (this.state.restStepperIndex >= 0) {
      this.setState({
        restStepperIndex: this.state.restStepperIndex - 1,
      });
    }
  };

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handlePlacesAutocompleteChange = (address: any) => {
    this.setState({ address: address });
  };

  handlePlacesAutocompleteSelect = (address: any) => {
    this.setState({ address: address });
  };

  handleChangeLat = (e: any) => {
    this.setState({
      mapCenter: {
        ...this.state.mapCenter,
        lat: parseFloat(e.target.value),
      },
    });
    const latlng = {
      lat: parseFloat(e.target.value),
      lng: parseFloat(this.state.mapCenter.lng),
    };
    const geocoder = new google.maps.Geocoder();

    geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response.results[0]) {
          this.setState({
            address: response.results[0].formatted_address,
          });
        }
      })
      .catch((e) => {
        console.log("error", e)
      });
  };

  handleChangeLng = (e: any) => {
    this.setState({
      mapCenter: {
        ...this.state.mapCenter,
        lng: parseFloat(e.target.value),
      },
    });
    const latlng = {
      lat: parseFloat(this.state.mapCenter.lat),
      lng: parseFloat(e.target.value),
    };
    const geocoder = new google.maps.Geocoder();

    geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response.results[0]) {
          this.setState({
            address: response.results[0].formatted_address,
          });
        }
      })
      .catch((e) => {
        console.log("error", e)
      });
  };

  detectPlace = async (setFieldValue: (field: string, value: any) => void) => {
    try {
      const coords: any = await getCoords()
      const { latitude, longitude } = coords
      setFieldValue("latitude", latitude)
      setFieldValue("longitude", longitude)
      this.setState({
        mapCenter: {
          lat: latitude,
          lng: longitude
        }
      })
    } catch (e) {
      toast.error(e?.message)
    }
  }

  // send otp
  postSendOtp = async (email: any) => {

    this.setState({
      isOtpSending: true
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = { email };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSendOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // verify otp
  postVerifyOtp = async (pin: any) => {

    this.setState({
      isOtpVerifying: true
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      email_otp_id: this.state.otpId,
      pin: pin,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVerifyOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postVerifyOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  formSchema = () => {
    const phoneRegExp = /^\(?([2-9][0-9]{2})\)?([2-9][0-9]{2})?([0-9]{4})$/g;

    switch (this.state.restStepperIndex) {
      case 0:
        return Yup.object().shape({
          name: Yup.string().required("This field is required."),
          org_address: Yup.string().required("This field is required."),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("This field is required."),
          ownerName: Yup.string().required("This field is required."),
          ownerEmail: Yup.string()
            .email("This email is not valid")
            .required("This field is required."),
          ownerNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("This field is required."),
          longitude: Yup.string().required("This field is required."),
          latitude: Yup.string().required("This field is required."),
          description: Yup.string()
            .max(200, "Description must be at most 200 characters.")
            .required("This field is required."),
        });
      case 1:
        return Yup.object().shape({
          restaurant_type: Yup.string().required("This field is required."),
          days: Yup.array()
            .required("This field is required.")
            .min(1),
          cuisines: Yup.array()
            .required("This field is required.")
            .min(1),
          outlet: Yup.array()
            .required("This field is required.")
            .min(1),
          opensAt: Yup.string().required("This field is required."),
          closesAt: Yup.string().required("This field is required."),
          bannerImages: Yup.array().required("This field is required."),
        });
      case 2:
        return Yup.object().shape({
          bankName: Yup.string().required("This field is required."),
          accountNumber: Yup.number()
            .typeError("Account number must be a number")
            .required("This field is required."),
          ibanNumber: Yup.string()
            .length(22, "IBAN number must be 22 characters long")
            .required("This field is required."),
          documents: Yup.array().required("This field is required."),
          acceptTerms: Yup.bool()
            .oneOf([true], "This field must be checked.")
            .required("This field is required."),
        });
      default:
        break;
    }
  };
  // Customizable Area Start

  //EditCampaign
  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  
  //linearprogressbarcampaigns
  linearprogessbarcamp = async (data: any) => {
    const token = await localStorage.getItem("authToken")
    const headers = {
      token: token
    }

    const form = new FormData();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createcampaignadmin
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  getAllRestaurants = async () => {
    !this.state.dishListLoader && this.setState({
      dishListLoader: true
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRestaurantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  
  handleRegisterRest = () => {
    this.props.history.push(
      `/admin/restaurant/details/register-form?id=${this.state.restaurantID}`
    );
    this.props.handleTabChange("", 1)
  }

  // Customizable Area End

}
