import React from "react";
import "../../../web/src/assets/css/style.scss";
import Setting from "../../Settings/src/Setting.web";
import EditAddress from "./EditAddress.web";
import "../assets/css/viewAddresses.scss";
import AddAddress from "./AddAddress.web";
import {
  trash,
  edit,
  getAddressListAction,
  deleteAddressAction
} from "./assets";
import ViewAddressesController, {
  Props,
  IAddress
} from "./ViewOrderAddressesController.web";
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import CustomActionDialog from "../../../components/src/CustomDialog.web";
import { formatPhoneNumber } from "../../../components/src/utility/helper";

export class ViewAddresses extends ViewAddressesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      userInfo: { address, isAddressDeleteBtnLoading, isAddressLoading }
    } = this.props;
    const {
      isAddAddressDialog,
      isEditAddressDialog,
      isAddressRemoveDialog,
      isRemovingAddress,
      filteredData
    } = this.state;
    const addresses = address || [];

    return (
      <Setting>
        <Box className="web_sidebar_link_view">
          <Grid container>
            <Box className="web_sidebar_link_view_header" width="100%">
              <Grid item xs={12} sm={8} md={7} lg={10}>
                <h5>Your Addresses</h5>
              </Grid>
              <Grid item xs={12} sm={4} md={5} lg={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    classes={{ root: "add_address", label: "btn-label" }}
                    onClick={this.openAddAddressDialog}
                  >
                    Add Address
                  </Button>
                </Box>
              </Grid>
            </Box>
            {isAddressLoading ? (
              <Box className="view_addresses_loader">
                <CircularProgress size={50} />
              </Box>
            ) : (
              <Box width="100%">
                {addresses.length > 0 ? (
                  addresses.map((addressInfo: IAddress) => {
                    const {
                      id,
                      attributes: {
                        first_name,
                        last_name,
                        company_name,
                        address,
                        phone_number
                      }
                    } = addressInfo;
                    return (
                      <Box key={id}>
                        <Grid
                          key={id}
                          container
                          className="wishlist_card_wrapper"
                          spacing={2}
                        >
                          <Grid item xs={9} sm={9} md={9} lg={10} xl={10}>
                            <h5 className="product_name">
                              {first_name} {last_name}
                            </h5>
                            <Typography className="product_grey_text">
                              {company_name}
                            </Typography>
                            <Typography className="product_grey_text">
                              {address}
                            </Typography>
                            <Typography className="product_grey_text">
                              {formatPhoneNumber(phone_number)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            lg={2}
                            className="product_image_center"
                          >
                            <Box className="address_action_container">
                              <Box>
                                <IconButton
                                  data-testid="remove_address_btn"
                                  classes={{
                                    root: "view_addresses_icon_btn"
                                  }}
                                  onClick={() =>
                                    this.openRemoveAddressDialog(id)
                                  }
                                  disabled={isAddressDeleteBtnLoading}
                                >
                                  <img src={trash} alt="remove" width="25px" />
                                </IconButton>
                              </Box>
                              <Box>
                                <IconButton
                                  data-testid="edit_address_btn"
                                  classes={{
                                    root: "view_addresses_icon_btn"
                                  }}
                                  onClick={() => this.editAddress(id)}
                                >
                                  <img src={edit} alt="edit" width="25px" />
                                </IconButton>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                ) : (
                  <Box className="addresses_blank_container">
                    <Typography>No saved address found</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Grid>

          {isEditAddressDialog && filteredData && (
            <EditAddress
              isOpen={isEditAddressDialog}
              id={filteredData.id}
              data={filteredData}
              close={this.closeEditAddressDialog}
            />
          )}

          <AddAddress
            isOpen={isAddAddressDialog}
            close={this.closeAddAddressDialog}
          />

          <CustomActionDialog
            open={isAddressRemoveDialog}
            title="Are you sure want to remove the address?"
            actionBtnTitle="Yes"
            closeBtnTitle="No"
            isLoading={isRemovingAddress}
            isActionDisabled={isRemovingAddress}
            isCloseDisabled={isRemovingAddress}
            handleClose={this.closeRemoveAddressDialog}
            handleAction={this.deleteAddress}
          />
        </Box>
      </Setting>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
};

export default connect(
  mapStateToProps,
  {
    getAddressListAction,
    deleteAddressAction
  }
)(ViewAddresses);
