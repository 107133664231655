// Customizable Area Start
import React, { Component } from "react";
import {
  Grid,
  Container,
  Box,
  TextareaAutosize,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@mui/material/Rating";
import _ from "lodash";
import GreenRoundedButton from "../../../components/src/GreenRoundedButton.web";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import ImageRenderComponent from "../../../components/src/FarmSectionImageRenderComponent.web";
import WebReviewCard from "../../../components/src/WebReviewCard.web";
import LeafImgWithTextBanner from "../../../components/src/WebLeafWithTextBanner.web";
import {
  getFarmDetailsAction,
  addRatingFarmAction,
  addToFavoriteAction,
  updateActiveTab,
  FacebookLogo,
  InstagramLogo,
} from "./assets";
import "../../../web/src/assets/css/style.scss";
import "./assets/css/farmdetails.scss";
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import "swiper/swiper-bundle.min.css";
import StorageProvider from "../../../framework/src/StorageProvider";
import LoginSignUpDialog from "./langinpageLoggedIn/LoginSignUpDialog.web";
import ShareButton from "../../../components/src/ShareButton.web";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import FarmPopularProducts from "./Farm/FarmPopularProducts.web";
import { getShareTitle } from "../../../components/src/utility/helper";
import SocialIconNav from "./Community/CommunityCustomComponents/SocialIconNav.web";
import {
  ICertifiedTag,
  IFarmReducerState,
  IReview,
} from "../../../components/src/interfaces/farm"
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
const configJSON = require("./config");

interface IAddReview {
  id: string
  rating: number | null
  comment: string
}
// Customizable Area End

interface Props extends RouteComponentProps {
  // Customizable Area Start
  match: any
  activeTab: string
  farms: IFarmReducerState
  FDPPopularProductsDesc: string
  updateActiveTab: (tab: string) => void
  getFarmDetailsAction: (id: string) => void
  addToFavoriteAction: (id: string) => void
  addRatingFarmAction: (
    ratingDetails: IAddReview,
    handleClear: () => void
  ) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string
  rating: number | null
  review: string
  loginDialog: boolean
  // Customizable Area End
}

class FarmDetails extends Component<Props, S> {
  constructor(props:Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      id: "",
      rating: 0,
      review: "",
      loginDialog: false,
    }
    this.handleRatingChange = this.handleRatingChange.bind(this)
    this.handleWriteText = this.handleWriteText.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.capitalizeValue = this.capitalizeValue.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    const { match, activeTab, updateActiveTab, getFarmDetailsAction } =
      this.props
    const { id } = match.params
    activeTab !== ActiveTabEnum.Market && updateActiveTab(ActiveTabEnum.Market)
    this.setState({ id })
    getFarmDetailsAction(id)
  }

  handleRatingChange(
    _: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) {
    this.setState({
      rating: value ?? 0,
    })
  }

  handleWriteText(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      review: e.target.value,
    })
  }

  handleClear() {
    this.setState({
      rating: 0,
      review: "",
    })
  }

  capitalizeValue = (value: string) => {
    if (!value) return ""
    return `${value[0].toUpperCase()}${value.slice(1)}`
  }

  handleRating = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    const { id, rating, review } = this.state
    if (isLoggedIn)
      this.props.addRatingFarmAction(
        { id, rating, comment: review },
        this.handleClear
      );
    else this.setState({ loginDialog: true })
  }

  handleAddToFavorite = async () => {
    const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))
    if (isLoggedIn) this.props.addToFavoriteAction(this.state.id)
    else this.setState({ loginDialog: true })
  }

  handleCloseLoginDialog = () => {
    this.setState({ loginDialog: false })
  }

  handleViewProductsRedirect = () => {
    const { id } = this.state
    this.props.history.push(`${AppRoutings.FarmDetailsCatelog}/${id}`)
  }
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { farms, FDPPopularProductsDesc, getFarmDetailsAction } = this.props
    const {
      id,
      rating,
      review,
      loginDialog
    } = this.state

    const {
      isLoading,
      isLoadingWrite,
      farmRatings,
      farmDetails: {
        average_rating,
        description,
        facebook,
        favourites,
        icons: {
          data: certifiedTags
        },
        images,
        instagram,
        name,
        popular_farm_catalogues: {
          data: popularProducts
        },
      }
    } = farms

    return (
      <>
        <Box className="farms_all_pages_container">
          {isLoading ? (
            <Box className="custom_loader_container">
              <CircularProgress size={40} color="inherit" />
            </Box>
          ) : (
            <Container>
              <BreadCrumb
                twoPage={false}
                threePage={false}
                firstPage="Home"
                secondPage="Farmers Market"
                firstPageLink={AppRoutings.Home}
                secondPageLink={AppRoutings.FarmsMarket}
                thirdPage={`Farm Details - ${this.capitalizeValue(
                  _.get(farms, "farmDetails.name")
                )}`}
                thirdPageLink={`${AppRoutings.FarmDetails}/${id}`}
              />
              <Box className="farm_details_top_section_wrappper">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ImageRenderComponent
                      imagesArray={images || []}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    spacing={2}
                    justifyContent="center"
                  >
                      <Grid item xs={12}>
                        <Box className="farm_details_heading">
                          <Box className="farm_name_fac_wrapper">
                            <h3>
                              {this.capitalizeValue(name)}
                            </h3>
                            <IconButton size="small" onClick={this.handleAddToFavorite}>
                              {favourites ? (
                                <FavoriteIcon style={{ color: "red" }} />
                              ) : (
                                <FavoriteBorderIcon />
                              )}
                            </IconButton>
                          </Box>
                          <Box className="farm_social_img_wrapper">
                            <SocialIconNav
                              url={instagram}
                              image={InstagramLogo}
                              imageClassName="farm_social_img"
                            />
                            <SocialIconNav
                              url={facebook}
                              image={FacebookLogo}
                              imageClassName="farm_social_img"
                            />
                          </Box>
                        </Box>
                        <Box className="farm_certified_tags_container">
                          {certifiedTags.map((tag: ICertifiedTag) => {
                            const { id, attributes: { icon } } = tag
                            return (
                              <img key={id} src={icon?.url} alt="tag" />
                            )
                          })}
                        </Box>
                        <Box className="farm_details_context">
                          {description} {description} {description}
                        </Box>

                        <Box className="farm_details_btn_container">
                          <Button
                            className="farm_view_product_btn"
                            onClick={this.handleViewProductsRedirect}
                          >
                            View Products
                          </Button>
                          <ShareButton
                            link={window.location.href}
                            title={getShareTitle(this.capitalizeValue(`${name} Farm`))}
                          />
                        </Box>
                      </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={10}>
                  {popularProducts.length > 0 && (
                    <Box className="leave_img_with_text">
                      <LeafImgWithTextBanner
                        heading="Popular Products"
                        text={FDPPopularProductsDesc}
                        linkText=""
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <FarmPopularProducts popularProducts={popularProducts} />

              {popularProducts.length > 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} className="popular_view_btn_container">
                    <GreenRoundedButton
                      buttonText="View Products"
                      path={`${AppRoutings.FarmDetailsCatelog}/${id}`}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={3} className="farm_details_review">
                <Grid item xs={7} className="farm_details_review_heading">
                  <h3>Reviews ({farmRatings?.length || 0})</h3>
                  <Rating
                    className="StyledRating"
                    classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                    name="customized-empty"
                    defaultValue={0}
                    value={average_rating || 0}
                    precision={0.5}
                    size="large"
                    readOnly
                    onChange={() => { }}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="write_review_with_underline">Write review</p>
                </Grid>
                <Grid item container spacing={2}>
                    {
                      farmRatings?.map((item: IReview) => {
                        const {
                          id,
                          attributes: { image, full_name, rating, date, comment },
                        } = item
                        return (
                          <Grid item xs={12} key={id}>
                            <WebReviewCard
                              profileImg={image?.url}
                              name={full_name}
                              rating={rating}
                              date={date}
                              review={comment}
                            />
                          </Grid>
                        )
                      })
                    }
                  <Grid item xs={12}>
                    <p className="review_label">Write review</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Rating
                      className="StyledRating"
                      classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                      name="customized-empty"
                      defaultValue={0}
                      value={rating}
                      precision={0.5}
                      size="large"
                      onChange={this.handleRatingChange}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                    <Box>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={4}
                        value={review}
                        placeholder="Write comment here..."
                        className="write_review_text_area"
                        onChange={this.handleWriteText}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="review_submit_btn"
                      disabled={isLoadingWrite}
                      onClick={() => this.handleRating()}
                    >
                      {isLoadingWrite ? (
                        <CircularProgress size={25} color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          )}
        </Box>
        <LoginSignUpDialog
          isOpen={loginDialog}
          onClose={this.handleCloseLoginDialog}
          onLoginSuccess={() => getFarmDetailsAction(id)}
        />
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {
    farms: state.farms,
    activeTab: state.user.activeTab,
    FDPPopularProductsDesc: state.cms.FDPPopularProductsDesc
  }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, {
    getFarmDetailsAction,
    addRatingFarmAction,
    addToFavoriteAction,
    updateActiveTab,
  })(FarmDetails)
)
// Customizable Area End
