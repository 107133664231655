import React from 'react';
import {getPopularCourses, getCourseList, getCategoriesOfCourses, getElearningDetails} from "./ELearningAPI";
import {toast} from "react-toastify";
import store from "../store";

// Action for Get ELearning Popular Categories
export const getPopularCoursesLearning = () => async (dispatch) => {
    dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_TRUE'});
    try {
      // get Popular Courses of e-learning and set in state
      const res1 = await getPopularCourses()
      if(res1.data){
        dispatch({type:"SET_POPULAR_COURSES_LIST",payload:res1.data})
      }else{
        res1.errors?.map((err)=>{
          toast.error(err?.message)
        })
      }
    }catch (e) {
      toast.error("Something went wrong..")
    }
    dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_FALSE'});
}

export const setCourse = (text) => async(dispatch) => {
  
  if(window.location.pathname.split("/")[2] === "courses" && text.length === 0){
    setTimeout(() => {
      dispatch(getCourseListOnSearch())
    }, 1000)
  }
  dispatch({type :'SET_ELEARNING_SEARCH_VALUE', payload: text});
  dispatch({type: 'SET_COURSE_CURRENT_PAGE',payload:1});
  
}  

export const getCourseListOnSearch = () => async(dispatch) => {
  dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_TRUE'});
  const searchText = store.getState().courses.searchCourse
  const current = store.getState().courses.currentPage
  const showCount = store.getState().courses.count
  try{
    const res = await getCourseList(searchText,"",current,showCount)
    if(res.data && res.meta.pagination.total_count > 0){
    dispatch({type : 'GET COURSE ON SEARCH', payload: res?.data});
    dispatch({
      type: "SET_PAGINATION_FOR_COURSE_LIST",
      payload: res?.meta?.pagination,
    })
    } else{
      res?.errors?.map((err)=>{
        toast.error(err?.message)
      })
      if(res.meta.pagination.total_count === 0 && searchText){
        toast.error("No such course available.")
        dispatch({type : 'GET COURSE ON SEARCH', payload:[]})
        dispatch({ type: "SET_PAGINATION_FOR_COURSE_LIST", payload: {} })
      }
    }
  } catch (e){
    toast.error("Something went wrong")
  }
  dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_FALSE'});
}

export const resetLearningFilters = () => async(dispatch) => {
  dispatch({ type : 'RESET_ELEARNING_FILTERS'})
}

export const resetSearchFilters = () => async(dispatch) => {
  dispatch({ type : 'RESET_SEARCH_FILTERS'})
}

//Action for get Categories of e-learning
export const getCategoriesELearning = () => async(dispatch) => {
  try {
    const res = await getCategoriesOfCourses()
    dispatch({type: 'GET CATEGORIES OF ELEARNING', payload :res})
  }catch (e){
    toast.error("Something went wrong.")
  }
}

export const categorySelectChip = (category) => async(dispatch) => {
  dispatch({type: 'SET_ELEARNING_CATEGORY_COURSES_IS_LOADING_TRUE'});
  if(category){
  dispatch({type : 'SET CATEGORY FOR ELEARNING', payload: category})
  dispatch({type: 'SET_COURSE_CURRENT_PAGE',payload:1})
  }
  const selCategory = store.getState().courses.selectedCategory
  const current = store.getState().courses.currentPage
  const searchText = store.getState().courses.searchCourse
  const showCount = store.getState().courses.count
  try {
    const res = await getCourseList(searchText,selCategory,current,showCount)
    if(res.data && res.meta.pagination.total_count > 0 ){
      dispatch({type: 'GET COURSE ON SEARCH', payload : res?.data})
      dispatch({
        type: "SET_PAGINATION_FOR_COURSE_LIST",
        payload: res?.meta?.pagination,
      })
    }else{
      res?.errors?.map((err)=>{
        toast.error(err?.message)
      })
      if (res.meta.pagination.total_count === 0 && selCategory) {
        toast.error("No course available under this category.")
        dispatch({ type: "GET COURSE ON SEARCH", payload: [] })
        dispatch({ type: "SET_PAGINATION_FOR_COURSE_LIST", payload: {} })
      }
    }
  } catch (e){
    toast.error("Something went wrong.")
  }
  dispatch({type: 'SET_ELEARNING_CATEGORY_COURSES_IS_LOADING_FALSE'});
}

export const getLearningDetails = () => async (dispatch) => {
  dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_TRUE'});
  try {
    // get details of e-learning and set in state
    const res1 = await getElearningDetails()
    if(res1.data){
      dispatch({type:"SET_LEARNING_DETAILS",payload:res1.data})
    }else{
      res1.errors?.map((err)=>{
        toast.error(err?.message)
      })
    }
  }catch (e) {
    toast.error("Something went wrong..")
  }
  dispatch({type: 'SET_ELEARNING_COURSES_IS_LOADING_FALSE'});
}

export const setCourseCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({type: 'SET_COURSE_CURRENT_PAGE',payload:currentPage});
  const selCategory = store.getState().courses.selectedCategory
  if(selCategory.length > 0){
    dispatch({type: 'SET_COURSE_CURRENT_PAGE',payload:currentPage});
    dispatch(categorySelectChip())
  }
  else { dispatch(getCourseListOnSearch())}
}
