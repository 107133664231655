import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config.js");
import * as Yup from "yup";
import {toast} from "react-toastify";
import React from "react";
import { shallowEqual } from 'recompose'
import { objectType } from "reactjs-social-login"
import { isTokenExpired } from "../../../components/src/utility/helper";

interface IConnectServicePayload {
  fb_access_token: string
  instagram_access_token: string
  google_access_token: string
}

interface IAccountInfo {
  first_name: string
  last_name: string
  email: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  history: any
  userInfo: any
  updateAccountDetailsAction: (userInfo: { account: IAccountInfo }) => void
  handleProfileImage: (profileImage: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountSettingSchema: any;
  isLoading:boolean;
  isImageLoading: boolean
  changePassDialog: boolean
  isFbConnected: boolean
  isGoogleConnected: boolean
  isInstagramConnected: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSettingWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profilePicRef: any = React.createRef()
  allowedExtension = ["jpeg", "jpg", "png", ];
  profileImageApiCallId: string = ""
  getRolesApiCallId: string = "";
  postFacebookTokenApiCallId : string = "";
  getAccountDetailsApiCallId:string = "";
  servicesKey: { [x: string]: string; } = {
    [configJSON.googleLabel]: "google_access_token",
    [configJSON.instagramLabel]: "instagram_access_token"
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const AccountSettingSchema = Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address format")
        .required("Email is required"),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required")
    })

    this.state = {
      isLoading:false,
      accountSettingSchema: AccountSettingSchema,
      isImageLoading: false,
      changePassDialog: false,
      isFbConnected: false,
      isGoogleConnected: false,
      isInstagramConnected: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  openPasswordDialog = () => {
    this.setState({ changePassDialog: true })
  }

  closePasswordDialog = () => {
    this.setState({ changePassDialog: false })
  }

  setImageUpdated = () => {
    this.setState({ isImageLoading: false })
  }

  setImageUpdating = () => {
    this.setState({ isImageLoading: true })
  }

  editProfileClick = () => {
    if(this.profilePicRef.current) {
      this.profilePicRef.current.click()
    }
  }

  handleProfilePicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files
    if (file && file.length > 0) {
      const { type } = file[0]
      const fileExtension = type.split("/").pop()
      const fileType = fileExtension ? fileExtension.toLowerCase() : ""

      if (!this.allowedExtension.includes(fileType)) {
        toast.error("Only JPEG/JPG/PNG file are allowed")
        return
      }
      const formData = new FormData()
      formData.append("account[profile_pic]", file[0])
      this.updateProfilePicture(formData)

    } else toast.error("Something went wrong!")

  }

  handleErrorApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    if (await isTokenExpired(responseJson)) return

    const allApiCallIds = [this.profileImageApiCallId, this.getRolesApiCallId]

    if (
      allApiCallIds.includes(apiRequestCallId) &&
      Array.isArray(responseJson.errors) &&
      responseJson.errors.length > 0
    ) {
      responseJson.errors.forEach((err: any) => {
        const errorMessage = err.message || err
        toast.error(errorMessage)
      })
    }

    if(apiRequestCallId === this.postFacebookTokenApiCallId) {
      if("message" in responseJson.errors) {
        toast.error(responseJson.errors.message)
      }
    }
  }

  handleFacebookResponse = (response: objectType | undefined, serviceType?: string) => {
    if(response) {
      let httpBody = {
        fb_access_token: "",
        instagram_access_token: "",
        google_access_token: ""
      }

      if(serviceType === configJSON.googleLabel) {
        httpBody = {
          ...httpBody,
          google_access_token: response.tokenId
        }
      } else {
        httpBody = {
          ...httpBody,
          fb_access_token: response.accessToken
        }
      }
      this.connectedService(httpBody)
    }
  }

  connectedService = async (httpBody: IConnectServicePayload) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postFacebookTokenApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.connectedServiceEndPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getAccountDetails = async() => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAccountDetailsApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialAccounConnectedEndPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  async componentDidMount(): Promise<void> {
    this.getAccountDetails()
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson?.errors) {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
        return
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.profileImageApiCallId) {
          this.setImageUpdated()
          this.props.handleProfileImage(responseJson.data.attributes.profile_pic)
        }
        if(apiRequestCallId === this.postFacebookTokenApiCallId){
          toast.success(configJSON.fbConnectedMsg)
          this.getAccountDetails()
        }
        if(apiRequestCallId === this.getAccountDetailsApiCallId) {
          const {
            is_facebook_connected,
            is_google_connected,
            is_instagram_connected,
          } = responseJson
          this.setState({
            isFbConnected : is_facebook_connected,
            isGoogleConnected: is_google_connected,
            isInstagramConnected: is_instagram_connected

          })
        }
      }
    }
    // Customizable Area End
  }

  async doUpdateAccount(values: IAccountInfo, initialValues: IAccountInfo): Promise<boolean> {
    const hasChanged = !shallowEqual(initialValues, values)

    if (hasChanged) {
      const account = { account: values }
      this.props.updateAccountDetailsAction(account)
    }
    return true
  }

  async updateProfilePicture (updateDetails: FormData): Promise<boolean> {
    this.setImageUpdating()
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
    const header = {
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    
    this.profileImageApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAccountDetailsEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      updateDetails
    )
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true;
  }
}
