// Customizable Area Start
import React, { Component } from "react"
import { Box, Typography } from "@material-ui/core"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { styled } from "@mui/system"
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
const configJSON = require("./config.js")

const ImageWrapper = styled("div")(
  ({ isLatestUpdateAd }: { isLatestUpdateAd?: boolean }) => ({
    height: isLatestUpdateAd ? "47.2vh" : "100%",
    borderRadius: "15px",
    cursor: "pointer",
    position: "relative",
  })
)

const CustomOpenInNewIcon = styled(OpenInNewRoundedIcon)({
  position: "absolute",
  bottom: "10px",
  right: "10px",
  color: "#333"
})

const CustomImage = styled("img")({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  borderRadius: "15px",
})

interface IProps extends WithStyles<typeof styles> {
  isAd: boolean
  isLatestUpdateAd?: boolean
  thirdPartyImageUrl: string | undefined
  thirdPartyUrl: string | null
  children: React.ReactNode
}

export class AdCampaignWrapper extends Component<IProps> {
  openThirdPartyUrl = () => {
    this.props.thirdPartyUrl &&
      window.open(this.props.thirdPartyUrl, configJSON.underscoreBlankText)
  }

  render() {
    const {
      isAd,
      isLatestUpdateAd,
      thirdPartyImageUrl,
      thirdPartyUrl,
      children,
      classes,
    } = this.props

    return isAd ? (
      <Box className={classes.mainWrapper}>
        <Typography variant="caption" className={classes.sponsoredCaption}>
          {configJSON.sponsoredLabel}
        </Typography>
        {thirdPartyUrl ? (
          <ImageWrapper
            isLatestUpdateAd={isLatestUpdateAd}
            onClick={this.openThirdPartyUrl}
          >
            <CustomImage src={thirdPartyImageUrl} alt={configJSON.adAltText} />
            <CustomOpenInNewIcon fontSize="small" />
          </ImageWrapper>
        ) : (
          <Box>{children}</Box>
        )}
      </Box>
    ) : (
      children
    )
  }
}

export const styles = () =>
  createStyles({
    mainWrapper: {
      border: "1px solid #20a258",
      borderRadius: "16px",
      position: "relative",
      padding: "13px 7px 7px",
      height: "100%",
    },
    sponsoredCaption: {
      position: "absolute",
      top: "-11px",
      left: "15px",
      backgroundColor: "#fff",
      borderRadius: "2px",
      color: "#979797",
      padding: "0px 8px",
      zIndex: 1,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      fontFamily: "'Montserrat', sans-serif",
    },
  })

export default withStyles(styles)(AdCampaignWrapper)
// Customizable Area End
