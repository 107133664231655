import React from "react";
//import "../../../web/src/assets/css/style.scss";
import "../assets/css/normalUserRegistration.scss";
// import Button from '@material-ui/core/Button';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
import { Logo } from "./assets";
import NormalUserRegistrationController, {
    Props,
} from "./NormalUserRegistrationController.web";

import { Box, Grid, Typography, TextField, Button } from "@material-ui/core";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FilledInput } from "@material-ui/core";
import { Link } from "react-router-dom";
import TopBar from "../../../components/src/TopBar.web";
import WhiteBackgroundContainer from "../../../components/src/WhiteBackgroundContainer.web";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

class NormalUserRegistration extends NormalUserRegistrationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <TopBar />
                <WhiteBackgroundContainer>

                    <form noValidate autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={1} sm={2} md={3} lg={4} />

                            <Grid item xs={10} sm={8} md={6} lg={4}>
                                <Box className="Normal_User_Regitration_Form_Wrapper">
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <Box width="100%">
                                                <Typography className="create-account-heading">
                                                    Create an account
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box width="100%">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    First Name
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter First Name"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box width="100%">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    Last Name
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter Last Name"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        focused: "Normal_User_Regitration_Input_Focused",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box width="100%">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    DOB
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter Date of birth"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box width="100%">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    E-mail
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter E-mail"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box width="100%" marginBottom="10px">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    Address
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Address Line 1"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box width="100%">
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Address Line 2"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box width="100%">
                                                <Typography className="Normal_User_Regitration_Input_Label">
                                                    Password
                                                </Typography>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    placeholder="Address Line 1"
                                                    classes={{
                                                        root: "Normal_User_Regitration_Input_Field",
                                                        input: "Normal_User_Regitration_Input",
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={2} />
                                        <Grid item xs={12} sm={10} md={8}>
                                            <Box width="100%">
                                                <Button
                                                    className="create_account_btn"
                                                    classes={{ label: "create_account_btn-text" }}
                                                >
                                                    Create Account
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={2} />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={1} sm={2} md={3} lg={4} />
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "40px",
                                    }}
                                >
                                    <Box className="anable_create_account_arrows">
                                        <ArrowBackIcon />
                                    </Box>
                                    <Box className="disable_create_account_arrows">
                                        <ArrowForwardIcon />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </WhiteBackgroundContainer>
            </>
        );
    }
}

export default NormalUserRegistration;
