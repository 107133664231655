// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import { googleImg } from "../assets"
import { objectType } from "reactjs-social-login"
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login"
import CustomServiceButton from "./CustomServiceButton.web"
import { toast } from "react-toastify"
const configJSON = require("../config.js")

interface IGoogleLoginServiceButtonProps {
  isConnected: boolean
  handleGoogleResponse: (data: objectType | undefined, type?: string) => void
}

const GoogleLoginServiceButton = ({
  isConnected,
  handleGoogleResponse,
}: IGoogleLoginServiceButtonProps) => {
  return (
    <Box>
      {!isConnected ? (
        <GoogleLogin
          clientId={configJSON.GOOGLE_LOGIN_CLIENT_ID}
          cookiePolicy="single_host_origin"
          render={(renderProps) => (
            <CustomServiceButton
              icon={googleImg}
              btnText={configJSON.googleLabel}
              onClick={renderProps.onClick}
              isConnected={isConnected}
            />
          )}
          onSuccess={(data: GoogleLoginResponse | GoogleLoginResponseOffline) =>
            handleGoogleResponse(data, configJSON.googleLabel)
          }
          onFailure={(error: { message: string }) => toast.error(error.message)}
        />
      ) : (
        <CustomServiceButton
          icon={googleImg}
          btnText={configJSON.googleLabel}
          isConnected={isConnected}
        />
      )}
    </Box>
  )
}

export default GoogleLoginServiceButton
// Customizable Area End
