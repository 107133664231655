// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import {
  getTokenFromURL,
  isTokenExpired,
} from "../../../../../components/src/utility/helper"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import { styles } from "./NavbarSearch.web"
import { WithStyles } from "@material-ui/core/styles"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import React from "react"
import { IImage } from "../../../../../components/src/interfaces/common"
import {
  ISearchCourse,
  ISearchDish,
  ISearchFarm,
  ISearchProduct,
  ISearchRestaurant,
} from "../../interfaces/search"
import { ISearchResultTypeEnum } from "../../interfaces/searchEnum"
const configJSON = require("../../config.js")

type Callback<T extends unknown[]> = (...args: T) => void

export interface ISearchResult {
  id: string
  type: ISearchResultTypeEnum
  name: string
  image: IImage | null
  producerId: string
}

interface ISearchResultResponse {
  farms: { data: ISearchFarm[] }
  restaurants: { data: ISearchRestaurant[] }
  courses: { data: ISearchCourse[] }
  products: { data: ISearchProduct[] }
  dishes: { data: ISearchDish[] }
  status: string
  courses_view_all: boolean
  dishes_view_all: boolean
  farm_catalogues_view_all: boolean
  farms_view_all: boolean
  restaurants_view_all: boolean
}

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  isMobileSearchPage?: boolean
}

interface S {
  loader: boolean
  searchValue: string
  isListPopupOpen: boolean
  searchResults: ISearchResult[]
}

interface SS {
  id: any
}

export default class NavbarSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchFieldRef = React.createRef<HTMLInputElement>()
  searchApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      searchValue: "",
      isListPopupOpen: false,
      searchResults: []
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    this.updateSearchValueFromURL()
  }

  async componentWillUnmount(): Promise<void> {
    this.handleSearchPopupClose()
  }

  getRedirectUrl = (type: string, id: string) => {
    switch(type) {
      case ISearchResultTypeEnum.Farm:
        return `${AppRoutings.FarmDetails}/${id}`
      case ISearchResultTypeEnum.Restaurant:
        return `${AppRoutings.RestaurantDetails}/${id}`
      case ISearchResultTypeEnum.Course:
        return `${AppRoutings.CourseDetails}${id}`
      case ISearchResultTypeEnum.Product:
        return `${AppRoutings.FarmProductDetails}/${id}`
      case ISearchResultTypeEnum.Dish:
        return `${AppRoutings.RestaurantDetails}/${id}`
      default:
        return ''
    }
  }

  updateSearchValueFromURL = () => {
    const query = getTokenFromURL(configJSON.queryText)
    if (query) this.setState({ searchValue: query })
    else this.setState({ searchValue: "" })
  }

  enableSearchLoader = () => {
    this.setState({ loader: true })
  }

  disableSearchLoader = () => {
    this.setState({ loader: false })
  }

  searchDebounce = <T extends unknown[]>(call: Callback<T>, delay: number) => {
    let timer: ReturnType<typeof setTimeout> | undefined

    return function(...args: T) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  handleEmptySearch = () => {
    this.setState({ loader: false, searchResults: [] })
  }

  SearchDebounceUpdate = this.searchDebounce(() => {
    const { searchValue } = this.state
    return searchValue.length > 2
      ? this.searchApi(`?query=${searchValue}&limit=2`)
      : this.handleEmptySearch()
  }, 700)

  handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({ searchValue: value })
    this.SearchDebounceUpdate()
  }

  handleSearchFieldKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e

    if (key === configJSON.ENTER_KEY) {
      this.handleSearchPopupClose()
      this.handleSearch()
    }

    if (key === configJSON.ESCAPE_KEY) {
      this.handleSearchPopupClose()
    }
  }

  handleResetSearch = () => {
    this.setState({
      searchValue: "",
      searchResults: [],
      isListPopupOpen: false,
    })
    if (this.searchFieldRef.current) this.searchFieldRef.current.focus()
  }

  handleSearchPopupOpen = () => {
    this.setState({ isListPopupOpen: true })
  }

  handleSearchPopupClose = () => {
    this.setState({ isListPopupOpen: false })
  }

  handleSearch = () => {
    const { searchValue } = this.state

    if (searchValue) {
      this.props.history.push(`${AppRoutings.Search}?query=${searchValue}`)
    }
  }

  searchApi = async (query: string) => {
    this.enableSearchLoader()

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchSuggestionApiEndPoint + query
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getFarmsConvertedResponse = (farms: ISearchFarm[]) => {
    return farms.map((farm: ISearchFarm) => {
      const {
        id,
        attributes: { name, images },
      } = farm
      return {
        id,
        type: ISearchResultTypeEnum.Farm,
        name,
        image: images,
        producerId: "",
      }
    })
  }

  getRestaurantsConvertedResponse = (restaurants: ISearchRestaurant[]) => {
    return restaurants.map((restaurant: ISearchRestaurant) => {
      const {
        id,
        attributes: { name, image },
      } = restaurant
      return {
        id,
        type: ISearchResultTypeEnum.Restaurant,
        name,
        image,
        producerId: "",
      }
    })
  }

  getCoursesConvertedResponse = (courses: ISearchCourse[]) => {
    return courses.map((course: ISearchCourse) => {
      const {
        id,
        attributes: { banner_image, title },
      } = course
      return {
        id,
        type: ISearchResultTypeEnum.Course,
        name: title,
        image: banner_image,
        producerId: "",
      }
    })
  }

  getProductsConvertedResponse = (products: ISearchProduct[]) => {
    return products.map((product: ISearchProduct) => {
      const {
        id,
        attributes: { name, images },
      } = product
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null
      return {
        id,
        type: ISearchResultTypeEnum.Product,
        name,
        image,
        producerId: "",
      }
    })
  }

  getDishesConvertedResponse = (dishes: ISearchDish[]) => {
    return dishes.map((dish: ISearchDish) => {
      const {
        id,
        attributes: { name, images, restaurant_id },
      } = dish
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null
      return {
        id,
        type: ISearchResultTypeEnum.Dish,
        name,
        image,
        producerId: restaurant_id,
      }
    })
  }

  handleSearchResult = (response: ISearchResultResponse) => {
    const {
      products: { data: products },
      dishes: { data: dishes },
      farms: { data: farms },
      restaurants: { data: restaurants },
      courses: { data: courses },
    } = response
    const farmsList = this.getFarmsConvertedResponse(farms)
    const restaurantsList = this.getRestaurantsConvertedResponse(restaurants)
    const coursesList = this.getCoursesConvertedResponse(courses)
    const productsList = this.getProductsConvertedResponse(products)
    const dishesList = this.getDishesConvertedResponse(dishes)

    const searchResults = [
      ...productsList,
      ...dishesList,
      ...coursesList,
      ...farmsList,
      ...restaurantsList,
    ]

    this.setState({ searchResults, isListPopupOpen: true, })
  }

  handleApiSuccessResponse = (apiRequestCallId: string, responseJson: ISearchResultResponse) => {
    if (apiRequestCallId === this.searchApiCallId) {
      if (responseJson.status === "ok") {
        this.handleSearchResult(responseJson)
      }
      this.disableSearchLoader()
    }
  }

  handleApiErrorResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.searchApiCallId) {
      this.disableSearchLoader()
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseDataNavbar = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseDataNavbar && !responseDataNavbar.errors) {
        this.handleApiSuccessResponse(apiRequestId, responseDataNavbar)
      } else {
        if (await isTokenExpired(responseDataNavbar)) {
          return
        }
        this.handleApiErrorResponse(apiRequestId)
      }
    }
  }
}
// Customizable Area End
