// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { Message } from "framework/src/Message"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "framework/src/StorageProvider"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper"
export const configJSON = require("../../config")

export interface Props extends RouteComponentProps {
  type: string
  location: any
  checkoutOrder: any
  selectedCard: string
  enteredPoint: string
  isLoyaltyPoint: boolean
  getCartItems: () => void
  toggleIsLoyaltyPoint: () => void
  handleCardSelectChange: (cardId: string) => void
  setOrderPaymentType: (type: string) => void
  setCheckoutOrder: (orderData: any) => void
  handleEnteredPointValueChange: (value: string) => void
}

interface S {
  loading: boolean
  transactionId: string[]
  pointErrorMessage: string
  loyaltyPointBalance: number
  isPaymentDoneDialog: boolean
  applicablePoints: number | null
}

interface SS {
  id: any
}

export default class CheckoutPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  changePaymentTypeApiCallId: string = ""
  cardPaymentApiCallId: string = ""
  getLoyaltyPointBalanceApiCallId: string = ""
  getApplicablePointsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      loading: false,
      transactionId: [],
      loyaltyPointBalance: 0,
      isPaymentDoneDialog: false,
      pointErrorMessage: "",
      applicablePoints: null
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    this.getLoyaltyPointBalanceApi()
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { isLoyaltyPoint } = this.props

    if(prevProps.isLoyaltyPoint !== isLoyaltyPoint && !!isLoyaltyPoint) {
      this.getApplicablePointsApi()
    }
  }

  getPaymentType = () => {
    if (this.props.checkoutOrder?.payment_method === "credit_or_debit_card") {
      return "card"
    }
    return ""
  }

  handlePointValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pointErrorMessage: ""
    })
    this.props.handleEnteredPointValueChange(e.target.value)
  }

  handleChangeCard = (cardId: string) => {
    this.props.handleCardSelectChange(cardId)
  }

  openPaymentDoneDialog = () => this.setState({ isPaymentDoneDialog: true })

  closePaymentDoneDialog = () => this.setState({ isPaymentDoneDialog: false })

  onPaymentDoneAccept = () => {
    const { type, history, getCartItems } = this.props
    getCartItems()
    this.closePaymentDoneDialog()
    if (type === "course") history.push(AppRoutings.Learning)
    else history.push(AppRoutings.Home)
  }

  handleCardPaymentApi = async (cardId: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const { location, checkoutOrder, enteredPoint, isLoyaltyPoint } = this.props
    const { orderIds, cartIds }: any = location.state
    const { total } = checkoutOrder.order_details

    const httpBody = {
      card_id: cardId,
      order_ids: orderIds,
      total,
      cart_item_ids: cartIds,
      apply_rewards: isLoyaltyPoint,
      points: Number(enteredPoint) || 0
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.cardPaymentApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardPaymentApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getLoyaltyPointBalanceApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getLoyaltyPointBalanceApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardPointsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getApplicablePointsApi = async () => {
    this.setState({ loading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const { location } = this.props
    const { orderIds }: any = location.state

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getApplicablePointsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApplicablePointsApiEndPoint}?order_ids=${orderIds}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.changePaymentTypeApiCallId) {
      this.props.setCheckoutOrder(responseJson)
    }

    if (apiRequestCallId === this.cardPaymentApiCallId) {
      if("message" in responseJson) {
        if (responseJson.message === "Payment Successful") {
          const transactionIds = responseJson.payment.map(
            (paymentInfo: { id: string }) => paymentInfo.id
          )
          this.setState({
            isPaymentDoneDialog: true,
            transactionId: transactionIds,
          })
        } else {
          toast.error(responseJson.message)
        }
      }
    }

    if (apiRequestCallId === this.getLoyaltyPointBalanceApiCallId) {
      this.setState({ loyaltyPointBalance: responseJson.reward_points })
    }

    if(apiRequestCallId === this.getApplicablePointsApiCallId) {
      this.setState({
        loading: false,
        applicablePoints: responseJson.reward_points
      })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessResponse(apiRequestCallId, responseJson)
      } else {
        if (isTokenExpireResponse(responseJson)) return

        if (apiRequestCallId === this.cardPaymentApiCallId) {
          responseJson?.errors?.forEach((err: any) =>
            toast.error(err?.detail ?? err?.message ?? err)
          )
          this.props.history.push(AppRoutings.ShoppingCart)
        }

        if (responseJson?.errors) {
          responseJson.errors?.forEach((err: any) => toast.error(err?.message))
        }
      }
    }
  }
}
// Customizable Area End
