import React from "react";
import "../../../web/src/assets/css/style.scss";
import AddNewAddressesController from "./AddNewAddressesController.web";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  FilledInput,
  DialogContent,
  Dialog,
  CircularProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "../assets/css/addAddress.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { addAddressAction, setAddAddressErrors } from "./assets";
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web";
import CustomDialogTitle from "../../../components/src/CustomDialogTitle.web";
import CustomDialogActions from "../../../components/src/CustomDialogActions.web";

const AddAddressSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  address: Yup.string().required("Address is required"),
  company_name: Yup.string(),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .length(10, "Please enter valid phone number"),
  postal_code: Yup.string()
    .required("Postal code is required")
    .length(5, "Please enter a valid postal code")
    .matches(/^\d{5}(?:-\d{4})?$/, "Please enter valid pin code"),
});

export class AddAddress extends AddNewAddressesController {
  render() {
    const { isOpen, userInfo, apiErrors, close } = this.props;
    const isLoading = userInfo ? userInfo.isAddressModalLoading : false;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="xs"
        classes={{
          paper: "custom_roundish_dialog_paper"
        }}
        onClose={close}
      >
        <CustomDialogTitle onClose={close}>
          <Box className="forgot_PW_tab_container">
            <Typography variant="subtitle1" className="forgot_PW_tab">
              Add New Address
            </Typography>
          </Box>
        </CustomDialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.addAddressRef}
            initialValues={{
              first_name: "",
              last_name: "",
              company_name: "",
              address: "",
              address2: "",
              city: "",
              state: "",
              country: "",
              postal_code: "",
              phone_number: "",
              address_type: "home"
            }}
            validationSchema={AddAddressSchema}
            onSubmit={values => this.addAddress(values)}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ values, touched, errors, handleChange }) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box className="text_field web_input_field" width="100%">
                      <FilledInput
                        name="first_name"
                        id="outlined-adornment-password"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        placeholder="First Name"
                        value={values.first_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.first_name}
                        isTouched={touched.first_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        name="last_name"
                        placeholder="Last Name "
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.last_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.last_name}
                        isTouched={touched.last_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="text_field web_input_field" width="100%">
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder="Company Name "
                        name="company_name"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.company_name}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.company_name}
                        error={errors.company_name}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        name="address"
                        id="outlined-adornment-password"
                        placeholder="Address Line 1"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.address}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.address}
                        isTouched={touched.address}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="text_field web_input_field" width="100%">
                      <FilledInput
                        id="outlined-adornment-password"
                        name="address2"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        placeholder="Address Line 2"
                        value={values.address2}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.address2}
                        isTouched={touched.address2}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        id="outlined-adornment-password"
                        autoComplete="off"
                        name="city"
                        placeholder="City"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        value={values.city}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.city}
                        isTouched={touched.city}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        placeholder="State"
                        id="outlined-adornment-password"
                        autoComplete="off"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        name="state"
                        value={values.state}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        error={errors.state}
                        isTouched={touched.state}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="text_field web_input_field" width="100%">
                      <FilledInput
                        placeholder="Country"
                        id="outlined-adornment-password"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        name="country"
                        value={values.country}
                        onChange={e => this.handleValueChange(e, handleChange)}
                      />
                      <FormikErrorMessage
                        isTouched={touched.country}
                        error={errors.country}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        placeholder="Postal Code"
                        id="outlined-adornment-password"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        name="postal_code"
                        value={values.postal_code}
                        onChange={e =>
                          this.handlePostalCodeChange(e, handleChange)
                        }
                      />
                      <FormikErrorMessage
                        error={errors.postal_code}
                        isTouched={touched.postal_code}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="text_field web_input_field">
                      <FilledInput
                        name="phone_number"
                        placeholder="Phone Number"
                        classes={{
                          input: "Login_Input phone_number_input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus"
                        }}
                        id="outlined-adornment-password"
                        startAdornment={
                          <InputAdornment position="start">
                            <Typography
                              className={`address_phone_number_prefix ${
                                values.phone_number.length > 0
                                  ? "value_contain_phone_prefix"
                                  : ""
                              }`}
                            >
                              +1
                            </Typography>
                          </InputAdornment>
                        }
                        value={values.phone_number}
                        onChange={e =>
                          this.handlePhoneNumberChange(e, handleChange)
                        }
                      />
                      <FormikErrorMessage
                        error={errors.phone_number}
                        isTouched={touched.phone_number}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>

          {apiErrors.length > 0 && (
            <div ref={this.errorElementRef}>
              {apiErrors.map((error: { message: string }) => {
                const { message } = error;
                return (
                  <Box mt={2} key={message}>
                    <Alert severity="error">{message}</Alert>
                  </Box>
                );
              })}
            </div>
          )}
        </DialogContent>
        <CustomDialogActions>
          <Box width="100%">
            <Button
              className="btn"
              classes={{
                label: "normal_login_btn"
              }}
              onClick={this.handleFormSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress color={"inherit"} size={25} />
              ) : (
                "Add Address"
              )}
            </Button>
          </Box>
        </CustomDialogActions>
      </Dialog>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.user,
    apiErrors: state.user.addAddressApiErrors || []
  };
};

export default connect(
  mapStateToProps,
  {
    addAddressAction,
    setAddAddressErrors
  }
)(AddAddress);
