// Customizable Area Start
import React from "react"
import { Grid } from "@material-ui/core"
import SearchCategoryLayout from "./SearchCategoryLayout.web"
import RestaurantCard from "../../../info-page/src/Restaurants/RestaurantCard.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ISearchRestaurant } from "../interfaces/search"
const configJSON = require("../config.js")

interface ISearchRestaurantsList {
  searchQuery: string
  isViewAll: boolean
  restaurantsList: ISearchRestaurant[]
}

const SearchRestaurantsList = ({
  searchQuery,
  isViewAll,
  restaurantsList,
}: ISearchRestaurantsList) => {
  const redirectUrl = isViewAll
    ? `${AppRoutings.SearchRestaurants}?${configJSON.queryText}=${searchQuery}`
    : ""

  return restaurantsList.length > 0 ? (
    <SearchCategoryLayout
      heading={configJSON.restaurantsText}
      viewAllRedirectUrl={redirectUrl}
    >
      <Grid container spacing={3}>
        {restaurantsList.map((restaurant: ISearchRestaurant) => {
          const {
            id,
            attributes: { name, image, quote_time, description },
          } = restaurant
          const imageUrl = image && image.url ? image.url : ""
          const redirectUrl = `${AppRoutings.RestaurantDetails}/${id}`

          return (
            <Grid item xs={12} sm={6} md={3} key={id}>
              <RestaurantCard
                cardName={name}
                thumbnail={imageUrl}
                duration={quote_time}
                description={description}
                redirectPath={redirectUrl}
              />
            </Grid>
          )
        })}
      </Grid>
    </SearchCategoryLayout>
  ) : null
}

export default SearchRestaurantsList
// Customizable Area End
