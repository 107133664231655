// Customizable Area Start
import React from "react"
import { Container, Grid } from "@material-ui/core"
import LPBottomSectionCard from "./LPBottomSectionCard.web"
import { connect } from "react-redux"
import { IImage } from "../../../../../components/src/interfaces/common"

interface IUniqueOfferInfo {
    id: string
    type: string
    attributes: {
        by: string
        heading: string
        image: IImage
        date: string
    }
}

interface ILPBottomSection {
    LPUniqueOffering: IUniqueOfferInfo[]
}

const LPBottomSection = ({ LPUniqueOffering }: ILPBottomSection) => {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid container spacing={5} justifyContent="center">
                    {LPUniqueOffering?.map((offerDetails: IUniqueOfferInfo) => {
                        const {
                            id,
                            attributes: { by, heading, image, date },
                        } = offerDetails

                        return (
                            <Grid key={id} item xs={10} sm={6} md={4}>
                                <LPBottomSectionCard
                                    by={by}
                                    date={date}
                                    heading={heading}
                                    image={image?.url ?? ""}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        LPUniqueOffering: state.cms.LPUniqueOffering
    }
}

export default connect(mapStateToProps, {})(LPBottomSection)
// Customizable Area End
