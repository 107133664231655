import {baseURL} from "../../../../framework/src/config"
import StorageProvider from "framework/src/StorageProvider";

export const sentOTP = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/account_block/accounts/send_email_otp`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const verifyOTP = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/account_block/accounts/verify_email_otp`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const verifyUser = async () => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/account_block/accounts`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const googleLoginAPI = async (data) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_login/social_login`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const facebookLoginAPI = async (info) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_login/social_login`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(info)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getAddressList = async () => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/address/addresses`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const addAddress = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/address/addresses`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const updateAddress = async (data,id) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/address/addresses/${id}`,{
            method:'PUT',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const updateAccountDetails = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/account_block/accounts`,{
            method:'PUT',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}



export const deleteAddress = async (id) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/address/addresses/${id}`,{
            method:'DELETE',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const getCartItemList = async () => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_order_management/carts/cart_detail`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const removeCartItemApi = async (id) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const httpBody = { cart_item: { id } }
        const response = await fetch(`${baseURL}/bx_block_order_management/cart_items/remove_to_cart`,{
            method:'DELETE',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(httpBody)
        });
        return response.json()
    } catch (e) {
        return e
    }
}

export const updateItemQuantityApi = async (id, quantity) => {
    
    const Token = await StorageProvider.get("authToken");
    try {
        const payload = {
            cart_item: {
                id,
                quantity
            }
        }

        const response = await fetch(`${baseURL}/bx_block_order_management/cart_items/update_quantity`,{
            method:'PUT',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body:JSON.stringify(payload)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const bulkAddToCart = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_order_management/cart_items/bulk_add_to_cart`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token
            },
            body: JSON.stringify(data)
        });
        await StorageProvider.remove("orders")
        return response.json()
    } catch (e) {
        return e
    }
}

export const getWishlistCount = async () => {
    const token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_favourites/favourites/wishlist_count`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}