// Customizable Area Start
import React from "react"
import CommunityExpertsController, {
  Props,
  IExpert,
} from "./CommunityExpertsController.web"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core"
import { connect } from "react-redux"
import Slider from "react-slick"
import "../../assets/css/community/communityExperts.scss"
import { withRouter } from "react-router-dom"
import ExpertCardCarousel from "../../../../../components/src/ExpertCardCarousel.web"
import ExpertDetailsDialog from "../../Experts/ExpertDetailsDialog.web"

const responsiveSlideConfig = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 2,
      centerMode: false
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1,
    },
  },
]

class CommunityExperts extends CommunityExpertsController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { communityExpertDesc, communityExperts } = this.props
    const { loader, isExpertDetailsDialog, expertId, currentSlidesToShow } = this.state

    const expertsListLength = communityExperts?.length
    const isRequiredSlidesExist = expertsListLength > currentSlidesToShow
    const slideToShow = isRequiredSlidesExist
      ? currentSlidesToShow
      : expertsListLength
    const initialSlide = Math.floor(expertsListLength / 2)

    return (
      <Box className="ce_main_wrapper">
        <Container>
          <Typography className="ce_main_heading">Our Experts</Typography>
          <Typography className="ce_description">
            {communityExpertDesc}
          </Typography>
        </Container>
        <Box className="ce_carousel_wrapper">
          {loader ? (
            <CircularProgress size={30} className="image_loader" />
          ) : (
            <>
              {communityExperts?.length > 0 ? (
                <>
                  <Slider
                    draggable
                    swipeToSlide
                    speed={700}
                    arrows={false}
                    infinite={true}
                    className="ce_carousel"
                    lazyLoad="ondemand"
                    slidesToShow={slideToShow}
                    initialSlide={initialSlide}
                    centerMode={isRequiredSlidesExist}
                    responsive={responsiveSlideConfig}
                    onReInit={this.handleOnReInit}
                    beforeChange={this.handleBeforeChange}
                    afterChange={this.handleAfterChange}
                  >
                    {communityExperts?.map((expert: IExpert) => {
                      const {
                        id,
                        attributes: {
                          first_name,
                          last_name,
                          headline,
                          educator_image,
                        },
                      } = expert
                      return (
                        <ExpertCardCarousel
                          key={id}
                          id={id}
                          image={educator_image?.url || ""}
                          name={`${first_name} ${last_name}`}
                          skill={headline}
                          handleOnClick={this.openExpertDetailsDialog}
                        />
                      )
                    })}
                  </Slider>

                  <Box className="view_all_btn_container">
                    <Button
                      classes={{ root: "orange_btn" }}
                      onClick={this.handleViewAllExperts}
                    >
                      View All
                    </Button>
                  </Box>
                </>
              ):
              <Container>
              <Box className="ce_empty_container">
                No experts are listed
              </Box>
              </Container>
              }
            </>
          )}
        </Box>
        {isExpertDetailsDialog && (
          <ExpertDetailsDialog
            isOpen={isExpertDetailsDialog}
            expertId={expertId}
            handleClose={this.closeExpertDetailsDialog}
          />
        )}
      </Box>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    communityExpertDesc: state.community.communityExpertDesc,
    communityExperts: state.community.communityExperts,
  }
}

export default withRouter(connect(mapStateToProps, {})(CommunityExperts))
// Customizable Area End
