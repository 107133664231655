Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpGetMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Favourites";
exports.labelBodyText = "Favourites Body";

exports.btnExampleTitle = "CLICK ME";

exports.GOOGLE_API_KEY = "AIzaSyAl6Aa52ZsQL2SAUECMYGKWbv3Pq0C1iF4"
exports.favouriteListApiEndPoint = "favourites/favourites"
exports.latestupdateListApiEndPoint =
  "bx_block_dashboard/landing_pages/recently_added";
exports.AUTH_TOKEN = "authToken"
// Customizable Area End