// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import { UserIcon } from "./assets";
export const configJSON = require("./config.js")

export interface Props {
    navigation: any;
    id: string;
  
    history?: any;
    location?:any
    updateActiveTab?: (activeTab: string) => void
    onLoginSuccess: () => void
    getCartItems: () => void
    classes:{
      unenrolledHeader : string,
      reviewBtn : string,
      leftHeader : string,
      center : string,
      accordionMargin : string,
      goToCourseLabel : string,
      favIcon : string
    }
}

interface S {
    courseDetail : {
      id : string,
      attributes : {
        already_added_in_cart : boolean
        banner_image: {
          url: string
        },
        biography : string
        description: string
        educator_image : {
          url : string
        },
        price : number
        favourites : boolean
        first_name : string
        headline : string
        id: number
        is_enrolled : boolean
        is_owner : boolean
        last_name : string
        title : string
        course_sub_sections :{
          data : {
            attributes : {
              description : string
              heading: string
            }
          }
        }
      }
    }
    isOpen : boolean
    commentText : string
    rating : number
    firstName : string
    lastName : string
    courseId : string
    isEditSave : boolean
    reply : string
    isEdit : boolean
    edittedReply : string
    loading : boolean
    commentId : string
    reviewsList : object[]
    hours : string
    mins : string
    hh : string
    mm : string
    enrolledStudents : string
    isCourseFav : boolean
    isFavourite : boolean
    isLoginDialog : boolean
    anchorEl : any
    copy :  boolean
    modalOpen: boolean
    sec : number
    cartId : any
    hideBiography : boolean
    openDialog : boolean
    loader : boolean
    calHours : string
    calSeconds : string
    lessonLength : string
    educatorImage : string
    courseData : object
    itemAddedToCart : boolean
}

interface SS {
    id : any
}

export default class LandingPage3Controller extends BlockComponent<
Props,
S,
SS
>{
  //Customizable Area Start
    getCourseDetailCallId : string = "" ;
    createReviewsCallId : string = "";
    delReplyCallId : string = "";
    createReplyCallId : string = "";
    saveEditReplyCallId : string = "";
    getVideoLengthCallId : string = "";
    setFavouriteCourseApiCallId : string = "";
    createPurchaseCallId : string = "";
    getCartDetailsCallId : string = "";
    createFreeOrderCallId : string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            courseDetail : {
              id : '',
              attributes : {
                already_added_in_cart : false,
                banner_image: {
                  url: ''
                },
                biography : '',
                description: '',
                educator_image : {
                  url : '',
                },
                price : 0,
                favourites : false,
                first_name : '',
                headline : '',
                id: 0,
                is_enrolled : false,
                is_owner : false,
                last_name : '',
                title : '',
                course_sub_sections :{
                  data : {
                    attributes : {
                      description : '',
                      heading: '',
                    }
                  }
                }
              }
            },
            isOpen : false,
            commentText : '',
            rating : 0,
            firstName : '',
            lastName : '',
            courseId : '',
            isEditSave : false,
            reply : '',
            isEdit : false,
            edittedReply : '',
            loading : false,
            commentId : '',
            reviewsList : [],
            hours : '',
            mins : '',
            hh : '',
            mm: '',
            enrolledStudents : '',
            isCourseFav: false,
            isFavourite : false,
            isLoginDialog: false,
            anchorEl : null,
            copy : false,
            modalOpen : false,
            sec : 0,
            cartId : '',
            hideBiography : false,
            openDialog : false,
            loader : false,
            calHours: '',
            calSeconds : '',
            lessonLength: '',
            educatorImage : '',
            courseData: [],
            itemAddedToCart : false
        }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
        
        if (this.isPlatformWeb() === false) {
          this.props.navigation.addListener("willFocus", () => {
          });
        }
        //---------------------
        function queryParam() {
          return new URLSearchParams(window.location.search);
        }
        let query = queryParam()
        const id = query.get('id');
         
        const firstName = await StorageProvider.get(configJSON.firstNameText)
        const lastName = await StorageProvider.get(configJSON.lastNameText)
        const cart_id = localStorage.getItem(configJSON.cartIdText)
        this.setState({firstName : firstName, lastName : lastName, cartId : cart_id})       

        if(id){
          await  this.getCourseDetails(id);
          await  this.getVideoLength(id)
          this.setState({courseId : id})
        }
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
        
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        
        const data = !responseJson?.error && !responseJson?.errors;
        const query = this.getQuery()
        const id:any = query.get('id')
        if (!data || apiRequestCallId === null) {
          if(apiRequestCallId === this.setFavouriteCourseApiCallId){
            this.setState({isCourseFav : false})
            toast.error(configJSON.somethingWentWrongText)
          }
        }

        await this.handleSuccessResponse(responseJson,apiRequestCallId,id)

      }

    handleSuccessResponse = async(responseJson : any, apiRequestCallId: string, id:string ) => {
  
        switch (apiRequestCallId) {
          case this.getCourseDetailCallId:
            const bioLength = responseJson?.data?.attributes?.biography?.length
            this.calTime()
            
            let length = 0
            const calculatedLength = responseJson?.data?.attributes?.course_sections?.data.map((i: any) => { 
              length = length + i?.attributes?.course_sub_sections?.data?.length
              return length
            })     
            const lessonLength = calculatedLength && calculatedLength[1] > 1 ? `${calculatedLength[1] - 1}+ Lessons` : configJSON.OneLessonText
            if (bioLength > 500){
              this.setState({hideBiography : true})
            }

            const courseData = responseJson?.data?.attributes?.course_sections?.data.filter((part:any) => part.attributes.course_sub_sections.data.length > 0).map((partInfo:any)=> partInfo)
            this.setState({
              loader : false,
              courseDetail: responseJson?.data,
              reviewsList: responseJson?.data?.attributes?.reviews,
              isFavourite: responseJson.data?.attributes?.favourites,
              lessonLength : lessonLength,
              educatorImage : responseJson?.data?.attributes?.educator_image?.url || UserIcon,
              courseData : courseData,
              itemAddedToCart : responseJson.data?.attributes?.already_added_in_cart
            });
            
            break;
          case this.createReviewsCallId:
            this.setState({ commentText: '', rating: 0 });
            await this.getCourseDetails(id)
            break;
          case this.saveEditReplyCallId:
          case this.createReplyCallId:
            this.setState({ isEditSave: false, reply: '', edittedReply: '' });
            await this.getCourseDetails(id)
            break;
          case this.delReplyCallId:
            this.setState({reply: ''});
            await this.getCourseDetails(id)
            break;
          case this.getVideoLengthCallId:
            if (responseJson.message){
            const [videoLength,enrolledStudents] = responseJson.message.match(/(\d+)\D+(\d+)/).slice(1);
            this.format(videoLength)
            this.calculateStudents(enrolledStudents)
            }
            break;
          case this.setFavouriteCourseApiCallId :
            if(responseJson?.meta?.favourite === true) {
              toast.success(configJSON.courseAddedToFavMsg)
            }
            else {
              toast.success(configJSON.courseRemovedFromFavMsg)
            }
            this.setState({ isFavourite: !!responseJson?.meta?.favourite, isCourseFav: false })
            break;
          case this.createPurchaseCallId : 
            this.props.getCartItems()
            toast.success(configJSON.courseAddedMsg) 
            await this.getCourseDetails(id);
            break;
          case this.createFreeOrderCallId : 
            await this.getCourseDetails(id);
            await this.getVideoLength(id);
            this.setState({openDialog : true})
            break;
          default:
            break;
        }
    }

    calTime = () => {
      const calHours = this.state.hours > '0' ? this.state.hours : this.state.mins
      const calSeconds = this.state.sec !== 0 ? `${this.state.sec}` + configJSON.secondsText : ''
      this.setState({
        calHours : calHours,
        calSeconds: calSeconds
      })
    }

    getQuery = () => {
      function useQuery() {
        return new URLSearchParams(window.location.search);
      }
      let query = useQuery();
      return (query)
  
    }
        
    getCourseDetails = async(id: string) => {
      this.setState({loader : true})
        const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
        const detailMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCourseDetailCallId = detailMessage.messageId;
    
        detailMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.courseDetailUserFlowAPIEndpoint + `${id}`
        );
        detailMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        detailMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
    
        runEngine.sendMessage(detailMessage.id, detailMessage);
    }

    getVideoLength = async(id: string) => {
      const token = await StorageProvider.get(configJSON.authToken);
        const header = {
          "Content-type": configJSON.categoryApiContentType,
          token : token
        };
        const videoMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getVideoLengthCallId = videoMessage.messageId;
    
        videoMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.videoLengthAPIEndpoint +`${id}`
        );
        videoMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        videoMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
    
        runEngine.sendMessage(videoMessage.id, videoMessage);
    }

    createCourseReviews = async(review:string, rating:string,id:string) => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        token : token
      };
  
      const formData = new FormData()
      formData.append("review[rating]", rating);
      formData.append("review[comment]", review);
      formData.append("review[reviewable_id]", id);
      formData.append("review[reviewable_type]", configJSON.reviewType);    
      
      const createReviewMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.createReviewsCallId = createReviewMessage.messageId;
  
      createReviewMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createReviewAPIEndpoint
      );
      createReviewMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      createReviewMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      createReviewMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
  
      runEngine.sendMessage(createReviewMessage.id, createReviewMessage);
    }

    delReply = async (id:string) => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        "Content-type": configJSON.categoryApiContentType,
        token : token
      };
      const delMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.delReplyCallId = delMessage.messageId;

      delMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.delReplyAPIEndpoint + `${id}`
      );
      delMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      delMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );

      runEngine.sendMessage(delMessage.id, delMessage);
    }

    createReplyReviews = async (replyText:string, id:string) => {
      this.setState({isOpen : false})
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        "Content-type": configJSON.categoryApiContentType,
        token : token
      };

      const httpBody = {
        "description": replyText,
        "review_id": id
      }
      const replyMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createReplyCallId = replyMessage.messageId;

      replyMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createReplyAPIEndpoint
      );
      replyMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      replyMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      replyMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(replyMessage.id, replyMessage);
    }

    editReplyReviews = async (replyText:string, id:string) => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        "Content-type": configJSON.categoryApiContentType,
        token : token
      };

      const httpBody = {
        "description": replyText
      }
      const editSaveReplyMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.saveEditReplyCallId = editSaveReplyMessage.messageId;

      editSaveReplyMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.delReplyAPIEndpoint + `${id}`
      );
      editSaveReplyMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      editSaveReplyMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      editSaveReplyMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutType
      );

      runEngine.sendMessage(editSaveReplyMessage.id, editSaveReplyMessage);
    }

    setFavoriteCourse = async () => {
      this.setState({ isCourseFav: true })
      const token = await StorageProvider.get(configJSON.authToken)
  
      const headers = {
        "Content-Type": configJSON.categoryApiContentType,
        token
      }
  
      const httpBody = {
        favouriteable_id: this.state.courseId || "",
        favouriteable_type: configJSON.reviewType
      }
  
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      )
  
      this.setFavouriteCourseApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.addToFavAPIEndpoint
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPatchType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    }

    addToCart = async(id:string) => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        token : token
      };

      const formdata = new FormData()
      formdata.append("cart_item[cartable_id]",id)
      formdata.append("cart_item[cartable_type]", configJSON.reviewType)
      formdata.append("cart_item[cart_id]", this.state.cartId)
      formdata.append("cart_item[quantity]", '1')

      const msg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createPurchaseCallId = msg.messageId;

      msg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addToCartAPIEndpoint
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(msg.id, msg);
    }

    getCartDetails = async() => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        token : token
      };

      const msg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCartDetailsCallId = msg.messageId;

      msg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.cartDetailAPIEndpoint
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(msg.id, msg);
    }

    createOrderForFreeCourse = async() => {
      const token = await StorageProvider.get(configJSON.authToken);
      const header = {
        token : token
      };

      const formdata = new FormData()
      formdata.append("course_id",this.state.courseId)

      const msg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createFreeOrderCallId = msg.messageId;

      msg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createFreeOrderAPIEndpoint
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      msg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(msg.id, msg);
    }

    format = (sec: number) => {
      if (sec >= 3600) {
        // Convert to hours
        const hour:any = (sec / 3600).toFixed(1);
        const roundedHours = parseFloat(hour) === Math.floor(hour) ? Math.floor(hour) : hour;
        const hours =  `${roundedHours} Hour${roundedHours > 1 ? 's' : ''}`;
        this.setState({hours : hours})
      } else if (sec >= 60) {
        // Convert to minutes
        const mins =  `${Math.round(sec / 60)} Min${Math.round(sec / 60) > 1 ? 's' : ''}`;
        this.setState({mins : mins})
      }
      else if(sec < 60){
        this.setState({sec : sec})
      }
    }

    handleCreateReviews = async (comment:string, rating:any, id: string) => {
      const isUserLoggedIn = !!await StorageProvider.get(configJSON.authToken)
      if(isUserLoggedIn) {
        if(rating < 0.5){
          toast.error(configJSON.addRatingMsg)
        }
        if(comment.length === 0){
          toast.error(configJSON.writeCommentMsg)
        }
        else if(rating >= 0.5 && comment.length > 0){
          await this.createCourseReviews(comment,rating,id)
        }
      }
      else{
        this.openLoginDialog()
      }
    }

    handleReplyBtn = async (id:string) => {
      const isUserLoggedIn = !!await StorageProvider.get(configJSON.authToken) 
      if(!isUserLoggedIn) {
        this.openLoginDialog()
      }else {
        this.setState({ isOpen: !this.state.isOpen, commentId: id, reply : '' })
      }   
      this.setState({reply : ''})
    }

    handleDelReply = async (id:string) => {
      const isUserLoggedIn = !!await StorageProvider.get(configJSON.authToken) 
      if(!isUserLoggedIn) {
        this.openLoginDialog()
      }
      else{
        await this.delReply(id)
      }
    }

    handleSaveReply = async (reply:string, id:string) => {
      const isUserLoggedIn = !!await StorageProvider.get(configJSON.authToken) 
      if(!isUserLoggedIn) {
        this.openLoginDialog()
      }
      else{
        await this.createReplyReviews(reply,id)
      }
    }

    handleSaveEditReply = async (text:string, id:string, reviewId:string) => {
      const isUserLoggedIn = !!await StorageProvider.get(configJSON.authToken) 
      if(!isUserLoggedIn) {
        this.openLoginDialog()
      }
      else{
        this.setState({isEditSave : true})
        await this.editReplyReviews(text,id)
      }
    }

    handleKnowMore = () => {
      this.setState({hideBiography : !this.state.hideBiography})
    }

    handleFavourite = async() => {
      const isLoggedIn = !!await StorageProvider.get(configJSON.authToken)
      if(!isLoggedIn) {
        this.openLoginDialog()
        return
      }
      await this.setFavoriteCourse()
    }

    openLoginDialog = () => {
      this.setState({ isLoginDialog: true })
    }
  
    closeLoginDialog = () => {
      this.setState({ isLoginDialog: false })
    }

    handleLoginSuccess = async() => {
      await this.getCourseDetails(this.state.courseId)
    }

    handleEnrollBtn = async(price:number,detail:any) => {
      const isLoggedIn = !!await StorageProvider.get(configJSON.authToken)
      if(!isLoggedIn){
        this.openLoginDialog()
      }
      else if(isLoggedIn && price <= 0 ){
        await this.createOrderForFreeCourse()
      }
      else if(isLoggedIn && price > 0 && !this.state.itemAddedToCart){
        await this.addToCart(detail?.id)
      }
      else if(isLoggedIn && price > 0 && this.state.itemAddedToCart){
        toast.error(configJSON.courseAlreadyAddedMsg)
      }
    }

    handleGoToCourse = async(id:string) => {
      this.props.history.push(configJSON.enrolledCoursePath + id , {hours:this.state.hh, mins:this.state.mins, enrolledStudents : this.state.enrolledStudents, sec:this.state.sec})
    }

    handleOptions = (event:any) => {
      this.setState({ anchorEl: event?.currentTarget });
    };

    handleCloseOptions = () => {
      this.setState({ anchorEl: null });
    };

    handleModalClose = () => {
      this.setState({modalOpen : false})
    }

    handleShare = () => {
      this.setState({modalOpen : true, anchorEl: null})
    }

    handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      this.setState({copy : true})
      setTimeout(() => this.setState({ copy: false, modalOpen: false }), 1500);
    } catch (err) {
      toast.error(configJSON.linkNotCopiedMsg)
    }
    this.handleCloseOptions();
  }

  calculateStudents = (enrolledStudents: number) => {
    if(enrolledStudents > 1){
      this.setState({enrolledStudents: `${enrolledStudents-1 + '+'} Students`})
    }
    else{
      this.setState({enrolledStudents: `${enrolledStudents} Student`})
    } 
  }

  biography = (hideBio: boolean) => hideBio ? `${this.state.courseDetail.attributes.biography?.slice(0, 500)}...` : this.state.courseDetail?.attributes?.biography
  copyText = (copy : boolean) => copy && configJSON.linkCopiedText 

  handleBackIcon = () => {
    window.history?.back()
  }
}

// Customizable Area End