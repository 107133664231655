import {baseURL} from "../../../../framework/src/config"
import StorageProvider from "framework/src/StorageProvider";
const configJSON = require("../../config.js")

export const getRestaurantsList = async (payload) => {
    try {
        const {
            page,
            count,
            filterByCategoryName,
            filterByTagName,
            searchText,
            latitude,
            longitude,
            filterType
        } = payload
        let {
            startPrice,
            endPrice
        } = payload
        if(startPrice === 0 && endPrice === 0) {
            startPrice = ""
            endPrice = ""
        }

        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        let headers = {
          "Content-Type": "application/json"
        }
        if(token) {
          headers = {
            ...headers,
            token
          }
        }
      const response = await fetch(
        `${baseURL}/bx_block_catalogue/restaurants?page=${page}&per=${count}&filter_by_category_name=${
          filterByCategoryName || ""
        }&filter_by_tag_name=${filterByTagName || ""}&filter_start_price=${
          startPrice
        }&filter_end_price=${endPrice}&latitude=${
          latitude || ""
        }&longitude=${
          longitude || ""
        }&search=${searchText}&price_sorting=${filterType}`,
        {
          method: "GET",
          headers: headers
        }
      )
      return response.json()
    } catch (e) {
      return e
    }
}

export const searchRestaurants = async (restaurantName) => {
    try {
        const response = await fetch(
            `${baseURL}/search?name=${restaurantName}`
            ,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            },
        });
    return response.json()
    }catch (e) {
        return e
    }
}

export const getPopularCategories = async () => {
    try {
        const response = await fetch(`${baseURL}/categories/categories`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getCategories = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/categories?category_type=restaurant`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getTags = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/tags?tags_type=restaurant`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getRestaurantsDetails = async (id) => {
    try {
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const response = await fetch(`${baseURL}/bx_block_catalogue/restaurants/${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
                token: token || ""
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getRestaurantsCatalogue = async (id) => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/farm_catalogues?farm_id=${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const addRatingRestaurants = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/catalogue/reviews`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                token:Token || ""
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const addToFavoriteRestaurants = async (data) => {
    const Token = await StorageProvider.get("authToken");
    try {
        const response = await fetch(`${baseURL}/bx_block_favourites/favourites/favourites`,{
            method:'PATCH',
            headers:{
                'Content-Type': 'application/json',
                token:Token || ""
            },
            body:JSON.stringify(data)
        });
        return response.json()
    }catch (e) {
        return e
    }
}

export const getResMinMaxPrices = async () => {
    try {
        const response = await fetch(`${baseURL}/bx_block_catalogue/min_max_price?type=Restaurant`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json',
            },
        });
        return response.json()
    } catch (e) {
        return e
    }
}
