// Customizable Area Start
import { CommunityEnum, CommunityFilterEnum } from "./communityEnum"

const headerInitialState = {
    searchDialog: false
}

const homePageInitialState = {
    communityLatestForumsDesc: "",
    communityLatestForums: [],
    communityExpertDesc: "",
    communityExperts: []
}

const discussionPageInitialState = {
    discussionFilter: CommunityFilterEnum.ALL,
    discussionTopics: [],
    activeTopicId: "",
    discussions: [],
    tagFilterDiscussions: []
}

const ExpertsPageInitialState = {
    expertsList: []
}

const initialState = {
    ...headerInitialState,
    ...homePageInitialState,
    ...discussionPageInitialState,
    ...ExpertsPageInitialState,
}

export const communityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CommunityEnum.SET_COMMUNITY_SEARCH_DIALOG:
            return {
                ...state,
                searchDialog: payload
            }
        case CommunityEnum.SET_COMMUNITY_HOME_DESC:
            const {
                latest_forums_description,
                our_expert_description,
                our_experts
            } = payload
            return {
                ...state,
                communityLatestForumsDesc: latest_forums_description || "",
                communityExpertDesc: our_expert_description || "",
                communityExperts: our_experts?.data || []
            }
        case CommunityEnum.SET_COMMUNITY_LATEST_FORUMS:
            return {
                ...state,
                communityLatestForums: payload
            }
        case CommunityEnum.SET_COMMUNITY_DISCUSSION_FILTER:
            return {
                ...state,
                discussionFilter: payload
            }
        case CommunityEnum.SET_COMMUNITY_DISCUSSION_TOPICS:
            const topicId = payload?.length > 0 ? payload[0].id : ""

            return {
                ...state,
                discussionTopics: payload,
                activeTopicId: topicId
            }
        case CommunityEnum.SET_COMMUNITY_ACTIVE_TOPIC:
            return {
                ...state,
                activeTopicId: payload
            }
        case CommunityEnum.SET_COMMUNITY_DISCUSSIONS:
            return {
                ...state,
                discussions: payload
            }
        case CommunityEnum.SET_COMMUNITY_TAG_FILTER_DISCUSSIONS:
            return {
                ...state,
                tagFilterDiscussions: payload
            }
        case CommunityEnum.SET_COMMUNITY_EXPERTS_LIST:
            return {
                ...state,
                expertsList: payload
            }
        default:
            return state
    }
}
// Customizable Area End
