import React, { Component } from "react";
import {
  Grid,
  Container,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { yellow_edit_icon, trash_red_icon } from "../../assets";

class CheckOutDeliveryCard extends Component {
  render() {
    return (
      <Box className="check_out_delivery_card">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={10} lg={9} xl={9}>
            <Box>
              <h6>John Doe</h6>
              <p>company name</p>
              <p>1234 Marqis View,Colardo,2022,United States</p>
              <p>+12345 6789 342</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
            <Box className="check_out_delivery_card_actions_container">
              <div>
                <img src={yellow_edit_icon} />
              </div>
              <div>
                <Radio
                  // checked={this.state.radioCheck == "a"}
                  // onChange={(e) => this.setState(e.target.value)}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default CheckOutDeliveryCard;
