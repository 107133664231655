// Customizable Area Start
import React from "react";
import "../../../../web/src/assets/css/style.scss";
import OrderDetailsDialogController, {
  Props,
} from "./OrderDetailsDialogController.web";
import "../../assets/css/orderDetailsDialog.scss";
import {
  Dialog,
} from "@material-ui/core";
import OrderDetailsDialogContent from "./OrderDetailsDialogContent.web";
// Customizable Area End

class OrderDetailsDialog extends OrderDetailsDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start

    const { isOpen, handleClose } = this.props
    const { orderDetails, orderDetailsLoader } = this.state
    const {
      type,
      order_date,
      order_id,
      order_total,
      payment_information,
      billing_address,
      order_summary,
      shipping_address,
    } = orderDetails.attributes;

    const { payment_method, price } = payment_information
    const {
      address_line1,
      address_line2,
      city,
      country,
      full_name,
      number,
      state,
    } = billing_address;
    const billingAddress = this.getCombineAddress([address_line1, address_line2, city, state, country])

    const {
      address_line1: sAddressLine1,
      address_line2: sAddressLine2,
      city: sCity,
      country: sCountry,
      full_name: sFullName,
      state: sState,
    } = shipping_address;

    const {
      items,
      discount,
      loyalty_points,
      sub_total,
      shipping,
      transaction_fees,
      total,
    } = order_summary

    return (
      <Dialog
        open={isOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="sm"
        classes={{
          paper: "custom_dialog_paper",
        }}
      >
        <OrderDetailsDialogContent
          type={type}
          orderDetailsLoader={orderDetailsLoader}
          orderDate={order_date}
          orderId={order_id}
          orderTotal={order_total}
          paymentMethod={payment_method}
          price={price}
          fullName={full_name}
          billingAddress={billingAddress}
          number={number}
          sFullName={sFullName}
          sAddressLine1={sAddressLine1}
          sAddressLine2={sAddressLine2}
          sCity={sCity}
          sState={sState}
          sCountry={sCountry}
          items={items}
          discount={discount}
          loyaltyPoints={loyalty_points}
          subTotal={sub_total}
          shipping={shipping}
          transactionFees={transaction_fees}
          total={total}
          handleClose={handleClose}
        />
      </Dialog>
    )
    // Customizable Area End
  }
}

export default OrderDetailsDialog;
