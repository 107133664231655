import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper";
// Customizable Area End

export interface Props extends RouteComponentProps {
    location: any
    checkoutOrder: any
    isShippingLoading: boolean
    setCheckoutOrder: (order: any) => void
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PickUpPaymentController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && !responseJson.error) {
              console.log("response", responseJson)
            } else {
                if(isTokenExpireResponse(responseJson)) return

                if (responseJson?.errors) {
                    responseJson.errors?.forEach((err: any) => {
                        toast.error(err?.message);
                    });
                }
            }
        }
        // Customizable Area End
    }
}
