//Customizable Area Start
import React from "react";
// common css
import "../../../web/src/assets/css/style.scss";
// custom components
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";

import { withRouter } from "react-router-dom";

// icons
import SearchIcon from "@material-ui/icons/Search";

//charts
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputLabel,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";

import AdminFarmCampaignsController, {
  Props,
} from "./AdminFarmCampaignsController.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomBorderLinearProgressbar from "../../../components/src/CustomBorderLinearProgressbar.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";

interface EnhancedTableProps {
  numSelected?: number;

  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
            data-test-id="table-head"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={34}
            />
            <span>CAMPAIGNS NAME</span>
          </div>
        </TableCell>
        <TableCell align="center"> STATUS</TableCell>
        <TableCell align="center"> CAMPAIGNS ID</TableCell>
        <TableCell> RAISED DONATION</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

class AdminFarmCampaigns extends AdminFarmCampaignsController {
  constructor(props: Props) {
    super(props);
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = this.state.campaignList.map((n: any) => n.id);
      this.setState({ seletedCampaigns: newSelected });
    } else {
      this.setState({ seletedCampaigns: [] });
    }
  };
  handleChangeTablePage = (value: number) => {
    this.setState({ campaignsCurrentPage: value });
    this.getCampaignList(this.state.search, this.state.campaignsPerPage, value);
  };

  handleIncreasePageView = () => {
    if (this.state.campaignsPerPage < 25) {
      this.setState({
        campaignsPerPage: this.state.campaignsPerPage + 5,
        campaignsCurrentPage: 1,
      });
      this.getCampaignList(
        this.state.search,
        this.state.campaignsPerPage + 5,
        1
      );
    }
  };

  handleDecreasePageView = () => {
    if (this.state.campaignsPerPage > 5) {
      this.setState({
        campaignsPerPage: this.state.campaignsPerPage - 5,
        campaignsCurrentPage: 1,
      });
      this.getCampaignList(
        this.state.search,
        this.state.campaignsPerPage - 5,
        1
      );
    }
  };
  render() {
    const indexOfLastList =
      this.state.campaignsCurrentPage * this.state.campaignsPerPage;
    const indexOfFirstList = indexOfLastList - this.state.campaignsPerPage;
    const cureentTableList = this.state.campaignList
      ? this.state.campaignList
      : "";

    const roundPages: any =
      cureentTableList &&
      Math.floor(this.state.total_count / this.state.campaignsPerPage);
    let totalListCount;
    if (this.state.campaignsCurrentPage > roundPages) {
      totalListCount = this.state.total_count;
    } else {
      totalListCount = indexOfLastList;
    }

    const { classes } = this.props;
    const menu = ["edit", "remove"];
    const farmStatus = ["active", "draft"];

    return (
      <>
        {/* main card container */}
        <div className="tableContainer">
          <Loader loading={this.state.loading} />

          {/* filter section */}
          <div className="headerListContainer">
            <div className="prodListHeader campaignListHeader">
              <div className="blocks">
                <h4>Campaigns</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div
                    className="addButton"
                    onClick={() => {
                      this.props.history.push(
                        `/admin/farm/add-campaigns?mode=create`
                      );
                    }}
                  >
                    <CustomButton label="Create Campaign" />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                  id="input-with-icon-textfield"
                  value={this.state.search}
                  onChange={async (e: any) => {
                    this.setState({
                      search: e.target.value,
                    });
                    this.SearchDebounceUpdate(
                      e.target.value,
                      this.state.campaignsPerPage,
                      1
                    );
                  }}
                  variant="standard"
                  placeholder="Search by Name, type, Id, etc... "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect itemStatus dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Actions
                      </InputLabel>
                      <Select
                      data-test-id="actions"
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="menu-customize"
                        value={this.state.bulkAction}
                        disabled={this.state.seletedCampaigns.length < 2}
                        onChange={(e: any) => {
                          this.bulkActions(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.itemStatus,
                          },
                        }}
                      >
                        {farmStatus.map((status: any, index: any) => {
                          return (
                            <MenuItem value={status} key={status}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block commonDropdown  campTable">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                {cureentTableList && cureentTableList.length > 0 && (
                  <EnhancedTableHead
                    data-test-id='table'
                    numSelected={this.state.seletedCampaigns.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={cureentTableList && cureentTableList.length}
                  />
                )}
                <TableBody>
                  {cureentTableList && cureentTableList.length > 0 ? (
                    cureentTableList.map((item: any, index: any) => {
                      const isItemSelected = this.isSelectedCampaigns(
                        item.id
                      );
                      return (
                        <TableRow key={item.id}>
                          <TableCell scope="row">
                            <div
                              className="productCell"
                              onClick={(event) =>
                                this.handleClickCampaignsRow(event, item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={item.attributes.banner.url}
                                className="prodImage"
                                alt="img"
                              />
                              <p>{item.attributes.heading}</p>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div
                              className={`customSelect itemStatus ${item
                                .attributes.status == "draft" &&
                                "warning-variant"}`}
                            >
                              <FormControl>
                                <Select
                                data-test-id="selectStatus"
                                  labelId="demo-controlled-open-select-label"
                                  id="demo-controlled-open-select"
                                  value={item.attributes.status}
                                  onChange={(e: any) => {
                                    this.updateForumStatus(
                                      item.id,
                                      e.target.value
                                    );
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {farmStatus.map(
                                    (status: any, index: any) => {
                                      return (
                                        <MenuItem
                                          value={status}
                                          key={status}
                                          className={` dish_list_status_menus ${this.getMenuStyleCss(status)}`}
                                        >
                                          {status}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            {item.attributes.campaign_id}
                          </TableCell>

                          <TableCell align="center">
                            <div className="fundProgressBar">
                              <div className="fundScale">
                                <span>0</span>
                                <span>
                                  $
                                  {parseInt(
                                    //@ts-ignore
                                    item.attributes.required_donation / 2
                                  )}
                                </span>
                                <span>
                                  ${item.attributes.required_donation}
                                </span>
                              </div>
                              <CustomBorderLinearProgressbar
                                value={item.attributes.donation_raised}
                                max={item.attributes.required_donation}
                              />
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                                item={item}
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0" }}
                      >
                        <CustomNoRecordsFound
                          routeUrl={`/admin/farm/add-campaigns?mode=create`}
                          title="Campaign"
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {cureentTableList?.length > 0 && (
              <div className="paginationRow restOrderPagination">
                <div>
                  {indexOfFirstList + 1}- {totalListCount} of{" "}
                  {this.state.total_count} items
                </div>
                <CustomPagination
                data-test-id="pagination"
                  count={this.state.total_pages}
                  page={this.state.campaignsCurrentPage}
                  pagePerView={this.state.campaignsPerPage}
                  handlePageChange={this.handleChangeTablePage}
                  shape="rounded"
                  siblingCount={1}
                  increasePagePerView={this.handleIncreasePageView}
                  decreasePagePerView={this.handleDecreasePageView}
                  boundaryCount={1}
                />
              </div>
            )}
          </div>

          {/* remove modal */}
          <div>
            <Dialog
              open={this.state.openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete the campaign ?
              </DialogTitle>
              <DialogActions>
                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    onClick={() => this.handleDialog()}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-test-id="yesBtn"
                    color="secondary"
                    label="Yes"
                    onClick={() => {
                      this.handleDialog();
                      this.deleteForum();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    );
  }
}
//@ts-ignore
export {AdminFarmCampaigns};
export default withRouter(withStyles(commonCmptStyles)(AdminFarmCampaigns));
//Customizable Area End
