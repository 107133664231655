// Customizable Area Start
import React from "react"
import { Box, IconButton, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowBackIcon from "@material-ui/icons/ArrowBackIosRounded"
import { withRouter, RouteComponentProps } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    padding: "10px 50px",
    backgroundColor: "#f6faf7",
    display: "flex",
    alignItems: "center",
    gap: "30px",
    '@media (max-width: 480px)': {
      padding: "10px 20px",
      gap: "15px"
    }
  },
  backBtn: {
    color: '#000',
    '@media (max-width: 450px)': {
      fontSize: '16px'
    }
  },
  navigationText: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '22px',
    fontWeight: 500,
    color: '#000',
    wordBreak: "break-word",
    '@media (max-width: 450px)': {
      fontSize: '16px'
    }
  }
}))

interface ICommunityNavigator extends RouteComponentProps {
  navigationText: string
}

const CommunityNavigator = ({ history, navigationText }: ICommunityNavigator) => {
  const classes = useStyles()

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Box className={classes.container}>
      <IconButton onClick={handleBack}>
        <ArrowBackIcon className={classes.backBtn} />
      </IconButton>
      <Typography className={classes.navigationText}>{navigationText}</Typography>
    </Box>
  )
}

export default withRouter(CommunityNavigator)
// Customizable Area End
