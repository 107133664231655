// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
import React from "react"
export const configJSON = require("../../config.js")

export interface IDiscussion {
  id: string
  type: string
  attributes: {
    account_id: number
    author_name: string
    date: string
    topic_id: number
    description: string
    discussion_heading: string
    comment_count: number
    forum_comments: any[]
    follows: boolean
  }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  discussions: IDiscussion[]
  setCommunityTagFilterDiscussions: (discussions: IDiscussion[]) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSearching: boolean
  isNoResultFound: boolean
  searchText: string
  // Customizable Area End
}

interface SS {
  id: any
}

export default class CommunityDiscussionTagsScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDiscussionByTagsApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      isSearching: false,
      isNoResultFound: false,
      searchText: "",
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    this.props.setCommunityTagFilterDiscussions([])
  }

  searchDebounce = (call: any, delay: number) => {
    let timer: any

    return function (...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  SearchDebounceUpdate = this.searchDebounce(
    () =>
      this.state.searchText
        ? this.searchDiscussionsApi()
        : this.handleEmptySearch(),
    700
  )

  handleEmptySearch = () => {
    this.setState({ isNoResultFound: false })
    this.props.setCommunityTagFilterDiscussions([])
  }

  handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({
      searchText: value,
    })
    this.SearchDebounceUpdate()
  }

  searchDiscussionsApi = async () => {
    this.setState({ isSearching: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const { searchText } = this.state

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getDiscussionByTagsApiCallId = requestMessage.messageId
    const encodedText = encodeURIComponent(`#${searchText}`)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchDiscussionsApiEndPoint}${encodedText}&page=1&per=2`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleErrorApiResponse = async (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getDiscussionByTagsApiCallId) {
      if (await isTokenExpired(responseJson)) return

      this.setState({ isSearching: false, isNoResultFound: true })
      toast.error("Something went wrong")
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.getDiscussionByTagsApiCallId) {
          if (responseJson?.data) {
            const { setCommunityTagFilterDiscussions } = this.props
            setCommunityTagFilterDiscussions(responseJson.data)
            this.setState({ isSearching: false, isNoResultFound: responseJson.data?.length === 0 })
            return
          }
          toast.error("Something went wrong")
          this.setState({ isSearching: false, isNoResultFound: true })
        }
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }
}
