import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format,startOfWeek, endOfWeek } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { IMonthReport, IOrderCount, IOrderDetails, IRestaurantList, ITodayReport, IWeeklyReport } from "../../../components/src/interfaces/adminRestaurant";
export const configJSON = require("./config");
import { RouteComponentProps } from "react-router-dom";
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
    navigation: any;
    id: string;
    location: any;
    history: any;
    classes: {
      farmOrdersDropDown : string
    };
    handleTabChange?: () => void;
    logout : () => void;
    // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean
  isEdit: boolean
  token: string
  modalOpen: boolean
  restOrderModal: boolean
  restOrderReportModal: boolean
  restaurantID: string
  openDialog: boolean
  activeRestaurant: number | string
  restaurantsList: IRestaurantList[]
  orderList: IOrderDetails[]
  status: string
  search: string
  searchVal: string
  today: string
  startDate: string
  endDate: string
  acceptOrder: boolean
  totalMin: number
  todayReport: ITodayReport[]
  weekReport: IWeeklyReport[]
  monthReport: IMonthReport[]
  orderCount: IOrderCount[]
  dishListLoader: boolean
  deliveryTime: string
  ordersCurrentPage: number
  ordersPerPage: number
  totalOrderCount: number
  totalOrderPage: number
  currentWeek : string
  currentMonth : string
  isRestChanged: boolean
  isOrderCancelApproveDialog: boolean
  isOrderCancelRejectDialog: boolean
  isUserAdminCancelling: boolean
  cancellationOrderId: string
  isInitiateRefund: boolean
  isOrderDetailsDialog: boolean
  orderDetails: any
  // Customizable Area End
}

interface SS {
    id: any;
}

export default class AdminRestaurantOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRestaurantApiCallId: string = "";
  timer: any;
  getRestOrdersApiCallId: string = "";
  putAcceptOrderApiCallId: string = "";
  putOrderReadyApiCallId: string = "";
  todaysReportApiCallId: string = "";
  weekReportApiCallId: string = "";
  monthReportApiCallId: string = "";
  putDeliveryTimeApiCallId : string = "";
  putPickOrderAPICallId : string = "";
  putAcceptingOrdersApiCallId : string = "";
  getAcceptingOrderStatApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      modalOpen: false,
      restOrderModal: false,
      restOrderReportModal: false,
      loading: false,
      restaurantID: "",
      openDialog: false,
      isEdit: false,
      activeRestaurant: 0 || '',
      restaurantsList: [],
      orderList: [],
      status: configJSON.allText,
      search: "",
      searchVal: "",
      today: "",
      startDate: '',
      endDate: '',
      acceptOrder: false,
      totalMin: 0,
      todayReport: [],
      weekReport: [],
      monthReport: [],
      orderCount: [],
      dishListLoader : false,
      deliveryTime : '',
      ordersCurrentPage : 1,
      ordersPerPage : 5,
      totalOrderCount : 0,
      totalOrderPage : 0,
      currentWeek : '',
      currentMonth : '',
      isRestChanged: false,
      isOrderCancelApproveDialog: false,
      isOrderCancelRejectDialog: false,
      isUserAdminCancelling: false,
      cancellationOrderId: "",
      isInitiateRefund: false,
      isOrderDetailsDialog: false,
      orderDetails: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
}

// Customizable Area Start
  async componentDidMount() {
    this.getAllRestaurants()
  }

  async componentDidUpdate(_: any, prevState: S) {
    const {
      activeRestaurant,
      ordersCurrentPage,
      ordersPerPage,
      searchVal,
      status,
      today
    } = this.state

    if (
      prevState.activeRestaurant !== activeRestaurant ||
      prevState.ordersCurrentPage !== ordersCurrentPage ||
      prevState.ordersPerPage !== ordersPerPage ||
      prevState.searchVal !== searchVal
    ) {
      this.setState({isRestChanged: true})
      this.getRestaurantOrders(status, today)
    }

    if (prevState.activeRestaurant !== activeRestaurant) {
      this.getAcceptingOrder()
    }
  }

  handleGetOrders = () => {
    const {
      status,
      today
    } = this.state
    this.getRestaurantOrders(status, today)
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      }
      else if (responseJson?.errors) {
        this.getErrors(apiRequestCallId, responseJson)
        this.setState({
          loading:false
        })
        if(responseJson?.errors[0] && (responseJson?.errors[0].message.includes([configJSON.tokenExpiredText,configJSON.invalidTokenText]))) {
           this.props.logout()
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getErrors = (apiRequestCallId: string, responseJson:any) => {
    if((apiRequestCallId !== this.todaysReportApiCallId) && (apiRequestCallId !== this.weekReportApiCallId) && (apiRequestCallId !== this.monthReportApiCallId)){
      toast.error(responseJson?.errors[0].message)
    }
  }

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getRestOrdersApiCallId) {
      const {
        total_count,
        total_pages
      } = responseJson?.meta?.pagination
      this.setState({
        orderList: responseJson.orders.data,
        orderCount: responseJson.order_count,
        totalOrderCount: total_count,
        totalOrderPage: total_pages
      })

    }

    //get Accepting order
    if (apiRequestCallId === this.getAcceptingOrderStatApiCallId) {
      this.setState({ acceptOrder: responseJson.order_accepted })
    }

    //set delivery time for orders
    if (apiRequestCallId === this.putDeliveryTimeApiCallId) {
      this.setState({
        loading: false,
        deliveryTime: responseJson.data.attributes.set_delivery_time
      })
    }

    //Accept Order
    if (apiRequestCallId === this.putAcceptOrderApiCallId) {
      this.getRestaurantOrders(this.state.status, this.state.today)
    }

    //Today Report
    if (apiRequestCallId === this.todaysReportApiCallId) {
      this.setState({ todayReport: responseJson })
    }

    //Week Report
    if (apiRequestCallId === this.weekReportApiCallId) {
      this.setState({ weekReport: responseJson })
    }

    //Month Report
    if (apiRequestCallId === this.monthReportApiCallId) {
      this.setState({ monthReport: responseJson })
    }

    if (apiRequestCallId === this.getAllRestaurantApiCallId) {
      if(responseJson.data.length > 0){
        this.setState({
          activeRestaurant: responseJson.data[0].attributes.id,
          restaurantsList: responseJson.data,
          dishListLoader: false,
        })
      }
    }
  }

  showOrdersReports = () => {
    this.handleRestOrderReportsOpenModal()
    this.reports(this.state.activeRestaurant)
  }

  resetFilters = () => {
    this.setState({
      ordersCurrentPage: 1,
      ordersPerPage: 5,
      search: '',
      searchVal: '',
      startDate: '',
      endDate: '',
      today: ''
    })
  }

  handleSearchOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({
        searchVal: value,
        ordersCurrentPage: 1
      })
    }, 1000)
  }

  handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({ search: value })
  }

  handleRestOrderOpenModal = () => {
    this.setState({ restOrderModal: true });
  };
  handleRestOrderCloseModal = () => {
    this.setState({ restOrderModal: false });
  };

  //get rest orders
  getRestaurantOrders = async (stat: any, date: any) => {
    this.setState({
      loading: true,
    })

    const { search, ordersCurrentPage, ordersPerPage } = this.state
    const endpoint = date ? `product[product_id]=${this.state.activeRestaurant}&page=${ordersCurrentPage}&per=${ordersPerPage}&product[product_type]=BxBlockCatalogue::Restaurant&status=${stat}&search=${search}&current_date=${date}` : 
    `product[product_id]=${this.state.activeRestaurant}&page=${ordersCurrentPage}&per=${ordersPerPage}&product[product_type]=BxBlockCatalogue::Restaurant&status=${stat}&search=${search}&start_date=${this.state.startDate}&end_date=${this.state.endDate}`

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.getRestOrdersApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRestOrderApiEndPoint + `?${endpoint}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getAllRestaurants = async () => {
    !this.state.dishListLoader &&
      this.setState({
        dishListLoader: true,
      })

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN),
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.getAllRestaurantApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  putAcceptOrder = async (id: any, stat: any) => {
    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const form = new FormData()
    form.append("order[id]", id)
    form.append("order[status]", stat)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.putAcceptOrderApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptRejectOrderAPIEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), form)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  setDeliveryTime = async (min: any, id: any) => {
    this.setState({
      loading: true,
    })

    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const form = new FormData()
    form.append("order[set_delivery_time]", min)
    form.append("order[id]", id)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.putDeliveryTimeApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setDeliveryTimeAPIEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), form)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  putOrderReady = async (id: any) => {
    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const form = new FormData()
    form.append("order[id]", id)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.putOrderReadyApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderyReadyAPIEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), form)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.getRestaurantOrders(this.state.status, this.state.today)
    return true
  }

  putPickOrder = async (id: any) => {
    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const header = {
      token: token,
    }

    const form = new FormData()
    form.append("order[id]", id)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.putPickOrderAPICallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pickupOrderAPIEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), form)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  putOrderDeliver = async (id:any) => {
    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const header = {
      token: token,
    }

    const form = new FormData()
    form.append("order[id]", id)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.putPickOrderAPICallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderDeliveredAPIEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), form)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getAcceptingOrder = async () => {
    const endpoint = `user_admin/orders/accepting_order?product[product_id]=${this.state.activeRestaurant}&product[product_type]=BxBlockCatalogue::Restaurant`

    const headers = {
      token: await StorageProvider.get(configJSON.AUTH_TOKEN),
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.getAcceptingOrderStatApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  putAcceptingOrders = async (stat: any) => {
    this.setState({ acceptOrder: !this.state.acceptOrder })

    const endpoint = `?product[product_id]=${this.state.activeRestaurant}&product[product_type]=BxBlockCatalogue::Restaurant&product[accepting_order]=${stat}`

    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const header = {
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.putAcceptingOrdersApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptingOrderAPIEndpoint + endpoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )
    runEngine.sendMessage(requestMessage.messageId, requestMessage)
    return true
  }

  reportsToday = async (id: any) => {
    const endpoint = `?product[product_id]=${id}&product[product_type]=BxBlockCatalogue::Restaurant`

    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.todaysReportApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.todaysReportAPIEndpoint + endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  reportsWeek = async (id: any) => {
    const endpoint = `?product[product_id]=${id}&product[product_type]=BxBlockCatalogue::Restaurant`

    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.weekReportApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.weeklyReportAPIEndpoint + endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  reportsMonth = async (id: any) => {
    const endpoint = `?product[product_id]=${id}&product[product_type]=BxBlockCatalogue::Restaurant`

    const token = await localStorage.getItem(configJSON.AUTH_TOKEN)

    const headers = {
      token: token,
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.monthReportApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.monthlyReportAPIEndpoint + endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  handleStatus = (status: any) => {
    let value = configJSON.allText
    if (status == 1) {
      value = configJSON.placedText
    } else if (status == 2) {
      value = configJSON.preparingText
    } else if (status == 3) {
      value = configJSON.readyText
    } else if (status == 4) {
      value = configJSON.pickedUpText
    }else if (status == 5) {
      value = configJSON.deliveredText
    }else if (status == 6) {
      value = configJSON.rejectedText
    }
    this.setState({
      status: value,
    })
    this.getRestaurantOrders(value, this.state.today)
  }

  handleSetToday = () => {
    const date = `${new Date().getDate()}/${new Date().getMonth() + 1
      }/${new Date().getFullYear()}`

    switch (this.state.today) {
      case "":
        this.setState({ today: date, startDate:'', endDate:'' })
        this.getRestaurantOrders(this.state.status, date)
        break
      case `${date}`:
        this.setState({ today: "" })
        this.getRestaurantOrders(this.state.status, "")
        break
    }
  }

  handleSetRange = (val: any) => {
    const start = val.start.format(configJSON.dateFormat)
    const end = val.end.format(configJSON.dateFormat)
    this.setState({
      startDate: start,
      endDate: end,
      today: ''
    })
    this.getRestaurantOrders(this.state.status, this.state.today)
  }

  handleOrderTime = (time: any, id: any) => {
    this.setState((prev: any) => ({ totalMin: prev.totalMin + time }))
    this.setDeliveryTime(time, id)
  }

  reports = (id: any) => {
    this.getWeekMonth()
    this.reportsToday(id)
    this.reportsWeek(id)
    this.reportsMonth(id)
  }

  getWeekMonth  = () => {
    const startOfCurrentWeek = startOfWeek(new Date(), {weekStartsOn : 1})
    const endOfCurrentWeek = endOfWeek(new Date(), {weekStartsOn : 1})
    
    const month = format(new Date(), configJSON.monthFormat)
    const startDate = format(startOfCurrentWeek, configJSON.dateMonFormat,{locale : enUS});
    const endDate = format(endOfCurrentWeek, configJSON.dateMonYearFormat,{locale : enUS});
    const currentWeek = `${startDate} - ${endDate}`
    this.setState({currentWeek : currentWeek, currentMonth : month})
  }

  handleChangeActiveRestaurant = (event: any) => {
    const value = event.target.value
    this.setState({
      activeRestaurant: value
    })
    this.resetFilters()
  }

  handleAcceptingOrderStat = (stat: any) => {
    this.setState({ acceptOrder: !this.state.acceptOrder })
    this.putAcceptingOrders(stat)
  }

  handleChangeTablePage = (value: number) => {
    this.setState({ ordersCurrentPage: value })
  }

  handleIncreasePageView = () => {
    if (this.state.ordersPerPage < 25) {
      this.setState({ ordersPerPage: this.state.ordersPerPage + 5 })
    }
  }

  handleDecreasePageView = () => {
    if (this.state.ordersPerPage > 5) {
      this.setState({ ordersPerPage: this.state.ordersPerPage - 5 })
    }
  }

  handlePagination = () => {
    this.setState({ ordersCurrentPage: 1 })
  }

  handleRestOrderReportsOpenModal = () => {
    this.setState({ restOrderReportModal: true })
  }

  handleRestOrderReportsCloseModal = () => {
    this.setState({ restOrderReportModal: false })
  }

  openOrderCancelApproveModal = (orderId: string, isInitiateRefund: boolean, isByUserAdmin?: boolean) => {
    this.setState({
      isOrderCancelApproveDialog: true,
      cancellationOrderId: orderId,
      isUserAdminCancelling: !!isByUserAdmin,
      isInitiateRefund
    })
  }
  
  closeOrderCancelApproveModal = () => {
    this.setState({
      isOrderCancelApproveDialog: false,
      cancellationOrderId: "",
      isUserAdminCancelling: false,
      isInitiateRefund: false
    })
  }
  
  openOrderCancelRejectModal = (orderId: string) => {
    this.setState({
      cancellationOrderId: orderId,
      isOrderCancelRejectDialog: true,
    })
  }
  
  closeOrderCancelRejectModal = () => {
    this.setState({ cancellationOrderId: "", isOrderCancelRejectDialog: false })
  }

  openOrderDetailsDialog = (orderDetails: any) => {
    this.setState({ isOrderDetailsDialog: true, orderDetails })
  }

  closeOrderDetailsDialog = () => {
    this.setState({ isOrderDetailsDialog: false, orderDetails: null })
  }
// Customizable Area End
}