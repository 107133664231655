// Customizable Area Start
import React from "react";
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminFarmlist.scss";
import { withRouter } from "react-router-dom";
import CustomRestDetailsTab from "../../../components/src/CustomRestDetailsTab.web";
import AdminRestaurantController, {
  Props,
} from "./AdminRestaurantController.web";
// Customizable Area End

class AdminRestaurantDetails extends AdminRestaurantController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    return (
      <CustomRestDetailsTab data-testid="customTab"/>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantDetails}
//@ts-ignore
export default withRouter(AdminRestaurantDetails);
// Customizable Area End