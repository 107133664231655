// Customizable Area Start
import React, { Component } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { DefaultProfileImg } from "./assets";
import "./assets/css/webReviewCard.scss";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";

interface Props {
    profileImg?: string
    name: string
    date: any
    review: string
    rating: any
};

class WebReviewCard extends Component<Props> {
    constructor(props:any) {
        super(props);
    }
    render() {
        return (
            <Box className="review_card_wrapper">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={2}>
                        <Box className="reviewer_img">
                            <img src={this.props.profileImg || DefaultProfileImg} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10} className="review_details">
                        <Box className="reviewer_details">
                            <Typography className="reviewer_name">
                                {this.props.name}
                            </Typography>
                            <Typography className="reviewed_date">
                                {this.props.date}
                            </Typography>
                        </Box>
                        <Box>
                            <Rating
                                className="StyledRating"
                                classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                                name="customized-empty"
                                defaultValue={this.props.rating}
                                precision={0.5}
                                readOnly
                                size="large"
                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                        </Box>
                        <Typography className="grey_text">{this.props.review}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default WebReviewCard;
// Customizable Area End