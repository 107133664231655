// Customizable Area Start
import React from "react"
import { Box, Button, IconButton, Popover } from "@material-ui/core"
import CommunityHeaderController, {
  Props,
} from "./CommunityHeaderController.web"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  BrandLogo,
  DefaultProfile,
  SearchYellow,
  setCartBadge,
  resetUserDetails,
  toggleSidebar,
  setCommunitySearchDialog,
  MenuIcon,
} from "../../assets"
import "../../assets/css/community/communityHeader.scss"
import UserInitialization from "../../../../../components/src/UserInitilization.web"
import SearchDiscussionDialog from "../CommunityCustomComponents/SearchDiscussionDialog.web"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import CustomScreenSize from "../../../../../components/src/CustomScreenSize"
// Customizable Area End

class CommunityHeader extends CommunityHeaderController {
  constructor(props: Props) {
    super(props)
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { firstName, lastName, profileImage, isSearchDialog, toggleSidebar } = this.props
    const { anchorEl } = this.state

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    return (
      <>
        <UserInitialization />
        <Box className="ch_container">
          <Box className="ch_left_container">
            <IconButton
              className="toggle_menu_icon"
              onClick={toggleSidebar}
            >
              <img src={MenuIcon} alt="menu" />
            </IconButton>
            <Box className="ch_logo_wrapper">
              <Link to="/">
                <img src={BrandLogo} alt="Brand Logo" />
              </Link>
            </Box>
          </Box>
          <CustomScreenSize>
            {(screenType: string) => {
              const isMobileScreen = screenType === "mobile"

              return (
                <Box className="ch_btn_container">
                  <Button
                    className="ch_btn"
                    startIcon={
                      isMobileScreen ? null : (
                        <img
                          src={SearchYellow}
                          alt="search"
                          className="ch_btn_image"
                        />
                      )
                    }
                    onClick={this.handleSearchDialogOpen}
                  >
                    {isMobileScreen ? (
                      <img
                        src={SearchYellow}
                        alt="search"
                        className="ch_btn_image"
                      />
                    ) : (
                      "Search"
                    )}
                  </Button>
                  <Button
                    className="ch_btn"
                    aria-describedby={id}
                    onClick={this.handlePopoverOpen}
                    startIcon={
                      isMobileScreen ? null : (
                        <img
                          src={profileImage || DefaultProfile}
                          alt="profile image"
                          className="ch_btn_image"
                        />
                      )
                    }
                  >
                    {isMobileScreen ? (
                      <img
                        src={profileImage || DefaultProfile}
                        alt="profile image"
                        className="ch_btn_image"
                      />
                    ) : (
                      `${firstName} ${lastName}`
                    )}
                  </Button>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box className="ch_account_menu_wrapper">
                      <Box
                        onClick={() =>
                          this.handleMenuRedirect(AppRoutings.Home)
                        }
                      >
                        Home
                      </Box>
                      <Box
                        onClick={() =>
                          this.handleMenuRedirect(AppRoutings.AccountSetting)
                        }
                      >
                        Account Setting
                      </Box>
                      <Box onClick={() => this.handleUserLogout()}>Log out</Box>
                    </Box>
                  </Popover>
                </Box>
              )
            }}
          </CustomScreenSize>
        </Box>
        {isSearchDialog && (
          <SearchDiscussionDialog
            isOpen={isSearchDialog}
            handleClose={this.handleSearchDialogClose}
          />
        )}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    firstName: state.user.userDetails?.first_name || "",
    lastName: state.user.userDetails?.last_name || "",
    profileImage: state.user.userDetails?.profile_pic || "",
    isSearchDialog: state.community.searchDialog,
  }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, {
    setCartBadge,
    toggleSidebar,
    resetUserDetails,
    setCommunitySearchDialog,
  })(CommunityHeader)
)
// Customizable Area End
