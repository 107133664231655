import React from "react";
import "../../../web/src/assets/css/style.scss";
import OrderHistoryCardController, {
  Props
} from "./OrderHistoryCardController.web";
import { Box, Grid } from "@material-ui/core";
import "../assets/css/orderHistory.scss";
import OrderDetailsDialog from "./OrderDetails/OrderDetailsDialog.web";
import OrderTrackingDialog from "./OrderTrackingDialog.web";
import OrderHistorySubCard from "./OrderHistorySubCard.web";

class OrderHistoryCard extends OrderHistoryCardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { orderList } = this.props;
    const {
      orderDetailsDialog,
      orderDetailsId,
      orderTrackingDialog
    } = this.state;
    const currentOrderList =
      orderList.current_order && orderList.current_order.data
        ? orderList.current_order.data
        : [];
    const previousOrderList =
      orderList.previous_order && orderList.previous_order.data
        ? orderList.previous_order.data
        : [];

    return (
      <>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Box className="web_sidebar_link_view_header">
              <h5>Current order</h5>
            </Box>
          </Grid>
          <OrderHistorySubCard
            orderType="current"
            orderList={currentOrderList}
            showOrderDetails={this.openOrderDetailsDialog}
            showOrderTrackingDetails={this.openOrderTrackingDialog}
          />
        </Grid>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Box className="web_sidebar_link_view_header">
              <h5>Previous order</h5>
            </Box>
          </Grid>
          <OrderHistorySubCard
            orderType="previous"
            orderList={previousOrderList}
            showOrderDetails={this.openOrderDetailsDialog}
            showOrderTrackingDetails={this.openOrderTrackingDialog}
          />
        </Grid>

        {orderDetailsDialog && (
          <OrderDetailsDialog
            isOpen={orderDetailsDialog}
            orderDetailsId={orderDetailsId}
            handleClose={() => this.closeOrderDetailsDialog()}
          />
        )}

        {orderTrackingDialog && (
          <OrderTrackingDialog
            isOpen={orderTrackingDialog}
            orderId={orderDetailsId}
            handleClose={() => this.closeOrderTrackingDialog()}
          />
        )}
      </>
    );
  }
}

export default OrderHistoryCard;
