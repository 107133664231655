// Customizable Area Start
import { Box, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import React from "react";
import "../../info-page/src/assets/css/receiptcard.scss";
import { expandArrow } from './assets';
import { makeStyles } from '@material-ui/core/styles';
import Image from "material-ui-image";
import LazyLoad from "react-lazy-load";
// Customizable Area End

//Customizable Area Start
const useStyles = makeStyles({
    long: {
        height: '124px',
        overflowX: 'hidden',
        overflowY : 'scroll',
        position: 'relative',
        textAlign:'justify',
        fontFamily : 'Poppins, sans-serif',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px'
        },
        '&::-webkit-scrollbar-thumb' : {
            backgroundColor: '#e4e4e4'
          },
          '&::-webkit-scrollbar-track' : {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: 'transparent'
          },
          '@media and (max-width: 560px)': {
            '.MuiTypography-root' :{
            fontSize: '12px'
          }}
      },
      content: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
      },
});
//Customizable Area End

//Customizable Area Start
export function LongAnswerTask({ loader,data, longAnswer, handleButtonClick, handleModalClose, isModalOpen }: any) {
    const [longAns, setLongAns] = React.useState('')
    const [words, setWords] = React.useState(0)
    const accountId = localStorage.getItem('cartId')
    return (
        <>
        {data.attributes.title_image &&
            <Grid item xs={12} style={{ display: 'block', marginBottom:'20px' }} className="taskImageContainer">
             <LazyLoad offset={100}>   
            <Image
              style={{position:'unset', paddingTop:'0'}}
              src={data?.attributes?.title_image?.url}
              alt="Title Image"
              imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
              animationDuration={500}
              loading={
                  <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
              }
              />
              </LazyLoad>
              </Grid>
            
            }
            
            <Grid container>
            {loader && !isModalOpen ? <Box className="task_result_loader">
            <CircularProgress size={35} />
          </Box> :
          <>
                {data.attributes.task_results.length === 0 ?
                    <>
                        <TaskLongAnswer data={data} longAns={longAns} setLongAns={setLongAns} longAnswer={longAnswer}
                          loader={loader}  words={words} setWords={setWords} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                    </>
                    :
                    <>
                        {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                            <>
                                <TaskLongAnswer data={data} longAns={longAns} setLongAns={setLongAns} longAnswer={longAnswer}
                                   loader={loader} words={words} setWords={setWords} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {                                   
                                    return (
                                        <>                                         
                                            <TaskLongAnswerWithResults data={data} longAns={longAns} setLongAns={setLongAns} longAnswer={longAnswer}
                                               loader={loader} words={words} setWords={setWords} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} item={item} />
                                    </>
                                    )
                                })}
                            </>

                        }
                    </>
                }
                </>}
            </Grid>
        </>
    )
}

const TaskLongAnswer = ({ loader,words, setWords,data, longAnswer, longAns, setLongAns, handleButtonClick, handleModalClose, isModalOpen }: any) => {
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const handleInputChange = (event:any) => {
        const value = event.target.value;
        const words = value.trim().split(/\s+/); 
        if (words.length <= 1000) { 
          setWords(words.length)
          setLongAns(value);
        }
        if (!value){
            setWords(0)
        }
    };
    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Answer</Typography>
            </Grid>
            <Grid container style={{ height: '180px', textAlign: 'right', boxSizing: 'content-box', position: 'relative' }}>
                <Box className="boxAns" style={{ position: 'absolute', left: '0', border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                    <TextField placeholder="Write your answer here..."
                        value={longAns}
                        onChange={handleInputChange}
                        multiline 
                        InputProps={{ disableUnderline: true }}
                        className={classes.long}
                        style={{height:'95px'}}
                    />
                    <p style={{ marginTop: '0px' }}>{words}/1000</p>
                </Box>
            </Grid>
            <Grid container className="taskBtnContainer">
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className='cancelBtn' onClick={() => setLongAns('')}>Cancel</button>
                    <button className='submitBtn' onClick={() => longAnswer(longAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                </Grid>
                <Grid style={{ display: 'flex', marginTop: '30px' }}>
                    <button className="fullBtnContainer"
                        onClick={handleButtonClick}>
                        <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' >
                        <LongAnsTaskPopUp loader={loader} words={words} setWords={setWords} data={data} longAnswer={longAnswer} setLongAns={setLongAns} longAns={longAns} />
                    </Box>
                </Grid>
            </Modal>
        </>
    )
}

const TaskLongAnswerWithResults = ({ loader,words, setWords, data, longAnswer, longAns, setLongAns, handleButtonClick, handleModalClose, isModalOpen, item }: any) => {
    const [scrollPosition, setScrollPosition] = React.useState(0);

    const handleWheel = (event:any) => {
        event.preventDefault();
        const delta = event.deltaY;
        const newScrollPosition = scrollPosition + delta;
        setScrollPosition(newScrollPosition);
        event.currentTarget.scrollTop = newScrollPosition;
    };
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const points = JSON.stringify(data?.attributes?.long_answer) === JSON.stringify(item?.attributes?.long_answer)
    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Answer</Typography>
                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                    return (
                        <>
                            {(data.attributes.long_answer).trim() !== (item.attributes.long_answer).trim() ?
                                <Typography className='select-option' style={{ color: '#d16565' }}>
                                    Wrong Answer
                                </Typography> :
                                <Typography className='select-option' style={{ color: '#147620' }}>
                                    Correct Answer
                                </Typography>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container style={{ height: '180px', textAlign: 'right', boxSizing: 'content-box', position: 'relative' }}>
                <Box className="boxAns" style={{ position: 'absolute', left: '0', border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                <Typography
                className={classes.long}
                onWheel={handleWheel}
                >
                <div className={classes.content}>
                    {item.attributes?.long_answer}
                </div>
                </Typography>
                </Box>
            </Grid>
            <Grid container style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between'}}>
                <Grid className="pointContainer">
                    <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
                </Grid>
                <Grid style={{ margin: '30px 0 20px' }}>
                    <button className="fullBtnContainer" onClick={handleButtonClick}>
                        <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' >
                        <LongAnsTaskPopUp loader={loader} words={words} setWords={setWords} data={data} longAnswer={longAnswer} setLongAns={setLongAns} longAns={longAns} />
                    </Box>
                </Grid>
            </Modal>
        </>
    )
}

function LongAnsTaskPopUp({ loader,words, setWords,data, longAns, setLongAns, longAnswer }: any) {
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const classes = useStyles();
    const accountId = localStorage.getItem('cartId')
    const handleLongAnsChange = (event:any) => {
        const val = event.target.value;
        const words = val.trim().split(/\s+/); 
        if (words.length <= 1000) { 
          setWords(words.length)
          setLongAns(val);
        }
        if (!val){
            setWords(0)
        }
    };

    const handleWheelChange = (event:any) => {
        event.preventDefault();
        const deltaY = event.deltaY;
        const newScrollPosition = scrollPosition + deltaY;
        setScrollPosition(newScrollPosition);
        event.currentTarget.scrollTop = newScrollPosition;
    };

    return (
        <>
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                    <Typography className="select-text">Give Short Answer</Typography>
                    <div className='horizontal-line'></div>
                    <Typography className='task-title'>Task Title</Typography>
                    <Box className='task-question'>
                    <Typography variant="body1" component="div" >
                        <div  className="question" dangerouslySetInnerHTML={{ __html:  data.attributes.title }} />
                    </Typography>
                    </Box>
                    {data.attributes.title_image &&
                        <Grid item xs={12} style={{ display: 'block', margin:'20px 0' }} className="taskImageContainer">
                         <LazyLoad offset={100}>   
                        <Image
                        style={{position:'unset', paddingTop:'0'}}
                        src={data?.attributes?.title_image?.url}
                        alt="Title Image"
                        imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
                        animationDuration={500}
                        loading={
                            <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
                        }
                        />
                        </LazyLoad>
                        </Grid>

                    }
                    {loader ? <Box className="task_result_loader">
            <CircularProgress size={35} />
          </Box> :
          <>
                    <Grid container className="ansWrapper">
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            
                            <Typography className='task-option'>Answer</Typography>
                            {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                return (
                                    <>
                                        {JSON.stringify(data.attributes.long_answer) !== JSON.stringify(item.attributes.long_answer) ?
                                            <Typography className='task-option' style={{ color: '#d16565' }}>
                                                Wrong Answer
                                            </Typography> :
                                            <Typography className='task-option' style={{ color: '#147620' }}>
                                                Correct Answer
                                            </Typography>
                                        }
                                    </>
                                )
                            })}
                        </Grid>
                        {data.attributes.task_results.length === 0 ?
                            <>
                                <Grid container style={{ height: '180px', textAlign: 'right', boxSizing: 'content-box', position: 'relative' }}>
                                    <Box className="boxAns" style={{ position: 'absolute', left: '0', border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                                        <TextField placeholder="Write your answer here..."
                                            value={longAns}
                                            onChange={handleLongAnsChange}
                                            multiline
                                            InputProps={{ disableUnderline: true }}
                                            className={classes.long}
                                            style={{height:'95px'}}
                                        />
                                        <p style={{ marginTop: '0px' }}>{`${words}/1000`}</p>
                                    </Box>
                                </Grid>
                                <Grid container className="taskBtnContainer">
                                    <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                        <button className='cancelBtn' onClick={() => setLongAns('')}>Cancel</button>
                                        <button className='submitBtn' onClick={() => longAnswer(longAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                                    <>
                                        <Grid container style={{ height: '180px', textAlign: 'right', boxSizing: 'content-box', position: 'relative' }}>
                                            <Box className="boxAns" style={{ position: 'absolute', left: '0', border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                                                <TextField placeholder="Write your answer here..."
                                                    value={longAns}
                                                    onChange={handleLongAnsChange}
                                                    multiline 
                                                    InputProps={{ disableUnderline: true }}
                                                    className={classes.long}
                                                    style={{height:'95px'}}
                                                />
                                                <p style={{ marginTop: '0px' }}>{`${words}/1000`}</p>
                                            </Box>
                                        </Grid>
                                        <Grid container className="taskBtnContainer">
                                            <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                                <button className='cancelBtn' onClick={() => setLongAns('')}>Cancel</button>
                                                <button className='submitBtn' onClick={() => longAnswer(longAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                            const points = JSON.stringify(data?.attributes?.long_answer) === JSON.stringify(item?.attributes?.long_answer)
                                            return (
                                                <>
                                                    <Grid container style={{ height: '180px', textAlign: 'right', boxSizing: 'content-box', position: 'relative' }}>
                                                        <Box className="boxAns" style={{ position: 'absolute', left: '0', border: '1px solid #979797', width: '100%', padding: '20px', borderRadius: '5px' }}>
                                                        <Typography
                                                            className={classes.long}
                                                            onWheel={handleWheelChange}
                                                            >
                                                            <div className={classes.content}>
                                                                {item.attributes?.long_answer}
                                                            </div>
                                                        </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid className="pointContainer">
                                                        <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                    </Grid>
                    </>}
                </Box>
            </Grid>
        </>
    )
}

//Customizable Area End