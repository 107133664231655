// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isTokenExpired} from "../../../components/src/utility/helper"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  routeUrl: any;
  history?: any;
  classes?: any;
  tabValue?: any;
  logout: any

}

interface S {

  arrayHolder: any;
  token: string;
  tableData: any[];
  selected: any[];
  prodPerPage: any;
  currentPage: any;
  farmsList: any;
  productList: any;
  loading: any;
  currentFarmId: any;
  categories: any;
  categorySelected: any;
  sortValue: any;
  openDialog: any;
  removedProduct: any;
  search: any;
  courseDetails: any;
  dateTimepickerValue: any
  productMode: any;
  bulkAction: any;
  restOrderModal: boolean;
  creatPartModal: boolean,
  creatLessonModal: boolean,
  addTaskModal: boolean,
  addMcqQAModal: boolean,
  addTrueFalseModal: boolean,
  addLongAnsModal: boolean,
  addActionModal: boolean,
  formRef: any;
  courseContent: any;
  editPart: any;
  editPartDetails: any;
  course_id: any;
  lesson_id: any;
  task_checkbox: any;
  expandedLessonIds: any;
  myRefs:any

}

interface SS {
  id: any;
}

export default class AdminLearningCourseContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDropdownApiCallId: string = "";
  getCategoryApiCallId: string = "";
  getProductListApiCallId: string = "";
  deleteProductApiCallId: string = "";
  updateProductStatusApiCallId: string = "";
  getProductDetailsApiCallId: string = "";
  postCreateProductApiCallId: string = "";
  deleteImageApiCallId: string = ""
  bulkActionsProductApiCallId: string = "";
  getCourseDetailsApiCallId: string = "";
  getCourseContentApiCallId: string = "";
  createPartApiCallId: string = "";
  deletePartApiCallId: string = "";
  createLessonApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      tableData: [],
      selected: [],
      restOrderModal: false,
      prodPerPage: 5,
      currentPage: 1,
      farmsList: "",
      productList: [],
      loading: false,
      currentFarmId: "",
      categories: "",
      categorySelected: "",
      sortValue: "default",
      openDialog: false,
      removedProduct: "",
      search: "",
      courseDetails: "",
      dateTimepickerValue: new Date("2018-01-01T00:00:00.000Z"),
      productMode: "",
      bulkAction: "",
      creatPartModal: false,
      creatLessonModal: false,
      addTaskModal: false,
      addMcqQAModal: false,
      addTrueFalseModal: false,
      addLongAnsModal: false,
      addActionModal: false,
      formRef: React.createRef(),
      courseContent: "",
      editPart: false,
      editPartDetails: "",
      course_id: "",
      lesson_id: "",
      task_checkbox: "mcq",
      expandedLessonIds: [],
      myRefs:React.createRef()

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    let query = this.getQuery()
    let mode = query.get("mode")
    let course_id = query.get("course_id")
    let lesson_id = query.get("lesson_id")
    this.setState({
      productMode: mode,
      course_id: course_id,
    })

    if (mode == "edit") {
      this.props.tabValue != 1 ? this.getCourseDetails(course_id) : this.getCourseContent(course_id)
      if(lesson_id){
        this.setState({
          expandedLessonIds:[lesson_id]
        })
      }
    }
    this.getCategory()

  }
  async componentDidUpdate() {
    if (this.state.currentFarmId) {

      if (window.location.pathname.split("/")[4] != this.state.currentFarmId) {
        const path = window.location.pathname.split("/")[4]
        this.setState({
          currentFarmId: path
        })

      }
    }
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.successApiCall(responseJson, apiRequestCallId)


      }
      if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        } 
      }
    }

  }


  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId != null) {
      //get farms


      //get category       
      if (apiRequestCallId === this.getCategoryApiCallId) {
        this.setState({
          categories: responseJson,
          categorySelected: "all",
          loading: false,
        });

      }
      //get course details 
      if (apiRequestCallId === this.getCourseDetailsApiCallId) {
        this.setState({
          courseDetails: responseJson.data,
          loading: false,
        });

      }

      // get course content 
      if (apiRequestCallId === this.getCourseContentApiCallId) {
        this.setState({
          courseContent: responseJson.data,
          loading: false,
        });

      }

      //delete part 
      if (apiRequestCallId === this.deletePartApiCallId) {
        this.getCourseContent(this.state.course_id)
        const type = this.state.editPartDetails.type
        this.setState({
          editPartDetails: "",
          loading: false,

        });

        toast.success(`${(type != "course_section") ? (type == "task" ? "Task" : "Lesson") : "Part"} Deleted Successfully`)

      }

      if (apiRequestCallId === this.createPartApiCallId) {

        this.getCourseContent(this.state.course_id)
        const isEdit = this.state.editPart;
        toast.success(`Part ${isEdit ? "Edited" : "Added"} Successfully`)
        this.setState({
          editPart: false,
          editPartDetails: "",
          loading: false,
        });

      }

      // create/update lesson
      if (apiRequestCallId == this.createLessonApiCallId) {

        this.getCourseContent(this.state.course_id)
        const isEdit = this.state.editPart
        toast.success(` Lesson ${isEdit ? "Edited" : "Added"} Successfully`)
        this.handleCloseLessonModal();
        this.setState({
          editPart: false,
          editPartDetails: "",
          loading: false,
        });

      }




    }
  }

  // get category
  getCategory = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCoursesCategoryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get  Course Details
  getCourseDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseDetailsAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  // get course content 

  getCourseContent = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseDetailsAPiEndPoint + `/${id}/course_content`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  // creating and updating part 
  createPart = async (values: any) => {
    this.setState({
      loading: true,
    });

    const isEdit = this.state.editPart

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      name: values.name,
      course_id: values.course_id

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPartCoursesAPiEndPoint + `${isEdit ? `/${this.state.editPartDetails.id}` : ""}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${isEdit ? configJSON.apiMethodTypePut : configJSON.apiMethodTypePost}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //deleting part
  deletePart = async () => {
    this.setState({
      loading: true,
    });

    const type = this.state.editPartDetails.type
    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };





    console.log(this.state.editPartDetails, "edit part")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${(type != "course_section") ? (type == "task" ? configJSON.createMcqTaskAPiEndPoint : configJSON.createlessonCoursesApiEndPoint) : (configJSON.createPartCoursesAPiEndPoint)}` + `/${this.state.editPartDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //creating and updating lesson
  createLesson = async (values: any) => {
    this.setState({
      loading: true,
    });

    const isEdit = this.state.editPart

    const token = await StorageProvider.get("authToken");

    const headers = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("course_id", values.course_id);
    formdata.append("course_section_id", values.section_id);
    formdata.append("description", values.description);
    formdata.append("heading", values.heading);
    formdata.append("video_duration", values.videoDuration);
    values.video.name && formdata.append("video", values.video);
    values.attachment && values.attachment[0].name && formdata.append("attachment", values.attachment[0]);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createLessonApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createlessonCoursesApiEndPoint + `${isEdit ? `/${this.state.editPartDetails.id}` : ""}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${isEdit ? configJSON.apiMethodTypePut : configJSON.apiMethodTypePost}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  };

  handleRestOrderOpenModal = () => {
    this.setState({ restOrderModal: true });
  };
  handleRestOrderCloseModal = () => {
    this.setState({ restOrderModal: false });
  };

  handleOpenCreatePartModal = () => {
    this.setState({ creatPartModal: true })
  }
  handleCloseCreatePartModal = () => {
    this.setState({
      creatPartModal: false,
      editPart: false,
      editPartDetails: "",
    })
  }
  handleOpenLessonModal = () => {
    this.setState({ creatLessonModal: true })
  }
  handleCloseLessonModal = () => {
    this.setState({
      creatLessonModal: false,
      editPart: false,
      editPartDetails: "",
    })
  }
  handleOpenAddTaskModal = () => {
    this.setState({ addTaskModal: true })
  }
  handleCloseAddTaskModal = () => {
    this.setState({
      addTaskModal: false,
      lesson_id: ""
    })
  }

  handleOpenAddMcqQAModal = () => {
    this.setState({ addMcqQAModal: true })
  }
  handleCloseAddMcqQAModal = () => {
    this.setState({ addMcqQAModal: false })
  }
  handleOpenAddTrueFalseQAModal = () => {
    this.setState({ addTrueFalseModal: true })
  }
  handleCloseAddTrueFalseQAModal = () => {
    this.setState({ addTrueFalseModal: false })
  }

  handleOpenAddLongAnsQAModal = () => {
    this.setState({ addLongAnsModal: true })
  }
  handleCloseAddLongAnsQAModal = () => {
    this.setState({ addLongAnsModal: false })
  }
  handleDialog = () => {

    this.setState({
      openDialog: !this.state.openDialog
    })
  }
  getMode = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode")
    return mode
  }

  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return query

  }

  getTab = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let tab = query.get("tab")
    return tab
  }

  findTaskType = (val: any) => {
    let taskType = val;
    let type;
    if (["mcq", "options", "single_option"].includes(taskType)) {
      type = "mcq";
    } else if (["true_false", "bool_answer"].includes(taskType)) {
      type = "true_false";
    } else if (taskType == "long_answer") {
      type = "long_answer";
    } else if (taskType == "short_answer") {
      type = "short_answer";
    } else {
      type = "action";
    }
    return type
  }

  findTaskLabel = (val: any) => {
    let taskType = val;
    let type;
    if (["mcq", "options", "single_option"].includes(taskType)) {
      type = "MCQ";
    } else if (["true_false", "bool_answer"].includes(taskType)) {
      type = "True False";
    } else if (taskType == "long_answer") {
      type = "Long Answer";
    } else if (taskType == "short_answer") {
      type = "Short Answer";
    } else {
      type = "Action";
    }
    return type
  }
  courseDetailInitialValues = (editDetails: any) => {
    return ({
      heading: editDetails?.heading || "",
      description: editDetails?.description || "",
      course_id: this.state.courseContent.id,
      section_id: this.state.editPart
        ? this.state.editPartDetails?.attributes?.course_section_id
        : this.state.editPartDetails?.id,
      attachment: (editDetails && editDetails?.attachment?.url) || "",
      attachmentName: (editDetails && editDetails?.attachment?.filename) || "",
      video: (editDetails && editDetails?.video?.url) || "",
      videoUrl: (editDetails && editDetails?.video?.url) || "",
      videoName: (editDetails && editDetails.video?.filename) || "",
      videoDuration:
        (editDetails && editDetails.video_duration) || "",

    })
  }
  handleLessonText = () => {
    return this.state.editPart ? "Edit" : "Create"
  }
  handlePartModalbuttonText = () => {
    return this.state.editPart ? "Save" : "Add"
  }
  handleTaskCheckBox = (task: any) => {
    return this.state.task_checkbox == task.name
      ? true
      : false
  }
  handleOpacity=()=>{
    let tab = this.getTab()
    //@ts-ignore
    return tab==1 ? .6 : 1
  }
  settingAccordian=(expanded:any,id:any)=>{
    if(expanded){
      this.setState({
       expandedLessonIds:[...this.state.expandedLessonIds,id]
      })
    }else{
    const filter =this.state.expandedLessonIds.filter((x:any)=>x!=id)
    this.setState({
      expandedLessonIds:filter
     })

    }
  }
  settingRefs=(id:any)=>{
    const lesson_id= this.getQuery().get("lesson_id")
    const isIdCorrect=this.state.expandedLessonIds.length==1 && this.state.expandedLessonIds.includes(id)
    if(lesson_id && isIdCorrect){
      return this.state.myRefs
    }
  }

}
// Customizable Area End
