import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { formatCoord, getCoords, getUniqueId } from "../../../components/src/utility/helper";
import { FormikTouched, FormikErrors } from "formik";
export const configJSON = require("./config.js");

export interface IAddressValues {
  id: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  number: string
  email: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  navigation?: any
  id?: string
  close?: any
  open?: any
  history: any
  userInfo: any
  resatOTPFlags: () => void
  sentOTP1Action: (value: string) => void
  changeOTP1Verify: (isVerified: boolean) => void
  setSameContactDetails: (isChecked: boolean) => void
  verifyUserAction: (history:  RouteComponentProps['history']) => void
  verifyOTP1Action: (otpDetails: { email_otp_id: string; pin: string }) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  org_name: string;
  org_address: string;
  org_owner_name: string;
  org_owner_address: string;
  org_contact_number: string;
  org_owner_contact_number: string;
  latitude: number;
  longitude: number;
  mapCoOrdinates: {
    lat: any;
    lng: any;
  };
  address: string;
  detectedLatitude: number;
  detectedLongitude: number;
  detectLocation: boolean;
  createFarmerProfileApiResponse: object;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  openModal: boolean;
  closeModal: boolean;
  token: string;
  createProfile: boolean;
  postedRoles: boolean;
  orgContactNumberError: string;
  orgOwnerContactNumberError: string;
  display: any;
  verify_OTP_click: boolean;
  verify_OTP_click1: boolean;
  isVerify_OTP_disable: boolean;
  OTP_Error: any;
  OTP_Error_msg: any;
  isChecked: any;
  OTP_header_diplay: any;
  isValid: any;
  isOwner: any;
  Onwer_OTP_header_diplay: any;
  Owner_isValid: any;
  Owner_OTP_Error: any;
  Owner_isDisable: any;
  otpSuccess: boolean;
  SMSOTPID: any;
  mobileType: any;
  otpclick: any;
  ownerOtp: any;
  createError: any[];
  isLoading: boolean;
  emailOTP: string;
  isEditingAddress: boolean;
  isAddressFormExpanded: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  userInfo: any;
  // Customizable Area End
}

export default class CreateFarmerProfileWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  updateRolesApiCallId: string = "";
  emailReg: RegExp;
  mobileReg: RegExp;
  labelTitle: string = "";
  PathRoleID: string = "";
  formikRef: any = React.createRef()
  addressFormRef: any = React.createRef()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      org_name: "",
      org_address: "",
      org_owner_name: "",
      org_owner_address: "",
      org_contact_number: "",
      org_owner_contact_number: "",
      latitude: 0,
      longitude: 0,
      address: " ",
      mapCoOrdinates: {
        lat: "",
        lng: ""
      },
      detectedLatitude: 0,
      detectedLongitude: 0,
      detectLocation: false,
      createFarmerProfileApiResponse: {},
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      openModal: false,
      closeModal: false,
      token: "",
      createProfile: false,
      postedRoles: false,
      orgContactNumberError: "",
      orgOwnerContactNumberError: "",
      display: "",
      OTP_Error: "",
      OTP_Error_msg: "",
      isChecked: false,
      OTP_header_diplay: "block",
      verify_OTP_click: false,
      verify_OTP_click1: false,
      isVerify_OTP_disable: false,
      isValid: true,
      otpSuccess: false,
      Onwer_OTP_header_diplay: "block",
      Owner_isValid: false,
      Owner_OTP_Error: false,
      Owner_isDisable: false,
      SMSOTPID: "",
      mobileType: "",
      otpclick: false,
      ownerOtp: false,
      isOwner: false,
      createError: [],
      isLoading: false,
      emailOTP: "",
      isEditingAddress: false,
      isAddressFormExpanded: false
    };

    this.emailReg = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    this.mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    this.PathRoleID = window.location.pathname.split(":")[1];
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): Promise<void> {
    const { isChecked } = this.state

    if(prevState.isChecked !== isChecked) {
      const orgContactNumber = this.getFormikValue('org_contact_number')
      const phoneNo = isChecked ? orgContactNumber : ""
      this.setFormikValue("org_owner_contact_number", phoneNo)
    }
  }

  setFormikValue = (key: string, value: any) => {
    if (this.formikRef.current) {
      this.formikRef.current.setFieldValue(key, value)
    }
  }

  getFormikValue = (key: string) => {
    let value = ''
    try {
      if(this.formikRef.current) {
        value = this.formikRef.current.values[key]
      }
    } catch(e) {
      console.log("error", e)
    }
    return value
  }
  
  handleEmailValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { userInfo, changeOTP1Verify } = this.props
    const { value } = e.target
    if (userInfo.verifiedOTP1) {
      this.setState({ emailOTP: "" })
      changeOTP1Verify(false)
    }
    this.setFormikValue("org_owner_address", value)
  }
  
  SearchDebounce = (call: any, delay: number) => {
    let timer: any
    return function (...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }  

  handleEmailOTPChange = (otpValue: string) => {
    const deb = this.SearchDebounce(this.OwnerOTPValidation, 1000)
    this.setState({ emailOTP: otpValue })
    otpValue.length === 4 && deb()
  }
  
  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0 || str === undefined || str === ""
  }
  
  isNumberNullOrBlank = (number: number) => {
    return number === null || number === undefined || number === 0
  }
  
  handleBack = () => {
    this.props.history.goBack()
  }  

  async componentWillUnmount() {
    this.props.resatOTPFlags()
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.setState({ openModal: true, isLoading: false });
          this.props.verifyUserAction(this.props.history);
        }
      } else {
        this.handleErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleErrorResponse = (responseJson: any) => {
    this.setState({
      isLoading: false,
      createError: responseJson.errors,
    })
  
    let orgContactError = ""
    let orgOwnerContactError = ""
  
    if (
      "org_contact_number" in responseJson.errors &&
      responseJson.errors.org_contact_number[0] === "has already been taken"
    ) {
      orgContactError = "this mobile number has already been taken"
    }
  
    if (
      "org_owner_contact_number" in responseJson.errors &&
      responseJson.errors.org_owner_contact_number[0] === "has already been taken"
    ) {
      orgOwnerContactError = "this mobile number has already been taken"
    }
  
    if (responseJson.status === 500) {
      toast.error("Something went wrong")
    }
  
    this.setState({
      orgContactNumberError: orgContactError,
      orgOwnerContactNumberError: orgOwnerContactError,
    })
  }
  
  doCreateProfile = async (values: any) => {
    if (this.props.userInfo.verifiedOTP1) {
      this.setState({
        isLoading: true,
      })
      await this.doCreateFarmerProfile(values)
    } else {
      toast.error("Email not verified. Please verify the email")
    }
  }
  
  doCreateFarmerProfile = async (values: {
    org_name: string
    org_address: string
    org_owner_name: string
    org_owner_address: string
    org_contact_number: string
    org_owner_contact_number: string
    latitude: string
    longitude: string,
    pickUpAddresses: IAddressValues[],
    purchase_limit_percentage: number
  }): Promise<boolean> => {
    this.setState({
      orgOwnerContactNumberError: "",
      orgContactNumberError: "",
    })
  
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
  
    const header = {
      "Content-Type": configJSON.createProfileContentType,
      token
    }

    const formattedPickupAddresses = [...values.pickUpAddresses].map((address: IAddressValues) => {
      const {
        id,
        addressLine1,
        addressLine2,
        zipCode,
        number,
        ...rest
      } = address
      return {
        ...rest,
        address_line1: addressLine1,
        address_line2: addressLine2,
        zipcode: zipCode,
        number: number ? `1${number}` : ""
      }
    })
  
    const farm = {
      name: values.org_name,
      org_address: values.org_address,
      org_owner_name: values.org_owner_name,
      org_owner_address: values.org_owner_address,
      org_contact_number: `1${values.org_contact_number}`,
      org_owner_contact_number: `1${values.org_owner_contact_number}`,
      latitude: values.latitude,
      longitude: values.longitude,
      role_id: this.PathRoleID,
      farm_pick_up_addresses_attributes: formattedPickupAddresses,
      purchase_limit_percentage: values.purchase_limit_percentage
    }
  
    const httpBody = { farm }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
  
    this.apiEmailLoginCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFarmerProfileEndPoint
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodCreateFarmerProfile
    )
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
  
    return true
  }
  
  postRoles = async (): Promise<boolean> => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }
  
    const httpBody = {
      roles: ["1"]
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
  
    this.updateRolesApiCallId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRolesApiEndPoint
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodUpdateRoles
    )
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }
  
  showModal = () => {
    this.setState({ openModal: true })
  }
  
  closeModal = () => {
    this.setState({ openModal: false })
  }
  
  handleDialogOnClose = (reason: string) => {
    const isValidClose = reason !== "escapeKeyDown" && reason !== "backdropClick"
    if (isValidClose) {
      this.closeModal()
    }
  }
  
  changeMobileNumber = () => {
    if (!this.props.userInfo.isOTP1Sent) {
      this.setState({
        otpSuccess: false,
        verify_OTP_click: false,
        orgContactNumberError: "",
      })
    }
  }
  
  handleBlurOrgNumber = () => {
    const orgContactNumber = this.getFormikValue('org_contact_number')
    if (this.state.isChecked) {
      this.setFormikValue("org_owner_contact_number", orgContactNumber)
    }
  }
  
  changeMobileNumber1 = () => {
    if (!this.props.userInfo.isOTP1Sent) {
      this.setState({
        otpSuccess: false,
        verify_OTP_click1: false,
        orgOwnerContactNumberError: "",
      })
    }
  }
  
  doVerifyOwnerEmail = async (e: any) => {
    e.preventDefault()
    const value = this.getFormikValue('org_owner_address')
    if (value) {
      this.setState({ orgOwnerContactNumberError: "" })
      this.props.sentOTP1Action(value)
      this.setState({
        verify_OTP_click: true,
      })
    } else {
      toast.error("Please enter an email address")
    }
  
    this.setState({
      mobileType: "Restaurant",
    })
  }
  
  OwnerOTPValidation = async () => {
    const { userInfo, verifyOTP1Action } = this.props
    const { emailOTP } = this.state
    const emailOTPLength = emailOTP.length

    if (emailOTPLength === 4) {
      this.setState({
        ownerOtp: true,
        isOwner: true,
      })
  
      if (userInfo.isOTP1Sent) {
        verifyOTP1Action({
          email_otp_id: userInfo.email_otp_id,
          pin: emailOTP,
        })
      }
    }
  }
  
  sameAsPrevious = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { display, isChecked } = this.state
  
    if (display === "none" && isChecked) {
      this.setState({
        display: "block",
        isChecked: false,
        Owner_isDisable: false,
      })
    } else {
      this.setState({
        display: "none",
        isChecked: true,
        Owner_isDisable: true,
      })
    }
    this.props.setSameContactDetails(isChecked)
  }
  
  handlePlacesAutocompleteSelect = (lat: number, lng: number) => {
    this.setFormikValue("latitude", lat)
    this.setFormikValue("longitude", lng)
    this.setState({ mapCoOrdinates: { lat, lng } })
  }
  
  handleChangeLat = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const longitude = this.getFormikValue('longitude')
    const latLng = {
      lat: Number(targetValue),
      lng: Number(longitude),
    }
    this.setFormikValue("latitude", targetValue)
    this.setState({
      mapCoOrdinates: { ...latLng },
      latitude: Number(targetValue),
    })
  }
  
  handleChangeLng = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latitude = this.getFormikValue('latitude')
    const latLng = {
      lat: Number(latitude),
      lng: Number(targetValue),
    }
    this.setFormikValue("longitude", targetValue)
    this.setState({
      mapCoOrdinates: { ...latLng },
      longitude: Number(targetValue),
    })
  }
  
  handleChangeLatLng = (lat: number, lng: number) => {
    const latLng = { lat, lng }
    this.setFormikValue("latitude", lat)
    this.setFormikValue("longitude", lng)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }
  
  detectPlace = async () => {
    try {
      const coords: any = await getCoords()
      const { latitude, longitude } = coords
      this.setFormikValue("latitude", latitude)
      this.setFormikValue("longitude", longitude)
      this.setState({
        mapCoOrdinates: {
          lat: latitude,
          lng: longitude,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  isError = (
    isError:
      | string
      | string[]
      | FormikErrors<any>
      | FormikErrors<any>[]
      | undefined,
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  ) => {
    return isTouched && !!isError
  }

  handleExpandOnChange = (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ isAddressFormExpanded: isExpanded })
  }

  handleAddressRemove = (id: string) => {
    const farmDetailsForm = this.formikRef.current
    const updatedAddresses = farmDetailsForm.values.pickUpAddresses.filter(
      (x: IAddressValues) => x.id !== id
    )
    farmDetailsForm.setFieldValue(
      "pickUpAddresses",
      updatedAddresses
    )
  }

  handleAddressEdit = (item: IAddressValues) => {
    const {
      id,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      number,
      email
    } = item
    if (this.addressFormRef.current) {
      const { setFieldValue } = this.addressFormRef.current

      setFieldValue("id", id)
      setFieldValue("addressLine1", addressLine1)
      setFieldValue("addressLine2", addressLine2)
      setFieldValue("city", city)
      setFieldValue("state", state)
      setFieldValue("zipCode", zipCode)
      setFieldValue("number", number.length > 10 ? number.slice(1) : number)
      setFieldValue("email", email)
      this.setState({
        isAddressFormExpanded: true,
        isEditingAddress: true
      })
    }
  }

  cancelEditAddress = () => {
    if(this.addressFormRef.current) {
      this.addressFormRef.current.resetForm()
      this.setState({
        isEditingAddress: false
      })
    }
  }

  handleAddressNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newVal = e.target.value
    if (newVal.length <= 10) {
      this.addressFormRef.current.setFieldValue("number", newVal)
    }
  }

  handleNewAddressSubmit = (
    values: IAddressValues,
    resetForm: () => void
  ) => {
    const updatedValues = {...values, id: values.id || getUniqueId()}
    const mainFormRef = this.formikRef.current
    const pickUpAddresses = [...mainFormRef.values.pickUpAddresses]

    if(this.state.isEditingAddress) {
      const updatedAddresses = pickUpAddresses.map((address: IAddressValues) => {
        if(address.id === values.id) return values
        return address
      })
      mainFormRef.setFieldValue("pickUpAddresses", updatedAddresses)
      this.setState({ isEditingAddress: false })
      toast.success("Address Edited Successfully")
    } else {
      mainFormRef.setFieldValue("pickUpAddresses", [...pickUpAddresses, updatedValues])
      toast.success("Address Added Successfully")
    }
    resetForm()
  }
  // Customizable Area End
}
