// Customizable Area Start
import React from "react"
import { Box, Container, Typography, IconButton } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import "react-loading-skeleton/dist/skeleton.css"
import TransactionHistoryController, {
  Props,
} from "./TransactionHistoryController.web"
import BreadCrumb from "../../../../../components/src/BreadCrumb.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import TransactionHistoryTable from "./TransactionHistoryTable.web"

export const styles = () =>
  createStyles({
    headingWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      "& > div": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
      },
      "& button": {
        padding: "10px",
        "& svg": {
          color: "#686868"
        }
      }
    },
    mainHeading: {
      fontSize: "26px",
      color: "#686868",
      fontFamily: "'Montserrat', sans-serif",
      "@media screen and (max-width:480px)": {
        fontSize: "22px",
      },
    },
    pointHeading: {
      color: "#20a258",
      fontSize: "22px",
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      "@media screen and (max-width:480px)": {
        fontSize: "20px",
      },
    },
  })

class TransactionHistory extends TransactionHistoryController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    const { isLoading, loyaltyPointBalance } = this.state

    return (
      <>
        <Container>
          <BreadCrumb
            twoPage={true}
            threePage={false}
            firstPage="Home"
            secondPage="History of point transactions"
            firstPageLink={AppRoutings.Home}
            secondPageLink={AppRoutings.TransactionHistory}
            thirdPage=""
            thirdPageLink=""
          />
          <Box paddingY={4}>
            <Box className={classes.headingWrapper}>
              <Box>
                <IconButton onClick={this.handleGoBack}>
                  <ArrowBackRoundedIcon />
                </IconButton>
                <Typography variant="h5" className={classes.mainHeading}>
                  Available points
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.pointHeading}>
                {isLoading ? (
                  <Skeleton
                    width="80px"
                    height={25}
                    borderRadius={5}
                    baseColor="#f0f3f5"
                  />
                ) : (
                  `${loyaltyPointBalance}pts`
                )}
              </Typography>
            </Box>
            <TransactionHistoryTable />
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withStyles(styles)(withRouter(TransactionHistory))
// Customizable Area Start
