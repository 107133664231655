import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("../config.js");
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify";
import StorageProvider from "../../../../../framework/src/StorageProvider";
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper";
// Customizable Area End

// Customizable Area Start
interface IFavBody {
    favouriteable_id: string
    favouriteable_type: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    id: number
    image: string
    name: string
    rating: number
    description: string
    type: "farm" | "restaurant"
    removeCMSFavFarm: (farmId: number) => void
    removeCMSFavRestaurant: (resId: number) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isRemoving: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LPDetailsCardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    favoriteApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            isRemoving: false
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleViewDetails = () => {
        const { id, type, history } = this.props
        const route = {
            "farm": "/farm-details",
            "restaurant": "/restaurant-details"
        }
        history.push(`${route[type]}/${id}`)
    }

    handleRemoveFromFav = () => {
        const { id, type } = this.props
        const favType = {
            "farm": configJSON.CARTABLE_TYPE_FARM,
            "restaurant": configJSON.CARTABLE_TYPE_RESTAURANT
        }

        const body: IFavBody = {
            favouriteable_id: id.toString(),
            favouriteable_type: favType[type]
        }
        this.removeFromFavouriteApi(body)
    }

    removeFromFavouriteApi = async (body: IFavBody) => {
        this.setState({ isRemoving: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.favoriteApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.toggleFavouriteApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPatchMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleRemoveFromLocal = () => {
        const { id, type, removeCMSFavFarm, removeCMSFavRestaurant } = this.props
        if(type === "farm") removeCMSFavFarm(id)
        else if(type === "restaurant") removeCMSFavRestaurant(id)

        this.setState({
            isRemoving: false
        })
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.favoriteApiCallId) {
            if(!!responseJson?.data?.attributes) {
                this.handleRemoveFromLocal()
                return
            }
            toast.error("Something went wrong")
        }
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(isTokenExpireResponse(responseJson)) return

                if (apiRequestCallId === this.favoriteApiCallId) {
                    this.setState({ isRemoving: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
}