// Customizable Area Start
import * as React from "react";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenTopBanner from "../../../components/src/GreenTopBanner.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import { Container, Box, Grid } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import AboutUsController, { Props } from "./AboutUsController.web";
import {
  aboutImg,
  visionImg,
  batch1Img,
  batch2Img,
  batch3Img,
  batch4Img,
  batch5Img,
  commaIconImg,
  updateActiveTab,
  defaultProfileImg
} from "./assets";

import { connect } from "react-redux";

import "../assets/css/aboutus.scss";
import "../../../components/src/assets/css/greencustomtabs.scss";

class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const aboutUs = this.state.aboutUs && this.state.aboutUs.attributes;
    return (
      <>
        <Loader loading={this.state.loading} />
        <GreenTopBanner bannerHeading="About Us" pageName="About Us" />
        {aboutUs ? (
          <Box className="about_section_wrapper">
            <Container className="about_section_wrapper">
              {/* who we are */}
              <Grid container justifyContent="center" alignItems="center" className="aboutmargin">
                <Grid item xs={12} sm={7}>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className="green_small_heading">
                        Quality and Fresh
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="farm_love">From Farm with Love</p>
                    </Grid>
                    <Grid item xs={12}>
                      <h3 className="aboutHeading">Who We Are</h3>
                    </Grid>
                    <Box className="green_tabs">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="green_tab_contents_wrapper">
                            <Box className="green_tab_contents">
                              <p>{aboutUs.who_we_are}</p>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box className="first_vegi_img_wrapper">
                    <img src={aboutImg} />
                  </Box>
                </Grid>
              </Grid>
              {/* our vision */}
              <Grid container justifyContent="center" alignItems="center" className="aboutmargin">
                <Grid item xs={12} sm={6}>
                  <Box className="first_vegi_img_wrapper vision_wrapper">
                    <img src={visionImg} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <h3 className="aboutHeading">Our Vision</h3>
                    </Grid>
                    <Box className="green_tabs">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="green_tab_contents_wrapper">
                            <Box className="green_tab_contents visionPara">
                              <p>{aboutUs.our_vision}</p>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* our mission */}
              <Grid container justifyContent="center" alignContent="center" className="aboutmargin">
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <h3 className="aboutHeading missionHeading">Our Mission</h3>
                  </Grid>
                  <Box className="green_tabs">
                    <Grid item xs={12}>
                      <div className="green_tab_contents_wrapper">
                        <Box className="green_tab_contents missionPara">
                          <p>{aboutUs.our_mission}</p>
                        </Box>
                      </div>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={8}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Box className="heading_wrapper aboutUsHeading">
                    <p className="green_small_heading aboutUsHeading">Our Team</p>
                    <h3>{aboutUs.our_service_description}</h3>
                  </Box>
                </Grid>
              </Grid>
              <Box className="team_section_wrapper">
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                >
                  {aboutUs &&
                    aboutUs.our_services.data.map((item: any) => {
                      return (
                        <Grid item xs={12} sm={4} key={item.id}>
                          <Box className="about_us_our_team_cards">
                            <img
                              src={item.attributes.image?.url || defaultProfileImg}
                              className="formerImg"
                              alt="farmerImg"
                            />
                            <Box className="team_card_content">
                              <p>{item.attributes.service_type}</p>
                              <h3>{item.attributes.name}</h3>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Container>
            <Box className="service_section_wrapper">
              <Container>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className="service_section_content_wrapper">
                      <Box className="service_section_card_wrapper">
                        <h1>{aboutUs.years_of_foundation}</h1>
                        <p>
                          Years of <br />
                          Foundation
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className="service_section_content_wrapper">
                      <Box className="service_section_card_wrapper">
                        <h1>{aboutUs.customer_served}</h1>
                        <p>
                          Customer
                          <br />
                          Served
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className="service_section_content_wrapper">
                      <Box className="service_section_card_wrapper">
                        <h1>{aboutUs.order_delivered}</h1>
                        <p>
                          Order <br />
                          Delivered
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box className="achivement_batch_section_wrapper">
              <Container>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={2}>
                    <Box className="batch_img_wrapper">
                      <img src={batch1Img} alt="batchImg" />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className="batch_img_wrapper">
                      <img src={batch2Img} alt="batchImg" />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className="batch_img_wrapper">
                      <img src={batch3Img} alt="batchImg" />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className="batch_img_wrapper">
                      <img src={batch4Img} alt="batchImg" />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className="batch_img_wrapper">
                      <img src={batch5Img} alt="batchImg" />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box className="aboutus_testimonial_section">
              <Container>
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={8}
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Box className="heading_wrapper aboutUsHeading">
                      <p className="green_small_heading">Testimonials</p>
                      <h3>{aboutUs.testimonial_heading}</h3>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {aboutUs &&
                    aboutUs.testimonial.data.map((item: any) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={item.id}
                        >
                          <Box className="aboutus_testimonial_card">
                            <Box className="testimonial_text_wrapper">
                              <p className="testimonial_text">
                                {
                                  item.attributes
                                    .description
                                }{" "}
                              </p>
                            </Box>
                            <Box>
                              <Box className="testimonial_content">
                                <img
                                  src={
                                    item?.attributes?.image?.url || defaultProfileImg

                                  }
                                  alt="manImg"
                                />
                                <div>
                                  <h6>
                                    {item.attributes.name}
                                  </h6>
                                  <p>
                                    {
                                      item.attributes
                                        .user_type
                                    }
                                  </p>
                                </div>
                              </Box>
                              <Box className="comma_icon_wrapper">
                                <img
                                  src={commaIconImg}
                                  alt="commaImg"
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Container>
            </Box>
          </Box>
        ) : (
          ""
        )}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(
  mapStateToProps,
  { updateActiveTab }
)(AboutUs);
// Customizable Area End
