// Customizable Area Start
import React from "react"
import { Grid } from "@material-ui/core"
import SearchCategoryLayout from "./SearchCategoryLayout.web"
import SearchCourseCard from "./SearchCourseCard.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ISearchCourse } from "../interfaces/search"
const configJSON = require("../config.js")

interface ISearchCoursesList {
  searchQuery: string
  isViewAll: boolean
  coursesList: ISearchCourse[]
}

const SearchCoursesList = ({
  searchQuery,
  isViewAll,
  coursesList,
}: ISearchCoursesList) => {
  const redirectUrl = isViewAll
    ? `${AppRoutings.SearchCourses}?${configJSON.queryText}=${searchQuery}`
    : ""

  return coursesList.length > 0 ? (
    <SearchCategoryLayout
      heading={configJSON.coursesText}
      viewAllRedirectUrl={redirectUrl}
    >
      <Grid container spacing={3}>
        {coursesList.map((course: ISearchCourse) => {
          const {
            id,
            attributes: { title, banner_image: image, first_name, last_name },
          } = course
          const imageUrl = image && image.url ? image.url : ""
          const authorName = `${first_name} ${last_name}`
          const redirectUrl = `${AppRoutings.CourseDetails}${id}`

          return (
            <Grid item xs={12} sm={6} md={3} key={id}>
              <SearchCourseCard
                image={imageUrl}
                courseName={title}
                author={authorName}
                redirectUrl={redirectUrl}
              />
            </Grid>
          )
        })}
      </Grid>
    </SearchCategoryLayout>
  ) : null
}

export default SearchCoursesList
// Customizable Area End
