Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ShoppingCart";
exports.labelBodyText = "ShoppingCart Body";
exports.httpGetMethod = "GET"
exports.AUTH_TOKEN = "authToken"
exports.ORDERS_STORAGE_KEY = "orders"
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue"
exports.CARTABLE_TYPE_MENU = "BxBlockCatalogue::Menu"
exports.btnExampleTitle = "CLICK ME";
exports.restaurantText = "Restaurant";
exports.farmText = "Farm";
exports.courseText = "Course";
exports.deliveryTimeText = "Delivery Time:";
exports.notAcceptingErrorText = "This restaurant is currently not accepting any order";
exports.courseCartText = "course_cart_item";
exports.totalText = "Total:";
exports.noteToOrderText = "Add a note to your order:";
exports.notePlaceholderText = "Add your note here...";
exports.proceedToCheckoutText = "Proceed to Check Out";
exports.errorText = "Something went wrong";
exports.availabilityErrorText = "Some of the product(s) are not available currently"
exports.quantityErrorText = "Required quantity is not available for item(s)"
exports.activeStateErrorText = "This item is currently unavailable!"

exports.cartOrdersApiEndPoint = "bx_block_order_management/carts/cart_detail"
exports.applyRemoveCouponEndPoint = "bx_block_coupons/coupons/coupon_apply"
exports.createOrderApiEndPoint = "bx_block_payments/payments/create_order"
exports.addDeliveryPickupAddApiEndPoint = "bx_block_order_management/cart_items/add_delivery_or_pickup_address"
exports.updateVariantSelectionApiEndPoint = "bx_block_order_management/cart_items/update_quantity"
exports.getTransactionFeesApiEndPoint = "others/get_transaction_fees"
exports.cardText = "card";
exports.farmText = "Farm";
exports.farmCartText = "farm_cart_item";
exports.restaurantCartText = "restaurant_cart_item";
exports.productOwnerDetailsText = "product_owner_details";
exports.checkOutOrderPath = "/check-out-order";
exports.couponRemovalMsg = "Coupon removed successfully";
exports.couponAppliedMsg = "Coupon applied successfully";
exports.couponAppliedMsgToCart = "Coupon applied successfully to Cart ";
exports.couponNotApplicableMsg = "Coupon is not applicable to Cart ";
exports.cardTypeText = "credit_or_debit_card";
exports.bitcoinWalletText = "bitcoin_wallet";
exports.rewardPointsText = "reward_points";
exports.ORDER_TYPE_BY_CATALOGUE = {
  "BxBlockCatalogue::FarmCatalogue": "farm",
  "BxBlockCatalogue::Menu": "restaurant"
}
// Customizable Area End