export const cardBg = require("./assets/images/cardbg.png");
export const greenMidBg = require("./assets/images/grass-banner.png");
export const leaveImg = require("./assets/images/leaveImg.png");
export const Tractor = require("./assets/images/Tractor.png");
export const searchImg = require("./assets/images/search.png");
export const locationImg = require("./assets/images/placeholder.png");
export const farm1 = require("./assets/images/farm1.png");
export const farm2 = require("./assets/images/farm2.png");
export const farm3 = require("./assets/images/farm3.png");
export const farm1Big = require("./assets/images/farm3x.png");
export const product = require("./assets/images/veggies.png");
export const bringer = require("./assets/images/bringerS.png");
export const brocali = require("./assets/images/brocaaliS.png");
export const cabbage = require("./assets/images/cabbageS.png");
export const bigCarrotImg = require("./assets/images/newCarrotImg.png");
export const cherry = require("./assets/images/cherry.png");
export const meat = require("./assets/images/meat.png");
export const carrot = require("./assets/images/charretImg.png");
export const drink = require("./assets/images/drink.png");
export const nuts = require("./assets/images/nuts.png");
export const salad = require("./assets/images/salad.png");
export const watermelon = require("./assets/images/watermelon.png");
export const meatBig = require("./assets/images/meat1.png");
export const soup = require("./assets/images/hot-soup-1.png");
export const RestaurantCardImg = require("./assets/images/restaurantCardimg.png");
export const womenChefImg = require("./assets/images/group-6.png");
export const carrotBanner = require("./assets/images/carratred.png");
export const BannerImg1 = require("./assets/images/banner1.png");
export const ChoseusImg1 = require("./assets/images/choseus-img1.png");
export const ChoseusImg2 = require("./assets/images/choseus-img2.png");
export const ChoseusImg3 = require("./assets/images/choseus-img3.png");
export const MentorIcon = require("./assets/images/icons/mentor-icon.png");
export const AwardIcon = require("./assets/images/icons/award-icon.png");
export const UsersIcon = require("./assets/images/icons/users-icon.png");
export const CourseImg1 = require("./assets/images/course1.png");
export const CourseImg2 = require("./assets/images/course2.png");
export const CourseImg3 = require("./assets/images/course3.png");
export const RightArrowIcon = require("./assets/images/icons/right-arrow-icon.png");
export const LeafWithoutBG = require("./assets/images/LeafWithoutBG.png");
export const LeafHand = require("./assets/images/PlantHand.png");
export const RedDustbin = require("./assets/images/red-dustbin.png");
export const EditPencil = require("./assets/images/edit-pencil.png");
export const WhiteDustbin = require("./assets/images/white-dustbin.png");
export const WhitePencil = require("./assets/images/white-edit.png");
export const LinkedInLogo = require("./assets/images/linkedin-logo.png");
export const FacebookLogo = require("./assets/images/facebook-logo.png");
export const YouTubeLogo = require("./assets/images/youtube-logo.png");
export const TwitterLogo = require("./assets/images/twitter-logo.png");
export const InstagramLogo = require("./assets/images/instagram-logo.png")
export const BlueBg = require("./assets/images/blue-bg.png");
export const MenuIcon = require("./assets/images/menu.png")


export const DottedSideBar = require("./assets/images/dottedsidebar.png");
export const GreenOverlayBanner = require("./assets/images/green_overlayed_restaurants_banner.jpg");
export const filter_restaurants_green_icon = require("./assets/images/filter_restaurants_green_icon.png");

export const closeModelWhiteIcon = require("./assets/images/closeModalWhiteIcon.png");
export const yellow_edit_icon = require("./assets/images/edit_yellow_icon.png");
export const copy_icon = require("./assets/images/copy.png");
export const scan_QR_code = require("./assets/images/scan_Qr_code.png");
export const DoneImg = require("./assets/images/done.png");
export const VeggiesBowl = require("./assets/images/veggies-bowl.png");
export const trash_red_icon = require("./assets/images/trash.png");

export const chat_icon = require("./assets/images/icons/bubble-chat.png");

export const like_icon = require("./assets/images/icons/like.png");
export const like_fill_icon = require("./assets/images/icons/like-fill.png");
export const FacebookIcon = require ("./assets/images/image-facebook.png");
export const InstagramIcon = require ("./assets/images/image-instagram.png");
export const TwitterIcon = require ("./assets/images/image-twitter.png");

export const PicnicBasket = require("./assets/images/picnic-basket.svg");
export const Plant = require("./assets/images/plant.svg");
export const Farming = require("./assets/images/farming.svg");

export const GrassCut = require("./assets/images/GrassCut.png");
export const HappyFaces = require("./assets/images/HappyFaces.png");
export const SunsetFarm = require("./assets/images/SunsetFarm.png");
export const DinnerSet = require("./assets/images/DinnerSet.png");
export const OpenBook = require("./assets/images/OpenBook.png");
export const VegBasket = require("./assets/images/VegBasket.png");
export const QuoteImage = require("./assets/images/Quote.png");
export const RightActive = require("./assets/images/RightActive.svg");
export const LeftDisable = require("./assets/images/LeftDisable.svg");
export const OldHome = require("./assets/images/OldHome.png"); 
export const Flower = require("./assets/images/Flower.png");
export const WomenAvatar = require("./assets/images/women.png");
export const Rings = require("./assets/images/Rings.svg");
export const Bubbles = require("./assets/images/Bubbles.svg");
export const ArrowImg = require("./assets/images/border-arrow.png");
export const GreenFilter = require("./assets/images/GreenFilter.svg");
export const GreenCart = require("./assets/images/cart.svg");
export const DefaultProfile = require("./assets/images/default-profile.svg");
export const BrandLogo = require("./assets/images/logo.png");
export const SearchYellow = require("./assets/images/search-yellow.png");
export const FollowIcon = require("./assets/images/icons/follow-icon.svg");
export const FollowingIcon = require("./assets/images/icons/following-icon.svg");
export const CommentGreenIcon = require("./assets/images/icons/comment-icon.png");
export const FilterIcon = require("./assets/images/icons/filter-icon.svg")
export const XIcon = require("./assets/images/twitter-icon.svg")
export const Basket = require("./assets/images/basket.png")
export const BasketGreen = require("./assets/images/basket-green.png")
export const Dinner = require("./assets/images/dinner-set.png")
export const DinnerGreen = require("./assets/images/dinner-set-green.png")
export const Magnify = require("./assets/images/magnify.png")
export const MagnifyGreen = require("./assets/images/magnify-green.png")
export const OpenBookIcon = require("./assets/images/open-book.png")
export const OpenBookIconGreen = require("./assets/images/open-book-green.png")

// REDUX IMPORT

export const {
        updateActiveTab,
        getAddressListAction,
        setCheckoutOrder,
        setOrderReceiveType,
        setOrderAddress,
        setOrderPaymentType,
        setOrderCouponCode,
        setOrderCouponDiscount,
        setOrderNote,
        getCartItems,
        handleCartLoader,
        setCartItems,
        updateItemQuantity,
        removeCartItem,
        setCartBadge,
        resetUserDetails,
        toggleSidebar
} = require("../../../components/src/redux/Users/userActions")

export const {
        getFarmListAction,
        setFarmsCurrentPage,
        getFarmDetailsAction,
        addRatingFarmAction,
        addToFavoriteAction,
        resetFarmFilters
} = require("../../../components/src/redux/Farms/farmsActions")

export const {
        setFarmProductsSelectedCategory,
        setFarmsProductsCurrentPage,
        getFarmProductListAction,
        addRatingFarmProductsAction,
        getFarmProductsDetailsAction,
        addRatingFarmItemAction,
        setProductsFilterBySearch,
        setProductsFilterPrice,
        addToFavoriteProdAction,
        setProductsFilterSearchText,
        resetProductCatalogueFilters,
        toggleFarmForumLike,
        handleFarmForumCommentCount
} = require("../../../components/src/redux/FarmsCatalogue/farmsCatalogueActions")

export const {
        getRestaurantsListAction,
        setRestaurantsCurrentPage,
        getRestaurantsPopularCategories,
        setRestaurantsSelectedCategory,
        setRestaurantFilterPrice,
        getMapViewRestaurants,
        setSearchValueForRestaurant,
        getRestaurantDetailsAction,
        resetMapViewRestaurant,
        resetRestaurantDetails,
        toggleRestaurantForumLike,
        handleResForumCommentCount,
        resetRestaurantFilters,
        addRatingRestaurantAction
} = require("../../../components/src/redux/Restaurant/RestaurantActions")

export const {
        setWLLPData,
        setLPData,
        setRLPData,
        removeCMSFavFarm,
        removeCMSFavRestaurant,
        removeCMSFavCourse
} = require("../../../components/src/redux/CMS/cmsActions")      

export const {
        setCommunitySearchDialog,
        setCommunityHomeDesc,
        setCommunityLatestForums,
        setDiscussionFilter,
        setDiscussionActiveTopic,
        setDiscussionTopics,
        setCommunityDiscussions,
        setCommunityTagFilterDiscussions,
        setCommunityExpertsList
} = require("../../../components/src/redux/Community/communityActions")
      