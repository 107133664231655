// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
})

exports.productApiContentType = "application/json"
exports.apiMethodTypeGet = "GET"
exports.apiMethodTypePost = "POST"
exports.apiMethodTypePut = "PUT"
exports.apiMethodTypePatch = "PATCH"
exports.apiMethodTypeDelete = "DELETE"
exports.productAPiEndPoint = "catalogue/catalogues"
exports.getRestOrderApiEndPoint = "user_admin/orders/orders"
exports.getRestaurantDetailsApiEndPoint = "user_admin/restaurants"
exports.getAllAvailabilities = "user_admin/restaurant_availabilities"
exports.deleteRestAPiEndPoint = "user_admin/restaurants/"
exports.getAllRestaurants = "user_admin/restaurants"
exports.postDeliveryAPiEndPoint = "bx_block_catalogue/delivery_time_settings"
exports.postSendOtpAPiEndPoint = "account_block/accounts/send_email_otp"
exports.postVerifyOtpAPiEndPoint = "account_block/accounts/verify_email_otp"
exports.postcreateRestaurantAPiEndPoint = "user_admin/restaurants"
exports.getFarmsAPiEndPoint = "user_admin/farms"
exports.getCategoryAPiEndPoint =
  "bx_block_catalogue/categories?category_type=farm"
exports.getProductListAPiEndPoint = "user_admin/products/index?"
exports.deleteProductAPiEndPoint = "user_admin/products/destroy"
exports.updateProductStatusAPiEndPoint = "user_admin/products/update"
exports.getProductDetailsAPiEndPoint = "user_admin/products/show"
exports.postCreateFarmProductApiEndPoint = "user_admin/products/create"
exports.postUpdateFarmProductApiEndPoint = "user_admin/products/update"
exports.getForumsListAPiEndPoint = "/user_admin/forums?"
exports.deleteImageAPiEndPoint = "user_admin/products/remove_image?id="
exports.updateForumStatusAPiEndPoint = "user_admin/forums/"
exports.bulkActionsAPiEndPoint = "user_admin/forums/bulk_actions"
exports.bulkActionsProductsAPiEndPoint = "user_admin/products/bulk_product"
exports.createForumAPiEndPoint = "user_admin/forums"
exports.getForumDetailsAPiEndPoint = "user_admin/forums/"
exports.createcampaignadmin = "user_admin/campaigns"
exports.Editcampaignadmin = "user_admin/campaigns"
exports.createforumadmin = "user_admin/forums"
exports.Editforumadmin = "user_admin/forums"
exports.downloadDocsApiEndPoint = "user_admin/restaurants/download_pdf"
exports.deleteReviewAPiEndPoint = "bx_block_catalogue/reviews/"
exports.createReviewsAPIEndPoint = "bx_block_catalogue/reviews"
exports.addReplyAPiEndPoint = "bx_block_catalogue/reply_reviews"
exports.deleteFarmAPiEndPoint = "user_admin/farms/"
exports.sendOtpAPiEndPoint = "account_block/accounts/send_otp"
exports.verifyOtpAPiEndPoint = "/account_block/accounts/varify_otp"
exports.postCreateFarmApiEndPoint = "user_admin/farms"
exports.UpdateFarmApiEndPoint = "bx_block_catalogue/farms/"
exports.getCampaignListAPiEndPoint = "user_admin/campaigns"
exports.bulkActionsCampaignsAPiEndPoint = "user_admin/campaigns/bulk_actions"
exports.CreateDishApiEndpoint = "user_admin/dishes"
exports.dishCategoriesApiEndPoint =
  "bx_block_catalogue/categories?category_type=restaurant"
exports.removeImageApiEndPoint =
  "bx_block_catalogue/restaurants/remove_image?id="
exports.removePdfApiEndPoint = "user_admin/restaurants/remove_pdf?id="
exports.bulkStatusUpdateEndPoint = "user_admin/dishes/bulk_update"
exports.removeAddOnTypeEndPoint = "user_admin/dishes/remove_addon_type?id="
exports.removeSingleAddOnEndPoint = "user_admin/dishes/remove_addon?id="
exports.updateDishStatusEndPoint = "user_admin/dishes/change_status"
exports.getOrdersListAPiEndPoint = "user_admin/orders/orders?"
exports.getCourseListAPiEndPoint = "bx_block_library/courses"
exports.bulkActionsCoursesAPiEndPoint = "bx_block_library/courses/bulk_actions"
exports.getCoursesCategoryAPiEndPoint =
  "bx_block_catalogue/categories?category_type=e-learning"
exports.createUpdateWeightDimensionsApiEndPoint =
  "user_admin/products/create_or_update_variant"
exports.getWeightDimensionsApiEndPoint = "user_admin/products/variants"
exports.removeWeightDimensionApiEndPoint = "user_admin/products/delete_variant"

exports.getEducatorsAPiEndPoint = "user_admin/educators"
exports.getCourseDetailsAPiEndPoint = "bx_block_library/courses"
exports.createPartCoursesAPiEndPoint = "bx_block_library/course_sections"
exports.createlessonCoursesApiEndPoint = "bx_block_library/course_sub_sections"
exports.createMcqTaskAPiEndPoint = "bx_block_library/tasks"
exports.getPurchaseApiEndPOint = "bx_block_order_management/purchases"
exports.deleteOptionAPiEndPoint = "bx_block_library/options/"
exports.bulkActionResAvailApiEndPoint =
  "user_admin/restaurant_availabilities/bulk_update"
exports.deleteOrderRuleApiEndPoint = "user_admin/order_price_rules/"
exports.RESTAURANT_CAMPAIGN_TYPE = "Restaurant"
exports.getCourseListInfoAPiEndPoint = "bx_block_library/courses/course_info"
exports.AUTH_TOKEN = "authToken"
exports.createDiscussionAPiEndPoint = "bx_block_library/discussions"
exports.getStudentsAPiEndPoint = "bx_block_library/students"
exports.getContributorApiEndPoint =
  "bx_block_catalogue/educational_contributors"
exports.updateOrderStatusApiEndPoint = "user_admin/orders/order_in_transit"
exports.getDiscussionNotificationAPiEndPoint =
  "bx_block_library/discussions/discussion_notification?"
exports.addCampaignLink = "/admin/learning/add-campaigns?mode=edit&campaign_id="
exports.getIconsAPiEndPoint = "/bx_block_catalogue/quality_icons"
exports.statusMenus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Deactivate",
    value: "deactive",
  },
  {
    label: "Draft",
    value: "draft",
  },
]
exports.pending = "pending"
exports.VerificationPending = "Verification Pending"
exports.statusText = "status"
exports.bottomText = "bottom"
exports.leftText = "left"
exports.topText = "top"
exports.centerText = "center"
exports.deactivateText = "deactivate"
exports.deactiveText = "deactive"
exports.createText = "create"
exports.createCampaignText = "Create Campaign"
exports.editCampaignText = "Edit Campaign"
exports.campaignStatusMenu = ["active", "draft"]
exports.editText = "edit"
exports.activeText = "active"
exports.draftStatus = "draft"
exports.warningVariantText = "warning-variant"
exports.actionsText = "actions"
exports.modeText = "mode"
exports.campaignsText = "campaigns"
exports.campaignIdText = "campaign_id"
exports.campaignRemovalMsg = "Campaign removed successfully"
exports.campaignUpdateMsg = "Campaign status updated successfully"
exports.campaignSuccessText = "Campaign successfully"
exports.addedText = "added"
exports.updatedText = "updated"
exports.fieldValidateText = "This field is required."
exports.descValidateText = "Description must be at most 1000 characters."
exports.donationValidateText = "Amount is not valid"
exports.learningText = "learning"
exports.removeText = "remove"
exports.requiredDonationText = "required_donation"
exports.dishText = "Dish"
exports.vegText = "Veg"
exports.dishTitleText = "Dish Title"
exports.descriptionText = "Description"
exports.dishImageText = "Dish Image"
exports.pricingText = "Pricing"
exports.dishPriceText = "Dish Price"
exports.compareAtPriceText = "Compare at Price"
exports.addOnsText = "Add Ons"
exports.addOnNameText = "Add On Name"
exports.priceText = "Price"
exports.addOnDetailsPlaceholder = "Double cheese, extra jam, etc..."
exports.addOnDetailPriceText = "Enter price for add on"
exports.newAddOnTypeText = "+ New Add On Type"
exports.cancelText = "Cancel"
exports.productStatusText = "Product status"
exports.deleteText = "Delete"
exports.dishCategoryText = "Dish Category"
exports.selectCategoryText = "Select a category"
exports.dishCategoryHeadingText =
  "Improve buyer discovery, analytics & workflows."
exports.tagsText = "TAGS"
exports.tagsPlaceholderText = "Fresh fruit, Healthy Food"
exports.deleteDishMessageText = "Are you sure you want to delete the dish ?"
exports.noText = "No"
exports.yesText = "Yes"
exports.allowedExtensionText = ["image/jpeg", "image/jpg", "image/png"]
exports.createButtonText = "Create"
exports.saveText = "Save"
exports.updateDishMessage = "Dish updated successfully"
exports.createDishMessage = "Dish created successfully"
exports.editDishText = "Edit"
exports.updateDishText = "Update"
exports.dishRemovalMessage = "Dish removed successfully"
exports.completeAddOnMessage = "Please complete current add on!"
exports.fillAddOnCategoryMessage = "Please fill add on category."
exports.fillAllFieldsMessage = "Please fill all fields in addons."
exports.facebookRegex = new RegExp(
  /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i
)
exports.instagramRegex = new RegExp(
  /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i
)
exports.editBannerText = "Edit Banner"
exports.dragFileText = "Drag and drop a file or click here"
exports.restaurantLabel = "restaurant"
exports.likesText = "Likes"
exports.commentsText = "Comments"
exports.saveChangesText = "Save Changes"
exports.headingText = "Heading"
exports.forumHeadingPlaceholder = "Write forum heading here..."
exports.forumDescPlaceholder = "Write forum Description here..."
exports.commentPlaceholder = "Write your comment here..."
exports.replyText = "reply"
exports.sendBtnText = "Send"
exports.replyEditText = "replyEdit"
exports.deleteForumText = "Are you sure you want to delete the forum ?"
exports.forumIdText = "forum_id"
exports.forumsText = "forums"
exports.tokenExpiredText = "Token has Expired"
exports.invalidTokenText = "Invalid token"
exports.forumsUpdatedText = "Forums status updated successfully"
exports.forumStatusUpdateText = "Forum status updated successfully"
exports.forumSuccessText = "Forum successfully "
exports.forumRemovalMsg = "Forum removed successfully"
exports.deleteReviewMsg = "Review Deleted Successfully"
exports.replyRemovalMsg = "Reply Deleted Successfully"
exports.forumEndPoint = "/admin/restaurant/forums/"
exports.restaurantEndPoint = "BxBlockCatalogue::Restaurant"
exports.UpdatedText = "Updated"
exports.AddedText = "Added"
exports.donationAPIEndpoint = "BxBlockDonationpayments::Forum"
exports.createForumText = "Create Forum "
exports.editForumText = "Edit Forum"
exports.allText = "all"
exports.acceptRejectOrderAPIEndpoint =
  "user_admin/orders/accept_or_reject_order"
exports.setDeliveryTimeAPIEndpoint = "user_admin/orders/set_delivery_time"
exports.orderyReadyAPIEndpoint = "user_admin/orders/order_ready"
exports.pickupOrderAPIEndpoint = "user_admin/orders/order_pickup"
exports.orderDeliveredAPIEndpoint = "user_admin/orders/order_delivered"
exports.acceptingOrderAPIEndpoint = "user_admin/orders/accepting_order"
exports.todaysReportAPIEndpoint = "user_admin/orders/todays_orders"
exports.weeklyReportAPIEndpoint = "user_admin/orders/weekly_orders"
exports.monthlyReportAPIEndpoint = "user_admin/orders/current_monthly_orders"
exports.removeVariantApiEndPoint = "bx_block_catalogue/variants"
exports.removeVariantOptionApiEndPoint = "bx_block_catalogue/variant_options"
exports.orderStatusUpdateApiEndpoint = "orders/order_in_transit"
exports.getGlobalRewardPointApiEndPoint = "others/points_acceptance_percentage"
exports.adCampaignApiEndPoint = "user_admin/campaign_ads"
exports.statusUpdateAdCampaignApiEndPoint = "user_admin/campaign_ads/status_update"
exports.adCampaignRatesApiEndPoint = "others/get_ad_campaign_rates"
exports.placedText = "placed"
exports.preparingText = "preparing"
exports.readyText = "ready"
exports.pickedUpText = "picked_up"
exports.deliveredText = "delivered"
exports.rejectedText = "rejected"
exports.dateFormat = "DD/MM/YYYY"
exports.monthFormat = "mmmm"
exports.dateMonFormat = "dd mmmm"
exports.dateMonYearFormat = "dd mmmm, yyyy"
exports.reportsText = "Reports"
exports.todayText = "Today"
exports.zeroDollarText = `$0`
exports.zeroOrdersText = `0 orders`
exports.thisWeekText = "This week"
exports.thisMonthText = "This month"
exports.ordersText = "Orders"
exports.selectRestText = "Select Restaurant"
exports.acceptingOrderText = "Accepting Order"
exports.greenSwitchClass = "greenSwitch"
exports.redSwitchClass = "redSwitch"
exports.yesText = "YES"
exports.noText = "NO"
exports.restOrderPlaceholderText = "Search by Name, type, Id, etc... "
exports.btnContainerClass = "btnContainer"
exports.todayBtnClass = "todayBtn"
exports.priceText = "price"
exports.compareAtPriceErrorMsg = "Compare at Price must be greater than 0"
exports.titleValidateMsg = "Title should be of maximum 100 characters"
exports.compareAtPricePositiveMsg = "Compare At Price should be positive"
exports.comparePriceError = "Compare At Price must be less than"
exports.dishPricePositiveMsg = "Dish Price should be positive"
exports.dishPriceErrorMsg = "Dish Price must be greater than 0"
exports.dishMaxPriceErrMsg = "Price must be less than or equal to 50000"
exports.titlePlaceholderText = "Apple, Grapes...."
exports.addOnCategoryPlaceholder = "Add on category"
exports.campaignNameLabel = "CAMPAIGNS NAME"
exports.campaignStatusLabel = "STATUS"
exports.campaignIdLabel = "CAMPAIGNS ID"
exports.raisedDonationLabel = "RAISED DONATION"
exports.options = ["edit", "remove"]
exports.campaignsLabel = "Campaigns"
exports.actionsLabel = "Actions"
exports.routeURL = "/admin/restaurant/add-campaigns?mode=create"
exports.campaignTitle = "Campaign"
exports.itemsText = "items"
exports.roundedText = "rounded"
exports.campaignDelDialogText = "Are you sure you want to delete the campaign ?"
exports.pathName = "/admin/restaurant/campaigns"
exports.isPositiveLabel = "Is positive?"
exports.numberGreaterThanZero = "The number must be greater than 0!"
exports.editCampaignURL =
  "/admin/restaurant/add-campaigns?mode=edit&campaign_id="
exports.sortingType = ["Default", "High To Low", "Low To High", "Latest"]
exports.dishesLabel = "Dishes"
exports.allDishesText = "All Dishes"
exports.selectRestaurantText = "Select restaurant"
exports.defaultText = "default"
exports.restDishesPlaceholder = "Search by name, type, id, etc... "
exports.actionText = "Action"
exports.noDishesListedText = "No dishes listed"
exports.uploadDishesText = "Upload dishes by clicking"
exports.createDishBtnText = '"Create Dish" button'
exports.createDishText = "Create Dish"
exports.activeLabel = "Active"
exports.deactiveLabel = "Deactivate"
exports.draftLabel = "Draft"
exports.removeDishText = "Are you sure you want to remove the dish ?"
exports.dishNameLabel = "DISH NAME"
exports.statusLabel = "Status"
exports.idLabel = "#ID"
exports.forumsLabel = "Forums"
exports.createForumBtn = "Create Forum"
exports.forumTitle = "Forum"
exports.forumNameLabel = "FORUM NAME"
exports.forumIdLabel = "FORUM id"
exports.likesLabel = "LIKES"
exports.commentsLabel = "COMMENTS"
exports.listingMenu = ["edit"]
exports.farmListText = "Farm List"
exports.newFarmLabel = "New Farm"
exports.createFarmRouteURL = `/admin/farm/add-farm?mode=create`
exports.farmTitle = "Farm"
exports.donationsLabel = "Donation"
exports.requiredDonationLabel = "Required Donation"
exports.donationRaisedText = "Donation Raised"
exports.raisedText = "Raised"
exports.donnerLabel = "Donners"
exports.noDonationReceivedText = "No donation received yet"
exports.taglineLabel = "Tagline"
exports.taglinePlaceholderText = "Write campaign tagline here..."
exports.campaignHeadingPlaceholderText = "Write campaign heading here..."
exports.campaignDescrPlaceholderText = "Write campaign Description here..."
exports.learningCampaignsPath = "/admin/learning/campaigns"
exports.learningCreateCampaignPath = `/admin/learning/add-campaigns?mode=create`
exports.sortType = ["default", "high to low", "low to high"]
exports.purchasesText = "Purchases"
exports.purchaseDetailsText = "Purchase Details"
exports.purchasedCourseText = "Purchased course"
exports.courseNameText = "Course Name"
exports.purchasedByLabel = "Purchased By:"
exports.purchaseDateLabel = "Purchase Date:"
exports.paymentLabel = "Payment"
exports.transactionIdLabel = "Transaction ID:"
exports.paymentAmountLabel = "Payment Amount:"
exports.orderDetailsLabel = "Order Details"
exports.notesLabel = "Notes"
exports.pickedUpLabel = "Picked up"
exports.printLabelLabel = "Print Label"
exports.trackingNumberLabel = "Tracking Number"
exports.quantityLabel = "Quantity"
exports.totalItemLabel = "Total Item"
exports.discountLabel = "Discount"
exports.loyaltyPointsLabel = "Loyalty points"
exports.subTotalLabel = "Sub total"
exports.transactionFeesLabel = "Transaction fees"
exports.shippingLabel = "Shipping"
exports.totalLabel = "Total"
exports.deliveringToLabel = "Delivery to"
exports.pickUpFromLabel = "Pickup from :"
exports.priceLabel = "Price"
exports.itemLabel = "Item"
exports.itemsLabel = "Items"
exports.pickUpLabel = "pickup"
exports.pointsTransactionsLabel = "Points Transactions"
exports.cancelledText = "Cancelled"
exports.customerLabel = "Customer Details"
exports.SomethingWentWrongMessage = "Something went wrong"
exports.removeWeightDimensionMessage = "Are you sure you want to delete?"
exports.removeVariantsMessage = "Are you sure you want to remove variants?"
exports.MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
}
exports.statusList = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "deactive",
    label: "Deactivate",
  },
]
exports.disabledStatusList = ["rejected", "refunded"]
// Customizable Area End
