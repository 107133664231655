import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends RouteComponentProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    activeTab: string
    courses : any
    searchCourse : string
    setCourse : (value: string) => void
    updateActiveTab: (tab: string) => void
    getPopularCoursesLearning : () => void
    getLearningDetails : () => void
    resetSearchFilters : () => void
    classes : {
        headerWrapper : string,
        headerContainer : string,
        imageLoader : string,
        courseBannerImage: string,
        viewAllBtn : string,
        enrollNowBtn : string
    }
    // Customizable Area End
}

interface S{
    // Customizable Area Start
    imageLoaded : boolean
    currentSlidesToShow: number
    isDragging: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class LandingPageController extends BlockComponent<
Props,
S,
SS
>{
// Customizable Area Start
sliderRef: any = React.createRef()
  private navigationPrevRef: React.RefObject<HTMLDivElement>;
  private navigationNextRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.navigationPrevRef = React.createRef<any>();
        this.navigationNextRef = React.createRef<any>();
        this.state = {
            imageLoaded : false,
            currentSlidesToShow: 3,
            isDragging: false
        }
        // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        const { activeTab, updateActiveTab,getPopularCoursesLearning, getLearningDetails  } = this.props
    activeTab !== ActiveTabEnum.Learning && updateActiveTab(ActiveTabEnum.Learning)
        
        getPopularCoursesLearning()
        getLearningDetails()
        // Customizable Area End
    }
    // Customizable Area Start
    searchCourseByEnter = (e:any) => {
        e.key === "Enter" && this.handleSearchCourse()
    }

    handleSearchCourse = () => {
        const {searchCourse, history} = this.props
        if(searchCourse){
            history.push('/learning/courses')
        }
    }

    handleCourseCard = async(a:any) => {
        this.props.history.push("/learning/courses/single?id=" + a)
    }

    handleButton = async() => {
        this.props.resetSearchFilters()
        this.props.history.push('/learning/courses')
    }

    handleSearchValue = (e: any) => this.props.setCourse(e.target.value)

    handleOnReInit = () => {
        const { currentSlidesToShow } = this.state
        if (!!this.sliderRef?.current) {
            const updatedSlideToShow =
                this.sliderRef.current.innerSlider.props.slidesToShow
            currentSlidesToShow !== updatedSlideToShow &&
                this.setState({ currentSlidesToShow: updatedSlideToShow })
        }
    }

    handleBeforeChange = () => {
        this.setState({ isDragging: true })
    }

    handleAfterChange = () => {
        this.setState({ isDragging: false })
    }

    handleImageLoad = () => {
        this.setState({imageLoaded : true})
    }

    // Customizable Area End
}