// Customizable Area Start
import React from "react"
import { Box, CircularProgress, Container, Typography } from "@material-ui/core"
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded"
import { withRouter } from "react-router-dom"
import SearchController from "./SearchController.web"
import GreenBanner from "../../../components/src/GreenBanner.web"
import WebFooter from "../../../components/src/WebFooter.web"
import SearchProductsList from "./components/SearchProductsList.web"
import SearchDishesList from "./components/SearchDishesList.web"
import SearchFarmsList from "./components/SearchFarmsList.web"
import SearchRestaurantsList from "./components/SearchRestaurantsList.web"
import SearchCoursesList from "./components/SearchCoursesList.web"

export class Search extends SearchController {
  render() {
    const {
      loader,
      searchQuery,
      products,
      dishes,
      farms,
      restaurants,
      courses,
      viewAllList,
    } = this.state

    const isEmptyProducts = products.length === 0
    const isEmptyDishes = dishes.length === 0
    const isEmptyFarms = farms.length === 0
    const isEmptyRestaurants = restaurants.length === 0
    const isEmptyCourses = courses.length === 0
    const isEmptyList =
      isEmptyProducts &&
      isEmptyDishes &&
      isEmptyFarms &&
      isEmptyRestaurants &&
      isEmptyCourses

    if (loader) {
      return (
        <Box style={styles.loaderWrapper}>
          <CircularProgress size={40} style={styles.loader} />
        </Box>
      )
    }

    return (
      <>
        <Container fixed>
          {isEmptyList ? (
            <Box marginY={4} style={styles.emptyWrapper}>
              <ErrorOutlineRoundedIcon
                fontSize="large"
                style={styles.errorIcon}
              />
              <Typography style={styles.emptyMessage}>
                No results found
              </Typography>
            </Box>
          ) : (
            <Box style={styles.mainWrapper}>
              <SearchProductsList
                searchQuery={searchQuery}
                isViewAll={viewAllList.farm_catalogues_view_all}
                productsList={products}
              />

              <SearchDishesList
                searchQuery={searchQuery}
                isViewAll={viewAllList.dishes_view_all}
                dishesList={dishes}
              />

              <SearchFarmsList
                searchQuery={searchQuery}
                isViewAll={viewAllList.farms_view_all}
                farmsList={farms}
              />

              <SearchRestaurantsList
                searchQuery={searchQuery}
                isViewAll={viewAllList.restaurants_view_all}
                restaurantsList={restaurants}
              />

              <SearchCoursesList
                searchQuery={searchQuery}
                isViewAll={viewAllList.courses_view_all}
                coursesList={courses}
              />
            </Box>
          )}
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

const styles = {
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "70vh",
  },
  loader: {
    color: "#20a258",
  },
  emptyWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    height: "60vh",
    width: "100%",
    borderRadius: "12px",
  },
  errorIcon: {
    color: "#333",
  },
  emptyMessage: {
    fontSize: "1.5rem",
    color: "#333",
  },
  mainWrapper: {
    height: "100%",
    width: "100%",
    padding: "30px 0px",
  },
}

export default withRouter(Search)
// Customizable Area End
