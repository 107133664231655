// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config.js");
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  updateActiveTab: any
}

interface S {
  aboutUs: any;
  loading: any

}

interface SS {
  id: any;
}

export default class AboutUsController extends BlockComponent<Props, S, SS> {
  getAboutUSApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      aboutUs: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  componentDidMount = async () => {
    !!this.props?.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.AboutUs)
    this.getAboutUS();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.successApiCall(responseJson, apiRequestCallId);
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getAboutUSApiCallId) {

      this.setState({
        loading: false,
        aboutUs: responseJson.data,
      });
    }
  };

  getAboutUS = async () => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAboutUSApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAboutUsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End
