// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { IBlock } from "../../../../../framework/src/IBlock"
import { RouteComponentProps } from "react-router-dom"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./RewardPointsSetting.web"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps, WithStyles<typeof styles> { }

interface S {
  rewardPoints: number
  isPointTransferDialog: boolean
}

interface SS {
  id: any
}

export default class RewardPointsSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getRewardPointsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]
    this.receive = this.receive.bind(this)

    this.state = {
      isPointTransferDialog: false,
      rewardPoints: 0,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }


  async componentDidMount(): Promise<void> {
    this.getRewardPointsApi()
  }

  openPointTransferDialog = () => {
    this.setState({ isPointTransferDialog: true })
  }

  closePointTransferDialog = () => {
    this.setState({ isPointTransferDialog: false })
  }

  redirectTransactionHistoryPage = () => {
    this.props.history.push(AppRoutings.TransactionHistory)
  }

  getRewardPointsApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getRewardPointsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardPointsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const isRewardPointsApiResponse =
        apiRequestCallId === this.getRewardPointsApiCallId
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (isRewardPointsApiResponse) {
          this.setState({ rewardPoints: responseJson.reward_points })
        }
      } else if (isRewardPointsApiResponse) {
        toast.error(configJSON.fetchBalanceErrorMessage)
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
