// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { WithStyles } from "@material-ui/core/styles";
import { styles } from "./OrderCancelApproveDialog.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
const configJSON = require("./config.js");

export type BUTTON_TYPES = "success" | "close" | "error";

export interface Props extends WithStyles<typeof styles> {
  orderId: string;
  isOpen: boolean;
  currentStep?: 0 | 1 | 2;
  cancellationByUserAdmin?: boolean;
  onCancellationSuccess: () => void;
  handleClose: () => void;
}

interface S {
  isLoading: boolean;
  step: 0 | 1 | 2;
  refundMessage: string;
  apiResponseErrors: {
    message: string;
  }[];
}

interface SS {
  id: any;
}

export default class OrderCancelApproveDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  cancelOrderApiCallId: string = "";
  initiateRefundApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isLoading: false,
      step: 0,
      refundMessage: "",
      apiResponseErrors: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.handleCurrentStep();
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { currentStep } = this.props;
    if (prevProps.currentStep !== currentStep) {
      this.handleCurrentStep();
    }
  }

  handleCurrentStep = () => {
    const { currentStep } = this.props;

    if (currentStep) {
      this.setState({ step: currentStep });
    }
  };

  handleDialogClose = () => {
    const { handleClose, onCancellationSuccess } = this.props;

    if (!this.state.isLoading) {
      handleClose();
      this.setState({
        step: 1,
        apiResponseErrors: [],
        isLoading: false,
        refundMessage: ""
      })
      onCancellationSuccess();
    }
  };

  setThirdStep = () => {
    this.setState({ step: 2 });
  };

  handleFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ refundMessage: value });
  };

  resetApiErrors = () => {
    this.setState({ apiResponseErrors: [] });
  };

  handleCancelOrder = () => {
    const httpBody = {
      id: this.props.orderId
    };

    this.resetApiErrors();
    this.cancelOrderApi(httpBody);
  };

  handleInitiateRefund = () => {
    const httpBody = {
      order_id: this.props.orderId,
      comment: this.state.refundMessage
    };
    this.resetApiErrors();
    this.initiateRefundApi(httpBody);
  };

  cancelOrderApi = async (httpBody: { id: string }) => {
    this.setState({ isLoading: true });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancellationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  initiateRefundApi = async (httpBody: {
    order_id: string;
    comment: string;
  }) => {
    this.setState({ isLoading: true });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.initiateRefundApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.initiateRefundApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSuccessResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.cancelOrderApiCallId) {
      this.setState({ isLoading: false, step: 1 });
    }

    if (apiRequestCallId === this.initiateRefundApiCallId) {
      this.setState({ isLoading: false, step: 1 });
      this.handleDialogClose();
    }
  };

  handleErrorResponse = (apiRequestCallId: string, responseJson: any) => {
    const isCancelOrInitiateApiCallIds = [
      this.cancelOrderApiCallId,
      this.initiateRefundApiCallId
    ].includes(apiRequestCallId);

    if (isCancelOrInitiateApiCallIds) {
      this.setState({
        isLoading: false,
        apiResponseErrors: responseJson.errors
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSuccessResponse(apiRequestCallId);
      } else {
        this.handleErrorResponse(apiRequestCallId, responseJson);
      }
    }
  }
  // Customizable Area End
}
