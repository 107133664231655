// Customizable Area Start
import React from "react"

interface IProps {
  isLatError: string | undefined
  isLatTouched: boolean | undefined
  isLngError: string | undefined
  isLngTouched: boolean | undefined
}

const CustomLocationErrorMessage = ({
  isLatError,
  isLatTouched,
  isLngError,
  isLngTouched,
}: IProps) => {
  return (isLatError && isLatTouched) || (isLngError && isLngTouched) ? (
    <span className="error">
      Please provide the location or directly enter latitude and longitude
    </span>
  ) : null
}

export default CustomLocationErrorMessage
// Customizable Area End
