// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
export const configJSON = require("./config");
import {isTokenExpired} from "../../../components/src/utility/helper"

import * as Yup from "yup";

export interface Props {
    navigation: any;
    id: string;
    history?: any;
    classes?: any;
    logout : any;
}

interface S {
    search : string
    loading : boolean 
    searchVal : string
    campaignList: any[];
    currentRestId: string;
    openDialog: boolean;
    removedProduct: string;
    bulkAction: string;
    productMode: string | null;
    campDetails: any;
    selectedCampaigns: string[];
    campaignsPerPage: number
    campaignsCurrentPage: number
    totalCampaignPage: number
    totalCampaignCount: number
}

interface SS {
    id: any;
}

export default class AdminLearningCampaignController extends BlockComponent<
Props,
S,
SS
> {
  getCampaignListApiCallId: any
  updateCampaignStatusApiCallId : any
  deleteCampaignApiCallId : any
  bulkActionsCampaignApiCallId : any
  createCampaignApiCallId : any
  getCampaignDetailsApiCallId : any
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
        ];
    
        this.state = {
            search : '',
            loading : false,
            searchVal : '',
            selectedCampaigns : [],
            campaignList: [],
            currentRestId: "",
            openDialog: false,
            removedProduct: "",
            bulkAction: configJSON.actionsText,
            productMode: "",
            campDetails: "",
            campaignsCurrentPage : 1,
            campaignsPerPage: 5,
            totalCampaignPage: 0,
            totalCampaignCount: 0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search)
        const mode = query.get(configJSON.modeText)
        this.setState({
          productMode: mode,
        });

        if (window.location.pathname === configJSON.learningCampaignsPath) {
          await this.getCampaignList(this.state.search);
        }
        if (mode === configJSON.editText) {
          await this.getCampDetails(query.get(configJSON.campaignIdText));
        }
    }

    async componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>) {
      const {
        campaignsCurrentPage,
        campaignsPerPage,
        currentRestId,
        searchVal
      } = this.state
  
      if (
        prevState.campaignsCurrentPage !== campaignsCurrentPage ||
        prevState.campaignsPerPage !== campaignsPerPage ||
        prevState.currentRestId !== currentRestId ||
        prevState.searchVal !== searchVal
      ) {
        await this.getCampaignList(searchVal)
      }
    }

    async receive(from: string, message: Message) {
        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (responseJson && !responseJson.errors) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
             await this.handleSuccessApiResponse(apiRequestCallId, responseJson)
          } else if (responseJson?.errors) {
            toast.error(responseJson?.errors[0].message)
            this.setState({
              loading: false
            })
            if (responseJson?.errors[0] && ((responseJson?.errors[0].message == configJSON.tokenExpiredText) || (responseJson?.errors[0].message == configJSON.invalidTokenText))) {
              this.props.logout()
            }
        }
        }
    }

    handleSuccessApiResponse = async(apiRequestCallId: string, responseJson: any) => {
      //get Forums list
      if (apiRequestCallId === this.getCampaignListApiCallId) {
        const {
          total_count,
          total_pages
        } = responseJson?.meta?.pagination
        const { campaignsCurrentPage } = this.state
  
        this.setState({
          campaignList: responseJson.data,
          loading: false,
          totalCampaignCount: total_count,
          totalCampaignPage: total_pages
        });
  
        if (responseJson?.data?.length === 0 && campaignsCurrentPage > 1) this.setState({ campaignsCurrentPage: campaignsCurrentPage - 1 })
      }
      //update forum status
      if (apiRequestCallId === this.updateCampaignStatusApiCallId) {
        await this.getCampaignList(this.state.search);
        this.setState({
          loading: false,
        });
        toast.success(`Campaign Status Updated Successfully `);
  
      }
  
      //delete forum
      if (apiRequestCallId === this.deleteCampaignApiCallId) {
        const mode = this.state.productMode == configJSON.editText
        this.setState({
          loading: false,
        });
        toast.success(configJSON.campaignRemovalMsg);
        mode && this.props.history.goBack()
      }
  
      // forum bulk actions
      if (apiRequestCallId === this.bulkActionsCampaignApiCallId) {
        await this.getCampaignList(this.state.search);
        this.setState({
          selectedCampaigns: [],
          loading: false,
        });
        toast.success(configJSON.campaignUpdateMsg);
      }
      // createForumApiCallId
      if (apiRequestCallId === this.createCampaignApiCallId) {
        const mode = this.state.productMode == configJSON.createText
        toast.success(`${configJSON.campaignSuccessText} ${mode ? configJSON.addedText : configJSON.updatedText}`)
        this.setState({
          loading: false,
        });
        this.props.history.goBack()
      }
  
      // get forum details
  
      if (apiRequestCallId === this.getCampaignDetailsApiCallId) {
        this.setState({
          campDetails: responseJson.data,
          loading: false,
        });
  
      }
    }

    Schema = () => {
      const donationAmount = /^(?:\d{1,5}(?:\.\d{1,2})?|99999(?:\.00)?)$/;
      return Yup.object().shape({
        heading: Yup.string().required(configJSON.fieldValidateText),
        tagline: Yup.string().required(configJSON.fieldValidateText),
        description: Yup.string()
        .max(1000, configJSON.descValidateText)
        .required(configJSON.fieldValidateText),
        banner: Yup.string().required(configJSON.fieldValidateText),
        required_donation: Yup.string()
          .test(
            'Is positive?', 
            'The number must be greater than 0!', 
            (value) => value > 0
          )
          .matches(donationAmount, configJSON.donationValidateText)
          .required(configJSON.fieldValidateText),
        });
    }

    getCampaignList = async (search: any) => {
      this.setState({
        loading: true,
      })
      const {
        campaignsPerPage,
        campaignsCurrentPage
      } = this.state
      const endpoint = `?search=${search}&campaign_type=Learning&page=${campaignsCurrentPage}&per=${campaignsPerPage}`;
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCampaignListApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCampaignListAPiEndPoint + endpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    //updating status
    updateCampaignStatus = async (id: any, status: any) => {
      this.setState({
        loading: true,
      });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
      };
  
      const httpBody = {
        campaign: {
          id: id,
          status: status,
        },
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateCampaignStatusApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCampaignListAPiEndPoint + `/${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePut
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    //remove product
    deleteCampaign = async () => {
      this.setState({
        loading: true,
      });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteCampaignApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCampaignListAPiEndPoint + `/${this.state.removedProduct}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeDelete
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    //bulk actions
    bulkActions = async (event: any) => {
      this.setState({
        loading: true,
      });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
      };
  
      const httpBody = {
        status: event.target.value,
        ids: this.state.selectedCampaigns,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.bulkActionsCampaignApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.bulkActionsCampaignsAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    //create forum
    createCampaign = async (values: any) => {
      this.setState({
        loading: true,
      });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN);
  
      const headers = {
        token: token,
      };
      const mode = this.state.productMode == configJSON.createText
  
      const endPoint = mode ? configJSON.getCampaignListAPiEndPoint : (configJSON.getCampaignListAPiEndPoint + `/${values.campaign_id}`)
      const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
      const formdata = new FormData();
      formdata.append("campaign[heading]", values.heading);
      formdata.append("campaign[tagline]", values.tagline.trim());
      formdata.append("campaign[description]", values.description);
      formdata.append("campaign[status]", values.status);
  
      if (values.banner?.length > 0) {
        values.banner[0].name && formdata.append("campaign[banner]", values.banner[0],
          values.banner[0].name)
      }
  
      formdata.append("campaign[campaign_type]", configJSON.learningText)
      formdata.append("campaign[required_donation]", values.required_donation)
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.createCampaignApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        apiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    //get Forum details
    getCampDetails = async (id: any) => {
  
      this.setState({
        loading: true,
      });
  
      const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
  
      const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCampaignDetailsApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.Editcampaignadmin + `/${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };
  
    handleClickCampaignsRow = (event: React.MouseEvent<unknown>, num: any) => {
      event.preventDefault();
      const selectedIndex = this.state.selectedCampaigns.indexOf(num);
  
      let newSelected: any[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selectedCampaigns, num);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selectedCampaigns.slice(1));
      } else if (selectedIndex === this.state.selectedCampaigns.length - 1) {
        newSelected = newSelected.concat(
          this.state.selectedCampaigns.slice(0, -1)
        );
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selectedCampaigns.slice(0, selectedIndex),
          this.state.selectedCampaigns.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selectedCampaigns: newSelected,
      });
    };
  
    isSelectedCampaigns = (num: any) =>
      this.state.selectedCampaigns.indexOf(num) !== -1;
  
    handleMenuClick = async(value: any, item: any) => {
      this.setState({
        removedProduct: item.id,
      });
      if (value == "edit") {
        this.props.history.push(
          configJSON.addCampaignLink + `${item.id}`
        );
        await this.getCampDetails(item.id)
      }
      if (value == configJSON.removeText) {
        this.handleDialog();
      }
    };

    handleCreateCampaign = () => {
      this.props.history.push(configJSON.learningCreateCampaignPath);
    }

    handleSearchCampaign = async (e: any) => {
      this.setState({
        search: e.target.value,
      });
      this.SearchDebounceUpdate(e.target.value);
    }
  
    handleDialog = () => {
      this.setState({
        openDialog: !this.state.openDialog,
      });
    };

    handleSelectAllClick = (event: any) => {
      if (event.target.checked) {
        const newSelected = this.state.campaignList.map((n: { id: string }) => n.id)
        this.setState({ selectedCampaigns: newSelected });
        return;
      }
      this.setState({ selectedCampaigns: [] })
    }

    handleChangeTablePage = (value: number) => {
      this.setState({ campaignsCurrentPage: value, selectedCampaigns: [] });
    }
  
    handleIncreasePageView = () => {
      if (this.state.campaignsPerPage < 25) {
        this.setState({ campaignsPerPage: this.state.campaignsPerPage + 5, selectedCampaigns: [] });
      }
      this.resettingPagination()
    }
  
    handleDecreasePageView = () => {
      if (this.state.campaignsPerPage > 5) {
        this.setState({ campaignsPerPage: this.state.campaignsPerPage - 5, selectedCampaigns: [] });
      }
      this.resettingPagination()
    }

    resettingPagination = () => {
      this.setState({
        campaignsCurrentPage: 1,
      })
    }

    handleSearchValChange = (value: string) => {
        this.setState({ searchVal: value, campaignsCurrentPage: 1, selectedCampaigns: [] })
    }

    handleDonationValueChange = (value: any, setFieldValue: (field: string, value: number) => void) => {
      const isNumber = !isNaN(value)
      if(isNumber) setFieldValue(configJSON.requiredDonationText, value)
    }

    SearchDebounce = (call: any, delay: any) => {
        let timer: any;
        return async function (...args: any) {
          clearTimeout(timer);
          timer = setTimeout(async () => {
            await call(...args)
    
    
    
          }, delay);
        };
      }
    
      SearchDebounceUpdate = this.SearchDebounce(this.handleSearchValChange, 1000)

      handleEndDecorator = (values: any) => {
        return values ? values?.trim()?.split(" ").length : "0"
      }
}
// Customizable Area End