// Customizable Area Start
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import WebDashboardController, { Props } from "./WebDashboardController.web";
import NewWebHeader from "../../../components/src/WebHeader.web";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import RestaurantLandingPage from "../../info-page/src/Restaurants/RestaurantLandingPage.web";
import WebRestaurantDetails from "../../info-page/src/Restaurants/RestaturantDetails.web";
import RestaurantCatelog from "../../info-page/src/Restaurants/restautantCatelog.web";
import RestaurantMapView from "../../info-page/src/Restaurants/RestaurantMapView.web";
import "../../../web/src/assets/css/style.scss";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import { ScrollView } from "react-native";
// Customizable Area End

class WebRestaurants extends WebDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    return (
      <ScrollView>
        <NewWebHeader />
        <WebNewRoutingTabs />
        <Switch>
          <Route
            path={AppRoutings.RestaurantLandingPage}
            render={(routeProps: any) => (
              <RestaurantLandingPage {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.RestaurantMapView}
            render={(routeProps: any) => (
              <RestaurantMapView {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.RestaurantCatelog}
            render={(routeProps: any) => (
              <RestaurantCatelog {...routeProps} {...this.state} />
            )}
            exact
          />
          <Route
            path={AppRoutings.RestaurantDetailsId}
            render={(routeProps: any) => (
              <WebRestaurantDetails {...routeProps} {...this.state} />
            )}
            exact
          />
        </Switch>
      </ScrollView>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withRouter(WebRestaurants);
// Customizable Area End
