import React, { Component } from "react";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { GrassBanner,leafImg } from "./assets";
import "./assets/css/greentopbanner.scss";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

type Props = {
    pageName: String;
    bannerHeading: string;
};
export default class GreenBanner extends Component<Props> {
    render() {
        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="grass_banner">
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} lg={3} />
                                <Grid item xs={12} lg={6}>
                                    <Box className="green_top_banner_text_wrapper breadFont">
                                        <img src={leafImg} className="Leave_icon" />

                                        <h3>{this.props.bannerHeading}</h3>
                                        <Box>
                                            <span>Home</span>
                                            <FiberManualRecordIcon className="bullet" />

                                            <span>{this.props.pageName}</span>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3} />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid />
            </>
        );
    }
}
