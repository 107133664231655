import * as React from "react";
import { useState } from 'react'
// custom components
import {
  Button, Avatar, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import DeleteOutlineSharpIcon from "@material-ui/icons/DeleteOutlineSharp";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";

import "./assets/css/customerReviewandReply.scss";
import { ReviewProfile, DustbinIcon } from "./assets";
import CustomTextAreafield from "./CustomTextAreafield.web";
import CustomButton from "./CustomButton.web";

const CustomerReviewandReply = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false)
  const {
    user,
    reviewdata,
    deleteReview,
    handleReviewReply,
    setFieldValue,
    replyData,
    handleReplyEditId,
    deleteReplyReview
  } = props;

  const handleDialog = () => {
    setOpenDialog(!openDialog)
  }
  console.log(props, "replydata")
  return (

    <div className="commentReviewContainer">
      <div className="detailsContainer">
        {user && (
          <div className="imageContainer">
            <Avatar alt="profile" src={reviewdata?.attributes?.image?.url} />
          </div>
        )}
        <div className="reviewContainer">
          <div className="heading">
            {user ? (
              <h5>{reviewdata?.attributes?.full_name}</h5>
            ) : (
              <h6>Your Reply</h6>
            )}
            {user && <p>{reviewdata?.attributes?.date}</p>}
          </div>


          <p className="review">{user ? reviewdata?.attributes?.comment : replyData?.attributes.description} </p>

        </div>
      </div>
      <div className="buttonContainer">
        <div className="dustbinBtnOutline" onClick={() => handleDialog()}>
          <img src={DustbinIcon} />
        </div>

        {user ? (
          <Button variant="contained" endIcon={<ReplyAllIcon />} onClick={() => {
            handleReviewReply(reviewdata.id, setFieldValue);
            handleReplyEditId("", setFieldValue);
          }}>
            reply
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => {
            setFieldValue("replyEdit", replyData.attributes.description)
            handleReplyEditId(replyData.id, setFieldValue);
            handleReviewReply("", setFieldValue)
          }}>Edit</Button>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={() => handleDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete {user ? "review" : "reply"} ?
        </DialogTitle>
        <DialogActions>
          <span className="redContained customCancelBtn">
            <CustomButton
              color="secondary"
              label="No"
              onClick={() => handleDialog()}
            />
          </span>
          <span className="greenContained">
            <CustomButton
              color="secondary"
              label="Yes"
              onClick={() => {
                handleDialog();
                {
                  user ? deleteReview(reviewdata.id) :
                    deleteReplyReview(replyData.id)
                }
              }}
              autoFocus
            />
          </span>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CustomerReviewandReply;
