// Customizable Area Start
import React from "react"
import { Dialog } from "@material-ui/core"
import LoginSignUpDialogController, {
  Props,
} from "./LoginSignUpDialogController.web"
import { connect } from "react-redux"
import OnlineResetPassword from "../../../forgot-password/src/OnlineResetPassword.web"
import LoginSignUpTabs from "./LoginSignUpTabs.web"
// Customizable Area End

class LoginSignUpDialog extends LoginSignUpDialogController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {
            isOpen,
            isForgotPassword,
            onClose,
            onLoginSuccess
        } = this.props

        const { isForgotPasswordScreen } = this.state

        return (
            <Dialog
                maxWidth="xs"
                open={isOpen}
                onClose={() => this.newOnClose()}
                classes={{
                    paper: "login_sign_up_dialog",
                }}
            >
                {
                    isForgotPasswordScreen ? (
                        <OnlineResetPassword
                            backToParent={this.closeForgotPasswordScreen}
                            close={this.newOnClose}
                        />
                    ) : (
                        <LoginSignUpTabs
                            isForgotPassword={isForgotPassword}
                            onClose={this.newOnClose}
                            onLoginSuccess={onLoginSuccess}
                            openForgotPasswordScreen={this.openForgotPasswordScreen}
                        />
                    )
                }
            </Dialog>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
    return {
        userInfo: state.user
    };
};

export default connect(mapStateToProps, {})(LoginSignUpDialog);
// Customizable Area End
