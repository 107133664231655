// Customizable Area Start
import React from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import LPDetailsCard from "./LPDetailsCard.web"
import LPCustomCarousel from "./LPCustomCarousel.web"
import { connect } from "react-redux"
import { IImage } from "../../../../../components/src/interfaces/common"

interface IRestaurantCard {
    id: number
    type: string
    attributes: {
        id: number
        name: string
        description: string
        image: IImage
        favourite: boolean
        rating: number
    }
}

interface ILPFavRestaurants {
    LPFavRestaurantDesc: string
    LPFavRestaurants: IRestaurantCard[]
}

const CAROUSEL_BREAKPOINTS = [
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        },
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        },
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        },
    }
]

const LPFavRestaurants = ({
    LPFavRestaurantDesc,
    LPFavRestaurants,
}: ILPFavRestaurants) => {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box className="landing_page_section_details">
                        <Typography className="green_small_heading">
                            Favourite Restaurants
                        </Typography>
                        <h1 className="h1">{LPFavRestaurantDesc}</h1>
                    </Box>
                </Grid>
            </Grid>
            <LPCustomCarousel
                slidesToShow={4}
                type="restaurant"
                emptyMessage="No favourite restaurants found"
                sliderProps={{ responsive: CAROUSEL_BREAKPOINTS }}
            >
                {LPFavRestaurants?.map((restaurantDetails: IRestaurantCard) => {
                    const {
                        id,
                        attributes: { id: resId, name, image, description, rating },
                    } = restaurantDetails
                    return (
                        <LPDetailsCard
                            key={id}
                            id={resId}
                            name={name}
                            description={description}
                            image={image?.url}
                            rating={rating}
                            type="restaurant"
                        />
                    )
                })}
            </LPCustomCarousel>
        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        LPFavRestaurantDesc: state.cms.LPFavRestaurantDesc,
        LPFavRestaurants: state.cms.LPFavRestaurants,
    }
}

export default connect(mapStateToProps, {})(LPFavRestaurants)
// Customizable Area End
