// Customizable Area Start
import React from "react"
import FavouritePanelController, { Props } from "./FavouritePanelController.web"
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import LeafHeadingBanner from "../../../../components/src/LeafHeadingBanner.web"
import CourseCard from "../../../../components/src/CourseCard.web"
import "../../assets/css/favoritepage.scss"
import CustomFavEmptyCardWeb from "../CustomFavEmptyCard.web"
import { connect } from "react-redux"

export class CoursesFavouritePanel extends FavouritePanelController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, favouriteList, FPCourseDesc } = this.props
    const purchasedCourses = "purchased_courses" in favouriteList ? favouriteList.purchased_courses.data : []
    const favCourses = "fav_courses" in favouriteList ? favouriteList.fav_courses.data : []
    const recentCourses = favouriteList || []
    const url = window.location.pathname.split("/")[1];
    const displayCourses = url == "favourites-page" ? favCourses : recentCourses;

    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <>
            <Grid item xs={12} container justifyContent="center" spacing={3}>
              {purchasedCourses.length > 0 && (
                <>
                  <Grid item xs={12} sm={10} md={8}>
                    <Typography align="center" className="purchases_course_heading">
                      Purchased Courses
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container spacing={3} justifyContent="center">
                    {purchasedCourses.map((item: any) => {
                      const { id, attributes } = item
                      const { course_name, name, image } = attributes
                      const imageURI = image ? image.url : null

                      return (
                        <Grid item xs={12} sm={6} md={4} key={id}>
                          <CourseCard
                            courseId={id}
                            authorName={name}
                            courseName={course_name}
                            courseImage={imageURI}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </>
              )}
              {displayCourses.length > 0 ? (
                <>
                  <Grid item xs={12} sm={10} md={8}>
                    <LeafHeadingBanner
                      heading={`${this.settingHeading()} Courses`}
                      description={FPCourseDesc}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={3}>
                    {displayCourses.map((item: any) => {
                      const { id, attributes } = item
                      const { name, image, course_name } = attributes
                      const imageUrl = image ? image.url : null

                      return (
                        <Grid item xs={12} sm={6} md={4} key={id}>
                          <CourseCard
                            courseId={id}
                            authorName={name}
                            courseName={course_name}
                            courseImage={imageUrl}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <CustomFavEmptyCardWeb
                    message={`No ${this.settingHeading(true)} courses found`}
                    buttonText="Explore Courses now"
                    redirectLink="/learning/courses"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    FPFarmDesc: state.cms.FPFarmDesc,
    FPRestaurantDesc: state.cms.FPRestaurantDesc,
    FPCourseDesc: state.cms.FPCourseDesc
  }
}

export default connect(mapStateToProps, {})(CoursesFavouritePanel)
// Customizable Area End
