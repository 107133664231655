// Customizable Area Start
import React, { Component } from "react"
import { Typography, Box } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { cardBg } from "../../assets"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

enum IType {
  FARM = "Farm",
  RESTAURANT = "Restaurant",
  COURSE = "Course"
}

interface Props extends RouteComponentProps {
  isDragging: boolean
  id: string
  type: IType
  heading: string
  image: string
  isAd: boolean
}

class LPLatestUpdateCard extends Component<Props> {

  handleSlideClick = () => {
    const { history, id, isDragging, type } = this.props

    if(!isDragging) {
      if(type === IType.FARM) {
        history.push(`${AppRoutings.FarmDetails}/${id}`)
      } else if(type === IType.RESTAURANT) {
        history.push(`${AppRoutings.RestaurantDetails}/${id}`)
      } else if(type === IType.COURSE) {
        history.push(`${AppRoutings.CourseDetails}${id}`)
      }
    }
  }

  render() {
    const { heading, image, isAd } = this.props

    return (
      <>
        <Box
          className={`latest_updates_card_wrapper ${isAd ? 'latest_updates_card_ad' : ''}`}
          onClick={this.handleSlideClick}
        >
          <img src={image || cardBg} alt="card image" />
          <Box className="latest_updates_card_name_wrapper">
            <Typography className="latest_updates_card_name">
              {heading}
            </Typography>
          </Box>
        </Box>
      </>
    )
  }
}

export default withRouter(LPLatestUpdateCard)
// Customizable Area End
