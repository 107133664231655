// Customizable Area Start
import React from "react"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { Form, Formik, ErrorMessage, FieldArray } from "formik"
import * as Yup from "yup"
import AdminProductWeightDimensionsController, {
  DEFAULT_WEIGHT_DIMENSION,
  IWeightDimension,
} from "./AdminProductWeightDimensionsController.web"
import { DustbinIcon } from "./assets"
import CustomActionDialog from "../../../components/src/CustomDialog.web"
import clsx from "clsx"
const configJSON = require("./config.js")

export const styles = () =>
  createStyles({
    mainWrapper: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headingWrapper: {
      padding: "25px 25px 20px",
      borderBottom: "1px solid #f0f0f0",
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      zIndex: 1,
      "& > h4": {
        fontWeight: "bold",
        color: "#394053",
        fontSize: "22px",
        fontFamily: '"Montserrat", sans-serif',
      },
    },
    loaderWrapper: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        color: "#20a258",
      },
    },
    contentWrapper: {
      flex: 1,
    },
    note: {
      padding: "8px 12px",
      fontSize: "14px",
      fontFamily: "'Montserrat', sans-serif",
      color: "#808080",
    },
    formWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    unitWrapper: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: "30px",
      padding: "20px 25px 0px",
      "& > h5": {
        fontWeight: 600,
        fontSize: "18px",
      },
    },
    unitSelect: {
      width: "100%",
      maxWidth: "90px",
      border: "1px solid rgba(193, 193, 193, 0.368627451)",
      borderRadius: "10px !important",
      "& .MuiSelect-select": {
        padding: "12px 22px 12px 14px",
      },
      "&.Mui-focused": {
        backgroundColor: "#f4f5f5",
        borderRadius: "10px !important",
      },
      "& .Mui-select:focus": {
        borderRadius: "10px !important",
      },
    },
    unitOptions: {
      borderRadius: "8px",
      marginTop: "5px",
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
      },
      "& .MuiMenu-list": {
        padding: "0px",
      },
    },
    fieldArrayWrapper: {
      padding: "0px 25px 20px",
      flex: 1,
      overflowX: "auto"
    },
    weightDimensionsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    allFieldsWrapper: {
      flex: 1,
      overflowX: "auto",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldWrapper: {
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
      minWidth: "715px",
    },
    dimensionsHeading: {
      marginBottom: "5px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      fontSize: "17px",
    },
    customField: {
      border: "1px solid #d0d0d0",
      borderRadius: "8px",
    },
    removeIcon: {
      marginTop: "4px",
      "& img": {
        height: "25px",
      },
    },
    firstRemoveIcon: {
      marginTop: "35px",
    },
    addButtonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px",
    },
    primaryButton: {
      backgroundColor: "#20a258",
      color: "#fff",
      padding: "8px 30px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#20a258",
        opacity: "0.9",
      },
      "& > span": {
        color: "#fff",
      },
    },
    actionButtonsWrapper: {
      position: "sticky",
      bottom: 0,
      borderTop: "1px solid #d0d0d0",
      padding: "15px 25px",
      backgroundColor: "#fff",
      "& svg": {
        color: "#fff",
      },
    },
  })

const WeightDimensionsSchema = Yup.object().shape({
  unit: Yup.string().required("This field is required"),
  weightDimensions: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      startWeight: Yup.number()
        .required("This field is required")
        .typeError("Start weight must be a number")
        .positive("Start weight must be positive"),
      endWeight: Yup.number()
        .required("This field is required")
        .typeError("End weight must be a number")
        .positive("End weight must be positive"),
      length: Yup.number()
        .required("This field is required")
        .typeError("Length must be a number")
        .positive("Length must be positive"),
      width: Yup.number()
        .required("This field is required")
        .typeError("Width must be a number")
        .positive("Width must be positive"),
      height: Yup.number()
        .required("This field is required")
        .typeError("Height must be a number")
        .positive("Height must be positive"),
    })
  ),
})

class AdminProductWeightDimensions extends AdminProductWeightDimensionsController {

  getHeading = (isFirstLoop: boolean, heading: string) => {
    const { classes } = this.props

    return (
      isFirstLoop && (
        <Typography
          className={
            classes.dimensionsHeading
          }
        >
          {heading}
        </Typography>
      )
    )
  }

  render() {
    const { classes } = this.props
    const {
      loading,
      isRemoving,
      isSubmitting,
      isRemoveDialog,
      dimensionsDetails,
    } = this.state

    const defaultInitialValues = {
      unit: "lb",
      weightDimensions: [DEFAULT_WEIGHT_DIMENSION],
    }
    const initialValues = {
      ...defaultInitialValues,
      ...(dimensionsDetails ?? {}),
    }

    return (
      <>
        <Box className={classes.mainWrapper}>
          <Box className={classes.headingWrapper}>
            <Typography variant="h4">Weight & Dimensions</Typography>
          </Box>
          <Box className={classes.contentWrapper}>
            {loading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress size={25} />
              </Box>
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={WeightDimensionsSchema}
                onSubmit={this.handleWeightDimensionsSubmit}
              >
                {({ values, handleChange }) => (
                  <Form className={classes.formWrapper}>
                    <Typography className={classes.note}>
                      NOTE: Based on these details, shipping labels and charges will be generated for orders, and the maximum weight per shipment is allowed to be up to 70 lb
                    </Typography>
                    <Box display="flex" flex={1} flexDirection="column">
                      <Box className={classes.unitWrapper}>
                        <Typography variant="h5">Unit:</Typography>
                        <Select
                          name="unit"
                          value={values.unit}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          variant="standard"
                          disableUnderline
                          className={classes.unitSelect}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: classes.unitOptions,
                            },
                          }}
                        >
                          <MenuItem value="lb">Lb</MenuItem>
                          <MenuItem value="kg">Kg</MenuItem>
                        </Select>
                      </Box>
                      <Box className={classes.fieldArrayWrapper}>
                        <FieldArray
                          name="weightDimensions"
                          render={({ push, remove }) => (
                            <Box className={classes.weightDimensionsWrapper}>
                              <Box className={classes.allFieldsWrapper}>
                              {values.weightDimensions.map(
                                (
                                  weightDimension: IWeightDimension,
                                  index: number
                                ) => {
                                  const {
                                    id,
                                    startWeight,
                                    endWeight,
                                    length,
                                    height,
                                    width,
                                  } = weightDimension
                                  const isFirstLoop = index === 0
                                  const startWeightName = `weightDimensions[${index}].startWeight`
                                  const endWeightName = `weightDimensions[${index}].endWeight`
                                  const lengthName = `weightDimensions[${index}].length`
                                  const widthName = `weightDimensions[${index}].width`
                                  const heightName = `weightDimensions[${index}].height`

                                  return (
                                    <Box
                                      className={classes.fieldWrapper}
                                      key={startWeightName}
                                    >
                                      <Box width="100%">
                                        {this.getHeading(isFirstLoop, "Start weight")}
                                        <TextField
                                          variant="outlined"
                                          value={startWeight}
                                          disabled={isSubmitting}
                                          placeholder="Start weight"
                                          className={classes.customField}
                                          name={startWeightName}
                                          onChange={handleChange}
                                        />
                                        <span className="errorMsg">
                                          <ErrorMessage
                                            name={startWeightName}
                                          />
                                        </span>
                                      </Box>
                                      <Box width="100%">
                                        {this.getHeading(isFirstLoop, "End weight")}
                                        <TextField
                                          variant="outlined"
                                          value={endWeight}
                                          disabled={isSubmitting}
                                          placeholder="End weight"
                                          className={classes.customField}
                                          name={endWeightName}
                                          onChange={handleChange}
                                        />
                                        <span className="errorMsg">
                                          <ErrorMessage name={endWeightName} />
                                        </span>
                                      </Box>
                                      <Box width="100%">
                                        {this.getHeading(isFirstLoop, "Length(Inch)")}
                                        <TextField
                                          variant="outlined"
                                          value={length}
                                          disabled={isSubmitting}
                                          placeholder="Length(Inch)"
                                          className={classes.customField}
                                          name={`weightDimensions[${index}].length`}
                                          onChange={handleChange}
                                        />
                                        <span className="errorMsg">
                                          <ErrorMessage name={lengthName} />
                                        </span>
                                      </Box>
                                      <Box width="100%">
                                        {this.getHeading(isFirstLoop, "Width(Inch)")}
                                        <TextField
                                          variant="outlined"
                                          value={width}
                                          disabled={isSubmitting}
                                          placeholder="Width(Inch)"
                                          className={classes.customField}
                                          name={`weightDimensions[${index}].width`}
                                          onChange={handleChange}
                                        />
                                        <span className="errorMsg">
                                          <ErrorMessage name={widthName} />
                                        </span>
                                      </Box>
                                      <Box width="100%">
                                        {this.getHeading(isFirstLoop, "Height(Inch)")}
                                        <TextField
                                          variant="outlined"
                                          value={height}
                                          disabled={isSubmitting}
                                          placeholder="Height(Inch)"
                                          className={classes.customField}
                                          name={`weightDimensions[${index}].height`}
                                          onChange={handleChange}
                                        />
                                        <span className="errorMsg">
                                          <ErrorMessage name={heightName} />
                                        </span>
                                      </Box>
                                      <IconButton
                                        type="button"
                                        data-testid={startWeightName}
                                        disabled={isSubmitting}
                                        className={clsx(classes.removeIcon, {
                                          [classes.firstRemoveIcon]: isFirstLoop
                                        })}
                                        onClick={() =>
                                          this.handleRemoveWeightDimension(
                                            id,
                                            index,
                                            remove
                                          )
                                        }
                                      >
                                        <img src={DustbinIcon} alt="remove" />
                                      </IconButton>
                                    </Box>
                                  )
                                }
                              )}
                              </Box>
                              <Box className={classes.addButtonWrapper}>
                                <Button
                                  type="button"
                                  disabled={isSubmitting}
                                  className={classes.primaryButton}
                                  onClick={() => push(DEFAULT_WEIGHT_DIMENSION)}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Box>
                          )}
                        ></FieldArray>
                      </Box>
                    </Box>
                    <Box className={classes.actionButtonsWrapper}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.primaryButton}
                      >
                        {isSubmitting ? <CircularProgress size={20} /> : configJSON.saveText}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
        {isRemoveDialog && <CustomActionDialog
          open={isRemoveDialog}
          title={configJSON.removeWeightDimensionMessage}
          actionBtnTitle={configJSON.yesText}
          isLoading={isRemoving}
          isActionDisabled={isRemoving}
          isCloseDisabled={isRemoving}
          handleClose={this.closeRemoveRecordDialog}
          handleAction={this.removeWeightDimensionApi}
        />}
      </>
    )
  }
}

export { AdminProductWeightDimensions }
export default withRouter(withStyles(styles)(AdminProductWeightDimensions))
// Customizable Area End
