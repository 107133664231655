// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { isTokenExpired } from "../../../components/src/utility/helper";
const configJSON = require("./config.js");

interface INotification {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_by: string | null;
    headings: string;
    contents: string;
    app_url: string | null;
    is_read: boolean;
    read_at: string | null;
    created_at: string;
    updated_at: string;
  };
}

interface INotificationsResponse {
  data: INotification[];
}

interface INotificationBadgeResponse {
  is_present: boolean;
}

export interface Props {
  navigation: any;
}

interface S {
  loader: boolean;
  anchorEl: HTMLButtonElement | null;
  notifications: INotification[];
  isUnSeenNotification: boolean;
}

interface SS {
  id: any;
}

export default class InAppNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationBadgeApiCallId: string = "";
  getNotificationsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loader: false,
      anchorEl: null,
      notifications: [],
      isUnSeenNotification: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.notificationBadgeApi();
  }

  enableNotificationLoader = () => {
    this.setState({ loader: true });
  };

  disableNotificationLoader = () => {
    this.setState({ loader: false });
  };

  openNotificationsPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
      isUnSeenNotification: false
    });
    this.getNotificationsApi();
  };

  closeNotificationsPanel = () => {
    this.setState({ anchorEl: null });
  };

  getNotificationCreationTime = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();

    const diffInMilliseconds = now.getTime() - date.getTime();

    const timeUnits = [
      { unit: configJSON.moText, value: 30 * 86400000 },
      { unit: configJSON.wText, value: 7 * 86400000 },
      { unit: configJSON.dText, value: 86400000 },
      { unit: configJSON.hText, value: 3600000 },
      { unit: configJSON.minText, value: 60000 }
    ];

    for (const { unit, value } of timeUnits) {
      const diff = Math.floor(diffInMilliseconds / value);
      if (diff > 0) {
        return `${diff}${unit}`;
      }
    }

    return configJSON.nowText;
  };

  getNotificationsApi = async () => {
    const { notifications } = this.state;
    notifications.length === 0 && this.enableNotificationLoader();
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  notificationBadgeApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationBadgeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationBadgeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleApiSuccessResponse = (
    apiRequestCallId: string,
    responseJson: INotificationsResponse | INotificationBadgeResponse
  ) => {
    if (apiRequestCallId === this.getNotificationsApiCallId) {
      if ("data" in responseJson) {
        this.setState({ notifications: responseJson.data });
      }
      this.disableNotificationLoader();
    }

    if (apiRequestCallId === this.notificationBadgeApiCallId) {
      if ("is_present" in responseJson) {
        this.setState({ isUnSeenNotification: responseJson.is_present });
      }
    }
  };

  handleApiErrorResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.getNotificationsApiCallId) {
      this.disableNotificationLoader();
      toast.error(configJSON.SOMETHING_WENT_WRONG);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleApiSuccessResponse(apiRequestId, responseJson);
      } else {
        if (await isTokenExpired(responseJson)) {
          return;
        }
        this.handleApiErrorResponse(apiRequestId);
      }
    }
  }
}
// Customizable Area End
