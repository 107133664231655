// Customizable Area Start
import React from "react"
import { Box, Typography, Button } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"

interface IProps extends RouteComponentProps {
  history: any
  message: string
  buttonText: string
  redirectLink: string
}

const CustomFavEmptyCard = ({
  history,
  message,
  buttonText,
  redirectLink,
}: IProps) => {
  const handleRedirect = () => {
    history.push(redirectLink)
  }

  return (
    <Box className="fav_page_empty_box">
      <Typography>{message}</Typography>
      <Button onClick={handleRedirect}>{buttonText}</Button>
    </Box>
  )
}

export default withRouter(CustomFavEmptyCard)
// Customizable Area End
