// Customizable Area Start
import React, { Component } from "react";
import { Grid, Box, TextareaAutosize, Button, CircularProgress } from "@material-ui/core";
import WebReviewCard from "../../../../../components/src/WebReviewCard.web";
import { withRouter } from "react-router-dom";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from '@mui/material/Rating';
import { connect } from "react-redux";
import { addRatingRestaurantAction } from '../../assets'
import StorageProvider from "../../../../../framework/src/StorageProvider";
import LoginSignUpDialog from "../../langinpageLoggedIn/LoginSignUpDialog.web";
const configJSON = require("../../config.js")

interface IReview {
  id: string
  type: string
  attributes: {
    comment: string
    date: string
    full_name: string
    rating: number
    image: {
      id: number
      filename: string
      url: string
    }
  }
}
// Customizable Area End

// Customizable Area Start
interface Props {
  match: any
  restaurantReviewLoader: boolean
  restaurantReviews: IReview[]
  addRatingRestaurantAction: (
    reviewDetail: {
      id: string
      rating: number | null
      comment: string
    },
    clearFunc: () => void
  ) => void
}
// Customizable Area End

// Customizable Area Start
interface S {
  id: string
  rating: number | null
  review: string
  isLoginDialog: boolean
}
// Customizable Area End

class WebReviewComments extends Component<Props, S> {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      id: "",
      rating: null,
      review: "",
      isLoginDialog: false
    }
    this.handleRating = this.handleRating.bind(this)
    this.handleWriteText = this.handleWriteText.bind(this)
    this.handleClear = this.handleClear.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    const { match: { params: { id } } } = this.props
    this.setState({ id })
  }

  handleRating(_: React.SyntheticEvent<Element, Event>, value: number | null) {
    this.setState({
      rating: value
    })
  }

  handleWriteText(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      review: e.target.value
    })
  }

  handleClear() {
    this.setState({
      rating: null,
      review: ''
    })
  }

  openLoginDialog = () => {
    this.setState({ isLoginDialog: true })
  }

  closeLoginDialog = () => {
    this.setState({ isLoginDialog: false })
  }

  handleSubmit = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)

    if (isLoggedIn) {
      const { id, rating, review } = this.state
      this.props.addRatingRestaurantAction({
        id,
        rating,
        comment: review,
      }, this.handleClear)
    } else this.openLoginDialog()
  }
  // Customizable Area End

  render() {

    // Customizable Area Start
    const { restaurantReviewLoader, restaurantReviews } = this.props
    const { rating, review, isLoginDialog } = this.state

    return (
      <>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6}>
            <Box className="farm_details_review_heading">
              <p>Reviews({restaurantReviews?.length || 0})</p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="farm_details_write_review_heading">
              <p className="write_review_with_underline">Write Review</p>
            </Box>
          </Grid>

          <Grid container spacing={3}>
            {restaurantReviews?.map((review: IReview) => {
              const {
                id,
                attributes: { full_name, image, rating, comment, date },
              } = review

              return (
                <Grid key={id} item xs={12}>
                  <WebReviewCard
                    name={full_name}
                    date={date}
                    review={comment}
                    rating={rating}
                    profileImg={image?.url || ""}
                  />
                </Grid>
              )
            })}

            <Grid item xs={12}>
              <p className="review_label">Write review</p>
            </Grid>

            <Grid item xs={12}>
              <Rating
                className="StyledRating"
                classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                name="customized-empty"
                defaultValue={0}
                value={Number(rating)}
                precision={0.5}
                size="large"
                onChange={this.handleRating}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Box>
                <TextareaAutosize
                  value={review}
                  onChange={this.handleWriteText}
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Write comment here..."
                  className="write_review_text_area"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                className="review_submit_btn"
                disabled={restaurantReviewLoader}
                onClick={this.handleSubmit}
              >
                {restaurantReviewLoader ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <LoginSignUpDialog
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
        />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    restaurantReviewLoader: state.restaurants.isLoadingWrite,
    restaurantReviews: state.restaurants.restaurantsRatings
  }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, { addRatingRestaurantAction })(WebReviewComments)
)
// Customizable Area End
