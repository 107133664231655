import React, { Component } from "react";
import { Grid, Box, Container, FormControl, Select } from "@material-ui/core";
import RestaurantSearchCard from "./restaurantSearchCardWeb.web";
import RestaurantMapView from "./restaurantMapViewWeb.web";
import FilterListIcon from "@material-ui/icons/FilterList";
import { filter_restaurants_green_icon } from "../../assets";
import "../../assets/css/restaurantsearchmapviewlayout.scss";
import WebRestaurantNavBar from "../../../../../components/src/WebRestaurantNavBar.web";

class RestaurantSearchMapViewLayout extends Component {
  render() {
    return (
      <>
        <WebRestaurantNavBar/>
        <Box className="restaurant_search_map_view_container">
          <Grid container>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2} spacing={3}>
              <Grid item xs={12}>
                <Box className="search_restaurant">
                  <Select
                    native
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>
                  </Select>

                  <img src={filter_restaurants_green_icon} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RestaurantSearchCard />
              </Grid>
              <Grid item xs={12}>
                <RestaurantSearchCard />
              </Grid>
              <Grid item xs={12}>
                <RestaurantSearchCard />
              </Grid>
              <Grid item xs={12}>
                <RestaurantSearchCard />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={8} md={9} lg={10} xl={10}>
              <Grid item xs={12} style={{ height: "100%" }}>
                <RestaurantMapView />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default RestaurantSearchMapViewLayout;
