// Customizable Area Start
import React from "react"
import { Box, ClickAwayListener, TextField } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import TagsSuggestionController, { ITag } from "./TagsSuggestionController.web"
import "../../web/src/assets/css/content/textfield.scss"

export const styles = () =>
  createStyles({
    mainWrapper: {
      position: "relative",
    },
    textField: {
      borderRadius: "8px !important",
      backgroundColor: "#fff !important",
      border: "1px solid rgba(193, 193, 193, 0.368627451) !important",
      "& .Mui-disabled": {
        opacity: 0.6
      }
    },
    listWrapper: {
      position: "absolute",
      top: "50px",
      zIndex: 1,
      borderRadius: "8px",
      padding: "10px",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.10)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    tagButton: {
      width: "100%",
      textAlign: "left",
      border: "none",
      padding: "4px 6px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      outline: "none",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      },
    },
  })

export class TagsSuggestion extends TagsSuggestionController {
  render() {
    const { name, value, dataTestId, disabled, classes, placeholder } = this.props
    const { isOpen, tags } = this.state
    const isListOpen = isOpen && tags.length > 0

    return (
      <ClickAwayListener onClickAway={this.handleCloseSuggestion}>
        <div className={`customTextfield ${classes.mainWrapper}`}>
          <TextField
            name={name}
            value={value}
            variant="standard"
            disabled={disabled}
            className={classes.textField}
            data-test-id={dataTestId}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={this.fieldRef}
            onClick={this.handleOpenSuggestion}
            onBlur={this.handleBlur}
            onChange={this.handleSearchValueChange}
          />
          {isListOpen && (
            <Box className={classes.listWrapper}>
              {tags.map((tag: ITag) => {
                const {
                  id,
                  attributes: { name },
                } = tag

                return (
                  <button
                    key={id}
                    type="button"
                    className={classes.tagButton}
                    onClick={() => this.handleTagSelection(name)}
                  >
                    {name}
                  </button>
                )
              })}
            </Box>
          )}
        </div>
      </ClickAwayListener>
    )
  }
}

export default withStyles(styles)(TagsSuggestion)
// Customizable Area End
