// Customizable Area Start
import React, { Component } from "react"
import { Grid, Container, Box, Typography } from "@material-ui/core"
import { connect } from "react-redux"
import Pagination from "@material-ui/lab/Pagination"
import { CircularProgress } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import RestaurantSideBar from "../../../../components/src/RestaurantPageSideBar.web"
import RestaurantsPageSearchFilterComponent from "../../../../components/src/RestaurantsPageSearchFilterComponent.web"
import {
  RestaurantCardImg,
  setRestaurantsCurrentPage,
  updateActiveTab,
  resetRestaurantFilters,
} from "../assets"
import "../../../../web/src/assets/css/style.scss"
import "../assets/css/farmproductcatelog.scss"
import RestaurantCard from "./RestaurantCard.web"
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import WebFooter from "../../../../components/src/WebFooter.web"
import GreenBanner from "../../../../components/src/GreenBanner.web"
import AdCampaignWrapper from "../../../LocalListingAds/src/AdCampaignWrapper.web"
import { IImage } from "../../../../components/src/interfaces/common"
import MobileFilterWrapper from "../../../../components/src/MobileFilterWrapper.web"
import { scrollToTop } from "../../../../components/src/utility/helper"

export interface IRestaurantDetails {
  id: string
  type: string
  attributes: {
    advertisement_banner: IImage | null
    name: string
    average_rating: number
    latitude: number
    description: string
    longitude: number
    dish_name: string
    images: IImage
    is_ad: boolean
    org_address: string
    quote_time: string
    price: string
    third_party_ad_url: string | null
    banner: IImage | null
  }
}

interface Props extends RouteComponentProps {
  location: any
  restaurants: any
  updateActiveTab: (tab: string) => void
  resetRestaurantFilters: () => void
  setRestaurantsCurrentPage: (pageNo: number) => void
}

interface S {
  isMobileScreen: boolean
  isFiltersOpen: boolean
}

class RestaurantCatelog extends Component<Props, S> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isMobileScreen: window.innerWidth <= 768,
      isFiltersOpen: false
    }
  }

  getWindowSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 768 })
  }

  handleFiltersClick = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen })
    if(!this.state.isFiltersOpen) scrollToTop("restaurant_page_top", "end")
  }

  componentDidMount() {
    const {
      updateActiveTab,
    } = this.props
    window.addEventListener('resize', this.getWindowSize)
    updateActiveTab && updateActiveTab(ActiveTabEnum.Restaurant)
  }

  componentWillUnmount() {
    const { resetRestaurantFilters } = this.props
    window.removeEventListener('resize', this.getWindowSize)
    resetRestaurantFilters && resetRestaurantFilters()
  }

  render() {
    const { location, restaurants, setRestaurantsCurrentPage } = this.props
    const { isMobileScreen, isFiltersOpen } = this.state
    const isFiltersVisible = isMobileScreen ? isFiltersOpen : true

    return (
      <>
        <Container id="restaurantList" className="restaurant_main_wrapper">
          <Box className="restaurant_page_top" />
          <Grid container spacing={3}>
            {isFiltersVisible && <>
            <Grid item xs={12}>
              <Box mb="15px">
                <RestaurantsPageSearchFilterComponent />
              </Box>
            </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <RestaurantSideBar
                  categoryTitle="Related Category"
                  state={location.state || null}
                />
              </Grid>
            </>
            }

            <Grid item xs={12} md={9} lg={9}>
              {restaurants.isLoading ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} className="custom_loader_container">
                    <CircularProgress size={40} />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Box className="res_heading_wrapper">
                        <Typography>Restaurants</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="stretch">
                    {restaurants?.restaurants?.length > 0 &&
                      restaurants.restaurants.map(
                        (item: IRestaurantDetails) => {
                          const {
                            id,
                            attributes: {
                              name,
                              images,
                              quote_time,
                              description,
                              is_ad,
                              advertisement_banner,
                              third_party_ad_url,
                              banner
                            },
                          } = item
                          const imageUrl = images ? images.url : ""
                          const adImage = banner ? banner.url : imageUrl
                          const thirdPartyImageUrl = advertisement_banner
                            ? advertisement_banner.url
                            : undefined

                          return (
                            <Grid item xs={6} md={4} key={id}>
                              <AdCampaignWrapper
                                isAd={is_ad}
                                thirdPartyImageUrl={thirdPartyImageUrl}
                                thirdPartyUrl={third_party_ad_url}
                              >
                                <RestaurantCard
                                  cardName={name}
                                  thumbnail={adImage || RestaurantCardImg}
                                  duration={quote_time}
                                  description={description}
                                  redirectPath={`${AppRoutings.RestaurantDetails}/${id}`}
                                />
                              </AdCampaignWrapper>
                            </Grid>
                          )
                        }
                      )}

                    <Grid item xs={12}>
                      {restaurants?.pagination?.total_pages > 1 && (
                        <Box className="show_more_btn_wrapper">
                          <Box className="pagination_grid">
                            <Box className="web_pagination_container">
                              <Pagination
                                count={restaurants?.pagination?.total_pages}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                  setRestaurantsCurrentPage(value)
                                  document
                                    .getElementById("restaurantList")
                                    ?.scrollIntoView()
                                }}
                                page={restaurants?.currentPage}
                                variant="outlined"
                                shape="rounded"
                                classes={{
                                  root: "farm_pagination",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        <GreenBanner />
        <WebFooter />
        <MobileFilterWrapper
          hideSort
          isFiltersOpen={isFiltersOpen}
          handleFiltersClick={this.handleFiltersClick}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return { restaurants: state.restaurants }
}

export default connect(mapStateToProps, {
  setRestaurantsCurrentPage,
  updateActiveTab,
  resetRestaurantFilters,
})(RestaurantCatelog)
// Customizable Area End
