// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
import { toast } from "react-toastify"
import { ISearchRestaurant } from "../../interfaces/search"
import queryString from "query-string"
import { ICategory, ITag } from "../../../../../components/src/interfaces/common"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps {}

interface S {
  loader: boolean
  categoriesLoader: boolean
  priceRangeLoader: boolean
  tagsLoader: boolean
  categories: ICategory[]
  minPrice: number
  maxPrice: number
  tags: ITag[]
  restaurants: ISearchRestaurant[]
  totalPages: number
}

interface SS {
  id: any
}

export default class SearchRestaurantsController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchRestaurantsApiCallId: string = ""
  restaurantsCategoriesApiCallId: string = ""
  restaurantsPriceRangeApiCallId: string = ""
  restaurantsTagsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      categoriesLoader: false,
      priceRangeLoader: false,
      tagsLoader: false,
      categories: [],
      minPrice: 0,
      maxPrice: 0,
      tags: [],
      restaurants: [],
      totalPages: 0,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    await this.restaurantsCategoriesApi()
    await this.restaurantsPriceRangeApi()
    await this.restaurantsTagsApi()
    this.handleSearchRestaurants()
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.handleSearchRestaurants()
    }
  }

  scrollToTop = () => {
    const element = document.getElementsByClassName("web_header")
    if (element && element.length > 0)
      element[0].scrollIntoView({ behavior: "smooth" })
  }

  enableSearchLoader = () => {
    this.setState({ loader: true })
  }

  disableSearchLoader = () => {
    this.setState({ loader: false })
  }

  handleSearchRestaurants = () => {
    this.scrollToTop()
    const { search } = this.props.location
    const parsedQueryString = queryString.parse(search)
    const { query, ...rest } = parsedQueryString
    const page = rest.page ?? 1
    const per = rest.per ?? 16

    const updatedQueryString = queryString.stringify({
      ...rest,
      search: query,
      page,
      per,
    })

    if (query) {
      this.searchRestaurantsApi(`?${updatedQueryString}`)
    } else {
      this.props.history.goBack()
    }
  }

  searchRestaurantsApi = async (query: string) => {
    this.enableSearchLoader()

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchRestaurantsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchRestaurantsApiEndPoint + query
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  restaurantsCategoriesApi = async () => {
    this.setState({ categoriesLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.restaurantsCategoriesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndPoint + configJSON.categoryTypeRestaurantQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  restaurantsPriceRangeApi = async () => {
    this.setState({ priceRangeLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.restaurantsPriceRangeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceRangeApiEndPoint + configJSON.typeRestaurantQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  restaurantsTagsApi = async () => {
    this.setState({ tagsLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.restaurantsTagsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsApiEndPoint + configJSON.tagsTypeRestaurantQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleSuccessResponseJson = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.searchRestaurantsApiCallId) {
      if (responseJson.data) {
        const {
          data,
          meta: {
            pagination: { total_pages },
          },
        } = responseJson
        this.setState({ restaurants: data, totalPages: total_pages })
      } else {
        toast.error(configJSON.SOMETHING_WENT_WRONG)
      }
      this.disableSearchLoader()
    }

    if (apiRequestCallId === this.restaurantsCategoriesApiCallId) {
      this.setState({ categoriesLoader: false, categories: responseJson })
    }

    if (apiRequestCallId === this.restaurantsPriceRangeApiCallId) {
      const { min_price, max_price } = responseJson
      this.setState({
        priceRangeLoader: false,
        minPrice: Number(min_price),
        maxPrice: Number(max_price),
      })
    }

    if (apiRequestCallId === this.restaurantsTagsApiCallId) {
      this.setState({ tagsLoader: false, tags: responseJson.data ?? [] })
    }
  }

  handleErrorResponseJson = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.searchRestaurantsApiCallId) {
      this.disableSearchLoader()
      this.setState({ restaurants: [] })
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestIdString = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const apiResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (apiResponseJson && !apiResponseJson.errors) {
        this.handleSuccessResponseJson(apiRequestIdString, apiResponseJson)
      } else {
        if (await isTokenExpired(apiResponseJson)) {
          return
        }
        this.handleErrorResponseJson(apiRequestIdString, apiResponseJson)
      }
    }
  }
}
// Customizable Area End
