import React, { Component } from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import './assets/css/breadcrumbs.scss';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
interface Props {
    twoPage: boolean,
    threePage:boolean,
    firstPage: string
    secondPage: string
    thirdPage: string
    firstPageLink: string
    secondPageLink: string
    thirdPageLink: string

}

class BreadCrumb extends Component<Props> {
    render() {
        return (<>
            {this.props.twoPage == true ? <>
                <Breadcrumbs className="breadcrumb_component"
                    separator={<KeyboardArrowRightIcon />}>
                    <Link to={this.props.firstPageLink}
                        className="breadcrumb__link"

                    >
                        {this.props.firstPage}
                    </Link>
                    <Link to={this.props.secondPageLink}
                        className="breadcrumb_active_link"
                    >
                        {this.props.secondPage}
                    </Link>

                </Breadcrumbs>
            </> : <Breadcrumbs className="breadcrumb_component"
                separator={<KeyboardArrowRightIcon />}>
                <Link to={this.props.firstPageLink}
                    className="breadcrumb__link"

                >
                    {this.props.firstPage}
                </Link>
                <Link to={this.props.secondPageLink}
                    className="breadcrumb__link"
                >
                    {this.props.secondPage}
                </Link>
                <Link to={this.props.thirdPageLink}
                    className="breadcrumb_active_link"
                >
                    {this.props.thirdPage}
                </Link>


            </Breadcrumbs>
            }

        </>);
    }
}

export default BreadCrumb;