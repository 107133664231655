import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
const configJSON = require("../config.js")
// Customizable Area End

export interface Props {
    userInfo: any
    isCommunity?: boolean
    accountId: number
    reviewId: string
    fullName: string
    comment: string
    profileImage: string
    reviewDate: string
    isSpamReported: boolean
    replies: Array<IReply>
    handleCommentRemove: (reviewId: string) => void
    handleCommentEdit: (reviewId: string, newReview: string) => void
    handleReviewsRemove: (reviewId: string, replyId: string) => void
    handleAddNewReview: (reviewId: string, newReview: IReply) => void
    handleReviewEdit: (
        reviewId: string,
        replyId: string,
        newReview: string
    ) => void
}

export interface IReply {
    id: string
    type: string
    attributes: {
        account_id: number
        date: string
        description: string
        image: {
            id: number
            filename: string
            url: string
        }
        full_name: string
        review_id: number
        report_spam: boolean
    }
}

interface S {
    reviewText: string
    isAddingReview: boolean
    addReviewLoader: boolean
    contentFlagLoader: boolean
    isContentFlag: boolean
    showAllReplies: boolean
    commentReplies: IReply[]
    isLoginDialog: boolean
    isEditingComment: boolean
    editCommentLoader: boolean
    commentText: string
    deleteCommentLoader: boolean
    deleteCommentDialog: boolean
}

interface SS {
    // Customizable Area Start
    id: any
    // Customizable Area End
}

export default class CommentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    addReplyApiCallId: string = ""
    editCommentApiCallId: string = ""
    deleteCommentApiCallId: string = ""
    contentFlagApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            reviewText: "",
            isAddingReview: false,
            addReviewLoader: false,
            contentFlagLoader: false,
            isContentFlag: false,
            showAllReplies: false,
            commentReplies: [],
            isLoginDialog: false,
            commentText: "",
            editCommentLoader: false,
            isEditingComment: false,
            deleteCommentLoader: false,
            deleteCommentDialog: false,
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { comment, isSpamReported } = this.props
        this.setState({ commentText: comment, isContentFlag: isSpamReported })
        this.initializeReplies()
    }

    async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
        const { replies } = this.props
        if (prevProps.replies !== replies) {
            this.initializeReplies()
        }
    }

    openLoginDialog = () => {
        this.setState({
            isLoginDialog: true,
        })
    }

    closeLoginDialog = () => {
        this.setState({
            isLoginDialog: false,
        })
    }

    handleDeleteComment = () => {
        this.setState({ deleteCommentDialog: true })
    }

    cancelDeleteComment = () => {
        this.setState({ deleteCommentDialog: false })
    }

    initializeReplies = () => {
        const { replies } = this.props
        const { showAllReplies } = this.state
        let tempReplies: IReply[] = [...replies]
        if (replies.length > 1 && !showAllReplies) {
            tempReplies = [replies[0]]
        }
        this.setState({ commentReplies: tempReplies })
    }

    toggleAllReplies = () => {
        const { showAllReplies } = this.state
        const { replies } = this.props
        let tempReplies: IReply[] = []

        if (showAllReplies) {
            tempReplies = [replies[0]]
        } else {
            tempReplies = replies
        }

        this.setState({
            commentReplies: tempReplies,
            showAllReplies: !showAllReplies,
        })
    }

    handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        this.setState({ reviewText: value })
    }

    handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        this.setState({ commentText: value })
    }

    toggleAddReview = async () => {
        const { isAddingReview } = this.state
        const isLoggedIn = !!(await StorageProvider.get(configJSON.AUTH_TOKEN))

        if (!isLoggedIn) {
            this.openLoginDialog()
            return
        }

        if (isAddingReview) {
            this.setState({
                reviewText: "",
            })
        }
        this.setState({
            isAddingReview: !isAddingReview,
        })
    }

    handleAddReply = async () => {
        this.addReplyApi()
    }

    toggleEditComment = () => {
        const { isEditingComment } = this.state
        const { comment } = this.props

        if (isEditingComment) {
            this.setState({
                isEditingComment: false,
                commentText: comment,
            })
            return
        }
        this.setState({ isEditingComment: true })
    }

    handleContentFlag = async () => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
        if(!isLoggedIn) {
            this.openLoginDialog()
            return
        }
        this.markContentFlagApi()
    }

    addReplyApi = async () => {
        this.setState({ addReviewLoader: true })
        const Token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: Token,
        }

        const { reviewText } = this.state
        const { isCommunity, reviewId } = this.props
        let httpBody: any = { description: reviewText }

        if (isCommunity) httpBody = { ...httpBody, forum_comment_id: reviewId }
        else httpBody = { ...httpBody, review_id: reviewId }

        const apiEndPoint = isCommunity
            ? configJSON.communityReplyReviewApiEndPoint
            : configJSON.replyReviewApiEndPoint

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.addReplyApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    editCommentApi = async () => {
        this.setState({ editCommentLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const { isCommunity, reviewId } = this.props
        const { commentText } = this.state

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const apiEndPoint = isCommunity
            ? configJSON.addDiscussionCommentApiEndPoint
            : configJSON.reviewApiEndPoint

        const httpBody = isCommunity ? { description: commentText } : { review: { comment: commentText }}

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.editCommentApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${apiEndPoint}/${reviewId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePut
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    deleteCommentApi = async () => {
        this.setState({ deleteCommentLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const { isCommunity, reviewId } = this.props

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const apiEndPoint = isCommunity
            ? configJSON.addDiscussionCommentApiEndPoint
            : configJSON.reviewApiEndPoint

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.deleteCommentApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${apiEndPoint}/${reviewId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDelete
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    markContentFlagApi = async () => {
        this.setState({ contentFlagLoader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token,
        }

        const { reviewId, isCommunity } = this.props
        const flagType  = isCommunity ? 'forum_comment' : 'review'
        const httpBody = { type: flagType, id: reviewId }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.contentFlagApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportSpamApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.addReplyApiCallId) {
            this.setState({
                addReviewLoader: false,
                isAddingReview: false,
                reviewText: "",
            })
            const { reviewId, handleAddNewReview } = this.props
            handleAddNewReview(reviewId, responseJson.data)
        }

        if (apiRequestCallId === this.deleteCommentApiCallId) {
            const { reviewId, handleCommentRemove } = this.props
            handleCommentRemove(reviewId)
        }

        if (apiRequestCallId === this.editCommentApiCallId) {
            const { reviewId, handleCommentEdit } = this.props
            handleCommentEdit(reviewId, "")
            this.setState({ editCommentLoader: false, isEditingComment: false })
        }

        if (apiRequestCallId === this.contentFlagApiCallId) {
            this.setState({ contentFlagLoader: false, isContentFlag: true })
            toast.success("Content marked as flagged successfully")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if (apiRequestCallId === this.addReplyApiCallId) {
                    this.setState({
                        addReviewLoader: false,
                    })
                }

                if (apiRequestCallId === this.contentFlagApiCallId) {
                    this.setState({ contentFlagLoader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
