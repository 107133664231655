// Customizable Area Start
import React, { Component } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { AppRoutings } from "../../../../components/src/utility/app-routing"

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

export class AdminTransactionHistoryButton extends Component<Props> {
  redirectToTransactionHistory = () => {
    this.props.history.push(AppRoutings.AdminTransactionHistory)
  }

  render() {
    const { classes } = this.props

    return (
      <Button
        className={classes.transactionButton}
        onClick={this.redirectToTransactionHistory}
      >
        Points Transaction History
      </Button>
    )
  }
}

export const styles = () =>
  createStyles({
    transactionButton: {
      padding: "12px 30px",
      borderRadius: "6px",
      backgroundColor: "#f0f0f0",
      marginTop: "20px",
      textTransform: "none",
      "& span": {
        color: "#5a5a5a",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
      },
    },
  })

export default withStyles(styles)(withRouter(AdminTransactionHistoryButton))
// Customizable Area End
