// Customizable Area Start
import React, { useState } from "react"
import { Box, FilledInput, InputAdornment } from "@material-ui/core"
import LockOpenIcon from "@material-ui/icons/LockOpenRounded"
import VisibilityIcon from "@material-ui/icons/VisibilityRounded"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffRounded"

interface IPasswordInputFieldProps {
    value: string
    placeholder: string
    name: string
    autoFocus?: boolean
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const PasswordInputField = ({
    value,
    placeholder,
    name,
    autoFocus = false,
    onChange,
}: IPasswordInputFieldProps) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false)

    const togglePasswordVisibility = () => {
        setPasswordVisible((isVisible) => !isVisible)
    }

    return (
        <Box className="web_input_field">
            <FilledInput
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position="end">
                        <LockOpenIcon className="login_input_icons" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment
                        position="start"
                        onClick={() => togglePasswordVisibility()}
                    >
                        {isPasswordVisible ? (
                            <VisibilityOffIcon className="password_visibility_icon" />
                        ) : (
                            <VisibilityIcon className="password_visibility_icon" />
                        )}
                    </InputAdornment>
                }
                autoFocus={autoFocus}
                inputProps={{
                    autocomplete: "new-password",
                    form: {
                        autoComplete: "off",
                    },
                }}
                type={isPasswordVisible ? "text" : "password"}
                name={name}
                classes={{
                    input: "Login_Input",
                    root: "Login_Input_Field",
                    focused: "Login_Input_Field_Focus",
                }}
                value={value}
                onChange={onChange}
            />
        </Box>
    )
}

export default PasswordInputField
// Customizable Area End
