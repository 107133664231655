// Customizable Area Start
import React, { Component } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import "./assets/css/websidebar.scss";
import { connect } from "react-redux";
import StorageProvider from "./../../framework/src/StorageProvider.web";
import { resetUserDetails, setCartBadge, setWishlistCount } from "./assets";
const configJSON = require("./config.js")
// Customizable Area End

// Customizable Area Start
interface Props {
  history: any
  wishlistCount: number
  resetUserDetails: () => void
  setWishlistCount: () => void
  setCartBadge: (cartBadge: number) => void
}
// Customizable Area End

class WebSideBar extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.logout = this.logout.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(isLoggedIn) {
      this.props.setWishlistCount()
    }

    const element = document.getElementsByClassName("web_sidebar_active_link")
    if(element.length > 0) element[0].scrollIntoView({  behavior: 'smooth', block: 'nearest' })
  }

  async logout () {
    const { history, setCartBadge, resetUserDetails } = this.props

    await StorageProvider.set(configJSON.ADMIN_AUTH, false)
    await StorageProvider.remove(configJSON.AUTH_TOKEN)
    await StorageProvider.remove(configJSON.FIRST_NAME_KEY)
    await StorageProvider.remove(configJSON.LAST_NAME_KEY)
    localStorage.removeItem(configJSON.PROFILE_KEY)
    localStorage.removeItem(configJSON.AUTH_TOKEN)
    localStorage.removeItem(configJSON.CART_ID_KEY)

    resetUserDetails()
    const cartOrders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    const ordersJSON = JSON.parse(cartOrders)
    setCartBadge(ordersJSON.length)

    history.push("/")
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { wishlistCount } = this.props

    return (
      <Box height="100%">
        <Grid container>
          <Grid item xs={12}>
            <ul className="web_sidebar">
              <li className="web_sidebar_list_item account_setting_item">
                <NavLink
                  exact
                  activeClassName="web_sidebar_active_link .account_setting_item"
                  to="/account-setting"
                  className="web_sidebar_link separate account_setting_item"
                >
                  account setting
                </NavLink>
              </li>
              <li className="web_sidebar_list_item">
                <NavLink
                  exact
                  activeClassName="web_sidebar_active_link"
                  to="/order-history"
                  className="web_sidebar_link separate"
                >
                  order history
                </NavLink>
              </li>
              <li className="web_sidebar_list_item">
                <NavLink
                  exact
                  activeClassName="web_sidebar_active_link"
                  to="/wish-list"
                  className="web_sidebar_link separate"
                >
                  wishlist ( {wishlistCount || 0} )
                </NavLink>
              </li>
              <li className="web_sidebar_list_item">
                <NavLink
                  exact
                  activeClassName="web_sidebar_active_link"
                  to="/view-addresses"
                  className="web_sidebar_link separate"
                >
                  View Addresses
                </NavLink>
              </li>
              <li className="web_sidebar_list_item">
                <NavLink
                  exact
                  activeClassName="web_sidebar_active_link"
                  to="/wallet"
                  className="web_sidebar_link"
                >
                  Wallet
                </NavLink>
              </li>
            </ul>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} />
              <Box className="logout_btn_wrapper">
                <Button
                  variant="contained"
                  className="logout_btn"
                  classes={{
                    label: "normal_login_btn",
                  }}
                  onClick={this.logout}
                >
                  Log Out
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {
    userInfo: state.user,
    wishlistCount: state.user.wishlistCount
  }
}
// Customizable Area End

// Customizable Area Start
// @ts-ignore
export default withRouter(
  connect(mapStateToProps, {
    resetUserDetails,
    setCartBadge,
    setWishlistCount,
  })(WebSideBar)
)
// Customizable Area End