// Customizable Area Start
import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Paper, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Lazyload from "react-lazy-load";
import ReactPlayer from "react-player";
export class LessonTab extends React.Component<any,any>{
    
    constructor(props:any){
        super(props);
        this.state={
          expanded : ''
        }
    }
  
    handleChange = (panel: any) => (event: any, isExpanded: any) => {
      this.setState({expanded : isExpanded ? panel : ""});
    };
    
    convertTime = (sec:any) => {
      if (sec >= 3600) {
        // Convert to hours
        const hours:any = (sec / 3600).toFixed(1);
        const roundedHours = parseFloat(hours) === Math.floor(hours) ? Math.floor(hours) : hours;
        return `${roundedHours} Hour${roundedHours > 1 ? 's' : ''}`;
      } else if (sec >= 60) {
        // Convert to minutes
        const minutes:any = (sec / 60).toFixed(1);
        const roundedMinutes = parseFloat(minutes) === Math.floor(minutes) ? Math.floor(minutes) : minutes;
        return `${roundedMinutes} Min${roundedMinutes > 1 ? 's' : ''}`;
      } else {
        // Convert to seconds
        return `${sec} Sec${sec > 1 ? 's' : ''}`;
      }
    }

    handleLesson = (c:any,index:any,partNum:any,partName:any) => {
        this.props.lesson(c,index,partNum,partName)
    }

    handleStartLesson=(c:any,lessonNum:any,partNum:any,partName:any) => {
      this.props.handleStartLessonBtn(c,lessonNum,partNum,partName )
    }
    render(){
        const courseInfo = this.props.courseData        
    return (
      <>
        {courseInfo && courseInfo.map((i: any, index: any) => {
          const partNum = index+1;
          const partName = i.attributes.name
          const lessonCount = i.attributes.course_sub_sections?.data?.filter((lesson:any) =>  lesson.attributes?.lesson_completed === true).length
          return (
  
            <div key={i} className="customAccordian6">
              <Paper variant="outlined" className="paper">
                <Accordion
                  data-test-id="lessonChange"
                  expanded={this.state.expanded === index}
                  onChange={this.handleChange(index)}
  
                >
                  <AccordionSummary
                    expandIcon={<ArrowForwardIosIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="AccordionSummary"
                    style={{ backgroundColor: this.state.expanded === index ? 'rgba(20, 118, 32, 0.08)' : '#fff' }}
  
                  >
                    <Typography className="topTitle6">
                      Part 0{index + 1} [{partName}]<br />
                      <p className="completion-status">{lessonCount ? lessonCount : '0'}/{i.attributes?.course_sub_sections?.data?.length} completed</p>
                    </Typography>
                  </AccordionSummary>
                  {i.attributes.course_sub_sections && i.attributes.course_sub_sections.data.map((c: any, index: any) => {
                    return (
                      <AccordionDetails key={i} className="AccordionSection" style={{ backgroundColor: '#fff' }}>
                        <Accordion
                          className="accordionWrapper" style={{ }}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowForwardIosIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="AccordionSummary"
                            data-test-id="lessonAccord"
                            onClick={() => this.handleLesson(c,index+1,partNum,partName)}
                            style={{display: "flex", justifyContent:'space-between', alignItems:'center'}}
                          >
                            <div className="detailWrapper">
                              {c.attributes?.video &&
                                <Lazyload offset={100} className="videoThumbnail">
                                  <ReactPlayer
                                    className="video1"
                                    width="100"
                                    height="100"
                                    preload="auto"
                                    playsinline
                                    url={c.attributes.video.url}
                                    controls={false}
                                  />
                                </Lazyload>}
                              <div style={{ marginLeft: 15 }}>
                                <h6 >Lesson {index + 1} - {c.attributes && c.attributes.heading}</h6>
                                <p className="time">{this.convertTime(c.attributes && c.attributes.video_duration)}</p>
                              </div>
                            </div>  
                              {c.attributes?.lesson_completed &&
                              <div style={{ display: 'flex',justifyContent:'flex-end',alignItems:'center' }}>
                                <h6 className="completedStat"> Completed</h6>
                              </div>
                              }
                              
                            
  
                          </AccordionSummary>
  
                          <AccordionDetails>
                            <div>
                            <p className="para" style={{ backgroundColor: '#fff', marginLeft: '40px', color: '#92929d'}}>{c.attributes && c.attributes.description}</p>
                            
                            <div className="buttonWraper" onClick={() => this.handleStartLesson(c.id,index+1,partNum, partName)}>
                              <Button className="lessonBtn" style={{borderRadius:'40px',color:'white',padding:'5px 20px', backgroundColor:'#20a258', border:'1px solid #59bd83'}}>Start Lesson</Button>
                              </div>
                            </div>
                          </AccordionDetails>
  
                        </Accordion>
  
                      </AccordionDetails>
  
                    )
                  })}
  
                </Accordion>
  
              </Paper>
              
            </div>
          )
        })}
      </>
    );
    }
  }

// Customizable Area End  