// Customizable Area Start
import React from "react"
import { Box, Typography, IconButton } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { withRouter } from "react-router-dom"
import AdminTransactionHistoryController, {
  Props,
} from "./AdminTransactionHistoryController.web"
import TransactionHistoryTable from "../../../info-page/src/Wallet/TransactionHistory/TransactionHistoryTable.web"
const configJSON = require("../config.js")

export const styles = () =>
  createStyles({
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "20px",
      height: "100%",
      "& > div:nth-child(2)": {
        flex: 1,
      },
    },
    headingWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      "& button": {
        padding: "12px",
        "& svg": {
          color: "#333"
        }
      }
    },
    mainHeading: {
      fontSize: "24px",
      color: "#686868",
      textAlign: "end",
      fontFamily: "'Montserrat', sans-serif",
      "@media screen and (max-width:480px)": {
        fontSize: "22px",
      },
    },
  })

export class AdminTransactionHistory extends AdminTransactionHistoryController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props

    return (
      <Box className={classes.mainWrapper}>
        <Box className={classes.headingWrapper}>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography variant="h6" className={classes.mainHeading}>
            {configJSON.pointsTransactionsLabel}
          </Typography>
        </Box>
        <TransactionHistoryTable />
      </Box>
    )
  }
}

export default withStyles(styles)(withRouter(AdminTransactionHistory))
// Customizable Area End
