// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
export const configJSON = require("../../config.js")

export interface IDiscussion {
    id: string
    type: string
    attributes: {
        account_id: number
        author_name: string
        date: string
        topic_id: number
        description: string
        discussion_heading: string
        comment_count: number
        forum_comments: any[]
        follows: boolean
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    activeTopicId: string
    discussionFilter: "all" | "following" | "tags"
    discussions: IDiscussion[]
    setCommunityDiscussions: (discussions: IDiscussion[]) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    loadMoreLoader: boolean
    currentPage: number
    totalPages: number
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityDiscussionListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDiscussionsApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            loadMoreLoader: false,
            currentPage: 1,
            totalPages: 0
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area
    async componentDidMount(): Promise<void> {
        !!this.props.activeTopicId && this.getDiscussionsApi()
    }

    async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
        const { activeTopicId, discussionFilter } = this.props
        if (
            activeTopicId !== prevProps.activeTopicId ||
            discussionFilter !== prevProps.discussionFilter
        ) {
            this.setState({ currentPage: 1 })
            this.getDiscussionsApi(1)
        }
    }

    increasePageNumber = () => {
        const nextPageNo = this.state.currentPage + 1
        this.setState({ currentPage: nextPageNo })
        this.getDiscussionsApi(nextPageNo)
    }

    getDiscussionsApi = async (pageNo = this.state.currentPage) => {
        if (pageNo === 1) this.setState({ loader: true })
        else this.setState({ loadMoreLoader: true })

        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
        const { activeTopicId, discussionFilter } = this.props

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getDiscussionsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.discussionListingFilterApiEndPoint}?topic_id=${activeTopicId}&filter=${discussionFilter}&page=${pageNo}&per=3`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDiscussionsApiCallId) {
            if (!!responseJson?.data) {
                const {
                    data,
                    meta: {
                        pagination: { current_page, total_pages },
                    },
                } = responseJson
                const { discussions, setCommunityDiscussions } = this.props

                if (current_page === 1) setCommunityDiscussions(data)
                else setCommunityDiscussions([...discussions, ...data])

                this.setState({ loader: false, totalPages: total_pages, loadMoreLoader: false })
                return
            }
            toast.error("Something went wrong")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                this.handleSuccessApiResponse(apiRequestCallId, responseJson)
            } else {
                if(await isTokenExpired(responseJson)) return
                if (apiRequestCallId === this.getDiscussionsApiCallId) {
                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}

