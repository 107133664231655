// Customizable Area Start
import React from "react"
import { Avatar, Box, Container, Grid, Typography } from "@material-ui/core"
import { QuoteImage, OldHome, DefaultProfile } from "../../assets"
import { connect } from "react-redux"

interface ITestimonial {
    id: string
    type: string
    attributes: {
        name: string
        user_type: string
        description: string
        image: {
            id: number
            filename: string
            url: string
        }
    }
}

interface IProps {
    WLLPTestimonialsHeading: string
    WLLPTestimonials: ITestimonial[]
}

const WLLPTestimonials = ({
    WLLPTestimonialsHeading,
    WLLPTestimonials,
}: IProps) => {
    return (
        <Box className="landing_info_fifth">
            <Container fixed>
                <Grid container className="landing_info_offer_heading">
                    <Grid item xs={12}>
                        <Typography align="center">Testimonials</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center">{WLLPTestimonialsHeading}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    {WLLPTestimonials?.map((testimonial: ITestimonial) => {
                        const {
                            id,
                            attributes: { name, description, image, user_type },
                        } = testimonial
                        return (
                            <Grid item xs={12} sm={6} md={4} key={id}>
                                <Box className="landing_info_testimonial_boxes">
                                    <Typography>{description}</Typography>
                                    <Box className="testimonial_user_box">
                                        <Avatar src={image?.url || DefaultProfile} />
                                        <Box>
                                            <Typography variant="h6">{name}</Typography>
                                            <Typography variant="h6">{user_type}</Typography>
                                        </Box>
                                    </Box>
                                    <img
                                        src={QuoteImage}
                                        alt="quote"
                                        className="testimonial_quote"
                                    />
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
            <img src={OldHome} alt="old_home" className="guest_landing_old_home" />
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        WLLPTestimonialsHeading: state.cms.WLLPTestimonialsHeading,
        WLLPTestimonials: state.cms.WLLPTestimonials,
    }
}

export default connect(mapStateToProps, {})(WLLPTestimonials)
// Customizable Area End
