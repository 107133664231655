//Customizable Area Start
import * as React from "react";
import { useState } from 'react'
// custom components
import {
  Button, Avatar, Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
} from "@material-ui/core";

import ReplyAllIcon from "@material-ui/icons/ReplyAll";

import "./assets/css/customerReviewandReply.scss";
import { DustbinIcon } from "../../blocks/catalogue/src/assets";
import CustomButton from "./CustomButton.web";
export const configJSON = require("./config.js");

const styles = {
  deleteContainer : {
    border: '1px solid red', 
    padding:'4px', 
    borderRadius:'4px', 
    marginRight:'20px',
    cursor : 'pointer'
  },
  deleteIcon : {
    width:'21px', 
    height:'21px'
  }
}

//Customizable Area End

const RestaurantReviewandReply = (props: any) => {
  //Customizable Area Start
  const [openDialog, setOpenDialog] = useState(false)
  const {
    user,
    reviewdata,
    deleteReview,
    handleReviewReply,
    setFieldValue,
    replyData,
    handleReplyEditId,
    deleteReplyReview,
    classes
  } = props;

  const handleDialog = () => {
    setOpenDialog(!openDialog)
  }
  //Customizable Area End
  return (
    //Customizable Area Start
      <div className="commentReviewContainer">
      <div className="detailsContainer">
        {user && (
          <div className="imageContainer">
            <Avatar alt="profile" src={reviewdata?.attributes?.image?.url} />
          </div>
        )}  
          <div className="reviewContainer">
            <div className="heading">
            {user ? (
                <h5>{reviewdata?.attributes?.full_name}</h5>
            ): (
              <h6>{configJSON.yourReplyText}</h6>
            )}
            {user && <p>{reviewdata?.attributes?.date}</p>}
            </div>
            <p className="review">{user ? reviewdata?.attributes?.comment : replyData?.attributes.description} </p>

          </div>
          </div>
          <div className="buttonContainer">
            <div className={classes.deleteContainer} data-test-id="delBtn" onClick={() => handleDialog()}>
              <img src={DustbinIcon} className={classes.deleteIcon}  />
            </div>
            {user ? (
              <Button data-test-id="replyBtn" variant="contained" endIcon={<ReplyAllIcon />} onClick={() => {
                handleReviewReply(reviewdata.id, setFieldValue);
                handleReplyEditId("", setFieldValue);
              }}>
                {configJSON.replyText}
              </Button>
            ) : (
              <Button variant="outlined" data-test-id="editBtn" onClick={() => {
                setFieldValue(configJSON.replyEditText, replyData.attributes.description)
                handleReplyEditId(replyData.id, setFieldValue);
                handleReviewReply("", setFieldValue)
              }}>{configJSON.editText}</Button>
            )}
          </div>
          <Dialog
            data-test-id="dialogBox"
            open={openDialog}
            onClose={() => handleDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {configJSON.deleteCommentText} {user ? configJSON.reviewText : configJSON.replyText}?
            </DialogTitle>
            <DialogActions>
              {/* //@ts-ignore */}
              <span className="redContained customCancelBtn">
                <CustomButton
                  color="secondary"
                  label="No" onClick={() => handleDialog()} /></span>
              {/* //@ts-ignore */}
              <span className="greenContained">
              <CustomButton
              color="secondary"
              label="Yes"
                onClick={() => {
                  handleDialog();
                  {
                    user ? deleteReview(reviewdata.id) :
                      deleteReplyReview(replyData.id)
                  }
                }}
                autoFocus
              />
              </span>
            </DialogActions>
          </Dialog>
      </div>

//Customizable Area End
  );
};

//Customizable Area Start
export default withStyles(styles)(RestaurantReviewandReply);
//Customizable Area End