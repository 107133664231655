// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config.js");
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import StorageProvider from "../../../framework/src/StorageProvider.web";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  FAQdata: any;
  expanded: any;
  loading: any;
}

interface SS {
  id: any;
}

export default class webFaqController extends BlockComponent<Props, S, SS> {
  getFAQApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      FAQdata: [],
      expanded: false,
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    this.getFAQ();
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.successApiCall(responseJson, apiRequestCallId);
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getFAQApiCallId) {
      this.setState({
        FAQdata: responseJson.data,
        loading: false,
      });
    }
  };

  getFAQ = async () => {
    this.setState({
      loading: true,
    });
    const token = await StorageProvider.get("authToken");

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFAQApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFAQApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (id: any) => {
    this.setState({
      expanded: id,
    });
  };
}

// Customizable Area End
