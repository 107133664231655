import React, { useEffect, useState } from "react";
// custom components
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Collapse,
  IconButton,
  Button,
} from "@material-ui/core";
import "../../web/src/assets/css/content/button.scss";
import "../../web/src/assets/css/content/tab.scss";
import "../../web/src/assets/css/content/table.scss";
import CustomButton from "./CustomButton.web";
import { HarvestImage } from "./assets";
import CustomCheckboxField from "./CustomCheckboxField.web";
import CustomPagination from "./CustomPagination.web";
import { MoreVertRounded } from "@material-ui/icons";
import CustomOrderCancellationMenu from "../../blocks/ordermanagement/src/CancellationComponents/CustomOrderCancellationMenu.web";
const configJSON = require('./config.js')

interface IMenuOptionUtility {
  isCancelled: boolean;
  isRefunded: boolean;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomOrderTab = (props: any) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [collapseId, setCollapseId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [requestAnchorEl, setRequestAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [menuId, setMenuId] = React.useState("");
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [isRefunded, setIsRefunded] = useState(false);

  const {
    productList,
    getProductList,
    currentFarmId,
    status,
    search,
    sortValue,
    handleStatus,
    updateOrderStatus,
    total_count,
    total_pages,
    farmOrdersPerPage,
    farmOrdersCurrentPage,
    pageChange,
    order_count,
    openOrderCancelApproveDialog,
    openOrderCancelRejectDialog
  } = props;

  const handleCancellationByAdmin = (callback: () => void) => {
    setAnchorEl(null)
    callback()
  }

  const handleCancellationRequest = (callback: () => void) => {
    setRequestAnchorEl(null)
    callback()
  }

  const adminCancelMenus = [{
    label: "Cancel order",
    disabled: isOrderCancelled,
    onClick: () => handleCancellationByAdmin(() => openOrderCancelApproveDialog(menuId, false, true))
  },
  {
    label: "Initiate refund",
    disabled: isRefunded,
    onClick: () => handleCancellationByAdmin(() => openOrderCancelApproveDialog(menuId, true, true))
  }]

  const requestCancelMenus = [{
    label: "Accept cancellation",
    onClick: () => handleCancellationRequest(() => openOrderCancelApproveDialog(menuId, false))
  },
  {
    label: "Reject cancellation",
    onClick: () => handleCancellationRequest(() => openOrderCancelRejectDialog(menuId))
  },
  {
    label: "Initiate refund",
    disabled: isRefunded,
    onClick: () => handleCancellationRequest(() => openOrderCancelApproveDialog(menuId, true))
  }]

  const handleButtonLabel = (status: string) => {
    if (status == "placed") {
      return "Accept Order";
    } else if (status == "preparing") {
      return "Pick Up Order";
    } else if (status === "picked_up") {
      return "In Transit Order"
    } else {
      return "Order Delivered";
    }
  };

  const handleOrderReady = (value: any) => {
    handleChange("", value);
  };

  const handleCollapse = (id: any) => {
    setOpen((isOpen: boolean) => !isOpen && !!id)
    setCollapseId(id)
  }

  const openOrderMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string, { isCancelled, isRefunded }: IMenuOptionUtility) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setMenuId(id)
    setIsOrderCancelled(isCancelled)
    setIsRefunded(isRefunded)
  }

  const closeOrderMenu = () => {
    setAnchorEl(null)
    setMenuId("")
    setIsOrderCancelled(false)
    setIsRefunded(false)
  }

  const openCancelRequestMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string, isRefunded: boolean) => {
    e.stopPropagation()
    setRequestAnchorEl(e.currentTarget)
    setMenuId(id)
    setIsRefunded(isRefunded)
  }

  const closeCancelRequestMenu = () => {
    setRequestAnchorEl(null)
    setMenuId("")
    setIsOrderCancelled(false)
    setIsRefunded(false)
  }

  useEffect(() => {
    handleCollapse("");
  }, [status]);

  const handleChange = (_: any, newValue: any) => {
    handleStatus(newValue);
    setValue(newValue);
  };

  const handleOpenOrderModal = (data: any) => {
    props.handleOpenModal(data)
  };

  const handleChangeTablePage = (value: number) => {
    pageChange(farmOrdersPerPage, value);
    getProductList(
      currentFarmId,
      sortValue,
      search,
      status,
      farmOrdersPerPage,
      value
    );
  };

  const handleIncreasePageView = () => {
    if (farmOrdersPerPage < 25) {
      pageChange(farmOrdersPerPage + 5, 1);
      getProductList(
        currentFarmId,
        sortValue,
        search,
        status,
        farmOrdersPerPage + 5,
        1
      );
    }
  };

  const handleDecreasePageView = () => {
    if (farmOrdersPerPage > 5) {
      pageChange(farmOrdersPerPage - 5, 1);
      getProductList(
        currentFarmId,
        sortValue,
        search,
        status,
        farmOrdersPerPage - 5,
        1
      );
    }
  };
  
  const indexOfLastList = farmOrdersCurrentPage * farmOrdersPerPage;
  const indexOfFirstList = indexOfLastList - farmOrdersPerPage;

  const currentTableList = productList.length > 0 ? productList : [];
  const roundPages =
    currentTableList && Math.floor(total_count / farmOrdersPerPage);
  let totalListCount: any;
  if (farmOrdersCurrentPage > roundPages) {
    totalListCount = total_count;
  } else {
    totalListCount = indexOfLastList;
  }

  return (
    <div className="customTab">
      <Tabs
        data-test-id="tabs"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: "green",
          },
        }}
      >
        <Tab label="All " {...a11yProps(0)} />
        <Tab
          label={<span className="title">New Order</span>}
          icon={
            order_count.new_order ? (
              <span className="badge">{order_count.new_order}</span>
            ) : (
             ""
            )
          }
          {...a11yProps(1)}
        />
        <Tab
          label="Preparing"
          icon={
            order_count.preparing ? (
              <span className="badge">{order_count.preparing}</span>
            ) : (
              ""
            )
          }
          {...a11yProps(2)}
        />

        <Tab
          label="Picked Up"
          icon={
            order_count.picked_up ? (
              <span className="badge">{order_count.picked_up}</span>
            ) : (
              ""
            )
          }
          {...a11yProps(3)}
        />
        <Tab
          label="In transit"
          icon={
            order_count?.in_transit ? (
              <span className="badge">{order_count.in_transit}</span>
            ) : (
             ""
            )
          }
          {...a11yProps(4)}
        />
        <Tab
          label="Delivered"
          icon={
            order_count.delivered ? (
              <span className="badge">{order_count.delivered}</span>
            ) : (
              ""
            )
          }
          {...a11yProps(5)}
        />
      </Tabs>
      {[...Array(6)].map((tab: any, index: any) => {
        return (
          <TabPanel
            value={value}
            index={index}
            className="adminOrderTab tabPanel"
            key={tab}
          >
            <TableContainer
              className="tableContainer restOrderListContainer"
              component={Paper}
            >
              {currentTableList && currentTableList.length > 0 ? (
                <Table aria-label="simple table" stickyHeader>
                  <TableHead className="tableHead">
                    <TableRow>
                      <TableCell align="left">#ID</TableCell>
                      <TableCell align="center">TOTAL BILL</TableCell>
                      <TableCell align="center">CUSTOMER</TableCell>
                      <TableCell align="center">order date & time</TableCell>
                      <TableCell align="center">payment</TableCell>
                      <TableCell align="center">DELIVERY DATE</TableCell>
                      <TableCell align="center">ORDER-DETAILS</TableCell>
                      <TableCell align="center">STATUS</TableCell>
                      <TableCell align="center" className="tableHead-menu-column"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTableList.map((item: any) => {
                      const { id, attributes: {
                        customer,
                        total_bill,
                        order_date_and_time,
                        payment,
                        delivery_date,
                        order_details,
                        status: orderStatus,
                        order_type,
                        order_cancel_status,
                        cancelled_by_owner,
                        refund_payment_status
                      } } = item
                      const isPickUpOrder = order_type === configJSON.pickUpLabel
                      const notValidTabs = isPickUpOrder
                        ? ["all", "picked_up", "in_transit", "delivered"]
                        : ["all", "delivered"]
                      const isCancellationRequest = order_cancel_status === "pending"
                      const isCancelled = order_cancel_status === "approved" || cancelled_by_owner
                      const isRefunded = isCancelled && refund_payment_status === "completed"
                      const isInitiateDisabled = !isCancelled || isRefunded
                      const orderMenuUtility = {
                        isCancelled,
                        isRefunded: isInitiateDisabled
                      }

                      return (
                        <>
                          <TableRow
                            data-test-id="tableRow"
                            key={id}
                            onClick={() =>
                              !notValidTabs.includes(status) &&
                              handleCollapse(id)
                            }
                          >
                            <TableCell scope="row">
                              {id}
                            </TableCell>
                            <TableCell align="center">
                              <div className="customerCell">
                                <h5 data-test-id="orderBill">{total_bill}</h5>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div className="customerCell">
                                <h5>{customer}</h5>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              {order_date_and_time}
                            </TableCell>
                            <TableCell align="center">
                              <div className="payment-checkbox dangerCheckbox">
                                <CustomCheckboxField
                                  id="paymentCheckbox"
                                  checked={payment === "Paid"}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              {delivery_date}
                            </TableCell>
                            <TableCell
                              data-test-id="viewDetails"
                              align="center"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                handleOpenOrderModal(item);
                              }}
                            >
                              <span className="viewLink">
                                View Details
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              <Button
                                className="cancel-order-button"
                                hidden={!isCancellationRequest}
                                onClick={(e) =>openCancelRequestMenu(e, id, isInitiateDisabled)}>
                                  Cancel
                              </Button>
                              {!isCancellationRequest &&
                                (orderStatus === "rejected" ? "Cancelled" : orderStatus.replace(/_/g," "))}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={(e) => openOrderMenu(e, id, orderMenuUtility)}>
                                  <MoreVertRounded />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          {collapseId == item.id && (
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                                colSpan={7}
                              >
                                <Collapse
                                  in={open}
                                  timeout="auto"
                                  unmountOnExit
                                  className="farmCollapse"
                                >
                                  <div className="cookingList accordian">
                                    <div className="scrollBlock">
                                      {order_details.data.map(
                                        (product: any) => {
                                          return (
                                              <div
                                                className="cookingrow"
                                                key={product.id}
                                              >
                                                <div className="blocks">
                                                  <img
                                                    src={
                                                      product.attributes
                                                        .product_image?.url
                                                    }
                                                    className="orderImg"
                                                    alt="Order Img"
                                                  />
                                                </div>
                                                <div className="blocks">
                                                  <h5>
                                                    {
                                                      product.attributes
                                                        .product_name
                                                    }
                                                  </h5>
                                                </div>
                                                <div className="blocks">
                                                  <h6>
                                                    Quantity:{" "}
                                                    {
                                                      product.attributes
                                                        .quantity
                                                    }{" "}
                                                    {product.attributes.unit}
                                                  </h6>
                                                </div>
                                              </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="greenContained">
                                      <CustomButton
                                        data-test-id="updateStatus"
                                        label={handleButtonLabel(status)}
                                        onClick={async () => {
                                          await updateOrderStatus(
                                            item.id,
                                            value + 1
                                          );
                                          handleOrderReady(value + 1);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <div className="norecord-container">
                  <img src={HarvestImage} alt="harvest" />

                  <h3>
                    No{" "}
                    <span
                      style={{
                        textTransform: "lowercase",
                        color: "#20a258",
                      }}
                    >
                      orders
                    </span>{" "}
                    found
                  </h3>
                </div>
              )}
            </TableContainer>
            {currentTableList?.length > 0 && <div className="paginationRow restOrderPagination">
              <div>
                {indexOfFirstList + 1}- {totalListCount} of {total_count} items
              </div>
              <CustomPagination
              data-test-id="pagination"
                count={total_pages}
                page={farmOrdersCurrentPage}
                pagePerView={farmOrdersPerPage}
                handlePageChange={handleChangeTablePage}
                shape="rounded"
                siblingCount={1}
                increasePagePerView={handleIncreasePageView}
                decreasePagePerView={handleDecreasePageView}
                boundaryCount={1}
              />
            </div>}
          </TabPanel>
        );
      })}

      <CustomOrderCancellationMenu
        menus={adminCancelMenus}
        anchorEl={anchorEl}
        closeOrderMenu={closeOrderMenu}
      />

      <CustomOrderCancellationMenu
        anchorEl={requestAnchorEl}
        menus={requestCancelMenus}
        closeOrderMenu={closeCancelRequestMenu}
      />
    </div>
  );
};
export default CustomOrderTab;
