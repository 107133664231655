const FarmDetailsInitialState = {
    average_rating: 0,
    description: "",
    facebook: "",
    favourites: false,
    icons: { data: [] },
    images: [],
    instagram: "",
    name: "",
    popular_farm_catalogues: { data: [] },
    popular_products_description: "",
    reviews: { data: [] },
    status: "",
}

const initialState = {
    farms: [],
    farmDetails: { ...FarmDetailsInitialState },
    farmRatings: [],
    pagination: {
        current_page: 0,
        next_page: 0,
        per_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0
    },
    currentPage: 1,
    showCount: 16,
    isLoading: false,
    startPrice: 0,
    endPrice: 0,
    minPrice: 0,
    maxPrice: 0,
    filterName: "",
    filterByTagName: "",
    filterByCategoryName: "",
    farmsCategories: [],
    farmsTags: [],
    selectedCategory: "",
    selectedTag: "",
    searchText: "",
    lat: "",
    lng: "",
    searchAddress: "",
    nearByFilter: false,
    isLoadingWrite: false,
}

export const farmReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case 'SET_FARM_LIST':
            return {
                ...state,
                farms: payload
            }
        case 'SET_FARM_DETAILS':
            return {
                ...state,
                farmDetails: payload,
                farmRatings:payload?.reviews?.data
            }
        case 'SET_PAGINATION_FOR_FARMERS_LIST':
            return {
                ...state,
                pagination: payload
            }
        case 'SET_IS_LOADING_TRUE':
            return {
                ...state,
                isLoading: true
            }
        case 'SET_IS_LOADING_FALSE':
            return {
                ...state,
                isLoading: false
            }
        case 'SET_FARMS_CURRENT_PAGE':
            return {
                ...state,
                currentPage: payload
            }
        case 'SET_FARMS_FILTER_START_PRICE':
            return {
                ...state,
                startPrice: payload
            }
        case 'SET_FARMS_FILTER_END_PRICE':
            return {
                ...state,
                endPrice: payload
            }
        case 'SET_FARMS_FILTER_PRICES':
            const { min_price, max_price } = payload
            return {
                ...state,
                minPrice: Number(min_price) || 0,
                maxPrice: Number(max_price) || 0
            }
        case 'SET_FARMS_FILTER_CATEGORIES':
            return {
                ...state,
                farmsCategories: payload
            }
        case 'SET_FARMS_FILTER_TAGS':
            return {
                ...state,
                farmsTags: payload
            }
        case 'SET_FARMS_FILTER_SELECTED_CATEGORY':
            if(state.selectedCategory === payload){
                return {
                    ...state,
                    selectedCategory: ""
                }
            }else{
                return {
                    ...state,
                    selectedCategory: payload
                }
            }
        case 'SET_FARMS_FILTER_SELECTED_TAG':
            if(state.selectedTag === payload){
                return {
                    ...state,
                    selectedTag: ""
                }
            }else{
                return {
                    ...state,
                    selectedTag: payload
                }
            }
        case 'SET_FARMS_FILTER_LAT':
            return {
                ...state,
                lat: payload
            }
        case 'SET_FARMS_FILTER_LNG':
            return {
                ...state,
                lng: payload
            }
        case 'SET_FARMS_FILTER_SEARCH_TEXT':
            return {
                ...state,
                searchText: payload
            }
        case 'CLEAR_FARMS_FILTER_SEARCH_AND_LATLNG_FILTERS':
            return {
                ...state,
                searchText: "",
                lat:"",
                lng:"",
            }
        case 'ADD_FARM_REVIEW':
            let ratings = state.farmRatings
            ratings.push(payload)

            let details = state.farmDetails

            const allRatings = details.reviews.data.map(rat => rat.attributes.rating)
            const sum = allRatings.reduce((partialSum, a) => partialSum + a, 0)
            const newRating = sum / allRatings.length
            
            details.average_rating = newRating.toFixed(1)

            return {
                ...state,
                farmDetails: details,
                farmRatings: ratings
            }
        case 'SET_IS_FARM_REVIEW_LOADING_TRUE':
            return {
                ...state,
                isLoadingWrite: true
            }
        case 'SET_IS_FARM_REVIEW_LOADING_FALSE':
            return {
                ...state,
                isLoadingWrite: false
            }
        case 'SET_FARM_SEARCH_ADDRESS':
          return {
              ...state,
              searchAddress: payload
          }
        case 'SET_NEAR_BY_FARM_FILTER':
            return {
                ...state,
                nearByFilter: payload
            }
        case 'FARM_ADD_TO_FAVORITE_BUTTON':
            let updatedFev = {
                ...state.farmDetails,
                favourites:!state.farmDetails.favourites
            }
            return {
                ...state,
                farmDetails: updatedFev
            }
        case 'RESET_FARMS_FILTERS':
            return {
                ...state,
                currentPage: 1,
                showCount: 16,
                isLoading: false,
                startPrice: 0,
                endPrice: 0,
                minPrice: 0,
                maxPrice: 0,
                filterName: "",
                filterByTagName: "",
                filterByCategoryName: "",
                selectedCategory: "",
                selectedTag: "",
                searchText: "",
                lat: "",
                lng: "",
                nearByFilter: false
            };
        default:
            return state
    }
}