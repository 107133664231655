// Customizable Area Start
import React from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import LocationPinIcon from "@material-ui/icons/LocationOnRounded";
import "../assets/css/restaurantmapview.scss";

interface IRestaurantCardMap {
  id: string;
  lat: number;
  lng: number;
  image: string;
  restaurantName: string;
  rating: string | number;
  dishName: string;
  address: string;
  averageTime: string;
  price: string | number;
  isActive: boolean;
  $hover?: boolean;
  handleOrderFood: (id: string) => void;
  openDetails: (id: string) => void;
  closeDetails: () => void;
}

const RestaurantCardMap = ({
  id,
  image,
  restaurantName,
  rating,
  dishName,
  address,
  averageTime,
  price,
  isActive,
  $hover,
  handleOrderFood,
  openDetails,
  closeDetails,
}: IRestaurantCardMap) => {
  return (
    <>
      <IconButton
        className={`restaurant_map_pin ${
          $hover ? "restaurant_map_pin_hover" : ""
        }`}
        onClick={() => openDetails(id)}
      >
        <LocationPinIcon />
      </IconButton>
      {isActive && (
        <Box
          className="restaurant_map_selected_card"
          onClick={(e: any) => e.preventDefault()}
        >
          <Box className="restaurant_map_upper_wrapper">
            <img src={image} alt="restaurant_image" />
            <Box className="selected_restaurant_card_rating_container">
              <Box className="selected_restaurant_card_rating">
                <StarOutlineIcon />
                <Typography>{rating}</Typography>
              </Box>
              <Box className="selected_restaurant_card_close">
                <IconButton onClick={() => closeDetails()}>
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className="selected_restaurant_card_details">
            <Typography className="selected_card_dish">{dishName}</Typography>
            <Typography className="selected_card_restaurant">
              {restaurantName}
            </Typography>
            <Typography className="selected_card_address">{address}</Typography>
            <Box className="selected_card_time_container">
              <Typography className="selected_card_time">
                {averageTime || "No estimated time"}
              </Typography>
              <Typography className="selected_card_price">
                From {price}
              </Typography>
            </Box>
            <Button
              className="selected_card_order_button"
              onClick={() => handleOrderFood(id)}
            >
              Order food
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RestaurantCardMap;
// Customizable Area End
