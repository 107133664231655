// Customizable Area Start
import * as React from 'react';
import landingPageController, {
  Props,
  configJSON
} from "./landingPageController.web";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import BusinessTeachBanner from './BusinessTeachBanner.web';
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import { withRouter } from "react-router-dom";
// css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import "../../../blocks/info-page/src/assets/css/community/communityExperts.scss";

import {
  CircularProgress,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
//images
import {
  GrpImg,
  updateActiveTab,
  getPopularCoursesLearning,
  getLearningDetails,
  setCourse, 
  courseBanner,
  TechPop01,
  resetSearchFilters,
} from "./assets";
//components
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
///swiper imports
// import SwiperCore, { Navigation, Pagination } from "swiper";
// import "swiper/swiper-bundle.min.css";
// Customizable Area Start
import Image from "material-ui-image"
import LazyLoad from 'react-lazy-load';
import { connect } from "react-redux";
// Customizable Area End

// swiper core styles
// import "swiper/swiper.min.css";

// modules styles
// import "swiper/components/navigation/navigation.min.css";
// import "swiper/components/pagination/pagination.min.css";
// SwiperCore.use([Navigation, Pagination]);
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


import { ScrollView } from "react-native";
import ExpertCardCarousel from "../../../components/src/ExpertCardCarousel.web";
import { Box } from "@mui/material";
import Slider from 'react-slick';
// Customizable Area End

export class LandingPage extends landingPageController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    // Customizable Area Start
    const {courses,searchCourse} = this.props
    const {currentSlidesToShow} = this.state;
    const details = courses?.learningDetails;
    const popularTeachers = details.attributes?.popular_teachers.data

    const popTeachersLength =  popularTeachers?.length 
    const initialSlide = Math.floor(popularTeachers?.length/2)
    const isRequiredSlidesExist = popTeachersLength > currentSlidesToShow
    const slideToShow = isRequiredSlidesExist
      ? currentSlidesToShow
      : popTeachersLength
    const {classes} = this.props
    
    return (
      <>
        {/* <div className="Header"> */}
        <ScrollView>
          <WebHeader />
          <WebNewRoutingTabs />
          <div id="learningHome" className="learningPadding">
            <Grid container spacing={2} className={classes.headerWrapper}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.headerContainer}>
                <div className="contentBlock bannerBlocks" >
                  <h1 className="learningHeading">
                    {details?.attributes?.learning_heading}
                  </h1>

                  <Grid item xs={12} sm={12} className="searchbox" style={{ display: 'flex' }}>
                    <CustomTextfield 
                    className="searchContainer" 
                      data-test-id={"searchTextField"}
                      placeholder={configJSON.searchPlaceholderText}
                      value={searchCourse}
                      onChange={this.handleSearchValue}
                      onKeyDown={this.searchCourseByEnter}
                    />

                    <div className="circleBtn searchBoxContainer" 
                    >
                      <CustomButton 
                      data-test-id={"searchCourseBtn"}
                      label="Search" 
                      onClick={this.handleSearchCourse} 
                      className="landingSearch"
                      />
                    </div>
                  </Grid>
                </div>

              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} className="learningHeaderWrapper">
                <div style={{ position: 'relative' }}>
                {!this.state.imageLoaded && 
                <CircularProgress className={classes.imageLoader} size={25}/>
                  }
                <LazyLoad offset={100}>
                <img
                onLoad={this.handleImageLoad}
                src={details?.attributes?.learning_header_image?.url} 
                className="headerImg"/> 
                </LazyLoad>
                  <div className="avtar-grp">
                    <img src={GrpImg} className="groupImg" />
                    <h6 className="totalStudents">
                      {details?.attributes?.student_count-1}+ {configJSON.studentsText}
                    </h6>
                    <p>
                      {configJSON.learnDailyText}
                    </p>
                  </div>
                </div>

              </Grid>

            </Grid>

            <Grid container className="learningEnrollSection">
              <Grid item xs={6} sm={6} md={6} lg={6} className="enrollImgContainer">
              {!this.state.imageLoaded && 
                <CircularProgress className={classes.imageLoader} size={25}/>
                  }
              <LazyLoad offset={100}>
                <img 
                onLoad={this.handleImageLoad}
                src={details?.attributes?.learning_enroll_image?.url} className="left-img" style={{ animationDuration:'500ms',display: 'flex', flexBasis: '-moz-max-content' }} />
              </LazyLoad>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className="enrollContentWrapper">
                <div className="contentBlock bannerBlocks">
                  <h1 className="h1 enrollHeading">
                    {details?.attributes?.learning_enroll_heading}
                  </h1>

                  <p className="paraText">
                    {details?.attributes?.learning_enroll_description}
                  </p>

                  <div className="circleBtn landingEnroll">
                    <CustomButton data-test-id={"enrollNowBtn"} className={classes.enrollNowBtn} onClick={this.handleButton} label="Enroll Now"/>
                  </div>


                </div>
              </Grid>

            </Grid>
          </div>


          <Grid container className="popTeachContainer" >
            <Grid item xs={12} className="popTeachPadding">
              <h1 className="popTeacher">
                {configJSON.popularTeacherText}
              </h1>
            </Grid>
            {popularTeachers?.length  > 0 &&  
            <Box className="ce_carousel_wrapper" width="92vw">
                    
                    <Slider
                        className="ce_carousel"
                        arrows={false}
                        centerMode={isRequiredSlidesExist}
                        centerPadding="150px"
                        infinite={true}
                        slidesToShow={slideToShow}
                        speed={700}
                        initialSlide={initialSlide}
                        draggable
                        responsive={[
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 3,
                                    centerPadding: '150px'
                                },
                            },
                            {
                                breakpoint: 960,
                                settings: {
                                    slidesToShow: 3,
                                    centerPadding: '0'
                                },
                            },
                            {
                              breakpoint: 600,
                              settings: {
                                  slidesToShow: 3,
                                  centerPadding: '0'
                              },
                          }
                        ]}
                        onReInit={this.handleOnReInit}
                    beforeChange={this.handleBeforeChange}
                    afterChange={this.handleAfterChange}
                    >
                        {popularTeachers?.map((data: any) => {
                            const {
                                attributes: { first_name, last_name, headline, educator_image },
                            } = data
                            return (
                              <Box key={headline} className="popTeachCardContainer" >
                                  <img
                                      src={educator_image?.url || TechPop01}
                                      alt="image"
                                      className="popTeachCardImage"
                                  />
                                  <Box className="popCardDetailContainer">
                                      <Typography className="popTeachName">{`${first_name} ${last_name}`}</Typography>
                                      <Typography className="popTeachSkill">{headline}</Typography>
                                  </Box>
                              </Box>
                            )
                        })}
                    </Slider>
            </Box>
            }        
          </Grid>

          <Grid container className="popularCoursesContainer">
            <Grid item xs={12}>
              <Typography variant="h2" className="popCourse">Popular Courses</Typography>
              <Typography variant="h5" className="popText"> {details?.attributes?.popular_courses_description}</Typography>
            </Grid>
          </Grid>

          {courses.isCoursesLoading ?
            <Box className="restaurant_landing_page_loader">
              <CircularProgress size={60} />
            </Box>
          :
            <Grid container spacing={1}  className="popCourseWrapper">
           
              {
                courses?.popularCourses?.filter((c:any, index:any) => index < 6 && (c?.attributes?.status === "active")).map((c: any, index: number) => {
                    return (
                      <>
                      <Grid
                        key={c.id}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        lg={4}
                        className="courseCardWrapper"
                      >
                        <Box className="courses_img_wrapper" data-test-id="courseCard" onClick={this.handleCourseCard.bind(this,c.id)} >
                          <LazyLoad offset={100}>
                          <Image
                      src={c?.attributes?.banner_image?.url || courseBanner}
                      alt="Course banner"
                      style={{ height:'250px',padding: '20px', borderRadius: '30px' }}
                      animationDuration={1000}
                      loading={
                          <CircularProgress className="image_loader" size={25} />
                      }/>
                      </LazyLoad>
                      </Box>
                      <Typography
                          variant="h5"
                          className="course-name"
                        >
                          {" "}
                          {c?.attributes.title}{" "}
                        </Typography>
                        <Typography variant="h6" className="author-name">
                          {" "}
                          by {c?.attributes.first_name} {c?.attributes.last_name}{" "}
                        </Typography>
                      </Grid>
                      </>
                    );
                  // }

                })
              }

            </Grid>
          }
          <Grid container>
            <Grid item
              xs={12}
              className="viewBtnWrapper"
            >
              <div className="circleBtn">
                <CustomButton
                  data-test-id={"viewAllBtn"}
                  className={classes.viewAllBtn}
                  label="View All"
                  onClick={this.handleButton}
                />
              </div>
            </Grid>
          </Grid>

          <BusinessTeachBanner />
          <WebFooter />
        </ScrollView>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  headerWrapper : {
    marginTop: '1rem'
  },
  headerContainer : {
    marginTop: '10%'
  },
  imageLoader : {
    color: '#20a258 !important',
    position:'absolute',
    top: '50%',
    left:'50%'
  },
  viewAllBtn : {
    backgroundColor: "rgb(255 193 7)",
    border: "none"
  },
  enrollNowBtn : {
    backgroundColor: '#ffc107',
    border: 'none'
  }
} 

export const mapStateToProps = (state: any) => {
  return {courses: state.courses
     , searchCourse : state.courses.searchCourse};
};
// Customizable Area End

// Customizable Area Start
const connectComp:any = withRouter(connect(mapStateToProps, { updateActiveTab,resetSearchFilters,getPopularCoursesLearning, getLearningDetails
  , setCourse 
})(LandingPage))

//@ts-ignore
export default withStyles(styles)(connectComp);
// Customizable Area End
