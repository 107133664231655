export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const FarmerMerchant = require("../assets/image/farmer-merchant.png");
export const Restauranteur = require("../assets/image/restauranteur.png");
export const EducationalContributor = require("../assets/image/educationalContributor.png");
export const {updateAccountDetailsAction, handleProfileImage} = require("../../../components/src/redux/Users/userActions")
export const fbImg = require("../assets/image/facebook.png");
export const googleImg = require("../assets/image/google.png");
export const instaImg = require("../assets/image/instagram.png");
export const DefaultProfileImg = require("../assets/image/default-profile.svg");
