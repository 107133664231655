Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.deleteApiMethodType = "DELETE";

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpGetMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.btnExampleTitle = "CLICK ME";
exports.postCreateOrderAPiEndPoint = "bx_block_order_management/orders";
exports.delCartItemAPiEndPoint = "bx_block_order_management/remove_product";
exports.postPaymentIntentAPiEndPoint = "bx_block_payments/payment_intent";
exports.postBuyPackagesApiEndPoint = "bx_block_payments/create_subscription";
exports.getRolesApiEndPoint = "account_block/accounts/get_user_roles";
exports.getDropdownAPiEndPoint = "user_admin/dropdown_list";
exports.getHeaderSearchedDataEndPoint = "bx_block_catalogue/search/search?query="
// Customizable Area End

//custom web

exports.resetPWAPiEndPoint = "bx_block_forgot_password/otps";
exports.resetPWAPiMethod = "POST";
exports.PostAPiMethod = "POST";
exports.restPWApiContentType = "application/json";
exports.GOOGLE_MAP_API_KEY = "AIzaSyAl6Aa52ZsQL2SAUECMYGKWbv3Pq0C1iF4"
exports.GOOGLE_LOGIN_CLIENT_ID = "350052156634-f6vcski427mlqkn299dquikiak2igact.apps.googleusercontent.com"
exports.AUTH_TOKEN = "authToken";
exports.ORDERS_STORAGE_KEY = "orders";
exports.FIRST_NAME_KEY = "firstname";
exports.LAST_NAME_KEY = "lastname";
exports.PROFILE_KEY = "profile";
exports.CART_ID_KEY = "cartId";
exports.CART_BADGE_KEY = "cartBadge";
exports.ADMIN_AUTH = "AdminAuth";
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue";
exports.ENTER_KEY = "Enter"
exports.getContributorApiEndPoint =
  "/bx_block_catalogue/educational_contributors";
exports.footerDetailsApiEndPoint = "bx_block_settings/settings/footer_details"
exports.subscribeApiEndPoint="bx_block_settings/settings/subscribe_now"
exports.getRemainingRolesEndPoint="account_block/accounts/get_user_roles"
exports.getSquareStatusEndPoint = "account_block/accounts/check_square_authorization"
exports.getTagsSuggestionApiEndPoint = "bx_block_catalogue/tags/tag_list"

exports.yourReplyText = "Your Reply";
exports.replyText = "reply";
exports.editText = "Edit";
exports.replyEditText = "replyEdit";
exports.deleteCommentText = "Are you sure you want to delete";
exports.reviewText = "review";
exports.squareConnectHelpMessage = `Connect your Square account to receive payments.\nUntil then, you can't sell products on the WTT platform.`
exports.facebookLoginFailedMsg = "Facebook login failed. Please try again";
exports.selectDateLabel = "select date";
exports.dateRangeLabel = "Date Range";
exports.newPurchaseLabel = "New Purchase";
exports.allText = "all";
exports.placedText = "placed";
exports.purchasesMenu = ["view"];
exports.coursePurchasedLabel = "COURSE PURCHASED";
exports.customerNameLabel = "CUSTOMER NAME";
exports.paymentTypeLabel= "PAYMENT TYPE";
exports.priceLabel = "PRICE";
exports.idLabel = "#ID"; 
exports.orderDateTimeLabel = "ORDER DATE & TIME";
exports.courseAltText = "course";
exports.errorText = "error"
exports.pickUpLabel = "pickup"
exports.termsConditionsLabel = "Terms & conditions"
exports.privacyPolicyLabel = "Privacy Policy"
exports.policyForBuyerLabel = "Policy For Buyer"
exports.policyForSellerLabel = "Policy For Seller"
exports.contactUsLabel = "Contact Us"
exports.faqsLabel = "FAQs"
exports.helpSectionLabel = "Help Section"
exports.instagramText = "Instagram"
exports.filterText = "Filter"
exports.sortText = "Sort"