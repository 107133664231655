import React, { Component } from "react";
import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import { yellow_edit_icon } from "../../assets";
import { formatPhoneNumber } from "../../../../../components/src/utility/helper";

interface Props {
  id: string
  firstName: string
  lastName: string
  companyName: string
  address1: string
  address2: string
  city: string
  country: string
  phoneNumber: string
  isPickUp?: boolean
  editAddress: () => void
}

class PickUpCard extends Component<Props, any, any> {

  getFullAddress = () => {
    const { address1, address2, city, country } = this.props
    return [address1, address2, city, country].filter((record: string) => !!record).join(', ')
  }

  render() {

    const {
      id,
      isPickUp,
      firstName,
      lastName,
      city,
      companyName,
      phoneNumber,
      editAddress } = this.props

    return (
      <Box className="check_out_delivery_card">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={9} md={10} lg={9}>
            <Box>
              <h6>
                {isPickUp ? `${city} Farmers Market` : `${firstName} ${lastName}`}
              </h6>
              <p>{companyName}</p>
              <p>{this.getFullAddress()}</p>
              {!isPickUp && <p>{formatPhoneNumber(phoneNumber)}</p>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={3}>
            <Box
              className={`check_out_delivery_card_actions_container ${isPickUp ? "non_editable_action_container" : ""
                }`}
            >
              {!isPickUp && (
                <IconButton size="small" onClick={editAddress}>
                  <img src={yellow_edit_icon} />
                </IconButton>
              )}
              <FormControlLabel
                value={id}
                control={
                  <Radio color="primary" classes={{ checked: "radio_checked" }} />
                }
                label=""
                classes={{ root: "radio", label: "radio_label" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default PickUpCard;
