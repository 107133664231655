// Customizable Area Start
import React from "react"
import WishlistCourseCardController, {
    Props,
} from "./WishlistCourseCardController.web"
import "./../assets/css/wishlistContentBox.scss"
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Hidden
} from "@material-ui/core"
import Image from "material-ui-image"
import FavoriteIcon from "@material-ui/icons/FavoriteRounded"
import { connect } from "react-redux"
import { getCartItems } from "./assets"
import { withRouter } from "react-router-dom"
import { configJSON } from "./WishListController.web"

export class WishlistCourseCard extends WishlistCourseCardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { image, name, sellerName,status } = this.props
        const { isRemoving } = this.state

        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} lg={2}>
              <Image
                data-test-id="image"
                src={image}
                alt="item_image"
                style={{
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
                imageStyle={{
                  borderRadius: "20px",
                }}
                onClick={this.handleBuyCourse.bind(this, status)}
                loading={<CircularProgress size={18} className="wcb_img_loader" />}
              />
            </Grid>
            <Grid item xs={8} sm={7} lg={6}>
              <Box className="wcc_content_container">
                <Box className="wcc_corse_details">
                  <Typography data-test-id="nameBtn" variant="h3" onClick={this.handleBuyCourse.bind(this, status)}>
                    {name}
                  </Typography>
                  <Typography>By {sellerName}</Typography>
                </Box>
                <Hidden only={["xs", "lg", "xl"]}>
                    <Box className="wcc_action_container">
                      <IconButton
                        data-test-id="favIcon"
                        size="small"
                        className="wcb_fav_icon"
                        onClick={this.handleRemoveFromFav}
                        disabled={isRemoving}
                      >
                        {isRemoving ? (
                          <CircularProgress size={22} color="inherit" />
                        ) : (
                          <FavoriteIcon />
                        )}
                      </IconButton>
                      <Button className="wcb_cart_btn" onClick={this.handleBuyCourse.bind(this, status)}>
                        {configJSON.buyCourseBtnText}
                      </Button>
                    </Box>
                  </Hidden>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
            <Hidden only={["sm", "md"]}>
              <Box className="wcc_action_container">
                <IconButton
                  data-test-id="favIcon"
                  size="small"
                  className="wcb_fav_icon"
                  onClick={this.handleRemoveFromFav}
                  disabled={isRemoving}
                >
                  {isRemoving ? (
                    <CircularProgress size={22} color="inherit" />
                  ) : (
                    <FavoriteIcon />
                  )}
                </IconButton>
                <Button className="wcb_cart_btn" onClick={this.handleBuyCourse.bind(this, status)}>
                  {configJSON.buyCourseBtnText}
                </Button>
              </Box>
              </Hidden>
            </Grid>
          </Grid>
        )
    }
}

export const mapStateToProps = (state: any) => {
    return {
        cartId: state.user.cartId,
    }
}

const CourseWishListCard:any = withRouter(
    connect(
        mapStateToProps,
        { getCartItems }
    )(WishlistCourseCard)
)

export default CourseWishListCard
// Customizable Area End
