// Customizable Area Start
import React, {  } from "react"
import { Box } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import CustomDonationBanner from "../../../../../components/src/CustomDonationBanner.web"
import "react-circular-progressbar/dist/styles.css"
import { GreenOverlayBanner } from "../../assets"

interface IRestaurantCampaignBannerProps extends RouteComponentProps {
  campaignId: number
  heading: string
  description: string
  raisedDonation: string
  requiredDonation: string
  backgroundImage: string
}

const RestaurantCampaignBanner = ({
  campaignId,
  heading,
  description,
  raisedDonation,
  requiredDonation,
  backgroundImage
}: IRestaurantCampaignBannerProps) => {
  return campaignId > 0 ? (
    <Box mt={4}>
      <CustomDonationBanner
        backgroundImage={backgroundImage}
        description={description}
        donatedAmount={Number(raisedDonation.replace("$", ""))}
        donationId={campaignId.toString()}
        heading={heading}
        requiredAmount={Number(requiredDonation.replace("$", ""))}
      />
    </Box>
  ) : null
}

export default withRouter(RestaurantCampaignBanner)
// Customizable Area End
