// Customizable Area Start
import React from "react"
import {
  Grid,
  Box,
  Button,
  TextareaAutosize,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { withRouter, Link } from "react-router-dom"
import ShoppingCartPaymentMethod from "./shoppingCartPaymentMethod.web"
import "../../assets/css/shoppingcartlayout.scss"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ShoppingCartLayoutController, {
  configJSON
} from "./ShoppingCartLayoutController.web"
import {
  setCheckoutOrder,
  setCartOrderMeta,
  resetCartOrderMeta,
  getCartItems
} from "../assets"
import LoginSignUpDialog from "../../../info-page/src/langinpageLoggedIn/LoginSignUpDialog.web"
import ErrorIcon from "@material-ui/icons/ErrorOutlineRounded"
import ShoppingCartLayoutItems from "./ShoppingCartLayoutItems.web"

export class ShoppingCartLayout extends ShoppingCartLayoutController {

  render() {
    const { orderNote, loginDialog, checkOutLoader } = this.state
    const {
      orderType,
      cartDetails,
      isAcceptingOrder,
      getAddressAndItems,
    } = this.props

    const { image: sellerImage, name: sellerName } =
      cartDetails.product_owner_details.data.attributes
    const cartItems = cartDetails.cart_items.data
    const { total, shipping, transaction_fees, sub_total, total_item, discount, item_count } =
      cartDetails.cart_detail
    const isCourse = orderType === configJSON.courseText

    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12} md={7} lg={8}>
            <Box className="shopping_cart_wrapper_box">
              <Grid container spacing={2} wrap="wrap">
                <Grid item xs={12}>
                  <Box className="shopping_cart_details">
                    <Link to={this.state.routeDetails}>
                      <Box>
                        <img src={sellerImage} width="70px" height="70px" alt="seller" />
                        <h3>{sellerName}</h3>
                      </Box>
                    </Link>
                  </Box>
                </Grid>

                {!isAcceptingOrder && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography className="restaurant_close_desc">
                        <ErrorIcon fontSize="small" />
                        {configJSON.notAcceptingErrorText}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <ShoppingCartLayoutItems
                    isAcceptingOrder={isAcceptingOrder}
                    cartItems={cartItems}
                    getAddressAndItems={getAddressAndItems}
                  />
                </Grid>

                <Grid item xs={12} lg={5}>
                  <Box className="cart_total_price_wrapper">
                    <h3>{configJSON.totalText}</h3>
                    <h4>${total_item}</h4>
                  </Box>
                </Grid>
                {!isCourse && (
                  <Grid item xs={12}>
                    <Box>
                      <p className="note_to_order_label">
                        {configJSON.noteToOrderText}
                      </p>
                      <TextareaAutosize
                        value={orderNote}
                        onChange={this.handleChangeOrderNote}
                        onKeyUp={() => this.handleSetOrderNoteGlobal()}
                        placeholder={configJSON.notePlaceholderText}
                        minRows={3}
                        className="note_to_order_textarea"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <Box className="shopping_cart_wrapper_box">
              <ShoppingCartPaymentMethod
                orderType={orderType}
                shippingFee={shipping}
                transactionFees={transaction_fees}
                discount={discount}
                totalPrice={total_item}
                subTotal={sub_total}
                total={total}
                itemCount={item_count}
              />
            </Box>
            <Box className="checkout_btn">
              <Button
                classes={{
                  disabled: "disable_btn",
                }}
                disabled={this.isCheckoutBtnDisabled()}
                onClick={this.handleCheckoutOrder}
              >
                {checkOutLoader ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  configJSON.proceedToCheckoutText
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <LoginSignUpDialog
          isOpen={loginDialog}
          onClose={this.closeLoginDialog}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    cartOrderMeta: state.user.cartOrderMeta,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCheckoutOrder: bindActionCreators(setCheckoutOrder, dispatch),
    setCartOrderMeta: bindActionCreators(setCartOrderMeta, dispatch),
    resetCartOrderMeta: bindActionCreators(resetCartOrderMeta, dispatch),
    getCartItems: bindActionCreators(getCartItems, dispatch)
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShoppingCartLayout)
) as any
// Customizable Area End
