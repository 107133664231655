import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
export const configJSON = require("./config");

interface IAddress {
  first_name: string;
  last_name: string;
  company_name: string;
  address: string;
  address2: string;
  city: string;
  state: string
  country: string;
  postal_code: string;
  phone_number: string;
  address_type: string;
}

interface IError {
  message: string;
}
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  isOpen: boolean;
  userInfo: any;
  apiErrors: IError[];
  close: () => void;
  setAddAddressErrors: (errors: IError[]) => void;
  addAddressAction: (
    addressInfo: { address: IAddress },
    closeFn: () => void
  ) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddNewAddressesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  errorElementRef: any = React.createRef();
  addAddressRef: any = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {};

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log("res", response);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { apiErrors: prevApiErrors } = prevProps;
    const { apiErrors } = this.props;

    const hasErrors = apiErrors.length > prevApiErrors.length;

    if (hasErrors && this.errorElementRef.current) {
      this.errorElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  async addAddress(values: IAddress): Promise<boolean> {
    const {
      first_name,
      last_name,
      company_name,
      address,
      address2,
      city,
      state,
      country,
      postal_code,
      phone_number
    } = values

    const httpBody = {
      address: {
        first_name,
        last_name,
        company_name,
        address,
        address2,
        city,
        state,
        country,
        postal_code,
        phone_number: `+1${phone_number}`,
        address_type: "home"
      }
    };

    this.props.addAddressAction(httpBody, this.props.close);
    return true;
  }

  handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    const { apiErrors, setAddAddressErrors } = this.props;
    handleChange(e);
    if (apiErrors.length > 0) {
      setAddAddressErrors([]);
    }
  };

  handleFormSubmit = () => {
    if (this.addAddressRef.current) {
      this.addAddressRef.current.handleSubmit();
    }
  };

  handlePhoneNumberChange = (e: any, handleChange: any) => {
    const { value } = e.target;
    const { apiErrors, setAddAddressErrors } = this.props;

    if (value.length < 11 && !isNaN(value)) {
      handleChange(e);
    }
    if (Array.isArray(apiErrors) && apiErrors.length > 0) {
      setAddAddressErrors([]);
    }
  };

  handlePostalCodeChange = (e: any, handleChange: any) => {
    const { value } = e.target;
    const { apiErrors, setAddAddressErrors } = this.props;

    if (value.length < 6 && !isNaN(value)) {
      handleChange(e);
    }
    if (apiErrors.length > 0) {
      setAddAddressErrors([]);
    }
  };
  // Customizable Area End
}
