import React, { useState, useEffect } from "react";
// custom components
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import "../../web/src/assets/css/content/select.scss";
import { withRouter } from "react-router-dom";

const styles = makeStyles({
  dropdownStyle: {
    width: "200px",
    height: "200px",
    overflowY: "scroll",
    backgroundColor: "red",
    "&::-webkit-scrollbar": {
      width: "5px",
      borderRadius: "50px",
    },
    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
      borderRadius: "50px",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb ": {
      background: "#4aa258",
      borderRadius: "50px",
    },
    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },

    "& .MuiMenuItem-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      // maxWidth: "200px"
      width: "100%",
      display: "block",
    },
  },
});

const CustomSelectField = (props: any) => {
  const { label, selectData, headerSelect, settingDropDown } = props;
  const [dataDisplay, setDataDisplay] = useState([]);
  const [dataValue, setDataValue] = useState("");
  useEffect(() => {
    setDataDisplay(selectData);
    selectData && setDataValue(selectData[0].attributes.product_id);
  }, [selectData]);



  const classes = styles();
  return (
    <div className="customSelect">
      <FormControl>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={dataValue && dataValue}
          className="menu-customize"
          onChange={(e: any) => {
            const selectedItem =
              dataDisplay &&
              dataDisplay.filter(
                (item: any) => item.attributes.id == e.target.value
              )[0];

            //@ts-ignore
            setDataValue(
              //@ts-ignore
              selectedItem.attributes.id
            );
            settingDropDown(
              //@ts-ignore
              selectedItem.attributes.product_id,
              //@ts-ignore
              selectedItem.attributes.product_type.split("::")[1]
            );
          }}
          MenuProps={{
            classes: { paper: headerSelect && classes.dropdownStyle },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {selectData &&
            selectData.map((item: any, index: any) => {
              return (
                <MenuItem value={item.attributes.id} key={index}>
                  {item.attributes.product_type.split("::")[1]}
                  {item.attributes.product_id}
                  {item.attributes.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
  // }
};
export default withRouter(CustomSelectField);
