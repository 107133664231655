// Customizable Area Start
import React from "react";
// common css
import "../../../web/src/assets/css/style.scss";
// custom components
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// components
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";

import SearchIcon from "@material-ui/icons/Search";

//components
import AdminLearningCourseListController, {
  Props,
} from "./AdminLearningCourseListController.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";
import Loader from "../../../components/src/Loader.web";

interface EnhancedTableProps {
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
            data-testid="allcheckBox"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={34}
            />
            <span>Course NAME</span>
          </div>
        </TableCell>
        <TableCell align="center">COURSE CATEGORY</TableCell>
        <TableCell align="center">Status</TableCell>
        <TableCell align="center">#id</TableCell>
        <TableCell align="center">Price</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

class AdminLearningCourseList extends AdminLearningCourseListController {
  constructor(props: Props) {
    super(props);
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected =
        this.state.productList && this.state.productList.map((n: any) => n.id);
      this.setState({ selected: newSelected });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleChangeTablePage = (value: number) => {
    this.setState({ currentPage: value });
    this.getCourseList(
      this.state.sortValue,
      this.state.categorySelected,
      this.state.search,
      this.state.prodPerPage,
      value
    );
  };

  handleIncreasePageView = () => {
    if (this.state.prodPerPage < 25) {
      this.setState({
        prodPerPage: this.state.prodPerPage + 5,
        currentPage: 1,
      });
      this.getCourseList(
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage + 5,
        1
      );
    }
  };

  handleDecreasePageView = () => {
    if (this.state.prodPerPage > 5) {
      this.setState({
        prodPerPage: this.state.prodPerPage - 5,
        currentPage: 1,
      });
      this.getCourseList(
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage - 5,
        1
      );
    }
  };

  render() {
    const indexOfLastList = this.state.currentPage * this.state.prodPerPage;
    const indexOfFirstList = indexOfLastList - this.state.prodPerPage;
    const cureentTableList = this.state.productList
      ? this.state.productList
      : "";
    const roundPages =
      cureentTableList &&
      Math.floor(this.state.total_count / this.state.prodPerPage);
    const allSorts = ["default", "high to low", "low to high", "latest"];
    let totalListCount;
    if (this.state.currentPage > roundPages) {
      totalListCount = this.state.total_count;
    } else {
      totalListCount = indexOfLastList;
    }
    const { classes } = this.props;
    const menu = ["edit"];
    const courseStatus = ["active", "deactivate", "draft"];
    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown">
          <Loader loading={this.state.loading} />

          {/* filter section */}
          <div className="headerListContainer ">
            <div className="prodListHeader">
              <div className="blocks">
                <h4 data-test-id="courseList">Course List</h4>
                <div className="block1">
                  <div className="customSelect farmOrdersDropDown dishCategorySelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="menu-customize"
                        data-testid="categorySelected"
                        value={this.state.categorySelected}
                        onChange={(e: any) => {
                          this.setState({
                            categorySelected: e.target.value,
                          });
                          this.getCourseList(
                            this.state.sortValue,
                            e.target.value,
                            this.state.search,
                            this.state.prodPerPage,
                            1
                          );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown,
                          },
                        }}
                      >
                        <MenuItem value="all">
                          <span>All Courses</span>
                          <span className="checkmark">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {this.state.categories &&
                          (this.state.categories.length > 0 &&
                            this.state.categories.map(
                              (item: any, index: any) => {
                                return (
                                  <MenuItem value={item.id} key={item.id}>
                                    <span>{item.name}</span>
                                    <span className="checkmark">
                                      <div className="checkmark_circle" />
                                      <div className="checkmark_stem" />
                                      <div className="checkmark_kick" />
                                    </span>
                                  </MenuItem>
                                );
                              }
                            ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="customSelect dishSortSelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="sorted"
                        className="menu-customize"
                        value={this.state.sortValue}
                        onChange={(e) => {
                          this.setState({
                            sortValue: e.target.value,
                          });
                          this.getCourseList(
                            e.target.value,
                            this.state.categorySelected,
                            this.state.search,
                            this.state.prodPerPage,
                            1
                          );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper:
                              classes.sortDropDown +
                              " " +
                              classes.commonSelectui,
                          },
                        }}
                        autoWidth={true}
                      >
                        {allSorts.map((sort: any, index: any) => {
                          return (
                            <MenuItem value={sort} key={sort}>
                              {sort}
                              <span className="checkmark">
                                <div className="checkmark_circle" />
                                <div className="checkmark_stem" />
                                <div className="checkmark_kick" />
                              </span>{" "}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                  <div
                    className="addButton"
                    data-testid="createBtn"
                    onClick={() => {
                      let pathName = window.location.pathname.split("/")[4];
                      //@ts-ignore
                      this.props.history.push(
                        `/admin/learning/add-course?mode=create&tab=0&contributer_id=${pathName}`
                      );
                    }}
                  >
                    <CustomButton label="Create Course" />
                  </div>
                  {/* <CustomButton variant="contained" className="btn btn-primary">Contained</Button> */}
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <div className="customTextfield">
                  <TextField
                    id="input-with-icon-textfield"                   
                    value={this.state.search}
                    data-testid="searchBox"
                    onChange={(e: any) => {
                      this.setState({
                        search: e.target.value,
                      });
                      this.SearchDebounceUpdate(
                        this.state.sortValue,
                        this.state.categorySelected,
                        e.target.value,
                        this.state.prodPerPage,
                        1
                      );
                    }}
                    variant="standard"
                    placeholder="Search by Name, type, Id, etc... "
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className="searchIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect categoryDropDown dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label" style={{"color":`${this.state.selected.length > 1 ? "black":"#ccc"}`}}>
                        Actions
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="bulkActions"
                        className="menu-customize"
                        value={this.state.bulkAction}
                        disabled={this.state.selected.length < 2}
                        onChange={(e: any) => {
                          this.bulkActions(
                            e.target.value,
                            this.state.selected
                          );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.categoryDropDown,
                          },
                        }}
                      >
                        {courseStatus.map((status: string) => {
                          return (
                            <MenuItem value={status} key={status}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block ">
            <TableContainer
              component={Paper}
              className="adminLearningCourseTable"
            >
              <Table aria-label="simple table">
                <EnhancedTableHead
                   data-testid="tableHead"
                  numSelected={this.state.selected.length}
                  onSelectAllClick={this.handleSelectAllClick}
                  rowCount={cureentTableList && cureentTableList.length}
                />
                <TableBody>
                  {cureentTableList && cureentTableList.length > 0 ? (
                    cureentTableList.map((item: any) => {
                      const isItemSelected = this.isSelected(item.id);
                      const x = item.attributes;
                      return (
                        <TableRow key={item.id}>
                          <TableCell
                            component={"th" as any}
                            scope="row"
                            align="center"
                          >
                            <div
                              className="productCell"
                              data-testid="handleClick"
                              onClick={(event) =>
                                this.handleClick(event, item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={x.banner_image.url}
                                className="prodImage"
                                alt="banner"
                                style={{"marginLeft":"20px"}}
                              />
                              <h5 className="learningCourseTitle">{item.attributes.title}</h5>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {x.category_name && x.category_name[0]}
                          </TableCell>
                          <TableCell align="center">
                            {/* itemStatus */}
                            <div
                              className={`customSelect itemStatus ${x.status ==
                                "draft" && "warning-variant"} ${x.status ==
                                "deactivate" && "danger-variant"}`}
                            >
                              {/* class for red: danger-variant warning-variant*/}
                              <FormControl>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  id="demo-controlled-open-select"
                                  data-testid="selectStatus"
                                  value={x.status}
                                  onChange={(e: any) => {
                                    this.bulkActions(e.target.value, [
                                      item.id,
                                    ]);
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    classes: {
                                      paper: classes.itemStatus,

                                      // class for red: ${classes.dangerVariant}
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  // IconComponent={() => <ExpandMoreIcon />}
                                >
                                  {courseStatus.map(
                                    (status: string) => {
                                      return (
                                        <MenuItem
                                          value={status}
                                          key={status}
                                          style={{
                                            textTransform: "none",
                                          }}
                                          className={` dish_list_status_menus ${this.getMenuStyleCss(status)}`}
                                        >
                                          {status}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            {item.id}
                          </TableCell>

                          <TableCell align="center" className="paymentCell">
                            ${x.price}
                          </TableCell>
                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                                item={item}
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0" }}
                      >
                        <CustomNoRecordsFound
                          routeUrl={`/admin/learning/add-course?mode=create&tab=0&contributer_id=${
                            window.location.pathname.split("/")[4]
                          }`}
                          title="Course"
                          disable={false}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {cureentTableList.length > 0 && (
              <div className="paginationRow restOrderPagination">
                <div>
                  {indexOfFirstList + 1}- {totalListCount} of{" "}
                  {this.state.total_count} items
                </div>
                <CustomPagination
                 data-testid="customPagination"
                  count={this.state.total_pages}
                  page={this.state.currentPage}
                  pagePerView={this.state.prodPerPage}
                  handlePageChange={this.handleChangeTablePage}
                  shape="rounded"
                  siblingCount={1}
                  increasePagePerView={this.handleIncreasePageView}
                  decreasePagePerView={this.handleDecreasePageView}
                  boundaryCount={1}
                />
              </div>
            )}
          </div>

          {/* remove modal */}
          <div>
            <Dialog
              data-testid="dialogId"
              open={this.state.openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete the course ?
              </DialogTitle>
              <DialogActions>
                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    onClick={() => this.handleDialog()}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                    color="secondary"
                    label="Yes"
                    onClick={() => {
                      this.handleDialog();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </>
    );
  }
}
export {AdminLearningCourseList}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningCourseList)
);
// Customizable Area End
