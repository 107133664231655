// Customizable Area Start
import React from "react"
import Popover from "@material-ui/core/Popover"
import { Box, Typography } from "@material-ui/core"
import HelpIcon from "@material-ui/icons/HelpOutlineRounded"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    message: {
      fontSize: '0.8rem',
      whiteSpace: 'pre-line',
      textAlign: 'center'
    }
  })
)

interface ICustomHelpPopoverProps {
  helpMessage: string
  iconColor?: string
  iconSize?: "small" | "medium" | "large" | "inherit" | "default"
}

const CustomHelpPopover = ({
  helpMessage,
  iconColor = "#000000",
  iconSize = "small",
}: ICustomHelpPopoverProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpIcon fontSize={iconSize} style={{ color: iconColor }} />
      </Box>
      <Popover
        id="mouse-over-popover"
        transitionDuration={400}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.message}>{helpMessage}</Typography>
      </Popover>
    </div>
  )
}

export default CustomHelpPopover
// Customizable Area End
