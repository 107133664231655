import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../../../components/src/utility/app-routing";
import { isTokenExpired } from "../../../../../components/src/utility/helper";
export const configJSON = require("../../config");
// Customizable Area End

export interface Props extends RouteComponentProps {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  history: any
  match: any
  amountInfo: any
  paymentMethod: string
  orderAddress: number | string
  orderAddressType: string
  getCartItems: () => void
  setCheckoutOrder: (order: any) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkoutLoader: boolean
  currentStep: number
  loginDialog: boolean
  selectedCard: string
  checkoutOrder: any
  stepLoader: boolean
  isPaymentDoneDialog: boolean
  transactionId: string[]
  type: string
  enteredPoint: string
  isShippingLoading: boolean
  isLoyaltyPoint: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CheckoutOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notDeliverableOrderTypes: string[] = ["restaurant", "course"]
  orderSummaryApiCallId: string = ""
  cardPaymentApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      checkoutLoader: false,
      currentStep: 0,
      loginDialog: false,
      selectedCard: "",
      checkoutOrder: null,
      stepLoader: false,
      isPaymentDoneDialog: false,
      transactionId: [],
      type: "",
      enteredPoint: "",
      isLoyaltyPoint: false,
      isShippingLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.setState({ checkoutLoader: true })
    const { state }: any = this.props.location
    const { orderIds } = state || { orderIds: [] }
    this.setState({ type: state.orderType })

    if (orderIds?.length > 0) {
      const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
      if (!isLoggedIn) this.setState({ loginDialog: true, checkoutLoader: false })
      else {
        await this.getOrderSummary(orderIds)
        this.setState({ currentStep: 1, checkoutLoader: true })
      }
    } else {
      this.props.history.push(AppRoutings.ShoppingCart)
    }
  }

  // Customizable Area Start
  handlePointValueChange = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/
    if (!regex.test(value)) {
      return
    }
    this.setState({
      enteredPoint: value
    })
  }

  setShippingLoading = (isLoading: boolean) => {
    this.setState({ isShippingLoading: isLoading })
  }

  toggleLoyaltyPointApply = () => {
    this.setState((prevState: S) => ({
      isLoyaltyPoint: !prevState.isLoyaltyPoint,
      enteredPoint: ""
    }))
  }

  handleCardSelectChange = (cardId: string) => this.setState({ selectedCard: cardId })

  openLoginDialog = () => this.setState({ loginDialog: true })

  closeLoginDialog = () => this.setState({ loginDialog: false })

  openPaymentDoneDialog = () => this.setState({ isPaymentDoneDialog: true })

  closePaymentDoneDialog = () => this.setState({ isPaymentDoneDialog: false })

  onPaymentDoneAccept = () => {
    const { history, getCartItems } = this.props
    getCartItems()
    this.closePaymentDoneDialog()
    if (this.state.type === 'course') history.push(AppRoutings.Learning)
    else history.push(AppRoutings.Home)
  }

  handleLoginComplete = () => {
    this.setState({ currentStep: 1, loginDialog: false })
  }

  handlePayment = () => {
    const { paymentMethod } = this.props
    const { isLoyaltyPoint, enteredPoint, selectedCard } = this.state

    if (isLoyaltyPoint && !enteredPoint) {
      toast.error("Please enter a valid number of points")
      return
    }

    if (paymentMethod === "credit_or_debit_card") {
      if (selectedCard) this.handleCardPaymentApi()
      else toast.error("Please add/select a card")
    }
  }

  handleStepChange = (newStep: number) => {
    const isValidStep =
      newStep > 0 && newStep < this.state.currentStep && newStep < 4
    if (isValidStep) this.setState({ currentStep: newStep })
  }

  handleNextChange = () => {
    const { orderAddressType, orderAddress } = this.props
    const isNotDeliverableOrder = this.notDeliverableOrderTypes.includes(this.state.type)

    if (isNotDeliverableOrder) {
      switch (this.state.currentStep) {
        case 1:
          this.setState({ currentStep: 2 })
          break;
        case 2:
          this.handlePayment()
          break;
        default:
          break;
      }
    }
    else {
      switch (this.state.currentStep) {
        case 1:
          if (orderAddressType && orderAddress?.toString()) this.setState({ currentStep: 2 })
          else toast.warning("Please choose a Pick up/Delivery address")
          break
        case 2:
          this.setState({ currentStep: 3 })
          break
        case 3:
          this.handlePayment()
          break
        default:
          break
      }
    }
  }

  getOrderSummary = async (orderIds: any) => {
    this.setState({ checkoutLoader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.orderSummaryApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.orderSummaryApiEndPoint}?order_ids=${orderIds}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCardPaymentApi = async () => {
    this.setState({ stepLoader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const { selectedCard, enteredPoint, isLoyaltyPoint } = this.state
    const { location, amountInfo } = this.props
    const { orderIds, cartIds }: any = location.state
    const { total } = amountInfo

    const httpBody = {
      card_id: selectedCard,
      order_ids: orderIds,
      total,
      cart_item_ids: cartIds,
      apply_rewards: isLoyaltyPoint,
      points: Number(enteredPoint) || 0
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cardPaymentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardPaymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.orderSummaryApiCallId) {
      if ("type" in responseJson) {
        this.setState({ type: responseJson.type })
        if (responseJson.type === 'course') {
          this.props.setCheckoutOrder(responseJson)
          this.setState({ checkoutLoader: false, currentStep: 1 })
        }

        this.props.setCheckoutOrder(responseJson)
        this.setState({ checkoutLoader: false })
      } else {
        toast.error(responseJson.message)
      }
    }

    if (apiRequestCallId === this.cardPaymentApiCallId) {
      if ("message" in responseJson) {
        if (responseJson.message === "Payment Successful") {
          const transactionIds = responseJson.payment.map(
            (paymentInfo: { id: string }) => paymentInfo.id
          )
          this.setState({
            stepLoader: false,
            isPaymentDoneDialog: true,
            transactionId: transactionIds,
          })
        } else {
          toast.error(responseJson.message)
          this.setState({
            stepLoader: false,
          })
        }
      }
    }
  }

  handleErrorApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    const { history } = this.props
    if (await isTokenExpired(responseJson)) return

    if (apiRequestCallId === this.orderSummaryApiCallId) {
      toast.error("Something went wrong")
      history.push(AppRoutings.ShoppingCart)
    }

    if (apiRequestCallId === this.cardPaymentApiCallId) {
      responseJson?.errors?.forEach((err: any) => toast.error(err?.detail ?? err?.message ?? err))
      history.push(AppRoutings.ShoppingCart)
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

}
