//@ts-ignore
//@ts-nocheck

import * as React from 'react';
import webEducationCourseDetailsController, {
  Props,
} from "./webEducationCourseDetailsController.web";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import EducationSidebar from "./EducationSidebar.web";
import { withRouter } from "react-router-dom";
// css
import '../../../web/src/assets/css/style.scss'
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import "../assets/css/accordion.scss";
import AddIcon from "@material-ui/icons/Add";



import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  CardActions,
  Paper,
  Drawer
} from "@material-ui/core";
//images
import {
  TimerIcon,
  TopicsIcon,
  EnrollIcon,
  TrainerImg,
  ServiceImg,
  BulletIcon,
  ProfileImg1
} from "./assets";
//components
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCourseDetailTab from "../../../components/src/CustomCourseDetailTab.web";
import CustomAccordion from "../../../components/src/CustomCourseAccordian.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import { Pagination } from "@mui/material";
import CommentWeb from "../../info-page/src/farmForum/comment.web";
import ReplyWeb from "../../info-page/src/farmForum/reply.web";
import TextField from "@mui/material/TextField";
import { Divider } from '@mui/material';

class LandingPage6 extends webEducationCourseDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      <>
        {/* <div className="Header"> */}
        {/* <WebHeader />
        <WebRoutingTab /> */}
        <WebHeader />
        <Grid Container style={{ display: 'flex' }}>
          <Grid xs={2}>
            <Paper>
              msmm
            </Paper>
          </Grid>


          <Grid item xs={6}>
            <Grid Container>
              <Grid xs={12} >

                <Box sx={{ m: '2rem 6rem' }}   >

                  <Typography variant='h4'>
                    <b> Create Courses</b>
                  </Typography>


                </Box>
                <Box  >
                  <Divider />
                  <Box sx={{ mt: '2rem', display: 'flex', gap: '1rem' }}>
                    <Typography variant='h5'>
                      Course details
                    </Typography>
                    <Typography variant='h5'>
                      Course content
                    </Typography>
                  </Box>
                  <Divider />
                </Box>

              </Grid>
            </Grid>


            <Grid container>
              <Grid xs={12} style={{ marginTop: '2rem' }}>

                <h5 className='h10'>Course Title</h5>
                <TextField
                  placeholder="write your view here"
                  style={{ height: "10vh", border: "0.5px  solid silver" }}
                />

              </Grid>
              <Grid xs={12} style={{ marginTop: '2rem' }}>

                <h5 className='h10'>Educator Name</h5>
                <TextField
                  placeholder="write educator name"
                  style={{ height: "10vh", border: "0.5px  solid silver" }}
                />

              </Grid>
              <Grid xs={12} style={{ marginTop: '2rem' }}>

                <h5 className='h10'>Description</h5>
                <TextField
                  placeholder="write educator name"
                  style={{ height: "40vh", border: "0.5px  solid silver" }}
                />

              </Grid>
            </Grid>

            <Grid Container>
              <Grid xs={12}>
                <Box>
                  <h4 style={{ fontWeight: 'bolder', margin: '1rem auto', color: 'green' }}>
                    Banner image</h4>
                  <TextField
                    placeholder=""
                    style={{ height: "20vh", width: "25rem", border: "0.5px  solid silver" }}
                  />
                </Box>
                <Box>
                  <Typography variant='h6'>
                    Required size 380px*260px
                  </Typography>
                </Box>
                <Box sx={{ m: '2rem auto', color: 'green' }}>
                  <Typography variant='h5'>
                    Pricing<span> <Divider /></span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

          </Grid>




          <Grid xs={4}></Grid>

        </Grid>

      </>
    );
  }
}
//@ts-ignore
export default withRouter(LandingPage6);


