// Customizable Area Start
import React from "react";
// common css
import "../../../web/src/assets/css/style.scss";

import "../assets/css/adminFarmlist.scss";
// custom components
import CustomButton from "../../../components/src/CustomButton.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";

import {
  InputAdornment,
  TextField
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// components
import Loader from "../../../components/src/Loader.web";
//images

// icons
import SearchIcon from "@material-ui/icons/Search";
import AdminFarmDetailsController, {
  configJSON,
  Props,
} from "./AdminFarmDetailsController.web";

export class FarmLisitng extends AdminFarmDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const menu = configJSON.listingMenu;
    return (
      <>
        <Loader loading={this.state.loading} />
        <div className="tableContainer ">
          <div className="headerListContainer">
            <div className="prodListHeader">
              <div className="blocks">
                <h4 data-test-id="farmList">{configJSON.farmListText}</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="addButton">
                    <CustomButton
                      label={configJSON.newFarmLabel}
                      onClick={
                        this.handleCreateFarm
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield" style={{ width: "100%" }}>
                <div className="customTextfield">
                  <TextField
                    id="input-with-icon-textfield"
                    value={this.state.search}
                    onChange={
                      this.handleSearchFarm
                    }
                    variant="standard"
                    placeholder={configJSON.restOrderPlaceholderText}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className="searchIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="farmListsContainer">
            {this.state.farmsList && this.state.farmsList.length > 0 ? (
              this.state.farmsList.map((farm: any, index: any) => {
                return (
                  <div className="farmContainer" key={farm.id}>
                    <h4 data-test-id="farmName">{farm.attributes.name}</h4>
                    <div className="option-dropDown">
                      <BasicMenu
                        item={farm}
                        menu={menu}
                        handleMenuClick={this.handleMenuClick}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <CustomNoRecordsFound
                routeUrl={configJSON.createFarmRouteURL}
                title={configJSON.farmTitle}
                search={this.state.search}
              />
            )}
          </div>
        </div>
        {/* </main> */}
        {/* farm market detail wrapper */}
      </>
    );
  }
}

//@ts-ignore
export default withRouter(FarmLisitng);
// Customizable Area End
