// Customizable Area Start
import React from "react";
import {
  Box,
} from "@material-ui/core";
import "../assets/css/landingpageguest.scss";
import LandingPageGuestController, {
  Props,
  ICarousel} from "./LandingPageGuestController.web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import {
  updateActiveTab,
  setWLLPData
} from "../assets";
import Carousel from "react-material-ui-carousel";
import WLLPBannerBox from "./WLLPComponents/WLLPBannerBox.web";
import WLLPWhatIsWTT from "./WLLPComponents/WLLPWhatIsWTT.web";
import WLLPAboutUs from "./WLLPComponents/WLLPAboutUs.web";
import WLLPFarming from "./WLLPComponents/WLLPFarming.web";
import WLLPOurServices from "./WLLPComponents/WLLPOurServices.web";
import WLLPTestimonials from "./WLLPComponents/WLLPTestimonials.web";
import WLLPSkeletonLoader from "./WLLPComponents/WLLPSkeletonLoader.web"
// Customizable Area End

class LandingPageGuest extends LandingPageGuestController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { wllpCarousels } = this.props
    const { loader } = this.state

    return (
      <>
        {loader ?
          <WLLPSkeletonLoader />
          :
          <>
            <Box className="guest_landing_banner">
              <Carousel
                autoPlay={true}
                indicators={true}
                animation="slide"
                fullHeightHover={true}
                cycleNavigation={true}
                stopAutoPlayOnHover={true}
                navButtonsAlwaysInvisible
                timeout={{
                  enter: 1000,
                  exit: 1000,
                  appear: 3000
                }}
                indicatorIconButtonProps={{
                  className: "guest_landing_slider_indicator",
                }}
                activeIndicatorIconButtonProps={{
                  className: "guest_landing_active_indicator",
                }}
              >
                {wllpCarousels?.map((list: ICarousel) => {
                  const { id, attributes: { heading, learn_more } } = list

                  return (
                    <WLLPBannerBox
                      key={id}
                      heading={heading}
                      buttonText="LEARN MORE"
                      handleClick={() => this.buttonRedirect(learn_more)}
                    />
                  )
                })}
              </Carousel>
            </Box>
            <Box className="center_box">
              <Box>

                <WLLPWhatIsWTT />

                <WLLPAboutUs />

                <WLLPFarming />

                <WLLPOurServices />

                <WLLPTestimonials />
              </Box>
            </Box>
        </>
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    wllpCarousels: state.cms.WLLPCarousels
  }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(connect(
  mapStateToProps,
  { updateActiveTab, setWLLPData }
)(LandingPageGuest))
// Customizable Area End