//Customizable Area Start
export enum ActiveTabEnum {
  NonNav = "",
  Home = "Home",
  Market = "Farmer's market",
  Restaurant = "Restaturant",
  Learning = "Learning",
  Community = "Community",
  AboutUs = "About Us",
}
//Customizable Area End
