// Customizable Area Start
import * as React from "react";
import LandingPage3Controller, {
  configJSON,
  Props,
} from "./LandingPage3Controller.web";
import WebFooter from "../../../components/src/WebFooter.web";
import { withRouter } from "react-router-dom";
// css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import "../assets/css/accordion.scss";
import "../../info-page/src/assets/css/reply.scss"
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "../../info-page/src/assets/css/comment.scss";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Button,
  TextareaAutosize,
  Menu,
  MenuItem,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
//images
import {
  LeftIcon,
  Repeat,
  filledHeart,
  heart
} from "./assets";
//components
import CustomButton from "../../../components/src/CustomButton.web";
import { Rating, AvatarGroup, TextField, Modal } from "@mui/material";
import BusinessTeachBanner from "./BusinessTeachBanner.web";
import { ScrollView } from "react-native";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import WebHeader from "../../../components/src/WebHeader.web";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import LoginSignUpDialogWeb from "../../info-page/src/langinpageLoggedIn/LoginSignUpDialog.web";
import { getCartItems } from "../../info-page/src/assets";
import { connect } from "react-redux";
import CourseEnrolledModal from "./CourseEnrolledModal.web";
import Image from "material-ui-image";
import Loader from "../../../components/src/Loader.web";
import LazyLoad from "react-lazy-load";
import { withStyles } from "@material-ui/core/styles";
import CourseComment from "./CourseComment.web";

const styles = {
  reviewBtn : {
    backgroundColor: '#20a258 !important',
    borderRadius: '30px !important',
    padding: '6px 50px !important',
    color: '$WHITE',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: '$MONTSERRAT',
    fontWeight: 500,

    "& .MuiButton-label" : {
      backgroundColor: '#20a258 !important',
      color: '#fff !important',
    },

    "@media screen and (max-width: 560px)" : {
      padding: '6px 16px !important',
      fontSize: '14px',
      borderRadius : '5px !important'
    },
  },
  unenrolledHeader : {
    display:'flex', 
    justifyContent: "space-between"
  },
  leftHeader : {
    display:"flex",
    gap: "1rem",
    alignItems: "center",
    padding: "1rem",
    justifyContent: "center"
  },
  center : {
    display:"flex",
    alignItems: "center"
  },
  accordionMargin : {
    marginTop: '2.5rem' 
  },
  goToCourseLabel : {
    textTransform : 'none'
  },
  favIcon : {
    marginRight: '30px',
    cursor:'pointer'
  }
}

// Customizable Area End
export class LandingPage3 extends LandingPage3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const {classes} = this.props;
    const detail = this.state.courseDetail
    const subSection = this.state.courseDetail?.attributes?.course_sub_sections?.data
    
    const name = this.state.firstName + " " + this.state.lastName
    const isBioLength = detail?.attributes?.biography?.length <= 500
    
    
    
    return (
        <ScrollView>
        <WebHeader />
          <WebNewRoutingTabs />
          <Grid
            container
            className="courseHeaderBox"
          >
            <Grid item xs={12} className={classes.unenrolledHeader}>
              <div
                className={classes.leftHeader}
              >
                <img
                  className="backIcon"
                  style={{cursor :'pointer' }}
                  src={LeftIcon}
                  alt=""
                  onClick={this.handleBackIcon}
                />
                <h5 className="courseHeader">{detail?.attributes?.title}</h5>
                </div>
                <div className={classes.center}>
                <Box ml={1} className={classes.favIcon}
                  data-test-id="favouriteIcon"
                 onClick={this.handleFavourite}>
                <img src={this.state.isFavourite ? filledHeart : heart} className="heartIcon"  />
                </Box>
                <Box>
                <MoreHorizRoundedIcon className="threeDots" 
                data-test-id="shareIcon"
                onClick={this.handleOptions} />
                <Menu
                id="basic-menu"
                style={{position:'absolute', top:'11%', padding:'20px'}}
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseOptions}
          className="menu-customize"
        >
          <MenuItem style={{backgroundColor:'#fff'}} onClick={this.handleShare}>{configJSON.shareText}</MenuItem>
        </Menu>
        <Modal data-test-id="shareModalBox" disableEnforceFocus open={this.state.modalOpen} onClose={this.handleModalClose} >
          <Box className="shareModal"> 
            <Typography className="shareCourseText">{configJSON.shareCourseText}</Typography>
            <Grid container className="shareContainer">
            <div 
            style={{display:'flex', width:'310px'}}
            >
                <TextField 
                className="sharelink"
                disabled value={window.location.href}/>
            </div>
            <div style={{display:'flex'}}><button className="copyBtn"
            onClick={this.handleCopy}>{configJSON.copyText}</button></div>
            
            </Grid> 
            <div className="copiedTxt">{this.copyText(this.state.copy)}</div>
          </Box>
        </Modal>
        </Box>
        </div>
              
            </Grid>

          </Grid>
          {  this.state.loading ?
          <Loader loading={this.state.loading} /> 
          :
          <div className="padding2">
            <Grid
              container
              className="courseDetail"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} >
                <div className="container-img-2">
                  <LazyLoad offset={100}>
                  <Image 
                  style={{paddingTop:'0'}}
                  src={detail?.attributes?.banner_image?.url}
                  animationDuration={1000} 
                  alt="Course Banner" className="image" 
                  loading={<CircularProgress className="image_loader" size={30}/>}
                  />
                  </LazyLoad>
                  <div
                    className="one"
                  >
                    <AvatarGroup >
                      <div className="avatarImgWrapper">
                      <LazyLoad offset={100}> 
                      <Image
                        imageStyle={{borderRadius:'50%'}}
                        alt="Remy Sharp"
                        animationDuration={1000}
                        loading={<CircularProgress className="image_loader" size={20}/>}
                        src={this.state.educatorImage}
                      />
                      </LazyLoad> 
                      </div>
                    </AvatarGroup>
                    <h6>{detail?.attributes?.first_name} {detail?.attributes?.last_name}</h6>
                    <p className="educatorHeadline">
                      {detail?.attributes?.headline}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="course-overview">
                  <h3 className="h3">{detail?.attributes?.title}</h3>
                  <h5>By {detail?.attributes?.first_name} {detail?.attributes?.last_name}</h5>
                  <p className="para">
                    {detail?.attributes?.description}
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container className="margin1">
              <Grid item xs={12}>
                <Paper variant="outlined" className="courseInfoContainer">
                  <div className="flex-1">
                    <Grid item xs={6} sm={6} md={6} lg={6} className="courseFlexContainer" >
                    <div className="timeWrapper">
                      <img src={Repeat} className="clockImg" />
                      <div className="timeCal">
                        <h3>
                          {this.state.calHours} 
                          {this.state.calSeconds}
                        </h3>
                        <h6>{configJSON.trainingText}</h6>
                      </div>
                    </div>
                    <div 
                      className="lessonLength">
                      <h5> {this.state.lessonLength} </h5>
                    </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className="courseFlexContainer">
                    <div className="enrollStud">
                      <h5>{this.state.enrolledStudents} </h5>
                    </div>
                    <div className="priceContainer">
                    {this.state.courseDetail?.attributes?.price > 0 ?
                      <h6
                        className="coursePrice">
                        ${this.state.courseDetail?.attributes?.price}
                        <span>/m</span>
                      </h6>
                      :
                      <h6 className="coursePrice">
                        {configJSON.freeText}
                      </h6>
                    }
                    </div>
                    <div className="enrollBtnWrapper">
                      <div className="circleBtn flex">
                       {detail?.attributes?.is_enrolled ?
                        <CustomButton 
                        data-test-id="goToCourseBtn"
                        className={classes.goToCourseLabel}
                        onClick={() => this.handleGoToCourse(detail?.id)}
                        label={configJSON.goToCourseLabel}/>
                        : 
                        <CustomButton
                        data-test-id="enrollNowBtn"
                          onClick={() => this.handleEnrollBtn(detail?.attributes?.price,detail)
                          }
                          label={configJSON.enrollNowLabel} />
                        } 
                      </div>
                    </div>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>

            <Grid container className="enrollBtnContainer">
                      <Grid item xs={12} className="circleBtn flex">
                       {detail?.attributes?.is_enrolled ?
                        <CustomButton className={classes.goToCourseLabel}
                        onClick={() => this.handleGoToCourse(detail?.id)}
                        label={configJSON.goToCourseLabel}/>
                        : 
                        <CustomButton
                          onClick={() => this.handleEnrollBtn(detail?.attributes?.price,detail)
                          }
                          label={configJSON.enrollNowLabel} />
                        } 
                      </Grid>
            </Grid>

            <Grid container className={classes.accordionMargin}>
              <Grid item xs={12} className="accordionContainer">
                <CustomAccordion2 CourseContent={this.state.courseData} SubSection={subSection} />
              </Grid>
            </Grid>
            <Grid container className="margin4">
              <Grid item xs={4} sm={4} md={4} lg={4} >
                <div className="container-educator-img">
                <LazyLoad offset={100}>
                  <Image
                    src={this.state.educatorImage}
                    alt="Educator Image"
                    animationDuration={1000}
                    className="image"
                  />
                </LazyLoad>   
                </div>
              </Grid>

              <Grid item xs={8} sm={8} md={8} lg={8} className="margin3" style={{marginBottom: this.state.hideBiography ? '0' : '50px' }}>

                  <Box className="rightEducatorContainer" >
                    <h3 className="h3">
                      {configJSON.aboutText} {detail?.attributes?.first_name} {detail?.attributes?.last_name}
                    </h3>
                    <div className="educatorDescription"
                    dangerouslySetInnerHTML={{ __html: this.biography(this.state.hideBiography) }}
                      />
                    {!isBioLength &&
                    <div 
                      style={{ 
                        paddingTop: '20px', marginBottom:'50px'
                      }}>
                      <h3 className="knowlink"  
                      onClick={() => this.handleKnowMore()} >
                        {this.state.hideBiography ? configJSON.knowMoreText : configJSON.knowLessText}
                      </h3>
                    </div>
                    }
                  </Box>

              </Grid>
              </Grid>
              <Grid container className="reviewWrapper">
              <Grid item
                xs={12}
                className="flex reviewGrid"
              >
                <div className="reviewText">{configJSON.reviewsText}</div>
                <div className="knowlink" 
                  style={{
                    cursor : 'default'
                  }}>
                    <u>{configJSON.writeCommentText} </u></div>
              </Grid>

            {this.state.reviewsList?.map((item: any) => (
                  <CourseComment
                key={item.id}
                data={item}
                onSave={this.handleSaveReply}
                name={name}
                onDel={this.handleDelReply}
                onReply={this.handleReplyBtn}
                open={this.state.isOpen}
                reviewId={item.id}
                repliesList={item.attributes.replies?.data}
                commentId={this.state.commentId}
                isEditSave={this.state.isEditSave}
                onSaveEditReply={this.handleSaveEditReply} navigation={undefined} id={""} />
                )
              )
            }

            <Grid item xs={12} style={{marginTop:'30px'}}>
              <p className="review_label" style={{marginLeft:'0'}}>{configJSON.writeReviewText}</p>
            </Grid>

            <Grid item xs={12} style={{marginBottom:'20px'}}>
              <Rating
                className="StyledRating"
                classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                name="customized-empty"
                defaultValue={0}
                value={this.state.rating}
                precision={0.5}
                size="large"
                onChange={(e: any, value: any) => this.setState({ rating: value })}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Box>
                <TextareaAutosize
                  value={this.state.commentText}
                  onChange={(e: any) => this.setState({ commentText: e.target.value })}
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Write comment here..."
                  className="write_review_text_area"
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{marginBottom:'20px'}}>
              <Button
                className={classes.reviewBtn}
                onClick={() => this.handleCreateReviews(this.state.commentText, this.state.rating, detail.id)}
              >
                Submit
              </Button>
            </Grid>
            </Grid>
          </div>
          }
          
          <LoginSignUpDialogWeb
          isOpen={this.state.isLoginDialog}
          onClose={this.closeLoginDialog}
          onLoginSuccess={this.handleLoginSuccess}
        />
        <Dialog
            open={this.state.openDialog}
            maxWidth="xs"
            classes={{
              paper: "checkout_order_dialog_paper"
            }}>
            <CourseEnrolledModal handleModalBtn={() => this.handleGoToCourse(detail?.id)}/>
      </Dialog>
        
          <BusinessTeachBanner />
          <WebFooter />
        </ScrollView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
//@ts-ignore
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    cartId: state.user.cartId
  }
}

const router:any = connect(
  mapStateToProps, {
    getCartItems
  }
)(LandingPage3);

const UnEnrolledPage:any = withRouter(router)
//@ts-ignore
export default withStyles(styles)(UnEnrolledPage)
// Customizable Area End

// Customizable Area Start
function CustomAccordion2({ CourseContent }:any) {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : "");
  };


  return (
    <>

      {CourseContent?.map((i: any, index: any) => {
        return (
          <div className="customAccordian" key={i.id} style={{ borderTop: `${expanded === index ? 0 : '2px solid #ebf0ff'}`, borderBottom: `${expanded === index ? 0 : '2px solid #ebf0ff'}`, paddingTop: '0px', alignItems: 'center', marginBottom: '30px',borderRadius: `${expanded === index ? '5px' : 0}` }}>
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              style={{ backgroundColor: expanded === index ? 'rgba(20, 118, 32, 0.08)' : '#fff' }}
            >
              <AccordionSummary
                expandIcon={<AddCircleOutlineOutlinedIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
              >

                <Typography variant="h5" className="topTitle">
                  Part <span>0{index + 1}</span>
                </Typography>

                {expanded === index ? '' : <Typography variant="h5" className="subTitle" >
                  {i?.attributes?.name}
                </Typography>
                }

              </AccordionSummary>
              <AccordionDetails className="AccordionDetails" style={{ marginTop: '-94px' }}>
                <>
                  {i?.attributes?.course_sub_sections?.data?.map((e: any, index: any) => {
                    return (
                      <>
                        <Typography key={e.id} variant="h5" className="subTitle" >{e?.attributes?.heading}</Typography>
                        <p className="para">
                          {e?.attributes?.description}
                        </p>
                      </>
                    )
                  })}
                </>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      })}
    </>
  );
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start

// Customizable Area End
