// Customizable Area Start
import React, { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { withRouter, RouteComponentProps } from "react-router-dom"
import queryString from "query-string"
const configJSON = require("../../src/config.js")

interface ISearchResultPaginationProps extends RouteComponentProps {
  totalPages: number
}

const SearchResultPagination = ({
  history,
  location,
  totalPages,
}: ISearchResultPaginationProps) => {
  const { pathname, search } = location
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const params = new URLSearchParams(search)
    const page = params.get(configJSON.pageText)
    const updatedPageNo = page ? Number(page) : 1
    setCurrentPage(updatedPageNo)
  }, [search])

  const updateQueryStringAndPush = (key: string, value: number) => {
    const parsedQueryString = queryString.parse(search)

    const newQueryString = queryString.stringify({
      ...parsedQueryString,
      [key]: value,
    })
    history.push(`${pathname}?${newQueryString}`)
  }

  const handlePaginationChange = (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page)
    updateQueryStringAndPush(configJSON.pageText, page)
  }

  return totalPages > 1 ? (
    <Box className="web_pagination_container">
      <Pagination
        shape="rounded"
        variant="outlined"
        page={currentPage}
        count={totalPages}
        onChange={handlePaginationChange}
        classes={{ root: "farm_pagination" }}
      />
    </Box>
  ) : null
}

export default withRouter(SearchResultPagination)
// Customizable Area End
