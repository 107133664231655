// Customizable Area Start
import React from "react";
import Setting from "../../Settings/src/Setting.web";
import { Grid, Box, Button, Typography, IconButton, FilledInput, CircularProgress } from "@material-ui/core";
import "../assets/css/accountSetting.scss";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Formik } from "formik";
import SettingsController, { Props } from "./AccountSettingController.web";
import {
  updateAccountDetailsAction,
  DefaultProfileImg,
  handleProfileImage
} from "../src/assets";
import AccountSettingRoleSelection from "../../RolesPermissions/src/AccountSettingRoleSelection.web";
import { connect } from "react-redux";
import Image from 'material-ui-image'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ChangePasswordDialog from "./ChangePasswordDialog.web";
import AccountSettingLoginServices from "./AccountSettingLoginServices.web";
// Customizable Area End
class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    // Customizable Area Start
    const { userInfo } = this.props
    const {
      isLoading,
      accountSettingSchema,
      isImageLoading,
      changePassDialog,
      isFbConnected,
      isGoogleConnected,
      isInstagramConnected
    } = this.state;
    
    const accountInitialValues = {
      first_name: userInfo.userDetails.first_name || "",
      last_name: userInfo.userDetails.last_name || "",
      email: userInfo.userDetails.email || ""
    }

    return (
      <>
        <Formik
          initialValues={accountInitialValues}
          enableReinitialize
          validationSchema={accountSettingSchema}
          onSubmit={(values) => this.doUpdateAccount(values, accountInitialValues)}
        >
          {({ values, errors, handleChange, handleSubmit }) => {
            return (
              <form encType="multipart/form">
                <Setting>
                  <Box className="web_sidebar_link_view">
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Box className="web_sidebar_link_view_header">
                          <h5>My Account</h5>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <Box className="as_profile_pic_container">
                          <Typography className="as_profile_heading">
                            Profile Image
                          </Typography>
                          <Box className="as_profile_wrapper">
                            <Image
                              src={
                                userInfo.userDetails.profile_pic || DefaultProfileImg
                              }
                              alt="profile image"
                              style={{
                                paddingTop: "0px",
                                height: "120px",
                              }}
                              iconContainerStyle={{
                                boxShadow: "0 2px 12px 0 #b7bdc480",
                                borderRadius: "50%",
                              }}
                              loading={
                                <CircularProgress size={20} className="image_loader" />
                              }
                            />
                            <IconButton
                              className="as_profile_edit"
                              size="small"
                              disabled={isImageLoading}
                              onClick={this.editProfileClick}
                            >
                              {isImageLoading ? (
                                <CircularProgress size={15} />
                              ) : (
                                <EditOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                            <input
                              data-testid="profile-image-input"
                              type="file"
                              hidden
                              className="as_profile_input"
                              accept="image/png, image/jpeg"
                              ref={this.profilePicRef}
                              onChange={this.handleProfilePicChange}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Box className="account_details_heading_wrapper">
                          <h5 className="account_details_heading">Account Details</h5>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Box width="100%" className="web_input_field">
                          <FilledInput
                          data-test-id="firstName"
                            placeholder="First Name"
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                            value={values.first_name}
                            name="first_name"
                            onChange={handleChange}
                          />
                        </Box>
                        <span className="error">
                          {errors.first_name ? errors.first_name : null}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Box width="100%" className="web_input_field">
                          <FilledInput
                            placeholder="Last Name"
                            value={values.last_name}
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                            name="last_name"
                            onChange={handleChange}
                          />
                        </Box>
                        <span className="error">
                          {errors.last_name ? errors.last_name : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <Grid item xs={12} sm={6} lg={6}>
                        <Box width="100%" className="web_input_field">
                          <FilledInput
                            placeholder="Email"
                            startAdornment={
                              <InputAdornment position="end">
                                <DraftsOutlinedIcon className="login_input_icons" />
                              </InputAdornment>
                            }
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                            disabled
                            name="email"
                            value={values.email}
                          />
                        </Box>
                        <span className="error">
                          {errors.email ? errors.email : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={4} xl={3}>
                        <Box>
                          <Button
                          data-test-id="save"
                            variant="contained"
                            className="account_setting_save_btn"
                            classes={{
                              label: "normal_login_btn",
                            }}
                            onClick={() => handleSubmit()}
                          >
                            {isLoading ? (
                              <CircularProgress color={"inherit"} size={30} />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                    <AccountSettingRoleSelection />

                    <AccountSettingLoginServices
                      isFbConnected={isFbConnected}
                      isGoogleConnected={isGoogleConnected}
                      isInstagramConnected={isInstagramConnected}
                      setResponse={this.handleFacebookResponse}
                    />

                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Box className="account_details_heading_wrapper">
                          <h5 className="account_details_heading">Password</h5>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={5} lg={4} xl={3}>
                        <Box>
                          <Button
                          data-test-id="changePassword"
                            variant="contained"
                            className="account_setting_changePWD_btn"
                            classes={{
                              label: "btn_color_black",
                            }}
                            onClick={this.openPasswordDialog}
                          >
                            Change Password
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Setting>
              </form>
            )
          }}
        </Formik>

        <ChangePasswordDialog
          data-test-id="changePasswordDialog"
          isOpen={changePassDialog}
          onClose={this.closePasswordDialog}
        />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
};
// Customizable Area End
export {Settings}
export default connect(
  mapStateToProps,
  { updateAccountDetailsAction, handleProfileImage }
)(Settings)
// Customizable Area Start
