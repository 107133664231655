// Customizable Area Start
import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Modal, Paper, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
    play_button,
    docs,
    lock
  } from "./assets";

export class ResourcesTab extends React.Component<any,any>{
    videoRef: any;
    constructor(props:any){
        super(props);
        this.state={
          expanded : ''
        }
        this.videoRef = React.createRef();
    }

    handleLoadedMetadata = (event:any) => {
      if (this.videoRef.current !== null) {
      this.setState({ duration : this.videoRef.current.duration});
      }
    }
  
    handleTimeUpdate = (event:any) => {
      if (this.videoRef.current !== null) {
      this.setState({currentTime : this.videoRef.current.currentTime});
      }
      if (event && (event.target.currentTime === event.target.duration)) {
        this.props.handleVideoStatus(this.state.id,'complete')
      }
    }
  
    handleChange = (panel: any) => (event: any, isExpanded: any) => {
      this.setState({expanded : isExpanded ? panel : ""});
    };

    handleClose = () => {
        this.setState({open:false})
    }

    handlePdfClick = (url:any) => {
      window.open( `${url}#toolbar=0` , "_blank")
    }

    render(){
    const CourseSection = this.props.section
  
    return (
      <>
      {CourseSection && CourseSection.map((i: any, index: any) => {
        return(
        <div key={i} className="customAccordian6">
          <Paper variant="outlined" className="paper" style={{ border: 'none' }}>
            <Accordion
              data-test-id="lessonChange"
              expanded={this.state.expanded === index}
              onChange={this.handleChange(index)}
  
            >
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="AccordionSummary"
                style={{ backgroundColor:'rgba(20, 118, 32, 0.08)' }}
              >
                <Typography className="resource-title" style={{ fontSize: '20px', fontWeight: 500, color: '#000', padding: '12px' }}>
                  Part 0{index+1} [{i.attributes && i.attributes.name}]
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="AccordionSection" style={{width:'100%', backgroundColor: '#d8d8d8' ,display:'block'}}>
              {i.attributes.course_sub_sections && i.attributes.course_sub_sections.data.map((e: any, index: any) => {
                      const {attachment} = e.attributes
                      return (
                <Accordion key={e.id}
                  style={{ width: '100%', backgroundColor: '#d8d8d8', alignItems: 'center' }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowForwardIosIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="AccordionSummary"
                    style={{ padding: '10px' }}
                  >
                    <div style={{ display: "flex", marginLeft: "16px", alignItems: 'center' }}>
                      <h6 className="lesson">Lesson {index+1} - {e.attributes && e.attributes.heading}</h6>
                    </div>
                  </AccordionSummary>
                  <div style={{ width:'100%',borderBottom: '1px solid #ccc' }}></div>
                  <AccordionDetails>
                  
                  
                    <Grid container style={{ width:'100%',alignItems: 'center',display:'flex', justifyContent:'space-between' }}>
                    {attachment &&
                    <>
                      <Grid item xs={12} sm={10} 
                       style={{display:'flex',alignItems: 'center'}}
                      >
                      <img src={docs} className="pdfIcon"/>
                      <h6 className="document" onClick={() => this.handlePdfClick(attachment.url)}>{attachment.filename}</h6>
                      </Grid>
                       
                    </>
                    }
                      
                      <Grid item xs={12} sm={10} style={{display:'flex',alignItems: 'center'}}>
                      <img src={play_button} className="playIcon"/>
                      <h6 onClick={() =>{
                        this.setState({id :e.id})
                        this.setState({open :true})}} 
                        className="videoName">{e.attributes.video.filename}</h6>
                      </Grid>
                      {e.id == this.state.id && 
                      <Modal
                      open = {this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                       
                        <Box style={{position: "absolute" as "absolute",top: "50%",left: "50%",transform: "translate(-50%, -50%)",
                                    maxWidth: '800', maxHeight:'400px'}}>
                          <video className="modalVideo" ref={this.videoRef} controls src={e.attributes && e.attributes.video.url}
                          onLoadedMetadata={this.handleLoadedMetadata}
                          onTimeUpdate={this.handleTimeUpdate} controlsList="nodownload"/>
                        </Box>
                      </Modal>
              }
                    </Grid>
                      
                  </AccordionDetails>
                  <div style={{ borderBottom: '1px solid #ccc'}}></div>
                </Accordion>
                )})}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </div>
      )})}
      </>
    )
    }
  }
// Customizable Area End  