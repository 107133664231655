// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./OrderCancelRequestDialog.web"

export interface Props extends WithStyles<typeof styles> {
  isOpen: boolean
  titleList: string[]
  buttonTitle: string
  isButtonLoading: boolean
  handleClose: () => void
  handleButtonClick: () => void
}

interface S {}

interface SS {
  id: any
}

export default class OrderCancelRequestDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {}
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  handleDialogClose = () => {
    const { isButtonLoading, handleClose } = this.props
    if(!isButtonLoading) {
      handleClose()
    }
  }
  // Customizable Area End
}
