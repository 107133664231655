Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST"
exports.apiMethodTypePut = "PUT"
exports.apiMethodTypePatch = "PATCH"
exports.apiMethodTypeDelete = "DELETE"
exports.productAPiEndPoint = "catalogue/catalogues";
exports.addToCartApiEndPoint= "bx_block_order_management/cart_items/add_to_cart"
exports.getAddressesApiEndPoint = "address/addresses"
exports.RESTAURANT_PAGE_MAX_VIEW = 3;
exports.GOOGLE_MAP_API_KEY = "AIzaSyAl6Aa52ZsQL2SAUECMYGKWbv3Pq0C1iF4";
exports.SQUARE_SANDBOX_API_KEY = "sandbox-sq0idb-rlhO-N5VIC-bH9tXx5hvmA"
exports.SQUARE_LOCATION_ID = "LSCN8G1NN07YQ"
exports.GOOGLE_MAP_REDIRECTION_API = "https://www.google.com/maps/search/?api=1&query="
exports.ORDERS_STORAGE_KEY = "orders"
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::FarmCatalogue"
exports.CARTABLE_TYPE_MENU = "BxBlockCatalogue::Menu"
exports.CARTABLE_TYPE_RESTAURANT = "BxBlockCatalogue::Restaurant"
exports.FORUM_REVIEW_TYPE = "BxBlockDonationpayments::Forum"
exports.AUTH_TOKEN = "authToken"
exports.ORDERS_STORAGE_KEY = "orders";
exports.FIRST_NAME_KEY = "firstname";
exports.LAST_NAME_KEY = "lastname";
exports.PROFILE_KEY = "profile";
exports.CART_ID_KEY = "cartId";
exports.CART_BADGE_KEY = "cartBadge";
exports.ADMIN_AUTH = "AdminAuth";
exports.HASH_TAG_REGEX = /#[\w\d]+/g
exports.getCardsApiEndPoint = "bx_block_payments/payments/get_cards"
exports.addCardApiEndPoint = "bx_block_payments/payments/create_card?source_id="
exports.removeCardApiEndPoint = "bx_block_payments/payments/remove_card?card_id="
exports.orderSummaryApiEndPoint = "bx_block_payments/payments/order_summary"
exports.pickUpAddressesApiEndPoint="bx_block_address/addresses/pickup_addresses?farm_id="
exports.addDeliveryPickUpAddApiEndPoint="bx_block_order_management/cart_items/add_delivery_or_pickup_address"
exports.changePaymentTypeApiEndPoint="bx_block_payments/payments/update_payment_method"
exports.cardPaymentApiEndPoint="bx_block_payments/payments/payment_for_order"
exports.verifyCVVApiEndPoint="bx_block_payments/payments/verify_cvc"
exports.addReviewApiEndPoint="catalogue/reviews"
exports.reviewApiEndPoint="bx_block_catalogue/reviews"
exports.replyReviewApiEndPoint="bx_block_catalogue/reply_reviews"
exports.communityReplyReviewApiEndPoint="bx_block_catalogue/comment_replies"
exports.getForumDetailsApiEndPoint="bx_block_donationpayments/forums/"
exports.likeApiEndPoint="like/likes"
exports.toggleFavouriteApiEndPoint= "bx_block_favourites/favourites/favourites"
exports.rlpApiEndPoint = "bx_block_settings/settings/restaurant_details"
exports.communityForumCmsApiEndPoint = "bx_block_settings/settings/community_details"
exports.communityTopicsApiEndPoint = "bx_block_catalogue/topics"
exports.expertsApiEndPoint = "bx_block_catalogue/educational_contributors/our_selected_experts"
exports.getExpertDetailsApiEndPoint = "bx_block_catalogue/educational_contributors/expert_details?educational_contributor_id="
exports.addDiscussionCommentApiEndPoint = "bx_block_catalogue/forum_comments"
exports.communityDiscussionsApiEndPoint= "bx_block_catalogue/community_discussions"
exports.discussionListingFilterApiEndPoint = "bx_block_catalogue/community_discussions/discussion_listing"
exports.searchDiscussionsApiEndPoint = "/bx_block_catalogue/community_discussions/search?query="
exports.toggleFollowDiscussionApiEndPoint = "bx_block_followers/follows"
exports.getDonationDetailsApiEndPoint = "bx_block_donationpayments/donations/campaign_details?id="
exports.donationLeaveReplyApiEndPoint = "bx_block_donationpayments/donations/campaign_leave_reply"
exports.getRewardPointsApiEndPoint = "bx_block_coupons/coupons/get_user_rewards"
exports.getApplicablePointsApiEndPoint = "bx_block_payments/payments/check_reward_points"
exports.getTransactionHistoryApiEndPoint = "bx_block_coupons/coupons/points_transaction_history"
exports.transferPointsApiEndPoint = "bx_block_coupons/coupons/transfer_points"
exports.reportSpamApiEndPoint = "bx_block_catalogue/report_spams"
exports.donationDataText = "BxBlockDonationpayments::Forum";
exports.errorText = "Something went wrong";

exports.restaurantText = "Restaurant";
exports.courseText = "Course";
exports.totalText = "Total:";
exports.farmText = "Farm";
exports.likeText = "Like";
exports.likesText = "Likes";
exports.commentText = "Comment";
exports.commentsText = "Comments";
exports.perItemText = "per_item";
exports.unitText = "Unit";
exports.addToCartText = "Add To Cart";
exports.outOfStockText = "Out Of Stock";
exports.reviewsText = "Reviews";
exports.writeReviewText = "Write review";
exports.customerReviewsText = "customers review";
exports.skuText = "SKU :";
exports.productCategoryText = "product category :";
exports.tagsText = "tags :";
exports.submitText = "Submit";
exports.leafBannerHeading = " Recently added Products";
exports.leafBannerText = " Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quisquam quam reiciendis laborum non molestiae corporis voluptate, ab nesciunt. Accusantium?";
exports.blockText = "block";
exports.noneText = "none";
exports.errorText = "error"
exports.somethingWentWrongText = "Something went wrong";
exports.amountText = "Amount"
exports.transferText = "Transfer"
exports.cancelText = "Cancel"
exports.EnterRecipientEmailText = "Enter recipient's email"
exports.transferPointsMainHeading = "Please indicate the amount to be transferred"
exports.enterRecipientEmailHeading = "What is the recipient’s email associated with WTT account?"
exports.fetchBalanceErrorMessage = "Unable to fetch the loyalty point balance"

exports.SORT_MENUS = [{
  label: "Default",
  value: "default"
},
{
  label: "Low to High",
  value: "low_to_high"
},
{
  label: "Popular",
  value: "popular"
},
{
  label: "High to Low",
  value: "high_to_low"
}
]
// Customizable Area End