// Customizable Area Start
import { IBlock } from "./../../framework/src/IBlock"
import { Message } from "./../../framework/src/Message"
import { BlockComponent } from "./../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "./../../framework/src/Messages/MessageEnum"
import { runEngine } from "./../../framework/src/RunEngine"
import StorageProvider from "./../../framework/src/StorageProvider.web"
import { styles } from "./TagsSuggestion.web"
import { isTokenExpired } from "./utility/helper"
import React from "react"
import { WithStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
const configJSON = require("./config.js")

const JOIN_CONSTANT = ", "

export interface ITag {
  id: string
  type: string
  attributes: {
    id: number
    name: string
  }
}

export interface Props extends WithStyles<typeof styles> {
  value: string
  name: string
  disabled?: boolean
  dataTestId?: string
  placeholder: string
  handleValueChange: (value: string) => void
}

interface S {
  isOpen: boolean
  tags: ITag[]
}

interface SS {
  id: any
}

export default class WebRoutingTabsController extends BlockComponent<
  Props,
  S,
  SS
> {
  fieldRef: React.RefObject<HTMLInputElement> = React.createRef()
  getTagsSuggestionApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      isOpen: false,
      tags: [],
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  splitValue = (value: string) => {
    return value.split(/,\s*|\s*,/)
  }

  handleCloseSuggestion = () => {
    this.setState({ isOpen: false })
  }

  handleOpenSuggestion = () => {
    this.setState({ isOpen: true })
  }

  handleEmptyTags = () => {
    this.setState({ isOpen: false, tags: [] })
  }

  handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.props.handleValueChange(value)
    this.SearchDebounceUpdate()
  }

  handleBlur = () => {
    const arrayValue = this.splitValue(this.props.value)
    const newValue = arrayValue.filter(value => !!value.trim()).join(', ')
    this.props.handleValueChange(newValue)
  }

  handleTagSelection = (name: string) => {
    const { value, handleValueChange } = this.props
    const valuesArray = this.splitValue(value).slice(0, -1)
    const withoutLastElementValue = valuesArray
      .filter((v) => !!v)
      .join(JOIN_CONSTANT)

    const newValue = [withoutLastElementValue, name]
      .filter((val) => !!val)
      .join(JOIN_CONSTANT)
      .trim()

    handleValueChange(`${newValue}, `)
    this.setState({ tags: [] })
    if (this.fieldRef.current) {
      this.fieldRef.current.focus()
    }
  }

  searchDebounce = (call: any, delay: number) => {
    let timer: any

    return function (...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  SearchDebounceUpdate = this.searchDebounce(() => {
    const { value } = this.props
    const valuesArray = this.splitValue(value)

    if (valuesArray.length > 0) {
      const lastElement = valuesArray[valuesArray.length - 1]

      return lastElement.length > 2
        ? this.getTagsSuggestion(`?query=${lastElement}`)
        : this.handleEmptyTags()
    }
  }, 700)

  getTagsSuggestion = async (query: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getTagsSuggestionApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTagsSuggestionApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getTagsSuggestionApiCallId) {
          this.setState({ tags: responseJson.data, isOpen: true })
        }
      } else {
        if (await isTokenExpired(responseJson)) {
          return
        }
        toast.error("Something went wrong")
      }
    }
  }
}
// Customizable Area End
