import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
import React from "react";
import { veggies } from "./assets";
import "../assets/css/orderHistory.scss";
import "../../../web/src/assets/css/style.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OrderHistorySubCardController, {
  Props
} from "./OrderHistorySubCardController.web";

export class OrderHistorySubCard extends OrderHistorySubCardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { reOrderLoader, reOrderId } = this.state;
    const { orderList, orderType, showOrderDetails } = this.props;
    const actionBtnText = orderType === "current" ? "Track Order" : "Reorder";

    return orderList.length > 0 ? (
      orderList.map((order: any) => {
        const {
          ordered_from,
          order_date,
          order_number,
          order_details
        } = order.attributes;

        return (
          <Grid
            item
            xs={12}
            container
            className="wishlist_card_wrapper"
            spacing={3}
            key={order.id}
          >
            <Grid item xs={12} className="track_order_details_link">
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <h3 className="store_name">Ordered From: {ordered_from}</h3>
                  <Box display="flex" flexWrap="wrap" gridGap="10px">
                  <h5 className="order_number">
                    Ordered Number: {order_number}
                  </h5>
                  <h5 className="order_number">{order_date}</h5>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} className="order_link_wrapper">
                  <button
                    className="order_link"
                    onClick={() => showOrderDetails(order.id)}
                  >
                    Order Details
                  </button>
                  {orderType === "previous" && <Button
                    classes={{
                      root: "track_order_btn",
                      label: "btn-label",
                      disabled: "od_action_btn"
                    }}
                    onClick={() => this.handleTrackOrder(order.id)}
                  >
                    Track order
                  </Button>}
                </Grid>
              </Grid>
            </Grid>

            {order_details.data.map((orderDetail: any) => {
              const {
                product_name,
                product_image,
                product_id,
                product_type,
                price,
                quantity,
                addons,
                addon_ids,
                special_cooking_instruction,
                selected_variant_details,
                variant_description
              } = orderDetail.attributes;
              const isVariantPurchased = variant_description && selected_variant_details

              return (
                <Grid container item xs={12} key={product_id}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    lg={2}
                    className="product_image_center"
                  >
                    <Box className="product_image_wrapper">
                      <img
                        src={product_image || veggies}
                        width="100%"
                        alt="wishlist-product"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={5} lg={7}>
                    <h5 className="product_name">{product_name}</h5>
                    {addons && (
                      <Typography className="order_history_product_desc">
                        Extra: {addons}
                      </Typography>
                    )}
                    {special_cooking_instruction && (
                      <Typography className="order_history_product_desc">
                        Cooking Instruction: {special_cooking_instruction}
                      </Typography>
                    )}
                    <Typography className="order_history_product_desc">
                      <span>Quantity :</span> {quantity}
                    </Typography>
                    {
                      isVariantPurchased && (
                        <Typography className="order_history_product_desc">
                          <span>{variant_description} :</span>{" "}
                          {selected_variant_details.attributes.option_name}
                        </Typography>
                      )
                    }
                    <br />
                    <span className="order_history_product_price">
                      ${this.getPrice(price, selected_variant_details)}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        classes={{
                          root: "track_order_btn",
                          label: "btn-label",
                          disabled: "od_action_btn"
                        }}
                        onClick={() =>
                          this.handleOrderAction(
                            order.id,
                            product_id,
                            quantity,
                            product_type,
                            addon_ids,
                            special_cooking_instruction,
                            orderDetail.id
                          )
                        }
                        disabled={reOrderLoader}
                      >
                        {reOrderLoader && reOrderId === orderDetail.id ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          actionBtnText
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        );
      })
    ) : (
      <Grid item xs={12} className="order_history_blank_container">
        <Typography>Looks like you don't have {orderType} orders.</Typography>
      </Grid>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    cartId: state.user.cartId
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(OrderHistorySubCard)
) as any;
