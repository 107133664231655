// Customizable Area Start
import React from "react";
import { deleteIcon } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";

import "../assets/css/adminFarmlist.scss";
// custom components
import { commonCmptStyles } from "./dropDownCss";
import CustomSelectField from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";

import { withRouter } from "react-router-dom";

// iconsF
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Box,
  withStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomTab from "../../../components/src/CustomTab.web";

import AdminLearningDiscussionController, {
  Props,
} from "./AdminLearningDiscussionController.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import CustomBorderLinearProgressbar from "../../../components/src/CustomBorderLinearProgressbar.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";

export class AdminLearningDiscussion extends AdminLearningDiscussionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {/* main card container */}
        <div className="discussionTableContainer tableContainer">
          <Loader loading={this.state.loading} />
          <Box className="discussionTableHeader commonDropdown">
            <h2 className="discussionHeading">Discussion</h2>
            <div className="outlineSelect">
              <div className="customSelect itemStatus">
                <FormControl>
                  <Select
                    data-test-id="selectCourse"
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={this.state.course_id}
                    disabled={!this.state.courseList}
                    onChange={this.selectOnChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      classes: {
                        paper: classes.itemStatus,
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={0} style={{ opacity: "0.5" }}>
                      Select Course
                    </MenuItem>
                    {this.state.courseList.length > 0
                      ? this.state.courseList.map((course: any, index: any) => {
                          return (
                            <MenuItem value={course.id} key={course.id}>
                              {course.attributes.title}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
          {this.state.courseList ? (
            <Box className="discussionContentWrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="leftBox">
                  <Box className="discussionBody">
                    {this.state.courseContent &&
                      this.state.courseContent.attributes.course_sections.data.map(
                        (part: any, index: any) => {
                          return (
                            <Accordion
                            data-test-id="discAccordian"
                              className="discussionAccordian"
                              key={part.id}
                              expanded={this.state.expandedPartId == part.id}
                              onChange={() => {
                                this.setState({
                                  expandedPartId: part.id,
                                  edit_id: "",
                                });
                                this.settingAccordianopen();

                                let parts = this.state.courseContent.attributes
                                  .course_sections.data;
                                this.AccordianChange(part);
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  Part {index + 1}: [{" "}
                                  {`${part.attributes.name}`} ]
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <nav aria-label="secondary mailbox folders">
                                    <List>
                                      {part.attributes.course_sub_sections.data
                                        .length > 0
                                        ? part.attributes.course_sub_sections.data.map(
                                            (lesson: any, index: any) => {
                                              return (
                                                <ListItem
                                                  key={lesson.id}
                                                  onClick={() => {
                                                    this.setState({
                                                      discussionList:
                                                        lesson.attributes
                                                          .discussions.data,
                                                      lesson_id: lesson.id,
                                                      edit_id: "",
                                                    });
                                                  }}
                                                >
                                                  {/* add "active" class here to make it green  */}
                                                  <ListItemButton
                                                    className={`${lesson.id ==
                                                      this.state.lesson_id &&
                                                      "active"}`}
                                                    component="a"
                                                  >
                                                    <ListItemText
                                                      primary={`Lesson ${index +
                                                        1} -
                                      ${lesson.attributes.heading}`}
                                                    />
                                                    <span className="badge">
                                                      {this.displayDiscussionBadge(
                                                        lesson.attributes
                                                          .discussion_notification
                                                      )}
                                                    </span>
                                                  </ListItemButton>
                                                </ListItem>
                                              );
                                            }
                                          )
                                        : "no lesson found"}
                                    </List>
                                  </nav>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  {this.state.discussionList &&
                  this.state.discussionList.length > 0 ? (
                    <Box className="chatWrapper">
                      <Box className="chatBody">
                        {/* received textbox ui */}

                        {/* sent textbox ui */}
                        {this.state.discussionList.map(
                          (discussion: any, index: any) => {
                            let x = discussion.attributes;
                            return (
                              <>
                                <Box
                                  className={`${this.settingClass(x)} `}
                                  key={discussion.id}
                                >
                                  <Box
                                    className={`chatBox ${this.settingClass2(
                                      x
                                    )}`}
                                  >
                                    <Box className="chatRow">
                                      {this.settingReplyName(x)}

                                      <span>
                                        {moment(x.created_at).format("LT")}
                                      </span>
                                    </Box>
                                    <p>{x.comment}</p>
                                    <Formik
                                      innerRef={this.state.edit_ref}
                                      enableReinitialize
                                      initialValues={{
                                        reply: "",
                                      }}
                                      validationSchema={Yup.object().shape({
                                        reply: Yup.string().required(
                                          "This field is required."
                                        ),
                                      })}
                                      onSubmit={(
                                        values: any,
                                        { resetForm }: any
                                      ) => {
                                        this.updatediscussion(
                                          discussion.id,
                                          values
                                        );
                                        resetForm({
                                          values: "",
                                        });
                                      }}
                                    >
                                      {(formikProps) => {
                                        const {
                                          values,
                                          setFieldValue,
                                          handleChange,
                                          touched,
                                          errors,
                                        } = formikProps;
                                        return (
                                          <Form
                                            noValidate
                                            autoComplete="off"
                                          >
                                            {x.discussion_type ==
                                              "discussion_reply" &&
                                              this.state.edit_id ==
                                                discussion.id && (
                                                <Box className="chatEditForm">
                                                  <div className="outlineTextfield boxRadiusBorder">
                                                    <div className="customTextfield discussionComment">
                                                      <TextField
                                                        data-test-id="discussion"
                                                        id="reply"
                                                        variant="outlined"
                                                        value={values.reply}
                                                        placeholder="Write your comment here..."
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        //@ts-ignore
                                                        onChange={handleChange}
                                                        error={
                                                          touched.reply &&
                                                          Boolean(errors.reply)
                                                        }
                                                      />
                                                      <span className="errorMsg">
                                                        <ErrorMessage name="reply" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <span className="greenContained">
                                                    <CustomButton
                                                      data-test-id="saveBtn"
                                                      color="secondary"
                                                      label="Save"
                                                      type="submit"
                                                    />
                                                  </span>
                                                </Box>
                                              )}
                                            <Box className="chatBottomRow">
                                              <Button
                                                className="DelButton"
                                                onClick={() => {
                                                  this.setState({
                                                    openDialog: true,
                                                    deleteId: discussion.id,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={deleteIcon}
                                                  className="DelIcon"
                                                  alt="DelIcon"
                                                />
                                              </Button>
                                              {x.discussion_type ==
                                                "discussion_reply" && (
                                                <span className="greenOutlineButton">
                                                  <CustomButton
                                                  data-test-id="editDisc"
                                                    color="secondary"
                                                    label="Edit"
                                                    onClick={() => {
                                                      this.setState({
                                                        edit_id: discussion.id,
                                                      });
                                                      setFieldValue(
                                                        "reply",
                                                        x.comment
                                                      );
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </Box>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  </Box>
                                </Box>
                              </>
                            );
                          }
                        )}
                      </Box>

                      <Formik
                        enableReinitialize
                        initialValues={{
                          reply: "",
                        }}
                        validationSchema={Yup.object().shape({
                          reply: Yup.string().required(
                            "This field is required."
                          ),
                        })}
                        onSubmit={(values: any, { resetForm }: any) => {
                          this.creatediscussion(values);
                          resetForm({ values: "" });
                        }}
                      >
                        {(formikProps) => {
                          const {
                            values,
                            handleChange,
                            touched,
                            errors,
                          } = formikProps;
                          return (
                            <Form noValidate autoComplete="off">
                              <Box className="chatFooter">
                                <div className="outlineTextfield boxRadiusBorder">
                                  <div className="customTextfield discussionComment">
                                    <TextField
                                      data-test-id="comment"
                                      id="reply"
                                      variant="outlined"
                                      value={values.reply}
                                      placeholder="Write your comment here..."
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      //@ts-ignore
                                      onChange={handleChange}
                                      error={
                                        touched.reply && Boolean(errors.reply)
                                      }
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name="reply" />
                                    </span>
                                  </div>
                                </div>
                                <span className="greenContained">
                                  <CustomButton
                                    data-test-id="addComment"
                                    color="secondary"
                                    label="Reply"
                                    type="submit"
                                  />
                                </span>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Box>
                  ) : (
                    <p className="chatNoDiscussionData">No discussion found</p>
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            "no course for discussion found"
          )}
        </div>
        {/* remove modal */}
        <div>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete the discussion ?
            </DialogTitle>
            <DialogActions>
              <span className="redContained customCancelBtn">
                <CustomButton
                  color="secondary"
                  label="No"
                  onClick={this.handleDialog}
                />
              </span>
              <span className="greenContained">
                <CustomButton
                data-test-id="yesbtn"
                  color="secondary"
                  label="Yes"
                  onClick={() => {
                    this.handleDialog();
                    this.deleteDiscussion();
                  }}
                  autoFocus
                />
              </span>
            </DialogActions>
          </Dialog>
        </div>
        {/* ends: main card container */}
      </>
    );
  }
}

export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningDiscussion)
);
// Customizable Area End
