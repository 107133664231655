import * as React from "react";
import "../../web/src/assets/css/content/progressbar.scss";

const CustomBorderLinearProgressbar = (props: any) => {
  // let x = 3000
  return (
    <div className="customLinearProgressbar">
      {/* <label htmlFor="file">Downloading progress:</label> */}
      <progress id="file" value={props.value} max={props.max}/>
      {/* value= {x/props.required_donation}*100 */}
      {/* value = x/{props.required_donation} */}
      {/* value = value * 100 */}
    </div >
  );
}
export default CustomBorderLinearProgressbar
