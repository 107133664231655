// Customizable Area Start
import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, IconButton, Typography } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecordRounded';
import { withRouter, RouteComponentProps } from "react-router-dom"
import { AppRoutings } from '../../../../../components/src/utility/app-routing';
import StorageProvider from '../../../../../framework/src/StorageProvider';
const configJSON = require("../../config.js")

const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '25px 35px',
        borderRadius: '21px',
        backgroundColor: '#f7f7f7',
        "@media(max-width: 480px)": {
          padding: "20px 15px 20px 20px"
        },
    },
    contentWrapper: {
        overflow: 'hidden',
    },
    name: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '22px',
        fontWeight: 500,
        color: '#000',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        msTextOverflow: 'ellipsis',
        "@media(max-width: 350px)": {
            fontSize: '18px'
        },
    },
    otherDetails: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '4px',
        marginTop: '8px',
        "& p": {
            fontFamily: "'Poppins', sans-serif",
            fontSize: '16px',
            fontWeight: 500,
            color: '#000',
            opacity: 0.5,
            textTransform: "capitalize"
        },
        "& svg": {
            fontSize: '8px',
            color: '#000',
            opacity: 0.5
        },
        "@media(max-width: 350px)": {
            "& p": {
                fontSize: '12px',
            },
            "& svg": {
                fontSize: '6px',
            }
        },
    },
    navBtn: {
        "&:hover": {
            backgroundColor: '#20a258 !important',
            "& svg": {
                color: '#fff !important'
            }
        },
        "& svg": {
            color: "#000"
        }
    }
}))

interface ICommunityCardProps extends RouteComponentProps {
    id: string
    name: string
    authorName: string
    date: string
    openLoginDialog: () => void
}

const CommunityCard = ({ history, id, name, authorName, date, openLoginDialog}: ICommunityCardProps) => {
    const classes = useStyles()

    const handleDiscussionDetailsRedirect = async () => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
        if(!isLoggedIn) {
            openLoginDialog()
            return
        }
        history.push(`${AppRoutings.CommunityDiscussionDetails}/${id}`)
    }

    return (
        <Box className={classes.cardContainer}>
            <Box className={classes.contentWrapper}>
                <Typography className={classes.name}>
                    {name}
                </Typography>
                <Box className={classes.otherDetails}>
                    <Typography>{authorName}</Typography>
                    <FiberManualRecordIcon />
                    <Typography>Started {date}</Typography>
                </Box>
            </Box>
            <Box>
                <IconButton className={classes.navBtn} onClick={handleDiscussionDetailsRedirect}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default withRouter(CommunityCard)
// Customizable Area End
