import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  orderList: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderDetailsDialog: boolean;
  orderDetailsId: string;
  orderTrackingDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderHistoryCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      orderDetailsDialog: false,
      orderDetailsId: "",
      orderTrackingDialog: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(_: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    // Customizable Area End
  }

  // Customizable Area Start
  openOrderDetailsDialog = (id: string) => {
    this.setState({
      orderDetailsDialog: true,
      orderDetailsId: id
    });
  };

  closeOrderDetailsDialog = () => {
    this.setState({
      orderDetailsDialog: false,
      orderDetailsId: ""
    });
  };

  openOrderTrackingDialog = (id: string) => {
    this.setState({
      orderTrackingDialog: true,
      orderDetailsId: id
    });
  };

  closeOrderTrackingDialog = () => {
    this.setState({
      orderTrackingDialog: false,
      orderDetailsId: ""
    });
  };
  // Customizable Area End
}
