// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

export const configJSON = require("./config");
import StorageProvider from "framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  name: string;
  loading: boolean;
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  contactUsApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      name: "",
      loading: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if(apiRequestCallId!=null){
        this.successApiCall(responseJson, apiRequestCallId);
        }
      }
       if (responseJson?.errors) {
        toast.error(responseJson?.errors[0].message)
        this.setState({
          loading:false
        })
        if (responseJson?.errors[0] && ((responseJson?.errors[0].message == "Token has Expired") || (responseJson?.errors[0].message == "Invalid token"))) {
          await StorageProvider.remove("authToken")
          //@ts-ignore
          this.props.history.push(`/`)
        }
        this.setState({
          loading: false,

        });
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.contactUsApiCallId) {
      this.setState({
        loading: false,
      });
      toast.success("Contacted Successfully");
    }
  };

  // contact us
  postContactUs = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      token: token,
    };
    const formdata = new FormData();

    formdata.append("data[name]", values.name);
    formdata.append("data[email]", values.email);
    formdata.append("data[phone_number]", values.phone_number);
    formdata.append("data[subject]", values.subject);
    formdata.append("data[message]", values.message);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContactUsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
}
// Customizable Area End
