// Customizable Area Start
import React, { Component } from "react";
import WebFooter from "../../../components/src/WebFooter.web";
import SettingLayout from "../../../components/src/SettingLayout.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withRouter, RouteComponentProps } from "react-router-dom";
const configJSON = require("./config.js")

interface Props extends RouteComponentProps{
  history: any
}

class Settings extends Component<Props, any> {

  async componentDidMount(): Promise<void> {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(!isLoggedIn) {
      this.props.history.push("/")
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <SettingLayout>
          <div>{this.props.children}</div>
        </SettingLayout>
        <GreenBanner />
        <WebFooter />
      </div>
    );
  }
}

export default withRouter(Settings)
// Customizable Area End
