import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");
import { RouteComponentProps } from "react-router-dom";
import {
  isTokenExpired,
  scrollToTop
} from "../../../components/src/utility/helper";
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  id: string;
  activeTab: string;
  updateActiveTab: (tab: string) => void;
  setFPData: (descriptions: {
    favorite_farm_description: string;
    favorite_restaurant_description: string;
    favorite_course_description: string;
  }) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean;
  favSearch: string;
  lat: number | null;
  lng: number | null;
  currentTab: number;
  currentPage: number;
  totalPages: number;
  favouriteList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AllFavouritesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  favouriteListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];

    this.state = {
      loader: false,
      currentTab: 0,
      currentPage: 1,
      totalPages: 1,
      favSearch: "",
      lat: null,
      lng: null,
      favouriteList: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidUpdate(
    _: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    const { currentTab } = this.state;
    if (currentTab !== prevState.currentTab) {
      this.resetFilters();
      this.handleChangeFavouriteType(1, "", null, null);
    }
  }

  async componentDidMount(): Promise<void> {
    const { location, activeTab, updateActiveTab } = this.props;
    activeTab !== ActiveTabEnum.Home && updateActiveTab(ActiveTabEnum.Home);
    const { state }: any = location;
    const anotherTypes = ["restaurant", "course"];
    const isAnotherType = anotherTypes.includes(state);
    if (isAnotherType) {
      if (state === "restaurant") {
        this.setState({ currentTab: 1 });
      } else if (state === "course") {
        this.setState({ currentTab: 2 });
      }
      return;
    }
    this.handleChangeFavouriteType();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson);
      } else {
        await isTokenExpired(responseJson);

        if (apiRequestCallId === this.favouriteListApiCallId) {
          this.setState({
            loader: false,
            favouriteList: [],
            currentPage: 1,
            totalPages: 1
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.favouriteListApiCallId) {
      const {
        meta,
        favorite_farm_description,
        favorite_restaurant_description,
        favorite_course_description
      } = responseJson;
      const { current_page, total_pages } = meta.pagination;
      const { setFPData } = this.props;
      let response = "data" in responseJson ? responseJson.data : [];

      if ("fav_courses" in responseJson) {
        const { fav_courses, purchased_courses } = responseJson;
        response = { fav_courses, purchased_courses };
      }

      this.setState({
        loader: false,
        favouriteList: response,
        currentPage: current_page,
        totalPages: total_pages
      });
      setFPData({
        favorite_farm_description,
        favorite_restaurant_description,
        favorite_course_description
      });
    }
  };

  handlePageChange = (page: number) => {
    const { favSearch, lat, lng } = this.state;
    this.setState({
      currentPage: page
    });
    scrollToTop();
    this.handleChangeFavouriteType(page, favSearch, lat, lng);
  };

  resetFilters = () => {
    this.setState({
      favSearch: "",
      lat: null,
      lng: null,
      currentPage: 1
    });
  };

  handleChangeFavouriteType = (
    pageNo = this.state.currentPage,
    searchVal = this.state.favSearch,
    lat = this.state.lat,
    lng = this.state.lng
  ) => {
    let activeOrder = "";

    switch (this.state.currentTab) {
      case 1:
        activeOrder = "Restaurant";
        break;
      case 2:
        activeOrder = "Course";
        break;
      default:
        activeOrder = "Farm";
        break;
    }
    this.favouriteListApi(activeOrder, pageNo, searchVal, lat, lng);
  };

  handleTabChange = (tab: number) => {
    this.setState({
      currentTab: tab
    });
  };

  handleSearch = (search: string, lat: number | null, lng: number | null) => {
    this.setState({ favSearch: search, lat, lng, currentPage: 1 });
    this.handleChangeFavouriteType(1, search, lat, lng);
  };

  favouriteListApi = async (
    activeTab: string,
    pageNo: number,
    searchVal: string,
    lat: number | null,
    lng: number | null
  ) => {
    this.setState({ loader: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const latitude = lat || "";
    const longitude = lng || "";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.favouriteListApiEndPoint
      }?page=${pageNo}&per=16&type=${activeTab}&search=${searchVal}&latitude=${latitude}&longitude=${longitude}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
