import React, { useState } from "react";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import EduCoursePlaySidebar from "./EduCoursePlaySidebar.web";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import ReactPlayer from "react-player"
import Button from "../../../components/src/Button";


import { withRouter } from "react-router-dom";
//images
import webEducationCourseDetailsController, {
  Props,
} from "./webEducationCourseDetailsController.web";

// css
import "../assets/css/eduCoursePlay.scss"
import CustomButton from "../../../components/src/CustomButton.web";
import PlayerControls from "./EducationPlayerControls";
import AdminFarmHeaderWeb from "../../../components/src/AdminFarmHeader.web";


// import myVideo from "../assets/videos/video.mp4"

class coursePlay extends webEducationCourseDetailsController {
  // private playerRef: HTMLDivElement;
  constructor(props: Props) {
    super(props);

    // this.playerRef = React.createRef();

  }
  playerRef = React.createRef<any>();
  controlsRef = React.createRef<HTMLDivElement>();
  currentTime =
    this.playerRef && this.playerRef.current
      ? this.playerRef.current.getCurrentTime()
      : "00:00";

  duration =
    this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";
  // elapsedTime = this.format(this.currentTime);


  // totalDuration = this.format(this.duration);

  // handleMouseMove = () => {
  //   console.log("mousemove");
  //   this.controlsRef.current.style.visibility = "visible";
  //   // count = 0;
  // };

  // hanldeMouseLeave = () => {
  //   this.controlsRef.current.style.visibility = "hidden";
  //   // count = 0;
  // };

  handleSeekMouseUp = (e: any, newValue: any) => {
    console.log({ value: e.target });
    // this.setState({ ...this.state, seeking: false });
    // console.log(sliderRef.current.value)
    this.playerRef.current.seekTo(newValue / 100, "fraction");
  };
  render() {
    return (
      <>
        <AdminFarmHeaderWeb />

        {/* main wrapper */}
        <main className="eduCoursePlayWapper">
          <div className="subContentWrapper">
            <section className="choseusSection commonSection">
              <Grid container spacing={2} className="cardContainer">
                <Grid item xs={12} md={3}>
                  <EduCoursePlaySidebar handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen} />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={9}>
                  <div className="eduCourseContentWrapper">
                    <div className="greenOutlineGrayButton filterBtn" >
                      <CustomButton label="Course Content" onClick={() => {
                        this.handleDrawerToggle()
                      }} />
                    </div>
                  </div>
                </Grid> */}
                <Grid item xs={12} md={9}>
                  <div className="videoPlayerContainer"
                  // onMouseMove={handleMouseMove}
                  // onMouseLeave={hanldeMouseLeave}

                  >
                    <ReactPlayer
                      ref={this.playerRef}
                      width={"100%"}
                      height="100%"
                      url="../assets/videos/video.mp4"
                      // muted={this.state.mute}
                      // playing={this.state.playing}
                      // loop={this.state.loop}
                      // onProgress={this.handleProgress}
                      // volume={this.state.volume}
                    />

                    <PlayerControls
                      ref={this.controlsRef}
                      // onPlayPause={this.handlePlayPause}
                      // handleMute={this.handleMuteUnmute}
                      // played={this.state.played}
                      // onSeek={this.handleSeekChange}
                      // onSeekMouseDown={this.handleSeekMouseDown}
                      // onSeekMouseUp={this.handleSeekMouseUp}
                      // playing={this.state.playing}
                      // elapsedTime={this.elapsedTime}
                      // totalDuration={this.totalDuration}
                      // volume={this.state.volume}
                      // onVolumeChange={this.handleVolumeChange}
                      // onVolumeSeekDown={this.handleVolumeSeekDown}
                      // muted={this.state.mute}
                    />
                  </div>

                  <div className="overViewConatiner">

                    <h3>Overview</h3>

                    <div className="overViewDetails">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Veniam asperiores repellendus harum sapiente, suscipit, ipsam alias voluptates aut
                        fugit repellat soluta fuga libero aspernatur consectetur nihil voluptatibus, quod necessitatibus
                        quia! Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia quasi reprehenderit voluptas
                        repellat, aperiam suscipit quos nobis
                        facere id ab harum sit error deleniti dolores quae fugit dolorum. Sit, doloremque.
                      </p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Veniam asperiores repellendus harum sapiente, suscipit, ipsam alias voluptates aut
                        fugit repellat soluta fuga libero aspernatur consectetur nihil voluptatibus, quod necessitatibus
                        quia! Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia quasi reprehenderit voluptas
                        repellat, aperiam suscipit quos nobis
                        facere id ab harum sit error deleniti dolores quae fugit dolorum. Sit, doloremque.
                      </p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Veniam asperiores repellendus harum sapiente, suscipit, ipsam alias voluptates aut
                        fugit repellat soluta fuga libero aspernatur consectetur nihil voluptatibus, quod necessitatibus
                        quia! Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia quasi reprehenderit voluptas
                        repellat, aperiam suscipit quos nobis
                        facere id ab harum sit error deleniti dolores quae fugit dolorum. Sit, doloremque.
                      </p>
                    </div>

                  </div>
                </Grid>
              </Grid>
            </section>
          </div>


        </main>
        {/* ends: main wrapper */}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}
//@ts-ignore
export default withRouter(coursePlay);
