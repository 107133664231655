// Customizable Area Start
import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { createStyles, withStyles } from "@material-ui/core/styles";
import OrderCancelApproveDialogController, {
  Props,
  BUTTON_TYPES
} from "./OrderCancelApproveDialogController.web";
import CustomCancelFlowButton from "./CustomCancelFlowButton.web";
import Alert from "@material-ui/lab/Alert";
const configJSON = require("./config.js");

export class OrderCancelApproveDialog extends OrderCancelApproveDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, isOpen, cancellationByUserAdmin } = this.props;
    const { isLoading, step, refundMessage, apiResponseErrors } = this.state;

    const USER_ADMIN_STEPS_TITTLES = [
      [configJSON.cancelOrderMessage],
      [configJSON.orderCancelledMessage, configJSON.initiateRefundMessage],
      [configJSON.initiateRefundText]
    ];

    const USER_STEPS_TITTLES = [
      [configJSON.wantToAcceptCancellationMessage],
      [
        configJSON.cancellationAcceptedMessage,
        configJSON.initiateRefundMessage
      ],
      [configJSON.initiateRefundText]
    ];

    const STEP_BUTTONS = [
      [
        {
          type: "close",
          isLoading: false,
          title: configJSON.noText,
          onClick: this.handleDialogClose
        },
        {
          type: "error",
          isLoading: isLoading,
          title: configJSON.yesCancelText,
          onClick: this.handleCancelOrder
        }
      ],
      [
        {
          type: "success",
          isLoading: false,
          title: configJSON.confirmText,
          onClick: this.setThirdStep
        }
      ],
      [
        {
          type: "success",
          isLoading: isLoading,
          title: configJSON.initiateRefundText,
          onClick: this.handleInitiateRefund
        }
      ]
    ];

    const STEPS_TITLES = cancellationByUserAdmin
      ? USER_ADMIN_STEPS_TITTLES
      : USER_STEPS_TITTLES;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        onClose={this.handleDialogClose}
      >
        <IconButton
          size="small"
          className={classes.closeIcon}
          onClick={this.handleDialogClose}
        >
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.titlesWrapper}>
            {STEPS_TITLES[step].map((title: string) => (
              <Typography key={title} className={classes.title}>
                {title}
              </Typography>
            ))}
            {step === 2 && (
              <Box className={classes.refundMessageWrapper}>
                <Box>
                  <Typography className={classes.title}>
                    {configJSON.enterCommentMessage}
                  </Typography>
                  <Typography variant="caption">
                    ({configJSON.optionalText})
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  value={refundMessage}
                  className={classes.refundField}
                  placeholder={configJSON.enterYourCommentMessage}
                  onChange={this.handleFieldValueChange}
                />
              </Box>
            )}
          </Box>
          <Box mt={3}>
            {apiResponseErrors.map(error => {
              const { message } = error;
              return (
                <Alert severity="error" key={message}>
                  {message}
                </Alert>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {STEP_BUTTONS[step].map(buttonDetails => {
            const { title, isLoading, type, onClick } = buttonDetails;
            return (
              <CustomCancelFlowButton
                key={title}
                title={title}
                buttonType={type as BUTTON_TYPES}
                isLoading={isLoading}
                handleClick={onClick}
              />
            );
          })}
        </DialogActions>
      </Dialog>
    );
  }
}

export const styles = () =>
  createStyles({
    dialogPaper: {
      borderRadius: "30px",
      position: "relative",
      maxWidth: "420px"
    },
    closeIcon: {
      position: "absolute",
      right: "15px",
      top: "15px",
      backgroundColor: "#f0f0f0",
      "& svg": {
        color: "#000"
      }
    },
    dialogActions: {
      padding: "20px 30px 30px",
      gap: "20px",
      justifyContent: "center",
      "@media screen and (max-width: 480px)": {
        padding: "10px 25px 25px"
      }
    },
    dialogContent: {
      padding: "25px 30px 20px !important",
      "@media screen and (max-width: 480px)": {
        padding: "25px 25px 15px !important"
      }
    },
    titlesWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      width: "95%"
    },
    title: {
      color: "#000",
      fontSize: "17px",
      fontFamily: "'Montserrat', sans-serif",
      "@media screen and (max-width: 480px)": {
        fontSize: "15px"
      }
    },
    refundMessageWrapper: {
      marginTop: "10px",
      "& > div:nth-child(1)": {
        display: "flex",
        alignItems: "center",
        gap: "2px",
        marginBottom: "10px",
        "& span": {
          color: "#979797",
          fontSize: "16px",
          fontFamily: "'Montserrat', sans-serif",
          fontStyle: "italic"
        }
      }
    },
    refundField: {
      border: "1px solid #d1d1d1",
      borderRadius: "4px",
      "& input": {
        padding: "10px 12px",
        fontFamily: "'Montserrat', sans-serif"
      }
    }
  });

export default withStyles(styles)(OrderCancelApproveDialog);
// Customizable Area End
