// Customizable Area Start
import { Box, Dialog, DialogActions, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LearningDiscussionController, { Props } from './LearningDiscussionController.web';
import {
  trash_red_icon
} from './assets';
import Buttn from "@mui/material/Button";
import '../assets/css/educationCourseDetail.scss';
import CustomButton from '../../../components/src/CustomButton.web';
import { displayLocalTime } from '../../../components/src/utility/helper';
// Customizable Area End

export default class Discussion extends LearningDiscussionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const { partNum, partName ,lessonNum, heading} = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className="discussionWrapper">
            <Grid container style={{ marginTop: '1rem' }}>
              <Grid item xs={12} style={{ backgroundColor: 'rgba(20, 118, 32, 0.08)' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                  <Typography className="partName">Part 0{partNum} [{partName}]</Typography>
                  <Typography style={{ marginLeft: '20px', color: 'darkgray', fontFamily: 'Poppins' }}><ArrowForwardIosIcon /></Typography>
                  <Typography  className="lessonName">Lesson {lessonNum} - {heading} </Typography>
                </div>
              </Grid>
            </Grid>
        </Box>

        {this.state.discussion?.map((item:any) => 
            <DiscussionContainer 
            i={item}
            key={item.id} commentId={this.state.commentId}
            firstName={firstName}
            lastName={lastName}
            handleDelDiscussion={this.handleDelDiscussion}
            handleEditDiscussion={this.handleEditDiscussion}
            isEdit={this.state.isEdit}
            handleSaveEditDiscussion={this.handleSaveEditDiscussion}/>
        )}

        <div style={{ marginTop: '3rem', borderBottom: '2px solid #ccc' }}></div>
        <Grid container>
          <Grid xs={12} className="textContainer">
            <Box className="textBox">
              <TextField
                data-test-id="setDisc"
                value={this.state.discComment}
                onChange={this.handleComment}
                placeholder="Write your Comment here..."
                style={{ border: "none", color: 'black', padding: '5px 20px 0' }}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            {this.state.discComment.length > 0 ? 
            <button data-test-id="postBtn" className="reply_btn postBtn"
            onClick={this.handleDiscComment} 
          >
            Post
          </button>
          :
            <button className="reply_btn postBtn" 
               disabled
            >
              Post
            </button>
          }
          </Grid>

        </Grid>
      </>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export class DiscussionData extends React.Component<any,any> {
  constructor(props: Props) {
    super(props);
    this.state={
      openDialog : false
    }
  }
  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };
  render() {
    const { i, handleDelDiscussion, handleEditDiscussion, commentId, isEdit, handleSaveEditDiscussion } = this.props
    
    return (
      <>
        {commentId !== i.id ?
            <Box data-test-id={this.props.dataTestId} 
            className="leftDiscussion">
              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={12} className="reply" style={{ backgroundColor: '$GRAY_SHADE2', padding: '22px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }}>
                  <Box>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h5 style={{ fontWeight: 'bold' }}>You</h5>
                      <p>{displayLocalTime(i?.attributes?.updated_at)}</p>
                    </div>
                  </Box>

                  <Grid item xs={12}>
                    <TextField className="text"
                      defaultValue={i?.attributes?.comment}
                      multiline
                      InputProps={{ disableUnderline: true, readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ marginTop: '1rem' }}>
                      <img 
                        className="delDisIcon"
                        src={trash_red_icon}
                        data-test-id="delDisc"
                        onClick={() => this.setState({
                          openDialog: true
                        })} />
                      <button className='edit_Disc'
                        data-test-id="editDisc"
                        onClick={() => handleEditDiscussion(i.id)}>Edit</button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          :
          <>
            {commentId == i.id && isEdit &&
                <Box data-test-id={this.props.dataTestId}  className="leftDiscussion">
                  <Grid container style={{ marginTop: '20px' }}>
                    <Grid item xs={12} md={12} className="reply" style={{ backgroundColor: '$GRAY_SHADE2', padding: '22px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }}>
                      <Box>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <h5 style={{ fontWeight: 'bold' }}>You</h5>
                          <p>{`${new Date(i.attributes?.updated_at).getHours()}:${new Date(i?.attributes?.updated_at).getMinutes()}`}
                            {new Date(i.attributes?.updated_at).getHours() > 12 ? ' PM' : ' AM'}</p>
                        </div>
                      </Box>
                      <Grid item xs={12}>
                        <TextField className="text"
                          InputProps={{ disableUnderline: true }}
                          multiline
                          defaultValue={i?.attributes?.comment}
                          data-test-id="editComment"
                          onChange={(e: any) => this.setState({ comment: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} className="saveBtnWrapper">
                        <Buttn
                          data-test-id="saveEditDisc"
                          onClick={() => handleSaveEditDiscussion(i.id, this.state.comment, i.attributes?.course_sub_section_id)}
                          className="send-btn"
                          style={{ backgroundColor: "#ffc107"}}
                          variant="contained">
                          Save
                        </Buttn>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
            }

          </>}
          <div>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete the discussion ?
            </DialogTitle>
            <DialogActions>
              <span className="redContained customCancelBtn">
                <CustomButton
                  color="secondary"
                  label="No"
                  onClick={this.handleDialog}
                />
              </span>
              <span className="greenContained">
                <CustomButton
                  color="secondary"
                  label="Yes"
                  onClick={() => {
                    this.handleDialog();
                    handleDelDiscussion(i.id)
                  }}
                  autoFocus
                />
              </span>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}
export class DiscussionContainer extends React.Component<any,any>{
  constructor(props: Props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const { i, isEdit, handleDelDiscussion, handleEditDiscussion, handleSaveEditDiscussion, commentId, firstName, lastName } = this.props
    let today = new Date(i.attributes?.created_at);
    return (
      <>
      {i.attributes?.first_name === firstName && i.attributes?.last_name === lastName ?
            <DiscussionData
            i={i}
            key={i.id}
            handleDelDiscussion={handleDelDiscussion}
            handleEditDiscussion={handleEditDiscussion}
            commentId={commentId}
            isEdit={isEdit}
            handleSaveEditDiscussion={handleSaveEditDiscussion}/>
            :
            <Box style={{ margin: '0 auto', width: '85%' }}>
              <Grid container className="discussionData">
                <Grid item xs={12} md={12} className="discContainer">
                  <Box>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h5 style={{ fontWeight: 'bold' }}>{i?.attributes.first_name} {i?.attributes.last_name}</h5>
                      <p>{displayLocalTime(today)}</p>
                    </div>
                  </Box>
                  <Grid item xs={12} >
                    <TextField className="text"
                      defaultValue={i?.attributes.comment}
                      multiline
                      InputProps={{disableUnderline: true, readOnly: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          }

      </>
    )
  }
}

// Customizable Area End