// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../../../framework/src/StorageProvider"
export const configJSON = require("../../config.js")
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    firstName: string
    lastName: string
    profileImage: string
    isSearchDialog: boolean
    setCartBadge: (count: number) => void
    toggleSidebar: () => void
    resetUserDetails: () => void
    setCommunitySearchDialog: (isOpen: boolean) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: any
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityHeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            anchorEl: null            
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    handlePopoverOpen = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    
    handlePopoverClose = () => {
        this.setState({ anchorEl: null })
    }

    handleSearchDialogOpen = () => {
        this.props.setCommunitySearchDialog(true)
    }
    
    handleSearchDialogClose = () => {
        this.props.setCommunitySearchDialog(false)
    }

    handleMenuRedirect = (redirectLink: string) => {
        this.props.history.push(redirectLink)
    }

    handleUserLogout = async () => {
        const { history, setCartBadge, resetUserDetails } = this.props
    
        await StorageProvider.remove(configJSON.AUTH_TOKEN)
        await StorageProvider.remove(configJSON.FIRST_NAME_KEY)
        await StorageProvider.remove(configJSON.LAST_NAME_KEY)
        localStorage.removeItem(configJSON.PROFILE_KEY)
        localStorage.removeItem(configJSON.AUTH_TOKEN)
        localStorage.removeItem(configJSON.CART_ID_KEY)
        
        const cartOrders = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
        const ordersJSON = JSON.parse(cartOrders)
        setCartBadge(ordersJSON.length)
        resetUserDetails()
    
        history.push("/")
      }
    // Customizable Area End
}

