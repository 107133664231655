// Customizable Area Start
import * as React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import "../../web/src/assets/css/content/button.scss";

export default function CustomButton(props: any) {
  return (
    <div className="customButton">
      <Button {...props} disabled={props?.disabled} data-testid="customBtn">
        <span className="plus_icon"></span>
        {props?.isLoading ? (
          <CircularProgress size={25} className="custom_btn_loader" />
        ) : (
          <span>{props.label}</span>
        )}
      </Button>
    </div>
  )
}
// Customizable Area End
