import React, { Component } from "react";
import { Grid, Box, Container, Button } from "@material-ui/core";
import { RestaurantCardImg } from "../../assets";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ClearIcon from "@material-ui/icons/Clear";
import "../../assets/css/restaurantsearchmapviewlayout.scss";
class RestaurantMapView extends Component {
  render() {
    return (
      <Box className="restaurant_search_map_container">
        <Box className="restaurant_card_in_map">
          <Box className="restaurant_card_img_in_map">
            <img src={RestaurantCardImg} />
            <Box className="restaurant_card_header_img_in_map">
              <div>
                <span>
                  <StarBorderIcon />
                </span>
                <p>9.8</p>
              </div>
              <div>
                <ClearIcon />
              </div>
            </Box>
          </Box>

          <Box className="restaurant_card_details_in_map">
            <h6>Burgers</h6>
            <h5>Rosa cafe</h5>
            <span>42 long Panchestar St,9073</span>
            <div>
              <p>20-30 min</p>
              <span>From $4</span>
            </div>
            <Button>Order food</Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default RestaurantMapView;
