import React, { Component } from "react";
import { Grid, Box, TextareaAutosize, Button } from "@material-ui/core";
import WebReviewCard from "../../../../components/src/WebReviewCard.web";
import ProductDetailsLayout from "./productDetailsLayout.web";
//Customizable Area Start
import { updateActiveTab } from '../assets'
import { connect } from 'react-redux'
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
//Customizable Area End

class WebReviews extends Component<any, any> {

  async componentDidMount() {
    this.props.activeTab !== ActiveTabEnum.Market && this.props.updateActiveTab(ActiveTabEnum.Market)
  }

  render() {
    return (
      <ProductDetailsLayout>
        <Box style={{ margin: "" }}>
          <Box className="product_details_top_right_heading">
            <p>Write review</p>
          </Box>
          <Box className="product_details_children_wrapper">
            <Grid container spacing={3} xs={12}>
              <Grid item xs={12}>
                <p className="review_label">Reviews(10)</p>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <WebReviewCard
                    name="lisa mathew"
                    date="22 Dec,2021"
                    review="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid molestias fugiat explicabo quam nulla aspernatur quas, quasi non alias assumenda laudantium mollitia beatae libero maxime a fugit accusamus esse iste."
                    rating={4}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <p className="review_label">Write review</p>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={4}
                    placeholder="Write comment here..."
                    className="write_review_text_area"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Button classes={{ root: "green-btn", label: "btn-label" }}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ProductDetailsLayout>
    );
  }
}

const mapStateToProps = (state:any) => {
  return { activeTab: state.user.activeTab };
};

export default connect(mapStateToProps, { updateActiveTab })(WebReviews);
