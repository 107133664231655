import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import "../../../../ShoppingCart/assets/css/Shoppingcartpaymentmethod.scss";

class CheckOutPaymentMethod extends Component {
  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="cart_payment_method_title">payment method</p>
            <FormControl component="fieldset">
              <RadioGroup aria-label="gender" name="gender1">
                <FormControlLabel
                  value="Credit or debit card"
                  control={
                    <Radio
                      classes={{
                        // colorSecondary: "radio_checked",
                        checked: "radio_checked",
                      }}
                    />
                  }
                  label="Credit or debit card"
                  classes={{ root: "radio", label: "radio_label" }}
                />
                <FormControlLabel
                  value="Bitcoin wallete"
                  control={
                    <Radio
                      classes={{
                        // colorSecondary: "radio_checked",
                        checked: "radio_checked",
                      }}
                    />
                  }
                  label="Bitcoin wallete"
                  classes={{ root: "radio", label: "radio_label" }}
                />
                <FormControlLabel
                  value="Reward points"
                  control={
                    <Radio
                      classes={{
                        // colorSecondary: "radio_checked",
                        checked: "radio_checked",
                      }}
                    />
                  }
                  label="Loyalty points"
                  classes={{ root: "radio", label: "radio_label" }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total item</p>
                <p>$400.000</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Discount</p>
                <p>-$50.000</p>
              </Box>
            </Box>
            <hr />
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>sub total</p>
                <p>$350.000</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Shipping</p>
                <p>$10.000</p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box className="cart_total_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total(2 items)</p>
                <p>$350.000</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default CheckOutPaymentMethod;
