import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
import { isTokenExpired } from "../../../../components/src/utility/helper";
export const configJSON = require("../config")
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  history: any
  cart: any
  cartLoader: boolean
  activeTab: string
  cartOrderMeta: any
  handleCartLoader: (loading: boolean) => Promise<void>
  getCartItems: () => Promise<void>
  setCartItems: (cartItems: any) => void
  getAddressListAction: () => Promise<void>
  updateActiveTab: (tab: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  paymentMethod: string
  couponCode: string
  couponLoader: boolean
  checkOutLoader: boolean
  loginDialog: boolean
  transactionFee: number | null
  isCouponError: boolean[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  applyRemoveCouponApiId: string = ""
  createOrderApiCallId: string = ""
  getTransactionFeesApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      paymentMethod: configJSON.cardText,
      couponCode: "",
      couponLoader: false,
      checkOutLoader: false,
      loginDialog: false,
      transactionFee: null,
      isCouponError: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const { activeTab, updateActiveTab } = this.props

    activeTab !== ActiveTabEnum.NonNav && updateActiveTab(ActiveTabEnum.NonNav)
    this.getAddressAndItems()
  }

  calculateTransactionFees = (amount: number) => {
    const { transactionFee } = this.state
    const feeAmount =  transactionFee ? (transactionFee * amount) / 100 : 0
    return parseFloat(feeAmount.toFixed(2))
  }

  getTransactionFeePercentage = async (orderCategory: string) => {
    const orderType = configJSON.ORDER_TYPE_BY_CATALOGUE[orderCategory]
    const query = `?fees_type=${orderType}`
    await this.getTransactionFeesApi(query)
  }

  getOrdersFromStorage = async () => {
    const localItems = await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY) || "[]"
    return JSON.parse(localItems)
  }

  getAddressAndItems = async (notLoading: boolean = true) => {
    const { getCartItems, handleCartLoader, setCartItems } = this.props
    notLoading && await handleCartLoader(true)
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)
    if (!!token) {
      await getCartItems()
    } else {
      const localItemsJson = await this.getOrdersFromStorage()
      if (localItemsJson.length > 0) {
        if(!this.state.transactionFee) {
          this.getTransactionFeePercentage(localItemsJson[0].orderCategory)
        } else {
          this.groupingOrderFromSame(localItemsJson)
          await handleCartLoader(false)
        }
      } else {
        setCartItems({})
        await handleCartLoader(false)
      }      
    }
  }

  getFormattedItems = (items: any) => {
    const formattedItems = items?.reduce((group: any, product: any) => {
      const {
        uniqueId,
        sellerId,
        productImage,
        orderCategory,
        sellerName,
        sellerImage,
        name,
        price,
        comparePrice,
        quantity,
        description,
      } = product
  
      const isFarmOrder = orderCategory === configJSON.CARTABLE_TYPE_FARM
      const isVariantSelected =
        isFarmOrder &&
        product.selectedVariantId &&
        product.variant_options.length > 0
      let amount = isFarmOrder
        ? Number(comparePrice)
        : Number(comparePrice) + product.addOnsPrice
  
      if (isVariantSelected) {
        const selectedVariant = product.variant_options.filter(
          (variant: any) => variant.id === product.selectedVariantId
        )
        if (selectedVariant.length > 0) {
          amount = Number(selectedVariant[0].attributes.price)
        }
      }
  
      const orderType = isFarmOrder
        ? configJSON.farmCartText
        : configJSON.restaurantCartText
      const restaurantOrderData = !isFarmOrder
        ? {
            special_cooking_instruction: product.cookingInstruction,
            addons: product.extraThings
              .map((addon: any) => addon.name)
              .join(", "),
          }
        : {
            selected_variant_option_id: product.selectedVariantId,
            variant_options: product.variant_options,
            variant_description: product.variant_description,
          }
  
      const itemDetails = {
        id: uniqueId,
        type: orderType,
        attributes: {
          name,
          description,
          image: productImage,
          cart_price: price,
          price,
          comparePrice,
          quantity,
          cart_compare_at_price: comparePrice,
          product_active: true,
          discount: null,
          after_discount_price: null,
          listing_price: null,
          sgst: null,
          cgst: null,
          coupon_discount: null,
          discount_on_delivery: null,
          ...restaurantOrderData,
        },
      }
      let cartDetail = {}
      if (!!group[sellerId]) {
        const {
          item_count,
          total_item,
          quantity: oldQuantity,
        } = group[sellerId].cart_detail
        const subTotal = total_item + amount * quantity
        const transactionFee = this.calculateTransactionFees(subTotal)
        
        cartDetail = {
          item_count: item_count + 1,
          total_item: subTotal,
          discount: 0,
          sub_total: subTotal,
          shipping: 0,
          transaction_fees: transactionFee,
          total: transactionFee + subTotal,
          quantity: oldQuantity + quantity,
        }
      } else {
        const subTotal = amount * quantity
        const transactionFee = this.calculateTransactionFees(subTotal)

        cartDetail = {
          item_count: 1,
          total_item: subTotal,
          discount: 0,
          sub_total: subTotal,
          shipping: 0,
          transaction_fees: transactionFee,
          total: subTotal + transactionFee,
          quantity,
        }
      }
  
      group[sellerId] = group[sellerId] ?? {
        product_owner_details: {
          data: {
            id: sellerId,
            type: configJSON.productOwnerDetailsText,
            attributes: {
              id: sellerId,
              name: sellerName,
              image: sellerImage,
              type: configJSON.farmText,
            },
          },
        },
        cart_items: {
          data: [],
        },
        cart_detail: {},
        coupon_details: {
          data: null,
        },
      }
      group[product.sellerId].cart_items.data.push(itemDetails)
      group[product.sellerId].cart_detail = cartDetail
      return group
    }, {})
  
    return formattedItems
  }
  
  getTotalCartValues = (formattedItems: any) => {
    const totalCart = Object.keys(formattedItems).reduce(
      (mainCart: any, key: any) => {
        const {
          total_count,
          total_item,
          discount,
          sub_total,
          shipping,
          transaction_fees,
          total,
          quantity,
        } = mainCart
        const cartDetail = formattedItems[key].cart_detail
        return {
          total_count: total_count + cartDetail.item_count,
          total_item: total_item + cartDetail.total_item,
          discount: discount + cartDetail.discount,
          sub_total: sub_total + (cartDetail.sub_total - cartDetail.discount),
          shipping: shipping + cartDetail.shipping,
          transaction_fees: transaction_fees + cartDetail.transaction_fees,
          total: total + cartDetail.total,
          quantity: quantity + cartDetail.quantity,
          coupon: {
            data: null,
          },
        }
      },
      {
        total_count: 0,
        total_item: 0,
        discount: 0,
        sub_total: 0,
        shipping: 0,
        transaction_fees: 0,
        total: 0,
        quantity: 0,
        coupon: {
          data: null,
        },
      }
    )
  
    return totalCart
  }
  
  groupingOrderFromSame = (items: any) => {
    console.log("here transaction", this.state.transactionFee)
    const orderType =
      items[0].orderCategory === configJSON.CARTABLE_TYPE_FARM
        ? configJSON.farmText
        : configJSON.restaurantText
    const timing = items?.length > 0 ? items[0]?.deliveryTime : ""
    const deliveryTime = orderType === configJSON.restaurantText ? timing : ""
  
    const formattedItems = this.getFormattedItems(items)
  
    const totalCart = this.getTotalCartValues(formattedItems)
  
    const orderData = {
      product_cart_items: { ...formattedItems },
      type: orderType,
      order_accepted: true,
      delivery_time: deliveryTime,
      cart_details: {
        data: {
          attributes: totalCart,
        },
      },
    }
    this.props.setCartItems(orderData)
  }

  handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ couponCode: e.target.value })
  }

  handleApplyRemoveCoupon = async (isCouponApplied: boolean) => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if (!isLoggedIn) this.openLoginDialog()
    else {
      const cartItems = this.props.cart?.product_cart_items
      const couponPayload = Object.keys(cartItems)?.map((key: string) => {
        return {
          coupon_code: isCouponApplied ? null : this.state.couponCode,
          coupon_apply_for: cartItems[key].cart_item_ids
        }
      })
      this.applyRemoveCouponApi(couponPayload)
    }
  }

  applyRemoveCouponApi = async (couponDetails: any[]) => {
    this.setState({ couponLoader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const httpBody = {
      coupon_apply: couponDetails
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyRemoveCouponApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyRemoveCouponEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  openLoginDialog = () => {
    this.setState({ loginDialog: true })
  }

  closeLoginDialog = () => {
    this.setState({ loginDialog: false })
  }

  getPaymentType = (type: string) => {
    switch (type) {
      case "card":
        return configJSON.cardTypeText
      case "reward":
        return configJSON.rewardPointsText
      default:
        return configJSON.cardTypeText
    }
  }

  handleCheckoutOrder = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if (!isLoggedIn) this.openLoginDialog()
    else {
      const { paymentMethod } = this.state
      const { cart, cartOrderMeta } = this.props
      const { product_cart_items } = cart

      const orders = Object.keys(product_cart_items)?.map((key: string) => {
        const { cart_item_ids, cart_detail, coupon_details } = product_cart_items[key]
        const { total, shipping, sub_total, total_item, discount, item_count } = cart_detail
        const coupon_code_id = coupon_details.data?.id || null
        const orderNote = cartOrderMeta.hasOwnProperty(key) ? cartOrderMeta[key].orderNote : ""

        return {
          item_count,
          total_item,
          discount,
          sub_total,
          shipping,
          total,
          coupon_code_id,
          note: orderNote,
          cart_item_ids
        }
      })
      const paymentOption = this.getPaymentType(paymentMethod)

      const payload = {
        payment_method: paymentOption,
        address_id: "",
        orders
      }
      this.createOrderApi(payload)
    }
  }

  createOrderApi = async (orderDetails: any) => {
    this.setState({ checkOutLoader: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(orderDetails)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getTransactionFeesApi = async (query: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionFeesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTransactionFeesApiEndPoint}${query}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSuccessApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.applyRemoveCouponApiId) {
      this.getErrorArray(responseJson)
      this.props.setCartItems(responseJson)
      if (responseJson?.cart_details?.data?.attributes?.discount > 0) {
        this.getCouponApplied(this.state.isCouponError)
      } else if (responseJson?.coupon.data === null && !(this.state.isCouponError.includes(true))) {
        this.setState({ couponCode: "" })
        toast.success(configJSON.couponRemovalMsg)
      }
      this.setState({ couponLoader: false })
    }

    if (apiRequestCallId === this.createOrderApiCallId) {
      this.setState({ checkOutLoader: false })
      if (responseJson?.cart_item_ids?.length > 0) {
        const orderIds = responseJson.orders.data?.map((order: any) => order.id)
        this.props.history.push({
          pathname: configJSON.checkOutOrderPath, state: {
            orderIds,
            cartIds: responseJson.cart_item_ids,
          },
        })
      } else if (responseJson?.quantity_error) {
        this.props.getCartItems()
        toast.error(configJSON.quantityErrorText)
      }
    }

    if(apiRequestCallId === this.getTransactionFeesApiCallId) {
      let fees = 0
      if(responseJson.transaction_fees) {
        fees = responseJson.transaction_fees
      }
      this.setState({ transactionFee: fees })
      const { handleCartLoader } = this.props
      const orders = await this.getOrdersFromStorage()
      this.groupingOrderFromSame(orders)
      handleCartLoader(false)
    }
  }

  getErrorArray = (responseJson: any) => {
    const cartItems = responseJson?.product_cart_items ?? {}
    const errorArr = Object.keys(cartItems)?.map((key: any) => cartItems[key].coupon_error)
    this.setState({ isCouponError: errorArr })
    this.getCouponErrMsg(this.state.isCouponError)
  }

  getCouponApplied = (errorArr: boolean[]) => {
    if (errorArr.length === 1) {
      toast.success(configJSON.couponAppliedMsg)
    }
    else if (errorArr.length > 1) {
      errorArr.forEach((errVal: boolean, index: number) => {
        if (errVal === false) {
          const cartVal = index + 1;
          toast.success(configJSON.couponAppliedMsgToCart + cartVal)
        }
      })
    }
  }
  getCouponErrMsg = (errorArr: boolean[]) => {
    if (errorArr.length === 1 && errorArr[0] === true) {
      toast.error(configJSON.couponNotApplicableMsg)
    }
    else {
      errorArr.forEach((errVal: boolean, index: number) => {
        if (errVal === true) {
          const cartVal = index + 1;
          toast.error(configJSON.couponNotApplicableMsg + cartVal)
        }
      })
    }
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        if (await isTokenExpired(responseJson)) return

        const validIds = [this.createOrderApiCallId, this.applyRemoveCouponApiId]

        if (apiRequestCallId === this.applyRemoveCouponApiId) {
          this.setState({ couponLoader: false })
          this.getErrorArray(responseJson)
          responseJson.errors?.forEach((err: string) => {
            toast.error(err)
          })
        }

        if (validIds.includes(apiRequestCallId) && responseJson?.errors) {
          responseJson.errors?.forEach((err: any) => {
            toast.error(err?.message)
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area End

}
