// Customizable Area Start
import React from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import { SunsetFarm, GrassCut, HappyFaces } from "../../assets"
import { connect } from "react-redux"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import LeftRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { Link } from "react-router-dom"

interface IProps {
    WLLPAboutUsHeading: string
    WLLPAboutUsDesc: string
}

const WLLPAboutUs = ({ WLLPAboutUsHeading, WLLPAboutUsDesc }: IProps) => {
    return (
        <Box className="landing_info_second">
            <Box className="landing_info_second_box">
                <Container fixed>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box className="landing_info_images_container">
                                <Box>
                                    <img
                                        src={SunsetFarm}
                                        alt="sunset_farm"
                                        className="landing_info_sunset"
                                    />
                                </Box>
                                <Box className="second_image_box">
                                    <img
                                        src={GrassCut}
                                        alt="grass_cut"
                                        className="landing_info_grasscut"
                                    />
                                    <img
                                        src={HappyFaces}
                                        alt="happy_faces"
                                        className="landing_info_faces"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Link to={AppRoutings.AboutUs}>
                                <Box className="landing_info_about">
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">About Us</Typography>
                                        <LeftRoundedIcon className="about_us_arrow" />
                                    </Box>
                                    <Box className="about_subheading">
                                        <Typography>{WLLPAboutUsHeading}</Typography>
                                    </Box>
                                    <Box className="about_description">
                                        <Typography>{WLLPAboutUsDesc}</Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        WLLPAboutUsHeading: state.cms.WLLPAboutUsHeading,
        WLLPAboutUsDesc: state.cms.WLLPAboutUsDesc,
    }
}

export default connect(mapStateToProps, {})(WLLPAboutUs)
// Customizable Area End
