// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { AppRoutings } from "../../../../../components/src/utility/app-routing";
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./OrderSummaryWeb.web"
export const configJSON = require("../../../../info-page/src/config.js")

export interface Props extends WithStyles<typeof styles> {
    checkoutOrders: any[],
    classes : any
}

interface S {
}

interface SS {
}

export default class OrderSummaryWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {}
    }

    async componentDidMount(): Promise<void> {
        document.getElementById("cartFlow")?.scrollIntoView({ behavior: "smooth" })
    }

    handleRoute = (type:string, sellerId:number) => {
        if(type === configJSON.restaurantText){
            return`${AppRoutings.RestaurantDetails}/${sellerId}`
        }
        else if(type === configJSON.courseText){
            return`${AppRoutings.CourseDetails}${sellerId}`
        }
        else return`${AppRoutings.FarmDetails}/${sellerId}`
    }
}

// Customizable Area End