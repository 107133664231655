// Customizable Area Start
import React from "react";
import { PencilIcon,ProfileImg,DeleteIcon,ProfileUpload } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// custom components
import CustomButton from "../../../components/src/CustomButton.web";
import {
  Button,
  MenuItem,
  Select,
  Grid,
  Typography,
  FormLabel,
  FormControl,
  withStyles,
  TextField,
  InputAdornment,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
} from "@material-ui/core";
import { withRouter} from "react-router-dom";
import Box from "@mui/material/Box";

//components
import AdminLearningCourseListController, {
} from "./AdminLearningCourseListController.web";


import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { commonCmptStyles } from "./dropDownCss";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import RewardAcceptanceSelect from "./RewardAcceptanceSelect/RewardAcceptanceSelect.web";
import AdminTransactionHistoryButton from "./AdminTransactionHistory/AdminTransactionHistoryButton.web";
import "react-toastify/dist/ReactToastify.css";

const regex = /^\d{0,5}(\.\d{1,2})?$/;
const Schema = Yup.object().shape({
  title: Yup.string().required("This field is required.")
  .min(5, 'Minimum 5 charaters required')
  .max(50, 'Maximum 50 characters allowed'),
  images: Yup.string().required("This field is required."),
  description: Yup.string()
  .test('len', 'Min 50 words are required', (val:any) =>val?.split(" ").length >50)
  .test('len', 'Max 150 words are allowed', (val:any) => val?.split(" ").length <150),
  price: Yup.string()
  .when("course_free", {
      is: (course_free) => !course_free,
      then: Yup.string().test(
        'Is positive?', 
        'The number must be greater than 0!', 
        (value) => value > 0
        //@ts-ignore
      ).matches(regex,"Prices with five digits and two decimal points are allowed").test(
        'Is positive?', 
        'The number must be greater than 0!', 
        (value:any) => value > 0
      ).required("This field is required."),
    })
    ,

});

const educatorSchema = Yup.object().shape({
  edu_name: Yup.string().required("This field is required."),
  edu_detail: Yup.string().required("This field is required."),
});

import Loader from "../../../components/src/Loader.web";

class AdminLearningCourseDetailWeb extends AdminLearningCourseListController {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const details =
      this.state.courseDetails && this.state.courseDetails.attributes;


    return (
      <>
        <Loader loading={this.state.loading} />

        <Formik
          enableReinitialize
          innerRef={this.state.myRef}
          initialValues={this.settingInitialValue(details)}
          validationSchema={Schema}
          onSubmit={(values) => {
            this.createCourseDetail({ ...values, ...this.props.rightSidevalues });
          }}
        >
          {(formikProps) => {
            const {
              values,
              setFieldValue,
              handleChange,
              touched,
              errors,
            } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                {/* course detail content */}
                <div className="productTitleContainer learningCourseContainer">
                  <div className="outlineTextfield boxRadiusBorder">
                    <label>Course Title</label>
                    <br />
                    <div className="customTextfield">
                      <TextField
                        id="title"
                        value={values.title}
                        variant="standard"
                        placeholder="Enter Course Title..."
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        error={touched.title && Boolean(errors.title)}
                      />
                      <span className="errorMsg">
                        <ErrorMessage name="title" />
                      </span>
                    </div>
                  </div>
                  <div className="outlineTextfield boxRadiusBorder textarea-wrapper">
                    <label>Course Description</label>
                    <br />
                    <div className="customTextAreafield">
                      <TextareaAutosize
                        id="description"
                        className="description"
                        minRows={8}
                        value={values.description}
                        onChange={handleChange}
                      // disabled={!this.state.isEdit}
                      />
                      <span className="errorMsg">
                        <ErrorMessage name="description" />
                      </span>
                    </div>
                  </div>
                  <div className="accordianHeader">
                    <label>Educator</label>
                    <div className="btn-container">
                      <Button className="save add-btn" data-testid="addBtn" onClick={() => {
                        setFieldValue("isExpanded", true)
                        setFieldValue(
                          "editEducatorValues",
                          ""
                        );
                        setFieldValue(
                          "isEditEducator",
                          false
                        );
                      }}>
                        ADD NEW
                      </Button>
                    </div>
                  </div>
                  <div className="deliveryDetailBlocks">
                    {values.educators.length > 0 && values.educators.map((educator: any) => {
                      return (
                        <div className="deliveryDetailRow" key={educator.id}>
                          <div className="deliveryBlocks deliveryBlock1">
                            <Typography variant="h6">
                              {educator.edu_name || educator.attributes.educator_name}
                            </Typography>
                          </div>
                          <div className="deliveryBlocks deliveryBlock2">
                            <Typography variant="h6">
                              {educator.edu_detail || educator.attributes.educator_detail}

                            </Typography>
                          </div>
                          <div
                            className="deliveryBlocks deliveryBlock3"
                          >
                            <Button className="icons-btn"
                             data-testid="iconsBtn"
                              onClick={() => {
                                formikProps.setFieldValue(
                                  "isExpanded",
                                  true
                                );
                              this.handlingAccordian(setFieldValue,educator)

                              }} >
                              <img src={PencilIcon} alt="Edit" />
                            </Button>
                          </div>
                          <div
                            className="deliveryBlocks deliveryBlock4">
                            <Button className="icons-btn" data-testid="iconsBtn2" onClick={() => {
                              const filterDelete = formikProps.values.educators.filter(
                                (x: any) =>
                                  x.id !=
                                  //@ts-ignore
                                  educator.id
                              );
                              formikProps.setFieldValue("educators", [
                                ...filterDelete,
                              ])

                            }} >
                              <img src={DeleteIcon} alt="Delete" />
                            </Button>
                          </div>
                        </div>
                      )
                    })}


                  </div>
                  {/* educators start */}
                  {formikProps.values.isExpanded && <div className="commonAccordian courseAccordian">
                    <Formik
                      data-testid="formik2"
                      innerRef={this.state.educatorsRef}
                      enableReinitialize
                      initialValues={{
                        edu_name: "",
                        edu_detail: "",
                        edu_select: 0,
                        banner_image:"",
                        id: new Date().toString(),
                      }}
                      validationSchema={educatorSchema}
                      onSubmit={(values: any, { resetForm }: any) => {
                       this.handleformSubmit(formikProps,values)
                        resetForm({ values: "" });
                      }}
                    >
                      {(formikProps1) => {
                        const {
                          values,
                          setFieldValue,
                          handleChange,
                          touched,
                          errors,
                        } = formikProps1;
                        return (
                          <Form noValidate autoComplete="off">

             
                            <Accordion
                            data-testid="Accordion"
                              expanded={
                                formikProps.values.isExpanded
                              }
                              onChange={() => {
                                formikProps.setFieldValue(
                                  "isExpanded",
                                  !formikProps.values.isExpanded
                                );
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <h6>Add New Educator</h6>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="outlineTextfield boxRadiusBorder textarea-wrapper">
                                  <div className="course-educator-row">
                                    <div className="green-outline">
                                      <div className="customSelect itemStatus">
                                        <FormControl>
                                          <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="media_mode"
                                            value={values.edu_select}
                                            onChange={(e: any) => {
                                              this.handleEducatorSelect(e,setFieldValue)                                            
                                            }}
                                            MenuProps={{
                                              anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                              },
                                              transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                              },
                                              classes: {
                                                paper: classes.itemStatus,
                                              },
                                              getContentAnchorEl: null,
                                            }}
                                          // IconComponent={() => <ExpandMoreIcon />}
                                          >
                                            <MenuItem disabled value={0}>
                                              Select Educator
                                            </MenuItem>
                                            {this.state.educatorsList && this.state.educatorsList.length > 0 && this.state.educatorsList.map((educator: any) => {
                                              return (
                                                <MenuItem key={educator.id} value={educator.id}>
                                                  {educator.attributes.educator_name}
                                                </MenuItem>

                                              )

                                            })}



                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div className="education-row">
                                  <Box className="web_input_field">
                                <div className="mediaUploaderLearning mediaUploaderLearninghelper detailsProfile">
                                  <img
                                    src={this.settingImage(values, ProfileImg)}
                                  />
                                </div>
                                <div className="textUpdateBlock newtextUpdateBlock">
                                  <span className="textUpdate">
                                    <input
                                      type="file"
                                      accept="image/png, image/jpeg, image/jpg"
                                      name="banner_image"
                                      id="banner_image"
                                      hidden
                                      className="inputfile"
                                      onChange={(e: any) => {
                                        this.uploadingEducatorImg(e.target.files,setFieldValue)                                      
                                      }}
                                    />
                                    <label htmlFor="banner_image">
                                  {this.buttonUpload(values)}
                                      
                                    </label>
                                  </span>
                                  {values.banner_image ? (
                                    <img
                                      src={DeleteIcon}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      alt="delete"
                                      id="delImg"
                                      onClick={() => {
                                        setFieldValue("banner_image", "");
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                 
                                </div>
                                </Box>
                                  <div className="outlineTextfield boxRadiusBorder">
                                    <label>Educator Name</label>
                                    <br />
                                    <div className="customTextfield">
                                      <TextField
                                        id="edu_name"
                                        value={values.edu_name}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={(e: any) => {
                                          this.handleEduName(setFieldValue,e)

                                        }}
                                        error={touched.edu_name && Boolean(errors.edu_name)}
                                      />
                                      <span className="errorMsg">
                                        <ErrorMessage name="edu_name" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="outlineTextfield boxRadiusBorder textarea-wrapper">
                                    <label>Educator detail</label>
                                    <br />
                                    <div className="customTextAreafield">
                                      <TextareaAutosize
                                        id="edu_detail"
                                        className="edu_detail"
                                        minRows={8}
                                        value={values.edu_detail}
                                        onChange={handleChange}
                                      />
                                      <span className="errorMsg">
                                        <ErrorMessage name="edu_detail" />
                                      </span>
                                    </div>
                                    <Grid item xs={12} sm={6}>
                                      <span className="greenContained" >
                                        <CustomButton
                                        style={{"marginBottom":"13px"}}
                                          color="secondary"
                                          label={`${this.handlesubmitText(formikProps)
                                            }`}
                                          type="submit"
                                        />
                                      </span>
                                    </Grid>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Form>)
                      }}

                    </Formik>
                  </div>}
                  {/* educators end */}


                  <div className="education-row">

                    <div className="mediaContainer">
                      <div className="mediaHeader">
                        <h4>Banner Image</h4>
                      </div>
                      <>
                        <div className="mediaUploader mediaUploadcourse">
                          <label>
                            <input
                              id="images"
                              name="images"
                              type="file"
                              hidden
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e: any) => {
                                setFieldValue("images",
                                  e.currentTarget.files,
                                )
                              }}
                            />
                            <img src={ProfileUpload}/>
                            <span >
                              Add Photo
                            </span>
                          </label>
                        </div>
                        {values?.images && <div className="uploadSection upload-mt">

                          <div
                            className="uploadImgbg"
                          >
                            {! (values.images && values.images[0] && values.images[0].name) ?

                              <img
                                src={values.images.url}
                                alt="banner"
                              /> :
                              <img
                                src={(
                                  window.URL ||
                                  // (window as any)
                                  window.webkitURL
                                ).createObjectURL(
                                  //@ts-ignore
                                  values.images[0]
                                )}
                                alt="banner"
                              />
                            }
                            <span
                              className="close-icon-bg"
                              onClick={(e: any) => {

                                //@ts-ignore
                                setFieldValue(
                                  "images",
                                  ""
                                );
                              }}
                            >
                              <span className="close-icon">
                                +
                              </span>
                            </span>
                          </div>

                        </div>
                        }
                      </>
                      <span className="errorMsg">
                        <ErrorMessage name="images" />
                      </span>
                    </div>
                    <div className="priceInfo">
                      <div className="customheading">
                        <h4>Pricing</h4>
                        <hr />
                      </div>
                      <div className="productState">
                        <div className="customCheckbox">
                          <input
                            type="checkbox"
                            name="course_free"

                            //@ts-ignore
                            id="course_free"
                            value="course_free"
                            checked={values.course_free}
                            onChange={(e: any) => {
                              this.courseFreeHandle(setFieldValue,e)
                              

                            }}
                          />
                          <label htmlFor="course_free" className="product_checkbox" />
                        </div>
                        <FormLabel component="legend">
                          This course is free
                        </FormLabel>
                      </div>
                      <div className="outlineTextfield boxRadiusBorder">
                        <label>Course Price</label>
                        <br />
                        <div className="customTextfield">
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachMoneyIcon />
                                </InputAdornment>
                              ),
                            }}
                            id="price"
                            value={values.price}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={values.course_free}
                            onChange={handleChange}
                            error={
                              touched.price &&
                              Boolean(errors.price)
                            }
                          />
                          <span className="errorMsg">
                            <ErrorMessage name="price" />
                          </span>
                        </div>
                      </div>
                      <Box mt={3}>
                        <hr />

                        <RewardAcceptanceSelect
                          value={values.purchase_limit_percentage}
                          handleValueChange={(value: number) => setFieldValue("purchase_limit_percentage", value)}
                          name="purchase_limit_percentage"
                        />
                        <AdminTransactionHistoryButton />
                      </Box>
                      <div />
                    </div>
                  </div>
                  <Hidden smDown>
                    <div className="edu-btn-container btn-container">
                      <Button
                        className="cancel"
                        onClick={this.props.history.goBack}
                      >
                        Cancel
                      </Button>
                      <Button className="save" type="submit">
                        Save
                      </Button>
                    </div>
                  </Hidden>
                </div>
                {/* end: course detail content */}

              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export {AdminLearningCourseDetailWeb}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningCourseDetailWeb)
);
// Customizable Area End
