// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
import { toast } from "react-toastify"
import { ISearchFarm } from "../../interfaces/search"
import queryString from "query-string"
import { ICategory, ITag } from "../../../../../components/src/interfaces/common"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps {}

interface S {
  loader: boolean
  categoriesLoader: boolean
  priceRangeLoader: boolean
  tagsLoader: boolean
  categories: ICategory[]
  minPrice: number
  maxPrice: number
  tags: ITag[]
  farms: ISearchFarm[]
  totalPages: number
}

interface SS {
  id: any
}

export default class SearchFarmsController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchFarmsApiCallId: string = ""
  farmsCategoriesApiCallId: string = ""
  farmsPriceRangeApiCallId: string = ""
  farmsTagsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      categoriesLoader: false,
      priceRangeLoader: false,
      tagsLoader: false,
      categories: [],
      minPrice: 0,
      maxPrice: 0,
      tags: [],
      farms: [],
      totalPages: 0,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    await this.farmsCategoriesApi()
    await this.farmsPriceRangeApi()
    await this.farmsTagsApi()
    this.handleSearchFarms()
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.handleSearchFarms()
    }
  }

  scrollToTop = () => {
    const element = document.getElementsByClassName("web_header")
    if (element && element.length > 0)
      element[0].scrollIntoView({ behavior: "smooth" })
  }

  enableSearchLoader = () => {
    this.setState({ loader: true })
  }

  disableSearchLoader = () => {
    this.setState({ loader: false })
  }

  handleSearchFarms = () => {
    this.scrollToTop()
    const { search } = this.props.location
    const parsedQueryString = queryString.parse(search)
    const { query, ...rest } = parsedQueryString
    const page = rest.page ?? 1
    const per = rest.per ?? 16

    const updatedQueryString = queryString.stringify({
      ...rest,
      search: query,
      page,
      per,
    })

    if (query) {
      this.searchFarmsApi(`?${updatedQueryString}`)
    } else {
      this.props.history.goBack()
    }
  }

  searchFarmsApi = async (query: string) => {
    this.enableSearchLoader()

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchFarmsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchFarmsApiEndPoint + query
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  farmsCategoriesApi = async () => {
    this.setState({ categoriesLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.farmsCategoriesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndPoint + configJSON.categoryTypeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  farmsPriceRangeApi = async () => {
    this.setState({ priceRangeLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.farmsPriceRangeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceRangeApiEndPoint + configJSON.typeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  farmsTagsApi = async () => {
    this.setState({ tagsLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.farmsTagsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsApiEndPoint + configJSON.tagsTypeFarmQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.searchFarmsApiCallId) {
      if (responseJson.data) {
        const {
          data,
          meta: {
            pagination: { total_pages },
          },
        } = responseJson
        this.setState({ farms: data, totalPages: total_pages })
      } else {
        toast.error(configJSON.SOMETHING_WENT_WRONG)
      }
      this.disableSearchLoader()
    }

    if (apiRequestCallId === this.farmsCategoriesApiCallId) {
      this.setState({ categoriesLoader: false, categories: responseJson })
    }

    if (apiRequestCallId === this.farmsPriceRangeApiCallId) {
      const { min_price, max_price } = responseJson
      this.setState({
        priceRangeLoader: false,
        minPrice: Number(min_price),
        maxPrice: Number(max_price),
      })
    }

    if (apiRequestCallId === this.farmsTagsApiCallId) {
      this.setState({ tagsLoader: false, tags: responseJson.data ?? [] })
    }
  }

  handleErrorApiResponse = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.searchFarmsApiCallId) {
      this.disableSearchLoader()
      this.setState({ farms: [] })
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        if (await isTokenExpired(responseJson)) {
          return
        }
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
  }
}
// Customizable Area End
