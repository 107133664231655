// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isExpertDetailsDialog: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class ExpertDetailsDialogController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            isExpertDetailsDialog: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.setState({ isExpertDetailsDialog: true })
    }

    handleCloseDialog = () => {
        this.props.history.push(AppRoutings.Experts)
    }
    // Customizable Area End
}
