// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../framework/src/Message"
import { runEngine } from "../../../../framework/src/RunEngine"
import { isTokenExpired } from "../../../../components/src/utility/helper"
import React from "react"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "../../../../framework/src/StorageProvider.web"
import { IImage } from "../../../../components/src/interfaces/common"
import {
  ISearchCourseAdmin,
  ISearchDish,
  ISearchFarm,
  ISearchProductAdmin,
  ISearchRestaurant,
} from "../interfaces/search"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { ISearchResultTypeEnum } from "../interfaces/searchEnum"
const configJSON = require("../config.js")

type CallbackType<T extends unknown[]> = (...args: T) => void

export interface ISearchResult {
  id: string
  type: ISearchResultTypeEnum
  name: string
  image: IImage | null
  producerId: string
}

interface ISearchResultResponse {
  farms: { data: ISearchFarm[] }
  restaurants: { data: ISearchRestaurant[] }
  courses: { data: ISearchCourseAdmin[] }
  products: { data: ISearchProductAdmin[] }
  dishes: { data: ISearchDish[] }
}

export interface Props extends RouteComponentProps {
  navigation: any
  isMobileSearchPage?: boolean
}

interface S {
  isListPopupOpen: boolean
  searchValue: string
  loader: boolean
  isSearchApiCalled: boolean
  searchResults: ISearchResult[]
}

interface SS {
  id: any
}

export default class NavbarSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchApiCallId: string = ""
  searchFieldRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      isListPopupOpen: false,
      searchValue: "",
      searchResults: [],
      isSearchApiCalled: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentWillUnmount(): Promise<void> {
    this.handleSearchPopupClose()
  }

  enableLoading = () => {
    this.setState({ loader: true })
  }

  disableLoading = () => {
    this.setState({ loader: false })
  }

  searchDebounce = <T extends unknown[]>(call: CallbackType<T>, delay: number) => {
    let timer: ReturnType<typeof setTimeout> | undefined
    return function(...args: T) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  handleEmptySearch = () => {
    this.setState({ loader: false, searchResults: [] })
  }

  SearchDebounceUpdate = this.searchDebounce(() => {
    const { searchValue } = this.state
    return searchValue.length > 2
      ? this.adminSearchApi(`?query=${searchValue}&limit=4`)
      : this.handleEmptySearch()
  }, 700)

  handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState({ searchValue: value })
    if(!value) this.setState({ isSearchApiCalled: false })
    this.SearchDebounceUpdate()
  }

  handleSearchFieldKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e

    if (key === configJSON.ESCAPE_KEY) {
      this.handleSearchPopupClose()
    }
  }

  handleResetSearch = () => {
    this.setState({
      searchValue: "",
      searchResults: [],
      isListPopupOpen: false,
      isSearchApiCalled: false
    })
  }

  handleClearIcon = () => {
    this.handleResetSearch()
    if (this.searchFieldRef.current) this.searchFieldRef.current.focus()
  }

  handleSearchPopupOpen = () => {
    this.setState({ isListPopupOpen: true })
  }

  handleSearchPopupClose = () => {
    this.setState({ isListPopupOpen: false })
  }

  handleRedirect = (result: ISearchResult) => {
    const { history } = this.props
    const { id, producerId, type } = result

    switch (type) {
      case ISearchResultTypeEnum.Farm:
        history.push(`${AppRoutings.AdminFarmEditFarmId}${id}`)
        break
      case ISearchResultTypeEnum.Product:
        history.push(
          `${AppRoutings.AdminFarmProduct}${producerId}?mode=edit&product_id=${id}`
        )
        break
      case ISearchResultTypeEnum.Restaurant:
        history.push(`${AppRoutings.AdminRestaurantDetails}/edit?id=${id}`)
        break
      case ISearchResultTypeEnum.Dish:
        history.push({
          pathname: `${AppRoutings.AdminRestaurantDishes}/${id}/create-dish`,
          state: {
            id: producerId,
            dishId: id,
          },
        })
        break
      case ISearchResultTypeEnum.Course:
        history.push(
          `${AppRoutings.AdminCourse}?mode=edit&course_id=${id}&contributer_id=${producerId}&lesson_id=&tab=0`
        )
        break
      default:
        break
    }
    this.handleResetSearch()
  }

  adminSearchApi = async (query: string) => {
    this.enableLoading()
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminSearchSuggestionsApiEndPoint + query
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getFarmsConvertedResponse = (farmsList: ISearchFarm[]) => {
    return farmsList.map((farm: ISearchFarm) => {
      const {
        id,
        attributes: { name, images },
      } = farm
      return {
        id,
        name,
        image: images,
        producerId: "",
        type: ISearchResultTypeEnum.Farm,
      }
    })
  }

  getRestaurantsConvertedResponse = (restaurantsList: ISearchRestaurant[]) => {
    return restaurantsList.map((restaurant: ISearchRestaurant) => {
      const {
        id,
        attributes: { image, name },
      } = restaurant
      return {
        id,
        name,
        image,
        producerId: "",
        type: ISearchResultTypeEnum.Restaurant,
      }
    })
  }

  getCoursesConvertedResponse = (coursesList: ISearchCourseAdmin[]) => {
    return coursesList.map((course: ISearchCourseAdmin) => {
      const {
        id,
        attributes: { banner_image, title, educational_contributor_id },
      } = course
      return {
        id,
        name: title,
        image: banner_image,
        type: ISearchResultTypeEnum.Course,
        producerId: String(educational_contributor_id)
      }
    })
  }

  getProductsConvertedResponse = (products: ISearchProductAdmin[]) => {
    return products.map((product: ISearchProductAdmin) => {
      const {
        id,
        attributes: { name, images, farm_id },
      } = product
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null
      return {
        id,
        type: ISearchResultTypeEnum.Product,
        name,
        image,
        producerId: String(farm_id),
      }
    })
  }

  getDishesConvertedResponse = (dishesList: ISearchDish[]) => {
    return dishesList.map((dish: ISearchDish) => {
      const {
        id,
        attributes: { restaurant_id, name, images },
      } = dish
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null
      return {
        id,
        name,
        image,
        producerId: restaurant_id,
        type: ISearchResultTypeEnum.Dish,
      }
    })
  }

  handleSearchResult = (response: ISearchResultResponse) => {
    const {
      farms: { data: farms },
      dishes: { data: dishes },
      products: { data: products },
      courses: { data: courses },
      restaurants: { data: restaurants },
    } = response
    const farmsList = this.getFarmsConvertedResponse(farms)
    const restaurantsList = this.getRestaurantsConvertedResponse(restaurants)
    const coursesList = this.getCoursesConvertedResponse(courses)
    const productsList = this.getProductsConvertedResponse(products)
    const dishesList = this.getDishesConvertedResponse(dishes)

    const searchResults = [
      ...productsList,
      ...dishesList,
      ...coursesList,
      ...farmsList,
      ...restaurantsList,
    ]

    this.setState({ isListPopupOpen: true, searchResults, isSearchApiCalled: true })
  }

  handleApiErrorResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.searchApiCallId) {
      this.disableLoading()
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  handleApiSuccessResponse = (apiRequestCallId: string, responseJson: ISearchResultResponse) => {
    if (apiRequestCallId === this.searchApiCallId) {
      if ("farms" in responseJson) {
        this.handleSearchResult(responseJson)
      }
      this.disableLoading()
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        this.handleApiSuccessResponse(apiRequestCallId, responseJson)
      } else {
        if (await isTokenExpired(responseJson)) {
          return
        }
        this.handleApiErrorResponse(apiRequestCallId)
      }
    }
  }
}
// Customizable Area End
