// Customizable Area Start
import React from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import SearchFarmsController from "./SearchFarmsController.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import SearchResultFilters from "../SearchResultFilters.web"
import FarmsSectionsImgCard from "../../../../../components/src/FarmsSectionsImgCard.web"
import { ISearchFarm } from "../../interfaces/search"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import FarmHeadingWithFilterInput from "../../../../info-page/src/Farm/FarmHeadingWithFilterInput.web"
import SearchCustomLayout from "../SearchCustomLayout.web"
import { styles } from "../commonStyles"
import AdCampaignWrapper from "../../../../LocalListingAds/src/AdCampaignWrapper.web"
const configJSON = require("../../config.js")

export class SearchFarms extends SearchFarmsController {
  render() {
    const {
      loader,
      categoriesLoader,
      priceRangeLoader,
      tagsLoader,
      categories,
      minPrice,
      maxPrice,
      tags,
      farms,
      totalPages,
    } = this.state
    const isFiltersLoading = categoriesLoader || priceRangeLoader || tagsLoader

    return (
      <>
        <Container fixed>
          <Box style={styles.mainWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <SearchResultFilters
                  categoryTitle={configJSON.relatedCategoriesText}
                  categories={categories}
                  isToShowPriceFilter={true}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  isToShowNearByFilter={true}
                  nearByText={configJSON.nearByFarmsText}
                  tags={tags}
                  isLoading={isFiltersLoading}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <FarmHeadingWithFilterInput heading={configJSON.farmsText} />
                <SearchCustomLayout
                  loader={loader}
                  totalPages={totalPages}
                  listLength={farms.length}
                  emptyMessage={configJSON.noFarmsFoundText}
                >
                  <Grid container spacing={3}>
                    {farms.map((farm: ISearchFarm) => {
                      const {
                        id,
                        attributes: {
                          name,
                          description,
                          images,
                          banner,
                          is_ad,
                          average_rating,
                          third_party_ad_url,
                          advertisement_banner
                        },
                      } = farm
                      const redirectUrl = `${AppRoutings.FarmDetails}/${id}`
                      const imageUrl = images ? images.url : undefined
                      const adImage = banner ? banner.url : imageUrl
                      const thirdPartyImageUrl = advertisement_banner
                        ? advertisement_banner.url
                        : undefined

                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                          <AdCampaignWrapper
                            isAd={is_ad}
                            thirdPartyImageUrl={thirdPartyImageUrl}
                            thirdPartyUrl={third_party_ad_url}
                          >
                            <FarmsSectionsImgCard
                              cardName={name}
                              description={description}
                              thumbnail={adImage}
                              displayPrice={configJSON.noneText}
                              displayButton={configJSON.blockText}
                              displayDuration={configJSON.noneText}
                              displayRating={configJSON.blockText}
                              average_rating={average_rating}
                              boxShadow={false}
                              path={redirectUrl}
                              imgPath={redirectUrl}
                            />
                          </AdCampaignWrapper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </SearchCustomLayout>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withRouter(SearchFarms)
// Customizable Area End
