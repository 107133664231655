// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
import { toast } from "react-toastify";
import StorageProvider from "../../../framework/src/StorageProvider.web";


export interface Props {
    navigation:any;
    id:string;
    history?: any;
    location?:any
    updateActiveTab?: (activeTab: string) => void
    heading : any
    partNum : any
    partName : any
    lessonNum : any
    courseDetail : any
    isOwner : boolean
    searchDiscText : string
}

interface S {
    discussion : any
    firstName : string
    lastName : string
    isEdit: boolean
    isReply : boolean
    discComment : string
    lessonId : string
    comment : string
    commentId : string
    replyText : string
    data1 : any
}

interface SS {
    id : any
}

export default class LearningDiscussionController extends BlockComponent<
Props,
S,
SS
>{
    getDiscussionsCallId : any
    delDiscussionCallId : any
    setCommDiscCallId : any
    updateCommDiscCallId : any
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            discussion : [],
            firstName : '',
            lastName : '',
            isEdit : false,
            isReply : false,
            discComment : '',
            lessonId : '',
            comment : '',
            commentId : '',
            replyText : '',
            data1 : []
        }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
        //---------------------
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        const firstName = await StorageProvider.get("firstname")
        const lastName = await StorageProvider.get("lastname")
        this.setState({firstName : firstName, lastName : lastName})

        if(id){
            this.getDiscussions(id)
            this.setState({lessonId : id})
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
      const {searchDiscText} = this.props;
      if (prevProps.searchDiscText !== searchDiscText){
        this.SearchDebounceUpdate(
          searchDiscText
        );
      }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const data = responseJson && !(responseJson.error || responseJson.errors)
    
          
          if (data && apiRequestCallId != null && apiRequestCallId === this.getDiscussionsCallId ) {
            const data1 = responseJson.data.filter((i: any) => i.attributes.first_name === this.state.firstName)
      .map((i: any) => i)
            this.setState({discussion : responseJson.data, data1 : data1})
          }

          if (data && apiRequestCallId != null && apiRequestCallId === this.delDiscussionCallId ) {
            toast.success("Discussion deleted successfully")
            this.getDiscussions(this.state.lessonId)
          }

          if (data && apiRequestCallId != null && (apiRequestCallId === this.setCommDiscCallId )) {
            this.setState({discComment : '', comment: '', isEdit: false, isReply: false})
            this.getDiscussions(this.state.lessonId)
          }

          if (data && apiRequestCallId != null && (apiRequestCallId ===  this.updateCommDiscCallId)) {
            this.setState({ comment: '', isEdit: false, commentId: ''})
            this.getDiscussions(this.state.lessonId)
          }
        }
    }

    getDiscussions = async(id:string) => {
        const token = await StorageProvider.get("authToken");
        const header = {
          "Content-type": 'application/json',
          token : token
        };
        const discussionMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getDiscussionsCallId = discussionMessage.messageId;
    
        discussionMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/discussions?course_sub_section_id=${this.state.lessonId}&search=${this.props.searchDiscText}`
        );
        discussionMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        discussionMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(discussionMessage.id, discussionMessage);
    }

    delDiscussion = async(id:any) => {
        const token = await StorageProvider.get("authToken");
        const header = {
          "Content-type": 'application/json',
          token : token
        };
        const delDiscMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.delDiscussionCallId = delDiscMessage.messageId;
    
        delDiscMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/discussions/${id}`
        );
        delDiscMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        delDiscMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "DELETE"
        );
    
        runEngine.sendMessage(delDiscMessage.id, delDiscMessage);
    }

    createDiscussion = async(type:any,comment:any, id:any) => {
        const token = await StorageProvider.get("authToken");
        const header = {
          "Content-type": 'application/json',
          token : token
        };
    
        const httpBody = {
            "comment": comment,
            "course_sub_section_id" : id,
            "discussion_type" : type
        }
        const createDiscussionMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.setCommDiscCallId = createDiscussionMessage.messageId;
    
        createDiscussionMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/discussions`
        );
        createDiscussionMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        createDiscussionMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        createDiscussionMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        runEngine.sendMessage(createDiscussionMessage.id, createDiscussionMessage);
    }

    updateDiscussion = async(comment:any, id:any,sub_section_id:any) => {
      const token = await StorageProvider.get("authToken");
      const header = {
        "Content-type": 'application/json',
        token : token
      };
  
      const httpBody = {
          "comment": comment,
          "course_sub_section_id" : sub_section_id
      }
      const updateDiscussionMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateCommDiscCallId = updateDiscussionMessage.messageId;
  
      updateDiscussionMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library/discussions/${id}`
      );
      updateDiscussionMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      updateDiscussionMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      updateDiscussionMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(updateDiscussionMessage.id, updateDiscussionMessage);
  }


    handleDelDiscussion = async (id:any) => {
        this.delDiscussion(id) 
    }

    handleEditDiscussion = (id:any) => {
        this.setState({isEdit:id, commentId : id})
    }

    handleSaveEditDiscussion = (id:any, comment: any, sub_section_id:any) => {
      this.updateDiscussion(comment,id,sub_section_id)
    }

    handleDiscComment = async () => {
      this.createDiscussion("discussion",this.state.discComment,this.state.lessonId)
    }

    handleReplyButton = (id:any) => {
      this.setState({isReply : !this.state.isReply, commentId : id})
    }

    handleSaveReply = (type:any,text:any,id:any) => {
      this.createDiscussion(type,text,id)
    }

    handleComment = (e:any) => {
      this.setState({ discComment: e?.target.value })
    }

    SearchDebounce = (call: any, delay: any) => {
      let timer: any;
      return function (...args: any) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          call(...args);
        }, delay);
      };
    }
  
    SearchDebounceUpdate = this.SearchDebounce(this.getDiscussions,
      1000)
}

// Customizable Area End