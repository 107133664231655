// Customizable Area Start
import React from "react"
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Menu,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded"
import clsx from "clsx"
import { withRouter } from "react-router-dom"
import AdminAdCampaignListController, {
  Props,
  IStatusList,
} from "./AdminAdCampaignListController.web"
import CustomPagination from "../../../components/src/CustomPagination.web"
import { CampaignStatusEnum } from "./AdCampaignEnums.web"
const configJSON = require("./config.js")

const DISABLED_OPTIONS = [
  CampaignStatusEnum.Scheduled,
  CampaignStatusEnum.Expired,
]

export class AdminAdCampaignList extends AdminAdCampaignListController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    const {
      searchValue,
      anchorEl,
      isLoading,
      selectedIds,
      adCampaignList,
      currentPage,
      perPage,
      totalPages,
      totalRecords,
    } = this.state
    const adCampaignLength = adCampaignList.length
    const selectedIdsLength = selectedIds.length
    const firstRecordNumber = (currentPage - 1) * perPage + 1
    const lastRecordNumber = Math.min(currentPage * perPage, totalRecords)

    return (
      <Box className={classes.mainWrapper}>
        <Box>
          <Typography variant="h4" className={classes.mainHeading}>
            {configJSON.adCampaignLabel}
          </Typography>
          <Box className={classes.searchCreateWrapper}>
            <TextField
              value={searchValue}
              variant="outlined"
              className={classes.searchField}
              onChange={this.handleSearchValueChange}
              placeholder={configJSON.adCampaignSearchPlaceholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              displayEmpty
              disableUnderline
              renderValue={() => "Actions"}
              data-testid="bulk-status-dropdown"
              value=""
              disabled={selectedIdsLength === 0}
              onChange={this.handleBulkStatusUpdate}
              IconComponent={ExpandMoreRoundedIcon}
              className={classes.batchActionsSelect}
              MenuProps={{ ...configJSON.MenuProps }}
            >
              <MenuItem value="pause">{configJSON.pauseText}</MenuItem>
            </Select>
            <Button
              className={classes.createCampaignButton}
              onClick={this.handleCreateCampaign}
            >
              {configJSON.createCampaignButtonText}
            </Button>
          </Box>
        </Box>
        <Box className={classes.campaignListWrapper}>
          {isLoading ? (
            <Box className={classes.centerContentWrapper}>
              <CircularProgress size={22} />
            </Box>
          ) : (
            <>
              {adCampaignLength > 0 ? (
                <>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell padding="checkbox">
                              <FormControlLabel
                                label=""
                                checked={adCampaignLength > 0 && selectedIdsLength === adCampaignLength}
                                className={classes.headCheckbox}
                                data-testid="all-record-checkbox"
                                control={
                                  <Checkbox
                                    indeterminate={
                                      selectedIdsLength > 0 && selectedIdsLength < adCampaignLength
                                    }
                                  />
                                }
                                onChange={this.handleSelectAllClick}
                              />
                          </TableCell>
                          {configJSON.AD_CAMPAIGN_COLUMNS.map(
                            (column: string) => (
                              <TableCell align="center" key={column}>
                                {column}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adCampaignList.map((adCampaignDetails) => {
                          const {
                            id,
                            attributes: {
                              name,
                              campaign_ad_status,
                              total_cost,
                            },
                          } = adCampaignDetails
                          const isSelected = selectedIds.indexOf(id) !== -1
                          const isStatusDisabled = DISABLED_OPTIONS.includes(
                            campaign_ad_status
                          )

                          return (
                            <TableRow
                              hover
                              key={id}
                              role="checkbox"
                              tabIndex={-1}
                              className={classes.tableRow}
                            >
                              <TableCell align="center" padding="checkbox">
                                <Checkbox
                                  checked={isSelected}
                                  disabled={isStatusDisabled}
                                  data-testid={`ad-row-${id}`}
                                  onClick={() => this.handleClick(id)}
                                />
                              </TableCell>
                              <TableCell
                                data-testid={`ad-name-${id}`}
                                align="center"
                              >
                                {name}
                              </TableCell>
                              <TableCell align="center">{id}</TableCell>
                              <TableCell align="center">
                                <Select
                                  value={campaign_ad_status}
                                  variant="filled"
                                  disableUnderline
                                  disabled={isStatusDisabled}
                                  onChange={(e) =>
                                    this.handleChangeAdCampaignStatus(e, id)
                                  }
                                  className={clsx(classes.statusDropdown, {
                                    [classes.activeStatus]:
                                      campaign_ad_status ===
                                      CampaignStatusEnum.Active,
                                    [classes.pauseStatus]:
                                      campaign_ad_status ===
                                      CampaignStatusEnum.Pause,
                                  })}
                                  inputProps={{
                                    "data-testid": "status-dropdown"
                                  }}
                                  IconComponent={ExpandMoreRoundedIcon}
                                  MenuProps={{
                                    ...configJSON.MenuProps,
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                  }}
                                >
                                  {configJSON.adCampaignStatus.map(
                                    (status: IStatusList) => {
                                      const { label, value, className } = status
                                      const isHidden = DISABLED_OPTIONS.includes(
                                        value as CampaignStatusEnum
                                      )

                                      return (
                                        <MenuItem
                                          key={value}
                                          value={value}
                                          hidden={isHidden}
                                          className={classes[className]}
                                        >
                                          {label}
                                        </MenuItem>
                                      )
                                    }
                                  )}
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                ${total_cost}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  size="small"
                                  className={classes.moreIcon}
                                  data-testid={`ad-menu-${id}`}
                                  onClick={(e) =>
                                    this.handleMenuOpen(e, adCampaignDetails)
                                  }
                                >
                                  <MoreHorizRoundedIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    data-testid="pagination"
                    className={classes.paginationWrapper}
                  >
                    <Box>
                      {firstRecordNumber}-{lastRecordNumber} {configJSON.ofText}{" "}
                      {totalRecords} {configJSON.itemsText}
                    </Box>
                    <CustomPagination
                      shape="rounded"
                      siblingCount={1}
                      boundaryCount={1}
                      count={totalPages}
                      page={currentPage}
                      pagePerView={perPage}
                      handlePageChange={this.handleChangePageNumber}
                      increasePagePerView={this.handleIncreasePageView}
                      decreasePagePerView={this.handleDecreasePageView}
                    />
                  </Box>
                </>
              ) : (
                <Box className={classes.centerContentWrapper}>
                  <Typography>
                    {configJSON.noAdCampaignsFoundMessage}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          MenuListProps={{
            classes: {
              root: classes.actionMenu,
            },
          }}
        >
          <MenuItem onClick={this.handleEditClick}>
            {configJSON.editCampaignMenu}
          </MenuItem>
          <MenuItem onClick={this.handleDuplicateClick}>
            {configJSON.duplicateCampaignMenu}
          </MenuItem>
        </Menu>
      </Box>
    )
  }
}

export const adCampaignListStyles = () =>
  createStyles({
    mainWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
    },
    mainHeading: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#394053",
      padding: "25px 20px 20px",
      fontFamily: "'Montserrat', sans-serif",
      borderBottom: "1px solid #e2e2ea",
    },
    searchCreateWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "15px",
      padding: "20px",
      "@media screen and (max-width: 768px)": {
        flexWrap: "wrap"
      }
    },
    searchField: {
      border: "1px solid #e2e2e2",
      borderRadius: "10px",
      fontFamily: "'Montserrat', sans-serif",
      "& .MuiOutlinedInput-input": {
        padding: "9px 14px 9px 2px",
      },
      "& svg": {
        color: "#92929d",
      },
    },
    createCampaignButton: {
      backgroundColor: "#20a258",
      borderRadius: "8px",
      textTransform: "none",
      minWidth: "170px",
      "& span": {
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: "#20a258",
        opacity: 0.9,
      },
    },
    campaignListWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: "#fff",
    },
    tableContainer: {
      flex: 1,
    },
    tableHead: {
      backgroundColor: "#e3eaf7",
      "& th": {
        padding: "6px 15px",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "11px",
        fontWeight: 600,
        letterSpacing: "0.8px",
        color: "#44444f",
      },
    },
    headCheckbox: {
      margin: 0,
      "& span:nth-child(1)": {
        padding: "2px !important",
      },
    },
    tableRow: {
      "& td": {
        borderBottom: "1px solid #e2e2ea !important",
        padding: "20px 15px",
        fontFamily: "Roboto",
        fontSize: "15px",
        lineHeight: 1.5,
        letterSpacing: "0.11px",
        color: "#44444f",
        "& .MuiCheckbox-root": {
          padding: "0px !important",
        },
      },
    },
    statusDropdown: {
      borderRadius: "4px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: 1.63,
      color: "#4f4f4f",
      border: "1px solid #4f4f4f",
      "& svg": {
        color: "#4f4f4f",
      },
      "& .MuiFilledInput-input": {
        padding: "4px 34px 4px 14px",
      },
    },
    activeStatus: {
      border: "1px solid #20a258 !important",
      color: "#20a258",
      backgroundColor: "#edf8f2",
      "& svg": {
        color: "#20a258",
      },
    },
    pauseStatus: {
      border: "1px solid #d98f08 !important",
      color: "#d98f08",
      backgroundColor: "#d98f0814 !important",
      "& svg": {
        color: "#d98f08",
      },
    },
    deActiveStatus: {
      color: "#fc4b4e",
      border: "1px solid #fc4b4e !important",
      backgroundColor: "rgba(252, 75, 78, 0.08)",
      "& svg": {
        color: "#fc4b4e",
      },
    },
    itemStatus: {
      marginTop: "2px",
      borderRadius: "4px",
      backgroundColor: "#edf8f2",
      border: "1px solid #edf8f2",
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
      },
    },
    scheduledMenu: {
      color: "#808080",
    },
    activeMenu: {
      color: "#20a258",
    },
    pauseMenu: {
      color: "#d98f08",
    },
    expiredMenu: {
      color: "#fc4b4e",
    },
    paginationWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 15px",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: "#44444f",
    },
    moreIcon: {
      "& svg": {
        color: "#92929d",
      },
    },
    centerContentWrapper: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "200px",
      backgroundColor: "#fff",
      "& p": {
        color: "#979797",
        fontFamily: "'Montserrat', sans-serif",
      },
      "& svg": {
        color: "#20a258",
      },
    },
    actionMenu: {
      marginTop: "2px",
      borderRadius: "8px",
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
      },
    },
    batchActionsSelect: {
      border: "1px solid #e2e2e2",
      borderRadius: "8px !important",
      "& .MuiInputBase-input": {
        fontFamily: "'Montserrat', sans-serif",
        padding: "9px 26px 9px 12px !important",
      }
    },
  })

export default withStyles(adCampaignListStyles)(withRouter(AdminAdCampaignList))
// Customizable Area End
