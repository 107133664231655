export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const green_leafy_veggies = require("../assets/images/green_leafy_veggies.png");
export const DeleteWhiteIcon = require("../assets/images/delete-white-icon.png");
export const EmptyCartSvg = require("../assets/images/empty-cart.svg");

export const {
    getCartItems,
    updateActiveTab,
    getAddressListAction,
    handleCartLoader,
    removeCartItem,
    updateItemQuantity,
    setCheckoutOrder,
    resetCheckoutOrder,
    setCartItems,
    setCartBadge,
    setCartOrderMeta,
    resetCartOrderMeta
} = require('../../../components/src/redux/Users/userActions')