// Customizable Area Start
import React from "react";
import { withRouter } from 'react-router-dom'
import { Grid, Container, Box, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import WebFooter from "../../../../components/src/WebFooter.web";
import GreenBanner from "../../../../components/src/GreenBanner.web";
import "../assets/css/restaurantlandingpage.scss";
import {
  getRestaurantsPopularCategories,
  getRestaurantsListAction,
  Bubbles,
  Rings,
  updateActiveTab,
  setRLPData,
  resetRestaurantFilters
} from "../assets";
import RestaurantLandingPageController, {
  Props,
} from "./RestaurantLandingPageController.web";
import RLPTopBanner from "./RestaurantLandingPage/RLPTopBanner.web"
import RLPPopularCategories from "./RestaurantLandingPage/RLPPopularCategories.web"
import RLPRestaurantsList from "./RestaurantLandingPage/RLPRestaurantsList.web"
import RLPSubmitRestaurant from "./RestaurantLandingPage/RLPSubmitRestaurant.web"
// Customizable Area End


class RestaurantLandingPageWeb extends RestaurantLandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    // Customizable Area Start
    const {
      loader
    } = this.state

    return (
      <>
        {loader ?
          <Box className="restaurant_landing_page_loader">
            <CircularProgress size={40} />
          </Box>
          :
          <>
            <RLPTopBanner />
            <Box className="restaurant_popular_categories_section">
              <Container>
                <RLPPopularCategories />

                <RLPRestaurantsList />

                <Grid container spacing={3}>
                  <Grid item xs={12} />
                </Grid>

                <RLPSubmitRestaurant />
              </Container>
              <img src={Bubbles} alt="bubbles" className="submit_restaurant_bubble" />
              <img src={Rings} alt="rings" className="submit_restaurant_rings" />
            </Box>
          </>
        }
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    RLPTagLine: state.cms.RLPTagLine
  }
};

export default withRouter(
  connect(mapStateToProps, {
    getRestaurantsPopularCategories,
    getRestaurantsListAction,
    updateActiveTab,
    setRLPData,
    resetRestaurantFilters
  })(RestaurantLandingPageWeb)
)
// Customizable Area End
