//Image
export const Apple_Icon = require("../assets/images/apple-icon.png");

export const {
  updateActiveTab,
  resetUserDetails,
  setCartBadge,
} = require("../../../components/src/redux/Users/userActions");

export const {
  setSearchValueForRestaurant,
} = require("../../../components/src/redux/Restaurant/RestaurantActions");
