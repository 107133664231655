// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import {isTokenExpired} from "../../../components/src/utility/helper"

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  classes?: any;
  logout: any;
  values: {
    banner: {
      name: string;
    };
  };
  setFieldValue: any;
}

interface S {
  loading: boolean;
  cardIndex: number;
  restStepperIndex: number;
  modalOpen: boolean;
  restOrderModal: boolean;
  restOrderReportModal: boolean;
  seletedForum: any[];
  forumsPerPage: any;
  forumsCurrentPage: any;
  farmsList: any;
  forumsList: any;
  currentFarmId: any;
  search: any;
  openDialog: any;
  removedProduct: any;
  bulkAction: any;
  productMode: any;
  forumDetails: any;
  replyId: any;
  replyEditId: any;
  headingWordCount: any;
  total_count: any
  total_pages: any

}

interface SS {
  id: any;
}

export default class AdminFarmForumsController extends BlockComponent<
  Props,
  S,
  SS
> {

  getProductApiCallId: any;
  lineChart: any;
  getDropdownApiCallId: string = "";
  getForumsListApiCallId: string = "";
  updateForumStatusApiCallId: string = "";
  deleteForumApiCallId: string = "";
  bulkActionsForumApiCallId: string = "";
  createForumApiCallId: string = "";
  getForumDetailsApiCallId: string = ""
  deleteReviewApiCallId: string = ""
  addReplyApiCallId: string = "";
  deleteReplyReviewApiCallId: string = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      cardIndex: 0,
      restStepperIndex: 0,
      modalOpen: false,
      restOrderModal: false,
      restOrderReportModal: false,
      seletedForum: [],
      total_count: 0,
      total_pages: 0,
      forumsPerPage: 5,
      forumsCurrentPage: 1,
      farmsList: "",
      forumsList: [],
      currentFarmId: "",
      search: "",
      openDialog: false,
      removedProduct: "",
      bulkAction: "actions",
      productMode: "",
      forumDetails: "",
      replyId: "",
      replyEditId: "",
      headingWordCount: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode");
    this.setState({
      productMode: mode,
    });

    if (window.location.pathname.split("/")[3] == "forums") {
      this.getFarms();
    }
    if (mode == "edit") {
      this.getForumDetails(query.get("forum_id"));
    }
  }

  async componentDidUpdate() {
    if (this.state.currentFarmId) {
      if (window.location.pathname.split("/")[4] != this.state.currentFarmId) {
        const path = window.location.pathname.split("/")[4];
        this.setState({
          currentFarmId: path,
        });
      }
    }
  }


  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {

          this.successApiCall(responseJson, apiRequestCallId)
        }
      } else if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        }
      }
    }

  }


  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    //get farms
    if (apiRequestCallId === this.getDropdownApiCallId) {
      const farmId: any = window.location.pathname.split("/")[4];
      const pathFarmId = this.pathFarmIdFinder(responseJson, farmId)

      this.setState({
        farmsList: responseJson.data,
        currentFarmId: pathFarmId,
      });
      this.props.history.push(`/admin/farm/forums/${pathFarmId}`);
      pathFarmId != 0 &&
        this.getForumsList(pathFarmId, this.state.search, this.state.forumsPerPage, this.state.forumsCurrentPage);
      this.setState({
        loading: false,
      });
    }

    //get Forums list
    if (apiRequestCallId === this.getForumsListApiCallId) {
      this.setState({
        forumsList: responseJson.data,
        total_pages: responseJson.meta.pagination.total_pages,
        total_count: responseJson.meta.pagination.total_count,
        loading: false,
        seletedForum: [],
      });
    }
    //update forum status
    if (apiRequestCallId === this.updateForumStatusApiCallId) {
      const path = window.location.pathname.split("/")[4];
      this.getForumsList(path, this.state.search, this.state.forumsPerPage, this.state.forumsCurrentPage);
      this.setState({
        loading: false,
      });
      toast.success(`Forums Status Updated Successfully `);

    }

    //delete forum
    if (apiRequestCallId === this.deleteForumApiCallId) {
      const path = window.location.pathname.split("/")[4];
      const mode = this.state.productMode == "edit"
      !mode && this.getForumsList(path, this.state.search, this.state.forumsPerPage, this.state.forumsCurrentPage);
      this.setState({
        loading: false,
      });
      toast.success(`Forum Removed Successfully `);
      mode && this.props.history.goBack()
    }

    // forum bulk actions
    if (apiRequestCallId === this.bulkActionsForumApiCallId) {
      const path = window.location.pathname.split("/")[4];
      this.getForumsList(path, this.state.search, this.state.forumsPerPage, this.state.forumsCurrentPage);
      this.setState({
        // bulkAction: "",
        seletedForum: [],
        loading: false,
      });
      toast.success(`Forum Status Updated Successfully `);
    }
    // createForumApiCallId
    if (apiRequestCallId === this.createForumApiCallId) {
      const mode = this.state.productMode == "create"
      toast.success(`Forum ${mode ? "Added" : "Updated"} Successfully`)
      this.setState({
        loading: false,
      });
      this.props.history.goBack()
    }

    // get forum details

    if (apiRequestCallId === this.getForumDetailsApiCallId) {
      this.setState({
        forumDetails: responseJson.data,
        loading: false,
      });

    }
    // delete review
    if (apiRequestCallId === this.deleteReviewApiCallId) {
      const query = this.getQuery()
      this.setState({
        forumDetails: responseJson.data,
        // loading: false,
      });
      toast.success(`Review Deleted Successfully `);
      this.getForumDetails(query.get("forum_id"));

    }
    // add reply 

    if (apiRequestCallId === this.addReplyApiCallId) {
      const query = this.getQuery()
      this.setState({
        forumDetails: responseJson.data,
        //  loading: false,
      });
      toast.success(`Reply Added Successfully `);
      this.getForumDetails(query.get("forum_id"));


    }

    //deleteReplyReviewApiCallId
    if (apiRequestCallId === this.deleteReplyReviewApiCallId) {
      const query = this.getQuery()
      this.setState({
        forumDetails: responseJson.data,
        // loading: false,
      });
      toast.success(`Reply Deleted Successfully `);
      this.getForumDetails(query.get("forum_id"));

    }

  }

  pathFarmIdFinder = (responseJson: any, farmId: any) => {
    if (farmId == "00") {
      return responseJson.data.length > 0
        ? responseJson.data[0].id
        : "0"
    } else {
      return window.location.pathname.split("/")[4]
    }
  }
  /// get farms
  getFarms = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropdownApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Forums list
  getForumsList = async (id: any, search: any, per: any, page: any) => {
    this.setState({
      loading: true,
    });
    const endpoint = `forum[forum_type]=Farm&farm_id=${id}&search=${search}&per=${per}&page=${page}`;
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getForumsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getForumsListAPiEndPoint + `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //updating status
  updateForumStatus = async (id: any, status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      forum: {
        id: id,
        status: status,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateForumStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateForumStatusAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //remove product
  deleteForum = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateForumStatusAPiEndPoint + `${this.state.removedProduct}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //bulk actions
  bulkActions = async (status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status: status,
      ids: this.state.seletedForum,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsAPiEndPoint
      // + `/${window.location.pathname.split("/")[4]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //create forum
  createForum = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      token: token,
    };
    const mode = this.state.productMode == "create"
    const endPoint = mode ? configJSON.createForumAPiEndPoint : (configJSON.createForumAPiEndPoint + `/${this.state.forumDetails.id}`)
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("forum[heading]", values.heading);
    formdata.append("forum[description]", values.description);
    formdata.append("forum[status]", values.status);
    values.banner[0] && formdata.append("forum[banner]", values.banner[0],
      values.banner[0].name)
    formdata.append("forum[forum_type]", values.forumable_type);
    formdata.append("forum[forumable_id]", values.forumable_id);
    formdata.append("forum[forumable_type]", "BxBlockCatalogue::Farm");


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Forum details
  getForumDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getForumDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getForumDetailsAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //delete review
  deleteReview = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteReviewAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //add reply
  addReply = async (reply: any, id: any, mode: any) => {
    this.setState({
      loading: true,
    });
    const token = await StorageProvider.get("authToken");
    const isCreate = (mode == "create") ? true : false
    const EndPoint = configJSON.addReplyAPiEndPoint + (!isCreate ? `/${id}` : "")
    const method = isCreate ? "POST" : "PUT"

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      "description": reply,
      ...(isCreate && { "review_id": id })
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReplyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      EndPoint

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //delete REply review
  deleteReplyReview = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteReplyReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReplyAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleClickForumRow = (event: React.MouseEvent<unknown>, num: any) => {
    event.preventDefault();
    const selectedIndex = this.state.seletedForum.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.seletedForum, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.seletedForum.slice(1));
    } else if (selectedIndex === this.state.seletedForum.length - 1) {
      newSelected = newSelected.concat(this.state.seletedForum.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.seletedForum.slice(0, selectedIndex),
        this.state.seletedForum.slice(selectedIndex + 1)
      );
    }
    console.log(newSelected, "newSelected");
    this.setState({ seletedForum: newSelected });
  };

  isSelectedForum = (num: any) => this.state.seletedForum.indexOf(num) !== -1;

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id,
    });
    if (value == "edit") {
      this.props.history.push(
        `/admin/farm/add-forum/${window.location.pathname.split("/")[4]
        }?mode=edit&forum_id=${item.id}`
      );
    }
    if (value == "remove") {
      this.handleDialog();
    }
  };

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  }

  handleReviewReply = (id: any, setFieldValue: any) => {
    this.setState({
      replyId: id
    })
    setFieldValue("reply", "")
  }

  handleReplyEditId = (id: any, setFieldValue: any) => {
    this.setState({
      replyEditId: id
    })
  }

  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return (query)

  }

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getForumsList,
    1000)

  handleHeadingChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const words = e.target.value?.trim()?.split(" ")

    if (words.length < 26) {
      setFieldValue("heading", e.target.value)
    }
  }

  handleReply = (
    reviewId: string,
    reply: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    if (!!reply) {
      this.addReply(reply, reviewId, "create")
      this.setState({ replyId: "" })
      setFieldValue("reply", "")
    }
  }

  handleReplyEdit = (
    replyId: string,
    replyEdit: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    if (!!replyEdit) {
      this.addReply(replyEdit, replyId, "edit")
      this.setState({ replyEditId: "" })
      setFieldValue("replyEdit", "")
    }
  }

  getHeading = () => {
    return this.state.productMode === "create" ? "Create Forum " : "Edit Forum"
  }

  settingButtonText = () => {
    return this.state.productMode == "create"
      ? "Create"
      : "Save Changes"
  }
  settingLikes=(likes:any)=>{
   return likes || 0
  }
  handleDragOver=(e:any)=>{
    e.preventDefault();
    e.stopPropagation();
  }
  handleDragDrop=(e:any,setFieldValue:any)=>{
    e.preventDefault();
    e.stopPropagation();
    if(e.dataTransfer.files && e.dataTransfer.files[0]){
      setFieldValue("banner", e.dataTransfer.files);
     }
    }
    getMenuStyleCss=(status:any)=>{
      if(status=="active"){
        return ""
      }else if(status=="deactive"){
        return "_menus de_active_menu"
      }else{
        return "draft_menu"
      }
      }

}
// Customizable Area End
