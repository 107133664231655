import * as React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';


import '../assets/css/adminLearningPage1.scss'
import {
    Grid,
    Box,
    Divider
  } from "@material-ui/core";
import { Delete } from '@material-ui/icons';
import { fontSize } from '@mui/system';



class AdminLearningCourseList3 extends React.Component {
    render(){
        return(
            <>
             <Grid container>
                 <Grid xs={6}>
                     <Box sx={{m:'3.5rem 1rem'}}>
                       <h6>
                        Here is you add course content- like course section. Click a+ icon on the 
                        left to get started.
                       </h6>
                     </Box>
                     <Box sx={{border:'0.5px solid silver', ml:'1rem'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>  
                           <h4 style={{margin:'1rem 0 1.5rem 2rem',fontWeight:'bold',
                              color:'black' }}> PART1: Sample Part
                            </h4>
                           <Delete  className='delete'/>
                        </div>
                       <Box sx={{m:'2rem', border:'0.1px solid lightgreen'}} >
                            <Box sx={{m:'2rem'
                             }}>
                               <div style={{display:'flex', justifyContent:'space-between'}}>
                                  <h5 className='h5'> 1. Sample Module
                                    
                                   </h5>
                                
                                   <div>
                                      <h5 className='h5'> Edit content</h5>
                                   </div>
                                   <hr/>
                               </div> 

                               <p style={{fontSize:'17px', fontWeight:'bolder', color:'#7d7272',
                                     marginTop:'1rem'}}>                                
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                               </p>
                                
                            </Box>
                      </Box>
                      </Box>
                      <div style={{
                        border: '1px dotted black' ,
                        margin:'1rem',
                         height:'4rem', width:'80%'}}>
                           <h3 style={{display:'flex', justifyContent:'center', 
                             margin:'inherit', fontSize:'17px' }}> Add Part
                          </h3>
                      </div>
                 </Grid>
             </Grid>
            </>
        )
    }
}

export default AdminLearningCourseList3