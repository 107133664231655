import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End


export interface Props {
    // Customizable Area Start
    isOpen: boolean
    isForgotPassword?: boolean
    onClose: () => void
    onLoginSuccess?: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeTab: "login" | "register"
    isForgotPasswordScreen: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LoginSignUpDialogController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            activeTab: "login",
            isForgotPasswordScreen: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    loginTab = () => {
        this.setState({ activeTab: "login" })
      }
      
    registerTab = () => {
        this.setState({ activeTab: "register" })
    }

    openForgotPasswordScreen = () => {
        this.setState({ isForgotPasswordScreen: true })
    }

    closeForgotPasswordScreen = () => {
        this.setState({ isForgotPasswordScreen: false })
    }

    newOnClose = () => {
        this.setState({ isForgotPasswordScreen: false })
        this.props.onClose()
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                console.log("response", responseJson)
            }
        }

        // Customizable Area End
    }

}