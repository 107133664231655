// Customizable Area Start
import React, { useEffect, useState } from "react"
import { Grid, Box, Button, Typography } from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/ErrorOutlineRounded"
import MenuItemWeb from "./MenuItemWeb.web"

interface IMenu {
  id: string
  type: string
  attributes: {
    addon_types: any
    category_name: string
    compare_at_price: string
    description: string
    dish_number: string
    favourites: boolean
    images: {
      id: number
      filename: string
      url: string
    }[]
    is_vegetarian: boolean
    name: string
    price: string
    status: string
  }
}

interface IRestaurantDetailsMenuProps {
  restaurantMenu: IMenu[]
  isAccepting: boolean
  isDineInOnly: boolean
  handleActiveOrder: (
    id: string,
    price: string,
    compareAtPrice: string,
    image: string,
    dishName: string,
    description: string,
    addons: any
  ) => void
}

const RestaurantDetailsMenu = ({
  restaurantMenu,
  isAccepting,
  isDineInOnly,
  handleActiveOrder,
}: IRestaurantDetailsMenuProps) => {
  const [isMenuCollapse, setIsMenuCollapse] = useState(false)

  useEffect(() => {
    if (restaurantMenu?.length > 4) setIsMenuCollapse(true)
  }, [restaurantMenu])

  const toggleMenuCollapse = () => {
    setIsMenuCollapse((prevValue) => !prevValue)
  }

  const sortMenu = (menuList: IMenu[]) => {
    if (menuList?.length > 0) {
      return [...menuList]?.sort((a: IMenu, b: IMenu) => {
        const { category_name: categoryA } = a.attributes
        const { category_name: categoryB } = b.attributes

        if (categoryA < categoryB) return -1
        if (categoryA > categoryB) return 1
        return 0
      })
    }
    return []
  }

  const differentiateCategories = (list: IMenu[]) => {
    let categories: string[] = []
    const sortedList = sortMenu(list)

    const tempList = isMenuCollapse ? sortedList?.slice(0, 4) : sortedList

    tempList?.forEach((details: IMenu) => {
      const { category_name } = details.attributes
      if (!categories.includes(category_name)) categories.push(category_name)
    })

    return categories
  }

  const filterMenuList = (menuList: IMenu[], category: string) => {
    const sortedList = sortMenu(menuList)
    const tempList = isMenuCollapse ? sortedList?.slice(0, 4) : sortedList

    return tempList?.filter(
      (menu: IMenu) => menu.attributes.category_name === category
    )
  }

  const getInfoMessage = () => {
    if(isDineInOnly)
      return 'This restaurant is currently available for dine-in only'
    
    if(!isAccepting)
      return 'This restaurant is currently not accepting any orders'
    
    return ''
  }

  return (
    <>
      {restaurantMenu?.length > 0 && (
        <Grid item xs={12}>
          <div className="restaurant_details_menu_header">
            <p>Menu</p>
            {(!isAccepting || isDineInOnly) && (
              <Typography className="restaurant_close_desc">
                <ErrorIcon fontSize="small" />
                {getInfoMessage()}
              </Typography>
            )}
          </div>
        </Grid>
      )}

      {differentiateCategories(restaurantMenu)?.map((category: string) => {
        return (
          <Grid item container spacing={2} key={category}>
            <Grid item xs={12}>
              <div className="restaurant_details_menu_heading">
                <p>{category || "OTHER"}</p>
              </div>
            </Grid>
            {filterMenuList(restaurantMenu, category)?.map((menu: IMenu) => {
              const { id, attributes } = menu
              const {
                price,
                compare_at_price,
                name,
                description,
                images,
                addon_types,
                favourites,
              } = attributes
              const firstImage = images?.length > 0 ? images[0].url : ""

              return (
                <Grid item xs={12} key={id}>
                  <MenuItemWeb
                    menuId={id}
                    isDisable={isDineInOnly || !isAccepting}
                    price={price}
                    discountPrice={compare_at_price}
                    dishName={name}
                    dishDescription={description}
                    image={firstImage}
                    isFavorite={favourites}
                    addMenu={() =>
                      handleActiveOrder(
                        menu.id,
                        price,
                        compare_at_price,
                        firstImage,
                        name,
                        description,
                        addon_types
                      )
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        )
      })}

      {restaurantMenu?.length > 4 && (
        <Grid xs={12}>
          <Box className="res_details_more_btn_container">
            <Button onClick={() => toggleMenuCollapse()}>
              {isMenuCollapse ? "Load more" : "Show less"}
            </Button>
          </Box>
        </Grid>
      )}
    </>
  )
}

export default RestaurantDetailsMenu
// Customizable Area End
