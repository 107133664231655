// Customizable Area Start
import React, { useState, useEffect } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import { Flower, PicnicBasket, Plant, Farming } from "../../assets"
import { connect } from "react-redux"

interface IWIWTT {
    id: string
    type: string
    attributes: {
        type: "farmers market" | "restaurants" | "education"
        heading: string
        description: string
    }
}

interface IProps {
    WLLPSegmentHeading: string,
    WLLPWhatIsWtt: IWIWTT[]
}

const WIWTTImages: any = {
    0: PicnicBasket,
    1: Plant,
    2: Farming
}

const LandingPageWhatIsWTT = ({ WLLPSegmentHeading, WLLPWhatIsWtt }: IProps) => {
    const [segmentList, setSegmentList] = useState<IWIWTT[]>([])

    const customSort = (a: IWIWTT, b: IWIWTT) => {
        const typeOrder = { "farmers market": 1, "restaurants": 2, "education": 3 }
        const { attributes: { type: typeA } } = a
        const { attributes: { type: typeB } } = b
    
        return typeOrder[typeA] - typeOrder[typeB]
    }    

    useEffect(() => {
        const sortedData = [...WLLPWhatIsWtt].sort(customSort)
        setSegmentList(sortedData)
    }, [WLLPWhatIsWtt])

    return (
        <Box className="landing_info_first">
            <Container fixed>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <Typography
                            variant="body2"
                            align="center"
                            className="landing_question_heading"
                        >
                            {WLLPSegmentHeading}
                        </Typography>
                    </Grid>
                    {segmentList?.map((item: IWIWTT, index: number) => {
                        const {
                            id,
                            attributes: { heading, description }
                        } = item
                        const isEvenPosition = index % 2 !== 0
                        return (
                            <Grid item xs={12} sm={6} md={4} key={id}>
                                <Box
                                    className={`landing_info_boxes ${isEvenPosition ? "landing_info_boxes_center" : ""
                                        }`}
                                >
                                    {isEvenPosition && <div className="landing_box_bubble" />}
                                    <Box
                                        className="landing_info_boxes_image"
                                        justifyItems="center"
                                    >
                                        <img src={WIWTTImages[index]} alt="image" />
                                    </Box>
                                    <Typography variant="h5" align="center">
                                        {heading}
                                    </Typography>
                                    <Typography align="center">{description}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
            <img src={Flower} alt="flower" className="guest_landing_flower" />
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        WLLPSegmentHeading: state.cms.WLLPSegmentHeading,
        WLLPWhatIsWtt: state.cms.WLLPWhatIsWtt,
    }
}

export default connect(mapStateToProps, {})(LandingPageWhatIsWTT)
// Customizable Area End
