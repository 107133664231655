// Customizable Area Start
import React, { useState } from "react";
// common css
import "../../../web/src/assets/css/content/button.scss";
import "../../../web/src/assets/css/content/tab.scss";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminFarmlist.scss";
import { withRouter } from "react-router-dom";
import AdminRestaurantController, {
  Props,
} from "./AdminRestaurantController.web";
import { Box,Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import AdminRestaurantRegisterInfoWeb from "../../../blocks/catalogue/src/AdminRestaurantRegisterInfo.web";
import AdminRestaurantAvailability from "../../../blocks/catalogue/src/AdminRestauranAvailability.web";
import AdminRestaurantRegisterStepperWeb from "./AdminRestaurantRegisterStepper.web";
import { commonCmptStyles } from "./dropDownCss";
// Customizable Area End

class AdminRegisterRestaurant extends AdminRestaurantController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    return (
      <CustomRestRegisterTab data-testid="customTab"/> 
    );
    // Customizable Area End
  }
}



// Customizable Area Start
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomRestRegisterTab = (props: any) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };


  return (
    <div className="customTab restDetailTab" data-testid="tabCtn">
      <Tabs
      data-testid="tabs"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#20a258",
            bottom: "2px",
            height: "5px",
          }
        }}
      >
        <Tab label=" Restaurant Details " {...a11yProps(0)} disabled={value !== 0} />
        <Tab label=" Delivery Time Setting" {...a11yProps(1)} disabled={value !== 1}/>
        <Tab label=" Availability " {...a11yProps(2)} disabled={value !== 2}/>
      </Tabs>
      <TabPanel value={value} index={0} className="tabPanel" data-testid="tabPanel">
        <AdminRestaurantRegisterStepperWeb 
        //@ts-ignore
        handleTabChange={handleChange}/>
      </TabPanel>
      <TabPanel value={value} index={1}  className="tabPanel">
        <AdminRestaurantRegisterInfoWeb 
        //@ts-ignore
        handleTabChange={handleChange}/>
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <AdminRestaurantAvailability
        //@ts-ignore
        handleTabChange={handleChange}/>
      </TabPanel>

    </div>
  );
};
// Customizable Area End

// Customizable Area Start
export {AdminRegisterRestaurant}
//@ts-ignore
export default withRouter(
    //@ts-ignore
    withStyles(commonCmptStyles)(AdminRegisterRestaurant)
  );
// Customizable Area End