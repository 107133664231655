import React from "react";
import "./assets/css/farmsectionimgcard.scss";
import { Box, Button, CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { arrowImg } from "./assets";
import { Link } from "react-router-dom";
import Image from 'material-ui-image'
const configJSON = require('./config.js')

interface Props {
  cardName: string;
  thumbnail: string | undefined;
  displayPrice: string;
  displayButton: string;
  displayRating: string;
  displayDuration: string;
  boxShadow: boolean;
  path: string;
  imgPath: string;
  description?: string;
  average_rating? : number
  amount?: number | string
  discountPrice?: number | string
  duration?: string
  isNotRedirectable?: boolean
}

const FarmsSectionsImgCard = (props: Props) => {

  const capitalizeValue = (value: string) => {
    if (!value) return '';
    return `${value[0].toUpperCase()}${(value.slice(1))}`
  }

  const isDisableRedirect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.isNotRedirectable && e.preventDefault();
  }

  const isDiscount = props.discountPrice && Number(props.discountPrice) < Number(props.amount)

  return (
    <Box>
      <Box className={props.boxShadow ? "farm_card_box_shadow" : "farm_card"}>
        <Box className="farm_img_wrapper">
          <Link onClick={isDisableRedirect} to={props.imgPath}>
            <Image
              loading={<CircularProgress className="image_loader" size={25} />}
              animationDuration={1500}
              src={props.thumbnail || configJSON.errorText}
              alt="farmImg"
            />
          </Link>
        </Box>
        <Box className="farm_card_content">
          <h6>{capitalizeValue(props.cardName)}</h6>
          <p>
            {props.description}
          </p>
          <Box style={{ display: `${props.displayRating}` }}>
            <Rating
              className="StyledRating"
              classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
              name="customized-empty"
              defaultValue={props?.average_rating}
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </Box>
          <Box style={{ display: `${props.displayDuration}` }}>
            <p className="duration">{
              props?.duration
            }</p>
          </Box>
          <Box style={{ display: `${props.displayButton}` }}>
            <Link onClick={isDisableRedirect} to={props.path}>
              <Button
                className="read_more_btn"
                classes={{ label: "read_more_btn_color" }}
              >
                Read More{' '}
                <img src={arrowImg} alt="arrowImg" />
              </Button>
            </Link>
          </Box>
          <Box
            className="card_price_wrapper"
            style={{ display: `${props.displayPrice}` }}
          >
            <Box className="product_price_wrapper">
              <h5>${props.discountPrice}</h5>
              {isDiscount && (
                <h5 className="product_compare_at_price">{`$${props.amount}`}</h5>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FarmsSectionsImgCard;
