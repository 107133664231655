import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { Component } from 'react';
import '../src/assets/css/Webpagination.scss'

export default class WebPagination extends Component {
  constructor(props:any) {
      super(props);
  }
  render() {
    return (
    <div>
        <Box className="web_pagination_container">
           <Pagination count={20} variant="outlined" shape="rounded" classes={{
            root : "farm_pagination"
          }}/>
        </Box>
    </div>
    )
  }
}
