import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import * as moment from "moment";
import { extendMoment } from "moment-range";
import { Button, Menu, MenuItem, withStyles } from "@material-ui/core";
//images
import { CalendarIcon } from "./assets";
export const configJSON = require("./config.js");
import "../../web/src/assets/css/content/dateRangePicker.scss";

const moments = extendMoment(moment);
const styles = {
  dropDown: {
    border: 0,
    borderRadius: 10,
    width: "350px",
    height: "auto",
    padding: "0",
    overflow: "hidden",
    backgroundColor: "#20a258",
    "& .MuiMenuItem-root": {
      "&:hover": {
      }
    }
  },
  listItem: {
    height: "100%"
  }
}
const StyledMenuItem = withStyles(() => ({
  root: {
    "&.MuiListItem-button": {
      "&:hover": {

        backgroundColor: "unset"
      }
    },

  }
}))(MenuItem);

class CustomDateRangePicker extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const today = moments();

    this.state = {
      value: moments.range(today.clone().subtract(7, "days"), today.clone()),
      anchorEl: null
    };
  }

  onSelect: any = (value: any, states: any) => {
    this.setState({ value, states });
    this.props.setRange(this.state.value)
  };



  renderSelectionValue = () => {

    return (
      <div>
        {this.state.value.start.format("DD MMMM, YYYY")}
        {" - "}
        {this.state.value.end.format("DD MMMM, YYYY")}
      </div>
    );
  };



  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const today = moments();
    this.setState({ anchorEl: event.currentTarget})
    const start = this.props.startDate  
    const end = this.props.endDate 
    const isRangeSet = start && end
    if((this.props.today || this.props.isRestChanged) && !isRangeSet){
      this.setState({value: moments.range(today.clone().subtract(7, "days"), today.clone()) })
    }
  };

  handleClose = () => {

    this.setState({ anchorEl: null })

  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const { classes } = this.props
    return (

      <div className="pickerContainer">
        <div className="grayButton">
          <Button onClick={this.handleClick}>
            <span>{configJSON.selectDateLabel}</span>
            <img src={CalendarIcon} className="calendarIcon" alt="" />
          </Button>
        </div>
        <Menu
          id="fade-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={open}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            classes: classes.dropDown
          }}
        >
          <StyledMenuItem disableGutters={true} classes={classes.listItem}>
            <div className="dateRangePicker">
              <p className="text">
                <span>{configJSON.dateRangeLabel}</span>
                <span>
                  {this.renderSelectionValue()}
                </span>
              </p>
              <DateRangePicker
                value={this.state.value}
                onSelect={this.onSelect}
                singleDateRange={true}
              />

            </div>
          </StyledMenuItem>

        </Menu>
      </div>

    );
  }
}

export default withStyles(styles)(CustomDateRangePicker);


