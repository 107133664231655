//Customizable Area Start
import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import "./assets/css/webrouting.scss";
import { useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { ActiveTabEnum } from "./redux/Users/userEnums";
import { useSelector } from "react-redux";
//Customizable Area End

export const WebRoutingTab = () => {

  const [showToggleButton, setshowToggleButton] = useState("none");
  const webView = useMediaQuery("(min-width:960px)");
  const [toggle, setToggle] = useState(false);
  const activeTab = useSelector((state: {
    user: {
      activeTab: string
    }
  }) => state.user.activeTab)

  const onhandleClick = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  const getDisplay = () => {
    setshowToggleButton("block");
  };

  useEffect(() => {
    if (webView) {
      setshowToggleButton("none");
    } else {
      getDisplay();
    }
  }, [webView]);

  const isActive = (tabName: string) => {
    return activeTab === tabName
  }

  return (
    <>
      {/* <Grid container style={{ display: `${showToggleButton}` }} spacing={3}>
        <Grid item xs={12}>
          <Box className="toogle_button_wrapper">
            <Button onClick={() => onhandleClick()}>
              <ExpandMoreIcon />
            </Button>
          </Box>
        </Grid>
      </Grid> */}
      <Box className="routesWrapper">
        <Grid
          container
          className="web_routing_tabs_container"
        >
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className="web_routing_tab_wrapper"
          >
            <NavLink
              exact
              to="/"
              className={isActive(ActiveTabEnum.Home) ? 'active_route' : ''}
            >
              Home
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className="web_routing_tab_wrapper"
          >
            <NavLink
              exact
              to="/farms-market"
              className={isActive(ActiveTabEnum.Market) ? 'active_route' : ''}
            >
              Farmers Market
            </NavLink>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className="web_routing_tab_wrapper"
          >
            <NavLink
              exact
              to="/restaurant-landing-page"
              className={isActive(ActiveTabEnum.Restaurant) ? 'active_route' : ''}
            >
              Restaurants
            </NavLink>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className="web_routing_tab_wrapper"
          >
            <NavLink
              exact
              to="/learning"
              className={isActive(ActiveTabEnum.Learning) ? 'active_route' : ''}
            >
              Learning
            </NavLink>
          </Grid>

          <Grid
              item
              xs={12}
              sm={3}
              md={2}
              className="web_routing_tab_wrapper"
            >
              <NavLink
                exact
                to="/community"
                className={isActive(ActiveTabEnum.Community) ? 'active_route' : ''}
                >
                Community
              </NavLink>
            </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className="web_routing_tab_wrapper"
          >
            <NavLink
              exact
              to="/about-us"
              className={isActive(ActiveTabEnum.AboutUs) ? 'active_route' : ''}
            >
              About us
            </NavLink>
          </Grid>
        </Grid>
      </Box>
      <Box className="web_routing_tabs_sidebar">
        <Box
          className={`web_routing_tabs_sidebar_close_icon  ${"web_routing_tabs_sidebar_toggleSidebar"}`}
        >
          <CloseIcon />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <NavLink
              exact
              to="/"
              className={isActive(ActiveTabEnum.Home) ? 'active_route' : ''}
            >
              Home
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              exact
              to="/farms-market"
              className={isActive(ActiveTabEnum.Market) ? 'active_route' : ''}
            >
              Farmers Market
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              exact
              to="/restaurant-landing-page"
              className={isActive(ActiveTabEnum.Restaurant) ? 'active_route' : ''}
            >
              Restaurants
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              exact
              to="/learning"
              className={isActive(ActiveTabEnum.Learning) ? 'active_route' : ''}
            >
              Learning
            </NavLink>
          </Grid>
          <Grid item xs={12}>
                <NavLink
                  exact
                  to="/community"
                  className={isActive(ActiveTabEnum.Community) ? 'active_route' : ''}
                  >
                  Community
                </NavLink>
              </Grid>
          <Grid item xs={12}>
            <NavLink
              exact
              to="/about-us"
              className={isActive(ActiveTabEnum.AboutUs) ? 'active_route' : ''}
            >
              About us
            </NavLink>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WebRoutingTab;
