// Customizable Area Start
import React, { Component } from "react";
import { Typography, Box } from "@material-ui/core";
import { cardBg } from "../../assets";

interface ILPBottomSectionCard {
    by: string
    date: string
    heading: string
    image: string
}

class LPBottomSectionCard extends Component<ILPBottomSectionCard> {
    render() {
        const {
            by,
            date,
            image,
            heading
        } = this.props

        return (
            <>
                <Box className="bottom_section_card_wrapper">
                    <Box className="bottom_section_card_img_wrapper">
                        <img src={image ?? cardBg} alt="card image" />
                        <p>{date}</p>
                    </Box>
                    <Box className="bottom_section_card_details">
                        <p>By: {by}</p>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className="green_question_text"
                        >
                            {heading}
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }
}

export default LPBottomSectionCard;
// Customizable Area End
