import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import CustomButton from "./CustomButton.web";

interface ICustomActionDialogProps {
  open: boolean;
  title: string;
  closeBtnTitle?: string;
  actionBtnTitle: string;
  isLoading?: boolean;
  isActionDisabled?: boolean;
  isCloseDisabled?: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

const CustomActionDialog = ({
  open,
  title,
  closeBtnTitle = "No",
  actionBtnTitle,
  isLoading,
  isActionDisabled,
  isCloseDisabled,
  handleClose,
  handleAction,
}: ICustomActionDialogProps) => {

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogActions>
        <span className="redContained customCancelBtn">
          <CustomButton
            color="secondary"
            label={closeBtnTitle}
            disabled={isCloseDisabled}
            onClick={() => handleClose()}
          />
        </span>
        <span className="greenContained">
          <CustomButton
            label={actionBtnTitle}
            isLoading={isLoading}
            disabled={isActionDisabled}
            onClick={() => handleAction()}
            autoFocus
          />
        </span>
      </DialogActions>
    </Dialog>
  )
}

export default CustomActionDialog;
