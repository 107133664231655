// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import CommunityDiscussionCardController, {
    Props,
} from "./CommunityDiscussionCardController.web"
import { connect } from "react-redux"
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
} from "@material-ui/core"
import { CommentGreenIcon, FollowingIcon, FollowIcon } from "../../assets"
import "../../assets/css/community/communityDiscussionCard.scss"
import Highlighter from "react-highlight-words"
import CommunityDiscussionCardComments from "../CommunityCustomComponents/CommunityDiscussionCardComments.web"
const configJSON = require("../../config.js")

class CommunityDiscussionCard extends CommunityDiscussionCardController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            isTagsCard,
            searchedValue,
            heading,
            description,
            authorName,
            startedDate,
            commentCount,
            comments,
        } = this.props
        const { loader, isFollowing } = this.state
        const followBtnText = isFollowing ? "Following" : "Follow"

        return (
            <Box className="cdc_main_container">
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs={12}>
                        <Box
                            className="cdc_redirect_container"
                            onClick={this.handleDiscussionDetailsRedirect}
                        >
                            <Typography className="cdc_user_info">
                                {authorName} • Started {startedDate}
                            </Typography>
                            <Highlighter
                                    className="cdc_heading"
                                    searchWords={[searchedValue ?? ""]}
                                    textToHighlight={heading}
                            />
                        </Box>
                    </Grid>
                    {isTagsCard ? (
                        <Grid item xs={12}>
                            <Box className="cdc_description_wrapper">
                                <Highlighter
                                    className="cdc_description"
                                    searchWords={[searchedValue ?? ""]}
                                    textToHighlight={
                                        description.replaceAll(configJSON.HASH_TAG_REGEX, "") || ""
                                    }
                                />
                            </Box>
                            <Highlighter
                                className="cdc_tags_highlight"
                                searchWords={[searchedValue ?? ""]}
                                textToHighlight={
                                    description.match(configJSON.HASH_TAG_REGEX)?.join(" ") ?? ""
                                }
                            />
                        </Grid>
                    ) : (
                        <CommunityDiscussionCardComments comments={comments} />
                    )}
                    <Grid item xs={12}>
                        <Box className="cdc_bottom_container">
                            <Button
                                className="cdc_follow_btn"
                                startIcon={
                                    loader ? null : (
                                        <img
                                            src={isFollowing ? FollowingIcon : FollowIcon}
                                            className="cdc_follow_icon"
                                        />
                                    )
                                }
                                disabled={loader}
                                onClick={this.toggleFollowApi}
                            >
                                {loader ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    followBtnText
                                )}
                            </Button>
                            <Box className="cdc_comment_count_container">
                                <img src={CommentGreenIcon} alt="comment" />
                                <Typography>{commentCount}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

export default withRouter(connect(mapStateToProps, {})(CommunityDiscussionCard))
// Customizable Area End
