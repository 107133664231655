import * as React from 'react';

import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import "../../web/src/assets/css/content/accordian.scss";
export default function CustomAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : "");
  };

  return (
    <>
      <div className="customAccordian">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="AccordionSummary"
          >
            <Typography variant='h5' className='topTitle'>
              Topic 1
            </Typography>
            <Typography variant='h5' className='subTitle'>Welcome To The Fundamentals</Typography>
          </AccordionSummary>
          <AccordionDetails className="AccordionDetails">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
              atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem.
            </p>
            <h6>Highlight include:</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
              atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            className="AccordionSummary"
          >
            <Typography variant='h5' className='topTitle'>
              Topic 2
            </Typography>
            <Typography variant='h5' className='subTitle'>Lifestyle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
              varius pulvinar diam eros in elit. Pellentesque convallis laoreet
              laoreet.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            className="AccordionSummary"
          >
            <Typography variant='h5' className='topTitle'>
              Topic 3
            </Typography>
            <Typography variant='h5' className='subTitle'>Remembering Long Lists</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
              amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
