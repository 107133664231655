// Customizable Area Start
import React from 'react'
import ExpertDetailsController, {
    Props,
} from "./ExpertDetailsController.web"
import ExpertDetailsDialog from "./ExpertDetailsDialog.web"
import { withRouter } from "react-router-dom"

class ExpertDetails extends ExpertDetailsController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { isExpertDetailsDialog } = this.state
        const { match: { params } }: any = this.props
        const expertId = params?.id || ""

        return (
            <ExpertDetailsDialog
                expertId={expertId}
                isOpen={isExpertDetailsDialog}
                handleClose={this.handleCloseDialog}
            />
    )
    }
}

export default withRouter(ExpertDetails)
// Customizable Area End
