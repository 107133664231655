// Customizable Area Start
import React from "react"
import { LoginSocialInstagram } from "reactjs-social-login"
import { connect } from "react-redux"
import { bindActionCreators, Dispatch  } from "redux"
import InstagramLoginRegistrationController from "./InstagramLoginRegistrationController.web"
import { getUserDetails, getCartItems, setUserCartId } from "./assets"
import InstagramLogoButton from "../../../components/src/InstagramLogoButton.web"
const configJSON = require("./config.js")

export class InstagramLoginRegistration extends InstagramLoginRegistrationController {
  render() {
    const { isLoading } = this.state

    return (
      <>
        {isLoading ? (
          <InstagramLogoButton isLoading={isLoading} />
        ) : (
          <LoginSocialInstagram
            client_id={configJSON.INSTAGRAM_APP_ID}
            client_secret={configJSON.INSTAGRAM_APP_SECRET}
            redirect_uri={window.location.href}
            scope="user_profile"
            onResolve={this.onSuccess}
            onReject={this.onError}
          >
            <InstagramLogoButton isLoading={isLoading} />
          </LoginSocialInstagram>
        )}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    getCartItems: bindActionCreators(getCartItems, dispatch),
    setUserCartId: bindActionCreators(setUserCartId, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(InstagramLoginRegistration)
// Customizable Area End
