import React from "react";
import webEducationController, { Props } from "./webEducationController.web";
import WebHeader from "../../../../components/src/WebHeader.web";
import WebFooter from "../../../../components/src/WebFooter.web";
import GreenBanner from "../../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../../components/src/WebRoutingTab.web";
import { withRouter } from "react-router-dom";
import "../../../../web/src/assets/css/style.scss";
import "../assets/css/education.scss";
// common css
//dash board css
// import "../assets/css/dashboard.scss";
// custom components
import {
  Link,
  Button,
  MenuItem,
  Select,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CustomTextfield from "../../../../components/src/CustomTextfield.web";
import CustomButton from "../../../../components/src/CustomButton.web";
// import EastIcon from '@mui/icons-material/East';

///swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import WebRestaurantNavBar from "../../../../components/src/WebRestaurantNavBar.web";
import {
  ChoseusImg1,
  ChoseusImg2,
  ChoseusImg3,
  BannerImg1,
  MentorIcon,
  AwardIcon,
  UsersIcon,
  CourseImg1,
  CourseImg2,
  CourseImg3,
  RightArrowIcon,
  DottedSideBar,
} from "../assets";
SwiperCore.use([Navigation, Pagination]);

class webEducation extends webEducationController {
  private navigationPrevRef: React.RefObject<HTMLDivElement>;
  private navigationNextRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();
  }

  render() {
    return (
      <>
        {/* <div className="Header"> */}
        {/* <WebHeader />
        <WebRoutingTab /> */}
        <WebRestaurantNavBar/>
        {/* education container */}
        <main className="educationWapper">
          <section className="bannerSection commonSection">
            <div className="banner_content">
              <div className="contentBlock bannerBlocks">
                <h1 className="h1">
                  Anywhere Learning at
                  <br />
                  your convenient
                </h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the it to make a type specimen
                  book.
                </p>
                <div className="searchbox">
                  <CustomTextfield

                    placeholder="Search for courses"
                  />
                  <CustomButton label="SEARCH" />
                </div>
              </div>
              <div className="videoBlock bannerBlocks">
                <iframe
                  src="https://www.youtube.com/watch?v=tFKRK7Uy_dg"
                  title="W3Schools Free Online Web Tutorials"
                />
              </div>
            </div>
          </section>
          <div className="subContentWrapper ">
            <section className="choseusSection commonSection">
              <h2>Why Chose With us</h2>
              <Grid container spacing={2} className="cardContainer">
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card choseCard">
                    <CardContent className="cardContent">
                      <Typography component="h4" className="h4">
                        Heading 1
                      </Typography>
                      <Typography component="p">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </Typography>
                      <div className="cardFooter">
                        <div className="imgOverlay">
                          <img src={ChoseusImg1} alt="Choseus Img1" />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card choseCard">
                    <CardContent className="cardContent">
                      <Typography component="h4" className="h4">
                        Heading 1
                      </Typography>
                      <Typography component="p">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </Typography>
                      <div className="cardFooter">
                        <div className="imgOverlay">
                          <img src={ChoseusImg2} alt="Choseus Img1" />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card choseCard">
                    <CardContent className="cardContent">
                      <Typography component="h4" className="h4">
                        Heading 1
                      </Typography>
                      <Typography component="p">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </Typography>
                      <div className="cardFooter">
                        <div className="imgOverlay">
                          <img src={ChoseusImg3} alt="Choseus Img1" />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </section>
            <section className="courseSection commonSection">
              <h2>Benefit of Joining Our Course</h2>
              <Grid container spacing={2} className="cardContainer">
                <Grid item xs={12} sm={5} className="cardmb">
                  <div className="courseBanner">
                    <img
                      src={BannerImg1}
                      alt="Course banner"
                      className="courseImg"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} className="cardmb">
                  <Card className="card joinCourseCard active">
                    <CardContent className="cardContent">
                      <div className="imgBg">
                        <img src={MentorIcon} alt="Mentor Icon" />
                      </div>
                      <div className="cardSubContent">
                        <h5>Expert Mentor's</h5>
                        <p>
                          The best mentors in the world from<br />the best
                          college graduates.
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="card joinCourseCard">
                    <CardContent className="cardContent">
                      <div className="imgBg">
                        <img src={AwardIcon} alt="Mentor Icon" />
                      </div>
                      <div className="cardSubContent">
                        <h5>Award Sertificat</h5>
                        <p>
                          Certificate that can be used to <br />apply for work
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="card joinCourseCard">
                    <CardContent className="cardContent">
                      <div className="imgBg">
                        <img src={UsersIcon} alt="Mentor Icon" />
                      </div>
                      <div className="cardSubContent">
                        <h5>Relation International</h5>
                        <p>
                          Wide relationship and get many<br />opportunities to
                          work
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </section>
            <section className="courseSection commonSection">
              <h6>Courses</h6>
              <h2>Our Popular Courses</h2>
              <Grid container spacing={4} className="cardContainer">
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg1}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Computer Class
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg2}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Public Speaking
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg3}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        UI/UX Design
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg1}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Computer Class
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg2}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        Public Speaking
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="cardmb">
                  <Card className="card courseCard">
                    <CardMedia
                      component="img"
                      image={CourseImg3}
                      alt="Course Img"
                      className="courseImg"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="div">
                        UI/UX Design
                      </Typography>
                      <Typography variant="body2">
                        This is the most popular and in-demand
                        <br />
                        class so it will be even more fun
                      </Typography>
                    </CardContent>
                    <CardActions className="CardActions">
                      <Button size="small">
                        <span>Learn More</span>
                        <img src={RightArrowIcon} alt="Right Arrow" />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>

              <div className="circleBtn">
                <CustomButton label="View All" />
              </div>
            </section>
            <section className="courseSection commonSection">
              <div className="aboutCourses">
                <Grid container spacing={2} className="cardContainer">
                  <Grid item sm={6} xs={12}>
                    <div className="leftContainer">
                      <h4>
                        The World Largest
                        <br /> Selection Of Course's
                      </h4>
                      <p className="content">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Eos quas aperiam aut laboriosam dolore illo qui sapiente
                        iure beatae blanditiis cumque assumenda velit
                        perspiciatis, itaque culpa voluptatibus porro nemo.
                        Optio?
                      </p>
                      <div className="courseCountails">
                        <div className="block">
                          <h3>120K+</h3>
                          <p>many Students</p>
                        </div>
                        <div className="block">
                          <h3>140K+</h3>
                          <p>Best E-Courses</p>
                        </div>
                        <div className="block">
                          <h3>400+</h3>
                          <p>Educators</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="introVideo">
                      <div className="videoBlock bannerBlocks">
                        <iframe
                          src="https://www.youtube.com/watch?v=tFKRK7Uy_dg"
                          title="W3Schools Free Online Web Tutorials"
                        />
                      </div>
                      <div className="dottedSideBar">
                        <img
                          src={DottedSideBar}
                          alt="dottedSIdebar"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
            <section className="courseSection commonSection">
              <div className="educatorsContainer">
                <h1> Our Top Educators </h1>
                <div className="swiperContainer">
                  <Swiper
                    className="swiperHome5"
                    spaceBetween={50}
                    // modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={3}
                    navigation={{
                      prevEl: this.navigationPrevRef.current,
                      nextEl: this.navigationNextRef.current,
                    }}
                    // navigation
                    // loop={true}
                    breakpoints={
                      // when window width is >= 320px
                      {
                        // when window width is >= 640px
                        769: {
                          slidesPerView: 3,
                        },
                        200: {
                          slidesPerView: 1,
                        },
                      }
                    }
                    pagination={{
                      clickable: true,
                    }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    onInit={(swiper: any) => {
                      swiper.params.navigation.prevEl = this.navigationPrevRef.current;
                      swiper.params.navigation.nextEl = this.navigationNextRef.current;
                      // swiper.navigation.init();
                      // swiper.navigation.update();
                    }}
                  >
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card className="card educatorCard">
                        <CardMedia
                          component="img"
                          image={CourseImg1}
                          alt="educator Img"
                          className="educatorImg"
                        />
                        <CardContent className="cardContent">
                          <Typography gutterBottom variant="h5" component="div">
                            Jhon Deo
                          </Typography>
                          <Typography variant="body2">
                            Public Speaking
                          </Typography>
                        </CardContent>
                      </Card>
                    </SwiperSlide>

                    <div className="swiperAarrowRow">
                      <div
                        className="prevNavigation"
                        ref={this.navigationPrevRef}
                      >
                        <ArrowBackIosIcon />
                      </div>
                      <div
                        className="nextNavigation"
                        ref={this.navigationNextRef}
                      >
                        <ArrowForwardIosIcon />
                      </div>
                    </div>

                    {/* <div ref={paginationRef} className="swiper-pagination"></div> */}
                  </Swiper>
                </div>
              </div>
            </section>
          </div>
        </main>
        {/* ends: education container */}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}
//@ts-ignore
export default withRouter(webEducation);
// Customizable Area Start

// Customizable Area End
