// Customizable Area Start
import React, { Component } from 'react';
import { GoogleApiWrapper, IMapProps } from 'google-maps-react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import { InputAdornment, TextField } from '@material-ui/core';
import "../../web/src/assets/css/style.scss";
import { AimIcon } from "./assets"
import "./assets/css/customgoogleplaceautocomplete.scss"
const configJSON = require("./config.js")

interface Props extends IMapProps {
  lat: number
  lng: number
  disabled?: boolean
  placeholder: string
  handlePlaceSelect: (lat: number, lng: number, setFieldValue: (key: string, value: any) => void) => void
  detectPlace: (setFieldVal: (key: string, value: any) => void) => void
  setFieldValue: (key: string, value: any) => void
}

interface S {
  locationAddress: string
}

export class CustomGooglePlaceAutoComplete extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      locationAddress: ""
    }
  }

  componentDidMount() {
    this.getAddressByLatLng()
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { lat, lng } = this.props
    const isValid = !isNaN(lat) && !isNaN(lng)
    if (isValid && (prevProps.lat !== lat || prevProps.lng !== lng)) {
        this.getAddressByLatLng()
    }
  }

  handleChange = (address: string) => {
    this.setState({ locationAddress: address })
  }

  handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: any) => {
        const { lat, lng } = latLng
        this.props.handlePlaceSelect(lat, lng, this.props.setFieldValue)
      })
      .catch((error: any) => console.error("error", error));
  }

  getAddressByLatLng = () => {
    const { lat, lng } = this.props
    if(!!lat || !!lng) {
    const geocoder = new google.maps.Geocoder()

    geocoder
      .geocode({ location: { lat, lng }})
      .then((response: any) => {
        if (response?.results?.length > 0) {
            const readableAddress = response.results[0].formatted_address
            this.setState({ locationAddress: readableAddress })
        }
      })
      .catch((e: any) => {
        console.log("error", e)
      })
    } else {
      this.setState({ locationAddress: "" })
    }
  }

  render() {
    const {
      detectPlace,
      setFieldValue,
      disabled,
      placeholder
    } = this.props
    const { locationAddress } = this.state

    return (
      <div id="googleMaps" className="restaurantGooglemap">
        <PlacesAutocomplete
            value={locationAddress || ""}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <div className="customTextfield">
                <TextField
                  {...getInputProps({
                    placeholder,
                    className: "location-search-input custom_google_place_textfield",
                  })}
                  value={locationAddress || ""}
                  disabled={disabled}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button
                          type="button"
                          className='custom_google_place_detect'
                          onClick={() => detectPlace(setFieldValue)}
                        >
                          <span className="detectIcon sideLine">
                            <img src={AimIcon} alt="aim" />
                            <p>
                              Detect
                            </p>
                          </span>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {(loading || suggestions.length > 0) && (
                <div className="googlemap-data">
                  <div className="autocomplete-dropdown-container  googlemap-dropdown">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: configJSON.GOOGLE_MAP_API_KEY
})(CustomGooglePlaceAutoComplete)
// Customizable Area End
