import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IImage } from "../../../../components/src/interfaces/common";

// Customizable Area Start
export const configJSON = require("../config");

export interface IFavDetails {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    average_rating: number
    is_ad: boolean
    images: IImage | null
    image: IImage | null
    advertisement_banner: IImage | null
    banner: IImage | null
    third_party_ad_url: string | null
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  loader: boolean;
  FPFarmDesc: string;
  FPRestaurantDesc: string;
  FPCourseDesc: string;
  favouriteList: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FavouritePanelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {};
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      console.log("responseJson");
    }
    // Customizable Area End
  }

  settingHeading = (isEmptyListMessage?: boolean) => {
    const url = window.location.pathname.split("/")[1];
    let heading = url == "favourites-page" ? "Favourite" : "Recently Added";
    return isEmptyListMessage ? heading.toLocaleLowerCase() : heading;
  };

  // Customizable Area Start
  getImages = (item: IFavDetails) => {
    const {
      attributes: { images, image, advertisement_banner, banner },
    } = item
    
    const imageUrl = image ? image.url : null
    const displayImage = images ? images.url : imageUrl
    const adBanner = banner ? banner.url : displayImage
    const thirdPartyImageUrl = advertisement_banner
      ? advertisement_banner.url
      : undefined
    
    return {
      adBanner,
      thirdPartyImageUrl,
    }    
  }  
  // Customizable Area End
}
