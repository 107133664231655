// Customizable Area Start
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import React from "react";
import "../../info-page/src/assets/css/receiptcard.scss";
import { expandArrow } from './assets';
import Image from "material-ui-image";
import LazyLoad from "react-lazy-load";


export function ActionsTask({ loader,data, handleButtonClick, handleModalClose, isModalOpen, taskAns }: any) {
    const [actionAns, setActionAns] = React.useState("")
    const options = [
        { value: 'yes', label: 'YES' },
        { value: 'no', label: 'NO' },
        { value: 'may be', label: 'MAY BE' },
    ];
    const accountId = localStorage.getItem('cartId')
    return (
        <>
            {data.attributes.title_image &&
            <Grid item xs={12} style={{ display: 'block', marginBottom:'20px' }} className="taskImageContainer">
            <LazyLoad offset={100}>  
            <Image
              style={{position:'unset', paddingTop:'0'}}
              src={data?.attributes?.title_image?.url}
              alt="Title Image"
              imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
              animationDuration={500}
              loading={
                  <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
              }
              />
              </LazyLoad>  
              </Grid>
            
            }
            <Grid container>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginRight: '25px' }}>
                    <Typography className='select-option'>Select Option:</Typography>
                </Grid>
                {loader && !isModalOpen ?
                            <Box className="task_result_loader">
                                <CircularProgress size={35} />
                            </Box> :
                        <>
                {data.attributes.task_results.length === 0 ?
                    <>
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {options.map((option) => (
                                <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex' }} key={option.value}>
                                    <button
                                        className={`${actionAns === option.value ? "selectAction" : "actOptions"} `}
                                        onClick={() => setActionAns(option.value)}
                                    >
                                        {option.label}
                                    </button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container className="taskBtnContainer" >
                            <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                <button className='cancelBtn' onClick={() => {
                                    setActionAns('')
                                }}>Cancel</button>
                                <button className='submitBtn' onClick={() => taskAns(actionAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                            </Grid>
                            <Grid style={{ display: 'flex', marginTop: '30px', marginRight: '25px' }}>
                                <button className='fullBtnContainer'
                                    style={{ margin: '0', padding: '16px 22px 16.2px', alignItems: 'flex-end' }}
                                    onClick={handleButtonClick}>
                                    <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                            </Grid>
                        </Grid>
                        <Modal open={isModalOpen} onClose={handleModalClose} >
                            <Grid container style={{ backgroundColor: 'white' }}>
                                <Box className='task-popup farm_forum_dialog' >
                                    <ActionsTaskPopUp data={data} accountId={accountId} setActionAns={setActionAns} actionAns={actionAns} taskAns={taskAns}/>
                                </Box>
                            </Grid>
                        </Modal>
                    </>
                    :
                    <>
                        {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                            <>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {options.map((option) => (
                                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex' }}  key={option.value}>
                                            <button
                                                className={`${actionAns === option.value ? "selectAction" : "actOptions"} `}
                                                onClick={() => setActionAns(option.value)}
                                            >
                                                {option.label}
                                            </button>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container className="taskBtnContainer">
                                    <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                        <button className='cancelBtn' onClick={() => {
                                            setActionAns('')
                                        }}>Cancel</button>
                                        <button className='submitBtn' onClick={() => taskAns(actionAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                    </Grid>
                                    <Grid style={{ display: 'flex', marginTop: '30px', marginRight: '25px' }}>
                                        <button className='fullBtnContainer'
                                            style={{ margin: '0', padding: '16px 22px 16.2px', alignItems: 'flex-end' }}
                                            onClick={handleButtonClick}>
                                            <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                                    </Grid>
                                </Grid>
                                <Modal open={isModalOpen} onClose={handleModalClose} >
                                    <Grid container style={{ backgroundColor: 'white' }}>
                                        <Box className='task-popup farm_forum_dialog'>
                                            <ActionsTaskPopUp loader={loader} data={data} accountId={accountId} setActionAns={setActionAns} actionAns={actionAns} taskAns={taskAns}/>
                                        </Box>
                                    </Grid>
                                </Modal>
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                    return (
                                        <>
                                            {ActionWithResults(data, item, handleButtonClick)}
                                            <Modal open={isModalOpen} onClose={handleModalClose} >
                                                <Grid container style={{ backgroundColor: 'white' }}>
                                                    <Box className='task-popup farm_forum_dialog'>
                                                        <ActionsTaskPopUp loader={loader} data={data}  setActionAns={setActionAns} actionAns={actionAns} taskAns={taskAns}/>
                                                    </Box>
                                                </Grid>
                                            </Modal>
                                        </>
                                    )
                                })}
                            </>
                        }
                    </>
                }
                </>
                }
            </Grid>
        </>
    )
}

const ActionWithResults = (data: any, item: any, handleButtonClick:any) => (
    <>
        <Grid container style={{ display: 'flex' }}>
            <Grid item xs={12} sm={4} md={4} lg={4} className="actionResult">
                <button className={`${item.attributes.selected_answer[0] === 'yes' ? "selectAction" : "actOptions"}`}
                >YES
                </button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className="actionResult">
                <button className={`${item.attributes.selected_answer[0] === 'no' ? "selectAction" : "actOptions"}`}
                >
                    NO
                </button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex' }}>
                <button className={`${item.attributes.selected_answer[0] === 'may be' ? "selectAction" : "actOptions"}`}
                >
                    MAY BE
                </button>
            </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end', marginLeft: '30px' }}>
            <Grid style={{ margin: '30px 0 20px' }}>
                <button className='fullBtnContainer'onClick={handleButtonClick}>
                    <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
            </Grid>
        </Grid>
        
    </>
)

function ActionsTaskPopUp({ loader,data, setActionAns, actionAns, taskAns }: any) {
    const options = [
        { value: 'yes', label: 'YES' },
        { value: 'no', label: 'NO' },
        { value: 'may be', label: 'MAY BE' },
    ];
    const accountId = localStorage.getItem('cartId')
    
    return (
        <>
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog'>
                    <Typography className="select-text">Select Correct Answer</Typography>
                    <div className='horizontal-line'></div>
                    <Typography className='task-title'>Task Title</Typography>
                    <Box className='task-question'>
                    <Typography variant="body1" component="div" >
                        <div  className="question" dangerouslySetInnerHTML={{ __html:  data.attributes.title }} />
                    </Typography>
                    </Box>
                    {data.attributes.title_image &&
                        <Grid item xs={12} style={{ display: 'block', margin:'20px 0' }} className="taskImageContainer">
                         <LazyLoad offset={100}>     
                        <Image
                        style={{position:'unset', paddingTop:'0'}}
                        src={data?.attributes?.title_image?.url}
                        alt="Title Image"
                        imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
                        animationDuration={500}
                        loading={
                            <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
                        }
                        />
                        </LazyLoad>  
                        </Grid>
                        
                    }
                    <Grid container style={{ marginTop: '24px' }}>
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='task-option'>Options</Typography>
                        </Grid>
                        {loader ?
                            <Box className="task_result_loader">
                                <CircularProgress size={35} />
                            </Box> :
                        <>
                        {data.attributes.task_results.length === 0 ?
                            <>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {options.map((option) => (
                                        <Grid style={{ display: 'flex' }} lg={4} key={option.value}>
                                            <button
                                                className={`${actionAns === option.value ? "selectAction-popup" : "actOptions-popup"} `}
                                                onClick={() => setActionAns(option.value)}
                                            >
                                                {option.label}
                                            </button>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container className="taskBtnContainer">
                                    <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                        <button className='cancelBtn' onClick={() => {
                                            setActionAns('')
                                        }}>Cancel</button>
                                        <button className='submitBtn' onClick={() => taskAns(actionAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                                    <>
                                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {options.map((option) => (
                                                <Grid style={{ display: 'flex' }} lg={4} key={option.value}>
                                                    <button
                                                        className={`${actionAns === option.value ? "selectAction-popup" : "actOptions-popup"} `}
                                                        onClick={() => setActionAns(option.value)}
                                                    >
                                                        {option.label}
                                                    </button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid container className="taskBtnContainer">
                                            <Grid style={{ display: 'flex', marginTop: '30px' }}>
                                                <button className='cancelBtn' onClick={() => {
                                                    setActionAns('')
                                                }}>Cancel</button>
                                                <button className='submitBtn' onClick={() => taskAns(actionAns, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                            return (
                                                <>
                                                    <Grid container style={{ display: 'flex' }}>
                                                        <Grid item xs={12} sm={4} md={4} lg={4} className="actionResultPopup">
                                                            <button className={`${item.attributes.selected_answer[0] === 'yes' ? "selectAction-popup" : "actOptions-popup"}`}
                                                            >YES
                                                            </button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={4} className="actionResultPopup">
                                                            <button className={`${item.attributes.selected_answer[0] === 'no' ? "selectAction-popup" : "actOptions-popup"}`}
                                                            >
                                                                NO
                                                            </button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex' }}>
                                                            <button className={`${item.attributes.selected_answer[0] === 'may be' ? "selectAction-popup" : "actOptions-popup"}`}
                                                            >
                                                                MAY BE
                                                            </button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                        </>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}  
// Customizable Area End