// Customizable Area Start
const commonCmptStyles = {
  categoryDropDown: {
    border: 0,
    borderRadius: 5,
    // padding: "20px 0",
    "&.MuiList-root": {},
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#20a258",
        },
      },
    },
    "& .MuiListItem-button": {
      padding: "5px 30px",
      "&:hover": {
        textDecoration: "none",
        color: "#FFF",
        backgroundColor: "#20a258",
      },
    },
    "& .MuiMenuItem-root": {
      padding: "5px 32px",
      margin: "10px 0",
      color: "#757575",
      fontWeight: "500",
      whiteSpace: "normal",
      textTransform: "capitalize !important",
      "&:hover": {
        backgrounColor: "#20a258",
      },
    },
  },
  norecords : {
    width: "100%",
    padding: "0"
  },
  sortDropDown: {
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #f0f0f0",
    marginTop: "5px",
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "white",
      },
    },
    "& .MuiListItem-root": {
      textTransform: "capitalize",
      color: "#212121",
      fontSize: "15px",
      padding: "4px 30px",
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
      "&:hover": {
        color: "#20a258",
      },
    },
    "& .MuiMenu-list": {
      minWidth: "200px",
    },
  },
  listStatus: {
    borderRadius: "0px 0px 5px 5px",
    width: "150px",
    border: "1px solid #f0f0f0",
    padding: "0px",
    marginTop: "10px",
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#20a258",
        },
      },
    },
    "&.MuiInputBase-input": {
      padding: "13px 38px 10px 3px !important",
    },
    "& .MuiListItem-button": {
      padding: "5px 30px",
      "&:hover": {
        textDecoration: "none",
        color: "#FFF",
        backgroundColor: "#20a258",
      },
    },
    "& .MuiMenuItem-root": {
      padding: "5px 30px",
      margin: "10px 0",
      color: "#757575",
      fontWeight: "500",
      whiteSpace: "normal",
      textTransform: "capitalize !important",

      "&:hover": {
        backgrounColor: "#20a258",
      },
    },
    "& .MuiMenu-paper": {
      boxShadow: "none !important",
      minWidth: "210px !important",
      maxWidth: "210px !important",
    },
  },
  itemStatus: {
    maxWidth: "176px !important",
    marginTop: "2px",
    border:"1px #20a258 solid",
    "& .MuiMenu-list": {
      backgroundColor: "#e7ffed",
      borderRadius: "5px",
    },
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        fontSize:"18px"
      },
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#e7ffed",
      color: "#20a258",
      whiteSpace: "normal",
      textTransform: "capitalize !important",
    },

    "& .MuiFormControl-root": {
      marginBottom: "2px",
    },

    "& .MuiMenu-paper": {
      // boxShadow: "none !important",
      minWidth: "210px !important",
      maxWidth: "210px !important",
    },
  },
  dropStatus: {
    "& .MuiMenuItem-root": {
     textTransform: "none !important",
    },
  },
  resDishStatus: {
    maxWidth: "176px !important",
    // boxShadow: "none",
    marginTop: "1px",
    "& .MuiMenu-list": {
      backgroundColor: "#edf8f2",
      border: "1px solid #20a258",
      borderRadius: "5px",
    },
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        fontSize: "1.1rem !important",
      },
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#edf8f2",
      color: "#20a258",
      whiteSpace: "normal",
      textTransform: "capitalize !important",
    },

    "& .MuiFormControl-root": {
      marginBottom: "2px",
    },

    "& .MuiMenu-paper": {
      // boxShadow: "none !important",
      minWidth: "210px !important",
      maxWidth: "210px !important",
    },
  },
  dangerVariant: {
    "& .MuiMenu-list": {
      backgroundColor: "#f7e5e5",
      border: "1px solid red",
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#f7e5e5",
      color: "red",
    },
  },
  warningVariant: {
    "& .MuiMenu-list": {
      backgroundColor: "#ffefd3",
      border: "1px solid #ec9e31",
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#ffefd3",
      color: "#ec9e31",
    },
  },
  farmOrdersDropDown: {
    marginTop: "5px !important",
    "& .MuiMenuItem-root": {
      display: "flex",
      justifyContent: "space-between",
      padding: "5px 35px !important",
      "& span": {
        color: "#44444f !important",
        paddingRight: "20px",
        "&:hover": {
          color: "#20a258 !important",
        },
      },
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "& .MuiMenu-list": {
      padding: "10px 0 !important",
    },
    "& .MuiListItem-root": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#20a258",
          color: "#fff",
        },
        "& .checkmark": {
          display: "inline-block",
          width: "19px",
          height: "19px",
          transform: "rotate(45deg)",
          marginLeft: "20px",
          top: "10px",
          left: "-8px",
        },
        "& .checkmarkDishes": {
          display: "inline-block",
          width: "19px",
          height: "19px",
          transform: "rotate(45deg)",
          marginLeft: "20px",
          top: "8px",
          left: "130px",
        },
        "& .checkmarkRest": {
          display: "inline-block",
          width: "19px",
          height: "19px",
          // top: "0",
          transform: "rotate(45deg)",
          marginLeft: "20px",
          top: "8px",
          left: "190px",
        },
        "& .checkmark_circle": {
          position: "absolute",
          width: "16px",
          height: "16px",
          backgroundColor: "green",
          borderRadius: "11px",
          left: 0,
          top: 0,
        },
        "& .checkmark_stem": {
          position: "absolute",
          width: "2.5px",
          height: "9px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "8px",
          top: "3px",
        },
        "& .checkmark_kick": {
          position: "absolute",
          width: "4.5px",
          height: "3px",
          backgroundColor: "#fff",
          borderRadius: "11px",
          left: "4px",
          top: "9px",
        },
      },
    },
    "& .MuiListItem-button": {
      padding: "5px 30px",
      fontFamily: "'Montserrat', sans-serif",
      "&:hover": {
        color: "#20a258 !important",
        fontWeight: "bold",
      },
    },
  },
  studentSelect: {
    top: "188px",
    right: "16px",
    width: "300px",
    height: "337px",
  },
};
export { commonCmptStyles };
// Customizable Area End
