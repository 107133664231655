import React from "react"
import { Grid, Box, RadioGroup, Typography } from "@material-ui/core"
import PickUpCard from "./pickupCardWeb.web"

interface IAddress {
    id: string
    type: string
    attributes: {
        first_name: string
        last_name: string
        company_name: string
        address: string
        address2: string
        city: string
        state: string
        country: string
        phone_number: string
    }
}

interface IOrderPickUpAddressesProps {
    selectedAddress: string | number
    pickUpAddresses: IAddress[]
    handleChangeAddress: (e: React.ChangeEvent<HTMLInputElement>) => void
    showEditAddressModal: (address: IAddress) => void
}

const OrderPickUpAddresses = ({
    selectedAddress,
    pickUpAddresses,
    handleChangeAddress,
    showEditAddressModal,
}: IOrderPickUpAddressesProps) => {
    return pickUpAddresses.length > 0 ? (
        <RadioGroup
            value={selectedAddress.toString()}
            onChange={handleChangeAddress}
            name="delivery-address"
            style={{ flexWrap: "nowrap", gap: "20px" }}
        >
            <Typography className="pickup_address_hint">
                You can pickup your parcel from the following local farmers market.
            </Typography>
            {pickUpAddresses?.map((address: IAddress) => {
                const {
                    id,
                    attributes: {
                        first_name,
                        last_name,
                        company_name,
                        address: address1,
                        address2,
                        city,
                        state,
                        phone_number,
                    },
                } = address

                return (
                    <Grid item xs={12} key={id}>
                        <PickUpCard
                            id={id}
                            isPickUp
                            firstName={first_name}
                            lastName={last_name}
                            companyName={company_name}
                            address1={address1}
                            address2={address2}
                            city={city}
                            country={state}
                            phoneNumber={phone_number}
                            editAddress={() => showEditAddressModal(address)}
                        />
                    </Grid>
                )
            })}
        </RadioGroup>
    ) : (
        <Box className="no_address_container">
            <Typography variant="h2">No pickup address available</Typography>
            <Typography variant="h5">
                You can choose delivery address and
                <br />
                your order will be deliver to your address
            </Typography>
        </Box>
    )
}

export default OrderPickUpAddresses
