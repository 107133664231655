// Customizable Area Start
import React from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import LazyLoad from "react-lazy-load";
import Image from "material-ui-image";
import { UserIcon } from "./assets";
import { Rating, TextField } from "@mui/material";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import Buttn from "@mui/material/Button";
import RepliesData from "./RepliesData.web";
import CourseCommentController, {Props} from "./CourseCommentController.web";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  commentSection : {
    marginTop: '27px'
  },
  replyBtnContainer : {
    display: "flex",
    justifyContent: "flex-end"
  },
  replyText : {
    marginLeft: '-12px',
    height:'50px',
    '@media (max-width: 560px)' : {
      marginLeft : 0
    }
  },
  saveBtn : {
    backgroundColor: "#ffc107",
    borderRadius: "34px",
    margin: "0.5rem" 
  }
}

export class CourseComment extends CourseCommentController {
    constructor(props:Props){
      super(props);
    }
    render() {
      const { classes } = this.props;
      return (
        <>
              
          <Grid container className={classes.commentSection}>
            <Grid item xs={12} md={12}>
              <Box className="comment_wrapper">
                <Box className="reviewImageContainer" >
                  <LazyLoad offset={100}>
                  <Image className="image" 
                  style={{borderRadius: '50%'}} 
                  loading={<CircularProgress size={20}/>}
                  src={this.props.data.attributes?.image?.url || UserIcon} />
                  </LazyLoad>
                </Box>
  
                <Box style={{flex: 80}}>
                  <Grid container spacing={2} className="rightReviewContainer">
                    <Grid item sm={8} className="reviewPadding">
                      <h5>{this.props.data.attributes?.full_name}</h5>
                    </Grid>
                    <Grid item sm={4} className="reviewPadding">
                      <Box className="comment_date">
                        <p>{this.props.data.attributes.date}</p>
                      </Box>
                    </Grid>
                    <Grid item xs={12} className="reviewPadding">
                      <Rating
                        className="StyledRating"
                        classes={{ iconFilled: "iconFilled", iconHover: "iconHover" }}
                        name="customized-empty"
                        defaultValue={this.props.data.attributes.rating}
                        precision={0.5}
                        readOnly
                        size="large"
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      />
                    </Grid>
                    <Grid item xs={12} className="reviewPadding">
                      <pre className="comment" >
                        {this.props.data?.attributes?.comment}
                      </pre>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.replyBtnContainer}
                    >
                      <button className="reply_btn"
                        onClick={this.handleReply}>
                        Reply
                        <p className="replyIcon"><ReplyAllIcon /></p>
                      </button>
  
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
  
          {this.props.open && this.props.data.id === this.props.commentId &&
  
            <Grid container className="replyBox">
              <Grid xs={12} className="reply-wrapper">
                <Box className={classes.replyText}>
                  <TextField value={this.state.replyText} onChange={e => this.setState({ replyText: e.target.value })} placeholder="Your reply" multiline maxRows={4} />
                </Box>
                <div className="saveBtnWrapper">
                  {this.state.replyText &&
                    <Buttn
                      onClick={
                        this.handleSave
                      }
                      className="send-btn"
                      classes={classes.saveBtn}
                      variant="contained">
                      Save
                    </Buttn>
                  }
                </div>
              </Grid>
            </Grid>
          }
  
          {this.props.repliesList?.map((replyItem: any) =>
            <RepliesData
              replyItem={replyItem}
              key={replyItem.id}
              name={this.props.name}
              del={this.props.onDel}
              SaveEditReply={this.props.onSaveEditReply}
              isEditSave={this.props.isEditSave} navigation={undefined} id={""} classes={undefined} />
  
          )}
          
        </>
      )
    }
  }


export default withStyles(styles)(CourseComment)  
// Customizable Area End