import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { isTokenExpired } from "../../../components/src/utility/helper";
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config.js");
import { toast } from "react-toastify";

export interface IAddress {
  id: string;
  type: string;
  attributes: {
    address: string;
    address2: string;
    address_type: string;
    city: string;
    company_name: string;
    country: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    postal_code: string;
  };
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  history: any;
  userInfo: {
    address: IAddress[];
    isAddressDeleteBtnLoading: boolean;
    isAddressLoading: boolean;
  };
  getAddressListAction: () => void;
  deleteAddressAction: (id: string) => Promise<void>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAddAddressDialog: boolean;
  isEditAddressDialog: boolean;
  filteredData: IAddress | null;
  isMessageDisplayed: boolean;
  isAddressRemoveDialog: boolean;
  removeAddressId: string;
  isRemovingAddress: boolean;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSettingWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      filteredData: null,
      isEditAddressDialog: false,
      isAddAddressDialog: false,
      isMessageDisplayed: false,
      isAddressRemoveDialog: false,
      removeAddressId: "",
      isRemovingAddress: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.props.getAddressListAction();
  }

  openAddAddressDialog = () => {
    this.setState({ isAddAddressDialog: true });
  };

  closeAddAddressDialog = () => {
    this.setState({ isAddAddressDialog: false });
  };

  closeEditAddressDialog = () => {
    this.setState({ isEditAddressDialog: false });
  };

  openRemoveAddressDialog = (id: string) => {
    this.setState({ isAddressRemoveDialog: true, removeAddressId: id });
  };

  closeRemoveAddressDialog = () => {
    this.setState({ isAddressRemoveDialog: false, removeAddressId: "" });
  };

  handleErrorApiResponse = (responseJson: any) => {
    if (responseJson.errors && responseJson.errors.length > 0) {
      if (!this.state.isMessageDisplayed) {
        responseJson.errors.map((err: any) => {
          toast.error(err.message);
        });
        this.setState({
          isMessageDisplayed: true
        });
      }
    }
  };

  handleAddEditSuccessResponse = (message: string) => {
    if (!this.state.isMessageDisplayed) {
      toast.success(message);
      this.setState({
        isMessageDisplayed: true
      });
    }
    this.props.getAddressListAction();
    this.closeAddAddressDialog();
  }

  handleSuccessApiResponse = (responseJson: any) => {
    if (responseJson.meta && responseJson.meta.message) {
      const successMessage = responseJson.meta.message;

      if (successMessage === "Address Created Successfully") {
        this.handleAddEditSuccessResponse("Address added successfully")
      }

      if (successMessage === "Address Updated Successfully") {
        this.handleAddEditSuccessResponse("Address Updated Successfully")
      }

      if (successMessage === "Address Deleted Successfully") {
        this.props.getAddressListAction();
        if (!this.state.isMessageDisplayed) {
          toast.success("Address Deleted Successfully");
        }
        this.setState({
          isMessageDisplayed: true
        });
      }
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (await isTokenExpired(responseJson)) {
        return;
      }

      if (responseJson && responseJson.data) {
        this.handleSuccessApiResponse(responseJson);
      } else {
        this.handleErrorApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  editAddress = (id: string) => {
    const {
      userInfo: { address: addresses }
    } = this.props;

    const editableAddress = Array.isArray(addresses)
      ? addresses.find((item: IAddress) => item.id === id)
      : null;

    if (editableAddress) {
      this.setState({
        isEditAddressDialog: true,
        filteredData: editableAddress
      });
    }
  };

  deleteAddress = async () => {
    this.setState({ isRemovingAddress: true });
    await this.props.deleteAddressAction(this.state.removeAddressId);
    this.setState({ isRemovingAddress: false });
    this.closeRemoveAddressDialog();
  };
  // Customizable Area End
}
