import React, { Component } from "react";
import {
  Grid,
  Box,
} from "@material-ui/core";
import "../../assets/css/Shoppingcartpaymentmethod.scss";
const configJSON = require("../config.js")

interface Props {
  orderType: string
  totalPrice: string
  subTotal: string
  total: string
  shippingFee: string
  transactionFees: string
  discount: string
  itemCount: number
}


class ShoppingCartPaymentMethod extends Component<Props, any, any> {

  constructor(props: Props) {
    super(props);
  }

  render() {

    const {
      orderType,
      discount,
      itemCount,
      shippingFee,
      totalPrice,
      subTotal,
      total,
      transactionFees,
    } = this.props
    const isFarmOrder = orderType === configJSON.farmText

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Total Item</p>
                <p>${totalPrice}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Discount</p>
                <p>-${discount}</p>
              </Box>
            </Box>
            <hr />
            <Box className="cart_payment_wrapper">
              <Box className="cart_payment_details">
                <p>Sub total</p>
                <p>${subTotal}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Transaction fees</p>
                <p>${transactionFees}</p>
              </Box>
              <Box className="cart_payment_details">
                <p>Shipping</p>
                <p>{isFarmOrder ? "--" : `$${shippingFee}`}</p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box className="cart_total_payment_wrapper">
              <Box className="cart_payment_details">
                <p>{`Total(${itemCount} ${itemCount === 1 ? 'item' : 'items'})`}</p>
                <p>${total}</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ShoppingCartPaymentMethod;