// Customizable Area Start
import React from "react";
import { Like_Icon, Chat_Icon, DustbinIcon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import CustomButton from "../../../components/src/CustomButton.web";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { commonCmptStyles } from "./dropDownCss";
import { withRouter } from "react-router-dom";
import AdminFarmForumsController, {
  Props,
} from "./AdminFarmForumsController.web";
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import {
  Button,
  Box,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
  IconButton
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../assets/css/adminRestaurantForumsEdit.scss";
import CustomerReviewandReply from "../../../components/src/CustomerReviewandReply.web";
import CustomTextAreafield from "../../../components/src/CustomTextAreafield.web";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// Customizable Area End

// Customizable Area Start
const Schema = Yup.object().shape({
  heading: Yup.string().required("This field is required."),
  description: Yup.string()
  .max(1000, "Description must be at most 1000 characters.")
  .required("This field is required."),
  banner: Yup.string().required("This field is required."),
});
// Customizable Area End

class AdminFarmForumsEdit extends AdminFarmForumsController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  getImageAtt = (banner: any, setFieldValue: (field: string, value: any) => void) => {
    return (
      <>
        {banner ? (
          <div className="imageContainer" data-testid="imageContainer">
            <div className="image">
              {banner &&
                banner.length > 0 &&
                banner[0].name ? (
                <img
                  src={(
                    window.URL ||
                    window.webkitURL
                  ).createObjectURL(
                    //@ts-ignore
                    banner[0]
                  )}
                  alt="banner"
                />
              ) : (
                <img src={banner.url} alt="banner" />
              )}
            </div>
            <label className="editBannerBtn" data-testid="editBannerButton">
              <input
                id="images"
                data-testid="inputBanner"
                name="images"
                type="file"
                hidden
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e: any) => {
                  e.currentTarget.files.length > 0 &&
                    setFieldValue("banner", e.currentTarget.files);
                }}
              />
              Edit Banner
            </label>
          </div>
        ) : (
          <div className="mediaContainer forumsMediaContainer">
            <div className="mediaUploader" data-testid="mediaUploader" onDragOver={(e:any)=>this.handleDragOver(e)} onDrop={(e:any)=>this.handleDragDrop(e,setFieldValue)}>
              <label>
                <input
                  id="banner"
                  name="banner"
                  type="file"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e: any) => {
                    e.currentTarget.files.length > 0 &&
                      setFieldValue(
                        "banner",
                        e.currentTarget.files
                      );
                  }}
                />
                <BackupOutlinedIcon />
                <span>Drag and drop a file or click here</span>
              </label>
            </div>
          </div>
        )}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const farmStatus = ["active", "draft"];
    const forumDetails = this.state.forumDetails?.attributes;

    return (
      <>
        {/* main card container */}
        <Formik
          enableReinitialize
          initialValues={{
            heading: forumDetails?.heading || "",
            description: forumDetails?.description || "",
            status: forumDetails?.status || "active",
            banner: forumDetails?.banner || "",
            forumable_type: "Farm",
            forumable_id: window.location.pathname.split("/")[4],
            reply: "",
            replyEdit: "",
          }}
          validationSchema={Schema}
          onSubmit={(values) => this.createForum(values)}
        >
          {({ values,
              setFieldValue,
              handleChange
            }) => {

            return (
              <Form noValidate autoComplete="off">
                <Box className="restaurantForumsEditContainer">
                  {/* header start */}
                  <div className="header">
                    <div className="leftSideHeader">
                      <IconButton data-testid="backBtn" onClick={() => this.props.history.goBack()}>
                        <ArrowBackIcon />
                      </IconButton>
                      <h4>
                        {this.getHeading()}
                      </h4>
                    </div>
                    <div className="rightSideHeader commonDropdown">
                      <div className={`customSelect campaignSelect itemStatus ${values.status == "draft" && "warning-variant"}`}>
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            className="menu-customize"
                            data-testid="selectStatus"
                            value={values.status}
                            onChange={(e: any) => {
                              setFieldValue("status", e.target.value);
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: classes.itemStatus,
                              },
                            }}
                          >
                            {farmStatus.map((statusValue: string) => {
                              return (
                                <MenuItem value={statusValue} key={statusValue}>
                                  {statusValue}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      {/* <CustomSelectField /> */}
                      <div>
                      {this.state.productMode == "edit" && (
                        <img src={DustbinIcon}
                        data-testid="DustbinIcon"
                          className="delIconSize"
                          onClick={() => {
                            this.setState({
                              removedProduct: this.state.forumDetails.id,
                            });
                            this.handleDialog();
                          }}
                        />
                      )}
                      </div>
                    </div>
                  </div>

                  {/* header start */}
                  {/* main wrapper start */}
                  <div className="forumMainWrapper" data-testid="forumMainWrapper">
                    {/* image container start */}
                    {this.getImageAtt(values.banner, setFieldValue)}
                    <span className="errorMsg">
                      <ErrorMessage name="banner" />
                    </span>

                    {/* image container end */}

                    {/* likescomment conatiner start */}
                    <div className="forumResponseContainer">
                      {this.state.productMode == "edit" && (
                        <div className="likesCommentsContainer">
                          <div className="likeComments">
                            {/* <ThumbUpOutlinedIcon /> */}
                            <img src={Like_Icon} alt="Like_Icon" />
                            <span className="count" data-testid="likes">
                              {this.settingLikes(forumDetails?.likes)}
                            </span>
                            <span>Likes</span>
                          </div>
                          <div className="likeComments">
                            {/* <ForumOutlinedIcon /> */}
                            <img src={Chat_Icon} alt="Like_Icon" />
                            <span className="count" data-testid="comments">
                              {this.settingLikes(forumDetails?.comments)}
                            </span>
                            <span>Comments</span>
                          </div>
                        </div>
                      )}
                      <div className="buttonContainer">
                        <Button type="submit">
                          {this.settingButtonText()}
                        </Button>
                      </div>
                    </div>
                    {/* likescomment conatiner end */}

                    <div className="forumHeaderDescription">
                      <h4>Heading</h4>
                      <div className="customTextArea customTextAutosize">
                        <Textarea
                          id="heading"
                          value={values.heading}
                          placeholder="Write forum heading here..."
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            this.handleHeadingChange(e, setFieldValue)
                          }
                          endDecorator={
                              <Typography level="body3" sx={{ ml: 'auto' }}>
                                {values?.heading ? values?.heading?.trim()?.split(" ").length : "0"}/25
                              </Typography>
                            }
                        />
                      </div>
                      <span className="errorMsg">
                        <ErrorMessage name="heading" />
                      </span>
                    </div>
                    <div className="forumHeaderDescription">
                      <h4>Description</h4>
                      <textarea
                        cols={30}
                        rows={10}
                        id="description"
                        value={values.description}
                        placeholder="Write forum Description here..."
                        onChange={handleChange}
                      />
                      <span className="errorMsg">
                        <ErrorMessage name="description" />
                      </span>
                    </div>
                    {/* {comments container start} */}
                    {this.state.productMode == "edit" && (
                      <div className="commentContainer" data-testid="commentContainer">
                        {forumDetails?.comments > 0 && <h4>Comments</h4>}

                        {forumDetails?.comments > 0 &&
                          forumDetails?.reviews?.data?.map((review: any) => {
                            return (
                              <>
                                <CustomerReviewandReply
                                data-testid="customCommentContainer"
                                  user={true}
                                  reviewdata={review}
                                  deleteReview={this.deleteReview}
                                  handleReviewReply={this.handleReviewReply}
                                  handleReplyEditId={this.handleReplyEditId}
                                  setFieldValue={setFieldValue}
                                />
                                {review.id == this.state.replyId && (
                                  <div className="newComment newCommentreply">
                                    <CustomTextAreafield
                                              data-testid="commentBox"
                                              cols={50}
                                              rows={4}
                                              placeholder="Write your comment"
                                              value={values.reply}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  "reply",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                    <div className="commentbtn farmforumreply">
                                      <Button
                                        variant="contained"
                                        data-testid="reviwCancel"
                                        onClick={() => this.handleReviewReply("", setFieldValue)}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                      data-testid="reviwSend"
                                        variant="contained"
                                        onClick={() => this.handleReply(review.id, values.reply, setFieldValue)}
                                      >
                                        Send
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                {review?.attributes?.replies?.data.map(
                                  (reply: any) => {
                                    return (
                                      <>
                                        {reply.id == this.state.replyEditId ? (
                                          <div className="newComment newCommentreply">
                                            <CustomTextAreafield
                                            data-testid="replyEdit"
                                              cols={50}
                                              rows={4}
                                              placeholder="Edit your comment"
                                              value={values.replyEdit}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  "replyEdit",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <div className="commentbtn farmforumreply">
                                              <Button
                                              data-testid="cancelEdit"
                                                variant="contained"
                                                onClick={() => this.handleReplyEditId("", setFieldValue)}
                                              >
                                                Cancel
                                              </Button>
                                            
                                              <Button
                                              data-testid="sendEdit"
                                                variant="contained"
                                                onClick={() => this.handleReplyEdit(reply.id, values.replyEdit, setFieldValue)}
                                              >
                                                Send
                                              </Button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="replyComment"
                                            key={reply.id}
                                          >
                                            <CustomerReviewandReply
                                              user={false}
                                              data-testid="CustomerReviewandReply"
                                              replyData={reply}
                                              setFieldValue={setFieldValue}
                                              handleReviewReply={this.handleReviewReply}
                                              handleReplyEditId={this.handleReplyEditId}
                                              deleteReplyReview={this.deleteReplyReview}
                                            />
                                          </div>
                                        )}
                                      </>
                                    );

                                  }
                                )}
                              </>
                            );
                          })}
                      </div>
                    )}
                    {/* {comments container end} */}
                  </div>
                  {/* remove modal */}
                  <div>
                    <Dialog
                    data-testid="dialog"
                      open={this.state.openDialog}
                      onClose={() => this.handleDialog()}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Are you sure you want to delete the forum ?
                      </DialogTitle>
                      <DialogActions>
                        <span className="redContained customCancelBtn">
                          <CustomButton
                            color="secondary"
                            label="No"
                            data-testid="cancelBtn"
                            onClick={() => this.handleDialog()}
                          />
                        </span>
                        <span className="greenContained">
                          <CustomButton
                          data-testid="greenBtn"
                            color="secondary"
                            label="Yes"
                            onClick={() => {
                              this.handleDialog();
                              this.deleteForum();
                            }}
                            autoFocus
                          />
                        </span>
                      </DialogActions>
                    </Dialog>
                  </div>
                  {/* main wrapper end */}
                </Box>
              </Form>
            );
          }}
        </Formik>
        {/* ends: main card container */}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminFarmForumsEdit}
//@ts-ignore
export default withRouter(withStyles(commonCmptStyles)(AdminFarmForumsEdit));
// Customizable Area End
