// Customizable Area Start
import React from "react"
import ShoppingCartLayout from "./ShoppingCartLayout.web"
import {
  Box,
  Button,
  CircularProgress,
  FilledInput,
  Grid,
  Typography,
} from "@material-ui/core"
import "web/src/assets/css/webstyle.scss"
import "../../assets/css/shoppingcart.scss"
import { EmptyCartSvg } from "../assets"
import clsx from 'clsx'
import ShoppingCartTotalAmount from "./ShoppingCartTotalAmount.web"

interface IShoppingCartContentProps {
  cart: any
  couponLoader: boolean
  couponCode: string
  getAddressAndItems: () => void
  handleCheckoutOrder: () => void
  handleApplyRemoveCoupon: (isApplied: boolean) => void
  handleCouponCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ShoppingCartContent = ({
  cart,
  couponLoader,
  couponCode,
  getAddressAndItems,
  handleCheckoutOrder,
  handleApplyRemoveCoupon,
  handleCouponCodeChange,
}: IShoppingCartContentProps) => {
  const displayItems = cart?.product_cart_items
  const cartLength = cart?.cart_details?.data?.attributes?.total_count || 0
  const coupon = cart?.coupon?.data?.attributes?.coupon_code || ""
  const isCouponApplied = cart?.cart_details?.data?.attributes?.discount > 0
  const couponBtnTxt = isCouponApplied ? "Remove" : "Apply"

  const isAllCartCheckoutDisabled = () => {
    const isQuantityNotAvailable = Object.values(cart?.product_cart_items).some((item: any) => {
      return item.cart_items.data.some((productDetails: any) => {
        const { attributes } = productDetails
        return (attributes?.track_quantity > 0 && attributes?.available_quantity === 0) || !attributes.product_active
      })
    })

    return !cart.product_available || isQuantityNotAvailable
  }

  return cartLength === 0 ? (
    <Grid container spacing={2} className="empty_cart_container">
      <Grid item xs={12} sm={5}>
        <img src={EmptyCartSvg} alt="empty-cart" />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Typography>Your cart is empty</Typography>
        <Typography variant="h6">
          Looks like you have not added anything to your cart. Go ahead &amp;
          explore.
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <>
      <Box className="shopping_cart_header">
        <h3>
          {`${cartLength} ${cartLength < 2 ? "item" : "items"
            } in Shopping Cart`}
        </h3>
        <div className="green_line" />
      </Box>

      {Object.keys(displayItems)?.map((key: string, index: number) => {
        return (
          <Box key={key}>
            {index !== 0 && (
              <Box className="shopping_cart_separator" />
            )}
            <ShoppingCartLayout
              orderType={cart.type}
              deliveryTime={cart.delivery_time}
              isAcceptingOrder={cart.order_accepted}
              cartDetails={cart.product_cart_items[key]}
              getAddressAndItems={getAddressAndItems}
            />
          </Box>
        )
      })}

      <Grid container spacing={6}>
        <Grid item xs={12} md={7} lg={8}>
          <Box className="coupon_code_container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <Box className="apply_coupon_wrapper web_input_field">
                  <p>have coupon code</p>
                  <FilledInput
                    value={couponCode || coupon}
                    disabled={isCouponApplied}
                    placeholder="Add coupon code"
                    classes={{
                      root: clsx("add_coupon_input_root", {
                        ["add_coupon_applied"]: isCouponApplied,
                      }),
                      input: "add_coupon_input",
                    }}
                    onChange={handleCouponCodeChange}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Button
                  data-testid="cart_coupon_btn"
                  onClick={() => handleApplyRemoveCoupon(isCouponApplied)}
                  disabled={couponLoader || (!couponCode && !coupon)}
                  classes={{
                    root: clsx("apply_coupon_btn", {
                      ["remove_coupon_btn"]: isCouponApplied,
                    }),
                    label: "btn-label",

                  }}
                >
                  {couponLoader ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    couponBtnTxt
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <ShoppingCartTotalAmount
        cart={cart}
        displayItems={displayItems}
        isCheckoutDisabled={isAllCartCheckoutDisabled()}
        handleCheckoutOrder={handleCheckoutOrder}
      />
    </>
  )
}

export default ShoppingCartContent
// Customizable Area End
