import React from "react";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/forgotPassword.scss";
import { Logo } from "../src/assets";
import OnlineCreateNewPasswordController, {
  Props,
} from "./OnlineCreateNewPasswordController.web";

import { Box, Grid, Typography, TextField, Button } from "@material-ui/core";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FilledInput } from "@material-ui/core";
import {Formik} from "formik";
import {CircularProgress} from "@mui/material";
import {withRouter} from "react-router-dom";

class OnlineCreateNewPasswordWeb extends OnlineCreateNewPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Formik initialValues={{password: "", repeatPassword:"" }}
      validationSchema={this.state.changePasswordSchema}
      onSubmit={(values, actions) => {
        this.doChangePassword(values);
        console.log("values", values);
      }}
      >
        {({
              handleChange,
              handleSubmit,
              errors,
              values,
              setFieldValue,
              touched,
            }) => {
             // console.log(values);
              
              return (
      <div className="login_signup_container">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
          <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
            <Box className="layout_wrapper">
              <Box className="logo_wrapper">
                <img src={Logo} alt="logo" width="200px" />
              </Box>
              <Box className="form_wrapper">
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className="forgot_PW_tab_container">
                        <Typography
                          variant="subtitle1"
                          className="forgot_PW_tab"
                        >
                          Create New Password
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Typography className="terms_of_condition">
                          Create your new password here
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          width="100%"
                          className="text_field web_input_field"
                        >
                          <FilledInput
                            id="outlined-adornment-password"
                            placeholder="New Password"
                            startAdornment={
                              <InputAdornment position="end">
                                <LockOpenOutlinedIcon className="login_input_icons" />
                              </InputAdornment>
                            }
                            onChange={handleChange}
                            name="password"
                            type="password"
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                          />
                          <span className="error">
                            {touched.password && errors.password
                              ? errors.password
                              : null}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          width="100%"
                          className="text_field web_input_field"
                        >
                          <FilledInput
                            id="outlined-adornment-password"
                            placeholder="Confirm New Password"
                            name="repeatPassword"
                            type="password"
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="end">
                                <LockOpenOutlinedIcon className="login_input_icons" />
                              </InputAdornment>
                            }
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                          />
                          <span className="error">
                            {touched.repeatPassword && errors.repeatPassword
                              ? errors.repeatPassword
                              : null}
                          </span>
                        </Box>
                      </Grid>
                      {this.state.statusMsg ? ( <p style={{color:"green"}}>
                        Password saved successfully .
                      </p> ) : null}

                      <Grid item xs={12}>
                        <Box width="100%">
                          <Button
                            className="btn"
                            classes={{
                              label: "normal_login_btn",
                            }}
                            type="submit"
                            onClick={() => {handleSubmit()}}>
                            {this.state.isLoading ? <CircularProgress size={25} color="inherit"/>:" Reset Password"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
        </Grid>
      </div>
              )}}
      </Formik>
    );
  }
}
// @ts-ignore
export default withRouter(OnlineCreateNewPasswordWeb);