// Customizable Area Start
import React from "react";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import { withRouter } from "react-router-dom";

// css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import Chip from "@mui/material/Chip";
import {
  Box,
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
//components
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import Pagination from "@material-ui/lab/Pagination";
import BusinessTeachBanner from "./BusinessTeachBanner.web";
import { ScrollView } from "react-native";
import LandingPage2Controller, {Props} from "./landingPage2Controller.web";
import {courseBanner,updateActiveTab,getCourseListOnSearch,resetLearningFilters,setCourse,getCategoriesELearning,categorySelectChip, setCourseCurrentPage} from './assets';
import { connect } from "react-redux";
import Image from "material-ui-image"
import LazyLoad from "react-lazy-load";
// Customizable Area End
export class LandingPage2 extends LandingPage2Controller {
  private navigationPrevRef: React.RefObject<HTMLDivElement>;
  private navigationNextRef: React.RefObject<HTMLDivElement>;
  pageTopRef: any
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();
    this.pageTopRef = React.createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  selectInterest = (courses:any, categorySelectChip:any) => (
    <>
    <Grid container className="selectCourseContainer" >
                <Grid item xs={12}>
                  <h1
                    className="selectText"
                  >
                    Select Your Interest
                  </h1>
                </Grid>
    </Grid>
    <Grid container >
    <Grid item xs={12}>
      <div>
        <ul className="flex-2" >
          {courses && courses?.categoriesList?.map((i: any, index: number) => {
            return (
              <li key={i.id}><Chip 
                className={`chip ${courses?.selectedCategory?.includes(i.name) ? 'chip-active' : ''}`} 
                data-test-id="categoryChip"
                onClick={() => categorySelectChip(i.name)} 
              label={i.name} ></Chip> </li>
            )
          })}
        </ul>
      </div>

    </Grid>
  </Grid>
  </>
  )
// Customizable Area End
  render() {
    // Customizable Area Start
    const {courses, courseList,searchCourse,categorySelectChip} = this.props;
    return (
      <>
        {/* <div className="Header"> */}
        <ScrollView>
          <WebHeader />
          <WebNewRoutingTabs />
          
          {courses && courses.onSearch ?
            <>
              <Grid container style={{ marginTop: "2rem" }}>
                <div className="listSearchbox">
                  <CustomTextfield className="searchContainer" 
                     placeholder={searchCourse}
                     value={searchCourse}
                     data-test-id="searchCourses"
                     onChange={this.handleSearchValue}
                     onKeyDown={this.searchCourseByEnter}
                  />

                  <div className="circleBtn searchButton">
                    <CustomButton 
                    data-test-id="searchButton"
                     onClick={this.handleSearchCourse}
                    label="Search"
                    className="courseSearch" 
                    />
                  </div>
                </div>
              </Grid>
            </> :
            <>
            {this.selectInterest(courses,categorySelectChip)}  
            </>
          }
          
          <Grid id="courseList" container>
            <Grid item>
              <Typography
                variant="h5"
                className="courseText">
                Courses
              </Typography  >
              <Typography className="courselistText"> 
              Lorem ipsum dolar sit amet, consectetor adipiscing elit,sed to euis </Typography>
            </Grid>

          </Grid>
          
          {courses.isCoursesLoading ?
          <Box className="restaurant_landing_page_loader">
            <CircularProgress size={60} />
          </Box>
          :
          <>  
          <Grid container spacing={1} className="popCourseWrapper">
          {courseList && 
          courseList.slice((this.state.currentPage * this.state.coursesPerPage)-this.state.coursesPerPage, this.state.currentPage * this.state.coursesPerPage).map((i:any, index:any)=>{
            return(
              <>
                <Grid key={i.id} item xs={6} sm={6} md={4} lg={4} className="courseCardWrapper">
                    <Box className="courses_img_wrapper" data-test-id="courseCard" onClick={() => this.handleCourse(i.id)} >
                    <LazyLoad offset={100}>
                    <Image
                      src={i.attributes.banner_image?.url || courseBanner}
                      alt="Course banner"
                      style={{ height:'250px',padding: '20px', borderRadius: '30px' }}
                      animationDuration={1000}
                      loading={
                          <CircularProgress className="image_loader" size={25} />
                      }
                      />
                      </LazyLoad>

                    </Box>
                    <Typography
                      variant="h5"
                      className="course-name"
                    >
                      {" "}
                      {i.attributes.title}{" "}
                    </Typography>
                    <Typography variant="h6" className="author-name">
                      {" "}
                      by {i.attributes.first_name} {i.attributes.last_name}{" "}
                    </Typography>
                  </Grid>   
              </>
            )
          }) }
          </Grid> 

          <Grid container>
            <Grid
            item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "3rem 0",
              }}
            >
            {courses?.pagination?.total_pages > 1 && (
              <Box className="show_more_btn_wrapper">
                <Box className="pagination_grid">
                  <Box className="web_pagination_container">
                    <Pagination
                      data-test-id="pageChange"
                      count={courses?.pagination?.total_pages}
                      onChange={(_: any, value: number) =>{
                        this.handleCourseListPage(value)
                      }}
                      page={courses?.currentPage}
                      variant="outlined"
                      shape="rounded"
                      classes={{
                        root: "farm_pagination",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
              
            </Grid>
          </Grid>
          </>
          }
          <BusinessTeachBanner />
          <WebFooter />
          </ScrollView>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    courses : state.courses,
    courseList : state.courses.searchCourseList
    , searchCourse : state.courses.searchCourse,
  };
};
// Customizable Area End

// Customizable Area Start
const connectLandingPage2:any = withRouter(connect(mapStateToProps, { updateActiveTab,resetLearningFilters,getCourseListOnSearch,setCourse, getCategoriesELearning,categorySelectChip, setCourseCurrentPage})(LandingPage2));
//@ts-ignore
export default connectLandingPage2
// Customizable Area End