// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { ActiveTabEnum } from "../../../../../components/src/redux/Users/userEnums"
import { Message } from "../../../../../framework/src/Message"
export const configJSON = require("../../config.js")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    activeTab: string
    communityLatestForumsDesc: string
    updateActiveTab: (tabName: string) => void
    setCommunityHomeDesc: (data: { latest_forums_description: string, our_expert_description: string }) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityHomePageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    communityForumCMSApiCallId: string = ""
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { activeTab, updateActiveTab } = this.props
        activeTab !== ActiveTabEnum.Community && updateActiveTab(ActiveTabEnum.Community)
        this.getCommunityForumCMSApi()
    }

    getCommunityForumCMSApi = async () => {
        const { communityLatestForumsDesc } = this.props
        !communityLatestForumsDesc && this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.productApiContentType
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.communityForumCMSApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.communityForumCmsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.communityForumCMSApiCallId) {
                    if(!!responseJson?.data?.attributes) {
                        const { setCommunityHomeDesc } = this.props
                        setCommunityHomeDesc(responseJson.data.attributes)
                        this.setState({ loader: false })
                    }
                }
            }
        }
    }
    // Customizable Area End
}

