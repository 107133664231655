Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httpDeleteMethod = "DELETE"
exports.validationApiContentType = "application/json"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";

exports.AUTH_TOKEN = "authToken"
exports.noText = "No"
exports.yesCancelText = "Yes, cancel"
exports.confirmText = "Confirm"
exports.initiateRefundText = "Initiate refund"
exports.closeText = "Close"
exports.optionalText = "optional"
exports.cancelOrderMessage = "Do you want to cancel the order?"
exports.orderCancelledMessage = "Order cancelled successfully!"
exports.initiateRefundMessage = "Do you also want to initiate refund?"
exports.enterCommentMessage = "Enter comments for refund"
exports.enterYourCommentMessage = "Enter your comment"
exports.rejectCancellationRequestMessage = "Reject cancellation request"
exports.selectReasonMessage = "Select a reason for your rejection"
exports.cancellationAcceptedMessage = "Cancellation accepted successfully!"
exports.cancellationRejectedMessage = "Cancellation rejected successfully!"
exports.wantToAcceptCancellationMessage = "Do you want to accept cancellation request?"

exports.rejectionReasonsApiEndPoint = "others/get_rejection_reasons"
exports.cancellationApiEndPoint = "bx_block_order_management/orders/order_cancel"
exports.initiateRefundApiEndPoint = "bx_block_payments/payments/refund_payment"

exports.MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
}
// Customizable Area End