// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import SearchDiscussionDialogController, {
    Props,
} from "./SearchDiscussionDialogController.web"
import { connect } from "react-redux"
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/SearchRounded"
import SearchDiscussionCard from "./SearchDiscussionCard.web"
import { IDiscussion } from "../CommunityHomePage/CommunityLatestForumsController.web"
import { bindActionCreators } from "redux"
import { setCommunityLatestForums } from "../../assets"

class SearchDiscussionDialog extends SearchDiscussionDialogController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { isOpen, latestDiscussions, handleClose } = this.props
        const {
            loader,
            isLatestDiscussionsLoader,
            searchValue,
            searchedDiscussions,
        } = this.state
        const isLoading = loader || isLatestDiscussionsLoader
        const isNotSearchAndNotResult = !searchValue && searchedDiscussions.length === 0
        const isSearchAndNotResult = !!searchValue && searchedDiscussions.length === 0

        return (
            <Dialog
                maxWidth="md"
                fullWidth
                open={isOpen}
                onClose={() => handleClose()}
                classes={{
                    paper: "custom_dialog_paper",
                }}
            >
                <DialogTitle className="sdd_dialog_title">
                    <Box>
                        <TextField
                            value={searchValue}
                            className="sdd_search_field"
                            placeholder="Search in the forums"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="sdd_search_icon" />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                            onChange={this.handleSearchValueChange}
                            onKeyUp={this.handleSearch}
                        />
                    </Box>
                </DialogTitle>
                <DialogContent className="sdd_dialog_content">
                    <Box>
                        <Typography className="sdd_topic_heading">
                            {searchValue ? "Results" : "Topics"}
                        </Typography>
                        {isLoading ? (
                            <Box className="sdd_empty_container">
                                <CircularProgress size={22} />
                            </Box>
                        ) : (
                            <Box className="sdd_topics_list">
                                {isNotSearchAndNotResult &&
                                    latestDiscussions.map((discussion: IDiscussion) => {
                                        const {
                                            id,
                                            attributes: {
                                                author_name,
                                                date,
                                                discussion_heading,
                                                follows,
                                                description,
                                            },
                                        } = discussion

                                        return (
                                            <SearchDiscussionCard
                                                key={id}
                                                topicId={id}
                                                heading={discussion_heading}
                                                description={description}
                                                authorName={author_name}
                                                date={date}
                                                isFollowed={follows}
                                                searchValue={searchValue}
                                            />
                                        )
                                    })}

                                {isSearchAndNotResult ? (
                                    <Box className="sdd_empty_container">
                                        <Typography>
                                            No discussion found for the given search value
                                        </Typography>
                                    </Box>
                                ) : (
                                    searchedDiscussions.map((discussion: IDiscussion) => {
                                        const {
                                            id,
                                            attributes: {
                                                author_name,
                                                date,
                                                discussion_heading,
                                                follows,
                                                description,
                                            },
                                        } = discussion

                                        return (
                                            <SearchDiscussionCard
                                                key={id}
                                                topicId={id}
                                                heading={discussion_heading}
                                                description={description}
                                                authorName={author_name}
                                                date={date}
                                                isFollowed={follows}
                                                isSearchCard
                                                searchValue={searchValue}
                                            />
                                        )
                                    })
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        latestDiscussions: state.community.communityLatestForums,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCommunityLatestForums: bindActionCreators(
            setCommunityLatestForums,
            dispatch
        ),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SearchDiscussionDialog)
)
// Customizable Area End
