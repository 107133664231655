// Customizable Area Start
import React from "react"
import { Box, Typography, CircularProgress } from "@material-ui/core"
import GoogleMapReact from "google-map-react"
import { connect } from "react-redux"
import WebFooter from "../../../../components/src/WebFooter.web"
import GreenBanner from "../../../../components/src/GreenBanner.web"
import "../assets/css/restaurantmapview.scss"
import {
  getMapViewRestaurants,
  resetMapViewRestaurant,
  updateActiveTab,
  setSearchValueForRestaurant,
} from "../assets"
import RestaurantMapViewController, {
  Props,
  IRestaurantDetails,
} from "./RestaurantMapViewController.web"
import RestaurantCardMap from "./RestaurantCardMap.web"
import RestaurantCardList from "./RestaurantCardList.web"
const { GOOGLE_MAP_API_KEY } = require("../config.js")
// Customizable Area End

class RestaurantMapView extends RestaurantMapViewController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    // Customizable Area Start
    const { isLoading, mapViewRestaurants } = this.props
    const { activeRestaurant, lat, lng } = this.state
    const isRestaurantsExist = mapViewRestaurants.length > 0

    return (
      <>
        <Box>
          {isLoading ? (
            <Box className="restaurant_map_loader_wrapper">
              <CircularProgress size={30} color="inherit" />
            </Box>
          ) : (
            <>
              <Box className="restaurant_map_left_container">
                <Box className="restaurant_map_filter_container">
                  <Box className="restaurant_map_total_heading">
                    <Typography>
                      {mapViewRestaurants.length} restaurants
                    </Typography>
                  </Box>
                </Box>
                <Box className="restaurant_map_all_restaurants">
                  {isRestaurantsExist &&
                    mapViewRestaurants.map((restaurant: IRestaurantDetails) => {
                      const {
                        id,
                        attributes: {
                          name,
                          average_rating,
                          latitude,
                          longitude,
                          dish_name,
                          image,
                        },
                      } = restaurant
                      const imageUrl = image ? image.url : ""

                      return (
                        <RestaurantCardList
                          key={id}
                          isActive={id === activeRestaurant}
                          id={id}
                          dishName={dish_name}
                          lat={latitude}
                          lng={longitude}
                          rating={average_rating}
                          restaurantName={name}
                          image={imageUrl}
                          handleClick={this.handleActiveRestaurant}
                        />
                      )
                    })}
                </Box>
              </Box>

              <Box className="restaurant_google_map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                  center={{ lat, lng }}
                  zoom={15}
                  yesIWantToUseGoogleMapApiInternals
                  hoverDistance={20}
                >
                  {isRestaurantsExist &&
                    mapViewRestaurants.map((restaurant: IRestaurantDetails) => {
                      const {
                        id,
                        attributes: {
                          name,
                          average_rating,
                          latitude,
                          longitude,
                          org_address,
                          quote_time,
                          dish_name,
                          price,
                          image,
                        }
                      } = restaurant
                      const imageUrl = image ? image.url : ""

                      return (
                        <RestaurantCardMap
                          isActive={activeRestaurant === id}
                          id={id}
                          key={id}
                          image={imageUrl}
                          restaurantName={name}
                          rating={average_rating}
                          dishName={dish_name}
                          address={org_address}
                          averageTime={quote_time}
                          price={price}
                          lat={latitude}
                          lng={longitude}
                          handleOrderFood={this.handleOrderFoodSubmit}
                          openDetails={this.openRestaurantDetails}
                          closeDetails={this.closeRestaurantDetails}
                        />
                      )
                    })}
                </GoogleMapReact>
              </Box>
            </>
          )}
        </Box>
        <GreenBanner />
        <WebFooter />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    activeTab: state.user.activeTab,
    isLoading: state.restaurants.isLoading,
    mapViewRestaurants: state.restaurants.mapViewRestaurants,
  }
}
// Customizable Area End

// Customizable Area Start
export default connect(mapStateToProps, {
  updateActiveTab,
  getMapViewRestaurants,
  resetMapViewRestaurant,
  setSearchValueForRestaurant,
})(RestaurantMapView)
// Customizable Area End
