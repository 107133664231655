// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
// icons
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";

import CustomChipArray from "../../../components/src/ChipArray.web";

import {
  AppBar,
  Box,
  CssBaseline,
  Button,
  // IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@material-ui/core";

// css
import "../assets/css/educationsidebar.scss"
// Customizable Area End

// Customizable Area Start
const drawerWidth = 240;
// Customizable Area End

const webEducationFilterSidebar = (props: any) => {
  // Customizable Area Start
  const { window ,handleDrawerToggle, mobileOpen} = props;
  // const [mobileOpen, setMobileOpen] = React.useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const drawer = (
    <div>
      <CustomChipArray />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="educationSidebar">
      <CssBaseline />
      
      <Box className="drawerNav"
        component="nav"
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className="drawerContainer"
        
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
         
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
  // Customizable Area End
}

// Customizable Area Start
webEducationFilterSidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
// Customizable Area End

// Customizable Area Start
//@ts-ignore
export default withRouter(webEducationFilterSidebar);
// Customizable Area End 

// Customizable Area Start

// Customizable Area End
