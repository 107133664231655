// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Badge,
  MenuItem,
  IconButton,
  Menu
} from "@material-ui/core";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {
  Logo,
  DefaultProfileImg,
  getUserDetails,
  setLoginModalFalse,
  setLoginModalTrue,
  resetUserDetails,
  getFarmCategoriesAndTagsAction,
  toggleSidebar,
  setCartBadge,
  getCartItems,
  updateActiveTab,
  MenuIcon
} from "./assets";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteFillIcon from '@material-ui/icons/FavoriteRounded';
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import WebHeaderController from "./WebHeaderController.web";
import { Link, withRouter } from "react-router-dom";
import "./assets/css/webHeader.scss";
import { connect } from "react-redux";
import UserInitialization from "./UserInitilization.web";
import LoginSignUpDialog from "../../blocks/info-page/src/langinpageLoggedIn/LoginSignUpDialog.web";
import { AppRoutings } from "./utility/app-routing";
import NavbarSearch from "../../blocks/Search/src/components/NavbarSearch/NavbarSearch.web";
// Customizable Area End

class NewWebHeader extends WebHeaderController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { cartBadge, userInfo } = this.props
    const { isLoginDialog, menuAnchorEl } = this.state
    const open = Boolean(menuAnchorEl)

    return (
      <>
        <UserInitialization />
        <Box className="web_header">
          <Box>
            <IconButton
              className="toggle_menu_icon"
              onClick={this.props.toggleSidebar}
            >
              <img src={MenuIcon} alt="menu" />
            </IconButton>

            <Box className="web_header_logo_wrapper">
              <Link to={AppRoutings.Home}>
                <img src={Logo} alt="wtt" />
              </Link>
            </Box>
          </Box>
          <Box className="web_header_search_input_wrapper web_input_field">
            <NavbarSearch />
          </Box>
          <Box className="web_header_action_icons_wrapper">
            <Box>
              <IconButton
                className="web_header_icon_btn"
                disabled={this.isFavPage()}
                onClick={this.redirectToFavourites}
              >
                {this.isFavPage() ? (
                  <FavoriteFillIcon className="web_header_fav_fill" />
                ) : (
                  <FavoriteBorderIcon className="web_header_icon" />
                )}
              </IconButton>
            </Box>
            <Link to={AppRoutings.ShoppingCart}>
              <Box>
                <IconButton className="web_header_icon_btn">
                  <Badge
                    badgeContent={cartBadge}
                    overlap="rectangular"
                    classes={{
                      anchorOriginTopRightRectangular: "shopping_cart_badge",
                    }}
                  >
                    <ShoppingCartOutlinedIcon className="web_header_icon" />
                  </Badge>
                </IconButton>
              </Box>
            </Link>

            {JSON.stringify(userInfo?.userDetails) != "{}" ? (
              <Box>
                <Box className="web_header_select">
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.toggleMenu}
                  >
                    <ExpandMoreRoundedIcon
                      className={open ? "openExpandIcon" : ""}
                      fontSize="small"
                    />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    open={open}
                    anchorEl={menuAnchorEl}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    MenuListProps={{
                      classes: {
                        root: "web_header_menu_paper",
                      },
                    }}
                  >
                    {userInfo?.userDetails?.is_admin ? (
                      <MenuItem onClick={() => this.getRemainingRoles()}>
                        <Box>Admin</Box>
                      </MenuItem>
                    ) : null}

                    <Link to={AppRoutings.AccountSetting}>
                      <MenuItem>Account Setting</MenuItem>
                    </Link>
                    <MenuItem onClick={this.handleUserLogout}>
                      <Box>Log Out</Box>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className="web_header_profile">
                  <Link to={AppRoutings.AccountSetting}>
                    <img
                      src={userInfo?.userDetails?.profile_pic || DefaultProfileImg}
                      alt="user_profile"
                    />
                  </Link>
                </Box>
              </Box>
            ) : (
              <Button
                variant="contained"
                className="navbar_login_btn_wrapper"
                onClick={this.openLoginDialog}
              >
                Log in
              </Button>
            )}
          </Box>
        </Box>

        <LoginSignUpDialog
          isForgotPassword
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
        />
      </>
    )
    // Customizable Area End
  }

}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user, cartBadge: state.user.cartBadge }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(mapStateToProps, {
    getUserDetails,
    setLoginModalFalse,
    setLoginModalTrue,
    resetUserDetails,
    getFarmCategoriesAndTagsAction,
    toggleSidebar,
    setCartBadge,
    getCartItems,
    updateActiveTab
  })(NewWebHeader)
)
// Customizable Area End
