// Customizable Area Start
import React from "react"
import {
  Grid,
  Box,
  Button,
  TextField,
} from "@material-ui/core"
import { DefaultProfile } from "../assets"
import "../assets/css/comment.scss"
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded"
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded"
import ReplyWeb from "./reply.web"
import CommentController, { Props, IReply } from "./CommentController.web"
import LoginSignUpDialogWeb from "../langinpageLoggedIn/LoginSignUpDialog.web"
import CommentActions from "./CommentActions.web"
import CustomActionDialog from "../../../../components/src/CustomDialog.web"
import { connect } from "react-redux"

class CommentWeb extends CommentController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      userInfo,
      isCommunity,
      accountId,
      reviewId,
      fullName,
      profileImage,
      reviewDate,
      replies,
      handleReviewEdit,
      handleReviewsRemove,
    } = this.props

    const {
      reviewText,
      addReviewLoader,
      isAddingReview,
      showAllReplies,
      commentReplies,
      isLoginDialog,
      deleteCommentDialog,
      deleteCommentLoader,
      editCommentLoader,
      contentFlagLoader,
      isContentFlag,
      commentText,
      isEditingComment
    } = this.state
    const isLoggedInUserComment = userInfo?.userDetails?.id === accountId
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Box className="custom_comment_wrapper">
              <Box className="comment_main_container">
                <Box className="comment_image_wrapper">
                  <img
                    src={profileImage || DefaultProfile}
                    className="comment_profile_img"
                  />
                </Box>
                <Box width="100%">
                  <Box className="comment_main_comment">
                    <Box className="comment_info_container">
                      <h5>{fullName}</h5>
                      <p className="comment_date">{reviewDate}</p>
                    </Box>
                    <Box>
                      {
                        isEditingComment ? (
                          <Box mt={2}>
                            <TextField
                              className="reply_edit_textfield"
                              placeholder="Write your reply here..."
                              value={commentText}
                              disabled={!isEditingComment}
                              onChange={this.handleCommentChange}
                              InputProps={{ disableUnderline: true }}
                            />
                          </Box>
                        ) : (
                          <p className="comment">{commentText}</p>
                        )
                      }
                    </Box>

                    <CommentActions
                      isLoggedInUserComment={isLoggedInUserComment}
                      addReviewLoader={addReviewLoader}
                      commentText={commentText}
                      deleteCommentLoader={deleteCommentLoader}
                      editCommentLoader={editCommentLoader}
                      isContentFlag={isContentFlag}
                      contentFlagLoader={contentFlagLoader}
                      isAddingReview={isAddingReview}
                      reviewText={reviewText}
                      isEditingComment={isEditingComment}
                      handleEditComment={this.editCommentApi}
                      handleAddReply={this.handleAddReply}
                      handleReviewChange={this.handleReviewChange}
                      toggleAddReview={this.toggleAddReview}
                      openConfirmationDialog={this.handleDeleteComment}
                      handleContentFlag={this.handleContentFlag}
                      toggleEditComment={this.toggleEditComment}
                    />
                  </Box>

                  {commentReplies?.length > 0 &&
                    commentReplies?.map((reply: IReply) => {
                      const { id, attributes } = reply
                      const {
                        full_name,
                        date,
                        image,
                        description,
                        account_id,
                        report_spam
                      } = attributes
                      return (
                        <ReplyWeb
                          key={id}
                          isCommunity={isCommunity}
                          replyId={id}
                          reviewId={reviewId}
                          reviewerId={account_id}
                          fullName={full_name}
                          image={image?.url || ""}
                          reviewDate={date}
                          comment={description}
                          isSpamReported={report_spam}
                          handleReviewEdit={handleReviewEdit}
                          handleReviewsRemove={handleReviewsRemove}
                        />
                      )
                    })}

                  {replies.length > 1 && (
                    <Grid item xs={12} className="forum_show_replies_container">
                      <Button
                        endIcon={
                          showAllReplies ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        onClick={() => this.toggleAllReplies()}
                      >
                        {showAllReplies
                          ? "View Less Replies"
                          : "View More Replies"}
                      </Button>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <LoginSignUpDialogWeb
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
        />

        <CustomActionDialog
          actionBtnTitle="Yes"
          title="Are you sure want to delete this comment?"
          open={deleteCommentDialog}
          isLoading={deleteCommentLoader}
          isActionDisabled={deleteCommentLoader}
          isCloseDisabled={deleteCommentLoader}
          handleClose={this.cancelDeleteComment}
          handleAction={this.deleteCommentApi}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
      userInfo: state.user
  }
}

export default connect(mapStateToProps, {})(CommentWeb)
// Customizable Area End
