import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums";
export const configJSON = require("./config.js");
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export interface ICarousel {
    id: string
    type: string
    attributes: {
        heading: string
        learn_more: string
        image: {
            id: number
            url: string
            filename: string
        }
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    close?: any;
    open?: any;
    setIsAuth?: any;
    isAuth?: boolean;
    history: any
    activeTab: string
    wllpCarousels: ICarousel[]
    updateActiveTab: (tab: string) => void
    setWLLPData: (data: any) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LandingPageGuestController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    wllpApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            loader: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    buttonRedirect = (link: string) => {
        !!link && this.props.history.push(link)
    }

    async componentDidMount(): Promise<void> {
        this.wllpApi()
        const { activeTab, updateActiveTab } = this.props
        activeTab !== ActiveTabEnum.Home && updateActiveTab(ActiveTabEnum.Home)
    }

    wllpApi = async () => {
        const { wllpCarousels } = this.props
        const isWLLPDataExist = wllpCarousels?.length > 0
        !isWLLPDataExist && this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.validationApiContentType
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.wllpApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.wllpApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.wllpApiCallId) {
                    if(!!responseJson?.data?.attributes) {
                        const { setWLLPData } = this.props
                        setWLLPData(responseJson.data.attributes)
                        this.setState({ loader: false })
                        return
                    }
                    toast.error("Something went wrong")
                }
            } else {
                if (apiRequestCallId === this.wllpApiCallId) {
                    this.setState({ loader: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }

}