// Customizable Area Start
import React from "react"
import { Grid, Box, Typography } from "@material-ui/core"
import { objectType } from "reactjs-social-login"
import GoogleLoginServiceButton from "./LoginServicesButtons/GoogleLoginServiceButton.web"
import FacebookLoginServiceButton from "./LoginServicesButtons/FacebookLoginServiceButton.web"
import InstagramLoginServiceButton from "./LoginServicesButtons/InstagramLoginServiceButton.web"
const configJSON = require("./config.js")

interface Props {
  isFbConnected: boolean
  isGoogleConnected: boolean
  isInstagramConnected: boolean
  setResponse: (data: objectType | undefined, type?: string) => void
}

const AccountSettingLoginServices = ({
  isFbConnected,
  isGoogleConnected,
  isInstagramConnected,
  setResponse,
}: Props) => {
  const isAnyConnected = isFbConnected || isGoogleConnected || isInstagramConnected

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box className="account_details_heading_wrapper">
            <h5 className="account_details_heading">Connected Login Service</h5>
          </Box>
        </Grid>
      </Grid>
      {!isAnyConnected && <Grid container spacing={6}>
        <Grid item lg={12}>
          <Box className="">
            <Typography variant="h6" className="not_connected_s">
              {configJSON.serviceNotConnectedMessage}
            </Typography>
          </Box>
        </Grid>
      </Grid>}

      <Box mt={1} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <GoogleLoginServiceButton
              isConnected={isGoogleConnected}
              handleGoogleResponse={setResponse}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FacebookLoginServiceButton
              isFbConnected={isFbConnected}
              handleFacebookResponse={setResponse}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InstagramLoginServiceButton
              isConnected={isInstagramConnected}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AccountSettingLoginServices
// Customizable Area End
