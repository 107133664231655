// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config.js");
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  location: any;
  history: any;
  updateActiveTab:any
}

interface S {
  policies: any;
  loading: any;
  value: any;
}

interface SS {
  id: any;
}

export default class PoliciesController extends BlockComponent<Props, S, SS> {
  getPoliciesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      policies: "",
      value: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    !!this.props?.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Home)
    this.getPolicies()
    let tab = window.location.pathname.split("/")[2];
    tab  &&
      this.setState({
        value: Number(tab),
      });

  };
  async componentDidUpdate(prevState:any){
    let tab = window.location.pathname.split("/")[2];
    if(tab!=this.state.value){
      this.setState({
        value: Number(tab),
      });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.successApiCall(responseJson, apiRequestCallId);
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getPoliciesApiCallId) {
      this.setState({
        loading: false,
        policies: responseJson,
      });
    }
  };

  getPolicies = async () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoliciesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPoliciesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({
      value: newValue,
    });

    this.props.history.push(`/policy&info/${newValue}`);
  };

  settingBreadCrumb=(value:any)=>{
    if (value == 0) {
      return "Privacy Policy";
    } else if (value == 1) {
      return "Terms and conditions";
    } else if (value == 2) {
      return "Policy For Buyer";
    } else {
      return "Policy For Seller";
    }

  }
}

// Customizable Area End
