// Customizable Area Start
import React  from "react";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";
import {
  MenuItem,
  Select,
  Grid,
  FormControl,
  withStyles,
  Hidden,
  Button
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import AdminCreateCourseListController, {
  Props,
} from "./AdminCreateCourseListController.web";
import CustomLearningCreateCourseTab from "./CustomLearningCreateCourseTab.web";
import { commonCmptStyles } from "./dropDownCss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TagsSuggestion from "../../../components/src/TagsSuggestion.web";

const Schema = Yup.object().shape({
  country_name: Yup.string().required("This field is required."),
  hsc: Yup.string().required("This field is required."),
});

class AdminLearningCreateCourseList extends AdminCreateCourseListController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const farmStatus = ["active", "deactivate", "draft"];
    const details =
      this.state.courseDetails && this.state.courseDetails.attributes;

    return (
      <>
        <Box className="adminAddProductWrapper">
          <Formik
            innerRef={this.state.formRef}
            enableReinitialize
            initialValues={{
              category_id: (details && details.category_id) || "default",
              farm_status: (details && details.status) || "draft",
              tags: (details && details.tags.toString()) || "",
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
            }}
          >
            {(formikProps) => {
              const {
                values,
                setFieldValue,
              } = formikProps;
              return (
                <Box className="adminAddProductWrapper">
                  <Form noValidate autoComplete="off">
                    <Grid container xs={12} spacing={2} justifyContent="center">
                      <Grid item xs={12} md={8}>
                        <div className="productDetailsContainer">
                          {/* header start */}
                          <div className="header">
                            <div onClick={() => {
                              this.props.history.push(`/admin/learning/course-list/${this.getQuery().get("contributer_id")}`)
                            }} data-testid="backBtn">
                              <ArrowBackIcon />
                            </div>
                            <h4>{`${
                              this.getMode() == "edit" ? "Edit" : "Create"
                            } Course`}</h4>
                          </div>
                          {/* header end */}

                          {/* start: CustomLearningCreateCourseTab */}
                          <CustomLearningCreateCourseTab
                            handleOpenModal={this.handleRestOrderOpenModal}
                            rightSidevalues={values}
                          />
                          {/* end: CustomLearningCreateCourseTab */}

                          {/* course detail content */}

                          {/* end: course detail content */}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div className="productStatusContainer commonDropdown">
                          {/* product status start */}
                          <div className="productStatus">
                            <h4>Course status</h4>

                            <div className="outlineSelect">
                              <div
                                className={`customSelect itemStatus ${values.farm_status ==
                                  "draft" &&
                                  "warning-variant"} ${values.farm_status ==
                                  "deactivate" && "danger-variant"}`}
                              >
                                <FormControl>
                                  <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={values.farm_status}
                                    data-testid="courseStatus"
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        "farm_status",
                                        e.target.value
                                      );
                                    }}
                                    //@ts-ignore
                                    disabled={this.getTab() == 1}
                                    style={{opacity:`${this.handleOpacity()}`}}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      classes: {
                                        paper: classes.itemStatus,
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {farmStatus.map(
                                      (status: string) => {
                                        return (
                                          <MenuItem value={status} key={status}>
                                            {status}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            {/* <div className="btns">
                              <Button>Delete</Button>
                            </div> */}
                          </div>
                          {/* product status end */}
                          {/* product type start */}
                          <div className="productTypeContainer">
                            <h5 style={{"textTransform":"capitalize"}}>Course Category</h5>
                            <div className="green-outline">
                              <div className="customSelect ">
                                <FormControl>
                                  <Select
                                  data-testid="category"
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={
                                      values?.category_id
                                    }
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        "category_id",
                                        e.target.value
                                      );
                                    }}
                                    //@ts-ignore
                                    disabled={this.getTab() == 1}
                                    style={{opacity:`${this.handleOpacity()}`}}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      classes: {
                                        paper: classes.itemStatus,
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    <MenuItem disabled value="default">
                                      <em>Select type</em>
                                    </MenuItem>
                                    {this.state.categories ? (
                                      this.state.categories.length > 0 &&
                                      this.state.categories.map(
                                        (item: any, index: any) => {
                                          return (
                                            <MenuItem
                                              value={item.id}
                                              key={item.id}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          );
                                        }
                                      )
                                    ) : (
                                      <MenuItem value="none">
                                        No category Found
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <p>
                              Improve buyer discovery , analytics & work flows
                            </p>

                            <div className="outlineTextfield boxRadiusBorder">
                              <label>TAGS</label>
                              <br />
                              <TagsSuggestion
                                name="tags"
                                value={values.tags}
                                disabled={this.getTab() === "1"}
                                placeholder="Technology,Science etc..."
                                handleValueChange={(value: string) => setFieldValue("tags", value)}
                              />
                            </div>

                            {this.getTab() === "0" && <Hidden mdUp>
                            <div className="edu-btn-container btn-container">
                              <Button
                                className="cancel"
                                onClick={this.props.history.goBack}
                              >
                                Cancel
                              </Button>
                              <Button className="save" type="submit">
                                Save
                              </Button>
                            </div>
                          </Hidden>}
                          </div>
                          {/* product type end */}
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
}

export {AdminLearningCreateCourseList}
export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningCreateCourseList)
);
// Customizable Area End
