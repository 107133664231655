// Customizable Area Start
import React, { Component } from "react";
import { Menu, MenuItem } from "@material-ui/core";

interface IMenu {
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

interface IProps {
  anchorEl: HTMLElement | null;
  menus: IMenu[];
  closeOrderMenu: () => void;
}

export class CustomOrderCancellationMenu extends Component<IProps> {
  render() {
    const { anchorEl, menus, closeOrderMenu } = this.props;

    return (
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeOrderMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        getContentAnchorEl={null}
        MenuListProps={{
          classes: {
            root: "farm-order-menu"
          }
        }}
      >
        {menus.map((menu: IMenu) => {
          const { label, disabled, onClick } = menu;
          return (
            <MenuItem key={label} disabled={disabled} onClick={onClick}>
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }
}

export default CustomOrderCancellationMenu;
// Customizable Area End
