const initialState = {
    popularCourses:[],
    learningDetails : [],
    isCoursesLoading:false,
    searchCourse : '',
    searchCourseList: [],
    selectedCategory : [],
    categoriesList : [],
    isCategoryCoursesLoading : false,
    onSearch : false,
    count : 9,
    currentPage : 1,
    pagination:{}
}

export const elearningReducer = (state = initialState, {type,payload}={}) =>{
    switch (type){
        case 'SET_POPULAR_COURSES_LIST' :
            return {
                ...state,
                popularCourses: payload,
                isCategoryCoursesLoading : false,
            }
        case 'SET_LEARNING_DETAILS' : 
            return {
                ...state,
                learningDetails : payload,
                currentPage : 1
            }    
        case 'SET_ELEARNING_COURSES_IS_LOADING_TRUE':
                return {
                    ...state,
                    isCoursesLoading: true
                }
        case 'SET_ELEARNING_COURSES_IS_LOADING_FALSE':
                return {
                    ...state,
                    isCoursesLoading: false
                }
        case 'SET_ELEARNING_SEARCH_VALUE': 
                return {
                    ...state,
                    searchCourse : payload, 
                    onSearch : true
                }
        case 'GET COURSE ON SEARCH' : 
                return {
                    ...state,
                    searchCourseList : payload 
                } 
        case 'GET CATEGORIES OF ELEARNING' :
                return {
                    ...state,
                    categoriesList : payload
                }    
        case 'SET CATEGORY FOR ELEARNING' : 
            if(state.selectedCategory.includes(payload)){
                return {
                    ...state,
                    selectedCategory : state.selectedCategory.filter((item) => item !== payload)
                }
            }else{
                return {
                    ...state,
                    selectedCategory : [payload, ...state.selectedCategory]
                } 
            }  
        case 'SET_ELEARNING_CATEGORY_COURSES_IS_LOADING_TRUE' :
                return {
                    ...state,
                    isCategoryCoursesLoading : true
                }  
        case 'SET_ELEARNING_CATEGORY_COURSES_IS_LOADING_FALSE' :
                return {
                    ...state,
                    isCategoryCoursesLoading : false
                }   
        case 'SET_COURSE_CURRENT_PAGE' :
            return {
                ...state,
                currentPage : payload
            }
        case 'SET_PAGINATION_FOR_COURSE_LIST':
            return {
                ...state,
                pagination: payload
            }    
        case 'RESET_ELEARNING_FILTERS' : 
            return {
                ...state,
                searchCourse : '',
                selectedCategory : [],
            }    
        case 'RESET_SEARCH_FILTERS' : 
            return {
                ...state,
                onSearch : false
            }                                                 
        default:
                return state          
    }
    
}              
