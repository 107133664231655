import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";
const configJSON = require("./config.js")
// Customizable Area End
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";

export interface Props {
    history?: any;
    location?:any
    // Customizable Area Start
    updateActiveTab?: (activeTab: string) => void
    list:any
    taskData : any
    isVideoComplete? : boolean
    isLessonComplete?: boolean
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    taskData : any
    isActive : any
    accountId : any
    taskResult : any
    subSectionId : string
    taskId : string,
    expanded : string,
    isModalOpen : boolean
    lessonStat : boolean
    openDialog : boolean
    loader : boolean
    // Customizable Area End
}

interface SS {
    id : any
}

export default class TaskController extends BlockComponent<
Props, 
S,
SS
>{
  // Customizable Area Start
    getTaskDetailsCallId : any
    taskResultCallId : any
    setBooleanAnsCallId : any
    setShortAnsCallId : any
    setLongAnsCallId : any 
    taskStatusCallId : any
    lessonCompleteCallId : any
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            taskData : [],
            isActive : false,
            accountId : '',
            taskResult : [],
            subSectionId : '',
            taskId : '',
            expanded : '',
            isModalOpen : false,
            lessonStat : false,
            openDialog : false, 
            loader : false
        }
        // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
      
        //---------------------
        // Customizable Area Start
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        if(id) {
          this.getTaskDetails(id)
          this.setState({subSectionId : id})
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const data = responseJson && !(responseJson.error || responseJson.errors)
    
          this.handleSuccessResponse(data,apiRequestCallId,responseJson)
          
          
        }

        // Customizable Area End
    }

    // Customizable Area Start

    handleSuccessResponse = (data:any, apiRequestCallId:string, responseJson:any) => {
      if (data && apiRequestCallId != null && apiRequestCallId === this.getTaskDetailsCallId) {
            
        const account_Id = localStorage.getItem('cartId');
        this.setState({accountId : account_Id})
            this.setState({taskData : responseJson.data})
            let taskRes = this.state.taskData.map((i:any) => i?.attributes?.task_results)
            if(taskRes.length > 0){
              let data = taskRes.flat().map((i:any) => i)
              let task = data.filter((i:any) => i?.attributes?.account_id == `${Number(this.state.accountId)}`)
              this.setState({taskResult : task})
            }
          }
          this.handleSuccessRes(data, apiRequestCallId)
          

          if (data && apiRequestCallId != null && apiRequestCallId === this.lessonCompleteCallId) {
            const lessonStat = responseJson.data?.attributes?.lesson_completed
            this.setState({lessonStat : lessonStat, openDialog: true})
          }
    } 

    handleSuccessRes = (data:any, apiRequestCallId:any) => {
      if (data && apiRequestCallId != null && apiRequestCallId === this.taskResultCallId ){
        this.setState({loader: false})
        this.setTaskStatus(this.state.taskId)
        this.getTaskDetails(this.state.subSectionId)
      }

      if (data && apiRequestCallId != null && apiRequestCallId === this.setBooleanAnsCallId ){
        this.setState({loader: false})
        this.setTaskStatus(this.state.taskId)
        this.getTaskDetails(this.state.subSectionId)
      }
 
      if (data && apiRequestCallId != null && apiRequestCallId === this.setShortAnsCallId ){
        this.setState({loader: false})
        this.setTaskStatus(this.state.taskId)
        this.getTaskDetails(this.state.subSectionId)
      }

      if (data && apiRequestCallId != null && apiRequestCallId === this.setLongAnsCallId ){
        this.setState({loader: false})
        this.setTaskStatus(this.state.taskId)
        this.getTaskDetails(this.state.subSectionId)
      }
      
    }

    getTaskDetails = async(id:any) => {
        const token = await StorageProvider.get("authToken");
        const header = {
          "Content-type": 'application/json',
          token : token
        };
        const taskMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getTaskDetailsCallId = taskMessage.messageId;
    
        taskMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/tasks?course_sub_section_id=${id}`
        );
        taskMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        taskMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(taskMessage.id, taskMessage);
    }

    setOptionRes = async(ans:any, taskId:any) => {
      this.setState({loader : true})
        const token = await StorageProvider.get("authToken");
        const header = {
          token : token
        };
    
        const formdata = new FormData();
        formdata.append("task_id", taskId)
        {ans.length > 1 && typeof(ans)!== "string" ?  
          ans.forEach((answer:any) => {
            formdata.append("selected_answer[]", answer)
          })
          :
          formdata.append("selected_answer[]", ans)
        }
        
        const taskResMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.taskResultCallId = taskResMessage.messageId;
    
        taskResMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/task_results`
        );
        taskResMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        taskResMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        taskResMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        runEngine.sendMessage(taskResMessage.id, taskResMessage);
      }

    setBoolAns = async(ans:any,taskId:any) => {
      this.setState({loader : true})
      const token = await StorageProvider.get("authToken");
      const header = {
        "Content-type": 'application/json',
        token : token
      };
  
      const httpBody = {
        "task_id": taskId,
        "bool_answer" : ans
      };
  
      const boolAnsMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.setBooleanAnsCallId = boolAnsMessage.messageId;
  
      boolAnsMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library/task_results`
      );
      boolAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      boolAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      boolAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(boolAnsMessage.id, boolAnsMessage);
    }

    setShortAns = async(taskId:any, ans:any) => {
      this.setState({loader : true})
      const token = await StorageProvider.get("authToken");
      const header = {
        token : token
      };
      const formdata = new FormData();
      formdata.append("task_id", taskId);
      formdata.append("short_answer", ans) ;
  
      const shortAnsMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.setShortAnsCallId = shortAnsMessage.messageId;
  
      shortAnsMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library/task_results`
      );
      shortAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      shortAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      shortAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(shortAnsMessage.id, shortAnsMessage);
    }

    setLongAns = async(taskId:any, ans:any) => {
      this.setState({loader : true})
      const token = await StorageProvider.get("authToken");
      const header = {
        token : token
      };
  
      const formdata = new FormData();
      formdata.append("task_id", taskId);
      formdata.append("long_answer", ans) ;
  
      const longAnsMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.setLongAnsCallId = longAnsMessage.messageId;
  
      longAnsMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library/task_results`
      );
      longAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      longAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      longAnsMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(longAnsMessage.id, longAnsMessage);
    }

    setTaskStatus = async(taskId:any) => {
      const token = await StorageProvider.get("authToken");
        const header = {
          "Content-type": 'application/json',
          token : token
        };
    
        const httpBody = {
          "task_id": taskId,
          "task_status" : "complete"
        };
          
        const taskStatMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.taskStatusCallId = taskStatMessage.messageId;
    
        taskStatMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_library/task_results/create_user_task_result`
        );
        taskStatMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        taskStatMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        taskStatMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        runEngine.sendMessage(taskStatMessage.id, taskStatMessage);
    }

    lessonComplete = async() => {
      const token = await StorageProvider.get("authToken");
      const header = {
        "Content-type": 'application/json',
        token : token
      };

      const httpBody = {
        id: this.state.subSectionId
      }
  
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.lessonCompleteCallId = message.messageId;
  
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.courseMarkAdCompleteApiEndPoint
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )

      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
  
      runEngine.sendMessage(message.id, message); 
  }

    handleTaskAns = (ans:any, taskId:any) => {
      if(ans != 0){
        this.setOptionRes(ans, taskId) 
        this.setState({taskId : taskId}) 
      }
      else{
        toast.error("Please select the option before submitting.")
      }
    }

    handleBoolAns = (ans:any, taskId:any) => {
      if(ans){
      this.setBoolAns(ans,taskId)
      this.setState({taskId : taskId})
      }
      else{
        toast.error("Please select the option before submitting.")
      }
    }

    handleShortAnswer = (ans:any, taskId:any) => {
      if(ans){
      this.setShortAns(taskId,ans)
      this.setState({taskId : taskId})
      }
      else{
        toast.error("Please attempt the question before submitting.")
      }
    }

    handleLongAnswer = (ans:any, taskId:any) => {
      if(ans){
      this.setLongAns(taskId,ans)
      this.setState({taskId : taskId})
      }
      else{
        toast.error("Please attempt the question before submitting.")
      }
    }

    handleComplete = () => {
      this.lessonComplete()
    }
    // Customizable Area End
}