// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
import { toast } from "react-toastify"
import { ISearchCourse } from "../../interfaces/search"
import queryString from "query-string"
import { ICategory } from "../../../../../components/src/interfaces/common"
const configJSON = require("../../config.js")

export interface Props extends RouteComponentProps {}

interface S {
  loader: boolean
  categoriesLoader: boolean
  priceRangeLoader: boolean
  categories: ICategory[]
  minPrice: number
  maxPrice: number
  courses: ISearchCourse[]
  totalPages: number
}

interface SS {
  id: any
}

export default class SearchCoursesController extends BlockComponent<
  Props,
  S,
  SS
> {
  searchCoursesApiCallId: string = ""
  coursesCategoriesApiCallId: string = ""
  coursesPriceRangeApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]

    this.state = {
      loader: false,
      categoriesLoader: false,
      priceRangeLoader: false,
      categories: [],
      minPrice: 0,
      maxPrice: 0,
      courses: [],
      totalPages: 0,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    await this.coursesCategoriesApi()
    await this.coursesPriceRangeApi()
    this.handleSearchCourses()
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.handleSearchCourses()
    }
  }

  scrollToTop = () => {
    const element = document.getElementsByClassName("web_header")
    if (element && element.length > 0)
      element[0].scrollIntoView({ behavior: "smooth" })
  }

  enableSearchLoader = () => {
    this.setState({ loader: true })
  }

  disableSearchLoader = () => {
    this.setState({ loader: false })
  }

  handleSearchCourses = () => {
    this.scrollToTop()
    const { search } = this.props.location
    const parsedQueryString = queryString.parse(search)
    const { query, filter_by_category_name, ...rest } = parsedQueryString
    const page = rest.page ?? 1
    const per = rest.per ?? 16
    const category = filter_by_category_name ?? ""

    const updatedQueryString = queryString.stringify({
      ...rest,
      search: query,
      page,
      per,
      filter_by_category_name: [category]
    })

    if (query) {
      this.searchCoursesApi(`?${updatedQueryString}`)
    } else {
      this.props.history.goBack()
    }
  }

  searchCoursesApi = async (query: string) => {
    this.enableSearchLoader()

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.searchCoursesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchCoursesApiEndPoint + query
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  coursesCategoriesApi = async () => {
    this.setState({ categoriesLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.coursesCategoriesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndPoint + configJSON.categoryTypeCourseQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  coursesPriceRangeApi = async () => {
    this.setState({ priceRangeLoader: true })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.coursesPriceRangeApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceRangeApiEndPoint + configJSON.typeCourseQuery
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleApiSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.searchCoursesApiCallId) {
      if (responseJson.data) {
        const {
          data,
          meta: {
            pagination: { total_pages },
          },
        } = responseJson
        this.setState({ courses: data, totalPages: total_pages })
      } else {
        toast.error(configJSON.SOMETHING_WENT_WRONG)
      }
      this.disableSearchLoader()
    }

    if (apiRequestCallId === this.coursesCategoriesApiCallId) {
      this.setState({ categoriesLoader: false, categories: responseJson })
    }

    if (apiRequestCallId === this.coursesPriceRangeApiCallId) {
      const { min_price, max_price } = responseJson
      this.setState({
        priceRangeLoader: false,
        minPrice: Number(min_price),
        maxPrice: Number(max_price),
      })
    }
  }

  handleApiErrorResponse = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.searchCoursesApiCallId) {
      this.disableSearchLoader()
      this.setState({ courses: [] })
      toast.error(configJSON.SOMETHING_WENT_WRONG)
    }
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (response && !response.errors) {
        this.handleApiSuccessResponse(apiRequestId, response)
      } else {
        if (await isTokenExpired(response)) {
          return
        }
        this.handleApiErrorResponse(apiRequestId, response)
      }
    }
  }
}
// Customizable Area End
