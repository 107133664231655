// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers';
import history from '../../components/src/utility/useHistory'


//Redux Store Import
import { Provider } from 'react-redux';
import store from '../../components/src/redux/store';

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 8000;
  return library
}

ReactDOM.render(
  <Router history={history}>
      <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
        </Web3ReactProvider>
      </Provider>   
      
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
