// Customizable Area Start
import { Dialog, DialogActions, DialogTitle, FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import React from "react";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import SearchIcon from "@material-ui/icons/Search";
import AdminLearningCampaignController, {Props} from "./AdminLearningCampaignController.web";
import Loader from "../../../components/src/Loader.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import { withRouter } from "react-router-dom";
import { commonCmptStyles } from "./dropDownCss";
import "../../../web/src/assets/css/style.scss";
import CustomBorderLinearProgressbar from "../../../components/src/CustomBorderLinearProgressbar.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import { configJSON } from "./AdminAnalyticsController.web";

interface TableProps {
    rowCount: number
    numSelected: number
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    orderBy?: string
  }
  
  function EnhancedTable(props: TableProps) {
    const { numSelected, rowCount, onSelectAllClick } = props
  
    return (
      <TableHead className="tableHead">
        <TableRow>
          <TableCell>
            <div className="productHead">
              <CustomCheckboxField
                id={-1}
                data-test-id="table-head"
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
              <span>{configJSON.campaignNameLabel}</span>
            </div>
          </TableCell>
          <TableCell align="center"> {configJSON.statusLabel}</TableCell>
          <TableCell align="center"> {configJSON.campaignIdLabel}</TableCell>
          <TableCell> {configJSON.raisedDonationLabel}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
    );
}

export class AdminLearningCampaigns extends AdminLearningCampaignController{
    constructor(props:Props){
        super(props);
    }

    render(){
        const learningStatus = configJSON.campaignStatusMenu;
        const { classes } = this.props;
        const {search, loading, campaignsCurrentPage,
            campaignsPerPage,
            totalCampaignCount,
            totalCampaignPage,
            campaignList,bulkAction,
            selectedCampaigns,
            openDialog} = this.state
        const currentTableList = campaignList 
        const menu = configJSON.options;
        const startingIndex = (campaignsCurrentPage - 1) * campaignsPerPage + 1   
        const showStatus = () => (
          learningStatus.map((status: any, index: any) => {
            return (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            );
          })
        )
        return(
            <>
            <div className="tableContainer">
          <Loader loading={loading} />
          <div className="headerListContainer">
            <div className="prodListHeader campaignListHeader">
              <div className="blocks">
                <h4>{configJSON.campaignsLabel}</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div
                    className="addButton"
                    onClick={this.handleCreateCampaign}
                  >
                    <CustomButton label={configJSON.createCampaignText} />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                  id="input-with-icon-textfield"
                  value={search}
                  onChange={this.handleSearchCampaign}
                  variant="standard"
                  placeholder={configJSON.restOrderPlaceholderText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect itemStatus dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label" style={{"color":`${selectedCampaigns.length > 1 ? "black":"#ccc"}`}}>{configJSON.actionsLabel}</InputLabel>
                      <Select
                        data-test-id="actions"
                        className="menu-customize"
                        value={bulkAction}
                        disabled={selectedCampaigns.length < 2}
                        onChange={this.bulkActions}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText,
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText,
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.itemStatus,
                          },
                        }}
                      >
                        {showStatus()}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-block commonDropdown  campTable">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                {currentTableList?.length > 0 && (
                  <EnhancedTable
                    onSelectAllClick={this.handleSelectAllClick}
                    data-test-id="table"
                    numSelected={selectedCampaigns.length} 
                    rowCount={currentTableList?.length}
                  />
                )}
                <TableBody>
                  {currentTableList?.length > 0 ? (
                    currentTableList.map((item: any, index: any) => {
                      const isItemSelected = this.isSelectedCampaigns(item.id);
                      return (
                        <TableRow key={item.id}>
                          <TableCell scope="row">
                            <div
                              className="productCell"
                              onClick={(event) =>
                                this.handleClickCampaignsRow(event, item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={item.attributes?.banner?.url || ""}
                                className="prodImage"
                                alt="img"
                              />
                              <p>{item.attributes.heading}</p>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div
                              className={`customSelect itemStatus ${item.attributes.status == configJSON.draftStatus && configJSON.warningVariantText
                                }`}
                            >
                              <FormControl>
                                <Select
                                  data-test-id="selectStatus"
                                  value={item.attributes.status}
                                  onChange={(e: any) => {
                                    this.updateCampaignStatus(item.id, e.target.value);
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: configJSON.bottomText,
                                      horizontal: configJSON.leftText,
                                    },
                                    transformOrigin: {
                                      vertical: configJSON.topText,
                                      horizontal: configJSON.leftText,
                                    },
                                    classes: {
                                      paper: classes.itemStatus,
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {showStatus()}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            {item.attributes?.campaign_id}
                          </TableCell>

                          <TableCell align="center">
                            <div className="fundProgressBar">
                              <div className="fundScale">
                                <span>0</span>
                                <span>
                                  $
                                  {parseInt(
                                    //@ts-ignore
                                    item.attributes.required_donation / 2
                                  )}
                                </span>
                                <span>${item.attributes.required_donation}</span>
                              </div>
                              <CustomBorderLinearProgressbar
                                max={item.attributes.required_donation}
                                value={item.attributes.donation_raised}
                              />
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                              data-test-id="basic-menu"
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                                item={item}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ width: "100%", padding: "0" }}>
                        <CustomNoRecordsFound
                          routeUrl={`/admin/learning/add-campaigns?mode=create`}
                          title={configJSON.campaignTitle}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {currentTableList?.length > 0 && <div className="paginationRow restOrderPagination">
              <div>
                {startingIndex}-{startingIndex + campaignList.length - 1} of{" "}
                {totalCampaignCount} {configJSON.itemsText}
              </div>
              <CustomPagination
                count={totalCampaignPage}
                data-test-id="pagination"
                boundaryCount={1}
                siblingCount={1}
                page={campaignsCurrentPage}
                pagePerView={campaignsPerPage}
                shape={configJSON.roundedText}
                increasePagePerView={this.handleIncreasePageView}
                decreasePagePerView={this.handleDecreasePageView}
                handlePageChange={this.handleChangeTablePage}
              />
            </div>}
          </div>

          <div>
            <Dialog
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              open={openDialog}
              onClose={this.handleDialog}
            >
              <DialogTitle id="alert-dialog-title">
              {configJSON.campaignDelDialogText}
              </DialogTitle>
              <DialogActions>
                <span className="redContained customCancelBtn">
                  <CustomButton
                    label={configJSON.noText}
                    color="secondary"
                    onClick={this.handleDialog}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                    label={configJSON.yesText}
                    data-test-id="yesBtn"
                    color="secondary"
                    onClick={() => {
                      this.handleDialog();
                      this.deleteCampaign();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
          </div>
            </>
        )
    }
}
//@ts-ignore
const learningCampRouter:any = withRouter(withStyles(commonCmptStyles)(AdminLearningCampaigns))

export default learningCampRouter;
// Customizable Area End