import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import * as Yup from "yup";
// Customizable Area End

export interface Props {
  // Customizable Area Start
    navigation: any;
    id: string;
    location?: any;
    history?: any;
    classes?: any;
    handleTabChange?: any;
    logout :  any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean
    isLoading: boolean
    deliveryData: any
    settingId : string
    restOrderPrice : string
    orderPriceId : string
    deliverySetting : string
    restaurantID : string
    orderPriceRules : {
      id: string,
      orderPrice: string,
      orderHOur: string,
      orderMin: string
    }[]
    cardIndex : number
    tempOrderRules: any[]
    deleteId: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AdminRestaurantDeliveryController extends BlockComponent<
    Props,
    S,
    SS
>  {

  // Customizable Area Start
  getDeliveryTimeApiCallId: any
  postDeliveryApiCallId: any
  putDeliveryApiCallId: any
  deleteOrderPriceApiCallId: any
  DEFAULT_ORDER_RULE:any = {
    id: -1,
    orderPrice: 0,
    orderHour: '',
    orderMin: ''
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loading: false,
      isLoading: false,
      deliveryData: [],
      settingId: '',
      restOrderPrice: '',
      orderPriceId: '',
      deliverySetting: 'manual',
      restaurantID: '',
      orderPriceRules: [],
      cardIndex: 0,
      tempOrderRules: [],
      deleteId: ''
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    if (id) {
      this.getDeliveryTime(id)
      this.setState({ restaurantID: id })
    }
  }
    // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !(responseJson.error || responseJson.errors)) {
        this.handleSuccessResponse(apiRequestCallId, responseJson)
      } else {
        this.setState({
          loading: false,
          isLoading: false
        })
        // Handle update restaurant delivery time settings error
        if (apiRequestCallId === this.putDeliveryApiCallId) {
          responseJson?.errors && this.showErrors(responseJson.errors);
        }
      }
    }
    // Customizable Area End      
  }

  // Customizable Area Start
  handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    // Get delivery time setting
    if (apiRequestCallId === this.getDeliveryTimeApiCallId) {
      this.setState({
        deliveryData: responseJson.data,
        settingId: responseJson.data?.id,
        deliverySetting: responseJson.data?.attributes.delivery_time_setting_type,
        isLoading: false
      })

      if(responseJson.data?.attributes?.order_price_rules?.length > 0) {
        const formattedRules = responseJson.data.attributes?.order_price_rules.map((rule: any) => {
          const {
            order_price,
            order_price_hour,
            order_price_minute
          } = rule.attributes
          return {
            id: rule.id,
            orderPrice: order_price,
            orderHour: order_price_hour,
            orderMin: order_price_minute
          }
        })

        this.setState({ orderPriceRules: formattedRules })
      }

      this.settings()
    }

    //create delivery time setting
    if (apiRequestCallId === this.postDeliveryApiCallId) {
      this.setState({ settingId: responseJson.data.id })
      toast.success("Delivery time settings created successfully")
      this.props.handleTabChange("", 2)
    }

    //delete order price rules
    if (apiRequestCallId === this.deleteOrderPriceApiCallId) {
      this.handleDeleteApiResponse(responseJson)
    }

    //update delivery time setting
    if (apiRequestCallId === this.putDeliveryApiCallId) {
      toast.success("Delivery time settings updated successfully")
      this.props.handleTabChange("", 2)
    }
  }

  handleDeleteApiResponse = (responseJson: any) => {
    const newRules = this.state.tempOrderRules.filter((rule: { id: string }) => rule.id !== responseJson?.data?.id?.toString())
    this.setState({
      tempOrderRules: [],
      orderPriceRules: newRules,
      deleteId: ''
    })
  }

  showErrors(errors: any) {
    errors?.forEach((err: any) => {
      toast.error(err?.message);
    });
    toast.error(errors[0].message)
    this.setState({
      loading:false
    })
    if(errors[0] && ((errors[0].message.includes(["Token has Expired","Invalid token"])))) {
      this.props.logout()
   }
  }

  handleDeliverySettingSubmit = (values: {
    hour: string,
    min: string,
    orderVolume: string,
    onlineOrdering: boolean,
    orderPriceRules: any[]
  }) => {
    const { deliveryData, deliverySetting, restaurantID } = this.state

    const isNotValidTakOut = Number(values.min) === 0 && Number(values.hour) === 0

    if(isNotValidTakOut) {
      toast.error("Please choose a valid takeout time")
      return
    }

    const {
      hour,
      min,
      orderVolume,
      onlineOrdering,
      orderPriceRules
    } = values

    let payload: any = {
      delivery_time: {
        quote_time_hour: hour,
        quote_time_minute: min,
        online_ordering: onlineOrdering,
        order_volume: 0,
        delivery_time_setting_type: "manual",
      },
      order_price_rules: []
    }

    if (deliverySetting === "cooking_capacity") {
      payload = {
        delivery_time: {
          ...payload.delivery_time,
          delivery_time_setting_type: "cooking_capacity",
          order_volume: orderVolume,
        },
        order_price_rules: [],
      }
    } else if (deliverySetting === "order_price") {
      if (orderPriceRules.length === 0) {
        toast.error("Please add order rule");
        return;
      }

      const formattedRules = orderPriceRules.map((rule: any) => {
        const { orderPrice, orderHour, orderMin } = rule;

        return {
          order_price_hour: orderHour,
          order_price_minute: orderMin,
          order_price: orderPrice,
        }
      })

      payload = {
        delivery_time: {
          ...payload.delivery_time,
          delivery_time_setting_type: "order_price",
          order_volume: orderVolume,
        },
        order_price_rules: formattedRules,
      }
    }

    if (!deliveryData?.id) {
      payload = {
        delivery_time: {
          ...payload.delivery_time,
          restaurant_id: restaurantID
        },
        order_price_rules: payload.order_price_rules
      }
      this.createDeliveryTime(payload)
      return
    }

    this.putDeliveryTime(payload, deliveryData?.id)
  }

  getDeliveryTime = async (id: string) => {
    this.setState({
      isLoading: true
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get(configJSON.AUTH_TOKEN)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDeliveryTimeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDeliveryAPiEndPoint + `?delivery_time[restaurant_id]=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  Schema = () => {
    switch (this.state.cardIndex) {
      case 0:
        return Yup.object().shape({
          hour: Yup.number().required("Hr field is required"),
          min: Yup.number().required("Min field is required")
        })
      case 1:
        return Yup.object().shape({
          hour: Yup.number().required("Hr field is required"),
          min: Yup.number().required("field is required"),
          orderVolume: Yup.string().required("Order Volume field is required").test(
            'Is positive?',
            'Invalid order volume', 
            (value: string) => Number(value) > 0
          )
        })
      case 2:
        return Yup.object().shape({
          hour: Yup.number().required("Hr field is required"),
          min: Yup.number().required("Min field is required"),
          orderVolume: Yup.string().test(
            'Is positive?',
            'Invalid order volume', 
            (value: string) => Number(value) > 0
          ),
          orderPriceRules: Yup.array()
          .of(
            Yup.object().shape({
              orderPrice: Yup.string()
                .required("Price required").test(
                  'Is positive?',
                  'Invalid price', 
                  (value: string) => Number(value) > 0
                ),
                orderMin: Yup.string().when("orderHour", {
                  is: (orderHour) => Number(orderHour) > 0,
                  then: Yup.string(),
                  otherwise: Yup.string().test(
                    'Is positive?',
                    'Invalid time', 
                    (value: string) => Number(value) > 0
                  )
                }),
                orderHour: Yup.string().when("orderMin", {
                  is: (orderMin) => Number(orderMin) > 0,
                  then: Yup.string(),
                  otherwise: Yup.string().test(
                    'Is positive?',
                    'Invalid time', 
                    (value: string) => Number(value) > 0
                  )
                })
            }, [["orderMin", "orderHour"]])
          )
        })
      default: break;
    }
  }

  createDeliveryTime = async (payload: any) => {

    this.setState({
      loading: true
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDeliveryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDeliveryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  putDeliveryTime = async (payload: any, id: string) => {
    this.setState({
      loading: true
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putDeliveryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postDeliveryAPiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteOrderPriceRule = async (orderRuleId: string, orderPriceRules: any[]) => {
    this.setState({
      deleteId: orderRuleId,
      tempOrderRules: orderPriceRules
    })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteOrderPriceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteOrderRuleApiEndPoint}${orderRuleId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  restDetailsCardShow = (index: number, setting: any) => {
    this.setState({ cardIndex: index })
    this.setState({ deliverySetting: setting })
  }

  settings = () => {
    switch (this.state.deliverySetting) {
      case 'manual': this.setState({ cardIndex: 0 })
        break;
      case 'cooking_capacity': this.setState({ cardIndex: 1 })
        break;
      case 'order_price': this.setState({ cardIndex: 2 })
        break;
    }
  }

  handleOrderValueChange = (
    settingId: number,
    value: string,
    field: string,
    values: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const orderRules = [...values.orderPriceRules]

    const newRules = orderRules.map((rule: any) => {
      if (rule.id === settingId) return { ...rule, [field]: value }
      return rule
    })

    setFieldValue("orderPriceRules", newRules)
  }

  handleDeleteOrderRule = (
    id: string,
    index: number,
    currentOrderRules: any[],
    localRemove: (index: number) => void
  ) => {
    if (id.includes("local")) localRemove(index);
    else this.deleteOrderPriceRule(id, currentOrderRules);
  }

  isActiveSetting = (index: number) => {
    const { cardIndex } = this.state
    return cardIndex === index ? "activeRestDetailCard" : ""
  }

  // Customizable Area End
}