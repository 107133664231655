import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"

// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider"
import { isTokenExpired } from "../../../../components/src/utility/helper"
import { toast } from "react-toastify"
export const configJSON = require("../config")

export interface IWishList {
  name: string
  wishlist: {
    data: IProduct[]
  }
}
export interface IProduct {
  id: string
  type: string
  attributes: {
    product_id: number
    seller_id: string
    added_from: string
    image: {
      id: number
      filename: string
      url: string
    }
    product_name: string
    price: number
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  currentTab: number
  wishlistCount: number
  updateWishlistCount: (wishlistCount: number) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean
  wishlists: any
  // Customizable Area End
}

interface SS {
  id: any
}

export default class AllWishlistPanelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wishlistApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      loader: false,
      wishlists: [],
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.handleTabChange()
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const { currentTab } = this.props
    if (prevProps.currentTab !== currentTab) {
      this.handleTabChange()
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.wishlistApiCallId) {
          this.setState({
            loader: false,
            wishlists: responseJson
          })
        }
      } else {
        if(await isTokenExpired(responseJson)) return

        if (apiRequestCallId === this.wishlistApiCallId) {
          this.setState({
            loader: false,
            wishlists: []
          })
          toast.error("Something went wrong")
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTabChange = () => {
    let activeOrder = ""

    switch (this.props.currentTab) {
      case 1:
        activeOrder = "Restaurant"
        break
      case 2:
        activeOrder = "Course"
        break
      default:
        activeOrder = "Farm"
        break
    }

    this.wishlistApi(activeOrder)
  }

  handleRemoveItemLocal = (sellerName: string, id: string) => {
    const { wishlists } = this.state
    const { wishlistCount, updateWishlistCount } = this.props

    const removedItem: IWishList[] = wishlists.filter((item: IWishList) => item.name === sellerName)
    const isLastItemRemoved = removedItem.length > 0 ? removedItem[0]?.wishlist?.data?.length === 1 : false

    updateWishlistCount(wishlistCount - 1)

    if (!!isLastItemRemoved) {
      const newItem = wishlists.filter((item: IWishList) => item.name !== sellerName)
      this.setState({
        wishlists: newItem
      })
      return
    }

    const newList = wishlists.map((item: IWishList) => {
      const { name, wishlist } = item
      if (name === sellerName) {
        const newWishlist = wishlist.data.filter((product: IProduct) => product.id !== id)
        return {
          ...item,
          wishlist: {
            data: newWishlist
          }
        }
      }
      return item
    })
    this.setState({ wishlists: newList })
  }

  handleRemoveItemLocalCourse = (id: string) => {
    const { wishlists } = this.state
    const courseWishlist = wishlists?.wishlist?.data || []
    if (courseWishlist?.length > 0) {
      const { wishlistCount, updateWishlistCount } = this.props

      const updateList = courseWishlist.filter((wishlist: any) => wishlist.id !== id)
      this.setState({
        wishlists: {
          ...wishlists,
          wishlist: {
            data: updateList
          }
        }
      })
      updateWishlistCount(wishlistCount - 1)
    }
  }

  wishlistApi = async (activeTab: string) => {
    this.setState({ loader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.wishlistApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.wishlistApiEndPoint}${activeTab}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  // Customizable Area End
}
