// Customizable Area Start
import React from "react"
import FavouritePanelController, { Props, IFavDetails } from "./FavouritePanelController.web"
import { Box, CircularProgress, Grid } from "@material-ui/core"
import LeafHeadingBanner from "../../../../components/src/LeafHeadingBanner.web"
import RestaurantCard from "../../../info-page/src/Restaurants/RestaurantCard.web"
import { RestaurantCardImg } from "../../../info-page/src/assets"
import CustomFavEmptyCardWeb from "../CustomFavEmptyCard.web"
import { connect } from "react-redux"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import AdCampaignWrapper from "../../../LocalListingAds/src/AdCampaignWrapper.web"

class RestaurantsFavouritePanel extends FavouritePanelController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, favouriteList, FPRestaurantDesc } = this.props
    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <>
            <Grid item xs={12} container justifyContent="center" spacing={3}>
              {favouriteList.length > 0 ? (
                <>
                  <Grid item xs={12} sm={10} md={8}>
                    <LeafHeadingBanner
                      heading={`${this.settingHeading()} Restaurants`}
                      description={FPRestaurantDesc}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={3}>
                    {favouriteList.map((restaurant: IFavDetails) => {
                      const { id, attributes } = restaurant
                      const {
                        name,
                        description,
                        is_ad,
                        third_party_ad_url,
                      } = attributes
                      const { adBanner, thirdPartyImageUrl } = this.getImages(restaurant)

                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                          <AdCampaignWrapper
                            isAd={is_ad}
                            thirdPartyImageUrl={thirdPartyImageUrl}
                            thirdPartyUrl={third_party_ad_url}
                          >
                            <RestaurantCard
                              cardName={name}
                              thumbnail={adBanner || RestaurantCardImg}
                              duration={"20-30 Min"}
                              description={description}
                              redirectPath={`${AppRoutings.RestaurantDetails}/${id}`}
                            />
                          </AdCampaignWrapper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <CustomFavEmptyCardWeb
                    message={`No ${this.settingHeading(true)} restaurants found`}
                    buttonText="Explore Restaurant now"
                    redirectLink="/restaurant-catelog"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    FPFarmDesc: state.cms.FPFarmDesc,
    FPRestaurantDesc: state.cms.FPRestaurantDesc,
    FPCourseDesc: state.cms.FPCourseDesc
  }
}

export { RestaurantsFavouritePanel }
export default connect(mapStateToProps, {})(RestaurantsFavouritePanel)
// Customizable Area End
