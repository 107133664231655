// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { toast } from "react-toastify"
import { RouteComponentProps } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import StorageProvider from "../../../framework/src/StorageProvider.web"

export const configJSON = require("./config")

export interface IStatusOption {
  label: string
  value: string
}

interface IStatusCounts {
  [key: string]: number
}

interface IOrderCount {
  all: number
  delivered: number
  new_order: number
  picked_up: number
  preparing: number
  ready: number
}

export interface Props extends RouteComponentProps {
  navigation: any
  id: string
  history: any
  classes: any
  logout: () => void
}

interface S {
  orderDetailsModal: boolean
  currentFarmId: string
  farmsList: any[]
  sortValue: unknown
  loading: boolean
  search: string
  productList: any[]
  status: string
  orderDetails: any
  total_count: number
  total_pages: number
  farmOrdersPerPage: number
  farmOrdersCurrentPage: number
  order_count: IOrderCount
  orderStatus: string
  statusLoader: boolean
  isOrderCancelApproveDialog: boolean
  isOrderCancelRejectDialog: boolean
  isUserAdminCancelling: boolean
  cancellationOrderId: string
  isInitiateRefund: boolean
}

interface SS {
  id: any
}

export default class AdminOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  orderStatusPickupOptions: IStatusOption[] = [
    {
      label: "Order Confirmed",
      value: "placed",
    },
    {
      label: "Preparing",
      value: "preparing",
    },
    {
      label: "Picked up",
      value: "picked_up",
    },
    {
      label: "Cancelled",
      value: "rejected",
    },
    {
      label: "Refunded",
      value: "refunded",
    },
  ]
  orderStatusOptions: IStatusOption[] = [
    ...this.orderStatusPickupOptions,
    {
      label: "In transit",
      value: "in_transit",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ]
  statusCounts: IStatusCounts = {
    "placed": 1,
    "preparing": 2,
    "picked_up": 3,
    "in_transit": 4,
    "delivered": 5
  }
  initialOrdersCount = {
    all: 0,
    delivered: 0,
    new_order: 0,
    picked_up: 0,
    preparing: 0,
    ready: 0,
  }
  getDropdownApiCallId: string = ""
  getProductListApiCallId: string = ""
  updateOrderStatusApiCallId: string = ""
  updateOrderStatusDropdownApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      orderDetailsModal: false,
      currentFarmId: "",
      farmsList: [],
      sortValue: "default",
      loading: false,
      search: "",
      productList: [],
      status: "all",
      orderDetails: null,
      total_count: 0,
      total_pages: 0,
      farmOrdersPerPage: 5,
      farmOrdersCurrentPage: 1,
      order_count: this.initialOrdersCount,
      orderStatus: "placed",
      statusLoader: false,
      isOrderCancelApproveDialog: false,
      isOrderCancelRejectDialog: false,
      isUserAdminCancelling: false,
      cancellationOrderId: "",
      isInitiateRefund: false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getFarms()
  }

  async componentDidUpdate() {
    if (this.state.currentFarmId) {
      if (window.location.pathname.split("/")[4] != this.state.currentFarmId) {
        const path = window.location.pathname.split("/")[4]
        this.setState({
          currentFarmId: path,
        })
      }
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        if (apiRequestCallId != null) {
          this.successApiCall(responseJson, apiRequestCallId)
        }
      }
      if (responseJson?.errors) {
        const tokenErrors = [
          configJSON.tokenExpiredText,
          configJSON.invalidTokenText,
        ]
        toast.error(responseJson?.errors[0].message)
        this.setState({
          loading: false,
        })
        if (
          responseJson?.errors[0] &&
          tokenErrors.includes(responseJson.errors[0].message)
        ) {
          this.props.logout()
        }
      }
    }
  }

  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getDropdownApiCallId) {
      const farmId: any = window.location.pathname.split("/")[4]
      const pathFarmId = this.pathFarmIdFinder(responseJson, farmId)
      this.setState({
        farmsList: responseJson.data,
        currentFarmId: pathFarmId,
      })
      this.props.history.push(`/admin/farm/orders/${pathFarmId}`)
      pathFarmId != 0 &&
        this.getProductList(
          pathFarmId,
          this.state.sortValue,
          this.state.search,
          this.state.status,
          this.state.farmOrdersPerPage,
          this.state.farmOrdersCurrentPage
        )
      this.setState({
        loading: false,
      })
    }

    if (apiRequestCallId === this.getProductListApiCallId) {
      if (responseJson.orders && responseJson.orders.data) {
        this.setState({
          productList: responseJson.orders.data,
          total_pages: responseJson.meta.pagination.total_pages,
          total_count: responseJson.meta.pagination.total_count,
          order_count: responseJson.order_count,
        })
      } else {
        this.setState({
          productList: [],
          total_pages: 0,
          total_count: 0,
          order_count: this.initialOrdersCount,
        })
        toast.error("Something went wrong")
      }
      this.setState({ loading: false })
    }

    if (apiRequestCallId === this.updateOrderStatusApiCallId) {
      toast.success("Order Status Updated Successfully")
    }

    if(apiRequestCallId === this.updateOrderStatusDropdownApiCallId) {
      this.setState({ statusLoader: false })
    }
  }

  getOrderStatus = (statusValue: string) => {
    const statusRecord = this.orderStatusOptions.find(
      (option) => option.value === statusValue
    )
    return statusRecord ? statusRecord.label : ""
  }

  getButtonOrderStatus = (statusValue: string) => {
    const status = this.getOrderStatus(statusValue)
    return status === "In transit" ? "Picked up" : status
  }

  handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target
    if(value) {
      const { orderDetails } = this.state

      this.setState({ orderStatus: value as string })
      this.updateOrderStatusDropDownApi(orderDetails.id, value as string)
    }
  }

  pathFarmIdFinder = (responseJson: any, farmId: any) => {
    if (farmId == "00") {
      return responseJson.data.length > 0 ? responseJson.data[0].id : "0"
    } else {
      return window.location.pathname.split("/")[4]
    }
  }

  getFarms = async () => {
    this.setState({
      loading: true,
    })

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getDropdownApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getProductList = async (
    id: string,
    sort: unknown,
    search: string,
    status: string,
    per: number,
    page: number
  ) => {
    this.setState({
      loading: true,
    })
    function sortBy() {
      let newSort = sort
      if (newSort == "high to low") {
        newSort = "high_to_low"
      } else if (newSort == "low to high") {
        newSort = "low_to_high"
      } else {
        newSort = sort
      }
      return newSort
    }

    const endpoint = `product[product_id]=${id}&product[product_type]=BxBlockCatalogue::Farm&sort_by=${sortBy()}&search=${search}&status=${status}&per=${per}&page=${page}`

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getProductListApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersListAPiEndPoint + `${endpoint}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  updateOrderStatus = async (id: string, activeTab: number) => {
    this.setState({ loading: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      token
    }

    const formData = new FormData()
    formData.append("order[id]", id)
    Object.keys(this.statusCounts).forEach((key: string) => {
      if(this.statusCounts[key] === activeTab) {
        formData.append("order[status]", key)
      }
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.updateOrderStatusApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateOrderStatusApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  updateOrderStatusDropDownApi = async (id: string, status: string) => {
    this.setState({ statusLoader: true })

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const httpBody = {
      order: {
        id,
        status
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.updateOrderStatusDropdownApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateOrderStatusApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  handleOrderDetailOpenModal = (data: any) => {
    this.setState({
      orderDetailsModal: true,
      orderDetails: data,
      orderStatus: data.attributes.status,
    })
  }

  getProductListing = () => {
    const {
      currentFarmId,
      sortValue,
      search,
      status,
      farmOrdersPerPage,
      farmOrdersCurrentPage,
    } = this.state

    this.getProductList(
      currentFarmId,
      sortValue,
      search,
      status,
      farmOrdersPerPage,
      farmOrdersCurrentPage
    )
  }

  handleOrderDetailCloseModal = () => {
    const {
      currentFarmId,
      sortValue,
      search,
      status,
      farmOrdersPerPage,
      farmOrdersCurrentPage,
      orderStatus,
      orderDetails,
    } = this.state
  
    const isStatusUpdated = orderStatus !== orderDetails.attributes.status
  
    this.setState({
      orderDetailsModal: false,
      orderDetails: null,
      orderStatus: "",
    })
  
    isStatusUpdated &&
      this.getProductList(
        currentFarmId,
        sortValue,
        search,
        status,
        farmOrdersPerPage,
        farmOrdersCurrentPage
      )
  }

  handleStatus = (status: any) => {
    let value = "all"
    if (status == 1) {
      value = "placed"
    } else if (status == 2) {
      value = "preparing"
    } else if (status == 3) {
      value = "picked_up"
    } else if (status == 4) {
      value = "in_transit"
    } else if (status == 5) {
      value = "delivered"
    }
    this.setState({
      status: value,
      farmOrdersCurrentPage: 1,
    })
    const farmId: any = window.location.pathname.split("/")[4]
    this.getProductList(
      farmId,
      this.state.sortValue,
      this.state.search,
      value,
      this.state.farmOrdersPerPage,
      1
    )
  }

  SearchDebounce = (call: any, delay: any) => {
    let timer: any
    return function(...args: any) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        call(...args)
      }, delay)
    }
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getProductList, 1000)

  pageChange = (per: any, page: any) => {
    this.setState({ farmOrdersCurrentPage: page, farmOrdersPerPage: per })
  }

  openOrderCancelApproveDialog = (orderId: string, isInitiateRefund: boolean, isByUserAdmin?: boolean) => {
    this.setState({
      isOrderCancelApproveDialog: true,
      isUserAdminCancelling: !!isByUserAdmin,
      cancellationOrderId: orderId,
      isInitiateRefund
    })
  }
  
  closeOrderCancelApproveDialog = () => {
    this.setState({
      isOrderCancelApproveDialog: false,
      isUserAdminCancelling: false,
      cancellationOrderId: "",
      isInitiateRefund: false
    })
  }
  
  openOrderCancelRejectDialog = (orderId: string) => {
    this.setState({
      isOrderCancelRejectDialog: true,
      cancellationOrderId: orderId,
    })
  }
  
  closeOrderCancelRejectDialog = () => {
    this.setState({
      isOrderCancelRejectDialog: false,
      cancellationOrderId: "",
    })
  }  
}
// Customizable Area End
