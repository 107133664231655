// Customizable Area Start
import React, { useState } from "react"
import { Box, Grid, Typography, Button } from "@material-ui/core"
import moment from "moment"
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded"
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded"

export interface IDiscussionComment {
    first_name: string
    last_name: string
    created_at: string
}

interface IProps {
    comments: IDiscussionComment[]
}

const CommunityDiscussionCardComments = ({ comments }: IProps) => {
    const [isCommentsExpanded, setIsCommentsExpanded] = useState(false)
    const isCommentsExist = comments?.length > 0
    const isMoreThanTwoComments = comments?.length > 2

    const toggleCommentsExpand = () => {
        setIsCommentsExpanded((isExpanded) => !isExpanded)
    }

    const formatDate = (createdAt: string) => {
      const today = new Date()
      const date = new Date(createdAt);

      const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
      if (date.toDateString() === today.toDateString()) {
        return date.toLocaleTimeString([], timeOptions);
      } else {
        const dateTimeOptions: Intl.DateTimeFormatOptions = {
          year: 'numeric', month: 'long', day: 'numeric'
        }
        const datePart = date.toLocaleDateString([], dateTimeOptions);
        const timePart = date.toLocaleTimeString([], timeOptions);
        return `${datePart} ${timePart}`
      }
    }

    return (
        <>
            <Grid item xs={12}>
                <Box className="cdc_separator" />
            </Grid>
            <Grid item xs={11}>
                <Box className="cdc_comments_container">
                    {isCommentsExist ? (
                        <>
                            <Typography className="cdc_comment_heading">Comments</Typography>
                            <Box>
                                {comments
                                    .slice(0, isCommentsExpanded ? comments.length : 2)
                                    .map((comment: IDiscussionComment, index: number) => {
                                        const { created_at, first_name, last_name } = comment

                                        return (
                                            <Typography
                                                key={`${first_name}${created_at}`}
                                                className={`cdc_user_info cdc_comment ${index !== 0 ? "cdc_not_first" : ""
                                                    }`}
                                            >
                                                {first_name} {last_name} •{" "}
                                                {formatDate(created_at)}
                                            </Typography>
                                        )
                                    })}
                            </Box>
                        </>
                    ) : (
                        <Box className="cdc_no_comments_wrapper">
                            <Typography className="cdc_user_info cdc_comment">
                                No Comments...
                            </Typography>
                        </Box>
                    )}
                </Box>

                {isMoreThanTwoComments && (
                    <Button
                        className="cdc_comment_filter_btn"
                        endIcon={
                            isCommentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                        onClick={toggleCommentsExpand}
                    >
                        {isCommentsExpanded ? "View Less" : "View All"}
                    </Button>
                )}
            </Grid>
        </>
    )
}

export default CommunityDiscussionCardComments
// Customizable Area End
