// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import CustomToastCloseIcon from '../../components/src/CustomToastCloseIcon.web'
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import ContentModeration from '../../blocks/ContentModeration/src/ContentModeration';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import DeliveryEstimator from '../../blocks/DeliveryEstimator/src/DeliveryEstimator';
import ShippingOptions from '../../blocks/ShippingOptions/src/ShippingOptions';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import Followers from '../../blocks/Followers/src/Followers';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Favourites from '../../blocks/Favourites/src/Favourites';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import Sms from '../../blocks/Sms/src/Sms';
import Notifications from '../../blocks/Notifications/src/Notifications';
import Location from '../../blocks/Location/src/Location';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary';
import ContentFlag from '../../blocks/ContentFlag/src/ContentFlag';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
// import WishListWeb from '../../blocks/Wishlist/src/Wishlist.web';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import Hashtags from '../../blocks/Hashtags/src/Hashtags';
import MenuOrdering from '../../blocks/MenuOrdering/src/MenuOrdering';
import MixpanelIntegration from '../../blocks/MixpanelIntegration/src/MixpanelIntegration';
import BitcoinPayment from '../../blocks/BitcoinPayment/src/BitcoinPayment';
import Settings from '../../blocks/Settings/src/Settings';
import CustomisableUserSubscriptions from '../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions';
import LoyaltySystem from '../../blocks/LoyaltySystem/src/LoyaltySystem';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import HelpCentre from '../../blocks/HelpCentre/src/HelpCentre';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import DonationPayments from '../../blocks/DonationPayments/src/DonationPayments';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import AutomaticCheckoutCalculation from '../../blocks/AutomaticCheckoutCalculation/src/AutomaticCheckoutCalculation';
import Maps from '../../blocks/Maps/src/Maps';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Share from '../../blocks/Share/src/Share';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import SubscriptionBilling from '../../blocks/SubscriptionBilling/src/SubscriptionBilling';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import SquareConnectRedirect from '../../blocks/Payments/src/SquareConnectRedirect.web';
import FeatureSettings from '../../blocks/FeatureSettings/src/FeatureSettings';
import QuickbooksIntegration from '../../blocks/QuickbooksIntegration/src/QuickbooksIntegration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import RecommendationEngine from '../../blocks/RecommendationEngine/src/RecommendationEngine';
import ProductTutorial from '../../blocks/ProductTutorial/src/ProductTutorial';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import Sorting from '../../blocks/sorting/src/Sorting';
import Gallery from '../../blocks/Gallery/src/Gallery';
import Reviews from '../../blocks/Reviews/src/Reviews';
import TargetedFeed from '../../blocks/TargetedFeed/src/TargetedFeed';
import Library from '../../blocks/Library/src/Library';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import CfSubcartsForShoppingCart from '../../blocks/CfSubcartsForShoppingCart/src/CfSubcartsForShoppingCart';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Payments from '../../blocks/Payments/src/Payments';
import CfExchangeablePointsrewardsSystem from '../../blocks/CfExchangeablePointsrewardsSystem/src/CfExchangeablePointsrewardsSystem';
import Comments from '../../blocks/Comments/src/Comments';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import Videos from '../../blocks/Videos/src/Videos';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum';
import BlockUsers from '../../blocks/BlockUsers/src/BlockUsers';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import LocalListingAds from '../../blocks/LocalListingAds/src/LocalListingAds';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import TaskList from '../../blocks/TaskList/src/TaskList';
import ConversationThreading from '../../blocks/ConversationThreading/src/ConversationThreading';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
// web //

// admin side routes start
import AdminConsole from '../../blocks/dashboard/src/AdminConsole.web';
import webEducation from '../../blocks/info-page/src/education/webEducation.web';
import webPurchasedProduct from '../../blocks/ProductTutorial/src/webPurchasedProduct.web';
import webEducationFilter from '../../blocks/categoriessubcategories/src/webEducationFilter.web';
import webEducationCourseDetails from '../../blocks/categoriessubcategories/src/webEducationCourseDetails.web';
import webEducationCoursePlay from '../../blocks/categoriessubcategories/src/webEducationCoursePlay.web';

// admin side routes end
//Login signup routes starts
import OnlineEmailAccountLogin from '../../blocks/email-account-login/src/OnlineEmailAccountLogin.web';
import OnlineEmailAccountRegistration from '../../blocks/email-account-registration/src/OnlineEmailAccountRegistration.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/OnlineResetPassword.web';
import CreateNewPasswordWeb from '../../blocks/forgot-password/src/OnlineCreateNewPassword.web';
import NormalUserRegistration from '../../blocks/email-account-registration/src/NormalUserRegistration.web';
//Login signup routes Ends
import SelectYourRole from '../../blocks/RolesPermissions/src/SelectYourRole.web';

import FarmersDonePage from '../../blocks/RolesPermissions/src/FarmersDonePage.web';
import RestauraDonePage from '../../blocks/RolesPermissions/src/RestaurantDonePage.web';
import EducatorDonePage from '../../blocks/RolesPermissions/src/EducatorDonePage.web';
//Account Setting routes starts
import AccountSetting from '../../blocks/Settings/src/AccountSetting.web';

import WishListWeb from '../../blocks/Wishlist/src/Wishlist.web';
import OrderHistoryWeb from '../../blocks/OrderDetailView/src/OrderHistory.web';
import RestaurantOrderHistoryWeb from '../../blocks/OrderDetailView/src/RestaurantOrderHistory.web';
import FarmOrderHistoryWeb from '../../blocks/OrderDetailView/src/FarmOrderHistory.web';
import ViewAdressesWeb from '../../blocks/OrderDetailView/src/ViewOrderAddresses.web';
//Account Setting routes ends
import AboutUs from '../../blocks/contactus/src/AboutUs.web';
import WebFAQ from '../../blocks/contactus/src/WebFAQ.web';
import Policies from '../../blocks/contactus/src/Policies.web';
import ContactUsUser from '../../blocks/contactus/src/ContactUsUser.web';
//Customer Farmer Market Routes start
import FarmsPageWeb from '../../blocks/info-page/src/FarmsPage.web';
import NewVendor from '../../blocks/info-page/src/NewVendor.web';
import FarmDetails from '../../blocks/info-page/src/FarmDetails.web';
import ProductDetails from '../../blocks/info-page/src/productDetails/productDetailsLayout.web';
import WebReviews from '../../blocks/info-page/src/productDetails/WebReviews.web';
import productDescription from '../../blocks/info-page/src/productDetails/productDescription.web';
import FarmProductCateloge from '../../blocks/info-page/src/FarmProductCateloge.web';
import Donate from '../../blocks/info-page/src/Donate.web';
import RestaurantLandingPage from '../../blocks/info-page/src/Restaurants/RestaurantLandingPage.web';
import ShoppingCartWeb from '../../blocks/ShoppingCart/src/shoppingCartWeb/ShoppingCart.web';
import FarmForum from '../../blocks/info-page/src/farmForum/farmForum.web';
import RestaurantCatelog from '../../blocks/info-page/src/Restaurants/restautantCatelog.web';
import WebRestaurantDetails from '../../blocks/info-page/src/Restaurants/RestaturantDetails.web';
import RestaurantSearchMapViewLayout from '../../blocks/info-page/src/Restaurants/restaurantSearchMapView/restaurantSerachMapviewLayoutWeb.web';
import ProductDescriptionWeb from '../../blocks/info-page/src/productDetails/productDescription.web';
import CreateRoleProfileLayout from '../../blocks/RolesPermissions/src/createRoleProfileLayoutWeb.web';
import EducatorRoleProfileLayout from '../../blocks/RolesPermissions/src/EducatorRoleProfileLayoutWeb.web';
import RestaurantRoleProfileLayout from '../../blocks/RolesPermissions/src/RestaurantRoleProfileLayoutWeb.web';
import CheckOutDeliveryWeb from '../../blocks/info-page/src/checkOut/checkOutDelivery/checkoutDeliveryWeb.web';
import CheckOutPickUpWeb from '../../blocks/info-page/src/checkOut/checkOutPickup/pickUpWeb.web';
import CheckOutBitcoinWeb from '../../blocks/info-page/src/checkOut/checkoutBitcoin/BitcoinWeb.web';
import CheckOutCCardWeb from '../../blocks/info-page/src/checkOut/checkOutCCard/CCardWeb.web';
import CheckOutOrderSummaryWeb from '../../blocks/info-page/src/checkOut/OrderSummary/OrderSummaryWeb.web';
import WebHome from '../../blocks/dashboard/src/WebDashboard.web';
import CommunityDashboard from '../../blocks/info-page/src/Community/CommunityDashboard/CommunityDashboard.web';
import WebRestaurants from '../../blocks/dashboard/src/WebRestaurants.web';
import RestaurantCartLayoutn from '../../blocks/ShoppingCart/src/shoppingCartWeb/RestaurantCartLayout.web';
import LandingPage from '../../blocks/categoriessubcategories/src/Landingpage.web';
import LandingPage2 from '../../blocks/categoriessubcategories/src/LandingPage2.web';
import LandingPage3 from '../../blocks/categoriessubcategories/src/LandingPage3.web';
import LandingPage5 from '../../blocks/categoriessubcategories/src/LandingPage5.web';
import LandingPage4 from '../../blocks/categoriessubcategories/src/LandingPage4.web';
import LandingPage6 from '../../blocks/categoriessubcategories/src/LandingPage6.web';
import AdminLearning1 from '../../blocks/catalogue/src/AdminLearningCourseList.web';
import AdminLearning2 from '../../blocks/catalogue/src/AdminLearningCourseList2.web';
import AdminLearningContent from '../../blocks/catalogue/src/AdminLearningContent.web';
import SearchPage from '../../blocks/info-page/src/SearchPage.web';
import MobileNavMenu from "../../blocks/info-page/src/MobileNavMenu.web"
import { AppRoutings } from '../../components/src/utility/app-routing';


const routeMap = {
  ContentModeration: {
    component: ContentModeration,
    path: '/ContentModeration'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },
  DeliveryEstimator: {
    component: DeliveryEstimator,
    path: '/DeliveryEstimator'
  },
  ShippingOptions: {
    component: ShippingOptions,
    path: '/ShippingOptions'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Sms: {
    component: Sms,
    path: '/Sms'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  Hashtags: {
    component: Hashtags,
    path: '/Hashtags'
  },
  MenuOrdering: {
    component: MenuOrdering,
    path: '/MenuOrdering'
  },
  MixpanelIntegration: {
    component: MixpanelIntegration,
    path: '/MixpanelIntegration'
  },
  BitcoinPayment: {
    component: BitcoinPayment,
    path: '/BitcoinPayment'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  CustomisableUserSubscriptions: {
    component: CustomisableUserSubscriptions,
    path: '/CustomisableUserSubscriptions'
  },
  LoyaltySystem: {
    component: LoyaltySystem,
    path: '/LoyaltySystem'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  Invitefriends: {
    component: Invitefriends,
    path: '/Invitefriends'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/ProjecttaskTracking'
  },
  DonationPayments: {
    component: DonationPayments,
    path: '/DonationPayments'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  AutomaticCheckoutCalculation: {
    component: AutomaticCheckoutCalculation,
    path: '/AutomaticCheckoutCalculation'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: '/SubscriptionBilling'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  FeatureSettings: {
    component: FeatureSettings,
    path: '/FeatureSettings'
  },
  QuickbooksIntegration: {
    component: QuickbooksIntegration,
    path: '/QuickbooksIntegration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: '/RecommendationEngine'
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: '/ProductTutorial'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  Search: {
    component: WebHome,
    path: AppRoutings.Search,
    exact: true
  },
  SearchProducts: {
    component: WebHome,
    path: AppRoutings.SearchProducts,
    exact: true
  },
  SearchDishes: {
    component: WebHome,
    path: AppRoutings.SearchDishes,
    exact: true
  },
  SearchFarms: {
    component: WebHome,
    path: AppRoutings.SearchFarms,
    exact: true
  },
  SearchRestaurants: {
    component: WebHome,
    path: AppRoutings.SearchRestaurants,
    exact: true
  },
  SearchCourses: {
    component: WebHome,
    path: AppRoutings.SearchCourses,
    exact: true
  },
  TargetedFeed: {
    component: TargetedFeed,
    path: '/TargetedFeed'
  },
  Library: {
    component: Library,
    path: '/Library'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  CfSubcartsForShoppingCart: {
    component: CfSubcartsForShoppingCart,
    path: '/CfSubcartsForShoppingCart'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  CfExchangeablePointsrewardsSystem: {
    component: CfExchangeablePointsrewardsSystem,
    path: '/CfExchangeablePointsrewardsSystem'
  },
  Comments: {
    component: Comments,
    path: '/Comments'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  CommunityForum: {
    component: CommunityForum,
    path: '/CommunityForum'
  },
  BlockUsers: {
    component: BlockUsers,
    path: '/BlockUsers'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  LocalListingAds: {
    component: LocalListingAds,
    path: '/LocalListingAds'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  ConversationThreading: {
    component: ConversationThreading,
    path: '/ConversationThreading'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SelectYourRole: {
    component: SelectYourRole,
    path: '/select-your-role'
  },

  FarmersDonePage: {
    component: FarmersDonePage,
    path: '/farmer-done'
  },

  RestauraDonePage: {
    component: RestauraDonePage,
    path: '/restaurant-done'
  },

  EducatorDonePage: {
    component: EducatorDonePage,
    path: '/educator-done'
  },
  Home: {
    component: WebHome,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  //web//

  OnlineEmailAccountLogin: {
    component: OnlineEmailAccountLogin,
    path: '/login'
  },
  OnlineEmailAccountRegistration: {
    component: OnlineEmailAccountRegistration,
    path: '/register'
  },
  ResetPasswordWeb: {
    component: ResetPasswordWeb,
    path: '/reset-password'
  },
  CreateNewPasswordWeb: {
    component: CreateNewPasswordWeb,
    path: '/create-new-password'
  },
  NormalUserRegistration: {
    component: NormalUserRegistration,
    path: '/normal-user-register'
  },

  AccountSetting: {
    component: WebHome,
    path: AppRoutings.AccountSetting,
    protected: true
  },

  FavouritesCategories: {
    component: WebHome,
    path: AppRoutings.FavoritePage,
    protected: true
  },

  WishListWeb: {
    component: WishListWeb,
    path: AppRoutings.WishList
  },

  // admin side routes start
  AdminConsole: {
    component: AdminConsole,
    path: '/admin'
  },
  OrderHistoryWeb: {
    component: WebHome,
    path: AppRoutings.OrderHistory
  },
  RestaurantOrderHistoryWeb: {
    component: RestaurantOrderHistoryWeb,
    path: '/restaurant-order-history'
  },
  FarmOrderHistoryWeb: {
    component: FarmOrderHistoryWeb,
    path: '/farm-order-history'
  },
  ViewAdressesWeb: {
    component: WebHome,
    path: AppRoutings.ViewAddresses
  },
  AboutUs: {
    component: WebHome,
    path: AppRoutings.AboutUs
  },
  ContactUsUser: {
    component: WebHome,
    path: AppRoutings.ContactUs
  },
  FAQWeb: {
    component: WebHome,
    path: AppRoutings.WebFAQ
  },
  TransactionHistory: {
    component: WebHome,
    path: AppRoutings.TransactionHistory
  },
  Policies: {
    component: WebHome,
    path: '/policy&info/:id'
  },
  HyperLink: {
    component: WebHome,
    path: AppRoutings.HyperLinkContentId
  },
  FarmsPageWeb: {
    component: WebHome,
    path: AppRoutings.FarmsMarket
  },
  LandingPageLoggedIn: {
    component: WebHome,
    path: AppRoutings.LandingPageLoggedIn
  },
  NewVendor: {
    component: NewVendor,
    path: '/new-vendor'
  },
  CmsVendorPage: {
    component: WebHome,
    path: AppRoutings.CmsVendorPage
  },
  FarmDetails: {
    component: WebHome,
    path: AppRoutings.FarmDetails
  },
  ShopingCartLayoyt: {
    component: WebHome,
    path: AppRoutings.ShoppingCart
  },
  FarmForum: {
    component: FarmForum,
    path: '/farm-forum'
  },
  WebReviews: {
    component: WebHome,
    path: '/product-details/reviews'
  },
  ProductDescriptionWeb: {
    component: WebHome,
    path: AppRoutings.FarmProductDetails
  },
  FarmProductCateloge: {
    component: WebHome,
    path: AppRoutings.FarmDetailsCatelog
  },
  Donate: {
    component: WebHome,
    path: AppRoutings.DonateId
  },
  RestaurantLandingPage: {
    component: WebRestaurants,
    path: AppRoutings.RestaurantLandingPage
  },
  RestaurantMapViewPage: {
    component: WebRestaurants,
    path: AppRoutings.RestaurantMapView
  },
  RestaurantCatelog: {
    component: WebRestaurants,
    path: AppRoutings.RestaurantCatelog
  },
  WebRestaurantDetails: {
    component: WebRestaurants,
    path: AppRoutings.RestaurantDetailsId
  },
  RestaurantSearchMapViewLayout: {
    component: RestaurantSearchMapViewLayout,
    path: '/restaurant-search-map-view'
  },
  CreateRoleProfileLayout: {
    component: CreateRoleProfileLayout,
    path: '/create-farmer-profile'
  },

  RestaurantRoleProfileLayout: {
    component: RestaurantRoleProfileLayout,
    path: '/create-restaurant-profile'
  },

  EducatorRoleProfileLayout: {
    component: EducatorRoleProfileLayout,
    path: '/create-educator-profile'
  },

  CheckOutDeliveryWeb: {
    component: WebHome,
    path: AppRoutings.CheckOutOrder
  },
  LandingPage: {
    component: LandingPage,
    path: '/learning',
    exact: true
  },
  LandingPage2: {
    component: LandingPage2,
    path: '/learning/courses',
    exact: true
  },
  LandingPage3: {
    component: LandingPage3,
    path: '/learning/courses/single',
    exact: true
  },

  LandingPage4: {
    component: LandingPage4,
    path: '/learning/courses/enrolled',
    exact: true
  },
  LandingPage5: {
    component: LandingPage5,
    path: '/learning/courses/sub_section',
    exact: true
  },
  LandingPage6: {
    component: LandingPage6,
    path: '/landing6'
  },

  AdminLearning1: {
    component: AdminLearning1, //AdminLearningCourseList2.web.tsx
    path: '/adminLearn'
  },
  AdminLearning2: {
    component: AdminLearning2,
    path: '/adminLearn2'
  },
  AdminLearning3: {
    component: AdminLearningContent,
    path: '/adminLearn3'
  },
  Community: {
    component: WebHome,
    path: AppRoutings.Community
  },
  CommunityDiscussion: {
    component: CommunityDashboard,
    path: AppRoutings.CommunityDiscussion
  },
  CommunityDiscussionDetails: {
    component: CommunityDashboard,
    path: AppRoutings.CommunityDiscussionDetailsId
  },
  Experts: {
    component: WebHome,
    path: AppRoutings.Experts
  },
  ExpertsDetails: {
    component: WebHome,
    path: AppRoutings.ExpertsDetailsId
  },
  SquareConnectRedirect: {
    component: SquareConnectRedirect,
    path: AppRoutings.SquareConnectRedirect
  },
  Wallet: {
    component: WebHome,
    path: AppRoutings.Wallet
  },
  SearchPage: {
    component: SearchPage,
    path: AppRoutings.SearchPage,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ overflowX: 'hidden' }}>
        {WebRoutesGenerator({ routeMap })}
        <MobileNavMenu />
        <ToastContainer hideProgressBar closeButton={CustomToastCloseIcon} />
      </View>
    );
  }
}

export default App;
