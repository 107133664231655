// Customizable Area Start
import React, { useRef, useState } from "react"
import { Box, CircularProgress, Grid } from "@material-ui/core"
import { setRestaurantsSelectedCategory, soup } from "../../assets"
import { connect } from "react-redux"
import Slider, { CustomArrowProps } from "react-slick"
import "../../assets/css/restaurantCategories.scss"
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIosRounded"
import ArrowBackIcon from "@material-ui/icons/ArrowBackIosRounded"
import clsx from "clsx"

interface ICategory {
  id: string
  type: string
  attributes: {
    id: number
    count: number
    icon: {
      id: number
      filename: string
      url: string
    }
    min_price: string
    name: string
  }
}

interface IRLPPopularCategories {
  isCategoriesLoading: boolean
  selectedCategory: string
  popularCategories: ICategory[]
  setRestaurantsSelectedCategory: (category: string) => void
}

interface ICustomNextArrowProps extends CustomArrowProps {
  slideToShow: number
}

const CustomNextArrow = ({
  className,
  currentSlide,
  slideCount,
  slideToShow,
  onClick,
}: ICustomNextArrowProps) => {
  return (
    <>
      {currentSlide !== Number(slideCount) - slideToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIcon />
        </div>
      )}
    </>
  )
}

const CustomPrevArrow = ({
  className,
  currentSlide,
  onClick,
}: CustomArrowProps) => {
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIcon />
        </div>
      )}
    </>
  )
}

const RLPPopularCategories = ({
  isCategoriesLoading,
  selectedCategory,
  popularCategories,
  setRestaurantsSelectedCategory,
}: IRLPPopularCategories) => {
  const sliderRef: any = useRef(null)
  const [currentSlidesToShow, setCurrentSlidesToShow] = useState(6)

  const handleOnReInit = () => {
    if (sliderRef?.current) {
      const updatedSlideToShow =
        sliderRef.current.innerSlider.props.slidesToShow
      currentSlidesToShow !== updatedSlideToShow &&
        setCurrentSlidesToShow(updatedSlideToShow)
    }
  }

  return (
    <>
      <Grid container justifyContent="center" alignContent="center">
        <Grid item xs={12} sm={10} md={6} lg={4}>
          <Box className="heading_wrapper">
            <p className="restaurant_green_background_heading_new">
              Categories
            </p>
            <h4>Popular Categories</h4>
          </Box>
        </Grid>
      </Grid>
      {isCategoriesLoading ? (
        <Box className="restaurant_category_loader">
          <CircularProgress size={50} style={{ color: "#20a258" }} />
        </Box>
      ) : (
        <Box>
          <Slider
            ref={sliderRef}
            className="restaurant_categories_slider"
            arrows={true}
            infinite={false}
            slidesToShow={6}
            speed={700}
            nextArrow={<CustomNextArrow slideToShow={currentSlidesToShow} />}
            prevArrow={<CustomPrevArrow />}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 5,
                },
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                },
              },
            ]}
            onReInit={handleOnReInit}
          >
            {popularCategories?.map((item: ICategory) => {
              const {
                id,
                attributes: { name, icon, count, min_price },
              } = item

              return (
                <Box
                  key={id}
                  className={clsx("popular_categories_dish_card", {
                    ["popular_categories_dish_card_selected"]:
                      selectedCategory === name,
                  })}
                  onClick={() => setRestaurantsSelectedCategory(name)}
                >
                  <Box className="dish_categories_content_wrapper">
                    <Box className="dish_img_wrapper">
                      <img src={icon?.url || soup} alt="dish_img" />
                      <Box className="dish_count">{count}</Box>
                    </Box>
                    <Box className="dish_name_price_wrapper">
                      <h6>{name}</h6>
                      <p>From ${min_price || 0}</p>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Slider>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isCategoriesLoading: state.restaurants.isCategoriesLoading,
    selectedCategory: state.restaurants.selectedCategory,
    popularCategories: state.restaurants.popularCategories,
  }
}

export default connect(mapStateToProps, { setRestaurantsSelectedCategory })(
  RLPPopularCategories
)
// Customizable Area End
