import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import _ from "lodash";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location?: any
  history?: any;
  dishCategories: {
    id: number,
    name : string
  }[];
  getDishAllCategories?: () => void;
  logout : any;
  classes : {
    priceText : string,
    addOnContainer : string,
    dishCategory : string
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  restaurantId: string
  dishId: string
  dishTitle: string;
  dishDescription: string;
  dishImages: object[];
  dishStatus: string;
  dishCategory: number | "";
  dishPrice: string;
  dishComparePrice: string;
  dishTags: string;
  isVeg: boolean;
  createDishAddons: any;
  status: {
    value: string,
    label: string,
  }[];
  dishLoader: boolean
  deleteDialog: boolean
  titleError: string
  descError: string
  imageError: string
  priceError: string
  modeType : string
  buttonName : string
  comparePriceError : string
  buttonLoader : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminRestaurantCreateDishesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createDishApiId: string = ""
  getDishApiId: string = ""
  removeDishApiId: string = ""
  removeImageApiId: string = ""
  removeAddOnTypeApiId: string = ""
  removeSingleAddOnTypeApiId: string = ""
  defaultAddOn = {
    addOnType: "",
    addOnList: [
      {
        name: "",
        price: "",
      },
    ],
  };
  allowedExtension = configJSON.allowedExtensionText
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      restaurantId: "",
      dishId: "",
      dishTitle: "",
      dishDescription: "",
      dishImages: [],
      dishStatus: configJSON.activeText,
      dishCategory: "",
      dishPrice: "",
      dishComparePrice: "",
      dishTags: "",
      isVeg: true,
      createDishAddons: [],
      dishLoader: false,
      deleteDialog: false,
      status: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "deactive",
          label: "Deactivate",
        },
      ],
      titleError: "",
      descError: "",
      imageError: "",
      priceError: "",
      modeType : "",
      buttonName : "",
      comparePriceError : "",
      buttonLoader : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    !!this.props.getDishAllCategories && this.props.getDishAllCategories();

    const search = this.props.location?.state;

    const restaurantId = search.id;
    const dishId = search?.dishId;

    this.setState({ restaurantId, modeType: configJSON.createButtonText, buttonName: configJSON.saveText })

    if (!!dishId) {
      this.setState({ dishId })
      this.getDishApi(dishId)
    }
  }

  createDishApi = async (dishDetails: any) => {
    this.setState({buttonLoader : true})
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const httpBody = dishDetails;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDishApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateDishApiEndpoint}/${this.state.dishId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.dishId ? configJSON.apiMethodTypePut : configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getDishApi = async (id: string) => {
    this.setState({ dishLoader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDishApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateDishApiEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeDishApi = async (id: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeDishApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateDishApiEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeDishImageApi = async (id: number) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeImageApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeImageApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeAddOnTypeApi = async (id: number) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeAddOnTypeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeAddOnTypeEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeSingleAddOnTypeApi = async (id: number) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeSingleAddOnTypeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeSingleAddOnEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        this.handleErrorResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.createDishApiId) {
      if (!!this.state.dishId) {
        toast.success(configJSON.updateDishMessage)
        this.setState({buttonLoader : false})
        this.props.history.push("/admin/restaurant/dishes/00")
      } else {
        
        toast.success(configJSON.createDishMessage)
        this.setState({ restaurantId: this.state.restaurantId })
      }
      let id = this.state.restaurantId
      this.props.history.push({
        pathname: `/admin/restaurant/dishes/${this.state.restaurantId}`, state: {
          id
        }
      })
    }

    if (apiRequestCallId === this.getDishApiId) {
      const {
        name,
        description,
        price,
        compare_at_price,
        dish_category,
        tags,
        is_vegetarian,
        status,
        images,
        addon_types,
      } = responseJson.data.attributes;          

      const dishTags = tags?.map((item: any) => item['name'])?.join() || ""

      const addOns = this.formatAddOnFromResponse(addon_types)

      this.setState({
        dishTitle: name,
        dishDescription: description,
        dishImages: images,
        dishStatus: status,
        dishCategory: dish_category?.data?.id || "",
        dishPrice: price,
        dishComparePrice: compare_at_price,
        dishTags: dishTags,
        isVeg: is_vegetarian,
        createDishAddons: addOns,
        dishLoader: false,
        dishId : responseJson.data?.id,
        modeType : configJSON.editDishText,
        buttonName: configJSON.updateDishText
      })

    }

    if (apiRequestCallId === this.removeDishApiId) {
      toast.success(configJSON.dishRemovalMessage)
      this.props.history.push("/admin/restaurant/dishes/00")
    }

    if (apiRequestCallId === this.removeAddOnTypeApiId) {
      const { id } = responseJson.data

      const index = this.state.createDishAddons?.findIndex((addOns: any) => addOns?.id === id)
      const newList = _.remove(
        this.state.createDishAddons,
        (_: any, indexNum: number) => {
          return indexNum !== index;
        }
      );
      this.setState({
        createDishAddons: newList
      })
    }

    if (apiRequestCallId === this.removeSingleAddOnTypeApiId) {

      const { addon_type_id, id } = responseJson.data

      const newAddOns = this.state.createDishAddons?.map((addOns: any) => {
        if (addOns?.id === addon_type_id) {
          const index = addOns?.addOnList?.findIndex((addOn: any) => addOn?.id == id)

          const newList = _.remove(
            [...addOns?.addOnList],
            (_: any, indexNum: number) => {
              return indexNum !== index;
            }
          );
          return {
            ...addOns,
            addOnList: newList
          }
        }
        return addOns
      })

      this.setState({
        createDishAddons: newAddOns
      })
    }
  }

  handleErrorResponse = (_: string, responseJson: any) => {
    this.setState({buttonLoader : false})
    if (responseJson?.errors) {
      responseJson.errors?.forEach((err: any) => {
        toast.error(err?.message);
      });
      if(responseJson?.errors[0] && (responseJson?.errors[0].message.includes([configJSON.tokenExpiredText,configJSON.invalidTokenText]))) {
        this.props.logout()
     }
    } 
  }

  formatAddOnFromResponse = (addon_types: any) => {
    const addOns: any = []
    Object.keys(addon_types)?.forEach((key: string) => {

      const list = addon_types[key]?.addons?.data.map((addOnList: {
        id: number,
        attributes : {
        name: string,
        price: number
        }
      }) => {
        const { name, price } = addOnList.attributes

        return {
          id: addOnList.id,
          name: name,
          price: price
        }
      })
      const data = {
        addOnType: key,
        id: addon_types[key].id,
        addOnList: list
      }
      addOns.push(data)
    })
    return addOns
  }

  handleBackButton = () => {
    this.props.history?.push({
      pathname: `/admin/restaurant/dishes/${this.state.restaurantId}`, state: {
        id : this.state.restaurantId
      }
    })
  }

  handleDeleteButton = () => {
    if(this.state.dishId) {
      this.openDeleteDialog()
    }
    else this.clearDishFields()
  }

  handleDishTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dishTitle: event.target.value, titleError:'' });
  };

  handleDishDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ dishDescription: event.target.value, descError:'' });
  };

  handleDishStatusChange = (event: React.ChangeEvent<{value: any}>) => {
    this.setState({ dishStatus: event.target?.value });
  };

  handleDishCategoryChange = (event : React.ChangeEvent<{value: any}>) => {
    if (event.target?.value === "default") event.target.value = "";
    this.setState({ dishCategory: event.target?.value });
  };

  handleDishPriceChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
    if (!isNaN(+event.target.value)) this.setState({ dishPrice: event.target.value, priceError:'' });
  };

  handleDishComparePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(+event.target.value)) this.setState({ dishComparePrice: event.target.value, comparePriceError:'' });
  };

  handleDishTags = (value: string) => {
    this.setState({ dishTags: value });
  };

  handleDishImages = (e: any) => {
    const fileArray: { url: string }[] = [];

    const allFiles = e.target.files;

    [...allFiles]?.forEach((file: any) => {
      if (this.allowedExtension.includes(file.type)) {
        const image = {
          file,
          url: URL.createObjectURL(file),
        };
        fileArray.push(image);
      }
    });

    this.setState({ dishImages: [...this.state.dishImages, ...fileArray] , imageError:''});
  };

  removeDishImage = (index: number, imageId: number) => {
    if (!!imageId){
       this.removeDishImageApi(imageId)
    }
    this.updateDishImageList(index)
  };

  updateDishImageList = (index: number) => {
    const newImages = _.remove(
      [...this.state.dishImages],
      (_: any, indexNum: number) => {
        return indexNum !== index;
      }
    );
    this.setState({ dishImages: newImages });
  }

  handleDishType = (e: any) => {
    this.setState({ isVeg: e.target.checked });
  };

  formatOptions = (options: any[]) => {
    return options?.map((option: any) => {
      return {
        value: option.id,
        label: option.name,
      };
    });
  };

  createAddOnType = () => {
    this.setState({
      createDishAddons: [
        ...this.state.createDishAddons,
        { ...this.defaultAddOn },
      ],
    });
  };

  removeAddOnType = (index: number, id: number) => {
    if (!!id) {
      this.removeAddOnTypeApi(id)
    } else {
      const newAddOns = _.remove(
        [...this.state.createDishAddons],
        (_: any, indexNum: number) => {
          return indexNum !== index;
        }
      );
      this.setState({ createDishAddons: newAddOns });
    }
  };

  handleChangeAddOnType = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newAddOns = [...this.state.createDishAddons]?.map(
      (addOn: any, indexNum: number) => {
        if (index === indexNum) {
          return {
            ...addOn,
            addOnType: event.target.value,
          };
        }
        return addOn;
      }
    );

    this.setState({ createDishAddons: newAddOns });
  };

  handleChangeAddOnDetails = (
    typeIndex: number,
    index: number,
    field: string,
    event:React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Valuee",typeIndex, index)
    const newAddOns = [...this.state.createDishAddons]?.map(
      (addOn: any, indexNum: number) => {
        if (typeIndex === indexNum) {
          const newList = [...addOn.addOnList]?.map(
            (list: any, childIndex: number) => {
              if (index === childIndex) {
                if (field === configJSON.priceText && isNaN(+event.target.value)) event.target.value = "";
                return {
                  ...list,
                  [field]: event.target.value,
                };
              }
              return list;
            }
          );

          return {
            ...addOn,
            addOnList: newList,
          };
        } else {
          return addOn;
        }
      }
    );

    this.setState({ createDishAddons: newAddOns });
  };

  addAddOnDetails = (typeIndex: number) => {
    const addOns = [...this.state.createDishAddons];
    const addOnList = [...addOns[typeIndex]?.addOnList];

    const isValueAdded =
      addOnList[addOnList.length - 1]?.name &&
      addOnList[addOnList.length - 1]?.price;

    if (isValueAdded) {
      addOns[typeIndex]?.addOnList.push({
        name: "",
        price: "",
      });
      this.setState({ createDishAddons: addOns });
    } else {
      toast.error(configJSON.completeAddOnMessage);
    }
  };

  removeAddOn = (typeIndex: number, childIndex: number, id?: number) => {

    if (!!id) {
      this.removeSingleAddOnTypeApi(id)
    } else {
      const newAddOns = [...this.state.createDishAddons]?.map(
        (addOn: any, index: number) => {
          if (typeIndex === index) {
            const list = [...addOn.addOnList];
            const newList = _.remove(list, (_: any, indexNum: number) => {
              return indexNum !== childIndex;
            });

            return {
              ...addOn,
              addOnList: newList,
            };
          }
          return addOn;
        }
      );
      this.setState({ createDishAddons: newAddOns });
    }
  };

  createDish = () => {
    const {
      dishTitle,
      dishDescription,
      dishCategory,
      dishStatus,
      dishTags,
      dishComparePrice,
      dishImages,
      dishPrice,
      isVeg,
    } = this.state;

    const isBlankField =
      !dishTitle ||
      !dishPrice ||
      !dishDescription ||
      dishImages.length === 0 ||
      !dishStatus;

    if (isBlankField) {
      this.setState({ titleError: configJSON.fieldValidateText })
    } else {
      const tags = !!dishTags
        ? dishTags?.split(",")?.map((value) => value.trim())
        : [];

      const formData = new FormData();
      formData.append("dish[name]", dishTitle)
      formData.append("dish[description]", dishDescription)
      formData.append("dish[price]", dishPrice)
      formData.append("dish[compare_at_price]", dishComparePrice)
      formData.append("dish[restaurant_id]", this.state.restaurantId)
      formData.append("dish[status]", dishStatus)
      formData.append("dish[category_id]", dishCategory.toString())
      formData.append("dish[is_vegetarian]", isVeg.toString())

      tags?.map((tag: string) => {
        formData.append(`dish[tag][]`, tag)
      })

      this.state.createDishAddons?.forEach((addOns: any) => {
        formData.append(`addon_types[][name]`, addOns.addOnType)
        formData.append(`addon_types[][id]`, addOns?.id || "")

        addOns.addOnList.forEach((addOnList: any) => {
          formData.append(`addon_types[][addons][][name]`, addOnList.name)
          formData.append(`addon_types[][addons][][price]`, addOnList.price)
          formData.append(`addon_types[][addons][][id]`, addOnList?.id || "")
        })

      })

      this.state.createDishAddons.length === 0 && formData.append(`addon_types[]`, "")

      this.state.dishImages?.forEach((image: any) => {
        !image?.id && formData.append("dish[images][]", image.file, image.file.name)
      })

      this.createDishApi(formData);
    }
  };

  resetDishDetails = () => {
    this.setState({
      dishTitle: "",
      dishDescription: "",
      dishImages: [],
      dishStatus: configJSON.activeText,
      dishCategory: "",
      dishPrice: "",
      dishComparePrice: "",
      dishTags: "",
      isVeg: true,
      createDishAddons: [],
    })
  }

  handleYesBtn = () => {
    this.closeDeleteDialog()
    this.deleteDish()
    this.handleBackButton()
  }

  openDeleteDialog = () => {
    this.setState({ deleteDialog: true })
  }

  closeDeleteDialog = () => {
    this.setState({ deleteDialog: false })
  }

  deleteDish = () => {
    this.removeDishApi(this.state.dishId)
  }

  clearDishFields = () => {
    this.setState({
      dishTitle: '',
      dishDescription: '',
      dishImages: [],
      dishPrice: '',
      dishComparePrice: '',
      createDishAddons: [],
      isVeg: true,
      dishStatus: configJSON.activeText,
      dishCategory: '',
      dishTags: ''

    })
  }

  validate = () => {
    const {
      dishTitle,
      dishDescription,
      dishImages,
      dishPrice,
      dishComparePrice
    } = this.state;

    const isNotValid = this.handleValidation()
    const numericPrice = parseFloat(dishPrice)
    const numericComparePrice = parseFloat(dishComparePrice)
    const isDishPriceValid = numericPrice > 0
    const isComparePriceValid = (numericComparePrice < numericPrice) && (numericComparePrice > 0) && (numericComparePrice < 50000)
    const isTitleValid = dishTitle.length <= 100
    if(!isNotValid && isComparePriceValid && isDishPriceValid && dishTitle && isTitleValid && dishDescription && dishPrice && dishImages && dishComparePrice){
      this.createDish()
    }
  }

  handleValidation = () => {
    const {
      dishTitle,
      dishDescription,
      dishImages,
      dishPrice,
      dishComparePrice
    } = this.state;
    const isPriceEmpty  = (dishComparePrice.length === 0 || (dishComparePrice === null))
    if(dishTitle.length > 100){
      toast.error(configJSON.titleValidateMsg)
    }
    if(!dishTitle) {
      this.setState({ titleError: configJSON.fieldValidateText })
    }
    if(!dishDescription){
      this.setState({ descError: configJSON.fieldValidateText })
    }
    if(!dishComparePrice && isPriceEmpty){
      this.setState({ comparePriceError: configJSON.fieldValidateText })
    }
    if(dishImages.length === 0 ){
      this.setState({ imageError: configJSON.fieldValidateText })
    }
    this.validateDishPrice(dishPrice)
    dishComparePrice && this.validateDishComparePrice(dishComparePrice, dishPrice)
    return this.addOnValidation()
  }

  validateDishComparePrice = (price : string, dishPrice: string) => {
    const numericValue = parseFloat(price);
    const checkDishPrice = parseFloat(dishPrice)
    if (numericValue && isNaN(numericValue) || numericValue < 0) {
      toast.error(configJSON.compareAtPricePositiveMsg)
    }

    if (numericValue === 0 || /^(0+(\.0+)?$|0*\.$)/.test(price)) {
      toast.error(configJSON.compareAtPriceErrorMsg)
    }
    
    if (numericValue >= parseFloat(dishPrice)  && (checkDishPrice > 0) && ((checkDishPrice <= 50000) || (checkDishPrice <= 50000.0))) {
      toast.error(configJSON.comparePriceError +` ${dishPrice}`)
    }
  }

  validateDishPrice  = (price : string) => {
    const numericValue = parseFloat(price);
    if(!price){
        this.setState({ priceError: configJSON.fieldValidateText })
      }
    if (numericValue && isNaN(numericValue) || numericValue < 0) {
      toast.error(configJSON.dishPricePositiveMsg)
    }

    if (numericValue === 0 || /^(0+(\.0+)?$|0*\.$)/.test(price)) {
      toast.error(configJSON.dishPriceErrorMsg)
    }

    if (numericValue > 50000) {
      toast.error(configJSON.dishMaxPriceErrMsg)
    }
  }

  addOnValidation = () => {
    const { createDishAddons } = this.state
    let addOns = false;
    if(createDishAddons && createDishAddons.length > 0){
      createDishAddons.forEach((element:any) => {
        if(element.addOnType == ''){
          toast.error(configJSON.fillAddOnCategoryMessage)
          addOns = true;
        } 
        element.addOnList?.forEach((i:any) => {
          if((i.name && i.price) === ''){
            toast.error(configJSON.fillAllFieldsMessage)
            addOns = true;
          }
        })
      });
      return addOns
    }
  }

  outlineClass = (status:string, targetStatus:string, className:string) => {
  return status === targetStatus ? className : '';
  }

  // Customizable Area End
}
