import React from "react";
import "../../../web/src/assets/css/style.scss";
import AllOrderHistoryController, { Props } from "./AllOrderHistoryController.web";
import { Box, CircularProgress } from "@material-ui/core";
import "../assets/css/orderHistory.scss";
import { withRouter } from "react-router-dom"
import OrderHistoryCard from "./OrderHistoryCard.web";

export class RestaurantOrderHistory extends AllOrderHistoryController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { loader, orderHistory } = this.state
    
    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <>
            <OrderHistoryCard orderList={orderHistory} />
          </>
        )}
      </>
    );
  }
}

export default withRouter(RestaurantOrderHistory);
