// Customizable Area Start
import React from "react"
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import { locationImg } from "../../info-page/src/assets"
import { toast } from "react-toastify"
import { GoogleApiWrapper, IMapProps } from "google-maps-react"
const configJSON = require("./config.js")

interface IProps extends IMapProps {
  nearByVal: string
  handleNearByValChange: (address: string) => void
  handleNearBySelect: (latLng: { lat: number; lng: number }) => void
}

const FavouriteCustomLocation = ({
  nearByVal,
  handleNearByValChange,
  handleNearBySelect,
}: IProps) => {
  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        handleNearBySelect(latLng)
      })
      .catch((error) => {
        console.error("error", error)
        toast.error("Something went wrong")
      })
    handleNearByValChange(address)
  }

  return (
    <Box className="restaurantGooglemap">
      <PlacesAutocomplete
        value={nearByVal}
        onChange={handleNearByValChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div className="customTextfield">
              <OutlinedInput
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
                placeholder="Near by"
                fullWidth={true}
                classes={{
                  root: "filter_input_root",
                  input: "filter_input_input",
                  focused: "filter_input_focus",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="end" style={{ padding: "0px" }}>
                      <img
                        className="filter_input_icon"
                        src={locationImg}
                        alt="search_icon"
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {(loading || suggestions.length > 0) && (
                <div className="googlemap-data">
                  <div className="autocomplete-dropdown-container googlemap-dropdown">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          }
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Box>
  )
}

export default GoogleApiWrapper({ apiKey: configJSON.GOOGLE_API_KEY })(
  FavouriteCustomLocation
)
// Customizable Area End
