import { ActiveTabEnum } from "../../../../components/src/redux/Users/userEnums"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import { IBlock } from "../../../../framework/src/IBlock"
import { runEngine } from "../../../../framework/src/RunEngine"

export interface IRestaurantDetails {
  id: string
  type: string
  attributes: {
    name: string
    average_rating: number
    latitude: number
    longitude: number
    dish_name: string
    image: {
      id: number
      url: string
      filename: string
    }
    org_address: string
    quote_time: string
    price: string
  }
}

export interface Props {
  navigation: any
  id: string
  // Customizable Area Start
  history: any
  location: any
  activeTab: string
  isLoading: boolean
  mapViewRestaurants: IRestaurantDetails[]
  resetMapViewRestaurant: () => void
  updateActiveTab: (tabName: string) => void
  setSearchValueForRestaurant: (value: string) => void
  getMapViewRestaurants: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeRestaurant: string
  lat: number
  lng: number
  // Customizable Area End
}

interface SS {
  id: any
}

export default class RestaurantMapViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.state = {
      activeRestaurant: "",
      lat: 0,
      lng: 0,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    const {
      location,
      activeTab,
      updateActiveTab,
      setSearchValueForRestaurant,
      getMapViewRestaurants,
    } = this.props
    const { state }: any = location

    activeTab !== ActiveTabEnum.Restaurant &&
      updateActiveTab(ActiveTabEnum.Restaurant)

    if (state) {
      setSearchValueForRestaurant(state)
      getMapViewRestaurants()
      this.setDefaultLocation()
    }
  }

  async componentWillUnmount(): Promise<void> {
    this.props.resetMapViewRestaurant()
  }

  setDefaultLocation = () => {
    navigator.geolocation.getCurrentPosition((position: any) => {
      const { latitude, longitude } = position.coords
      this.setState({ lat: latitude, lng: longitude, activeRestaurant: "" })
    })
  }

  openRestaurantDetails = (id: string) => {
    this.setState({ activeRestaurant: id })
  }

  closeRestaurantDetails = () => {
    this.setState({ activeRestaurant: "" })
  }

  handleActiveRestaurant = (lat: number, lng: number, id: string) => {
    this.setState({ lat, lng, activeRestaurant: id })
  }

  handleUnActiveRestaurant = () => {
    this.setDefaultLocation()
  }

  handleOrderFoodSubmit = (id: string) => {
    this.props.history.push({ pathname: `${AppRoutings.RestaurantDetails}/${id}` })
  }
}
