// Customizable Area Start
import React from "react"
import { Button } from "@material-ui/core"
import CustomHelpPopover from "./CustomHelpPopover.web"
const configJSON = require("./config.js")

interface IProps {
  isSquareConnected: boolean
  squareConnectionUrl: string
}

const CustomSquareConnectButton = ({
  isSquareConnected,
  squareConnectionUrl,
}: IProps) => {
  return (
    <>
      {isSquareConnected ? null : (
        <div className="connectContainer connectSmallContainer">
          <a href={squareConnectionUrl}>
            <Button className="connectButton">Connect Square Account</Button>
          </a>
          <CustomHelpPopover
            helpMessage={configJSON.squareConnectHelpMessage}
          />
        </div>
      )}
    </>
  )
}

export default CustomSquareConnectButton
// Customizable Area End
