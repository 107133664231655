// Customizable Area Start
import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import HeadingWithButtonController from "./HeadingWithButtonController.web"

class HeadingWithButton extends HeadingWithButtonController {
  render() {
    const { heading, viewAllRedirectUrl } = this.props

    return (
      <Box style={styles.mainWrapper}>
        <Typography variant="h4" style={styles.heading}>{heading}</Typography>
        {viewAllRedirectUrl && <Link to={viewAllRedirectUrl}>
          <button style={styles.viewAllBtn}>View all</button>
        </Link>}
      </Box>
    )
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  heading: {
    color: "#000",
    fontWeight: 600,
    fontSize: "22px",
    fontFamily: "'Montserrat', sans-serif",
  },
  viewAllBtn: {
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid #20a258",
    padding: "6px 12px",
    color: "#333",
    backgroundColor: "#fff"
  },
}

export default HeadingWithButton
// Customizable Area End
