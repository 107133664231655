// Customizable Area Start
import React, { useEffect, useState } from "react"
import { Grid, Box, Slider } from "@material-ui/core"
import "./assets/css/farmpagesidebar.scss"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import CloseIcon from "@material-ui/icons/Close"
import {
  setFarmSelectedCategory,
  setFarmSelectedTag,
  setFilterByPrice,
  setNearByFilter,
  getFarmListAction
} from "./assets"
import { useDispatch, useSelector } from "react-redux"
import { IFarmCategory, IFarmTag } from "./interfaces/farm"
import clsx from "clsx"
import { SLIDER_STEP } from "./utility/constants"

interface Props {
  categoryTitle: string
}

function valuetext(value: number) {
  return `${value}°C`
}

const FarmPageSideBar = (props: Props) => {
  const { categoryTitle } = props
  const {
    farmsTags,
    farmsCategories,
    selectedCategory,
    selectedTag,
    nearByFilter,
    minPrice,
    maxPrice,
  } = useSelector((state: any) => state.farms)
  const dispatch = useDispatch()
  const [rangeValue, setRangeValue] = useState<number[]>([minPrice, maxPrice])

  useEffect(() => {
    setRangeValue([minPrice, maxPrice])
  }, [minPrice, maxPrice])

  useEffect(() => {
    getLocation()
  }, [])

  const handleChange = (
    _: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue)) setRangeValue(newValue)
    else setRangeValue([newValue, newValue])
  }

  const handlePriceChange = (
    _: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    dispatch(setFilterByPrice(newValue))
  }

  const handleCategoriesClick = (value: string) => {
    dispatch(setFarmSelectedCategory(value))
  }

  const handleTagClick = (value: string) => {
    dispatch(setFarmSelectedTag(value))
  }

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      dispatch(
        setNearByFilter(position.coords.latitude, position.coords.longitude)
      )
    }, () => {
      dispatch(getFarmListAction())
    })
  }

  return (
    <Box className="farm_sidebar_container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <Box className="farm_sidebar_heading">
              <ArrowBackIosIcon className="farm_side_bar_icons" />
              <p className="farm_sidebar_box_heading">{categoryTitle}</p>
            </Box>
            <Box className="related_category_contents">
              <Box className="sidebar_text_and_content_wrapper">
                <Box>
                  {farmsCategories?.length > 0 &&
                    farmsCategories.map((item: IFarmCategory) => {
                      const { id, name } = item
                      return (
                        <p
                          key={id}
                          className={clsx({["selected"]: selectedCategory === item.name })}
                          onClick={() => handleCategoriesClick(item.name)}
                        >
                          {`${name[0].toUpperCase()}${name.slice(1)}`}
                        </p>
                      )
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <p className="farm_sidebar_box_heading">Refine by</p>
            <Box
              className={clsx("refine_by_content", {
                ["refine_by_content_active"]: nearByFilter
              })}
              onClick={getLocation}
              style={{ cursor: "pointer" }}
            >
              <p>Near by farms</p>
              <CloseIcon className="side_bar_close_icon_active" fontSize="small" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <p className="farm_sidebar_box_heading">Price</p>
            <Box className="farm_sidebar_range">
              <Slider
                min={minPrice}
                step={SLIDER_STEP}
                max={maxPrice}
                value={rangeValue}
                onChange={handleChange}
                onChangeCommitted={handlePriceChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
            </Box>
            <p className="price_container">
              Price :{" "}
              <span>
                ${rangeValue[0]} - ${rangeValue[1]}
              </span>
            </p>
          </Box>
        </Grid>

        <Box className="product_tag_content_wrapper">
          <p className="product_tag_header">Product Tag</p>
          <Grid container spacing={2} className="product_tag_content">
            {farmsTags?.map((tags: IFarmTag) => {
              const { id, name } = tags.attributes
              return (
                <Grid item xs={12} sm={6} md={12} lg={6} key={id}>
                  <p
                    className={clsx({ ["selected"]: selectedTag === name })}
                    onClick={() => handleTagClick(name)}
                  >
                    {`${name[0].toUpperCase()}${name.slice(1)}`}
                  </p>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

export default FarmPageSideBar
// Customizable Area End
