import React,{useEffect} from 'react';
import {useDispatch} from "react-redux";
import { setUserDetailsFromLocal, setUserCartIdFromLocal } from "./assets"

// This is Action to Initialize redux user store
const UserInitialization = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setUserDetailsFromLocal())
        dispatch(setUserCartIdFromLocal())
    }, []);

    return (
        <div></div>
    );
};

export default UserInitialization;
