export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

// Customizable Area Start
export const {
    updateActiveTab
} = require("../../../components/src/redux/Users/userActions")

export const {
    setFPData
} = require("../../../components/src/redux/CMS/cmsActions")
// Customizable Area End
