// Customizable Area Start
import React, { useState } from "react";
// common css
import "../../../web/src/assets/css/style.scss";
// custom components
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import CustomNoRecordsFound from "../../../components/src/customNoRecordsFound.web";

import { withRouter } from "react-router-dom";

// icons
import SearchIcon from "@material-ui/icons/Search";
//charts

import AdminFarmForumsController, {
  Props,
} from "./AdminFarmForumsController.web";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  InputLabel,
} from "@material-ui/core";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";

interface EnhancedTableProps {
  numSelected?: number;

  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
              data-testid="allcheckBox"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={34}
            />
            <span>FORUM NAME</span>
          </div>
        </TableCell>
        <TableCell align="center"> STATUS</TableCell>
        <TableCell align="center"> FORUM id</TableCell>
        <TableCell align="center">LIKES</TableCell>
        <TableCell align="center">COMMENTS</TableCell>

        <TableCell />
      </TableRow>
    </TableHead>
  );
}

class AdminFarmForums extends AdminFarmForumsController {
  constructor(props: Props) {
    super(props);
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected =
        this.state.forumsList && this.state.forumsList.map((n: any) => n.id);
      this.setState({ seletedForum: newSelected });
    } else {
      this.setState({ seletedForum: [] });
    }
  };
  handleChangeTablePage = (value: number) => {
    this.setState({ forumsCurrentPage: value });
    this.getForumsList(
      this.state.currentFarmId,
      this.state.search,
      this.state.forumsPerPage,
      value
    );
  };

  handleIncreasePageView = () => {
    if (this.state.forumsPerPage < 25) {
      this.setState({
        forumsPerPage: this.state.forumsPerPage + 5,
        forumsCurrentPage: 1,
      });
      this.getForumsList(
        this.state.currentFarmId,
        this.state.search,
        this.state.forumsPerPage + 5,
        1
      );
    }
  };

  handleDecreasePageView = () => {
    if (this.state.forumsPerPage > 5) {
      this.setState({
        forumsPerPage: this.state.forumsPerPage - 5,
        forumsCurrentPage: 1,
      });
      this.getForumsList(
        this.state.currentFarmId,
        this.state.search,
        this.state.forumsPerPage - 5,
        1
      );
    }
  };
  render() {
    const { classes } = this.props;

    const indexOfLastList =
      this.state.forumsCurrentPage * this.state.forumsPerPage;
    const indexOfFirstList = indexOfLastList - this.state.forumsPerPage;
    const cureentTableList = this.state.forumsList && this.state.forumsList;
    const roundPages =
      cureentTableList &&
      Math.floor(this.state.total_count / this.state.forumsPerPage);
    let totalListCount;
    if (this.state.forumsCurrentPage > roundPages) {
      totalListCount = this.state.total_count;
    } else {
      totalListCount = indexOfLastList;
    }
    const menu = ["edit", "remove"];
    const farmStatus = ["active", "draft"];
    //@ts-ignore
    const farmExit = window.location.pathname.split("/")[4] == 0;
    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown restForumTableContainer">
          {/* filter section */}
          <Loader loading={this.state.loading} />
          <div className="headerListContainer">
            <div className="prodListHeader">
              <div className="blocks">
                <h4 data-testid="forumHeading">Forums</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="customSelect prodFarmSelect farmOrdersDropDown dishCategorySelect">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="farmSelected"
                        className="menu-customize"
                        value={this.state.currentFarmId}
                        disabled={farmExit}
                        onChange={(e: any) => {
                          const targetValue = e.target.value;
                          targetValue != "0" &&
                            targetValue != "00" &&
                            this.getForumsList(
                              targetValue,
                              this.state.search,
                              this.state.forumsPerPage,
                              1
                            );
                          if (targetValue != "0") {
                            this.setState({
                              currentFarmId: targetValue,
                            });
                            this.props.history.push(
                              `/admin/farm/forums/${targetValue}`
                            );
                          }
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown,
                          },
                        }}
                      >
                        <MenuItem value={0} className="">
                          <span>No farms listed</span>
                          <span className="checkmark">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {this.state.farmsList &&
                          this.state.farmsList.length > 0 &&
                          this.state.farmsList.map((item: any, index: any) => {
                            return (
                              <MenuItem value={item.id} key={item.id}>
                                <span>{item.attributes.name}</span>
                                <span className="checkmark">
                                  <div className="checkmark_circle" />
                                  <div className="checkmark_stem" />
                                  <div className="checkmark_kick" />
                                </span>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                  <div className="addButton">
                    <CustomButton
                      label="Create Forum"
                      data-testid="createBtn"
                      disabled={farmExit}
                      onClick={() => {
                        this.props.history.push(
                          `/admin/farm/add-forum/${
                            window.location.pathname.split("/")[4]
                          }?mode=create`
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodList prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                  id="input-with-icon-textfield"
                  data-testid="searchBox"
                  value={this.state.search}
                  onChange={(e: any) => {
                    this.setState({
                      search: e.target.value,
                    });
                    this.SearchDebounceUpdate(
                      this.state.currentFarmId,
                      e.target.value,
                      this.state.forumsPerPage,
                      1
                    );
                  }}
                  variant="standard"
                  placeholder="Search by Name, type, Id, etc... "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  {/* <CustomSelectField label="Action" /> */}
                  <div className="customSelect categoryDropDown dishCategorySelect">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label" style={{"color":`${this.state.seletedForum.length > 1 ? "black":"#ccc"}`}}>
                        Actions
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        data-testid="bulkActions"
                        className="menu-customize"
                        value={this.state.bulkAction}
                        disabled={this.state.seletedForum.length < 2}
                        onChange={(e: any) => {
                          this.bulkActions(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.categoryDropDown,
                          },
                        }}
                      >
                        {farmStatus.map((status: string) => {
                          return (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block restforums">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                {cureentTableList && cureentTableList.length > 0 && (
                  <EnhancedTableHead
                  data-testid="tableHead"
                    numSelected={this.state.seletedForum.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={cureentTableList && cureentTableList.length}
                  />
                )}
                <TableBody>
                  {cureentTableList && cureentTableList.length > 0 ? (
                    cureentTableList.map((item: any) => {
                      const isItemSelected = this.isSelectedForum(item.id);
                      const x = item.attributes;

                      return (
                        <TableRow key={item.id}>
                          <TableCell scope="row">
                            <div
                              className="productCell"
                              data-testid="handleClick"
                              onClick={(event: any) =>
                                this.handleClickForumRow(event, item.id)
                              }
                            >
                              <CustomCheckboxField
                                id={item.id}
                                checked={isItemSelected}
                              />
                              <img
                                src={x.banner.url}
                                className="prodImage"
                                alt="banner"
                              />
                              <p>{x.heading}</p>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            <div
                              className={`customSelect itemStatus ${x.status ==
                                "draft" && "warning-variant"}`}
                            >
                              <FormControl>
                                <Select
                                  labelId="demo-controlled-open-select-label"
                                  id="demo-controlled-open-select"
                                  data-testid="selectStatus"
                                  value={x.status}
                                  onChange={(e: any) => {
                                    this.updateForumStatus(
                                      item.id,
                                      e.target.value
                                    );
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    classes: {
                                      paper: `${classes.itemStatus}`,
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {farmStatus.map((status: any, index: any) => {
                                    return (
                                      <MenuItem value={status} key={status} className={` dish_list_status_menus ${this.getMenuStyleCss(status)}`}>
                                        {status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="BoldCell">
                            {x.forum_id}
                          </TableCell>
                          <TableCell align="center">{x.likes}</TableCell>
                          <TableCell align="center">{x.comments}</TableCell>
                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                                data-testid="basicMenu"
                                item={item}
                                menu={menu}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0" }}
                      >
                        <CustomNoRecordsFound
                          routeUrl={`/admin/farm/add-forum/${
                            window.location.pathname.split("/")[4]
                          }?mode=create`}
                          title="Forum"
                          disable={farmExit}
                          search={this.state.search}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {cureentTableList?.length > 0 && (
              <div className="paginationRow restOrderPagination">
                <div>
                  {indexOfFirstList + 1}- {totalListCount} of{" "}
                  {this.state.total_count} items
                </div>
                <CustomPagination
                data-testid="customPagination"
                  count={this.state.total_pages}
                  page={this.state.forumsCurrentPage}
                  pagePerView={this.state.forumsPerPage}
                  handlePageChange={this.handleChangeTablePage}
                  shape="rounded"
                  siblingCount={1}
                  increasePagePerView={this.handleIncreasePageView}
                  decreasePagePerView={this.handleDecreasePageView}
                  boundaryCount={1}
                />
              </div>
            )}
          </div>

          {/* remove modal */}
          <div>
            <Dialog
              open={this.state.openDialog}
              onClose={() => this.handleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete the forum ?
              </DialogTitle>
              <DialogActions>
                {/* //@ts-ignore */}
                {/* <Button onClick={() => this.handleDialog()}>No</Button>
                <Button
                  onClick={() => {
                    this.handleDialog();
                    this.deleteForum();
                  }}
                  autoFocus
                >
                  Yes
                </Button> */}

                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label="No"
                    onClick={() => this.handleDialog()}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                    color="secondary"
                    label="Yes"
                    onClick={() => {
                      this.handleDialog();
                      this.deleteForum();
                    }}
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    );
  }
}
export {AdminFarmForums}
//@ts-ignore
export default withRouter(withStyles(commonCmptStyles)(AdminFarmForums));
// Customizable Area End
