// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import StorageProvider from "../../../framework/src/StorageProvider.web"
import { RouteComponentProps } from "react-router-dom"
export const configJSON = require("./config.js")

export interface IRole {
  id: number
  name: string
  created_at: string
  updated_at: string
}
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  remainingRoles: IRole[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class AccountSettingRoleSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRolesApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ]

    this.state = {
      isLoading: false,
      remainingRoles: [],
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRoles()
  }

  getRedirectionLink = (name: string) => {
    const roleRedirectionLinks: any = {
      "Farmer/merchant": "/create-farmer-profile/:1",
      "Restaurateur": "/create-restaurant-profile/:2",
      "Contributor": "/create-educator-profile/:3",
    }

    return roleRedirectionLinks[name]
  }

  getRoles = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getRolesApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetRoles
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.getRolesApiCallId) {
          this.setState({ remainingRoles: responseJson, isLoading: false })
        }
      } else {
        if (apiRequestCallId === this.getRolesApiCallId) {
          this.setState({ remainingRoles: [], isLoading: false })
        }
      }
    }
    // Customizable Area End
  }
}
