import React, { Component } from "react";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import {
  Grid,
  Container,
  Box,
  MenuItem,
  Button,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import FarmsPageSearchFilterComponent from "../../../components/src/FarmsPageSearchFilterComponent.web";
import Heading from "../../../components/src/HeadingComponent.web";
import FarmsCard from "../../../components/src/FarmsSectionsImgCard.web";
import { leaveImg } from "./assets";
import "./assets/css/FarmsPage.scss";
// import './assets/css/newvendor.scss';
import { farm1, farm2, farm3 } from "./assets";
import LeafImgWithTextBanner from "../../../components/src/WebLeafWithTextBanner.web";
import WebPagination from "../../../components/src/WebPagination";
import GreenRoundedButton from "../../../components/src/GreenRoundedButton.web";

class FarmsPage extends Component {
  render() {
    return (
      <>
        {/* <WebHeader />
        <WebRoutingTab /> */}

        <Box className="farms_page_content_wrapper">
          <Container fixed>
            <FarmsPageSearchFilterComponent />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                <LeafImgWithTextBanner
                  heading=" Recently added Farms"
                  text=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quisquam quam reiciendis laborum non molestiae corporis voluptate, ab nesciunt. Accusantium?"
                  linkText="Click Here"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                <FarmsCard
                  cardName="product Name"
                  thumbnail={farm1}
                  displayPrice="none"
                  displayButton="block"
                  displayDuration="none"
                  displayRating="block"
                  boxShadow={false}
                  path=""
                  imgPath=""
                />
              </Grid>
              <Grid item xs={12} >
              <Box className="btn_view_all">
              <GreenRoundedButton path="/" buttonText="View All" />
              </Box>
              </Grid>
            </Grid>
            
            
            <Box className="pagination_grid">
            <WebPagination/>
            </Box>
          </Container>
        </Box>
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}

export default FarmsPage;
