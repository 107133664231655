import React from "react";
import webEducationCourseDetailsController, {
  Props,
} from "./webEducationCourseDetailsController.web";
import WebHeader from "../../../components/src/WebHeader.web";
import WebFooter from "../../../components/src/WebFooter.web";
import GreenBanner from "../../../components/src/GreenBanner.web";
import WebRoutingTab from "../../../components/src/WebRoutingTab.web";
import EducationSidebar from "./EducationSidebar.web";
import { withRouter } from "react-router-dom";
// css
import '../../../web/src/assets/css/style.scss'
import "../assets/css/educationCourseDetail.scss";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
//images
import {
  TimerIcon,
  TopicsIcon,
  EnrollIcon,
  TrainerImg,
  ServiceImg,
  BulletIcon,
  ProfileImg1
} from "./assets";
//components
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCourseDetailTab from "../../../components/src/CustomCourseDetailTab.web";
import CustomAccordion from "../../../components/src/CustomCourseAccordian.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";

class webEducationCourseDetails extends webEducationCourseDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      <>
        {/* <div className="Header"> */}
        <WebHeader />
        <WebRoutingTab />
        {/* education course detail wrapper */}
        <main className="educationCourseDetailWapper">
          <div className="subContentWrapper">
            <section className="publicSpeakingSection commonSection">
              <h1>Public Speaking</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci vero alias perspiciatis<br></br>dolore non
                possimus maxime!
              </p>
              <div className="videoBlock bannerBlocks">
                <iframe src="https://www.youtube.com/watch?v=tFKRK7Uy_dg" title="W3Schools Free Online Web Tutorials">
                </iframe>
              </div>
            </section>
            <section className="enrollSection commonSection">
              <Grid container spacing={3} alignItems="stretch" className="cardContainer">
                <Grid item xs={12} sm={3}>
                  <Card className="card courseCard">
                    <CardContent className="cardContent">
                      <img src={TimerIcon} alt="Timer Icon" />
                      <div className="rightBlock">
                        <h3>8.5 Hours</h3>
                        <p>Training Duration</p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card className="card courseCard">
                    <CardContent className="cardContent">
                      <img src={EnrollIcon} alt="Timer Icon" />
                      <div className="rightBlock">
                        <h3>2500</h3>
                        <p>Enrolled Students</p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card className="card courseCard">
                    <CardContent className="cardContent">
                      <img src={TopicsIcon} alt="Timer Icon" />
                      <div className="rightBlock">
                        <h3>55</h3>
                        <p>Topics Covered</p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className="greenOutlineButton">
                    <CustomButton label="Enroll Now" />
                  </div>
                </Grid>
              </Grid>
            </section>
            {/* course detail tab content */}
            <section className="commonSection">
              <CustomCourseDetailTab />
            </section>
            {/* Accordian content */}
            <section className="commonSection">
              <CustomAccordion />
            </section>
            {/* buy course */}
            <section className="buyCourseSection commonSection">
              <Grid container spacing={0} className="cardContainer">
                <Grid item xs={12} sm={4} className="classesCard">
                  <Card className="card courseCard details">

                    <CardContent className="cardContent">
                      <ul className="nav">
                        <li>
                          <p>Live Online Class</p>
                        </li>
                        <li>
                          <p>Test Series & Analysis</p>
                        </li>
                        <li>
                          <p>Assignment & Notes</p>
                        </li>
                        <li>
                          <p>Doubt Solving During Class</p>
                        </li>
                        <li>
                          <p>Doubt Solving on app</p>
                        </li>
                      </ul>
                    </CardContent>

                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card courseCard basicPrice">
                    <div className="cardHeader">
                      <h5>Basic</h5>
                    </div>
                    <CardContent className="cardContent">
                      <ul className="nav priceNav">
                        <li className="test">
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CancelIcon className="cancelIcon" /></p>
                        </li>
                        <li>
                          <p><CancelIcon className="cancelIcon" /></p>
                        </li>
                      </ul>
                    </CardContent>
                    <div className="cardFooter">
                      <h3>$300</h3>
                      <div className="bottomContent">
                        <span><del>₹500</del></span>
                        <h6>19% off</h6>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} className="advancedCard">
                  <Card className="card courseCard adVancePrice">
                    <div className="cardHeader active">
                      <h5>Advanced</h5>
                      <div className="priceCheckbox circleCheckbox">
                        <CustomCheckboxField />
                      </div>
                    </div>
                    <CardContent className="cardContent">
                      <ul className="nav priceNav">
                        <li className="test">
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                        <li>
                          <p><CheckCircleIcon /></p>
                        </li>
                      </ul>
                    </CardContent>
                    <div className="cardFooter">
                      <h3>$1500</h3>
                      <div className="bottomContent">
                        <span><del>₹3000</del></span>
                        <h6>19% off</h6>
                      </div>
                      <div className="monthContent">
                        <h5>Try for 1 month</h5>
                        <div className="rightBlock">
                          <h6>$ 1500</h6>
                          <span><del>$ 3000</del></span>
                        </div>
                      </div>
                      <div className="greenOutlineGrayButton">
                        <CustomButton label="BUY FULL COURSE" />
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>



            </section>
            {/* trainer content */}
            <section className="trainerSection commonSection">
              <Grid container spacing={5} className="cardContainer">
                <Grid item xs={12} sm={6}>
                  <div className="trainerBlock">
                    <img src={TrainerImg} className="trainerImg" alt="Trainer Img" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="trainerRightBlock">
                    <h1>Meet Your Triner<br></br>John Doe</h1>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Neque odio quia consequatur dolor animi odio quia
                    </p>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Neque odio quia consequatur dolor animi odio quia
                    </p>
                    <ul className="nav">
                      <li>
                        <img src={BulletIcon} className="BulletIcon" alt="" />
                        <p>Lorem ipsum dolor, sit amet consectetur</p>
                      </li>
                      <li>
                        <img src={BulletIcon} className="BulletIcon" alt="" />
                        <p>Lorem ipsum dolor, sit amet consectetur</p>
                      </li>
                      <li>
                        <img src={BulletIcon} className="BulletIcon" alt="" />
                        <p>Lorem ipsum dolor, sit amet consectetur</p>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className="trainerSection commonSection">
              <Grid container spacing={5} alignItems="stretch" className="cardContainer">
                <Grid item xs={12} sm={6}>
                  <div className="trainerBlock">
                    <img src={ServiceImg} className="serviceImg" alt="Trainer Img" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="trainerRightBlock">
                    <h1>We Offer Great<br></br>Service</h1>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Neque odio quia consequatur dolor animi odio quia
                    </p>
                  </div>
                </Grid>
              </Grid>
          </section>
          </div>
          <section className="studentCardSection commonSection">
            <div className="subContentWrapper">
              <div className="studentCardHeader">
                <h3>
                  <span><FormatQuoteIcon /></span>
                  Hear from our students</h3>
              </div>
              <Grid container spacing={2} className="cardContainer">
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar src={ProfileImg1}></Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent className="cardContent">
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          R
                        </Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent >
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          R
                        </Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent >
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          R
                        </Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent >
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          R
                        </Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent >
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="card studentCard">
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          R
                        </Avatar>
                      }
                      title="Jhon Deo"
                      subheader="Student"
                    />
                    <CardContent >
                      <Typography component="p">
                        My son loves the session and i can already see the change.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </section>
        </main>
        {/* ends: education course detail wrapper */}
        <GreenBanner />
        <WebFooter />
      </>
    );
  }
}
//@ts-ignore
export default withRouter(webEducationCourseDetails);