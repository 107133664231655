// Customizable Area Start
import React from "react";
import { connect } from "react-redux";
import CheckoutOrderController from "./CheckoutOrderController.web";
import WebStepperBar from "../../../../../components/src/WebStepperBar.web"
import { Container, Box, Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../../../../../components/src/assets/css/webHeader.scss";
import "../../assets/css/shoppingcartcheckout.scss"
import PickUpWeb from "../checkOutPickup/pickUpWeb.web"
import OrderSummary from "../OrderSummary/OrderSummaryWeb.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web";
import WebFooter from "../../../../../components/src/WebFooter.web";
import PickUpPaymentMethod from "../checkOutPickup/pickupPaymentWeb.web";
import CheckOutPayment from "../CheckoutPayment/CheckoutPayment.web";
import { setCheckoutOrder, getCartItems } from "../../assets"
import PaymentDoneDialog from "../checkoutBitcoin/PaymentDoneDialog.web";
import BreadCrumb from "../../../../../components/src/BreadCrumb.web";
import LoginSignUpDialogWeb from "../../langinpageLoggedIn/LoginSignUpDialog.web";
import { AppRoutings } from "../../../../../components/src/utility/app-routing";
// Customizable Area End
class CheckoutOrder extends CheckoutOrderController {

  // Customizable Area Start
  LoginComponent = () => {
    return (
      <Box className="checkout_login_container">
        <Typography>Please login to continue checkout the order</Typography>
        <Button
          onClick={() => this.openLoginDialog()}
          className="checkout_login_button">
            Login/Sign Up
        </Button>
      </Box>
    )
  }

  getStepComponentWithoutDelivery = (type: string) => {
    const { currentStep, selectedCard, enteredPoint, isLoyaltyPoint } = this.state

    switch (currentStep) {
      case 0:
        return this.LoginComponent()
      case 1:
        return <OrderSummary />
      case 2:
        return <CheckOutPayment
          type={type}
          enteredPoint={enteredPoint}
          isLoyaltyPoint={isLoyaltyPoint}
          toggleIsLoyaltyPoint={this.toggleLoyaltyPointApply}
          selectedCard={selectedCard}
          handleEnteredPointValueChange={this.handlePointValueChange}
          handleCardSelectChange={this.handleCardSelectChange}
        />
    }
  }

  getStepComponent = (type: string) => {
    const { currentStep, selectedCard, enteredPoint, isLoyaltyPoint } = this.state
    switch (currentStep) {
      case 0:
        return this.LoginComponent()
      case 1:
        return <PickUpWeb setShippingLoading={this.setShippingLoading} />
      case 2:
        return <OrderSummary />
      case 3:
        return <CheckOutPayment
          type={type}
          enteredPoint={enteredPoint}
          isLoyaltyPoint={isLoyaltyPoint}
          toggleIsLoyaltyPoint={this.toggleLoyaltyPointApply}
          selectedCard={selectedCard}
          handleEnteredPointValueChange={this.handlePointValueChange}
          handleCardSelectChange={this.handleCardSelectChange}
        />
    }
  }
  // Customizable Area End

  render() {

    // Customizable Area Start
    const {
      type,
      currentStep,
      loginDialog,
      checkoutLoader,
      stepLoader,
      isPaymentDoneDialog,
      transactionId,
      isShippingLoading
    } = this.state
    const isNotDeliverableOrder = this.notDeliverableOrderTypes.includes(type)
    const lastStep = isNotDeliverableOrder ? 2 : 3
    const paymentBtnText = currentStep === lastStep ? "Proceed to Check out" : "Next"
    const isCheckoutDisabled = stepLoader || isShippingLoading

    return (
      <>
        <Container>
          <BreadCrumb
            twoPage={true}
            threePage={false}
            firstPage="Home"
            secondPage="Shopping Cart"
            firstPageLink={AppRoutings.Home}
            secondPageLink={AppRoutings.ShoppingCart}
            thirdPage=""
            thirdPageLink=""
          />

          <Grid container spacing={6}>
            {checkoutLoader ? (
              <Grid item className="checkout_loader_container">
                <CircularProgress size={40} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={12}>
                  <WebStepperBar
                    type={type}
                    activeStep={currentStep}
                    handleStepChange={this.handleStepChange}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box className="checkout_current_step_box">
                    {type === "farm"
                      ? this.getStepComponent(type) 
                      : this.getStepComponentWithoutDelivery(type)
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box className="check_out_delivery_cards_container">
                    <PickUpPaymentMethod isShippingLoading={isShippingLoading} />
                  </Box>
                  <Box mb={5} className="checkout_btn">
                    <Button
                      disabled={isCheckoutDisabled}
                      onClick={() => {
                        this.handleNextChange()
                        document
                          .getElementById("cartFlow")
                          ?.scrollIntoView({ behavior: "smooth" })
                      }}
                    >
                      {stepLoader ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        paymentBtnText
                      )}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <LoginSignUpDialogWeb
            isOpen={loginDialog}
            onClose={this.closeLoginDialog}
            onLoginSuccess={this.handleLoginComplete}
          />
          <PaymentDoneDialog
            isOpen={isPaymentDoneDialog}
            isRestaurantOrder={type === "restaurant"}
            transactionId={transactionId}
            onAccept={this.onPaymentDoneAccept}
          />
        </Container>
        <GreenBanner />
        <WebFooter />
      </>)
      // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {
    orderAddress: state.user.checkoutOrder?.address_id,
    orderAddressType: state.user.checkoutOrder?.address_type,
    amountInfo: state.user.checkoutOrder?.order_details,
    paymentMethod: state.user.checkoutOrder?.payment_method
  }
}
// Customizable Area End

export default withRouter(connect(mapStateToProps, { setCheckoutOrder, getCartItems })(CheckoutOrder));
