// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { IBlock } from "../../../../../framework/src/IBlock"
import { RouteComponentProps } from "react-router-dom"
import { WithStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
import { styles } from "./TransactionHistoryTable.web"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
const configJSON = require("../../config.js")

export interface ITransactionHistory {
  id: string
  type: string
  attributes: {
    id: number
    account_id: number
    transfer_type: string
    transfer_from: string
    points: string
    created_at: string
    updated_at: string
  }
}

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface S {
  isLoading: boolean
  currentPage: number
  totalPages: number
  transactionHistory: ITransactionHistory[]
}

interface SS {
  id: any
}

export default class TransactionHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTransactionHistoryApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]
    this.receive = this.receive.bind(this)

    this.state = {
      isLoading: false,
      currentPage: 1,
      totalPages: 1,
      transactionHistory: [],
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount(): Promise<void> {
    this.getTransactionHistoryApi()
  }

  async componentDidUpdate(
    _: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    if (this.state.currentPage !== prevState.currentPage) {
      this.getTransactionHistoryApi()
    }
  }

  handlePaginationChange = (_: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value })
  }

  getTransactionHistoryApi = async () => {
    this.setState({ isLoading: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const { currentPage } = this.state
    const endPoint = `${
      configJSON.getTransactionHistoryApiEndPoint
    }?page=${currentPage}&per=20`

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getTransactionHistoryApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const isRewardPointsApiResponse =
        apiRequestCallId === this.getTransactionHistoryApiCallId
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (isRewardPointsApiResponse) {
          if ("data" in responseJson) {
            const {
              data,
              meta: {
                pagination: { total_pages },
              },
            } = responseJson
            this.setState({
              transactionHistory: data,
              totalPages: total_pages,
            })
          } else {
            toast.error(configJSON.somethingWentWrongText)
          }
          this.setState({ isLoading: false })
        }
      } else if (isRewardPointsApiResponse) {
        toast.error(configJSON.somethingWentWrongText)
        this.setState({
          isLoading: false,
        })
      }
    }
  }
  // Customizable Area End
}
