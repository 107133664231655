// Customizable Area Start
import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
import {isTokenExpired} from "../../../components/src/utility/helper"

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  classes?: any;
  logout: any
}

interface S {
  loading: boolean;
  cardIndex: number;
  restStepperIndex: number;
  modalOpen: boolean;
  restOrderModal: boolean;
  restOrderReportModal: boolean;
  seletedForum: any[];
  farmsList: any;
  campaignList: any;
  currentFarmId: any;
  search: any;
  openDialog: any;
  removedProduct: any;
  bulkAction: any;
  productMode: any;
  forumDetails: any;
  replyId: any;
  replyEditId: any;
  headingWordCount: any;
  seletedCampaigns: any;
  campaignsPerPage: any,
  campaignsCurrentPage: any,
  total_count: any,
  total_pages: any,
}

interface SS {
  id: any;
}

export default class AdminFarmForumsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  lineChart: any;
  getDropdownApiCallId: string = "";
  getCampaignListApiCallId: string = "";
  updateForumStatusApiCallId: string = "";
  deleteForumApiCallId: string = "";
  bulkActionsForumApiCallId: string = "";
  createForumApiCallId: string = "";
  getForumDetailsApiCallId: string = ""
  deleteReviewApiCallId: string = ""
  addReplyApiCallId: string = "";
  deleteReplyReviewApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      loading: false,
      cardIndex: 0,
      restStepperIndex: 0,
      modalOpen: false,
      restOrderModal: false,
      restOrderReportModal: false,
      seletedForum: [],
      farmsList: "",
      campaignList: [],
      currentFarmId: "",
      search: "",
      openDialog: false,
      removedProduct: "",
      bulkAction: "actions",
      productMode: "",
      forumDetails: "",
      replyId: "",
      replyEditId: "",
      headingWordCount: "",
      seletedCampaigns: [],
      campaignsPerPage: 5,
      campaignsCurrentPage: 1,
      total_count: 0,
      total_pages: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode");
    this.setState({
      productMode: mode,
    });

    if (window.location.pathname.split("/")[3] == "campaigns") {
      this.getCampaignList(this.state.search, this.state.campaignsPerPage, this.state.campaignsCurrentPage);
    }
    if (mode == "edit") {
      this.getForumDetails(query.get("campaign_id"));
    }

  }
 

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.successApiCall(responseJson, apiRequestCallId)


      } if (responseJson?.errors) {
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading: false
          })
          if (responseJson?.errors[0] && ((responseJson?.errors[0].message == configJSON.tokenExpiredText) || (responseJson?.errors[0].message == configJSON.invalidTokenText))) {
            this.props.logout()
          }
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId != null) {


      //get Forums list
      if (apiRequestCallId === this.getCampaignListApiCallId) {
        this.setState({
          campaignList: responseJson.data,
          total_pages: responseJson.meta.pagination.total_pages,
          total_count: responseJson.meta.pagination.total_count,
          loading: false,
          seletedCampaigns: [],
        });
      }
      //update forum status
      if (apiRequestCallId === this.updateForumStatusApiCallId) {
        this.getCampaignList(this.state.search, this.state.campaignsPerPage, this.state.campaignsCurrentPage);
        this.setState({
          loading: false,
        });
        toast.success(`Campaign Status Updated Successfully `);

      }

      //delete forum
      if (apiRequestCallId === this.deleteForumApiCallId) {
        this.getCampaignList(this.state.search, this.state.campaignsPerPage, this.state.campaignsCurrentPage);
        this.setState({
          loading: false,
        });
        toast.success(`Campaign Removed Successfully `);
      }

      this.successApiCall2(responseJson, apiRequestCallId)


    }

  }
  successApiCall2 = (responseJson: any, apiRequestCallId: any) => {
    // forum bulk actions
    if (apiRequestCallId === this.bulkActionsForumApiCallId) {
      this.getCampaignList(this.state.search, this.state.campaignsPerPage, this.state.campaignsCurrentPage);
      this.setState({
        seletedCampaigns: [],
        loading: false,
      });
      toast.success(`Campaigns Status Updated Successfully `);
    }
    // createForumApiCallId
    if (apiRequestCallId === this.createForumApiCallId) {
      const mode = this.state.productMode == "create"
      toast.success(`Campaign Successfully ${mode ? "Added" : "Updated"}`)
      this.setState({
        loading: false,
      });
      this.props.history.goBack()
    }

    // get forum details

    if (apiRequestCallId === this.getForumDetailsApiCallId) {
      this.setState({
        forumDetails: responseJson.data,
        loading: false,
      });

    }

  }

  //get Forums list
  getCampaignList = async (search: any, per: any, page: any) => {
    this.setState({
      loading: true,
    });
    const endpoint = `?campaign_type=Farm&search=${search}&per=${per}&page=${page}`;
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //updating status
  updateForumStatus = async (id: any, status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      campaign: {
        id: id,
        status: status,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateForumStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //remove product
  deleteForum = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `/${this.state.removedProduct}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //bulk actions
  bulkActions = async (status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status: status,
      ids: this.state.seletedCampaigns,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsCampaignsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //create forum
  createForum = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      token: token,
    };
    const mode = this.state.productMode == "create"

    const endPoint = mode ? configJSON.getCampaignListAPiEndPoint : (configJSON.getCampaignListAPiEndPoint + `/${values.forumable_id}`)
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("campaign[heading]", values.heading);
    formdata.append("campaign[tagline]", values.tagline.trim());
    formdata.append("campaign[description]", values.description);
    formdata.append("campaign[status]", values.status);

    if (values.banner?.length > 0) {
      values.banner[0].name && formdata.append("campaign[banner]", values.banner[0],
        values.banner[0].name)
    }
    formdata.append("campaign[campaign_type]", values.campaign_type);
    formdata.append("campaign[required_donation]", values.required_donation);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createForumApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get Forum details
  getForumDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getForumDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignListAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleClickCampaignsRow = (event: React.MouseEvent<unknown>, num: any) => {
    event.preventDefault();
    const selectedIndex = this.state.seletedCampaigns.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.seletedCampaigns, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.seletedCampaigns.slice(1));
    } else if (selectedIndex === this.state.seletedCampaigns.length - 1) {
      newSelected = newSelected.concat(
        this.state.seletedCampaigns.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.seletedCampaigns.slice(0, selectedIndex),
        this.state.seletedCampaigns.slice(selectedIndex + 1)
      );
    }
    this.setState({
      seletedCampaigns: newSelected,
    });
  };

  isSelectedCampaigns = (num: any) =>
    this.state.seletedCampaigns.indexOf(num) !== -1;

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id,
    });
    if (value == "edit") {
      this.props.history.push(
        `/admin/farm/add-campaigns?mode=edit&campaign_id=${item.id}`
      );
    }
    if (value == "remove") {
      this.handleDialog();
    }
  }

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  }

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return async function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await call(...args)



      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getCampaignList,
    1000)

  handleDonationValueChange = (value: any, setFieldValue: (field: string, value: number) => void) => {
    const isNumber = !isNaN(value)
    if (isNumber) setFieldValue("required_donation", value)
  }

  settingBannerImage = (values: any) => {
    return (
      values.banner &&
        values.banner.length > 0 &&
        values.banner[0].name ? (
        <img
          src={(
            window.URL ||
            // (window as any)
            window.webkitURL
          ).createObjectURL(
            //@ts-ignore
            values.banner[0]
          )}
          alt="banner"
        />
      ) : (
        <img src={values.banner.url} alt="banner" />
      )
    )
  }
  bannerUpload = (e: any, setFieldValue: any) => {
    if (e.length > 0) {
      setFieldValue("banner", e);
    }

  }
  settingProductText = (productMode: any) => {
    return productMode == "create" ? "Create Campaign" : "Save Changes"
  }
  textAreaChange = (e: any, setFieldValue: any,type:any) => {
    let words = e.target.value?.trim()?.split(" ");
    if(type === "tagline" && words.length < 7){
      setFieldValue(type, e.target.value);
    }
    if(type === "heading" && words.length < 26) {
      setFieldValue(type, e.target.value);
    }
  }
  handleEndDecorator = (values: any) => {
    return values ? values?.trim()?.split(" ").length : "0"
  }
  handleCampaignDonationValue = (campaignDetails: any) => {
    return ((campaignDetails && campaignDetails.donation_raised) ||
      "0")
  }
  gettingImage = (image: any, WomanPicture: any) => {
    return image?.url || WomanPicture
  }
  gettingInitialValue = (campaignDetails: any, forumDetails: any) => {
    return {
      heading: (campaignDetails?.heading) || "",
      tagline: (campaignDetails && campaignDetails.tagline) || "",
      description: (campaignDetails && campaignDetails.description) || "",
      status: (campaignDetails && campaignDetails.status) || "active",
      banner: (campaignDetails && campaignDetails.banner) || "",
      campaign_type: "Farm",
      forumable_id: (forumDetails && forumDetails.id) || "",
      required_donation:
        (campaignDetails && campaignDetails.required_donation) || 0,
    }
  }
  
  handleDragOver=(e:any)=>{
    e.preventDefault();
    e.stopPropagation();
  }
  handleDragDrop=(e:any,setFieldValue:any)=>{
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFieldValue("banner", e.dataTransfer.files);
    }
     }
     getMenuStyleCss=(status:any)=>{
      if(status=="active"){
        return ""
      }else if(status=="deactive"){
        return "_menus de_active_menu"
      }else{
        return "draft_menu"
      }
      }
}
// Customizable Area End
