import * as React from "react";
// custom components
import {
  TextareaAutosize,
} from "@material-ui/core";

export default function CustomTextAreafield(props: any) {
  const { placeholder, ref, rows } = props;
  return (
    <div className="customTextAreafield">
      <label className="customLabel" htmlFor="input-with-icon-textfield">{props.label}</label>
      <TextareaAutosize
        id="input-with-icon-textfield"
        placeholder={placeholder}
        ref={ref}
        minRows={rows}
        {...props}
      />
    </div>
  );
}
