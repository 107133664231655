// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../../framework/src/Message"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { IBlock } from "../../../../../framework/src/IBlock"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./TransferRewardPointsDialog.web"
import { FormikErrors } from "formik"
import { toast } from "react-toastify"
const configJSON = require("../../config.js")

interface IValues {
  transferAmount: string
  recipientEmail: string
}

export interface IMessageError {
  message: string
}

export interface Props extends WithStyles<typeof styles> {
  isOpen: boolean
  availableBalance: number
  handleClose: () => void
  getRewardPointsApi: () => void
}

interface S {
  apiErrors: IMessageError[] | string[]
  isTransferring: boolean
}

interface SS {
  id: any
}

export default class TransferRewardPointsDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  transferPointsApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ]
    this.receive = this.receive.bind(this)

    this.state = {
      apiErrors: [],
      isTransferring: false,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  handlePointsValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (
      key: string,
      value: string
    ) => Promise<void | FormikErrors<{
      transferAmount: string
      recipientEmail: string
    }>>
  ) => {
    const { value } = e.target
    const regex = /^\d*\.?\d{0,2}$/
    if (!regex.test(e.target.value)) {
      return
    }
    setFieldValue("transferAmount", value)
  }

  handleDecreasePoint = (
    currentValue: string,
    setFieldValue: (
      key: string,
      value: string
    ) => Promise<void | FormikErrors<{
      transferAmount: string
      recipientEmail: string
    }>>
  ) => {
    const newValue = Math.max(0, Number(currentValue) - 5)
    setFieldValue("transferAmount", String(newValue))
  }

  handleIncreasePoint = (
    currentValue: string,
    setFieldValue: (
      key: string,
      value: string
    ) => Promise<void | FormikErrors<{
      transferAmount: string
      recipientEmail: string
    }>>
  ) => {
    const { availableBalance } = this.props
    const newValue = Math.min(availableBalance, Number(currentValue) + 5)
    setFieldValue("transferAmount", String(newValue))
  }

  handleTransferSubmit = (values: IValues) => {
    const { transferAmount, recipientEmail } = values
    const query = `?email=${recipientEmail}&points=${transferAmount}`
    this.transferPointsApi(query)
  }

  transferPointsApi = async (query: string) => {
    this.setState({ isTransferring: true, apiErrors: [] })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.transferPointsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.transferPointsApiEndPoint}${query}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const isTransferPointsApiResponse =
        apiRequestCallId === this.transferPointsApiCallId
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (isTransferPointsApiResponse) {
          const { getRewardPointsApi, handleClose } = this.props
          this.setState({
            isTransferring: false,
          })
          getRewardPointsApi()
          handleClose()
          toast.success(responseJson.message)
        }
      } else if (isTransferPointsApiResponse) {
        const errorJson =
          "errors" in responseJson
            ? responseJson.errors
            : [configJSON.somethingWentWrongText]
        this.setState({ apiErrors: errorJson, isTransferring: false })
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
