// Customizable Area Start
import React from "react"
import {
  Box,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/CloseRounded"
import ReplyAllIcon from "@material-ui/icons/ReplyAll"
import { RedDustbin } from "../assets"

interface ICommentActionsProps {
  isLoggedInUserComment: boolean
  isAddingReview: boolean
  commentText: string
  reviewText: string
  addReviewLoader: boolean
  editCommentLoader: boolean
  deleteCommentLoader: boolean
  isEditingComment: boolean
  isContentFlag: boolean
  contentFlagLoader: boolean
  toggleAddReview: () => void
  handleEditComment: () => void
  handleAddReply: () => void
  openConfirmationDialog: () => void
  toggleEditComment: () => void
  handleContentFlag: () => void
  handleReviewChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CommentActions = ({
  isLoggedInUserComment,
  isAddingReview,
  commentText,
  reviewText,
  addReviewLoader,
  deleteCommentLoader,
  contentFlagLoader,
  isContentFlag,
  isEditingComment,
  editCommentLoader,
  toggleAddReview,
  handleEditComment,
  handleAddReply,
  openConfirmationDialog,
  toggleEditComment,
  handleContentFlag,
  handleReviewChange,
}: ICommentActionsProps) => {
  return isAddingReview ? (
    <Box className="comment_reply_container">
      <Box width="100%">
        <TextField
          className="reply_edit_textfield"
          placeholder="Write your reply here..."
          value={reviewText}
          disabled={addReviewLoader}
          onChange={handleReviewChange}
          InputProps={{ disableUnderline: true }}
        />
      </Box>
      <Box>
        <Box className="review_action_container">
          <IconButton
            className="add_review_cancel"
            disabled={addReviewLoader}
            onClick={toggleAddReview}
          >
            <CloseIcon />
          </IconButton>
          <Button
            classes={{
              root: "reply_save_btn",
            }}
            disabled={!reviewText || addReviewLoader}
            onClick={handleAddReply}
          >
            {addReviewLoader ? (
              <CircularProgress
                className="add_reply_loader"
                color="inherit"
                size={20}
              />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className="comment_actions_container">
      {isEditingComment ? (
        <Box className="review_action_container" mt={2}>
          <IconButton
            className="add_review_cancel"
            disabled={editCommentLoader}
            onClick={toggleEditComment}
          >
            <CloseIcon />
          </IconButton>
          <Button
            classes={{
              root: "reply_save_btn",
            }}
            disabled={!commentText || editCommentLoader}
            onClick={handleEditComment}
          >
            {editCommentLoader ? (
              <CircularProgress
                className="add_reply_loader"
                color="inherit"
                size={20}
              />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      ) : (
        <>
          {isLoggedInUserComment && (
            <>
              <Button
                className="delete_reply"
                onClick={openConfirmationDialog}
                disabled={deleteCommentLoader}
              >
                <img src={RedDustbin} alt="delete_icon" />
              </Button>
              <Button
                className="edit_reply"
                onClick={toggleEditComment}
                disabled={deleteCommentLoader}
              >
                Edit
              </Button>
            </>
          )}
          <Button
            className="review_reply_btn"
            endIcon={<ReplyAllIcon />}
            onClick={toggleAddReview}
            disabled={deleteCommentLoader}
          >
            Reply
          </Button>
          {!isLoggedInUserComment &&
          <Button
            className="report_btn"
            disabled={isContentFlag || contentFlagLoader}
            onClick={handleContentFlag}
          >
            {contentFlagLoader ? (
              <CircularProgress
                className="add_reply_loader"
                color="inherit"
                size={18}
              />
            ) : (
              "Flag content"
            )}
          </Button> 
          }
        </>
      )}
    </Box>
  )
}

export default CommentActions
// Customizable Area End
