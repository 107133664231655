// Customizable Area Start
import React, { Component } from "react"
import {
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FieldArray, ErrorMessage } from "formik"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
import clsx from "clsx"
import { DustbinIcon } from "./assets"
import CustomActionDialog from "../../../components/src/CustomDialog.web"
const configJSON = require("./config.js")

interface IWeightDimension {
  id: string
  start_weight: string
  end_weight: string
  weight_unit: string
  length: string
  width: string
  height: string
}

interface IVariantOption {
  optionId: string
  optionHeading: string
  optionPrice: string
  optionWeight: string
}
interface IVariant {
  variantId: string
  variantHeading: string
  variantOptions: IVariantOption[]
}

const DEFAULT_VARIANT_OPTION = {
  optionId: "",
  optionHeading: "",
  optionPrice: "",
  optionWeight: "",
}

export const styles = () =>
  createStyles({
    mainWrapper: {
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
    },
    accordionHeadingWrapper: {
      flexGrow: 1,
      overflowX: "hidden",
    },
    accordion: {
      boxShadow: "none",
      borderRadius: "8px",
      flexGrow: 1,
    },
    accordionSummary: {
      paddingLeft: "5px",
      borderRadius: "8px",
      border: "1px solid rgba(193, 193, 193, 0.35)",
      "&.Mui-focused": {
        backgroundColor: "#fff",
        border: "1px solid rgba(193, 193, 193, 0.50)",
      },
      "&.Mui-isExpanded": {
        minHeight: "0px",
      },
      "&.Mui-expanded": {
        minHeight: "auto",
      },
      "& .MuiAccordionSummary-content": {
        margin: "0px !important",
      },
      "& .MuiAccordionSummary-expandIcon": {
        "& svg": {
          color: "#333",
        },
      },
    },
    accordionDetails: {
      padding: "20px 0px 10px",
      margin: "0px",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#d5d5d5",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        backgroundColor: "rgba(0,0,0,0.04)",
        borderRadius: "8px",
      },
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "100%",
      minWidth: "570px",
    },
    variantOptionsWrapper: {
      display: "flex",
      alignItems: "flex-start",
      gap: "10px",
      "& > div": {
        flex: 1,
      },
    },
    variantOptionHeading: {
      fontSize: "16px",
      fontWeight: 600,
      paddingBottom: "10px",
    },
    customTextField: {
      borderRadius: "8px",
      border: "1px solid rgba(193, 193, 193, 0.368627451)",
    },
    removeBtn: {
      borderRadius: "50%",
      backgroundColor: "#fff",
      padding: "12px",
      border: "none",
      marginTop: "4px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "& > img": {
        height: "25px",
      },
    },
    firstOptionRemoveBtn: {
      marginTop: "32px"
    }
  })

interface Props extends WithStyles<typeof styles> {
  formikErrors: any
  variants: IVariant[]
  isRemovingVariant: boolean
  weightDimensions: IWeightDimension[]
  isVariantConfirmationDialog: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  handleRemoveVariant: (variantId: string) => void
  handleRemoveVariantDialog: (isOpen: boolean) => void
}

interface S {
  isExpanded: boolean
}

export class AdminProductVariantAccordion extends Component<Props, S> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isExpanded: true,
    }
  }

  handleConfirmationDialogClose = () => {
    this.props.handleRemoveVariantDialog(false)
  }

  handleConfirmationDialogOpen = () => {
    this.props.handleRemoveVariantDialog(true)
  }

  handleRemoveAllVariants = () => {
    const { variants, handleRemoveVariant } = this.props
    handleRemoveVariant(variants[0].variantId)
  }

  handleVariantExpansion = () => {
    this.setState((prevState: Readonly<S>) => ({
      isExpanded: !prevState.isExpanded,
    }))
  }

  handleAddVariantOption = (push: (value: unknown) => void) => (
    _: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { variants } = this.props

    if (variants.length > 0) {
      let emptyValue = false
      variants[0].variantOptions.forEach((option) => {
        const { optionHeading, optionPrice, optionWeight } = option

        const isAnyFieldEmpty =
          (!optionHeading || !optionPrice || !optionWeight) && !emptyValue

        if (isAnyFieldEmpty) {
          emptyValue = true
        }
      })

      emptyValue
        ? toast.error("Please fill in the all details in the option")
        : push(DEFAULT_VARIANT_OPTION)
    }
  }

  handleRemoveVariantOption = (
    index: number,
    remove: (value: number) => void
  ) => (_: React.MouseEvent<HTMLButtonElement>) => {
    remove(index)
  }

  getHeading = (isFirstElement: boolean, heading: string) => {
    const { classes } = this.props
    return (
      isFirstElement && (
        <h6 className={classes.variantOptionHeading}>{heading}</h6>
      )
    )
  }

  render() {
    const {
      classes,
      variants,
      weightDimensions,
      formikErrors,
      isRemovingVariant,
      isVariantConfirmationDialog,
      handleChange,
    } = this.props
    const { isExpanded } = this.state

    const { variantHeading, variantOptions } = variants[0]
    const variantHeadingName = "variants[0].variantHeading"
    const isVariantPending = !!formikErrors.variants && !isExpanded
    const optionsError =
      formikErrors.variants &&
      typeof formikErrors.variants[0].variantOptions === "string"
        ? formikErrors.variants[0].variantOptions
        : ""
    const weightUnit =
      weightDimensions.length > 0 ? `(${weightDimensions[0].weight_unit})` : ""

    return (
      <>
        <div className={classes.mainWrapper}>
          <div className={classes.accordionHeadingWrapper}>
            <Accordion
              className={classes.accordion}
              expanded={isExpanded}
              onChange={this.handleVariantExpansion}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <TextField
                  variant="outlined"
                  name={variantHeadingName}
                  placeholder="Add variants like size, color, etc to the product"
                  value={variantHeading}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleChange}
                />
              </AccordionSummary>
              {isExpanded && (
                <span className="errorMsg">
                  <ErrorMessage name={variantHeadingName} />
                </span>
              )}
              <AccordionDetails className={classes.accordionDetails}>
                <div className={classes.optionsContainer}>
                  <FieldArray name="variants[0].variantOptions">
                    {({ push, remove }) => (
                      <>
                        {variantOptions.map(
                          (option: IVariantOption, optionIndex: number) => {
                            const {
                              optionHeading,
                              optionPrice,
                              optionWeight,
                            } = option

                            const isFirstElement = optionIndex === 0
                            const optionHeadingName = `variants[0].variantOptions[${optionIndex}].optionHeading`
                            const optionPriceName = `variants[0].variantOptions[${optionIndex}].optionPrice`
                            const optionWeightName = `variants[0].variantOptions[${optionIndex}].optionWeight`

                            return (
                              <div key={optionHeadingName}>
                                <div className={classes.variantOptionsWrapper}>
                                  <Box>
                                    {this.getHeading(
                                      isFirstElement,
                                      "Option Name"
                                    )}
                                    <TextField
                                      variant="outlined"
                                      name={optionHeadingName}
                                      className={classes.customTextField}
                                      placeholder="enter option name - i.e. s, m, l or 1/2 dzn, 1 dzn"
                                      value={optionHeading}
                                      onChange={handleChange}
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name={optionHeadingName} />
                                    </span>
                                  </Box>
                                  <Box>
                                    {this.getHeading(
                                      isFirstElement,
                                      `Option Value ($)`
                                    )}
                                    <TextField
                                      variant="outlined"
                                      className={classes.customTextField}
                                      name={optionPriceName}
                                      placeholder="enter option price"
                                      value={optionPrice}
                                      onChange={handleChange}
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name={optionPriceName} />
                                    </span>
                                  </Box>
                                  <Box>
                                    {this.getHeading(
                                      isFirstElement,
                                      `Option Weight ${weightUnit || ""}`
                                    )}
                                    <TextField
                                      variant="outlined"
                                      className={classes.customTextField}
                                      name={optionWeightName}
                                      placeholder="enter option weight"
                                      value={optionWeight}
                                      onChange={handleChange}
                                    />
                                    <span className="errorMsg">
                                      <ErrorMessage name={optionWeightName} />
                                    </span>
                                  </Box>
                                  <button
                                    data-testid="remove-variant-option"
                                    type="button"
                                    className={clsx(classes.removeBtn, {
                                      [classes.firstOptionRemoveBtn]: isFirstElement
                                    })}
                                    onClick={this.handleRemoveVariantOption(
                                      optionIndex,
                                      remove
                                    )}
                                  >
                                    <img src={DustbinIcon} alt="remove" />
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        )}
                        <div>
                          <span className="errorMsg">{optionsError}</span>
                        </div>
                        <div className="add-variant-wrapper">
                          <button
                            data-test-id="add-variant-button"
                            type="button"
                            onClick={this.handleAddVariantOption(push)}
                          >
                            Add Variants
                          </button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </AccordionDetails>
            </Accordion>
            {isVariantPending && (
              <div>
                <span className="errorMsg">
                  Please fill in all fields for this variant
                </span>
              </div>
            )}
          </div>
          <button
            type="button"
            data-testid="remove-variant"
            className={classes.removeBtn}
            onClick={this.handleConfirmationDialogOpen}
          >
            <img src={DustbinIcon} alt="remove" />
          </button>
        </div>
        <CustomActionDialog
          open={isVariantConfirmationDialog}
          isLoading={isRemovingVariant}
          isActionDisabled={isRemovingVariant}
          isCloseDisabled={isRemovingVariant}
          handleClose={this.handleConfirmationDialogClose}
          handleAction={this.handleRemoveAllVariants}
          title={configJSON.removeVariantsMessage}
          actionBtnTitle={configJSON.yesText}
        />
      </>
    )
  }
}

export default withStyles(styles)(AdminProductVariantAccordion)
// Customizable Area End
