// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Link,
  FilledInput,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Dialog,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import React from "react";
import TopBar from "../../../components/src/TopBar.web";
import WhiteBackgroundContainer from "../../../components/src/WhiteBackgroundContainer.web";
import "../assets/css/createroleprofile.scss";
import "../../categoriessubcategories/assets/css/accordion.scss"
import "../../catalogue/assets/css/farmDetails.scss"
import {
  sentOTP1Action,
  verifyOTP1Action,
  setSameContactDetails,
  resatOTPFlags,
  changeOTP1Verify,
  verifyUserAction
} from "./assets";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import CreateFarmerProfileWebController, {
  Props,
  IAddressValues
} from "./createFarmerProfileWebController.web";
import FarmersDonePage from "./FarmersDonePage.web";
import { Formik, Form, ErrorMessage } from "formik";
import CustomGoogleMap from "../../../components/src/CustomGoogleMap.web";
import CustomGooglePlaceAutoComplete from "../../../components/src/CustomGooglePlaceAutoComplete.web";
import Alert from "@material-ui/lab/Alert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web";
import CustomLocationErrorMessage from "../../../components/src/CustomLocationErrorMessage.web";
import CustomOTPInput from "../../../components/src/CustomOTPInput.web";
import * as Yup from "yup";
import CustomButton from "../../../components/src/CustomButton.web";
import RewardAcceptanceSelect from "../../catalogue/src/RewardAcceptanceSelect/RewardAcceptanceSelect.web";

const createProfileSchema = Yup.object().shape({
  org_name: Yup.string().required("This field is required"),
  org_address: Yup.string().required("This field is required"),
  org_owner_name: Yup.string().required("This field is required"),
  org_owner_address: Yup.string()
    .email("Invalid email address format")
    .required("This field is required"),
  org_contact_number: Yup.string()
    .required("This field is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number"),
  org_owner_contact_number: Yup.string()
    .required("This field is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number"),
  latitude: Yup.string().required("This field is required"),
  longitude: Yup.string().required("This field is required"),
  pickUpAddresses: Yup.array()
    .required("Pick up address is required")
    .min(1)
});

const addressSchema = Yup.object().shape({
  addressLine1: Yup.string().required("Address Line1 is required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string()
    .required("Zip code is required")
    .length(5, "Please enter a valid zip code")
    .matches(/^\d{5}(?:-\d{4})?$/, "Please enter valid zip code"),
  number: Yup.string()
    .matches(/^\d{10}$/, "Invalid phone number"),
  email: Yup.string()
    .email("Invalid email format")
})
// Customizable Area End

export class CreateRoleProfileLayout extends CreateFarmerProfileWebController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    const {
      isChecked,
      mapCoOrdinates,
      orgContactNumberError,
      orgOwnerContactNumberError,
      isLoading,
      openModal,
      createError,
      emailOTP,
      isAddressFormExpanded,
      isEditingAddress
    } = this.state;

    const { userInfo } = this.props;
    const addressButtonText = isEditingAddress ? "Save" : "Add new"

    return (
      <>
        <TopBar />
        <WhiteBackgroundContainer>
          <Box className="create_role_main_wrapper">
            <Button
              classes={{
                root: "create_profile_nav_btn create_profile_back",
                disabled: "select_role_move_disabled",
              }}
              onClick={this.handleBack}
            >
              <ArrowBackIcon />
            </Button>
            <Container className="create_profile_container">
              <Box className="create_role_profile_form_container">
                <Formik
                  innerRef={this.formikRef}
                  enableReinitialize
                  initialValues={{
                    org_name: "",
                    org_address: "",
                    org_owner_name: "",
                    org_owner_address: "",
                    org_contact_number: "",
                    org_owner_contact_number: "",
                    latitude: "",
                    longitude: "",
                    pickUpAddresses: [],
                    purchase_limit_percentage: 0
                  }}
                  validationSchema={createProfileSchema}
                  onSubmit={(values) => this.doCreateProfile(values)}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                  }) => {
                    return (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Create Farmer Profile</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <h6 className="create_profile_label">Farm Name</h6>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Enter Farm Name"
                                inputProps={{ maxLength: 100 }}
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="org_name"
                                onChange={handleChange}
                              />
                              <FormikErrorMessage
                                error={errors.org_name}
                                isTouched={touched.org_name}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <h6 className="create_profile_label">
                              Farm Complete Address
                            </h6>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Enter Farm Address"
                                inputProps={{ maxLength: 200 }}
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="org_address"
                                onChange={handleChange}
                              />
                              <FormikErrorMessage
                                error={errors.org_address}
                                isTouched={touched.org_address}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <h6 className="create_profile_label">
                              Please place the pin accurately at your outlet's location
                              on the map
                            </h6>
                            <p className="create_profile_label_text">
                              This will help your customers to locate your store
                            </p>

                            <CustomLocationErrorMessage
                              isLatError={errors.latitude}
                              isLatTouched={touched.latitude}
                              isLngError={errors.longitude}
                              isLngTouched={touched.longitude}
                            />

                            <CustomGooglePlaceAutoComplete
                              placeholder="Enter your farm locality"
                              lat={Number(mapCoOrdinates.lat)}
                              lng={Number(mapCoOrdinates.lng)}
                              detectPlace={this.detectPlace}
                              setFieldValue={setFieldValue}
                              handlePlaceSelect={this.handlePlacesAutocompleteSelect}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomGoogleMap
                            lat={mapCoOrdinates.lat}
                            lng={mapCoOrdinates.lng}
                            handleDragEnd={(lat, lng) =>
                              this.handleChangeLatLng(lat, lng)
                            }
                          />
                        </Grid>
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={1} sm={3} md={4}>
                            <Box className="create_role_profile_hr_line">
                              <hr />
                            </Box>
                          </Grid>
                          <Grid item xs={10} sm={8} md={4}>
                            <Box className="create_profile_location_message">
                              <Typography className="create_role_profile_desc">
                                or directly enter the co-ordinates
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1} sm={3} md={4}>
                            <Box className="create_role_profile_hr_line">
                              <hr />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Latitude"
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                value={values.latitude}
                                name="latitude"
                                onChange={this.handleChangeLat}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Longitude"
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="longitude"
                                value={values.longitude}
                                onChange={this.handleChangeLng}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <h6 className="create_profile_label">
                              Contact number at Farm
                            </h6>
                          </Grid>
                          <Grid item container xs={12} sm={10} md={7} lg={6}>
                            <Grid item>
                              <p className="create_profile_label_text">
                                Your customers will call on this number for general
                                enquiries
                              </p>
                              <Grid item container xs={12} spacing={3}>
                                <Grid item xs={12} sm={9}>
                                  <Box className="create_role_profile_mobile_number_input_wrapper">
                                    <Box>
                                      <p>+1</p>
                                    </Box>
                                    <Box>
                                      <FilledInput
                                        placeholder="Mobile Number at Farm"
                                        classes={{
                                          root: "create_role_profile_detect_location_input_root",
                                          input: "create_role_profile_input",
                                        }}
                                        name="org_contact_number"
                                        inputProps={{ maxLength: 10 }}
                                        disableUnderline
                                        value={values.org_contact_number}
                                        onChange={handleChange}
                                        onFocus={this.changeMobileNumber}
                                        onBlur={this.handleBlurOrgNumber}
                                      />
                                    </Box>
                                  </Box>
                                  <FormikErrorMessage
                                    extraError={orgContactNumberError}
                                    error={errors.org_contact_number}
                                    isTouched={touched.org_contact_number}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            xs={12}
                            sm={6}
                            md={5}
                            lg={6}
                            alignItems="center"
                          >
                            <Grid item xs={12} />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <h4 className="create_profile_owner_heading">
                            Register Owner details
                          </h4>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12}>
                            <h6 className="create_profile_label">Farm owner details</h6>
                          </Grid>
                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} sm={7} md={5}>
                              <Box className="web_input_field">
                                <FilledInput
                                  placeholder="Owner Name"
                                  inputProps={{ maxLength: 100 }}
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  name="org_owner_name"
                                  onChange={handleChange}
                                />
                                <FormikErrorMessage
                                  error={errors.org_owner_name}
                                  isTouched={touched.org_owner_name}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                              <Box className="create_role_profile_mobile_number_input_wrapper">
                                <Box>
                                  <p>+1</p>
                                </Box>
                                <Box>
                                  <FilledInput
                                    value={values.org_owner_contact_number}
                                    placeholder="Owner's Mobile Number"
                                    classes={{
                                      root: "create_role_profile_detect_location_input_root",
                                      input: "create_role_profile_input",
                                    }}
                                    name="org_owner_contact_number"
                                    inputProps={{ maxLength: 10 }}
                                    disableUnderline
                                    onChange={handleChange}
                                    onFocus={this.changeMobileNumber1}
                                    disabled={userInfo.isSameContactDetails}
                                  />
                                </Box>
                              </Box>
                              <FormikErrorMessage
                                extraError={orgOwnerContactNumberError}
                                error={errors.org_owner_contact_number}
                                isTouched={touched.org_owner_contact_number}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                              <RadioGroup aria-label="quiz" name="quiz">
                                <FormControlLabel
                                  checked={isChecked}
                                  value={values.org_contact_number}
                                  control={
                                    <Radio
                                      classes={{ checked: "radio_checked" }}
                                      onClick={this.sameAsPrevious}
                                    />
                                  }
                                  label="Same as Farm"
                                  classes={{
                                    root: "radio",
                                    label: "radio_label create_profile_radio",
                                  }}
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <form onSubmit={this.doVerifyOwnerEmail}>
                                <Grid item container xs={12} spacing={3}>
                                  <Grid item xs={12} sm={9} md={5}>
                                    <Box className="web_input_field create_role_profile_email">
                                      <FilledInput
                                        placeholder="Owner Email Address"
                                        inputProps={{ maxLength: 100 }}
                                        classes={{
                                          root: "create_role_profile_input_root",
                                          input: "create_role_profile_input",
                                        }}
                                        name="org_owner_address"
                                        onChange={this.handleEmailValueChange}
                                      />
                                      <FormikErrorMessage
                                        error={errors.org_owner_address}
                                        isTouched={touched.org_owner_address}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={2}>
                                    <Button
                                      type="submit"
                                      className="create_role_btn_farm"
                                      classes={{
                                        root: !userInfo.verifiedOTP1
                                          ? "create_role_profile_btn"
                                          : "create_role_profile_btn_disabled",
                                      }}
                                      disabled={
                                        userInfo.verifiedOTP1 ||
                                        !values.org_owner_address
                                      }
                                    >
                                      {userInfo.loadingOTP1 ? (
                                        <CircularProgress size={22} color="inherit" />
                                      ) : (
                                        "Verify"
                                      )}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    className="create_role_otp_container"
                                  >
                                    <Box>
                                      {!userInfo.verifiedOTP1 ? (
                                        <>
                                          <p className="OTP_header">Enter Otp</p>
                                          <Box className="create_role_profile_otp_input_container">
                                            <CustomOTPInput
                                              value={emailOTP}
                                              isDisabled={
                                                !userInfo.isOTP1Sent ||
                                                !values.org_owner_address ||
                                                userInfo.loadingOTPVerification1
                                              }
                                              onChange={this.handleEmailOTPChange}
                                            />
                                            {userInfo.loadingOTPVerification1 && (
                                              <Box className="otp_loader_container">
                                                <CircularProgress size={25} />
                                              </Box>
                                            )}
                                          </Box>
                                        </>
                                      ) : (
                                        <Box className="web_input_field">
                                          <p className="OTP_Success_Msg1">
                                            <CheckCircleOutlineOutlined /> Verified
                                          </p>
                                        </Box>
                                      )}
                                      {userInfo.verificationErrorOTP1 && (
                                        <p className="OTP_Error">Invalid OTP</p>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </form>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              <h6 className="create_profile_owner_heading">Delivery Details</h6>
                              <hr />
                              <p className="create_profile_label_text">
                                You can add multiple pickup address so user can select most nearest pickup
                                point.
                              </p>
                              <div className="deliveryDetailBlocks">
                                {values.pickUpAddresses.map((item: IAddressValues) => {
                                  const {
                                    id,
                                    addressLine1,
                                    city,
                                  } = item

                                  return (
                                    <div className="deliveryDetailRow" key={id}>
                                      <div className="deliveryBlocks deliveryBlock1">
                                        <Typography variant="h6">{city} Farmers Market</Typography>
                                      </div>
                                      <div className="deliveryBlocks deliveryBlock2">
                                        <Typography variant="h6">{addressLine1}</Typography>
                                      </div>
                                      <button
                                        data-test-id="delete-address"
                                        className="deliveryBlocks deliveryBlock3"
                                        onClick={() => this.handleAddressRemove(id)}
                                      >
                                        <Link href="#">Delete</Link>
                                      </button>
                                      <button
                                        data-test-id="edit-address"
                                        className="deliveryBlocks deliveryBlock4"
                                        onClick={() => this.handleAddressEdit(item)}
                                      >
                                        <Link href="#">Edit</Link>
                                      </button>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="commonAccordian">
                              <Formik
                                innerRef={this.addressFormRef}
                                initialValues={{
                                  id: "",
                                  addressLine1: "",
                                  addressLine2: "",
                                  city: "",
                                  state: "",
                                  zipCode: "",
                                  number: "",
                                  email: "",
                                }}
                                validationSchema={addressSchema}
                                onSubmit={(values, { resetForm }) =>
                                  this.handleNewAddressSubmit(values, resetForm)
                                }
                              >
                                {({ values, touched, errors, handleChange }) => {
                                  return (
                                    <Form noValidate autoComplete="off">
                                      <Accordion
                                        expanded={isAddressFormExpanded}
                                        onChange={this.handleExpandOnChange}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <h6>Add New Pickup Address</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box width="100%" paddingBottom={2}>
                                          <h6 className="create_profile_label">Pick up Address</h6>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="addressLine1"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.addressLine1}
                                                  placeholder="Address Line 1"
                                                  onChange={handleChange}
                                                  error={this.isError(
                                                    errors.addressLine1,
                                                    touched.addressLine1
                                                  )}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.addressLine1}
                                                  isTouched={touched.addressLine1}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="addressLine2"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.addressLine2}
                                                  placeholder="Address Line 2"
                                                  onChange={handleChange}
                                                  error={this.isError(
                                                    errors.addressLine2,
                                                    touched.addressLine2
                                                  )}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.addressLine2}
                                                  isTouched={touched.addressLine2}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="city"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.city}
                                                  placeholder="City"
                                                  onChange={handleChange}
                                                  error={this.isError(errors.city, touched.city)}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.city}
                                                  isTouched={touched.city}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="state"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.state}
                                                  placeholder="State"
                                                  onChange={handleChange}
                                                  error={this.isError(errors.state, touched.state)}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.state}
                                                  isTouched={touched.state}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="zipCode"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.zipCode}
                                                  placeholder="Zip code"
                                                  onChange={handleChange}
                                                  error={this.isError(errors.zipCode, touched.zipCode)}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.zipCode}
                                                  isTouched={touched.zipCode}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={3} className="contactRow">
                                            <Grid item xs={12} sm={6}>
                                            <h6 className="create_profile_label">Contact No.</h6>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="number"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.number}
                                                  placeholder="Contact number"
                                                  onChange={this.handleAddressNumberChange}
                                                  error={this.isError(errors.number, touched.number)}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.number}
                                                  isTouched={touched.number}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <h6 className="create_profile_label">E-mail Address</h6>
                                              <Box className="web_input_field">
                                                <FilledInput
                                                  name="email"
                                                  classes={{
                                                    root: "create_role_profile_input_root",
                                                    input: "create_role_profile_input",
                                                  }}
                                                  value={values.email}
                                                  placeholder="Email address"
                                                  onChange={handleChange}
                                                  error={this.isError(errors.email, touched.email)}
                                                />
                                                <FormikErrorMessage
                                                  error={errors.email}
                                                  isTouched={touched.email}
                                                />
                                              </Box>
                                            </Grid>
                                              <Grid item xs={12} sm={6}>
                                                <Box className="address_button_container">
                                                  <span className="greenContained">
                                                    <CustomButton data-test-id="save-address" color="secondary" label={addressButtonText} type="submit" />
                                                  </span>
                                                  <span className="grayButton">
                                                    {isEditingAddress && (
                                                      <CustomButton data-test-id="cancel-address"  color="secondary" label="Cancel" onClick={this.cancelEditAddress} />
                                                    )}
                                                  </span>
                                                </Box>
                                              </Grid>
                                          </Grid>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Form>
                                  )
                                }}
                              </Formik>
                              <span className="errorMsg">
                                <ErrorMessage name="pickUpAddresses" />
                              </span>
                            </div>

                            <Box mt={2}>

                              <hr />
                              <RewardAcceptanceSelect
                                name="purchase_limit_percentage"
                                value={values.purchase_limit_percentage}
                                handleValueChange={(value: number) =>
                                  setFieldValue("purchase_limit_percentage", value)
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {createError?.length > 0 &&
                          createError.map((err: { message: string }) => {
                            return (
                              <Grid item xs={12} key={err.message}>
                                <Alert color="error">{err.message}</Alert>
                              </Grid>
                            )
                          })}
                        <Grid item xs={12} sm={4} md={2}>
                          <Button
                            data-test-id="next-button"
                            classes={{ root: "create_role_profile_btn" }}
                            disabled={isLoading}
                            onClick={() => {
                              handleSubmit()
                              this.setState({ createProfile: true })
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress size={30} color="inherit" />
                            ) : (
                              "Next"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  }}
                </Formik>
              </Box>
            </Container>
            <Button
              disabled
              classes={{
                root: "create_profile_nav_btn create_profile_right",
                disabled: "select_role_move_disabled",
              }}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </WhiteBackgroundContainer>
        <Dialog
          maxWidth="sm"
          open={openModal}
          onClose={(_: object, reason: string) =>
            this.handleDialogOnClose(reason)
          }
          classes={{
            paper: "custom_dialog_paper"
          }}
        >
          <FarmersDonePage />
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
};
// Customizable Area End

// Customizable Area Start
export default withRouter(
  connect(
    mapStateToProps,
    {
      sentOTP1Action,
      verifyOTP1Action,
      changeOTP1Verify,
      setSameContactDetails,
      resatOTPFlags,
      verifyUserAction
    }
  )(CreateRoleProfileLayout)
);
// Customizable Area End
