import React, { Component } from "react";
import { Grid, Box } from "@material-ui/core";
import { green_leafy_veggies } from "../assets";
import "../../assets/css/shoppingcartcard.scss";

interface Props {
  isFarmOrder: boolean;
  name: string;
  image: string;
  description: string;
  price: string;
  discountPrice: string;
  quantity: number;
  productId: number;
  cookingInstruction: string;
  addOns: string;
  isLearningOrder: boolean;
  selectedVariantDetails: {
    id: string
    type: string
    attributes: {
      price: string
      option_name: string
      is_active: boolean
    }
  } | null
  variantDescription: string
}

interface S {}

class OrderSummaryShoppingCard extends Component<Props, S, any> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getPrice = () => {
    const { discountPrice, selectedVariantDetails } = this.props
    if(!selectedVariantDetails) {
      return discountPrice
    } else {
      return selectedVariantDetails.attributes.price
    }
  }

  isDiscount = () => {
    return Number(this.props.discountPrice) < Number(this.props.price);
  }; 

  render() {
    const {
      name,
      image,
      price,
      quantity,
      cookingInstruction,
      addOns,
      isLearningOrder,
      variantDescription,
      selectedVariantDetails
    } = this.props;
    const isExtraInOrder = addOns || cookingInstruction;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Box>
            <img
              src={image || green_leafy_veggies}
              className="cart_product_image"
              alt="product_image"
              width="100%"
              height="100%"
            />
          </Box>
        </Grid>
        <Grid item container xs={12} sm={8} spacing={3}>
          <Grid item xs={12}>
            <Box className="shopping_cart_details_wrapper">
              <Box className="shopping_cart_card_header">
                <h4 className="shopping_cart_product_name">{name}</h4>
                {!isLearningOrder && (
                  <p className="order_summary_quantity">{`Quantity: ${quantity}`}</p>
                )}
              </Box>
              {isExtraInOrder && (
                <Box>
                  {addOns && (
                    <p className="shopping_cart_card_paragraph">{`Extra: ${addOns}`}</p>
                  )}
                  {cookingInstruction && (
                    <>
                      <p className="shopping_cart_card_instruction">
                        Cooking Instruction:
                      </p>
                      <p className="shopping_cart_card_paragraph">
                        {cookingInstruction}
                      </p>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="shopping_cart_price_wrapper">
              <Box className="cart_price">
                <span data-testid="summary-card-price">${this.getPrice()}</span>
                {(this.isDiscount() && !selectedVariantDetails) && <del>${price}</del>}
              </Box>
              {
                variantDescription && selectedVariantDetails && (
                  <Box className="order_summary_variant">
                    <h6>{variantDescription}: </h6>
                    <p>{selectedVariantDetails?.attributes?.option_name || ""}</p>
                  </Box>
                )
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default OrderSummaryShoppingCard;
