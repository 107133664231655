import { baseURL } from "../../../../framework/src/config";
import StorageProvider from "framework/src/StorageProvider";

export const getDishCategories = async () => {
  const Token = await StorageProvider.get("authToken");
  try {
    const response = await fetch(
      `${baseURL}/bx_block_catalogue/categories?category_type=restaurant`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: Token || "",
        },
      }
    );
    return response.json();
  } catch (e) {
    return e;
  }
};
