Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";

//custom create profile

//web uses

exports.createProfileAPiEndPoint = "/account_block/accounts/create_profile";
exports.createProfileAPiMethod = "POST";
exports.createProfileContentType = "application/json";
exports.btnExampleTitle = "CLICK ME";

exports.accountsAPiEndPoint = "account/accounts";

exports.ApiContentType = "application/json";

//get remaining roles
exports.apiMethodGetRoles = "GET";
exports.getRolesApiEndPoint = "account_block/accounts/remaining_roles";
exports.getRoleDescApiEndPoint = "bx_block_settings/settings/role_details";

//post roles
exports.apiMethodUpdateRoles = "POST";
exports.updateRolesApiEndPoint = "account_block/accounts/update_roles";

//create farmer profile
exports.apiMethodCreateFarmerProfile = "POST";
exports.createFarmerProfileEndPoint = "bx_block_catalogue/farms";

//create restaurant profile
exports.apiMethodCreateRestaurantProfile = "POST";
exports.createRestaurantProfileEndPoint = "bx_block_catalogue/restaurants";

//create Educator profile
exports.apiMethodCreateEducatorProfile = "POST";
exports.createEducatorProfileEndPoint =
  "bx_block_catalogue/educational_contributors";

// send mobile OTP
exports.apiMethodPost = "POST"
exports.sendOTPEndpoint = "account_block/accounts/send_otp";

// verify mobile OTP
exports.verifyOTPEndpoint = "account_block/accounts/varify_otp";
exports.AUTH_TOKEN = "authToken"

exports.createRestProfileText = "Create Restaurant Profile";
exports.restaurantNameText = "Restaurant Name";
exports.restNamePlaceholder = "Enter Restaurant Name";
exports.restAddressText = "Restaurant Complete Address";
exports.restAddressPlaceholder = "Enter Restaurant Address";
exports.locationHeadingText = "Please place the pin accurately at your outlet's location on the map";
exports.locationParaText = "This will help your customers to locate your store";
exports.locationPlaceholder = "Enter your restaurant locality";
exports.cordinatesText = "or directly enter the co-ordinates";
exports.contactNumberHeading = "Contact number at Restaurant";
exports.contactNumberPara = "Your customers will call on this number for general enquiries";
exports.plusOneText = "+1";
exports.contactNumberPlaceholder = "Mobile Number at Restaurant";
exports.regOwnerDetailsText = "Register Owner details";
exports.ownerDetailsText = "Restaurant owner details";
exports.ownerNamePlaceholder = "Owner Name";
exports.ownerMobNoPlaceholder = "Owner's Mobile Number";
exports.sameAsRestaurantLabel = "Same as Restaurant";
exports.ownerEmailAddPlaceholder = "Owner Email Address";
exports.verifyBtnText = "Verify";
exports.enterOTPText = "Enter Otp";
exports.verifiedText = "Verified";
exports.invalidOTPText = "Invalid OTP";
exports.nextBtnText = "Next";
exports.validateText = "This field is required";
exports.emailValidateText = "Invalid email address format";
exports.numberValidateText = "Invalid mobile number";
exports.blockText = "block";
exports.escKeyDownText = 'escapeKeyDown';
exports.backDropClickText = 'backdropClick';
exports.errorsText = "errors";
exports.errorText = "error";
exports.orgContactNumberText = "org_contact_number";
exports.hasAlreadyBeenTakenText = "has already been taken";
exports.mobileAlreadyTakenText = "this mobile number has already been taken";
exports.orgOwnerNumberText = "org_owner_contact_number";
exports.somethingWentWrongText = "Something went wrong";
exports.arrowRightText = "ArrowRight";
exports.backspaceText = "Backspace";
exports.arrowleftText = "ArrowLeft";
exports.enterEmailAddressText = "Please enter an email address";
exports.restaurantText = "Restaurant";
exports.noneText = 'none';
exports.emailNotVerifiedText = "Email not verified. Please verify the email";
exports.latitudeText = "latitude";
exports.longitudeText = "longitude";
// Customizable Area End

//online login config
