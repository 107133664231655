//Customizable Area Start
import React from 'react'
import { fbImg,facebookLoginAction } from "../assets";
import { Button, CircularProgress } from "@material-ui/core";
import '../assets/css/social_btn.scss'
import { LoginSocialFacebook } from 'reactjs-social-login';
import FacebookButtonController , {Props} from "./FacebookButtonController.web";
import { connect } from 'react-redux';
const configJSON = require("../../../blocks/Settings/src/config.js")
class FacebookButton extends FacebookButtonController {
    constructor(props:Props){
        super(props);
    }
    render(){
        const {facebookInfo} = this.props
        return (
            <LoginSocialFacebook
                appId={configJSON.FACEBOOK_APP_ID || ""}
                fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                }
                onResolve={this.onLoginSuccessHandle}
                onReject={this.handleErrorResponse}
                redirect_uri={""}
                >
            <Button variant="contained"
                className="social_btn"
                classes={{
                    label: "btn_color_black",
                }}
                disabled={facebookInfo?.isFacebookLoading}
                startIcon={facebookInfo.isFacebookLoading ? null : <img src={fbImg} alt="fb_img" className="social_Img"/>}>
                     {facebookInfo.isFacebookLoading ? (
                            <CircularProgress size={22} color="inherit" />
                        ) : (
                            configJSON.facebookLabel
                    )}
            </Button>
            </LoginSocialFacebook>

        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        facebookInfo: state.user
    }
}

export default connect(mapStateToProps,{facebookLoginAction})(FacebookButton);
//Customizable Area End
