// Customizable Area Start
import React, { useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";

const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    justifyContent: "center",
  },
  stepLabelRoot: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    justifyContent: "center"
  },
  active: {
    "& $line": {},
    display: "flex",
    justifyContent: "flex-end",
  },
  completed: {
    "& $line": {},
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    width: "100%",
  },
})(StepConnector)

const useColorLibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#f0f0f0",
    zIndex: 1,
    color: "#000",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 0px",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600
  },
  stepLabelRoot: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    justifyContent: "center"
  },
  active: {
    color: "#fff",
    backgroundColor: "#20a258 !important"
  },
  completed: {
    color: "#000",
  },
})

function ColorLibStepIcon(props: StepIconProps) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div>1</div>,
    2: <div>2</div>,
    3: <div>3</div>,
    4: <div>4</div>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckOutlinedIcon className={classes.completed} />
      ) : (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
          })}
        >
          {icons[String(props.icon)]}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: 'auto',
      "&::-webkit-scrollbar": {
        height: '9px'
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "9px",
      },
      "&::-webkit-scrollbar-thumb ": {
        backgroundColor: "#d3d3d3",
        borderRadius: "9px",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        backgroundColor: "#d3d3d3",
      }
    },
    stepperRoot: {
      padding: "24px 0px",
      minWidth: '600px',
      display: 'flex'
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepLabelRoot: {
      "@media(max-width: 800px)": {
        flexDirection: "column"
      }
    },
    label: {
     "& > span": {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '600 !important',
        textAlign: "center",
        wordBreak: 'break-word'
     }
    }
  })
)

const getSteps = () => {
  return ["Login/Signup", "Pick up/Delivery", "Order Summary", "Payment"];
}

const getStepForPickupOrder = () => {
  return ["Login/Signup", "Order Summary", "Payment"]
}

const notDeliverableOrderTypes = ["restaurant", "course"]

interface ICustomizedSteppersProps {
  type : string
  activeStep: number
  handleStepChange?: (newStep: any) => void
}

export default function CustomizedSteppers({
  type,
  activeStep,
  handleStepChange,
}: ICustomizedSteppersProps) {
  const classes = useStyles();
  const steps = getSteps();
  const stepsForCourse = getStepForPickupOrder();
  const isPickUpTypeOrder = notDeliverableOrderTypes.includes(type)

  const handleActiveStepInView = () => {
    const allSteps = isPickUpTypeOrder ? getStepForPickupOrder() : getSteps()
    const activeStepLabel = allSteps[activeStep]
    document.getElementById(activeStepLabel)?.scrollIntoView()
  }

  useEffect(() => {
    handleActiveStepInView()
  }, [activeStep])
  

  return (
    <div id="cartFlow" className={classes.root}>
      <Stepper
        classes={{
          root: classes.stepperRoot
        }}
        activeStep={activeStep}
        unselectable="on"
        onChange={handleStepChange}
        connector={<ColorLibConnector />}
      >
        {isPickUpTypeOrder ? 
        stepsForCourse.map((label: string, step: number) => (
          <Step id={label} key={label} onClick={() => !!handleStepChange && handleStepChange(step)}>
            <StepLabel StepIconComponent={ColorLibStepIcon} classes={{
              root: classes.stepLabelRoot,
              labelContainer: classes.label
            }}>{label}</StepLabel>
          </Step>
        ))
        :
        steps.map((label: string, step: number) => (
          <Step id={label} key={label} onClick={() => !!handleStepChange && handleStepChange(step)}>
            <StepLabel StepIconComponent={ColorLibStepIcon} classes={{
              root: classes.stepLabelRoot,
              labelContainer: classes.label
            }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
// Customizable Area End
