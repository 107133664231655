// Customizable Area Start
import React from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import SearchRestaurantsController from "./SearchRestaurantsController.web"
import GreenBanner from "../../../../../components/src/GreenBanner.web"
import WebFooter from "../../../../../components/src/WebFooter.web"
import SearchResultFilters from "../SearchResultFilters.web"
import { ISearchRestaurant } from "../../interfaces/search"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"
import CustomHeading from "../../../../info-page/src/Farm/FarmHeadingWithFilterInput.web"
import RestaurantCard from "../../../../info-page/src/Restaurants/RestaurantCard.web"
import SearchCustomLayout from "../SearchCustomLayout.web"
import { styles } from "../commonStyles"
import AdCampaignWrapper from "../../../../LocalListingAds/src/AdCampaignWrapper.web"
const configJSON = require("../../config.js")

export class SearchRestaurants extends SearchRestaurantsController {
  render() {
    const {
      loader,
      categoriesLoader,
      priceRangeLoader,
      tagsLoader,
      categories,
      minPrice,
      maxPrice,
      tags,
      restaurants,
      totalPages,
    } = this.state
    const isFiltersLoading = categoriesLoader || priceRangeLoader || tagsLoader

    return (
      <>
        <Container>
          <Box style={styles.mainWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <SearchResultFilters
                  categoryTitle={configJSON.relatedCategoriesText}
                  categories={categories}
                  isToShowPriceFilter={true}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  isToShowNearByFilter={true}
                  nearByText={configJSON.nearByRestaurantText}
                  tags={tags}
                  isLoading={isFiltersLoading}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <CustomHeading heading={configJSON.restaurantsText} />
                <SearchCustomLayout
                  loader={loader}
                  totalPages={totalPages}
                  listLength={restaurants.length}
                  emptyMessage={configJSON.noRestaurantsFoundText}
                >
                  <Grid container spacing={2}>
                    {restaurants.map((restaurant: ISearchRestaurant) => {
                      const {
                        id,
                        attributes: { name, images, quote_time, description, banner, is_ad, third_party_ad_url, advertisement_banner,  },
                      } = restaurant
                      const imageUrl = images && images.url ? images.url : ""
                      const adImage = banner ? banner.url : imageUrl
                      const thirdPartyImageUrl = advertisement_banner
                        ? advertisement_banner.url
                        : undefined
                      const redirectUrl = `${AppRoutings.RestaurantDetails}/${id}`

                      return (
                        <Grid item xs={12} sm={6} md={4} key={id}>
                          <AdCampaignWrapper
                            isAd={is_ad}
                            thirdPartyImageUrl={thirdPartyImageUrl}
                            thirdPartyUrl={third_party_ad_url}
                          >
                            <RestaurantCard
                              cardName={name}
                              thumbnail={adImage}
                              duration={quote_time}
                              description={description}
                              redirectPath={redirectUrl}
                            />
                          </AdCampaignWrapper>
                        </Grid>
                      )
                    })}
                  </Grid>
                </SearchCustomLayout>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default withRouter(SearchRestaurants)
// Customizable Area End
