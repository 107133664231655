// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import { instaImg } from "../assets"
import { LoginSocialInstagram, IResolveParams } from "reactjs-social-login"
import CustomServiceButton from "./CustomServiceButton.web"
const configJSON = require("../config.js")

interface IInstagramLoginServiceButtonProps {
    isConnected: boolean
}

const InstagramLoginServiceButton = ({
    isConnected,
}: IInstagramLoginServiceButtonProps) => {
    return (
        <Box>
            {!isConnected ? (
                <LoginSocialInstagram
                    client_id={configJSON.INSTAGRAM_APP_ID || ""}
                    client_secret={configJSON.INSTAGRAM_APP_SECRET || ""}
                    redirect_uri={""} // url to redirect after successful login
                    onResolve={({ data }: IResolveParams) => console.log("data", data)}
                    onReject={(err) => console.log("error", err)}
                >
                    <CustomServiceButton
                        icon={instaImg}
                        btnText="Instagram"
                        isConnected={isConnected}
                    />
                </LoginSocialInstagram>
            ) : (
                <CustomServiceButton
                    icon={instaImg}
                    btnText="Instagram"
                    isConnected={isConnected}
                />
            )}
        </Box>
    )
}

export default InstagramLoginServiceButton
// Customizable Area End
