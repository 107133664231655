
//@ts-ignore
//@ts-nocheck

import * as React from 'react';
/*import webEducationCourseDetailsController, {
  Props,
} from "./webEducationCourseDetailsController.web";*/
//file
import AdminLearningCourseList3 from "./AdminLearningCourseList3.web"

import { withRouter } from "react-router-dom";
// css
import '../../../web/src/assets/css/style.scss'
import '../assets/css/adminLearningPage1.scss'

import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



import {
  Grid,
  Typography,
  Box,
  Paper,
  Drawer
} from "@material-ui/core";
//images
import {
  TimerIcon,
  TopicsIcon,
  EnrollIcon,
  TrainerImg,
  ServiceImg,
  BulletIcon,
  ProfileImg1
} from "./assets";

import AdminHeaderWeb from "../../../components/src/AdminHeader.web";
import AdminSidebarWeb from "../../../components/src/AdminSidebar.web";
//components
import AdminLearningCourseListController, {
  Props,
} from "./AdminLearningCourseListController.web";

import TextField from "@mui/material/TextField";
import { Divider } from '@mui/material';
//import Butn from '../../../components/src/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { green } from '@material-ui/core/colors';
import Butn from '@mui/material/Button';
import Checkbox from '@material-ui/core/Checkbox';


class AdminLearningContent extends AdminLearningCourseListController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    console.log(window.location.href)

    return (
      <>
        {/* <div className="Header"> */}
        {/* <WebHeader />
        <WebRoutingTab /> */}
        <div style={{ backgroundColor: 'lightblue' }}>
          <AdminHeaderWeb />
          <Grid Container style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Grid xs={2}>

              <AdminSidebarWeb />

            </Grid>


            <Grid item xs={6} className='Block2'
              style={{
                border: '1px solid black',
                backgroundColor: 'white', borderRadius: '10px'
              }}>
              <Grid Container>
                <Grid xs={12} >

                  <Box className='flex' sx={{ m: '2rem 2rem' }}   >
                    {<ArrowBackIcon />}

                    <Typography variant='h4'>
                      <b> Create Courses</b>
                    </Typography>


                  </Box>
                  <Box  >
                    <Divider />
                    <Box className='Course'
                      sx={{ m: '2rem', gap: '1rem', color: 'green' }}>

                      <Typography className='text' style={{ fontWeight: 'unset', color: window.location.href.includes('/adminLearn2') ? 'green' : '#9ba8b3', borderBottom: window.location.href.includes('/adminLearn2') ? '2px solid green' : 'none' }}
                        onClick={() => this.props.history.push('/adminLearn2')}
                        variant='h5'>
                        Course details
                      </Typography>
                      <Typography onClick={() => this.props.history.push('/adminLearn3')} className='text' style={{ fontWeight: 'unset', color: window.location.href.includes('/adminLearn3') ? 'green' : '#9ba8b3', borderBottom: window.location.href.includes('/adminLearn3') ? '2px solid green' : 'none' }}
                        variant='h5'>
                        Course content
                      </Typography>

                    </Box>
                    <Divider />
                  </Box>

                </Grid>
              </Grid>


              <AdminLearningCourseList3 />

            </Grid>




            <Grid xs={3}>
              <Grid Container>
                <Grid xs={12} style={{
                  border: '1px solid black',
                  height: 'auto',
                  borderRadius: '10px',
                  backgroundColor: 'white'
                }}>
                  <Box className='Box3'>
                    <Typography variant='h5' style={{ color: 'green' }}>
                      <b> Course status</b>
                    </Typography>
                    <Butn
                      style={{ marginTop: '1.5rem', justifyContent: 'space-between' }}
                      variant='outlined' color='success' size='small'
                      endIcon={<ExpandMoreIcon />}>
                      Active
                    </Butn>
                    <h6 className='delete' style={{ color: 'red' }}>
                      Delete
                    </h6>
                  </Box>
                </Grid>

                <Grid xs={12}
                  style={{
                    border: '1px solid black',
                    backgroundColor: 'white', marginTop: '0.7rem',
                    height: '70vh',
                    borderRadius: '10px'
                  }}>
                  <Box className='Box3'>

                    <Typography variant='h5' style={{ color: 'green' }}>
                      <b> Course Category</b>
                    </Typography>
                    <Butn style={{ marginTop: '1.5rem', justifyContent: 'space-between' }}
                      variant='outlined' color='success' endIcon={<ExpandMoreIcon />}>
                      Active
                    </Butn>
                    <h6 className='paragraph' style={{ color: 'silver', fontSize: '13px' }}>
                      Improve buyers discovery , analytic & <br />
                      workflow
                    </h6>
                    <Box>
                      <h6> <b> TAGS</b></h6>
                      <TextField
                        placeholder="Tecnology, Science etc"
                        style={{
                          height: "6vh", width: "100%",
                          display: 'flex', justifyContent: 'center',
                          border: "0.5px  solid silver", marginTop: '0.4rem',
                          borderRadius: '5px'
                        }}
                      />


                    </Box>

                  </Box>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </div>

      </>
    );
  }
}
//@ts-ignore
export default withRouter(AdminLearningContent);


