// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, CircularProgress } from "@material-ui/core";
import Image from 'material-ui-image'
import "../assets/css/restaurantcard.scss";
import { ArrowImg } from "../assets";
const configJSON = require('../config.js')

interface Props {
    cardName: string;
    thumbnail: string;
    redirectPath: string;
    description: string;
    duration: string;
}

const RestaurantCard = (props: Props) => {

    const { cardName,
        thumbnail,
        redirectPath,
        description,
        duration } = props

    const capitalizeValue = (value: string) => {
        if (!value) return '';
        return `${value[0].toUpperCase()}${(value.slice(1))}`
      }

    return (
        <Box className="restaurant_card">
            <Box className="restaurant_card_img_wrapper">
                <Link to={redirectPath}>
                    <Image
                        src={thumbnail || configJSON.errorText}
                        alt="restaurant banner"
                        className="restaurant_card_image"
                        style={{
                            borderRadius: '15px'
                        }}
                        loading={<CircularProgress className="image_loader" size={25} />}
                    />
                </Link>
            </Box>
            <Box className="restaurant_card_content">
                <h6>{capitalizeValue(cardName)}</h6>
                <p>{description}</p>
                <Box>
                    <p className="duration">{duration}</p>
                </Box>
                <Box>
                    <Link to={redirectPath}>
                        <Button
                            className="check_menu_btn"
                            classes={{ label: "check_menu_btn_color" }}
                        >
                            Check Menu{' '}
                            <img src={ArrowImg} alt="arrowImg" />
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default RestaurantCard;
// Customizable Area End
