// Customizable Area Start
export const setWLLPData = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_WLLP_DATA', payload })
}

export const setLPData = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_LP_DATA', payload })
}

export const removeCMSFavFarm = (payload) => async (dispatch) => {
    dispatch({ type: 'REMOVE_CMS_FAV_FARM', payload })
}

export const removeCMSFavRestaurant = (payload) => async (dispatch) => {
    dispatch({ type: 'REMOVE_CMS_FAV_RES', payload })
}

export const removeCMSFavCourse = (payload) => async (dispatch) => {
    dispatch({ type: 'REMOVE_CMS_FAV_COURSE', payload })
}

export const setSelectRoleData = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_SELECT_ROLE_DATA', payload })
}

export const setRLPData = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_RLP_DATA', payload })
}

export const setFPData = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_FP_DATA', payload })
}

export const setGreenBannerDesc = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_FOOTER_BANNER_DESC', payload })
}

export const setFooterDesc = (payload) => async (dispatch) => {
    dispatch({ type: 'SET_FOOTER_DESC', payload })
}


// Customizable Area End