// Customizable Area Start
import React from "react"
import { Box, Button, Container, Grid, Typography } from "@material-ui/core"
import LPFavCourseCard from "./LPFavCourseCard.web"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

interface ICourseCard {
    id: number
    type: string
    attributes: {
        id: number
        name: string
        author_name: string
        image: {
            id: number
            filename: string
            url: string
        }
        favourite: boolean
        rating: number
    }
}

interface ILPFavCourses extends RouteComponentProps {
    LPFavCoursesDesc: string
    LPFavCourses: ICourseCard[]
}

const LPFavCourses = ({
    history,
    LPFavCoursesDesc,
    LPFavCourses,
}: ILPFavCourses) => {

    const handleViewAll = () => {
        history.push({ pathname: AppRoutings.FavoritePage, state: "course" })
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box className="landing_page_section_details">
                        <Typography className="green_small_heading">
                            Favourite Courses
                        </Typography>
                        <h1 className="h1">{LPFavCoursesDesc}</h1>
                    </Box>
                </Grid>
                {LPFavCourses?.length > 0 ? (
                    <Grid container spacing={5} justifyContent="center">
                        {LPFavCourses.map((courseDetails: ICourseCard) => {
                            const {
                                id,
                                attributes: {
                                    id: courseId,
                                    name,
                                    author_name,
                                    image,
                                    rating,
                                    favourite,
                                },
                            } = courseDetails
                            return (
                                <Grid item xs={12} sm={6} md={4} key={id}>
                                    <LPFavCourseCard
                                        id={courseId}
                                        name={name}
                                        image={image?.url}
                                        author={author_name}
                                        rating={rating}
                                        isFav={favourite}
                                    />
                                </Grid>
                            )
                        })}

                        <Grid item xs={10}>
                            <Box className="landing_page_btn_container_center">
                                <Button
                                    classes={{ root: "green-btn", label: "btn-label" }}
                                    onClick={handleViewAll}
                                >
                                    View All
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Box className="landing_page_courses_container">
                            No favorite courses found
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}

const mapStateToProps = (state: any) => {
    return {
        LPFavCoursesDesc: state.cms.LPFavCoursesDesc,
        LPFavCourses: state.cms.LPFavCourses,
    }
}

export default withRouter(connect(mapStateToProps, {})(LPFavCourses))
// Customizable Area End
