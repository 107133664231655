// Customizable Area Start
import React from "react"
import { Grid, Typography, Box } from "@material-ui/core"
import {
  FarmerMerchant,
  EducationalContributor,
  Restaurateur
} from "../src/assets"
import { Link, withRouter } from "react-router-dom"
import AccountSettingRoleSelectionController, {
  Props, IRole
} from "./AccountSettingRoleSelectionController.web"

const roleImages: any = {
  "Farmer/merchant": FarmerMerchant,
  "Restaurateur": Restaurateur,
  "Contributor": EducationalContributor
}

class AccountSettingRoleSelection extends AccountSettingRoleSelectionController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      remainingRoles
    } = this.state

    return (
      <>
        {remainingRoles.length > 0 ? (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box className="account_details_heading_wrapper">
                  <h5 className="account_details_heading">
                    Want to sell or teach on We Thrive
                  </h5>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" className="not_connected_s">
                  You can register as multiple role also
                </Typography>
              </Grid>

              {remainingRoles.map((role: IRole) => {
                const { id, name } = role
                return (
                  <Grid
                    key={id}
                    item
                    xs={12}
                    sm={4}
                    xl={3}
                    className="account_setting_role_card_grid"
                  >
                    <Link
                      to={this.getRedirectionLink(name)}
                      style={{ display: "inherit" }}
                    >
                      <Box className="account_setting_role_card_wrapper">
                        <img
                          src={roleImages[name]}
                        />
                        <Typography className="account_setting_role_card_title">
                          {name}
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                )
              })}
            </Grid>
          </>
        ) : null}
      </>
    )
  }
}
export default withRouter(AccountSettingRoleSelection)
// Customizable Area End
