// Customizable Area Start
import React, { Component } from "react"
import { Button, CircularProgress } from "@material-ui/core"
import { styled } from '@mui/system'
import { instaImg } from "./assets"
const configJSON = require("./config.js")

const CustomButton = styled(Button)({
  boxShadow: "none !important",
  fontSize: "12.5px",
  padding: "8px",
  width: "100%",
  borderRadius: "25px",
  textTransform: "capitalize",
  "&:disabled": {
    opacity: 0.6,
  },
  "& span": {
    color: "#000",
  },
  "& .MuiButton-startIcon": {
    marginRight: "4px",
  },
})

const CustomImg = styled('img')({
  width: "17px"
})

interface Props {
  isLoading: boolean
}

export class InstagramLogoButton extends Component<Props> {
  render() {
    const { isLoading } = this.props

    return (
      <CustomButton
        variant="contained"
        disabled={isLoading}
        startIcon={
          isLoading ? null : (
            <CustomImg
              src={instaImg}
              alt="instagram"
            />
          )
        }
      >
        {isLoading ? (
          <CircularProgress size={22} color="inherit" />
        ) : (
          configJSON.instagramText
        )}
      </CustomButton>
    )
  }
}

export default InstagramLogoButton
// Customizable Area End
