// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActiveTabEnum } from "../../../components/src/redux/Users/userEnums";

export interface Props {
    navigation: any;
    id: string;
    updateActiveTab?: (activeTab: string) => void
    data: any;
    onReply : (id: string) => void;
    commentId : string;
    open: boolean;
    reviewId : string;
    onSave : (text: string,reviewId: string) => void
    repliesList : Array<object>;
    name: string;
    isEditSave: boolean;
    onDel : (id: string) => any ;
    onSaveEditReply : (text:string, replyId:string, reviewId:string) => void;
    classes : any;
}

interface S {
    replyText : string
}

interface SS {
    id : any
}

export default class CourseCommentController extends BlockComponent<
Props,
S,
SS
>{
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.NavigationPayLoadMessage)
        ];
    
        this.state = {
            replyText : ''
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.props.updateActiveTab && this.props.updateActiveTab(ActiveTabEnum.Learning)
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
            });
        }
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    }  
    
    handleSave = () => {
        let text = this.state.replyText
        this.props.onSave(text, this.props.reviewId)
        this.setState({replyText : ''})
    }

    handleReply = () => {
        this.props.onReply(this.props.data.id)
    }
}

// Customizable Area End