// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isTokenExpired } from "../../../components/src/utility/helper";
import Box from "@mui/material/Box";
import React from "react";
export const configJSON = require("./config");

interface IVariantOption {
  optionId: string
  optionHeading: string
  optionPrice: string
  optionWeight: string
}
interface IVariant {
  variantId: string
  variantHeading: string
  variantOptions: IVariantOption[]
}

export const DEFAULT_VARIANT: IVariant = {
  variantId: "",
  variantHeading: "",
  variantOptions: [{
    optionId: "",
    optionHeading: "",
    optionPrice: "",
    optionWeight: "",
  }]
}

export interface Props {
  navigation: any;
  id: string;
  routeUrl: any;
  history?: any;
  classes?: any;
  logout: any;
  label: any;
}

interface S {
  arrayHolder: any;
  token: string;
  tableData: any[];
  selected: any[];
  prodPerPage: any;
  currentPage: any;
  farmsList: any;
  productList: any;
  loading: any;
  currentFarmId: any;
  categories: any;
  categorySelected: any;
  sortValue: any;
  openDialog: any;
  removedProduct: any;
  search: any;
  productDetails: any;
  dateTimepickerValue: any;
  productMode: any;
  bulkAction: any;
  total_count: any;
  total_pages: any;
  weightDimensions: any[]
  isRemovingVariant: boolean
  isVariantConfirmationDialog: boolean
}

interface SS {
  id: any;
}

export default class AdminProductListController extends BlockComponent<
  Props,
  S,
  SS
> {
  formikRef: any = React.createRef()
  getProductApiCallId: any;
  getDropdownApiCallId: string = "";
  getCategoryApiCallId: string = "";
  getProductListApiCallId: string = "";
  deleteProductApiCallId: string = "";
  updateProductStatusApiCallId: string = "";
  getProductDetailsApiCallId: string = "";
  postCreateProductApiCallId: string = "";
  deleteImageApiCallId: string = "";
  bulkActionsProductApiCallId: string = "";
  removeVariantApiCallId: string = ""
  weightDimensionsApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      tableData: [],
      selected: [],
      prodPerPage: 5,
      currentPage: 1,
      farmsList: "",
      productList: [],
      loading: false,
      currentFarmId: "",
      categories: "",
      categorySelected: "",
      sortValue: "default",
      openDialog: false,
      removedProduct: "",
      search: "",
      productDetails: "",
      dateTimepickerValue: new Date("2018-01-01T00:00:00.000Z"),
      productMode: "",
      bulkAction: "",
      total_count: 0,
      total_pages: 0,
      weightDimensions: [],
      isRemovingVariant: false,
      isVariantConfirmationDialog: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const mode = query.get("mode");

    this.setState({
      productMode: mode,
    });

    if (mode == "edit") {
      this.getProductDetails(query.get("product_id"));
    }

    if (window.location.pathname.split("/")[3] == "products") {
      this.getFarms();
    }

    this.getWeightDimensionsListingApi()
    this.getCategory();
  }

  async componentDidUpdate() {
    if (this.state.currentFarmId) {
      if (window.location.pathname.split("/")[4] != this.state.currentFarmId) {
        const path = window.location.pathname.split("/")[4];
        this.setState({
          currentFarmId: path,
        });
      }
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {
          this.successApiCall(responseJson, apiRequestCallId);
        }
      } else {
        if (await isTokenExpired(responseJson)) return;
        
        toast.error(responseJson?.errors[0].message);
        this.setState({
          loading: false,
        });
      }
    }
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    const variantsApiIds = [this.removeVariantApiCallId, this.weightDimensionsApiCallId]
    //get farms
    if (apiRequestCallId === this.getDropdownApiCallId) {
      const farmId: any = window.location.pathname.split("/")[4];
      const pathFarmId = this.pathFarmIdFinder(responseJson, farmId);
      this.setState({
        farmsList: responseJson.data,
        currentFarmId: pathFarmId,
      });
      this.props.history.push(`/admin/farm/products/${pathFarmId}`);
      pathFarmId != 0 &&
        this.getProductList(
          pathFarmId,
          this.state.sortValue,
          this.state.categorySelected,
          this.state.search,
          this.state.prodPerPage,
          this.state.currentPage
        );
      this.setState({
        loading: false,
      });
    }

    if(variantsApiIds.includes(apiRequestCallId)) {
      this.handleVariantApisResponse(apiRequestCallId, responseJson);
    }

    //get category
    if (apiRequestCallId === this.getCategoryApiCallId) {
      this.setState({
        categories: responseJson,
        categorySelected: "all",
        loading: false,
      });
    }

    //get product list
    if (apiRequestCallId === this.getProductListApiCallId) {
      this.setState({
        productList: responseJson.data,
        total_pages: responseJson.meta.pagination.total_pages,
        total_count: responseJson.meta.pagination.total_count,
        loading: false,
        selected: [],
      });
    }

    //get product details
    if (apiRequestCallId === this.getProductDetailsApiCallId) {
      this.setState({
        productDetails: responseJson.data,
        loading: false,
      });
    }

    //delete product
    if (apiRequestCallId === this.deleteProductApiCallId) {
      const path = window.location.pathname.split("/")[4];
      const mode = this.getQuery().get("mode");
      this.getProductList(
        path,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage,
        this.state.currentPage
      );
      toast.success(`Product Removed Successfully `);
      if (mode == "edit") {
        this.props.history.goBack();
      }
    }
    // Product bulk actions
    if (apiRequestCallId === this.bulkActionsProductApiCallId) {
      const path = window.location.pathname.split("/")[4];
      this.getProductList(
        path,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage,
        this.state.currentPage
      );
      this.setState({
        selected: [],
        // loading: false,
      });
      toast.success(`Products Status Updated Successfully `);
    }

    //update product status
    if (apiRequestCallId === this.updateProductStatusApiCallId) {
      const path = window.location.pathname.split("/")[4];
      this.getProductList(
        path,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage,
        this.state.currentPage
      );
    }

    //create product
    if (apiRequestCallId === this.postCreateProductApiCallId) {
      const mode = this.state.productMode == "create";
      toast.success(`Product ${mode ? "Added" : "Updated"} Successfully`);
      this.setState({
        loading: false,
      });
      this.props.history.goBack();
    }

    //delete image
    if (apiRequestCallId === this.deleteImageApiCallId) {
      this.setState({
        loading: false,
      });
      toast.success(`Image Removed Successfully `);
    }
  };

  pathFarmIdFinder = (responseJson: any, farmId: any) => {
    if (farmId == "00") {
      return responseJson.data.length > 0 ? responseJson.data[0].id : "0";
    } else {
      return window.location.pathname.split("/")[4];
    }
  };

  getFarms = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropdownApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getCategory = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getWeightDimensionsListingApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.weightDimensionsApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWeightDimensionsApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true
  }

  getProductList = async (
    id: any,
    sort: any,
    filter: any,
    search: any,
    per: any,
    page: any
  ) => {
    this.setState({
      loading: true,
    });
    const endpoint = `product[product_id]=${id}&product[product_type]=BxBlockCatalogue::Farm&sort_by=${
      sort == "default" ? "" : sort
    }&filter_by_category=${
      filter == "all" ? "" : filter
    }&search=${search}&per=${per}&page=${page}`;

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductListAPiEndPoint + `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteProduct = async () => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      id: this.state.removedProduct,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProductAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteImage = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteImageAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getProductDetails = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDetailsAPiEndPoint + `?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateProductStatus = async (id: any, status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      product: {
        id: id,
      },
      farm_catalogue: {
        status: status,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProductStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProductStatusAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  createProduct = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      token: token,
    };
    const mode = this.state.productMode == "create";
    const endPoint = mode
      ? configJSON.postCreateFarmProductApiEndPoint
      : configJSON.postUpdateFarmProductApiEndPoint;
    const apiMethod = mode
      ? configJSON.apiMethodTypePost
      : configJSON.apiMethodTypePut;
    const formdata = new FormData();
    formdata.append(
      `product${mode ? "[product_id]" : "[id]"}`,
      mode ? window.location.pathname.split("/")[4] : values.product_id
    );
    formdata.append("product[product_type]", "BxBlockCatalogue::Farm");
    formdata.append("farm_catalogue[name]", values.name);
    formdata.append("farm_catalogue[description]", values.description);
    formdata.append("farm_catalogue[per_pound]", "true");
    formdata.append("farm_catalogue[price_per_unit]", values.compare_at_price);
    formdata.append("farm_catalogue[compare_at_price]", values.price_per_unit);
    formdata.append("farm_catalogue[cost_per_item]", values.cost_per_item);
    formdata.append(
      "farm_catalogue[schedule_availability]",
      values.schedule_availability
    );
    formdata.append("farm_catalogue[inventory_attributes][sku]", values.sku);
    formdata.append(
      "farm_catalogue[inventory_attributes][barcode]",
      values.barcode
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][track_quantity]",
      values.track_quantity ? values.track_quantity : false
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][selling_out_of_stock]",
      values.selling_out_of_stock ? values.selling_out_of_stock : false
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][available_quantity]",
      values.available_quantity
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][available_quantity_unit]",
      values.quantity_unit
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][physical_product]",
      values.physical_product ? values.physical_product : false
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][weight]",
      values.weight
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][weight_unit]",
      values.weight_unit
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][length]",
      values.length
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][width]",
      values.width
    );
    formdata.append(
      "farm_catalogue[inventory_attributes][height]",
      values.height
    );
    formdata.append(
      "farm_catalogue[customer_information_attributes][country_name]",
      values.country_name
    );
    formdata.append(
      "farm_catalogue[customer_information_attributes][hormonized_system_code]",
      values.hsc
    );
    formdata.append(
      "farm_catalogue[search_engine_listing_attributes][title]",
      values.page_title
    );
    formdata.append(
      "farm_catalogue[search_engine_listing_attributes[description]",
      values.page_description
    );
    formdata.append(
      "farm_catalogue[search_engine_listing_attributes][url_and_handle]",
      values.url_handle
    );
    formdata.append("farm_catalogue[category_id]", values.category_id);
    formdata.append("farm_catalogue[status]", values.farm_status);
    formdata.append(
      "farm_catalogue[online_store]",
      values.online_store ? values.online_store : false
    );
    values.media_mode == "drag"
      ? [...values.images].forEach((image: any) => {
          formdata.append("farm_catalogue[images][]", image, image.name);
        })
      : [...values.images].forEach((url: any) => {
          formdata.append("media_urls[]", url);
        });

    const tag1 = values.tags.split(/,\s*/);
    tag1.forEach((tag: string) => {
      formdata.append("farm_catalogue[tags][]", tag.trim());
    });

    if (values.variants.length > 0) {
      const { variantId, variantHeading, variantOptions }: IVariant = values.variants[0]
    
      variantId && formdata.append(
        "farm_catalogue[variant_attributes][id]",
        variantId
      )
      formdata.append(
        "farm_catalogue[variant_attributes][description]",
        variantHeading
      )
      const variantOptionIds: string[] = []
      variantOptions.forEach((option: IVariantOption) => {
        const { optionId, optionHeading, optionPrice, optionWeight } = option
        optionId && variantOptionIds.push(optionId)
    
        optionId && formdata.append(
          `farm_catalogue[variant_attributes][variant_options_attributes][][id]`,
          optionId
        )
        formdata.append(
          `farm_catalogue[variant_attributes][variant_options_attributes][][option_name]`,
          optionHeading
        )
        formdata.append(
          `farm_catalogue[variant_attributes][variant_options_attributes][][price]`,
          optionPrice
        )

        formdata.append(
          `farm_catalogue[variant_attributes][variant_options_attributes][][weight]`,
          optionWeight
        )
      })
      formdata.append("variant_option_ids[]", variantOptionIds.join(','))
    }    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  bulkActions = async (status: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status: status,
      product_ids: this.state.selected,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsProductsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeVariantApi = async (variantId: string) => {
    this.setState({ isRemovingVariant: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeVariantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeVariantApiEndPoint}/${variantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleRemoveVariant = (variantId: string) => {
    if(variantId) {
      this.removeVariantApi(variantId)
    } else if(this.formikRef.current) {
      this.formikRef.current.setFieldValue("variants", [])
    }
  }

  convertVariantFromResponse = (
    variantDescription: any,
    variantOptions: any[]
  ) => {
    if (variantDescription?.data?.id && variantOptions) {
      const {
        data: {
          id,
          attributes: { description },
        },
      } = variantDescription
  
      const options = variantOptions.map((option: any) => {
        const {
          id: optionId,
          attributes: { option_name, price, weight },
        } = option
        return {
          optionId,
          optionHeading: option_name,
          optionPrice: price,
          optionWeight: weight
        }
      })
  
      return [
        {
          variantId: id,
          variantHeading: description,
          variantOptions: options,
        },
      ]
    }
    return []
  }  

  handleVariantApisResponse = (apiRequestCallId: string, responseJson: any) => {
    if(apiRequestCallId === this.weightDimensionsApiCallId) {
      this.setState({ weightDimensions: responseJson.data })
    }

    if(apiRequestCallId === this.removeVariantApiCallId) {
      this.removeVariantApiResponse(responseJson);
    }
  }

  removeVariantApiResponse = (responseJson: any) => {
    if (this.formikRef.current && responseJson.data) {
      const { setFieldValue } = this.formikRef.current
      setFieldValue("variants", [])
      return
    }
    this.setState({ isRemovingVariant: false })
    toast.error("Something went wrong")
  }  

  handleAddVariant = () => {
    if (this.formikRef.current) {
      this.formikRef.current.setFieldValue("variants", [
        { ...DEFAULT_VARIANT },
      ])
    }
  }

  handleVariantConfirmationDialog = (isOpen: boolean) => {
    this.setState({ isVariantConfirmationDialog: isOpen })
  }

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id,
    });
    if (value == "edit") {
      this.props.history.push(
        `/admin/farm/add-product/${
          window.location.pathname.split("/")[4]
        }?mode=edit&product_id=${item.id}`
      );
    }
    if (value == "remove") {
      this.handleDialog();
    }
  };

  handleClick = (event: React.MouseEvent<unknown>, num: any) => {
    event.preventDefault();
    const selectedIndex = this.state.selected.indexOf(num);

    let newSelectedValue: any[] = [];
    if (selectedIndex === -1) {
      newSelectedValue = newSelectedValue.concat(this.state.selected, num);
    } else if (selectedIndex === 0) {
      newSelectedValue = newSelectedValue.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelectedValue = newSelectedValue.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedValue = newSelectedValue.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelectedValue });
  };

  isSelected = (num: any) => this.state.selected.indexOf(num) !== -1;

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function(...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  SearchDebounceUpdate = this.SearchDebounce(this.getProductList, 1000);

  marginCalculator = (
    price_per_unit: any,
    cost_per_item: any,
    setFieldValue: any
  ) => {
    let margin: any =
      (Number(price_per_unit) - Number(cost_per_item)) / Number(cost_per_item);
    setFieldValue("margin", (margin * 100).toFixed(1));
  }

  //price debouncing
  PriceDebounceUpdate = this.SearchDebounce(this.marginCalculator, 1000);

  imageUrlExit = (isUrlExist: any, setFieldValue: any, values: any) => {
    if (!isUrlExist) {
      setFieldValue("images", [...values.images, values.images_url]);
      setFieldValue("images_url", "");
    } else {
      toast.warning("Image url already added");
    }
  };

  deleteDisplay = () => {
    let query = this.getQuery();
    let mode = query.get("mode");
    return mode == "edit";
  };

  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return query;
  };

  gettingInitialValue = (
    details: any,
    inventory: any,
    customerInfo: any,
    searchEngine: any,
    availability: any,
    priceExit: any
  ) => {

    let inventoryParams = {
      sku: "",
      barcode: "",
      track_quantity: false,
      selling_out_of_stock: false,
      available_quantity: "",
      available_quantity_unit: "Kg",
      physical_product: false,
      weight: "",
      weight_unit: "Kg",
      length: "",
      width: "",
      height: ""
    }
    if(inventory) {
      inventoryParams = { ...inventoryParams, ...inventory }
    }

    const variant = this.convertVariantFromResponse(details?.variant_description, details?.variant_options)

    const object = {
      name: details?.name || "",
      description: details?.description || "",
      price_per_unit: details?.compare_at_price || "",
      compare_at_price: details?.price_per_unit || "",
      cost_per_item: details?.cost_per_item || "",
      sku: inventoryParams.sku,
      barcode: inventoryParams.barcode,
      track_quantity: inventoryParams.track_quantity,
      selling_out_of_stock: inventoryParams.selling_out_of_stock,
      available_quantity: inventoryParams.available_quantity,
      quantity_unit: inventoryParams.available_quantity_unit,
      physical_product: inventoryParams.physical_product,
      weight: inventoryParams.weight,
      weight_unit: inventoryParams.weight_unit,
      length: inventoryParams.length,
      width: inventoryParams.width,
      height: inventoryParams.height,
      country_name: customerInfo?.country_name || "",
      variants: variant
    };

    return this.gettingInitialValue2(
      details,
      inventory,
      customerInfo,
      searchEngine,
      availability,
      priceExit,
      object
    );
  };

  gettingInitialValue2 = (
    details: any,
    inventory: any,
    customerInfo: any,
    searchEngine: any,
    availability: any,
    priceExit: any,
    object: any
  ) => {
    return {
      ...object,
      url_handle: searchEngine?.url_and_handle || "",
      images: [],
      editImages: details?.images || [],
      edit_images_Url: details?.media_images || [],
      images_url: "",
      media_mode: "drag",
      category_id: details?.categories?.id || "default",
      online_store: details?.online_store,
      farm_status: details?.product_status || "active",
      product_id: this.state.productDetails?.id || "",
      schedule_availability: (availability && new Date(availability)) || null,
      margin: priceExit && (priceExit * 100).toFixed(1),
      tags: (details && details?.tags.join(", ")) || "",
      hsc: customerInfo?.hormonized_system_code || "",
      page_title: searchEngine?.title || "",
      page_description: searchEngine?.description || "",
    };
  };

  multipleUploadImage = (values: any, e: any, setFieldValue: any) => {
    let uploaded: any = [...values.images];

    let files = e.currentTarget ? e.currentTarget.files : e;
    files.length > 0 &&
      Array.from(files).some((file: any) => {
        if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
          uploaded.push(file);
        }
      });

    files.length > 0 &&
      //@ts-ignore
      setFieldValue("images", uploaded);
  };

  getCategoryId = (values: any) => {
    return values.category_id ? values.category_id : "none";
  };

  handleButtonText = () => {
    return (
      this.state.productMode &&
      (this.state.productMode == "create" ? "Save" : "Update")
    );
  };

  handlePageHeading = (mode: any) => {
    return mode == "create" ? "Add" : "Edit";
  };

  handleDraftCss = (status: any) => {
    return status == "draft" && "warning-variant";
  };

  handleDeactiveCss = (status: any) => {
    return status == "deactive" && "danger-variant";
  };

  handleInventoryCell = (x: any) => {
    return x.inventory.available_quantity
      ? `${x.inventory.available_quantity} ${x.inventory.available_quantity_unit}
                                  `
      : "-";
  };

  handleFarmsOnChange = (targetValue: any) => {
    targetValue != "0" &&
      targetValue != "00" &&
      this.getProductList(
        targetValue,
        this.state.sortValue,
        this.state.categorySelected,
        this.state.search,
        this.state.prodPerPage,
        1
      );
    if (targetValue != "0") {
      this.setState({
        currentFarmId: targetValue,
      });

      this.props.history.push(`/admin/farm/products/${targetValue}`);
    }
  };

  handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragDrop = (e: any, values: any, setFieldValue: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.multipleUploadImage(values, e.dataTransfer.files, setFieldValue);
    }
  };

  handleTrackQuantity = (type: any, value: any, setFieldValue: any) => {
    let isQuantity = type == "track_quantity";
    if (value) {
      setFieldValue(
        `${isQuantity ? "selling_out_of_stock" : "track_quantity"}`,
        false
      );
      !isQuantity && setFieldValue("available_quantity", "");
    }
  };

  handleUnits = (setFieldValue: any, item: any) => {
    let units = item == "per_item" ? ["Unit"] : ["Kg", "Lb"];
    setFieldValue("units", units);
    setFieldValue("quantity_unit", units[0]);
    setFieldValue("weight_unit", units[0]);
  };

  renderOptions = (props: any, option: any) => {
    return (
      <Box
        component="li"
        sx={{
          //@ts-ignore
          "& > img": {
            mr: 2,
            flexShrink: 0,
          },
        }}
        {...props}
      >
        <img
          //@ts-ignore
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          alt=""
        />
        {option.label} ({option.code}) +{option.phone}
      </Box>
    );
  };

  getMenuStyleCss=(status:any)=>{
  if(status=="active"){
    return ""
  }else if(status=="deactive"){
    return "_menus de_active_menu"
  }else{
    return "draft_menu"
  }
  }
}
// Customizable Area End
