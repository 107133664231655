//Customizable Area Start
import React from "react";
import "../../components/src/assets/css/webrestaurantnavbar.scss";
import { Box, Grid, Button, FilledInput, Select, MenuItem, Typography } from "@material-ui/core";
import { NavLink, Link, withRouter } from "react-router-dom";
import {getUserDetails, Logo, OldMan, setLoginModalFalse, setLoginModalTrue,resetUserDetails,getFarmCategoriesAndTagsAction, updateActiveTab} from "./assets";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { FastForwardOutlined } from "@material-ui/icons";
import { truncate } from "fs";
import OnlineEmailAccountRegister from "../../blocks/email-account-registration/src/OnlineEmailAccountRegistration.web";
import OnlineEmailAccountLogin from "../../blocks/email-account-login/src/OnlineEmailAccountLogin.web";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import WebHeaderController from "./WebHeaderController.web";
import TransitionsModal from "./Modal.web";
import StorageProvider from "./../../framework/src/StorageProvider.web";
import OnlineResetPassword from "../../blocks/forgot-password/src/OnlineResetPassword.web";
import GoogleButton from "./Social_buttons/GoogleButton.web";
import FacebookButton from "./Social_buttons/FacebookButton.web";
import InstagramButton from "./Social_buttons/InstagramButton.web";
import {connect} from "react-redux";
import UserInitialization from "./UserInitilization.web";
import { ActiveTabEnum } from './redux/Users/userEnums';
//Customizable Area End

class WebRestaurantNavBar extends WebHeaderController {
  constructor(props: any) {
    super(props);
  }

  LoginSignup = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={1} sm={2} md={3} lg={4} xl={4}>
            <Box
              onClick={() => {
                console.log("DID I CALLED FROM HERE",this.state.openModal)
                this.setState({
                  openModal: false,
                  formType: "",
                });
              }}
              className="modal_box"
              style={{ height: "100%", width: "100%" }}
            />
          </Grid>
          <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
            <Box className="layout_wrapper">
              <Box className="logo_wrapper">
                <img src={Logo} alt="logo" width="200px" />
              </Box>
              <Box className="form_wrapper">
                <Box>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <Box
                        onClick={() => {
                          this.loginTab();

                          // this.closeModal.bind(this);
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            className={
                              this.state.activeTab === "login"
                                ? "active_tab"
                                : "tab adjust_tab"
                            }
                          >
                            Login
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Box
                        onClick={() => {
                          this.registerTab();
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            className={
                              this.state.activeTab === "register"
                                ? "active_tab"
                                : "tab "
                            }
                          >
                            Create Account
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {this.state.activeTab === "login" ? (
                      <OnlineEmailAccountLogin
                        open={this.showModal}
                        close={() => {
                          this.closeModal()
                          // this.closeSecondInnerModal.bind(this);
                          // setTimeout(() => {
                          //   this.closeInnerModal.bind(this);
                          // }, 1000);
                          // setTimeout(() => {
                          //   this.closeModal.bind(this);
                          // }, 2000);
                        }}
                        // setIsAuth={this.props.setIsAuth}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="forgotPassword_wrapper"
                        >
                          <Button
                            onClick={() => {
                              this.setState({ openModal: false });
                              this.setState({ openInnerModal: true });
                              this.setState({ formType: "forgotPassword" });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Forgot Password?
                          </Button>
                        </Box>
                      </OnlineEmailAccountLogin>
                    ) : (
                      <OnlineEmailAccountRegister />
                    )}
                    <Grid item xs={12}>
                      <Box
                        style={{
                          marginTop: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid
                            item
                            sm={12}
                            md={4}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <GoogleButton close={() => this.closeModal()}/>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <FacebookButton />
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <InstagramButton />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Typography className="terms_of_condition">
                      By clicking create account or login with google, facebook
                      or instagram, you agree to WTT{" "}
                      <Link to="normal-user-login">
                        {" "}
                        <span>Terms of use </span>
                      </Link>
                      and <span className="green_text">Privacy Policy</span>.
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={4} xl={4}>
            <Box
              onClick={() => {
                this.setState({
                  openModal: false,
                  formType: "",
                });
              }}
              className="modal_box"
              style={{ height: "100%", width: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };


  resetPassword = () => {
    console.log("formtype", this.state.formType);
    return (
      <div>
        <OnlineResetPassword
          close={() => {
            this.setState({ openModal: false });
            this.setState({ openInnerModal: false });
            // this.setState({ formType: "forgotPassword" });
          }}
        />
      </div>
    ); };
 

  render() {
    return (
      <Box className="web_restaurant_nav_bar">
        <UserInitialization/>
        <Grid container alignItems="center">
          <Grid item xs={3} sm={3} md={3} lg={2}>
            <Box style={{ display: "flex" }}>
              {" "}
              <Box>
                <MenuIcon
                  className="toggle_menu_icon"
                  onClick={() => this.props.sidebarToggle()}
                />
              </Box>
              <Box>
                <Link to="/landing-page-logged-in">
                  <img src={Logo} />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={5}
            sm={5}
            md={5}
            lg={7}
            alignItems="center"
            // justifyContent="space-evenly"
          >
            <Grid item xs={12}>
              <Box
                className={
                  this.state.showTabs
                    ? "web_restaurant_routing_tabs"
                    : "web_restaurant_routing_tabs_none"
                }
              >
                <Box>
                  <NavLink
                    exact
                    to="/"
                    className={this.isActive(ActiveTabEnum.Home) ? 'web_restaurant_active_route underline' : ''}
                  >
                    <span>Home</span>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink
                    exact
                    to="/farms-market"
                    className={this.isActive(ActiveTabEnum.Market) ? 'web_restaurant_active_route underline' : ''}
                  >
                    <span>Farmers market</span>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink
                    exact
                    to="/restaurant-landing-page"
                    className={this.isActive(ActiveTabEnum.Restaurant) ? 'web_restaurant_active_route underline' : ''}
                  >
                    <span>Restaurants</span>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink
                    exact
                    to="/learning"
                    className={this.isActive(ActiveTabEnum.Learning) ? 'web_restaurant_active_route underline' : ''}
                  >
                    <span>Learning</span>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink
                    exact
                    to="/about-us"
                    className={this.isActive(ActiveTabEnum.AboutUs) ? 'web_restaurant_active_route underline' : ''}
                  >
                    <span>About Us</span>
                  </NavLink>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={
                  this.state.showSearchInput
                    ? "restaurant_navbar_search_input_wrapper"
                    : "restaurant_navbar_search_input_wrapper_none"
                }
              >
                <FilledInput
                  placeholder="Search here"
                  classes={{ root: "restaurant_navbar_search_input" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={3}>
            <Box className="restaurant_navbar_actions">
              <Box
                onClick={() => this.hideTabs.bind(this)}
                className="search_icon"
              >
                <SearchIcon />
              </Box>
              <Box
                className={
                  this.state.showSearchInput
                    ? "restaurant_navbar_icon_none"
                    : "restaurant_navbar_icon"
                }
              >
                <FavoriteBorderIcon />
              </Box>
              <Link to="/shopping-cart" style={{ color: "#000" }}>
                <Box
                  className={
                    this.state.showSearchInput
                      ? "restaurant_navbar_icon_none"
                      : "restaurant_navbar_icon"
                  }
                >
                  <ShoppingCartOutlinedIcon />
                </Box>
              </Link>
              <Box
                className={
                  this.state.showSearchInput
                    ? "restaurant_navbar_icon_none"
                    : "restaurant_navbar_icon"
                }
              >
                {" "}
                {/* <Button onClick={this.showModal.bind(this)}>Log in</Button> */}
              </Box>
              {
                // @ts-ignore
                JSON.stringify(this.props?.userInfo?.userDetails) != "{}" ? (
              <>
                {" "}
                <Box className="web_header_select">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    {
                      // @ts-ignore
                      this.props.userInfo?.userDetails?.is_admin ? (
                      <>
                        <Link to="/admin/dashboard">
                          <MenuItem>Admin</MenuItem>
                        </Link>
                      </>
                    ) : null}
                    <Link to="/account-setting">
                      <MenuItem>Account Setting</MenuItem>
                    </Link>
                    <MenuItem>
                      <Box
                        onClick={async () => {
                          await StorageProvider.remove("authToken");
                          await StorageProvider.set("AdminAuth", false);
                          await StorageProvider.remove("firstname");
                          await StorageProvider.remove("lastname");
                          localStorage.removeItem("profile")
                          localStorage.removeItem("authToken")
                          // @ts-ignore
                          this.props.resetUserDetails()
                          this.props.history.push("/");
                        }}
                      >
                        Log Out
                      </Box>
                    </MenuItem>
                  </Select>
                </Box>
                <Box className="web_header_profile">
                  <Link to="/account-setting">
                    <img src={OldMan} />
                  </Link>
                </Box>
              </>
            ) : (
              <Box className="navbar_login_btn_wrapper">
                {" "}
                <Button onClick={this.showModal.bind(this)}>Log in</Button>
              </Box>
            )}
            </Box>
          </Grid>
        </Grid>

        <Box />
        <div>
          <TransitionsModal
            open={
              this.state.openModal
                ? this.state.openModal
                : this.state.openInnerModal
                ? this.state.openInnerModal
                : this.state.openSecondInnerModal
            }
            close={this.closeModal}
            component={
              this.state.openModal ? (
                this.LoginSignup()
              ) : this.state.openInnerModal ? (
                this.resetPassword()
              ) : (
                <>
                  {this.state.formType === "forgotPassword"
                    ? this.resetPassword()
                    : this.LoginSignup()}
                </>
              )
            }
          />
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state:any) => {
  return { userInfo: state.user };
};

export default withRouter(connect(mapStateToProps,{getUserDetails,setLoginModalFalse,setLoginModalTrue,resetUserDetails,getFarmCategoriesAndTagsAction, updateActiveTab})(WebRestaurantNavBar));

// export default withRouter(WebRestaurantNavBar);
