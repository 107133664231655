// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import WalletController, { Props } from "./WalletController.web"
import Setting from "../../../Settings/src/Setting.web"
import CardListing from "../checkOut/checkOutCCard/CardListing.web"
import "../assets/css/checkout/checkoutccard.scss"
import RewardPointsSetting from "./RewardPoints/RewardPointsSetting.web"

export const styles = () =>
  createStyles({
    mainWrapper: {
      padding: "25px",
    },
    paymentOptionHeading: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: "normal",
      marginBottom: "30px",
      fontSize: "26px",
      color: "#000"
    },
    optionHeading: {
      fontSize: "18px",
    },
    rewardPointsWrapper: {
      marginTop: "30px",
    }
  })

class Wallet extends WalletController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <Setting>
          <Box className={classes.mainWrapper}>
            <h4 className={classes.paymentOptionHeading}>Payment Option</h4>
            <Box>
              <h6 className={classes.optionHeading}>Credit or debit card</h6>
              <Box>
                <CardListing
                  isOnlySave
                  selectedCardId=""
                  onSelectedCardChange={() => {}}
                  handlePaymentOnUnSaveCard={() => {}}
                />
              </Box>
            </Box>

            <Box className={classes.rewardPointsWrapper}>
              <RewardPointsSetting />
            </Box>
          </Box>
        </Setting>
      </>
    )
  }
}

export default withStyles(styles)(withRouter(Wallet))
// Customizable Area Start
