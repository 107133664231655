import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider";
import { toast } from "react-toastify";
const configJSON = require("../../config.js")
import { RouteComponentProps } from "react-router-dom"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper";
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    match: any
    menuId: string
    price: string
    discountPrice: string
    image: string
    dishName: string
    dishDescription: string
    isDisable: boolean
    isFavorite: boolean
    addMenu: () => void
    getRestaurantDetailsAction: (id: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    hideDescription: boolean
    isFavorite: boolean
    isToggleFav: boolean
    isLoginDialog: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class MenuItemController extends BlockComponent<
    Props,
    S,
    SS
> {
    //Customizable Area Start
    toggleFavouriteApiCallId: string = ""
    //Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        //Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]

        this.state = {
            hideDescription: false,
            isFavorite: this.props.isFavorite,
            isToggleFav: false,
            isLoginDialog: false
        }
        //Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        const descLength = this.props.dishDescription.length
        if (descLength > 40) this.setState({ hideDescription: true })
    }

    getResDetails = () => {
        const { match, getRestaurantDetailsAction } = this.props
        const { id } = match.params
        if (+id > 0) {
            getRestaurantDetailsAction(id)
        }
    }

    capitalizeValue = (value: string) => {
        if (!value) return '';
        return `${value[0].toUpperCase()}${(value.slice(1))}`
    }

    isDiscount = () => {
        return this.props.discountPrice && Number(this.props.discountPrice) < Number(this.props.price)
    }

    toggleDescription = () => {
        this.setState({
            hideDescription: !this.state.hideDescription
        })
    }

    openLoginDialog = () => {
        this.setState({ isLoginDialog: true })
    }

    closeLoginDialog = () => {
        this.setState({ isLoginDialog: false })
    }

    toggleFavorite = async () => {
        const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
        if (!isLoggedIn) {
            this.openLoginDialog()
            return
        }
        this.toggleFavoriteApi()
    }

    toggleFavoriteApi = async () => {
        this.setState({ isToggleFav: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const { menuId } = this.props

        const httpBody = {
            favouriteable_id: menuId || "",
            favouriteable_type: configJSON.CARTABLE_TYPE_MENU
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.toggleFavouriteApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.toggleFavouriteApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePatch
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.toggleFavouriteApiCallId) {
                    this.setState({ isFavorite: !!responseJson?.meta?.favourite, isToggleFav: false })
                }

            } else {
                if (!isTokenExpireResponse(responseJson) && apiRequestCallId === this.toggleFavouriteApiCallId) {
                    this.setState({ isToggleFav: false })
                    toast.error("Something went wrong")
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End

}
