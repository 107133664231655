// Customizable Area Start
import React from "react"
import { Box, Button, Typography } from "@material-ui/core"
import { LeafWithoutBG } from "../../assets"
import "../../assets/css/guestlandingbannerbox.scss"

interface IBannerBox {
  heading: string
  buttonText: string
  handleClick: (e: any) => void
}

const BannerBox = ({ heading, buttonText, handleClick }: IBannerBox) => {
  return (
    <Box className="guest_landing_banner_content">
      <img src={LeafWithoutBG} />
      <Typography variant="h3">{heading}</Typography>
      <Button onClick={handleClick}>{buttonText}</Button>
    </Box>
  )
}

export default BannerBox
// Customizable Area End
