// Customizable Area Start
import React, { Component } from "react"
import { styled } from "@mui/system"
import { Box, CircularProgress } from "@material-ui/core"

const CenterContentWrapper = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const Loader = styled(CircularProgress)({
  color: "#20a258",
})

interface Props {
  isLoading: boolean
  children: React.ReactNode
}

export class NotificationLoaderWrapper extends Component<Props> {
  render() {
    const { isLoading, children } = this.props

    return isLoading ? (
      <CenterContentWrapper>
        <Loader size={22} />
      </CenterContentWrapper>
    ) : (
      children
    )
  }
}

export default NotificationLoaderWrapper
// Customizable Area End
