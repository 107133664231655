// Customizable Area Start
import React from "react"
import AllWishlistPanelController, {
  Props,
} from "./AllWishlistPanelController.web"
import "../../assets/css/wishlist.scss"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import WishlistCourseCard from "../WishlistCourseCard.web"
import { connect } from "react-redux"
import { updateWishlistCount } from "../assets"
import {configJSON} from "../WishlistCourseCardController.web";

class CourseWishlistPanel extends AllWishlistPanelController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, wishlists } = this.state
    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <Box>
            {wishlists?.wishlist?.data?.length > 0 ? (
              <Box className="cwp_courses_container">
                {wishlists.wishlist.data.map((wishlist: any) => {
                  const {
                    id,
                    attributes: { product_name, product_id, added_from, image, status },
                  } = wishlist
                  return (
                    <WishlistCourseCard
                      key={id}
                      id={id}
                      courseId={product_id}
                      image={image?.url}
                      name={product_name}
                      sellerName={added_from}
                      status={status}
                      handleRemoveItemLocal={this.handleRemoveItemLocalCourse}
                    />
                  )
                })}
              </Box>
            ) : (
              <Box mt={3} className="order_history_blank_container">
                <Typography align="center">
                  {configJSON.noCoursesText}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    wishlistCount: state.user.wishlistCount,
  }
}

export default connect(mapStateToProps, { updateWishlistCount })(
  CourseWishlistPanel
)
// Customizable Area End
