// Customizable Area Start
import React from "react";
import AdminDashboardController, {
  Props,
} from "./AdminDashboardController.web";
import { withRouter } from "react-router-dom";

import { Apple_Icon } from "./assets";

// common css
import "../../../web/src/assets/css/style.scss";
//dash board css
//  import "../assets/css/dashboard.scss";
// custom components
import {
  Link,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Menu";
import CustomChips from "../../../components/src/chip";
//charts
import {
  Chart,
  registerables,
  ChartTypeRegistry,
  ChartConfiguration
} from "chart.js";
Chart.register(...registerables);
// Customizable Area End

class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  createChart() {
    const labels: any = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const data = {
      labels: labels,
      datasets: [
        {
          label: "New Customer",
          backgroundColor: "#796CFA",
          data: [5, 30, 300, 400, 60, 200, 90, 0, 30, 30, 40, 900],
          fill: false,
          tension: 0.4,
          barThickness: 20,
          borderRadius: 6,
        },
        {
          label: "Returning Customer",
          backgroundColor: "#E1E4E8",
          data: [10, 200, 50, 10, 0, 200, 500, 10],
          fill: false,
          tension: 0.4,
          barThickness: 20,
          borderRadius: 6,
        },
      ],
    };
    const lineConfig: ChartConfiguration<
      keyof ChartTypeRegistry,
      number[],
      string
    > = {
      type: "bar",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            // beginAtZero: true,
            grid: {
              display: true,
              color: "#F2F3F7",
              borderDash: [4, 4],
            },
            // display: false,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
      },
    };
    this.lineChart = new Chart(
      document.getElementById("lineChart") as HTMLCanvasElement,
      lineConfig
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { match }: any = this.props;
    return (
      <>
        {/* top cards */}
        
        <Grid container spacing={5} className="cardContainer">
          <Grid item xs={12} md={3}>
            <Card className="card">
              <CardContent className="cardContent">
                <Typography className="h6">Total Revenue</Typography>
                <div className="priceRow">
                  <Typography className="h1">$87,805</Typography>
                  <CustomChips label="+3.04%" />
                </div>
              </CardContent>
              <div className="iconCircle">
                <HomeIcon />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className="card">
              <CardContent className="cardContent">
                <Typography className="h6">Orders</Typography>
                <div className="priceRow">
                  <Typography className="h1">3,700</Typography>
                  <CustomChips label="+5%" />
                </div>
              </CardContent>
              <div className="iconCircle">
                <HomeIcon />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className="card">
              <CardContent className="cardContent">
                <Typography className="h6">Customers</Typography>
                <div className="priceRow">
                  <Typography className="h1">5,300</Typography>

                </div>
              </CardContent>
              <div className="iconCircle">
                <HomeIcon />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className="card">
              <CardContent className="cardContent">
                <Typography className="h6">Conversion rate</Typography>
                <div className="priceRow">
                  <Typography className="h1">87.45%</Typography>

                </div>
              </CardContent>
              <div className="iconCircle">
                <HomeIcon />
              </div>
            </Card>
          </Grid>
        </Grid>
        {/* ends: top cards */}

        {/* chart */}
        <Grid className="chartContainer cardContainer">
          <Card className="card">
            <div className="chartCard">
              <div className="header">
                <h5>Sales Statistics</h5>
                <div className="button-container">
                  <button className="graph-btn ">Daily</button>
                  <button className="graph-btn ">Weekly</button>
                  <button className="graph-btn active">Monthly</button>
                </div>
              </div>
              <div className="dashboardChart">
                <canvas id="lineChart" />
              </div>
            </div>
          </Card>
        </Grid>
        {/* chart */}

        {/* main card container */}
        <div className="tableContainer dashboardTableContainer">
          {/* filter section */}
          <div className="headerListContainer">
            <div className="prodListHeader">
              <h6>Product Summary</h6>
              <Link href="#" className="link">
                Export
              </Link>
            </div>
          </div>
          {/* table section */}
          <div>
            <TableContainer className="tableContainer tableHeaderContainer" component={Paper}>
              <Table aria-label="simple table">

                <TableHead className="tableHead dashBoadTableHead">
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Payement Method</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>


                {/* <TableHead className="tableHead dashBoadTableHead">
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Payement Method</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  <TableRow>
                    <TableCell component={"th" as any} scope="row">
                      <div className="productCell">
                        <img
                          src={Apple_Icon}
                          className="prodImage"
                          alt="Apple_Icon"
                        />
                        <h5>Apple</h5>
                      </div>
                    </TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell className="BoldCell">$89.00 / Kg</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell className="paymentCell">paypal</TableCell>
                    <TableCell>
                      <div className="outlineChip">
                        <CustomChips label="delivering" />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component={"th" as any} scope="row">
                      <div className="productCell">
                        <img
                          src={Apple_Icon}
                          className="prodImage"
                          alt="Apple_Icon"
                        />
                        <h5>Apple</h5>
                      </div>
                    </TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell className="BoldCell">$89.00 / Kg</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell className="paymentCell">paypal</TableCell>
                    <TableCell>
                      <div className="outlineChip">
                        <CustomChips label="delivering" />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component={"th" as any} scope="row">
                      <div className="productCell">
                        <img
                          src={Apple_Icon}
                          className="prodImage"
                          alt="Apple_Icon"
                        />
                        <h5>Apple</h5>
                      </div>
                    </TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell className="BoldCell">$89.00 / Kg</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell className="paymentCell">paypal</TableCell>
                    <TableCell>
                      <div className="outlineChip">
                        <CustomChips label="delivering" />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component={"th" as any} scope="row">
                      <div className="productCell">
                        <img
                          src={Apple_Icon}
                          className="prodImage"
                          alt="Apple_Icon"
                        />
                        <h5>Apple</h5>
                      </div>
                    </TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell className="BoldCell">$89.00 / Kg</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell className="paymentCell">paypal</TableCell>
                    <TableCell>
                      <div className="outlineChip">
                        <CustomChips label="delivering" />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component={"th" as any} scope="row">
                      <div className="productCell">
                        <img
                          src={Apple_Icon}
                          className="prodImage"
                          alt="Apple_Icon"
                        />
                        <h5>Apple</h5>
                      </div>
                    </TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell className="BoldCell">$89.00 / Kg</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell className="paymentCell">paypal</TableCell>
                    <TableCell>
                      <div className="outlineChip">
                        <CustomChips label="delivering" />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    );
    // Customizable Area End
  }
}
//@ts-ignore
// Customizable Area Start
export default withRouter(AdminDashboard);
// Customizable Area End
