// Customizable Area Start
import React from "react"
import { Grid, Box, IconButton } from "@material-ui/core"
import { RestaurantCardImg, getRestaurantDetailsAction } from "../../assets"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import MenuItemController, { Props } from "./MenuItemController.web"
import CustomFavoriteIcon from "../CustomFavoriteIcon.web"
import LoginSignUpDialogWeb from "../../langinpageLoggedIn/LoginSignUpDialog.web"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class MenuItemWeb extends MenuItemController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      price,
      discountPrice,
      image,
      dishName,
      dishDescription,
      isDisable,
      addMenu,
    } = this.props
    const {
      hideDescription,
      isToggleFav,
      isFavorite,
      isLoginDialog,
    } = this.state
    const isValidLength = dishDescription.length <= 40

    return (
      <>
        <Box className="restaurant_details_menu_item">
          <Grid
            container
            spacing={2}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={3} md={2}>
              <div>
                <img
                  src={image || RestaurantCardImg}
                  style={{ width: "100%" }}
                  alt="dish"
                />
              </div>
            </Grid>
            <Grid item xs={7} sm={6}>
              <div className="restaurant_details_menu_item_description">
                <div>
                  <h6>{this.capitalizeValue(dishName)}</h6>
                  <CustomFavoriteIcon
                    isFavorite={isFavorite}
                    isToggleFav={isToggleFav}
                    toggleFavorite={this.toggleFavorite}
                  />
                </div>
                <div className="res_details_desc_container">
                  <p>
                    {hideDescription
                      ? `${dishDescription.slice(0, 40)},...`
                      : dishDescription}
                    {!isValidLength && (
                      <button onClick={() => this.toggleDescription()}>
                        &nbsp;{hideDescription ? "READ MORE" : "READ LESS"}
                      </button>
                    )}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={5} sm={3}>
              <div className="restaurant_details_menu_item_details">
                <div>
                  <p>${discountPrice}</p>
                  <del>{this.isDiscount() && `$${price}`}</del>
                </div>
                <IconButton
                  size="small"
                  classes={{
                    disabled: "menu_item_disable_btn",
                  }}
                  disabled={isDisable}
                  onClick={addMenu}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Box>
        <LoginSignUpDialogWeb
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
          onLoginSuccess={this.getResDetails}
        />
      </>
    )
  }
}

export default withRouter(
  connect(
    null,
    { getRestaurantDetailsAction }
  )(MenuItemWeb)
)
// Customizable Area End
