// Customizable Area Start
import React from "react"
import ExpertDetailsDialogController, {
  Props,
  ICourse,
} from "./ExpertDetailsDialogController.web"
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core"
import "../assets/css/experts/experts.scss"
import { withRouter } from "react-router-dom"
import Image from "material-ui-image"
import {
  LinkedInLogo,
  FacebookLogo,
  YouTubeLogo,
  TwitterLogo,
} from "../assets"
import CustomDonationBanner from "../../../../components/src/CustomDonationBanner.web"
import CourseCard from "../../../../components/src/CourseCard.web"
import SocialIconNav from "../Community/CommunityCustomComponents/SocialIconNav.web"
import ShareButton from "../../../../components/src/ShareButton.web"
import { AppRoutings } from "../../../../components/src/utility/app-routing"
import CustomDialogTitle from "../../../../components/src/CustomDialogTitle.web"
import { getShareTitle } from "../../../../components/src/utility/helper"
const configJSON = require("../config.js")

class ExpertDetailsDialog extends ExpertDetailsDialogController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { isOpen, handleClose } = this.props
    const { loader, expertDetails } = this.state
    const {
      id,
      attributes: {
        first_name,
        last_name,
        biography,
        educator_image,
        facebook,
        facebook_username,
        headline,
        youtube,
        youtube_username,
        twitter,
        twitter_username,
        linkedin,
        linkedin_username,
        website,
        status,
        courses,
        campaign: { data: campaignData },
      },
    } = expertDetails

    const facebookUrl = this.getSocialUrl(facebook, facebook_username)
    const twitterUrl = this.getSocialUrl(twitter, twitter_username)
    const linkedInUrl = this.getSocialUrl(linkedin, linkedin_username)
    const youtubeUrl = this.getSocialUrl(youtube, youtube_username)

    const fullName = `${first_name} ${last_name}`

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: "custom_dialog_paper",
        }}
        className="edd_main_wrapper"
      >
        <CustomDialogTitle onClose={handleClose}>
          <Typography variant="h3" className="edd_main_heading">
            Expert Profile
          </Typography>
        </CustomDialogTitle>
        {loader ? (
          <Box className="edd_loader_container">
            <CircularProgress size={25} />
          </Box>
        ) : (
          expertDetails && (
            <Box>
              <Box paddingX="40px" paddingBottom="40px">
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={5} lg={4}>
                      <Box>
                        <Image
                          src={educator_image?.url || configJSON.errorText}
                          style={{
                            borderRadius: "20px"
                          }}
                          imageStyle={{
                            borderRadius: "20px"
                          }}
                          iconContainerStyle={{
                            boxShadow: "0 24px 48px 0 rgba(0, 24, 52, 0.08)",
                            borderRadius: "20px"
                          }}
                          loading={<CircularProgress size={30} className="edd_image_loader" />}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={7} lg={8}>
                        <Box>
                          <Typography variant="h4" className="edd_name_heading">
                            {fullName}
                          </Typography>
                          <Typography className="edd_education_details">
                            {status}
                          </Typography>
                          <Typography className="edd_experience_details">
                            {headline}
                          </Typography>

                          <Box mt={2}>
                            <Typography className="edd_secondary_heading">
                              Social Media Profile
                            </Typography>
                            <Box className="edd_social_container">
                              <SocialIconNav
                                url={linkedInUrl}
                                image={LinkedInLogo}
                              />
                              <SocialIconNav
                                url={facebookUrl}
                                image={FacebookLogo}
                              />
                              <SocialIconNav
                                url={youtubeUrl}
                                image={YouTubeLogo}
                              />
                              <SocialIconNav
                                url={twitterUrl}
                                image={TwitterLogo}
                              />
                            </Box>
                          </Box>

                        {!!website && (
                          <Box mt={2}>
                            <Typography className="edd_secondary_heading">
                              Website
                            </Typography>
                            <a
                              href={website}
                              className="edd_website"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {website}
                            </a>
                          </Box>
                        )}

                        <Box mt={2}>
                          <ShareButton
                            link={`${window.location.protocol}//${window.location.hostname}${AppRoutings.ExpertsDetails}/${id}`}
                            title={getShareTitle(`${fullName} Expert`)}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={4}>
                        <Typography variant="h3" className="edd_main_heading">
                          About {fullName}
                        </Typography>

                        <Typography className="edd_expert_description">
                          <div dangerouslySetInnerHTML={{ __html: biography }} />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {campaignData && (
                <CustomDonationBanner
                  donationId={campaignData.id}
                  heading={`Help ${fullName}`}
                  description={campaignData.attributes.tagline}
                  backgroundImage={campaignData.attributes.banner?.url || ""}
                  donatedAmount={campaignData.attributes.donation_raised}
                  requiredAmount={campaignData.attributes.required_donation}
                />
              )}

              {courses?.data?.length > 0 && (
                <Box padding="40px 40px 60px">
                  <Box mb={3}>
                    <Typography variant="h3" className="edd_main_heading">
                      Courses By {fullName}
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    {courses.data.map((courseDetails: ICourse) => {
                      const {
                        id,
                        attributes: { banner_image },
                      } = courseDetails
                      return (
                        <Grid item xs={12} sm={6} md={4} key={id}>
                          <CourseCard
                            courseId={courseDetails?.attributes?.id}
                            authorName=""
                            courseName=""
                            courseImage={banner_image?.url || ""}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </Box>
          )
        )}
      </Dialog>
    )
  }
}

export default withRouter(ExpertDetailsDialog)
// Customizable Area End
