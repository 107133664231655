// Customizable Area Start
import React from "react"
import { Box, Button, Typography } from "@material-ui/core"
import { cardBg } from "../../assets"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps } from "react-router-dom"
import LPCustomCarousel from "../LPComponents/LPCustomCarousel.web"
import { AppRoutings } from "../../../../../components/src/utility/app-routing"

interface IProps extends RouteComponentProps {
  WLLPFarmingHeading: string
  WLLPFarmingDesc: string
}

const CAROUSEL_BREAKPOINTS = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    },
  }
]

const WLLPFarming = ({
  history,
  WLLPFarmingHeading,
  WLLPFarmingDesc,
}: IProps) => {
  const viewFarmRedirect = () => {
    history.push(AppRoutings.FarmsMarket)
  }

  return (
    <Box className="landing_info_third">
      <Box className="farming_left_portion">
        <Typography>Farming</Typography>
        <Typography>{WLLPFarmingHeading}</Typography>
        <Typography>{WLLPFarmingDesc}</Typography>
        <Button
          classes={{
            root: "view_farm_button",
          }}
          onClick={viewFarmRedirect}
        >
          View Farms
        </Button>
      </Box>
      <Box className="farming_carousel">
        <LPCustomCarousel
          type="latest"
          slidesToShow={2}
          showAllBtn={false}
          className="farm-slick"
          emptyMessage="No farming records found"
          sliderProps={{ responsive: CAROUSEL_BREAKPOINTS}}
        >
          <img className="farming_images" src={cardBg} />
          <img className="farming_images" src={cardBg} />
          <img className="farming_images" src={cardBg} />
        </LPCustomCarousel>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    WLLPFarmingHeading: state.cms.WLLPFarmingHeading,
    WLLPFarmingDesc: state.cms.WLLPFarmingDesc,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(WLLPFarming)
)
// Customizable Area End
