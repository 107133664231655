// Customizable Area Start
import React from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import { connect } from "react-redux"

interface IService {
    id: string
    type: string
    attributes: {
        type: string
        heading: string
        description: string
    }
}

interface IProps {
    WLLPServicesHeading: string
    WLLPServices: IService[]
}

const WLLPOurServices = ({ WLLPServicesHeading, WLLPServices }: IProps) => {
    return (
        <Box className="landing_info_fourth">
            <Container fixed>
                <Grid container className="landing_info_offer_heading">
                    <Grid item xs={12}>
                        <Typography align="center">{WLLPServicesHeading}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    {WLLPServices?.map((service: IService) => {
                        const {
                            id,
                            attributes: { heading, description },
                        } = service
                        return (
                            <Grid item xs={12} sm={6} md={4} key={id}>
                                <Box className="landing_info_offer_boxes">
                                    <Typography>{heading}</Typography>
                                    <Typography>{description}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        WLLPServicesHeading: state.cms.WLLPServicesHeading,
        WLLPServices: state.cms.WLLPServices,
    }
}

export default connect(
    mapStateToProps,
    {}
)(WLLPOurServices)
// Customizable Area End
