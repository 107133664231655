// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { formatCoord, getCoords, isTokenExpired} from "../../../components/src/utility/helper";
export const configJSON = require("./config");
import { FormikTouched, FormikErrors } from "formik";
import { AppRoutings } from "../../../components/src/utility/app-routing";

export interface Props {
  navigation: any;
  id: string;
  logout: any;
  classes: any;
  history: any;
}

interface S {
  arrayHolder: any;
  token: string;
  farmsList: any;
  loading: any;
  farmMode: any;
  removedFarm: any;
  search: any;
  isEdit: any;
  address: string;
  mapCenter: {
    lat: any;
    lng: any;
  };
  mapCoOrdinates: {
    lat: any;
    lng: any;
  };
  org_otp_id: any;
  owner_otp_id: any;
  formRef: any;
  addressRef: any;
  farmDetails: any;
  farmIcons:any
}

interface SS {
  id: any;
}

export default class AdminFarmDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDropdownApiCallId: string = "";
  sendOtpApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  ownersendOtpApiCallId: string = "";
  ownerverifyOtpApiCallId: string = "";
  postCreateFarmApiCallId: string = "";
  getFarmsDetailsApiCallId: string = "";
  deleteImageApiCallId: string = "";
  getIconsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      farmsList: "",
      loading: false,
      farmMode: "",
      removedFarm: "",
      search: "",
      isEdit: "",
      address: "",
      mapCenter: {
        lat: null,
        lng: null,
      },
      mapCoOrdinates: {
        lat: null,
        lng: null,
      },
      org_otp_id: "",
      owner_otp_id: "",
      formRef: React.createRef(),
      addressRef: React.createRef(),
      farmDetails: "",
      farmIcons:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const query = new URLSearchParams(window.location.search)
    const mode = query.get("mode")
    const pathName = window.location.pathname.split("/")[3]
    this.setState({
      farmMode: mode,
    });
    const createFarm = "/admin/farm/add-farm?mode=create"
    if(createFarm){
      this.getIcons()
    }
    if (pathName == "details") {
      this.getFarms(this.state.search);
    }
    if (mode == "edit") {
      this.getFarmsDetails(query.get("farm_id"))
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && !responseJson?.errors){
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiRequestCallId != null) {
        this.successApiCall(responseJson, apiRequestCallId)
        }
      }
      if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        }
      }
    }
  }

  // success api call 
  successApiCall = (responseJson: any, apiRequestCallId: any) => {

    //get farms
    if (apiRequestCallId === this.getDropdownApiCallId) {
      this.setState({
        farmsList: responseJson.data,
      });
      this.setState({
        loading: false,
      });
    }
    //get icons
    if (apiRequestCallId === this.getIconsApiCallId) {
      this.setState({
        farmIcons: responseJson.data,
      });
      this.setState({
        loading: false,
      });
    }

    //owner send otp
    if (apiRequestCallId === this.ownersendOtpApiCallId) {
      responseJson.email_otp_id && toast.success("Otp Send Successfully");
      this.setState({
        owner_otp_id: responseJson.email_otp_id,
        loading: false,
      });
    }
    //owner verify otp

    if (apiRequestCallId === this.ownerverifyOtpApiCallId) {
      toast.success("Otp Verified Successfully");
      this.state.formRef.current.setFieldValue("isOwnerVerified", true);
      this.state.formRef.current.setFieldValue("verifiedAddress", this.state.formRef.current.values.owner_address);
      this.state.formRef.current.setFieldValue("owner_otp", "");
      this.setState({
        loading: false,
      });
    }


    //farm  
    if (apiRequestCallId === this.getFarmsDetailsApiCallId) {
      this.setState({
        farmDetails: responseJson.data,
        mapCoOrdinates: {
          lat: responseJson.data.attributes.latitude,
          lng: responseJson.data.attributes.longitude,
        },
        loading: false,
      });
    }

    // create farm 
    if (apiRequestCallId === this.postCreateFarmApiCallId) {
      this.setState({
        loading: false,
      });
      const mode = this.state.farmMode == "edit";
      toast.success(`Farm ${mode ? "Edited" : "Created"} Successfully`);
      this.props.history.push(AppRoutings.AdminFarmDetails)
    }
      if (apiRequestCallId === this.ownerverifyOtpApiCallId) {
          this.state.formRef.current.setFieldValue("owner_otp", "");

        }
    //delete image
    if (apiRequestCallId === this.deleteImageApiCallId) {
      function useQuery() {
        return new URLSearchParams(window.location.search);
      }
      let query = useQuery();
      this.getFarmsDetails(query.get("farm_id"))
      this.setState({
        loading: false,
      });
      toast.success(`Image Removed Successfully `)

    }

  }

  /// get farms
  getFarms = async (search: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropdownApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint + `/search?search=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  /// get farms
  getFarmsDetails = async (id: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFarmsDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFarmsAPiEndPoint + `/${id}}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
/// get icons
getIcons = async () => {
  this.setState({
    loading: true,
  });

  const token = await StorageProvider.get("authToken");

  const headers = {
    "Content-Type": configJSON.productApiContentType,
    token: token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getIconsApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getIconsAPiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};



  // sentOtp
  //@ts-ignore
  ownersendOtp = async (number: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      email: number,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ownersendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSendOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // verify otp
  //@ts-ignore
  ownerverifyOtp = async (number: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      email_otp_id: this.state.owner_otp_id,
      pin: number,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ownerverifyOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postVerifyOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //creating farm product
  createFarm = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");
    const headers = {
      token: token,
    };
    const mode = this.state.farmMode == "create";
    const endPoint = mode
      ? configJSON.postCreateFarmApiEndPoint
      : `${configJSON.postCreateFarmApiEndPoint}/${values.farm_id}`
    const apiMethod = mode
      ? configJSON.apiMethodTypePost
      : configJSON.apiMethodTypePut;
    const isSame = values.org_contact_number == values.owner_contact_number
    const formdata = new FormData();
    !mode && formdata.append("farm[role_id]", values.farm_id);
    formdata.append("farm[name]", values.name);
    formdata.append("farm[description]", values.description);
    formdata.append("farm[org_address]", values.org_address);
    formdata.append("farm[org_owner_name]", values.owner_name);
    formdata.append("farm[org_owner_address]", values.verifiedAddress);
    formdata.append("farm[instagram]", values.instagram);
    formdata.append("farm[facebook]", values.facebook);
    formdata.append("farm[purchase_limit_percentage]", values.purchase_limit_percentage);
    formdata.append(
      "farm[org_contact_number]",
      "1" + `${values.org_contact_number}`
    );
    formdata.append(
      "farm[org_owner_contact_number]",
      values.is_same_number
        ? `1${values.org_contact_number}`
        : `1${values.owner_contact_number}`
    );
    formdata.append("farm[latitude]", values.latitude);
    formdata.append("farm[longitude]", values.longitude);
    formdata.append("farm[is_same_number]", isSame ? true : values.is_same_number);


    [...values.selectedImages].forEach((image: any) => {
      formdata.append("farm[images][]", image, image.name);
    });
     
    if(values.iconsIds.length>0){
      [...values.iconsIds].forEach((id: any) => {
        formdata.append("farm[icons][]", id);
      });
    }else{
      formdata.append("farm[icons][]", "none");
    }
      
    [...values.address].forEach((item: any, index: any) => {
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][address_line1]`,
        item.address_line1
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][address_line2]`,
        item.address_line2
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}]city]`,
        item.city
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][state]`,
        item.state
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][zipcode]`,
        item.zipcode
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][email]`,
        item.email
      );
      formdata.append(
        `farm[farm_pick_up_addresses_attributes][${index}][number]`,
        item.number ? `1${item.number}`: ""
      );
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateFarmApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteImage = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteImageAPiEndPoint + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedFarm: item.id,
    });
    if (value == "edit") {
      //@ts-ignore
      this.props.history.push(
        `${AppRoutings.AdminFarmEditFarmId}${item.id}`
      );
    }
  };

  handleCreateFarm = () => {
    this.props.history.push(
      `/admin/farm/add-farm?mode=create`
    );
  }

  handleSearchFarm = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      search: event.target.value,
    });
    this.SearchDebounceUpdate(event.target.value);
  }
  
  handlePlacesAutocompleteSelect = (
    lat: any,
    lng: any,
    setFieldValue: (field: string, value: number) => void
  ) => {
    setFieldValue("latitude", lat)
    setFieldValue("longitude", lng)
    this.setState({ mapCoOrdinates: { lat, lng } })
  }

  handleChangeLat = (
    e: React.ChangeEvent<HTMLInputElement>,
    longitude: number,
    setFieldValue: (key: string, value: any) => void
  ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latLng = {
      lat: Number(targetValue),
      lng: Number(longitude),
    }
    setFieldValue("latitude", targetValue)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }
  
  handleChangeLng = (
    e: React.ChangeEvent<HTMLInputElement>,
    latitude: number,
    setFieldValue: (key: string, value: any) => void
  ) => {
    const { value } = e.target
    const targetValue = formatCoord(value)
    const latLng = {
      lat: Number(latitude),
      lng: Number(targetValue),
    }
    setFieldValue("longitude", targetValue)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }  

  handleChangeLatLng = (
    lat: number,
    lng: number,
    setFieldValue: (key: string, value: number) => void
  ) => {
    const latLng = { lat, lng }
    setFieldValue("latitude", lat)
    setFieldValue("longitude", lng)
    this.setState({ mapCoOrdinates: { ...latLng } })
  }

  detectPlace = async (setFieldValue: (field: string, value: any) => void) => {
    try {
      const coords: any = await getCoords()
      const { latitude, longitude } = coords
      setFieldValue("latitude", latitude)
      setFieldValue("longitude", longitude)
      const latLng = {
        lat: latitude,
        lng: longitude
      }
      this.setState({
        mapCenter: { ...latLng },
        mapCoOrdinates: { ...latLng }
      })
    } catch (e) {
      toast.error(e?.message)
    }
  }

  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  }

  SearchDebounceUpdate = this.SearchDebounce(this.getFarms,
    1000)

  handleAddressEdit = (item: any, setFieldValue: (field: string, value: any) => void) => {
    const form2 = this.state.addressRef.current.setFieldValue
    setFieldValue("isEditAddress", true)
    setFieldValue("editAddressValues", item)
    form2("address_line1", item.address_line1)
    form2("address_line2", item.address_line2)
    form2("city", item.city)
    form2("state", item.state)
    form2("zipcode", item.zipcode)
    form2("number", item.number.length > 10 ? item.number.slice(1) : item.number)
    form2("email", item.email)
    setFieldValue("isExpanded", true)
  }

  handleNewAddressSubmit = (
    mainFormProps: any,
    values: any,
    setFieldValue: (field: string, value: any) => void,
    resetForm: (values: any) => void
  ) => {
    const filterEdit = mainFormProps.values.address.filter(
      (x: any) =>
        x.address_line1 !=
        mainFormProps.values.editAddressValues.address_line1
    )
    mainFormProps.values.isEditAddress
      ? mainFormProps.setFieldValue("address", [...filterEdit, values])
      : mainFormProps.setFieldValue("address", [...mainFormProps.values.address, values])
    toast.success(
      `Address ${mainFormProps.values.isEditAddress ? "Edited" : "Added"} Successfully`
    )
    setFieldValue("editAddressValues", "")
    setFieldValue("isEditAddress", false)
    resetForm({ values: "" })
  }

  handleAddressNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newVal = e.target.value
    if (newVal.length <= 10) {
      setFieldValue("number", newVal)
    }
  }

  isError = (
    isError:
      | string
      | string[]
      | FormikErrors<any>
      | FormikErrors<any>[]
      | undefined,
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  ) => {
    return isTouched && !!isError
  }

  handleOrgNumberChange = (e: any, values: any, setFieldValue: any) => {
    if (
      e.target.value.length <=
      10
    ) {
      setFieldValue(
        "org_contact_number",
        e.target.value
      );

      if (
        values.is_same_number
      ) {
        setFieldValue(
          "owner_contact_number",
          e.target.value
        );
      }
    }
  }

  handleOwnerNumberChange = (e: any, setFieldValue: any) => {
    e.target.value.length <= 10 &&
      setFieldValue(
        "owner_contact_number",
        e.target.value
      );
  }

  handleSameNumberChange = (e: any, values: any, setFieldValue: any) => {
    setFieldValue(
      "is_same_number",
      e.target.checked
    );
    setFieldValue(
      "owner_contact_number",
      !e.target.checked
        ? ""
        : values.org_contact_number
    );

  }

  handleOwnerEmailChange = (e: any, values: any, setFieldValue: any) => {
    setFieldValue(
      "owner_address",
      e.target.value
    );
    values.verifiedAddress &&
      (e.target.value !=
        values.verifiedAddress
        ? setFieldValue(
          "isOwnerVerified",
          false
        )
        : setFieldValue(
          "isOwnerVerified",
          true
        ));

  }

  handleOTPChange = (otp: any, setFieldValue: any) => {
    setFieldValue("owner_otp", otp);
    otp.length == 4 &&
      this.ownerverifyOtp(otp)

  }

  handleFileChange = (values: any, banner: any, e: any, setFieldValue: any) => {
    const removerBanner = values.selectedImages.filter(
      (img: any) =>
        //@ts-ignore
        img.name !=
        banner.name
    );
    !e.target.checked
      ? setFieldValue(
        "selectedImages",
        [
          ...values.selectedImages,
          banner,
        ]
      )
      : setFieldValue(
        "selectedImages",
        removerBanner
      );

  }

  handleFileUploadChange = (e: any, values: any, setFieldValue: any) => {
    let uploaded: any = [...values.bannerImages]
    let newUpload:any=[]
    let files = e.currentTarget.files
    files.length > 0 &&
    Array.from(files).some((file: any) => {
      if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
        newUpload.push(file)
      }
    })

    if(e.currentTarget.files.length > 0 && newUpload.length > 0){
      setFieldValue("bannerImages", [
        ...uploaded,
        ...newUpload,
      ])
      setFieldValue("selectedImages", [
          ...values.selectedImages,
          ...newUpload,
        ])
    }
  }

  gettingIcons = (farmDetails: any) => {
    return (farmDetails?.icons.join().split(',')) || []
  }
}
// Customizable Area End