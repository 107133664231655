// Customizable Area Start
import React from "react";
import { cookingCapacity, manual, orderPrice } from "./assets";
import "../../../web/src/assets/css/style.scss";
import "../assets/css/adminRestDishes.scss";
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import {
  Grid,
  CardContent,
  Typography,
  Card,
  InputAdornment,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
  Box
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import "../../../blocks/catalogue/assets/css/farmDetails.scss";
import "../assets/css/adminRestaurantDetails.scss";
import "../../../web/src/assets/css/content/card.scss";
import { ErrorMessage, FieldArray,  Form, Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import AdminRestaurantDeliveryController, { Props } from "./AdminRestaurantDeliveryController.web";
// Customizable Area End

class AdminRestaurantRegisterInfo extends AdminRestaurantDeliveryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { deliveryData, cardIndex, orderPriceRules, isLoading, loading, deleteId } = this.state
    const timeSettings = deliveryData?.attributes
    const isSubmitDisabled = loading || isLoading

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const mins = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
    const shouldDisplayOrderVolume = cardIndex === 1 || cardIndex === 2;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            hour: timeSettings?.quote_time_hour || 0,
            min: timeSettings?.quote_time_minute || 0,
            orderVolume: timeSettings?.order_volume || 0,
            onlineOrdering: !!timeSettings?.online_ordering,
            orderPriceRules: orderPriceRules || []
          }}
          validationSchema={this.Schema()}
          onSubmit={this.handleDeliverySettingSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => {
            const switchClass = values?.onlineOrdering ? "greenSwitch" : "redSwitch";
            return (
              <Form noValidate autoComplete="off">
                <div className="restDetailsContainer">
                  <div className="restDetailsHeader">
                    <h4>Delivery Time Setting</h4>

                    <div className="saveBtn">
                      <CustomButton
                        data-test-id="saveChangesBtn"
                        disabled={isSubmitDisabled}
                        isLoading={loading}
                        label="Save Changes"
                        type="submit"
                      />
                    </div>
                  </div>

                  {isLoading ? (
                    <Box className="loader_container">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <div className="restDetailsCardsContainer">
                        <Grid
                          container
                          justifyContent="center"
                          spacing={5}
                          className="cardContainer"
                          alignItems="stretch"
                        >
                          <Grid
                          data-test-id="manual"
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            onClick={() => this.restDetailsCardShow(0, "manual")}
                            className="gridCells"
                          >
                            <Card
                              className={`card restDetailsCard ${this.isActiveSetting(0)}`}
                            >
                              <CardContent className="cardContent">
                                <div className="header">
                                  <div className="headerIcon">
                                    <img src={manual} className="deliverySettIcon" />
                                  </div>
                                  <Typography className="h6">Manual</Typography>
                                </div>
                                <div className="description">
                                  <Typography className="p">
                                    Take fixed quote time for takeout{" "}
                                  </Typography>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>

                          <Grid
                            data-test-id="cookingCapacity"
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            onClick={() => this.restDetailsCardShow(1, "cooking_capacity")}
                            className="gridCells"
                          >
                            <Card
                              className={`card restDetailsCard ${this.isActiveSetting(1)}`}
                            >
                              <CardContent className="cardContent">
                                <div className="header">
                                  <div className="headerIcon">
                                    <img src={cookingCapacity} className="deliverySettIcon" />
                                  </div>
                                  <Typography className="h6">Cooking Capacity</Typography>
                                </div>
                                <div className="description">
                                  <Typography className="p">
                                    Automated quote times by how quickly orders are made{" "}
                                  </Typography>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>

                          <Grid
                          data-test-id="orderPrice"
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            onClick={() => this.restDetailsCardShow(2, "order_price")}
                            className="gridCells"
                          >
                            <Card
                              className={`card restDetailsCard ${this.isActiveSetting(2)}`}
                            >
                              <CardContent className="cardContent">
                                <div className="header">
                                  <div className="headerIcon">
                                    <img src={orderPrice} className="deliverySettIcon" />
                                  </div>
                                  <Typography className="h6">Order Price</Typography>
                                </div>

                                <div className="description">
                                  <Typography className="p">
                                    Set Quote time based on total price of order{" "}
                                  </Typography>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="quoteTimeContainer">
                        <h6>Quote Time</h6>
                        <div className="quoteTime">
                          <h6>Takeout</h6>
                          <div className="timingSelectContainer">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6}>

                                <div className="outlineSelect">
                                  <div className="customSelect dishListStatus timeAlign">
                                    <FormControl>
                                      <Select
                                      data-test-id="hour"
                                        name="hour"
                                        value={values.hour}
                                        onChange={handleChange}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          classes: {
                                            paper: classes.listStatus,
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        endAdornment={
                                          <InputAdornment
                                            position="end"
                                            style={{ position: "absolute", right: "70px" }}
                                          >
                                            Hr
                                          </InputAdornment>
                                        }
                                        error={touched.hour && Boolean(errors.hour)}
                                      >
                                        <MenuItem disabled value="">
                                          Select Hour
                                        </MenuItem>
                                        {hours.map((i: any) => {
                                          return <MenuItem key={i} value={i}>{i}</MenuItem>;
                                        })}
                                      </Select>
                                    </FormControl>
                                    <span className="errorMsg timeError">
                                      <ErrorMessage name="hour" />
                                    </span>
                                  </div>
                                </div>
                              </Grid>

                              <Grid item xs={6} sm={6}>
                                <div className="outlineSelect">
                                  <div className="customSelect hourSelect dishListStatus timeAlign">
                                    <FormControl>
                                      <Select
                                      data-test-id="min"
                                        name="min"
                                        value={values.min}
                                        onChange={handleChange}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                          },
                                          transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                          },
                                          classes: {
                                            paper: classes.listStatus,
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        endAdornment={
                                          <InputAdornment
                                            position="end"
                                            style={{ position: "absolute", right: "50px" }}
                                          >
                                            Min
                                          </InputAdornment>
                                        }
                                        error={touched.min && Boolean(errors.min)}
                                      >
                                        <MenuItem disabled value="">
                                          Select Minutes
                                        </MenuItem>
                                        {mins.map((i: any) => {
                                          return <MenuItem key={i} value={i}>{i}</MenuItem>;
                                        })}
                                      </Select>
                                    </FormControl>
                                    <span className="errorMsg timeError">
                                      <ErrorMessage name="min" />
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div></div>
                        </div>
                        <div className="quoteBorder"></div>
                      </div>
                      {shouldDisplayOrderVolume && (
                        <>
                          <div className="orderVolumeCOntainer">
                            <h6 data-test-id="orderVolume">Order Volume</h6>

                            <div className="header">
                              <span className="orderVol">We can deliver</span>
                              <span className="outlineTextfield boxRadiusBorder readOnlyField">
                                <CustomTextfield
                                  className="volumeField"
                                  name="orderVolume"
                                  value={values.orderVolume}
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: false,
                                  }}
                                />
                              </span>
                              <span style={{ color: "#000" }}>orders in 15 min</span>
                              <span className="errorMsg volError">
                                <ErrorMessage name="orderVolume" />
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      {cardIndex === 2 && (
                        <>
                          <div className="orderDetailsContainer">
                            <h6 data-test-id="orderPrice">Order Price</h6>
                            <p className="orderPriceText">
                              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla
                              suscipit consectetur facere aspernatur sunt, itaque tenetur quas
                              error voluptatibus! Quibusdam est numquam modi tempore obcaecati
                              ex odio consequatur quidem iusto.
                            </p>

                            <FieldArray
                              name="orderPriceRules"
                              render={(arrayHelpers: any) => {
                                const orderPriceRules = values.orderPriceRules

                                return (
                                  <>
                                    <div className="greenOutlineButton">
                                      <CustomButton
                                        data-test-id="addPriceRulesBtn"
                                        label="Add order Price rules"
                                        onClick={() => arrayHelpers.push({
                                          id: `local${values.orderPriceRules.length}`,
                                          orderPrice: '',
                                          orderHour: '0',
                                          orderMin: '1'
                                        })}
                                      />
                                    </div>

                                    {
                                      values.orderPriceRules?.length > 0 && (
                                        <p className="orderPriceText">
                                          Please add price and time to add order price rule
                                        </p>
                                      )
                                    }

                                    {
                                      orderPriceRules?.length > 0
                                        ? orderPriceRules.map((orderRule: any, index: number) => {
                                          const fieldName = `orderPriceRules.${index}`
                                          const {
                                            id,
                                            orderPrice,
                                            orderHour,
                                            orderMin
                                          } = orderRule

                                          return (
                                            <Grid key={id} container spacing={2} className="orderSlot" alignItems="center" justifyContent="center">
                                              <Grid item xs={6} sm={3} lg={2}>
                                                <span className="orderText">If an order is over</span>
                                              </Grid>
                                              <Grid item xs={5} sm={3} lg={2}>
                                                <span className="outlineTextfield boxRadiusBorder">
                                                  <CustomTextfield
                                                    data-test-id="price"
                                                    name={`${fieldName}.orderPrice`}
                                                    value={orderPrice}
                                                    onChange={(e: any) => this.handleOrderValueChange(id, e.target.value, "orderPrice", values, setFieldValue)}
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <AttachMoneyIcon fontSize="small" />
                                                        </InputAdornment>
                                                      )
                                                    }}
                                                  />
                                                </span>
                                                <ErrorMessage component="span" className="custom_formik_error" name={`${fieldName}.orderPrice`} />
                                              </Grid>
                                              <Grid item xs={2} md={1}>
                                                <span className="orderText" style={{ justifyContent: 'center' }}>add</span>
                                              </Grid>
                                              <Grid container spacing={2} xs={10} sm={6} lg={3}>
                                                <Grid item xs={6}>
                                                  <span
                                                    className="outlineSelect"
                                                  >
                                                    <div className="customSelect dishListStatus">
                                                      <FormControl>
                                                        <Select
                                                        data-test-id="ruleHour"
                                                          name={`${fieldName}.orderHour`}
                                                          value={orderHour}
                                                          onChange={(e: any) => this.handleOrderValueChange(id, e.target.value, "orderHour", values, setFieldValue)}
                                                          MenuProps={{
                                                            anchorOrigin: {
                                                              vertical: "bottom",
                                                              horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                              vertical: "top",
                                                              horizontal: "left",
                                                            },
                                                            classes: {
                                                              paper: classes.listStatus,
                                                            },
                                                            getContentAnchorEl: null,
                                                          }}
                                                          endAdornment={
                                                            <InputAdornment
                                                              position="end"
                                                              style={{
                                                                position: "absolute",
                                                                right: "30px",
                                                                color: "#000",
                                                              }}
                                                            >
                                                              Hr
                                                            </InputAdornment>
                                                          }
                                                        >
                                                          <MenuItem disabled value="">
                                                            Select Hour
                                                          </MenuItem>
                                                          {hours.map((i: number) => {
                                                            return <MenuItem key={i} value={i}>{i}</MenuItem>;
                                                          })}
                                                        </Select>
                                                      </FormControl>
                                                    </div>
                                                  </span>
                                                  <ErrorMessage component="span" className="custom_formik_error" name={`${fieldName}.orderHour`} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <span
                                                    className="outlineSelect"
                                                  >
                                                    <div className="customSelect dishListStatus">
                                                      <FormControl>
                                                        <Select
                                                        data-test-id="ruleMin"
                                                          name={`${fieldName}.orderMin`}
                                                          value={orderMin}
                                                          onChange={(e: any) => this.handleOrderValueChange(id, e.target.value, "orderMin", values, setFieldValue)}
                                                          MenuProps={{
                                                            anchorOrigin: {
                                                              vertical: "bottom",
                                                              horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                              vertical: "top",
                                                              horizontal: "left",
                                                            },
                                                            classes: {
                                                              paper: classes.listStatus,
                                                            },
                                                            getContentAnchorEl: null,
                                                          }}
                                                          endAdornment={
                                                            <InputAdornment
                                                              position="end"
                                                              style={{
                                                                position: "absolute",
                                                                right: "20px",
                                                                color: "#000",
                                                              }}
                                                            >
                                                              Min
                                                            </InputAdornment>
                                                          }
                                                        >
                                                          <MenuItem disabled value="">
                                                            Select Minutes
                                                          </MenuItem>
                                                          {mins.map((i: string) => {
                                                            return <MenuItem key={i} value={i}>{i}</MenuItem>
                                                          })}
                                                        </Select>
                                                      </FormControl>
                                                    </div>
                                                  </span>
                                                  <ErrorMessage component="span" className="custom_formik_error" name={`${fieldName}.orderMin`} />
                                                </Grid>
                                              </Grid>
                                              <Grid item xs={8} sm={4} lg={3}>
                                                <span className="orderText">to the takeout time</span>
                                              </Grid>
                                              <Grid item xs={4} sm={1} className="linkButtonWrapper">
                                                <span className="linkButton">
                                                  {id === deleteId ?
                                                  <CircularProgress className="image_loader" size={18} />
                                                  :
                                                  <CustomButton
                                                    data-test-id="delete"
                                                    label="Delete"
                                                    onClick={() => this.handleDeleteOrderRule(id, index, values.orderPriceRules, arrayHelpers.remove)}
                                                  />}
                                                </span>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
                                        : null
                                    }
                                  </>
                                )
                              }}
                            />
                          </div>
                        </>
                      )}

                      <div className="orderType" style={{ paddingBottom: "30px" }}>
                        <h6>Online Ordering</h6>

                        <div className="customToggleButton" style={{ marginBottom: "10px" }}>
                          <label className="switch">
                            <input
                              name="onlineOrdering"
                              checked={values?.onlineOrdering}
                              onChange={handleChange}
                              className="switchCheckbox"
                              type="checkbox"
                            />
                            <div className={switchClass} />
                            <div className="switchLabels">
                              <span>ON</span>
                              <span>OFF</span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    )
    // Customizable Area End
  }
}


// Customizable Area Start
export {AdminRestaurantRegisterInfo}
//@ts-ignore
const AdminRestaurantRegisterInfoRouter = withRouter(AdminRestaurantRegisterInfo);
export default withStyles(commonCmptStyles)(AdminRestaurantRegisterInfoRouter);
// Customizable Area End
