import React, { useEffect } from "react";

export default function VideoInput(props: any) {
  const { width, height, fileData, setFieldValue, duration } = props;

  const inputRef = React.useRef();

  //@ts-ignore
  const [source, setSource] = React.useState();

  useEffect(() => {
    const url = fileData;
    setTimeout(() => {
      if (inputRef) {
        if (inputRef.current) {
          //@ts-ignore

         setFieldValue &&  setFieldValue(
            "videoDuration",
            //@ts-ignore
            inputRef.current.duration != NaN
              ? //@ts-ignore
                inputRef.current.duration
              : duration
          );
        }
      }
    }, 1000);
    //@ts-ignore
    setSource(url);
  }, [fileData]);

  return (
    <div className="VideoInput">
      {source && (
        <video
          //@ts-ignore
          ref={inputRef}
          className="VideoInput_video"
          width="100%"
          height={height}
          src={source}
        />
      )}
    </div>
  );
}
