// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
import StorageProvider from "../../../../framework/src/StorageProvider.web"
import { WithStyles } from "@material-ui/core/styles"
import { styles } from "./RewardAcceptanceSelect.web"
const configJSON = require("../config.js")

const DEFAULT_OPTIONS = [0, 2.5, 5, 10]

export interface Props extends WithStyles<typeof styles> {
  name: string
  value: number | null
  handleValueChange: (value: number) => void
}

interface S {
  globalReward: number | null
  rewardAcceptanceOptions: number[]
}

interface SS {
  id: any
}

export default class RewardAcceptanceSelectController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGlobalRewardApiCallId: string = ""

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]

    this.state = {
      globalReward: null,
      rewardAcceptanceOptions: DEFAULT_OPTIONS,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getGlobalRewardApi()
  }

  handleChangeOptionSelection = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target
    this.props.handleValueChange(Number(value))
  }

  getGlobalRewardApi = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getGlobalRewardApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGlobalRewardPointApiEndPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleGlobalRewardApiResponse = (globalPercentage: number) => {
    const isValidOption =
      globalPercentage && !DEFAULT_OPTIONS.includes(globalPercentage)
    if (isValidOption) {
      const { value, handleValueChange } = this.props

      const newOptions = [...DEFAULT_OPTIONS, globalPercentage].sort(
        (a, b) => a - b
      )
      this.setState({ rewardAcceptanceOptions: newOptions })
      if (!value) handleValueChange(globalPercentage)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (responseJson && !responseJson.errors) {
        if (apiCallId === this.getGlobalRewardApiCallId) {
          this.handleGlobalRewardApiResponse(
            responseJson.points_acceptance_percentage
          )
        }
      }
    }
  }
  // Customizable Area End
}
