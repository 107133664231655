// Customizable Area Start
import React from "react"
import { Container, Grid, Box, Button, CircularProgress } from "@material-ui/core"
import "../assets/css/landingpageloggedin.scss"
import LPLatestUpdates from "./LPComponents/LPLatestUpdates.web"
import LPLikedFarms from "./LPComponents/LPLikedFarms.web"
import LPFavRestaurants from "./LPComponents/LPFavRestaurants.web"
import LPFavCourses from "./LPComponents/LPFavCourses.web"
import LPBottomSection from "./LPComponents/LPBottomSection.web"
import LandingPageLoginController, {
    Props,
} from "./LandingPageLoginController.web"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { setLPData } from "../assets"
// Customizable Area End

class LandingPageLogin extends LandingPageLoginController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        // Customizable Area Start
        const { loader } = this.state
        const { userInfo, LPIsReOrder, LPBodyDesc } = this.props
        const firstName = userInfo?.userDetails?.first_name

        return (
            <>
                {loader ? (
                    <Box className="llp_loader_container"><CircularProgress size={40} /></Box>
                ) : (
                    <Box className="landing_page_container">
                        <Box className="landing_page_section">
                            <Container maxWidth="lg">
                                <Grid container alignItems="center">
                                    {!!firstName && (
                                        <Grid item xs={7} md={6}>
                                            <Box className="user_details_section">
                                                <h1 className="user_name">
                                                    Hi, <span className="user_name_value">{firstName}</span>
                                                </h1>
                                            </Box>
                                        </Grid>
                                    )}
                                    {LPIsReOrder && <Grid item xs={5} md={6}>
                                        <Box className="landing_page_reorder">
                                            <Button
                                                onClick={() => this.handleReOrderRedirect()}
                                                classes={{ root: "green-btn", label: "btn-label" }}
                                            >
                                                REORDER
                                            </Button>
                                        </Box>
                                    </Grid>}
                                </Grid>

                                <LPLatestUpdates />
                            </Container>
                        </Box>
                        <Box className="landing_page_section">
                            <LPLikedFarms />
                        </Box>
                        <Box className="landing_page_section landing_page_EHF">
                            <LPFavRestaurants />
                        </Box>
                        <Box className="landing_page_section landing_page_color_container">
                        <Container fixed>
                            <LPFavCourses />
                        </Container>
                        </Box>
                        <Box className="green_mid_banner">
                            <Grid container justifyContent="center">
                                <Grid item xs={10} sm={8} md={6}>
                                    <p>{LPBodyDesc}</p>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="landing_page_section landing_page_color_container">
                            <LPBottomSection />
                        </Box>
                    </Box>
                )}
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
    return {
        userInfo: state.user,
        LPIsReOrder: state.cms.LPIsReOrder,
        LPLatestUpdatesDesc: state.cms.LPLatestUpdatesDesc,
        LPBodyDesc: state.cms.LPBodyDesc
    }
}

export default withRouter(connect(mapStateToProps, { setLPData })(LandingPageLogin))
// Customizable Area End
