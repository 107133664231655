// Customizable Area Start
import React, { PropsWithChildren } from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const useStyles = makeStyles(() => ({
  contentBox: {
    padding: "60px 0px",
    borderBottom: "2px solid #f3f3f3",
  },
  inlineLoaderWrapper: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  carouselWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    textAlign: 'center'
  },
  carouselDotsWrapper: {
    marginTop: "70px",
    display: "flex",
    justifyContent: "center",
  },
  whatIsWTTHeading: {
    marginBottom: "30px",
  },
  auVerticalImagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "25px",
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "25px",
  },
  paragraphWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "60px",
  },
  farmBtn: {
    marginTop: "35px",
  },
}))

const CustomSkeleton = (props: any) => <Skeleton {...props} borderRadius={12} />

const InlineWrapperWithMargin = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles()
  return <span className={classes.inlineLoaderWrapper}>{children}</span>
}

const CarouselLoader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.contentBox}>
      <Box className={classes.carouselWrapper}>
        <CustomSkeleton width="20%" height={50} />
        <CustomSkeleton width="75%" height={60} />
        <Skeleton width="22%" borderRadius={35} height={50} />
      </Box>
      <Box className={classes.carouselDotsWrapper}>
        <Skeleton
          inline
          count={3}
          wrapper={InlineWrapperWithMargin}
          borderRadius={50}
          width={15}
          height={15}
        />
      </Box>
    </Box>
  )
}

const WhatIsWTTLoader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.contentBox}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={10} sm={10} md={6}>
            <Box className={classes.whatIsWTTHeading}>
              <CustomSkeleton height={50} />
            </Box>
          </Grid>
          <Grid item xs={12} container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <CustomSkeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSkeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSkeleton height={400} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const AboutUsLoader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.contentBox}>
      <Container>
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid item xs={6}>
              <CustomSkeleton height={400} />
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.auVerticalImagesWrapper}>
                <CustomSkeleton height={130} />
                <CustomSkeleton height={400} />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="flex-end">
            <Grid item xs={10}>
              <CustomSkeleton width="25%" height={20} />
              <Box className={classes.headingWrapper}>
                <CustomSkeleton width="80%" height={30} />
                <CustomSkeleton width="60%" height={30} />
              </Box>
              <Box className={classes.paragraphWrapper}>
                <CustomSkeleton width="80%" height={15} />
                <CustomSkeleton width="80%" height={15} />
                <CustomSkeleton width="80%" height={15} />
                <CustomSkeleton width="80%" height={15} />
                <CustomSkeleton width="80%" height={15} />
                <CustomSkeleton width="80%" height={15} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const FarmingLoader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.contentBox}>
      <Container>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={10} md={6}>
            <CustomSkeleton width="25%" height={20} />
            <Box className={classes.headingWrapper}>
              <CustomSkeleton width="80%" height={30} />
              <CustomSkeleton width="60%" height={30} />
            </Box>
            <Box className={classes.paragraphWrapper}>
              <CustomSkeleton width="80%" height={15} />
              <CustomSkeleton width="80%" height={15} />
              <CustomSkeleton width="80%" height={15} />
              <CustomSkeleton width="80%" height={15} />
              <CustomSkeleton width="80%" height={15} />
              <CustomSkeleton width="80%" height={15} />
            </Box>
            <Box className={classes.farmBtn}>
              <Skeleton width="35%" borderRadius={50} height={40} />
            </Box>
          </Grid>
          <Grid container spacing={3} item xs={12} md={6}>
            <Grid item xs={12} sm={6}>
              <CustomSkeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSkeleton height={400} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const WLLPSkeletonLoader = () => {
  return (
    <Box>
      <CarouselLoader />
      <WhatIsWTTLoader />
      <AboutUsLoader />
      <FarmingLoader />
    </Box>
  )
}

export default WLLPSkeletonLoader
// Customizable Area End
