// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import CommunityDiscussionListController, {
    Props, IDiscussion
} from "./CommunityDiscussionListController.web"
import { connect } from "react-redux"
import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import CommunityDiscussionCard from "./CommunityDiscussionCard.web"
import { setCommunityDiscussions } from "../../assets"
import "../../assets/css/community/communityDiscussionList.scss"

class CommunityDiscussionList extends CommunityDiscussionListController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { loader, loadMoreLoader, currentPage, totalPages } = this.state
        const { discussions } = this.props

        return (
            <Box>
                {loader ? (
                    <Box className="cdl_loader_container">
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    <Box className="cdl_main_container">
                        {discussions?.length > 0 ?
                            <>
                                {discussions?.map((discussion: IDiscussion) => {
                                    const {
                                        id,
                                        attributes: {
                                            author_name,
                                            date,
                                            comment_count,
                                            discussion_heading,
                                            description,
                                            forum_comments,
                                            follows,
                                        },
                                    } = discussion

                                    return (
                                        <CommunityDiscussionCard
                                            key={id}
                                            discussionId={id}
                                            heading={discussion_heading}
                                            description={description}
                                            authorName={author_name}
                                            startedDate={date}
                                            isFollowed={follows}
                                            commentCount={comment_count}
                                            comments={forum_comments}
                                        />
                                    )
                                })}
                                {totalPages > currentPage && (
                                    <Box display="flex" justifyContent="center">
                                        <Button className="cdl_lod_more_btn" disabled={loadMoreLoader} onClick={this.increasePageNumber}>
                                            {loadMoreLoader ? <CircularProgress size={26} color="inherit" /> : 'Load More'}
                                        </Button>
                                    </Box>
                                )}
                            </>

                            :
                            <Box className="cdl_empty_list_container">
                                <Typography>
                                    No new discussions have been added for this topic
                                </Typography>
                            </Box>
                        }
                    </Box>

                )}
            </Box>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        activeTopicId: state.community.activeTopicId,
        discussionFilter: state.community.discussionFilter,
        discussions: state.community.discussions
    }
}

export default withRouter(
    connect(mapStateToProps, { setCommunityDiscussions })(CommunityDiscussionList)
)
// Customizable Area End
