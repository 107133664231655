// Customizable Area Start
import React from "react";
import { Box, Dialog, Typography, withStyles } from "@material-ui/core";
import { chat_icon,like_icon, like_fill_icon } from "../assets";
import "../assets/css/receiptcard.scss";
import "../../../../web/src/assets/css/constants/base/global.scss";
import FarmForumWeb from "../farmForum/farmForum.web";
import ReceiptCardWebController, {configJSON} from "./ReceiptCardWebController.web";
import LoginSignUpDialogWeb from "../langinpageLoggedIn/LoginSignUpDialog.web";

const styles = {
  date : {
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  },
  heading : {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 600,
    color: '#4cb479',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  likeCount : {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '8px',
    marginRight: '3px',
    color: '#000',
    paddingTop: '2px',
    fontFamily: 'Montserrat',
  },
  likeCommentText : {
    fontSize: '13px',
    fontWeight: '500 !important',
    paddingTop: '2px',
    fontFamily : 'Montserrat'
  }
}
// Customizable Area End

class ReceiptCardWeb extends ReceiptCardWebController {

  render() {
    // Customizable Area Start
    const { info, onCommentUpdate, classes } = this.props
    const { openModal, isLoginDialog, forumDetails } = this.state

    return (
      <>
        <Box
          className="farm_catelog_receipt_card"
          onClick={() => this.openForumDialog(info)}
        >
          <Box className="farm_catelog_img_wrapper">
            <Box>
              <img src={info?.attributes?.banner?.url} alt="CarrotImg" />
            </Box>
            <Box className="farm_Catelog_receipt_date">
              <Typography className={classes.date}>{info?.attributes?.created_at}</Typography>
            </Box>
          </Box>
          <Box className="farm_catelog_receipt_contents">
            <Typography variant="h5">
              {
                this.capitalizeValue(info?.attributes?.heading)
              }
            </Typography>
            <Box className="farm_catelog_likes_and_comments_section">
              <Box className="farm_catelogs_likes_comment_div">
                <Box
                  className="forum_like_wrapper"
                  onClick={this.handleLikeChange}
                >
                  <img
                    src={info?.attributes?.is_like ? like_fill_icon : like_icon}
                    height={20}
                    alt="comment_con"
                  />
                  <Typography className={classes.likeCount}>{
                    info?.attributes?.likes
                  }</Typography>
                  <Typography className={classes.likeCommentText}>{info?.attributes?.likes > 1 ? configJSON.likesText : configJSON.likeText}</Typography>
                </Box>
              </Box>
              <Box className="farm_catelogs_likes_comment_div">
                <img src={chat_icon} height={20} alt="comment_con" />
                <Typography className={classes.likeCount}>{
                  info?.attributes?.comments
                }</Typography>
                <Typography className={classes.likeCommentText}>{info?.attributes?.comments > 1 ? configJSON.commentsText : configJSON.commentText}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={openModal}
          onClose={() => this.closeForumDialog()}
          fullWidth
          maxWidth="md"
          classes={{ paperWidthMd: "farm_forum_dialog" }}
        >
          <FarmForumWeb
            forumDetails={forumDetails}
            onClose={this.closeForumDialog}
            handleLike={this.handleLikeChange}
            onCommentUpdate={onCommentUpdate}
          />
        </Dialog>
        <LoginSignUpDialogWeb
           isOpen={isLoginDialog}
           onClose={this.closeLoginDialog}
           onLoginSuccess={this.handleLoginSuccess}
        />
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(ReceiptCardWeb);
// Customizable Area End
