// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteComponentProps } from "react-router-dom";
import { WithStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  getTokenFromURL,
  isTokenExpired
} from "../../../components/src/utility/helper";
import { AppRoutings } from "../../../components/src/utility/app-routing";
import { styles } from "./SquareConnectRedirect.web";
const configJSON = require("./config.js");

export interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  history: any;
}

interface S {}

interface SS {
  id: any;
}

export default class SquareConnectRedirectController extends BlockComponent<
  Props,
  S,
  SS
> {
  postSquareTokenApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {};

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.handleSquareToken();
  }

  homePageRedirect = () => {
    this.props.history.push(AppRoutings.Home);
  };

  handleSquareToken = () => {
    const squareError = getTokenFromURL(configJSON.SQUARE_ERROR_KEY);
    if (squareError) {
      this.handleAccessDeniedError();
      return;
    }

    const squareToken = getTokenFromURL(configJSON.SQUARE_TOKEN_KEY);
    if (!squareToken) {
      this.handleNullTokenValue();
      return;
    }

    this.postSquareTokenApi(squareToken);
  };

  handleNullTokenValue = () => {
    toast.error(configJSON.SOMETHING_WENT_WRONG_MESSAGE);
    this.homePageRedirect();
  };

  handleAccessDeniedError = () => {
    toast.error(configJSON.ACCESS_DENIED_ERROR_MESSAGE);
    this.homePageRedirect();
  };

  postSquareTokenApi = async (squareToken: string) => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const httpBody = {
      code: squareToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSquareTokenApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.squareTokenApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.postSquareTokenApiCallId) {
      if (responseJson.success) {
        toast.success(configJSON.SQUARE_CONNECT_SUCCESS_MESSAGE);
        this.homePageRedirect();
      } else {
        this.handleNullTokenValue();
      }
    }
  };

  handleErrorApiResponse = (apiRequestCallId: string, _: any) => {
    if (apiRequestCallId === this.postSquareTokenApiCallId) {
      this.handleNullTokenValue();
    }
  };

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson);
      } else {
        if (await isTokenExpired(responseJson)) {
          return;
        }
        this.handleErrorApiResponse(apiRequestCallId, responseJson);
      }
    }
  }
}
// Customizable Area End
