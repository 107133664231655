// Customizable Area Start
import React from "react";
import { Apple_Icon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import "../../../web/src/assets/css/content/select.scss";
import "../assets/css/adminRestDishes.scss";
import { commonCmptStyles } from "./dropDownCss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search"
import AdminRestaurantDishesController, {
  Props,
} from "./AdminRestaurantDishesController.web";
import CustomCheckboxField from "../../../components/src/CustomCheckboxField.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
const configJSON = require("./config")
// Customizable Area End

// Customizable Area Start
interface EnhancedTableProps {
  numSelected: number;
  orderBy?: string;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell>
          <div className="productHead">
            <CustomCheckboxField
            data-test-id="table-head"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              id={-1}
            />
            <span>{configJSON.dishNameLabel}</span>
          </div>
        </TableCell>
        <TableCell align="center">{configJSON.statusLabel}</TableCell>
        <TableCell align="center">{configJSON.idLabel}</TableCell>
        <TableCell align="center">{configJSON.priceText}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End

class AdminRestaurantDishes extends AdminRestaurantDishesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {
      dishesCurrentPage,
      dishesPerPage,
      totalDishesCount,
      totalDishesPage,
      activeRestaurant,
      activeDishSorting,
      activeDishType,
      dishCategoryList,
      dishListLoader,
      restaurantsList,
      dishSearchValue,
      dishList,
      selectedDishes,
      openDialog
    } = this.state
    const startingIndex = (dishesCurrentPage - 1) * dishesPerPage + 1
    const { classes } = this.props;
    const sorting = configJSON.sortingType;

    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown adminRestDishesContainer">
          {/* filter section */}
          <div className="headerListContainer">
            <div className="prodListHeader">
              <div className="blocks">
                <h4 data-test-id="dishesLabel">{configJSON.dishesLabel}</h4>
                <div className="block1">
                  <div className="customSelect dishesType farmOrdersDropDown dishCategorySelect">
                    <FormControl>
                      <Select
                        data-test-id="dishType"
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={activeDishType || configJSON.defaultText}
                        onChange={this.handleChangeDishType}
                        className="menu-customize"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown
                          }
                        }}
                        autoWidth={true}
                      >
                        <MenuItem value={configJSON.defaultText}>
                          <span>{configJSON.allDishesText}</span>
                          <span className="checkmarkDishes">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {dishCategoryList?.map((category: { id: number, name: string }) => {

                          const { id, name } = category

                          return (
                            <MenuItem
                              key={id}
                              value={name}>
                              <span>{name}</span>
                              <span className="checkmarkDishes">
                                <div className="checkmark_circle" />
                                <div className="checkmark_stem" />
                                <div className="checkmark_kick" />
                              </span>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="customSelect restForum farmOrdersDropDown restaurantDropDown">
                    <FormControl>
                      <Select
                        data-test-id="restList"
                        value={activeRestaurant}
                        disabled={dishListLoader}
                        onChange={this.handleActiveResChange}
                        className="menu-customize"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.farmOrdersDropDown
                          }
                        }}
                      >
                        {restaurantsList?.length === 0 && <MenuItem
                          className="dish_type_menus"
                          disabled
                          value={0}><span>{configJSON.selectRestaurantText}</span>
                          <span className="checkmarkRest">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>}
                        {restaurantsList?.map((restaurant: any) => {
                          const { id, name } = restaurant.attributes
                          return (
                            <MenuItem key={id} className="dish_type_menus" value={id}>
                              <span>{name}</span>
                              <span className="checkmarkRest">
                                  <div className="checkmark_circle" />
                                  <div className="checkmark_stem" />
                                  <div className="checkmark_kick" />
                                </span>
                              </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="block1">
                    <div className="customSelect dishSortSelect">
                      <FormControl>
                        <Select
                          data-test-id="sortValue"
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="menu-customize"
                          value={activeDishSorting}
                          onChange={this.handleChangeDishSorting}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: configJSON.bottomText,
                              horizontal: configJSON.centerText
                            },
                            transformOrigin: {
                              vertical: configJSON.topText,
                              horizontal: configJSON.centerText
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: classes.sortDropDown +
                               " " +
                              classes.commonSelectui,
                            }
                          }}
                          autoWidth={true}
                        >
                          {sorting.map((sort: any, index: any) => {
                            return (
                              <MenuItem key={sort} className="dishSorting" value={sort}>
                                {sort}
                                <span className="checkmark">
                                  <div className="checkmark_circle" />
                                  <div className="checkmark_stem" />
                                  <div className="checkmark_kick" />
                                </span>{" "}
                              </MenuItem>
                            );
                          })}
                          
                        </Select>
                      </FormControl>
                    </div>
                  {/* </div> */}
                </div>
                <div className="block1">
                  <div className="addButton" >
                    <CustomButton
                      data-test-id="createDish"
                      label={configJSON.createDishText}
                      disabled={!activeRestaurant}
                      onClick={() => this.handleDishActionRouting(activeRestaurant)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield">
                <CustomTextfield
                  data-test-id="searchVal"
                  value={dishSearchValue}
                  onChange={this.handleChangeDishSearching}
                  onKeyUp={this.handleSearch}
                  placeholder={configJSON.restDishesPlaceholder}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="searchIcon" />
                      </InputAdornment>
                    ),
                  }} />
              </div>
              <div className="block1">
                <div className="outlineSelect">
                  <div className="customSelect commonOrdersDropDown">
                    <FormControl>
                      <Select
                        data-test-id="batchStatus"
                        className="menu-customize"
                        value={configJSON.defaultText}
                        disabled={selectedDishes.length === 0}
                        onChange={this.changeBatchDishStatus}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: configJSON.bottomText,
                            horizontal: configJSON.leftText
                          },
                          transformOrigin: {
                            vertical: configJSON.topText,
                            horizontal: configJSON.leftText
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.categoryDropDown,
                          }
                        }}
                      >
                        <MenuItem
                          className="dish_list_filter_status_menus"
                          value={configJSON.defaultText}
                          hidden
                          disabled>
                          {configJSON.actionText}
                        </MenuItem>
                        {configJSON.statusMenus.map((menu: { label: string, value: string }) => {
                          const { label, value } = menu
                          return (
                            <MenuItem key={label}
                              className="dish_list_filter_status_menus"
                              value={value}>
                              {label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block restDishesTable">
            {dishListLoader ?
              <Box className="loader_container">
                <CircularProgress />
              </Box> :
              <>
                {dishList.length === 0 ? (
                  <Box className="no_dishes_container">
                    <h4>{configJSON.noDishesListedText}</h4>
                    <p>{configJSON.uploadDishesText}<br />&nbsp;&nbsp;{configJSON.createDishBtnText}</p>
                    <div className="addButton" >
                      <CustomButton
                        data-test-id="createDishBtn"
                        label={configJSON.createDishText}
                        disabled={!activeRestaurant}
                        onClick={() => this.handleDishActionRouting(activeRestaurant)} />
                    </div>
                  </Box>
                ) :
                  <>
                    <TableContainer component={Paper} className="restDishesTable">
                      <Table aria-label="simple table" stickyHeader>
                        <EnhancedTableHead
                          data-test-id="table"
                          numSelected={selectedDishes.length}
                          onSelectAllClick={this.handleSelectAllClick}
                          rowCount={dishList.length}
                        />
                        <TableBody>
                          {dishList.map((item: any) => {

                            const { name, status, price, images } = item.attributes

                            const isItemSelected = this.isSelectedDish(item.id);

                            return (
                              <TableRow key={item.id} >
                                <TableCell component={"th" as any} scope="row" align="center">
                                  <div className="productCell">
                                    <CustomCheckboxField
                                      data-test-id="dishRow"
                                      id={item.id}
                                      checked={isItemSelected}
                                      onChange={this.handleClickDishRow.bind(this,item.id)}
                                    />
                                    <img
                                      src={images[0]?.url || Apple_Icon}
                                      className="prodImage"
                                      alt="Apple_Icon"
                                    />
                                    <h5 data-test-id="dishName">{name}</h5>
                                  </div>
                                </TableCell>

                                <TableCell align="center">
                                  <div className="customSelect itemStatus">
                                    <FormControl>
                                      <Select
                                        data-test-id="updateStatus"
                                        value={status}
                                        onChange={this.updateDishStatus.bind(this,item.id)}
                                        className={this.getMenuStyle(status)}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: configJSON.bottomText,
                                            horizontal: configJSON.centerText
                                          },
                                          transformOrigin: {
                                            vertical: configJSON.topText,
                                            horizontal: configJSON.centerText
                                          },
                                          classes: {
                                            paper: classes.resDishStatus,
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                      >
                                        <MenuItem className="dish_list_status_menus" value="active">{configJSON.activeLabel}</MenuItem>
                                        <MenuItem className="dish_list_status_menus de_active_menu" value="deactive">{configJSON.deactivateText}</MenuItem>
                                        <MenuItem className="dish_list_status_menus draft_menu" value="draft">{configJSON.draftLabel}</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </TableCell>
                                <TableCell align="center">{item.attributes.dish_number}</TableCell>
                                <TableCell align="center" className="BoldCell">${price}</TableCell>
                                <TableCell align="center">
                                  <div className="option-dropDown">
                                    <BasicMenu
                                    data-test-id="basic-menu"
                                      item={item.id}
                                      menu={configJSON.options}
                                      handleMenuClick={this.handleMenuClick} />
                                  </div>
                                </TableCell>
                              </TableRow>)
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="paginationRow restOrderPagination">
                      <div data-test-id="listInfo">
                        {startingIndex}-{startingIndex + dishList.length - 1} of {totalDishesCount} {configJSON.itemsText}
                      </div>
                      <CustomPagination
                      data-test-id="pagination"
                        count={totalDishesPage}
                        page={dishesCurrentPage}
                        pagePerView={dishesPerPage}
                        handlePageChange={this.handleChangeTablePage}
                        shape={configJSON.roundedText}
                        siblingCount={1}
                        increasePagePerView={this.handleIncreasePageView}
                        decreasePagePerView={this.handleDecreasePageView}
                        boundaryCount={1} />
                    </div>
                  </>}
              </>}
          </div>

          <div>
            <Dialog
              open={openDialog}
              onClose={this.handleDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {configJSON.removeDishText}
              </DialogTitle>
              <DialogActions>

                <span className="redContained customCancelBtn">
                  <CustomButton
                    color="secondary"
                    label={configJSON.noText}
                    onClick={this.handleDialog}
                  />
                </span>
                <span className="greenContained">
                  <CustomButton
                  data-test-id="yesBtn"
                    color="secondary"
                    label={configJSON.yesText}
                    onClick={this.removeDishApi
                    }
                    autoFocus
                  />
                </span>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ends: main card container */}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export {AdminRestaurantDishes}
export default withRouter(withStyles(commonCmptStyles)(AdminRestaurantDishes));
// Customizable Area End
