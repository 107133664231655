import * as React from 'react';
import { Component } from 'react';
import './assets/css/WhiteBackgroundContainer.scss'


class WhiteBackgroundContainer extends Component {
    render() {
        return (<><div className="all_white_background_wrapper">
            {this.props.children}</div></>);
    }
}

export default WhiteBackgroundContainer;