Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.httpGetMethod = "GET"
exports.httpPatchMethod = "PATCH"

exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

//login
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";

//get remaining roles
exports.AUTH_TOKEN = "authToken"
exports.CARTABLE_TYPE_FARM = "BxBlockCatalogue::Farm"
exports.CARTABLE_TYPE_RESTAURANT = "BxBlockCatalogue::Restaurant"
exports.CARTABLE_TYPE_COURSE = "BxBlockLibrary::Course"
exports.apiMethodGetRoles = "GET";
exports.getRolesApiEndPoint = "account_block/accounts/remaining_roles";
exports.wllpApiEndPoint = "bx_block_dashboard/landing_pages/without_login_landing_page"
exports.llpApiEndPoint = "bx_block_dashboard/landing_pages"
exports.toggleFavouriteApiEndPoint= "bx_block_favourites/favourites/favourites"
// Customizable Area End
