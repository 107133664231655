// Customizable Area Start
import React, { useState } from "react"
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/CloseRounded"
import {
  Box,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core"
import CustomShareButton from "./CustomShareButton.web"
import { CopyIcon, fbImg, gmailImg, twitterImg } from "./assets"
import copy from "copy-to-clipboard"
import Alert from "@material-ui/lab/Alert"
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share"
import { SHARE_CHECKOUT_TEXT } from "./utility/constants"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  paper: {
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      borderRadius: "20px 20px 0px 0px",
    },
  },
  socialContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "10px",
  },
  socialButtonWrapper: {
    width: "100%",
  },
  linkTitle: {
    fontSize: "1.3rem",
    color: "#3c3c3c",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  linkTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 7px 7px 15px",
    borderRadius: "8px",
    backgroundColor: "#f3f0f0",
    marginTop: "10px",
    "& button": {
      padding: "10px",
    },
    "& img": {
      height: "23px",
      width: "23px",
      filter:
        "invert(73%) sepia(2%) saturate(2%) hue-rotate(340deg) brightness(95%) contrast(94%)",
    },
  },
  linkText: {
    color: "#818181",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1.1rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    titleWrapper: {
      borderBottom: "7px solid #ededed",
      display: "flex",
      justifyContent: "center",
    },
    title: {
      borderBottom: "7px solid #20a258",
      marginBottom: "-6.5px",
      padding: "5px 30px 10px",
      color: "#000",
      fontSize: "23px",
      fontFamily: '"Montserrat", sans-serif',
    },
    closeButton: {
      position: "absolute",
      right: "15px",
      top: "15px",
      "& svg": {
        color: "#000",
      },
    },
  })

interface ISocialShareDialog {
  link: string
  title: string
  isOpen: boolean
  handleClose: () => void
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box ml={2} mr={2} className={classes.titleWrapper}>
        <Typography className={classes.title} variant="h6" align="center">
          {children}
        </Typography>
      </Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const SocialShareDialog = ({
  link,
  title,
  isOpen,
  handleClose,
}: ISocialShareDialog) => {
  const classes = useStyles()
  const [isCopiedSuccess, setIsCopiedSuccess] = useState(false)
  const [isCopiedError, setIsCopiedError] = useState(false)

  const handleClipboardCopy = () => {
    const isCopy = copy(link)
    if (isCopy) {
      setIsCopiedSuccess(true)
      setTimeout(() => {
        setIsCopiedSuccess(false)
      }, 2000)
      return
    }
    setIsCopiedError(true)
    setTimeout(() => {
      setIsCopiedError(false)
    }, 2000)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      aria-labelledby="share-dialog"
      classes={{
        container: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle id="share-dialog" onClose={handleClose}>
        Share
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Grid container spacing={2} justifyContent="center" >
            <Grid item xs={12} sm={6} md={4}>
              <FacebookShareButton
                url={link}
                quote={title}
                className={classes.socialButtonWrapper}
              >
                <CustomShareButton icon={fbImg} btnText="Facebook" />
              </FacebookShareButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TwitterShareButton
                url={`\n${link}`}
                title={title}
                className={classes.socialButtonWrapper}
              >
                <CustomShareButton icon={twitterImg} btnText="Twitter" />
              </TwitterShareButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <EmailShareButton
                url={link}
                subject={SHARE_CHECKOUT_TEXT}
                body={title}
                separator={`\n`}
                className={classes.socialButtonWrapper}
              >
                <CustomShareButton icon={gmailImg} btnText="Email" />
              </EmailShareButton>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Typography className={classes.linkTitle}>Link</Typography>
            <Box className={classes.linkTextContainer}>
              <Typography className={classes.linkText}>{link}</Typography>
              <IconButton onClick={handleClipboardCopy}>
                <img src={CopyIcon} alt="copy" />
              </IconButton>
            </Box>
            <Box mt={1}>
              {isCopiedSuccess && (
                <Alert severity="success">Link copied to clipboard</Alert>
              )}
              {isCopiedError && (
                <Alert severity="error">Something went wrong</Alert>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SocialShareDialog
// Customizable Area End
