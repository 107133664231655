import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
    navigation: any;
    id: string;
    location?: any;
    history?: any;
    classes?: any;
    logout : any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: string;
    selectedDishes: any[];
    dishesPerPage: any;
    dishesCurrentPage: any;
    totalDishesCount: number
    totalDishesPage: number
    restaurantID: any;
    dishListLoader: boolean;
    activeRestaurant: string;
    restaurantsList: any;
    dishCategoryList: any;
    activeDishType: string | number;
    activeDishSorting: string
    dishSearchValue: string;
    dishList: any;
    deleteDishDialog: boolean
    deleteDishId: string
    search: string
    searchVal: string
    openDialog: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AdminRestaurantDishesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllRestaurantApiCallId: string = "";
    getAllDishApiCallId: string = "";
    getDishCategoriesApiCallId: string = "";
    removeDishApiCallId: string = "";
    updateDishStatusApiCallId: string = "";
    updateBatchDishStatusApiCallId: string = "";
    timer: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: "",
            selectedDishes: [],
            dishesPerPage: 5,
            dishesCurrentPage: 1,
            totalDishesCount: 0,
            totalDishesPage: 0,
            restaurantID: "",
            dishListLoader: false,
            activeRestaurant: '',
            restaurantsList: [],
            dishCategoryList: [],
            activeDishType: "",
            activeDishSorting: "Default",
            dishSearchValue: "",
            dishList: [],
            deleteDishDialog: false,
            deleteDishId: "",
            search : '',
            searchVal: '',
            openDialog: false,

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
}

  // Customizable Area Start
  async componentDidMount() {
    this.getAllRestaurants()
    this.getAllDishCategories()
  }

  async componentDidUpdate(_: any, prevState: S) {

    const {
      activeRestaurant,
      activeDishType,
      activeDishSorting,
      dishesCurrentPage,
      dishesPerPage,
      searchVal
    } = this.state

    if (
      prevState.activeRestaurant !== activeRestaurant ||
      prevState.activeDishType !== activeDishType ||
      prevState.activeDishSorting !== activeDishSorting ||
      prevState.dishesCurrentPage !== dishesCurrentPage ||
      prevState.dishesPerPage !== dishesPerPage ||
      prevState.searchVal !== searchVal
    ) {
      this.getAllDishList(this.state.activeRestaurant);
    }    

    if (prevState.activeRestaurant !== activeRestaurant) {
      const search = this.props.location?.state;
      const id = search?.id

      if (id) {
        this.setState({ activeRestaurant: id })
        this.getAllDishList(this.state.activeRestaurant)
      }
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
          this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        if ((apiRequestCallId === this.getAllRestaurantApiCallId)
          || (apiRequestCallId === this.getDishCategoriesApiCallId)
          || (apiRequestCallId === this.getAllDishApiCallId)
          || (apiRequestCallId === this.updateBatchDishStatusApiCallId)) {
          this.setState({
            dishListLoader: false
          })

          if (responseJson?.errors) {
            toast.error(responseJson?.errors[0].message)
            this.setState({
              loading:false
            })
            if(responseJson?.errors[0] && ((responseJson?.errors[0].message.includes(["Token has Expired","Invalid token"])))) {
              this.props.logout()
           }
          } 
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getMenuStyle = (value: string) => {
    switch(value) {
      case 'deactive':
      case 'draft':
        return value
      default:
        return ''
    }
  }
  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getAllRestaurantApiCallId) {

      const restId: string | undefined = window.location.pathname.split("/")?.pop()

      if(!!restId && restId !== "00") {
        this.setState({
          activeRestaurant: restId,
          restaurantsList: responseJson.data
        })
      } else if (responseJson?.data?.length > 0) {
        const pathRestId = responseJson.data[0].id

        this.setState({
          activeRestaurant: pathRestId,
          restaurantsList: responseJson.data
        })
        this.props.history.push(
          `/admin/restaurant/dishes/${pathRestId}`
        );
      } else {
        this.setState({
          dishListLoader: false
        });
      }
    }

    if (apiRequestCallId === this.getAllDishApiCallId) {

      const {
        total_count,
        total_pages
      } = responseJson?.meta?.pagination

      this.setState({
        dishListLoader: false,
        dishList: responseJson.data,
        selectedDishes: [],
        totalDishesCount: total_count,
        totalDishesPage: total_pages
      })
    }

    if (apiRequestCallId === this.removeDishApiCallId) {
      this.setState({
        deleteDishDialog: false,
        deleteDishId: ""
      })
      this.getAllDishList(this.state.activeRestaurant)
    }

    if (apiRequestCallId === this.updateDishStatusApiCallId) {
      const dishList = [...this.state.dishList]
      const index = dishList?.findIndex((dish: any) => dish.id === responseJson.data.id)
      dishList[index] = responseJson.data
      this.setState({ dishList })
    }

    if (apiRequestCallId === this.getDishCategoriesApiCallId) {
      this.setState({
        dishCategoryList: responseJson
      })
    }

    if (apiRequestCallId === this.updateBatchDishStatusApiCallId) {

      responseJson.data?.forEach((dish: any) => {
        const newData = this.state.dishList.map((dishDetails: any) => {
          if (dish.id === dishDetails.id) {
            return dish
          }
          return dishDetails
        })
        this.setState({ dishList: newData })
      })

      this.setState({ dishListLoader: false, selectedDishes: [] })
    }
  }

  handleActiveResChange = (event: React.ChangeEvent<{value:any}>) => {
    this.handleChangeActiveRestaurant(event.target.value);
    this.props.history.push(
      `/admin/restaurant/dishes/${event.target.value}`
    );
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = this.state.dishList.map((item: any) => item.id);
      this.setState({ selectedDishes: newSelected });
    } else {
      this.setState({ selectedDishes: [] })
    }
  };

  handleChangeTablePage = (value: number) => {
    this.setState({ dishesCurrentPage: value, selectedDishes: [] })
  }

  handleIncreasePageView = () => {
    if (this.state.dishesPerPage < 25) {
      this.setState({ dishesPerPage: this.state.dishesPerPage + 5, selectedDishes: [] })
    }
  }

  handleDecreasePageView = () => {
    if (this.state.dishesPerPage > 5) {
      this.setState({ dishesPerPage: this.state.dishesPerPage - 5, selectedDishes: [] })
    }
  }

  resetFilters = () => {
    this.setState({
      dishesCurrentPage: 1,
      dishesPerPage: 5,
      dishSearchValue: '',
      searchVal: '',
      activeDishType: "",
      activeDishSorting: "Default",
      selectedDishes: []
    })
  }

  handleChangeDishType = (event: React.ChangeEvent<{value:any}>) => {
    if (event.target.value === "default") event.target.value = ""
    this.setState({
      activeDishType: event.target.value,
      selectedDishes: []
    })
  }

  handleChangeDishSorting = (event: React.ChangeEvent<{value:any}>) => {
    const value = event.target.value
    if (
      value === "Default" ||
      value === "High To Low" ||
      value === "Low To High" ||
      value === "Latest"
    ) {
      this.setState({
        activeDishSorting: value,
        selectedDishes: []
      })
    }    
  }

  handleChangeDishSearching = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      dishSearchValue: event.target.value
    })
  }

  handleSearch = (event:any) => {
    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      this.setState({
        searchVal: event.target.value,
        dishesCurrentPage: 1,
        selectedDishes: []
      })
    }, 1000)
  }

  changeBatchDishStatus = (event: React.ChangeEvent<{value:any}>) => {
    this.updateBatchDishStatus(event.target.value)
  }

  handleChangeActiveRestaurant = (value: string) => {
    this.setState({
      activeRestaurant: value
    })
    this.resetFilters()
  }

  openDeleteDishDialog = (id: string) => {
    this.setState({
      deleteDishDialog: true,
      deleteDishId: id
    })
  }

  handleMenuClick = (menuType: string, id: string) => {
    if (menuType === "edit") {
      this.handleDishActionRouting(this.state.activeRestaurant, id)
    } else {
      this.openDeleteDishDialog(id)
      this.handleDialog()
    }
  }

  handleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  handleDishActionRouting = (id: string | number, dishId?: string) => {
    if (!!dishId) {
      this.props.history.push({
        pathname: `/admin/restaurant/dishes/${id}/create-dish`, state: {
          id,
          dishId
        }
      })
    } else {
      this.props.history.push({
        pathname: `/admin/restaurant/dishes/${id}/create-dish`, state: { id }
      })
    }
  }

  handleClickDishRow = (id: number) => {

    const selectedIndex = this.state.selectedDishes.indexOf(id);

    if (selectedIndex === -1) {
      this.setState({
        selectedDishes: [...this.state.selectedDishes, id]
      })
    } else {
      const tempIndex = [...this.state.selectedDishes]
      tempIndex.splice(selectedIndex, 1)
      this.setState({
        selectedDishes: tempIndex
      })
    }
  };

  isSelectedDish = (num: any) => this.state.selectedDishes.indexOf(num) !== -1;

  getAllRestaurants = async () => {
    !this.state.dishListLoader && this.setState({
      dishListLoader: true
    });

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRestaurantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRestaurants
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getAllDishList = async (id: any) => {

    this.setState({
      dishListLoader: true
    })

    let val;

    if (this.state.activeDishSorting === "High To Low") {
      val = "high_to_low"
    }
    else if (this.state.activeDishSorting === "Low To High") {
      val = "low_to_high"
    }
    else if (this.state.activeDishSorting === "Latest") {
      val = "latest"
    }
    else {
      val = "default"
    }

    const {
      dishesCurrentPage,
      dishesPerPage,
      activeRestaurant,
      activeDishType,
      searchVal
    } = this.state

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDishApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateDishApiEndpoint}?page=${dishesCurrentPage}&per=${dishesPerPage}&dish[restaurant_id]=${activeRestaurant}&dish[filter_by_category]=${activeDishType}&dish[sort_by]=${(val)}&dish[search]=${searchVal}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  removeDishApi = async () => {
    this.handleDialog()
    const token = await StorageProvider.get("authToken");

    const headers = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeDishApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateDishApiEndpoint}/${this.state.deleteDishId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateDishStatus = async ( dishId: string, event:any) => {

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDishStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateDishStatusEndPoint}?id=${dishId}&status=${event.target.value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getAllDishCategories = async () => {

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await StorageProvider.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDishCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dishCategoriesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateBatchDishStatus = async (status: string) => {

    this.setState({ dishListLoader: true })
    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status,
      dish_ids: this.state.selectedDishes
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBatchDishStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkStatusUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
