import React, { useEffect, useRef, useState } from "react"
import "./assets/css/farmsectionimgrendercomponent.scss"
import { farm1ImgSmall, farm1ImgLarge } from "./assets"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import "react-image-gallery/styles/scss/image-gallery.scss"
import Carousel, { Modal, ModalGateway } from "react-images"

const customStyles = {
  container: (base: any) => ({
    ...base,
    "& img": {
      height: "85vh",
      borderRadius: "15px",
    },
  }),
}

const FarmSectionImageRenderComponent = (props: any) => {
  const { imagesArray } = props
  const imagesRef: any = useRef()
  const [imagesList, setImagesList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const openImageModal = () => {
    if (imagesRef?.current)
      setCurrentImageIndex(imagesRef.current.state.currentIndex)
    else setCurrentImageIndex(0)
    setIsModalOpen(true)
  }

  const closeImageModal = () => {
    setCurrentImageIndex(0)
    setIsModalOpen(false)
  }

  const formatImages = () => {
    const images =
      imagesArray?.length > 0 ? imagesArray : [{ url: farm1ImgLarge }]
    return images.map((image: { url: string }) => {
      return { source: image.url }
    })
  }

  useEffect(() => {
    if (imagesArray?.length > 0) {
      const newArray = imagesArray?.map((item: any) => {
        return {
          original: item.url,
          thumbnail: item.thumb_url,
          originalHeight: "400px",
          originalWidth: "400px",
        }
      })
      setImagesList(newArray)
    } else {
      const newArray = [
        {
          original: farm1ImgLarge,
          thumbnail: farm1ImgSmall,
          originalHeight: "400px",
          originalWidth: "400px",
        },
      ]
      // @ts-ignore
      setImagesList(newArray)
    }
  }, [imagesArray])

  return (
    <>
      <ImageGallery
        ref={imagesRef}
        items={imagesList}
        lazyLoad={true}
        showFullscreenButton={false}
        showPlayButton={false}
        onClick={openImageModal}
      />
      <ModalGateway>
        {isModalOpen ? (
          <Modal closeOnBackdropClick={false} onClose={closeImageModal}>
            <Carousel
              styles={customStyles}
              currentIndex={currentImageIndex}
              views={formatImages()}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default FarmSectionImageRenderComponent
