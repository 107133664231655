// Customizable Area Start
import React from "react";
import { Grid, Typography, Box, Button, Tooltip } from "@material-ui/core";
import {
  FarmerMerchant,
  EducationalContributor,
  Restaurateur,
  setSelectRoleData
} from "../src/assets";
import "../assets/css/roleselection.scss";
import TopBar from "../../../components/src/TopBar.web";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter} from "react-router-dom";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import SelectYourRoleWebController, { Props } from "./SelectYourRoleController.web";
import {CircularProgress} from "@mui/material";
import { connect } from "react-redux"
import clsx from 'clsx';

const roleImages: any = {
  "Farmer/merchant": FarmerMerchant,
  "Restaurateur": Restaurateur,
  "Contributor": EducationalContributor
}
// Customizable Area End

 class SelectYourRoleWeb extends SelectYourRoleWebController{
  constructor(props: Props) {
    super(props);
  }

   render() {
     // Customizable Area Start
     const { SelectRoleDesc } = this.props

     const {
       isLoading,
       rolesData,
       selectedRoleId,
     } = this.state

     const isForwardBtnDisabled = rolesData?.length === 3 || isLoading

     return (
       <>
         <TopBar />
         <Grid container spacing={3} justifyContent="center">
           {isLoading ? (
             <Grid item xs={12} className="select_role_loader_container">
               <CircularProgress size={50} />
             </Grid>
           ) :
             <>
               {rolesData.length > 0 && <Grid item xs={12} sm={8}>
                 <Box className="select_your_role">
                   {rolesData?.length < 3 ? (
                     <Typography className="select_your_role_heading">
                       Do you want to register as one of the
                       <br />
                       following roles too?
                     </Typography>
                   ) : (
                     <Typography className="select_your_role_heading">
                       Select your Role
                     </Typography>
                   )}
                   <Grid item xs={12} sm={10} md={8} lg={5}>
                    <Typography className="select_your_role_text">
                      {SelectRoleDesc}
                    </Typography>
                   </Grid>
                 </Box>
               </Grid>}
               <Grid item container justifyContent="center" spacing={4} xs={12} sm={10} lg={8}>
                 {
                   rolesData?.length > 0 ? (
                     rolesData.map((role: { id: number, name: string }) => {
                       const { id, name } = role
                       const isSelected = id === selectedRoleId

                       return (
                         <Grid item xs={12} sm={6} md={4} key={id}>
                           <Box
                             className={clsx('role_container', {
                               ["selected_role"]: isSelected
                             })}
                             onClick={() => this.handleSelectedRole(id)}
                           >
                             {isSelected &&
                               <Box className="selected_role_container">
                                 <CircleCheckedFilled />
                               </Box>}
                             <Box className="role_content">
                               <img
                                 src={roleImages[name]}
                                 alt="role image"
                               />
                               <Typography className="role_card_title">
                                 {name}
                               </Typography>
                             </Box>
                           </Box>
                         </Grid>
                       )
                     })
                   ) : (
                     <Grid item xs={12} className="select_role_blank_container">
                       <Typography variant="h4" align="center">
                         All roles are already created successfully
                       </Typography>
                     </Grid>
                   )
                 }
               </Grid>
             </>}
           <Grid item xs={11}>
             <Box
               style={{
                 display: "flex",
                 justifyContent: "space-between",
                 width: "100%"
               }}
             >
               <Tooltip title="Go to Homepage" arrow>
                 <Button
                   disabled={isLoading}
                   onClick={() => this.handleHomePageRedirect()}
                   classes={{ root: "select_role_move_button", disabled: "select_role_move_disabled" }}
                 >
                   <ArrowBackIcon />
                 </Button>
               </Tooltip>

               {!selectedRoleId ?
                 <Tooltip title={isForwardBtnDisabled ? "" : "Go to Admin Dashboard"} arrow>
                   <Button
                     disabled={isForwardBtnDisabled}
                     classes={{ root: "select_role_move_button", disabled: "select_role_move_disabled" }}
                     onClick={this.handleSkipAdminRedirect}
                   >
                     {rolesData.length > 1 && rolesData.length < 3 ? 'Finish' : <ArrowForwardIcon />}
                   </Button>
                 </Tooltip>
                 :
                 <Button classes={{ root: "select_role_move_button" }} onClick={this.handleCreateRoleProcess}>
                   Create Profile
                 </Button>
               }
             </Box>
           </Grid>
         </Grid>
       </>
     )
     // Customizable Area End
  }
 }

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {
    SelectRoleDesc: state.cms.SelectRoleDesc
  }
}
// Customizable Area End

// Customizable Area Start
export default withRouter(connect(mapStateToProps, { setSelectRoleData })(SelectYourRoleWeb))
// Customizable Area End