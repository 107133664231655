// Customizable Area Start
export enum BusinessTypeEnum {
  Farm = "farm",
  Restaurant = "restaurant",
}

export enum AdvertisementTypeEnum {
  BusinessTile = "business_tile",
  UploadCreativeAssets = "upload_creative_assets"
}

export enum CampaignTypeEnum {
  LocalCampaign = "local_campaign",
  GlobalCampaign = "global_campaign"
}

export enum CampaignStatusEnum {
  Active = "active",
  Pause = "pause",
  Scheduled = "scheduled",
  Expired = "expired"
}
// Customizable Area End