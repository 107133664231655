// Customizable Area Start
import React, { useState, useEffect } from "react";
import { Grid, Box, Slider } from "@material-ui/core";
import "./assets/css/farmpagesidebar.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  setFarmProductsSelectedCategory,
  setFarmProductsSelectedTag,
  setProductsFilterByPrice,
} from "./assets";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SLIDER_STEP } from "./utility/constants";

interface Props extends RouteComponentProps {
  match: any
  categoryTitle: string
}

function valuetext(value: any) {
  return `${value}°C`;
}
const FarmPageSideBar = (props: Props) => {
  const { match, categoryTitle } = props
  const { id } = match.params
  const { farmsTags, farmsCategories, selectedCategory, selectedTag, minPrice, maxPrice } =
    useSelector((state: any) => state.farmProducts)

  const dispatch = useDispatch()
  const [rangeValue, setRangeValue] = useState([minPrice, maxPrice])

  useEffect(() => {
    setRangeValue([minPrice, maxPrice])
  }, [minPrice, maxPrice])
  

  const handleChange = (_: any, newValue: any) => {
    setRangeValue(newValue)
    document.getElementById("FarmList")?.scrollIntoView({behavior: 'smooth'})
  }

  const handlePriceChange = (_: any, newValue: any) => {
    dispatch(setProductsFilterByPrice(newValue, id))
    document.getElementById("FarmList")?.scrollIntoView({behavior: 'smooth'})
  }

  const handleCategoriesClick = (value: any) => {
    dispatch(setFarmProductsSelectedCategory(value, id))
    document.getElementById("FarmList")?.scrollIntoView({behavior: 'smooth'})
  }

  const handleTagClick = (value: any) => {
    dispatch(setFarmProductsSelectedTag(value, id))
    document.getElementById("FarmList")?.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <Box className="farm_sidebar_container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <Box className="farm_sidebar_heading">
              <ArrowBackIosIcon className="farm_side_bar_icons" />
              <p className="farm_sidebar_box_heading">{categoryTitle}</p>
            </Box>
            <Box className="related_category_contents">
              <Box className="sidebar_text_and_content_wrapper">
                <Box>
                  {farmsCategories?.map((item: { id: number, name: string }) => {
                    const { id, name } = item
                    return (
                      <p
                        key={id}
                        className={selectedCategory === name ? "selected" : ""}
                        onClick={() => handleCategoriesClick(name)}
                      >
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                      </p>
                    )
                  })
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Box className="farm_sidebar_common_box">
            <p className="farm_sidebar_box_heading">Price</p>
            <Box className="farm_sidebar_range">
              <Slider
                min={minPrice}
                step={SLIDER_STEP}
                max={maxPrice}
                value={rangeValue}
                onChange={handleChange}
                onChangeCommitted={handlePriceChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Box>
            <p className="price_container">
              Price :{" "}
              <span>
                ${rangeValue[0]} - ${rangeValue[1]}
              </span>
            </p>
          </Box>
        </Grid>

        <Box className="product_tag_content_wrapper">
          <Box />
          <p className="product_tag_header">Product Tag</p>
          <Grid container spacing={2} className="product_tag_content">
            {farmsTags.map((tag: { attributes: { id: number, name: string } }) => {
              const { id, name } = tag.attributes;

              return (
                <Grid item xs={6} md={12} lg={6} key={id}>
                  <p
                    className={selectedTag === name ? "selected" : ""}
                    onClick={() => handleTagClick(name)}
                  >
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </p>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

// @ts-ignore
export default withRouter(FarmPageSideBar);
// Customizable Area End
