import React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles"
import { Box, DialogTitle, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/CloseRounded"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3),
      position: "sticky",
      left: 0,
      top: 0,
      right: 0,
      backgroundColor: "#fff",
      zIndex: 1,
    },
    titleWrapper: {
      width: "95%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1.5),
      top: theme.spacing(1.8),
      color: theme.palette.grey[500],
      "& svg": {
        color: "#000",
      },
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode
  rootClassName?: string
  onClose: () => void
}

const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, rootClassName, onClose, ...other } = props
  return (
    <DialogTitle
      disableTypography
      className={`${classes.root} ${rootClassName || ""}`}
      {...other}
    >
      <Box className={classes.titleWrapper}>{children}</Box>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

export default CustomDialogTitle
