Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";

exports.btnExampleTitle = "CLICK ME";

//custom web

exports.ApiContentType = "multipart/form-data";
//get account details
exports.getAccountDeatilsAPiEndPoint = "account_block/accounts";
exports.getAccountDeatilsAPiMethod = "GET";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";

//update account details
exports.GOOGLE_CLIENT_ID = "350052156634-f6vcski427mlqkn299dquikiak2igact.apps.googleusercontent.com"
exports.FACEBOOK_APP_ID = "699832571029994"
exports.INSTAGRAM_APP_ID = "338421755744546"
exports.INSTAGRAM_APP_SECRET = "7a0bd7dbc65511afddcda025c9344d78"
exports.updateAccountDetailsEndPoint = "account_block/accounts";
exports.socialAccounConnectedEndPoint = "account_block/accounts/is_social_account_connected";
exports.connectedServiceEndPoint = "account_block/accounts/store_fb_access_token";
exports.updateAccountDeatilsMethod = "PUT";
exports.AUTH_TOKEN = "authToken"
exports.httpPutMethod = "PUT"
exports.changePasswordApiEndPoint = "account_block/accounts/change_password"
exports.facebookLabel = "Facebook"
exports.googleLabel = "Google"
exports.instagramLabel = "Instagram"
exports.fbConnectedMsg = "Account Connected Successfully"
exports.facebookLoginFailedMsg = "Facebook login failed. Please try again";
exports.serviceNotConnectedMessage = "You do not have connected any external login service to your account"
// Customizable Area End
