// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface ICustomCheckBoxProps {
  name: string
  setValue: (field: string, value: string[]) => void
  imageUrl:any
  iconsIds:any
}

const CustomCheckBox = ({ name, imageUrl, setValue, iconsIds }: ICustomCheckBoxProps) => {

  return (
    <div className="customLabelCheckbox">
      <FormControlLabel
        control={
          <Checkbox
          data-test-id="icons"
             name={name}
             checked={iconsIds?.includes(name)}
            onChange={(e:any)=>{
                if(e.target.checked){
                    setValue("iconsIds",[...iconsIds,name])
                }else{
                    const filteredIds=iconsIds.filter((id:any)=>id!=name)
                    setValue("iconsIds",filteredIds)
                }
            }}
          />
        }
        label={<img className="imgLabel" src={imageUrl}/>}
      />

    </div>
  );
};
export default CustomCheckBox;
// Customizable Area End
