// Customizable Area Start
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import React from "react";
import "../../info-page/src/assets/css/receiptcard.scss";
import { expandArrow } from './assets';
import Image from "material-ui-image";
import LazyLoad from "react-lazy-load";


export function MultipleOptionTask({ loader,data, option, accountId, taskAns, handleButtonClick, handleModalClose, isModalOpen }: any) {
    const [multipleAns, setMultiple] = React.useState<number[]>([])

    return (
        <>
            {data.attributes.title_image &&
            <Grid item xs={12} style={{ display: 'block', marginBottom:'20px' }} className="taskImageContainer">
            <LazyLoad offset={100}>   
            <Image
              style={{position:'unset', paddingTop:'0'}}
              src={data?.attributes?.title_image?.url}
              alt="Title Image"
              imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
              animationDuration={500}
              loading={
                  <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
              }
              />
              </LazyLoad> 
              </Grid>
            
            }
            {loader && !isModalOpen ? 
            <Box className="task_result_loader">
                <CircularProgress size={30} />
            </Box> :
            <Grid container style={{ backgroundColor: 'white' }}>
                <MultipleOptions data={data} accountId={accountId} />
                {data.attributes.task_results && data.attributes.task_results?.length === 0 ?
                    <>
                        <MultipleOptionType loader={loader} option={data.attributes.options} data={data} taskAns={taskAns} setMultiple={setMultiple} multiple={multipleAns}
                            handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                    </>
                    :
                    <>
                        {data.attributes.task_results && data.attributes.task_results?.filter((i: any) => i.attributes?.account_id === Number(accountId)).length == 0 ?
                            <>
                                <MultipleOptionType loader={loader} option={data.attributes.options} data={data} setMultiple={setMultiple} multiple={multipleAns} taskAns={taskAns}
                                    handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
                            </>
                            :
                            <>
                                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                                    const isImgOption = option.filter((i:any) => i.attributes?.option_image).length > 0 
                                    const isTextOption = option.filter((i:any) => i.attributes?.title_option).length > 0 
                                    const optionSec = isImgOption && isTextOption
                                    const leftMargin = optionSec ? 0 :'30px'
                                    return (
                                        <>
                                            <MultipleOptionWithResults loader={loader} option={option} data={data} item={item} taskAns={taskAns} 
                                            leftMargin={leftMargin} optionSec={optionSec} handleButtonClick={handleButtonClick} handleModalClose={handleModalClose} isModalOpen={isModalOpen} setMultiple={setMultiple} multipleAns={multipleAns}/>
                                        </>
                                    )
                                })}
                            </>
                        }
                    </>
                }

            </Grid>
            }
        </>
    )
}

const handleResult = (options: any, answers: any) => {
    let optionIds = options.map((i: any) => Number(i.id))
    const indexes = optionIds.map((element: any, index: any) => {
        for (let i of answers) {
            if (element === i) {
                return index;
            }
        }
    })
        .filter((element: any) => element >= 0).map((i: any) => String.fromCharCode(i + 65));

    if (indexes.length > 1) {
        let correctAns = indexes.map((i: any) => i)
        return correctAns.join(", ")
    }
    else {
        return indexes[0]
    }
}

const MultipleOptions = ({ data, accountId }: any) => {
    
    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='select-option'>Select Multiple Options:</Typography>
                {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(accountId)).map((item: any) => {
                    return (
                        <>
                            {!JSON.stringify(data.attributes.right_answer_image_id.sort()).includes(JSON.stringify(item.attributes.selected_answer.map(Number).sort())) ?
                                <Typography className='select-option' style={{ color: '#d16565', marginRight: '0' }}>
                                    Wrong. Correct Answer is {handleResult(data.attributes?.options, data.attributes?.right_answer_image_id)}
                                </Typography> :
                                <Typography className='select-option' style={{ color: '#147620', marginRight: '0' }}>
                                    Correct Answer
                                </Typography>
                            }
                        </>
                    )
                })}
            </Grid>
        </>
    )
}

const MultipleOptionType = ({ loader,option, data, setMultiple, taskAns, multiple, handleButtonClick, handleModalClose, isModalOpen }: any) => {

    const option4Sel = option[4]?.attributes.option_image ? 'selectImage' : 'select';
    const option4Unsel = option[4]?.attributes.option_image ? 'optionImage' : 'option-container';
    const isImgOption = option.filter((i:any) => i.attributes?.option_image).length > 0 
    const isTextOption = option.filter((i:any) => i.attributes?.title_option).length > 0 
    const optionSec = isImgOption && isTextOption 
    return (
        <>
            <Grid container style={{ display: optionSec ? 'block': 'flex' }}>
                {option.slice(0, 2).map((opt: any, idx: any) => {
                    const optionSel = opt?.attributes.option_image ? 'selectImage' : 'select';
                    const optionUnSel = opt?.attributes.option_image ? 'optionImage' : 'option-container';
                    return (
                        <Grid key={opt.id} item xs={12} sm={12} md={6} lg={6} className={`${opt.attributes.option_image && idx > 0 ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                        style={{ display:'flex', alignItems: opt.attributes.option_image ? 'left':'center' }}>
                            <button className='option-A' style={{ backgroundColor: idx === 0 ? '#8db1f6' : '#b28df6' }}>
                                {String.fromCharCode(65 + idx)}
                            </button>
                            <button
                                className={`${multiple.includes(Number(opt.id)) ? optionSel : optionUnSel}`}
                                style={{marginLeft :'7px'}}
                                onClick={() => setMultiple((multiple: any) => [...new Set(multiple.concat(Number(opt.id)))])}
                            >
                                {opt.attributes.option_image ? (
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={opt.attributes.option_image.url} />
                                    </LazyLoad>
                                ) : (
                                    opt.attributes.title_option
                                )}
                            </button>
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container  style={{ display: optionSec ? 'block': 'flex' }}>
                {option.slice(2, 4).map((opt: any, idx: any) => {
                    const optionSel = opt?.attributes.option_image ? 'selectImage' : 'select';
                    const optionUnSel = opt?.attributes.option_image ? 'optionImage' : 'option-container';
                    return (
                        <Grid key={opt.id} item xs={12} sm={12} md={6} lg={6} className={`${opt.attributes.option_image && idx > 0 ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                        style={{display:'flex'}}>
                            <button className='option-A' style={{ backgroundColor: idx === 0 ? '#b28df6' : '#69cbb2' }}>{String.fromCharCode(67 + idx)}</button>
                            <button className={`${multiple.includes(Number(opt?.id)) ? optionSel : optionUnSel}`}
                                style={{marginLeft :'7px'}}
                                onClick={() => setMultiple((multiple: any) => [...new Set(multiple.concat(Number(opt.id)))])}>
                                {opt.attributes.option_image ? (
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} imageStyle={{width:'100%', height:'138px'}} animationDuration={500} src={opt.attributes.option_image.url} />
                                    </LazyLoad>
                                ) : (
                                    opt.attributes.title_option
                                )}
                            </button>
                        </Grid>
                    )
                })}
                {option.length === 5 &&
                    <>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex'}}>
                                <button className='option-A'>E</button>
                                <button className={`${multiple.includes(Number(option[4]?.id)) ? option4Sel : option4Unsel}`}
                                    style={{marginLeft :'7px'}}
                                    onClick={() => {
                                        setMultiple((multiple: any) => [...new Set(multiple.concat(Number(option[4].id)))])
                                    }}
                                >
                                    {!option[4].attributes.option_image ? option[4].attributes.title_option : 
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[4].attributes.option_image.url} />
                                    </LazyLoad>
                                    }
                                </button>
                            </Grid>
                        </Grid>
                    </>
                }
                <Grid container className="taskBtnContainer" >
                    <Grid style={{ display: 'flex', marginTop: '30px' }}>
                        <button className='cancelBtn' onClick={() => setMultiple([])}>Cancel</button>
                        <button className='submitBtn' onClick={() => taskAns(multiple, data.id, data.attributes.course_sub_section_id)}>Submit</button>
                    </Grid>
                    {!isModalOpen &&
                        <Grid style={{ display: 'flex', marginTop: '30px'}}>
                            <button className='fullBtnContainer'
                                style={{ margin: '0', padding: '16px 22px 16.2px', alignItems: 'flex-end' }}
                                onClick={handleButtonClick}>
                                <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                        </Grid>}
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                        <MultipleTaskPopUp loader={loader} data={data} accountId={data.attributes.account_id} taskAns={taskAns} setMultiple={setMultiple} multipleAns={multiple}/>
                    </Box>
                </Grid>
            </Modal>
        </>
    )

};

const getMultiOptionClassName = (option: any, selectedAnswer: any, rightAnswer: any) => {
    const selected_answer = selectedAnswer.map(Number)

    if (selected_answer.includes(Number(option.id)) && !rightAnswer.includes(Number(option.id))) {
        return option.attributes.option_image ? "wrongImage" : "wrong";
    } else if (rightAnswer.includes(Number(option.id))) {
        return option.attributes.option_image ? "selectImage" : "select";
    } else {
        return option.attributes.option_image ? "optionImage" : "option-container";
    }
};

const multiOption0ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[0], item.attributes.selected_answer, data.attributes.right_answer_image_id);
const multiOption1ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[1], item.attributes.selected_answer, data.attributes.right_answer_image_id);
const multiOption2ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[2], item.attributes.selected_answer, data.attributes.right_answer_image_id);
const multiOption3ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[3], item.attributes.selected_answer, data.attributes.right_answer_image_id);
const multiOption4ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[4], item.attributes.selected_answer, data.attributes.right_answer_image_id);

const MultipleOptionWithResults = ({optionSec, leftMargin,loader,option, data, item, taskAns, handleButtonClick, handleModalClose, isModalOpen, setMultiple, multipleAns}:any) => {
    const displayOptions = optionSec ? 'block' : 'flex';
    const points = JSON.stringify(data.attributes.right_answer_image_id.sort()).includes(JSON.stringify(item.attributes.selected_answer.map(Number).sort()))
    return (
        <>
            <Grid container style={{ display: displayOptions }}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                    <button className='option-A'>A</button>
                    <button className={multiOption0ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>{!option[0].attributes.option_image ? option[0].attributes.title_option : 
                    <LazyLoad offset={100}>
                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[0].attributes.option_image.url} />
                    </LazyLoad>
                    }
                    </button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={`${option[1].attributes.option_image ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                style={{ display:'flex'}}>
                    <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
                    <button className={multiOption1ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>
                        {!option[1].attributes.option_image ? option[1].attributes.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[1].attributes.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
            </Grid>
            <Grid container style={{ display: displayOptions }}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                    <button className='option-A' style={{ backgroundColor: '#b28df6' }}>C</button>
                    <button className={multiOption2ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>
                        {!option[2].attributes.option_image ? option[2].attributes.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[2].attributes.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}  className={`${option[3].attributes.option_image ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                style={{ display :'flex'}}>
                    <button className='option-A' style={{ backgroundColor: '#69cbb2' }}>D</button>
                    <button
                        style={{marginLeft : '7px'}}
                        className={multiOption3ClassName(option, item, data)}>
                        {!option[3].attributes.option_image ? option[3].attributes.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[3].attributes.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
                {option.length === 5 &&
                    <>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                                <button className='option-A'>E</button>
                                <button className={multiOption4ClassName(option, item, data)}
                                style={{marginLeft:'7px'}}>
                                    {!option[4].attributes.option_image ? option[4].attributes.title_option : 
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[4].attributes.option_image.url} />
                                    </LazyLoad>
                                    }
                                </button>
                            </Grid>
                        </Grid>
                    </>
                }
                <Grid container style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                    <Grid className="pointContainer">
                    <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
                    </Grid>
                    <Grid style={{ margin: '30px 0 20px' }}>
                        <button className='fullBtnContainer' style={{ margin: '0', padding: '16px 22px 16.2px' }} onClick={handleButtonClick}>
                            <img className="fullScreenIcon" src={expandArrow} />Full Screen</button>
                    </Grid>
                </Grid>
            </Grid>
            <Modal open={isModalOpen} onClose={handleModalClose} >
                <Grid container style={{ backgroundColor: 'white' }}>
                    <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                        <MultipleTaskPopUp loader={loader} data={data} accountId={data.attributes.account_id} taskAns={taskAns} multipleAns={multipleAns} setMultiple={setMultiple}/>
                    </Box>
                </Grid>
            </Modal>
        </>
    )
}


const MultipleOptionModal = ({ option, data, setMultiple, taskAns, multiple }: any) => {
    const option4Sel = option[4]?.attributes.option_image ? 'selectImage' : 'select-popup';
    const option4Unsel = option[4]?.attributes.option_image ? 'optionImage' : 'options-popup';
    const isImgOption = option.filter((i:any) => i.attributes?.option_image).length > 0 
    const isTextOption = option.filter((i:any) => i.attributes?.title_option).length > 0 
    const optionSec = isImgOption && isTextOption 
    return (
        <>
            <Grid container style={{ display: optionSec ? 'block' : 'flex'}}>
                {option.slice(0, 2).map((opt: any, idx: any) => {
                    const optionSel = opt?.attributes.option_image ? 'selectImage' : 'select-popup';
                    const optionUnSel = opt?.attributes.option_image ? 'optionImage' : 'options-popup';
                    return (
                        <Grid key={opt.id} item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex'}}>
                            <button className='option-A' style={{ backgroundColor: idx === 0 ? '#8db1f6' : '#b28df6' }}>
                                {String.fromCharCode(65 + idx)}
                            </button>
                            <button
                                className={`${multiple.includes(Number(opt.id)) ? optionSel : optionUnSel}`}
                                style={{marginLeft: '7px'}}
                                onClick={() => setMultiple((multiple: any) => [...new Set(multiple.concat(Number(opt.id)))])}
                            >
                                {opt.attributes.option_image ? (
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={opt.attributes.option_image.url} />
                                    </LazyLoad>
                                ) : (
                                    opt.attributes.title_option
                                )}
                            </button>
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container style={{ display: optionSec ? 'block' :'flex'}}>
                {option.slice(2, 4).map((opt: any, idx: any) => {
                    const optionSel = opt?.attributes.option_image ? 'selectImage' : 'select-popup';
                    const optionUnSel = opt?.attributes.option_image ? 'optionImage' : 'options-popup';
                    return (
                        <Grid key={opt.id} item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" 
                        style={{ display: 'flex'}}>
                            <button className='option-A' style={{ backgroundColor: idx === 0 ? '#b28df6' : '#69cbb2' }}>{String.fromCharCode(67 + idx)}</button>
                            <button className={`${multiple.includes(Number(opt?.id)) ? optionSel : optionUnSel}`}
                                style={{marginLeft: '7px'}}
                                onClick={() => setMultiple((multiple: any) => [...new Set(multiple.concat(Number(opt.id)))])}>
                                {opt.attributes.option_image ? (
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={opt.attributes.option_image.url} />
                                    </LazyLoad>
                                ) : (
                                    opt.attributes.title_option
                                )}
                            </button>
                        </Grid>
                    )
                })}
                {option.length === 5 &&
                    <>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                                <button className='option-A'>E</button>
                                <button className={`${multiple.includes(Number(option[4]?.id)) ? option4Sel : option4Unsel}`}
                                    style={{marginLeft: '7px'}}
                                    onClick={() => {
                                        setMultiple((multiple: any) => [...new Set(multiple.concat(Number(option[4].id)))])
                                    }}
                                >
                                    {!option[4].attributes.option_image ? option[4].attributes.title_option : 
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[4].attributes.option_image.url} />
                                    </LazyLoad>
                                    }
                                </button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container className="nextLine" style={{ display: 'flex', margin: '30px 0px 50px' }}>
                <Grid style={{ display: 'flex', marginTop: '30px',alignItems:'center' }}>
                    <button className='cancelBtn' onClick={() => setMultiple([])}>Cancel</button>
                    <button className='submitBtn' onClick={() => {
                        taskAns(multiple, data.id, data.attributes.course_sub_section_id)
                    }}>Submit</button>
                </Grid>
            </Grid>

        </>
    )
}

const MultipleOptWithResultsModal = ({option, data, item}: any) => {
    const isImgOption = option.filter((i:any) => i.attributes?.option_image).length > 0 
    const isTextOption = option.filter((i:any) => i.attributes?.title_option).length > 0 
    const optionSec = isImgOption && isTextOption 

    const getMultiOptionClassName = (option: any, selectedAnswer: any, rightAnswer: any) => {
        const selected_answer = selectedAnswer?.map(Number)
        if (selected_answer?.includes(Number(option?.id)) && !rightAnswer?.includes(Number(option?.id))) {
            return option?.attributes?.option_image ? "wrongImage" : "wrong-popup";
        } else if (rightAnswer?.includes(Number(option?.id))) {
            return option?.attributes?.option_image ? "selectImage" : "select-popup";
        } else {
            return option?.attributes?.option_image ? "optionImage" : "options-popup";
        }
    };
    const points = JSON.stringify(data.attributes.right_answer_image_id.sort()).includes(JSON.stringify(item.attributes.selected_answer.map(Number).sort()))
    const multiOption0ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[0], item.attributes.selected_answer, data.attributes?.right_answer_image_id);
    const multiOption1ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[1], item.attributes.selected_answer, data.attributes?.right_answer_image_id);
    const multiOption2ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[2], item.attributes.selected_answer, data.attributes?.right_answer_image_id);
    const multiOption3ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[3], item.attributes.selected_answer, data.attributes?.right_answer_image_id);
    const multiOption4ClassName = (option: any, item: any, data: any) => getMultiOptionClassName(option[4], item.attributes.selected_answer, data.attributes?.right_answer_image_id);
    return (
        <>
            <Grid container style={{ display: optionSec ? 'block': 'flex' }}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                    <button className='option-A'>A</button>
                    <button className={multiOption0ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>
                        {!option[0]?.attributes?.option_image ? option[0]?.attributes?.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[0]?.attributes?.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={`${option[1]?.attributes.option_image ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                style={{ display:'flex' }}>
                    <button className='option-A' style={{ backgroundColor: '#b28df6' }}>B</button>
                    <button className={multiOption1ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>
                        {!option[1]?.attributes?.option_image ? option[1]?.attributes?.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[1]?.attributes?.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
            </Grid>
            <Grid container  style={{ display: optionSec ? 'block' :'flex'}}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                    <button className='option-A' style={{ backgroundColor: '#b28df6' }}>C</button>
                    <button className={multiOption2ClassName(option, item, data)}
                    style={{marginLeft : '7px'}}>
                        {!option[2]?.attributes?.option_image ? option[2]?.attributes?.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[2]?.attributes?.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={`${option[3]?.attributes.option_image ? "imageOpt-Container" : "textOptContainer"} nextLineSingle`} 
                style={{ display:'flex' }}>
                    <button className='option-A' style={{ backgroundColor: '#69cbb2' }}>D</button>
                    <button
                        className={multiOption3ClassName(option, item, data)}
                        style={{marginLeft : '7px'}}>
                        {!option[3]?.attributes?.option_image ? option[3]?.attributes?.title_option : 
                        <LazyLoad offset={100}>
                        <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[3]?.attributes?.option_image.url} />
                        </LazyLoad>
                        }
                    </button>
                </Grid>
                {option.length === 5 &&
                    <>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="nextLineSingle" style={{ display: 'flex' }}>
                                <button className='option-A'>E</button>
                                <button className={multiOption4ClassName(option, item, data)}
                                style={{marginLeft : '7px'}}>
                                    {!option[4]?.attributes?.option_image ? option[4]?.attributes?.title_option : 
                                    <LazyLoad offset={100}>
                                    <Image style={{paddingTop: 0, height:'138px'}} animationDuration={500} imageStyle={{width:'100%', height:'138px'}} src={option[4]?.attributes?.option_image.url} />
                                    </LazyLoad>}
                                </button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid className="pointContainer">
                <Typography className="taskPoints">Points: {points ? data?.attributes?.points : '0' }/{data?.attributes?.points}</Typography>
            </Grid>
        </>
    )
}

function MultipleTaskPopUp({ loader,data, taskAns, setMultiple, multipleAns }: any) {
    
    const account_Id = localStorage.getItem('cartId')

    return (
        <>
            <Grid container style={{ backgroundColor: 'white' }}>
                <Box className='task-popup farm_forum_dialog' style={{ position: "absolute" as "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: '530px' }}>
                    <Typography className="select-text">Select Correct Answer</Typography>
                    <div className='horizontal-line'></div>
                    <Typography className='task-title'>Task Title</Typography>
                    <Box className='task-question'>
                    <Typography variant="body1" component="div" >
                        <div  className="question" dangerouslySetInnerHTML={{ __html:  data.attributes.title }} />
                    </Typography>
                    </Box>
                    {data.attributes.title_image &&
                    <Grid item xs={12} style={{ display: 'block', margin:'20px 0' }} className="taskImageContainer">
                     <LazyLoad offset={100}>   
                    <Image
                    style={{position:'unset', paddingTop:'0'}}
                    src={data?.attributes?.title_image?.url}
                    alt="Title Image"
                    className="taskImage"
                    imageStyle={{width:'auto', position:'none', maxHeight:'250px', maxWidth:'700px', height:'auto'}}
                    animationDuration={500}
                    loading={
                        <CircularProgress className="image_loader" style={{position:'absolute', top:'25%', left:'25%'}} size={25} />
                    }
                    />
                    </LazyLoad>
                    </Grid>
                    
                    }
                    {loader ?
                    <Box className="task_result_loader" style={{width:'60vw'}}>
                        <CircularProgress size={30} />
                    </Box> :
                    <Grid container style={{ marginTop: '24px' }}>
                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className='task-option'>Select Multiple Options:</Typography>
                            {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(account_Id)).map((item: any) => {
                                return (
                                    <>
                                        {!JSON.stringify(data.attributes.right_answer_image_id.sort()).includes(JSON.stringify(item.attributes.selected_answer.map(Number).sort())) ?
                                            <Typography className='task-option' style={{ color: '#d16565', marginRight: '30px' }}>
                                                Wrong. Correct Answer is {handleResult(data.attributes?.options, data.attributes?.right_answer_image_id)}
                                            </Typography> :
                                            <Typography className='task-option' style={{ color: '#147620', marginRight: '30px' }}>
                                                Correct Answer
                                            </Typography>
                                        }
                                    </>
                                )
                            })}
                            {data.attributes.task_results && data.attributes.task_results?.length === 0 ?
                                <>
                                    <MultipleOptionModal option={data.attributes.options} data={data} taskAns={taskAns} setMultiple={setMultiple} multiple={multipleAns} />
                                </>
                                :
                                <>
                                    {data.attributes.task_results && data.attributes.task_results?.filter((i: any) => i.attributes?.account_id === Number(account_Id)).length == 0 ?
                                        <>
                                            <MultipleOptionModal option={data.attributes.options} data={data} setMultiple={setMultiple} multiple={multipleAns} taskAns={taskAns} />
                                        </>
                                        :
                                        <>
                                            {data.attributes.task_results.filter((item: any) => item.attributes?.account_id === Number(account_Id)).map((item: any) => {
                                                const option = data.attributes.options
                                                return (
                                                    <>
                                                        <MultipleOptWithResultsModal option={option} data={data} item={item} />
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    }
                </Box>
            </Grid>
        </>
    )
}
//Customizable Area End