// Customizable Area Start
import { IBlock } from "framework/src/IBlock"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum"
import { runEngine } from "framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom"
import { Message } from "framework/src/Message"
import StorageProvider from "framework/src/StorageProvider"
import { toast } from "react-toastify"
export const configJSON = require("../config.js")

export interface ICourse {
    id: string
    type: string
    attributes: {
        id: number
        title: string
        banner_image: {
            id: number
            filename: string
            url: string
        }
    }
}

interface ICampaign {
    id: string
    type: string
    attributes: {
        banner: {
            id: number
            url: string
            filename: string
        }
        campaign_id: string
        campaign_type: string
        description: string
        donation_raised: number
        donation_raised_percentage: string
        heading: string
        required_donation: number
        status: string
        tagline: string
    }
}

export interface IExpert {
    id: string
    type: string
    attributes: {
        first_name: string
        last_name: string
        headline: string
        educator_image: {
            id: number
            filename: string
            url: string
        }
        biography: string
        facebook: string
        facebook_username: string
        linkedin: string
        linkedin_username: string
        youtube: string
        youtube_username: string
        website: string
        twitter: string
        twitter_username: string
        status: string
        language: string
        courses: {
            data: ICourse[]
        }
        campaign: {
            data: ICampaign | null
        }
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    isOpen: boolean
    expertId: string
    handleClose: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    expertDetails: IExpert
    // Customizable Area End
}

interface SS {
    id: any
}

export default class ExpertDetailsDialogController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getExpertDetailsApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false,
            expertDetails: {
                id: "",
                type: "",
                attributes: {
                    first_name: "",
                    last_name: "",
                    biography: "",
                    educator_image: {
                        filename: "",
                        id: 0,
                        url: ""
                    },
                    facebook: "",
                    facebook_username: "",
                    headline: "",
                    language: "",
                    linkedin: "",
                    linkedin_username: "",
                    status: "",
                    twitter: "",
                    twitter_username: "",
                    website: "",
                    youtube: "",
                    youtube_username: "",
                    courses: {
                        data: []
                    },
                    campaign: {
                        data: null
                    }
                }
            }
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getExpertDetailsApi()
    }

    getSocialUrl = (hostUrl: string, userName: string) => {
        return (hostUrl && userName) ? `${hostUrl}/${userName}` : ''
    }

    getExpertDetailsApi = async () => {
        this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getExpertDetailsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getExpertDetailsApiEndPoint}${this.props.expertId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.getExpertDetailsApiCallId) {
                    this.setState({
                        loader: false,
                        expertDetails: responseJson.data
                    })
                }
            } else {
                if (apiRequestCallId === this.getExpertDetailsApiCallId) {
                    toast.error("Something went wrong")
                    this.props.handleClose()
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
