// Customizable Area Start
import React, { Component } from "react"
import { Box } from "@material-ui/core"
import { ICartProductDetails } from "../../../../components/src/interfaces/shoppingCart"
import ShoppingCartCard from "./ShoppingCartCard.web"

interface IProps {
  isAcceptingOrder: boolean
  cartItems: ICartProductDetails[]
  getAddressAndItems: () => void
}

class ShoppingCartLayoutItems extends Component<IProps> {
  render() {
    const { cartItems, isAcceptingOrder, getAddressAndItems } = this.props

    return cartItems?.map((product: ICartProductDetails) => {
      const {
        id,
        type,
        attributes: {
          name,
          image,
          cart_price,
          description,
          cart_compare_at_price,
          quantity,
          product_active,
        },
      } = product
      const isFarmItem = type === "farm_cart_item"
      const isRestaurantItem = type === "restaurant_cart_item"
      const isLearningItem = type === "course_cart_item"
    
      //@ts-ignore
      const isTrackQuantity = isFarmItem ? product.attributes.track_quantity : false
      //@ts-ignore
      const variantDetails = isFarmItem && product.attributes?.variant_description ? {
        //@ts-ignore
        variants: product.attributes.variant_options,
        //@ts-ignore
        variantDescription: product.attributes.variant_description,
        //@ts-ignore
        selectedVariantId: product.attributes.selected_variant_option_id
      } : {
        variants: [],
        variantDescription: "",
        selectedVariantId: ""
      }
    
      const availableQuantity = isFarmItem
        //@ts-ignore
        ? product.attributes.available_quantity || 0
        : 0
    
      //@ts-ignore
      const addOns = isRestaurantItem ? product.attributes.addons : ""
    
      const specialCookingInstruction = isRestaurantItem
        //@ts-ignore
        ? product.attributes.special_cooking_instruction
        : ""
    
      return (
        <Box key={id} mb="20px" paddingBottom="25px" borderBottom="1px solid #bbbbbb">
          <ShoppingCartCard
            isAcceptingOrder={isAcceptingOrder}
            isLearningOrder={isLearningItem}
            isProductActive={product_active}
            cartId={id}
            productId={id}
            name={name}
            image={image}
            description={description}
            price={cart_price}
            discountPrice={cart_compare_at_price}
            quantity={quantity}
            isTrackQuantity={isTrackQuantity}
            availableQuantity={availableQuantity}
            {...variantDetails}
            addOns={addOns}
            cookingInstruction={specialCookingInstruction}
            getAddressAndItems={getAddressAndItems}
          />
        </Box>
      )
    })    
  }
}

export default ShoppingCartLayoutItems
// Customizable Area End
