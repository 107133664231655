// Customizable Area Start
import React from "react";
import SquareConnectRedirectController from "./SquareConnectRedirectController.web";
import { withRouter } from "react-router-dom";
import { Box, CircularProgress, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    mainWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%"
    },
    loader: {
      color: "#20a258"
    }
  });

export class SquareConnectRedirect extends SquareConnectRedirectController {
  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.mainWrapper}>
        <CircularProgress className={classes.loader} />
      </Box>
    );
  }
}

export default withStyles(styles)(withRouter(SquareConnectRedirect));
// Customizable Area End
