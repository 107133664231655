import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../framework/src/StorageProvider.web";
import { AppRoutings } from "./utility/app-routing";
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  toggle: any;
  classes: any;
  sidebarToggle: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  roles: string[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdminSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRolesApiCallId: string = "";
  getContibuterId: string = "";
  getContibuterStudentId: string = "";
  getContibuterDiscussionId: string = "";
  getContibuterdetailsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      roles: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getRegisteredRoles();
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.successApiCall(responseJson, apiRequestCallId);
      }
    }
    // Customizable Area End
  }

  // success api call
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getRolesApiCallId) {
      const registeredRoles = responseJson.data.map(
        (role: any) => role.attributes.role.name
      );
      this.setState({
        roles: registeredRoles,
      });
    }
    if (apiRequestCallId === this.getContibuterId) {
      this.props.history.push(
        `/admin/learning/course-list/${responseJson.data.id}`
      );
    }
    if (apiRequestCallId === this.getContibuterStudentId) {
      this.props.history.push(
        `/admin/learning/students/${responseJson.data.id}/0`
      );
    }
    if (apiRequestCallId === this.getContibuterDiscussionId) {
      this.props.history.push(
        `/admin/learning/discussion/${responseJson.data.id}`
      );
    }
    if (apiRequestCallId === this.getContibuterdetailsId) {
      this.props.history.push(
        `/admin/learning/details/${responseJson?.data?.id}`
      );
    }
  };

  // Customizable Area Start
  getRegisteredRoles = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContibuter = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContibuterstudents = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterStudentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContibuterdiscussion = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterDiscussionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContibuterdetails = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterdetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAdCampaignRoute = () => {
    const { toggle, history, sidebarToggle } = this.props
    history.push(AppRoutings.AdminAdCampaigns)
    toggle && sidebarToggle()
  }
  // Customizable Area End
}
