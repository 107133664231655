//Customizable Area Start
import React, { Component } from "react";
import { Grid, Box, Button, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppRoutings } from "../../../components/src/utility/app-routing";

export interface IProps {
    FooterBannerDescription: string
    history : any
}

export class BusinessTeachBanner extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
      }
    render() {
        const { FooterBannerDescription } = this.props
        return (
          //Customizable Area Start
            <Box className="business_banner">
          <Container className="businessTeachContainer">
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={12} sm={8} md={8}>
                <h3 className="banner_text">{FooterBannerDescription}</h3>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className="btnBlock">
                <Button onClick={() => { 
                  this.props.history.push(AppRoutings.Learning)
                }} 
                  classes={{ root: "banner_btn", label: "btn-label" }}>
                  Start Shopping
                </Button>
              </Grid>
            </Grid>
            <Grid />
          </Container>
        </Box>
        );
    }
}

export const mapStateToProps = (state: any) => {
    return {
      FooterBannerDescription: state.cms.FooterBannerDescription
    }
}

const banner:any = withRouter(connect(mapStateToProps, {})(BusinessTeachBanner));

export default banner
//Customizable Area End