// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock"
import { Message } from "../../../../../framework/src/Message"
import { BlockComponent } from "../../../../../framework/src/BlockComponent"
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../../framework/src/RunEngine"
import { RouteComponentProps } from "react-router-dom";
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { toast } from "react-toastify"
import { isTokenExpired } from "../../../../../components/src/utility/helper"
export const configJSON = require("../../config.js")

export interface ITopic {
    id: string
    type: string
    attributes:  {
        name: string
        author_name: string
        community_forum_id: number
        created_at: string
        updated_at: string
    }
}
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    activeTopicId: string
    discussionTopics: ITopic[]
    setDiscussionActiveTopic: (topicId: string) => void
    setDiscussionTopics: (topics: ITopic[]) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean
    // Customizable Area End
}

interface SS {
    id: any
}

export default class CommunityDiscussionTabsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDiscussionTopicsApiCallId: string = ""
    // Customizable Area End
    
    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            loader: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getDiscussionTopicsApi()
    }

    async componentWillUnmount(): Promise<void> {
        this.props.setDiscussionActiveTopic("")
    }

    handleTopicSelectionChange = (id: string) => {
        const { activeTopicId, setDiscussionActiveTopic } = this.props
        if(activeTopicId !== id) {
            setDiscussionActiveTopic(id)
        }
    }

    getDiscussionTopicsApi = async () => {
        const { discussionTopics } = this.props
        const isTopicListExist = discussionTopics?.length > 0
        !isTopicListExist && this.setState({ loader: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getDiscussionTopicsApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.communityTopicsApiEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)

        return true
    }

    handleErrorApiResponse = async (apiRequestCallId: string, responseJson: any) => {        
        if (apiRequestCallId === this.getDiscussionTopicsApiCallId) {
            if(await isTokenExpired(responseJson)) return

            this.setState({ loader: false })
            toast.error("Something went wrong")
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.getDiscussionTopicsApiCallId) {
                    if(!!responseJson?.data) {
                        const { setDiscussionTopics } = this.props
                        setDiscussionTopics(responseJson.data)
                        this.setState({ loader: false })
                        return
                    }
                    toast.error("Something went wrong")
                }
            } else {
                this.handleErrorApiResponse(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}

