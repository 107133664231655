// Customizable Area Start
import React, { useState } from "react";
import {
  Grid,
  Box,
  InputAdornment,
  Button,
  OutlinedInput,
  IconButton,
} from "@material-ui/core";
import "./assets/css/farmpagesearchfilter.scss";
import { GoogleApiWrapper } from 'google-maps-react';
import { searchImg } from "./assets";
import { locationImg, setRestaurantFilterByTextAndLatLng, clearRestaurantFilterByTextAndLatLng, setRestaurantSearchAddress } from "./assets";
import { useSelector, useDispatch } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const configJSON = require("./config.js")

const RestaurantSearchFilters = () => {
  // @ts-ignore
  const { searchText, lat, lng, restaurantSearchAddress, nearByFilter } = useSelector(state => state.restaurants)
  const dispatch = useDispatch()
  const [latlng, setLatlng] = useState({});
  const [search, setSearch] = useState("");
  const isNotNearBy = (lat || lng) && !nearByFilter
  const nearByLatLng = restaurantSearchAddress ? latlng : { lat, lng }

  const handleChange = (address: any) => {
    dispatch(setRestaurantSearchAddress(address))

    if (address?.length === 0) {
      setLatlng({})
      dispatch(setRestaurantSearchAddress(""))
      dispatch(setRestaurantFilterByTextAndLatLng(nearByLatLng, search, restaurantSearchAddress))
    }
  }
  
  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // @ts-ignore
        setLatlng(latLng)
      })
      .catch(error => {
        console.error('Error', error)
      });
    dispatch(setRestaurantSearchAddress(address))
  }

  const handleSubmit = () => {
    if (isNotNearBy || searchText) {
      dispatch(clearRestaurantFilterByTextAndLatLng())
      setSearch("")
      dispatch(setRestaurantSearchAddress(""))
      setLatlng({})
    } else {
      dispatch(setRestaurantFilterByTextAndLatLng(nearByLatLng, search, restaurantSearchAddress))
    }
  }

  const handleSearchEnter = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(setRestaurantFilterByTextAndLatLng(nearByLatLng, search, restaurantSearchAddress))
    }
  }

  const handleSearchText = (value: string) => {
    setSearch(value)

    if(value?.length === 0) {
      dispatch(setRestaurantFilterByTextAndLatLng(nearByLatLng, value, restaurantSearchAddress))
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={5}>
          <Box>
            <OutlinedInput
              placeholder="Search for product, restaurant and categories..."
              fullWidth={true}
              value={search}
              onKeyDown={handleSearchEnter}
              onChange={(e) => handleSearchText(e.target.value)}
              classes={{
                root: "filter_input_root",
                input: "filter_input_input",
                focused: "filter_input_focus",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="end">
                    <img
                      src={searchImg}
                      alt="search_icon"
                      className="filter_input_icon"
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={4}>
          <Box className='restaurantGooglemap'>
            <PlacesAutocomplete
              value={restaurantSearchAddress}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div className="customTextfield" >
                    <OutlinedInput
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input',
                      })}
                      placeholder="Near by"
                      fullWidth={true}
                      classes={{
                        root: "filter_input_root",
                        input: "filter_input_input",
                        focused: "filter_input_focus",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton edge="end">
                            <img
                              className="filter_input_icon"
                              src={locationImg}
                              alt="search_icon"
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {(loading || suggestions.length > 0) && <div className='googlemap-data'>
                      <div className="autocomplete-dropdown-container googlemap-dropdown">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={3} style={{ marginBottom: '5px' }}>
          <Box>
            <Button
              className="filter_advance_search_btn"
              classes={{
                label: "normal_login_btn",
              }}
              onClick={handleSubmit}
            >
              {
                isNotNearBy || searchText ? "Clear Search" : "Advanced Search"
              }
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleApiWrapper({ apiKey: configJSON.GOOGLE_MAP_API_KEY })(RestaurantSearchFilters)
// Customizable Area End