import React from "react"
import "../../../web/src/assets/css/style.scss"
import AllOrderHistoryController, {
  configJSON,
  Props,
} from "./AllOrderHistoryController.web"
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { veggies } from "./assets"
import { withRouter } from "react-router-dom"
import "../assets/css/orderHistory.scss"
import OrderDetailsDialog from "./OrderDetails/OrderDetailsDialog.web"

export class CoursesOrderHistory extends AllOrderHistoryController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { loader, orderHistory, isInvoiceDialog, orderInvoiceId } = this.state
    return (
      <>
        {loader ? (
          <Box className="order_history_loader_container">
            <CircularProgress className="order_history_loader" />
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Box className="web_sidebar_link_view_header">
                <h5 data-test-id="header">{configJSON.purchasedCourseText}</h5>
              </Box>
            </Grid>

            <Grid container className="wishlist_card_wrapper" spacing={2}>
              {orderHistory?.purchased_course?.data?.length > 0 ? (
                orderHistory.purchased_course.data.map((order: any) => {
                  const {
                    id,
                    attributes: { course_name, name, image, course_id, status },
                  } = order

                  return (
                    <Grid container spacing={2} item xs={12} key={id}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={2}
                        className="product_image_center"
                      >
                        <Box className="product_image_wrapper">
                          <img
                            src={image?.url || veggies}
                            width="100%"
                            alt="wishlist-product"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <h5 className="product_name">{course_name}</h5>
                        <Typography className="order_history_product_desc">
                          <span>By {name}</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} lg={5}>
                        <Box className="od_course_btns">
                          <Button
                          data-test-id="invoiceBtn"
                            classes={{
                              root: "history_invoice_btn",
                              label: "btn-label",
                            }}
                            onClick={this.openInvoiceDialog.bind(this,id)}
                          >
                            {configJSON.viewInvoiceText}
                          </Button>
                          <Button
                            data-test-id="orderBtn"
                            classes={{
                              root: "track_order_btn",
                              label: "btn-label",
                            }}
                            onClick={this.handleCourseDetailsRedirect.bind(this,status,course_id)}
                          >
                            {configJSON.goToCourseBtnText}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                })
              ) : (
                <Grid
                  item
                  xs={12}
                  className="order_history_blank_container course_od_blank_container"
                >
                  <Typography>
                    {configJSON.noPurchasedCourseText}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {isInvoiceDialog && (
              <OrderDetailsDialog
              data-test-id="invoiceDialog"
                isOpen={isInvoiceDialog}
                orderDetailsId={orderInvoiceId}
                handleClose={this.closeInvoiceDialog}
              />
            )}
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(CoursesOrderHistory)
