// import * as React from "react";
import React, { useState } from "react";
// custom components
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Avatar,
  Button,
} from "@material-ui/core";

import "../../web/src/assets/css/content/button.scss";
import "../../web/src/assets/css/content/tab.scss";
import CustomChips from "./chip";
import CustomButton from "./CustomButton.web";
import { Apple_Icon } from "../../blocks/catalogue/src/assets";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomCourseDetailTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <div className="customTab courseDetailTab">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: "green",
            height: "4px"
          }
        }}
      >
        <Tab label="Course Details " {...a11yProps(0)} />
        <Tab label="About Educator" {...a11yProps(1)} />
        <Tab label="Student Reviews" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0} className="orderTab tabPanel">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
          atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem. Facilis
          velit error voluptates placeat saepe rem perspiciatis. Aspernatur ullam impedit nam dolorem vitae beatae tempora ipsum
          eligendi atque error, distinctio, recusandae aperiam! Nisi quos, harum sequi similique numquam dicta, dolores accusantium
          repudiandae dolorem, esse eaque mollitia. Doloribus alias quam veniam earum neque unde ab adipisci libero, fuga vel sit
          perspiciatis omnis iure quo, aliquam praesentium necessitatibus. Ut voluptatem reiciendis a quidem, amet deleniti ullam
          temporibus quasi mollitia libero dignissimos ratione nesciunt deserunt ab odio asperiores quibusdam animi rerum dolores
          pariatur sed doloremque labore necessitatibus molestiae. Tempora accusantium porro quia, enim in inventore quidem omnis
          qui dolores alias animi, soluta aliquid unde modi incidunt, sint expedita corrupti architecto eveniet dignissimos fugiat
          eaque molestias? Vitae veritatis atque delectus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
          atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem. Facilis
          velit error voluptates placeat saepe rem perspiciatis. Aspernatur ullam impedit nam dolorem vitae beatae tempora ipsum
          eligendi atque error, distinctio, recusandae aperiam! Nisi quos, harum sequi similique numquam dicta, dolores accusantium
          repudiandae dolorem, esse eaque mollitia. Doloribus alias quam veniam earum neque unde ab adipisci libero, fuga vel sit
          perspiciatis omnis iure quo, aliquam praesentium necessitatibus. Ut voluptatem reiciendis a quidem, amet deleniti ullam
          temporibus quasi mollitia libero dignissimos ratione nesciunt deserunt ab odio asperiores quibusdam animi rerum dolores
          pariatur sed doloremque labore necessitatibus molestiae. Tempora accusantium porro quia, enim in inventore quidem omnis
          qui dolores alias animi, soluta aliquid unde modi incidunt, sint expedita corrupti architecto eveniet dignissimos fugiat
          eaque molestias? Vitae veritatis atque delectus?
        </p>
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
          atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem. Facilis
          velit error voluptates placeat saepe rem perspiciatis. Aspernatur ullam impedit nam dolorem vitae beatae tempora ipsum
          eligendi atque error, distinctio, recusandae aperiam! Nisi quos, harum sequi similique numquam dicta, dolores accusantium
          repudiandae dolorem, esse eaque mollitia. Doloribus alias quam veniam earum neque unde ab adipisci libero, fuga vel sit
          perspiciatis omnis iure quo, aliquam praesentium necessitatibus. Ut voluptatem reiciendis a quidem, amet deleniti ullam
          temporibus quasi mollitia libero dignissimos ratione nesciunt deserunt ab odio asperiores quibusdam animi rerum dolores
          pariatur sed doloremque labore necessitatibus molestiae. Tempora accusantium porro quia, enim in inventore quidem omnis
          qui dolores alias animi, soluta aliquid unde modi incidunt, sint expedita corrupti architecto eveniet dignissimos fugiat
          eaque molestias? Vitae veritatis atque delectus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
          atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem. Facilis
          velit error voluptates placeat saepe rem perspiciatis. Aspernatur ullam impedit nam dolorem vitae beatae tempora ipsum
          eligendi atque error, distinctio, recusandae aperiam! Nisi quos, harum sequi similique numquam dicta, dolores accusantium
          repudiandae dolorem, esse eaque mollitia. Doloribus alias quam veniam earum neque unde ab adipisci libero, fuga vel sit
          perspiciatis omnis iure quo, aliquam praesentium necessitatibus. Ut voluptatem reiciendis a quidem, amet deleniti ullam
          temporibus quasi mollitia libero dignissimos ratione nesciunt deserunt ab odio asperiores quibusdam animi rerum dolores
          pariatur sed doloremque labore necessitatibus molestiae. Tempora accusantium porro quia, enim in inventore quidem omnis
          qui dolores alias animi, soluta aliquid unde modi incidunt, sint expedita corrupti architecto eveniet dignissimos fugiat
          eaque molestias? Vitae veritatis atque delectus?
        </p>
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur omnis, minus nam molestias, numquam cumque id asperiores
          atque, ad modi ex dolor obcaecati eligendi tempore veniam enim et facere doloremque repellat molestiae voluptatem. Facilis
          velit error voluptates placeat saepe rem perspiciatis. Aspernatur ullam impedit nam dolorem vitae beatae tempora ipsum
          eligendi atque error, distinctio, recusandae aperiam! Nisi quos, harum sequi similique numquam dicta, dolores accusantium
          repudiandae dolorem, esse eaque mollitia. Doloribus alias quam veniam earum neque unde ab adipisci libero, fuga vel sit
          perspiciatis omnis iure quo, aliquam praesentium necessitatibus. Ut voluptatem reiciendis a quidem, amet deleniti ullam
          temporibus quasi mollitia libero dignissimos ratione nesciunt deserunt ab odio asperiores quibusdam animi rerum dolores
          pariatur sed doloremque labore necessitatibus molestiae. Tempora accusantium porro quia, enim in inventore quidem omnis
          qui dolores alias animi, soluta aliquid unde modi incidunt, sint expedita corrupti architecto eveniet dignissimos fugiat
          eaque molestias? Vitae veritatis atque delectus?
        </p>

      </TabPanel>
    </div>
  );
};
export default CustomCourseDetailTab;
