import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { getCoords } from "../../../components/src/utility/helper";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  close?: any;
  open?: any;
  userInfo: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  org_name: string;
  org_address: string;
  org_owner_name: string;
  org_owner_address: string;
  org_contact_number: string;
  org_owner_contact_number: string;
  latitude: number;
  longitude: number;
  mapCoOrdinates: {
    lat: any,
    lng: any
  }
  address: string
  detectedLatitude: number;
  detectedLongitude: number;
  detectLocation: boolean;
  createFarmerProfileApiResponse: object;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  openModal: boolean;
  closeModal: boolean;
  token: string;
  createProfile: boolean;
  display: any;
  verify_OTP_click: boolean;
  verify_OTP_click1: boolean;
  isVerify_OTP_disable: boolean;
  OTP_Error: any;
  OTP_Error_msg: any;
  isChecked: any;
  OTP: string;
  OTP1: string,
  OTP2: string,
  OTP3: string,
  OTP4: string,
  OTP_header_diplay: any,
  isValid: any,
  isOwner: any,
  OTP5: string;
  OTP6: string,
  OTP7: string,
  OTP8: string,
  OTP9: string,
  Onwer_OTP_header_diplay: any,
  Owner_isValid: any,
  Owner_OTP_Error: any,
  Owner_isDisable: any,
  otpSuccess: boolean,
  SMSOTPID: any;
  mobileType: any;
  otpclick: any;
  ownerOtp: any;
  isLoading: boolean;
  createError: any[]
  orgContactNumberError: string;
  orgOwnerContactNumberError: string;
  createProfileSchema: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateFarmerProfileWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  mobileReg: RegExp;
  labelTitle: string = "";
  PathRoleID: string = "";
  updateRolesApiCallId: string = "";
  postCreateContributerApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.isNumberNullOrBlank = this.isNumberNullOrBlank.bind(this);
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ]

    let CreateProfileSchema = Yup.object().shape({
      org_name: Yup.string().required("This field is required"),
      org_address: Yup.string().required("This field is required"),
      org_owner_name: Yup.string().required("This field is required"),
      org_owner_address: Yup.string()
        .email("Invalid email address format")
        .required("This field is required"),

      org_contact_number: Yup.string()
        .required("This field is required")
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number"),
      org_owner_contact_number: Yup.string()
        .required("This field is required")
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number"),
      latitude: Yup.string(),
      longitude: Yup.string()
    })

    this.state = {
      org_name: "",
      org_address: "",
      org_owner_name: "",
      org_owner_address: "",
      org_contact_number: "",
      org_owner_contact_number: "",
      latitude: 0,
      longitude: 0,
      mapCoOrdinates: {
        lat: "",
        lng: ""
      },
      address: "",
      detectedLatitude: 0,
      detectedLongitude: 0,
      detectLocation: false,
      createFarmerProfileApiResponse: {},
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      openModal: false,
      closeModal: false,
      token: "",
      createProfile: false,
      createProfileSchema: CreateProfileSchema,
      orgContactNumberError: "",
      orgOwnerContactNumberError: "",
      display: "",
      OTP_Error: "",
      OTP_Error_msg: "",
      isChecked: false,
      OTP_header_diplay: "block",
      verify_OTP_click: false,
      verify_OTP_click1: false,
      isVerify_OTP_disable: false,
      isValid: true,
      otpSuccess: false,
      OTP: "",
      OTP1: "",
      OTP2: "",
      OTP3: "",
      OTP4: "",
      OTP5: "",
      OTP6: "",
      OTP7: "",
      OTP8: "",
      OTP9: "",
      Onwer_OTP_header_diplay: "block",
      Owner_isValid: false,
      Owner_OTP_Error: false,
      Owner_isDisable: false,
      SMSOTPID: "",
      mobileType: "",
      otpclick: false,
      ownerOtp: false,
      isOwner: false,
      isLoading: false,
      createError: [],
    }

    this.emailReg = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    this.mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    this.PathRoleID = window.location.pathname.split(":")[1];
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0 || str === undefined || str === "";
  }

  isNumberNullOrBlank(number: number) {
    return number === null || number === undefined || number === 0;
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  async componentWillUnmount() {
    // @ts-ignore
    this.props.resatOTPFlags()
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (
        responseJson &&
        this.state.createProfile &&
        !responseJson.hasOwnProperty("errors")
      ) {
        this.successApiCall(responseJson, apiRequestCallId)
      } else {
        this.setState({
          isLoading: false,
          createError: responseJson.errors ? responseJson.errors : "",
        })


      }
    }
  }


  // success api call 

  successApiCall = async (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.setState({
        isLoading: false,
        openModal: true,
      })
      // @ts-ignore
      this.props.verifyUserAction(this.props.history)
    }
    if (apiRequestCallId === this.postCreateContributerApiCallId) {
      this.setState({
        openModal: true
      })
      this.updateRole()
      // @ts-ignore
          this.props.verifyUserAction(this.props.history)
      toast.success("Contributer Created Successfully")
    }

    if (responseJson?.data?.attributes?.is_admin) {
      await StorageProvider.set(
        "AdminAuth",
        responseJson?.data?.attributes?.is_admin
      )
    }
  }


  showModal() {
    this.setState({ openModal: true })
  }

  closeModal() {
    this.setState({ openModal: false })
  }







  //creating contributer
  createContributor = async (values: any) => {

    const token = await StorageProvider.get("authToken")

    const headers = {
      token:
        token
    };


    const formdata = new FormData();
    formdata.append("educational_contributor[first_name]", values.firstName);
    formdata.append("educational_contributor[last_name]", values.lastName);
    formdata.append("educational_contributor[headline]", values.heading);
    formdata.append("educational_contributor[biography]", values.title);
    formdata.append("educational_contributor[youtube]", values.youTube);
    formdata.append("educational_contributor[youtube_username]", values.youTubeUsername);
    formdata.append("educational_contributor[twitter]", values.twitter);
    formdata.append("educational_contributor[twitter_username]", values.twitterUsername);
    formdata.append("educational_contributor[facebook]", values.facebook);
    formdata.append("educational_contributor[facebook_username]", values.facebookUsername);
    formdata.append("educational_contributor[linkedin]", values.linkedIn);
    formdata.append("educational_contributor[linkedin_username]", values.linkedInUsername);
    formdata.append("educational_contributor[website]", values.website);
   !values.image.url && values.image[0].name && formdata.append("educational_contributor[educator_image]", values.image[0]);




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateContributerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEducatorProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //update riles
  updateRole = async () => {

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.createProfileContentType,
      token:
        token
    };

    const httpBody = {
      roles: ["3"]

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodUpdateRoles
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
