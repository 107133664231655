// Customizable Area Start
import React from "react"
import { withRouter } from "react-router-dom"
import CommunityDiscussionTagsScreenController, {
  Props,
  IDiscussion,
} from "./CommunityDiscussionTagsScreenController.web"
import { connect } from "react-redux"
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import CommunityDiscussionCard from "./CommunityDiscussionCard.web"
import { setCommunityTagFilterDiscussions } from "../../assets"
import "../../assets/css/community/communityDiscussionList.scss"
import SearchIcon from "@material-ui/icons/Search"

class CommunityDiscussionTagScreen extends CommunityDiscussionTagsScreenController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { isSearching, isNoResultFound, searchText } = this.state
    const { discussions } = this.props

    return (
      <Box>
        <Box className="cdts_main_container">
          <Box>
            <TextField
              autoFocus
              className="cdts_search_field"
              placeholder="Search for tags here"
              value={searchText}
              onChange={this.handleSearchValueChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Box className="cdts_filed_icons_wrapper">
                      <SearchIcon className="cdts_search_icon" />
                      <span className="cdts_hashtag">#</span>
                    </Box>
                  </InputAdornment>
                ),
                endAdornment:
                  isSearching ? (
                    <InputAdornment position="start">
                      <CircularProgress
                        size={20}
                        className="cdts_searching_loader"
                      />
                    </InputAdornment>
                  ) : null,
              }}
            />
          </Box>

          {discussions?.length > 0 ? (
            <>
              {discussions?.map((discussion: IDiscussion) => {
                const {
                  id,
                  attributes: {
                    author_name,
                    date,
                    comment_count,
                    discussion_heading,
                    description,
                    forum_comments,
                    follows,
                  },
                } = discussion

                return (
                  <CommunityDiscussionCard
                    key={id}
                    isTagsCard
                    searchedValue={`#${searchText}`}
                    discussionId={id}
                    heading={discussion_heading}
                    description={description}
                    authorName={author_name}
                    startedDate={date}
                    isFollowed={follows}
                    commentCount={comment_count}
                    comments={forum_comments}
                  />
                )
              })}
            </>
          ) : (
            <Box className="cdl_empty_list_container">
              <Typography>{isNoResultFound ? "No discussions have been found" : "Type any keyword to search through discussions"}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    discussions: state.community.tagFilterDiscussions,
  }
}

export default withRouter(
  connect(mapStateToProps, { setCommunityTagFilterDiscussions })(
    CommunityDiscussionTagScreen
  )
)
// Customizable Area End
