// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  FilledInput,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Dialog,
  Typography,
  withStyles
} from "@material-ui/core";
import React from "react";
import TopBar from "../../../components/src/TopBar.web";
import WhiteBackgroundContainer from "../../../components/src/WhiteBackgroundContainer.web";
import "../assets/css/createroleprofile.scss";
import { withRouter } from "react-router-dom";
import RestaurantDonePageWeb from "./RestaurantDonePage.web";
import CreateRestaurantProfileWebController, {
  Props,
  configJSON
} from "./CreateRestaurantProfileWebController.web";
import { Formik } from "formik";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import CustomGoogleMap from "../../../components/src/CustomGoogleMap.web";
import CustomGooglePlaceAutoComplete from "../../../components/src/CustomGooglePlaceAutoComplete.web";
import {
  sentOTP1Action,
  changeOTP1Verify,
  verifyOTP1Action,
  verifyOTP2Action,
  setSameContactDetails,
  resatOTPFlags,
  verifyUserAction
} from "./assets";
import { connect } from "react-redux";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web";
import CustomLocationErrorMessage from "../../../components/src/CustomLocationErrorMessage.web";
import CustomOTPInput from "../../../components/src/CustomOTPInput.web";
import { createStyles } from "@material-ui/core/styles";
import RewardAcceptanceSelect from "../../catalogue/src/RewardAcceptanceSelect/RewardAcceptanceSelect.web";

export const styles = () =>
  createStyles({
    createRestText: {
      textAlign: "center",
      fontWeight: 600,
      color: "#000000",
      textTransform: "capitalize"
    },
    plusOne: {
      padding: "0px 10px !important",
      color: "#000000",
      fontWeight: 600,
      fontSize: "18px",
      fontFamily: "Montserrat"
    },
    coordinates: {
      width: "100%",
      textAlign: "center"
    },
    ownerAddress: {
      marginTop: "17px !important"
    },
    topPadding: {
      paddingTop: "0 !important"
    },
    otpErr: {
      color: "red",
      paddingLeft: "100px",
      paddingTop: "25px",
      fontFamily: "Montserrat",
      fontSize: "13px"
    }
  });

// Customizable Area End

export class RestaurantRoleProfileLayout extends CreateRestaurantProfileWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {
      createProfileSchema,
      isLoading,
      isChecked,
      orgContactNumberError,
      orgOwnerContactNumberError,
      mapCoOrdinates,
      openModal,
      emailOTP
    } = this.state;
    const { userInfo, classes } = this.props;

    return (
      <>
        <TopBar />
        <WhiteBackgroundContainer>
          <Box className="create_role_main_wrapper">
            <Button
              classes={{
                root: "create_profile_nav_btn create_profile_back",
                disabled: "select_role_move_disabled",
              }}
              onClick={this.handleBack}
            >
              <ArrowBackIcon />
            </Button>
            <Container className="create_profile_container">
              <Box className="create_role_profile_form_container">
                <Formik
                  innerRef={this.formikRef}
                  enableReinitialize
                  initialValues={{
                    org_name: "",
                    org_address: "",
                    org_owner_name: "",
                    org_owner_address: "",
                    org_contact_number: "",
                    org_owner_contact_number: "",
                    latitude: "",
                    longitude: "",
                    purchase_limit_percentage: 0
                  }}
                  validationSchema={createProfileSchema}
                  onSubmit={this.doCreateProfile}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                  }) => {
                    return (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="h3" className="createRestText">
                            {configJSON.createRestProfileText}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" className="create_profile_label">
                              {configJSON.restaurantNameText}
                            </Typography>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder={configJSON.restNamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="org_name"
                                onChange={handleChange}
                              />
                              <FormikErrorMessage
                                error={errors.org_name}
                                isTouched={touched.org_name}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" className="create_profile_label">
                              {configJSON.restAddressText}
                            </Typography>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder={configJSON.restAddressPlaceholder}
                                inputProps={{ maxLength: 200 }}
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="org_address"
                                onChange={handleChange}
                              />
                              <FormikErrorMessage
                                error={errors.org_address}
                                isTouched={touched.org_address}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" className="create_profile_label">
                              {configJSON.locationHeadingText}
                            </Typography>
                            <Typography className="create_profile_label_text">
                              {configJSON.locationParaText}
                            </Typography>

                            <CustomLocationErrorMessage
                              isLatError={errors.latitude}
                              isLatTouched={touched.latitude}
                              isLngError={errors.longitude}
                              isLngTouched={touched.longitude}
                            />

                            <CustomGooglePlaceAutoComplete
                              placeholder={configJSON.locationPlaceholder}
                              lat={Number(mapCoOrdinates.lat)}
                              lng={Number(mapCoOrdinates.lng)}
                              detectPlace={this.detectPlace}
                              setFieldValue={setFieldValue}
                              handlePlaceSelect={this.handlePlacesAutocompleteSelect}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomGoogleMap
                            lat={mapCoOrdinates.lat}
                            lng={mapCoOrdinates.lng}
                            handleDragEnd={(lat, lng) =>
                              this.handleChangeLatLng(lat, lng)
                            }
                          />
                        </Grid>
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={1} sm={3} md={4}>
                            <Box className="create_role_profile_hr_line">
                              <hr />
                            </Box>
                          </Grid>
                          <Grid item xs={10} sm={6} md={4}>
                            <Box className={classes.coordinates}>
                              <Typography className="create_role_profile_desc">
                                {configJSON.cordinatesText}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1} sm={3} md={4}>
                            <Box className="create_role_profile_hr_line">
                              <hr />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Latitude"
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                value={values.latitude}
                                name="latitude"
                                onChange={this.handleChangeLat}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="web_input_field">
                              <FilledInput
                                placeholder="Longitude"
                                classes={{
                                  root: "create_role_profile_input_root",
                                  input: "create_role_profile_input",
                                }}
                                name="longitude"
                                value={values.longitude}
                                onChange={this.handleChangeLng}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className="create_profile_label">
                              {configJSON.contactNumberHeading}
                            </Typography>
                          </Grid>
                          <Grid item container xs={12} sm={10} md={7} lg={6}>
                            <Box>
                              <Typography className="create_profile_label_text">
                                {configJSON.contactNumberPara}
                              </Typography>
                              <Grid item container xs={12} spacing={3}>
                                <Grid item xs={12} sm={9}>
                                  <Box className="create_role_profile_mobile_number_input_wrapper">
                                    <Box>
                                      <Typography className={classes.plusOne}>
                                        {configJSON.plusOneText}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <FilledInput
                                        placeholder={
                                          configJSON.contactNumberPlaceholder
                                        }
                                        classes={{
                                          root: "create_role_profile_detect_location_input_root",
                                          input: "create_role_profile_input",
                                        }}
                                        name="org_contact_number"
                                        disableUnderline
                                        inputProps={{ maxLength: 10 }}
                                        onChange={handleChange}
                                        onFocus={this.changeMobileNumber}
                                        onBlur={this.handleBlurOrgNumber}
                                      />
                                    </Box>
                                  </Box>
                                  <FormikErrorMessage
                                    extraError={orgContactNumberError}
                                    error={errors.org_contact_number}
                                    isTouched={touched.org_contact_number}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            className="create_profile_owner_heading"
                          >
                            {configJSON.regOwnerDetailsText}
                          </Typography>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className="create_profile_label">
                              {configJSON.ownerDetailsText}
                            </Typography>
                          </Grid>
                          <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} sm={8} md={5}>
                              <Box className="web_input_field">
                                <FilledInput
                                  placeholder={configJSON.ownerNamePlaceholder}
                                  inputProps={{ maxLength: 100 }}
                                  classes={{
                                    root: "create_role_profile_input_root",
                                    input: "create_role_profile_input",
                                  }}
                                  name="org_owner_name"
                                  onChange={handleChange}
                                />
                                <FormikErrorMessage
                                  error={errors.org_owner_name}
                                  isTouched={touched.org_owner_name}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={8} md={5}>
                              <Box className="create_role_profile_mobile_number_input_wrapper">
                                <Box>
                                  <Typography className={classes.plusOne}>
                                    {configJSON.plusOneText}
                                  </Typography>
                                </Box>
                                <Box>
                                  <FilledInput
                                    value={values.org_owner_contact_number}
                                    placeholder={configJSON.ownerMobNoPlaceholder}
                                    classes={{
                                      root: "create_role_profile_detect_location_input_root",
                                      input: "create_role_profile_input",
                                    }}
                                    name="org_owner_contact_number"
                                    disableUnderline
                                    inputProps={{ maxLength: 10 }}
                                    onChange={handleChange}
                                    onFocus={this.changeMobileNumber1}
                                    disabled={userInfo.isSameContactDetails}
                                  />
                                </Box>
                              </Box>
                              <FormikErrorMessage
                                extraError={orgOwnerContactNumberError}
                                error={errors.org_owner_contact_number}
                                isTouched={touched.org_owner_contact_number}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                              <RadioGroup aria-label="quiz" name="quiz">
                                <FormControlLabel
                                  checked={isChecked}
                                  value={values.org_contact_number}
                                  control={
                                    <Radio
                                      classes={{ checked: "radio_checked" }}
                                      onClick={this.sameAsPrevious}
                                    />
                                  }
                                  label={configJSON.sameAsRestaurantLabel}
                                  classes={{
                                    root: "radio",
                                    label: "radio_label create_profile_radio",
                                  }}
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                          <Grid item sm={12}>
                            <Grid item xs={12}>
                              <form onSubmit={this.doVerifyMobile}>
                                <Grid item container xs={12} spacing={3}>
                                  <Grid item xs={12} sm={9} md={5}>
                                    <Box
                                      className={`web_input_field ${classes.ownerAddress}`}
                                    >
                                      <FilledInput
                                        placeholder={
                                          configJSON.ownerEmailAddPlaceholder
                                        }
                                        inputProps={{ maxLength: 100 }}
                                        classes={{
                                          root: "create_role_profile_input_root",
                                          input: "create_role_profile_input",
                                        }}
                                        name="org_owner_address"
                                        onChange={this.handleEmailValueChange}
                                      />
                                      <FormikErrorMessage
                                        error={errors.org_owner_address}
                                        isTouched={touched.org_owner_address}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={2}>
                                    <Button
                                      type="submit"
                                      className={classes.ownerAddress}
                                      classes={{
                                        root: "create_role_profile_btn",
                                        disabled: "create_role_profile_btn_disabled",
                                      }}
                                      disabled={
                                        userInfo.verifiedOTP1 ||
                                        !values.org_owner_address
                                      }
                                    >
                                      {userInfo.loadingOTP1 ? (
                                        <CircularProgress size={24} color="inherit" />
                                      ) : (
                                        configJSON.verifyBtnText
                                      )}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    className={classes.topPadding}
                                  >
                                    <Box>
                                      {!userInfo.verifiedOTP1 ? (
                                        <>
                                          <p className="OTP_header1">
                                            {configJSON.enterOTPText}
                                          </p>
                                          <Box className="create_role_profile_otp_input_container">
                                            <CustomOTPInput
                                              value={emailOTP}
                                              isDisabled={
                                                !userInfo.isOTP1Sent ||
                                                !values.org_owner_address ||
                                                userInfo.loadingOTPVerification1
                                              }
                                              onChange={this.handleEmailOTPChange}
                                            />
                                            {userInfo.loadingOTPVerification1 && (
                                              <Box className="otp_loader_container">
                                                <CircularProgress size={25} />
                                              </Box>
                                            )}
                                          </Box>
                                        </>
                                      ) : (
                                        <Box className="web_input_field">
                                          <Typography className="OTP_Success_Msg1">
                                            <CheckCircleOutlineOutlined />{" "}
                                            {configJSON.verifiedText}
                                          </Typography>
                                        </Box>
                                      )}
                                      {userInfo.verificationErrorOTP1 && (
                                        <Typography className={classes.otpErr}>
                                          {configJSON.invalidOTPText}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </form>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Box mt={2}>
                              <hr />
                              <RewardAcceptanceSelect
                                value={values.purchase_limit_percentage}
                                name="purchase_limit_percentage"
                                handleValueChange={(value: number) =>
                                  setFieldValue("purchase_limit_percentage", value)
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <Button
                            classes={{ root: "create_role_profile_btn" }}
                            disabled={isLoading}
                            onClick={() => handleSubmit()}
                          >
                            {isLoading ? (
                              <CircularProgress size={30} color="inherit" />
                            ) : (
                              configJSON.nextBtnText
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  }}
                </Formik>
              </Box>
            </Container>

            <Button
              disabled
              classes={{
                root: "create_profile_nav_btn create_profile_right",
                disabled: "select_role_move_disabled",
              }}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </WhiteBackgroundContainer>
        <Dialog
          disableEscapeKeyDown
          maxWidth="sm"
          open={openModal}
          onClose={(_: object, reason: string) =>
            this.handleDialogOnClose(reason)
          }
          classes={{
            paper: "custom_dialog_paper"
          }}
        >
          <RestaurantDonePageWeb />
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
};
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {
        sentOTP1Action,
        changeOTP1Verify,
        verifyOTP1Action,
        verifyOTP2Action,
        setSameContactDetails,
        resatOTPFlags,
        verifyUserAction
      }
    )(RestaurantRoleProfileLayout)
  )
);
// Customizable Area End
