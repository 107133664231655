Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpGetMethod = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LocalListingAds";
exports.labelBodyText = "LocalListingAds Body";
exports.btnExampleTitle = "CLICK ME";
exports.productApiContentType = "application/json"
exports.apiMethodTypeGet = "GET"
exports.apiMethodTypePost = "POST"
exports.apiMethodTypePut = "PUT"
exports.apiMethodTypePatch = "PATCH"
exports.apiMethodTypeDelete = "DELETE"
exports.productAPiEndPoint = "catalogue/catalogues"
exports.AUTH_TOKEN = "authToken"
exports.deactivateText = "deactivate"
exports.deactiveText = "deactive"
exports.createText = "create"
exports.editText = "edit"
exports.activeText = "active"
exports.draftStatus = "draft"
exports.duplicateText = "duplicate"
exports.modeText = "mode"
exports.campaignsText = "campaigns"
exports.campaignIdText = "campaign_id"
exports.addedText = "added"
exports.updatedText = "updated"
exports.deleteText = "Delete"
exports.idText = "id"
exports.noText = "No"
exports.yesText = "Yes"
exports.allowedExtensionText = ["image/jpeg", "image/jpg", "image/png"]
exports.createButtonText = "Create"
exports.saveText = "Save"
exports.editDishText = "Edit"
exports.updateDishText = "Update"
exports.dragFileText = "Drag and drop a file or click here"
exports.addMediaText = "Add Media"
exports.restaurantLabel = "restaurant"
exports.headingText = "Heading"
exports.tokenExpiredText = "Token has Expired"
exports.invalidTokenText = "Invalid token"
exports.UpdatedText = "Updated"
exports.AddedText = "Added"
exports.adCampaignApiEndPoint = "user_admin/campaign_ads"
exports.statusUpdateAdCampaignApiEndPoint = "user_admin/campaign_ads/status_update"
exports.bulkStatusUpdateAdCampaignApiEndPoint = "user_admin/campaign_ads/bulk_status_update"
exports.adCampaignRatesApiEndPoint = "others/get_ad_campaign_rates"
exports.adCampaignEstimationCostApiEndPoint = "user_admin/campaign_ads/estimated_cost_calculation"
exports.yesText = "YES"
exports.noText = "NO"
exports.priceText = "price"
exports.campaignTitle = "Campaign"
exports.ofText = "of"
exports.itemsText = "items"
exports.roundedText = "rounded"
exports.activeLabel = "Active"
exports.deactiveLabel = "Deactivate"
exports.draftLabel = "Draft"
exports.statusLabel = "Status"
exports.farmTitle = "Farm"
exports.restaurantTitle = "Restaurant"
exports.SomethingWentWrongMessage = "Something went wrong"
exports.campaignCreatedMessage = "Ad campaign created successfully"
exports.campaignUpdatedMessage = "Ad campaign updated successfully"
exports.adCampaignLabel = "Ad Campaign"
exports.campaignNameText = "Campaign Name"
exports.selectBusinessText = "Select Business"
exports.selectCampaignText = "Select Campaign"
exports.ratesText = "Rates"
exports.cpmText = "CPM"
exports.cpcText = "CPC"
exports.fromText = "From"
exports.toText = "To"
exports.startsAtText = "Starts at"
exports.endsAtText = "Ends at"
exports.estimatedCostText = "Estimated Cost"
exports.totalCostText = "Total Cost"
exports.campaignStatusText = "Campaign status"
exports.localCampaignText = "Local Campaign"
exports.globalCampaignText = "Global Campaign"
exports.advertisementCreativeText = "Advertisement Creative"
exports.selectBusinessTileText = "Select Business's tile"
exports.uploadCreativeAssetsText = "Upload Creative/Assets"
exports.operationalHoursText = "Operational hours (UTC)"
exports.adCampaignSearchPlaceholder = "Search by Name, Id etc..."
exports.createCampaignText = "Create campaign"
exports.createCampaignButtonText = "Create Campaign"
exports.noAdCampaignsFoundMessage = "No ad campaigns found"
exports.editCampaignMenu = "Edit"
exports.duplicateCampaignMenu = "Duplicate"
exports.submitLabel = "Submit"
exports.descriptionText = "Description"
exports.allowedImageExtensionsMessage = "Only JPEG, JPG, and PNG files are allowed"
exports.allowedImageExtensions = "image/png, image/jpeg, image/jpg"
exports.getFarmsAPiEndPoint = "user_admin/farms"
exports.getAllRestaurants = "user_admin/restaurants"
exports.sponsoredLabel = "Sponsored"
exports.AD_CAMPAIGN_COLUMNS = ["CAMPAIGN NAME", "CAMPAIGN ID", "STATUS", "SPENT", ""]
exports.DATE_FORMAT = "MM/DD/YYYY"
exports.TIME_FORMAT = "HH"
exports.TIME_PAYLOAD_FORMAT = "HH:mm:ss"
exports.TIME_VIEWS = ["hours"]
exports.RESPONSE_TIME_FORMAT = "HH:mm:ss"
exports.activeText = "Active"
exports.pauseText = "Pause"
exports.scheduledText = "Scheduled"
exports.underscoreBlankText = "_blank"
exports.adAltText = "Ad image"
exports.scheduledText = "Scheduled"
exports.expiredText = "Expired"

exports.MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
}

exports.adCampaignStatus = [
  {
    label: "Scheduled",
    value: "scheduled",
    className: "scheduledMenu",
  },
  {
    label: "Active",
    value: "active",
    className: "activeMenu",
  },
  {
    label: "Pause",
    value: "pause",
    className: "pauseMenu",
  },
  {
    label: "Expired",
    value: "expired",
    className: "expiredMenu",
  },
]
// Customizable Area End