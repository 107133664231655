// Customizable Area Start
import React from "react"
import WebFooter from "../../../components/src/WebFooter.web"
import GreenBanner from "../../../components/src/GreenBanner.web"
import DollarIcon from "@material-ui/icons/AttachMoneyRounded"
import {
  Grid,
  Box,
  Container,
  Button,
  FilledInput,
  TextareaAutosize,
  FormControl,
  CircularProgress,
} from "@material-ui/core"
import "./assets/css/donate.scss"
import { Formik, Form } from "formik"
import DonateController, { Props, IInitialValues } from "./DonateController.web"
import FormikErrorMessage from "../../../components/src/FormikErrorMessage.web"
import * as Yup from "yup"
import DonationDialog from "./Donation/DonationDialog.web"
import ShareButton from "../../../components/src/ShareButton.web"
import { getShareTitle } from "../../../components/src/utility/helper"

const LeaveReplySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  phone_number: Yup.string().length(10, "Please enter a valid phone number"),
  comments: Yup.string().required("Comment is required")
})

class Donate extends DonateController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      loader,
      isPostingReply,
      isDonationDialog,
      donationAmount,
      donationDetails: {
        tagline,
        heading,
        description,
        campaign_image
      }
    } = this.state

    return (
      <>
        {loader ? (
          <Box className="chp_loader_container">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className="donate_green_container" />
            <Box
              className="donate_banner"
              style={{ backgroundImage: `url(${campaign_image.url})` }}
            >
              <Grid container>
                <Grid item xs={12} sm={10} lg={9} spacing={3}>
                  <Box>
                    <h1 className="donate_container_header">{tagline}</h1>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="donate_container">
              <Container fixed>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Box className="featured_donate_wrapper web_input_field">
                      <Grid container spacing={3}>
                        <Grid item container xs={12} lg={7} spacing={3}>
                          <Grid item xs={12}>
                            <h4>featured donate</h4>
                            <h2>{heading}</h2>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                          <FormControl component="fieldset">
                            <Box className="amount_donate web_input_field">
                              <p>Amount ($) :</p>
                              <FilledInput
                                startAdornment={<DollarIcon fontSize="small" />}
                                classes={{
                                  root: "donate_input_root",
                                  input: "donate_input",
                                }}
                                value={donationAmount}
                                onChange={this.handleDonationValueChange}
                              />
                            </Box>
                            <Box>
                              <Button
                                classes={{
                                  root: "donate_btn",
                                  label: "donate_btn_label",
                                  disabled: "donate_btn_disabled",
                                }}
                                disabled={Number(donationAmount) <= 0}
                                onClick={this.handleDonationDialogOpen}
                              >
                                Donate
                              </Button>
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <p className="donate_description">{description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ShareButton
                      link={window.location.href}
                      title={getShareTitle('Donation Campaign')}
                    />
                  </Grid>
                </Grid>

                <Formik
                  innerRef={this.leaveReplyFormRef}
                  initialValues={this.initialValues}
                  validationSchema={LeaveReplySchema}
                  onSubmit={(values: IInitialValues) =>
                    this.handleLeaveReplySubmit(values)
                  }
                  validateOnChange={true}
                  validateOnBlur={false}
                >
                  {({ values, touched, errors, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Box className="donate_leave_reply">
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Box>
                                <h4>leave a reply</h4>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <p>
                                  Your email address will not be published. Required
                                  fields are marked *
                                </p>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <Box className="web_input_field">
                                <FilledInput
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  placeholder="Name*"
                                  classes={{
                                    root: "donate_reply_input_root",
                                    focused: "donate_input_focused",
                                    input: "donate_input",
                                  }}
                                />
                                <FormikErrorMessage
                                  error={errors.name}
                                  isTouched={touched.name}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <Box className="web_input_field">
                                <FilledInput
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  placeholder="Email*"
                                  classes={{
                                    root: "donate_reply_input_root",
                                    focused: "donate_input_focused",
                                    input: "donate_input",
                                  }}
                                />
                                <FormikErrorMessage
                                  error={errors.email}
                                  isTouched={touched.email}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <Box className="web_input_field">
                                <FilledInput
                                  name="phoneNumber"
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  placeholder="Phone"
                                  classes={{
                                    root: "donate_reply_input_root",
                                    focused: "donate_input_focused",
                                    input: "donate_input",
                                  }}
                                />
                                <FormikErrorMessage
                                  error={errors.phoneNumber}
                                  isTouched={touched.phoneNumber}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <TextareaAutosize
                                  name="comments"
                                  value={values.comments}
                                  onChange={handleChange}
                                  placeholder="Comment*"
                                  className="donate_text_area"
                                  minRows={6}
                                />
                                <FormikErrorMessage
                                  error={errors.comments}
                                  isTouched={touched.comments}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3} lg={2}>
                              <Button
                                type="submit"
                                classes={{
                                  root: "donate_btn_2",
                                  label: "donate_btn_label",
                                }}
                                disabled={isPostingReply}
                              >
                                {isPostingReply ? (
                                  <CircularProgress color="inherit" size={22} />
                                ) : (
                                  "Submit Comments"
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Form>
                    )
                  }}
                </Formik>
              </Container>
            </Box>

            <DonationDialog
              isOpen={isDonationDialog}
              handleClose={this.handleDonationDialogClose}
            />
          </>
        )}

        <GreenBanner />
        <WebFooter />
      </>
    )
  }
}

export default Donate
// Customizable Area Start
