// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {isTokenExpired} from "../../../components/src/utility/helper"



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  routeUrl: any;
  history?: any;
  classes?: any;
  rightSidevalues?: any;
  handleTabChange?: any;
  logout: any;
}

interface S {
  arrayHolder: any;
  token: string;
  tableData: any[];
  selected: any[];
  prodPerPage: any;
  currentPage: any;
  farmsList: any;
  productList: any;
  loading: any;
  currentFarmId: any;
  categories: any;
  categorySelected: any;
  sortValue: any;
  openDialog: any;
  removedProduct: any;
  search: any;
  courseDetails: any;
  dateTimepickerValue: any
  productMode: any;
  bulkAction: any;
  restOrderModal: boolean;
  educatorsList: any;
  educatorsRef: any;
  myRef: any;
  contributor_id: any;
  total_count: any,
  total_pages: any,

}

interface SS {
  id: any;
}

export default class AdminProductListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDropdownApiCallId: string = "";
  getCategoryApiCallId: string = "";
  getCourseListApiCallId: string = "";
  getProductDetailsApiCallId: string = "";
  postCreateProductApiCallId: string = "";
  bulkActionsProductApiCallId: string = "";
  getEducatorsApiCallId: string = "";
  getCourseDetailsApiCallId: string = ""
  getContibuterId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      tableData: [],
      selected: [],
      restOrderModal: false,
      prodPerPage: 5,
      currentPage: 1,
      farmsList: "",
      productList: [],
      loading: false,
      currentFarmId: "",
      categories: "",
      categorySelected: "",
      sortValue: "default",
      openDialog: false,
      removedProduct: "",
      search: "",
      courseDetails: "",
      dateTimepickerValue: new Date("2018-01-01T00:00:00.000Z"),
      educatorsRef: React.createRef(),
      productMode: "",
      bulkAction: "",
      educatorsList: "",
      myRef: React.createRef(),
      contributor_id: "",
      total_count: 0,
      total_pages: 0,


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    let mode = query.get("mode")
    this.setState({
      productMode: mode
    })

    const path=window.location.pathname.split("/")[3] == "course-list"
    if (path) {
      this.getCategory()
      this.getCourseList(this.state.sortValue, this.state.categorySelected, this.state.search, this.state.prodPerPage, this.state.currentPage)
    }
    if (mode == "edit") {
      this.getContibuter()   
    }
    if (mode == "create") {
      this.getContibuter()
    }

  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (apiRequestCallId != null) {
          this.successApiCall(responseJson, apiRequestCallId)
        }
      }
      if (responseJson?.errors) {
        if(await isTokenExpired(responseJson)) return
        else{
          toast.error(responseJson?.errors[0].message)
          this.setState({
            loading:false
          })  
        }     
      }
    }

  }

  // success api call 
  successApiCall = (responseJson: any, apiRequestCallId: any) => {
    //get farms
    if (apiRequestCallId === this.getDropdownApiCallId) {
      const farmId: any = window.location.pathname.split("/")[4];
      const pathFarmId = this.pathFarmIdFinder(responseJson, farmId)
      this.setState({
        farmsList: responseJson.data,
        currentFarmId: pathFarmId
      })
      this.props.history.push(`/admin/farm/products/${pathFarmId}`)
      pathFarmId != 0 && this.getCourseList(this.state.sortValue, this.state.categorySelected, this.state.search, this.state.prodPerPage, this.state.currentPage)
      this.setState({
        loading: false,
      });
    }

    //get category       
    if (apiRequestCallId === this.getCategoryApiCallId) {
      this.setState({
        categories: responseJson,
        categorySelected: "all",
        loading: false,
      });

    }

    // get contributor 
    if (apiRequestCallId === this.getContibuterId) {
      function useQuery() {
        return new URLSearchParams(window.location.search);
      }
      let query = useQuery();
      const mode= query.get("mode") == "edit"
      this.callEducators(query,mode)     
      this.setState({
        contributor_id: responseJson.data.id,
        loading: false,
      });


    }




    //get product list       
    if (apiRequestCallId === this.getCourseListApiCallId) {
      this.setState({
        productList: responseJson.data,
        total_pages: responseJson.meta.pagination.total_pages,
        total_count: responseJson.meta.pagination.total_count,
        loading: false,
        selected: [],
      });

    }

    //get course details 
    if (apiRequestCallId === this.getCourseDetailsApiCallId) {

      this.setState({
        courseDetails: responseJson.data,
        loading: false,
      });

    }

    
    // Product bulk actions
    if (apiRequestCallId === this.bulkActionsProductApiCallId) {
      this.getCourseList(this.state.sortValue, this.state.categorySelected, this.state.search, this.state.prodPerPage, this.state.currentPage)
      this.setState({
        bulkAction: "",
        selected: [],
        // loading: false,
      });
      toast.success(`Courses Status Updated Successfully `);
    }

  

    //create product 
    if (apiRequestCallId === this.postCreateProductApiCallId) {
      const mode = this.state.productMode == "create"
      toast.success(`Course ${mode ? "Added" : "Updated"} Successfully`)
      mode && toast.info("Please add course content")

      this.setState({
        loading: false,
      });
      this.SettingMode(responseJson, mode)


    }



    // get educators 
    if (apiRequestCallId === this.getEducatorsApiCallId) {
      this.setState({
        educatorsList: responseJson.data,
        loading: false,
      });

    }



  }

  pathFarmIdFinder = (responseJson: any, farmId: any) => {
    if (farmId == "00") {
      return responseJson.data.length > 0
        ? responseJson.data[0].id
        : "0"
    } else {
      return window.location.pathname.split("/")[4]
    }
  }

  SettingMode = (responseJson: any, mode: any) => {
    if (mode) {
      this.props.history.push(
        `/admin/learning/add-course?mode=edit&course_id=${responseJson.data.id}&tab=1&contributer_id=${this.state.contributor_id}`
      );
      this.props.handleTabChange("", 1)
    }
    !mode && this.props.history.push(`/admin/learning/course-list/${this.state.contributor_id}`)

  }

  // get category
  getCategory = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCoursesCategoryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getContibuter = async () => {
    const token = await StorageProvider.get("authToken")

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token:token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContibuterId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContributorApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };




  //get getCourseList 
  getCourseList = async (sort: any, filter: any, search: any, per: any, page: any) => {
    this.setState({
      loading: true,
    });

    function sortBy() {
      let newSort = sort
      if (newSort == "default") {
        newSort = ""
      }
      else if (newSort == "high to low") {
        newSort = "high_to_low"
      }
      else if (newSort == "low to high") {
        newSort = "low_to_high"
      }
      else {
        newSort = sort
      }
      return newSort
    }

    const endpoint = `?course[educational_contributor_id]=${window.location.pathname.split("/")[4]}&sort_by=${sortBy()}&filter_by_category_id=${(filter == "all") ? "" : filter}&search=${search}&per=${per}&page=${page}`

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseListAPiEndPoint
      + `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  
  //get  Course Details
  getCourseDetails = async (id: any) => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCourseDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseDetailsAPiEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


 

  //creating course 
  createCourseDetail = async (values: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      token:
        token
    };
    const mode = this.state.productMode == "create"
    const endPoint = configJSON.getCourseDetailsAPiEndPoint + `${!mode ? `/${values.course_id}` : ""}`
    const apiMethod = mode ? configJSON.apiMethodTypePost : configJSON.apiMethodTypePut
    const formdata = new FormData();
    formdata.append("course[title]", values.title);
    formdata.append("course[description]", values.description);
    formdata.append("course[price]", values.price ? values.price : 0);
    formdata.append("course[category_id]", values?.category_id);
    formdata.append("course[status]", values.farm_status);
    formdata.append("course[purchase_limit_percentage]", values.purchase_limit_percentage);
    formdata.append("course[educational_contributor_id]", this.state.contributor_id)
    values.images[0] && values.images[0].name && formdata.append("course[banner_image]", values.images[0]);


    [...values.educators].forEach((educator: any, index: any) => {
      formdata.append(
        `course[educators_attributes][${index}][educator_name]`,
        educator.edu_name || educator.attributes.educator_name

      );
      educator.banner_image && formdata.append(
        `course[educators_attributes][${index}][banner_image]`,
        educator.banner_image[0]

      );
      formdata.append(
        `course[educators_attributes][${index}][educator_detail]`,
        educator.edu_detail || educator.attributes.educator_detail

      );

    })
    let tag1 = values.tags.split(",")
    tag1.forEach((tag: any) => {
      formdata.append(
        "course[tag][]",
        tag
      );
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  //bulk actions
  bulkActions = async (status: any, ids: any) => {
    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken");

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const httpBody = {
      status: status,
      ids: ids,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulkActionsProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkActionsCoursesAPiEndPoint + `?course[educational_contributor_id]=${window.location.pathname.split("/")[4]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get product details
  getEducators = async () => {

    this.setState({
      loading: true,
    });

    const token = await StorageProvider.get("authToken")

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token:
        token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducatorsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducatorsAPiEndPoint + `?course_id=${this.getQuery().get("course_id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  handleRestOrderOpenModal = () => {
    this.setState({ restOrderModal: true });
  };
  handleRestOrderCloseModal = () => {
    this.setState({ restOrderModal: false });
  };

  handleMenuClick = (value: any, item: any) => {
    this.setState({
      removedProduct: item.id
    })
    if (value == "edit") {
      this.props.history.push(
        `/admin/learning/add-course?mode=edit&course_id=${item.id}&tab=0&contributer_id=${window.location.pathname.split("/")[4]}`
      );
    }
    if (value == "remove") {
      this.handleDialog()
    }
  }

  handleClick = (event: React.MouseEvent<unknown>, num: any) => {
    event.preventDefault();
    const selectedIndex = this.state.selected.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  isSelected = (num: any) => this.state.selected.indexOf(num) !== -1;

  handleDialog = () => {

    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  //SCROLL TO TOP
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  //search decouncing
  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);

      }, delay);
    };
  }
  SearchDebounceUpdate = this.SearchDebounce(this.getCourseList,
    1000)

  settingImage = (values: any, ProfileImg: any) => {
    if (values.banner_image) {
      return values.banner_image[0].name
        ? (
          window.URL ||
          window.webkitURL
        )
          //@ts-ignore
          .createObjectURL(
            values
              .banner_image[0]
          )
        : values.banner_image
    } else {
      return ProfileImg
    }
  }
  uploadingEducatorImg = (e: any, setFieldValue: any) => {
    if (e.length > 0) {
      setFieldValue("banner_image", e);
    }
  }

  handlingAccordian = (setFieldValue: any, educator: any) => {
    setTimeout(() => {
      const iseducatorExit = this.state.educatorsList && this.state.educatorsList.length > 0 && this.state.educatorsList.filter((educator: any) => educator.attributes.first_name == (educator.edu_name || educator.attributes.educator_name))

      iseducatorExit.length > 0 ? setFieldValue("edu_select", (educator.edu_name || educator.attributes.educator_name)) : setFieldValue("edu_select", 0)

      const form2 = this.state
        .educatorsRef.current
        .setFieldValue;
      setFieldValue(
        "isEditEducator",
        true
      );
      setFieldValue(
        "editEducatorValues",
        educator
      );


      form2(
        "edu_name",
        educator.edu_name || educator.attributes.educator_name
      );
      form2(
        "edu_detail",
        educator.edu_detail || educator.attributes.educator_detail
      );
      form2(
        "banner_image",
        educator.banner_image || educator.attributes.banner_image
      );
      form2(
        "edu_detail",
        educator.edu_detail || educator.attributes.educator_detail
      );
      form2(
        "id",
        educator.id
      );


    }, 300)
  }

  handleformSubmit = (formikProps: any, values: any) => {
    const formikSetValues = formikProps.setFieldValue;
    const filterEdit = formikProps.values.educators.filter(
      (x: any) =>
        x.id !=
        //@ts-ignore
        formikProps.values.editEducatorValues.id
    );
    formikProps.values.isEditEducator
      ? formikSetValues("educators", [
        ...filterEdit,
        values,
      ])
      : formikSetValues("educators", [
        ...formikProps.values.educators,
        values,
      ]);

    formikSetValues("editEducatorValues", "");
    formikSetValues("isEditEducator", false);
  }

  handleEduName = (setFieldValue: any, e: any) => {
    setFieldValue("edu_name", e.target.value)
    const iseducatorExit = this.state.educatorsList && this.state.educatorsList.length > 0 && this.state.educatorsList.filter((educator: any) => educator.attributes.first_name == e.target.value)

    iseducatorExit.length > 0 ? setFieldValue("edu_select", e.target.value) : setFieldValue("edu_select", 0)
  }

  settingInitialValue = (details: any) => {
    return ({
      title: details?.title || "",
      description: details?.description || "",
      images: details?.banner_image || "",
      course_free: (details && (details.price ? false : true)) || false,
      price: details?.price,
      educators: (details && details.educators) || [],
      isEditEducator: false,
      editEducatorValues: "",
      purchase_limit_percentage: details?.purchase_limit_percentage || 0,
      isExpanded: false,
      course_id:
        details?.id ||
        "",

    })

  }
  buttonUpload = (values: any) => {
    return (values.banner_image ? "Update" : "Upload")

  }
  handlesubmitText = (formikProps: any) => {
    return (
      formikProps.values.isEditEducator
        ? "Save"
        : "Add now"
    )

  }

  courseFreeHandle = (setFieldValue: any, e: any) => {
    setFieldValue(
      "course_free",
      e.target.checked
    );
    setFieldValue("price", e.target.checked ? 0 : "")

  }
  getQuery = () => {
    function useQuery() {
      return new URLSearchParams(window.location.search);
    }
    let query = useQuery();
    return query
  }
  handleEducatorSelect = (e: any, setFieldValue: any) => {
    const educator = this.state.educatorsList.filter((x: any) => x.id == e.target.value)
    setFieldValue(
      "edu_select",
      e.target.value
    );
    if (e.target.value) {
      setFieldValue(
        "edu_name",
        educator[0].attributes.educator_name
      );
      setFieldValue(
        "edu_detail",
        educator[0].attributes.educator_detail
      );
      setFieldValue(
        "banner_image",
        educator[0].attributes.banner_image
      );
    }

  }
  callEducators=(query:any,mode:any)=>{
    if(mode){
      this.getEducators()
    this.getCourseDetails(query.get("course_id"))
    }
  }
  getMenuStyleCss=(status:any)=>{
    if(status=="active"){
      return ""
    }else if(status=="deactivate"){
      return "_menus de_active_menu"
    }else{
      return "draft_menu"
    }
    }

}

// Customizable Area End