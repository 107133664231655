// Customizable Area Start
import React from "react";

//Resources
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
import "../assets/css/adminAddProduct.scss";
// mui components

// custom components
import CustomButton from "../../../components/src/CustomButton.web";
import SearchIcon from "@material-ui/icons/Search";
import {DefaultProfileImg} from "./assets"
//accordian css

import {
  Dialog,
  DialogActions,
  DialogTitle,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  MenuItem,
  Select,
  Paper,
  FormControl,
  withStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { HarvestImage } from "../../../components/src/assets";
import AdminLearningStudentsController from "./AdminLearningStudentsController.web";
import { commonCmptStyles } from "./dropDownCss";
import CustomPagination from "../../../components/src/CustomPagination.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";

//@ts-ignore

//@ts-ignore
import Loader from "../../../components/src/Loader.web";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

interface EnhancedTableProps {
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead className="tableHead">
      <TableRow>
        <TableCell align="center">NAME</TableCell>
        <TableCell align="center">#ID</TableCell>
        <TableCell align="center">EMAIL ID</TableCell>
        <TableCell align="center">STATUS</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
export class AdminLearningStudents extends AdminLearningStudentsController {
  //@ts-ignore
  constructor(props: Props) {
    super(props);
  }

  handleChangeTablePage = (value: number) => {
    this.setState({ currentPage: value });
    this.getStudentList(this.state.course_id,this.state.search, this.state.prodPerPage, value);
  };

  handleIncreasePageView = () => {
    if (this.state.prodPerPage < 25) {
      this.setState({
        prodPerPage: this.state.prodPerPage + 5,
        currentPage: 1,
      });
    this.getStudentList(
      this.state.course_id,
      this.state.search,
      this.state.prodPerPage+5,
      1
    );

    }
  };

  handleDecreasePageView = () => {
    if (this.state.prodPerPage > 5) {
      this.setState({
        prodPerPage: this.state.prodPerPage - 5,
        currentPage: 1,
      });
      this.getStudentList(
        this.state.course_id,
        this.state.search,
        this.state.prodPerPage - 5,
        1
      );
    }
  };

  render() {
    const indexOfLastList = this.state.currentPage * this.state.prodPerPage;
    const indexOfFirstList = indexOfLastList - this.state.prodPerPage;
    const cureentTableList = this.state.studentList
      ? this.state.studentList
      : "";
    const roundPages =
      cureentTableList &&
      Math.floor(this.state.total_count / this.state.prodPerPage);
    let totalListCount;
    if (this.state.currentPage > roundPages) {
      totalListCount = this.state.total_count;
    } else {
      totalListCount = indexOfLastList;
    }
    const { classes } = this.props;
    return (
      <>
        {/* main card container */}
        <div className="tableContainer commonDropdown">
          <Loader loading={this.state.loading} />

          {/* filter section */}
          <div className="headerListContainer ">
            <div className="prodListHeader">
              <div className="blocks">
                <h4>Students</h4>
              </div>
              <div className="blocks">
                <div className="block1">
                  <div className="customSelect farmOrdersDropDown dishCategorySelect studentSelect">
                    <FormControl>
                      <Select
                      data-test-id="selectCourse"
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="menu-customize"
                        value={this.state.course_id}
                        onChange={(e: any) => {
                          const targetValue = e.target.value;
                          this.setState({
                            course_id: targetValue,
                            search: "",
                            currentPage: 1,
                          });
                          this.getStudentList(
                            targetValue,
                            "",
                            this.state.prodPerPage,
                            1
                          );
                          this.props.history.push(
                            `/admin/learning/students/${
                              window.location.pathname.split("/")[4]
                            }/${targetValue}`
                          );
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.farmOrdersDropDown} ${classes.studentSelect}` ,
                          },
                        }}
                      >
                        <MenuItem value={0} className="">
                          All Courses
                          <span className="checkmark">
                            <div className="checkmark_circle" />
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </span>
                        </MenuItem>
                        {this.state.courseList &&
                          this.state.courseList.length > 0 &&
                          this.state.courseList.map(
                            (item: any, index: any) => {
                              return (
                                <MenuItem data-test-id="courseList" value={item.id} key={item.id}>
                                  <span>{item.attributes.title}</span>
                                  <span className="checkmark">
                                    <div className="checkmark_circle" />
                                    <div className="checkmark_stem" />
                                    <div className="checkmark_kick" />
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="prodListHeader prodListBottomRow">
              <div className="outlineTextfield" style={{ width: "100%" }}>
                <div className="customTextfield">
                  <TextField
                  data-test-id="searchStudent"
                    id="input-with-icon-textfield"
                    value={this.state.search}
                    onChange={(e: any) => {
                      this.setState({
                        search: e.target.value,
                        currentPage: 1,
                      });
                      this.SearchDebounceUpdate(
                        this.state.course_id,
                        e.target.value,
                        this.state.prodPerPage,
                        1
                      );
                    }}
                    variant="standard"
                    placeholder="Search by Name, type, Id, etc... "
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className="searchIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* table section */}
          <div className="table-block adminLearningCourseTable">
            <TableContainer
              component={Paper}
              className="adminLearningCourseTable"
            >
              <Table aria-label="simple table">
                <EnhancedTableHead
                  data-test-id="table"
                  numSelected={this.state.selected.length}
                  rowCount={cureentTableList?.length}
                />
                <TableBody>
                  {cureentTableList && cureentTableList.length > 0 ? (
                    cureentTableList.map((item: any) => {
                      const x = item.attributes;
                      return (
                        <TableRow key={item.id}>
                          <TableCell
                            component={"th" as any}
                            scope="row"
                            align="center"
                          >
                            <div
                              className="productCell"
                            >
                              <img
                                src={x.profile_pic || DefaultProfileImg}
                                className="prodImage"
                                alt="profile"
                              />
                              <h5>
                                {x.first_name} {x.last_name}
                              </h5>
                            </div>
                          </TableCell>

                          <TableCell align="center" className="BoldCell">
                            #{item.id}
                          </TableCell>

                          <TableCell
                            align="center"
                            // className="paymentCell"
                          >
                            {x.email}
                          </TableCell>
                          <TableCell align="center">
                            {/* itemStatus */}
                            <div
                              className={`customSelect studentStatus ${x.student_status ==
                                "blocked" &&
                                "studentBlock"} ${x.student_status ==
                                "active" && "studentActive"}`}
                            >
                              {x.student_status}
                              {/* class for red: danger-variant warning-variant*/}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="option-dropDown">
                              <BasicMenu
                                item={item}
                                menu={this.handleMenu(x.student_status)}
                                handleMenuClick={this.handleMenuClick}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ width: "100%", padding: "0" }}
                      >
                        <div className="norecord-container">
                          <img src={HarvestImage} alt="harvest" />

                          <h3>
                            No{" "}
                            <span
                              style={{
                                textTransform: "lowercase",
                                color: "#20a258",
                              }}
                            >
                              Students
                            </span>{" "}
                            listed
                          </h3>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {cureentTableList?.length > 0 && (
              <div className="paginationRow restOrderPagination">
                <div>
                  {indexOfFirstList + 1}- {totalListCount} of{" "}
                  {this.state.total_count} items
                </div>
                <CustomPagination
                data-test-id="pagination"
                  count={this.state.total_pages}
                  page={this.state.currentPage}
                  pagePerView={this.state.prodPerPage}
                  handlePageChange={this.handleChangeTablePage}
                  shape="rounded"
                  siblingCount={1}
                  increasePagePerView={this.handleIncreasePageView}
                  decreasePagePerView={this.handleDecreasePageView}
                  boundaryCount={1}
                />
              </div>
            )}
          </div>

        </div>
      </>
    );
  }
}

export default withRouter(
  //@ts-ignore
  withStyles(commonCmptStyles)(AdminLearningStudents)
);
// Customizable Area End
