import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom"
import StorageProvider from "../../../../../framework/src/StorageProvider.web"
import { isTokenExpireResponse } from "../../../../../components/src/utility/helper";
import { AppRoutings } from "../../../../../components/src/utility/app-routing";
const configJSON = require("../../config")
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  addresses: any[]
  selectedAddress: string
  orderReceiveType: string
  orderType: string
  checkoutOrders: any
  setShippingLoading: (isLoading: boolean) => void
  getAddressListAction: () => Promise<void>
  setOrderAddress: (address: string) => void
  setCheckoutOrder: (checkoutOrderDetails: any) => void
  setOrderReceiveType: (data: { type: string, id: string | null }) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader: boolean
  addresses: any[]
  pickUpAddresses: any[]
  editAddressData: any
  openModal: boolean
  editAddressModal: boolean
}

interface SS {
  id: any;
}

export default class PickUpWebController extends BlockComponent<Props, S, SS> {

  pickUpAddressesApiCallId:string = ""
  addOrderAddressApiCallId: string = ""

  constructor(props: Props) {
    super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
          loader: false,
          addresses: [],
          pickUpAddresses: [],
          editAddressData: {},
          openModal: false,
          editAddressModal: false          
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loader: true })
    await this.props.getAddressListAction()
    if(this.isPickUp()) this.getPickUpAddresses()
    this.setState({ loader: false })
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { orderReceiveType, selectedAddress, addresses, setOrderAddress } = this.props
    const currentAddress = selectedAddress ? selectedAddress.toString() : ""
    const prevSelectedAddress = prevProps.selectedAddress ? prevProps.selectedAddress.toString() : ""

    if (prevProps.orderReceiveType !== orderReceiveType) {
      this.updateAddressOnListChange()
    }

    if(!this.isPickUp() && addresses?.length > 0 && !selectedAddress) {
      setOrderAddress(addresses[0].id)
    }

    if(currentAddress && (currentAddress !== prevSelectedAddress)) {
      this.addOrderAddressApi()
    }
  }

  updateAddressOnListChange = () => {
    const { selectedAddress, addresses, setOrderAddress } = this.props
    const { pickUpAddresses } = this.state
    const hasAddress = Array.isArray(addresses) && addresses.length > 0;

    if (this.isPickUp()) {
      if (pickUpAddresses.length > 0) {
        setOrderAddress(pickUpAddresses[0].id);
      }
      this.getPickUpAddresses();
    } else if(hasAddress) {
      setOrderAddress(selectedAddress || addresses[0].id);
    }
  }

  loaderCondition = () => {
    const { addresses, orderReceiveType } = this.props
    const { loader, pickUpAddresses } = this.state
    if(orderReceiveType === "delivery") return loader && addresses?.length === 0
    else return loader && pickUpAddresses.length === 0
  }

  isPickUp = () => {
    return this.props.orderReceiveType === "pickup" 
  }

  showModal = () => {
    this.setState({ openModal: true });
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  showEditAddressModal = (addressDetails: any) => {
    this.setState({ editAddressModal: true, editAddressData: addressDetails })
  }

  closeEditAddressModal = () =>  {
    this.setState({ editAddressModal: false })
  }

  handleChangeReceiveType = (e: any) => {
    this.props.setOrderReceiveType({ type: e.target.value, id: "" })
  }

  handleChangeAddress = (e: any) => {
    this.props.setOrderAddress(e.target.value)
  }

  getPickUpAddresses = async () => {
    this.setState({ loader: true });
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN);

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    
    const farmId = this.props.checkoutOrders.data[0].attributes.product_owner_details.data.id

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pickUpAddressesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pickUpAddressesApiEndPoint}${farmId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  addOrderAddressApi = async () => {
    const { orderReceiveType, selectedAddress, location, setShippingLoading } = this.props
    setShippingLoading(true)
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
        "Content-Type": configJSON.productApiContentType,
        token
    }

    const { orderIds }: any = location.state

    const httpBody = {
        address_type: orderReceiveType,
        address_id: selectedAddress,
        order_ids: orderIds
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addOrderAddressApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addDeliveryPickUpAddApiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
}

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    const { history, setOrderAddress, setCheckoutOrder, setShippingLoading } = this.props
    
    if(this.pickUpAddressesApiCallId === apiRequestCallId) {
      const pickUpAddresses = responseJson?.pickup_addresses?.data
      if(pickUpAddresses.length > 0) {
        this.setState({ pickUpAddresses: pickUpAddresses })
        setOrderAddress(pickUpAddresses[0].id)
      }
      this.setState({ loader: false });
    }

    if(apiRequestCallId === this.addOrderAddressApiCallId) {
      if("type" in responseJson) {
        setCheckoutOrder(responseJson)
        setShippingLoading(false)
      } else {
        history.push(AppRoutings.ShoppingCart)
        toast.error(responseJson.message)
      }
    }
  }

  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (isTokenExpireResponse(responseJson)) return

    if (this.pickUpAddressesApiCallId === apiRequestCallId) {
      this.setState({ loader: false })
      toast.error("Something went wrong");
    }

    if (apiRequestCallId === this.addOrderAddressApiCallId) {
      this.props.history.push(AppRoutings.ShoppingCart)
      toast.error("Something went wrong")
    }

    if (responseJson?.errors) {
      responseJson.errors?.forEach((err: any) => {
        toast.error(err?.message);
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }
}
