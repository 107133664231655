//Customizable Area Start
export enum CommunityEnum {
    SET_COMMUNITY_SEARCH_DIALOG = "SET_COMMUNITY_SEARCH_DIALOG",
    SET_COMMUNITY_HOME_DESC = "SET_COMMUNITY_HOME_DESC",
    SET_COMMUNITY_LATEST_FORUMS = "SET_COMMUNITY_LATEST_FORUMS",

    SET_COMMUNITY_DISCUSSION_FILTER = "SET_COMMUNITY_DISCUSSION_FILTER",
    SET_COMMUNITY_DISCUSSION_TOPICS = "SET_COMMUNITY_DISCUSSION_TOPICS",
    SET_COMMUNITY_ACTIVE_TOPIC = "SET_COMMUNITY_ACTIVE_TOPIC",
    SET_COMMUNITY_DISCUSSIONS = "SET_COMMUNITY_DISCUSSIONS",
    SET_COMMUNITY_TAG_FILTER_DISCUSSIONS = "SET_COMMUNITY_TAG_FILTER_DISCUSSIONS",

    SET_COMMUNITY_EXPERTS_LIST = "SET_COMMUNITY_EXPERTS_LIST",
}

export enum CommunityFilterEnum {
    ALL = "all",
    FOLLOWING = "following",
    TAGS = "tags"
}
//Customizable Area End