// Customizable Area Start
import React from "react";
import { DustbinIcon } from "./assets";
import "../../../web/src/assets/css/style.scss";
import { commonCmptStyles } from "./dropDownCss";
import AdminRestCampaignsController, {
  configJSON,
  Props
} from "./AdminRestCampaignsController.web";
import {
  Box,
  Select,
  MenuItem,
  DialogTitle,
  DialogActions,
  Dialog,
  withStyles,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../assets/css/editCampaign.scss";
import FormControl from "@mui/material/FormControl";
import "../../../web/src/assets/css/content/progressedit.scss";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CampaignDetailsContainer from "./LearningCampaignDetails.web";
// Customizable Area End

class AdminRestaurantEditCampaign extends AdminRestCampaignsController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  campHeaderContent = (loading:any, classes:any,campStatClass:any, values:any, setFieldValue:any, restStatus:any, deleteIcon: any) => {
    return(
      <>
    {!loading && (
    <div className="rightSideHeader commonDropdown">
      <div className="">
        <div
          className={`customSelect campaignSelect itemStatus ${campStatClass}`}
        >
          <FormControl>
            <Select
              name={configJSON.statusText}
              className="menu-customize campaign_status"
              value={values.status}
              onChange={(e: any) => {
                setFieldValue(configJSON.statusText, e.target.value);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: configJSON.bottomText,
                  horizontal: configJSON.leftText,
                },
                transformOrigin: {
                  vertical: configJSON.topText,
                  horizontal: configJSON.leftText,
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.itemStatus,
                },
              }}
            >
              {restStatus.map((status: any, index: any) => {
                const menuItemStat = status
                return (
                  <MenuItem value={status} key={status}>
                    {menuItemStat}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {/* <CustomSelectField /> */}
      </div>
      {deleteIcon()}
    </div>
  )}
  </>
  )}
  // Customizable Area End
  render() {

    // Customizable Area Start
    const { classes } = this.props
    const { campDetails, productMode, openDialog, loading } = this.state
    const campMode = (productMode == configJSON.createText) ? configJSON.createCampaignText : configJSON.editCampaignText
    const restStatus = [configJSON.activeText, configJSON.draftStatus];
    const forumDetails = campDetails?.attributes;
    const deleteIcon = () => (
      (productMode == configJSON.editText) && (
      <img src={DustbinIcon}
        className="campaign_delete_icon"
        onClick={() => {
          this.setState({
            removedProduct: campDetails.id,
          });
          this.handleDialog();
        }}
      />
    ))

    return (
      <Formik
          enableReinitialize
          initialValues={{
            tagline : (forumDetails?.tagline) || "",
            heading: (forumDetails?.heading) || "",
            description: (forumDetails?.description) || "",
            status: (forumDetails?.status) || configJSON.activeText,
            banner: (forumDetails?.banner) || "",
            forumable_id: (this.state.campDetails && this.state.campDetails.id) || "",
            required_donation:
              (forumDetails?.required_donation),
          }}
          validationSchema={this.Schema}
          onSubmit={(values, actions) => {
            this.createForum(values);
          }}
        >
        {(formikProps) => {
          const { values, setFieldValue, handleChange, touched, errors } =
            formikProps;
          const campStatClass = (values.status == configJSON.draftStatus) && configJSON.warningVariantText
          return (
            <Form noValidate autoComplete="off">
              <Box className="restaurantForumsEditContainer">
                {/* header start */}
                <div className="header">
                  <div className="leftSideHeader">
                    <IconButton onClick={() => this.props.history.goBack()}>
                      <ArrowBackIcon />
                    </IconButton>
                    <h4 data-test-id="campaignLabel">
                      {campMode}
                    </h4>
                  </div>
                  {this.campHeaderContent(loading, classes,campStatClass,values, setFieldValue, restStatus, deleteIcon)}
                </div>

                {/* header start */}
                {/* main wrapper start */}
                <CampaignDetailsContainer
                  data-test-id="campaignDetails"
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  loading={loading}
                  campDetails={campDetails}
                  productMode={productMode}
                  handleDonationValueChange={this.handleDonationValueChange}
                  endDecorator={this.handleEndDecorator}/>
                
                {/* remove modal */}
                <div>
                  <Dialog
                    open={openDialog}
                    onClose={() => this.handleDialog()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {configJSON.campaignDelDialogText}
                    </DialogTitle>
                    <DialogActions>
                      <span className="redContained customCancelBtn">
                        <CustomButton
                        data-test-id="noBtn"
                          color="secondary"
                          label={configJSON.noText}
                          onClick={() => this.handleDialog()}
                        />
                      </span>
                      <span className="greenContained">
                        <CustomButton
                          data-test-id="yesBtn"
                          color="secondary"
                          label={configJSON.yesText}
                          onClick={() => {
                            this.handleDialog();
                            this.deleteCampaign();
                          }}
                          autoFocus
                        />
                      </span>
                    </DialogActions>
                  </Dialog>
                </div>
                {/* main wrapper end */}
              </Box>
            </Form>
          );
        }}
      </Formik>
    )
    // Customizable Area End
  }
}
// Customizable Area Start
export { AdminRestaurantEditCampaign }
//@ts-ignore
const restCampRouter:any = withRouter(withStyles(commonCmptStyles)(AdminRestaurantEditCampaign))
export default restCampRouter;
// Customizable Area End
