import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { AppRoutings } from "../../../components/src/utility/app-routing";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends RouteComponentProps {
  navigation?: any;
  id?: string;
  SelectRoleDesc?: string
  setSelectRoleData?: (desc: string) => void
}

export interface S {
  // Customizable Area Start
  rolesData: any[]
  isLoading: boolean
  selectedRoleId: number
  otpAuthToken: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id?: any;
  // Customizable Area End
}

export default class SelectYourRoleWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  getRolesApiCallId: string = "";
  getRoleDescApiCallId: string = "";
  updateRolesApiCallId: string = "";
  getContributorDetailsApiCallId: string = ""

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    console.log("receive", this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      rolesData: [],
      selectedRoleId: 0,
      isLoading: true,
      otpAuthToken: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if(apiRequestCallId === this.getRolesApiCallId) {
      this.setState({ rolesData: responseJson, isLoading: false })
    }

    if(apiRequestCallId === this.getRoleDescApiCallId) {
      const { setSelectRoleData } = this.props
      !!setSelectRoleData && setSelectRoleData(responseJson?.role_description || "")
    }

    if(apiRequestCallId === this.getContributorDetailsApiCallId) {
      this.props.history.push(`${AppRoutings.AdminLearningDetails}/${responseJson.data.id}`)
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (apiRequestCallId && responseJson) {
        this.handleSuccessApiResponse(apiRequestCallId, responseJson)
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)
    if(!!isLoggedIn) {
      this.getRoleDesc()
      this.getRoles()
    }
  }

  handleSelectedRole = (id: number) => {
    this.setState({ selectedRoleId: id })
  }

  handleCreateRoleProcess = () => {
    const { selectedRoleId } = this.state
    const { history } = this.props

    switch(selectedRoleId) {
      case 1:
        history.push(`/create-farmer-profile/:${selectedRoleId}`)
        break
      case 2:
        history.push(`/create-restaurant-profile/:${selectedRoleId}`)
        break
      case 3:
        history.push(`/create-educator-profile/:${selectedRoleId}`)
        break
      default:
        break
    }
  }

  handleHomePageRedirect = () => {
    this.props.history.push(AppRoutings.Home)
  }

  handleAnotherRoleRedirect = () => {
    this.props.history.push(AppRoutings.SelectYourRole)
  }

  handleSkipAdminRedirect = () => {
    const { rolesData } = this.state;
  
    const isRolePending = (roleName: string) => rolesData.some((role) => role.name === roleName)
  
    if (!isRolePending("Farmer/merchant")) {
      this.props.history.push(AppRoutings.AdminFarmDetails)
    } else if (!isRolePending("Restaurateur")) {
      this.props.history.push(AppRoutings.AdminRestaurantDetails)
    } else if (!isRolePending("Contributor")) {
      this.getContributorDetails();
    }
  }

  handleDashboardRedirect = (type: string) => {
    if(type === 'farm') this.props.history.push(AppRoutings.AdminFarmDetails)
    else if(type === 'restaurant') this.props.history.push(AppRoutings.AdminRestaurantDetails)
    else if(type === 'contributor') this.getContributorDetails()
  }

  getRoles = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetRoles
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getRoleDesc = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoleDescApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRoleDescApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetRoles
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getContributorDetails = async () => {
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContributorDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEducatorProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetRoles
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
