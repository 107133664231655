// Customizable Area Start
import * as React from "react";
import "../../../web/src/assets/css/style.scss";
import { Box, Grid, Button } from "@material-ui/core";
import { DoneImg } from "../../../blocks/info-page/src/assets";

export default class LessonCompletedModal extends React.Component<any,any> {
    constructor(props:any){
        super(props);
    }

  render() {

    return (
      <Grid container spacing={2} className="add_address_modal_container">
        <Grid item xs={12} style={{ padding: "0px" }}>
          <Box className="layout_wrapper">
            <Box className="bitcoin_payment_form_wrapper">
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Box className="bitcoin_payment_form_content">
                    <img src={DoneImg} alt="done" />
                    <h5>Lesson Completed Successfully</h5>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={() => history.back()}>OK</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
    
  }
}
// Customizable Area End