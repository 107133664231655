// Customizable Area Start
import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary,Typography } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

export class OverviewTab extends React.Component<any,any>{
    constructor(props:any){
        super(props);
        this.state={
          expanded : false
        }
    }
    
    handleChange = (panel: any) => (event: any, isExpanded: any) => {
        this.setState({expanded : isExpanded ? panel : ""});
    };
    render() {
    const CourseSection = this.props.section
    return (
      <>
        {CourseSection && CourseSection.map((i: any, index: any) => {
          return (
            <div className="customAccordian" key={i} style={{ borderBottom: '2px solid #ebf0ff', borderTop: '2px solid #ebf0ff', paddingTop: '0px', alignItems: 'center', marginBottom: '30px' }}>
              <Accordion
                data-test-id="lessonChange"
                expanded={this.state.expanded === index}
                onChange={this.handleChange(index)}
                style={this.state.expanded === index ? { backgroundColor: 'rgba(20, 118, 32, 0.08)' } : { backgroundColor: '#fff' }}
              >
                <AccordionSummary
                  expandIcon={<AddCircleOutlineOutlinedIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="AccordionSummary"
                >
  
                  <Typography variant="h5" className="topTitle">
                    Part <span style={{ display: 'flex', flexDirection: 'column', color: '#333', alignItems: 'center', fontSize: '25px', fontWeight: 500 }}>0{index + 1}</span>
                  </Typography>
                  {this.state.expanded === index ? '' : <Typography variant="h5" className="overview-subTitle" >
                    {i.attributes && i.attributes.name}
                  </Typography>}
  
  
                </AccordionSummary>
                <AccordionDetails className="AccordionDetails overviewDetails">
                  <>
                    {i.attributes.course_sub_sections && i.attributes.course_sub_sections.data.map((e: any, index: any) => {
                      return (
                        <>
                          <Typography variant="h5" className="overview-text">Lesson {index + 1} - {e.attributes && e.attributes.heading}</Typography>
                          <div className="paragraph"><p className="para">
                            {e.attributes && e.attributes.description}
                          </p></div>
                        </>
                      )
                    })}
                  </>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        })}
      </>
    )
    }
  }
// Customizable Area End  