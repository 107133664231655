import React from 'react'
import { instaImg } from '../assets';
import { Button } from "@material-ui/core";
import '../assets/css/social_btn.scss'



const InstagramButton = () => {
    return (
        <Button variant="contained"
            className="social_btn"
            classes={{
                label: "btn_color_black",
            }}
            startIcon={<img src={instaImg} alt="fb_img" className="social_Img" />}
        >
            Instagram
        </Button>

    )
}

export default InstagramButton;
