// Customizable Area Start
import React, { Component } from "react"
import "../../components/src/assets/css/cartnumberinput.scss"
import { Box } from "@material-ui/core"
import clsx from "clsx"
// Customizable Area End

// Customizable Area Start
interface Props {
  count: number
  addCount: () => void
  minusCount: () => void
  isDisable?: boolean
}
// Customizable Area End

class WebCartNumberInput extends Component<Props, any, any> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    // Customizable Area Start
    const { count, isDisable = false, addCount, minusCount } = this.props

    return (
      <Box
        className={clsx("custom_quantity_container", {
          ["custom_quantity_container_disabled"]: isDisable,
        })}
      >
        <button onClick={minusCount} disabled={count === 1 || isDisable}>
          -
        </button>
        <span>{count}</span>
        <button disabled={isDisable} onClick={addCount}>
          +
        </button>
      </Box>
    )
    // Customizable Area End
  }
}

export default WebCartNumberInput
