// Customizable Area Start
import React from "react";
import "../../email-account-login/src/assets/css/signup.scss";
import OnlineEmailAccountRegistrationController, {
  Props,
} from "./OnlineEmailAccountRegistrationController.web";
import { getUserDetails, getCartItems, setUserCartId } from "./assets"
import { Box, Grid, Button } from "@material-ui/core";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FilledInput } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux"
// Customizable Area End
class OnlineEmailAccountRegister extends OnlineEmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { registerSchema, regErrors, isLoading } = this.state

    return (
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        }}
        validationSchema={registerSchema}
        onSubmit={(values) => this.handleSignUp(values)}
      >
        {({ handleChange, handleSubmit, errors, touched }) => {
          return (
            <>
              <form onSubmit={handleSubmit}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit()
                }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} />
                  <Grid item xs={12} />
                  <Grid item xs={12} lg={6}>
                    <Box className="text_field web_input_field">
                      <FilledInput
                        placeholder="First Name"
                        name="first_name"
                        inputProps={{ maxLength: 100 }}
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            registerFailedError: "",
                          });
                        }}
                      />
                      <span className="error">
                        {(touched.first_name && errors.first_name) ? errors.first_name : null}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box className="text_field web_input_field">
                      <FilledInput
                        placeholder="Last Name"
                        name="last_name"
                        inputProps={{ maxLength: 100 }}
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            registerFailedError: "",
                          });
                        }}
                      />
                      <span className="error">
                        {(touched.last_name && errors.last_name) ? errors.last_name : null}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="text_field web_input_field">
                      <FilledInput
                        placeholder="Email"
                        inputProps={{ maxLength: 100 }}
                        startAdornment={
                          <InputAdornment position="end">
                            <DraftsOutlinedIcon className="login_input_icons" />
                          </InputAdornment>
                        }
                        name="email"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            registerFailedError: "",
                            regErrors:[]
                          });
                        }}
                      />
                      <span className="error">
                        {(touched.email && errors.email) ? errors.email : null}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="web_input_field">
                      <FilledInput
                        placeholder="Password"
                        type="password"
                        inputProps={{ maxLength: 16 }}
                        startAdornment={
                          <InputAdornment position="end">
                            <LockOpenOutlinedIcon className="login_input_icons" />
                          </InputAdornment>
                        }
                        name="password"
                        classes={{
                          input: "Login_Input",
                          root: "Login_Input_Field",
                          focused: "Login_Input_Field_Focus",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            registerFailedError: "",
                          });
                        }}
                      />
                      <span className="error">
                        {(touched.password && errors.password) ? errors.password : null}
                      </span>
                    </Box>
                  </Grid>
                  {
                    regErrors.length !== 0 &&
                    <Grid item xs={12}>
                      {regErrors.map((error: any, key: number) => {
                        return (
                          <Alert severity="error">
                            {error.message}
                          </Alert>
                        )
                      })}
                    </Grid>
                  }

                  <Grid item xs={12}>
                      <Button
                        className="login_signup_btn"
                        classes={{
                          label: "normal_login_btn",
                        }}
                        onClick={() => handleSubmit()}
                      >
                        {isLoading ? <CircularProgress size={25} className="login_signup_loader" /> : "Create Account"}
                      </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
  return {}
}
// Customizable Area End

// Customizable Area Start
export default withRouter(connect(mapStateToProps, { getUserDetails, getCartItems, setUserCartId }) (OnlineEmailAccountRegister))
// Customizable Area End
