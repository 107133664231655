// Customizable Area Start
import React from "react";
import { Formik } from "formik";
import ChangePasswordDialogController, {
  Props,
} from "./ChangePasswordDialogController.web";
import {
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { Logo } from "../../email-account-registration/src/assets";
import * as Yup from "yup";
import "../assets/css/accountSetting.scss";
import CloseIcon from '@material-ui/icons/CloseRounded';
import PasswordInputField from "./PasswordInputField.web";
import Alert from "@material-ui/lab/Alert";

const updatePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
        .required("New password is required")
        .matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}(\S*)$/,
            "Password must contain at least 8 characters, one lower, one uppercase, one number and one special case character"
        ),
    confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf(
            [Yup.ref("newPassword"), null],
            "Confirm password must match with the new password"
        ),
})
// Customizable Area End

class ChangePasswordDialog extends ChangePasswordDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {isOpen} = this.props
    const { isLoading, backendError } = this.state

    return (
        <Dialog
            maxWidth="xs"
            open={isOpen}
            onClose={() => this.handleCloseDialog()}
            classes={{
                paper: "checkout_order_dialog_paper",
            }}
        >
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validationSchema={updatePasswordSchema}
                onSubmit={(values) => this.handleUpdatePassword(values)}
            >
                {({ values, errors, touched, handleChange, handleSubmit }) => {
                    return (
                        <Grid container>
                            <Grid item xs={12} style={{ padding: "0px" }}>
                                <Box className="layout_wrapper">
                                    <Box className="logo_wrapper update_password_header">
                                        <img src={Logo} alt="logo" width="200px" />
                                        <IconButton onClick={() => this.handleCloseDialog()}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <Box className="cp_field_container">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Box className="forgot_PW_tab_container">
                                                    <Typography variant="subtitle1" className="forgot_PW_tab">
                                                        Change Password
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <PasswordInputField
                                                    placeholder="Old Password"
                                                    autoFocus
                                                    name="oldPassword"
                                                    value={values.oldPassword}
                                                    onChange={handleChange}
                                                />

                                                <span className="error">
                                                    {touched.oldPassword && errors.oldPassword
                                                        ? errors.oldPassword
                                                        : null}
                                                </span>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <PasswordInputField
                                                    placeholder="New Password"
                                                    name="newPassword"
                                                    value={values.newPassword}
                                                    onChange={handleChange}
                                                />

                                                <span className="error">
                                                    {touched.newPassword && errors.newPassword
                                                        ? errors.newPassword
                                                        : null}
                                                </span>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <PasswordInputField
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                />

                                                <span className="error">
                                                    {touched.confirmPassword && errors.confirmPassword
                                                        ? errors.confirmPassword
                                                        : null}
                                                </span>
                                            </Grid>

                                            {!!backendError && <Grid item xs={12}>
                                                <Alert severity="error">
                                                    {backendError}
                                                </Alert>
                                            </Grid>}

                                            <Grid item xs={12}>
                                                <Button
                                                    classes={{
                                                        label: "normal_login_btn",
                                                    }}
                                                    className="btn"
                                                    onClick={() => handleSubmit()}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress size={25} color="inherit" />
                                                    ) : (
                                                        "Update Password"
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                }}
            </Formik>
        </Dialog>

    )
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {  }
};
// Customizable Area End

export default ChangePasswordDialog
// Customizable Area Start
