// Customizable Area Start
import React from "react";
import { Grid, Box, Button, TextField, CircularProgress, Typography } from "@material-ui/core";
import { RedDustbin, DefaultProfile } from "../assets";
import "../assets/css/reply.scss";
import { connect } from 'react-redux'
import ReplyController, { Props } from "./ReplyController.web"
import CustomActionDialog from "../../../../components/src/CustomDialog.web";
import LoginSignUpDialog from "../langinpageLoggedIn/LoginSignUpDialog.web"
// Customizable Area End

class ReplyWeb extends ReplyController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { userInfo, fullName, image, reviewerId, reviewDate } = this.props
    const {
      editReview,
      isEditing,
      isDeleteDialog,
      deleteReviewLoader,
      editReviewLoader,
      contentFlagLoader,
      isContentFlag,
      isLoginDialog
    } = this.state
    const isLoggedInReply = userInfo?.userDetails?.id === reviewerId

    return (
      <>
        <Grid item xs={12}>
          <Box className="forum_reply_container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={2}>
                <Box className="reply_image_wrapper">
                  <img
                    src={image || DefaultProfile}
                    className="reply_profile_img"
                    alt="profile_pic"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={10} container>
                <Grid item xs={6} sm={8}>
                  <h5 className="reply_heading">
                    {isLoggedInReply ? "Your reply" : fullName}
                  </h5>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box className="comment_date">
                    <p>{reviewDate}</p>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {isEditing ? (
                    <TextField
                      className="reply_edit_textfield"
                      placeholder="Write your reply here..."
                      value={editReview}
                      disabled={!isEditing}
                      onChange={this.handleReviewChange}
                      InputProps={{ disableUnderline: true }}
                    />
                  ) : (
                    <Typography className="comment">{editReview}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box className="reply_edit_delete_wrapper">
                    {isLoggedInReply && (
                      !isEditing ? (
                        <>
                          <Button
                            className="delete_reply"
                            onClick={() => this.openConfirmationDialog()}
                            disabled={deleteReviewLoader}
                          >
                            <img src={RedDustbin} alt="delete_icon" />
                          </Button>
                          <Button
                            className="edit_reply"
                            onClick={() => this.enableEditReview()}
                            disabled={deleteReviewLoader}
                          >
                            Edit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="cancel_reply"
                            onClick={() => this.cancelEditReview()}
                            disabled={editReviewLoader}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="save_reply"
                            onClick={() => this.ediReviewApi()}
                            disabled={!editReview || editReviewLoader}
                          >
                            {editReviewLoader ? (
                              <CircularProgress color="inherit" size={18} />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </>
                      )
                    )}
                    {!isEditing && !isLoggedInReply && <Button
                      className="report_btn"
                      disabled={contentFlagLoader || isContentFlag}
                      onClick={this.handleContentFlag}
                    >
                      {contentFlagLoader ? (
                        <CircularProgress color="inherit" size={18} />
                      ) : (
                        "Flag content"
                      )}
                    </Button>}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <CustomActionDialog
          open={isDeleteDialog}
          handleClose={this.closeConfirmationDialog}
          isCloseDisabled={deleteReviewLoader}
          isActionDisabled={deleteReviewLoader}
          isLoading={deleteReviewLoader}
          title="Are you sure want to delete this reply?"
          actionBtnTitle="Yes"
          closeBtnTitle="No"
          handleAction={this.deleteReviewApi}
        />

        <LoginSignUpDialog
          isOpen={isLoginDialog}
          onClose={this.closeLoginDialog}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return { userInfo: state.user };
}
// Customizable Area End

// Customizable Area Start
export default connect(mapStateToProps, {})(ReplyWeb);
// Customizable Area End
