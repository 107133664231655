export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const CourseImg4 = require("../assets/images/course4.png");
export const CourseImg5 = require("../assets/images/course5.png");
export const CourseImg6 = require("../assets/images/course6.png");
export const FarmBannerImg = require("../assets/images/farm-banner.jpg");
export const Receipt1Img = require("../assets/images/receipt1.png");
export const LikeIcon = require("../assets/images/like-icon.png");
export const CommentIcon = require("../assets/images/comment-icon.png");
export const ProfileImg1 = require("../assets/images/profile-img1.jpg");
export const ProfileImg2 = require("../assets/images/profile-img2.jpeg");
export const TimerIcon = require("../assets/images/timer_icon.png");
export const TopicsIcon = require("../assets/images/topics_icon.png");
export const EnrollIcon = require("../assets/images/enroll_icon.png");
export const TrainerImg = require("../assets/images/trainer-profile.png");
export const ServiceImg = require("../assets/images/service-profile.png");
export const BulletIcon = require("../assets/images/bullet-icon.png");
export const PlayIcon = require("../assets/images/play-icon.png");
export const PauseIcon = require("../assets/images/pause-icon.png");
export const TechIcon01 = require("../assets/images/teacher0-1.png");
export const TechIcon02 = require("../assets/images/teacher0-2.png");
export const TechPop01 = require("../assets/images/teacher-pop-1.png");
export const TechPop02 = require("../assets/images/teacher-pop-2.png");
export const TechPop03 = require("../assets/images/teacher-pop-3.png");
export const TechPop04 = require("../assets/images/teacher-pop-4.png");
export const Course01 = require("../assets/images/course-1.png");
export const Course02 = require("../assets/images/course-2.png");
export const Course03 = require("../assets/images/course-3.png");
export const Course04 = require("../assets/images/course-4.png");
export const GrpImg = require("../assets/images/grpimg.jpeg");
export const PageCourse4 = require("../assets/images/page-course-4.jpeg");
export const PageCourse04 = require("../assets/images/page-course-04.jpeg");
export const PageCourse5 = require("../assets/images/page-5.jpeg");
export const LeftIcon = require("../assets/images/lefticon.jpeg");
export const RighttIcon = require("../assets/images/right-arrow.jpeg");
export const Play = require("../assets/images/play.jpeg");
export const Public = require("../assets/images/public.jpeg");
export const Video = require("../assets/videos/video.mp4");
export const Repeat = require("../assets/images/repeat.png");
export const Teaching = require("../assets/images/business-teaching-rhpe-8-jc.png");
export const PlayIcon1 = require("../assets/images/play-icon1.png");
export const PauseIcon1 = require("../assets/images/pause-icon1.png");
export const PlayButton = require("../assets/images/play-button.png");
export const trash_red_icon = require("../assets/images/trash.png");
export const play_button = require("../assets/images/play_button.png");
export const docs = require("../assets/images/google-docs.png");
export const lock = require("../assets/images/lock.png");
export const searchIcon = require("../assets/images/magnifyingGlass.png");
export const expandArrow = require("../assets/images/expandArrows.png");
export const emptyCheckbox = require("../assets/images/oval.svg");
export const greenTick = require("../assets/images/greenTick.png");
export const filledHeart = require("../assets/images/filledheart.png");
export const heart = require("../assets/images/heart.png");
export const RightArrow = require("../assets/images/RightArrow.png");
export const UserIcon = require("../assets/images/UserIcon.png");
export const courseBanner = require("../assets/images/course_banner.png");


export const { updateActiveTab } = require("../../../components/src/redux/Users/userActions");

export const {
    getPopularCoursesLearning,
    getLearningDetails
    , setCourse,
    getCourseListOnSearch, resetSearchFilters,resetLearningFilters,getCategoriesELearning,categorySelectChip,setCourseCurrentPage
} = require("../../../components/src/redux/ELearning/ELearningActions");