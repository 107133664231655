// Customizable Area Start
import * as React from "react";
import LandingPage4Controller, {
  configJSON,
  Props,
} from "./LandingPage4Controller.web";
import { withRouter } from "react-router-dom";
// css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/educationCourseDetail.scss";
import "../assets/css/landingpage.scss";
import "../assets/css/accordion.scss";

import {
  Grid,
  Typography,
  Box,
  Modal,
  Menu,
  MenuItem,
  withStyles,
  Button
} from "@material-ui/core";
//images
import {
  LeftIcon
} from "./assets";
//components
import CustomButton from "../../../components/src/CustomButton.web";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import { ScrollView } from "react-native";
import ReactPlayer from 'react-player';
import { Line } from 'rc-progress';
import { EnrolledPageTabs } from "./EnrolledPageTabs.web";
import { VideoPlayer } from "./VideoPlayer.web";
import { TextField } from "@mui/material";
import WebNewRoutingTabs from "../../../components/src/WebNewRoutingTabs.web";
import WebHeader from "../../../components/src/WebHeader.web";
import Lazyload from "react-lazy-load";
import Loader from "../../../components/src/Loader.web";

const styles = {
  enrolledCourseWrapper : {
    border: '1px solid #92929d',
    padding: '20px',
    backgroundColor: '#F4F9F5',
    '@media (max-width: 560px)': {
      boxShadow: '0 4px 4px 0 rgba(21, 21, 21, 0.18)',
      border: 'none !important'
    }
  },
  courseTitle : {
    color: '#169d4e',
    paddingLeft: '15px',
    fontWeight: 700,
    wordSpacing: '4px'
  },
  shareText : {
    backgroundColor:'#fff'
  },
  videoContainer : {
    display: 'flex',
    marginTop: 10,
    alignItems: 'center'
  },
  startBtn : {
    justifyContent: 'flex-start'
  },
  copyBtnContainer : {
    display: 'flex'
  },
  completed : {
    width: '355px',
    borderRadius: '40px',
    padding: '10px 40px',
    border: '1px solid #59bd83 !important',
    backgroundColor: '#20a258 !important',
    cursor : 'default !important',
    fontFamily : 'Poppins, sans-serif',
      "& .MuiButton-label" : {
        backgroundColor: '#20a258 !important',
        fontSize: '16px',
        color : '#fff',
        fontWeight: 500,
        textTransform : 'capitalize',
        cursor : 'default',
    },
    "@media screen and (max-width: 560px)" : {
      padding: '7px 11px 8.1px',
      borderRadius: '5px !important',
      width: '100% !important'
    }
  }
}
// Customizable Area End
export class LandingPage4 extends LandingPage4Controller {
  video1Ref: any;
  constructor(props: Props) {
    super(props);
    this.video1Ref = React.createRef();
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {classes} = this.props
    const { hours, mins, secs, enrolledStudents } = this.props.location.state;
    const { content, lessonData, section, completedLessons, totalLessons, lessonBtnText, courseData } = this.state;  

    return (
        <ScrollView>
        <WebHeader />
        <WebNewRoutingTabs />
          <Grid
            container
            className={classes.enrolledCourseWrapper}
          >
            <Grid item xs={12}>
              <div
                className="flex learningHeaderContainer"
              >
                <img
                  data-test-id = "leftIcon"
                  className="backIcon"
                  style={{cursor:'pointer' }}
                  src={LeftIcon}
                  alt="Back Icon"
                  onClick={this.handleBackIcon}
                />
                <h5 className={classes.courseTitle}>{content.attributes && content.attributes.title}</h5>
                <MoreHorizRoundedIcon className="threeDots" onClick={this.handleOptions} />
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleCloseOptions}
                  className="menu-customize shareMenu"
                >
          <MenuItem className={classes.shareText} onClick={this.handleShare}>{configJSON.shareText}</MenuItem>
        </Menu>
        <Modal disableEnforceFocus open={this.state.modalOpen} onClose={this.handleModalClose} >
          <Box className="shareModal"> 
            <Typography className="shareCourseText">{configJSON.shareCourseText}</Typography>
            <Grid container className="shareContainer">
            <div style={{display:'flex', width:'310px'}}>
                <TextField className="sharelink" disabled value={window.location.href}/>
                
            </div>
            <div className={classes.copyBtnContainer}><button className="copyBtn"
            onClick={this.handleCopy}>{configJSON.copyText}</button></div>
            
            </Grid> 
            {this.state.copy && <div className="copiedTxt">{configJSON.linkCopiedText}</div> }
          </Box>
        </Modal>
              </div>
            </Grid>
          </Grid>
        {  this.state.loading ?
          <Loader loading={this.state.loading} /> 
        :
        <>
          <div className="padding" >
            { this.state.lessonDetail?.length === 0 ?
            <Grid container className="enrolledContainer">
        
              <Grid item xs={12} sm={12} md={4} lg={4} >

                <div className="lessonWrapper" >
                  <h3 className="courseTitle" >
                    {content.attributes && content.attributes.title}
                  </h3>
                  <h5 className="author">
                    {content?.attributes?.first_name} {content?.attributes?.last_name}
                  </h5>
                  <div className="progressBox">
                    <div className="width">
                      <Line strokeWidth={2.5} percent={this.state.progress} trailWidth={2.5} strokeColor='#049E51' />
                    </div>

                    <p className="status-num">{completedLessons}/{totalLessons} {configJSON.lessonText}</p>
                  </div>

                  <div className={classes.videoContainer}>
                        {lessonData &&
                          lessonData?.video &&
                          <Lazyload offset={500} className="videoThumbnail">
                            <ReactPlayer
                              className="video"
                              width="100"
                              height="100"
                              preload="auto"
                              playsinline
                              url={lessonData?.video?.url}
                              controls={false}
                            />
                          </Lazyload>
                      }
                    <div className="videoRightBox">

                      <p className="day-status">
                        {configJSON.lessonText} {this.state.index} -
                      </p>
                      <h6 className="enrolledLessonHeading">
                      {lessonData?.heading}
                      </h6>
                    </div>
                  </div>
                  

                </div>
                {
                  lessonBtnText === configJSON.completedText ? 
                  <Grid item xs={12} className="completedBtn flex margin3 startBtn">
                  <Button 
                  className={classes.completed}> {lessonBtnText}
                  </Button>
                </Grid>
                :
                <Grid item xs={12} className="circleBtn flex margin3 startBtn">
                  <CustomButton 
                  data-test-id="lessonBtn"
                  label={lessonBtnText} className='btn-2' style={{width:'355px'}} 
                  onClick={this.handleLesson1}/>
                </Grid>
                }

              </Grid>
              {lessonData?.video && <Grid item xs={12} sm={12} md={8} lg={8} className="playerPadding">
                <VideoPlayer lessonDetail={lessonData} handleVideoStatus={this.handleVideoStatus}
                  Video={lessonData.video} 
                  isVideoComplete={lessonData?.video_completed}
                  navigation={undefined} id={""} section={undefined} content={undefined} lesson={undefined} lessonLength={undefined} hours={undefined} mins={undefined} enrolled={undefined} />
              </Grid>}
            </Grid>
            : 
            <Grid container className="enrolledContainer">
        
              <Grid item xs={12} sm={12} md={4} lg={4} >

                <div className="lessonWrapper">
                  <h3 className="courseTitle">
                    {content?.attributes?.title}
                  </h3>
                  <h5 className="author">
                    {content?.attributes?.first_name} {content?.attributes?.last_name}
                  </h5>
                  <div className="progressBox">
                    <div className="width">
                      <Line strokeWidth={2.5} percent={this.state.progress} trailWidth={2.5} strokeColor='#049E51' />
                    </div>

                    <p className="status-num">{completedLessons}/{totalLessons} {configJSON.lessonText}</p>
                  </div>
                  
                  <div className={classes.videoContainer}>
                        {this.state.lessonDetail?.attributes?.video &&
                          <Lazyload offset={500} className="videoThumbnail">
                            <ReactPlayer
                              className="video"
                              width="100"
                              height="100"
                              preload="auto"
                              playsinline
                              url={this.state.lessonDetail.attributes.video.url}
                              controls={false}
                            />
                          </Lazyload>}
                  
                    <div className="videoRightBox">

                      <p className="day-status">
                        {configJSON.lessonText} {this.state.lessonNumber} -
                      </p>
                      <h6 className="enrolledLessonHeading">
                      {this.state.lessonDetail?.attributes?.heading}
                      </h6>
                    </div>
                  </div>
                  

                </div>
                <Grid item xs={12} className="circleBtn flex margin3 startBtn">
                  <CustomButton label= {`Start- Lesson ${this.state.lessonNumber}`} 
                  data-test-id="lessonBtn"
                  className="btn-start"  
                  onClick={this.handleLessonButton}/>
                </Grid>

              </Grid>
              {this.state.lessonDetail?.attributes?.video && <Grid item xs={12} sm={12} md={8} lg={8} className="playerPadding">
                <VideoPlayer lessonDetail={this.state.lessonDetail}
                  Video={this.state.lessonDetail.attributes.video} navigation={undefined} id={""} section={undefined} content={undefined} lesson={undefined} lessonLength={undefined} hours={undefined} mins={undefined} enrolled={undefined} />
              </Grid>}
            </Grid>
            }
          </div>
  
          <EnrolledPageTabs
            lessonLength={totalLessons}
            section={section}
            courseData={courseData}
            content={content}
            lesson={this.handleClick}
            hours={hours}
            mins={mins}
            sec={secs}
            enrolled={enrolledStudents}
            handleStartLessonBtn={this.handleStartLessonBtn}
            handleVideoStatus={this.handleVideoStatus} navigation={undefined} id={""} lessonDetail={undefined} Video={undefined} />
        </>
        }
        </ScrollView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
//@ts-ignore
const routerLandingPage4:any = withRouter(LandingPage4);

export default withStyles(styles)(routerLandingPage4)
// Customizable Area End