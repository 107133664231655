// Customizable Area Start
import React, { Component } from "react"
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Box,
  Avatar,
  Button,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded"
const configJSON = require("./config.js")

export interface IStatusOption {
  label: string
  value: string
  hidden?: boolean
}

interface IVariantDetails {
  id: string
  type: string
  attributes: {
    is_active: boolean
    option_name: string
    price: string
    weight: string
  }
}

const orderStatusPickupOptions: IStatusOption[] = [
  {
    label: "Order Confirmed",
    value: "placed",
  },
  {
    label: "Preparing",
    value: "preparing",
  },
  {
    label: "Picked Up",
    value: "picked_up",
  },
  {
    label: "Cancelled",
    value: "rejected",
    hidden: true
  },
  {
    label: "Refunded",
    value: "refunded",
    hidden: true
  }
]

const orderStatusOptions: IStatusOption[] = [
  ...orderStatusPickupOptions,
  {
    label: "In Transit",
    value: "in_transit",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
]

interface IProps {
  orderStatus: string
  orderStatusLabel: string
  orderStatusButtonLabel: string
  orderDetails: any
  handleStatusChange: (event: React.ChangeEvent<{ value: unknown }>) => void
}

export class AdminOrderDetailsDialogContent extends Component<IProps> {
  getAddressString = () => {
    const { orderDetails } = this.props
    const orderDetailsAtt = orderDetails ? orderDetails.attributes : null
    if (orderDetailsAtt) {
      const deliveryAddress = orderDetailsAtt.delivery_address
      const pickUpAddress = orderDetailsAtt.pick_up_address
      const isPickUpOrder =
        orderDetailsAtt.order_type === configJSON.pickUpLabel
      if (isPickUpOrder && pickUpAddress) {
        const { address_line1, address_line2, city, state } = pickUpAddress
        return [address_line1, address_line2, city, state]
          .filter((field) => !!field)
          .join(", ")
      } else if (deliveryAddress) {
        const { address, city } = deliveryAddress
        return [address, city].join(", ")
      }
      return
    }
    return ""
  }

  getUserFullName = () => {
    const { orderDetails } = this.props
    const orderDetailsAtt = orderDetails ? orderDetails.attributes : null
    if (orderDetailsAtt && orderDetailsAtt.delivery_address) {
      const { full_name } = orderDetailsAtt.delivery_address
      return full_name
    }
    if (orderDetailsAtt.customer) {
      return orderDetailsAtt.customer
    }
    return ""
  }

  isMenuDisabled = (value: string) => {
    const menuIndex = orderStatusOptions.findIndex(
      (status: IStatusOption) => status.value === value
    )
    const currentStatusIndex = orderStatusOptions.findIndex(
      (status: IStatusOption) => status.value === this.props.orderStatus
    )

    return menuIndex < currentStatusIndex
  }

  getPrice = (selectedVariantDetails: IVariantDetails | null, price: string) => {
    if (selectedVariantDetails) {
      return selectedVariantDetails.attributes.price
    }
    return price
  }  

  getVariantDetails = (
    variantDescription: string,
    selectedVariantDetails: any
  ) => {
    const isVariantPurchased = variantDescription && selectedVariantDetails
    if (isVariantPurchased) {
      const {
        attributes: { option_name },
      } = selectedVariantDetails
      return (
        <h5>
          {variantDescription} : {option_name}
        </h5>
      )
    }
    return null
  }

  render() {
    const {
      orderStatus,
      orderStatusLabel,
      orderStatusButtonLabel,
      orderDetails,
      handleStatusChange,
    } = this.props

    const orderDetailsAtt = orderDetails ? orderDetails.attributes : null
    const details = orderDetailsAtt
      ? {
          note: orderDetailsAtt.note,
          isPickUpOrder: orderDetailsAtt.order_type === configJSON.pickUpLabel,
          trackingURL: orderDetailsAtt.tracking_url,
          trackingNumber: orderDetailsAtt.tracking_number,
          profileImage: orderDetailsAtt.profile_pic
            ? orderDetailsAtt.profile_pic.url
            : "",
          orderProductsList: orderDetailsAtt.order_details.data,
        }
      : {
          note: "",
          isPickUpOrder: false,
          trackingURL: "",
          trackingNumber: "",
          profileImage: "",
          orderProductsList: [],
        }

    const {
      note,
      isPickUpOrder,
      trackingURL,
      trackingNumber,
      profileImage,
      orderProductsList,
    } = details

    const statusOptions = isPickUpOrder
      ? orderStatusPickupOptions
      : orderStatusOptions
    const isCancelledOrder = configJSON.disabledStatusList.includes(orderStatus)

    return (
      <Box className="adminOrderModal">
        {orderProductsList.length > 0 && (
          <div className="subModalBlock">
            <div className="modalContent adminOrderModalContent">
              <Grid container spacing={5}>
                <Grid item xs={12} md={7}>
                  <Box className="orderActionWrapper">
                    <Select
                      value={orderStatus}
                      variant="filled"
                      disabled={isCancelledOrder}
                      className="orderStatusDropdown"
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      onChange={handleStatusChange}
                      MenuProps={{
                        ...configJSON.MenuProps,
                        classes: {
                          paper: "orderStatusPaper",
                        },
                      }}
                    >
                      {statusOptions.map((option: IStatusOption) => {
                        const { label, value, hidden } = option

                        return (
                          <MenuItem
                            key={value}
                            value={value}
                            hidden={hidden}
                            disabled={this.isMenuDisabled(value)}
                          >
                            {label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <Button className="orderStatusButton" disabled>
                      {orderStatusButtonLabel}
                    </Button>
                    {trackingURL && !isCancelledOrder && (
                      <a
                        href={trackingURL}
                        target="_blank"
                        className="orderPrintLabel"
                      >
                        {configJSON.printLabelLabel}
                      </a>
                    )}
                  </Box>
                  {trackingNumber && !isCancelledOrder && (
                    <Box mb={2} className="trackingContainer">
                      <Typography variant="h6">
                        {configJSON.trackingNumberLabel}
                      </Typography>
                      <Typography>{trackingNumber}</Typography>
                    </Box>
                  )}
                  {orderProductsList.map((product: any) => {
                    const {
                      id,
                      attributes: {
                        product_name,
                        description,
                        product_image,
                        price,
                        quantity,
                        selected_variant_details,
                        variant_description,
                      },
                    } = product
                    const imageUrl = product_image ? product_image.url : ""

                    return (
                      <div className="farmCard" key={id}>
                        <h3>{product_name}</h3>
                        <div className="farmCardContent">
                          <div className="imgBlock">
                            <img
                              src={imageUrl}
                              className="farmSubProfile"
                              alt="product"
                            />
                          </div>
                          <div className="rightBlock">
                            <p>{description}</p>
                            <h5>
                              {configJSON.priceLabel} : <span>${this.getPrice(selected_variant_details, price)}</span>
                            </h5>
                            <h5>
                              {configJSON.quantityLabel} :{" "}
                              <span>{quantity}</span>
                            </h5>
                            {this.getVariantDetails(
                              variant_description,
                              selected_variant_details
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <Box>
                    <Card className="priceCard">
                      <CardContent className="cardContent">
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.totalItemLabel}
                          </Typography>
                          <Typography variant="h6">
                            $
                            {
                              orderDetailsAtt.order_details.billing_details
                                .total_item
                            }
                          </Typography>
                        </div>
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.discountLabel}
                          </Typography>
                          <Typography variant="h6">
                            -$
                            {
                              orderDetailsAtt.order_details.billing_details
                                .discount
                            }
                          </Typography>
                        </div>
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.loyaltyPointsLabel}
                          </Typography>
                          <Typography variant="h6">
                            -$
                            {
                              orderDetailsAtt.order_details.billing_details
                                .loyalty_points
                            }
                          </Typography>
                        </div>
                        <hr />
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.subTotalLabel}
                          </Typography>
                          <Typography variant="h6">
                            $
                            {
                              orderDetailsAtt.order_details.billing_details
                                .sub_total
                            }
                          </Typography>
                        </div>
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.transactionFeesLabel}
                          </Typography>
                          <Typography variant="h6">
                            $
                            {
                              orderDetailsAtt.order_details.billing_details
                                .transaction_fees
                            }
                          </Typography>
                        </div>
                        <div className="cardRow">
                          <Typography variant="h6">
                            {configJSON.shippingLabel}
                          </Typography>
                          <Typography variant="h6">
                            $
                            {
                              orderDetailsAtt.order_details.billing_details
                                .shipping
                            }
                          </Typography>
                        </div>
                        <hr />
                        <div className="cardRow cardTotalRow">
                          <Typography
                            variant="h6"
                            data-testid="item-count-heading"
                          >
                            {`${configJSON.totalLabel}(${
                              orderDetailsAtt.order_details.billing_details
                                .item_count
                            }
                                  ${
                                    orderDetailsAtt.order_details
                                      .billing_details.item_count > 1
                                      ? configJSON.itemsLabel
                                      : configJSON.itemLabel
                                  })`}
                          </Typography>
                          <Typography variant="h6">
                            $
                            {
                              orderDetailsAtt.order_details.billing_details
                                .total
                            }
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  {note && (
                    <Box mb={2} className="farmOrderCard farmOrderNotesWrapper">
                      <Typography variant="h6">
                        {configJSON.notesLabel}
                      </Typography>
                      <textarea disabled value={note} />
                    </Box>
                  )}

                  <Box className="farmOrderCard farmOrderTrackingWrapper">
                    <Box>
                      <Typography className="orderTrackingStatus">
                        {orderStatusLabel}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className="orderDeliverText">
                        {isPickUpOrder
                          ? configJSON.pickUpFromLabel
                          : configJSON.deliveringToLabel}
                      </Typography>
                      <Box className="orderUserDetails">
                        {!isPickUpOrder && (
                          <Avatar
                            src={profileImage}
                            className="orderUserAvatar"
                          />
                        )}
                        <Box className="orderUserInfoDetails">
                          {!isPickUpOrder && (
                            <Typography>{this.getUserFullName()}</Typography>
                          )}
                          <Typography>{this.getAddressString()}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Box>
    )
  }
}

export default AdminOrderDetailsDialogContent
// Customizable Area End
