import React, { Component } from "react"
import { Box } from "@material-ui/core"
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import {
  Basket,
  BasketGreen,
  Dinner,
  DinnerGreen,
  Magnify,
  MagnifyGreen,
  OpenBookIcon,
  OpenBookIconGreen,
} from "./assets"
import { AppRoutings } from "../../../components/src/utility/app-routing"

export const styles = () =>
  createStyles({
    mainWrapper: {
      display: "none",
      "@media (max-width: 768px)": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        padding: "0px 15px",
        position: "sticky",
        bottom: "-1px",
        backgroundColor: "#fff",
        zIndex: 9,
        webkitBoxShadow: "0px -4px 3px rgba(0, 0, 0, 0.1)",
        mozBoxShadow: "0px -4px 3px rgba(0, 0, 0, 0.1)",
        boxShadow: "0 -2px 4px 0 rgba(198, 197, 197, 0.25)"
      },
      "& a": {
        display: "block",
        padding: "10px",
      },
      "& img": {
        height: "30px",
      },
    },
    bottomNavIndicator: {
      position: "absolute",
      top: "-2px",
      left: 0,
      height: "3px",
      borderRadius: "6px",
      backgroundColor: "#20a258",
    },
    activeIndicator: {
      width: "100%",
    },
  })

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface S {
  showMenu: boolean
}

const NavMenus = [
  {
    path: AppRoutings.FarmsMarket,
    icon: Basket,
    iconActive: BasketGreen,
  },
  {
    path: AppRoutings.RestaurantLandingPage,
    icon: Dinner,
    iconActive: DinnerGreen,
  },
  {
    path: AppRoutings.Learning,
    icon: OpenBookIcon,
    iconActive: OpenBookIconGreen,
  },
  {
    path: AppRoutings.SearchPage,
    icon: Magnify,
    iconActive: MagnifyGreen,
  },
]

const FarmSectionPaths = [
  AppRoutings.FarmsMarket,
  AppRoutings.FarmDetails,
  AppRoutings.FarmDetailsCatelog,
  AppRoutings.ProductDetails,
]

const RestaurantSectionPaths = [
  AppRoutings.RestaurantLandingPage,
  AppRoutings.RestaurantMapView,
  AppRoutings.RestaurantDetails,
  AppRoutings.RestaurantCatelog,
]

const LearningSectionPaths = [
  AppRoutings.Learning,
  AppRoutings.LearningCourses,
  AppRoutings.LearningCoursesSingle,
  AppRoutings.LearningCoursesEnrolled,
  AppRoutings.LearningCoursesSubsection,
]

const SearchSectionPaths = [
  AppRoutings.Search,
  AppRoutings.SearchPage,
  AppRoutings.SearchFarms,
  AppRoutings.SearchRestaurants,
  AppRoutings.SearchCourses,
  AppRoutings.SearchProducts,
  AppRoutings.SearchDishes,
]

export class MobileNavMenu extends Component<Props, S> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showMenu: false,
    }
  }

  componentDidMount(): void {
    this.handleMenuVisibility()
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {
      location: { pathname },
    } = this.props
    const {
      location: { pathname: prevPathname },
    } = prevProps

    if (pathname !== prevPathname) {
      this.handleMenuVisibility()
    }
  }

  handleMenuVisibility = () => {
    const {
      location: { pathname },
    } = this.props
    const currentSection = pathname.split("/")[1]
    this.setState({ showMenu: currentSection !== "admin" })
  }

  isActive = (path: string) => {
    const pathname = window.location.pathname
    const parts = pathname.split("/")
    const currentSection = `/${parts[1]}` as AppRoutings

    switch (path) {
      case AppRoutings.FarmsMarket:
        return FarmSectionPaths.includes(currentSection)
      case AppRoutings.RestaurantLandingPage:
        return RestaurantSectionPaths.includes(currentSection)
      case AppRoutings.Learning:
        return LearningSectionPaths.includes(currentSection)
      case AppRoutings.SearchPage:
        return SearchSectionPaths.includes(currentSection)
      default:
        return false
    }
  }

  render() {
    const { classes } = this.props
    const { showMenu } = this.state

    return showMenu ? (
      <Box className={classes.mainWrapper}>
        {NavMenus.map((navMenu) => {
          const { path, icon, iconActive } = navMenu
          const isActive = this.isActive(path)
          const iconPath = isActive ? iconActive : icon

          return (
            <Box key={path} style={{ position: "relative" }}>
              <NavLink exact to={path}>
                <img src={iconPath} alt="farms" />
              </NavLink>
              <Box
                className={`${classes.bottomNavIndicator} ${
                  isActive ? classes.activeIndicator : ""
                }`}
              />
            </Box>
          )
        })}
      </Box>
    ) : null
  }
}

export default withStyles(styles)(withRouter(MobileNavMenu))
