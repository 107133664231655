import React, { Component, ReactNode } from 'react';

type ScreenSizeProps = {
  children: (screenSize: string) => ReactNode;
};

type ScreenSizeState = {
  isMobile: boolean;
};

class ScreenSize extends Component<ScreenSizeProps, ScreenSizeState> {
  constructor(props: ScreenSizeProps) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768 // Set initial value based on your mobile breakpoint
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowSize);
  }

  checkWindowSize = () => {
    const isMobile = window.innerWidth <= 768; // Define your mobile breakpoint
    this.setState({ isMobile });
  };

  render() {
    const { isMobile } = this.state;
    return this.props.children(isMobile ? 'mobile' : 'desktop');
  }
}

export default ScreenSize;
