import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { toast } from "react-toastify";
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    isOpen: boolean
    onClose: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean
    backendError: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ChangePasswordDialogController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    updatePasswordApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            isLoading: false,
            backendError: ''
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleCloseDialog = () => {
        this.setState({
            backendError: '',
            isLoading: false
        })
        this.props.onClose()
    }

    handleUpdatePassword = (values: {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    }) => {
        const { oldPassword, newPassword, confirmPassword } = values
        const passwordChanges = {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_new_password: confirmPassword
        }
        
        this.changePasswordApi(passwordChanges)
    }

    changePasswordApi = async (passwordDetails: {
        old_password: string,
        new_password: string,
        confirm_new_password: string
    }) => {
        this.setState({ isLoading: true })
        const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        )
    
        this.updatePasswordApiCallId = requestMessage.messageId
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.changePasswordApiEndPoint
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(passwordDetails)
        )
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutMethod
        )
    
        runEngine.sendMessage(requestMessage.id, requestMessage)
        return true
    }
    // Customizable Area End
      
    async receive(from: string, message: Message) {
        // Customizable Area Start 
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors && !responseJson.error) {

                if(apiRequestCallId === this.updatePasswordApiCallId) {
                    toast.success("Password updated successfully")
                    this.setState({ isLoading: false, backendError: "" })
                    this.handleCloseDialog()
                }
            } else {
                if(apiRequestCallId === this.updatePasswordApiCallId) {
                    const errors = responseJson?.errors?.map((err: any) => err.message || err)?.join(", ")
                    this.setState({ isLoading: false, backendError: errors })
                }
            }
        }
        // Customizable Area End
    }

}
