// Customizable Area Start
import React from "react"
import RewardAcceptanceSelectController, {
  Props,
} from "./RewardAcceptanceSelectController.web"
import { Box, MenuItem, Select } from "@material-ui/core"
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded"
import { createStyles, withStyles } from "@material-ui/core/styles"

export const styles = () =>
  createStyles({
    mainWrapper: {
      marginTop: "10px",
    },
    mainHeading: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#394053",
      marginBottom: "10px",
      fontFamily: "'Montserrat', sans-serif",
    },
    select: {
      width: "100%",
      border: "none",
      borderRadius: "8px !important",
      fontFamily: "'Montserrat', sans-serif",
      backgroundColor: "#f4f5f5",
      "& > .MuiFilledInput-input": {
        textAlign: "end",
        padding: "15px 35px 15px 12px !important",
      },
      "&.Mui-focused": {
        backgroundColor: "#f4f5f5",
        borderRadius: "8px !important",
      },
    },
    itemStatus: {
      marginTop: "2px",
      borderRadius: "8px",
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  })

export class RewardAcceptanceSelect extends RewardAcceptanceSelectController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { value, name, classes } = this.props
    const { rewardAcceptanceOptions } = this.state

    return (
      <Box className={classes.mainWrapper}>
        <h5 className={classes.mainHeading}>Set loyalty point acceptance</h5>
        <Select
          variant="filled"
          value={value}
          data-testid="purchase_percentage_limit"
          name={name}
          disableUnderline
          className={classes.select}
          IconComponent={ExpandMoreRoundedIcon}
          onChange={this.handleChangeOptionSelection}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: {
              paper: classes.itemStatus,
            },
          }}
        >
          {rewardAcceptanceOptions.map((option: number) => (
            <MenuItem key={option} value={option}>
              {option}%
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }
}

export default withStyles(styles)(RewardAcceptanceSelect)
// Customizable Area End
