import * as React from "react";
import "../../web/src/assets/css/content/checkbox.scss";

const CustomCheckboxField = (props: any) => {

  return (
    <div className="customCheckbox">
      <input 
        type="checkbox" 
        id={props.id} 
        checked={props.checked}
        onChange={props.onChange}
      /> 
      <label htmlFor={props.id}></label>
    </div>
  );
}
export default CustomCheckboxField
