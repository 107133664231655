import React from "react"
import { DialogActions, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
    actionRoot: {
        justifyContent: "flex-start",
        padding: theme.spacing(3),
    },
}))

interface IDialogActionsProps {
    children: any
}

const CustomDialogActions = ({ children }: IDialogActionsProps) => {
    const classes = useStyles()
    return (
        <DialogActions className={classes.actionRoot}>{children}</DialogActions>
    )
}

export default CustomDialogActions
