// Customizable Area Start
import React from "react";
import "./assets/css/login.scss";
import {getUserDetails, getCartItems, setUserCartId} from "./assets"
import {connect} from "react-redux";
import OnlineEmailAccountController, {
  Props,
} from "./OnlineEmailAccountController.web";
import { Box, Grid, Button, FilledInput } from "@material-ui/core";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import {CircularProgress} from "@mui/material";
// Customizable Area End
class OnlineEmailAccountLogin extends OnlineEmailAccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { loginSchema, loginSuccess, loginFailedError, openSnackBar, isLoading } = this.state
    const { children } = this.props
    return (
      (
        <>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values) => this.doEmailLogIn(values)}
          >
            {({ handleChange, handleSubmit, errors, touched }) => {
              return (
                <>
                  <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter" || e.code === "NumpadEnter") {
                        handleSubmit()
                      }
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} />
                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        <Box className="text_field web_input_field">
                          <FilledInput
                            placeholder="Email"
                            startAdornment={
                              <InputAdornment position="end">
                                <DraftsOutlinedIcon className="login_input_icons" />
                              </InputAdornment>
                            }
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                            name="email"
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ loginFailedError: "" })
                            }}
                          />
                          {/* <ErrorMessage name="email" /> */}
                          <span className="error">
                            {touched.email && errors.email ? errors.email : null}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="web_input_field">
                          <FilledInput
                            placeholder="Password"
                            type="password"
                            startAdornment={
                              <InputAdornment position="end">
                                <LockOpenOutlinedIcon className="login_input_icons" />
                              </InputAdornment>
                            }
                            classes={{
                              input: "Login_Input",
                              root: "Login_Input_Field",
                              focused: "Login_Input_Field_Focus",
                            }}
                            name="password"
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ loginFailedError: "" })
                            }}
                            inputProps={{ maxLength: 16 }}
                          />
                          <span className="error">
                            {touched.password && errors.password
                              ? errors.password
                              : null}
                          </span>
                        </Box>
                      </Grid>
                      {loginSuccess ? (
                        <Grid item xs={12}>
                          <Alert severity="success">{loginSuccess}</Alert>
                        </Grid>
                      ) : (
                        loginFailedError && (
                          <Grid item xs={12}>
                            <Alert severity="error">{loginFailedError}</Alert>
                          </Grid>
                        )
                      )}

                      {openSnackBar ? (
                        <Grid item xs={12}>
                          <Alert severity="success">logged in Successfully!</Alert>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        {!!children && children}
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          className="login_signup_btn"
                          classes={{
                            label: "normal_login_btn",
                          }}
                          onClick={async () => {
                            await StorageProvider.remove("authToken")
                            handleSubmit()
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={25}
                              className="login_signup_loader"
                            />
                          ) : (
                            "Login"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </>
              )
            }}
          </Formik>
        </>
      )
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const mapStateToProps = (state:any) => {
    return { userInfo: state.user };
}

export {
  OnlineEmailAccountLogin
}

export default withRouter(
  connect(mapStateToProps, { getUserDetails, getCartItems, setUserCartId })(
    OnlineEmailAccountLogin
  )
)
// Customizable Area End
