// Customizable Area Start
import React from "react";
import AdminHeaderController from "./AdminHeaderController.web";
import { withRouter } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "@material-ui/core";
import "../../web/src/assets/css/style.scss";
import "./assets/css/header.scss";
import { Logo, Search_Icon } from "./assets";
import BasicMenu from "./dropdownMenu.web";
import UserInitialization from "./UserInitilization.web";
import CustomSquareConnectButton from "./CustomSqaureConnectButton.web";
import InAppNotifications from "../../blocks/Notifications/src/InAppNotifications.web";
import AdminSearch from "../../blocks/Search/src/AdminSearch/AdminSearch.web";

class AdminHeader extends AdminHeaderController {

  render() {
    const menu = ["Home", "Account Setting", "Log Out"]
    const { sidebarToggle } = this.props
    const {
      loading,
      accountDetails,
      isSquareConnected,
      squareConnectionUrl
    } = this.state
    const name = JSON.parse(accountDetails)
    const firstName = name?.first_name

    return (
      <>
        <UserInitialization />
        <header className="mainHeader">
          <div className="blocks block1">
            <MenuIcon className="toggleIcon toggleBar" onClick={sidebarToggle} />
            <img
              src={Logo}
              className="headerLogo"
              onClick={this.handleHomePageRedirect}
            />
            <div className="userBlock">
              <h5>Good Morning, {firstName}</h5>
              <span>Nice to see you again!</span>
            </div>
          </div>
          <div className="blocks block2">
            <div className="form-group">
              <AdminSearch navigation={this.props.navigation} />
            </div>
          </div>

          {!isSquareConnected && <div className="connectSquareHeaderWrapper">
            <CustomSquareConnectButton
              isSquareConnected={isSquareConnected}
              squareConnectionUrl={squareConnectionUrl}
            />
          </div>}

          <div className="blocks block3">
            <Link className="Icons headerSearchIcon" onClick={sidebarToggle}>
              <img src={Search_Icon} className="notificationImg" />
            </Link>
            <div className="notificationWrapper">
              <InAppNotifications navigation={this.props.navigation} />
            </div>
            <div className="btn-group"></div>
            <BasicMenu handleMenuClick={this.handleMenuClick} menu={menu} />
          </div>
        </header>

        {!loading && !isSquareConnected && <div className="connectSquareBlockWrapper">
          <CustomSquareConnectButton
            isSquareConnected={isSquareConnected}
            squareConnectionUrl={squareConnectionUrl}
          />
        </div>}
      </>
    )
  }
}

//@ts-ignore
export default withRouter(AdminHeader);
// Customizable Area End
