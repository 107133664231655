// Customizable Area Start
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  image: {
    height: "40px",
    width: "40px",
  },
  disableImage: {
    height: "40px",
    width: "40px",
    opacity: 0.5,
  },
})

interface ISocialIconNavProps {
  url: string
  image: string
  imageClassName?: string
}

const SocialIconNav = ({ url, image, imageClassName }: ISocialIconNavProps) => {
  const classes = useStyles()

  return url ? (
    <a href={url} target="_blank" rel="noreferrer">
      <img
        src={image}
        alt="social icon"
        className={`${classes.image} ${imageClassName}`}
      />
    </a>
  ) : (
    <img
      src={image}
      alt="social icon"
      className={`${classes.disableImage} ${imageClassName}`}
    />
  )
}

export default SocialIconNav
// Customizable Area End
