// Customizable Area Start
import React from "react"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Image from "material-ui-image"
const configJSON = require("../config.js")

const useStyles = makeStyles({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "20px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "15px",
  },
  image: {
    borderRadius: "20px",
    objectFit: "cover",
  },
  imageLoader: {
    color: "#20a258"
  },
  courseName: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    color: "#333",
    textTransform: "capitalize",
  },
  authorName: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
    color: "#746f6f",
    fontSize: "15px",
  },
})

interface ISearchCourseCardProps {
  image: string
  courseName: string
  author: string
  redirectUrl: string
}

const SearchCourseCard = ({
  image,
  courseName,
  author,
  redirectUrl,
}: ISearchCourseCardProps) => {
  const classes = useStyles()

  return (
    <Link to={redirectUrl}>
      <Box className={classes.mainWrapper}>
        <Image
          src={image || configJSON.errorText}
          alt={configJSON.courseImageText}
          animationDuration={1500}
          className={classes.image}
          loading={<CircularProgress className={classes.imageLoader} size={25} />}
        />
        <Box>
          <Typography variant="h6" className={classes.courseName}>
            {courseName}
          </Typography>
          <Typography className={classes.authorName}>by {author}</Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default SearchCourseCard
// Customizable Area End
