export const aboutImg = require("../assets/aboutImg");
export const visionImg = require("../assets/visionImg.png");
export const manImg = require("../assets/m1.png");
export const women1Img = require("../assets/w2.PNG");
export const women2Img = require("../assets/w3.PNG");
export const batch1Img = require("../assets/s1.PNG");

export const batch2Img = require("../assets/s2.PNG");
export const batch3Img = require("../assets/s3.PNG");
export const batch4Img = require("../assets/s4.PNG");
export const batch5Img = require("../assets/s6.PNG");
export const formerWoman = require("../assets/women2.PNG");
export const defaultProfileImg = require("../assets/group-8.png")
export const testimonialBG = require("../assets/group-10@2x.png")
export const commaIconImg = require("../assets/quote.png");
export const OldHomeImg = require("../assets/OldHome.png");


export const map = require("../assets/map.png");
export const faqSideImg = require("../assets/faqsideimg.png");
export const fern = require("../assets/image-fern.png");

export const { updateActiveTab } = require('../../../components/src/redux/Users/userActions');