import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    children: React.ReactNode
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class WishlistController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);

    };
    // Customizable Area End




}
