// Customizable Area Start
import React from "react";
import {
  Grid,
  Container,
  Box,
  TextareaAutosize,
  Button,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import CommentWeb from "./comment.web";
import "../assets/css/farmForum.scss";
import {
  closeModelWhiteIcon,
  chat_icon,
  like_icon,
  like_fill_icon,
  carrotBanner
} from "../assets";
import ForumDetailsController, { IReview } from "./ForumDetailsController.web"
import LoginSignUpDialog from "../langinpageLoggedIn/LoginSignUpDialog.web";
import { ToastContainer } from "react-toastify"
// Customizable Area End

class FarmForumWeb extends ForumDetailsController {

  render() {
    // Customizable Area Start
    const { forumDetails, onClose } = this.props
    const bannerImage = forumDetails?.bannerImage ?? carrotBanner
    const {
      loader,
      description,
      review,
      loginDialog,
      addReviewLoader,
      isLiked,
      commentCount,
      reviews,
    } = this.state

    return (
      <>
        <Box>
          <Box className="farm_forum_modal_container">
            <Grid container>
              <Grid item xs={12}>
                <Box style={{ background: "#fff" }}>
                  <Box
                    className="carrot_banner"
                    style={{ backgroundImage: `url(${bannerImage})` }}
                  >
                    <IconButton onClick={() => onClose()}>
                      <img src={closeModelWhiteIcon} height={38} />
                    </IconButton>
                  </Box>
                  <Box className="forum_content_wrapper">
                    <Container>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h2>{forumDetails?.heading}</h2>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="farm_forum_likes_comments_wrapper">
                            <p className="forum_like_wrapper" onClick={this.handleLikeChange}>
                              <img src={isLiked ? like_fill_icon : like_icon} />
                              <span>
                                <span>{forumDetails?.likeCount}</span>
                                {forumDetails && forumDetails.likeCount > 1 ? "Likes" : "Like"}
                              </span>
                            </p>
                            <p>
                              <img src={chat_icon} />
                              <span>
                                <span>{commentCount}</span>
                                {commentCount > 1 ? "Comments" : "Comment"}
                              </span>
                            </p>
                          </Box>
                        </Grid>
                        {loader ? (
                          <Grid item xs={12}>
                            <Box className="forum_loader_container">
                              <CircularProgress size={25} />
                            </Box>
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <Box className="scroller">
                                <TextareaAutosize
                                  maxRows={15}
                                  readOnly
                                  className="farm_forum_scroll_textarea"
                                  value={description}
                                />
                              </Box>
                            </Grid>

                            {reviews?.length > 0 && (
                              <>
                                <Grid item xs={12}>
                                  <Box className="farm_forum_comments_header">
                                    <h5>Comments</h5>
                                    <span>Write Comment</span>
                                  </Box>
                                </Grid>

                                {reviews.map((review: IReview) => {
                                  const {
                                    id,
                                    attributes: {
                                      account_id,
                                      full_name,
                                      comment,
                                      image,
                                      date,
                                      report_spam,
                                      replies,
                                    },
                                  } = review
                                  return (
                                    <Grid item xs={12} key={review.id}>
                                      <CommentWeb
                                        reviewId={id}
                                        accountId={account_id}
                                        fullName={full_name}
                                        comment={comment}
                                        profileImage={image?.url || ""}
                                        reviewDate={date}
                                        isSpamReported={report_spam}
                                        replies={replies.data}
                                        handleCommentEdit={this.editComment}
                                        handleCommentRemove={this.removeComment}
                                        handleReviewEdit={this.editReply}
                                        handleAddNewReview={this.handleAddNewReview}
                                        handleReviewsRemove={this.removeReply}
                                      />
                                    </Grid>
                                  )
                                })}
                              </>
                            )}
                          </>
                        )}

                        <Grid item xs={12}>
                          <Box className="farm_forum_comment_textarea_wrapper">
                            <TextareaAutosize
                              className="farm_forum_comment_textarea"
                              minRows={3}
                              placeholder="Write your view here..."
                              disabled={addReviewLoader}
                              value={review}
                              onChange={this.handleReviewChange}
                            />
                            <Box className="farm_forum_comment_textarea_btn_wrapper">
                              <Button
                                classes={{
                                  disabled: "reply_btn_disabled",
                                }}
                                disabled={addReviewLoader || !review}
                                onClick={this.handleReviewSubmit}
                              >
                                {addReviewLoader ? (
                                  <CircularProgress size={18} color="inherit" />
                                ) : (
                                  "Send"
                                )}
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ToastContainer />
        <LoginSignUpDialog
          isOpen={loginDialog}
          onClose={this.handleLoginClose}
          onLoginSuccess={this.getForumDetailsApi}
        />
      </>
    )
    // Customizable Area End
  }
}

export default FarmForumWeb;
