// Customizable Area Start
import React from "react"
import { Collapse, ListItemText, ListItemIcon, List } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import "../../web/src/assets/css/content/list.scss"
import { ProductsNormal } from "./assets"
import { withRouter } from "react-router-dom"

interface IMenu {
  name: string
  route: string
}

const CustomList = (props: any) => {
  const [open, setOpen] = React.useState(false)
  const {
    route,
    component,
    subMenu,
    subMenuClick,
    sidebarToggle,
    toggle,
    subdomain,
    leftIcon,
    getContibuterdetails,
  } = props

  React.useEffect(() => {
    if (
      props.location.pathname.includes(subdomain) ||
      (subdomain === "farm" && props.location.pathname.includes("add-product"))
    ) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [props.location.pathname])

  const handleClick = () => {
    if (!open) {
      if (subdomain == "learning" && subMenu[0].route == "details") {
        getContibuterdetails()
      } else {
        props.history.push(`/admin/${subdomain}/${subMenu[0].route}`)
      }
    }

    setOpen(!open)
    toggle && sidebarToggle()
  }

  const handleClassNameFarm = (route: string) => {
    const currentPathName = props.location.pathname

    switch (route) {
      case "products":
        return currentPathName.includes("add-product")
      case "forums":
        return currentPathName.includes("add-forum")
      case "details":
        return currentPathName.includes("add-farm")
      case "campaigns":
        return currentPathName.includes("add-campaigns")
      default:
        return false
    }
  }

  const handleClassNameCourse = (route: string) => {
    const currentPathName = props.location.pathname

    switch (route) {
      case "course-list":
        return (
          currentPathName.includes("add-course") ||
          currentPathName.includes("add-task")
        )
      case "students":
      case "forums":
        return currentPathName.includes("add-forum")
      case "purchases":
        return currentPathName.includes("add-farm")
      case "campaigns":
        return currentPathName.includes("add-campaigns")
      default:
        return false
    }
  }

  const routingFunction = (route: string[], menu: IMenu) => {
    if (route[3] === menu.route.split("/")[0]) {
      return "listItemsTextActive"
    } else if (route[2] === "farm") {
      return handleClassNameFarm(menu.route) && "listItemsTextActive"
    } else {
      return handleClassNameCourse(menu.route) && "listItemsTextActive"
    }
  }

  return (
    <div className="customList">
      <li
        className={`listItems ${
          route[2] === subdomain ? "listItemsActive" : ""
        }`}
        onClick={handleClick}
      >
        <ListItemIcon>
          <img
            src={leftIcon || ProductsNormal}
            className="icons"
            height="18px"
            width="18px"
          />
        </ListItemIcon>
        <span className={open ? " bottomIconActive" : "bottomIcon"} />
        <ListItemText primary={`${component}`} />

        {open ? <ExpandLess /> : <ExpandMore />}
      </li>
      <Collapse className="collapseList" in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenu.map((menu: IMenu) => {
            const { name, route: menuRoute } = menu

            return (
              <ListItemText
                key={name}
                className={`${routingFunction(route, menu)}`}
                primary={name}
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  subMenuClick(menuRoute)
                  toggle && sidebarToggle()
                }}
              />
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}

export default withRouter(CustomList)
// Customizable Area End
