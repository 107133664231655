Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Search";
exports.labelBodyText = "Search Body";

exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"

exports.AUTH_TOKEN = "authToken"
exports.SOMETHING_WENT_WRONG = "Something went wrong"
exports.ENTER_KEY = "Enter"
exports.ESCAPE_KEY = "Escape"
exports.noResultsFoundText = "No results found"
exports.noFarmsFoundText = "No farms found"
exports.noRestaurantsFoundText = "No restaurants found"
exports.noCoursesFoundText = "No courses found"
exports.noProductsFoundText = "No products found"
exports.noDishesFoundText = "No dishes found"
exports.productsText = "Products"
exports.dishesText = "Dishes"
exports.farmsText = "Farms"
exports.restaurantsText = "Restaurants"
exports.coursesText = "Courses"
exports.queryText = "query"
exports.relatedCategoriesText = "Related categories"
exports.nearByText = "Near by"
exports.nearByFarmsText = "Near by farms"
exports.nearByRestaurantText = "Near by restaurants"
exports.priceText = "Price"
exports.refineByText = "Refine by"
exports.productTagText = "Product Tag"
exports.courseImageText = "course image"
exports.errorText = "error"
exports.noneText = "none"
exports.blockText = "block"
exports.categoryTypeFarmQuery = "?category_type=farm"
exports.typeFarmQuery = "?type=Farm"
exports.tagsTypeFarmQuery = "?tags_type=farm"
exports.categoryTypeRestaurantQuery = "?category_type=restaurant"
exports.typeRestaurantQuery = "?type=Restaurant"
exports.tagsTypeRestaurantQuery = "?tags_type=restaurant"
exports.categoryTypeCourseQuery = "?category_type=e-learning"
exports.typeCourseQuery = "?type=Course"
exports.pageText = "page"
exports.latitudeText = "latitude"
exports.longitudeText = "longitude"
exports.filterByCategoryNameText = "filter_by_category_name"
exports.filterByTagNameText = "filter_by_tag_name"
exports.filterStartPriceText = "filter_start_price"
exports.filterEndPriceText = "filter_end_price"
exports.SearchForProductsText = "Search for products..."
exports.SearchSomethingHereText = "Search something here..."

exports.searchApiEndPoint = "bx_block_catalogue/search/search"
exports.searchSuggestionApiEndPoint = "bx_block_catalogue/search/pop_up"
exports.searchFarmsApiEndPoint = "bx_block_catalogue/farms"
exports.searchProductsApiEndPoint = "bx_block_catalogue/products"
exports.searchDishesApiEndPoint = "bx_block_catalogue/menus"
exports.searchRestaurantsApiEndPoint = "bx_block_catalogue/restaurants"
exports.searchCoursesApiEndPoint = "bx_block_library/courses/course_list"
exports.categoriesApiEndPoint = "bx_block_catalogue/categories"
exports.priceRangeApiEndPoint = "bx_block_catalogue/min_max_price"
exports.tagsApiEndPoint = "bx_block_catalogue/tags"
exports.adminSearchSuggestionsApiEndPoint = "user_admin/search/search"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End