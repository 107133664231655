import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import StorageProvider from "../../../../framework/src/StorageProvider";
import { isTokenExpireResponse } from "../../../../components/src/utility/helper";
import { IImage } from "../../../../components/src/interfaces/common";
import { IAddonsObject } from "../../../../components/src/interfaces/restaurant";
import { v4 as uuidv4 } from "uuid"
export const configJSON = require("../config")

interface IAddOn {
    id: number
    name: string
    price: number
    category: string
    categoryId: number
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string
  image: string
  price: string
  cartId: string
  addons: IAddonsObject
  dishName: string
  description: string
  restaurantId: string
  deliveryTime: string
  restaurantName: string
  discountedPrice: string
  restaurantImages: IImage[]
  close: () => void
  getCartItems: () => void
  setCartBadge: (count: number) => void
  getDisplayCartItems: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quantity: number
  totalAmount: number
  cookingInstruction: string
  extraThings: IAddOn[]
  addToCartLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShowMenuItemModalWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  addToCartApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      quantity: 1,
      extraThings: [],
      cookingInstruction: '',
      totalAmount: Number(this.props.discountedPrice),
      addToCartLoader: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  getCharges = () => {
    const { discountedPrice } = this.props
    return Number(discountedPrice)
  }

  getExtraThingsPrices = (extraThings = this.state.extraThings) => {
    return +extraThings
      ?.map((item: { price: number }) => +item.price)
      .reduce((prev: number, curr: number) => prev + curr, 0)
  }  

  capitalizeValue = (value: string) => {
    if (!value) return '';
    return `${value[0].toUpperCase()}${(value.slice(1))}`
  }

  handleTotalAmount = (
    quantity = this.state.quantity,
    extraPrices = this.getExtraThingsPrices()
  ) => {
    const total = quantity * (this.getCharges() + extraPrices)
    this.setState({ totalAmount: total })
  }  

  increaseQuantity = () => {
    const newQuantity = this.state.quantity + 1
    this.setState({ quantity: newQuantity })
    this.handleTotalAmount(newQuantity)
  }

  decreaseQuantity = () => {
    const newQuantity = this.state.quantity - 1
    if (this.state.quantity > 1) {
      this.setState({ quantity: newQuantity })
      this.handleTotalAmount(newQuantity)
    }
  }

  toggleExtraThings = (
    categoryId: number,
    category: string,
    name: string,
    price: number,
    id: number
  ) => {
    const details = {
      category,
      id,
      name,
      price,
      categoryId,
    }
  
    this.setState(prevState => {
      const extraThings = [...prevState.extraThings];
      const sameCatIndex = extraThings.findIndex(
        (item: IAddOn) => item.categoryId === categoryId
      )
  
      if (sameCatIndex !== -1) {
        if (id === extraThings[sameCatIndex].id) {
          extraThings.splice(sameCatIndex, 1)
        } else {
          extraThings[sameCatIndex] = details
        }
      } else {
        extraThings.push(details)
      }
  
      const extraPrices = this.getExtraThingsPrices(extraThings)
      this.handleTotalAmount(prevState.quantity, extraPrices)
  
      return { extraThings }
    })
  }  

  isExtraThings = (category: string, id: number) => {
    return !!this.state.extraThings?.find(
      (details: IAddOn) => details.id === id && details.category === category
    )
  }  

  addOrders = async () => {
    const isLoggedIn = !!await StorageProvider.get(configJSON.AUTH_TOKEN)

    if(isLoggedIn) this.handleLoggedInAddOrder()
    else this.handleLocalAddOrder()
  }

  handleLoggedInAddOrder = () => {
    const { id, cartId } = this.props
    const { extraThings, cookingInstruction, quantity } = this.state
    const addOnIds = extraThings?.map((addOn: { id: number }) => addOn.id)

    const order = {
      cartable_id: id,
      cartable_type: configJSON.CARTABLE_TYPE_MENU,
      cart_id: cartId,
      quantity,
      special_cooking_instruction: cookingInstruction.trim(),
      addons_ids: addOnIds
    }
    this.addToCartApi(order)
  }

  handleLocalAddOrder = async () => {
    const uniqueId = uuidv4()
    const {
      restaurantId,
      restaurantName,
      restaurantImages,
      deliveryTime,
      image,
      id,
      dishName,
      price,
      discountedPrice,
      description,
      close,
      setCartBadge,
      getDisplayCartItems,
    } = this.props
  
    const { cookingInstruction, extraThings, quantity } = this.state
  
    try {
      const ordersFromStorage =
        (await StorageProvider.get(configJSON.ORDERS_STORAGE_KEY)) || "[]"
      const orders = JSON.parse(ordersFromStorage)
      let newOrders = [...orders]
  
      const isForSameRestaurant = newOrders?.some(
        (order: any) =>
          order.sellerId === restaurantId &&
          order.orderCategory === configJSON.CARTABLE_TYPE_MENU
      )
  
      const order = {
        uniqueId,
        orderCategory: configJSON.CARTABLE_TYPE_MENU,
        cartableId: id,
        quantity,
        description,
        productImage: image,
        name: dishName,
        price: price,
        comparePrice: Number(discountedPrice),
        cookingInstruction: cookingInstruction.trim(),
        extraThings,
        addOnsPrice: this.getExtraThingsPrices(),
        sellerId: restaurantId,
        sellerName: restaurantName,
        sellerImage: restaurantImages?.length > 0 ? restaurantImages[0]?.url : "",
        deliveryTime,
      }
  
      if (!isForSameRestaurant) newOrders = [order]
      else {
        const sameOrderIndex = newOrders.findIndex((ord: any) => {
          const curOrdAddOnsIds = ord.extraThings.map((addon: any) => addon.id)
          const newOrdAddOnsIds = extraThings.map((addon: any) => addon.id)
          const isAddonsSame =
            curOrdAddOnsIds.length === newOrdAddOnsIds.length &&
            newOrdAddOnsIds.every((addonId: string) =>
              curOrdAddOnsIds.includes(addonId)
            )
          const isInstructionSame =
            ord.cookingInstruction === cookingInstruction.trim()
          const isSameProduct = ord.cartableId === id
  
          return isAddonsSame && isInstructionSame && isSameProduct
        })
        if (sameOrderIndex === -1) newOrders.push(order)
        else {
          newOrders[sameOrderIndex].quantity =
            newOrders[sameOrderIndex].quantity + quantity
        }
      }
  
      await StorageProvider.set(
        configJSON.ORDERS_STORAGE_KEY,
        JSON.stringify(newOrders)
      )
      setCartBadge(newOrders.length)
      close()
      getDisplayCartItems()
      toast.success("Item added to cart")
    } catch (e) {
      console.log("error", e)
      toast.error("Something went wrong!")
    }
  }  

  addToCartApi = async (orderDetails: any) => {
    this.setState({ addToCartLoader: true })
    const token = await StorageProvider.get(configJSON.AUTH_TOKEN)

    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addToCartApiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(orderDetails)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleErrorApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (isTokenExpireResponse(responseJson)) return

    if (apiRequestCallId === this.addToCartApiCallId) {
      this.setState({ addToCartLoader: false })
    }

    if (responseJson?.errors) {
      responseJson.errors?.forEach((err: any) => {
        toast.error(err?.message);
      });
    } else {
      toast.error("Something went wrong");
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        if(apiRequestCallId === this.addToCartApiCallId) {
          this.setState({ addToCartLoader: false })
          this.props.getCartItems()
          this.props.close()
          toast.success("Item added to cart")
        }
      } else {
        this.handleErrorApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

}
