// Customizable Area Start
import React from "react"
import { Box } from "@material-ui/core"
import { fbImg } from "../assets"
import { LoginSocialFacebook, IResolveParams } from "reactjs-social-login"
import CustomServiceButton from "./CustomServiceButton.web"
import { toast } from "react-toastify"
const configJSON = require("../config.js")

interface IFacebookLoginServiceButtonProps {
    isFbConnected: boolean
    handleFacebookResponse : any
}

const FacebookLoginServiceButton = ({
    isFbConnected,
    handleFacebookResponse
}: IFacebookLoginServiceButtonProps) => {
    const handleErrorResponse = (error:any) => {
        if(error.message){
          toast.error(configJSON.facebookLoginFailedMsg)
        }
    }
    return (
        <Box>
            {!isFbConnected ? (
                <LoginSocialFacebook
                appId={configJSON.FACEBOOK_APP_ID || ""}
                fieldsProfile={
                    "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                }
                onResolve={({data} : IResolveParams) => handleFacebookResponse(data)}
                onReject={(err) => handleErrorResponse(err)}
            >
                <CustomServiceButton
                    icon={fbImg}
                    btnText="Facebook"
                    isConnected={isFbConnected}
                />
            </LoginSocialFacebook>
            ) : (
                <CustomServiceButton
                    data-test-id="fbConnected"
                    icon={fbImg}
                    btnText="Facebook"
                    isConnected={isFbConnected}
                />
            )}
        </Box>
    )
}

export default FacebookLoginServiceButton
// Customizable Area End
