// Customizable Area Start
import React, { useState } from "react";
import { Apple_Icon } from "./assets";
// common css
import "../../../web/src/assets/css/style.scss";
import "../assets/css/analytics.scss";
// mui components
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LocalPostOfficeIcon from "@material-ui/icons/LocalPostOffice";
// custom components
import CustomSelectField from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextfield from "../../../components/src/CustomTextfield.web";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Link,
  Button,
  MenuItem,
  Select,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
  Box,
  Typography,
} from "@material-ui/core";
import CustomChips from "../../../components/src/chip";
import { withRouter } from "react-router-dom";

// icons
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';

//charts
import {
  Chart,
  registerables,
  ChartTypeRegistry,
  ChartConfiguration,
  ChartType,
  ChartData,
} from "chart.js";
Chart.register(...registerables);

import AdminAnalyticsController, {
  Props,
} from "./AdminAnalyticsController.web";
import BasicMenu from "../../../components/src/dropdownMenu.web";
// Customizable Area End

class AdminAnalytics extends AdminAnalyticsController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  createChart() {
    const labels: any = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const data = {
      labels: labels,
      datasets: [
        {
          label: "New Customer",
          backgroundColor: "#1668FA",
          data: [0, 300, 30, 40, 60, 400, 90, 100, 30, 300, 40],
          borderWidth: 3,
          borderColor: "#1668FA",
          fill: false,
          tension: 0.4,
        },
        {
          label: "Returning Customer",
          backgroundColor: "#5FDAA4",
          data: [100, 200, 50, 10, 0, 200, 50, 100],
          borderWidth: 3,
          borderColor: "#5FDAA4",
          fill: false,
          tension: 0.4,
        },
      ],
    };
    const lineConfig: ChartConfiguration<
      keyof ChartTypeRegistry,
      number[],
      string
    > = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            // display: false,
          },
          x: {
            grid: {
              display: true,
              color: "#F2F3F7",
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
      },
    };
    this.lineChart = new Chart(
      document.getElementById("lineChart") as HTMLCanvasElement,
      lineConfig
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <main className="analyticsWrapper">
          <div className="AnalyticsTopHeader">
            <Typography
              variant="h6"
              className="Analytics"
              style={{ fontWeight: "bold" }}
            >
              Analytics
            </Typography>
            <div className="list-items">
              <ul>
                <li>
                  <a href="#" style={{ background: "#fff" }}>
                    Farm
                  </a>
                </li>
                <li>
                  <a href="#">Learning</a>
                </li>
                <li>
                  <a href="#">Restaurant</a>
                </li>
              </ul>
            </div>
          </div>

          {/* top cards */}
          <Grid container spacing={5} className="analyticsContainer cardContainer">
            <Grid item xs={12} md={4}>
              <Card className="card infoCard">
                <CardContent className="cardContent">
                  <Typography className="h5">Total sales</Typography>
                  <div className="priceRow">
                    <Typography className="h1">$276323</Typography>
                    {/* <CustomChips  label="+2.5% ↑" /> */}
                    <small>+2.5% ↑</small>
                  </div>
                  <div className="comparsion">
                    <Typography variant="caption" display="block" gutterBottom>
                      Compared to ($21340 last year)
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="card infoCard ">
                <CardContent className="cardContent">
                  <Typography className="h5">This Month Sales </Typography>
                  <div className="priceRow">
                    <Typography className="h1">$20199</Typography>
                    {/* <CustomChips label="+0.5% ↑" /> */}
                    <small>+0.5% ↑</small>
                  </div>
                  <div className="comparsion">
                    <Typography variant="caption" display="block" gutterBottom>
                      Compared to ($19000 last Month)
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="card infoCard">
                <CardContent className="cardContent">
                  <Typography className="h5">Return</Typography>
                  <div className="priceRow">
                    <Typography className="h1">$110</Typography>
                    {/* <CustomChips label="-1.5% ↓" /> */}
                    <small style={{ color: "red" }}>-1.5% ↓</small>
                  </div>
                  <div className="comparsion">
                    <Typography variant="caption" display="block" gutterBottom>
                      Compared to ($165 last year)
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* ends: top cards */}

          {/* chart */}
          <Grid className="chartContainer cardContainer">
            <Card className="card">
              <div className="chartCard">
                <div className="charthead">
                  <h5>Sales Figures</h5>
                  <ul className="listMenu">
                    <li className="listItems primary">
                      <span>New Customers</span>
                    </li>
                    <li className="listItems success">
                      <span> Returning Customer</span>
                    </li>
                  </ul>
                </div>
                <div className="dashboardChart" >
                  <canvas id="lineChart" />
                </div>
              </div>
            </Card>
          </Grid>
          {/* chart */}

          {/* table container */}
          <Grid container spacing={3} className="anaylticsHistoryRow">
            <Grid item xs={12} md={4} className="anaylticsCol">
              <div className="analyticsContainer cardContainer pt-0">
                <Card className="card analyticsCard">
                  <Box className="customer_transaction">
                    <Box className="analytics_header">
                      <h5 className="h5">New <br></br>Customers</h5>
                      <div className="option-dropDown">

                        <BasicMenu />
                      </div>
                    </Box>
                    <Box className="customer_ids">
                      <div className="customer_flex">
                        <div className="circle" />
                        <div>
                          <Typography className="id_name">
                            Isabella Moran
                          </Typography>
                          <small>Customer ID#00222</small>
                        </div>
                      </div>
                      <div>
                        <LocalPostOfficeIcon className="envelope" />
                      </div>
                    </Box>
                    <Box className="customer_ids">
                      <div className="customer_flex">
                        <div className="circle" />
                        <div>
                          <Typography className="id_name">Warren Craig</Typography>
                          <small>Customer ID#00221</small>
                        </div>
                      </div>
                      <div>
                        <LocalPostOfficeIcon className="envelope" />
                      </div>
                    </Box>
                    <Box className="customer_ids">
                      <div className="customer_flex">
                        <div className="circle" />
                        <div>
                          <Typography className="id_name">Andrew Ellis</Typography>
                          <small>Customer ID#00220</small>
                        </div>
                      </div>
                      <div>
                        <LocalPostOfficeIcon className="envelope" />
                      </div>
                    </Box>
                    <Box className="customer_ids">
                      <div className="customer_flex">
                        <div className="circle" />
                        <div>
                          <Typography className="id_name">Sam Conner</Typography>
                          <small>Customer ID#00219</small>
                        </div>
                      </div>
                      <div>
                        <LocalPostOfficeIcon className="envelope" />
                      </div>
                    </Box>
                    <Box
                      className="customer_ids">
                      <div className="customer_flex">
                        <div className="circle" />
                        <div>
                          <Typography className="id_name">Sam Conner</Typography>
                          <small>Customer ID#00218</small>
                        </div>
                      </div>
                      <div>
                        <LocalPostOfficeIcon className="envelope" />
                      </div>
                    </Box>
                    <Typography variant="caption" className="view_more">
                      <a href="#">VIEW MORE CUSTOMERS </a>
                    </Typography>
                  </Box>
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} md={8} className="anaylticsCol">
              <div className="analyticsContainer cardContainer pt-0">
                <Card className="card analyticsCard">
                  {/* table container */}
                  <div className="analytics_header">
                    <h5>Transaction History</h5>
                    <div className="option-dropDown">
                      <BasicMenu />
                    </div>
                  </div>
                  <div className="tableContainer table-block analyticsListTable">
                    <TableContainer className="tableContainer tableHeaderContainer" component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead className="tableHead">
                          <TableRow>
                            <TableCell align="left">Payment Number</TableCell>
                            <TableCell align="left" >Date & Time</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="center" >Status</TableCell>
                          </TableRow>
                        </TableHead>


                        <TableBody>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <DoneIcon className="paymentSuccessIcon" />
                                <Typography>
                                  Payment from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className=" greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <RefreshIcon className="paymentRefundIcon" />
                                <Typography>
                                  Process refund from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className="greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <DoneIcon className="paymentSuccessIcon" />
                                <Typography>
                                  Payment from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className=" greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <RefreshIcon className="paymentRefundIcon" />
                                <Typography>
                                  Process refund from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className="greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <DoneIcon className="paymentSuccessIcon" />
                                <Typography>
                                  Payment from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className=" greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >
                              <div className="productCell">
                                <RefreshIcon className="paymentRefundIcon" />
                                <Typography>
                                  Process refund from <br />
                                  #10321
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component={"td" as any}
                              scope="row"
                              align="left"
                            >

                              <Typography>
                                March 21, 2019 <br />
                                03:30pm
                              </Typography>

                            </TableCell>
                            <TableCell

                              style={{ borderBottom: "none" }}
                              align="left"
                            >
                              + $250.00
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              <div className="greenContainChip">
                                <CustomChips label="completed" />
                              </div>
                            </TableCell>
                          </TableRow>


                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <Typography variant="caption" className="view_more">
                    <a href="#">VIEW ALL TRANSACTIONS </a>
                  </Typography>
                  {/* ends: table container */}
                </Card>
              </div>
            </Grid>
          </Grid>
          {/* ends: table container */}
        </main>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withRouter(AdminAnalytics);
// Customizable Area End
