import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Box, StepConnector, StepIconProps, Typography } from '@material-ui/core';
import { PlacedIcon, ConfirmedIcon, ProcessedIcon, DeliveredIcon } from './assets';
import clsx from 'clsx';
import RoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepDetailContainer: {
    display: "flex",
    gap: "20px",
    padding: '5px 10px'
  },
  stepperLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '18px',
    fontWeight: 500,
    color: '#000',
    "@media screen and (max-width: 600px)": {
      fontSize: '16px'
    },
    "@media screen and (max-width: 300px)": {
      fontSize: '14px'
    }
  },
  stepperDesc: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    color: '#7e7e7e'
  },
  currentStep: {
    color: '#20a258 !important'
  },
  pendingStep: {
    color: '#cecccc !important'
  },
  currentStepImg: {
    filter: 'invert(54%) sepia(11%) saturate(3020%) hue-rotate(93deg) brightness(92%) contrast(84%)'
  },
  pendingStepImg: {
    filter: 'invert(80%) sepia(20%) saturate(0%) hue-rotate(63deg) brightness(98%) contrast(91%)'
  }
}))

const useCustomStepStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        marginLeft: '1.8px'
      },
      active: {
        color: '#20a258',
      },
      completed: {
        color: '#f51c1c',
        zIndex: 1,
        fontSize: 18,
      },
})

const getLabel = () => {
    return [{
        label: 'Order Placed',
        description: 'We have received your order',
        stepIcon: PlacedIcon
    },
    {
        label: 'Order Confirmed',
        description: 'Your order has been confirmed',
        stepIcon: ConfirmedIcon
    },
    {
        label: 'Order Processed',
        description: 'We are preparing your order',
        stepIcon: ProcessedIcon
    },
    {
        label: 'Delivered',
        description: 'Your order is delivered',
        stepIcon: DeliveredIcon
    }]
}

interface IOrderTrackingStepper {
  isDisabled: boolean
  currentStep: number
}

const OrderTrackingStepper = ({ currentStep, isDisabled } : IOrderTrackingStepper) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(currentStep)
  const steps = getLabel()

  const CustomStep = (props: StepIconProps) => {
    const classes = useCustomStepStyles();
    const { active, completed } = props;
  
    return (
        <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <RoundedIcon fontSize='small' className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
          })} />
    </div>
    );
  }

  const CustomConnector = withStyles({
    active: {
      '& $line': {
        borderColor: '#4c95f2',
        borderWidth: '2px'
      },
    },
    completed: {
      '& $line': {
        borderColor: '#4c95f2',
        borderWidth: '2px'
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
      borderWidth: '2px'
    },
  })(StepConnector);

  return (
      <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomConnector />}>
              {steps.map((step: { label: string, description: string, stepIcon: string }, index: number) => {
                const { label, description, stepIcon } = step

                  return (<Step key={label}>
                      <StepLabel StepIconComponent={CustomStep}>
                        <Box className={classes.stepDetailContainer}>
                            <Box className='ot_step_img_wrapper'>
                                <img
                                    className={clsx('ot_step_img', {
                                        [classes.currentStepImg]: index === activeStep,
                                        [classes.pendingStepImg]: index > activeStep || isDisabled,
                                    })}
                                    src={stepIcon}
                                    alt="step-icon"
                                />
                            </Box>
                              <Box>
                                  <Typography
                                      className={clsx(classes.stepperLabel, {
                                          [classes.currentStep]: index === activeStep,
                                          [classes.pendingStep]: index > activeStep || isDisabled,
                                      })}
                                  >
                                      {label}
                                  </Typography>
                                  <Typography
                                      className={clsx(classes.stepperDesc, {
                                          [classes.currentStep]: index === activeStep,
                                          [classes.pendingStep]: index > activeStep || isDisabled,
                                      })}
                                  >
                                      {description}
                                  </Typography>
                              </Box>
                        </Box>
                        </StepLabel>
                  </Step>)
              })}
          </Stepper>
      </div>
  );
}

export default OrderTrackingStepper