// Customizable Area Start
import React, { Component } from "react"
import { Grid, Box } from "@material-ui/core"
import { leaveImg } from "./assets"
import "./assets/css/leaveImgWithText.scss"

interface Props {
    heading: string
    text: string
    linkText: string
}

class LeafImgWithTextBanner extends Component<Props> {
    render() {
        const { heading, text, linkText } = this.props
        return (
            <>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10}>
                        <Box className="leave_img_with_text">
                            <Box>
                                <img src={leaveImg} alt="leaveImg" />
                            </Box>
                            <h3>{heading}</h3>
                            <p>
                                {text}{!!linkText && <span>&nbsp;{linkText}</span>}
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default LeafImgWithTextBanner
// Customizable Area End
