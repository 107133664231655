// Customizable Area Start
import React from "react";
import "../../../web/src/assets/css/style.scss";
import "../../../web/src/assets/css/content/tab.scss";
import "../assets/css/farmDetails.scss";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCheckBox from "./AdminFarmDetailsCheckBox.web"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { commonCmptStyles } from "./dropDownCss";
import {
  Grid,
  Typography,
  Link,
  IconButton,
  withStyles,
  TextField,
  InputAdornment,
  TextareaAutosize,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { FileUploadIcon, correct_icon} from "../../../components/src/assets";
import {facebook, instagram} from "./assets"
import AdminFarmDetailsController, {
  Props,
} from "./AdminFarmDetailsController.web";
import OtpInput from "react-otp-input";
import CustomGoogleMap from "../../../components/src/CustomGoogleMap.web";
import CustomGooglePlaceAutoComplete from "../../../components/src/CustomGooglePlaceAutoComplete.web";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLocationErrorMessage from "../../../components/src/CustomLocationErrorMessage.web";
import RewardAcceptanceSelect from "./RewardAcceptanceSelect/RewardAcceptanceSelect.web";
import AdminTransactionHistoryButton from "./AdminTransactionHistory/AdminTransactionHistoryButton.web";
export const configJSON = require("./config");

const Schema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string()
  .max(1000, "Description must be at most 1000 characters.")
  .required("This field is required."),
  owner_otp: Yup.string()
    .when("isOwnerVerified", {
      is: (isOwnerVerified) => !isOwnerVerified,
      then: Yup.string().required("This field is required."),
    }),
  org_address: Yup.string()
    .required("This field is required."),
  org_contact_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number not valid")
    .required("This field is required."),
  owner_name: Yup.string().required("This field is required."),
  latitude: Yup.string().required("This field is required"),
  longitude: Yup.string().required("This field is required"),
  owner_address: Yup.string()
    .email("Invalid email format")
    .required("This field is required."),
  owner_contact_number: Yup.string().when("is_same_number", {
    is: (is_same_number) => !is_same_number,
    then: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number not valid")
      .required("This field is required."),
  }),
  address: Yup.array()
    .required("This field is required.")
    .min(1),
  selectedImages: Yup.array().when("editImages", {
    is: (editImages) => (editImages.length == 0),
    then: Yup.array()
      .required("This field is required.")
      .min(1),
  }),
  facebook:Yup.string().matches(configJSON.facebookRegex,"Please enter a valid URL"),
  instagram:Yup.string().matches(configJSON.instagramRegex,"Please enter a valid URL")
});
const addressSchema = Yup.object().shape({
  address_line1: Yup.string().required("This field is required."),
  city: Yup.string().required("This field is required."),
  state: Yup.string().required("This field is required."),
  zipcode: Yup.string()
    .required("This field is required.")
    .length(5, "Please enter a valid zip code")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter valid zip code"),
  number: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number not valid"),
  email: Yup.string()
    .email("Invalid email format")
});
// Customizable Area End

class FarmDetails extends AdminFarmDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    const isEdit = this.state.farmMode == "edit";
    const farmdetails =this.state.farmDetails?.attributes;
    const farmIcons= this.state.farmIcons

    return (
      <>
        <Formik
          innerRef={this.state.formRef}
          enableReinitialize
          initialValues={{
            name: (farmdetails?.name) || "",
            org_address: (farmdetails?.org_address) || "",
            description: (farmdetails?.description) || "",
            org_contact_number:
              (farmdetails?.org_contact_number?.slice(1)) || "",
            owner_name: (farmdetails?.org_owner_name) || "",
            owner_address: (farmdetails?.org_owner_address) || "",
            owner_contact_number:
              (farmdetails?.org_owner_contact_number?.slice(1)) ||
              "",
            latitude: (farmdetails?.latitude) || "",
            longitude: (farmdetails?.longitude) || "",
            is_same_number: !!farmdetails?.is_same_number,
            isOwnerVerified: isEdit,
            org_otp: "",
            owner_otp: "",
            bannerImages: [],
            selectedImages: [],
            editImages: (farmdetails?.images) || [],
            address: (farmdetails?.farm_pick_up_addresses) || [],
            isEditAddress: false,
            editAddressValues: "",
            farm_id: this.state.farmDetails.id,
            isExpanded: false,
            verifiedAddress:
              (farmdetails?.org_owner_address) || "",
            expanded: true,
            facebook:(farmdetails?.facebook) || "",
            instagram:(farmdetails?.instagram) || "",
            purchase_limit_percentage: farmdetails?.purchase_limit_percentage || 0,
            iconsIds:this.gettingIcons(farmdetails)
          }}
          validationSchema={Schema}
          onSubmit={(values) => {
            values.verifiedAddress
              ? this.createFarm(values)
              : toast.error("Please verify your email address");
          }}
        >
          {(formikProps) => {
            const {
              values,
              setFieldValue,
              handleChange,
              touched,
              errors,
              resetForm,
            } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <div className="tabPanel regStepperContainer farmDetailsTabPanel">
                  <div className="headerFarm">
                    <h4>
                      Farm / Market Details{" "}
                      <span>{farmdetails?.status === "pending" && "Verification Pending"}</span>
                    </h4>
                    <div
                      className="plainButton"
                      onClick={() => {
                        //@ts-ignore
                        this.props.history.goBack();
                      }}
                    >
                      <CustomButton label="Back" />
                    </div>
                  </div>

                  <div className="regInfoContainer">
                    <div className="regAdress">
                      <h4>Register Information</h4>
                      <div className="regRestInfoForm">
                        <h6>Farm Name</h6>
                        <div className="outlineTextfield boxRadiusBorder">
                          <div className="customTextfield">
                            <TextField
                              id="name"
                              variant="standard"
                              value={values.name}
                              placeholder="Enter Farm Name"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              error={this.isError(errors.name, touched.name)}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="name" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="regRestInfoForm">
                        <h6>Farm Address</h6>
                        <div className="outlineTextfield boxRadiusBorder">
                          <div className="customTextfield">
                            <TextField
                              id="org_address"
                              variant="standard"
                              value={values.org_address}
                              placeholder=" Enter Farm Address"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              error={this.isError(errors.org_address, touched.org_address)}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="org_address" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="regRestInfoForm">
                        <h6>
                          Please place the pin accurately at your
                          outlet's location on the map
                        </h6>
                        <p>
                          {" "}
                          This will help your customer to locate
                          your store
                        </p>

                        <CustomLocationErrorMessage
                          isLatError={errors.latitude?.toString()}
                          isLatTouched={!!touched.latitude}
                          isLngError={errors.longitude?.toString()}
                          isLngTouched={!!touched.longitude}
                        />
                        
                        <CustomGooglePlaceAutoComplete
                          placeholder="Enter your farm locality"
                          lat={Number(this.state.mapCoOrdinates.lat)}
                          lng={Number(this.state.mapCoOrdinates.lng)}
                          detectPlace={this.detectPlace}
                          setFieldValue={setFieldValue}
                          handlePlaceSelect={this.handlePlacesAutocompleteSelect}
                        />
                      </div>
                    </div>
                    <div className="iframeContainer">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <CustomGoogleMap
                            data-test-id="googleMap"
                            lat={this.state.mapCoOrdinates.lat}
                            lng={this.state.mapCoOrdinates.lng}
                            handleDragEnd={(lat, lng) => this.handleChangeLatLng(lat, lng, setFieldValue)}
                          />
                        </div>
                      </div>
                      <div className="coOrdinateContainer">
                        <h6 className="ordinateHeader">
                          <span>
                            or directly enter the co-ordinates
                          </span>
                        </h6>
                        <div className="coOrdinate-form">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <TextField
                                    value={values.latitude}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                      this.handleChangeLat(e, values.longitude, setFieldValue)
                                    }
                                    variant="standard"
                                    placeholder="Latitude"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className="outlineTextfield boxRadiusBorder">
                                <div className="customTextfield">
                                  <TextField
                                    value={values.longitude}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                      this.handleChangeLng(e, values.latitude, setFieldValue)
                                    }
                                    variant="standard"
                                    placeholder="Longitude"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                    <div className="restContactNumber">
                      <div className="contact row">
                        <div className="outlineTextfield boxRadiusBorder textarea-wrapper">
                          <label>Farm Description</label>
                          <br />
                          <div className="customTextAreafield">
                            <TextareaAutosize
                              data-test-id="description"
                              id="description"
                              className="description"
                              minRows={3}
                              value={values.description}
                              placeholder="Enter Description"
                              //@ts-ignore
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              error={this.isError(errors.description, touched.description)}
                            />
                            <span className="errorMsg">
                              <ErrorMessage name="description" />
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="conactNumber">
                            <h6>Contact number at Farm</h6>
                            <p>
                              Your customers will call on this
                              number for general enquiries
                            </p>
                            <div className="conactNumberForm">
                              <Grid container>
                                <Grid item xs={12} sm={8}>
                                  <div className="outlineTextfield boxRadiusBorder">
                                    <div className="customTextfield">
                                      <TextField
                                        id="org_contact_number"
                                        variant="standard"
                                        value={
                                          values.org_contact_number
                                        }
                                        placeholder="Mobile Number at Farm"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton aria-label="toggle password visibility">
                                                <span className="countryCodeIcon">
                                                  <p>+ 1</p>
                                                </span>
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e: any) =>
                                          this.handleOrgNumberChange(e, values, setFieldValue)
                                        }
                                        error={
                                          this.isError(errors.org_contact_number, touched.org_contact_number)
                                        }
                                        disabled={
                                          //@ts-ignore
                                          values.isOrgVerified &&
                                          //@ts-ignore
                                          !values.isEditOrgVerified
                                        }
                                      />
                                      <span className="errorMsg">
                                        <ErrorMessage name="org_contact_number" />
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="ownerDetailsContainer">
                      <h4>Social Media Account</h4>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="instagram"
                                variant="standard"
                                value={values.instagram}
                                placeholder="https://www.instagram.com"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton aria-label="toggle password visibility">
                                        <span>
                                          <img src={instagram}/>
                                        </span>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  this.isError(errors.instagram, touched.instagram)
                                }
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="instagram" />
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="facebook"
                                variant="standard"
                                value={values.facebook}
                                placeholder="https://www.facebook.com"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton aria-label="toggle password visibility">
                                        <span>
                                        <img src={facebook}/>
                                        </span>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleChange}
                                error={this.isError(errors.facebook, touched.facebook)}
                              />
                                <span className="errorMsg">
                                  <ErrorMessage name="facebook" />
                                </span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container sm={12} md={6} className="outletContainer productVariety">
                        {farmIcons?.map((icon:any)=>{
                          return(
                            <Grid item key={icon.id} className="productVarietyGrid">
                                  <CustomCheckBox
                                    name={icon.id}
                                    iconsIds={values.iconsIds}
                                    setValue={setFieldValue}
                                    imageUrl={icon.attributes.icon.url}
                                  />
                                </Grid>

                          )
                        })}
                        </Grid>                       
                    </div>
                    <hr />
                    <div className="ownerDetailsContainer">
                      <h4>Register Owner Details</h4>
                      <p>Farm owner details</p>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="owner_name"
                                variant="standard"
                                value={values.owner_name}
                                placeholder="Owner Name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={handleChange}
                                error={
                                  this.isError(errors.owner_name, touched.owner_name)
                                }
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="owner_name" />
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className="outlineTextfield boxRadiusBorder farmOwnerDetailsWrapper">
                            <div className="customTextfield">
                              <TextField
                                data-test-id="OwnerNumber"
                                id="owner_contact_number"
                                variant="standard"
                                value={values.owner_contact_number}
                                placeholder="Owner's Mobile Number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton aria-label="toggle password visibility">
                                        <span className="countryCodeIcon">
                                          <p>+ 1</p>
                                        </span>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleOwnerNumberChange(e, setFieldValue)}
                                error={this.isError(errors.owner_contact_number, touched.owner_contact_number)}
                                disabled={values.is_same_number}
                              />
                              {!values.is_same_number && (
                                <span className="errorMsg">
                                  <ErrorMessage name="owner_contact_number" />
                                </span>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className="farmOwnerCheckbox customCheckbox">
                            <input
                              type="checkbox"
                              id="is_same_number"
                              checked={values.is_same_number}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.handleSameNumberChange(e, values, setFieldValue)
                              }
                            />
                            <label
                              className="is_same_number"
                              htmlFor="is_same_number"
                            >
                              Same as Farm
                            </label>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <div className="customTextfield">
                              <TextField
                                id="owner_address"
                                variant="standard"
                                value={values.owner_address}
                                placeholder="Enter Owner Email Address"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  this.handleOwnerEmailChange(e, values, setFieldValue)
                                }
                                error={this.isError(errors.owner_address, touched.owner_address)}
                              />
                              <span className="errorMsg">
                                <ErrorMessage name="owner_address" />
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <div className="outlineTextfield boxRadiusBorder">
                            <span className="greenContained">
                              <CustomButton
                                color="secondary"
                                label="Verify"
                                disabled={
                                  errors.owner_address ||
                                  values.isOwnerVerified
                                }
                                onClick={() => {
                                  this.ownersendOtp(
                                    values.owner_address
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </Grid>
                        {values.isOwnerVerified ? (
                          <Grid item xs={12} sm={3} >
                            <div className="verify-block verifyMaginBlock">
                              <span className="verify-icon" />
                              <h5 className="text-verify">
                                verified
                              </h5>
                            </div>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={4}>
                            <div className="otpContainer customOtpContainer verifyMaginBlock">
                              <div className="otpForm">
                                <OtpInput
                                  value={values.owner_otp}
                                  onChange={(otp: string) =>
                                    this.handleOTPChange(otp, setFieldValue)
                                  }
                                  numInputs={4}
                                  separator={<span> </span>}
                                  isDisabled={
                                    //@ts-ignore
                                    !this.state.owner_otp_id
                                  }
                                  isInputNum={true}
                                  disabledStyle={"disabledInput"}
                                />
                                <span className="errorMsg">
                                  <ErrorMessage name="owner_otp" />
                                </span>
                                {/* )} */}
                              </div>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <hr />
                    <div className="bannerImgContainer">
                      <h6 className="farmImgHead">Farm Image</h6>
                      <div className="fileUploadContainer">
                        {values.bannerImages.length > 0 &&
                          values.bannerImages.map(
                            (banner: any) => {
                              return (
                                <div
                                  className="headerImageBlock newUploadFarm newUploadFarm2"
                                  key={banner.lastModified}
                                >
                                  <div className="headerImage">
                                    <img
                                      src={
                                        banner &&
                                        (
                                          window.URL ||
                                          window.webkitURL
                                        ).createObjectURL(banner)
                                      }
                                      className="farmImg"
                                      alt="Farm"
                                    />
                                    <input
                                      type="checkbox"
                                      id={banner.lastModified}
                                      className="correctIconInput"
                                      defaultChecked={false}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        this.handleFileChange(values, banner, e, setFieldValue)
                                      }
                                    />
                                    <label
                                      htmlFor={
                                        banner.lastModified
                                      }
                                      className="correctIconLabel"
                                    >
                                      <img
                                        src={correct_icon}
                                        className="correct_icon"
                                        alt="Correct Icon"
                                      />
                                    </label>
                                  </div>
                                  <h6>Header Image</h6>
                                </div>
                              );
                            }
                          )}

                        <div className="FileUpload newUploadFarmCustom">
                          <div className="customFileUpload">
                            <input
                              type="file"
                              multiple
                              id="fileUpload"
                              className="fileUploadInput"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.handleFileUploadChange(e, values, setFieldValue)
                              }
                            />
                            <label
                              htmlFor="fileUpload"
                              className="fileUploadLabel"
                            >
                              <img
                                src={FileUploadIcon}
                                alt="carrot"
                              />
                              <span>Add Photos</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <span className="errorMsg">
                        <ErrorMessage name="selectedImages" />
                      </span>
                      <div className="fileUploadContainer">
                        <div className="headerImageBlock headerPdfFileBlock newUploadFarm">
                          {isEdit &&
                            farmdetails?.images?.length > 0 &&
                            farmdetails.images.map(
                              (image: any) => {
                                return (
                                  <div
                                    className="headerImage farmHeaderImage newUploadFarmImage"
                                    key={image.id}
                                  >
                                    <div className="pdfFileBlock">
                                      <div className="farmImg">
                                        <img
                                          src={image.url}
                                          className="farmImg"
                                          alt="img"
                                        />
                                        <button
                                          type="button"
                                          className="close_icon"
                                          onClick={() => {
                                            this.deleteImage(
                                              image.id
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="deliveryDetailsBlock">
                      <h6 style={{"marginTop":"25px                                               "}}>Delivery Details</h6>
                      <span className="errorMsg">
                        <ErrorMessage name="address" />
                      </span>
                      <hr />
                      <p>
                        You can add multiple pickup address so user
                        can select most nearest pickup point.
                      </p>
                      <div className="deliveryDetailBlocks">
                        {values.address.length > 0 &&
                          values.address.map(
                            (item: any) => {
                              return (
                                <div className="deliveryDetailRow" key={item.city}>
                                  <div className="deliveryBlocks deliveryBlock1">
                                    <Typography variant="h6">
                                      {item.city} Farmers Market
                                    </Typography>
                                  </div>
                                  <div className="deliveryBlocks deliveryBlock2">
                                    <Typography variant="h6">
                                      {item.address_line1}
                                    </Typography>
                                  </div>
                                  <div
                                    data-test-id="deleteAddress"
                                    className="deliveryBlocks deliveryBlock3"
                                    onClick={() => {
                                      const deletedAdd = values.address.filter(
                                        (x: any) =>
                                          x.address_line1 !=
                                          item.address_line1
                                      );
                                      setFieldValue(
                                        "address",
                                        deletedAdd
                                      );
                                      toast.success(
                                        "Address Deleted Successfully"
                                      );
                                    }}
                                  >
                                    <Link href="#">Delete</Link>
                                  </div>
                                  <div
                                    data-test-id="editAddress"
                                    className="deliveryBlocks deliveryBlock4"
                                    onClick={() =>
                                      this.handleAddressEdit(item, setFieldValue)
                                    }
                                  >
                                    <Link href="#">Edit</Link>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                    <div className="commonAccordian">
                      <Formik
                        innerRef={this.state.addressRef}
                        enableReinitialize
                        initialValues={{
                          address_line1: "",
                          address_line2: "",
                          city: "",
                          state: "",
                          zipcode: "",
                          number: "",
                          email: "",
                        }}
                        validationSchema={addressSchema}
                        onSubmit={(
                          values: any,
                          { resetForm }: any
                        ) =>
                          this.handleNewAddressSubmit(formikProps, values, formikProps.setFieldValue, resetForm)
                        }
                      >
                        {(formikProps1) => {
                          const {
                            values,
                            setFieldValue,
                            handleChange,
                            touched,
                            errors,
                          } = formikProps1;
                          return (
                            <Form
                              noValidate
                              autoComplete="off"
                            >
                              <Accordion
                                expanded={
                                  formikProps.values.expanded
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    "expanded",
                                    !formikProps.values.expanded
                                  );
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <h6>Add New Pickup Address</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={5}>
                                    <Grid item xs={12} sm={6}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="address_line1"
                                            variant="standard"
                                            value={
                                              values.address_line1
                                            }
                                            placeholder="Address Line 1"
                                            label="Pick up Address"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={
                                              this.isError(errors.address_line1, touched.address_line1)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="address_line1" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="address_line2"
                                            variant="standard"
                                            value={
                                              values.address_line2
                                            }
                                            placeholder="Address Line 2"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={
                                              this.isError(errors.address_line2, touched.address_line2)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="address_line2" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={5}>
                                    <Grid item xs={12} sm={4}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="city"
                                            variant="standard"
                                            value={values.city}
                                            placeholder="city"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={
                                              this.isError(errors.city, touched.city)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="city" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="state"
                                            variant="standard"
                                            value={values.state}
                                            placeholder="state"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={
                                              this.isError(errors.state, touched.state)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="state" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="zipcode"
                                            variant="standard"
                                            value={values.zipcode}
                                            placeholder="zipcode"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={this.isError(errors.zipcode, touched.zipcode)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="zipcode" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={5}
                                    className="contactRow"
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="number"
                                            variant="standard"
                                            value={values.number}
                                            placeholder="Contact number"
                                            label="Contact No."
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              this.handleAddressNumberChange(e, setFieldValue)
                                            }
                                            error={this.isError(errors.number, touched.number)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="number" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <div className="outlineTextfield boxRadiusBorder">
                                        <div className="customTextfield">
                                          <TextField
                                            id="email"
                                            variant="standard"
                                            value={values.email}
                                            placeholder="Email address"
                                            label="E-mail Address"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={handleChange}
                                            error={this.isError(errors.email, touched.email)}
                                          />
                                          <span className="errorMsg">
                                            <ErrorMessage name="email" />
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <span className="greenContained">
                                        <CustomButton
                                          color="secondary"
                                          label={`${formikProps.values.isEditAddress ? "Save" : "Add new"}`}
                                          type="submit"
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <hr />

                    <div>
                      <RewardAcceptanceSelect
                        value={values.purchase_limit_percentage}
                        name="purchase_limit_percentage"
                        handleValueChange={(value: number) => setFieldValue("purchase_limit_percentage", value)}
                      />
                      <AdminTransactionHistoryButton />
                    </div>

                    <span className="greenContained">
                      <div className="btnContainer farmSaveButton">
                        {isEdit && (
                          <span className="grayButton">
                            <CustomButton
                              color="secondary"
                              label="Cancel"
                              onClick={() => {
                                setFieldValue("otp", "");
                                setFieldValue("owner_otp", "");
                                resetForm();
                                this.setState({
                                  // isEdit: false,
                                });
                                //@ts-ignore
                                this.props.history.goBack();
                              }}
                            />
                          </span>
                        )}
                        <span className="greenContained">
                          <CustomButton
                          data-test-id="createBtn"
                            color="secondary"
                            label={`${isEdit ? "Save" : "Create"}`}
                            type="submit"
                          />
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
//@ts-ignore
export {FarmDetails}
const AdminFarmDetailsrouter = withRouter(FarmDetails);
export default withStyles(commonCmptStyles)(AdminFarmDetailsrouter);
// Customizable Area End
