// Customizable Area Start
import React from "react"
import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/CloseRounded"

const useStyles = makeStyles({
  dialogContent: {
    overflow: "visible"
  },
  paymentContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  paymentIframeWrapper: {
    width: "100%",
    minHeight: "500px"
  },
})

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      position: 'sticky',
      left: 0,
      top: 0,
      right: 0,
      backgroundColor: '#fff',
      zIndex: 1,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      "& svg": {
        color: "#000",
      },
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode
  onClose: () => void
}

interface IDonationDialogProps {
  isOpen: boolean
  handleClose: () => void
}

const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography align="center" variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const DonationDialog = ({ isOpen, handleClose }: IDonationDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isOpen}
      onClose={() => handleClose()}
      classes={{
        paper: "custom_dialog_paper",
      }}
    >
      <CustomDialogTitle onClose={handleClose}>Donation</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.paymentContainer}>
          <iframe
            title="Donation form powered by Zeffy"
            className={classes.paymentIframeWrapper}
            src="https://www.zeffy.com/en-US/embed/donation-form/82d03232-fbab-47f7-a8ce-160add43d4c5"
            allowTransparency
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DonationDialog
// Customizable Area End
