import * as React from "react";
// custom components
import { TextField, InputAdornment } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

import "../../web/src/assets/css/content/textfield.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
export default function CustomTextfield(props: any) {
  const { placeholder, label, ref ,id } = props;

  // const addronment = {
  //   ...props.in
  // }
  return (
    <div className="customTextfield">
      <TextField
        {...props}
        id={id}
        label={label}
        variant="standard"
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
        
      />   
    </div>
  );
}
