//Image
export const Apple_Icon = require("../assets/images/apple-icon.png");
export const Carrot_Image = require("../assets/images/carrot.jpg");
export const Banner_Img = require("../assets/images/bannerImg.jpg");
export const Page_Icon = require("../assets/images/page-icon.png");
export const Computer_Icon = require("../assets/images/computer-icon.png");
export const Order_Icon = require("../assets/images/order-icon.png");
export const Modal_Picture = require("../assets/images/modalpicture.png");
export const WomanPicture = require("../assets/images/women.png");
export const FilterIcon = require("../assets/images/filter-icon.png");
export const OrderImg = require("../assets/images/order1.png");
export const VegetableImg = require("../assets/images/vegetable1.png");
export const ReportsIcon = require("../assets/images/reports_icon.png");
export const CorrectIcon = require("../assets/images/correct_icon.svg");
export const BannerImage = require("../assets/images/bannerImg.jpg");
export const RedDustbin = require("../assets/images/red-dustbin.png");
export const GreenPlus = require("../assets/images/plus.png");
export const PencilIcon = require("../assets/images/pencil_icon.png");
export const EditIcon = require("../assets/images/editing@2x.png");
export const DeleteIcon = require("../assets/images/image-delete@2x.png");
export const Like_Icon = require("../assets/images/like_icon.png");
export const Chat_Icon = require("../assets/images/chat_icon.png");
export const LessonImg1 = require("../assets/images/lesson-img1.png");
export const TodayReport = require("../assets/images/todayReport.svg");
export const WeekReport = require("../assets/images/weekReport.svg");
export const MonthReport = require("../assets/images/monthReport.svg");
export const manual = require("../assets/images/manual.png")
export const cookingCapacity = require("../assets/images/cooking-capacity.png")
export const orderPrice = require("../assets/images/price-tag.png")
export const deleteIcon = require("../assets/images/deleteIcon.png");
export const DelIcon = require("../assets/images/deleteIcon.png");
export const farmTech = require("../assets/images/farm-tech.png")
export const purchaseProfile = require("../assets/images/purchase_profile.png")
export const DustbinIcon = require("../assets/images/dustbin.png")
export const ProfileImg = require("../assets/images/no-profile.svg");
export const ProfileUpload = require("../assets/images/combined-shape-10576@2x.png");
export const DefaultProfileImg = require("../assets/images/group-8.png");
export const DefaultCourseImg = require("../assets/images/group-9.png");
export const fileUploadIcon = require("../assets/images/image-attached.png")
export const facebook = require("../assets/images/facebook.jpg")
export const instagram = require("../assets/images/instagram-1.jpg")
export const grass = require("../assets/images/grass.jpg")
export const antibiotic = require("../assets/images/antibiotic.jpg")
export const steroid = require("../assets/images/steroid-free.jpg")
export const usda= require("../assets/images/usda-certified.jpg")

export const {
  sentOTP1Action,
  sentOTP2Action,
  verifyOTP1Action,
  verifyOTP2Action,
  setSameContactDetails,
  resatOTPFlags,
  verifyUserAction,
} = require("../../../components/src/redux/Users/userActions");

export const {
  getDishAllCategories
} = require("../../../components/src/redux/UserAdminRestaurant/userAdminRestaurantActions");
