// Customizable Area Start
import React, { Component } from "react";
import "../../../../../web/src/assets/css/style.scss";
import { Box, Grid, Button, Dialog } from "@material-ui/core";
import { DoneImg, VeggiesBowl } from "../../assets";
import "../../assets/css/checkout/paymentDoneDialog.scss"
// Customizable Area End

// Customizable Area Start
interface Props {
  isOpen: boolean
  isRestaurantOrder?: boolean
  transactionId: string[]
  onAccept: () => void
}
// Customizable Area End

class PaymentDoneDialog extends Component<Props> {

  render() {
    // Customizable Area Start
    const { isOpen, isRestaurantOrder, transactionId, onAccept } = this.props
    const displayImage = isRestaurantOrder ? VeggiesBowl : DoneImg
    const mainMessage = isRestaurantOrder ? 'Order Placed' : 'Payment Successful'
    const isSingleTransactionId = transactionId.length === 1
    const transactionIdMessage = isSingleTransactionId ? "Transaction ID" : "Transaction IDs" 

    return (
      <Dialog
        open={isOpen}
        onClose={() => onAccept()}
        maxWidth="xs"
        fullWidth
        classes={{
          paper: "custom_dialog_paper",
        }}
      >
        <Box className="pd_main_wrapper">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box className="pd_content_wrapper">
                <img src={displayImage} alt="done" />
                <h5>{mainMessage}</h5>
                <h6>Thank you for your payment</h6>
                <p>
                  {transactionIdMessage} : {isSingleTransactionId && transactionId[0]}
                </p>
                {
                  !isSingleTransactionId && (
                    <ul className="pd_multi_id_wrapper">
                      {transactionId.map((id: string) => {
                        return (
                          <li key={id}>
                            <p className="pd_multi_id">{id}</p>
                          </li>
                        )
                      })}
                    </ul>
                  )
                }
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onAccept}>OK</Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
    // Customizable Area End
  }
}

export default PaymentDoneDialog;
