Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.AUTH_TOKEN = "authToken";
exports.ORDERS_STORAGE_KEY = "orders";
exports.FIRST_NAME_KEY = "firstname";
exports.LAST_NAME_KEY = "lastname";
exports.PROFILE_KEY = "profile";
exports.CART_ID_KEY = "cartId";
exports.CART_BADGE_KEY = "cartBadge";
exports.ADMIN_AUTH = "AdminAuth";
// Customizable Area End
